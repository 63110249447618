import React, { Component } from 'react'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import * as selectors from '../../app/selectors'
import { KBPopover, KBPopoverTop, KBError, KBSuccess } from 'components'
import config from 'app/config'

var AssistBox = React.createClass({
  componentDidMount() {
    this._ensureNotLoggedIn(this.props)
  },
  componentWillReceiveProps(nextProps) {
    this._ensureNotLoggedIn(nextProps)
  },
  _ensureNotLoggedIn(props) {
    //const { isAuthenticated, routerActions } = props
    //if (Object.keys(isAuthenticated).length&&isAuthenticated) {
    //  routerActions.push('/')
    //}
  },
  render() {
    return (
      <div className="kb-assist-box">
        <div className="lr-header t-center">
          <div className="lr-logo">
            <Link to="/">
              <img
                src={config.logo_url_horizontal}
                alt="logo"
                style={{ height: 40, marginTop: 10 }}
              />
            </Link>
          </div>
        </div>
        {this.props.children}
        {/* <div className="lr-footer">
          <div className="kb-record"><span>沪ICP备16024831号</span></div>
        </div> */}
        <KBError error={this.props.error} />
        <KBSuccess success={this.props.success} />
        <KBPopover />
        <KBPopoverTop />
      </div>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const isAuthenticated = selectors.isAuthenticated(state)
  return {
    success: state.success || {},
    error: state.error, // save global error here
    isAuthenticated
  }
}

// Map action creators dispatch to props
// So we can call this.props.actions.<actionName>(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssistBox)
