import React from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { locationFloorsAction, locationActions } from 'app/actions'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import { connect } from 'react-redux'
import { KBPopover, KBTipsy } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import FloorInfoForm from '../desks/FloorInfoForm'
import { canAccessLocation } from 'app/reducers/role'

var AreasBox = React.createClass({
  displayName: 'AreasBox',

  getInitialState() {
    return {
      selectedFloorSection: '全部',
      selectedFloor: ''
    }
  },

  componentDidMount() {
    this.getLocFloors()
  },

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.floors) !== JSON.stringify(nextProps.floors)
    ) {
      const currentFloors = nextProps.floors || []
      this.setState({
        selectedFloor: '',
        floorInfoId: currentFloors.map(f => f.id).join(',')
      })
    }
  },
  getLocation() {
    const { getLocationInfo, loc_id } = this.props
    api.getLocationInfo(loc_id).then(
      json => {
        getLocationInfo.success(json)
        this.refreshAreaStatistic()
      },
      errors => {
        getLocationInfo.failure(errors)
      }
    )
  },
  getLocFloors(params) {
    const { loc_id } = this.props

    api.getLocationFloors(loc_id, params).then(
      json => {
        this.props.getLocationFloorsAction.success(json, {
          location_id: loc_id,
          key: 'locationFloors'
        })
      },
      error => {
        this.props.getLocationFloorsAction.failure(error)
      }
    )
  },

  addFloorInfo() {
    KBPopover.show(
      <FloorInfoForm loc_id={this.props.loc_id} callback={this.getLocation} />
    )
  },

  selectFloorSection(f_sec) {
    this.getLocFloors({
      layer_num_min: f_sec.start_floor,
      layer_num_max: f_sec.end_floor
    })

    this.setState({
      selectedFloorSection: f_sec.label
    })
  },

  selectExactFloor(floor) {
    const { floors } = this.props
    this.setState({
      selectedFloor: floor.layer_num || '',
      floorInfoId: floor.id || floors.map(f => f.id).join(',')
    })
  },

  deleteFloor(floor) {
    const { loc_id, deleteLocationFloorsAction } = this.props
    KBPopoverConfirm({
      name: '删除楼层',
      context: `您确定要删除楼层 ${floor.layer_num} 吗?`,
      callback: () => {
        return api.deleteLocationFloor(loc_id, floor.id).then(
          json => {
            deleteLocationFloorsAction.success(json, {
              key: 'locationFloors',
              id: floor.id
            })
            KBPopover.close()
            this.getLocation()
          },
          error => {
            deleteLocationFloorsAction.failure(error)
          }
        )
      }
    })
  },

  render() {
    const {
      loc_id,
      location_entering_rate_calc_type,
      lastSection,
      isTowerLoc,
      floorSections,
      floors,
      isManager
    } = this.props
    const { selectedFloorSection, selectedFloor, floorInfoId } = this.state
    let floorInfoIds = floorInfoId
    if (selectedFloorSection == '全部' && !selectedFloor) {
      floorInfoIds = ''
    }
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        location_entering_rate_calc_type,
        isTowerLoc,
        floorInfoId: floorInfoIds,
        locFloors: floors
      })
    )

    return (
      <div style={{ height: '100%' }}>
        <header
          className="m-bottom-sm"
          style={{
            height: 30,
            width: 1136,
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <span className="f-left" style={{ fontSize: 24 }}>
            分区管理
          </span>
          <div className="edit-way f-right">
            {/** <Link className={!lastSection  ? 'active' : ''}  to={ `/admin/locations/${loc_id}/areas/desks` } >平面图</Link> */}
            <Link
              className={`${
                lastSection && lastSection == 'list' ? 'active' : ''
              }`}
              to={`/admin/locations/${loc_id}/areas/desks/list`}
            >
              列表显示
            </Link>
            <Link
              className={`border-left-none ${
                lastSection && lastSection == 'area' ? 'active' : ''
              }`}
              to={`/admin/locations/${loc_id}/areas/desks/area`}
            >
              切面显示
            </Link>
            {
              // isTowerLoc ? null :
              <Link
                className={
                  lastSection && lastSection == 'time_line' ? 'active' : ''
                }
                to={`/admin/locations/${loc_id}/areas/desks/time_line`}
              >
                时间轴显示
              </Link>
            }
          </div>
        </header>
        {
          // isTowerLoc ?
          <div
            className="org-filter m-bottom-sm"
            style={{ position: 'relative' }}
          >
            {isManager ? (
              <button
                className="bordered-btn"
                style={{ position: 'absolute', right: 20, top: 10 }}
                onClick={this.addFloorInfo}
              >
                添加楼层
              </button>
            ) : null}
            <div className="search-topic">
              <span style={{ color: '#7F8FA4', fontSize: 14, marginRight: 5 }}>
                楼层区间:
              </span>
              {floorSections.map((f_sec, index) => (
                <span
                  key={index}
                  className={`tag-str ${
                    f_sec.label === selectedFloorSection ? 'topic-click' : ''
                  }`}
                  onClick={() => {
                    this.selectFloorSection(f_sec)
                  }}
                >
                  {f_sec.label}
                </span>
              ))}
            </div>
            {true ? (
              <div
                className="search-topic"
                style={{
                  paddingTop: 16,
                  marginTop: 16,
                  borderTop: '1px solid #DFE2E5'
                }}
              >
                <span
                  style={{ color: '#7F8FA4', fontSize: 14, marginRight: 5 }}
                >
                  具体楼层:
                </span>
                <span
                  className={`letter-str-all ${
                    !selectedFloor ? 'letter-str-all_active' : ''
                  }`}
                  style={{ width: 'auto' }}
                  onClick={() => {
                    this.selectExactFloor({})
                  }}
                >
                  全部
                </span>
                {floors.map((floor, index) => {
                  return (
                    <span
                      key={index}
                      className={`tag-str ${
                        floor.layer_num === selectedFloor ? 'topic-click' : ''
                      } deletable-block`}
                      onClick={() => {
                        this.selectExactFloor(floor)
                      }}
                    >
                      {floor.layer_num}
                      {isManager ? (
                        <KBTipsy content="删除楼层">
                          <i
                            className="iconfont icon-close"
                            onClick={e => {
                              e.stopPropagation()
                              this.deleteFloor(floor)
                            }}
                          />
                        </KBTipsy>
                      ) : null}
                    </span>
                  )
                })}
              </div>
            ) : null}
          </div>
          // : null
        }
        {childrenWithProps}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let sections = location.pathname.split('/')
  let section = sections.length > 2 ? sections[3] : ''
  const locationSettings = selectors.getLocationSetting(state, loc_id) || {}
  const location_settings = locationSettings.location_settings || {}
  const location_entering_rate_calc_type =
    location_settings.entering_rate_calc_type || 'by_desk'
  const lastSection = sections.length > 6 ? sections[6] : ''

  const currentLocation = selectors.getLocation(state, loc_id) || {}
  const maxFloor = currentLocation.floor_layer_num_max || 0
  const minFloor = currentLocation.floor_layer_num_min || 0
  const totalFloorAboveGroundSecs = maxFloor
    ? parseInt((maxFloor - 1) / 10, 10) + 1
    : 0
  const totalFloorUnderGroundSecs = minFloor
    ? parseInt((1 - minFloor) / 10, 10) + 1
    : 0
  const floorSections = []
  // 处理地下楼层
  for (let i = 1; i < totalFloorUnderGroundSecs + 1; i++) {
    let endFloor = -1 - 10 * (i - 1)
    let startFloor = endFloor - 10 < minFloor ? minFloor : endFloor - 9
    if (startFloor != 1 || endFloor != -1) {
      floorSections.push({
        label: `${startFloor} ~ ${endFloor}层`,
        start_floor: startFloor,
        end_floor: endFloor
      })
    }
  }
  floorSections.reverse()
  // 处理地上楼层
  for (let i = 1; i < totalFloorAboveGroundSecs + 1; i++) {
    let startFloor = 1 + 10 * (i - 1)
    let endFloor = startFloor + 10 > maxFloor ? maxFloor : startFloor + 9
    floorSections.push({
      label: `${startFloor} ~ ${endFloor}层`,
      start_floor: startFloor,
      end_floor: endFloor
    })
  }
  floorSections.unshift({
    label: '全部',
    start_floor: minFloor,
    end_floor: maxFloor
  })
  const floors = selectors.getLocationFloorsFromState(state) || []
  floors.sort((a, b) => a.layer_num_i - b.layer_num_i)
  const { user } = state
  let isManager = canAccessLocation(user, loc_id)

  return {
    section: section || '',
    sections,
    lastSection,
    loc_id,
    location_entering_rate_calc_type,
    floorSections,
    floors,
    isManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLocationInfo: bindActionCreators(locationActions.update, dispatch),
    getLocationFloorsAction: bindActionCreators(
      locationFloorsAction.replace,
      dispatch
    ),
    deleteLocationFloorsAction: bindActionCreators(
      locationFloorsAction.delete,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AreasBox)
