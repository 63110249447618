import React from 'react'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import * as valid from 'utils/validate'
import { KBForm, KBInput, KBTransfer, KBButton, KBUserInput } from 'components'
import { arrayToObject, getServiceErrorMessage } from 'utils/kbUtil'
import { api } from 'app/services'
import { apiCallFailure } from 'app/actions'
import { canAdminSpace } from 'app/reducers/role'
import _ from 'lodash'

var OrgRentInfoForm = React.createClass({
  getInitialState() {
    const { floorsObj, areaSource, areaTarget } = this.props
    return {
      formSubmitting: this.props.formSubmitting,
      areaSource,
      areaTarget,
      floorsObj,
      currentSelectedFloors: []
    }
  },

  componentDidMount() {
    const { areaSource } = this.state
    if (areaSource.length < 1) {
      this.getLocFloors()
    }
  },

  getLocFloors() {
    const { location_id } = this.props

    api
      .getLocationFloors(location_id)
      .then(json => {
        const floors = json.response.body || []
        const floorsObj = arrayToObject(floors)
        this.setState({
          floorsObj: floors
        })
      })
      .finally(() => {

        const {org,setFloorAndAreas, fields: {floor_info_id}} = this.props
        const {floorsObj} = this.state
        const currentFloorSource = this.getGroupedFloor()

        const currentOrgAreas = (org && org.areas) || []
        const areaTarget = this.getGroupedAreasByFloor(currentOrgAreas)

        this.setState({
          areaSource: currentFloorSource,
          areaTarget
        }, () => {
          if (org.floor_info_id) {
            const {org, fields: {floor_info_id}} = this.props
            floor_info_id.onChange(org.floor_info_id)
          }
        })
        setFloorAndAreas && setFloorAndAreas(floorsObj, currentFloorSource, areaTarget)
        this.selectedAreaOnChange(areaTarget)
      })
  },

  getGroupedFloor() {
    const { floorsObj } = this.state

    let groupedFloor = []
    floorsObj &&
      floorsObj.map(data => {
        let id = data.id || groupedFloor.length
        let name = (data.layer_num)

        let childrens = []
        groupedFloor.push({ id, name, childrens })
      })
      return groupedFloor
  },

  getAllAreasOfCurrentLoc(floor_info_id) {
    const { location_id, setFloorAndAreas, org } = this.props
    const { floorsObj } = this.state

    api
      .getAreas({
        location_id,
        area_type: 'private_office',
        includes: 'organizations,areas',
        floor_info_id: floor_info_id || null,
        per_page: 1000
      })
      .then(json => {
        const areas = json.response.body || []
        const currentOrgAreas = (org && org.areas) || []
        const currentOrgAreaIds = currentOrgAreas.map(a => a.id)
        const filteredAreas = areas.filter(
          a => !['assigned', 'disabled'].includes(a.state)
        )
        const areaSource = this.getGroupedAreasByFloor(areas)
        const areaTarget = this.getGroupedAreasByFloor(currentOrgAreas)

        this.setState({
          areaSource,
          areaTarget
        })
        setFloorAndAreas && setFloorAndAreas(floorsObj, areaSource, areaTarget)
        this.selectedAreaOnChange(areaTarget)
      })
  },

  getGroupedAreasByFloor(areas) {
    const { floorsObj } = this.state
    const groupedAreasByFloor = _.groupBy(areas, 'floor_info_id')
    let arrayedGroupedAreas = Object.values(groupedAreasByFloor)
    let groupedAreas = []

    arrayedGroupedAreas &&
      arrayedGroupedAreas.map(data => {
        let id = groupedAreas.length || 0
        let nameIndex = data[0] && data[0].floor_info_id
        let name =
          (_.find(floorsObj, ['id', nameIndex]).layer_num) || '楼层未定'
        let childrens = data
        groupedAreas.push({ id, name, childrens })
      })

    return groupedAreas
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.formSubmitting !== this.props.formSubmitting) {
      this.setState({
        formSubmitting: nextProps.formSubmitting
      })
    }
  },

  saveOrgInfoOfComplete(model) {
    const { saveParams } = this.props
    saveParams && saveParams(model, 3, true)
  },

  // saveOrgInfoOfUpdate(model) {
  //   const { saveParams } = this.props
  //   const selectedAreasTarget = this.refs.areaTransfer.getData().target
  //   let selectedAreas = []
  //   selectedAreasTarget.map(tar => {
  //     selectedAreas = selectedAreas.concat(tar.childrens)
  //   })

  //   if (selectedAreas.length) {
  //     model.area_id = selectedAreas.map(a => a.id).join(',')
  //     return this.linkAreasWithCurrentOrg(model)
  //   }
  //   return saveParams && saveParams(model, false, true)
  // },

  // linkAreasWithCurrentOrg(model) {
  //   const {
  //     saveParams,
  //     apiCallFailureActions,
  //     location_id,
  //     org,
  //     org_id
  //   } = this.props

  //   const params = Object.assign(
  //     {},
  //     { location_id, organization_id: org_id || org.id, area_id: model.area_id }
  //   )

  //   api.createTowerLocationSubscription(params).then(
  //     json => {
  //       delete model.area_id
  //       saveParams && saveParams(model, false, true)
  //     },
  //     error => {
  //       apiCallFailureActions({
  //         status: 'error',
  //         message: getServiceErrorMessage(error)
  //       })
  //     }
  //   )
  // },

  saveOrgInfoOfLast() {
    const { fields, saveParams, setFloorAndAreas } = this.props
    const areaTarget = this.refs.areaTransfer.getData().target
    let model = {}
    Object.keys(fields).map(field_key => {
      model[field_key] = fields[field_key].value
    })
    saveParams && saveParams(model, 2)
    setFloorAndAreas && setFloorAndAreas(null, null, areaTarget)
  },

  getAreas(id) {

    const { location_id } = this.props

    let areas = api.getAreas({
      location_id,
      area_type: 'private_office',
      // includes: 'organizations,areas',
      includes: 'occupancy',
      floor_info_id: id || null,
      per_page: 1000
    })
    .then(({ json }) => {
      let newArr = []
      const { entities: { areas = {} } = {} } = json
      Object.values(areas).map(item => {
        if (item.state !== 'assigned' && item.state !== 'disabled') {
          newArr.push(item)
        }
      })
      return newArr
    })
    return areas
  },

  selectedAreaOnChange(data) {
    const {
      fields: { floor_info_id, area_ids },
      org
    } = this.props
    const floorsArray = Object.values(this.state.floorsObj)
    let floorsOrgIn = []
    let areas = []
    const selectedFloorIds = data.map(d => {
      areas = areas.concat(d.childrens)
      return d.childrens[0].floor_info_id
    })
    floorsOrgIn = floorsArray
      .filter(f => selectedFloorIds.includes(f.id))
      .map(f => {
        f.name = f.layer_num
        return f
      })
    this.setState({
      currentSelectedFloors: floorsOrgIn
    })
    if (floorsOrgIn.length === 1) {
      floor_info_id.onChange(floorsOrgIn[0] && floorsOrgIn[0].id)
    } else {
      floor_info_id.onChange(
        (org && org.floor_info_id) ||
          (floorsOrgIn[0] && floorsOrgIn[0].id) ||
          null
      )
    }
    area_ids.onChange(areas.map(a => a.id).join(','))
  },

  render() {
    const {
      fields: { floor_info_id, face_plus_settings, area_ids }
    } = this.props
    const { handleSubmit, isUpdate, isWizard, isSpaceAdmin } = this.props
    const {
      formSubmitting,
      areaSource,
      areaTarget,
      currentSelectedFloors
    } = this.state
    const selectedFloor = currentSelectedFloors.filter(
      cf => cf.id == floor_info_id.value
    )

    return (
      <KBForm type="reduxForm" fields={this.props.fields}>
        <header className="kb-form-header">
          <h2 className="header-title">租赁信息</h2>
        </header>
        <div className="kb-form-container">
          {/* <div className="kb-tip-warning">
						<div className="icon"><i className="iconfont icon-hint" /></div>
						<div className="msg">注意：修改公司租赁房间信息后需要手动更新员工门禁权限</div>
					</div> */}
          <div
            style={{
              paddingBottom: 22,
              color: '#7F8FA4',
              fontSize: 12,
              fontWeight: 'bold'
            }}
          >
            <span className="must-fillR">*</span>租赁房间信息
          </div>
          <div className="kb-row">
            <KBTransfer
              ref="areaTransfer"
              isSearch={false}
              isHead={true}
              isMiddle={false}
              isChildrenLoad={true}
              getExpandChilds={this.getAreas}
              multi={true}
              sourceTitle="可租房间"
              targetTitle="选中房间"
              source={areaSource}
              target={areaTarget}
              targetDataOnChange={this.selectedAreaOnChange}
            />
            {area_ids.touched && area_ids.error ? (
              <p className="lr-error">{area_ids.error}</p>
            ) : null}
          </div>
          <div className="kb-row kb-form-66">
            <KBUserInput
              title="默认楼层"
              isRequired={true}
              placeholder="选择公司默认楼层"
              className="kb-form-group kb-form-210 kb-form-alone"
              field={floor_info_id}
              disabled={currentSelectedFloors.length === 1}
              users={currentSelectedFloors}
              selectedUsers={selectedFloor}
              multi={false}
            />
          </div>

          {/* {
						isSpaceAdmin ?
						<div className="kb-row" >
							<div className="border-top m-top" style={{ padding: '22px 0', color: '#7F8FA4', fontSize: 12, fontWeight: "bold" }} >Face++ 设置</div>
							<div className='kb-row kb-form-66'>
								<KBInput className='kb-form-group kb-form-210 kb-form-alone' field={face_plus_settings.base_url} title='URL' />
							</div>
							<div className='kb-row kb-form-66'>
								<KBInput className='kb-form-group kb-form-210 kb-form-alone' field={face_plus_settings.username} title='User name' />
							</div>
							<div className='kb-row kb-form-66'>
								<KBInput className='kb-form-group kb-form-210 kb-form-alone' field={face_plus_settings.password} title='Password' />
							</div>
						</div>
						: null
					} */}

          <div className="kb-row">
            {!isWizard ? (
              <KBButton
                className="certain-btn"
                type="button"
                onClick={this.saveOrgInfoOfLast}
                style={{ marginRight: 30 }}
              >
                上一步
              </KBButton>
            ) : null}
            {!isUpdate ? (
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={formSubmitting}
                onClick={handleSubmit(this.saveOrgInfoOfComplete)}
              >
                {/* 稍后填写资料 */}
                完成编辑
              </KBButton>
            ) : (
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={formSubmitting}
                onClick={handleSubmit(this.saveOrgInfoOfComplete)}
              >
                完成编辑
              </KBButton>
            )}
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { org, urlParams } = props
  const { user } = state
  const isUpdate = urlParams && urlParams.type === 'update' && org
  const isWizard = urlParams && urlParams.wizard === '1' && org
  const org_id = urlParams && urlParams.org_id
  let space_id = Object.keys(state.entities.spaces || {})[0]
  let posUrl = (org && org.business_license_positive_url) || ''
  let negUrl = (org && org.business_license_negative_url) || ''
  let defaultBusinessLicense = posUrl ? [posUrl] : null
  let defaultOtherLicense = negUrl ? [negUrl] : null
  const isSpaceAdmin = canAdminSpace(user)

  return {
    org,
    org_id,
    space_id,
    isUpdate,
    isWizard,
    defaultBusinessLicense,
    defaultOtherLicense,
    isSpaceAdmin,
    initialValues: org
      ? {
          ...org
        }
      : {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.area_ids)) {
    errors.area_ids = '请至少选择一个分区作为默认分区'
  }

  if (valid.required(values.floor_info_id)) {
    errors.floor_info_id = '请选择公司默认楼层'
  }

  return errors
}

const formConfig = {
  form: 'OrgRentInfoForm',
  fields: [
    'area_ids',
    'floor_info_id',
    'face_plus_settings.base_url',
    'face_plus_settings.username',
    'face_plus_settings.password'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgRentInfoForm)
