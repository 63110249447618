import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { KBPopover, KBInput } from 'components'
import { apiCallFailure } from 'app/actions'
import OrgSubscriptionAreasForm from './OrgSubscriptionAreasForm'
import OrgSubscriptionAllotDeskForm from './OrgSubscriptionAllotDeskForm'
import validator from 'validator'
import { notification } from 'antd'

var OrgSubscriptionDeskListPrivateOfDesk = React.createClass({
  getInitialState() {
    return {
      selectArea: {},
      allotType: 'auto',
      selectDesks: [],
      disabledPrice: false,
      disabledPriceTotal: false
    }
  },

  componentDidMount() {
    const {
      desk: { office_price, totalRent },
      calculationDeposit
    } = this.props
    calculationDeposit({ target: { value: totalRent.value || 0 } }, totalRent)
    this.officePriceChange({ target: { value: office_price.value || 0 } })
  },

  selectAreas() {
    const {
      loc_id,
      endDateValue,
      contract_id,
      yetAreaIds,
      apiCallFailureActions,
      startDateValue
    } = this.props
    if (!endDateValue) {
      return apiCallFailureActions({
        status: 'error',
        message: '请选择终止日期'
      })
    }
    KBPopover.show(
      <OrgSubscriptionAreasForm
        type="private"
        charge_type="by_desk"
        loc_id={loc_id}
        yetAreaIds={yetAreaIds}
        callBack={this.certainArea}
        startDateValue={startDateValue}
        contract_id={contract_id}
        endDateValue={endDateValue}
        isReplace={true}
      />
    )
  },

  certainArea(area) {
    const {
      desk: {
        area_id,
        area_name,
        area_size,
        desk_ids,
        units,
        square,
        propertyFee,
        tenement,
        totalRent,
        price,
        rent,
        office_price
      },
      disposeOfficeAreaName
    } = this.props
    let tenementNew = 0
    this.setState({
      selectArea: area
    })
    tenementNew =
      (square.value || 0) * (propertyFee.value || 0) * area.free_desks_count
    units.onChange(area.free_desks_count)
    if (price.value) {
      totalRent.onChange(parseFloat(price.value * area.free_desks_count))
      rent.onChange(
        parseFloat(price.value * area.free_desks_count) -
          parseFloat(tenementNew)
      )
      office_price.onChange(parseFloat(price.value * area.free_desks_count))
    } else {
      totalRent.onChange(tenementNew)
      rent.onChange(0)
      office_price.onChange(area.listing_price || 0)
    }
    tenement.onChange(parseFloat(tenementNew).toFixed(2))
    area_id.onChange(area.id)
    area_name.onChange(area.name)
    area_size.onChange(area.free_desks_count)
    desk_ids.onChange('')
    disposeOfficeAreaName && disposeOfficeAreaName()
  },

  priceChange(e) {
    let value = e.target.value
    const {
      desk: { units, rent, totalRent, tenement, office_price }
    } = this.props
    const { disabledPrice, disabledPriceTotal } = this.state
    let rentNew = 0
    let totalRentNew = 0
    let officePriceNew = 0
    if (!disabledPrice) {
      if (value) {
        this.setState({ disabledPriceTotal: true }, () => {
          if (units.value) {
            rentNew =
              parseFloat(value * units.value)
            totalRentNew = value * units.value + parseFloat(tenement.value)
            officePriceNew = value * units.value
            rent.onChange(parseFloat(rentNew).toFixed(2))
            totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
            office_price.onChange(parseFloat(officePriceNew).toFixed(2))
          }
        })
      } else {
        this.setState(
          { disabledPrice: false, disabledPriceTotal: false },
          () => {

            totalRentNew = parseFloat(tenement.value)
            office_price.onChange('')
            totalRent.onChange('')
            rent.onChange('')
          }
        )
      }
    } else {
      return
    }

    // rent.onChange(parseFloat(rentNew).toFixed(2))
    // totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
    // office_price.onChange(parseFloat(officePriceNew).toFixed(2))
  },

  unitsChange(e) {
    let value = e.target.value
    const {
      desk: {
        price,
        propertyFee,
        square,
        totalRent,
        tenement,
        rent,
        office_price
      },
      disposeOfficeAreaName,
      apiCallFailureActions
    } = this.props
    const { disabledPrice, disabledPriceTotal } = this.state
    let tenementNew = 0
    let rentNew = 0
    let totalRentNew = 0
    let officePriceNew = 0
    if(!validator.isInt(value)){
      return apiCallFailureActions({
        status: 'error',
        message: '请输入整数'
      })
    }
    if (disabledPriceTotal) {
      if (value && price.value) {
        rentNew = parseFloat(value * price.value) - parseFloat(tenementNew)
        totalRentNew = value * price.value
        officePriceNew = value * price.value
        totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
        rent.onChange(parseFloat(rentNew).toFixed(2))
        office_price.onChange(parseFloat(officePriceNew).toFixed(2))
      }
      if (!value) {
        office_price.onChange('')
        totalRent.onChange('')
      }
    } else if (disabledPrice) {
      if (value && office_price.value) {
        rentNew = parseFloat(office_price.value) - parseFloat(tenementNew)
        totalRentNew = office_price.value
        const newPrice = office_price.value / value
        price.onChange(parseFloat(newPrice).toFixed(2))
        totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
        rent.onChange(parseFloat(rentNew).toFixed(2))
      } else if (value && totalRent.value) {
        rentNew = parseFloat(totalRent.value) - parseFloat(tenementNew)
        const newOfficePrice =
          parseFloat(totalRent.value) - parseFloat(tenementNew)
        const newPrice = newOfficePrice.value / value
        price.onChange(parseFloat(newPrice).toFixed(2))
        office_price.onChange(parseFloat(newOfficePrice).toFixed(2))
        rent.onChange(parseFloat(rentNew).toFixed(2))
      }
      if (!value) {
        price.onChange('')
      }
    }
    if (value && square.value && propertyFee.value) {
      tenementNew = square.value * propertyFee.value * value
    }

    tenement.onChange(parseFloat(tenementNew).toFixed(2))
    // totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
    // rent.onChange(parseFloat(rentNew).toFixed(2))
    // office_price.onChange(parseFloat(officePriceNew).toFixed(2))
    disposeOfficeAreaName && disposeOfficeAreaName()
  },

  officePriceChange(e) {
    let value = e.target.value
    const {
      desk: { price, totalRent, tenement, rent, units, office_price }
    } = this.props
    const { disabledPrice, disabledPriceTotal } = this.state
    let rentNew = 0
    let totalRentNew = 0
    let priceNew = 0
    if (!disabledPriceTotal) {
      if (value) {
        this.setState({ disabledPrice: true }, () => {
          priceNew = value / units.value
          rentNew = priceNew * units.value
          totalRentNew = parseFloat(rentNew) + parseFloat(tenement.value)
          price.onChange(parseFloat(priceNew).toFixed(2))
          rent.onChange(parseFloat(rentNew).toFixed(2))
          totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
        })
      } else {
        this.setState(
          { disabledPrice: false, disabledPriceTotal: false },
          () => {
            totalRentNew = tenement.value
            totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
            price.onChange('')
            rent.onChange(0.00)
          }
        )
      }
    } else {
      return
    }
  },
  officeTotalPriceChange(e) {
    let value = e.target.value
    const {
      desk: { price, totalRent, tenement, rent, units, office_price }
    } = this.props
    const { disabledPrice, disabledPriceTotal } = this.state
    let priceNew = 0
    let rentNew = 0
    if (!disabledPriceTotal) {
      if (value) {
        console.log(Number(value)<Number(tenement.value));
        if(Number(value)<Number(tenement.value)) {
          price.onChange('')
          rent.onChange(0.00)
          return
        }
        this.setState({ disabledPrice: true }, () => {
          const newValue = parseFloat(value) - parseFloat(tenement.value)
          priceNew = newValue / units.value
          rentNew = newValue
          console.log(priceNew,'priceNew');
          price.onChange(parseFloat(priceNew).toFixed(2))
          rent.onChange(parseFloat(rentNew).toFixed(2))
          office_price.onChange(parseFloat(newValue).toFixed(2))
        })
      } else {
        this.setState(
          { disabledPrice: false, disabledPriceTotal: false },
          () => {
            office_price.onChange('')
            price.onChange('')
            rent.onChange(0.00)
          }
        )
      }
    } else {
      return
    }
  },

  perDeskChange(e) {
    const { desk } = this.props
    const { propertyFee, price, units, totalRent } = desk
    let value = e.target.value
    let tenementNew = 0
    let totalRentNew = 0
    if (value) {
      tenementNew = value * propertyFee.value * desk.units.value
      totalRentNew =
        parseFloat(price.value * units.value) + parseFloat(tenementNew)
    } else {
      totalRentNew = parseFloat(price.value * units.value)
    }
    desk.tenement.onChange(parseFloat(tenementNew).toFixed(2))
    totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
  },

  perFeeChange(e) {
    const { desk } = this.props
    const { square, totalRent, price, units } = desk
    let value = e.target.value
    let tenement = 0
    let totalRentNew = 0
    if (value) {
      tenement = value * square.value * desk.units.value
      totalRentNew =
        parseFloat(price.value * units.value) + parseFloat(tenement)
    } else {
      totalRentNew = parseFloat(price.value * units.value)
    }
    desk.tenement.onChange(parseFloat(tenement).toFixed(2))
    totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
  },

  selectDesks(desks) {
    const {
      desk: { desk_ids, desk_nums }
    } = this.props
    let deskIds = desks.map(desk => desk.id).join(',')
    let deskNums = desks.map(desk => desk.serial_number).join(',')
    desk_ids.onChange(deskIds)
    desk_nums.onChange(deskNums)
    this.setState({
      selectDesks: desks
    })
  },

  editSelectDesks() {
    const {
      desk: { area_id, area_name, units, desk_allot_type },
      loc_id,
      organization_id
    } = this.props
    const { selectDesks } = this.state
    if (desk_allot_type.value == 'auto') {
      return
    }
    KBPopover.show(
      <OrgSubscriptionAllotDeskForm
        loc_id={loc_id}
        type="edit"
        selectDesks={selectDesks}
        area_id={area_id.value}
        area_name={area_name.value}
        units={units.value}
        callBack={this.selectDesks}
        organization_id={organization_id}
      />
    )
  },

  allotDeskManualChange() {
    const {
      desk: { desk_allot_type, area_id, area_name, units },
      loc_id,
      organization_id
    } = this.props
    KBPopover.show(
      <OrgSubscriptionAllotDeskForm
        loc_id={loc_id}
        area_id={area_id.value}
        area_name={area_name.value}
        units={units.value}
        callBack={this.selectDesks}
        organization_id={organization_id}
      />
    )
    desk_allot_type.onChange('manual')
  },

  allotDeskAutoChange() {
    const {
      desk: { desk_allot_type, desk_ids, desk_nums }
    } = this.props

    desk_allot_type.onChange('auto')
    desk_ids.onChange('')
    desk_nums.onChange('')
  },

  render() {
    const {
      desk,
      deskList,
      index,
      calculationDeposit,
      locationSetting: { house_fee_per_m2 },
      deleteCallBack
    } = this.props
    const { allotType, disabledPrice, disabledPriceTotal } = this.state
    return (
      <li style={{ padding: '0 30px 30px 0' }}>
        <i
          className="iconfont icon-close"
          onClick={() => {
            deskList.removeField(index)
            deleteCallBack && deleteCallBack()
          }}
          style={{ top: 10, right: 0 }}
        />
        <div className="kb-row kb-form-210">
          <div
            className="select-area-btn"
            onClick={this.selectAreas}
            onMouseEnter={e => {
              this.setState({ showReplaceText: true })
              e.stopPropagation()
            }}
            onMouseLeave={e => {
              this.setState({ showReplaceText: false })
              e.stopPropagation()
            }}
          >
            <span>
              {this.state.showReplaceText
                ? '重新选择'
                : desk.area_id.value
                ? desk.area_name.value
                : '选择分区'}
            </span>
          </div>
          <div
            className="kb-form-48 clear-fix f-left"
            style={{ paddingLeft: 30 }}
          >
            <div className="kb-form-group kb-div-c48">
              <label className="label-rt">
                <span className="must-fillR">*</span>工位数量
              </label>
              <div>
                <input
                  disabled={true}
                  className="kb-input input-num kb-input-disabled"
                  style={{ backgroundColor: '#fff' }}
                  {...desk.units}
                  onChange={event => {
                    calculationDeposit(event, desk.units)
                    this.unitsChange(event)
                  }}
                />
                {desk.units.touched && desk.units.error && (
                  <p className="lr-error"> {desk.units.error} </p>
                )}
              </div>
            </div>

            <div className="kb-form-66 clear-fix f-left">
              <div className="kb-form-group kb-div-c39">
                <KBInput
                  disabled={disabledPrice}
                  isRequiredR={true}
                  title="工位价格"
                  fistFoucs={true}
                  className={`kb-form-group kb-div-c48 `}
                  inputClass={`input-dollar-moth kb-input ${
                    disabledPrice ? 'kb-input-disabled' : ''
                  }`}
                  field={desk.price}
                  onChange={event => {
                    calculationDeposit(event, desk.price)
                    this.priceChange(event)
                  }}
                  hint={disabledPriceTotal&&<p style={{color: '#fd9a18', fontSize:12}}>清空后修改【房间价格】</p>}
                />

              </div>
              <div className="kb-form-group kb-div-c39">
                <label className="label-rt">
                  <span className="must-fillR">*</span>房间价格
                </label>
                <div>
                  <input
                    disabled={disabledPriceTotal}
                    className={`input-dollar-moth kb-input ${
                      disabledPriceTotal ? 'kb-input-disabled' : ''
                    }`}
                    {...desk.office_price}
                    onChange={event => {
                      calculationDeposit(event, desk.office_price)
                      this.officePriceChange(event)
                    }}
                  />
                  {desk.office_price.touched && desk.office_price.error && (
                    <p className="lr-error"> {desk.office_price.error} </p>
                  )}
                  {parseFloat(desk.office_price.value) <
                    parseFloat(desk.lowest_price.value) && (
                    <p className="lr-warning">低于底价，需要审批</p>
                  )}
                  {disabledPrice&&<p className="lr-warning">清空后修改【工位价格】</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
        {desk.area_id.touched && desk.area_id.error && (
          <p style={{ marginBottom: 20, color: '#e03641' }}>请选择分区</p>
        )}
        <div
          className="rent-box border-none"
          style={{ backgroundColor: '#fbfcfc' }}
        >
          <div className="total-rent">
            <div>
              <span style={{ marginRight: 38 }}>总会员费:</span>
              <input
                disabled={disabledPriceTotal}
                className={`input-dollar-moth totalRent-input ${
                  disabledPriceTotal ? 'kb-input-disabled' : ''
                }`}
                {...desk.totalRent}
                style={{ color: '#d72431', textIndent: '11px', height: 36 }}
                onChange={event => {
                  calculationDeposit(event, desk.totalRent)
                  this.officeTotalPriceChange(event)
                }}
              />
              {disabledPrice&&<p className="lr-warning">清空后修改【工位价格】</p>}
              {parseFloat(desk.totalRent.value) <
                    parseFloat(desk.tenement.value) && (
                    <p className="lr-warning">总会员费需大于物业费</p>
                  )}
            </div>
          </div>
          {!house_fee_per_m2 || house_fee_per_m2 == 0 ? (
            ''
          ) : (
            <div style={{ paddingLeft: 15 }}>
              <div className="color-nine" style={{ marginTop: 15 }}>
                以月为单位填写物业费与会员费
              </div>
              <div className="rent-list">
                <span style={{ width: 50, marginRight: 35 }}>会员费:</span>
                <span>¥ {parseFloat(desk.rent.value).toFixed(2)}</span>
              </div>
              <div className="rent-list">
                <span style={{ width: 50, marginRight: 35 }}>物业费:</span>
                <span style={{ minWidth: 90 }}>
                  ¥ {parseFloat(desk.tenement.value).toFixed(2)}
                </span>
                <div style={{ position: 'relative' }}>
                  <input
                    {...desk.square}
                    onChange={event => {
                      calculationDeposit(event, desk.square)
                      this.perDeskChange(event)
                    }}
                    className="per-desk-input"
                  />
                  <span className="per-desk-unit">平米/工位</span>
                </div>
                <span
                  style={{ marginLeft: 20, marginRight: 20, color: '#999' }}
                >
                  X
                </span>
                <div style={{ position: 'relative' }}>
                  <input
                    {...desk.propertyFee}
                    onChange={event => {
                      calculationDeposit(event, desk.propertyFee)
                      this.perFeeChange(event)
                    }}
                    className="per-free-input"
                  />
                  <span className="per-free-unit">物业费/平米</span>
                </div>
                <span
                  style={{ marginLeft: 20, marginRight: 20, color: '#999' }}
                >
                  X
                </span>
                <span>{desk.units.value || 0}</span>
              </div>
            </div>
          )}
          {/*
            desk.units.value && desk.units.value != '0' ? <div className="allot-desk">
              <div style={ {marginRight: 22, color: '#323232'} }>选择工位:</div>
              <div>
                <div className="allot-type">
                  <input type="radio"  value='auto'  {...desk.desk_allot_type}  checked={ desk.desk_allot_type.value == 'auto' ? 'checked' : '' } id={`auto-radio${index}`} name={ `radio-${index}` } onChange={ this.allotDeskAutoChange }/>
                  <label htmlFor={`auto-radio${index}`} style={ {marginLeft: 10} }>自动分配工位</label>
                </div>
                <div className="allot-type">
                  <div>
                    <input type="radio" value='manual'  {...desk.desk_allot_type} checked={ desk.desk_allot_type.value == 'manual' ? 'checked' : '' }  id={`manual-radio${index}`} name={ `radio-${index}` } onChange={ this.allotDeskManualChange }/>
                    <label htmlFor={`manual-radio${index}`} style={ {marginLeft: 10} }>手动分配工位</label>
                  </div>
                  {
                    desk.desk_ids.value ? <div className="desk-num">
                      <span className="m-right-sm" style={ {whiteSpace: 'nowrap'} }>已分配工位号:</span>
                      <div className="m-right-sm" style={ {maxWidth: '580px'} }>
                        {
                          desk.desk_nums.value
                        }
                      </div>
                    </div> : ''
                  }
                  <div className={ `contract-edit-desk-btn ${desk.desk_allot_type.value == 'manual' ? '' : 'contract-edit-desk-btn_disabled'}`} onClick={ () => { this.editSelectDesks() } }>修改</div>
                </div>
              </div>
            </div> : ''
          */}
        </div>
      </li>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionDeskListPrivateOfDesk)
