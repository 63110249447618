import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as constants from 'app/constants'
import * as selectors from 'app/selectors'
import { canAccessSpaceShop } from 'app/reducers/role'
import { KBPopover } from 'components'
import ShopsForm from './ShopsForm'
import { api, apiUtils } from 'app/services'
import { getEntitiesJsonArray } from 'utils/kbUtil'

var shoppingBlock = React.createClass({
  getInitialState() {
    return {
      shops: []
    }
  },
  updateShops(shop) {
    KBPopover.show(
      <ShopsForm initialValues={shop} shop={shop} state="update" />
    )
  },

  componentDidMount() {},

  render() {
    const { shopping, user, shops, virtual_currency_name } = this.props
    let shopping_clone = {}
    shops &&
      shops.map(json => {
        if (shopping.id == json.id) {
          shopping_clone = json
        }
      })
    const {
      name,
      shop_type,
      payment_type,
      product_count,
      total_amount,
      total_points,
      location
    } = shopping_clone
    return (
      <Link
        className="shopping-block"
        to={`/admin/shopping/${shopping_clone.id}`}
      >
        {canAccessSpaceShop(user) ? (
          <i
            className="iconfont icon-edit"
            onClick={e => {
              e.preventDefault()
              this.updateShops(shopping_clone)
            }}
          />
        ) : null}

        <div className="shopping-title">
          <span className="shopping-name">{name}</span>
          <span className={`shopping-type shopping-type_${shop_type}`}>
            {constants.SHOP_OBJ_TYPES[shop_type]}
          </span>
        </div>
        <div style={{ color: '#999', marginBottom: '25px' }}>
          {shop_type == 'online'
            ? '仅供线上使用'
            : location && location.name
            ? location.name
            : '暂无分店信息'}
        </div>

        <div className="payment-type">
          <span>支付方式:</span>
          {payment_type == 'both' ? (
            <div className="type">
              <img
                src="https://media-ssl.kuban.io/static/web/v1/images/phase-state_icon2.png"
                alt="icon"
              />
              <span>现金</span>
              <img
                src="https://media-ssl.kuban.io/static/web/v1/images/phase-state_icon2.png"
                alt="icon"
              />
              <span>{virtual_currency_name}</span>
            </div>
          ) : payment_type == 'money' ? (
            <div className="type">
              <img
                src="https://media-ssl.kuban.io/static/web/v1/images/phase-state_icon2.png"
                alt="icon"
              />
              <span>现金</span>
            </div>
          ) : (
            <div className="type">
              <img
                src="https://media-ssl.kuban.io/static/web/v1/images/phase-state_icon2.png"
                alt="icon"
              />
              <span>{virtual_currency_name}</span>
            </div>
          )}
        </div>

        <div className="shop-statistics">
          <div>
            <span>{product_count}</span>
            <span>商品数</span>
          </div>
          <div>
            <span>{total_amount}</span>
            <span>销售额</span>
          </div>
          <div>
            <span>{total_points}</span>
            <span>{virtual_currency_name}销售额</span>
          </div>
        </div>
      </Link>
    )
  }
})

function mapStateToProps(state, props) {
  let user = selectors.getCurrentUser(state)
  const shops = selectors.getShops(state)
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)

  return {
    user,
    shops,
    virtual_currency_name
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(shoppingBlock)
