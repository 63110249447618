import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import { membershipsAction, apiCallFailure, successState } from 'app/actions'
import {
  KBLoadingContainer,
  KBDropdown,
  KBPagination,
  KBPopover
} from 'components'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES } from 'app/constants'
import { util, momentFromNow } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import { paginate } from 'utils/kbUtil'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import MembershipsCardContent from './MembershipsCardContent'
import moment from 'moment'

var MembershipCards = React.createClass({
  displayName: 'MembershipCards',
  getInitialState() {
    return {}
  },

  componentDidMount() {
    this.loadData()
  },

  loadData() {
    const { membershipsAction } = this.props

    api
      .getMemberships({
        type: 'all',
        per_page: 30,
        include_histories: true
      })
      .then(
        res => {
          membershipsAction.success(res)
        },
        err => {
          membershipsAction.failure(err)
        }
      )
  },

  toSettingMemberCard() {
    this.props.routerActions.push(
      `/admin/memberships/memberManage/cards/setting`
    )
  },

  updateMembershipPosition(membership, type) {
    const { updateMembershipAction, formalMems } = this.props
    let ids = []
    formalMems &&
      formalMems.map(m => {
        ids.push(m.id)
      })
    let id = membership.id
    // 以下部分用来根据用户操作移动 ids
    let index = ids.findIndex(i => {
      return i == id
    })
    // 1 删除要移动位置的 id
    ids.splice(index, 1)
    // 2 获取要添加的位置
    let indexAdd = type == 'up' ? index - 1 : index + 1
    // 3 在新的位置添加上 id
    ids.splice(indexAdd, 0, id)
    api.putSortMemberships({ ids }).then(
      json => {
        let sortedMems = json.response.body
        let memberships = {}
        sortedMems.map(m => {
          memberships[m.id] = m
        })
        let data = { json: { entities: { memberships } } }
        updateMembershipAction.success(data, { key: 'memberships' })
      },
      e => {
        updateMembershipAction.failure(e)
      }
    )
  },

  deleteMembership(id) {
    const { deleteMembershipsAction, successAction } = this.props
    KBPopover.plugins.confirm('删除会员卡', '您是否删除该会员卡?', {
      confirm: () => {
        api.deleteMemberships(id).then(
          json => {
            deleteMembershipsAction.success(json, { id, key: 'memberships' })
            successAction({ message: '删除会员等级成功!' })
            KBPopover.close()
          },
          e => {
            deleteMembershipsAction.failure(e)
          }
        )
      }
    })
  },

  updateMembershipVisbility(id, visbility) {
    const { updateMembershipAction, successAction } = this.props
    // KBPopover.plugins.confirm('删除会员卡', '您是否删除该会员卡?', { confirm : () => {
    api.updateVisbilityMemberships(id, { is_show: visbility }).then(
      json => {
        updateMembershipAction.success(json, { id, key: 'memberships' })
        successAction({ message: '修改会员卡成功!' })
      },
      e => {
        updateMembershipAction.failure(e)
      }
    )
    // }})
  },

  activateMembership(id) {
    const { updateMembershipAction, successAction } = this.props
    KBPopover.plugins.confirm(
      '激活会员卡',
      '您是否激活该会员卡，激活后该卡将对用户可见?',
      {
        confirm: () => {
          api.updateBecomeCurrentMemberships(id, { is_show: true }).then(
            json => {
              updateMembershipAction.success(json, { id, key: 'memberships' })
              successAction({ message: '激活会员卡成功!' })
              KBPopover.close()
            },
            e => {
              updateMembershipAction.failure(e)
            }
          )
        }
      }
    )
  },

  getAvailableCredits(credits) {
    let creditTotal = 0
    credits.length &&
      credits.map(credit => (creditTotal += credit.available_credits))
    return creditTotal
  },

  render() {
    const { memberships, bottomLength } = this.props
    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>会员卡</span>
          </div>
          <button
            className="bordered-btn f-right"
            onClick={this.toSettingMemberCard}
          >
            <i className="iconfont icon-add" />
            新增会员卡
          </button>
        </header>
        <div className="nav-section-content-container">
          <div className="m-top">
            {memberships.map((membership, index) => {
              let deskCountTotal = this.getAvailableCredits(membership.desks)
              let bookingCountTotal = this.getAvailableCredits(
                membership.bookings
              )
              let resourceCountTotal = this.getAvailableCredits(
                membership.resources
              )
              let hideMoveTop = index == 0
              let hideMoveBottom = index == bottomLength - 1
              return (
                <MembershipsCardContent
                  membership={membership}
                  deskCountTotal={deskCountTotal}
                  bookingCountTotal={bookingCountTotal}
                  resourceCountTotal={resourceCountTotal}
                  deleteMembership={this.deleteMembership}
                  updateMembershipVisbility={this.updateMembershipVisbility}
                  activateMembership={this.activateMembership}
                  updateMembershipPosition={this.updateMembershipPosition}
                  hideMoveTop={hideMoveTop}
                  hideMoveBottom={hideMoveBottom}
                />
              )
            })}
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const memberships = selectors.getMemberships(state)
  let formalMems =
    (memberships &&
      memberships.filter(m => {
        return m.status == 'formal'
      })) ||
    []
  let draftMems =
    (memberships &&
      memberships.filter(m => {
        return m.status == 'draft'
      })) ||
    []
  formalMems &&
    formalMems.length &&
    formalMems.sort((a, b) => {
      return a.position - b.position
    })
  let newMemberships = formalMems.concat(draftMems)
  let bottomLength = formalMems.length

  return {
    memberships: newMemberships,
    formalMems,
    bottomLength
  }
}

function mapDispatchToProps(dispatch) {
  return {
    membershipsAction: bindActionCreators(membershipsAction.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    deleteMembershipAction: bindActionCreators(
      membershipsAction.delete,
      dispatch
    ),
    updateMembershipAction: bindActionCreators(
      membershipsAction.update,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipCards)
