import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBPopover,
  KBTipsy
} from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { paginate } from 'utils/kbUtil'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import ServiceProviderForm from './ServiceProviderForm'
import ServiceList from './ServiceList'
import _ from 'lodash'

var ServiceIndex = React.createClass({
  displayName: 'FaqDownload',

  getInitialState() {
    return {
      secondCategoryStatus: false,
      subCategoriesFilters: [],
      selectCategory: {},
      selectSecondCategory: {},
      filters: {},
      serviceProviders: [],
      allCategory: '',
      stairState: true,
      loading: false
    }
  },

  componentDidMount() {
    this.mounted = true
    this.loadData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    const { serviceProviders } = nextProps
    var oldServiceProviders = this.state.serviceProviders
    let isChange = false

    if (!oldServiceProviders || !oldServiceProviders.length) {
      return
    }

    if (serviceProviders.length != this.props.serviceProviders.length) {
      this.loadData(this.state.filters)
      return
    }

    oldServiceProviders = oldServiceProviders.map(service => {
      let newService = serviceProviders.find(json => json.id == service.id)
      if (!_.isEqual(service, newService)) {
        isChange = true
        return newService
      }

      return service
    })

    if (isChange) {
      this.setState({
        serviceProviders: oldServiceProviders
      })
    }
  },

  loadData(filters) {
    const { getServiceProvidersAction } = this.props

    let params = Object.assign({}, filters)

    if (params.service_category_id == 'true') {
      params.is_prioritized = true
      delete params.service_category_id
    }
    this.setState({ loading: true })

    api
      .getServiceProviders({ per_page: 100, ...params })
      .then(
        json => {
          getServiceProvidersAction.success(json)
          let serviceProviders = []
          serviceProviders = getEntitiesJsonArray(json, 'serviceProviders')
          if (this.mounted) {
            this.setState({
              serviceProviders
            })
          }
        },
        error => {
          getServiceProvidersAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  toProvider(service) {
    const { routerActions } = this.props
    routerActions.push(`/admin/service/provider/${service.id}/info`)
  },

  createServiceProvider() {
    KBPopover.show(<ServiceProviderForm />, {
      closeOnOutsideClick: false
    })
  },

  secondaryClassification(category, key, value) {
    const {
      serviceCategoriesArray,
      subCategoriesArray,
      subCategories
    } = this.props
    const { subCategoriesFilters } = this.state
    var filters = this.state.filters
    const secondId = serviceCategoriesArray[category.id].sub_categories
    var secondCategoryArray = []
    secondId &&
      secondId.map((idArray, index) => {
        const subCategoriesFilter = subCategories.filter(
          category => category.id == idArray
        )
        let subCategoriesObject = {}
        subCategoriesObject['id'] = subCategoriesFilter[0].id
        subCategoriesObject['name'] = subCategoriesFilter[0].name
        secondCategoryArray.push(subCategoriesObject)
      })
    this.setState({
      secondCategoryStatus: true,
      is_enabled: false,
      subCategoriesFilters: secondCategoryArray,
      stairState: false,
      selectCategory: category,
      selectSecondCategory: {}
    })

    if (filters && filters.is_enabled) {
      delete filters.is_enabled
    }

    filters[key] = value
    this.setFilters(filters)
  },

  selectSecondCategory(category) {
    this.setState({
      selectSecondCategory: category
    })
  },

  setFilters(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters
    })
    this.loadData(newFilters)
  },

  clearFilters() {
    this.setState({
      filters: {},
      selectSecondCategory: {},
      selectCategory: {},
      is_enabled: false,
      secondCategoryStatus: false,
      stairState: true,
      allCategory: ''
    })
    this.loadData()
  },

  stickSelect(key, value) {
    this.setState({
      is_enabled: false,
      secondCategoryStatus: false,
      selectCategory: {}
    })
    this.setFilters({ [key]: value })
  },

  allCategory(str) {
    this.setState({
      allCategory: str
    })
  },

  filterRender() {
    const { serviceCategories } = this.props
    const {
      selectCategory,
      secondCategoryStatus,
      filters: { is_enabled },
      filters,
      stairState
    } = this.state
    return (
      <div className={`select-tab m-top`} style={{ marginBottom: 10 }}>
        <span
          className={stairState ? 'active' : ''}
          onClick={() => {
            this.clearFilters()
          }}
        >
          全部
        </span>
        {serviceCategories &&
          serviceCategories.map((category, index) => {
            return (
              <span
                key={index}
                className={category.id == selectCategory.id ? 'active' : ''}
                onClick={() => {
                  this.secondaryClassification(
                    category,
                    'service_category_id',
                    category.id
                  )
                }}
              >
                {category.name}
              </span>
            )
          })}
      </div>
    )
  },

  render() {
    const {
      secondCategoryStatus,
      subCategoriesFilters,
      selectSecondCategory,
      serviceProviders,
      filters,
      allCategory,
      loading
    } = this.state
    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>服务商</span>
            </div>
            <div className="f-right">
              <button
                className="bordered-btn"
                onClick={this.createServiceProvider}
              >
                <i className="iconfont icon-add" />
                添加服务商
              </button>
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          {this.filterRender()}
          {secondCategoryStatus ? (
            <div className="second-category m-top-sm">
              {subCategoriesFilters &&
                subCategoriesFilters.map((category, index) => {
                  return (
                    <span
                      key={index}
                      className={
                        selectSecondCategory.id == category.id
                          ? 'active'
                          : 'a-hover'
                      }
                      onClick={() => {
                        this.selectSecondCategory(category)
                        this.setFilters({ service_category_id: category.id })
                      }}
                    >
                      {category.name}
                    </span>
                  )
                })}
            </div>
          ) : (
            <div className="second-category m-top-sm">
              <span
                className={
                  Object.keys(filters).length <= 0 ? 'active' : 'a-hover'
                }
                onClick={() => {
                  this.clearFilters()
                }}
              >
                全部服务
              </span>
              <span
                className={allCategory == 'enabled' ? 'active' : 'a-hover'}
                onClick={() => {
                  this.stickSelect('is_enabled', 'true')
                  this.allCategory('enabled')
                }}
              >
                启用的服务
              </span>
              <span
                className={allCategory == 'disEnabled' ? 'active' : 'a-hover'}
                onClick={() => {
                  this.stickSelect('is_enabled', 'false')
                  this.allCategory('disEnabled')
                }}
              >
                未启用的服务
              </span>
            </div>
          )}
          <KBLoadingContainer loading={loading}>
            <ServiceList
              is_enabled={true}
              serviceProviders={serviceProviders}
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const serviceProviders = selectors.getServiceProviders(state)
  // 一级分类
  const serviceCategories = selectors.getServiceCategories(state)
  const serviceCategoriesArray = state.entities.serviceCategories
  // 二级分类
  const subCategories = selectors.getSubCategoriesOfId(state)
  const subCategoriesArray = state.entities.subCategories
  return {
    serviceProviders,
    serviceCategories,
    subCategories,
    serviceCategoriesArray,
    subCategoriesArray
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getServiceProvidersAction: bindActionCreators(
      actions.serviceProvidersAction.get,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    successAction: bindActionCreators(actions.successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceIndex)
