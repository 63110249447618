import React, { Component, PropTypes } from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import { serviceRequestsAction, successState } from 'app/actions'
import {
  KBLoadingContainer,
  KBDropdown,
  KBAvatar,
  KBPagination,
  KBPopover
} from 'components'
import { connect } from 'react-redux'
import {
  OPPORTUNITY_STATES,
  SERVICE_REQUEST_STATE_OBJ,
  SERVICE_REQUEST_STATE_CLASS
} from 'app/constants'
import * as selectors from 'app/selectors'
import moment from 'moment'
import {
  paginate,
  getEntitiesJsonArray,
  util,
  formatYearDayEN
} from 'utils/kbUtil'
import * as constant from 'app/constants'
import { KBDayPicker } from 'components/views'

var ServiceRequestDetail = React.createClass({
  displayName: 'ServiceRequestDetail',
  getInitialState() {
    return {
      user_comment_body: '',
      service_comment_body: '',
      admin_comment_body: '',
      user_comment_status: true,
      service_comment_status: true,
      admin_comment_status: true
    }
  },
  componentDidMount() {
    const { request_id, getServiceRequestsAction } = this.props
    let user_comment = []
    let service_comment = []
    request_id &&
      api.getServiceRequestDetail(request_id).then(
        json => {
          getServiceRequestsAction.success(json)
        },
        error => {
          getServiceRequestsAction.failure(error)
        }
      )
  },
  componentWillReceiveProps(nextProps) {
    const { request } = nextProps
    if (request && request.requester_comments) {
      let requester_comments = request.requester_comments[0]
      let provider_comments = request.provider_comments[0]
      let admin_comments = request.admin_comments[0]
      this.setState({
        user_comment_body:
          (requester_comments && requester_comments.comment_body) || '',
        service_comment_body:
          (provider_comments && provider_comments.comment_body) || '',
        admin_comment_body:
          (admin_comments && admin_comments.comment_body) || ''
      })
    }
  },
  commentUserEvaluate() {
    const { user_comment_body } = this.state
    const { request, updateServiceRequestsAction, successAction } = this.props
    const body = user_comment_body

    if (!util.clearSpacing(body)) {
      return
    }

    api
      .createServiceRequestComments(request.id, {
        comment_body: body,
        star: 5,
        is_requester: true,
        is_admin: false
      })
      .then(
        json => {
          successAction({ message: '添加用户评论成功!' })
          updateServiceRequestsAction.success(json)
          this.setState({
            user_comment_status: true
          })
        },
        error => {
          updateServiceRequestsAction.failure(error)
        }
      )
  },
  commentServiceEvaluate() {
    const { service_comment_body } = this.state
    const { request, updateServiceRequestsAction, successAction } = this.props
    const body = service_comment_body

    if (!util.clearSpacing(body)) {
      return
    }

    api
      .createServiceRequestComments(request.id, {
        comment_body: body,
        star: 5,
        is_requester: false,
        is_admin: false
      })
      .then(
        json => {
          successAction({ message: '添加服务商评论成功!' })
          updateServiceRequestsAction.success(json)
          this.setState({
            service_comment_status: true
          })
        },
        error => {
          updateServiceRequestsAction.failure(error)
        }
      )
  },

  commentAdminEvaluate() {
    const { admin_comment_body } = this.state
    const { request, updateServiceRequestsAction, successAction } = this.props
    const body = admin_comment_body

    if (!util.clearSpacing(body)) {
      return
    }

    api
      .createServiceRequestComments(request.id, {
        comment_body: body,
        star: 5,
        is_requester: false,
        is_admin: true
      })
      .then(
        json => {
          successAction({ message: '添加备注成功!' })
          updateServiceRequestsAction.success(json)
          this.setState({
            admin_comment_status: true
          })
        },
        error => {
          updateServiceRequestsAction.failure(error)
        }
      )
  },

  changeStatus(status) {
    const { request, updateServiceRequestsAction, successAction } = this.props
    if (!request) {
      return
    }
    this.refs['indentDropDown'].hide()
    api
      .putChangePrioritizeStatus(request.id, {
        status
      })
      .then(
        json => {
          successAction({ message: '改变订单状态成功， 请填写评论!' })
          updateServiceRequestsAction.success(json)
        },
        error => {
          updateServiceRequestsAction.failure(error)
        }
      )
  },

  updateUserComment(e) {
    let value = e.target.value
    this.setState({
      user_comment_body: value
    })
  },

  changeDayPicker(date) {
    const {
      request_id,
      updateServiceRequestsAction,
      successAction
    } = this.props

    api.getModifyRemindAt(request_id, { date }).then(
      json => {
        updateServiceRequestsAction.success(json)
        successAction({ message: '订单跟进日期修改成功!' })
      },
      error => {
        updateServiceRequestsAction.failure(error)
      }
    )
  },

  updateServiceComment(e) {
    let value = e.target.value
    this.setState({
      service_comment_body: value
    })
  },

  updateAdminComment(e) {
    let value = e.target.value
    this.setState({
      admin_comment_body: value
    })
  },

  changeUserCommentStatus() {
    this.setState({
      user_comment_status: false
    })
  },

  changeUserCommentStatusHidden() {
    setTimeout(() => {
      this.setState({
        user_comment_status: true
      })
    }, 300)
  },

  changeServiceCommentStatus() {
    this.setState({
      service_comment_status: false
    })
  },

  changeServiceCommentStatusHidden() {
    setTimeout(() => {
      this.setState({
        service_comment_status: true
      })
    }, 300)
  },

  changeAdminCommentStatus() {
    this.setState({
      admin_comment_status: false
    })
  },

  changeAdminCommentStatusHidden() {
    setTimeout(() => {
      this.setState({
        admin_comment_status: true
      })
    }, 300)
  },

  render() {
    const { request, serviceProvider } = this.props
    const {
      user_comment_body,
      service_comment_body,
      admin_comment_body,
      user_comment_status,
      service_comment_status,
      admin_comment_status
    } = this.state

    if (!request || !serviceProvider) {
      return <KBLoadingContainer loading={!request || !serviceProvider} />
    }

    const completed =
      request.status == 'completed' ? { backgroundColor: '#2ea1f8' } : {}
    const canceled =
      request.status == 'canceled' ? { backgroundColor: '#2ea1f8' } : {}

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div
              className="nav-section-header-title f-left"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ marginRight: 15 }}>
                {request.service_name}－{request.organization_name}
              </span>
              <span
                className={`default-status ${
                  SERVICE_REQUEST_STATE_CLASS[request.status]
                }`}
              >
                {SERVICE_REQUEST_STATE_OBJ[request.status]}
              </span>
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          <div className="clear-fix" style={{ margin: '20px 0' }}>
            {request.remind_at ? (
              <div className="f-left" style={{ marginTop: 8 }}>
                <span style={{ marginRight: 10 }}>下一次跟进日期拟定为</span>
                <KBDropdown ref="timeDropdown">
                  <KBDropdown.KBDropdownTrigger>
                    <div className="task-status-title">
                      <i
                        className="iconfont icon-calendar"
                        style={{ marginRight: '3px' }}
                      />
                      <span>
                        {request.remind_at
                          ? `${formatYearDayEN(request.remind_at)}`
                          : '选择日期'}
                      </span>
                      <span className="task-deg" />
                    </div>
                  </KBDropdown.KBDropdownTrigger>
                  <KBDropdown.KBDropdownContent>
                    <KBDayPicker
                      onChange={this.changeDayPicker}
                      selectedDay={moment(request.remind_at).toDate()}
                    />
                  </KBDropdown.KBDropdownContent>
                </KBDropdown>
              </div>
            ) : (
              ''
            )}
            <div className="f-right">
              <KBDropdown ref="indentDropDown">
                <KBDropdown.KBDropdownTrigger>
                  <button className="bordered-btn m-none">更改订单状态</button>
                </KBDropdown.KBDropdownTrigger>
                <KBDropdown.KBDropdownContent>
                  <div className="user-dropdown">
                    <a
                      onClick={this.changeStatus.bind(null, 'request_accepted')}
                    >
                      <span>已接单</span>
                    </a>
                    <a onClick={this.changeStatus.bind(null, 'rejected')}>
                      <span>拒绝接单</span>
                    </a>
                    <a onClick={this.changeStatus.bind(null, 'completed')}>
                      <span>完成</span>
                    </a>
                    <a onClick={this.changeStatus.bind(null, 'canceled')}>
                      <span>取消</span>
                    </a>
                  </div>
                </KBDropdown.KBDropdownContent>
              </KBDropdown>
            </div>
          </div>
          <div className="kb-title">
            <span>基本信息</span>
          </div>
          <div className="kb-section" style={{ margin: '20px 0 0 20px' }}>
            <ul>
              <li>
                <span className="section-title">公司:</span>
                <span className="section-title-content">
                  {request.organization_name}
                </span>
              </li>
              <li>
                <span className="section-title">服务商:</span>
                <span className="section-title-content">
                  <Link
                    to={`/admin/service/provider/${request.service_provider_id}/info`}
                    className="color-link"
                  >
                    {request.service_provider_name}
                  </Link>
                </span>
              </li>
              <li>
                <span className="section-title">服务:</span>
                <span className="section-title-content">
                  {request.service_name}
                </span>
              </li>
              <li>
                <span className="section-title">请求时间:</span>
                <span className="section-title-content">
                  {formatYearDayEN(request.created_at)}
                </span>
              </li>
              <li>
                <span className="section-title">联系方式:</span>
                <span className="section-title-content">
                  {serviceProvider.contact_phone}
                </span>
              </li>
              <li>
                <span className="section-title f-left">申请信息:</span>
                {request.notes ? (
                  <span
                    className="section-title-content"
                    style={{ maxWidth: 700 }}
                  >
                    {request.notes}
                  </span>
                ) : (
                  <span className="section-title-content">暂无</span>
                )}
              </li>
            </ul>
          </div>
          <div className="kb-title m-top-sm">
            <span>用户评论</span>
          </div>
          {user_comment_status ? (
            <div className="service-comment">
              <div className="m-top color-nine">
                {user_comment_body || '点击编辑按钮填写评论'}
              </div>
              <button
                className="certain-btn"
                onClick={this.changeUserCommentStatus}
              >
                编辑
              </button>
            </div>
          ) : (
            <div className="service-comment">
              <textarea
                placeholder="添加用户评论"
                ref="user_comment_text"
                autoFocus="autoFocus"
                value={user_comment_body || ''}
                onBlur={this.changeUserCommentStatusHidden}
                onChange={this.updateUserComment}
              />
              <button
                className="certain-btn"
                onClick={e => {
                  this.commentUserEvaluate()
                  e.stopPropagation()
                }}
              >
                {'确定'}
              </button>
            </div>
          )}
          <div className="kb-title m-top-sm">
            <span>服务商评论</span>
          </div>
          {service_comment_status ? (
            <div className="service-comment">
              <div className="m-top color-nine">
                {service_comment_body || '点击编辑按钮填写评论'}
              </div>
              <button
                className="certain-btn"
                onClick={this.changeServiceCommentStatus}
              >
                编辑
              </button>
            </div>
          ) : (
            <div className="service-comment">
              <textarea
                placeholder="添加服务商评论"
                autoFocus="autoFocus"
                value={service_comment_body || ''}
                onBlur={this.changeServiceCommentStatusHidden}
                onChange={this.updateServiceComment}
              />
              <button
                className="certain-btn"
                onClick={this.commentServiceEvaluate}
              >
                {'确定'}
              </button>
            </div>
          )}
          <div className="kb-title m-top-sm">
            <span>备注</span>
          </div>
          {admin_comment_status ? (
            <div className="service-comment">
              <div className="m-top color-nine">
                {admin_comment_body || '点击编辑按钮填写备注'}
              </div>
              <button
                className="certain-btn"
                onClick={this.changeAdminCommentStatus}
              >
                编辑
              </button>
            </div>
          ) : (
            <div className="service-comment">
              <textarea
                placeholder="填写备注"
                autoFocus="autoFocus"
                value={admin_comment_body || ''}
                onBlur={this.changeAdminCommentStatusHidden}
                onChange={this.updateAdminComment}
              />
              <button
                className="certain-btn"
                onClick={this.commentAdminEvaluate}
              >
                {'确定'}
              </button>
            </div>
          )}
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const request_id = params.request_id
  const request = selectors.getServiceRequestOfRequestId(state, request_id)
  const serviceProvider = selectors.getServiceProvidersOfId(
    state,
    request && request.service_provider_id
  )

  return {
    request,
    serviceProvider,
    request_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    updateServiceRequestsAction: bindActionCreators(
      serviceRequestsAction.update,
      dispatch
    ),
    getServiceRequestsAction: bindActionCreators(
      serviceRequestsAction.get,
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceRequestDetail)
