import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import {
  getDeskOfDeskId,
  getOrgMembers,
  getSpaceVirtualCurrencyName,
  getUserById
} from 'app/selectors'
import { locksActions, usersActions } from 'app/actions'
import { KBLoadingContainer, KBAvatar } from 'components'
import { isListValue, getListFirstValue } from 'utils/kbUtil'
import { getUserInfoUrl } from 'utils/kbUrl'
import {
  onlyHelpdesk,
  onlyHasWeChatAdmin
} from 'app/reducers/role'

var LocationMemberInfo = React.createClass({
  displayName: 'LocationMemberInfo',
  getInitialState() {
    return {
      loading: true,
      lockEvents: [],
      isMember: false,
      member: null
    }
  },
  componentDidMount() {
    const { member_id } = this.props
    this._loadMembersData(member_id)
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.member_id !== this.props.member_id) {
      this._loadMembersData(nextProps.member_id)
    }
  },

  _loadMembersData(member_id) {
    const { loc_id, org_id, getLocksAction, updateUserActions } = this.props

    api
      .getOrgMember(member_id, { includes: 'locations,face_photo' })
      .then(
        json => {
          updateUserActions.success(json)
          this.setState({
            isMember: true,
            member: json.response.body
          })
        },
        error => {
          updateUserActions.failure(error)
          this.setState({
            loading: false,
            isMember: false
          })
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
    // 获取门禁列表
    api.getLocks({ location_id: loc_id, per_page: 2000 }).then(
      json => {
        getLocksAction.success(json)
      },
      errors => {
        getLocksAction.failure(errors)
      }
    )
    // 开门记录
    api
      .getLockEvents({ user_id: member_id, location_id: loc_id })
      .then(({ json }) => {
        let lockEvents = []
        lockEvents = json.lock_events
        this.setState({
          lockEvents
        })
      })
  },
  render() {
    const { loading, lockEvents, isMember, member } = this.state
    const {
      section,
      member_id,
      virtual_currency_name,
      menu,
      user,
      preSection,
      isMemberShipPath
    } = this.props
    const isMemberManage = preSection != 'memberManage'
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { member, lockEvents, virtual_currency_name })
    )

    return (
      <KBLoadingContainer
        loading={loading || !user}
        className={isMemberManage ? `kb-out-box clear-fix` : ''}
      >
        {isMember && member ? (
          <section
            className="kb-content clear-fix"
            style={{ marginTop: 0, paddingTop: !isMemberManage ? 0 : 30 }}
          >
            <nav className="nav-container f-left">
              <div className="nav-container-content">
                <header className="nav-header clear-fix">
                  <div className="member-box">
                    <KBAvatar
                      user={member}
                      size={50}
                      style={{ marginLeft: '57.5px' }}
                    />
                    <span
                      className="member_name"
                      style={{ fontSize: '20px', paddingTop: '10px' }}
                    >
                      {member.name}
                    </span>
                    <span className="member_name">{member.phone_num}</span>
                  </div>
                </header>
                <div className="nav-body">
                  <ul className="nav-container-list">
                    {menu.map((data, index) => {
                      return (
                        <li
                          key={index}
                          className={
                            isListValue(data.value, section)
                              ? 'nav-container-list-hover'
                              : ''
                          }
                        >
                          <Link
                            to={`${getUserInfoUrl(
                              member_id,
                              isMemberShipPath
                            )}/${getListFirstValue(data.value)}`}
                          >
                            {' '}
                            {data.name}{' '}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </nav>
            {childrenWithProps}
          </section>
        ) : (
          <div style={{ padding: 30, textAlign: 'center', fontSize: 20 }}>
            暂无当前员工
          </div>
        )}
      </KBLoadingContainer>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  const { user } = state
  let member_id = props.params.member_id
  let org_id = props.params.organization_id
  let loc_id = props.params.id
  let sections = location.pathname.split('/')
  let section =
    sections[2] === 'memberships'
      ? sections.length > 6
        ? sections[6]
        : ''
      : sections.length > 4
      ? sections[4]
      : ''
  let preSection = sections.length > 3 ? sections[3] : ''
  let isMemberShipPath =
    sections[2] === 'memberships' && sections[3] === 'memberManage'

  let virtual_currency_name = getSpaceVirtualCurrencyName(state)
  // const user = getUserById(state, member_id)

  let menu = [
    {
      name: '会员信息',
      value: ['']
    },
    {
      name: '门禁权限',
      value: 'lock_permissions'
    },
    {
      name: '门禁记录',
      value: 'locks'
    },
    {
      name: '会议室记录',
      value: 'meeting'
    },
    {
      name: '订单列表',
      value: 'member_orders'
    },
    {
      name: `${virtual_currency_name}记录`,
      value: 'point'
    },
    {
      name: 'face++信息',
      value: 'elevator'
    }
  ]

  if(onlyHelpdesk(user)){
    menu = [menu[0]]
  }

  if(onlyHasWeChatAdmin(user)){
    menu = [
      {
        name: '会员信息',
        value: ['']
      },
      {
        name: '门禁记录',
        value: 'locks'
      },
      {
        name: '会议室记录',
        value: 'meeting'
      },
      {
        name: '订单列表',
        value: 'member_orders'
      },
      {
        name: `${virtual_currency_name}记录`,
        value: 'point'
      },
    ]
  }


  return {
    loc_id,
    org_id,
    user,
    preSection,
    member_id,
    section,
    virtual_currency_name,
    isMemberShipPath,
    menu
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getLocksAction: bindActionCreators(locksActions.all, dispatch),
    updateUserActions: bindActionCreators(usersActions.update, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationMemberInfo)
