import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import { checkObject, getEntitiesJsonArray } from 'utils/kbUtil'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBUserInput,
  KBSelect,
  KBTextarea
} from 'components'
import { ADD_SERVICE } from 'app/constants/hint'
import * as selectors from 'app/selectors'

var ServiceForm = React.createClass({
  displayName: 'ServiceForm',
  createServiceForm(model, type) {
    const {
      createServiceAction,
      callback,
      updateServiceAction,
      successAction,
      state,
      serviceProvider,
      service,
      resetForm
    } = this.props

    if (state == 'update') {
      return api
        .updateService(serviceProvider.id, service.id, model)
        .then(json => {
          callback && callback(getEntitiesJsonArray(json, 'services'))
          updateServiceAction.success(json)
          KBPopover.close()
          return true
        })
    }

    return api.createService(serviceProvider.id, model).then(json => {
      createServiceAction.success(json)
      callback && callback(getEntitiesJsonArray(json, 'services'))
      if (type == 'create_another') {
        successAction({ message: '创建成功，继续添加下一个!' })
        resetForm()
        return true
      }

      KBPopover.close()
      return true
    })
  },

  getChildrenCategory() {
    const { subCategories, serviceCategories } = this.props
    const {
      fields: { service_parent_category_id }
    } = this.props

    const tempSubCategories = serviceCategories.find(
      service => service.id == service_parent_category_id.value
    )
    const sub_categories = tempSubCategories && tempSubCategories.sub_categories

    return (
      tempSubCategories &&
      subCategories.filter(subCategory => {
        return sub_categories.find(id => id == subCategory.id)
      })
    )
  },

  render() {
    const {
      fields: {
        name,
        description,
        service_category_id,
        service_parent_category_id,
        list_price,
        member_price,
        conditions
      },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { serviceCategories, subCategories, state } = this.props

    const currentSubCategories = this.getChildrenCategory() || []

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    return (
      <form onSubmit={handleSubmit(this.createServiceForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改服务' : '添加服务'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container-m">
          <div className="kb-row kb-form-66">
            <KBSelect
              isRequired={true}
              placeholder="选择服务分类"
              title="服务分类"
              {...halfClass}
              field={service_parent_category_id}
              multi={false}
              arrayData={serviceCategories}
            />
            <KBSelect
              isRequired={true}
              defaultValue="-1"
              placeholder="选择服务类型"
              titleClass="label-rt"
              title="子分类"
              {...halfClass}
              field={service_category_id}
              multi={false}
              arrayData={currentSubCategories}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="服务名称"
              field={name}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              isRequired={true}
              title="服务描述"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={description}
            />
          </div>
          <div className="kb-row">
            <KBInput
              isRequired={true}
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="价格描述"
              hint={ADD_SERVICE.list_price}
              field={list_price}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="优惠价格"
              hint={ADD_SERVICE.member_price}
              field={member_price}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              isRequired={true}
              title="适用条件"
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={ADD_SERVICE.conditions}
              field={conditions}
            />
          </div>
          <div className="fixed-form-btn">
            {state != 'update' ? (
              <KBButton
                className="certain-btn"
                type="button"
                style={{ marginRight: '20px' }}
                onClick={handleSubmit(values => {
                  this.createServiceForm(values, 'create_another')
                })}
              >
                创建并添加下一个
              </KBButton>
            ) : (
              ''
            )}

            <KBButton
              className="certain-btn"
              type="submit"
              style={{ marginRight: '20px' }}
              submitting={submitting}
            >
              {state != 'update' ? '创建并关闭' : '确认修改'}
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const serviceCategories = selectors.getServiceCategories(state)
  const subCategories = selectors.getSubCategoriesOfId(state)
  const { service } = props

  var params = {
    serviceCategories,
    subCategories
  }

  if (!props.state) {
    params.initialValues = {
      service_parent_category_id:
        serviceCategories[0] && serviceCategories[0].id
    }
  }

  if (props.state) {
    let service_parent_category_id = serviceCategories.find(category =>
      category.sub_categories.find(id => service.service_category_id == id)
    ).id
    params.initialValues = {
      ...service,
      service_parent_category_id
    }
  }

  return params
}

function mapDispatchToProps(dispatch) {
  return {
    createServiceAction: bindActionCreators(
      actions.servicesAction.create,
      dispatch
    ),
    updateServiceAction: bindActionCreators(
      actions.servicesAction.update,
      dispatch
    ),
    successAction: bindActionCreators(actions.successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.description)) {
    errors.description = '请输入服务描述'
  }

  if (valid.required(values.service_parent_category_id)) {
    errors.service_parent_category_id = '请选择服务分类'
  }

  if (valid.required(values.service_category_id)) {
    errors.service_category_id = '请选择子分类'
  }

  if (valid.required(values.list_price)) {
    errors.list_price = '请输入价格'
  }

  if (valid.required(values.conditions)) {
    errors.conditions = '请输入使用条件'
  }

  return errors
}

const formConfig = {
  form: 'ServiceForm',
  fields: [
    'name',
    'description',
    'service_category_id',
    'list_price',
    'member_price',
    'conditions',
    'service_parent_category_id'
  ],
  validate: validate,
  touchOnBlur: false
}

ServiceForm.propTypes = {
  service: PropTypes.object
}

ServiceForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ServiceForm)

export default ServiceForm
