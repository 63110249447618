import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { replacementsAction, successState, apiCallFailure } from 'app/actions'
import {
  KBDropdown,
  KBPagination,
  KBLoadingContainer,
  KBNoData,
  KBRangeDate,
  KBAvatar
} from 'components'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import { ACTIVITY_STATUS_OBJ, ACTIVITY_STATUS_TAG_OBJ } from 'app/constants'
import {
  percent,
  toImageProxyDirect,
  queryFilter,
  getFilterAndQuery,
  formatYMDN4TwoDate,
  formatHmN4TwoDate,
  formatYearDayEN,
  paginate
} from 'utils/kbUtil'
import { connect } from 'react-redux'
import moment from 'moment'
import { canAccessLocationRoles } from 'app/reducers/role'
import { getEditRole } from './constant'

var ReplacementList = React.createClass({
  displayName: 'ReplacementList',
  getInitialState() {
    let { filters } = this.props
    // filters = Object.assign({}, { status: '', time_at: filters.start_date ? '' : 'week' }, filters)
    return {
      filters,
      pagination: null,
      page: this.props.page || 1,
      queryFiltersStr: '',
      loading: true,
      location_id: '',
      list: []
    }
  },

  componentDidMount() {
    this.mounted = true
    this.loadData()
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      this.loadData({}, nextProps.page)
      return
    }
  },

  componentWillUnmount() {
    this.mounted = false
  },

  loadData(model, newPage) {
    const per_page = 10
    const { loc_id, query, routerActions, successAction, apiCallFailureAction } = this.props
    const { filters, page } = this.state
    let params = Object.assign({}, filters, model)
    if (loc_id) {
      params.location_id = loc_id
    }

    let queryFiltersStr = queryFilter({
      ...query,
      ...params,
      page: newPage || page
    })
    delete params.time_at
    this.setState({ loading: true })
    api
      .getReplacements({ ...params, per_page, page: newPage || page })
      .then(
        ({ json, response }) => {
          this.setState({
            list: json
          })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          if (loc_id) {
            routerActions.replace(
              `/admin/locations/${loc_id}/replacement${queryFiltersStr}`
            )
          } else {
            routerActions.replace(
              `/admin/memberships/memberManage/replacement${queryFiltersStr}`
            )
          }
          let pagination = paginate(response)
          this.setState({
            pagination,
            queryFiltersStr
          })
        },
        error => {
          apiCallFailureAction({
            status: 'error',
            message: (error.message)
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  _setFilter(obj) {
    let newFilt = obj || {}
    let newFilters = Object.assign({}, this.state.filters, newFilt)
    this.setState({ filters: newFilters })
    this.refs['location_idDropdown'] && this.refs['location_idDropdown'].hide()
    this.loadData(newFilters)
  },

  _clearFilters() {
    this.setState({ filters: {} })
    setTimeout(this.loadData)
  },

  changeDate(range, status) {
    let { from, to } = range
    let start_date = moment(from)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
    let end_date = moment(to)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
    this._setFilter({
      start_date,
      end_date,
      time_at: status || ''
    })
  },

  selectActivitiesOfTime(status) {
    let from = moment()
      .startOf(status)
      .toDate()
    let to = moment()
      .endOf(status)
      .toDate()
    this.changeDate(
      {
        from,
        to
      },
      status
    )
  },

  selectActivitiesOfStatus(status) {
    this._setFilter({ status: status })
  },

  setTag(name) {
    this._setFilter({ tag: name })
  },

  _renderFilters() {
    const { loc_id, locations } = this.props
    const { start_date, end_date } = this.state.filters
    const { time_at, status, location_id } = this.state.filters
    return (
      <div
        className="m-top"
        style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}
      >
        {!loc_id ? (
          <KBDropdown
            ref="location_idDropdown"
            style={{ marginLeft: 20, marginRight: 10 }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div className="task-status-title">
                <i className="iconfont icon-position" />
                <span>
                  {location_id && Object.keys(locations).length > 0
                    ? locations[location_id].name
                    : '选择分店'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter({ location_id: '' })}>
                    全部
                  </li>
                  {locations &&
                    Object.keys(locations).map((location_id, index) => {
                      let location = locations[location_id]
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ location_id: location_id })
                          }
                        >
                          <span>{location.name}</span>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        ) : (
          ''
        )}
        <KBRangeDate
          format="YYYY/MM/DD"
          title="选择活动开始日期范围"
          from={start_date || ''}
          to={end_date || ''}
          callback={this.changeDate}
        />
        <div className="common-tab" style={{ marginRight: 20, marginLeft: 20 }}>
          <div
            className={`tab-list ${time_at == 'day' ? 'tab-list_active' : ''}`}
            style={{ padding: '6px 13px' }}
            onClick={this.selectActivitiesOfTime.bind(null, 'day')}
          >
            <span className="radio" />
            <span className="title">今天</span>
          </div>
          <div
            className={`tab-list ${time_at == 'week' ? 'tab-list_active' : ''}`}
            style={{ padding: '6px 13px' }}
            onClick={this.selectActivitiesOfTime.bind(null, 'week')}
          >
            <span className="radio" />
            <span className="title">本周</span>
          </div>
          <div
            className={`tab-list ${
              time_at == 'month' ? 'tab-list_active' : ''
            }`}
            style={{ padding: '6px 13px' }}
            onClick={this.selectActivitiesOfTime.bind(null, 'month')}
          >
            <span className="radio" />
            <span className="title">本月</span>
          </div>
        </div>
        <div className="common-tab">
          <div
            className={`tab-list ${status == '' ? 'tab-list_active' : ''}`}
            style={{ padding: '6px 13px' }}
            onClick={this.selectActivitiesOfStatus.bind(null, '')}
          >
            <span className="radio" />
            <span className="title">全部</span>
          </div>
          <div
            className={`tab-list ${
              status == 'active' ? 'tab-list_active' : ''
            }`}
            style={{ padding: '6px 13px' }}
            onClick={this.selectActivitiesOfStatus.bind(null, 'active')}
          >
            <span className="radio" />
            <span className="title">已发布</span>
          </div>

          <div
            className={`tab-list ${status == 'draft' ? 'tab-list_active' : ''}`}
            style={{ padding: '6px 13px' }}
            onClick={this.selectActivitiesOfStatus.bind(null, 'draft')}
          >
            <span className="radio" />
            <span className="title">草稿</span>
          </div>
        </div>
      </div>
    )
  },

  toActivityInfo(act) {
    const { routerActions, loc_id } = this.props
    if (loc_id) {
      routerActions.push(`/admin/locations/${loc_id}/replacement/${act.id}/info`)
    } else {
      routerActions.push(
        `/admin/memberships/memberManage/replacement/${act.id}/info`
      )
    }
  },

  toActivitiesCreate() {
    const { routerActions, loc_id } = this.props
    if (loc_id) {
      routerActions.push(`/admin/locations/${loc_id}/replacement/edit`)
    } else {
      routerActions.push(`/admin/memberships/memberManage/replacement/edit`)
    }
  },

  sortFun(property) {
    return function(a, b) {
      let value1 = moment
        .unix(a[property])
        .toDate()
        .valueOf()
      let value2 = moment
        .unix(b[property])
        .toDate()
        .valueOf()
      return value2 - value1
    }
  },

  sortData(activities) {
    return activities.sort(this.sortFun('start_at'))
  },

  render() {
    const { activities, tags, loc_id, hasEditRole } = this.props
    const { loading, filters, pagination, queryFiltersStr, list } = this.state
    let mActivities = this.sortData(list)

    const contentBox = (
      <div>
        <header className="nav-section-header clear-fix border-bottom">
          <div className="nav-section-header-title f-left">
            <span>加购管理</span>
          </div>
          {
            hasEditRole && (
              <span
                className="bordered-btn f-right"
                onClick={this.toActivitiesCreate}
              >
                创建权益
              </span>
            )
          }
        </header>
        <div style={{ marginBottom: 10 }}>
          {this._renderFilters()}
          {Object.keys(this.state.filters).length > 0 ? (
            <div className="clear-criteria m-left" onClick={this._clearFilters}>
              <i className="iconfont icon-close" />
              <span>清除所有筛选条件</span>
            </div>
          ) : null}
        </div>
        <KBLoadingContainer loading={loading}>
          <div className="nav-section-content-container">
            {list && list.length > 0 ? (
              mActivities.map((act, index) => {
                const { start_at, end_at, min_price } = act
                let activityPrice = min_price

                return (
                  <div key={act.id} className="activity-container">
                    <img
                      src={toImageProxyDirect(act.cover, 200, 200)}
                      alt="img"
                      style={{
                        objectFit: 'cover',
                        width: '200px',
                        height: '200px',
                      }}
                    />
                    <div className="activity-content">
                      <div
                        className="activity-title"
                        onClick={this.toActivityInfo.bind(null, act)}
                      >
                        {act.name}
                      </div>
                      <div className="activity-date-status">
                        <span className="activity-date">
                          {formatYMDN4TwoDate(start_at, end_at)}
                          <span style={{ marginLeft: 10 }}>
                            {formatHmN4TwoDate(start_at, end_at)}
                          </span>
                        </span>
                        <span
                          className={`activity-status activity-status_${
                            act.status === 'active'
                              // ? act.status_tag
                              ? act.status
                              : act.status
                          }`}
                        >
                          {/* {act.status === 'active'
                            ? ACTIVITY_STATUS_TAG_OBJ[act.status_tag]
                            : ACTIVITY_STATUS_OBJ[act.status]} */}
                            {ACTIVITY_STATUS_OBJ[act.status]}
                        </span>
                      </div>
                      <div style={{ marginBottom: 16, color: '#969696' }}>
                        <i
                          className="iconfont icon-position"
                          style={{ marginRight: 10 }}
                        />
                        <span>
                          {
                            act.areas.map(i => i.name).join()
                          }
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#969696'
                        }}
                      >
                        <i
                          className="iconfont icon-account_circle"
                          style={{ marginRight: 10, marginBottom: 2 }}
                        />
                        <span style={{ marginRight: 10 }}>创建人:</span>
                        <KBAvatar
                          style={{
                            display: 'inline-block',
                            marginRight: '5px'
                          }}
                          imgStyle={{ marginRight: 10 }}
                          user={act.creator}
                          size={20}
                        />
                        <span>{act.creator.name}</span>
                      </div>
                    </div>
                        <div className="activity-price-capacity">
                          <div className="activity-price">
                            <span>{act.price} 元</span>
                          </div>
                        <div className="activity-capacity">
                          {act.sold_number}人购买
                        </div>
                      </div>
                  </div>
                )
              })
            ) : (
              <KBNoData tipMessage="暂无权益" />
            )}
            {loc_id ? (
              <KBPagination
                pagination={pagination}
                template={`/admin/locations/${loc_id}/replacement${
                  queryFiltersStr
                    ? queryFiltersStr + '&page=#PAGE#'
                    : '?page=#PAGE#'
                }`}
              />
            ) : (
              <KBPagination
                pagination={pagination}
                template={`/admin/memberships/memberManage/replacement${
                  queryFiltersStr
                    ? queryFiltersStr + '&page=#PAGE#'
                    : '?page=#PAGE#'
                }`}
              />
            )}
          </div>
        </KBLoadingContainer>
      </div>
    )

    return (
      <div className="kb-content" style={loc_id ? {} : { paddingTop: 0 }}>
        <div
          className="kb-content-container clear-fix"
          style={{ minHeight: '100%' }}
        >
          {contentBox}
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location, tags, pageType } = props
  let loc_id = params && params.id
  let locations = selectors.getSerializeLocationObj(state)
  let FILTER_STATE = ['time_at', 'status', 'start_date', 'end_date', 'tag']
  if (!loc_id) {
    FILTER_STATE = [
      'time_at',
      'status',
      'start_date',
      'end_date',
      'tag',
      'location_id'
    ]
  }
  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page
  let params_query = getFilterAndQuery(location.query, FILTER_STATE)

  const hasEditRole = getEditRole(state.user, loc_id)

  return {
    page,
    loc_id,
    locations,
    tags,
    hasEditRole,
    ...params_query
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplacementList)
