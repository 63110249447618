import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as selectors from 'app/selectors'
import { api, apiUtils } from 'app/services'
import { shopsActions, successState } from 'app/actions'
import { KBPopover, KBPagination } from 'components'
import ShoppingBlock from './shoppingBlock'
import OrdersReport from './OrdersReport'
import { routerActions } from 'react-router-redux'
import { paginate, arrayToObject, util, queryFilter } from 'utils/kbUtil'
import moment from 'moment'

var Shops = React.createClass({
  getInitialState() {
    return {
      total_amount: 0,
      total_profit: 0,
      total_quantities: 0
    }
  },

  componentDidMount() {
    const { page } = this.props
    this.mounted = true
    this._loadData(page)
    const currentDate = moment().format('YYYY-MM-DD')
    api
      .getSalesStatistics({ start_date: currentDate, end_date: currentDate })
      .then(({ json }) => {
        const { total_amount, total_profit, total_quantities } = json
        this.setState({
          total_amount,
          total_profit,
          total_quantities
        })
      })
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      this._loadData(nextProps.page)
      return
    }
  },

  _loadData(page) {
    const { getShopsActions } = this.props

    let params = {}
    params.per_page = 18
    params.page = page || 1
    let queryFiltersStr = queryFilter({ page: params.page })
    return api.getShops(params).then(
      ({ json, response }) => {
        getShopsActions.success(json, { entityName: 'shops' })
        let pagination = paginate(response)
        // 防止用户点击过快时路由来回跳转
        if (!this.mounted) {
          return
        }
        routerActions.replace(`/admin/shopping${queryFiltersStr}`)
        if (this.mounted) {
          this.setState({
            pagination: pagination,
            queryFiltersStr
          })
        }
        return true
      },
      errors => {
        getShopsActions.failure(errors)
      }
    )
  },

  deleteShops(shop) {
    const { deleteShopsActions, successAction } = this.props
    KBPopover.plugins.confirm(
      '删除商城',
      `您确定要删除当前商城${shop.name}吗?`,
      {
        confirm: () => {
          api.deleteShop(shop.id).then(
            json => {
              deleteShopsActions.success(json, { key: 'shops', id: shop.id })
              successAction({ message: '删除商城成功!' })
              KBPopover.close()
            },
            error => {
              deleteShopsActions.failure(error)
            }
          )
        }
      }
    )
  },

  exportOrders() {
    KBPopover.show(<OrdersReport />)
  },

  render() {
    let { shops } = this.props
    const {
      pagination,
      total_amount,
      total_profit,
      total_quantities
    } = this.state

    return (
      <div className="kb-content">
        <div className="kb-content-container clear-fix">
          <div
            className="clear-fix border-bottom"
            style={{ padding: '20px 30px' }}
          >
            <div className="nav-section-header-title f-left">
              <span>商城管理</span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  marginTop: 5
                }}
              >
                <div>
                  <span style={{ marginRight: 5 }}>今日销售额:</span>
                  <span>{total_amount}</span>
                </div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <span style={{ marginRight: 5 }}>今日销售商品数:</span>
                  <span>{total_quantities}</span>
                </div>
                <div>
                  <span style={{ marginRight: 5 }}>今日利润:</span>
                  <span>{total_profit}</span>
                </div>
              </div>
            </div>
            <button
              className="c-btn-secondary f-right"
              onClick={this.exportOrders}
            >
              导出订单
            </button>
          </div>
          <div style={{ padding: 30 }}>
            {shops && shops.length == 0 ? (
              <p className="t-center">
                您的空间暂时不支持商城,请联系Capitaland Office开通
              </p>
            ) : (
              ''
            )}
            {shops &&
              shops.map((shop, index) => {
                return <ShoppingBlock key={index} shopping={shop} />
              })}
            <KBPagination
              pagination={pagination}
              template={`/admin/shopping?page=#PAGE#`}
            />
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  const shops = selectors.getShops(state)
  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page

  return {
    page,
    shops
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    getShopsActions: bindActionCreators(shopsActions.replace, dispatch),
    deleteShopsActions: bindActionCreators(shopsActions.delete, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shops)
