import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { subscriptionsAction, successState, taxesAction } from 'app/actions'
import {
  KBFormError,
  KBButton,
  KBPopover,
  KBPopoverTop,
  KBDateInput,
  KBSelect,
  KBTextarea,
  KBNoData,
  KBCheckBox,
  KBRadio
} from 'components'
import { routerActions } from 'react-router-redux'
import { TERMINATE_REASON, LINE_ITEM_TYPES, POINT_TYPES} from 'app/constants'
import moment from 'moment'
import { toPercent } from 'utils/kbUtil'
import { getEntity, getTaxes } from 'app/selectors'
import _ from 'lodash'
import { DateUtils } from 'react-day-picker'

import InvoiceList from '../invoices/InvoiceList'

var OrgSubTerminateForm = React.createClass({
  getInitialState() {
    const {
      subscription: { sales_renting_phases, deposit_invoices }
    } = this.props
    let phaseInvoices = []
    sales_renting_phases &&
      sales_renting_phases.map(phase => {
        if (phase.sales_invoice) {
          phaseInvoices.push(phase.sales_invoice)
        }
      })
    let subInvoices =
      deposit_invoices && deposit_invoices.length
        ? phaseInvoices.concat(deposit_invoices)
        : phaseInvoices
    subInvoices = _.uniqBy(subInvoices, 'id')
    subInvoices = _.filter(
      subInvoices,
      o =>
        o.status === 'unpaid' || (o.status === 'overdue' && o.paid_amount === 0)
    )
    // this.setState({'invoices':subInvoices})
    let pickedFruits = subInvoices.map(({ status, invoice_type, ...rent }) => ({
      ...rent,
      status,
      invoiceType: invoice_type
    }))

    return {
      line_items: [],
      terminate_immediately: false,
      isAllInvoicesChecked: false,
      selectInvoices: [],
      allCheckedBtn: false,
      invoices: pickedFruits || [],
      billType: []
    }
  },
  updateSubScriptionForm(model) {
    const {
      updateSubScriptionAction,
      member,
      subId,
      successAction,
      filters,
      getSubscriptions,
      isTop,
      callback
    } = this.props
    const { terminate_immediately, selectInvoices } = this.state
    var params = Object.assign({}, model)

    if (terminate_immediately) {
      params.expire_date = null
    }
    if (params.can_destroy_invoices !== 'unTerminateorDelOrder') {
      delete params.line_items
    }

    if (selectInvoices && selectInvoices.length) {
      params.reversal_invoice_ids = _.map(selectInvoices, 'id')
    }

    return api.terminateSubScriptiopns(subId, params).then(json => {
      const { expire_date } = model
      updateSubScriptionAction.success(json)
      getSubscriptions && getSubscriptions(filters)
      callback && callback()
      successAction({
        message: params.promptly
          ? '合同已经终止！'
          : `合同已经定于${moment(expire_date).format(
              'YYYY年MM月DD日'
            )}提前终止，合同终止之后不会继续生成账单!`
      })
      if (isTop) {
        KBPopoverTop.close()
        return true
      }
      KBPopover.close()
      return true
    })
  },

  componentDidMount() {
    const {
      fields: { expire_date, line_items, can_destroy_invoices },
      subId,
      getTaxesAction
    } = this.props
    expire_date.onChange(new Date())
    can_destroy_invoices.onChange('unTerminateorDelOrder')

    api.getSpaceTaxes().then(
      json => {
        getTaxesAction.success(json)
      },
      error => {
        getTaxesAction.failure(error)
      }
    )

    api.getSelfDefinedLineItemTypes().then(
      ({ json }) => {
        let NEW_LINE_ITEM_TYPES = JSON.parse(JSON.stringify(LINE_ITEM_TYPES))
        NEW_LINE_ITEM_TYPES.splice(NEW_LINE_ITEM_TYPES.length - 1, 0, ...json)
        this.setState({
          billType: NEW_LINE_ITEM_TYPES
        })
      },
      error => {}
    )
  },

  changeInvoiceDealing(bool) {
    const {
      fields: { can_destroy_invoices }
    } = this.props
    can_destroy_invoices.onChange(bool)

    if (bool) {
      this.changeDateDeal(true)
    }
  },

  changeTerminateReason(value) {
    const {
      fields: { terminate_reason }
    } = this.props

    terminate_reason.onChange(value)
  },

  changeDateDeal(bool) {
    const {
      fields: { promptly, can_destroy_invoices }
    } = this.props
    let value = bool
    if (can_destroy_invoices.value === 'terminateorDelOrder') {
      value = true
    }

    promptly.onChange(value)
    this.setState({
      terminate_immediately: value
    })
  },

  goCustom() {
    const { routerActions } = this.props
    routerActions.replace('/admin/spaces_setting/invoice')
    KBPopover.close()
  },

  UnTerminateorDelOrder(line_items, taxes) {
    const {billType} = this.state
    // const newTaxes = taxes.filter(taxe=>taxe.rate===0 || taxe.rate === 0.05 || taxe.rate === 0.06 || taxe.rate === 0.09)
    const newTaxes = [...(taxes || [])].sort((a,b) => a.rate - b.rate)
    return (
      <div className="">
        {line_items && line_items.length ? (
          <ul className="region-input-box">
            {line_items &&
              line_items.map((line, index) => {
                return (
                  <li key={index}>
                    <div className="kb-row">
                      <i
                        className="iconfont icon-close"
                        onClick={() => line_items.removeField(index)}
                      />
                      <div className="kb-row">
                        <div className="kb-form-group kb-form-alone kb-div-c18">
                          <label style={{ position: 'relative' }}>
                            单价
                            <span
                              style={{
                                color: '#F62D51',
                                position: 'absolute',
                                top: 3,
                                left: '-8px'
                              }}
                            >
                              *
                            </span>
                          </label>
                          <div>
                            <input type="text" {...line.unit_price} />
                            {line.unit_price.touched &&
                              line.unit_price.error && (
                                <p className="lr-error">
                                  {' '}
                                  {line.unit_price.error}{' '}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="kb-row">
                        <div className="kb-form-group kb-form-alone kb-div-c18">
                          <label
                            style={{ position: 'relative' }}
                            className="label-rt"
                          >
                            数量
                            <span
                              style={{
                                color: '#F62D51',
                                position: 'absolute',
                                top: 3,
                                left: '-8px'
                              }}
                            >
                              *
                            </span>
                          </label>
                          <div>
                            <input type="number" {...line.units} />
                            {line.units.touched && line.units.error && (
                              <p className="lr-error"> {line.units.error} </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="kb-row">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <KBSelect
                            style={{ width: '80%' }}
                            isRequired={true}
                            defaultValue="-1"
                            field={line.line_item_type}
                            arrayData={billType}
                            title="类型"
                            className="kb-form-group kb-form-c18 kb-form-alone"
                          />
                          <span
                            style={{
                              width: '15%',
                              height: '34px',
                              marginTop: '30px'
                            }}
                            className="cancel-btn"
                            onClick={this.goCustom}
                          >
                            自定义
                          </span>
                        </div>
                      </div>
                      {line.line_item_type.value &&
                      line.line_item_type.value == 'renting_fee' ? (
                        <div className="kb-row">
                          <KBRadio
                            isRequired={true}
                            className="kb-form-group kb-form-c18 kb-form-alone"
                            rightLabel="label-rt"
                            title="租赁内容"
                            arrayType={POINT_TYPES}
                            inClassName="kb-radio-box"
                            divClassName="kb-form-group kb-div-48"
                            itemStyle={{ width: '80px' }}
                            field={line.charge_code}
                          />
                        </div>
                      ) : null}
                      <div className="kb-row">
                        <KBDateInput
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          title="开始日期"
                          field={line.start_date}
                          isRequired={true}
                          placeholder=""
                          disabledDays={DateUtils.isPastDay}

                        />
                      </div>
                      <div className="kb-row">
                        <KBDateInput
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          title="结束日期"
                          field={line.end_date}
                          isRequired={true}
                          placeholder=""
                          disabledDays={DateUtils.isPastDay}

                        />
                      </div>
                      <div className="kb-row">
                        <KBDateInput
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          title="缴费日期"
                          field={line.due_date}
                          isRequired={true}
                          placeholder=""
                          disabledDays={DateUtils.isPastDay}

                        />
                      </div>
                      <div className="kb-row">
                        <KBSelect
                          defaultValue="-1"
                          field={line.sales_tax_id}
                          arrayData={newTaxes}
                          title="税率"
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          isRequired={true}
                        />
                      </div>
                      <div className="kb-row">
                        <KBTextarea
                          field={line.description}
                          title="说明"
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          isRequired={true}
                        />
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        ) : (
          ''
        )}
        <div className="f-left">
          <button
            className="bordered-btn"
            style={{ marginLeft: 0 }}
            onClick={() => {
              line_items.addField()
            }}
            type="button"
          >
            <i className="iconfont icon-add" />
            账款
          </button>
        </div>
      </div>
    )
  },

  checkAllInvoices() {
    const { isAllInvoicesChecked, invoices, allCheckedBtn } = this.state
    let isAllInvoicesChecked_now = !allCheckedBtn
    let invoices_now = invoices
    if (!isAllInvoicesChecked_now) {
      invoices_now = []
    }
    this.setState({
      isAllInvoicesChecked: isAllInvoicesChecked_now,
      selectInvoices: invoices_now,
      allCheckedBtn: isAllInvoicesChecked_now
    })
  },

  changeInvoice(checked, data) {
    var invoices = Object.assign([], this.state.selectInvoices)
    if (checked) {
      invoices.push(data)
      // 当选中的 invoice 逐个增加时的判断
      if (invoices.length == 10) {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: true,
          isAllInvoicesChecked: true
        })
      } else {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: false
        })
      }

      return
    }

    let removeIndex = invoices.findIndex(json => json.id == data.id)

    if (removeIndex == -1) {
      return
    }

    invoices.splice(removeIndex, 1)
    // 当选中的 invoice 逐个减少时的判断
    if (invoices.length == 10) {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: true
      })
    } else {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: false
        // isAllInvoicesChecked : false
      })
    }
  },

  CustomTerminateorDelOrder() {
    const {
      subscription: { sales_renting_phases, deposit_invoices },
      location_id
    } = this.props
    const { invoices } = this.state
    const tdStyle = {
      height: '280px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8,
      cursor: 'default'
    }
    const newInvoices = invoices.filter(
      invoice => invoice.invoiceType !== 'deposit'
    )
    return (
      <table className="content-table edit-table">
        <thead>
          <tr>
            <th>
              <KBCheckBox
                style={{ marginTop: 2 }}
                data={invoices}
                callback={this.checkAllInvoices}
                checked={this.state.allCheckedBtn}
              />
            </th>
            <th>账单状态</th>
            <th>公司名称</th>
            <th>应付金额</th>
            <th>待付金额</th>
            <th>到期日期</th>
            <th>实际付款日</th>
          </tr>
        </thead>
        {newInvoices && newInvoices.length ? (
          newInvoices.map((json, index) => {
            let obligation =
              Math.round(json.total_amount * 100) / 100 -
              Math.round(json.paid_amount * 100) / 100
            let isReviewed = json.is_reviewed
            return (
              <InvoiceList
                key={index}
                json={json}
                location_id={location_id}
                isReviewed={isReviewed}
                obligation={obligation}
                isTeam={false}
                isApplicationShow={true}
                isLastItem={true}
                selectInvoices={this.state.selectInvoices}
                callback={this.changeInvoice}
              />
            )
          })
        ) : (
          <tbody>
            <tr style={{ border: 0, backgroundColor: '#ffffff' }}>
              <td colSpan="9" style={tdStyle}>
                <KBNoData />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    )
  },

  billChoiceComplie(billType = 'unTerminateorDelOrder', line_items, taxes) {
    const { subscription } = this.props

    const billMap = {
      // terminateorDelOrder: '',
      unTerminateorDelOrder: this.UnTerminateorDelOrder(line_items, taxes),
      customTerminateorDelOrder:
        subscription && this.CustomTerminateorDelOrder()
    }

    return billMap[billType]
  },

  render() {
    const {
      fields: {
        expire_date,
        promptly,
        terminate_reason,
        terminate_note,
        line_items,
        can_destroy_invoices
      },
      error,
      submitting,
      handleSubmit,
      member,
      org,
      subscriptions,
      taxes,
      isTop
    } = this.props
    const line_items_array = this.state.line_items
    const { terminate_immediately } = this.state

    let dealInvoice = [
      // {
      //   id: 'terminateorDelOrder',
      //   name: '终止合同后删除所有合同相关账单'
      // },
      {
        id: 'unTerminateorDelOrder',
        name: '不删除已生成的账单'
      },
      {
        id: 'customTerminateorDelOrder',
        name: '自定义选择删除账单'
      }
    ]

    let dealTerminateDate = [
      {
        id: true,
        name: '立刻终止'
      },
      {
        id: false,
        name: '选择日期'
      }
    ]

    return (
      <form onSubmit={handleSubmit(this.updateSubScriptionForm)}>
        <KBFormError error={error} />
        <header className="kb-form-header">
          <h2 className="header-title">终止合同</h2>
          <i
            className="header-close iconfont icon-cancel"
            onClick={isTop ? KBPopoverTop.close : KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <div
              className="kb-form-group kb-form-alone"
              style={{ fontSize: 16, marginBottom: 10 }}
            >
              合同终止后将不再有效，账单不会继续生成，请确认是否要终止
            </div>
            <div
              className="kb-form-group kb-form-alone"
              style={{ fontSize: 16, marginBottom: 10 }}
            >
              合同终止后系统自动生成保证金退款账单，无需手工创建。
            </div>
          </div>

          <fieldset className="fieldset">
            <div className="form-content">
              <header className="kb-nav-header" style={{ marginBottom: 10 }}>
                <div className="region-title">
                  <i className="iconfont icon-adjust" />
                  <span className="title-text">账单处理</span>
                </div>
                <div className="kb-nav-line pact-line">
                  <div style={{ width: '475px' }}></div>
                </div>
              </header>

              <div className="nav-content-container">
                <div
                  className="kb-row kb-form-66"
                  style={{ paddingTop: 10, display: 'flex' }}
                >
                  {dealInvoice.map(data => {
                    return (
                      <div
                        className="choices"
                        style={{ textAlign: 'left' }}
                        onClick={this.changeInvoiceDealing.bind(null, data.id)}
                      >
                        <span
                          className={`radio ${
                            can_destroy_invoices.value === data.id
                              ? 'radio-active'
                              : ''
                          }`}
                        ></span>
                        <label>{data.name}</label>
                      </div>
                    )
                  })}
                </div>

                {/*                {can_destroy_invoices.value === 'unTerminateorDelOrder' ? (
                  <div className="">
                    {line_items && line_items.length ? (
                      <ul className="region-input-box">
                        {line_items &&
                          line_items.map((line, index) => {
                            return (
                              <li key={index}>
                                <div className="kb-row">
                                  <i
                                    className="iconfont icon-close"
                                    onClick={() =>
                                      line_items.removeField(index)
                                    }
                                  />
                                  <div className="kb-row">
                                    <div className="kb-form-group kb-form-alone kb-div-c18">
                                      <label>单价</label>
                                      <div>
                                        <input
                                          type="text"
                                          {...line.unit_price}
                                        />
                                        {line.unit_price.touched &&
                                          line.unit_price.error && (
                                            <p className="lr-error">
                                              {' '}
                                              {line.unit_price.error}{' '}
                                            </p>
                                          )}
                                        {line.unit_price.value > 0 && (
                                          <p
                                            style={{
                                              paddingTop: 2,
                                              color: '#999',
                                              fontSize: 12
                                            }}
                                          >
                                            {' '}
                                            {
                                              '如果是退款，单价最好设置为负数哦'
                                            }{' '}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="kb-row kb-form-66">
                                    <div className="kb-form-group kb-div-c39">
                                      <label>数量</label>
                                      <div>
                                        <input type="number" {...line.units} />
                                        {line.units.touched &&
                                          line.units.error && (
                                            <p className="lr-error">
                                              {' '}
                                              {line.units.error}{' '}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <KBSelect
                                      defaultValue="-1"
                                      field={line.sales_tax_id}
                                      arrayData={taxes}
                                      title="税率"
                                      titleClass="label-rt"
                                      className="kb-form-group kb-form-c39"
                                    />
                                  </div>
                                  <div className="kb-row">
                                    <KBSelect
                                      defaultValue="-1"
                                      field={line.line_item_type}
                                      arrayData={LINE_ITEM_TYPES}
                                      title="类型"
                                      className="kb-form-group kb-form-c18 kb-form-alone"
                                    />
                                  </div>
                                  <div className="kb-row">
                                    <KBTextarea
                                      field={line.description}
                                      title="说明"
                                      className="kb-form-group kb-form-c18 kb-form-alone"
                                    />
                                  </div>
                                </div>
                              </li>
                            )
                          })}
                      </ul>
                    ) : (
                      ''
                    )}
                    <div className="f-left">
                      <button
                        className="bordered-btn"
                        style={{ marginLeft: 0 }}
                        onClick={() => {
                          line_items.addField()
                        }}
                        type="button"
                      >
                        <i className="iconfont icon-add" />
                        账款
                      </button>
                    </div>
                  </div>
                ) : null}*/}
                {this.billChoiceComplie(
                  can_destroy_invoices.value,
                  line_items,
                  taxes
                )}
              </div>
            </div>

            <div className="form-content">
              <header className="kb-nav-header" style={{ marginBottom: 10 }}>
                <div className="region-title">
                  <i className="iconfont icon-adjust" />
                  <span className="title-text">终止日期</span>
                </div>
                <div className="kb-nav-line pact-line">
                  <div style={{ width: '475px' }}></div>
                </div>
              </header>

              <div className="nav-content-container">
                <div className="kb-row kb-form-66">
                  <div
                    className="choices kb-form-66"
                    style={{ padding: '9px 0' }}
                  >
                    {dealTerminateDate.map(data => {
                      return (
                        <div
                          className="choices"
                          onClick={this.changeDateDeal.bind(null, data.id)}
                        >
                          <span
                            className={`radio ${
                              data.id == terminate_immediately
                                ? 'radio-active'
                                : ''
                            }`}
                          ></span>
                          <label
                            style={
                              !data.id &&
                              can_destroy_invoices.value ===
                                'terminateorDelOrder'
                                ? { color: '#ccc' }
                                : null
                            }
                          >
                            {data.name}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                  {!terminate_immediately ? (
                    <div className="kb-row choices">
                      <KBDateInput
                        className="kb-form-group kb-form-alone"
                        field={expire_date}
                        isRequired={true}
                        placeholder=""
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="form-content">
              <header className="kb-nav-header" style={{ marginBottom: 10 }}>
                <div className="region-title">
                  <i className="iconfont icon-adjust" />
                  <span className="title-text">终止原因</span>
                </div>
                <div className="kb-nav-line pact-line">
                  <div style={{ width: '475px' }}></div>
                </div>
              </header>

              <div className="nav-content-container">
                <div className="kb-row">
                  {TERMINATE_REASON.map(data => {
                    return (
                      <div
                        className="terminate-reasons"
                        onClick={this.changeTerminateReason.bind(null, data.id)}
                      >
                        <span
                          className={`radio ${
                            data.id == terminate_reason.value
                              ? 'radio-active'
                              : ''
                          }`}
                        ></span>
                        <label>{data.name}</label>
                      </div>
                    )
                  })}
                </div>
                {terminate_reason.touched && terminate_reason.error ? (
                  <div className="kb-row" style={{ marginTop: '-20px' }}>
                    <p className="lr-error">{terminate_reason.error}</p>
                  </div>
                ) : (
                  ''
                )}
                <div className="kb-row">
                  <KBTextarea
                    style={{ width: '100%' }}
                    className="kb-form-group kb-form-c18 kb-form-alone reason-note"
                    placeholder="备注"
                    field={terminate_note}
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <div className="kb-row fixed-form-btn">
            <span
              className="cancel-btn"
              onClick={isTop ? KBPopoverTop.close : KBPopover.close}
            >
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              终止
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

OrgSubTerminateForm.propTypes = {
  subId: PropTypes.string.isRequired
}

function mapStateToProps(state, props) {
  let taxes = Object.assign([], getTaxes(state))
  taxes = _.cloneDeep(taxes).map(tax => {
    let name = tax.name
    tax.name = `${name} (税率:${toPercent(tax.rate, 2)})`
    return tax
  })

  let subscription_id = props.subId
  let subscription = getEntity(state, 'subscriptions', subscription_id)

  return {
    taxes,
    subscription,
    initialValues: {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubScriptionAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch),
    getTaxesAction: bindActionCreators(taxesAction.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validateLine = line => {
  const errors = {}
  if (valid.isNumber(line.unit_price)) {
    errors.unit_price = '请输入正确的价格, 小数点保留2位'
  }

  if (valid.required(line.units) || line.units == 0) {
    errors.units = '请输入数量并且不等于0'
  }

  if (valid.isNumber(line.unit_price)) {
    errors.unit_price = '请输入正确的价格, 小数点保留2位'
  }

  if (valid.required(line.units) || line.units == 0) {
    errors.units = '请输入数量并且不等于0'
  }

  if (valid.required(line.line_item_type)) {
    errors.line_item_type = '请选择类型'
  }

  if (!line.charge_code && line.line_item_type == 'renting_fee') {
    errors.charge_code = '请选择租赁内容'
  }

  if (!line.start_date) {
    errors.start_date = '请选择开始时间'
  }

  if (!line.end_date) {
    errors.end_date = '请选择结束时间'
  }

  if (!line.due_date ) {
    errors.due_date = '请选择缴费时间'
  }

  if(line.start_date && line.end_date && !moment(line.start_date).isSameOrBefore(line.end_date)){
    errors.end_date = '请选择晚于或者等于开始时间的结束时间'
  }


  if (!line.sales_tax_id || line.sales_tax_id == -1) {
    errors.sales_tax_id = '请选择税率'
  }

  if(!line.description){
    errors.description = '请输入说明'
  }

  return errors
}

const validate = values => {
  const errors = {}

  if (!values.promptly && valid.required(values.expire_date)) {
    errors.expire_date = '请选择终止日期'
  }

  if (valid.required(values.terminate_reason)) {
    errors.terminate_reason = '请选择终止原因'
  }

  errors.line_items = values.line_items.map(validateLine)

  return errors
}

const formConfig = {
  form: 'OrgSubTerminateForm',
  fields: [
    'expire_date',
    'terminate_reason',
    'terminate_note',
    'promptly',
    'can_destroy_invoices',
    'line_items[].units',
    'line_items[].unit_price',
    'line_items[].sales_tax_id',
    'line_items[].line_item_type',
    'line_items[].description',
    'line_items[].start_date',
    'line_items[].end_date',
    'line_items[].due_date',
    'line_items[].charge_code'
  ],
  validate: validate,
  touchOnBlur: false
}

OrgSubTerminateForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgSubTerminateForm)

export default OrgSubTerminateForm
