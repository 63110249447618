import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm, getValues } from 'redux-form'
import { successState, pointPlansAction, apiCallFailure } from 'app/actions'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  INVOICE_CERTIFICATE_PAYMENT_TYPES,
  POINT_RECHARGE_TABS
} from 'app/constants'
import * as selectors from 'app/selectors'
import {
  KBFormError,
  KBPopover,
  KBPopoverTop,
  KBButton,
  KBInput,
  KBSelect,
  KBDateInput,
  KBRadio,
  KBTextarea
} from 'components'

var OrganizationRechargePointForm = React.createClass({
  getInitialState() {
    return {
      tab: 'giving'
    }
  },

  componentDidMount() {
    const { getPointPlansAction } = this.props
    const {
      fields: { tab }
    } = this.props

    tab.onChange('giving')

    api.getPointPlans().then(
      json => {
        getPointPlansAction.success(json)
      },
      error => {
        getPointPlansAction.failure(error)
      }
    )
  },

  rechargeable(model) {
    const {
      org,
      successActions,
      loadOrganizationInfo,
      deletePointPlansAction,
      virtual_currency_name,
      apiCallFailureActions
    } = this.props
    const { tab } = this.state
    const item_count = model.item_count
    delete model.tab
    model.sales_customer_id = org.sales_customer.id
    if (model.total_price > 1000) {
      return apiCallFailureActions({
        status: 'error',
        message: '金额不得大于1000!'
      })
    }

    if (tab == 'giving' || tab == 'revoke') {
      delete model.item_count
      delete model.paid
      delete model.total_price
      delete model.payment_mode

      if (tab == 'giving') {
        return KBPopoverTop.plugins.confirm(
          `赠送${virtual_currency_name}`,
          `将赠送当前用户 ${model.gift_count} ${virtual_currency_name}，是否确定？`,
          {
            confirm: () => {
              KBPopoverTop.close()
              return api.postGivingPoint(model).then(
                () => {
                  successActions({
                    message: `赠送${virtual_currency_name}成功`
                  })
                  loadOrganizationInfo && loadOrganizationInfo()
                  KBPopover.close()
                },
                error => {
                  deletePointPlansAction.failure(error)
                }
              )
            }
          }
        )
      }

      if (tab == 'revoke') {
        model.gift_count = model.count
        return KBPopoverTop.plugins.confirm(
          `扣除${virtual_currency_name}`,
          `将扣除当前用户 ${model.count} ${virtual_currency_name}，是否确定？`,
          {
            confirm: () => {
              KBPopoverTop.close()
              return api.postRevokePoint(model).then(
                () => {
                  successActions({
                    message: `扣除${virtual_currency_name}成功`
                  })
                  loadOrganizationInfo && loadOrganizationInfo()
                  KBPopover.close()
                },
                error => {
                  deletePointPlansAction.failure(error)
                }
              )
            }
          }
        )
      }
    }

    let hint = `充值${item_count}${virtual_currency_name}，收取用户金额¥${model.total_price}，是否确定？`

    delete model.gift_count
    KBPopoverTop.plugins.confirm(`充值${virtual_currency_name}`, hint, {
      confirm: () => {
        KBPopoverTop.close()
        return api.postMembersPointPlansGrant(model).then(
          () => {
            successActions({ message: '充值成功' })
            loadOrganizationInfo && loadOrganizationInfo()
            KBPopover.close()
          },
          error => {
            deletePointPlansAction.failure(error)
          }
        )
      }
    })
  },

  changeItemCount(e) {
    const {
      fields: { item_count, total_price },
      currencyRate
    } = this.props
    item_count.onChange(e.target.value)
    let priceValue = 0
    if (!isNaN(parseFloat(e.target.value)) && isFinite(e.target.value))
      priceValue = parseInt(e.target.value) * currencyRate
    total_price.onChange(parseFloat(priceValue).toFixed(2))
  },

  getGiftPoint(points) {
    const { pointPlans } = this.props

    let giftPoints = 0
    pointPlans &&
      pointPlans.map(data => {
        if (points >= data.number) {
          giftPoints = points * data.gift_rate
        }
      })
    return Math.floor(giftPoints * 100) / 100
  },

  changeTab(tabs) {
    const {
      fields: { tab, note }
    } = this.props
    let tabId = tabs.id

    tab.onChange(tabId)
    note.onChange('')
    this.setState({
      tab: tabId
    })
  },

  changePointType(value) {
    const {
      fields: { point_type }
    } = this.props

    point_type.onChange(value)
  },

  render() {
    const {
      fields: {
        item_count,
        paid,
        total_price,
        payment_mode,
        gift_count,
        count,
        note,
        point_type
      },
      error,
      submitting,
      handleSubmit,
      currencyRate,
      firstPlan,
      isPaid,
      virtual_currency_name,
      org
    } = this.props
    const { tab } = this.state
    const itemCount = parseInt(item_count.value)
    const classNmaeCom = {
      className: 'kb-form-group kb-form-c178 kb-form-alone'
    }
    const tabsStyle = {
      padding: '30px 0px 15px'
    }

    let tabs = POINT_RECHARGE_TABS

    const POINT_TYPES = [
      {
        title: `充值${virtual_currency_name}`,
        value: 'balance'
      },
      {
        title: `赠送${virtual_currency_name}`,
        value: 'gift_balance'
      }
    ]

    const giftBalance = (org && org.balances && org.balances.gift_point) || 0

    return (
      <form ref="areaForm" onSubmit={handleSubmit(this.rechargeable)}>
        <KBFormError err={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">{virtual_currency_name}充值</h2>
          <i
            className="header-close iconfont icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <ul
            className="nav-section-tab"
            style={{ marginBottom: 0, borderBottom: '1px solid #e6e6e6' }}
          >
            {tabs.map(data => {
              return (
                <li
                  className={`${tab && tab == data.id ? 'nav-section-tab-active' : ''
                    }`}
                  onClick={this.changeTab.bind(null, data)}
                >
                  <a className="status-text">{data.name}</a>
                </li>
              )
            })}
          </ul>
          {tab == 'recharge' ? (
            <div style={tabsStyle}>
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-form-c178 kb-form-alone"
                  isRequired={true}
                  field={item_count}
                  onChange={this.changeItemCount}
                  title={`充值${virtual_currency_name}数量`}
                  hint={`价值：¥${itemCount && Number.isInteger(itemCount)
                    ? parseFloat(itemCount * currencyRate).toFixed(2)
                    : 0
                    } ${firstPlan && firstPlan.number <= itemCount
                      ? `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0赠送 ${this.getGiftPoint(
                        itemCount
                      )} ${virtual_currency_name}`
                      : ''
                    }`}
                />
              </div>
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-form-c178 kb-form-alone"
                  isRequired={true}
                  field={total_price}
                  title="收款金额"
                />
              </div>
              <div className="kb-row">
                <KBSelect
                  className="kb-form-group kb-div-c178 kb-form-alone"
                  title="支付方式"
                  field={payment_mode}
                  arrayData={INVOICE_CERTIFICATE_PAYMENT_TYPES}
                />
              </div>
              {/** <div className='kb-row'>
                    <KBRadio {...classNmaeCom} isRequired={ true } outlineClass='kb-form-group kb-form-66' inClassName='kb-form-group kb-form-66' divClassName='kb-form-group kb-form-33' title='已付款' arrayType={ isPaid } name='paid' field={ paid } />
                  </div> */}
              <div className="kb-row">
                <KBTextarea
                  field={note}
                  title="备注"
                  isRequired={false}
                  className="kb-form-group kb-form-c178 kb-form-alone"
                />
              </div>
            </div>
          ) : null}
          {tab == 'giving' ? (
            <div style={tabsStyle}>
              <div className="kb-row">
                <KBInput
                  type="number"
                  className="kb-form-group kb-form-c178 kb-form-alone"
                  isRequired={true}
                  field={gift_count}
                  title="赠送数量"
                />
              </div>
              <div className="kb-row">
                <KBTextarea
                  field={note}
                  title="备注"
                  isRequired={false}
                  className="kb-form-group kb-form-c178 kb-form-alone"
                />
              </div>
            </div>
          ) : null}
          {tab == 'revoke' ? (
            <div style={tabsStyle}>
              <div className="kb-row">
                <KBInput
                  type="number"
                  className="kb-form-group kb-form-c178 kb-form-alone"
                  isRequired={true}
                  field={count}
                  title="扣除数量"
                  hint={`当前最多可扣除\u00A0${giftBalance}\u00A0个赠送${virtual_currency_name}`}
                />
              </div>
              <div className="kb-row">
                <div className="kb-form-group kb-form-c178 kb-form-alone">
                  <label>
                    {virtual_currency_name}类型
                    <span className="must-fill">*</span>
                  </label>
                  <div>
                    <div className="kb-form-group r1_radios">
                      {POINT_TYPES.map((data, index) => {
                        return (
                          <div
                            className="kb-form-group r1_radio"
                            onClick={this.changePointType.bind(
                              null,
                              data.value
                            )}
                            key={index}
                          >
                            <div
                              className={`radio-btn ${point_type.value == data.value
                                ? 'radio-btn-active'
                                : ''
                                }`}
                            ></div>
                            <label className="label">{data.title}</label>
                          </div>
                        )
                      })}
                    </div>
                    {point_type.touched && point_type.error ? (
                      <p className="lr-error">{point_type.error}</p>
                    ) : (
                        ''
                      )}
                  </div>
                </div>
              </div>
              <div className="kb-row">
                <KBTextarea
                  field={note}
                  title="备注"
                  isRequired={false}
                  className="kb-form-group kb-form-c178 kb-form-alone"
                />
              </div>
            </div>
          ) : null}
          <div className="kb-row fixed-form-btn">
            <KBButton
              className="certain-btn"
              type="submit"
              disabled={submitting}
            >
              {submitting ? '确定中' : '确定'}
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const spaceSetting = selectors.getSpaceSetting(state)
  const currencyRate =
    spaceSetting.virtual_currency_settings.point_show_rate_setting
  let pointPlans = selectors.getPointPlans(state)
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)
  pointPlans = pointPlans.sort((a, b) => {
    return a.number - b.number
  })
  let firstPlan = pointPlans[0]

  const isPaid = [
    { title: '是', value: true },
    { title: '否', value: false }
  ]

  return {
    firstPlan,
    pointPlans,
    isPaid,
    currencyRate,
    virtual_currency_name,
    initialValues: {
      paid: true,
      total_price: '',
      payment_mode: 'cash',
      point_type: 'gift_balance'
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successActions: bindActionCreators(successState, dispatch),
    deletePointPlansAction: bindActionCreators(
      pointPlansAction.delete,
      dispatch
    ),
    getPointPlansAction: bindActionCreators(pointPlansAction.get, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (values.tab != 'recharge') {
    if (valid.required(values.gift_count) && values.tab == 'giving') {
      errors.gift_count = `请输入赠送数量`
    }
    if (values.gift_count > 50000) {
      errors.gift_count = `赠送数量最多不能大于5w`
    }

    if (valid.required(values.count) && values.tab == 'revoke') {
      errors.count = `请输入扣除数量`
    }

    if (values.count > 50000) {
      errors.count = `扣除数量最多不能大于5w`
    }

    if (valid.required(values.point_type) && values.tab == 'revoke') {
      errors.point_type = '请选择要扣除的类型'
    }

    return errors
  }

  if (valid.required(values.item_count)) {
    errors.item_count = '请输入充值数量'
  }

  if (values.item_count < 0) {
    errors.item_count = '充值数量不能小于0'
  }

  if (values.total_price < 0) {
    errors.total_price = '收取金额不能小于0'
  }

  if (valid.isNumber(values.total_price)) {
    errors.total_price = '请输入正确的金额'
  }

  if (valid.integer(values.item_count)) {
    errors.item_count = '请输入整数'
  }

  return errors
}

const formConfig = {
  form: 'AddDeskForm',
  fields: [
    'item_count',
    'paid',
    'total_price',
    'payment_mode',
    'gift_count',
    'count',
    'tab',
    'note',
    'point_type'
  ],
  validate: validate,
  touchOnBlur: false
}

OrganizationRechargePointForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrganizationRechargePointForm)

export default OrganizationRechargePointForm
