import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { KBPopover } from 'components'
import SalesProductsForm from './SalesProductsForm'
import { getEntitiesJsonArray } from 'utils/kbUtil'
import ProductCategoriesForm from './ProductCategoriesForm'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import { canAccessSpaceShop } from 'app/reducers/role'
import moment from 'moment'

const menu = [
  {
    name: '商品',
    value: ''
  },
  {
    name: '订单',
    value: 'product_orders'
  },
  {
    name: '分类',
    value: 'product_categories'
  }
]

var SalesProductsBox = React.createClass({
  getInitialState() {
    const { shopping_id } = this.props
    // const shop = shops.filter(json => json.id == shopping_id)
    return {
      shopping_id: shopping_id,
      // shop: shop,
      visible: null,
      shops: [],
      total_amount: 0,
      total_profit: 0,
      total_quantities: 0
    }
  },

  componentDidMount() {
    const { shopping_id } = this.props
    const currentDate = moment().format('YYYY-MM-DD')

    api.getShopDetail(shopping_id).then(data => {
      let shops = data.json.entities.shops
      let shop = shops[Object.keys(shops)[0]]
      this.setState({
        shops: shop,
        visible: shop.visible
      })
    })

    api
      .getSalesStatistics({
        start_date: currentDate,
        end_date: currentDate,
        shop_id: shopping_id
      })
      .then(({ json }) => {
        const { total_amount, total_profit, total_quantities } = json
        this.setState({
          total_amount,
          total_profit,
          total_quantities
        })
      })
  },

  addSalesProducts() {
    const { shopping_id } = this.props
    KBPopover.show(
      <SalesProductsForm
        shopping_id={shopping_id}
        initialValues={{ number: '0' }}
      />
    )
  },

  addProductCategories() {
    const { shopping_id } = this.props
    KBPopover.show(<ProductCategoriesForm shopping_id={shopping_id} />)
  },

  changeSlide() {
    const { shops } = this.state
    let visible = !this.state.visible
    let params = Object.assign(
      {},
      { payment_type: shops.payment_type },
      { visible: visible }
    )
    this.setState({
      visible
    })
    api.putShops(shops.id, params).then(json => {
      console.log('成功')
    })
  },

  changeSlideConfirm(visible) {
    KBPopover.plugins.confirm(
      `商城${visible ? '下架' : '上线'}`,
      `您是否确定${visible ? '下架' : '上线'}商城，用户将${
        visible ? '不' : ''
      }可以浏览和购买商品？`,
      {
        confirm: () => {
          this.changeSlide && this.changeSlide()
          KBPopover.close()
        }
      }
    )
  },

  render() {
    const { shopping_id, section, user } = this.props
    const {
      visible,
      shops,
      total_amount,
      total_profit,
      total_quantities
    } = this.state
    const canOperateShop = canAccessSpaceShop(user)
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { shopInfo: shops })
    )
    return (
      <div className="kb-content">
        <div className="kb-content-container clear-fix">
          <div
            className="clear-fix border-bottom"
            style={{ padding: '20px 30px' }}
          >
            <div className="nav-section-header-title f-left">
              <span>{shops.name}</span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  marginTop: 5
                }}
              >
                <div>
                  <span style={{ marginRight: 5 }}>今日销售额:</span>
                  <span>{total_amount}</span>
                </div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <span style={{ marginRight: 5 }}>今日销售商品数:</span>
                  <span>{total_quantities}</span>
                </div>
                <div>
                  <span style={{ marginRight: 5 }}>今日利润:</span>
                  <span>{total_profit}</span>
                </div>
              </div>
            </div>
            {canOperateShop ? (
              <div className="f-right">
                <button
                  onClick={this.addProductCategories}
                  className="bordered-btn"
                >
                  添加商品分类
                </button>
                <button
                  onClick={this.addSalesProducts}
                  className="bordered-btn"
                >
                  添加商品
                </button>
              </div>
            ) : null}
          </div>
          <div
            style={{
              padding: '0 30px',
              marginTop: 20,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row-reverse',
              borderBottom: '1px solid #e1e1e1',
              margin: '15px 30px'
            }}
          >
            {canOperateShop ? (
              <div
                style={{ display: 'flex', alignItems: 'center', width: 130 }}
              >
                <div
                  className="slide-btn"
                  onClick={() => {
                    this.changeSlideConfirm(visible)
                  }}
                >
                  <span
                    className={`slide-bg ${
                      visible ? 'slide-left' : 'slide-right'
                    }`}
                  >
                    <span className="slide-move" />
                  </span>
                </div>
                <span style={{ marginLeft: 10, marginBottom: 5 }}>
                  用户可见
                </span>
              </div>
            ) : null}
            <ul
              className="nav-section-tab"
              style={{ marginBottom: 0, border: 'none' }}
            >
              {menu.map((json, index) => {
                if (!canOperateShop && json.value == 'product_orders') {
                  return null
                }

                return (
                  <li
                    key={index}
                    className={`kb-tabs-menu-item ${
                      json.value == section ? 'nav-section-tab-active' : ''
                    }`}
                  >
                    <Link to={`/admin/shopping/${shopping_id}/${json.value}`}>
                      {json.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          {childrenWithProps}
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let sections = props.location.pathname.split('/')
  let section = sections.length > 3 ? sections[4] || '' : ''
  let shopping_id = parseInt(props.params.shopping_id)
  let user = selectors.getCurrentUser(state)

  return {
    section,
    shopping_id,
    user
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesProductsBox)
