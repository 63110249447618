import React from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import {
  KBLoadingContainer,
  KBRangeDate,
  KBPagination,
  KBTableWithoutData
} from 'components'
import {
  paginate,
  queryFilter,
  getFilterAndQuery,
  formatYearDayEN,
  formatOnlyMinuteSecondEN,
  kbCloseDropDown
} from 'utils/kbUtil'
import { lockerReservationsAction } from 'app/actions'
import * as selectors from 'app/selectors'
import intl from 'react-intl-universal'
import moment from 'moment'
import _ from 'lodash'

const DROP_DOWNS = ['dateDropDown']
const FILTER_STATE = ['start_at', 'end_at']

var LockerCleanUpRecord = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      filters,
      lockerReservations: []
    }
  },

  componentDidMount() {
    const { filters, page } = this.props

    this._loadData(filters, page)
  },

  _loadData(filters, page) {
    const {
      loc_id,
      getLockerReservationsAction,
      routerActions,
      query
    } = this.props
    const { from, to } = filters
    let params = Object.assign({}, filters, { page })

    params.per_page = 20
    params.location_id = loc_id
    params.close_from = 'mgmt'

    if (from && to) {
      params.start_at = from && formatYearDayEN(from)
      params.end_at = to && formatYearDayEN(to)
    }
    delete params.from
    delete params.to

    let queryFiltersStr = queryFilter({ ...query, ...params, page: page })
    this.setState({ loading: true })

    api
      .getCleanUpRecord(params)
      .then(
        json => {
          let pagination = paginate(json.response)
          // getLockerReservationsAction.success(json, { entityName: 'lockerReservations' })
          this.setState({
            pagination,
            lockerReservations: _.get(json, 'json', [])
          })
          routerActions.replace(
            `/admin/locations/${loc_id}/lockers/cleanup${queryFiltersStr}`
          )
        },
        error => {
          // getLockerReservationsAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page != nextProps.page) {
      this._loadData({ ...this.state.filters, ...filters }, nextProps.page)
    }
  },

  _setFilter(key, value, obj) {
    let newFilters = Object.assign(
      {},
      this.state.filters,
      obj ? obj : { [key]: value }
    )

    this.setState({ filters: newFilters, loading: true })

    this._loadData(newFilters, 1)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  changeDate(range) {
    this._setFilter(null, null, range)
  },

  clearFilter() {
    this.setState({
      filters: {},
      loading: true
    })
    this._loadData({}, 1)
  },

  render() {
    const { loc_id } = this.props
    const { loading, pagination, lockerReservations } = this.state
    const { from, to, start_at, end_at } = this.state.filters
    return (
      <div className="kb-content-container clear-fix">
        <header className="nav-header">
          <div className=" clear-fix">
            <div className="nav-section-header-title f-left">
              <span>清柜记录</span>
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          {/* <div className='m-top-sm'>
            <KBRangeDate
              format='YYYY/MM/DD'
              from={ from || (start_at || '') }
              to={ to || (end_at || '') }
              leftNone={ true }
              ref={ ref => { this.dateDropDown = ref } }
              dropDown_name='dateDropDown'
              closeClick={ this.closeDropDowns }
              callback={ this.changeDate } />
            <div>
              { Object.keys(this.state.filters).length > 0 ?
                <div className='clear-criteria' style={ {marginTop: 5} } onClick={ this.clearFilter }>
                  <i className='iconfont icon-close' />
                  <span>清除所有筛选条件</span>
                </div> : null }
            </div>
          </div> */}
          <KBLoadingContainer loading={loading}>
            <table className="content-table">
              <thead>
                <tr>
                  <th>储物柜</th>
                  <th>货柜编号</th>
                  <th>使用人</th>
                  <th>日期</th>
                  <th>开始时间</th>
                  <th>结束时间</th>
                  <th>总时长</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={lockerReservations.length > 0}
                tableHeadNum="8"
                tipMessage="暂无清柜记录"
              >
                {lockerReservations &&
                  lockerReservations.map(res => {
                    let text = ''
                    const compareTime = res.close_at
                      ? moment.unix(res.close_at)
                      : moment()
                    const totalSeconds = compareTime.diff(
                      moment.unix(res.start_at),
                      'seconds'
                    )
                    const totalMinutes = totalSeconds / 60
                    const hours = parseInt(totalMinutes / 60)
                    const minutes = parseInt(totalMinutes % 60)
                    if (totalSeconds < 60) {
                      text = '少于1分钟'
                    } else {
                      if (hours === 0) {
                        text = `${minutes} 分钟`
                      } else if (minutes === 0) {
                        text = `${hours} 小时`
                      } else {
                        text = `${hours} 小时 ${minutes} 分钟`
                      }
                    }
                    return (
                      <tr>
                        <td>{_.get(res, 'cabinet.name', '')}</td>
                        <td>{_.get(res, 'locker.name', '')}</td>
                        <td>{_.get(res, 'host.name', '')}</td>
                        <td>{formatYearDayEN(res.created_at)}</td>
                        <td>
                          {res.start_at
                            ? formatOnlyMinuteSecondEN(res.start_at)
                            : ''}
                        </td>
                        <td>
                          {res.close_at
                            ? formatOnlyMinuteSecondEN(res.close_at)
                            : ''}
                        </td>
                        <td>{text}</td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/locations/${loc_id}/lockers/cleanup?page=#PAGE#`}
            />
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const location = props.location || {}
  const loc_id = params && params.id
  // const lockerReservations = selectors.getLockerReservations(state)

  let page = location.query && parseInt(location.query.page)
  page = isNaN(page) ? 1 : page
  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)

  return {
    loc_id,
    page,
    ...paramsAll
    // lockerReservations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLockerReservationsAction: bindActionCreators(
      lockerReservationsAction.replace,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockerCleanUpRecord)
