import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { POST_CATEGORY_OBJ } from 'app/constants'
import { KBAvatar, KBPopover } from 'components'
import { PopoverShowPicture } from 'components/views'
import { KBPopoverConfirm } from 'components/tools'
import { getUserInfoUrl } from 'utils/kbUrl'
import moment from 'moment'

var PostBlock = React.createClass({
  toPostDetail() {
    const { post, routerActions } = this.props
    routerActions.push(`/admin/memberships/memberManage/posts/${post.id}`)
  },

  clickPhoto(url) {
    KBPopover.show(<PopoverShowPicture photoUrl={url} />)
  },

  deletePost() {
    const { post, isDetail, routerActions, deleteCallback } = this.props
    const { id, user } = post
    KBPopoverConfirm({
      name: '删除帖子',
      context: `您确定要删除 ${user.name} 发布的帖子吗?`,
      callback: () => {
        return api.deletePost(id).then(
          ({ json }) => {
            let deletedPost = json
            if (isDetail) {
              routerActions.push(`/admin/memberships/memberManage/posts`)
            } else {
              deleteCallback && deleteCallback(deletedPost.id)
            }
            KBPopover.close()
          },
          errors => {
            KBPopover.close()
          }
        )
      }
    })
  },

  render() {
    const { post, isDetail } = this.props
    const {
      user,
      category,
      tags,
      summary,
      images,
      post_votes_count,
      post_comments_count,
      created_at
    } = post
    let org_title =
      user.org_name || user.title
        ? `（${user.org_name || ''}${
            user.org_name && user.title ? '·' : ''
          }${user.title || ''}）`
        : ''
    let timeFromNow = created_at ? moment.unix(created_at).fromNow() : ''

    return (
      <div
        className="post-block"
        onClick={isDetail ? null : this.toPostDetail}
        style={isDetail ? { border: 0 } : {}}
      >
        <div className="header-info">
          <div className="vf-center">
            <KBAvatar
              user={user}
              size={60}
              style={{ margin: '0 16px' }}
              imgStyle={{ margin: '0 16px' }}
            />
            <div>
              <div className="creator">
                <Link className="color-link name" to={getUserInfoUrl(user.id)}>
                  {user.name || ''}
                </Link>
                <span className="title">{org_title || ''}</span>
              </div>
              <div className="category-and-time vf-center">
                {category === 'demand' ? (
                  <span className="category category-demand m-right-sm">
                    {POST_CATEGORY_OBJ[category] || ''}
                  </span>
                ) : null}
                <span className="time">{timeFromNow || ''}</span>
              </div>
            </div>
          </div>
          <i
            className="iconfont iconfont icon-delete"
            onClick={e => {
              e.stopPropagation()
              this.deletePost()
            }}
          />
        </div>
        <div className="body-detail">
          {tags && tags.length > 0 ? (
            <div className="post-tags">
              {tags.map((tag, index) => {
                return (
                  <span className="post-tag-block" key={index}>
                    {tag}
                  </span>
                )
              })}
            </div>
          ) : null}
          <div className="post-summary">{summary || '此帖暂无描述内容'}</div>
          {images && images.length > 0 ? (
            <div className="post-images">
              {images.map((img, index) => {
                return (
                  <span
                    style={{ backgroundImage: `url(${img})` }}
                    className="img-block"
                    key={index}
                    onClick={e => {
                      e.stopPropagation()
                      this.clickPhoto(img)
                    }}
                  />
                )
              })}
            </div>
          ) : null}
          <div className="votes-and-comments vf-center">
            <div className="m-right">
              <i className="iconfont iconfont icon-collect_icon" />
              <span className="number">{post_votes_count || 0}</span>
            </div>
            <div>
              <i className="iconfont iconfont icon-comment_icon" />
              <span className="number">{post_comments_count || 0}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

PostBlock.PropTypes = {
  post: PropTypes.object.isRequired
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostBlock)
