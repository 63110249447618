import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { Link } from 'react-router'
import { reservationsActions, orgAnnexActions } from 'app/actions'
import { connect } from 'react-redux'
import {
  KBLoadingContainer,
  KBPopover,
  KBRangeDate,
  KBPagination,
  KBSearchList,
  KBMorePhotoUpload,
  KBTableWithoutData,
  KBAvatar
} from 'components'
import {
  getEntitiesJsonArray,
  paginate,
  queryFilter,
  getFilterAndQuery,
  formatMinuteSecondEN
} from 'utils/kbUtil'
import moment from 'moment'
import { KBDayPicker } from 'components/views'
import { KBDropSelect } from 'components/plugins'
import UploadAnnexForm from './UploadAnnexForm'
import * as selectors from 'app/selectors'
import { canAccessLocation } from 'app/reducers/role'
import { getInitialDate } from 'utils/kbData'
import { routerActions } from 'react-router-redux'
import _ from 'lodash'
import { getUserInfoUrl } from 'utils/kbUrl'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy';

const FILTER_STATE = ['from', 'to', 'area_id', 'organization_id']

var OrganizationAnnex = React.createClass({
  displayName: 'OrganizationAnnex',
  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      annex: [],
      filters,
      annexType: []
    }
  },

  componentDidMount() {
    const { page, filters } = this.props
    this.mounted = true
    this._loadData(filters, page)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(filters, page) {
    const { loc_id, org_id, routerActions, query } = this.props
    const { from, to } = filters
    const preFilters = this.props.filters
    let params = Object.assign(
      {},
      preFilters,
      filters,
      { page: page },
      { per_page: 10 }
    )

    if (from && to) {
      params.from = from && moment(from).format('YYYY-MM-DD')
      params.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete params.from
      delete params.to
    }
    let queryFiltersStr = queryFilter({ ...query, ...params, page: page })

    // 获取公司附件列表
    api
      .getAnnexList(org_id, params)
      .then(
        data => {
          let pagination = paginate(data.response)
          data.json.sort((a, b) => {
            return b.id - a.id
          })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/locations/${loc_id}/organizations/${org_id}/annex${queryFiltersStr}`
          )
          this.setState({
            annex: data.json,
            pagination
          })
          // this.getAnnexTypeList()
        },
        errors => {}
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page != nextProps.page) {
      this._loadData({ ...this.state.filters, ...filters }, nextProps.page)
    }
  },
  changeDate(range) {
    this._setFilter(null, null, range)
  },

  _setFilter(key, value, obj) {
    let newFilters = Object.assign(
      {},
      this.state.filters,
      obj ? obj : { [key]: value }
    )

    this.setState({ filters: newFilters, loading: true })

    this._refresh(key, newFilters)
  },
  _refresh(key, filters) {
    const {
      tasks_type,
      page,
      newTask,
      allTasksAction,
      location_id,
      query
    } = this.props
    let dropDown = key + 'Dropdown'
    this.refs[dropDown] && this.refs[dropDown].hide()
    this._loadData(filters, 1)
  },

  clearFilter() {
    this._clearFilters()
  },

  _clearFilters() {
    const filters = { filters: {}, loading: true }
    this.setState({ filters: {}, loading: true })
    this._loadData({}, 1)
  },

  deleteMember(annex) {
    let { loc_id, org_id, page, filters } = this.props
    let params = Object.assign({}, { medium_id: annex.id })

    KBPopover.plugins.confirm(
      '删除附件',
      `您确定要删除附件${annex.filename || annex.id}吗?`,
      {
        confirm: () => {
          api.deleteAnnex(loc_id, org_id, params).then(
            json => {
              this._loadData(filters, page)
              KBPopover.close()
            },
            errors => {
              KBPopover.close()
            }
          )
        }
      }
    )
  },

  // getAnnexTypeList(){
  //   api.getAnnexTypeList().then(data => {
  //     this.setState({ annexType: data.json })
  //   })
  // },

  toUploadAnnex() {
    const { loc_id, org_id, filters, page } = this.props
    const { annexType } = this.state
    KBPopover.show(
      <UploadAnnexForm
        org_id={org_id}
        loc_id={loc_id}
        annexType={annexType}
        filters={filters}
        page={page}
        loadData={this._loadData}
        clearFilters={this._clearFilters}
      />
    )
  },

  render() {
    const { loc_id, org_id, orgs, org, isManager } = this.props
    const { annex, pagination, filters, loading } = this.state

    const tdStyle = {
      height: '280px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8
    }

    const { from, to } = this.state.filters

    return (
      <div
        className="nav-section-content-container"
        style={{ padding: 0, marginBottom: 30 }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div>
            <KBRangeDate
              format="YYYY/MM/DD"
              from={from || ''}
              to={to || ''}
              leftNone={true}
              callback={this.changeDate}
            />
            {Object.keys(this.state.filters).length > 0 ? (
              <div>
                <div
                  className="clear-criteria"
                  style={{ marginTop: 5 }}
                  onClick={this.clearFilter}
                >
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              </div>
            ) : null}
          </div>
          <div className="bordered-btn" onClick={this.toUploadAnnex}>
            上传附件
          </div>
        </div>
        <KBLoadingContainer loading={loading}>
          <table className="content-table">
            <thead>
              <tr>
                <th>附件名称</th>
                <th>上传操作人</th>
                <th>上传时间</th>
                <th>附件</th>
                <th>操作</th>
              </tr>
            </thead>
            <KBTableWithoutData
              hasData={annex.length > 0}
              tableHeadNum="5"
              tipMessage="暂无附件"
            >
              {annex &&
                annex.map(json => {
                  return (
                    <tr>
                      <td>{json.filename}</td>
                      <td>
                        <KBAvatar
                          user={json.uploaded_by}
                          className="vertical-middle"
                          size={30}
                          style={{
                            width: '30px',
                            height: '30px',
                            lineHeight: '33px'
                          }}
                        />
                        <Link
                          className="color-link"
                          style={{ marginLeft: 5, verticalAlign: 'middle' }}
                          to={getUserInfoUrl(
                            json.uploaded_by && json.uploaded_by.id
                          )}
                        >
                          {(json.uploaded_by && json.uploaded_by.name) || ''}
                        </Link>
                      </td>
                      <td>{formatMinuteSecondEN(json.created_at)}</td>
                      <td>
                        {json.url ? (
                          <KBDownloadPrivacy url={json.url}>
                            <a
                              style={{ color: '#5fa7ec' }}
                              className="app-module-url"
                            >
                              下载附件
                            </a>
                          </KBDownloadPrivacy>
                        ) : (
                          '无'
                        )}
                      </td>
                      <td>
                        <i
                          style={{ marginLeft: 8 }}
                          className="iconfont icon-delete"
                          alt="删除"
                          onClick={e => {
                            this.deleteMember(json)
                            e.stopPropagation()
                          }}
                        />
                      </td>
                    </tr>
                  )
                })}
            </KBTableWithoutData>
          </table>
          <KBPagination
            pagination={this.state.pagination}
            template={`/admin/locations/${loc_id}/organizations/${org_id}/annex?page=#PAGE#`}
          />
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { user } = state
  let loc_id = params.id

  let page = parseInt(props.query && props.query.page) || 1

  let meetings = selectors.getMeetingRoomsOfLocation(state, loc_id)
  let orgs = selectors.getOrgsOfLocation(state, loc_id)

  let paramsAll = getFilterAndQuery(props.query, FILTER_STATE)
  let isManager = canAccessLocation(user, loc_id)

  return {
    loc_id,
    orgs,
    meetings,
    page,
    ...paramsAll,
    isManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteAnnex: bindActionCreators(orgAnnexActions.delete, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationAnnex)
