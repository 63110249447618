import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { subscriptionActions } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { getSpaceVirtualCurrencyName } from 'app/selectors'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBPopoverTop,
  KBCheckBox
} from 'components'
import * as constants from 'app/constants'
import SpaceAddMemberShipDiscountForm from './SpaceAddMemberShipDiscountForm'

var SpaceAddMemberShipDeskForm = React.createClass({
  getInitialState() {
    return {
      isLoading: false,
      allowExtraPurchase: false
    }
  },
  parseDiscountForm(model) {
    const { callback } = this.props
    this.setState({
      isLoading: true
    })
    callback && callback(model)
    KBPopover.close()
  },

  componentWillUnmount() {
    this.setState({
      isLoading: false
    })
  },
  updateDiscounts(model) {
    const {
      fields: { price, discounts }
    } = this.props
    model.price = price.value || '0'
    discounts.addField(model)
  },
  addDiscounts() {
    KBPopoverTop.show(
      <SpaceAddMemberShipDiscountForm
        quota_type="desk"
        isTop={true}
        callback={this.updateDiscounts}
      />
    )
  },

  changeAllowExtraPurchaseStatus(status) {
    this.setState({
      allowExtraPurchase: status
    })
  },

  render() {
    const {
      fields: {
        price,
        point,
        available_credits,
        accessible_areas,
        complimentary_point,
        timespans,
        discounts
      },
      error,
      submitting,
      handleSubmit,
      virtual_currency_name
    } = this.props
    const { isLoading, allowExtraPurchase } = this.state
    const oneClass = {
      className: 'kb-form-group kb-form-210 kb-form-alone'
    }

    return (
      <form onSubmit={handleSubmit(this.parseDiscountForm)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-header">
          <h2 className="header-title">添加工位额度</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBUserInput
              title="使用区域"
              {...oneClass}
              field={accessible_areas}
              multi={true}
              placeholder="可以使用哪个区域的工位"
              users={constants.MEETING_SHIP_AREA_TYPE}
            />
          </div>
          <div className="kb-row">
            <KBInput
              title="时间段"
              isRequired={true}
              label="true"
              placeholder="可使用的时间段例如：08:30-12:00"
              className="kb-form-group kb-form-210 kb-form-alone"
              field={timespans}
              value={timespans.value}
            />
          </div>
          {/* <div className='kb-row'>
            <KBInput title={ `${virtual_currency_name}购买` } isRequired = {true} label='true' placeholder={`用多少${virtual_currency_name}购买，默认是价格*100`} className = 'kb-form-group kb-form-c18 kb-form-alone' field={ point } value={ point.value } />
          </div> */}
          <div className="kb-row">
            <KBInput
              title="使用次数"
              type="number"
              isRequired={true}
              label="true"
              hint="输入该会员类型包含的工位免费使用次数"
              className="kb-form-group kb-form-210 kb-form-alone"
              inputClass="input-frequency"
              field={available_credits}
              value={available_credits.value}
            />
          </div>
          <div className="kb-row">
            <div className="kb-form-group kb-form-210 kb-form-alone">
              <label>额外购买</label>
              <div>
                <div style={{ display: 'flex', paddingTop: 9 }}>
                  <div className="d-iblock">
                    <KBCheckBox
                      checked={allowExtraPurchase}
                      callback={this.changeAllowExtraPurchaseStatus}
                    />
                  </div>
                  <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>
                    允许额外购买更多使用次数
                  </span>
                </div>
                {allowExtraPurchase ? (
                  <div className="kb-row kb-form-66">
                    <KBInput
                      title="价格"
                      type="number"
                      isRequired={true}
                      label="true"
                      placeholder="¥"
                      className="kb-form-group kb-form-c39 kb-form-alone"
                      inputClass="input-per-time"
                      field={price}
                      value={price.value}
                    />
                  </div>
                ) : null}
                {allowExtraPurchase ? (
                  <div className="kb-row">
                    <div className="kb-form-group kb-form-c18 kb-form-alone">
                      <label>优惠</label>
                      <div>
                        {discounts.map((discount, index) => {
                          return (
                            <div style={{ paddingTop: 9 }}>
                              <span>
                                满&nbsp;{discount.number.value || 0}
                                &nbsp;赠&nbsp;
                                {discount.complimentary_number.value || 0}
                              </span>
                              <a
                                className="color-link m-left-sm"
                                style={{ cursor: 'pointer' }}
                                onClick={e => {
                                  discounts.removeField(index)
                                }}
                              >
                                删除
                              </a>
                            </div>
                          )
                        })}
                        <div style={{ paddingTop: 9 }}>
                          <a
                            className="color-link"
                            style={{ cursor: 'pointer' }}
                            onClick={this.addDiscounts}
                          >
                            添加优惠
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <fieldset className='fieldset'>
            <legend>优惠信息</legend>
            <table className='content-table' style={{tableLayout : 'fixed', marginTop: 0}}>
              <thead>
                <tr>
                  <th>购买满多少</th>
                  <th>价格</th>
                  <th>{ virtual_currency_name }</th>
                  <th>赠送数量</th>
                  <th>可获得的{ virtual_currency_name }</th>
                </tr>
              </thead>
              <tbody>
                {
                  discounts.map(discount => {
                    return (
                      <tr>
                        <td>{ discount.number.value }</td>
                        <td>{ discount.price.value }</td>
                        <td>{ discount.point.value }</td>
                        <td>{ discount.complimentary_number.value }</td>
                        <td>{ discount.point.value }</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <button className="certain-btn m-top-sm" type='button' onClick={ this.addDiscounts }>添加</button>
          </fieldset> */}
          <div className="f-right">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <button className="certain-btn m-left-sm" type="submit">
              {isLoading ? '加载中...' : '确定'}
            </button>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  let virtual_currency_name = getSpaceVirtualCurrencyName(state)
  return {
    virtual_currency_name
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  // if (valid.isPrice(values.recurring_amount)) {
  //   errors.recurring_amount = '请输入正确的价格, 小数点保留2位'
  // }

  return errors
}

const formConfig = {
  form: 'SpaceAddMemberShipDeskForm',
  fields: [
    'price',
    'point',
    'available_credits',
    'accessible_areas',
    'timespans',
    'discounts[].number',
    'discounts[].price',
    'discounts[].complimentary_point',
    'discounts[].complimentary_number',
    'discounts[].point'
  ],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceAddMemberShipDeskForm)
