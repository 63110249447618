import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { Link } from 'react-router'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBRangeDate,
  KBDropdown,
  KBTableWithoutData,
  KBTipsy
} from 'components'
import { KBDropSelect } from 'components/plugins'
import { routerActions } from 'react-router-redux'
import { POINT_PLAN_CUSTOMER_TYPE, INVOICE_SALES_PAYMENTS } from 'app/constants'
import { connect } from 'react-redux'
import {
  paginate,
  formatMinuteSecondEN,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown
} from 'utils/kbUtil'
import moment from 'moment'
import * as selectors from 'app/selectors'
import {
  apiCallFailure,
  getLocationMembersAction,
  orgActions
} from 'app/actions'
import { getUserInfoUrl } from 'utils/kbUrl'

const DROP_DOWNS = [
  'reviewedDropdown',
  'location_idDropdown',
  'dateDropDown',
  'userIdDropDown',
  'teamIdDropDown'
]

var MembershipsPointsRecord = React.createClass({
  displayName: 'MembershipsPointsRecord',
  getInitialState() {
    const { filters, loc_id } = this.props
    return {
      pagination: null,
      pointList: [],
      loading: true,
      filters,
      loc_id,
      orgs: [],
      members: [],
      currentOrg: ''
    }
  },
  componentDidMount() {
    const { loc_id } = this.props
    this.loadData()
    this._setLocId(loc_id)
  },

  loadData(page = 1) {
    const { member_id, query, routerActions } = this.props
    const { filters } = this.state
    let params = Object.assign({}, { per_page: 10 }, { page: page }, filters)
    let { from, to } = params
    if (from && to) {
      params.from = from && moment(from).format('YYYY-MM-DD')
      params.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete params.from
      delete params.to
    }
    let queryFiltersStr = queryFilter({ ...query, ...params })
    api
      .getPointPlansLedgers(params)
      .then(({ json, response }) => {
        let pagination = paginate(response)
        routerActions.replace(
          `/admin/memberships/memberManage/pointsRecord${queryFiltersStr}`
        )
        this.setState({
          pointList: json,
          pagination
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.loadData(nextProps.page)
    }
  },

  changeDate(range) {
    this._setFilter(range)
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    Object.keys(obj).map(key => {
      if (!obj[key]) {
        delete newFilters[key]
      }
    })
    this.setState({ filters: newFilters })
    this['reviewedDropdown'] && this['reviewedDropdown'].hide()
    // force refresh
    this._refresh()
  },

  _refresh() {
    setTimeout(this.loadData)
  },

  _clearFilters() {
    this.setState({ filters: {}, loc_id: null, orgs: [], members: [] })
    // force refresh
    this._refresh()
  },

  _setLocId(id) {
    const { getOrganizationsAction, getLocationMembersAction } = this.props
    let orgs, members
    // get orgs
    this['location_idDropdown'] && this['location_idDropdown'].hide()
    api
      .getOrganizations({
        location_id: id,
        per_page: 1000,
        organization_type: 'normal'
      })
      .then(
        res => {
          getOrganizationsAction.success(res)
          orgs = res.json.entities.organizations
        },
        err => {
          getOrganizationsAction.failure(err)
        }
      )
      .finally(() => {
        this.setState({
          orgs: orgs
        })
      })
    // get members
    api
      .getUsers({ location_id: id, per_page: 1000, includes: 'space_users' })
      .then(
        ({ json, response }) => {
          getLocationMembersAction.success(json)
          members = json.entities.users
        },
        err => {
          getLocationMembersAction.failure(err)
        }
      )
      .finally(() => {
        this.setState({
          members: members
        })
      })

    this.setState({
      loc_id: id
    })
  },

  selectOrgUserCallBack(data) {
    this._setFilter({
      sales_customer_id:
        data && data.sales_customer_id ? data.sales_customer_id : ''
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  render() {
    const { pointList, loading, filters } = this.state
    const { from, to } = filters
    const { locations, virtual_currency_name } = this.props
    return (
      <section className="kb-content-container">
        <header className="nav-header">
          <div className=" clear-fix">
            <div className="nav-section-header-title f-left">
              <span>{virtual_currency_name}记录</span>
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          <div className="m-top-sm">
            <KBRangeDate
              format="YYYY/MM/DD"
              from={from || ''}
              to={to || ''}
              callback={this.changeDate}
              ref={ref => {
                this.dateDropDown = ref
              }}
              dropDown_name="dateDropDown"
              closeClick={this.closeDropDowns}
              leftNone={true}
            />

            <KBDropdown
              ref={ref => {
                this.reviewedDropdown = ref
              }}
              style={{ margin: '0 20px' }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'reviewedDropdown')}
                >
                  <span>
                    {typeof this.state.filters.customer_type == 'string'
                      ? this.state.filters.customer_type == 'org'
                        ? '公司'
                        : '个人'
                      : '账号类型'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    {POINT_PLAN_CUSTOMER_TYPE.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ customer_type: state.id })
                          }
                        >
                          <span>{state.name}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>

            {this.state.filters.customer_type ? (
              <KBDropdown
                ref={ref => {
                  this.location_idDropdown = ref
                }}
              >
                <KBDropdown.KBDropdownTrigger>
                  <div
                    className="task-status-title"
                    onClick={this.closeDropDowns.bind(
                      null,
                      'location_idDropdown'
                    )}
                  >
                    <i className="iconfont icon-position" />
                    <span>
                      {this.state.loc_id && Object.keys(locations).length > 0
                        ? locations[this.state.loc_id].name
                        : '选择分店'}
                    </span>
                    <span className="task-deg" />
                  </div>
                </KBDropdown.KBDropdownTrigger>
                <KBDropdown.KBDropdownContent>
                  <div>
                    <ul className="task-type">
                      {locations &&
                        Object.keys(locations).map((location_id, index) => {
                          let location = locations[location_id]
                          return (
                            <li
                              key={index}
                              onClick={() => this._setLocId(location.id)}
                            >
                              <span>{location.name}</span>
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </KBDropdown.KBDropdownContent>
              </KBDropdown>
            ) : null}

            {this.state.loc_id &&
            this.state.filters.customer_type &&
            this.state.filters.customer_type == 'org' ? (
              <div
                style={{ display: 'inline-block', marginLeft: 20 }}
                className="org"
              >
                <KBDropSelect
                  ref={ref => {
                    this.teamIdDropDown = ref
                  }}
                  options={{ defaultSingleContent: '选择公司' }}
                  defaultData={this.state.orgs}
                  multi={false}
                  showImg={false}
                  dropDown_name="teamIdDropDown"
                  closeClick={this.closeDropDowns}
                  callback={this.selectOrgUserCallBack}
                  style={{ marginLeft: 0 }}
                />
              </div>
            ) : this.state.loc_id &&
              this.state.filters.customer_type &&
              this.state.filters.customer_type == 'user' ? (
              <div style={{ display: 'inline-block', marginLeft: 20 }}>
                <KBDropSelect
                  ref={ref => {
                    this.userIdDropDown = ref
                  }}
                  options={{ defaultSingleContent: '选择会员' }}
                  defaultData={this.state.members}
                  multi={false}
                  showImg={false}
                  dropDown_name="userIdDropDown"
                  closeClick={this.closeDropDowns}
                  callback={this.selectOrgUserCallBack}
                  style={{ marginLeft: 0 }}
                />
              </div>
            ) : null}

            <div style={{ marginTop: 5 }}>
              {Object.keys(this.state.filters).length > 0 ? (
                <div
                  className="clear-criteria"
                  style={{ marginTop: 5 }}
                  onClick={this._clearFilters}
                >
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
          </div>
          <KBLoadingContainer loading={loading}>
            <table className="content-table">
              <thead>
                <tr>
                  <th>账号名称</th>
                  <th>基础余额</th>
                  <th>赠送余额</th>
                  <th>支付现金</th>
                  <th>支付方式</th>
                  <th>交易时间</th>
                  <th>操作人</th>
                  <th>备注</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={pointList.length > 0}
                tableHeadNum="9"
                tipMessage="暂无信息"
              >
                {pointList &&
                  pointList.map((point, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <KBTipsy
                            content={
                              point.customer &&
                              point.customer.foreign_type === 'org'
                                ? '公司'
                                : '个人'
                            }
                          >
                            <i
                              className={`iconfont icon-${
                                point.customer &&
                                point.customer.foreign_type === 'org'
                                  ? 'company2'
                                  : 'account_circle'
                              }`}
                              style={{
                                marginRight: 5,
                                fontSize: '16px',
                                color: '#999'
                              }}
                            />
                          </KBTipsy>
                          {(point.customer && point.customer.name) || ''}
                        </td>
                        <td>
                          {point.outgoings == 0 ? (
                            <span style={{ color: '#25c324' }}>
                              +{point.incomings}
                            </span>
                          ) : (
                            <span style={{ color: '#ff0000' }}>
                              -{point.outgoings}
                            </span>
                          )}
                        </td>
                        <td>
                          {point.gift_outgoings == 0 ? (
                            <span style={{ color: '#25c324' }}>
                              +{point.gift_incomings}
                            </span>
                          ) : (
                            <span style={{ color: '#ff0000' }}>
                              -{point.gift_outgoings}
                            </span>
                          )}
                        </td>
                        <td>
                          {point.payment_mode === 'point'
                            ? ''
                            : `${point.paid_amount ? point.paid_amount : 0} 元`}
                        </td>
                        <td>
                          {point.payment_mode
                            ? INVOICE_SALES_PAYMENTS[point.payment_mode]
                            : '暂无'}
                        </td>
                        <td>{formatMinuteSecondEN(point.created_at)}</td>
                        <td>
                          {point.operator ? (
                            <div className="vf-center">
                              <KBAvatar
                                user={point.operator}
                                imgStyle={{ marginRight: 5 }}
                                style={{
                                  display: 'inline-block',
                                  marginRight: '5px'
                                }}
                                size={30}
                              />
                              <Link
                                to={getUserInfoUrl(point.operator.id)}
                                className="color-link"
                              >
                                {point.operator.name || ''}
                              </Link>
                            </div>
                          ) : null}
                        </td>
                        <td>{point.note}</td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={this.state.pagination}
              template={`/admin/memberships/memberManage/pointsRecord?page=#PAGE#`}
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location, loc_id } = props
  let page = parseInt(location.query && location.query.page) || 1

  let locations = selectors.getSerializeLocationObj(state)

  var FILTER_STATE = ['from', 'to', 'customer_type']
  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)

  return {
    page,
    locations,
    virtual_currency_name,
    ...paramsAll
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getLocationMembersAction: bindActionCreators(
      getLocationMembersAction,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    getOrganizationsAction: bindActionCreators(orgActions.get, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipsPointsRecord)
