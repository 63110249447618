import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import { connect } from 'react-redux'
import { checkObject, getEntitiesJsonArray } from 'utils/kbUtil'
import { KBPopover } from 'components'
import * as constants from 'app/constants'
import * as selectors from 'app/selectors'
import ServiceForm from './ServiceForm'

var ProviderServices = React.createClass({
  displayName: 'ProviderServices',
  addService() {
    const { serviceProvider } = this.props
    KBPopover.show(<ServiceForm serviceProvider={serviceProvider} />, {
      closeOnOutsideClick: false
    })
  },
  editService(service) {
    const { serviceProvider } = this.props

    KBPopover.show(
      <ServiceForm
        serviceProvider={serviceProvider}
        state="update"
        service={service}
        initialValues={{
          ...service
        }}
      />
    )
  },
  componentDidMount() {
    const { type, routerActions, serviceProvider } = this.props

    this.mounted = true

    if (type == 'service') {
      this.addService()
      // 防止用户点击过快时路由来回跳转
      if (!this.mounted) {
        return
      }
      routerActions.replace(
        `/admin/service/provider/${serviceProvider.id}/detail`
      )
    }
  },

  componentWillUnmount() {
    this.mounted = false
  },

  deleteService(service) {
    const { deleteServiceAction, serviceProvider } = this.props
    const id = service.id
    KBPopover.plugins.confirm('删除服务', '确定要删除该服务商?', {
      confirm: () => {
        api.deleteService(serviceProvider.id, id).then(
          json => {
            deleteServiceAction.success(json, { id, key: 'services' })
            KBPopover.close()
          },
          errors => {
            deleteServiceAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  switchServiceEnable(service) {
    const { updateServiceAction, serviceProvider, successAction } = this.props
    const is_enabled = service.is_enabled
    api.putServiceEnabled(serviceProvider.id, service.id).then(
      json => {
        updateServiceAction.success(json)
      },
      error => {
        updateServiceAction.failure(error)
      }
    )
  },

  render() {
    const { services, serviceCategoriesArray } = this.props

    return (
      <div>
        {services.length
          ? services.map((service, index) => {
              return (
                <div
                  key={index}
                  className={`kb-section border-full ${
                    service.is_enabled ? '' : 'close-service'
                  }`}
                  style={{ padding: 20, margin: '20px 0 0 0' }}
                >
                  <ul>
                    <li style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="section-title">服务分类:</span>
                      <span
                        className="section-title-content"
                        style={{ flex: 1 }}
                      >
                        {
                          serviceCategoriesArray[
                            service.service_parent_category_id
                          ].name
                        }
                        －{service.serviceCategory.name}
                      </span>
                      <div className="service-setting">
                        <span
                          className="bordered-btn"
                          style={{ padding: '5px 10px' }}
                          onClick={this.editService.bind(null, service)}
                        >
                          修改
                        </span>
                        <span
                          className="bordered-btn"
                          style={{ margin: '0 10px', padding: '5px 10px' }}
                          onClick={this.deleteService.bind(null, service)}
                        >
                          删除
                        </span>
                        <div className="service-status">
                          <span>启用</span>
                          <div
                            className="slide-btn"
                            onClick={this.switchServiceEnable.bind(
                              null,
                              service
                            )}
                          >
                            <span
                              className={`slide-bg ${
                                service.is_enabled
                                  ? 'slide-left'
                                  : 'slide-right'
                              }`}
                            >
                              <span className="slide-move" />
                            </span>
                          </div>
                          <span>关闭</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <span
                        className="section-title f-left"
                        style={{ marginLeft: 4 }}
                      >
                        服务说明:
                      </span>
                      <span
                        className="section-title-content"
                        style={{ maxWidth: 700 }}
                      >
                        {service.description}
                      </span>
                    </li>
                    <li>
                      <span
                        className="section-title f-left"
                        style={{ marginLeft: 4 }}
                      >
                        使用条件:
                      </span>
                      <span
                        className="section-title-content"
                        style={{ maxWidth: 700 }}
                      >
                        {service.conditions}
                      </span>
                    </li>
                    <li>
                      <span className="section-title" style={{ marginLeft: 4 }}>
                        公开价格:
                      </span>
                      <span
                        className="section-title-content"
                        style={{ maxWidth: 700 }}
                      >
                        {service.list_price}
                      </span>
                    </li>
                    <li>
                      <span className="section-title" style={{ marginLeft: 4 }}>
                        优惠内容:
                      </span>
                      <span
                        className="section-title-content"
                        style={{ maxWidth: 700 }}
                      >
                        {service.member_price}
                      </span>
                    </li>
                  </ul>
                </div>
              )
            })
          : ''}
        <div style={{ padding: '20px 0' }}>
          <button
            className="bordered-btn"
            style={{ margin: 0 }}
            onClick={this.addService}
          >
            +添加
          </button>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  //一级分类
  const serviceCategoriesArray = state.entities.serviceCategories

  const { serviceProvider, location } = props
  var services = Object.assign(
    [],
    selectors.getServiceOfProviderId(state, serviceProvider.id)
  )
  services.map(service => {
    service.serviceCategory = selectors.getSubCategoriesOfId(
      state,
      service.service_category_id
    )
    return service
  })
  return {
    services,
    serviceCategoriesArray
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteServiceAction: bindActionCreators(
      actions.servicesAction.delete,
      dispatch
    ),
    updateServiceAction: bindActionCreators(
      actions.servicesAction.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

ProviderServices.propTypes = {
  serviceProvider: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderServices)
