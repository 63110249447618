import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import {
  lockPermissionsActions,
  lockGroupsAction,
  apiCallFailure
} from 'app/actions'
import * as selectors from 'app/selectors'
import {
  KBPopover,
  KBPagination,
  KBLoadingContainer,
  KBTableWithoutData,
  KBAvatar
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { paginate, formatHoursMinutesCHS } from 'utils/kbUtil'
import LocksPermissionsUpdateForm from '../members/LocksPermissionsUpdateForm'
import LocksPermissionsForm from '../members/LocksPermissionsForm'
import { canAccessLocation } from 'app/reducers/role'
import moment from 'moment'
import _ from 'lodash'
import LockGroupForm from './LockGroupForm'
import { getUserInfoUrl } from 'utils/kbUrl'

var LockGroupDetail = React.createClass({
  displayName: 'LockGroupDetail',

  getInitialState() {
    return {
      currentTab: 'locks',
      pagination: null
    }
  },

  componentDidMount() {
    this.mounted = true

    const { group_id, lockGroup, locks } = this.props
    if (!lockGroup) {
      this._loadLockGroupInfo(group_id)
    }
    this._loadLockGroupPermissions()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page !== nextProps.page) {
      this._loadLockGroupPermissions(nextProps.page)
    }
  },

  _loadLockGroupInfo(lock_group_id) {
    const { updateLockGroupAction } = this.props

    api.getLockGroup(lock_group_id).then(
      json => {
        updateLockGroupAction.success(json, {
          lock_group_id,
          key: 'lockGroups'
        })
      },
      errors => {
        updateLockGroupAction.failure(errors)
      }
    )
  },

  _loadLockGroupPermissions(page) {
    const { group_id, location_id, getLocksPermissionsAction } = this.props
    const per_page = 10
    let pageObj = { per_page, page: page || 1 }
    let params = Object.assign({}, pageObj)

    api
      .getLockPermissions({ location_id, lock_group_id: group_id, ...params })
      .then(
        json => {
          let pagination = paginate(json.response)
          if (this.mounted) {
            this.setState({
              pagination
            })
          }
          getLocksPermissionsAction.success(json, {
            entityName: 'lockPermissions'
          })
        },
        errors => {
          getLocksPermissionsAction.failure(errors)
        }
      )
  },

  updateLocksPermissions(data) {
    const { location_id } = this.props
    let startAt = moment.unix(data.start_at).toDate()
    let endAt = moment.unix(data.end_at).toDate()
    KBPopover.show(
      <LocksPermissionsUpdateForm
        loc_id={location_id}
        initialValues={{
          lock_permissions: [
            {
              id: data.id,
              startYMD: this.setYMD(startAt),
              endYMD: this.setYMD(endAt),
              start_at: startAt,
              end_at: endAt,
              startHM: this.setHM(startAt),
              endHM: this.setHM(endAt)
            }
          ]
        }}
      />
    )
  },

  addLockPermissions() {
    const { location_id, lock_id } = this.props
    let initValue = {}
    initValue.lock_permissions = [
      {
        startYMD: new Date(),
        endYMD: new Date(),
        lock_id: lock_id,
        startHM: '9,1',
        endHM: '9,1',
        start_at: moment(new Date())
          .clone()
          .set({ hour: 9, minute: 0, second: 0 }),
        end_at: moment(new Date())
          .clone()
          .set({ hour: 9, minute: 0, second: 0 })
      }
    ]
    KBPopover.show(
      <LocksPermissionsForm
        initialValues={initValue}
        loc_id={location_id}
        operateType="create"
      />
    )
  },

  setYMD(value) {
    let date = moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    return moment(value)
      .clone()
      .set({ year, month, date: day })
      .toDate()
  },

  setHM(value) {
    let date = moment(value)
    let hour = date.get('hour')
    let minute = date.get('minute')
    let processHour = ''
    let processMinute = ''

    processHour = hour

    if (minute == 30) {
      processMinute = 2
    } else {
      processMinute = 1
    }

    return processHour + ',' + processMinute
  },

  deleteLocksPermissions(lock) {
    const { location_id, deleteLocksPermissionsAction } = this.props
    let idArray = []
    idArray.push(lock.id)
    KBPopoverConfirm({
      name: '删除门禁权限',
      context: '是否要删除门禁 ' + lock.lock.name + ' 的权限',
      callback: () => {
        return api.deleteLockPermissions(location_id, { ids: idArray }).then(
          json => {
            deleteLocksPermissionsAction.success(json, {
              id: lock.id,
              key: 'lockPermissions'
            })
            KBPopover.close()
          },
          error => {
            deleteLocksPermissionsAction.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  toMemberInfo(json) {
    const { routerActions } = this.props
    const { user_id, organization_id } = json
    organization_id && routerActions.push(getUserInfoUrl(user_id))
  },

  deleteLockGroup() {
    const {
      deleteLockGroupAction,
      lockGroup,
      routerActions,
      location_id
    } = this.props
    let id = lockGroup.id
    KBPopoverConfirm({
      name: '删除门禁组',
      context: `确定要删除名称为：${lockGroup.name} 的门禁组?`,
      callback: () => {
        return api.deleteLockGroup(id).then(
          json => {
            deleteLockGroupAction.success(json, { id, key: 'lockGroups' })
            routerActions.push(
              `/admin/locations/${location_id}/locks/lock_info/lock_group`
            )
            KBPopover.close()
          },
          errors => {
            deleteLockGroupAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  updateLockGroup() {
    const { lockGroup, location_id, locks } = this.props
    let uniquedLocks = _.pullAllBy(locks, lockGroup.locks, 'id')
    let filterLocks = uniquedLocks.filter(
      lock => lock.location_id == location_id
    )
    KBPopover.show(
      <LockGroupForm
        type="update"
        loc_id={location_id}
        locks={filterLocks}
        lockGroup={lockGroup}
      />
    )
  },

  deleteSingleLock(lock) {
    const { updateLockGroupAction, lockGroup } = this.props
    let id = lockGroup.id
    let params = {}
    params.lock_ids = lockGroup.locks
      .filter(l => l.id != lock.id)
      .map(l => l.id)
      .join(',')
    KBPopoverConfirm({
      name: '删除门禁',
      context: `您将要删除门禁组：${lockGroup.name} 下的门禁：${lock.name}，门禁组添加或减少门禁，已赋予的门禁组权限人员也会相应的修改，是否确认删除?`,
      callback: () => {
        return api.putLockGroup(id, params).then(
          json => {
            updateLockGroupAction.success(json, { id, key: 'lockGroups' })
            KBPopover.close()
          },
          errors => {
            updateLockGroupAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  render() {
    const {
      locksPermissionsUser,
      location_id,
      group_id,
      lockGroup,
      isManager
    } = this.props
    const { pagination, currentTab } = this.state

    return (
      <section className="nav-section-container  f-right">
        <KBLoadingContainer loading={!lockGroup}>
          <header
            className="nav-header clear-fix"
            style={{ borderBottom: 'none' }}
          >
            <h2 className="nav-section-header-title f-left">
              <span>{lockGroup && lockGroup.name}</span>
            </h2>
            {isManager ? (
              <button
                className="f-right bordered-btn-end"
                onClick={e => {
                  this.deleteLockGroup()
                  e.stopPropagation()
                }}
              >
                删除
              </button>
            ) : (
              ''
            )}
            {isManager ? (
              <button
                className="f-right bordered-btn"
                onClick={e => {
                  this.updateLockGroup()
                  e.stopPropagation()
                }}
              >
                编辑
              </button>
            ) : (
              ''
            )}
          </header>
          <div className="nav-section-content-container">
            <ul className="nav-section-tab">
              <li
                className={`${
                  currentTab === 'locks' ? 'nav-section-tab-active' : ''
                }`}
                onClick={e => {
                  this.setState({ currentTab: 'locks' })
                }}
              >
                <a>关联门禁</a>
              </li>
              <li
                className={`${
                  currentTab === 'permissions' ? 'nav-section-tab-active' : ''
                }`}
                onClick={e => {
                  this.setState({ currentTab: 'permissions' })
                }}
              >
                <a>人员权限</a>
              </li>
            </ul>
            {currentTab === 'locks' ? (
              <div>
                <table
                  className="content-table"
                  style={{ tableLayout: 'fixed' }}
                >
                  <thead>
                    <tr>
                      <th>门禁编号</th>
                      <th>门禁名称</th>
                      <th>所在区域</th>
                      <th className="t-right">操作</th>
                    </tr>
                  </thead>
                  <KBTableWithoutData
                    hasData={lockGroup && lockGroup.locks.length > 0}
                    tableHeadNum="4"
                    tipMessage="暂无关联门禁设备"
                  >
                    {lockGroup &&
                      lockGroup.locks.map((lock, index) => {
                        return (
                          <tr key={index}>
                            <td>{lock.device_id}</td>
                            <td>{lock.name}</td>
                            <td>
                              {lock.is_gate
                                ? '大门'
                                : lock.area
                                ? lock.area.name
                                : '无'}
                            </td>
                            <td className="t-right">
                              {isManager ? (
                                <i
                                  className="iconfont icon-font icon-delete"
                                  onClick={e => {
                                    this.deleteSingleLock(lock)
                                    e.stopPropagation()
                                  }}
                                />
                              ) : null}
                            </td>
                          </tr>
                        )
                      })}
                  </KBTableWithoutData>
                </table>
              </div>
            ) : null}
            {currentTab === 'permissions' ? (
              <div>
                <table className="content-table">
                  <thead>
                    <tr>
                      <th>会员</th>
                      <th>公司</th>
                      <th>门禁名称</th>
                      <th>开始时间</th>
                      <th>结束时间</th>
                      <th />
                    </tr>
                  </thead>
                  <KBTableWithoutData
                    hasData={locksPermissionsUser.length > 0}
                    tableHeadNum="5"
                    tipMessage="暂无会员权限"
                  >
                    {locksPermissionsUser.map((json, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {json.user ? (
                              <div className="vf-center">
                                <KBAvatar
                                  user={json.user}
                                  imgStyle={{ marginRight: 5 }}
                                  style={{
                                    display: 'inline-block',
                                    marginRight: '5px'
                                  }}
                                  size={30}
                                />
                                <Link
                                  to={getUserInfoUrl(json.user.id)}
                                  className="color-link"
                                >
                                  {json.user.name || ''}
                                </Link>
                              </div>
                            ) : null}
                          </td>
                          <td>{json.organization && json.organization.name}</td>
                          <td>{json.lock && json.lock.name}</td>
                          <td>{formatHoursMinutesCHS(json.start_at)}</td>
                          <td>{formatHoursMinutesCHS(json.end_at)}</td>
                          {isManager ? (
                            <td className="t-right">
                              <i
                                className="iconfont icon-edit a-hover"
                                onClick={e => {
                                  e.stopPropagation()
                                  this.updateLocksPermissions(json)
                                }}
                              />
                              <i
                                className="iconfont icon-delete m-left-sm a-hover"
                                onClick={e => {
                                  e.stopPropagation()
                                  this.deleteLocksPermissions(json)
                                }}
                              />
                            </td>
                          ) : null}
                        </tr>
                      )
                    })}
                  </KBTableWithoutData>
                </table>
                <KBPagination
                  pagination={pagination}
                  template={`/admin/locations/${location_id}/locks/lock_info/lockGroup/${group_id}?page=#PAGE#`}
                />
              </div>
            ) : null}
          </div>
        </KBLoadingContainer>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { user } = state
  const group_id = params.group_id
  const location_id = params.id
  const lockGroup = selectors.getLockGroupInfo(state, group_id)
  const locksPermissionsUser = selectors.getLocksPermissions(state)
  const locks = selectors.getLocks(state)
  let isManager = canAccessLocation(user, location_id)
  let page = parseInt(location.query && location.query.page, 10)
  page = isNaN(page) ? 1 : page

  return {
    group_id,
    lockGroup,
    location_id,
    locksPermissionsUser,
    locks,
    page,
    isManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLockGroupAction: bindActionCreators(
      lockGroupsAction.update,
      dispatch
    ),
    deleteLockGroupAction: bindActionCreators(
      lockGroupsAction.delete,
      dispatch
    ),
    getLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.replace,
      dispatch
    ),
    deleteLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.delete,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockGroupDetail)
