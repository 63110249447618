import React, { Component, PropTypes } from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { getSpaceVirtualCurrencyName, getOrg } from 'app/selectors'
import { orgActions } from 'app/actions'
import { KBLoadingContainer, KBAvatar } from 'components'
import { isListValue, getListFirstValue } from 'utils/kbUtil'

var OrgDetailBox = React.createClass({
  displayName: 'OrgDetailBox',
  getInitialState() {
    return {
      loading: true,
      lockEvents: []
    }
  },
  componentDidMount() {
    this.loadOrganizationInfo()
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.org_id !== this.props.org_id) {
      this.loadOrganizationInfo(nextProps.org_id)
    }
  },

  loadOrganizationInfo(organization_id) {
    let { org_id, updateOrganizationsAction } = this.props

    api
      .getOrganizationInfo(org_id, {
        includes: 'details,users,balances,opportunities'
      })
      .then(
        json =>
          updateOrganizationsAction.success(json, {
            organization_id: organization_id || org_id
          }),
        errors => updateOrganizationsAction.failure(errors)
      )
      .then(() => {
        this.setState({
          loading: false
        })
      })
  },

  render() {
    const { loading } = this.state
    const { organization } = this.props
    const { section, org_id, menu } = this.props
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        loadOrganizationInfo: this.loadOrganizationInfo,
        ...this.props
      })
    )
    return (
      <KBLoadingContainer loading={loading} className={`kb-out-box clear-fix`}>
        {organization ? (
          <section
            className="kb-content clear-fix"
            style={{ marginTop: 0, paddingTop: 30 }}
          >
            <nav className="nav-container f-left">
              <div className="nav-container-content">
                <header className="nav-header clear-fix">
                  <div className="member-box">
                    <KBAvatar
                      user={organization}
                      size={50}
                      style={{ marginLeft: '57.5px' }}
                    />
                    <span
                      className="member_name"
                      style={{ fontSize: '20px', paddingTop: '10px' }}
                    >
                      {organization.name}
                    </span>
                    <span className="member_name">
                      {organization.full_name}
                    </span>
                  </div>
                </header>
                <div className="nav-body">
                  <ul className="nav-container-list">
                    {menu.map((data, index) => {
                      return (
                        <li
                          key={index}
                          className={
                            isListValue(data.value, section)
                              ? 'nav-container-list-hover'
                              : ''
                          }
                        >
                          <Link
                            to={`/admin/organizations/${org_id}/${getListFirstValue(
                              data.value
                            )}`}
                          >
                            {' '}
                            {data.name}{' '}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </nav>
            {childrenWithProps}
          </section>
        ) : (
          <div style={{ padding: 30, textAlign: 'center', fontSize: 20 }}>
            暂无当前公司
          </div>
        )}
      </KBLoadingContainer>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let org_id = params.org_id
  let sections = location.pathname.split('/')
  let section = sections.length > 4 ? sections[4] : ''
  let virtual_currency_name = getSpaceVirtualCurrencyName(state)
  let organization = getOrg(state, org_id)

  const menu = [
    {
      name: '公司概况',
      value: ['']
    },
    {
      name: '员工',
      value: ['members']
    }
  ]

  return {
    org_id,
    section,
    virtual_currency_name,
    organization,
    menu
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    updateOrganizationsAction: bindActionCreators(orgActions.update, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgDetailBox)
