import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { KBPopover } from 'components'
import MeetingChangeTimeForm from './MeetingChangeTimeForm'
import {
  AREATYPES_CLASS,
  RESERVATION_PROGRESS_STATUS,
  RESERVATION_PROGRESS_CLASS
} from 'app/constants'
import moment from 'moment'

var InvoiceList = React.createClass({
  closePopup() {
    const { closePopup } = this.props
    closePopup && closePopup()
  },

  changeMeetingTime() {
    const { reservation_time_range, reservation } = this.props
    KBPopover.show(
      <MeetingChangeTimeForm
        duration={reservation.duration_in_hours}
        start_at={reservation.start_at}
        reservation_time_range={reservation_time_range}
        reservation_id={reservation.id}
        initialValues={{
          start_at: reservation.start_at
        }}
      />
    )
  },

  render() {
    const {
      reservation,
      isManager,
      cancelMeeting,
      cancelBooking,
      defaultDate,
      isShowMeetingPopup,
      topType
    } = this.props
    const { distanceLeft, meetingIndex } = reservation
    let topNum = topType ? meetingIndex : meetingIndex + 1
    let PopupTop = topNum * 26 + (meetingIndex - 1) * 15 + 10

    return isShowMeetingPopup ? (
      <div
        className="meeting-popup"
        style={{ top: PopupTop, left: distanceLeft + reservation.width / 2 }}
      >
        <header className="meeting-header">
          <h2 className="header-title">
            <span style={{ maxWidth: 150 }}>
              {reservation.organization_name || reservation.host.name || ''}
            </span>
            <br />
            <span
              className={`badge ${
                RESERVATION_PROGRESS_CLASS[reservation.progress]
              }`}
            >
              {RESERVATION_PROGRESS_STATUS[reservation.progress]}
            </span>
          </h2>
          <i className="iconfont icon-cancel" onClick={this.closePopup} />
        </header>
        <ul className="subscribe-details">
          <li>
            <i className="iconfont icon-account_circle" />
            <span>{reservation.contacts_name}</span>
          </li>
          <li>
            <i className="iconfont icon-phone" />
            <span>{reservation.contacts_phone}</span>
          </li>
          <li>
            <i className="iconfont icon-timer" />
            <span>
              {moment(reservation.start_at).format('MM月DD日')}&nbsp;&nbsp;{' '}
              {moment(reservation.start_at).format('HH:mm')}-
              {moment(reservation.end_at).format('HH:mm')}
            </span>
          </li>
        </ul>
        {isManager ? (
          <footer className="meeting-footer">
            {/* {reservation.progress == 'upcoming' ? (
              <span
                className="certain-btn-ms"
                style={{ marginRight: 10 }}
                onClick={this.changeMeetingTime}
              >
                更改时间
              </span>
            ) : (
              ''
            )} */}
            {/* {reservation.progress != 'ended' ? (
              <span
                className="certain-btn-ms"
                style={{ marginRight: 10 }}
                onClick={() => {
                  cancelMeeting(reservation)
                }}
              >
                结束会议
              </span>
            ) : (
              ''
            )} */}
            {moment(reservation.end_at).diff(new Date(), 'hours', true) >= 0 ? (
              <span
                className="certain-btn-ms"
                onClick={() => {
                  cancelBooking(reservation)
                }}
              >
                取消预约
              </span>
            ) : (
              ''
            )}
          </footer>
        ) : (
          ''
        )}
      </div>
    ) : (
      <div></div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList)
