import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { reservationsActions } from 'app/actions'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import {
  KBLoadingContainer,
  KBPopover,
  KBRangeDate,
  KBPagination,
  KBTableWithoutData,
  KBAvatar
} from 'components'
import {
  RESERVATION_PROGRESS_STATUS,
  // RESERVATION_PROGRESS_CLASS
} from 'app/constants'
import {
  getEntitiesJsonArray,
  paginate,
  queryFilter,
  getFilterAndQuery,
  formatYearDayEN,
  formatMinuteSecondEN,
  kbCloseDropDown,
  formatOnlyMinuteSecondEN
} from 'utils/kbUtil'
import moment from 'moment'
import { KBDayPicker } from 'components/views'
import { KBDropSelect } from 'components/plugins'
// import MeetingSearchRoomForm                                        from './MeetingSearchRoomForm'
import * as selectors from 'app/selectors'
import { canAccessLocation } from 'app/reducers/role'
import { getInitialDate } from 'utils/kbData'
import _ from 'lodash'
import { getUserInfoUrl, getMeetingRoomDetailPage } from 'utils/kbUrl'

const DROP_DOWNS = ['dateDropDown', 'meeting_room']

let meetingTimeUnitObj = {
  number: '小时',
  infinity: '',
  per_desk: '小时/工位'
}

var OrganizationMeeting = React.createClass({
  displayName: 'OrganizationMeeting',
  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      reservations: [],
      filters
    }
  },
  getDefaultProps() {
    return {}
  },

  getParamsThree() {
    return 'area_id'
  },

  componentDidMount() {
    const { page, filters } = this.props
    this.mounted = true
    this._loadData(filters, page)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(newFilters, page) {
    const {
      loc_id,
      org_id,
      getReservationsAction,
      routerActions,
      query
    } = this.props
    const preFilters = this.props.filters
    const { from, to } = newFilters
    let params = Object.assign(
      {},
      preFilters,
      newFilters,
      { page: page },
      { organization_id: org_id },
      { state: 'all' },
      { per_page: 10 },
      { location_id: loc_id }
    )

    if (from && to) {
      params.start_at = from && moment(from).format('YYYY-MM-DD')
      params.end_at = to && moment(to).format('YYYY-MM-DD')
    }
    delete params.from
    delete params.to
    let queryFiltersStr = queryFilter({ ...query, ...params, page: page })

    // 获取会议室预定列表
    api
      .getReservations(params)
      .then(
        json => {
          getReservationsAction.success(json)
          let reservations = []
          reservations = getEntitiesJsonArray(json, 'reservations')
          let pagination = paginate(json.response)

          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/locations/${loc_id}/organizations/${org_id}/meeting${queryFiltersStr}`
          )

          this.setState({
            reservations,
            pagination
          })
        },
        errors => {
          getReservationsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page != nextProps.page) {
      this._loadData({ ...this.state.filters, ...filters }, nextProps.page)
    }
  },
  changeDate(range) {
    this._setFilter(null, null, range)
  },

  changeDayPicker(date) {
    const { loc_id } = this.props
    this._setFilter('time', date)
  },

  selectCallBack(data) {
    if (!data) {
      this._removeFilter('area_id')
      return
    }

    this._setFilter('area_id', data && data.id)
  },

  _removeFilter(key) {
    let newFilters = _.omit(this.state.filters, [key])
    this.setState({ filters: newFilters })
    // force refresh
    this._refresh(key, newFilters)
  },

  _setFilter(key, value, obj) {
    let newFilters = Object.assign(
      {},
      this.state.filters,
      obj ? obj : { [key]: value }
    )
    this.setState({ filters: newFilters, loading: true })

    this._refresh(key, newFilters)
  },
  _refresh(key, filters) {
    const {
      tasks_type,
      page,
      newTask,
      allTasksAction,
      location_id,
      query,
      routerActions
    } = this.props
    let dropDown = key + 'Dropdown'
    this[dropDown] && this[dropDown].hide()
    this._loadData(filters, 1)
  },

  clearFilter() {
    this._clearFilters()
    this.meeting_room.clearData()
  },

  _clearFilters() {
    const filters = { filters: {}, loading: true }
    this.setState(filters)
    this.props.filters = {}
    this._loadData({}, 1)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  render() {
    const {
      loc_id,
      org_id,
      meetings,
      orgs,
      isManager,
      org,
      currentArea,
      meetingLimitation
    } = this.props
    const { reservations, pagination, filters, loading } = this.state

    const meeting_room_remain = org.meeting_room_usage
      ? org.meeting_room_usage.meeting_room_remain
      : ''
    const meeting_room_used = org.meeting_room_usage
      ? org.meeting_room_usage.meeting_room_used
      : ''
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')

    const { from, to, start_at, end_at } = this.state.filters

    // const RESERVATION_STATES_OBJ = {
    //   reserved: {
    //     name: '已预订',
    //     class: 'finance-default receipt-going'
    //   },
    //   active: {
    //     name: '进行中',
    //     class: 'finance-default receipt-finish'
    //   },
    //   cancelled: {
    //     name: '已取消',
    //     class: 'finance-default receipt-debt'
    //   }
    // }

    const RESERVATION_PROGRESS_CLASS = {
      upcoming: 'finance-default receipt-going',
      proceeding: 'finance-default receipt-finish',
      ended: 'finance-default receipt-debt'
    }

    return (
      <div className="nav-section-content-container">
        <div className="meeting_available_time">
          <div className="meeting_room">
            <div className="remain">
              {meetingLimitation && meetingLimitation !== 'infinity'
                ? meeting_room_remain || 0
                : '不限制'}
              {meetingTimeUnitObj[meetingLimitation]}
            </div>
            <p>今日剩余时长</p>
          </div>
          <div className="meeting_room">
            <div className="used">
              {meeting_room_used}
              {meetingTimeUnitObj[meetingLimitation] || '小时'}
            </div>
            <p>已使用的小时数</p>
          </div>
        </div>
        <div className="m-top-sm">
          <KBRangeDate
            format="YYYY/MM/DD"
            from={from || start_at || ''}
            to={to || end_at || ''}
            ref={ref => {
              this.dateDropDown = ref
            }}
            dropDown_name="dateDropDown"
            closeClick={this.closeDropDowns}
            leftNone={true}
            callback={this.changeDate}
          />
          <KBDropSelect
            singleIsCancel={false}
            ref={ref => {
              this.meeting_room = ref
            }}
            dropDown_name="meeting_room"
            closeClick={this.closeDropDowns}
            options={{ defaultSingleContent: '选择会议室' }}
            selectedData={currentArea}
            defaultData={meetings}
            multi={false}
            callback={this.selectCallBack}
            style={{ marginLeft: 20 }}
          />
          {Object.keys(this.state.filters).length > 0 ? (
            <div>
              <div
                className="clear-criteria"
                style={{ marginTop: 5 }}
                onClick={this.clearFilter}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            </div>
          ) : null}
        </div>
        <KBLoadingContainer loading={loading}>
          <table className="content-table">
            <thead>
              <tr>
                <th>订单号</th>
                <th>会议室名称</th>
                <th>会议状态</th>
                <th>会议日期</th>
                <th>开始时间</th>
                <th>结束时间</th>
                <th>预订人</th>
                <th>订单创建时间</th>
              </tr>
            </thead>
            <KBTableWithoutData
              hasData={reservations.length > 0}
              tableHeadNum="7"
              tipMessage="暂无预定的会议室"
            >
              {reservations &&
                reservations.map(meeting => {
                  return (
                    <tr>
                      <td>{meeting.invoice_serial_number || '-'}</td>
                      {/* <td>{meeting.serial_number || '-'}</td> */}
                      <td>
                        <Link
                          className="color-link"
                          to={getMeetingRoomDetailPage(loc_id, meeting.area_id)}
                        >
                          {meeting.area_name}
                        </Link>
                      </td>
                      <td>
                        <span
                          className={
                            RESERVATION_PROGRESS_CLASS[meeting.progress]
                          }
                          style={{ marginLeft: 0 }}
                        >
                          {RESERVATION_PROGRESS_STATUS[meeting.progress]}
                        </span>
                        {meeting.state === 'cancelled' && !meeting.canceller ? (
                          <span className="span-type-smail-i m-left-sm">
                            系统取消
                          </span>
                        ) : null}
                      </td>
                      <td>{formatYearDayEN(meeting.start_at)}</td>
                      <td>{formatOnlyMinuteSecondEN(meeting.start_at)}</td>
                      <td>{formatOnlyMinuteSecondEN(meeting.end_at)}</td>
                      <td>
                        {meeting.host ? (
                          <div className="vf-center">
                            <KBAvatar
                              user={meeting.host}
                              imgStyle={{ marginRight: 5 }}
                              style={{
                                display: 'inline-block',
                                marginRight: '5px'
                              }}
                              size={30}
                            />
                            <Link
                              to={getUserInfoUrl(meeting.host.id)}
                              className="color-link"
                            >
                              {meeting.host.name || ''}
                            </Link>
                          </div>
                        ) : null}
                      </td>
                      <td>{formatMinuteSecondEN(meeting.created_at)}</td>
                    </tr>
                  )
                })}
            </KBTableWithoutData>
          </table>
          <KBPagination
            pagination={this.state.pagination}
            template={`/admin/locations/${loc_id}/organizations/${org_id}/meeting?page=#PAGE#`}
          />
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { user } = state
  let loc_id = params.id

  // let page = location.query && parseInt(location.query.page)
  // page = isNaN(page) ? 1 : page
  let page = parseInt(props.query && props.query.page) || 1

  let meetings = selectors.getMeetingRoomsOfLocation(state, loc_id)
  let orgs = selectors.getOrgsOfLocation(state, loc_id)

  var FILTER_STATE = ['area_id', 'start_at', 'end_at', 'from', 'to']
  let paramsAll = getFilterAndQuery(props.query, FILTER_STATE)
  let isManager = canAccessLocation(user, loc_id)
  let currentArea
  if (paramsAll.filters.area_id) {
    currentArea = meetings.find(
      meeting => paramsAll.filters.area_id == meeting.id
    )
  }

  let locationSetting = selectors.getLocationSetting(state, loc_id)
  let meetingDailyLimitation =
    locationSetting &&
    locationSetting.meeting_room_settings &&
    locationSetting.meeting_room_settings.daily_limitation
  let meetingMonthlyLimitation =
    locationSetting &&
    locationSetting.meeting_room_settings &&
    locationSetting.meeting_room_settings.monthly_limitation
  let meetingLimitation = meetingDailyLimitation || meetingMonthlyLimitation

  return {
    loc_id,
    orgs,
    meetings,
    currentArea,
    page,
    ...paramsAll,
    isManager,
    meetingLimitation
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getReservationsAction: bindActionCreators(
      reservationsActions.all,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationMeeting)
