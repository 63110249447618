import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import { apiCallFailure } from 'app/actions'
import { KBPopover, KBFormError, KBButton, KBTransfer } from 'components'
import { LOCK_MANAGE_TIPSY } from 'app/constants'
import moment from 'moment'
const pinyin = require('tiny-pinyin')

var SubscriptionLockAddForm = React.createClass({
  displayName: 'SubscriptionLockAddForm',
  getInitialState() {
    return {
      lockSource: [],
      lockGroupSource: [],
      lockTarget: [],
      lockGroupTarget: []
    }
  },

  createLockdForm() {
    const { apiCallFailureActions, addCallback } = this.props
    let locks = this.refs.lockTransfer.getData().target
    let lockGroups = this.refs.lockGroupTransfer.getData().target

    if (lockGroups.length <= 0 && locks.length <= 0) {
      apiCallFailureActions({
        status: 'error',
        message: '请选择门禁'
      })
      return
    }

    addCallback && addCallback(lockGroups, locks)
    KBPopover.close()
  },

  componentDidMount() {
    const { allLockGroups, allLockAreas } = this.props
    if (!allLockGroups.length) {
      this.getLockGroups()
    } else {
      this.setState({
        lockGroupSource: allLockGroups
      })
    }
    if (!allLockAreas.length) {
      this.getLocks()
    } else {
      this.setState({
        lockSource: allLockAreas
      })
    }
  },

  groupLocksByAreaType(locks) {
    // 对门禁按照 area.area_type 进行分组
    let tempIds = {}
    locks &&
      locks.map(json => {
        let tempIdKeys = Object.keys(tempIds)
        let s_id = (json.area && json.area.area_type) || 'gate'
        if (tempIdKeys.includes(String(s_id))) {
          tempIds[s_id].push(json)
        } else {
          tempIds[s_id] = []
          tempIds[s_id].push(json)
        }
      })

    let arrayedLocks = Object.values(tempIds)
    let mapedLocks = []

    arrayedLocks &&
      arrayedLocks.map(data => {
        let id = mapedLocks.length || 0
        let nameIndex = (data[0].area && data[0].area.area_type) || 'gate'
        let name = LOCK_MANAGE_TIPSY[nameIndex]
        let childrens = data
        mapedLocks.push({ id, name, childrens })
      })
    return mapedLocks
  },

  getLockGroups() {
    const { loc_id, setSelectingData } = this.props

    api.getLockGroups({ location_id: loc_id, per_page: 100, page: 1 }).then(
      json => {
        let lockGroupSource = json.response.body || []
        setSelectingData && setSelectingData('allLockGroups', lockGroupSource)

        this.setState({
          lockGroupSource
        })
      },
      errors => {}
    )
  },

  getLocks() {
    const { loc_id, setSelectingData } = this.props

    api.getLocks({ location_id: loc_id, per_page: 100, page: 1 }).then(
      json => {
        let lockSource = json.response.body || []
        let lockAreas = this.groupLocksByAreaType(lockSource)
        setSelectingData && setSelectingData('allLockAreas', lockAreas)

        this.setState({
          lockSource: lockAreas
        })
      },
      errors => {}
    )
  },

  getLocksOfGroup(id) {
    const { lockGroupSource } = this.state
    let children = lockGroupSource.find(l_g => l_g.id == id).locks

    children &&
      children.map(lock => {
        lock.pinyin = pinyin.convertToPinyin(lock.name).split('')[0]
      })
    children &&
      children.sort((a, b) => {
        return a.pinyin.localeCompare(b.pinyin)
      })

    return children
  },

  getAreaLocks(id) {
    const { lockSource } = this.state
    let children = lockSource[id].childrens

    children &&
      children.map(lock => {
        lock.pinyin = pinyin.convertToPinyin(lock.name).split('')[0]
      })
    children &&
      children.sort((a, b) => {
        return a.pinyin.localeCompare(b.pinyin)
      })

    return children
  },

  render() {
    const {
      fields: {},
      submitting,
      handleSubmit
    } = this.props
    let {
      lockSource,
      lockTarget,
      lockGroupSource,
      lockGroupTarget
    } = this.state

    return (
      <form onSubmit={handleSubmit(this.createLockdForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">添加门禁权限</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-title border-none">
            <i
              className="iconfont icon-close2"
              style={{ fontSize: 18, marginRight: 5, color: '#666' }}
            />
            <span>门禁组选择</span>
          </div>
          <div className="kb-row">
            <KBTransfer
              ref="lockGroupTransfer"
              isSearch={false}
              isHead={true}
              isMiddle={false}
              isChildrenLoad={true}
              isGroupAdd={true}
              getExpandChilds={this.getLocksOfGroup}
              multi={true}
              sourceTitle="门禁组"
              targetTitle="已添加"
              source={lockGroupSource}
              target={lockGroupTarget}
            />
          </div>

          <div className="kb-title border-none">
            <i
              className="iconfont icon-close2"
              style={{ fontSize: 18, marginRight: 5, color: '#666' }}
            />
            <span>门禁选择</span>
          </div>
          <div className="kb-row">
            <KBTransfer
              ref="lockTransfer"
              isSearch={false}
              isHead={true}
              isMiddle={false}
              isChildrenLoad={true}
              getExpandChilds={this.getAreaLocks}
              multi={true}
              sourceTitle="门禁"
              targetTitle="已添加"
              source={lockSource}
              target={lockTarget}
            />
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id } = props
  return {
    loc_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'SubscriptionLockAddForm',
  fields: [],
  validate: validate,
  touchOnBlur: false
}

SubscriptionLockAddForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionLockAddForm)

export default SubscriptionLockAddForm
