import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import { canAccessSpaceRoles } from 'app/reducers/role'
import * as selectors from 'app/selectors'
import { util, isHideModule } from 'utils/kbUtil'
import _ from 'lodash'

const DEFAULT_TOP_MENU = [
  {
    name: 'CRM统计',
    value: 'crm',
    roles: ['crm']
  },
  {
    name: '工位统计',
    value: '',
    roles: ['location_manager']
  },
  {
    name: '会员统计',
    value: 'organization',
    roles: ['location_manager']
  },
  {
    name: '会议室统计',
    value: 'meeting',
    roles: ['location_manager']
  },
  {
    name: '报修统计',
    value: 'task',
    roles: ['location_manager']
  },
  {
    name: '收款统计',
    value: 'invoice',
    roles: ['location_manager']
  },
  {
    name: '开门统计',
    value: 'lock',
    roles: ['location_manager']
  }
]

const HIDE_MODULE = [
  {
    name: 'CRM统计',
    value: 'crm',
    roles: ['crm']
  },
  {
    name: '报修统计',
    value: 'task',
    roles: ['location_manager']
  }
]

var SpaceStatisticBox = React.createClass({
  displayName: 'SpaceStatisticBox',
  componentWillMount() {
    const { user } = this.props
    if (!canAccessSpaceRoles(user, ['statistic'])) {
      _.remove(DEFAULT_TOP_MENU, function(n) {
        return n.value == 'crm'
      })
    }
  },
  componentDidMount() {},
  render() {
    const { section, group_id, space_id } = this.props

    let menu = DEFAULT_TOP_MENU

    if (isHideModule(space_id)) {
      menu = _.differenceWith(DEFAULT_TOP_MENU, HIDE_MODULE, _.isEqual)
    }

    let pathname = `/admin/groups/${group_id}/statistic`
    // if (group_id) {
    //   pathname = `/admin/locations/${location_id}/statistic`
    // }
    const childrenWithProps = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        group_id
      })
    })
    return (
      <div className="kb-out-box clear-fix">
        <section className="kb-content">
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">统计报表</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menu.map((data, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          section == data.value
                            ? 'nav-container-list-hover'
                            : ''
                        }
                      >
                        <Link to={`${pathname}/${data.value}`}>
                          {' '}
                          {data.name}{' '}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          {childrenWithProps}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const {
    params,
    location,
    params: { id }
  } = props
  let sections = location.pathname.split('/')
  let section = sections.length > 2 ? sections[3] : ''
  let space_id = Object.keys(state.entities.spaces || {})[0]
  let group_id = id
  // const group = selectors.getCurrentGroup(state, location_id)
  return {
    user,
    space_id,
    section: section || '',
    // location_id,
    group_id
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceStatisticBox)
