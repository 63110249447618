import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api, apiUtils } from 'app/services'
import {
  KBPopover,
  KBError,
  KBPopoverTop,
  KBLoadingContainer,
  KBAvatar,
  KBSuccess,
  KBDropdown
} from 'components'
import AutoReload from './AutoReload'
import * as selectors from 'app/selectors'
import config from 'app/config'
import {
  canAccessSpaceRoles,
  canAccessLocationVisitor,
  canAccessLocationRoles,
  canAccessSpaceFinance,
  canAccessLocationOfRoles,
  canAdminSpace,
  isGroupContentManager,
  onlyCanAdminLocationOfWeChat
} from 'app/reducers/role'
import _ from 'lodash'
import {
  membersActions,
  devicesActions,
  serializeLocationsActions,
  logout,
  spacesSalesSettingAction,
  spacesSettingAction,
  locationActions,
  getCitiesAction,
  spaceRoleAction,
  getProvincesAction,
  locationGroupsAction,
  groupsActions
} from 'app/actions'
import classNames from 'classnames'
import {
  util,
  isHideModule,
  groupLocationsByCity,
  kbCloseDropDown,
  getEntitiesJsonArray,
  setCurrentLocation,
  getCurrentLocation,
  removeCurrentLocation,
  isListValue,
  getListFirstValue,
  isTowerLocation
} from 'utils/kbUtil'
import 'moment/locale/zh-cn'
import {
  SEARCH_TYPE,
  SEARCH_TYPE_CONTENT,
  ORG_SUBSCRIPTION_STATES_CLASS,
  ORG_SUBSCRIPTION_STATES,
  INVOICES_STATE_CLASS,
  INVOICES_STATE,
  OFFICE_AREA_STATE
} from 'app/constants'
import { getUserInfoUrl } from 'utils/kbUrl'

const SPACE_MENU = [
  'locations',
  'employee',
  'crm',
  'tasks',
  'invoices',
  'faq',
  'memberships',
  'service',
  'statistic',
  'report',
  'shopping',
  'spaces_setting'
]

const DEFAULT_TOP_MENU = [
  {
    name: '全部分店',
    value: 'locations',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'observer',
      'location_finance',
      'location_repair',
      'location_contract',
      'abnormal_contract',
      'sales',
      'visitor',
      // 'location_group_manager',
      'location_group_operator',
      'location_group_wechat_manager',
      'location_group_content_manager',
      'landlord'
    ],
    childrens: []
  },
  {
    name: '账号管理',
    value: 'employee',
    roles: ['admin', 'hr', 'helpdesk']
  },
  {
    name: '客户管理',
    value: 'crm',
    roles: ['admin', 'crm', 'crm_admin']
  },
  {
    name: '任务管理',
    value: ['tasks/repair/type/my', 'tasks', 'tasks/feedback'],
    roles: ['admin', 'repair', 'task_feedback'],
    type: 'repair'
  },
  {
    name: '财务管理',
    value: 'invoices',
    roles: ['admin', 'finance', 'contract', 'abnormal_contract']
  },
  {
    name: '小程序显示管理',
    value: 'faq',
    roles: ['admin', 'faq', 'app']
  },
  {
    name: '会员管理',
    value: ['memberships/memberManage/members', 'memberships', 'members'],
    roles: ['admin']
  },
  {
    name: '服务商管理',
    value: 'service',
    roles: ['admin', 'enterprise']
  },
  {
    name: '自助认证',
    value: 'autoAuth',
    roles: ['admin']
  },
  // {
  //   name: '数据统计',
  //   value: 'statistic',
  //   roles: ['admin', 'statistic']
  // },
  {
    name: '报表中心',
    value: 'report',
    roles: [
      'admin',
      // 'location_manager',
      // 'location_operator',
      'observer',
      'statistic',
      // 'location_finance',
      // 'location_repair',
      // 'location_contract',
      'finance',
      'shop_manager',
      'abnormal_contract',
      'sales',
      'location_group_wechat_manager',
      'location_group_operator',
      'helpdesk'
    ]
  },
  {
    name: '商城管理',
    value: 'shopping',
    roles: ['admin', 'shop_manager', 'shop']
  },
  {
    name: '优惠券管理',
    value: 'coupon',
    roles: ['admin']
  },
  {
    name: '空间设置',
    value: 'spaces_setting',
    roles: ['admin']
  }
]

const HIDE_MODULE = [
  {
    name: '客户管理',
    value: 'crm',
    roles: ['admin', 'crm', 'crm_admin']
  },
  {
    name: '任务管理',
    value: ['tasks/repair/type/my', 'tasks', 'feedback'],
    roles: ['admin', 'repair']
  },
  {
    name: '小程序显示管理',
    value: 'faq',
    roles: ['admin', 'faq', 'app']
  },
  {
    name: '会员制管理',
    value: 'memberships',
    roles: ['admin']
  },
  {
    name: '服务商管理',
    value: 'service',
    roles: ['admin', 'enterprise']
  },
  {
    name: '商城管理',
    value: 'shopping',
    roles: ['admin']
  }
]

const DEFAULT_LOCATION_MENU = [
  {
    name: '全部分店',
    value: 'locations',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'observer',
      'location_finance',
      'location_repair',
      'location_contract',
      'finance',
      'abnormal_contract',
      'sales',
      'visitor',
      // 'location_group_manager',
      'location_group_operator',
      'location_group_wechat_manager',
      'location_group_content_manager',
      'landlord'
    ],
    childrens: []
  },
  {
    name: '首页',
    value: '',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_operator',
      'location_manager',
      'observer',
      'finance',
      'location_finance',
      'sales',
      'location_contract',
      'abnormal_contract',
      'visitor',
      // 'location_group_wechat_manager'
    ]
  },
  {
    name: '公司',
    value: 'organizations',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_operator',
      'location_manager',
      'observer',
      'location_finance',
      'sales',
      'location_contract',
      'abnormal_contract',
      'location_group_wechat_manager',
      'landlord'
    ]
  },
  {
    name: '空间管理',
    value: ['areas/desks/list', 'areas'],
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_operator',
      'location_manager',
      'observer',
      'sales'
    ]
  },
  // { name: '车位', value: ['parking/manage', 'parking'], roles: ['admin', 'location_operator', 'location_manager', 'observer', 'sales'] },
  {
    name: '会员',
    value: 'members',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_operator',
      'location_manager',
      'observer',
      'sales',
      'location_group_wechat_manager'
    ]
  },
  // {
  //   name: '任务',
  //   value: ['tasks/repair/type/my', 'tasks'],
  //   roles: [
  //     'admin',
  //     // 'location_group_manager',
  //     'location_group_operator',
  //     'location_operator',
  //     'location_manager',
  //     'observer',
  //     'location_repair',
  //     'sales'
  //   ]
  // },
  {
    name: '财务',
    value: 'invoices',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'observer',
      'finance',
      'location_finance',
      'location_contract',
      'abnormal_contract',
      'location_operator'
    ]
  },
  {
    name: '场地',
    value: 'meeting',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_operator',
      'location_manager',
      'observer',
      // 'location_group_wechat_manager'
    ]
  },
  {
    name: '门禁',
    value: 'locks',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_operator',
      'location_manager',
      'observer',
      'visitor'
    ]
  },
  // {name: '平面图', value: 'floorplan', roles: ['location_manager', 'location_operator']},
  {
    name: '访客',
    value: 'visit',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'location_operator',
      'observer',
      'sales',
      'visitor',
      'location_repair',
      'location_group_wechat_manager',
      'landlord'
    ]
  },
  // { name: 'APP', value: 'app', roles: ['admin', 'location_manager', 'location_operator', 'observer'] },
  // {name: '订单', value: 'location_orders', roles: ['admin', 'location_manager', 'observer', 'location_finance', 'location_operator']},
  {
    name: '活动',
    value: 'activities',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'location_operator',
      'location_group_wechat_manager',
      'location_group_content_manager',
    ]
  },
  {
    name: '加购管理',
    value: 'replacement',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'location_group_wechat_manager',
      'observer' // 观察员仅查看
    ]
  },
  {
    name: '优惠券管理',
    value: 'coupon',
    roles: [
      'admin',
      'location_manager',
    ]
  },
  {
    name: '自助认证',
    value: 'autoAuth',
    roles: [
      'admin',
      'location_manager',
      'location_group_operator',
    ]
  },
  // {name: '资讯', value: 'news', roles: ['location_manager', 'location_operator']},
  // {
  //   name: '储物柜',
  //   value: 'lockers',
  //   roles: ['admin', 'location_manager', 'location_operator', 'location_group_operator']
  // },
  {
    name: '灯控',
    value: 'light',
    roles: ['admin', 'location_manager', 'location_operator','location_group_operator']
  },
  {
    name: '设置',
    value: 'settings',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'location_operator',
      'location_group_wechat_manager'
    ]
  }
  // {name: '数据统计', value: 'statistic', roles: ['admin']}
]

const TOWER_LOCATION_MENU = [
  {
    name: '全部分店',
    value: 'locations',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'observer',
      'location_finance',
      'location_repair',
      'location_contract',
      'abnormal_contract',
      'sales',
      'visitor',
      // 'location_group_manager',
      'location_group_operator'
    ],
    childrens: []
  },
  {
    name: '首页',
    value: '',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'observer',
      'location_finance',
      'sales',
      'location_contract',
      'abnormal_contract',
      'visitor'
    ]
  },
  {
    name: '公司',
    value: 'organizations',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'observer',
      'location_finance',
      'sales',
      'location_contract',
      'abnormal_contract'
    ]
  },
  {
    name: '空间管理',
    value: ['areas/desks/list', 'areas'],
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'observer',
      'sales'
    ]
  },
  {
    name: '会员',
    value: 'members',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'observer',
      'sales'
    ]
  },
  {
    name: '门禁',
    value: 'locks',
    roles: [
      'admin',
      'location_operator',
      'location_manager',
      'observer',
      'visitor'
    ]
  },
  {
    name: '访客',
    value: 'visit',
    roles: ['admin', 'location_manager', 'observer', 'sales', 'visitor', 'location_group_wechat_manager']
  },
  // {name: 'APP', value: 'app', roles: ['admin', 'location_manager', 'location_operator', 'observer']},
  { name: '设置', value: 'settings', roles: ['admin', 'location_manager'] }
]

const DROP_DOWNS = ['userDropdown', 'locationDropdown']

const GROUP_MENU = [
  {
    name: '全部分店',
    value: 'locations',
    roles: [
      'admin',
      'location_manager',
      'location_operator',
      'observer',
      'location_finance',
      'location_repair',
      'location_contract',
      'abnormal_contract',
      'sales',
      'visitor',
      // 'location_group_manager',
      'location_group_operator',
      'location_group_wechat_manager',
      'location_group_content_manager'
    ],
    childrens: []
  },
  {
    name: '首页',
    value: '',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_operator',
      'location_manager',
      'observer',
      'location_finance',
      'sales',
      'location_contract',
      'abnormal_contract',
      'visitor',
      'location_group_wechat_manager'
    ]
  },
  {
    name: '小程序显示管理',
    value: 'app',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      // 'location_manager',
      // 'location_operator',
      'observer',
    ]
  },
  {
    name: '增值服务管理',
    value: 'valueAddedServices',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'location_operator',
      'observer',
      'location_group_content_manager'
    ]
  },
  {
    name: '会员福利管理',
    value: 'memberWelfare',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'location_operator',
      'observer',
      'location_group_content_manager'
    ]
  },
  {
    name: '门禁',
    value: 'locks',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      // 'location_operator',
      // 'location_manager',
      'observer',
    ]
  },
  {
    name: '数据统计',
    value: 'statistic',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      // 'location_operator',
      // 'location_manager',
      'observer',
    ]
  },
  {
    name: '设置',
    value: 'settings',
    roles: [
      'admin',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'location_group_wechat_manager',
      'location_group_content_manager'
    ]
  }
]

class AdminBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      spaceMenu: [],
      mode: 'space',
      currentLocation: {},
      searchType: 'user',
      searchResult: [],
      dropDownState: false,
      isShowSearchInput: false,
      groupMenu: []
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      error,
      locations,
      initLocation,
      section,
      currentSessionLocation,
      sections,
      group
    } = nextProps
    // check if 401 error

    if (error && error.status === 401) {
      return this._userLogout(401)
    }
    if (!_.isEqual(this.props.locations, locations)) {
      this.setLocationMenu(locations)
    }

    let initLocationId = initLocation - 0
    if (
      (locations &&
        locations.length &&
        section == 'locations' &&
        initLocationId &&
        Number.isInteger(initLocationId) &&
        initLocation !== this.props.initLocation) ||
      currentSessionLocation.id
    ) {
      let current_loc_id = initLocationId || currentSessionLocation.id
      this.setState({
        mode: 'location',
        currentLocation: locations.find(j => j.id === current_loc_id) || {}
      })
    }
    if (SPACE_MENU.includes(sections[2]) && isNaN(Number(sections[3]))) {
      removeCurrentLocation()
      this.setState({
        currentLocation: {},
        mode: 'space'
      })
    }

    if (
      JSON.stringify(this.props.currentStateLocation) !==
      JSON.stringify(nextProps.currentStateLocation)
    ) {
      this.handleLocationMenu(nextProps.currentStateLocation)
    }

    if (section === 'groups') {
      this.setState({
        currentLocation: group,
        mode: 'groups'
      })
    }
  }

  filterLocationRoute() {

  }

  handleLocationMenu(state_location) {
    const { space_id, user, location_id, currentStateLocation } = this.props
    let locationMenu = DEFAULT_LOCATION_MENU

    if (isHideModule(space_id)) {
      locationMenu = _.differenceWith(locationMenu, HIDE_MODULE, _.isEqual)
    }

    this.setState({
      locationMenu
    })
  }

  componentDidMount() {
    const {
      getMembersActions,
      getCitiesAction,
      getSpaceRoleAction,
      getDevicesAction,
      getSpaceSettingAction,
      getLocationGroupsAction,
      user
    } = this.props
    const {
      getSerializeLocationsActions,
      initLocation,
      section,
      space_id,
      currentStateLocation,
      groupId,
      getGroupsInfoAction
    } = this.props
    this.handleLocationMenu(currentStateLocation)

    let menu = DEFAULT_TOP_MENU
    let groupMenu = GROUP_MENU

    if (isHideModule(space_id)) {
      menu = _.differenceWith(DEFAULT_TOP_MENU, HIDE_MODULE, _.isEqual)
    }

    const abnormalContractRoles =
      user &&
      user.space_roles &&
      user.space_roles.filter(role => role.role === 'abnormal_contract')
    const hasSpaceAbnormal =
      abnormalContractRoles &&
      abnormalContractRoles.find(role => !role.location_id)
    if (!hasSpaceAbnormal) {
      menu = menu.map(m => {
        if (m.value === 'invoices') {
          m.roles.splice(
            m.roles.findIndex(r => r === 'abnormal_contract'),
            1
          )
        }
        return m
      })
    }

    this.setState({
      spaceMenu: menu,
      groupMenu
    })

    // 获取当前用户的详情
    api
      .currentUserAllInfo(user.id)
      .then(json => {
        getMembersActions.success(json)
      })
      .catch(error => {
        if (error.code == 401) {
          this._userLogout(401)
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })

    api.getLocationGroups().then(
      json => {
        getLocationGroupsAction.success(json)
      },
      error => {
        getLocationGroupsAction.failure(error)
      }
    )

    // 当前所有分店
    api.getLocations().then(
      json => {
        // 在页面刷新以后初始化当前左上角的location
        let initLocationId = initLocation - 0
        if (
          initLocationId &&
          Number.isInteger(initLocationId) &&
          section == 'locations'
        ) {
          let locations = getEntitiesJsonArray(json, 'locations')
          this.setState({
            mode: 'location',
            currentLocation: locations.find(j => j.id === initLocationId) || {}
          })
        }
        this.props.getLocationsAction.success(json)
      },
      errors => {
        this.props.getLocationsAction.failure(errors)
      }
    )

    // 空间员工
    api.getMembers({ per_page: 500 }).then(
      ({ json, response }) => {
        this.props.getMembersActions.success(json)
      },
      errors => {
        this.props.getMembersActions.failure(errors)
      }
    )

    // 城市列表
    // api.getCities().then((json) => {
    //   getCitiesAction.success(json)
    // }, error => {
    //   getCitiesAction.failure(error)
    // })

    // 省份列表
    // api.getProvinces().then(json => {
    //   this.props.getProvincesAction.success(json)
    // }, errors => {
    //   this.props.getProvincesAction.failure(errors)
    // })

    api
      .getSpaceSetting({
        inherit: true
      })
      .then(
        json => {
          getSpaceSettingAction.success(json)
        },
        errors => {
          getSpaceSettingAction.success(errors)
        }
      )

    api.getSpaceRoles().then(
      json => {
        getSpaceRoleAction.success(json)
      },
      errors => {
        getSpaceRoleAction.failure(errors)
      }
    )

    // 获取设备信息
    api.getDevices().then(
      json => {
        getDevicesAction.success(json)
      },
      errors => {
        getDevicesAction.failure(errors)
      }
    )

    api.getSerializeLocations({ per_page: 10000 }).then(
      json => {
        getSerializeLocationsActions.success(json)
      },
      errors => {
        getSerializeLocationsActions.failure(errors)
      }
    )

    //获取项目信息
    groupId &&
      api.getGroupsInfo(groupId).then(
        json => {
          getGroupsInfoAction.success(json)
        },
        errors => {
          getGroupsInfoAction.failure(errors)
        }
      )
  }

  componentWillUnmount() {
    this.setState({ loading: false })
  }

  triggerSelectLocation = location => {
    this.refs.locationDropdown.hide()
    this.setState({
      currentLocation: location,
      mode: 'location'
    })
    setCurrentLocation(location)
    const {user} = this.props
    if(canAccessLocationOfRoles(user, location.id)) {
      this.props.routerActions.push(`/admin/locations/${location.id}`)
    } else if(canAccessSpaceFinance(user) || canAccessLocationRoles(user, location.id, ['invoice_check', 'invoice_confirm', 'invoice_check_notify'])) {
      this.props.routerActions.push(`/admin/invoices`)
    } else if(onlyCanAdminLocationOfWeChat(user, location.id)) {
      return this.props.routerActions.push(`/admin/locations/${location.id}/organizations?page=1&per_page=100`)
    } else if(isGroupContentManager(user, location.id)) {
      // 活动管理员
      this.props.routerActions.push(`/admin/locations/${location.id}/activities`)
    } else {
      this.props.routerActions.push(`/admin/locations/${location.id}`)
    }
  }

  clearSelectLocation = () => {
    this.refs.locationDropdown.hide()
    this.setState({
      currentLocation: {},
      mode: 'space'
    })
    removeCurrentLocation()
    this.props.routerActions.push('/admin/locations')
  }

  setLocationMenu = locations => {
    const { spaceMenu, locationMenu, groupMenu } = this.state
    const newSpaceMenu = spaceMenu.slice()
    const newLocationMenu = locationMenu.slice()
    const newGroupMenu = groupMenu.slice()
    newSpaceMenu[0].childrens = locations
    newLocationMenu[0].childrens = locations
    newGroupMenu[0].childrens = locations
    this.setState({
      spaceMenu: newSpaceMenu,
      locationMenu: newLocationMenu,
      groupMenu: newGroupMenu
    })
  }

  _userLogout = (code) => {
    if(code === 401) {
      // token时间过期
      apiUtils.removeCurrentUser()
      removeCurrentLocation()
      // this.props.logout()
      localStorage.clear()
      sessionStorage.clear()
      api.logout401User()
    } else {
      // 手动点击退出
      this.hideDropdown()
      apiUtils.removeCurrentUser()
      removeCurrentLocation()
      // this.props.logout()
      localStorage.clear()
      sessionStorage.clear()
      api.logoutUser().then(() => {
        window.location.href = 'https://galaxy.capitaland.com/adfs/ls/?wa=wsignout1.0'
      })
    }
  }

  hideDropdown = name => {
    kbCloseDropDown(this, DROP_DOWNS, name)
  }

  _openSettings = () => {
    this.hideDropdown()
    this.props.routerActions.push('/admin/user_setting')
  }

  getRnederMenuParams = menuItem => {
    const { locationSection, section, sections } = this.props
    const { mode } = this.state
    let active = false
    let isLocationBox = mode === 'location'
    let isGroupBox = mode === 'groups'
    if (isLocationBox || isGroupBox) {
      active = isListValue(
        menuItem.value,
        sections[2] === 'members' ? section : locationSection
      )
    } else {
      active = isListValue(menuItem.value, section)
    }

    let locations = menuItem.childrens
    let locationsByCity = groupLocationsByCity(locations)

    return {
      active,
      isLocationBox,
      locationsByCity,
      isGroupBox
    }
  }

  selectSearchType = type => {
    this.setState({
      searchType: type.id,
      searchResult: [],
      dropDownState: false
    })
    this.refs['search_typeDropdown'].hide()
  }

  changeDropDown = e => {
    const value = e.target.value
    const { location_id } = this.props
    const { searchType, currentLocation } = this.state
    let params = { type: searchType, query: value, page: 1, per_page: 100 }
    if (!value) {
      return
    }
    if (location_id) {
      if (
        currentLocation &&
        currentLocation.locations &&
        currentLocation.locations.length > 0
      ) {
        params.location_group_id = location_id
      } else {
        params.location_id = location_id
      }
    }

    util.setTimeout(
      'locationSearchInput',
      () => {
        //value
        this.setState({ searching: true })
        api
          .getSearchAllType(params)
          .then(({ json }) => {
            this.setState({
              searchResult: json
            })
          })
          .finally(() => {
            this.setState({ searching: false })
          })
      },
      300
    )
  }

  showDropDown = () => {
    this.setState({
      dropDownState: true
    })
  }

  closeResult = () => {
    this.setState({
      dropDownState: false
    })
  }

  _renderMenu = () => {
    const { user, cities, location_id, currentStateLocation } = this.props
    const {
      spaceMenu,
      mode,
      locationMenu,
      currentLocation,
      groupMenu
    } = this.state
    let renderMenu = spaceMenu

    if (mode === 'location') {
      renderMenu = locationMenu

      // 没有任何该分店的权限，但是有项目内容管理员时，只展示活动模块
      const {space_roles} = user
      if (
        space_roles &&
        Array.isArray(space_roles) &&
        currentStateLocation &&
        space_roles.every(i => i.location_id != location_id) &&
        space_roles.some(i => !i.location_id && i.location_group_id == _.get(currentStateLocation, 'location_groups[0].id') && i.role === 'location_group_content_manager')
      ) {
        renderMenu = renderMenu.filter(i => i.value === 'activities' || i.value === 'locations')
      }
    }

    if (mode === 'groups') {
      renderMenu = groupMenu
    }

    return (
      <ul className="header-nav-list clear-fix">
        {renderMenu.map((menuItem, index) => {

          if (
            !canAccessSpaceRoles(user, menuItem.roles, this.props.location_id)
          ) {
            return null
          }

          const {
            isLocationBox,
            locationsByCity,
            active,
            isGroupBox
          } = this.getRnederMenuParams(menuItem)
          const isTaskFeedback = canAccessSpaceRoles(user, ['task_feedback'])
          let urlIndex = 0
          if (isTaskFeedback && menuItem.type && menuItem.type === 'repair') {
            urlIndex = 2
          }

          return (
            <li key={index + menuItem.name}>
              {menuItem.childrens ? (
                <KBDropdown ref="locationDropdown">
                  <KBDropdown.KBDropdownTrigger>
                    <span className={classNames(active ? 'color-white' : '')}>
                      <i
                        className={`iconfont icon-company ${active ? 'color-white' : ''
                          }`}
                      />
                      {_.get(currentLocation, 'name') ||
                        _.get(menuItem, 'name')}
                      {menuItem.childrens ? (
                        <i
                          className={`iconfont icon-arrow_drop_down_circle ${active ? 'color-white' : ''
                            }`}
                        />
                      ) : null}
                    </span>
                  </KBDropdown.KBDropdownTrigger>
                  <KBDropdown.KBDropdownContent
                    style={{
                      position: 'absolute',
                      left: -65,
                      top: 55,
                      marginTop: 0
                    }}
                  >
                    <div className="bar-drop-down">
                      <div className="bar-all-locations">
                        <span onClick={this.clearSelectLocation}>全部分店</span>
                      </div>
                      {Object.keys(locationsByCity).map((city_id, index) => {
                        let city = cities[city_id] || {}
                        let cityLocations = locationsByCity[city_id]
                        return (
                          <div className="city-list" key={index + '' + city_id}>
                            <header className="city-name">{city.name}</header>
                            <ul className="company-name clear-fix">
                              {cityLocations &&
                                cityLocations.map((location, index) => (
                                  <li key={index}>
                                    <span
                                      onClick={this.triggerSelectLocation.bind(
                                        null,
                                        location
                                      )}
                                    >
                                      {location.name}
                                    </span>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )
                      })}
                    </div>
                  </KBDropdown.KBDropdownContent>
                </KBDropdown>
              ) : (
                  // <Link to={`${isLocationBox ? `/admin/locations/${_.get(currentLocation,'id')}/${getListFirstValue(menuItem.value)}` : `/admin/${getListFirstValue(menuItem.value, urlIndex)}`}`} className={classNames(active ? 'color-white' : '')}>
                  //   {menuItem.name}
                  // </Link>
                  <Link
                    to={this.tolink(menuItem, urlIndex)}
                    className={classNames(active ? 'color-white' : '')}
                  >
                    {menuItem.name}
                  </Link>
                )}
            </li>
          )
        })}
      </ul>
    )
  }
  tolink = (menuItem, urlIndex) => {
    let url
    const { currentLocation } = this.state
    const { isLocationBox, isGroupBox } = this.getRnederMenuParams(menuItem)
    if (isLocationBox) {
      url = `/admin/locations/${_.get(
        currentLocation,
        'id'
      )}/${getListFirstValue(menuItem.value)}`
    } else if (isGroupBox) {
      url = `/admin/groups/${_.get(currentLocation, 'id')}/${getListFirstValue(
        menuItem.value
      )}`
    } else {
      url = `/admin/${getListFirstValue(menuItem.value, urlIndex)}`
    }
    return url
  }
  _renderSearch = () => {
    const { searchResult, searchType, dropDownState } = this.state
    if (!dropDownState) {
      return null
    }

    return searchResult.length ? (
      <div className="search-staff-company">
        <div className="company-staff-result" style={{ width: 402, top: 26 }}>
          <ul className="add-staff-list search-company-result">
            {searchResult.map((result, index) => {
              if (searchType === 'desk') {
                return (
                  <li key={index} className="t-left" onClick={this.closeResult}>
                    <Link
                      to={`/admin/locations/${result.location.id}/areas/desks/list?area_id=${result.area.id}`}
                    >
                      <span style={{ marginRight: 10 }}>
                        {result.area.name}
                      </span>
                      <span style={{ marginRight: 10 }}>
                        {OFFICE_AREA_STATE[result.area.area_type]}
                      </span>
                      <span>工位号: {result && result.serial_number}</span>
                    </Link>
                  </li>
                )
              }
              if (searchType === 'org') {
                return (
                  <li key={index} onClick={this.closeResult}>
                    <Link
                      to={`/admin/locations/${result.location.id}/organizations/${result.id}`}
                    >
                      <div className="add-staff-portrait">
                        <KBAvatar user={{ avatar: result.logo }} size={30} />
                      </div>
                      <span className="search-company-name">{result.name}</span>
                    </Link>
                  </li>
                )
              }
              if (searchType === 'user') {
                return (
                  <li key={index} onClick={this.closeResult}>
                    <Link to={getUserInfoUrl(result.id)}>
                      <div className="add-staff-portrait">
                        <KBAvatar user={{ avatar: result.avatar }} size={30} />
                      </div>
                      <span className="search-company-name">{result.name}</span>
                    </Link>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </div>
    ) : (
        <div className="search-staff-company">
          <div className="company-staff-result" style={{ width: 402, top: 26 }}>
            <div style={{ padding: 20 }}>
              {this.state.searchingOrgs ? '正在搜索' : '输入搜索关键字'}
            </div>
          </div>
        </div>
      )
  }

  render() {
    const {
      user,
      currentStateLocation,
      isvisitorManager,
      isManager,
      isLocationManager
    } = this.props
    const { searchType, isShowSearchInput } = this.state
    const filteredSearchType = isTowerLocation(currentStateLocation)
      ? SEARCH_TYPE.filter(type => type.id !== 'desk')
      : SEARCH_TYPE
    return (
      <KBLoadingContainer loading={this.state.loading}>
        <AutoReload url="/index.html" tryDelay={5 * 60 * 1000} />
        <header className="header-top-menu clear-fix">
          <div
            style={{
              minWidth: 1380,
              display: 'flex',
              alignItems: 'center',
              height: 66
            }}
            className="clear-fix"
          >
            <img
              src={config.logo_url}
              width="40"
              height="40"
              style={{ marginLeft: 30 }}
            />
            <div className="header-logo-container" style={{ flex: 1 }}>
              {!isShowSearchInput ? (
                <nav className="header-nav m-left">
                  {this._renderMenu()}
                  {(isManager || isLocationManager) && (
                    <i
                      className="iconfont icon-search"
                      style={{
                        fontSize: 18,
                        marginBottom: 4,
                        color: '#fff',
                        cursor: 'pointer',
                        zIndex: 9999
                      }}
                      onClick={() => {
                        this.setState({ isShowSearchInput: true })
                      }}
                    />
                  )}
                </nav>
              ) : (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div className="sub-nav-search">
                      <KBDropdown ref="search_typeDropdown">
                        <KBDropdown.KBDropdownTrigger>
                          <div className="search-type">
                            <span>{SEARCH_TYPE_CONTENT[searchType]}</span>
                            <i
                              className="iconfont icon-arrow_drop_down_circle"
                              style={{ marginLeft: 2, fontSize: 10 }}
                            />
                          </div>
                        </KBDropdown.KBDropdownTrigger>
                        <KBDropdown.KBDropdownContent
                          style={{ marginTop: 15, zIndex: 11 }}
                        >
                          <ul className="search-type_content">
                            {filteredSearchType.map((type, index) => {
                              return (
                                <li
                                  key={index}
                                  onClick={this.selectSearchType.bind(null, type)}
                                >
                                  {type.name}
                                </li>
                              )
                            })}
                          </ul>
                        </KBDropdown.KBDropdownContent>
                      </KBDropdown>
                      <input
                        type="text"
                        onChange={this.changeDropDown}
                        onFocus={this.showDropDown}
                      />
                    </div>
                    {this._renderSearch()}
                    <i
                      className="iconfont icon-close"
                      style={{
                        color: '#fff',
                        marginLeft: 30,
                        fontSize: 20,
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        this.setState({
                          isShowSearchInput: false,
                          searchResult: [],
                          dropDownState: false
                        })
                      }}
                    />
                  </div>
                )}
            </div>
            <div
              className="header-container-right"
              style={{ position: 'fixed', right: 10, top: 15 }}
            >
              <div className="user-avatar-container f-right">
                <KBDropdown ref="userDropdown">
                  <KBDropdown.KBDropdownTrigger>
                    <KBAvatar
                      className="user-avatar"
                      user={user}
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle'
                      }}
                      size={36}
                    />
                  </KBDropdown.KBDropdownTrigger>
                  <KBDropdown.KBDropdownContent style={{ left: -65, top: 50 }}>
                    <div className="user-dropdown">
                      <a onClick={this._openSettings}>设置</a>
                      <a onClick={this._userLogout}>退出</a>
                    </div>
                  </KBDropdown.KBDropdownContent>
                </KBDropdown>
              </div>
            </div>
          </div>
        </header>
        <div
          style={{ width: '100%', height: '100%' }}
          onClick={() => {
            this.setState({ dropDownState: false })
          }}
        >
          {this.props.children}
        </div>
        <KBPopover />
        <KBPopoverTop />
        <KBError error={this.props.error} />
        <KBSuccess success={this.props.success} />
        <a
          className="feedback_url"
          target="_blank"
          href="https://kuban.kf5.com/hc/request/new"
        >
          <div className="feedback">反馈</div>
          <div className="feedback_hover">
            <div className="feedback_img">
              <img src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_feedback_img@3x.png" />
              <span>在线反馈入口</span>
              <div className="arrow_left"></div>
            </div>
            <p className="feedback_text">您可以在线提交bug、建议或者意见</p>
          </div>
        </a>
      </KBLoadingContainer>
    )
  }
}

function mapStateToProps(state, props) {
  const { user } = state
  const { params, location } = props
  const location_id = params && params.id
  const groupId = params && params.id
  let sections = location.pathname.split('/')
  let section = sections.length > 1 ? sections[2] : ''
  let locationSection = sections.length > 4 ? sections[4] : ''
  let space_id = Object.keys(state.entities.spaces || {})[0]
  let locations = selectors.getLocations(state)
  let cities = selectors.getCities(state) || []
  let currentSessionLocation = getCurrentLocation() || {}
  let initLocation =
    (sections.length > 3 && sections[2] === 'locations' && sections[3]) ||
    currentSessionLocation.id ||
    ''
  let currentStateLocation =
    selectors.getCurrentLocation(state, location_id) ||
    currentSessionLocation ||
    {}
  let group = selectors.getCurrentGroup(state, groupId)
  let isvisitorManager = canAccessLocationVisitor(user, location_id)
  let isAdmin = canAdminSpace(user)
  let isManager =
    canAccessLocationRoles(user, location_id, [
      'admin',
      'location_operator',
      'location_manager'
    ]) || isAdmin
  const isLocationManager =
    canAccessLocationRoles(user, location_id, ['admin', 'location_manager']) ||
    isAdmin
  return {
    space_id,
    initLocation,
    currentSessionLocation,
    cities,
    locations,
    space: state.entities.spaces || {},
    user,
    findRole: selectors.findRole,
    section,
    locationSection: locationSection,
    success: state.success || {},
    error: state.error, // save global error here
    location_id,
    sections,
    currentStateLocation,
    group,
    isvisitorManager,
    isManager,
    isLocationManager
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getSerializeLocationsActions: bindActionCreators(
      serializeLocationsActions.get,
      dispatch
    ),
    getSpaceRoleAction: bindActionCreators(spaceRoleAction.get, dispatch),
    getMembersActions: bindActionCreators(membersActions.get, dispatch),
    getDevicesAction: bindActionCreators(devicesActions.get, dispatch),
    logout: bindActionCreators(logout, dispatch),
    getLocationsAction: bindActionCreators(locationActions.all, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    getCitiesAction: bindActionCreators(getCitiesAction, dispatch),
    getSpacesSalesSettingAction: bindActionCreators(
      spacesSalesSettingAction.get,
      dispatch
    ),
    getSpaceSettingAction: bindActionCreators(
      spacesSettingAction.get,
      dispatch
    ),
    getProvincesAction: bindActionCreators(getProvincesAction, dispatch),
    getLocationGroupsAction: bindActionCreators(
      locationGroupsAction.get,
      dispatch
    ),
    getGroupsInfoAction: bindActionCreators(groupsActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminBox)
