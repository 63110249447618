import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { lockGroupsAction } from 'app/actions'
import { KBPopoverConfirm } from 'components/tools'
import { KBPopover } from 'components'
import LockGroupForm from './LockGroupForm'
import _ from 'lodash'
import { canAccessLocation } from 'app/reducers/role'

var LockGroupBlock = React.createClass({
  getInitialState() {
    return {
      isActive: false
    }
  },

  showOrHideLocks() {
    this.setState({
      isActive: !this.state.isActive
    })
  },

  deleteLockGroup() {
    const {
      deleteLockGroupAction,
      lock_group,
      isPermission,
      onDelete
    } = this.props
    let id = lock_group.id

    if (isPermission) {
      return onDelete && onDelete(lock_group)
    }

    KBPopoverConfirm({
      name: '删除门禁组',
      context: `确定要删除名称为：${lock_group.name} 的门禁组?`,
      callback: () => {
        return api.deleteLockGroup(id).then(
          json => {
            deleteLockGroupAction.success(json, { id, key: 'lockGroups' })
            KBPopover.close()
          },
          errors => {
            deleteLockGroupAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  deleteSingleLock(lock) {
    const {
      updateLockGroupAction,
      lock_group,
      isPermission,
      onDeleteSingle
    } = this.props

    if (isPermission) {
      return onDeleteSingle && onDeleteSingle(lock)
    }

    let id = lock_group.id
    let params = {}
    params.lock_ids = lock_group.locks
      .filter(l => l.id != lock.id)
      .map(l => l.id)
      .join(',')
    KBPopoverConfirm({
      name: '删除门禁',
      context: `您将要删除门禁组：${lock_group.name} 下的门禁：${lock.name}，门禁组添加或减少门禁，已赋予的门禁组权限人员也会相应的修改，是否确认删除?`,
      callback: () => {
        return api.putLockGroup(id, params).then(
          json => {
            updateLockGroupAction.success(json, { id, key: 'lockGroups' })
            KBPopover.close()
          },
          errors => {
            updateLockGroupAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  updateLockGroup() {
    const { lock_group, loc_id, locks, isPermission, onEdit } = this.props

    if (isPermission) {
      return onEdit && onEdit(lock_group)
    }

    let uniquedLocks = _.pullAllBy(locks, lock_group.locks, 'id')
    let filterLocks = uniquedLocks.filter(lock => lock.location_id == loc_id)
    KBPopover.show(
      <LockGroupForm
        type="update"
        loc_id={loc_id}
        locks={filterLocks}
        lockGroup={lock_group}
      />
    )
  },

  toLockGroupDetail() {
    const { routerActions, loc_id, lock_group, isPermission } = this.props
    if (isPermission) {
      return
    }
    routerActions.push(
      `/admin/locations/${loc_id}/locks/lock_info/lock_group/${lock_group.id}`
    )
  },

  render() {
    const { lock_group, isManager } = this.props
    const { name, locks } = lock_group
    const { isActive } = this.state

    return (
      <div className="lock-group-block" onClick={this.toLockGroupDetail}>
        <div className={`status-bar${isActive ? ' status-bar-active' : ''}`} />
        <div className="block-container">
          <div className="group-summary">
            <div className="group-name">
              <div
                className="value a-hover"
                onClick={e => {
                  this.showOrHideLocks()
                  e.stopPropagation()
                }}
              >
                {name}
                <i
                  className={`iconfont ${
                    isActive
                      ? 'icon-arrow_drop_down_circle'
                      : 'icon-navigate_next'
                  }`}
                  style={{ marginLeft: 10 }}
                />
              </div>
              <div className="title">门禁组名称</div>
            </div>
            <div className="group-lock-num">
              <div className="value">{locks.length}</div>
              <div className="title">关联门禁数量</div>
            </div>
            {isManager ? (
              <div className="group-operations">
                <i
                  className="iconfont icon-font icon-edit m-right-sm a-hover"
                  onClick={e => {
                    this.updateLockGroup()
                    e.stopPropagation()
                  }}
                />
                <i
                  className="iconfont icon-font icon-delete hover-delete"
                  onClick={e => {
                    this.deleteLockGroup()
                    e.stopPropagation()
                  }}
                />
              </div>
            ) : null}
          </div>
          {isActive && locks.length > 0 ? (
            <div className="group-locks">
              {locks.map(lock => {
                return (
                  <div className="lock-block">
                    <div>
                      <div className="lock-name">门禁名称：{lock.name}</div>
                      <div className="lock-area">
                        所属区域：{(lock.area && lock.area.name) || '大门'}
                      </div>
                    </div>
                    {isManager ? (
                      <i
                        className="iconfont icon-font icon-delete hover-delete"
                        onClick={e => {
                          this.deleteSingleLock(lock)
                          e.stopPropagation()
                        }}
                      />
                    ) : null}
                  </div>
                )
              })}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { loc_id } = props
  let isManager = canAccessLocation(user, loc_id)

  return {
    isManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deleteLockGroupAction: bindActionCreators(
      lockGroupsAction.delete,
      dispatch
    ),
    updateLockGroupAction: bindActionCreators(
      lockGroupsAction.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockGroupBlock)
