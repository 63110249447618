import React, { Component, PropTypes } from 'react'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { leaveEnterOptionActions } from 'app/actions'

var OrganizationOptionsRow = React.createClass({
  getInitialState() {
    return {
      option_checked: this.props.option.checked
    }
  },

  changeOptionValue() {
    const { option_checked } = this.state
    const { updateOptions, option } = this.props
    let newCheckValue = !option_checked

    updateOptions && updateOptions(option.id, newCheckValue)
    this.setState({
      option_checked: newCheckValue
    })
  },

  render() {
    const { option } = this.props
    const classNmaeCom = {
      className: 'kb-form-group kb-div-c39'
    }

    return (
      <li>
        <input
          type="checkbox"
          onChange={this.changeOptionValue}
          checked={this.state.option_checked}
        />
        &nbsp;&nbsp;<span>{option.item}</span>
        {option.is_required ? '（必填）' : ''}
      </li>
    )
  }
})

OrganizationOptionsRow.PropTypes = {
  option: PropTypes.object.isRequired,
  location_id: PropTypes.number.isRequired,
  org: PropTypes.object.isRequired
}

function mapStateToProps(state, ownProps) {
  return {}
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationOptionsRow)
