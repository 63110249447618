import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import * as selectors from 'app/selectors'
import InvoiceList from '../invoices/InvoiceList'
import _ from 'lodash'
import { KBNoData } from 'components'

var OrgSubscriptionDetailInvoices = React.createClass({
  getInitialState() {
    return {}
  },

  componentDidMount() {},

  invoicesJsx() {
    const {
      subscription: { sales_renting_phases, deposit_invoices },
      location_id
    } = this.props
    const tdStyle = {
      height: '280px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8,
      cursor: 'default'
    }

    let phaseInvoices = []
    sales_renting_phases &&
      sales_renting_phases.map(phase => {
        if (phase.sales_invoice) {
          phaseInvoices.push(phase.sales_invoice)
        }
      })
    let subInvoices =
      deposit_invoices && deposit_invoices.length
        ? phaseInvoices.concat(deposit_invoices)
        : phaseInvoices
    subInvoices = _.uniqBy(subInvoices, 'id')
    return (
      <table className="content-table edit-table">
        <thead>
          <tr>
            <th>收款状态</th>
            <th>开票状态</th>
            <th style={{ width: 115 }}>账单类型</th>
            <th>公司名称</th>
            <th>应付金额</th>
            <th>待付金额</th>
            <th>到期日期</th>
            <th>实际付款日</th>
            <th>收款信息</th>
          </tr>
        </thead>
        {subInvoices && subInvoices.length ? (
          subInvoices.map((json, index) => {
            let obligation =
              Math.round(json.total_amount * 100) / 100 -
              Math.round(json.paid_amount * 100) / 100
            let isReviewed = json.is_reviewed
            return (
              <InvoiceList
                key={index}
                json={json}
                location_id={location_id}
                isReviewed={isReviewed}
                obligation={obligation}
                isTeam={true}
              />
            )
          })
        ) : (
          <tbody>
            <tr style={{ border: 0, backgroundColor: '#ffffff' }}>
              <td colSpan="9" style={tdStyle}>
                <KBNoData />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    )
  },

  render() {
    const { subscription, pathname, typeSource } = this.props

    return (
      <div className="nav-section-container f-right">
        <KBLoadingContainer loading={false}>
          <header className="kb-form-header">
            <h2 className="header-title clear-fix">
              <span className="f-left" style={{ color: '#333' }}>
                <Link
                  to={`${pathname}${
                    typeSource && typeSource == 'crmrecord'
                      ? '?typeSource=crmrecord'
                      : ''
                  }`}
                  style={{ color: '#999' }}
                  className="a-hover"
                >
                  合同详情 >{' '}
                </Link>
                合同账单
              </span>
            </h2>
          </header>
          <div className="kb-form-container">
            {subscription && this.invoicesJsx()}
          </div>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let subscription_id = props.params.subscription_id
  let subscription = selectors.getEntity(
    state,
    'subscriptions',
    subscription_id
  )
  let location_id = props.params.id

  return {
    subscription,
    location_id
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionDetailInvoices)
