import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import {
  paginate,
  queryFilter,
  getFilterAndQuery,
  formatMinuteSecondEN,
  getServiceErrorMessage
} from 'utils/kbUtil'
import { apiCallFailure } from 'app/actions'
import {
  KBPopover,
  KBPagination,
  KBLoadingContainer,
  KBTableWithoutData,
  KBAvatar,
  KBPopoverTop,
  KBToReportCenter,
  KBStatisticNumber
} from 'components'
import { canAccessLocation } from 'app/reducers/role'
import { KBPopoverConfirm } from 'components/tools'
import moment from 'moment'

var ItemLockDashboard = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      loading: false,
      pagination: null,
      data: [],
      statistics: null
    }
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="Dashboard 数据导出"
        message="Dashboard数据已成功导出，可以前往报表中心下载"
      />
    )
  },

  exportLockPermissions() {
    const { loc_id, apiCallFailureActions } = this.props
    let start_date = moment()
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
    let end_date = moment()
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
    return api
      .getFacePhotoCountReport({
        location_group_id: loc_id,
        start_date,
        end_date,
        as_json: false
      })
      .then(
        () => {
          this.toReportCenter && this.toReportCenter()
        },
        error => {
          apiCallFailureActions({
            status: 'error',
            message: getServiceErrorMessage(error)
          })
        }
      )
  },

  componentDidMount() {
    const { filters, page } = this.props
    this.mounted = true
    this._loadData(page, filters)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page !== nextProps.page) {
      this._loadData(nextProps.page, { ...this.state.filters, ...filters })
    }
  },

  _loadData(page, filters) {
    const { loc_id, apiCallFailureActions, query, routerActions } = this.props
    const per_page = 10
    let pageObj = { per_page, page }
    let params = Object.assign({}, pageObj, filters, {
      location_group_id: loc_id,
      as_json: true,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    })
    let queryFiltersStr = queryFilter({ ...query, ...pageObj, ...filters })
    api.getFacePhotoCountReport(params).then(
      json => {
        // 防止用户点击过快时路由来回跳转
        if (!this.mounted) {
          return
        }
        //	routerActions.replace(`/admin/locations/${loc_id}/locks/lock_dashboard${queryFiltersStr}`)
        let pagination = paginate(json.response)
        let resb = json.response.body || {}
        let data = resb.body || []
        let statistics = resb.summary || {}
        this.setState({
          pagination,
          data,
          statistics
        })
      },
      errors => {
        apiCallFailureActions({
          status: 'error',
          message: getServiceErrorMessage(errors)
        })
      }
    )
  },

  _renderStatistics() {
    const { statistics } = this.state

    if (!statistics) {
      return null
    }

    return (
      <div className="chart-out_box t-center m-updown">
        <div className="chart-box t-left" style={{ marginRight: 100 }}>
          <KBStatisticNumber
            number={statistics.org_users_count}
            unit="个"
            name="总员工数"
          />
        </div>
        <div className="chart-box t-left" style={{ marginRight: 20 }}>
          <KBStatisticNumber
            number={statistics.face_photos_count}
            unit="个"
            name="上传照片数"
          />
        </div>
      </div>
    )
  },

  render() {
    const { loading, pagination, data } = this.state
    const { loc_id } = this.props

    return (
      <section className="kb-content-container clear-fix">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>Dashboard</span>
          </h2>
          <div className="f-right">
            <button
              className="c-btn-secondary"
              onClick={this.exportLockPermissions}
            >
              导出报表
            </button>
          </div>
        </header>
        <div className="nav-section-content-container">
          {this._renderStatistics()}
          <KBLoadingContainer loading={loading}>
            <table className="content-table">
              <thead>
                <tr>
                  <th>时间</th>
                  <th>公司</th>
                  <th>OCRM注册人数（累计）</th>
                  <th>OCRM上传照片人数（累计）</th>
                  <th>访客注册人数（去重）</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={data.length > 0}
                tableHeadNum="6"
                tipMessage="暂无数据"
              >
                {data &&
                  data.map((json, index) => {
                    return (
                      <tr key={index}>
                        <td>{json.date}</td>
                        <td>{json.org}</td>
                        <td>{json.member_count}</td>
                        <td>{json.face_photo_count}</td>
                        <td>{json.visitor_count}</td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/groups/${loc_id}/locks?page=#PAGE#`}
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const loc_id = params.id

  let isManager = canAccessLocation(state.user, loc_id)

  let page = parseInt(location.query && location.query.page, 10)
  page = isNaN(page) ? 1 : page

  let paramsAll = getFilterAndQuery(location.query, [])

  return {
    loc_id,
    isManager,
    page,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemLockDashboard)
