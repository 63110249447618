import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { KBPagination, KBTableWithoutData } from 'components'
import moment from 'moment'
import { paginate } from 'utils/kbUtil'
import _ from 'lodash'
import { Icon, notification } from 'antd'
import SettingCoupon from './SettingCoupon'

var MeetingCoupon = React.createClass({
  displayName: 'MeetingCoupon',
  getInitialState() {
    const { params = {} } = this.props
    return {
      loc_id: params.id,
      coupons: [],
      pagination: {
        page: 1,
        per_page: 10
      },
      modalVisible: false,
      currentData: {}
    }
  },

  getList(params) {
    api.getCoupon(params).then(res => {
      console.log(res, '=res=')
      const { json } = res
      let pagination = paginate(res.response)
      this.setState({ coupons: json, pagination })
    })
  },

  componentDidMount() {
    const { pageType } = this.props
    const { loc_id, pagination } = this.state
    this.getList({
      location_id: loc_id,
      discount_for: pageType === 'coupon' ? 'meeting_room' : 'flexible_office',
      ...pagination
    })
    // api.getCoupon()
  },

  updateCoupon(id, params, type) {
    const { pageType } = this.props
    const { loc_id, pagination } = this.state
    api.updateCoupon(id, params).then(
      res => {
        console.log(res, 'res')
        const Params = {
          location_id: loc_id,
          discount_for:
            pageType === 'coupon' ? 'meeting_room' : 'flexible_office',
          ...pagination
        }
        if (type === 'edit') {
          this.setState({ modalVisible: false }, () => {
            this.getList(Params)
          })
        } else {
          this.getList(Params)
        }
      },
      err => {
        notification.error({ message: err.message })
      }
    )
  },

  goToDetail(id) {
    const { pageType, routerActions } = this.props
    const { loc_id } = this.state
    if (pageType === 'coupon') {
      routerActions.push(
        `/admin/locations/${loc_id}/meetingCoupon/detail?coupon_id=${id}&&type=coupon`
      )
    } else {
      routerActions.push(
        `/admin/locations/${loc_id}/meetingCoupon/detail?coupon_id=${id}&&type=areacoupon`
      )
    }
  },

  render() {
    const { pageType } = this.props
    const {
      coupons,
      currentData,
      modalVisible,
      pagination,
      loc_id
    } = this.state

    return (
      <div className="kb-content" style={{ paddingTop: 0 }}>
        <section
          className={`${
            pageType === 'coupon'
              ? 'nav-section-container f-right'
              : 'kb-content-container'
          }`}
          ref="container"
        >
          <header
            className={`${
              pageType === 'coupon' ? 'nav-section-header' : 'nav-header'
            }  clear-fix`}
            style={{ position: 'relative' }}
          >
            <h2 className="nav-section-header-title f-left">
              <span>优惠管理</span>
            </h2>
          </header>
          <div>
            <table className="content-table" style={{ width: '100%' }}>
              <thead>
                <th style={{ minWidth: 100 }}>
                  {pageType === 'coupon' ? '会议室名称' : '灵活工位名称'}
                </th>
                <th style={{ minWidth: 80 }}>创建人</th>
                <th style={{ minWidth: 80 }}>优惠名称</th>
                <th style={{ minWidth: 100 }}>优惠活动时间</th>
                <th style={{ minWidth: 100 }}>
                  价格（元/{pageType === 'coupon' ? '时' : '天'}）
                </th>
              </thead>
              <KBTableWithoutData
                hasData={coupons.length > 0}
                tableHeadNum="8"
                tipMessage="暂无优惠信息"
              >
                {coupons &&
                  coupons.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.goToDetail(item.id)}
                      >
                        <td>{(item.source || {}).name || ''}</td>
                        <td>{(item.creator || {}).name}</td>
                        <td>{item.title}</td>
                        <td>
                          <div>
                            {moment(item.start_date).format('YYYY年MM月DD日')}-
                            {moment(item.end_date).format('YYYY年MM月DD日')}
                          </div>
                        </td>

                        <td>
                          <div>
                            普通会员：
                            <span>{item.price}（原价）</span>
                            <Icon
                              type="arrow-right"
                              style={{ marginRight: 5 }}
                            />
                            {item.price > Math.abs(item.discount_price)
                              ? item.price - Math.abs(item.discount_price)
                              : 0}
                            （终价）
                          </div>
                          <div style={{ color: '#fa689a' }}>
                            办公楼会员：
                            <span>{item.tenant_price}（原价）</span>
                            <Icon
                              type="arrow-right"
                              style={{ marginRight: 5 }}
                            />
                            {item.tenant_price >
                            Math.abs(item.discount_tenant_price)
                              ? item.tenant_price -
                                Math.abs(item.discount_tenant_price)
                              : 0}
                            （终价）
                          </div>

                          <div style={{ color: '#fa689a' }}>
                            奕桥会员：
                            <span>{item.bridge_price || 0}（原价）</span>
                            <Icon
                              type="arrow-right"
                              style={{ marginRight: 5 }}
                            />
                            {item.bridge_price >
                            Math.abs(item.discount_bridge_price)
                              ? item.bridge_price -
                                Math.abs(item.discount_bridge_price)
                              : 0}
                            （终价）
                          </div>
                          <span
                            style={{ textDecoration: 'line-through' }}
                          ></span>
                        </td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/locations/${loc_id}/meeting/coupon?page=#PAGE#`}
            />
          </div>
          {modalVisible && (
            <SettingCoupon
              source_type={pageType === 'coupon' ? 'meeting' : 'desk'}
              type={'edit'}
              close={() => this.setState({ modalVisible: false })}
              visible={modalVisible}
              data={currentData}
              callback={(id, params, type) => {
                this.updateCoupon(id, params, type)
              }}
            />
          )}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location: { pathname } = {} } = props
  const path = pathname.split('/')
  const pageType = path[path.length - 1]
  return { pageType }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingCoupon)
