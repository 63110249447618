import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBForm, KBInput, KBSelect, KBButton, KBPopover } from 'components'
import InvoiceTitleForm from './InvoiceTitleForm'
import { api } from 'app/services'
import _ from 'lodash'

const isInvoiceType = [
  {
    id: 'special',
    name: '增值税专用发票'
  },
  {
    id: 'common',
    name: '增值税普通发票'
  }
]

const invoiceContent = [
  { title: '房租', value: '房租' },
  { title: '水费', value: '水费' },
  { title: '电费', value: '电费' },
  { title: '服务费', value: '服务费' },
  { title: '物业管理费', value: '物业管理费' },
  { title: '会议室使用费', value: '会议室使用费' },
  { title: '网络服务费', value: '网络服务费' },
  { title: '其他费用', value: '其他费用' }
]

const invoiceContentArray = [
  '房租',
  '物业管理费',
  '会议室使用费',
  '水费',
  '电费',
  '服务费',
  '网络服务费',
  ''
]

const isAutoInvoice = [
  { id: false, name: '否' },
  { id: true, name: '是' }
]

var OrgInvoiceMessage = React.createClass({
  getInitialState() {
    const {
      fields: { invoice_content }
    } = this.props

    return {
      //invoiceContentArray.includes(invoice_content.value) || invoice_content.value === '其他费用' ? invoice_content.value : '其他费用',
      selectedContent: '',
      formSubmitting: this.props.formSubmitting,
      invoiceTitles: []
    }
  },

  componentDidMount() {
    const { newOrg, org } = this.props
    const sales_customer_id =
      (newOrg && newOrg.sales_customer && newOrg.sales_customer.id) ||
      (org.sales_customer && org.sales_customer.id)
    sales_customer_id && this.loadInvoiceTitle(sales_customer_id)
  },

  componentWillReceiveProps(nextProps) {
    const { org, newOrg } = nextProps
    const org_o = this.props.org
    const newOrg_o = this.props.newOrg
    const sales_customer_id =
      (newOrg && newOrg.sales_customer && newOrg.sales_customer.id) ||
      (org.sales_customer && org.sales_customer.id)
    const old_sales_customer_id =
      (newOrg_o && newOrg_o.sales_customer && newOrg_o.sales_customer.id) ||
      (org_o.sales_customer && org_o.sales_customer.id)
    if (nextProps.formSubmitting !== this.props.formSubmitting) {
      this.setState({
        formSubmitting: nextProps.formSubmitting
      })
    }
    if (sales_customer_id && sales_customer_id !== old_sales_customer_id) {
      sales_customer_id && this.loadInvoiceTitle(sales_customer_id)
    }
  },

  loadInvoiceTitle(sales_customer_id) {
    const { location_id } = this.props
    sales_customer_id &&
      api
        .getInvoiceTitles({ location_id, sales_customer_id })
        .then(({ json }) => {
          this.setState({
            invoiceTitles: json
          })
        })
  },

  saveOrgInvoiceOfNext(model) {
    const { saveParams } = this.props
    const { formSubmitting } = this.state
    !formSubmitting && saveParams && saveParams(model, 4)
  },

  saveOrgInvoiceOfComplete() {
    const { saveParams } = this.props
    const { formSubmitting } = this.state
    !formSubmitting && saveParams && saveParams({}, 4)
  },

  saveOrgInvoiceOfUpdate(model) {
    const { saveParams } = this.props
    return saveParams && saveParams(model, false, true)
  },

  saveOrgInvoiceOfLast() {
    const { fields, saveParams } = this.props
    let model = {}
    Object.keys(fields).map(field_key => {
      model[field_key] = fields[field_key].value
    })
    saveParams && saveParams(model, 2)
  },

  addInvoiceTitle() {
    const {
      location_id,
      newOrg,
      org,
      fields: { invoice_type }
    } = this.props
    const sales_customers_id =
      (newOrg && newOrg.sales_customer.id) || org.sales_customer.id
    KBPopover.show(
      <InvoiceTitleForm
        is_special={invoice_type.value === '增值税专用发票'}
        location_id={parseInt(location_id)}
        callBack={this.invoiceTitleCallBack}
        sales_customer_id={parseInt(sales_customers_id)}
      />
    )
  },

  invoiceTitleCallBack(json) {
    const title_id = json.id
    const { invoiceTitles } = this.state
    let invoiceTitles_new = _.cloneDeep(invoiceTitles)
    const existIndex = invoiceTitles_new.findIndex(
      title => title.id === title_id
    )
    if (existIndex !== -1) {
      invoiceTitles_new[existIndex] = json
    } else {
      invoiceTitles_new.push(json)
    }
    this.setState({
      invoiceTitles: invoiceTitles_new
    })
  },

  deleteInvoiceTitle(title) {
    const { location_id, newOrg, org } = this.props
    const sales_customer_id =
      (newOrg && newOrg.sales_customer.id) || org.sales_customer.id
    const { invoiceTitles } = this.state
    let invoiceTitles_new = _.cloneDeep(invoiceTitles)
    const existIndex = invoiceTitles_new.findIndex(
      title_n => title_n.id === title.id
    )
    KBPopover.plugins.confirm(
      '删除发票抬头',
      `是否确定删除 ${title.title} 发票抬头`,
      {
        confirm: () => {
          invoiceTitles_new.splice(existIndex, 1)
          this.setState({
            invoiceTitles: invoiceTitles_new
          })
          KBPopover.close()
          api.deleteInvoiceTitle(title.id, { location_id, sales_customer_id })
        }
      }
    )
  },

  editInvoiceTitle(title) {
    const {
      location_id,
      newOrg,
      org,
      fields: { invoice_type }
    } = this.props
    const sales_customers_id =
      (newOrg && newOrg.sales_customer.id) || org.sales_customer.id

    KBPopover.show(
      <InvoiceTitleForm
        is_special={invoice_type.value === '增值税专用发票'}
        location_id={parseInt(location_id)}
        callBack={this.invoiceTitleCallBack}
        sales_customer_id={parseInt(sales_customers_id)}
        invoiceTitle={title}
        type="update"
      />
    )
  },

  render() {
    const {
      fields: { invoice_type, auto_invoice }
    } = this.props
    const { handleSubmit, isUpdate, isWizard } = this.props
    const { formSubmitting, invoiceTitles } = this.state

    return (
      <KBForm type="reduxForm" fields={this.props.fields}>
        <header className="kb-form-header">
          <h2 className="header-title">发票信息</h2>
        </header>
        <div className="kb-form-container">
          <div className="kb-row kb-form-48">
            <KBSelect
              defaultValue="-1"
              className="kb-form-group kb-div-39"
              title="发票类型"
              field={invoice_type}
              arrayData={isInvoiceType}
            />
            {/* <div className='kb-row kb-form-66' style={ {float: 'left'} }>
              <KBSelect defaultValue='-1' className='kb-form-group kb-div-48' titleClass='label-rt' title='自动开票' field={auto_invoice} arrayData={isAutoInvoice} />
            </div> */}
          </div>
          <div style={{ marginBottom: 30 }}>
            <div>
              <span style={{ color: '#999' }}>发票抬头</span>
              <KBButton
                className="certain-btn"
                type="button"
                style={{ marginLeft: 33 }}
                onClick={() => {
                  this.addInvoiceTitle()
                }}
              >
                添加发票抬头
              </KBButton>
            </div>
            <div style={{ marginTop: 25, paddingLeft: 88 }}>
              {invoiceTitles.length > 0 &&
                invoiceTitles.map((title, index) => {
                  return (
                    <div
                      key={index}
                      className="location-provider"
                      style={{ marginBottom: 20 }}
                    >
                      <span
                        className="operate-provider delete-provider"
                        onClick={() => {
                          this.deleteInvoiceTitle(title)
                        }}
                      >
                        <i className="iconfont icon-delete" />
                      </span>
                      <span
                        className="operate-provider update-provider"
                        onClick={() => {
                          this.editInvoiceTitle(title)
                        }}
                      >
                        <i className="iconfont icon-edit" />
                      </span>
                      <div className="provider-line">
                        <div>
                          <span>发票类别</span>
                          <span>
                            {title.invoice_type
                              ? title.invoice_type == 'common'
                                ? '增值税普通发票'
                                : '增值税专用发票'
                              : '-'}
                          </span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>发票抬头</span>
                          <span>{title.title || '暂无'}</span>
                        </div>
                        <div>
                          <span>税号</span>
                          <span>{title.tax_num || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>邮箱地址</span>
                          <span>{title.email || '暂无'}</span>
                        </div>
                        <div>
                          <span>开户银行</span>
                          <span>{title.bank || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>银行账号</span>
                          <span>{title.bank_account || '暂无'}</span>
                        </div>
                        <div>
                          <span>注册电话</span>
                          <span>{title.phone_num || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>注册地址</span>
                          <span>{title.address || '暂无'}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="kb-row">
            {!isWizard ? (
              <KBButton
                className="certain-btn"
                type="button"
                onClick={this.saveOrgInvoiceOfLast}
                style={{ marginRight: 30 }}
              >
                上一步
              </KBButton>
            ) : null}
            {!isWizard ? (
              <KBButton
                className="certain-btn"
                type="submit"
                onClick={handleSubmit(this.saveOrgInvoiceOfNext)}
                style={{ marginRight: 30 }}
              >
                下一步
              </KBButton>
            ) : null}
            {!isUpdate ? (
              <KBButton
                className="certain-btn"
                onClick={this.saveOrgInvoiceOfComplete}
              >
                暂不填写发票信息
              </KBButton>
            ) : (
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={formSubmitting}
                onClick={handleSubmit(this.saveOrgInvoiceOfUpdate)}
              >
                完成编辑
              </KBButton>
            )}
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { org, urlParams } = props
  const isUpdate = urlParams && urlParams.type === 'update' && org
  const isWizard = urlParams && urlParams.wizard === '1' && org

  return {
    isUpdate,
    isWizard,
    initialValues: org
      ? {
          ...org
        }
      : {}
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  if (values.auto_invoice === 'true' || values.auto_invoice === true) {
    // if (valid.required(values.invoice_header)) {
    //   errors.invoice_header = '请输入发票抬头'
    // }
    //
    // if (valid.required(values.open_bank)) {
    //   errors.open_bank = '请输入开户行'
    // }
    //
    // if (valid.required(values.bank_account)) {
    //   errors.bank_account = '请输入银行账户'
    // }
    //
    // if (valid.isNumber(values.bank_account)) {
    //   errors.bank_account = '银行账号只能为数字'
    // }
    //
    // if (valid.required(values.tax_number)) {
    //   errors.tax_number = '请输入税号'
    // }
    //
    // if (valid.numberAndLetter(values.tax_number)) {
    //   errors.tax_number = '税号必须为数字和字母形式'
    // }
    //
    // if (valid.required(values.invoice_address)) {
    //   errors.invoice_address = '请输入发票地址'
    // }
    //
    // if (valid.required(values.invoice_content)) {
    //   errors.invoice_content = '请选择或输入发票内容'
    // }
    //
    // if (valid.required(values.invoice_phone)) {
    //   errors.invoice_phone = '请输入发票电话'
    // }
    // if (valid.commonPhoneNumber(values.invoice_phone)) {
    //   errors.invoice_phone = '请输入正确的电话,由数字和”-“组成'
    // }
  }

  return errors
}

const formConfig = {
  form: 'OrgInvoiceMessage',
  fields: [
    'invoice_type',
    'auto_invoice'
    // 'invoice_header',
    // 'invoice_content',
    // 'open_bank',
    // 'bank_account',
    // 'tax_number',
    // 'invoice_address',
    // 'invoice_phone'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgInvoiceMessage)
