import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { KBLoadingContainer, KBPagination, KBSearchList } from 'components'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import {
  paginate,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown
} from 'utils/kbUtil'
import PostBlock from './PostBlock'
import { KBNoData } from 'components'

const DROP_DOWNS = ['location_idDropdown']

const POST_STATUS = [
  {
    id: '',
    name: '全部'
  },
  {
    id: 'normal',
    name: '普通帖'
  },
  {
    id: 'demand',
    name: '需求帖'
  }
]

var PostList = React.createClass({
  getInitialState() {
    const { filters } = this.props

    return {
      filters,
      pagination: null,
      posts: [],
      loading: false
    }
  },

  componentDidMount() {
    this.mounted = true

    const { filters } = this.state
    this._loadData(filters)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(filters, page) {
    const { routerActions, query } = this.props
    const per_page = 10
    let pageObj = {
      per_page,
      page: page || filters.page || this.props.page
    }

    let params = Object.assign({}, filters, pageObj)

    let queryFiltersStr = queryFilter({ ...query, ...params, ...pageObj })

    this.setState({ loading: true })

    api
      .getPosts(params)
      .then(({ json, response }) => {
        let pagination = paginate(response)
        let posts = json || []
        // 防止用户点击过快时路由来回跳转
        if (!this.mounted) {
          return
        }
        routerActions.replace(
          `/admin/memberships/memberManage/posts${queryFiltersStr}`
        )
        if (this.mounted) {
          this.setState({
            posts,
            pagination
          })
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = this.state
    if (nextProps.page !== this.props.page) {
      this._loadData(filters, nextProps.page)
    }
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })
    this.refs.location_idDropdown && this.refs.location_idDropdown.hide()

    this._loadData(newFilters, newFilters.page || 1)
  },

  _clearFilters() {
    const { routerActions } = this.props
    this.setState({ filters: {} })
    routerActions.replace(`/admin/memberships/memberManage/posts`)
    this._loadData({}, 1)
  },

  updatePostList(deleted_id) {
    const { posts } = this.state
    let newPosts = Object.assign([], posts)
    let deletedIndex = posts.findIndex(p => p.id === deleted_id)
    newPosts.splice(deletedIndex, 1)
    this.setState({
      posts: newPosts
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  _renderFilters() {
    const { locations } = this.props
    const { filters } = this.state
    const current_category = filters.category || ''

    return (
      <div className="nav-section-action-bar">
        <div className="select-progress">
          {POST_STATUS.map((json, index) => {
            const isCheckedType = json.id === current_category
            return (
              <div
                key={index}
                style={{ marginRight: 0, padding: '7px 13px' }}
                className={`border-show_status ${
                  isCheckedType ? 'border-show_status_active' : ''
                }`}
                onClick={() => this._setFilter({ category: json.id })}
              >
                <span className="status-text">{json.name}</span>
              </div>
            )
          })}
        </div>
        <div className="select-progress">
          {/* 接口分店筛选条件暂无效 */}
          {
            // <KBDropdown ref="location_idDropdown">
            //   <KBDropdown.KBDropdownTrigger>
            //     <div className='task-status-title' onClick={ this.closeDropDowns.bind(null, 'location_idDropdown') }>
            //       <i className='iconfont icon-position' />
            //       <span>{this.state.filters.location_id && Object.keys(locations).length > 0 ? locations[this.state.filters.location_id].name : '选择分店'}</span>
            //       <span className='task-deg' />
            //     </div>
            //   </KBDropdown.KBDropdownTrigger>
            //   <KBDropdown.KBDropdownContent>
            //     <div>
            //       <ul className='task-type'>
            //         <li onClick={()=>this._setFilter({ 'location_id' : ''})}>全部</li>
            //         { locations && Object.keys(locations).map((location_id, index) => {
            //           let location = locations[location_id]
            //           return (
            //             <li key={ index } onClick={()=>this._setFilter({ 'location_id' : location.id})}>
            //               <span>{location.name}</span>
            //             </li>
            //           )})
            //         }
            //       </ul>
            //     </div>
            //   </KBDropdown.KBDropdownContent>
            // </KBDropdown>
          }
        </div>
      </div>
    )
  },

  render() {
    const { loading, posts, pagination } = this.state
    const styleNoData = {
      height: '280px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8,
      paddingTop: 40,
      cursor: 'default'
    }

    return (
      <section className="kb-content-container">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>社群管理</span>
          </div>
        </header>
        <div className="nav-section-content-container">
          <div>
            {this._renderFilters()}
            {Object.keys(this.state.filters).length > 0 ? (
              <div className="clear-criteria" onClick={this._clearFilters}>
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : null}
          </div>
          <KBLoadingContainer loading={loading}>
            {(posts.length > 0 &&
              posts.map((post, index) => {
                return (
                  <PostBlock post={post} deleteCallback={this.updatePostList} />
                )
              })) || (
              <KBNoData tipMessage="暂时没有帖子哦" outterStyle={styleNoData} />
            )}
          </KBLoadingContainer>
          <KBPagination
            pagination={pagination}
            template={`/admin/memberships/memberManage/posts?page=#PAGE#`}
          />
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page
  let locations = selectors.getLocationsAsDictionary(state)
  var FILTER_STATE = ['category', 'info', 'location_id']

  var param = getFilterAndQuery(location.query, FILTER_STATE)

  return {
    page,
    locations,
    ...param
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostList)
