import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import { api, apiUtils } from 'app/services'
import { KBAvatar } from 'components'
import {
  locationActions,
  getLocationMembersAction,
  floorplansAction,
  locationRoleAction,
  locationSettingAction,
  areaActions,
  reservationsActions,
  loadingAction,
  meetingsActions,
  orgActions,
  apiCallFailure,
  groupsActions
} from 'app/actions'
import classNames from 'classnames'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  ORG_SUBSCRIPTION_STATES,
  ORG_SUBSCRIPTION_STATES_CLASS,
  INVOICES_STATE,
  INVOICES_STATE_CLASS,
  OFFICE_AREA_STATE
} from 'app/constants'
import { util, isHideModule, groupLocationsByCity } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import {
  canAdminSpace,
  canAccessLocationRoles,
  canSpaceAdmin,
  canAccessLocation,
  canAccessLocationObserver,
  canAccessLocationOnlyFinance,
  canAccessLocationRepairOnly
} from 'app/reducers/role'
import * as kbData from 'utils/kbData'
import _ from 'lodash'
import { getUserInfoUrl } from 'utils/kbUrl'

var GroupBox = React.createClass({
  displayName: 'GroupBox',
  getInitialState() {
    return {
      invoices: [],
      contracts: [],
      desks: [],
      searchResult: [],
      searchingOrgs: false
    }
  },

  changeParentScrollTop() {
    ReactDOM.findDOMNode(this).scrollTop = 0
  },

  componentDidMount() {
    const { location_id, isRepair, routerActions } = this.props
    this._loadData(location_id)
  },

  _loadData(location_id) {
    const { isRepair, user, getGroupsInfoAction } = this.props

    api.getGroupsInfo(location_id).then(
      json => {
        getGroupsInfoAction.success(json)
      },
      errors => {
        getGroupsInfoAction.failure(errors)
      }
    )
  },

  selectKbOutBox() {},

  render() {
    const { location_id, locations, user, isRepair } = this.props
    if (!locations.length) {
      return <div></div>
    }
    return (
      <div className="kb-out-box clear-fix">
        {this.props.children && (
          <ReactCSSTransitionGroup
            onClick={this.selectKbOutBox}
            className="margin-top-lg"
            component="div"
            transitionName="example"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
            transitionLeave={false}
          >
            {React.cloneElement(this.props.children, {
              key: this.props.pathname,
              changeParentScrollTop: this.changeParentScrollTop
            })}
          </ReactCSSTransitionGroup>
        )}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let location_id = props.params.id
  let locations = selectors.getLocations(state)
  let user = selectors.getCurrentUser(state)
  var pathname = props.location.pathname.split('/')
  // let isManager = canAccessLocation(user, location_id)
  // let isRepair = canAccessLocationRepairOnly(user, location_id)
  const group = selectors.getCurrentGroup(state, location_id)

  if (pathname.length > 5) {
    pathname = pathname.slice(0, 5)
  }

  return {
    pathname: pathname.join('/'),
    location_id,
    locations,
    user,
    // isManager,
    // isRepair,

    group
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getGroupsInfoAction: bindActionCreators(groupsActions.update, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupBox)
