import React, { Component, PropTypes } from 'react'
import { Button } from 'antd'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { orgActions, areaActions } from 'app/actions'
import { connect } from 'react-redux'
import { subscriptionActions } from 'app/actions'
import { canAccessSpaceContract, canSpaceAdmin } from 'app/reducers/role'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'

var OrganizationAuditSuccess = React.createClass({
  displayName: 'OrganizationAuditSuccess',
  getInitialState() {
    const { filters } = this.props
    return {
      nextLoading:false
    }
  },
  componentDidMount() {
    const { filters } = this.props
  },
  nextStep() {
    const {
      setNextAndCurrentSubscription,
      updateSubscriptionActions,
      currentSubscription
    } = this.props
    this.setState({nextLoading:true}, ()=>{
      api
      .putSubscriptions(currentSubscription.id, {
        step: 'sign'
      })
      .then(json => {
        updateSubscriptionActions.success(json)
        let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
        setNextAndCurrentSubscription(subscription, 'sign')
        this.setState({nextLoading:false})
      })
    })

  },

  returnStep() {
    const { routerActions } = this.props
    routerActions.go(-1)
  },

  render() {
    const { user, isParkingContract } = this.props
    const {nextLoading} = this.state
    const isCanContract = canAccessSpaceContract(user)
    const isAdmin = canSpaceAdmin(user)

    return (
      <div
        className="t-center"
        style={isParkingContract ? { paddingTop: 100 } : {}}
      >
        <div className="flex-horizontal-center">
          <img
            src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/complete.png"
            alt="complete"
            style={{ width: 80, height: 80, marginRight: 20 }}
          />
          <div
            className="flex-horizontal-center"
            style={{ flexDirection: 'column' }}
          >
            <div style={{ fontSize: 20 }} className="t-left">
              合同完成
            </div>
          </div>
        </div>
        <div style={{ marginTop: 40 }}>
          {isAdmin ? (
            <Button
              className="bordered-btn"
              style={{ padding: '7px 25px', marginLeft: 0 }}
              onClick={this.nextStep}
              loading={nextLoading}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;下一步&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          ) : !isCanContract ? (
            <Button
              className="bordered-btn"
              style={{ padding: '7px 25px', marginLeft: 0 }}
              onClick={this.nextStep}
              loading={nextLoading}
            >
              下一步
            </Button>
          ) : (
            ''
          )}
        </div>
        {isCanContract ? (
          <div style={{ marginTop: 40 }}>
            <Button
              className="bordered-btn"
              style={{ padding: '7px 25px', marginLeft: 0 }}
              onClick={this.returnStep}
            >
              返回上一步
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubscriptionActions: bindActionCreators(
      subscriptionActions.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationAuditSuccess)
