import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { lockPermissionsActions } from 'app/actions'
import { DAY_TIME_HM } from 'app/constants'
import { api, apiUtils } from 'app/services'
import {
  KBPopover,
  KBUserInput,
  KBFormError,
  KBButton,
  KBDateInput,
  KBSelect
} from 'components'
import moment from 'moment'
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
const { Option } = Select;

var LockPermissionsForm = React.createClass({
  getInitialState() {
    return {
      users: null,
      userList: [],
      userSearchLoading: false
    }
  },

  componentDidMount() {
  },

  createLockForm(model) {
    const { loc_id, createLocksPermissionsAction } = this.props
    return api.createLockPermissions(loc_id, model).then(json => {
      createLocksPermissionsAction.success(json)
      KBPopover.close()
      return true
    })
  },

  changeStartYMDCallback(data) {
    const {
      fields: { lock_permissions }
    } = this.props
    this.setYMD(lock_permissions[0].start_at, data)
  },

  changeEndYMDCallback(data) {
    const {
      fields: { lock_permissions }
    } = this.props
    this.setYMD(lock_permissions[0].end_at, data)
  },

  changeStartHMCallback(data) {
    const {
      fields: { lock_permissions }
    } = this.props
    this.setHM(lock_permissions[0].start_at, data)
  },

  changeEndHMCallback(data) {
    const {
      fields: { lock_permissions }
    } = this.props
    this.setHM(lock_permissions[0].end_at, data)
  },

  setYMD(field, value) {
    let date = moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  setHM(field, value) {
    let t = value.split(',')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ hour: t[0], minute: t[1] == '1' ? 0 : 30, second: 0 })
        .toDate()
    )
  },

  searchUser(searchString = '') {
    const value = typeof searchString === 'string' ? searchString.trim() : ''
    if (value) {
      const { loc_id } = this.props
      this.setState({ userSearchLoading: true })
      api
        .getUsers({
          location_id: loc_id,
          page: 1,
          per_page: 999999,
          includes: 'space_users',
          name: value
        })
        .then(({ json }) => {
          const list = json.entities.users ? Object.values(json.entities.users) : []
          this.setState({ userList: list })
        }).finally(() => {
          this.setState({ userSearchLoading: false })
        })
    } else {
      this.setState({ userList: [], userSearchLoading: false });
    }
  },

  render() {
    const {
      fields: { lock_permissions },
      error,
      submitting,
      handleSubmit,
      locks,
      operateType,
      DEAL_DAY_TIME_HM
    } = this.props
    const { users, userSearchLoading, userList } = this.state
    var halfClass = {
      className: 'kb-form-group kb-div-c48'
    }
    let usersArray = this.state.users && Object.values(this.state.users)
    return (
      <form onSubmit={handleSubmit(this.createLockForm)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">添加门禁权限</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        {lock_permissions.length > 0 &&
          lock_permissions.map((permissions, index) => {
            return (
              <div className="kb-form-container-m" key={index}>
                {operateType == 'create' ? (
                  <div className="kb-row">
                    <div className="kb-form-group kb-form-210 kb-form-alone">
                      <label>会员<span className="must-fill">*</span></label>
                    </div>
                    <Select
                      showSearch
                      // value={permissions.user_id.value}
                      placeholder="输入名称搜索会员"
                      notFoundContent={userSearchLoading ? <Spin size="small" /> : undefined}
                      showArrow={false}
                      filterOption={false}
                      onSearch={debounce(this.searchUser, 500)}
                      onChange={value => permissions.user_id.onChange(value)}
                    >
                      {
                        userList.map(item => {
                          return <Option key={item.id} value={item.id}>{item.name}</Option>
                        })
                      }
                    </Select>
                    {
                      permissions.user_id.touched && permissions.user_id.error && <p className="lr-error">{permissions.user_id.error}</p>
                    }
                  </div>
                ) : (
                  <div className="kb-row">
                    <KBUserInput
                      title="门禁名称"
                      placeholder="选择门禁"
                      className="kb-form-group kb-form-210 kb-form-alone"
                      field={permissions.lock_id}
                      isRequired={true}
                      multi={false}
                      users={locks}
                      selectedUsers={[]}
                    />
                  </div>
                )}
                <div className="kb-row kb-form-66">
                  <KBDateInput
                    type="date"
                    title="开始日期"
                    isRequired={true}
                    className="kb-form-group kb-div-48"
                    field={permissions.startYMD}
                    onChange={this.changeStartYMDCallback}
                  />
                  <KBSelect
                    title="开始时间"
                    onChange={this.changeStartHMCallback}
                    titleClass="label-rt"
                    arrayData={DEAL_DAY_TIME_HM}
                    field={permissions.startHM}
                    {...halfClass}
                  />
                </div>
                <div className="kb-row kb-form-66">
                  <KBDateInput
                    type="date"
                    title="结束日期"
                    isRequired={true}
                    className="kb-form-group kb-div-48"
                    field={permissions.endYMD}
                    onChange={this.changeEndYMDCallback}
                  />
                  <KBSelect
                    title="结束时间"
                    onChange={this.changeEndHMCallback}
                    titleClass="label-rt"
                    arrayData={DEAL_DAY_TIME_HM}
                    field={permissions.endHM}
                    {...halfClass}
                  />
                </div>
                <div className="kb-row fixed-form-btn">
                  <span className="cancel-btn" onClick={KBPopover.close}>
                    取消
                  </span>
                  <KBButton
                    className="certain-btn"
                    type="submit"
                    submitting={submitting}
                  >
                    确定
                  </KBButton>
                </div>
              </div>
            )
          })}
      </form>
    )
  }
})

//

function mapStateToProps(state, props) {
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(0, 24)
  return {
    DEAL_DAY_TIME_HM
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.create,
      dispatch
    )
  }
}

const validatePermissions = Permissions => {
  const errors = {}

  if (valid.required(Permissions.user_id)) {
    errors.user_id = '请选择会员'
  }

  if (valid.required(Permissions.lock_id)) {
    errors.lock_id = '请选择门禁'
  }

  if (
    Permissions.start_at &&
    Permissions.end_at &&
    moment(Permissions.end_at).diff(moment(Permissions.start_at)) <= 0
  ) {
    errors.endHM = '结束日期必须大于开始日期'
  }

  return errors
}

const validate = values => {
  const errors = {}

  errors.lock_permissions = values.lock_permissions.map(validatePermissions)

  return errors
}

const formConfig = {
  form: 'MeetingForm',
  fields: [
    'lock_permissions[].user_id',
    'lock_permissions[].start_at',
    'lock_permissions[].end_at',
    'lock_permissions[].lock_id',
    'lock_permissions[].startHM',
    'lock_permissions[].endHM',
    'lock_permissions[].startYMD',
    'lock_permissions[].endYMD'
  ],
  validate: validate,
  touchOnBlur: false
}

LockPermissionsForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LockPermissionsForm)

export default LockPermissionsForm
