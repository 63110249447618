import React from 'react'
import { TimePicker } from 'antd'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { apiCallFailure, successState } from 'app/actions'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import { KBInput, KBSelect, KBButton, KBPopover, KBForm } from 'components'
import { getServiceErrorMessage } from 'utils/kbUtil'
import intl from 'react-intl-universal'

const format = 'HH:mm'

var LightForm = React.createClass({
  getInitialState() {
    return {
      WEEK_ARRAY: [
        { title: '周一', check: false, value: 1 },
        { title: '周二', check: false, value: 2 },
        { title: '周三', check: false, value: 3 },
        { title: '周四', check: false, value: 4 },
        { title: '周五', check: false, value: 5 },
        { title: '周六', check: false, value: 6 },
        { title: '周日', check: false, value: 7 }
      ]
    }
  },
  componentDidMount() {
    const { WEEK_ARRAY } = this.state
    const { weekly_days } = this.props
    for (let i = 0; i < WEEK_ARRAY.length; i++) {
      if (weekly_days) {
        for (let j = 0; j < weekly_days.length; j++) {
          if (WEEK_ARRAY[i].value == weekly_days[j]) {
            WEEK_ARRAY[i].check = true
          }
        }
      } else {
        WEEK_ARRAY[i].check = true
      }
    }
  },
  editCabinet(model) {
    const { cabinet, callBack, successAction } = this.props
    const { WEEK_ARRAY } = this.state
    // if (model.turn_on_time === '00:00') model.turn_on_time = 0;
    // if (model.turn_off_time === '00:00') model.turn_off_time = 0;
    if (typeof model.turn_on_time === 'string') {
      model.turn_on_time = this.parseTime(model.turn_on_time)
    }
    if (typeof model.turn_off_time === 'string') {
      model.turn_off_time = this.parseTime(model.turn_off_time)
    }

    const weekly_days = []
    WEEK_ARRAY.filter(item => {
      if (item.check) {
        weekly_days.push(item.value)
      }
    })
    model.auto_switch_settings = JSON.stringify({
      turn_on_time: model.turn_on_time,
      turn_off_time: model.turn_off_time,
      weekly_days
    })
    delete model.turn_on_time
    delete model.turn_off_time
    if (!model.auto_switch) {
      delete model.auto_switch_settings
    }

    api
      .editSensor(cabinet.id, {
        id: cabinet.id,
        assetable_id: cabinet.assetable_id,
        assetable_type: cabinet.assetable_type,
        device_id: cabinet.device_id,
        sensor_type: cabinet.sensor_type,
        ...model
      })
      .then(
        json => {
          successAction('操作成功')
          callBack && callBack()
          KBPopover.close()
        },
        error => {}
      )
      .finally(() => {})
  },

  closeLight(id) {
    const { successAction, callBack, apiCallFailureActions } = this.props
    api
      .switchSensors(id, {
        id,
        switch_type: false
      })
      .then(
        json => {
          successAction('操作成功')
          callBack && callBack()
          KBPopover.close()
        },
        error => {
          apiCallFailureActions(error)
        }
      )
      .finally(() => {})
  },

  openLight(id) {
    const { successAction, callBack, apiCallFailureActions } = this.props
    api
      .switchSensors(id, {
        id,
        switch_type: true
      })
      .then(
        json => {
          successAction('操作成功')
          callBack && callBack()
          KBPopover.close()
        },
        error => {
          apiCallFailureActions(error)
        }
      )
      .finally(() => {})
  },

  changeSlide() {
    const {
      fields: { name, status, auto_switch }
    } = this.props
    auto_switch.onChange(!auto_switch.value)
  },

  startTimeChange(time) {
    const {
      fields: { turn_on_time }
    } = this.props
    const start = moment(time).format(format)
    turn_on_time.onChange(this.parseTime(start))
  },

  endTimeChange(time) {
    const {
      fields: { turn_off_time }
    } = this.props
    const end = moment(time).format(format)
    turn_off_time.onChange(this.parseTime(end))
  },

  parseTime(time) {
    const hour = Number(time.split(':')[0])
    const min = Number(time.split(':')[1])
    return hour * 60 + min
  },

  render() {
    const {
      fields: { pwr, name, status, auto_switch, turn_on_time, turn_off_time },
      handleSubmit,
      submitting,
      id,
      LIGHT_STATUS,
      LIGHT_OPEN_STATUS
    } = this.props
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.editCabinet)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">详情</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBSelect
              disabled
              className="kb-form-group kb-div-c18 kb-form-alone"
              title="状态"
              field={status}
              arrayData={LIGHT_STATUS}
            />
          </div>
          <div className="kb-row">
            <KBInput
              disabled
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="设备名称"
            />
          </div>
          <div className="kb-row">
            <KBSelect
              disabled
              className="kb-form-group kb-div-c18 kb-form-alone"
              title="状态"
              field={pwr}
              arrayData={LIGHT_OPEN_STATUS}
            />
          </div>
          <div className="kb-row">
            <KBButton
              type="button"
              onClick={() => this.closeLight(id)}
              className="certain-delete-btn"
            >
              关闭
            </KBButton>
            <KBButton
              type="button"
              onClick={() => this.openLight(id)}
              className="certain-btn"
              style={{ marginLeft: 12 }}
            >
              开启
            </KBButton>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 30,
              marginBottom: 20
            }}
          >
            <div
              className="slide-btn"
              onClick={() => {
                this.changeSlide()
              }}
            >
              <span
                className={`slide-bg ${
                  !!auto_switch.value === true ? 'slide-left' : 'slide-right'
                }`}
              >
                <span className="slide-move" />
              </span>
            </div>
            <span
              style={{ marginLeft: 10, marginBottom: 5 }}
            >{`点击开启/关闭自动开关灯功能`}</span>
          </div>
          {!!auto_switch.value === true && (
            <div className="kb-row">
              <span>自动开灯时间</span>
              <TimePicker
                onChange={this.startTimeChange}
                allowClear={false}
                minuteStep={10}
                popupStyle={{ zIndex: 10001 }}
                defaultValue={moment(turn_on_time.value, format)}
                format={format}
              />
            </div>
          )}
          {!!auto_switch.value === true && (
            <div className="kb-row">
              <span>自动关灯时间</span>
              <TimePicker
                onChange={this.endTimeChange}
                allowClear={false}
                minuteStep={10}
                popupStyle={{ zIndex: 10001 }}
                defaultValue={moment(turn_off_time.value, format)}
                format={format}
              />
            </div>
          )}
          {!!auto_switch.value === true && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label className="label">每周:</label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 30
                }}
              >
                {this.state.WEEK_ARRAY.map((item, index) => {
                  return (
                    <div
                      style={{
                        border: 'solid 1px #C0C0C0',
                        width: '50px',
                        display: 'flex',
                        color: item.check ? '#ffffff' : '#999999',
                        background: item.check ? '#007AFF' : '#ffffff',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      key={index}
                      onClick={() => {
                        let weeks = this.state.WEEK_ARRAY
                        let item = weeks[index]
                        item.check = !item.check
                        this.setState({
                          WEEK_ARRAY: weeks
                        })
                      }}
                    >
                      {item.title}
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              关闭
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state) {
  const LIGHT_STATUS = [
    {
      id: 'online',
      name: '在线'
    },
    {
      id: 'offline',
      name: '离线'
    },
    {
      id: 'expired',
      name: '长期离线'
    },
    {
      id: 'never_reported',
      name: '未上报'
    }
  ]
  const LIGHT_OPEN_STATUS = [
    {
      id: '0',
      name: '关'
    },
    {
      id: '255',
      name: '开'
    }
  ]
  return {
    LIGHT_OPEN_STATUS,
    LIGHT_STATUS
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  // if (valid.required(values.section)) {
  //   errors.section = '请输入锁控板号'
  // }

  return errors
}

const formConfig = {
  form: 'LightForm',
  fields: [
    'name',
    'status',
    'auto_switch',
    'turn_on_time',
    'turn_off_time',
    'pwr'
  ],
  validate: validate,
  touchOnBlur: false
}

LightForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LightForm)

export default LightForm
