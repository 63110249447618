import React, { Component } from 'react'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import * as selectors from 'app/selectors'
import { api, apiUtils } from 'app/services'
import {
  paginate,
  getFilterAndQuery,
  queryFilter,
  toImageProxyDirect
} from 'utils/kbUtil'
import {
  salesProductsActions,
  successState,
  productCategoriesActions
} from 'app/actions'
import {
  KBPopover,
  KBPopoverTop,
  KBError,
  KBSuccess,
  KBPagination,
  KBTableWithoutData
} from 'components'
import SalesProductsForm from './SalesProductsForm'
import { canAccessSpaceShop } from 'app/reducers/role'

var SalesProducts = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      selectState: true,
      numberType: filters.type || 'all',
      productStatus: filters.status || '',
      pagination: null
    }
  },

  componentDidMount() {
    const { productCategoriesActions, shopping_id } = this.props

    this.mounted = true

    api.getProductCategories({ shop_id: shopping_id, per_page: 50 }).then(
      json => {
        productCategoriesActions.success(json)
      },
      errors => {
        productCategoriesActions.failure(errors)
      }
    )

    this.loadData(1)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    let { productStatus } = this.state
    if (productStatus == '') {
      productStatus = ''
    }
    if (nextProps.page !== this.props.page) {
      this.loadData(nextProps.page, productStatus)
    }
  },

  loadData(page = 1, status) {
    const { productStatus, numberType } = this.state
    status = status || productStatus
    const per_page = 15
    const {
      salesProductsActions,
      shopping_id,
      query,
      routerActions
    } = this.props
    let params = Object.assign(
      {},
      { status: status || '' },
      { type: numberType || 'all' },
      { shop_id: shopping_id },
      { page: page },
      { per_page: per_page }
    )
    let queryFiltersStr = queryFilter({ ...query, ...params })
    api
      .getSalesProducts({
        status: status || '',
        shop_id: shopping_id,
        page: page,
        per_page: per_page
      })
      .then(
        json => {
          let pagination = paginate(json.response)
          salesProductsActions.success(json, {
            entityName: 'salesProducts'
          })
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/shopping/${shopping_id}${queryFiltersStr}`
          )
          this.setState({
            pagination: pagination
          })
        },
        errors => {
          salesProductsActions.failure(errors)
        }
      )
  },

  addSalesProducts() {
    const { shopping_id } = this.props
    KBPopover.show(<SalesProductsForm shopping_id={shopping_id} />)
  },
  updateSalesProducts(product) {
    const { shopping_id } = this.props
    product.number = product.number.toString()
    KBPopover.show(
      <SalesProductsForm
        shopping_id={shopping_id}
        initialValues={product}
        product={product}
        state="update"
      />
    )
  },
  deleteSalesProducts(product) {
    const { deleteSalesProductsActions, successAction } = this.props
    KBPopover.plugins.confirm(
      '删除商品',
      `您确定要删除当前商品${product.title}吗?`,
      {
        confirm: () => {
          api.deleteSaleProducts(product.id).then(
            json => {
              deleteSalesProductsActions.success(json, {
                key: 'salesProducts',
                id: product.id
              })
              successAction({ message: '删除商品成功!' })
              KBPopover.close()
            },
            error => {
              deleteSalesProductsActions.failure(error)
            }
          )
        }
      }
    )
  },

  selectProductOfNumber(type) {
    const { productStatus } = this.state
    this.setState({
      numberType: type
    })
    const per_page = 15
    const { shopping_id, query, routerActions } = this.props
    let params = Object.assign(
      {},
      { status: productStatus || '' },
      { type: type || 'all' },
      { shop_id: shopping_id },
      { page: 1 },
      { per_page: per_page }
    )
    let queryFiltersStr = queryFilter({ ...query, ...params })
    routerActions.replace(`/admin/shopping/${shopping_id}/${queryFiltersStr}`)
  },

  selectProductOfStatus(status) {
    this.setState(
      {
        productStatus: status
      },
      () => {
        if (!status) {
          return this.loadData(1)
        }
        return this.loadData(1, status)
      }
    )
  },

  render() {
    let { salesProducts, currencyName, shopping_id, user } = this.props
    const { numberType, productStatus } = this.state
    const canOperateShop = canAccessSpaceShop(user)

    if (numberType == 'none') {
      salesProducts = salesProducts.filter(product => product.number == 0)
    }
    if (numberType == 'exist') {
      salesProducts = salesProducts.filter(product => product.number != 0)
    }
    return (
      <div style={{ padding: '20px 30px' }}>
        <div style={{ display: 'flex' }}>
          <div className="common-tab" style={{ marginRight: 30 }}>
            <div
              className={`tab-list ${
                numberType == 'all' ? 'tab-list_active' : ''
              }`}
              onClick={this.selectProductOfNumber.bind(null, 'all')}
            >
              <span className="radio" />
              <span className="title">全部</span>
            </div>
            <div
              className={`tab-list ${
                numberType == 'none' ? 'tab-list_active' : ''
              }`}
              onClick={this.selectProductOfNumber.bind(null, 'none')}
            >
              <span className="radio" />
              <span className="title">无库存</span>
            </div>
            <div
              className={`tab-list ${
                numberType == 'exist' ? 'tab-list_active' : ''
              }`}
              onClick={this.selectProductOfNumber.bind(null, 'exist')}
            >
              <span className="radio" />
              <span className="title">有库存</span>
            </div>
          </div>
          <div className="common-tab">
            <div
              className={`tab-list ${
                productStatus == '' ? 'tab-list_active' : ''
              }`}
              onClick={this.selectProductOfStatus.bind(null, '')}
            >
              <span className="radio" />
              <span className="title">全部</span>
            </div>
            <div
              className={`tab-list ${
                productStatus == 'on_sale' ? 'tab-list_active' : ''
              }`}
              onClick={this.selectProductOfStatus.bind(null, 'on_sale')}
            >
              <span className="radio" />
              <span className="title">上架</span>
            </div>
            <div
              className={`tab-list ${
                productStatus == 'off_sale' ? 'tab-list_active' : ''
              }`}
              onClick={this.selectProductOfStatus.bind(null, 'off_sale')}
            >
              <span className="radio" />
              <span className="title">下架</span>
            </div>
          </div>
        </div>
        <table className="content-table">
          <thead>
            <tr>
              <th>商品信息</th>
              <th>售价</th>
              <th>进价</th>
              <th>库存数</th>
              <th>销售数目</th>
              <th />
            </tr>
          </thead>
          <KBTableWithoutData
            hasData={salesProducts.length > 0}
            tableHeadNum="6"
            tipMessage="暂无商品"
          >
            {salesProducts &&
              salesProducts.map((product, index) => {
                const shop_images =
                  (product.images && product.images[0]) ||
                  'https://media-ssl.kuban.io/static/web/v1/images/product_default.png'
                return (
                  <tr key={index}>
                    <td style={{ display: 'flex', position: 'relative' }}>
                      <img
                        src={toImageProxyDirect(shop_images, 90, 90)}
                        alt="image"
                        style={{ height: 90, width: 90 }}
                      />
                      {product.number == 0 ? (
                        <span className="inventory-number">0</span>
                      ) : null}
                      <div className="product-info">
                        <div className="title">
                          <span>{product.title}</span>
                          {product.status == 'off_sale' ? (
                            <span>已下架</span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="product-category">
                          <span>
                            {product.product_category &&
                              product.product_category.name}
                          </span>
                        </div>
                        <div>
                          <span style={{ fontSize: 10 }}>
                            商品编号: {product.sku || '无'}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div style={{ marginBottom: 20 }}>
                        ¥ {product.unit_price}
                      </div>
                      <div>
                        {product.point}
                        {currencyName || '积分'}
                      </div>
                    </td>
                    <td>{product.cost != null ? `¥ ${product.cost}` : ''}</td>
                    <td>{product.number}</td>
                    <td>
                      {product.sales_number == 0 ? (
                        product.sales_number
                      ) : canOperateShop ? (
                        <Link
                          className="c-pointer"
                          to={`/admin/shopping/${shopping_id}/product_orders?shop_id=${shopping_id}&page=1&product_id=${product.id}`}
                        >
                          <img
                            src="https://media-ssl.kuban.io/static/web/v1/images/icon-number.png"
                            alt="icon"
                            style={{
                              marginRight: 8,
                              width: 12,
                              verticalAlign: 'middle'
                            }}
                          />
                          <span
                            style={{
                              color: '#2ea1f8',
                              verticalAlign: 'middle'
                            }}
                          >
                            {product.sales_number}
                          </span>
                        </Link>
                      ) : (
                        <a className="c-pointer">
                          <img
                            src="https://media-ssl.kuban.io/static/web/v1/images/icon-number.png"
                            alt="icon"
                            style={{
                              marginRight: 8,
                              width: 12,
                              verticalAlign: 'middle'
                            }}
                          />
                          <span
                            style={{
                              color: '#2ea1f8',
                              verticalAlign: 'middle'
                            }}
                          >
                            {product.sales_number}
                          </span>
                        </a>
                      )}
                    </td>
                    {canOperateShop ? (
                      <td className="t-right">
                        <button
                          className="bordered-btn-small"
                          onClick={this.updateSalesProducts.bind(null, product)}
                        >
                          修改信息
                        </button>
                        <button
                          className="bordered-btn-small m-left-sm"
                          onClick={this.deleteSalesProducts.bind(null, product)}
                        >
                          删除
                        </button>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                )
              })}
          </KBTableWithoutData>
        </table>
        <KBPagination
          pagination={this.state.pagination}
          template={`/admin/shopping/${shopping_id}?page=#PAGE#`}
        />
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  const salesProducts = selectors.getSalesProducts(state)
  let shopping_id = parseInt(props.params.shopping_id)
  let spaceSetting = selectors.getSpaceSetting(state)
  let currencyName =
    spaceSetting &&
    spaceSetting.virtual_currency_settings &&
    spaceSetting.virtual_currency_settings.virtual_currency_name
  // let { settings: { virtual_currency_name } } = spaceSetting
  // let currencyName = virtual_currency_name
  let page = parseInt(location.query && location.query.page) || 1
  let user = selectors.getCurrentUser(state)
  var FILTER_STATE = ['status', 'type']
  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)
  return {
    salesProducts,
    shopping_id,
    currencyName,
    page,
    user,
    ...paramsAll
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    salesProductsActions: bindActionCreators(
      salesProductsActions.replace,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch),
    productCategoriesActions: bindActionCreators(
      productCategoriesActions.get,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    deleteSalesProductsActions: bindActionCreators(
      salesProductsActions.delete,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesProducts)
