import React, { Component } from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { KBAvatar } from 'components'
import { currentUserActions, successState } from 'app/actions'
import classNames from 'classnames'
import * as selectors from 'app/selectors'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { apiUtils } from 'app/services'

class UserSettingBox extends Component {
  updateUserForm = model => {
    if (model.phone_num && model.phone_num.includes('*')) {
      delete model.phone_num
    }
    const { updateCurrentUserActions, successAction, user } = this.props
    return api.currentUpdateUserInfo(user.id, model).then(data => {
      successAction({ message: '保存成功!' })
      let currentUser = data.json.entities.users[data.json.result]
      const updateUser = Object.assign({}, user, currentUser)
      apiUtils.setCurrentUser(updateUser)
      data.json.entities.users[data.json.result] = updateUser
      updateCurrentUserActions.success(data)
    })
  }

  updateUserPasswordForm = model => {
    const { successAction, user } = this.props
    return api.updatePassword(user.id, model).then(data => {
      successAction({ message: '保存成功!' })
    })
  }

  menus = [
    { label: '个人信息', section: '' },
    { label: '安全设置', section: 'password' }
  ]

  render() {
    let menus = this.menus
    const { section, user } = this.props

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        updateUserForm: this.updateUserForm,
        updateUserPasswordForm: this.updateUserPasswordForm,
        user
      })
    )

    return (
      <div className="kb-out-box clear-fix">
        <section className="kb-content">
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <div className="user-message">
                <KBAvatar
                  user={user}
                  style={{ display: 'inline-block', float: 'left' }}
                  size={44}
                />
                <div className="user-name-tel">
                  <span>{user.name}</span>
                  <span>{user.phone_num}</span>
                </div>
              </div>
            </div>
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">个人设置</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menus.map((menu_item, index) => {
                    let active = menu_item.section === section ? true : false
                    return (
                      <li
                        key={index}
                        className={classNames(
                          active
                            ? 'nav-container-list-hover select-list-hover'
                            : ''
                        )}
                      >
                        <Link
                          to={`/admin/user_setting/${menu_item.section}`}
                          className="d-block"
                        >
                          {' '}
                          {menu_item.label}{' '}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          <ReactCSSTransitionGroup
            component="div"
            transitionName="example"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
            transitionLeave={false}
            style={{ height: '100%' }}
          >
            {childrenWithProps}
          </ReactCSSTransitionGroup>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  let sections = props.location.pathname.split('/')
  let section = sections[3] || ''
  const user = selectors.getMemberById(state, state.user.id)
  return {
    user,
    section,
    pathname: props.location.pathname
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateCurrentUserActions: bindActionCreators(
      currentUserActions.update,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingBox)
