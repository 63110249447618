import React, { Component, PropTypes } from 'react'
import { Link } from 'react-router'
import {
  KBDropdown,
  KBRangeDate,
  KBTableWithoutData,
  KBLoadingContainer,
  KBPagination
} from 'components'
import { connect } from 'react-redux'
import { api, apiUtils } from 'app/services'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import * as selectors from 'app/selectors'
import { reservationsActions } from 'app/actions'
import {
  getEntitiesJsonArray,
  queryFilter,
  getFilterAndQuery,
  formatOnlyMinuteSecondEN,
  formatYearDayEN,
  paginate
} from 'utils/kbUtil'
import moment from 'moment'
import { getInitialDate } from 'utils/kbData'
import {
  getUserInfoUrl,
  getMeetingRoomDetailPage,
  getUrlOfLocationHomePage
} from 'utils/kbUrl'

const FILTER_STATE = ['from', 'to', 'area_id']

var MembersMeetRecord = React.createClass({
  displayName: 'MembersMeetRecord',
  getInitialState() {
    const { filters } = this.props
    return {
      reservations: [],
      filters,
      pagination: null,
      loading: false
    }
  },

  componentDidMount() {
    const { filters, page } = this.props
    if (!filters.from) {
      filters.from = moment()
        .subtract(1, 'months')
        .toDate()
    }
    if (!filters.to) {
      filters.to = moment().toDate()
    }
    this.mounted = true
    this._loadData(page, filters)
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page !== nextProps.page) {
      this._loadData(nextProps.page, { ...this.state.filters, ...filters })
    }
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(page, filters) {
    const {
      member,
      loc_id,
      getReservationsAction,
      routerActions,
      query,
      isMemberShipPath
    } = this.props
    const { from, to } = filters

    const per_page = 10
    let pageObj = { per_page, page }

    let params = Object.assign({}, filters, pageObj)

    if (from && to) {
      params.start_at = from && getInitialDate(from).initStartDateString
      params.end_at = to && getInitialDate(to).initEndDateString
    }

    delete params.from
    delete params.to

    let queryFiltersStr = queryFilter({ ...query, ...params, ...pageObj })
    this.setState({ loading: true })
    api
      .getReservations({ user_id: member.id, ...params })
      .then(
        json => {
          getReservationsAction.success(json)
          let reservations = []
          reservations = getEntitiesJsonArray(json, 'reservations')
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `${getUserInfoUrl(
              member.id,
              isMemberShipPath
            )}/meeting${queryFiltersStr}`
          )
          let pagination = paginate(json.response)

          this.setState({
            reservations,
            pagination
          })
          return true
        },
        errors => {
          getReservationsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  _clearFilters() {
    this.setState({ filters: {} })
    this._refresh(null, {})
  },

  _setFilter(key, value, obj) {
    let newFilters = Object.assign(
      {},
      this.state.filters,
      obj ? obj : { [key]: value }
    )
    this.setState({
      filters: newFilters
    })
    this._refresh(key, newFilters)
  },

  _refresh(key, filters) {
    let dropDown = key + 'DropDown'
    this.refs[dropDown] && this.refs[dropDown].hide()
    setTimeout(this._loadData(filters.page || 1, filters))
  },

  changeDate(range) {
    this._setFilter(null, null, range)
  },

  _renderFilters() {
    const { filters } = this.state
    const { from, to } = filters
    return (
      <div className="nav-section-action-bar">
        <KBRangeDate
          from={from || ''}
          to={to || ''}
          leftNone={true}
          callback={this.changeDate}
        />
      </div>
    )
  },

  render() {
    const { member, locations, loc_id } = this.props
    const { reservations, pagination, loading } = this.state
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    return (
      <section className="nav-section-container f-right">
        <div style={{ height: '100%' }}>
          <header className="nav-header clear-fix">
            <div className="nav-header-title">
              <h2 className="header-title f-left" style={{ fontSize: 22 }}>
                会议室记录
              </h2>
            </div>
          </header>
          <div className="nav-section-content-container">
            <div className="m-top">
              {this._renderFilters()}
              {selectFilters.length > 0 ? (
                <div
                  className="clear-criteria"
                  style={{ marginTop: 5 }}
                  onClick={this._clearFilters}
                >
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
            <KBLoadingContainer loading={loading}>
              <table className="content-table">
                <thead>
                  <tr>
                    <th>分店名称</th>
                    <th>会议室名称</th>
                    <th>开始时间</th>
                    <th>结束时间</th>
                    <th>日期</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={reservations.length > 0}
                  tableHeadNum="5"
                  tipMessage="暂无数据"
                >
                  {reservations &&
                    reservations.map((reservations, index) => {
                      let location = locations[reservations.location_id]
                      let loc_id = location && location.id
                      return (
                        <tr key={index}>
                          <td>
                            <Link
                              className="color-link"
                              to={getUrlOfLocationHomePage(loc_id)}
                            >
                              {location && location.name}
                            </Link>
                          </td>
                          <td>
                            <Link
                              className="color-link"
                              to={getMeetingRoomDetailPage(
                                loc_id,
                                reservations.area_id
                              )}
                            >
                              {reservations.area_name}
                            </Link>
                          </td>
                          <td>
                            {formatOnlyMinuteSecondEN(reservations.start_at)}
                          </td>
                          <td>
                            {formatOnlyMinuteSecondEN(reservations.end_at)}
                          </td>
                          <td>{formatYearDayEN(reservations.start_at)}</td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={pagination}
                template={`${getUserInfoUrl(member.id)}/meeting?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let orgId = params.organization_id

  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page

  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)
  const locations = state.entities.locations || {}
  let sections = location.pathname.split('/')
  let isMemberShipPath =
    sections[2] === 'memberships' && sections[3] === 'memberManage'

  return {
    orgId,
    locations,
    loc_id,
    page,
    isMemberShipPath,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getReservationsAction: bindActionCreators(
      reservationsActions.all,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersMeetRecord)
