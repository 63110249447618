import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { KBButton, KBPopover } from 'components'

var SelectProvidersForm = React.createClass({
  getInitialState() {
    const { defaultProvider } = this.props
    return {
      selectProvider: defaultProvider
    }
  },

  createLocationProvider() {
    const { callBack } = this.props
    const { selectProvider } = this.state
    callBack && callBack(selectProvider)
    KBPopover.close()
  },

  selectProvider(provider) {
    this.setState({
      selectProvider: provider
    })
  },

  render() {
    const { locationProviders } = this.props
    const { selectProvider } = this.state

    return (
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">选择收款账户</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          {locationProviders &&
            locationProviders.map((provider, index) => {
              return (
                <div className="kb-row">
                  <div
                    key={index}
                    className={`provider-item ${
                      selectProvider.id == provider.id
                        ? 'provider-item_active'
                        : null
                    }`}
                    onClick={this.selectProvider.bind(null, provider)}
                  >
                    <input
                      type="radio"
                      id={`providerRadio${index}`}
                      name="radio"
                      checked={
                        selectProvider.id == provider.id ? 'checked' : null
                      }
                    />
                    <div className="location-provider">
                      <div className="provider-line">
                        <div>
                          <span>收款账户信息</span>
                          <span>{provider.info || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>账户开户行</span>
                          <span>{provider.account_bank || '暂无'}</span>
                        </div>
                        <div>
                          <span>账户地址</span>
                          <span>{provider.physical_address || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>账户名</span>
                          <span>{provider.account_name || '暂无'}</span>
                        </div>
                        <div>
                          <span>账号</span>
                          <span>{provider.account_number || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>联系人</span>
                          <span>{provider.contact_name || '暂无'}</span>
                        </div>
                        <div>
                          <span>联系方式</span>
                          <span>{provider.contact_phone || '暂无'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <button
              className="certain-btn"
              type="button"
              onClick={this.createLocationProvider}
            >
              确定
            </button>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectProvidersForm)
