import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import * as constants from 'app/constants'
import {
  KBInput,
  KBDateInput,
  KBTextarea,
  KBSelect,
  KBFormError,
  KBButton,
  KBAvatar,
  KBPopover
} from 'components'
import SpaceAddMemberShipDiscountForm from './SpaceAddMemberShipDiscountForm'
import SpaceAddMemberShipDeskForm from './SpaceAddMemberShipDeskForm'
import SpaceAddMemberShipBookingForm from './SpaceAddMemberShipBookingForm'
import SpaceAddMemberShipResourceForm from './SpaceAddMemberShipResourceForm'
import _ from 'lodash'

var SpaceAddMemberShip = React.createClass({
  displayName: 'SpaceAddMemberShip',
  getInitialState() {
    return {}
  },

  updateMembership(model) {
    const {
      createMembershipAction,
      replaceMembershipAction,
      currentMemberShip,
      deleteMembershipAction,
      updateMembershipAction,
      routerActions,
      successAction,
      isUpdate,
      query
    } = this.props

    if (isUpdate) {
      return api
        .putMemberships(query.id, {
          ...model
        })
        .then(
          res => {
            if (
              currentMemberShip.status == 'draft' &&
              !currentMemberShip.parent_id
            ) {
              updateMembershipAction.success(res)
            } else {
              replaceMembershipAction.success(res, {
                status: 'single',
                key: `${
                  currentMemberShip.is_current
                    ? currentMemberShip.id
                    : currentMemberShip.parent_id
                }.histories`,
                removeId: currentMemberShip.is_current ? null : query.id
              })
            }
            successAction({ message: '修改会员等级成功!' })
            routerActions.goBack()
          },
          err => {
            updateMembershipAction.failure(err)
          }
        )
    }

    model.cycle_type = 'one_time'
    return api
      .createMemberships({
        ...model
      })
      .then(
        res => {
          createMembershipAction.success(res)
          successAction({ message: '创建会员等级成功!' })
          routerActions.goBack()
        },
        err => {
          createMembershipAction.failure(err)
        }
      )
  },

  changeStatus(data) {
    const {
      fields: { duration_units }
    } = this.props

    duration_units.onChange(data)
  },

  changePriceWithListingPrice(e) {
    const {
      fields: { price, listing_price }
    } = this.props
    let value = e.target.value

    listing_price.onChange(value)
    price.onChange(value)
  },

  renderMembershipBase() {
    const {
      fields: {
        title,
        alias,
        description,
        price,
        listing_price,
        point,
        deposit,
        duration_units,
        cycle_type,
        deposit_cycles,
        recurring_cycles
      },
      virtual_currency_name
    } = this.props

    const oneClass = { className: 'kb-form-group kb-form-c18 kb-form-alone' }

    return (
      <fieldset className="fieldset">
        <legend>基础信息</legend>
        <div style={{ width: '70%', marginBottom: 20 }}>
          <div className="kb-row">
            <KBInput
              title="标题"
              isRequired={true}
              label="true"
              hint="输入会员类型的名称，用户可见。例如“金卡”，“白金会员”等。"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={title}
              value={title.value}
            />
          </div>
          <div className="kb-row">
            <KBInput
              title="别名"
              label="true"
              hint="会员类型的别名，可省略"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={alias}
              value={alias.value}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              title="详情介绍"
              label="true"
              hint="对会员类型的详细介绍，用户可见。"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={description}
              value={description.value}
            />
          </div>
        </div>
        <legend>价格设置</legend>
        <div style={{ width: '70%', marginBottom: 20 }}>
          <div className="kb-row">
            <div className="kb-form-group kb-form-c18 kb-form-alone">
              <label>
                支付<span className="must-fill">*</span>
              </label>
              <div>
                <div className="kb-form-group r1_radios">
                  {constants.MEMBERSHIPS_DURATION_UNITS_ARRAY.map(
                    (data, index) => {
                      return (
                        <div
                          key={index}
                          className="kb-form-group r1_radio"
                          style={{ marginRight: 30 }}
                          onClick={this.changeStatus.bind(null, data.id)}
                        >
                          <div
                            className={`radio-btn ${
                              duration_units.value == data.id
                                ? 'radio-btn-active'
                                : ''
                            }`}
                          ></div>
                          <label>{data.name}</label>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="kb-row">
            <KBInput
              title="标价"
              type="number"
              label="true"
              inputClass={`${
                duration_units.value == 'days'
                  ? 'input-per-day'
                  : 'input-per-month'
              }`}
              placeholder="¥"
              hint="标注的价格"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={listing_price}
              value={listing_price.value}
              onChange={e => {
                this.changePriceWithListingPrice(e)
              }}
            />
          </div>
          <div className="kb-row">
            <KBInput
              title="实价"
              type="number"
              label="true"
              inputClass={`${
                duration_units.value == 'days'
                  ? 'input-per-day'
                  : 'input-per-month'
              }`}
              isRequired={true}
              placeholder="¥"
              hint="用户实际购买的价格"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={price}
              value={price.value}
            />
          </div>

          <div className="kb-row">
            <KBInput
              title="押金"
              type="number"
              label="true"
              placeholder="¥"
              hint="用户首次使用需要支付的押金"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={deposit}
              value={deposit.value}
            />
          </div>
          {/* <div className='kb-row'>
            <KBInput title={`${virtual_currency_name}购买`} label='true' placeholder={`用${virtual_currency_name}购买要多少${virtual_currency_name}，默认是价格*100`} className = 'kb-form-group kb-form-c18 kb-form-alone' field={ point } value={ point.value } />
          </div> */}
          {/* <div className='kb-row'>
            <KBSelect defaultValue='-1' title='交付方式' { ...oneClass } isRequired={true} field={ duration_units } arrayData={ constants.MEMBERSHIPS_DURATION_UNITS_ARRAY } />
          </div> */}
          {/* <div className='kb-row'>
            <KBSelect defaultValue='-1' title='付款方式' { ...oneClass } isRequired={true} field={ cycle_type } arrayData={ constants.MEMBERSHIPS_CYCLE_TYPE_ARRAY } />
          </div> */}
          {/* {
            cycle_type.value == 'recurring' ? (
              <div>
                <div className='kb-row'>
                  <KBInput title='押金月数' type='number' label='true' placeholder='交付几个月的押金' className = 'kb-form-group kb-form-c18 kb-form-alone' field={ deposit_cycles } value={ deposit_cycles.value } />
                </div>
                <div className='kb-row'>
                  <KBInput title='会员费支付月数' type='number' label='true' placeholder='几个月支付一次' className = 'kb-form-group kb-form-c18 kb-form-alone' field={ recurring_cycles } value={ recurring_cycles.value } />
                </div>
              </div>
            ) : ''
          } */}
        </div>
      </fieldset>
    )
  },

  updateDiscounts(model) {
    const {
      fields: { discounts }
    } = this.props
    discounts.addField(model)
  },

  addDiscounts() {
    KBPopover.show(
      <SpaceAddMemberShipDiscountForm callback={this.updateDiscounts} />
    )
  },

  renderDiscounts() {
    const {
      fields: { discounts },
      virtual_currency_name
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend>优惠信息</legend>
        <table className="content-table" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th>购买满多少</th>
              <th>价格</th>
              <th>{virtual_currency_name}购买</th>
              <th>赠送数量</th>
              <th>可获得的{virtual_currency_name}</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {discounts.map((discount, index) => {
              return (
                <tr>
                  <td>{discount.number.value}</td>
                  <td>{discount.price.value}</td>
                  <td>{discount.point.value}</td>
                  <td>{discount.complimentary_number.value}</td>
                  <td>{discount.point.value}</td>
                  <td onClick={() => discounts.removeField(index)}>删除</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <button
          className="certain-btn"
          type="button"
          onClick={this.addDiscounts}
        >
          添加优惠信息
        </button>
      </fieldset>
    )
  },

  updateDesks(model) {
    const {
      fields: { desks }
    } = this.props
    desks.addField(model)
  },

  addDesk() {
    KBPopover.show(<SpaceAddMemberShipDeskForm callback={this.updateDesks} />)
  },

  renderDesks() {
    const {
      fields: { desks }
    } = this.props
    return (
      <div>
        {desks.map((desk, index) => {
          let number = desk.discounts[0] && desk.discounts[0].number.value
          let giftNumber =
            desk.discounts[0] && desk.discounts[0].complimentary_number.value
          return (
            <fieldset className="fieldset">
              <legend className="clear-fix">
                <span>工位</span>
                <button
                  className="bordered-btn f-right"
                  onClick={() => desks.removeField(index)}
                >
                  删除
                </button>
              </legend>
              <div className="membership-card-list">
                <div>
                  <span>可使用次数</span>
                  <span>{desk.available_credits.value || 0}次</span>
                </div>
                <div>
                  <span>可使用区域</span>
                  <span>
                    {constants.MEETING_SHIP_AREA_TYPE_OBJ[
                      desk.accessible_areas.value
                    ] || '暂无'}
                  </span>
                </div>
                <div>
                  <span>可使用时段</span>
                  <span>{desk.timespans.value || 0}</span>
                </div>
                <div>
                  <span>超过限额次数每次价格</span>
                  <span>¥{desk.price.value || 0}</span>
                </div>
                <div>
                  <span>优惠</span>
                  {number && giftNumber ? (
                    <span>
                      满{number || 0}赠{giftNumber || 0}
                    </span>
                  ) : (
                    <span>无</span>
                  )}
                </div>
              </div>
            </fieldset>
          )
        })}
      </div>
    )
  },

  updateBooking(model) {
    const {
      fields: { bookings }
    } = this.props
    bookings.addField(model)
  },

  addBooking() {
    KBPopover.show(
      <SpaceAddMemberShipBookingForm callback={this.updateBooking} />
    )
  },

  renderBookings() {
    const {
      fields: { bookings },
      virtual_currency_name
    } = this.props
    return (
      <div>
        {bookings.map((booking, index) => {
          let number = booking.discounts[0] && booking.discounts[0].number.value
          let giftNumber =
            booking.discounts[0] &&
            booking.discounts[0].complimentary_number.value
          return (
            <fieldset className="fieldset">
              <legend className="clear-fix">
                <span>会议室</span>
                <button
                  className="bordered-btn f-right"
                  onClick={() => bookings.removeField(index)}
                >
                  删除
                </button>
              </legend>
              <div className="membership-card-list">
                <div>
                  <span>可使用小时数</span>
                  <span>{booking.available_credits.value || 0}小时</span>
                </div>
                <div>
                  <span>超过小时数每小时价格</span>
                  <span>¥{booking.price.value || 0}</span>
                </div>
                <div>
                  <span>优惠</span>
                  {number && giftNumber ? (
                    <span>
                      满{number || 0}赠{giftNumber || 0}
                    </span>
                  ) : (
                    <span>无</span>
                  )}
                </div>
              </div>
            </fieldset>
          )
        })}
      </div>
    )
  },

  updateResource(model) {
    const {
      fields: { resources }
    } = this.props
    resources.addField(model)
  },

  addResource() {
    KBPopover.show(
      <SpaceAddMemberShipResourceForm callback={this.updateResource} />
    )
  },

  renderResources() {
    const {
      fields: { resources },
      virtual_currency_name
    } = this.props
    return (
      <div>
        {resources.map((resource, index) => {
          let number =
            resource.discounts[0] && resource.discounts[0].number.value
          let giftNumber =
            resource.discounts[0] &&
            resource.discounts[0].complimentary_number.value
          return (
            <fieldset className="fieldset">
              <legend className="clear-fix">
                <span>打印</span>
                <button
                  className="bordered-btn f-right"
                  onClick={() => resources.removeField(index)}
                >
                  删除
                </button>
              </legend>
              <div className="membership-card-list">
                {/* <div>
                    <span>资源类型:</span>
                    <span>{ constants.MEETING_SHIP_RESOURCES_TYPE_OBJ[resource.resource_type.value] }</span>
                  </div> */}
                <div>
                  <span>可打印张数</span>
                  <span>{resource.available_credits.value || 0}张</span>
                </div>
                <div>
                  <span>超过张数每张价格</span>
                  <span>¥{resource.price.value || 0}</span>
                </div>
                <div>
                  <span>优惠</span>
                  {number && giftNumber ? (
                    <span>
                      满{number || 0}赠{giftNumber || 0}
                    </span>
                  ) : (
                    <span>无</span>
                  )}
                </div>
              </div>
            </fieldset>
          )
        })}
      </div>
    )
  },

  render() {
    const {
      handleSubmit,
      submitting,
      isUpdate,
      fields: { desks, bookings, resources }
    } = this.props
    let desksLength = desks.length
    let bookingsLength = bookings.length
    let resourcesLength = resources.length

    return (
      <div className="nav-section-container  f-right">
        <div className="kb-form-container" style={{ padding: 0 }}>
          <header className="nav-header clear-fix">
            <div className="nav-section-header-title">
              <span>{isUpdate ? '编辑' : '新增'}会员卡</span>
            </div>
          </header>
          <div style={{ padding: '30px' }}>
            <form
              onSubmit={handleSubmit(this.updateMembership)}
              style={{ height: '100%' }}
            >
              <KBFormError error={this.props.error} />
              {this.renderMembershipBase()}
              {/*{ this.renderDiscounts() }*/}
              <fieldset className="fieldset">
                <legend>
                  <span>资源设置</span>
                </legend>
                <div
                  style={
                    !desksLength && !bookingsLength && !resourcesLength
                      ? {}
                      : { padding: 15, border: '1px solid #e6e6e6' }
                  }
                >
                  {this.renderDesks()}
                  {this.renderBookings()}
                  {this.renderResources()}
                </div>
                <div className="membership-btn">
                  <button
                    className="bordered-btn"
                    type="button"
                    onClick={this.addDesk}
                    style={{ marginLeft: 0 }}
                  >
                    <i className="iconfont icon-add" />
                    添加工位额度
                  </button>
                  <button
                    className="bordered-btn"
                    type="button"
                    onClick={this.addBooking}
                  >
                    <i className="iconfont icon-add" />
                    添加会议室额度
                  </button>
                  <button
                    className="bordered-btn"
                    type="button"
                    onClick={this.addResource}
                  >
                    <i className="iconfont icon-add" />
                    添加打印额度
                  </button>
                </div>
              </fieldset>
              <div className="clear-fix">
                <KBButton
                  className="certain-btn f-right"
                  type="submit"
                  submitting={submitting}
                >
                  {isUpdate ? '修改' : '保存'}
                </KBButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
})

function parseData(data) {
  if (!data) {
    return {}
  }

  let dt = _.cloneDeep(data)
  if (dt.desks && dt.desks.length) {
    dt.desks = dt.desks.map(json => {
      json.accessible_areas = json.accessible_areas.join(',')
      json.timespans =
        (json.timespans &&
          json.timespans
            .map(json => {
              json = json.join('-')
              return json
            })
            .join(',')) ||
        ''
      return json
    })
  }

  return dt
}

function mapStateToProps(state, props) {
  const { location } = props
  const query = location.query
  const isUpdate = query.status
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)

  let params = {
    query,
    isUpdate,
    virtual_currency_name
  }

  if (isUpdate) {
    const { id, is_current, parent_id, status } = query
    const currentMemberShip = selectors.getMemberships(state, {
      id,
      parent_id
    })
    params.initialValues = {
      ...parseData(currentMemberShip)
    }
    params.currentMemberShip = currentMemberShip
  }

  return params
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    createMembershipAction: bindActionCreators(
      actions.membershipsAction.create,
      dispatch
    ),
    deleteMembershipAction: bindActionCreators(
      actions.membershipsAction.delete,
      dispatch
    ),
    updateMembershipAction: bindActionCreators(
      actions.membershipsAction.update,
      dispatch
    ),
    replaceMembershipAction: bindActionCreators(
      actions.membershipsAction.replace,
      dispatch
    ),
    successAction: bindActionCreators(actions.successState, dispatch),
    apiCallFailureActions: bindActionCreators(actions.apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.title)) {
    errors.title = '请输入title'
  }

  return errors
}

const formConfig = {
  form: 'SpaceAddMemberShip',
  fields: [
    'title',
    'alias',
    'description',
    'price',
    'listing_price',
    'point',
    'duration_units',
    'cycle_type',
    'deposit_cycles',
    'recurring_cycles',
    'deposit',
    'discounts[].number',
    'discounts[].price',
    'discounts[].complimentary_number',
    'discounts[].point',
    'discounts[].complimentary_point',
    'desks[].price',
    'desks[].point',
    'desks[].available_credits',
    'desks[].accessible_areas',
    'desks[].timespans',
    'desks[].discounts[].number',
    'desks[].discounts[].price',
    'desks[].discounts[].complimentary_point',
    'desks[].discounts[].complimentary_number',
    'desks[].discounts[].point',
    'bookings[].price',
    'bookings[].point',
    'bookings[].available_credits',
    'bookings[].discounts[].number',
    'bookings[].discounts[].price',
    'bookings[].discounts[].complimentary_point',
    'bookings[].discounts[].complimentary_number',
    'bookings[].discounts[].point',
    'resources[].price',
    'resources[].point',
    'resources[].resource_type',
    'resources[].available_credits',
    'resources[].discounts[].number',
    'resources[].discounts[].price',
    'resources[].discounts[].complimentary_point',
    'resources[].discounts[].complimentary_number',
    'resources[].discounts[].point'
  ],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceAddMemberShip)
