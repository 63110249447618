import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { invoicesActions, successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { checkObject } from 'utils/kbUtil'
import classNames from 'classnames'
import { KBPopover, KBButton, KBDropdown, KBLoadingContainer } from 'components'
import {
  INVOICES_STATE,
  INVOICES_STATE_CLASS,
  INVOICES_SENDING_STATE,
  INVOICES_SENDING_STATE_CLASS,
  INVOICE_SALES_PAYMENTS
} from 'app/constants'
import { get, getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import { getInvoiceOfId, getCurrentUser } from 'app/selectors'
import * as valid from 'utils/validate'
import * as selectors from 'app/selectors'
import {
  canAccessSpaceFinance,
  canAccessLocationFinance,
  canAccessLocationObserver
} from 'app/reducers/role'
import moment from 'moment'

var InvoicesInfo = React.createClass({
  getInitialState() {
    return {
      loading: true
    }
  },
  componentDidMount() {
    const { getInvoicesActions, invoice_id } = this.props
    api
      .getInvoice(invoice_id)
      .then(
        json => {
          getInvoicesActions.success(json)
        },
        errors => {
          getInvoicesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  disposeTotal(invoice) {
    const { payment_type, total_amount, total_credits, total_points } = invoice
    if (payment_type == 'point') {
      return total_points
    }
    if (payment_type == 'credits') {
      return total_credits
    }
    return total_amount
  },

  invoiceJsx() {
    const { invoice, exchangeUnit, shopInfo } = this.props
    const { loading } = this.state

    const status = INVOICES_STATE
    const status_class = INVOICES_STATE_CLASS

    let total = invoice && this.disposeTotal(invoice)
    total = isNaN(total) ? 0 : total

    return (
      <KBLoadingContainer loading={loading}>
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span className="f-left">
              {(invoice.sales_customer && invoice.sales_customer.name) || ''}
            </span>
            <span
              className={`finance-default ${status_class[invoice.status]}`}
              style={{ marginTop: 4 }}
            >
              {status[invoice.status]}
            </span>
            <br />
            <div className="f-left" style={{ marginTop: '5px', fontSize: 14 }}>
              <span>{(invoice.location && invoice.location.name) || ''}</span>
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          <div className="kb-section p-bottom">
            <ul>
              <li>
                <span className="section-title">账单编号:</span>
                <span className="section-title-content">
                  {invoice.serial_number}
                </span>
              </li>
              <li>
                <span className="section-title">生成日期:</span>
                <span className="section-title-content">
                  {invoice.created_at
                    ? moment(invoice.created_at).format('YYYY-MM-DD')
                    : '暂无'}
                </span>
              </li>
              <li>
                <span className="section-title">截止日期:</span>
                <span className="section-title-content">
                  {invoice.due_date
                    ? moment(invoice.due_date).format('YYYY-MM-DD HH:mm:ss')
                    : '暂无'}
                </span>
              </li>
            </ul>
          </div>
          <table className="content-table m-bottom t-layout">
            <thead>
              <tr>
                <th style={{ width: '50%' }}>说明</th>
                <th>单价</th>
                <th>数量</th>
                <th className="t-right">总价</th>
              </tr>
            </thead>
            <tbody>
              {invoice.line_items &&
                invoice.line_items.map((json, index) => {
                  return (
                    <tr key={index}>
                      <td>{json.description}</td>
                      {invoice.payment_type == 'money' ? (
                        <td>¥ {json.unit_price}</td>
                      ) : invoice.payment_type == 'point' ? (
                        <td>{`${json.unit_point} ${exchangeUnit}`}</td>
                      ) : (
                        <td>
                          {shopInfo && shopInfo.payment_type == 'point'
                            ? json.unit_price + ' ' + exchangeUnit
                            : '¥ ' + json.unit_price}
                        </td>
                      )}

                      <td>{json.units}</td>
                      <td className="t-right">
                        {invoice.payment_type == 'money'
                          ? `¥ ${parseFloat(json.total_amount).toFixed(2)}`
                          : invoice.payment_type == 'point'
                          ? `${json.total_points} ${exchangeUnit}`
                          : shopInfo && shopInfo.payment_type == 'point'
                          ? parseFloat(json.total_points).toFixed(2) +
                            ' ' +
                            exchangeUnit
                          : '¥ ' + parseFloat(json.total_amount).toFixed(2)}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <div className="p-bottom clear-fix">
            <table className="content-table-half f-right">
              <tbody>
                <tr>
                  <td>应付金额</td>
                  <td className="t-right">
                    {invoice.payment_type == 'money'
                      ? `¥ ${parseFloat(invoice.total_amount).toFixed(2)}`
                      : invoice.payment_type == 'point'
                      ? `${invoice.total_points} ${exchangeUnit}`
                      : shopInfo && shopInfo.payment_type == 'point'
                      ? parseFloat(invoice.total_points).toFixed(2) +
                        ' ' +
                        exchangeUnit
                      : '¥ ' + parseFloat(invoice.total_amount).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {invoice &&
          invoice.sales_payments &&
          invoice.sales_payments.length > 0 ? (
            <div className="finance-detail">
              <header>收款明细</header>
              <table className="content-table t-layout">
                <thead>
                  <tr>
                    <th>时间</th>
                    <th>经手人</th>
                    <th>付款方式</th>
                    <th>金额</th>
                    <th className="t-right">流水号</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.sales_payments.map((json, index) => {
                    return (
                      <tr key={index}>
                        <td>{json.created_at}</td>
                        <td>{json.creator.name}</td>
                        <td>
                          {json.payment_mode
                            ? INVOICE_SALES_PAYMENTS[json.payment_mode]
                            : '无'}
                        </td>
                        <td>{json.amount}</td>
                        <td className="t-right">{json.reference_number}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            ''
          )}
        </div>
      </KBLoadingContainer>
    )
  },
  render() {
    const { invoice } = this.props
    return (
      <section className="kb-content" style={{ marginTop: 0 }}>
        <div className="kb-content-container clear-fix">
          {invoice && this.invoiceJsx()}
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const invoice_id = params.invoice_id
  const invoice = getInvoiceOfId(state, invoice_id)
  const spaceSetting = selectors.getSpaceSetting(state)
  const exchangeUnit =
    spaceSetting &&
    spaceSetting.virtual_currency_settings &&
    spaceSetting.virtual_currency_settings.virtual_currency_name
  let shopping_id = parseInt(props.params.shopping_id)
  let shops = selectors.getShops(state)
  let shopInfo =
    shops &&
    shops.find(data => {
      return data.id == shopping_id
    })
  return {
    invoice_id,
    invoice,
    shopInfo,
    exchangeUnit
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.get, dispatch),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.note)) {
    errors.note = '请输入备注信息'
  }

  return errors
}

const formConfig = {
  form: 'InvoicesInfo',
  fields: ['note'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicesInfo)
