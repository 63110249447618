import React from 'react'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import * as valid from 'utils/validate'
import { KBForm, KBInput, KBSelect, KBButton } from 'components'
import { CONTACTS_IDCARD_TYPE } from 'app/constants'

var OrgManagerForm = React.createClass({
  getInitialState() {
    return {
      formSubmitting: this.props.formSubmitting
    }
  },

  componentDidMount() {},

  componentWillReceiveProps(nextProps) {
    if (nextProps.formSubmitting !== this.props.formSubmitting) {
      this.setState({
        formSubmitting: nextProps.formSubmitting
      })
    }
  },

  saveOrgManagerOfNext(model) {
    const { saveParams, isUpdate, formSubmitting } = this.props
    // !formSubmitting && saveParams && saveParams(model, 3)
    if (isUpdate) {
      return !formSubmitting && saveParams && saveParams(model, 3)
    }
    saveParams && saveParams(model, 3, false, true)
    // saveParams && saveParams(model, 3, true, true)
  },

  saveOrgManagerOfComplete(model) {
    const { saveParams, isUpdate } = this.props
    if (isUpdate) {
      return saveParams && saveParams(model, false, true)
    }
    saveParams && saveParams(model, false, true)
  },

  saveOrgManagerOfLast() {
    const { fields, saveParams } = this.props
    let model = {}
    Object.keys(fields).map(field_key => {
      model[field_key] = fields[field_key].value
    })
    saveParams && saveParams(model, 1)
  },

  render() {
    const {
      fields: {
        contacts_name,
        contacts_phone,
        contacts_idcard_type,
        contacts_id_number,
        org_contacts_phone,
        contacts_email
      }
    } = this.props
    const { handleSubmit, isUpdate, isOCRM } = this.props
    const { formSubmitting } = this.state

    return (
      <KBForm type="reduxForm" fields={this.props.fields}>
        <header className="kb-form-header">
          <h2 className="header-title">公司负责人</h2>
        </header>
        <div className="kb-form-container">
          <div className="kb-row kb-form-48">
            <KBInput
              className="kb-form-group kb-div-39"
              title="姓名"
              field={contacts_name}
            />
            <div className="kb-row kb-form-66" style={{ float: 'left' }}>
              <KBInput
                className="kb-form-group kb-div-48"
                title="联系电话"
                titleClass="label-rt"
                field={contacts_phone}
                type="text"
              />
            </div>
          </div>
          <div className="kb-row kb-form-48">
            <KBSelect
              className="kb-form-group kb-div-39"
              title="证件类型"
              defaultValue="-1"
              field={contacts_idcard_type}
              arrayData={CONTACTS_IDCARD_TYPE}
            />
            <div className="kb-row kb-form-66" style={{ float: 'left' }}>
              <KBInput
                className="kb-form-group kb-div-48"
                title="证件号码"
                titleClass="label-rt"
                field={contacts_id_number}
                type="text"
              />
            </div>
          </div>
          <div className="kb-row kb-form-48">
            <KBInput
              className="kb-form-group kb-div-39"
              title="邮箱"
              field={contacts_email}
            />
            <div className="kb-row kb-form-66" style={{ float: 'left' }}>
              <KBInput
                className="kb-form-group kb-div-48"
                title="企业电话"
                titleClass="label-rt"
                field={org_contacts_phone}
                type="text"
              />
            </div>
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="button"
              onClick={this.saveOrgManagerOfLast}
            >
              上一步
            </KBButton>
            <KBButton
              className="certain-btn"
              type="submit"
              onClick={handleSubmit(this.saveOrgManagerOfNext)}
              submitting={formSubmitting}
              style={{ marginLeft: 30 }}
            >
              下一步
            </KBButton>
            {!isOCRM && (
              <KBButton
                className="certain-btn"
                type="submit"
                onClick={handleSubmit(this.saveOrgManagerOfComplete)}
                submitting={formSubmitting}
                style={{ marginLeft: 30 }}
              >
                {isUpdate ? '完成编辑' : '完成添加'}
              </KBButton>
            )}
          </div>
          {!isUpdate ? (
            <div
              style={{
                backgroundColor: '#F8FAFC',
                border: '1px solid #DFE3E9',
                borderRadius: 4,
                padding: '20px 24px'
              }}
            >
              <p style={{ color: '#333C48' }}>
                点击"完成添加"后, 其他信息可以稍后到公司详情页 -
                点击"编辑"进行补充
              </p>
            </div>
          ) : null}
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { org, urlParams } = props
  const isUpdate = urlParams && urlParams.type === 'update' && org

  return {
    isUpdate,
    initialValues: org
      ? {
          ...org
        }
      : {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (values.contacts_phone && valid.isNumber(values.contacts_phone)) {
    errors.contacts_phone = '手机号码只能为数字'
  }

  if (values.contacts_id_number) {
    if (
      values.contacts_idcard_type === 'idcard' &&
      valid.IDCard(values.contacts_id_number)
    ) {
      errors.contacts_id_number = '请输入18位身份证号码，最后一位x必须大写'
    }

    if (
      values.contacts_idcard_type === 'passport' &&
      valid.passport(values.contacts_id_number)
    ) {
      errors.contacts_id_number = '请输入正确的护照格式'
    }
  }

  return errors
}

const formConfig = {
  form: 'OrgManagerForm',
  fields: [
    'contacts_name',
    'contacts_phone',
    'contacts_idcard_type',
    'contacts_id_number',
    'org_contacts_phone',
    'contacts_email'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgManagerForm)
