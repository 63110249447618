import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { orgActions, areaActions } from 'app/actions'
import { connect } from 'react-redux'
import { subscriptionActions } from 'app/actions'
import { canAccessSpaceContract, canSpaceAdmin } from 'app/reducers/role'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'

var OrganizationAuditFailure = React.createClass({
  displayName: 'OrganizationAuditFailure',
  getInitialState() {
    const { filters } = this.props
    return {}
  },
  componentDidMount() {
    const { filters } = this.props
  },
  nextStep() {
    const {
      setNextAndCurrentSubscription,
      updateSubscriptionActions,
      currentSubscription
    } = this.props
    setNextAndCurrentSubscription(currentSubscription, 'detail')
  },
  returnStep() {
    const { routerActions } = this.props
    routerActions.go(-1)
  },
  render() {
    const { user, isParkingContract } = this.props
    const isCanContract = canAccessSpaceContract(user)
    const isAdmin = canSpaceAdmin(user)

    return (
      <div
        className="t-center"
        style={isParkingContract ? { paddingTop: 100 } : {}}
      >
        <div className="flex-horizontal-center">
          <img
            src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/error.png"
            alt="no pass"
            style={{ width: 80, height: 80, marginRight: 20 }}
          />
          <div
            className="flex-horizontal-center"
            style={{ flexDirection: 'column' }}
          >
            <div style={{ fontSize: 20 }} className="t-left">
              合同审批不通过
            </div>
          </div>
        </div>
        <div style={{ marginTop: 40 }}>
          {isAdmin ? (
            <span
              className="bordered-btn"
              style={{ padding: '7px 25px', marginLeft: 0 }}
              onClick={this.nextStep}
            >
              进入下一步
            </span>
          ) : !isCanContract ? (
            <span
              className="bordered-btn"
              style={{ padding: '7px 25px', marginLeft: 0 }}
              onClick={this.nextStep}
            >
              进入下一步
            </span>
          ) : (
            ''
          )}
        </div>
        {isCanContract ? (
          <div style={{ marginTop: 40 }}>
            <span
              className="bordered-btn"
              style={{ padding: '7px 25px', marginLeft: 0 }}
              onClick={this.returnStep}
            >
              返回上一步
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubscriptionActions: bindActionCreators(
      subscriptionActions.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationAuditFailure)
