import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'

const step = [
  {
    id: 'chooseType',
    name: '选择合同类型',
    step_num: 1
  },
  {
    id: 'newTemplate',
    name: '选择新合同模板',
    superior: 'chooseType',
    isHidden: true
  },
  {
    id: 'extendSubscription',
    name: '选择续约合同',
    superior: 'chooseType',
    isHidden: true
  },
  {
    id: 'detail',
    name: '输入合同细节',
    step_num: 2
  },
  {
    id: 'audit',
    name: '提交合同审批',
    step_num: 3
  },
  {
    id: 'auditSuccess',
    name: '选择续约合同',
    superior: 'audit',
    isHidden: true
  },
  {
    id: 'auditFailure',
    name: '选择续约合同',
    superior: 'audit',
    isHidden: true
  },
  {
    id: 'sign',
    name: '打印合同签字',
    step_num: 4
  },
  {
    id: 'finish',
    name: '完成合同',
    step_num: 5
  }
]

const EditOrganization = React.createClass({
  displayName: 'EditOrganization',
  getInitialState() {
    return {
      currentState: 'chooseType',
      errorMessage: '',
      data: {},
      extendSubscriptionList: [],
      currentSubscription: {},
      isLoading: false
    }
  },
  render() {}
})

EditOrganization.PropTypes = {}

function mapStateToProps(state, props) {}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganization)
