import React from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { locksActions, orgActions } from 'app/actions'
import { connect } from 'react-redux'
import { isListValue, getListFirstValue } from 'utils/kbUtil'

const menu = [
  {
    name: '门禁列表',
    value: ['lock_list', 1]
  },
  {
    name: '门禁组',
    value: ['lock_group', 2]
  }
]

var LockManageBox = React.createClass({
  displayName: 'LockManageBox',
  componentDidMount() {},

  render() {
    const { loc_id, section } = this.props
    return (
      <div className="clear-fix" style={{ height: '100%' }}>
        <section className="kb-content" style={{ marginTop: 0, paddingTop: 0 }}>
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">门禁管理</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menu.map((data, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          isListValue(data.value, section)
                            ? 'nav-container-list-hover'
                            : ''
                        }
                      >
                        <Link
                          to={`/admin/locations/${loc_id}/locks/lock_info/${getListFirstValue(
                            data.value
                          )}`}
                        >
                          <i className={`iconfont ${data.icon}`} />
                          <span>{data.name}</span>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          {this.props.children}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let sections = location.pathname.split('/')
  let section = sections.length > 5 ? sections[6] || '' : ''

  return {
    loc_id,
    section
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLocksAction: bindActionCreators(locksActions.all, dispatch),
    getOrganizationsAction: bindActionCreators(orgActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockManageBox)
