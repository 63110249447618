import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { KBPopover, KBInput } from 'components'

var OrgSubscriptionDeskListFlexible = React.createClass({
  priceChange(e) {
    let value = e.target.value
    const {
      desk: { units, totalRent }
    } = this.props
    let totalRentNew = 0
    if (value && units.value) {
      totalRentNew = value * units.value
    }
    totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
  },

  unitsChange(e) {
    let value = e.target.value
    const {
      desk: { price, totalRent }
    } = this.props
    let totalRentNew = 0
    if (value && price.value) {
      totalRentNew = value * price.value
    }
    totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
  },

  render() {
    const {
      desk,
      deskList,
      index,
      calculationDeposit,
      deleteCallBack
    } = this.props

    return (
      <li style={{ padding: '0 30px 30px 0' }}>
        <i
          className="iconfont icon-close"
          onClick={() => {
            deskList.removeField(index)
            deleteCallBack && deleteCallBack()
          }}
          style={{ top: 10, right: 0 }}
        />
        <div className="kb-row kb-form-66">
          <KBInput
            isRequired={true}
            title="工位价格"
            fistFoucs={true}
            className="kb-form-group kb-div-c39"
            inputClass="input-dollar-moth kb-input"
            field={desk.price}
            onChange={event => {
              calculationDeposit(event, desk.price)
              this.priceChange(event)
            }}
          />
          <div className="kb-form-group kb-div-c39">
            <label className="label-rt">
              <span className="must-fillR">*</span>工位数量
            </label>
            <div>
              <input
                className="kb-input input-num"
                style={{ backgroundColor: '#fff' }}
                {...desk.units}
                onChange={event => {
                  calculationDeposit(event, desk.units)
                  this.unitsChange(event)
                }}
              />
              {desk.units.touched && desk.units.error && (
                <p className="lr-error"> {desk.units.error} </p>
              )}
            </div>
          </div>
        </div>
      </li>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionDeskListFlexible)
