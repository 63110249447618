import React from 'react'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { isListValue, getListFirstValue } from 'utils/kbUtil'
import intl from 'react-intl-universal'
import { lockerCategoriesAction } from 'app/actions'
import { api } from 'app/services'

var LightBox = React.createClass({
  componentDidMount() {},

  render() {
    const { locationSection, location_id, menu } = this.props

    return (
      <div
        className="kb-out-box clear-fix"
        style={location_id ? { paddingTop: 0 } : {}}
      >
        {/* <div className="sub-nav-bar" style={{ minWidth: 1450 }}>
          <div style={{ position: 'relative' }}>
            <div className="sub-nav-list clear-fix">
              {
                menu.map((data, index) => {
                  return (
                    <Link key={index} className={location_id && isListValue(data.value, locationSection) ? 'active' : ''} to={`/admin/locations/${location_id}/lockers/${getListFirstValue(data.value)}`}> {data.name} </Link>
                  )
                })
              }
            </div>
          </div>
        </div> */}

        <div className="margin-top-lg">
          <div className="clear-fix" style={{ height: '100%' }}>
            <section className="kb-content-t">{this.props.children}</section>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { params } = props
  // let sections = props.location.pathname.split('/')
  // let locationSection = sections.length > 5 ? (sections)[5] : ''
  let location_id = params && params.id
  // const menu = [
  //   {
  //     name: '储物柜管理',
  //     value: ['']
  //   },
  //   {
  //     name: '使用记录',
  //     value: ['record']
  //   },
  //   {
  //     name: '规格管理',
  //     value: ['categories']
  //   }
  // ]

  return {
    // locationSection,
    user,
    location_id
    // menu,
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getLockerCategoriesAction: bindActionCreators(
      lockerCategoriesAction.replace,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LightBox)
