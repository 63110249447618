import React from 'react'
import { checkObject, checkName, encryptionPhone } from 'utils/kbUtil'
import { api, apiUtils } from 'app/services'
import { Link } from 'react-router'
import { KBPopover, KBAvatar, KBTableWithoutData } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import { getDeskOfDeskId } from 'app/selectors'
import { bindActionCreators } from 'redux'
import { checkNull } from 'utils/kbUtil'
import { canAccessLocation } from 'app/reducers/role'
import OrganizationMemberForm from 'containers/organization/OrganizationMemberForm'
import _ from 'lodash'

var NonMembersList = React.createClass({
  displayName: 'NonMembersList',

  getInitialState() {
    return {
      users: this.props.users
    }
  },

  componentWillReceiveProps(nextProps) {
    const users = nextProps.users
    if (!_.isEqual(users, this.props.users)) {
      this.setState({
        users
      })
    }
  },

  render() {
    const { loc_id } = this.props
    const { users } = this.state

    return (
      <table className="content-table">
        <thead>
          <tr>
            <th>姓名</th>
            <th>性别</th>
            <th>电话号</th>
            <th>微信号</th>
          </tr>
        </thead>
        <KBTableWithoutData
          hasData={users.length > 0}
          tableHeadNum="8"
          tipMessage="暂无会员信息"
        >
          {users &&
            users.map((user, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: 150 }}>
                    <div className="vf-center">
                      <KBAvatar
                        user={user}
                        imgStyle={{ marginRight: 5 }}
                        style={{ display: 'inline-block', marginRight: '5px' }}
                        size={30}
                      />
                      <span>{user.name}</span>
                    </div>
                  </td>
                  <td>{user.gender == 'male' ? '男' : '女'}</td>
                  <td>
                    {user.phone_num_disabled ? (
                      <span>已禁用</span>
                    ) : (
                      checkName(user.phone_num) || '无'
                    )}
                  </td>
                  <td>{checkName(user.wechat) || '无'}</td>
                </tr>
              )
            })}
        </KBTableWithoutData>
      </table>
    )
  }
})

NonMembersList.PropTypes = {
  users: React.PropTypes.array,
  loc_id: React.PropTypes.number.isRequired
}

function mapStateToProps(state, props) {
  var users = props.users || []
  const { user } = state
  const { loc_id } = props

  return {
    users
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(NonMembersList)
