import React from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import {
  locationActions,
  successState,
  locationSettingAction,
  apiCallFailure,
  memberActions,
  spacesSettingAction,

} from 'app/actions'
import { KBLoadingContainer } from 'components'
import { canAdminLocation, onlyCanAdminLocationOfWeChat, onlyHasLocationGroupOperator, onlyLocationManger } from 'app/reducers/role'
import * as selectors from 'app/selectors'
import { setCurrentLocation } from 'utils/kbUtil'

var LocationSettingBox = React.createClass({
  displayName: 'LocationSettingBox',
  updateLocation(model) {
    if (model.reservation_time_range) {
      model.reservation_time_range = JSON.stringify(
        model.reservation_time_range
      )
    }
    const {
      updateLocationAction,
      loc_id,
      successAction,
      isManager,
      apiCallFailureActions
    } = this.props
    if (!isManager) {
      apiCallFailureActions({
        status: 'error',
        message: '您没有此操作权限!'
      })
      return
    }
    return api.updateLocation(loc_id, model).then(json => {
      successAction({ message: '保存成功!' })
      //const updatedLocation = json.response.body
      // setCurrentLocation(updatedLocation)
      updateLocationAction.success(json)
    })
  },
  updateLocationSetting(model, { key }) {
    const { locationSettingActions, loc_id, successAction } = this.props
    return api
      .putSpaceSetting({
        target_type: 'Location',
        target_id: loc_id,
        settings: model
      })
      .then(
        json => {
          locationSettingActions.success(json, { location_id: loc_id, key })
          successAction({ message: '保存成功!' })
        },
        error => {
          locationSettingActions.failure(error)
        }
      )
  },
  /**
   * 如果已经Call locationinfo 那么不执行ajax
   */
  componentDidMount() {
    const { isInfo, getSpaceSettingAction } = this.props
    api.getMembers({ per_page: 1000 }).then(
      ({ json }) => {
        this.props.getMembersAction.success(json, { entityName: 'members' })
      },
      errors => {
        this.props.getMembersAction.failure(errors)
      }
    )
    if (isInfo) {
      return
    }

    api.getLocationInfo(this.props.loc_id).then(
      json => {
        this.props.getLocationInfoAction.success(json)
      },
      errors => {
        this.props.getLocationInfoAction.failure(errors)
      }
    )

    api
      .getSpaceSetting({
        inherit: true,
        target_type: 'Location',
        target_id: this.props.loc_id
      })
      .then(
        json => {
          getSpaceSettingAction.success(json)
        },
        errors => {
          getSpaceSettingAction.success(errors)
        }
      )
      .finally(() => {
        // this.setState({
        //   loading: false
        // })
      })
  },
  render() {
    const {
      loc_id,
      section,
      isInfo,
      location,
      menu,
      spaceSeeting,
      cities,
      provinces
    } = this.props

    const childrenWithProps = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        setting: spaceSeeting,
        updateLocation: this.updateLocation,
        location,
        updateLocationSetting: this.updateLocationSetting,
        updateCurrentSpace: this.updateLocationSetting,
        cities,
        provinces
      })
    })

    return (
      <section className="kb-content">
        <nav className="nav-container f-left">
          <div className="nav-container-content">
            <header className="nav-header clear-fix">
              <h2 className="header-title f-left">分店设置</h2>
            </header>
            <div className="nav-body">
              <ul className="nav-container-list">
                {menu.map((menuItem, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        menuItem.value === section
                          ? 'nav-container-list-hover select-list-hover'
                          : ''
                      }
                    >
                      <Link
                        to={`/admin/locations/${loc_id}/settings/${menuItem.value}`}
                      >
                        {menuItem.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </nav>
        <section className="nav-section-container f-right">
          <KBLoadingContainer loading={!isInfo}>
            {childrenWithProps}
          </KBLoadingContainer>
        </section>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { user } = state
  const { id } = params

  let sections = location.pathname.split('/')
  let section = sections.length > 4 ? sections[5] || '' : ''
  let currentLocation = selectors.getLocation(state, id) || {}
  let isInfo = currentLocation && currentLocation.team ? true : false
  let isManager = canAdminLocation(user, id)
  let spaceSeeting = selectors.getSpaceSetting(state)
  const cities = selectors.getCities(state)
  const provinces = selectors.getProvinces(state)
  if (currentLocation) {
    currentLocation.city_id = currentLocation.city
    currentLocation.district_id =
      currentLocation.district && currentLocation.district.id
  }
  const isWeChatManger =onlyCanAdminLocationOfWeChat(user, id)

  let menu = [
    {
      name: '基本设置',
      value: ''
    },
    // {
    //   name: '财务设置',
    //   value: 'invoice'
    // },
    {
      name: '权限设置',
      value: 'role'
    },
    // {
    // 	name : '会议室设置',
    // 	value : 'meeting'
    // },
    {
      name: '工位设置',
      value: 'area'
    },
    // {
    // 	name : '合同设置',
    // 	value : 'subscription'
    // },
    {
      name: '数据导入',
      value: 'guide'
    },
    // {
    // 	name : '楼层管理',
    // 	value : 'floorinfo'
    // },
    {
      name: '开放功能设置',
      value: 'open_func'
    },
    {
      name: '打印机管理',
      value: 'printer'
    },
    {
      name: '门禁设置',
      value: 'lock_permission'
    },
    // {
    //   name: '第三方权益管理',
    //   value: 'third_equity'
    // }
  ]

  if(isWeChatManger){
    menu = [
      {
        name: '基本设置',
        value: ''
      },
    ]
  }
  if(onlyHasLocationGroupOperator(user,id)||onlyLocationManger(user,id)){
    menu =[
      {
        name: '基本设置',
        value: ''
      },
      {
        name: '工位设置',
        value: 'area'
      },
      {
        name: '数据导入',
        value: 'guide'
      },
      {
        name: '开放功能设置',
        value: 'open_func'
      },
      {
        name: '打印机管理',
        value: 'printer'
      },
      {
        name: '门禁设置',
        value: 'lock_permission'
      },
      // {
      //   name: '第三方权益管理',
      //   value: 'third_equity'
      // }
    ]
  }
  return {
    spaceSeeting,
    menu,
    loc_id: id,
    section,
    isInfo,
    location: currentLocation,
    isManager,
    cities,
    provinces
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getLocationInfoAction: bindActionCreators(locationActions.get, dispatch),
    updateLocationAction: bindActionCreators(locationActions.update, dispatch),
    locationSettingActions: bindActionCreators(
      locationSettingAction.update,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getMembersAction: bindActionCreators(memberActions.replace, dispatch),
    getSpaceSettingAction: bindActionCreators(spacesSettingAction.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSettingBox)
