import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import * as valid from 'utils/validate'
import { reduxForm } from 'redux-form'
import { Router, Route, Link } from 'react-router'
import { newsAction, successState, apiCallFailure } from 'app/actions'
import {
  KBLoadingContainer,
  KBPopover,
  KBPopoverTop,
  KBInput,
  KBButton,
  KBImageCropperInput,
  KBRadio
} from 'components'
import { api, apiUtils } from 'app/services'
import { NEWS_STATUS, NEWS_STATUS_OBJ } from 'app/constants'
import KBEditor from 'components/plugins/KBEditor'
import * as selectors from 'app/selectors'
import SendMessageForm from '../spaces/LocationSendMessageForm'
import { toImageProxyDirect } from 'utils/kbUtil'
import MobilePreview from '../activities/MobilePreview'
import { notification } from 'antd'

var NewsEditForm = React.createClass({
  displayName: 'NewsEditForm',
  getInitialState() {
    return {
      loading: true,
      preview: false
    }
  },

  componentDidMount() {
    const { news_id, getNewsAction } = this.props
    if (news_id) {
      api
        .getNewsInfo(news_id)
        .then(
          json => {
            getNewsAction.success(json)
          },
          error => {
            getNewsAction.failure(error)
          }
        )
        .finally(() => {
          this.setState({
            loading: false
          })
        })
    }
  },

  createNews(model) {
    const {
      news_id,
      loc_id,
      updateNewsAction,
      createNewsAction,
      routerActions,
      successAction
    } = this.props
    model.location_id = loc_id
    const { preview } = this.state

    delete model.content_type

    if (news_id) {
      return api.updateNews(news_id, model).then(
        json => {
          let new_news = json.response && json.response.body
          successAction({ message: '资讯已更新！' })
          updateNewsAction.success(json)
          if (model && model.status == 'published') {
            KBPopover.show(
              <SendMessageForm
                role={''}
                memberType={'message'}
                msgContent={new_news.title}
              />
            )
          }
          if (preview) {
            KBPopoverTop.show(
              <MobilePreview
                qrcode_url={new_news.preview_qrcode}
                isTop={true}
              />
            )
          }
          routerActions.push(`/admin/memberships/memberManage/news`)
        },
        error => {
          updateNewsAction.failure(error)
        }
      )
    }
    return api.createNews(model).then(
      json => {
        let new_news = json.response && json.response.body
        successAction({ message: '资讯创建成功！' })
        if (model && model.status == 'published') {
          KBPopover.show(
            <SendMessageForm
              role={''}
              memberType={'message'}
              msgContent={new_news.title}
            />
          )
        }
        if (preview) {
          KBPopoverTop.show(
            <MobilePreview qrcode_url={new_news.preview_qrcode} isTop={true} />
          )
        }
        routerActions.push(`/admin/memberships/memberManage/news`)
        createNewsAction.success(json)
      },
      error => {
        createNewsAction.failure(error)
      }
    )
  },
  updateStatus(type) {
    const {
      fields: { status }
    } = this.props
    status.onChange(type)
  },

  // 基本信息
  basicMessageJsx() {
    const {
      fields: { title, content_type }
    } = this.props
    const isImportant = [
      { title: '资讯详情', value: 'detail' },
      { title: '原文链接', value: 'link' }
    ]

    return (
      <fieldset className="fieldset">
        <legend>基本信息</legend>
        <div className="kb-row">
          <KBInput
            title="资讯标题"
            isRequired={true}
            label="true"
            placeholder="请填写资讯标题(不少于5个字)"
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={title}
          />
        </div>
        <div className="kb-row kb-form-66">
          <KBRadio
            className="kb-form-group kb-div-100"
            inClassName="kb-form-group kb-div-33"
            groupStyle={{ width: '100%' }}
            divClassName="kb-form-group kb-div-66-active"
            rightLabel="label-rt"
            arrayType={isImportant}
            name="lock"
            field={content_type}
          />
        </div>
      </fieldset>
    )
  },

  // 原文链接
  urlJsx() {
    const {
      fields: { url }
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend className="clear-fix">
          <span>原文链接</span>
        </legend>
        <div className="kb-row kb-form-66">
          <KBInput
            isRequired={true}
            label="true"
            placeholder="https://www.example.com"
            className="kb-form-group kb-form-c39"
            field={url}
          />
        </div>
      </fieldset>
    )
  },

  // 资讯详情
  detailsJsx() {
    const {
      fields: { details }
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend className="clear-fix">
          <span>资讯详情</span>
        </legend>
        <KBEditor field={details} />
        {details.touched && details.error && (
          <p className="lr-error">{details.error}</p>
        )}
      </fieldset>
    )
  },

  failureCallBack(err) {
  if(!err) return
    notification.error({ message: '上传失败', description: err })
  },

  // 活动封面
  coverJsx() {
    const {
      fields: { image_url }
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend className="clear-fix">
          <span>资讯封面</span>
        </legend>
        <div className="kb-row" style={{ textAlign: 'left' }}>
          <KBImageCropperInput
            title={false}
            aspect={false}
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={image_url}
            type="news"
            accept=".png,.jpg"
            failureCallBack={this.failureCallBack}
            isPublic
          >
            <div className="upload-portrait">
              {image_url && image_url.value ? (
                <img
                  src={toImageProxyDirect(image_url.value, 440, 284)}
                  style={{
                    width: '440px',
                    height: '284px',
                    display: 'inline-block',
                    marginLeft: 0,
                    borderRadius: 0
                  }}
                  alt=""
                />
              ) : (
                ''
              )}
              <div
                className="d-iblock"
                style={{ verticalAlign: 'middle', paddingLeft: 20 }}
              >
                <button className="bordered-btn m-none" type="button">
                  {image_url.dirty ? '图片已上传' : '上传图片'}
                </button>
                <p
                  className="color-nine"
                  style={{ paddingTop: 20, fontSize: 12 }}
                >
                  图片格式为png或jpg,文件大小不大于1M
                </p>
              </div>
            </div>
          </KBImageCropperInput>
        </div>
      </fieldset>
    )
  },

  render() {
    const {
      handleSubmit,
      submitting,
      news_id,
      fields: { content_type }
    } = this.props
    const { loading } = this.state

    return (
      <section className="kb-content-container">
        <header className="nav-section-header clear-fix border-bottom">
          <div className="nav-section-header-title f-left">
            <span>{news_id ? '编辑资讯' : '添加资讯'}</span>
          </div>
        </header>
        <KBLoadingContainer loading={news_id && loading}>
          <div className="kb-form-container">
            <form onSubmit={handleSubmit(this.createNews)}>
              {this.basicMessageJsx()}
              {content_type.value === 'link' ? this.urlJsx() : null}
              {content_type.value === 'detail' ? this.detailsJsx() : null}
              {this.coverJsx()}
              <div className="kb-row m-top-lg">
                <div className="kb-form-fr">
                  <KBButton
                    className="c-btn-warning"
                    submitting={submitting}
                    onClick={() => {
                      this.updateStatus('draft')
                      this.setState({ preview: true })
                    }}
                    style={{ marginRight: 15 }}
                  >
                    保存草稿并预览
                  </KBButton>
                  <KBButton
                    className="certain-btn"
                    submitting={submitting}
                    onClick={() => {
                      this.updateStatus('published')
                    }}
                    style={{ marginRight: 0 }}
                  >
                    立即发布
                  </KBButton>
                </div>
              </div>
            </form>
          </div>
        </KBLoadingContainer>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location, tags } = props
  let loc_id = params.id
  let news_id = location.query && location.query.news_id
  let news = news_id && selectors.getNewsInfo(state, news_id)
  let initValues = news_id ? news : {}

  return {
    loc_id,
    news_id,
    initialValues:
      news_id && news
        ? Object.assign({}, initValues, {
            content_type: news.url ? 'link' : 'detail'
          })
        : { content_type: 'detail' }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getNewsAction: bindActionCreators(newsAction.get, dispatch),
    updateNewsAction: bindActionCreators(newsAction.update, dispatch),
    createNewsAction: bindActionCreators(newsAction.create, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.title) || values.title.length < 5) {
    errors.title = '请输入不少于5个字的资讯标题'
  }

  if (values.content_type === 'detail' && valid.required(values.details)) {
    errors.details = '请输入资讯详情'
  }

  if (values.content_type === 'link' && valid.required(values.url)) {
    errors.url = '请输入资讯链接'
  }

  // if (valid.required(values.image_url)) {
  //   errors.image_url = '请选择封面图片'
  // }

  return errors
}

const formConfig = {
  form: 'NewsEditForm',
  fields: [
    'title',
    'image_url',
    'url',
    'status',
    'location_id',
    'details',
    'body_code',
    'content_type'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(NewsEditForm)
