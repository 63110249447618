import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import { subscriptionActions } from 'app/actions'
import {
  KBTipsy,
  KBFormError,
  KBTableWithoutData,
  KBButton,
  KBPopover
} from 'components'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import * as selectors from 'app/selectors'
import SubscriptionLockAddForm from './SubscriptionLockAddForm'
import _ from 'lodash'

var SubscriptionLockLinkForm = React.createClass({
  getInitialState() {
    const { currentSubscription } = this.props
    let selectedLocks =
      (currentSubscription.locks.length && currentSubscription.locks) ||
      currentSubscription.default_locks ||
      []
    return {
      selectedLocks,
      selectedLockGroupLocks: [],
      allLockGroups: [],
      allLockAreas: []
    }
  },

  linkSubscriptionLocks(model) {
    const {
      nextCallBack,
      currentSubscription,
      updateSubscriptionActions,
      step
    } = this.props
    const { selectedLocks, selectedLockGroupLocks } = this.state
    model.lock_ids = _.pullAllBy(selectedLocks, selectedLockGroupLocks, 'id')
      .map(l => l.id)
      .join(',')
    model.step = step
    return api
      .putSubscriptionLockPermissions(currentSubscription.id, model)
      .then(
        json => {
          updateSubscriptionActions.success(json)
          let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
          nextCallBack && nextCallBack(subscription)
        },
        error => {
          updateSubscriptionActions.failure(error)
        }
      )
  },

  addCallback(lock_groups, lock_areas) {
    const {
      fields: { lock_group_ids }
    } = this.props
    let lockGroupIds = lock_groups.map(lg => lg.id).join(',')
    lock_group_ids.onChange(lockGroupIds)
    let allSelectedLocks = []
    let lockGroupLocks = []
    lock_groups.map(lg => {
      allSelectedLocks = allSelectedLocks.concat(
        (lg.childrens || []).map(lgc => {
          let lock_g = {}
          lock_g.name = lg.name
          lgc.lock_group = lock_g
          return lgc
        })
      )
      lockGroupLocks = lockGroupLocks.concat(lg.childrens || [])
    })
    lock_areas.map(
      la => (allSelectedLocks = allSelectedLocks.concat(la.childrens || []))
    )
    let selectedLocks = _.uniqBy(
      allSelectedLocks.concat(this.state.selectedLocks),
      'id'
    )
    let selectedLockGroupLocks = _.uniqBy(
      this.state.selectedLockGroupLocks.concat(lockGroupLocks),
      'id'
    )
    this.setState({
      selectedLocks,
      selectedLockGroupLocks
    })
  },

  setSelectingData(key, data) {
    this.setState({
      [key]: data
    })
  },

  addLocks() {
    const { currentSubscription } = this.props
    const { allLockGroups, allLockAreas } = this.state
    KBPopover.show(
      <SubscriptionLockAddForm
        loc_id={currentSubscription.location_id}
        allLockGroups={allLockGroups}
        allLockAreas={allLockAreas}
        setSelectingData={this.setSelectingData}
        addCallback={this.addCallback}
      />
    )
  },

  removeLock(lock, index) {
    let newLocks = Object.assign([], this.state.selectedLocks)
    newLocks.splice(index, 1)
    this.setState({
      selectedLocks: newLocks
    })
  },

  render() {
    const { submitting, handleSubmit } = this.props
    const { selectedLocks } = this.state

    return (
      <form onSubmit={handleSubmit(this.linkSubscriptionLocks)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div
            className="kb-title m-bottom-sm"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span className="header-title">关联门禁权限</span>
            <a className="certain-btn-disabled">
            {/* <a className="bordered-btn" onClick={this.addLocks}> */}
              添加门禁权限
            </a>
          </div>
          <div className="kb-tip-warning">
            <div className="icon">
              <i className="iconfont icon-hint" />
            </div>
            <div className="msg">
              默认分配已选择中分区的所有门禁和大门门禁的权限。
            </div>
          </div>
          <table className="content-table">
            <thead>
              <tr>
                <th>门禁名称</th>
                <th>设备编号</th>
                <th>所在区域</th>
                <th>关联门禁组</th>
                <th className="t-right">操作</th>
              </tr>
            </thead>
            <KBTableWithoutData
              hasData={selectedLocks.length > 0}
              tableHeadNum="5"
              tipMessage="暂无门禁设备"
            >
              {selectedLocks &&
                selectedLocks.map((lock, index) => {
                  return (
                    <tr key={index}>
                      <td>{lock.name}</td>
                      <td>{lock.device_id}</td>
                      <td>
                        {lock.is_gate
                          ? '大门'
                          : lock.area
                          ? lock.area.name
                          : '无'}
                      </td>
                      <td>{lock.lock_group ? lock.lock_group.name : '无'}</td>
                      <td className="t-right">
                        <KBTipsy content="移除此门禁的分配">
                          <i
                            className="iconfont icon-delete m-left-sm"
                            style={{ opacity: 1 }}
                            onClick={e => {
                              e.stopPropagation()
                              this.removeLock(lock, index)
                            }}
                          />
                        </KBTipsy>
                      </td>
                    </tr>
                  )
                })}
            </KBTableWithoutData>
          </table>

          <div className="kb-row m-top">
            <KBButton
              className="certain-btn f-right"
              type="submit"
              submitting={submitting}
            >
              确认关联并下一步
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

SubscriptionLockLinkForm.PropTypes = {
  currentSubscription: PropTypes.object
}

function mapStateToProps(state, props) {
  const user = selectors.getCurrentUser(state)

  return {
    user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubscriptionActions: bindActionCreators(
      subscriptionActions.update,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'SubscriptionLockLinkForm',
  fields: ['lock_ids', 'lock_group_ids'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionLockLinkForm)
