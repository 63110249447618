import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  sectionsActions,
  appModulesActions,
  spacesSettingAction
} from 'app/actions'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import { isListValue, getListFirstValue } from 'utils/kbUtil'
import { canAccessSpaceRoles } from 'app/reducers/role'
import {
  subscriptionsAction,
  areaActions,
  orgActions,
  successState
} from 'app/actions'
import * as selectors from 'app/selectors'

var SubscriptionDetailBox = React.createClass({
  displayName: 'SubscriptionDetailBox',
  componentDidMount() {
    const { subscription_id } = this.props

    this._loadData(subscription_id)
  },

  _loadData(subscription_id) {
    api.getSubscription(subscription_id).then(
      json => {
        this.props.getSubscriptionAction.success(json)
        let subscription = json.response.body
        let loc_id = subscription && subscription.location_id
        let sales_customer = (subscription && subscription.sales_customer) || {}
        if (sales_customer.foreign_type == 'org') {
          this.getOrgInfo(loc_id, sales_customer.foreign_id)
        }
      },
      errors => {
        this.props.getSubscriptionAction.failure(errors)
      }
    )
  },

  getOrgInfo(loc_id, org_id) {
    api
      .getOrganizationInfo(org_id, {
        location_id: loc_id
      })
      .then(
        json =>
          this.props.getOrgAction.success(json, {
            organization_id: org_id,
            location_id: loc_id
          }),
        errors => this.props.getOrgAction.failure(errors)
      )
  },

  render() {
    const {
      section,
      isParkingContract,
      pathname,
      typeSource,
      isParkingUrl
    } = this.props
    const { subscription, menu } = this.props

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        pathname,
        typeSource,
        _loadData: this._loadData,
        isParkingContract
      })
    )

    return (
      <div
        className="kb-content"
        style={isParkingContract && isParkingUrl ? { paddingTop: 0 } : {}}
      >
        <nav className="nav-container f-left">
          <div className="nav-container-content">
            <header className="nav-header clear-fix">
              <h2
                className="header-title f-left"
                style={{ wordBreak: 'break-all' }}
              >
                合同：{(subscription && subscription.serial) || ''}
              </h2>
            </header>
            <div className="nav-body">
              <ul className="nav-container-list">
                {menu.map((data, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        isListValue(data.value, section)
                          ? 'nav-container-list-hover'
                          : ''
                      }
                    >
                      <Link
                        to={`${pathname}${getListFirstValue(data.value)}${
                          typeSource && typeSource == 'crmrecord'
                            ? '?typeSource=crmrecord'
                            : ''
                        }`}
                      >
                        {' '}
                        {data.name}{' '}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </nav>
        {childrenWithProps}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { params, location } = props
  let org_id = props.params.organization_id
  let sections = location.pathname.split('/')
  let subscription_id = props.params.subscription_id
  let subscription = selectors.getEntity(
    state,
    'subscriptions',
    subscription_id
  )
  let isParkingUrl = sections[4] === 'parking'
  let isParkingContract =
    isParkingUrl ||
    (subscription && subscription.subscription_type === 'parking')
  let subType = sections[2]
  let sectionType = {
    crm: {
      section: sections[9] || '',
      path: () => {
        sections[9] && sections.pop()
        return sections.join('/')
      }
    },
    invoices: {
      section: sections[8] || '',
      path: () => {
        sections[8] && sections.pop()
        return sections.join('/')
      }
    },
    locations: {
      section:
        isParkingContract && isParkingUrl
          ? sections[7] || ''
          : sections[8] || '',
      path: () => {
        ;(sections[8] || (isParkingContract && isParkingUrl && sections[7])) &&
          sections.pop()
        return sections.join('/')
      }
    }
  }
  let section = sectionType[subType].section
  let pathname = sectionType[subType].path()

  let typeSource = props.location.query.typeSource || null

  const menu = [
    {
      name: '合同信息',
      value: ['']
    },
    {
      name: '操作记录',
      value: ['/history', 'history']
    },
    {
      name: '历史记录',
      value: ['/histories', 'histories']
    },
    {
      name: '合同账单',
      value: ['/invoices', 'invoices']
    }
  ]
  if (isParkingContract) {
    menu.splice(1, 0, {
      name: '车牌变更记录',
      value: ['/plates_record', 'plates_record']
    })
  }

  return {
    user,
    org_id,
    section: section || '',
    isParkingContract,
    pathname,
    subscription_id,
    subscription,
    typeSource,
    menu,
    isParkingUrl
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSubscriptionAction: bindActionCreators(
      subscriptionsAction.get,
      dispatch
    ),
    getOrgAction: bindActionCreators(orgActions.get, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionDetailBox)
