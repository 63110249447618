import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { api } from 'app/services'
import { meeetingDevicesActions } from 'app/actions'
import { connect } from 'react-redux'
import { KBLoadingContainer, KBPopover } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { arrayToObject, toImageProxyDirect } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import { KBNoData } from 'components'

var MeetingTheme = React.createClass({
  getInitialState() {
    return {
      loading: false
    }
  },

  componentDidMount() {
    this.loadData()
  },

  loadData() {
    const { loc_id, getMeetingDevicesActions, isOffice, area } = this.props
    const params = Object.assign(
      {},
      { location_id: loc_id, per_page: 50 },
      isOffice ? { area_id: area.id } : {}
    )

    this.setState({ loading: true })

    api
      .getMeetingDevices(params)
      .then(
        json => {
          getMeetingDevicesActions.success(json)
        },
        errors => {
          getMeetingDevicesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  toPadAddPage() {
    const { loc_id, routerActions } = this.props
    routerActions.push(
      `/admin/locations/${loc_id}/meeting/theme/add?type=meeting`
    )
  },

  deleteDevice(device) {
    const { loc_id, deleteMeetingDevicesActions } = this.props
    const { id } = device

    KBPopoverConfirm({
      name: '删除设备',
      context: `确定删除设备\u00A0${device.name || ''}`,
      callback: () => {
        return api.deleteMeetingDevices(id, { location_id: loc_id }).then(
          json => {
            deleteMeetingDevicesActions.success(json, {
              id,
              key: 'meetingDevices'
            })
            KBPopover.close()
          },
          errors => {
            deleteMeetingDevicesActions.failure(errors)
          }
        )
      }
    })
  },

  render() {
    const { devices, meetingRoomsObj, isOffice, addOfficePad } = this.props
    const { loading } = this.state
    const styleNoData = {
      height: '280px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8,
      paddingTop: 40,
      cursor: 'default'
    }

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>设备列表</span>
          </h2>
          <button
            className="bordered-btn f-right"
            onClick={isOffice ? addOfficePad : this.toPadAddPage}
          >
            添加平板
          </button>
        </header>

        <div
          className="nav-section-content-container"
          style={{ paddingTop: 30 }}
        >
          <KBLoadingContainer loading={loading}>
            {(devices.length > 0 &&
              devices.map((device, index) => {
                return (
                  <div className="visit-setting-block">
                    <div
                      className="title-container"
                      style={{ marginBottom: 0, alignItems: 'center' }}
                    >
                      <div className="vf-center">
                        <img
                          src={toImageProxyDirect(
                            'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/pad_active.png',
                            28,
                            34
                          )}
                          alt=""
                          style={{ display: 'inline-block', marginRight: 10 }}
                        />
                        <div className="title-and-des">
                          <div className="title">{device.name || ''}</div>
                          <div
                            className="des vf-center"
                            style={{ marginTop: 5 }}
                          >
                            <span style={{ color: '#999' }}>
                              绑定会议室:&nbsp;
                            </span>
                            <span>
                              {(device.area_id &&
                                meetingRoomsObj[device.area_id] &&
                                meetingRoomsObj[device.area_id].name) ||
                                ''}
                            </span>
                          </div>
                        </div>
                      </div>
                      <button
                        className="certain-delete-btn"
                        onClick={this.deleteDevice.bind(null, device)}
                      >
                        删除
                      </button>
                    </div>
                  </div>
                )
              })) || (
              <KBNoData tipMessage="暂时没有设备哦" outterStyle={styleNoData} />
            )}
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  let loc_id = params.id
  let devices = selectors.getMeetingDevicesOfLocation(state, loc_id)
  let meetings = selectors.getMeetingRoomsOfLocation(state, loc_id)
  let meetingRooms = meetings.filter(room => {
    return room.area_type === 'meeting_room'
  })
  let meetingRoomsObj = arrayToObject(meetingRooms)
  return {
    loc_id,
    devices,
    meetingRoomsObj
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getMeetingDevicesActions: bindActionCreators(
      meeetingDevicesActions.replace,
      dispatch
    ),
    deleteMeetingDevicesActions: bindActionCreators(
      meeetingDevicesActions.delete,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingTheme)
