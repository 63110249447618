import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { invoicesActions, successState, apiCallFailure } from 'app/actions'
import { api, apiUtils } from 'app/services'
import {
  paginate,
  arrayToObject,
  util,
  formatMinuteSecondEN,
  formatYearDayCHS
} from 'utils/kbUtil'
import { reduxForm } from 'redux-form'
import {
  KBDropdown,
  KBPagination,
  KBCheckBox,
  KBLoadingContainer,
  KBRadioList,
  KBSearchList,
  KBPopover,
  KBRangeDate,
  KBTableWithoutData
} from 'components'
import {
  INVOICES_STATE,
  INVOICES_ORDER_STATE,
  INVOICES_TYPE,
  INVOICES_ORDER_TYPE,
  ORDER_STATE_ARRAY,
  ORDER_STATES_ARRAY,
  INVOICE_ORDER_SALES_PAYMENTS,
  INVOICES_STATE_CLASS,
  PAYMENT_TYPE_ORDER,
  INVOICE_ORDER_CERTIFICATE_PAYMENT_TYPES
} from 'app/constants'
import {
  percent,
  mergeArrayWithKey,
  queryFilter,
  getFilterAndQuery,
  formatYearDayEN,
  kbCloseDropDown
} from 'utils/kbUtil'
import { KBDropSelect } from 'components/plugins'
import * as selectors from 'app/selectors'
import DayPicker, { DateUtils } from 'react-day-picker'
import { routerActions } from 'react-router-redux'
import MomentLocaleUtils from 'react-day-picker/moment'
import { canAccessLocationFinance, canAccessLocation } from 'app/reducers/role'
import InvoicesReport from '../invoices/InvoicesReport'
import moment from 'moment'
import _ from 'lodash'
import {
  getUserInfoUrl,
  getUrlOfOrgDetailPage,
  getUrlOfLocationHomePage
} from 'utils/kbUrl'

const DROP_DOWNS = [
  'location_idDropdown',
  'dateDropDown',
  'statusDropdown',
  'typesDropdown',
  'discountDropdown'
]
const backgroundColorOfState = {
  unpaid: '#f2a73d',
  paid: '#73bb4b',
  cancelled: '#e1e1e1',
  refunding: '#dd5a55',
  refunded: '#0AC862',
  offline_refunded: '#5ccd89',
}

const IS_COUPON = [
  {
    id: true,
    value: '是'
  },
  {
    id: false,
    value: '否'
  }
]

const INVOICE_STATUS = [
  {
    id: 'offline_issued',
    value: '已手工开票'
  },
  {
    id: 'online_issued',
    value: '已线上开票'
  }
]

const REPLACE_STATUS = [
  {
    id: true,
    value: '是'
  },
  {
    id: false,
    value: '否'
  },
]

const THIRD_POINT_STATUS = [
  {
    id: true,
    value: '是'
  },
  {
    id: false,
    value: '否'
  },
]

var OrdersPersonal = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      total: 0,
      paid: 0,
      percentUnpaid: 0,
      invoices: [],
      filters,
      page: this.props.page || 1,
      loading: false,
      queryFiltersStr: '',
      fixedParams: {
        status: filters.status ? filters.status : ORDER_STATE_ARRAY[0].id,
        invoice_type: filters.invoice_type
          ? filters.invoice_type
          : INVOICES_ORDER_TYPE[0].id
      }
    }
  },
  componentDidMount() {
    const { isLocations, params, isMembers } = this.props
    let obj = {}

    this.mounted = true

    if (isLocations) {
      obj.location_id = params.id
    }

    if (isMembers) {
      obj.customer_id = params.member_id
      obj.customer_type = 'user'
    }

    this.setParams(obj)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(model, newPage) {
    const per_page = 10
    const { getInvoicesActions, routerActions, query, currentPath } = this.props
    const { page, filters, fixedParams } = this.state
    const newFixedParams = Object.assign({}, fixedParams, model)

    let params = Object.assign({}, filters, newFixedParams)
    let { from, to } = params
    if (from && to) {
      params.from = from && moment(from).format('YYYY-MM-DD')
      params.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete params.from
      delete params.to
    }

    let queryFiltersStr = queryFilter({
      ...query,
      ...params,
      page: newPage || page
    })
    this.setState({ loading: true })
    return api
      .getInvoices({
        ...newFixedParams,
        ...params,
        per_page,
        page: newPage || page,
        source_type: 'personal'
      })
      .then(
        ({ json, response }) => {
          const { invoices } = json
          let pagination = paginate(response)
          getInvoicesActions.success({
            entities: { invoices: arrayToObject(invoices) }
          })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(`${currentPath}${queryFiltersStr}`)
          if (this.mounted) {
            const { total, unpaid, paid } = json
            this.setState({
              total: total,
              invoices: invoices || [],
              unpaid: unpaid,
              paid: paid,
              pagination: pagination,
              queryFiltersStr
            })
          }
          return true
        },
        errors => {
          getInvoicesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      this._loadData({}, nextProps.page)
      return
    }
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, page: 1 })

    // force refresh
    this._refresh()
  },

  changeDate(range) {
    this._setFilter(range)
  },

  _refresh() {
    this['location_idDropdown'] && this['location_idDropdown'].hide()
    this['statusDropdown'] && this['statusDropdown'].hide()
    this['typesDropdown'] && this['typesDropdown'].hide()
    this['reviewedDropdown'] && this['reviewedDropdown'].hide()
    this['discountDropdown'] && this['discountDropdown'].hide()

    setTimeout(this._loadData)
  },

  _clearFilters() {
    const { isMembers } = this.props
    this.setState({ filters: {}, page: 1 })
    // force refresh
    this._refresh(null, {})
    if (!isMembers) this.refs.dropDownOrg.clearInput()
    // this.refs.invoiceType.clearData()
  },

  _selectRange(range) {
    this.setState({
      ...range
    })
  },

  getOrgs(str, callback) {
    const { params, isLocations } = this.props
    let query = { query: str, type: 'org' }

    if (isLocations) {
      query.location_id = params.id
    }
    util.setTimeout(
      'invoicesSearchInput',
      () => {
        api.getSearchAllType(query).then(({ json }) => {
          callback && callback(json)
        })
      },
      0
    )
  },

  getOrder(str, callback) {
    const { params, isLocations } = this.props
    let query = { query: str, type: 'org' }

    if (isLocations) {
      query.location_id = params.id
    }
    util.setTimeout(
      'invoicesSearchInput',
      () => {
        // api.getSearchAllType(query).then(({ json }) => {
        //   callback && callback(json)
        // })
      },
      0
    )
  },

  getInvoces(str, callback) {
    util.setTimeout(
      'invoicesSearchInput',
      () => {
        api
          .getInvoices({ reference_number: str, source_type: 'personal' })
          .then(({ json }) => {
            let invoicesData = json.invoices
            invoicesData.map(data => {
              return (data.name = data.sales_payments[0].reference_number)
            })
            callback && callback(invoicesData)
          })
      },
      0
    )
  },

  getInvocesBySerialNumber(str, callback) {
    const {location_id} = this.props.location.query
    util.setTimeout(
      'invoicesSearchInput',
      () => {
        api
          .getInvoices({ serial_number: str, source_type: 'personal', location_id })
          .then(({ json }) => {
            let invoicesData = json.invoices
            invoicesData.map(data => {
              return (data.name = data.serial_number)
            })
            callback && callback(invoicesData)
          })
      },
      0
    )
  },

  _selectCallBack(data, searchArg) {
    if(!data) return
    var filters = Object.assign({}, this.state.filters)
    delete filters.customer_type
    delete filters.customer_id
    delete filters.reference_number
    delete filters.source_type

    if(searchArg.name === '订单编号') {
      filters = Object.assign({}, filters, {
        serial_number: data.serial_number,
        source_type: 'personal'
      })
    } else if(searchArg.name === '公司搜索') {
      filters = Object.assign({}, filters, {
        customer_type: 'org',
        customer_id: data.id
      })
    } else {
      // 支付流水号
      if (data && data.sales_payments && data.sales_payments.length > 0) {
        filters = Object.assign({}, filters, {
          reference_number: data.sales_payments[0].reference_number,
          source_type: 'personal'
        })
      }
    }

    this.setState({ filters })
    this._refresh()
  },

  fromToTextRender(from, to) {
    return from || to
      ? `${from ? formatYearDayCHS(from) : ''}${
          to ? '-' + formatYearDayCHS(to) : ''
        }`
      : '选择日期'
  },

  selectInvoiceTypeCallBack(data) {
    this._setFilter({
      invoice_type: data.map(json => json.id).join(',')
    })
  },

  setParams(obj) {
    const { fixedParams } = this.state
    const newParams = Object.assign({}, fixedParams, obj)
    this.setState({
      fixedParams: newParams,
      page: 1
    })
    this._loadData(newParams)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  renderLocationFilters() {
    const { locations, isLocations, isMembers } = this.props
    const iconStyle = { color: '#0a7ae6', paddingRight: '10px' }

    if (isLocations || isMembers) {
      return ''
    }

    return (
      <KBDropdown
        ref={ref => {
          this.location_idDropdown = ref
        }}
        style={{ marginRight: 15 }}
      >
        <KBDropdown.KBDropdownTrigger>
          <div
            className="task-status-title"
            onClick={this.closeDropDowns.bind(null, 'location_idDropdown')}
          >
            <i className="iconfont icon-position" style={iconStyle} />
            <span className="span-pr-smail">
              {this.state.filters.location_id &&
              Object.keys(locations).length > 0
                ? locations[this.state.filters.location_id].name
                : '选择分店'}
            </span>
            <span className="task-deg" />
          </div>
        </KBDropdown.KBDropdownTrigger>
        <KBDropdown.KBDropdownContent>
          <div>
            <ul className="task-type">
              <li onClick={() => this._setFilter({ location_id: '' })}>全部</li>
              {locations &&
                Object.keys(locations).map((location_id, index) => {
                  let location = locations[location_id]
                  return (
                    <li
                      key={index}
                      onClick={() =>
                        this._setFilter({ location_id: location.id })
                      }
                    >
                      <span>{location.name}</span>
                    </li>
                  )
                })}
            </ul>
          </div>
        </KBDropdown.KBDropdownContent>
      </KBDropdown>
    )
  },

  _renderFilters() {
    const { from, to, has_discount, invoicing_issued_type, has_discount_goods, has_coupon_template, deduct_star_point } = this.props.filters
    const { status, invoice_type } = this.state.fixedParams
    const { locations, defaultInvoiceTypes } = this.props
    const iconStyle = { color: '#0a7ae6', paddingRight: '10px' }
    const spanStyle = { paddingRight: '20px' }
    let searchArgument = [
      {
        name: '支付流水号',
        ajax: this.getInvoces,
        placeholder: '请输入支付流水号'
      },
      {
        name: '公司搜索',
        ajax: this.getOrgs,
        placeholder: '请输入公司名称'
      },
      {
        name: '订单编号',
        ajax: this.getInvocesBySerialNumber,
        placeholder: '请输入订单编号'
      },
    ]
    return (
      <div>
        <div
          className="select-progress dc-mr-smail"
          style={{ marginRight: 0, marginBottom: 0 }}
        >
          <KBRangeDate
            format="YYYY/MM/DD"
            title="交易日期"
            spanStyle={spanStyle}
            iconStyle={iconStyle}
            leftNone={true}
            from={from || ''}
            to={to || ''}
            callback={this.changeDate}
            ref={ref => {
              this.dateDropDown = ref
            }}
            dropDown_name="dateDropDown"
            closeClick={this.closeDropDowns}
            style={{ marginRight: 15 }}
          />
          {this.renderLocationFilters()}
          {<KBDropdown
            ref={ref => {
              this.typesDropdown = ref
            }}
            style={{ marginBottom: '10px', float: 'left' }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'typesDropdown')}
              >
                <span className="span-pr-smail">
                  {this.state.filters.payment_mode
                    ? INVOICE_ORDER_SALES_PAYMENTS[
                        this.state.filters.payment_mode
                      ]
                    : '支付类型'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter({ payment_mode: '' })}>
                    全部
                  </li>
                  {INVOICE_ORDER_CERTIFICATE_PAYMENT_TYPES.map(
                    (state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ payment_mode: state.id })
                          }
                        >
                          <span>{state.name}</span>
                        </li>
                      )
                    }
                  )}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>}
          {
            <KBDropdown
              ref={ref => {
                this.discountDropdown = ref
              }}
              style={{ marginBottom: '10px', marginRight: 15 }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'discountDropdown')}
                >
                  <span className="span-pr-smail">
                    是否优惠：
                    {
                      (IS_COUPON.filter(item => String(item.id) === String(has_discount))[0] || {})
                        .value||'全部'
                    }
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ has_discount: '' })}>
                      全部
                    </li>
                    {IS_COUPON.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ has_discount: state.id })
                          }
                        >
                          <span>{state.value}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          }
          {
            <KBDropdown
              ref={ref => {
                this.discountDropdown = ref
              }}
              style={{ marginBottom: '10px', marginRight: 15 }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'discountDropdown')}
                >
                  <span className="span-pr-smail">
                    开票状态：
                    {
                      (INVOICE_STATUS.filter(item => String(item.id) === String(invoicing_issued_type))[0] || {})
                        .value||'全部'
                    }
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ invoicing_issued_type: '' })}>
                      全部
                    </li>
                    {INVOICE_STATUS.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ invoicing_issued_type: state.id })
                          }
                        >
                          <span>{state.value}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          }
          {
            <KBDropdown
              ref={ref => {
                this.discountDropdown = ref
              }}
              style={{ marginBottom: '10px', marginRight: 15 }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'discountDropdown')}
                >
                  <span className="span-pr-smail">
                    是否加购权益：
                    {
                      (REPLACE_STATUS.filter(item => String(item.id) === String(has_discount_goods))[0] || {})
                        .value||'全部'
                    }
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ has_discount_goods: '' })}>
                      全部
                    </li>
                    {REPLACE_STATUS.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ has_discount_goods: state.id })
                          }
                        >
                          <span>{state.value}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          }
          {
            <KBDropdown
              ref={ref => {
                this.discountDropdown = ref
              }}
              style={{ marginBottom: '10px', marginRight: 15 }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'discountDropdown')}
                >
                  <span className="span-pr-smail">
                    是否优惠券：
                    {
                      (REPLACE_STATUS.filter(item => String(item.id) === String(has_coupon_template))[0] || {})
                        .value||'全部'
                    }
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ has_coupon_template: '' })}>
                      全部
                    </li>
                    {REPLACE_STATUS.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ has_coupon_template: state.id })
                          }
                        >
                          <span>{state.value}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          }
          {
            <KBDropdown
              ref={ref => {
                this.discountDropdown = ref
              }}
              style={{ marginBottom: '10px', marginRight: 15 }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'discountDropdown')}
                >
                  <span className="span-pr-smail">
                  是否积分抵扣：
                    {
                      (THIRD_POINT_STATUS.filter(item => String(item.id) === String(deduct_star_point))[0] || {})
                        .value||'全部'
                    }
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ deduct_star_point: '' })}>
                      全部
                    </li>
                    {THIRD_POINT_STATUS.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ deduct_star_point: state.id })
                          }
                        >
                          <span>{state.value}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          }
          <KBSearchList
            inputStyle={{
              width: '200px'
            }}
            selectCallBack={this._selectCallBack}
            searchArgument={searchArgument}
            ref="dropDownOrg"
            style={{
              display: 'inline-block',
              marginLeft: '8px',
              width: '159px'
            }}
          />
        </div>
        <div
          className="select-progress dc-mr-smail f-left"
          style={{ marginRight: 24, marginTop: '10px' }}
        >
          {ORDER_STATE_ARRAY.map((data, index) => {
            return (
              <div
                key={index}
                style={{ marginRight: 0, padding: '7px 9px' }}
                className={`border-show_status ${
                  status == data.id ? 'border-show_status_active' : ''
                }`}
                onClick={() => this.setParams({ status: data.id })}
              >
                <span
                  className={`color-show`}
                  style={{
                    border: 0,
                    width: 16,
                    height: 16,
                    marginRight: 6,
                    backgroundColor: `${
                      backgroundColorOfState[data.id]
                        ? backgroundColorOfState[data.id]
                        : status
                        ? '#2ea1f8'
                        : '#ffffff'
                    }`
                  }}
                ></span>
                <span className="status-text">{data.name}</span>
              </div>
            )
          })}
        </div>
        <div className="f-left" style={{ margin: '10px 0 8px' }}>
          {INVOICES_ORDER_TYPE.map((json, index) => {
            const isCheckedType = json.id == invoice_type
            return (
              <div
                key={index}
                style={{ marginRight: 0, padding: '7px 13px' }}
                className={`border-show_status ${
                  isCheckedType ? 'border-show_status_active' : ''
                }`}
                onClick={() => this.setParams({ invoice_type: json.id })}
              >
                <span className="status-text">{json.name}</span>
              </div>
            )
          })}
        </div>
      </div>
    )
  },

  memberFilter() {
    const { from, to, has_discount } = this.props.filters
    const { status, invoice_type } = this.state.fixedParams
    const { locations, defaultInvoiceTypes, isMembers } = this.props
    const iconStyle = { color: '#0a7ae6', paddingRight: '10px' }
    const spanStyle = { paddingRight: '20px' }
    return (
      <div>
        <div
          className="select-progress dc-mr-smail"
          style={{ marginRight: 0, marginBottom: 0 }}
        >
          <KBRangeDate
            style={{ float: 'left' }}
            format="YYYY/MM/DD"
            title="交易日期"
            spanStyle={spanStyle}
            iconStyle={iconStyle}
            leftNone={true}
            from={from || ''}
            to={to || ''}
            ref={ref => {
              this.dateDropDown = ref
            }}
            dropDown_name="dateDropDown"
            closeClick={this.closeDropDowns}
            callback={this.changeDate}
          />

          <KBDropdown
            ref={ref => {
              this.typesDropdown = ref
            }}
            style={{ marginBottom: '20px', float: 'left' }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'typesDropdown')}
              >
                <span className="span-pr-smail">
                  {this.state.filters.payment_mode
                    ? INVOICE_ORDER_SALES_PAYMENTS[
                        this.state.filters.payment_mode
                      ]
                    : '支付类型'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter({ payment_mode: '' })}>
                    全部
                  </li>
                  {INVOICE_ORDER_CERTIFICATE_PAYMENT_TYPES.map(
                    (state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ payment_mode: state.id })
                          }
                        >
                          <span>{state.name}</span>
                        </li>
                      )
                    }
                  )}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
          <KBDropdown
            ref={ref => {
              this.discountDropdown = ref
            }}
            style={{ marginBottom: '10px', marginRight: 15 }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'discountDropdown')}
              >
                <span className="span-pr-smail">
                  是否优惠：
                  {
                    (IS_COUPON.filter(item => String(item.id)===String(has_discount))[0] || {})
                      .value
                  }
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter({ has_discount: '' })}>
                    全部
                  </li>
                  {IS_COUPON.map((state, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          this._setFilter({ has_discount: state.id })
                        }
                      >
                        <span>{state.value}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        </div>
        <div style={{ marginBottom: 5, display: 'inline-block' }}>
          <div
            style={{
              display: 'inline-block',
              marginRight: 15,
              marginBottom: 15
            }}
            className="f-left"
          >
            {ORDER_STATE_ARRAY.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{ marginRight: 0, padding: '7px 9px' }}
                  className={`border-show_status ${
                    status == data.id ? 'border-show_status_active' : ''
                  }`}
                  onClick={() => this.setParams({ status: data.id })}
                >
                  <span
                    className={`color-show`}
                    style={{
                      border: 0,
                      width: 16,
                      height: 16,
                      marginRight: 6,
                      backgroundColor: `${
                        backgroundColorOfState[data.id]
                          ? backgroundColorOfState[data.id]
                          : status
                          ? '#2ea1f8'
                          : '#ffffff'
                      }`
                    }}
                  ></span>
                  <span className="status-text">{data.name}</span>
                </div>
              )
            })}
          </div>
          <div className="f-left">
            {INVOICES_ORDER_TYPE.map((json, index) => {
              const isCheckedType = json.id == invoice_type
              return (
                <div
                  key={index}
                  style={{ marginRight: 0, padding: '7px 13px' }}
                  className={`border-show_status ${
                    isCheckedType ? 'border-show_status_active' : ''
                  }`}
                  onClick={() => this.setParams({ invoice_type: json.id })}
                >
                  <span className="status-text">{json.name}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  },

  toOrderInfo(invoice) {
    const { routerActions, currentPath, isLocations, isMembers } = this.props
    routerActions.push(
      `${currentPath}/${invoice.id}?type=${
        isLocations || isMembers ? '' : 'membership'
      }`
    )
  },

  disposeTotal(invoice) {
    const { payment_type, total_amount, total_credits, total_points } = invoice
    if (payment_type == 'point') {
      return total_points
    }
    if (payment_type == 'credits') {
      return total_credits
    }
    return total_amount
  },

  orderReport() {
    const { isLocations, loc_id, member } = this.props
    const { filters, fixedParams } = this.state

    let reportFilters = fixedParams
    if (filters && filters.from) {
      reportFilters.from = filters.from
    }
    if (filters && filters.to) {
      reportFilters.to = filters.to
    }

    KBPopover.show(
      <InvoicesReport
        reportType="orders"
        orderStateData={ORDER_STATES_ARRAY}
        loc_id={loc_id}
        invoiceTypes={INVOICES_ORDER_TYPE}
        isLocations={isLocations}
        filters={reportFilters}
        member={member}
      />
    )
  },

  invoicesJsx() {
    const { invoices, pagination, loading, queryFiltersStr } = this.state
    const {
      exchangeUnit,
      currentPath,
      isMembers,
      isManager,
      isFinance,
      loc_id
    } = this.props
    delete this.state.filters.status
    delete this.state.filters.invoice_type
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    return (
      <div>
        <header className="nav-header" style={{ border: 'none' }}>
          <div
            className=" clear-fix"
            style={{ borderBottom: '1px solid #bbb', paddingBottom: '10px' }}
          >
            <div className="nav-section-header-title f-left">
              <span>订单</span>
            </div>
            <div className="f-right">
              {isManager || isFinance ? (
                <button className="c-btn-secondary" onClick={this.orderReport}>
                  导出订单
                </button>
              ) : null}
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          <div>
            {isMembers ? this.memberFilter() : this._renderFilters()}
            {selectFilters.length > 0 ? (
              <div
                className="clear-criteria"
                style={{ marginTop: 5 }}
                onClick={this._clearFilters}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : null}
          </div>
          <div style={{ float: 'left', width: '100%', position: 'relative' }}>
            <KBLoadingContainer loading={loading}>
              <table className="content-table table-align">
                <thead style={{ whiteSpace: 'nowrap' }}>
                  <tr>
                    <th>订单编号</th>
                    <th>应付金额</th>
                    <th style={{ width: 80 }}>收款状态</th>
                    <th>订单类型</th>
                    <th>所在分店</th>
                    <th>客户名称</th>
                    <th>交易时间</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={invoices.length > 0}
                  tableHeadNum="7"
                  tipMessage="暂无订单信息"
                >
                  {invoices &&
                    invoices.map((json, index) => {
                      let total = this.disposeTotal(json)
                      total = Math.round(total * 100) / 100
                      let isUser =
                        json.sales_customer &&
                        json.sales_customer.foreign_type === 'user'
                      let isOrg =
                        json.sales_customer &&
                        json.sales_customer.foreign_type === 'org'
                      return (
                        <tr key={index}>
                          <td style={{ verticalAlign: 'middle' }}>
                            <span
                              className="color-link a-hover"
                              onClick={e => {
                                e.stopPropagation()
                                this.toOrderInfo(json)
                              }}
                            >
                              {json.serial_number}
                            </span>
                            {
                              // json.sales_payments.length && json.payment_type !== 'point' ? (
                              //   <div style={{marginTop: 5}}>
                              //     <span className='span-title-smail'>流水号：</span>
                              //     { json.sales_payments.map((j, index) =>
                              //       <span key={ index } className='span-title-smail'>{ j.reference_number }{ index != (json.sales_payments.length - 1) ? ',' : '' }</span>
                              //       ) }
                              //   </div>
                              // ) : ''
                            }
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            {json.payment_type == 'money'
                              ? '¥ ' + total
                              : json.payment_type == 'point'
                              ? total + ' ' + exchangeUnit
                              : '¥ ' + total}{' '}
                            {json.payment_type && INVOICE_ORDER_SALES_PAYMENTS[
                                    json.payment_mode || json.payment_type
                                  ] ? (
                              <span
                                className="span-type-smail-i"
                                style={{ display: 'inline-block' }}
                              >
                                {
                                  INVOICE_ORDER_SALES_PAYMENTS[
                                    json.payment_mode || json.payment_type
                                  ]
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <span
                              style={{ marginLeft: 0 }}
                              className={`order-default ${
                                INVOICES_STATE_CLASS[json.status]
                              }`}
                            >
                              {INVOICES_STATE[json.status]}
                            </span>
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            {INVOICES_TYPE[json.invoice_type]}
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <Link
                              className="color-link"
                              to={getUrlOfLocationHomePage(loc_id)}
                            >
                              {json.location && json.location.name}
                            </Link>
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <span
                              style={{ display: 'inline-block', marginTop: 5 }}
                            >
                              {isUser ? (
                                <i className="iconfont icon-account_circle" />
                              ) : isOrg ? (
                                <i className="iconfont icon-company2" />
                              ) : (
                                ''
                              )}
                              <span style={{ marginLeft: 5 }}>
                                <Link
                                  className={
                                    isUser || isOrg ? 'color-link' : ''
                                  }
                                  to={
                                    isUser
                                      ? getUserInfoUrl(
                                          json.sales_customer.foreign_id
                                        )
                                      : isOrg
                                      ? getUrlOfOrgDetailPage(
                                          loc_id,
                                          json.sales_customer.foreign_id
                                        )
                                      : ''
                                  }
                                >
                                  {json.sales_customer.name}
                                </Link>
                              </span>
                            </span>
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            {(json.created_at &&
                              formatMinuteSecondEN(json.created_at)) ||
                              ''}
                          </td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={pagination}
                template={`${currentPath}?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  },

  render() {
    const { isLocations, isMembers, isMemberShips } = this.props
    if (isLocations) {
      return (
        <div className="clear-fix" style={{ height: '100%' }}>
          <section
            className="kb-content"
            style={{ marginTop: 0, paddingTop: 0 }}
          >
            <div className="kb-content-container clear-fix">
              {this.invoicesJsx()}
            </div>
          </section>
        </div>
      )
    }

    if (isMembers) {
      return (
        <div className="nav-section-container f-right">
          {this.invoicesJsx()}
        </div>
      )
    }

    return (
      <div className="kb-content-container clear-fix" style={{ paddingTop: 0 }}>
        {this.invoicesJsx()}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  const { user } = state

  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page
  let sections = props.location.pathname.split('/')
  let isMemberShipPath =
    sections[2] === 'memberships' && sections[3] === 'memberManage'
  let currentSectionsPath = sections[sections.length - 1]
  let currentPath = '/admin/memberships/memberManage/orders'
  // 当前订单显示在会员管理里面
  let isMemberShips = false
  // 当前订单显示在会员详情
  let isMembers = false
  // 当前订单显示在分店下订单
  let isLocations = false
  let loc_id = props.params.id
  let member_id = props.params.member_id
  let org_id = props.params.organization_id
  if (currentSectionsPath.indexOf('membership_orders') != -1) {
    currentPath = '/admin/invoices/membership_orders'
    isMemberShips = true
  } else if (currentSectionsPath.indexOf('member_orders') != -1) {
    currentPath = `${getUserInfoUrl(member_id, isMemberShipPath)}/member_orders`
    isMembers = true
  } else if (currentSectionsPath.indexOf('location_orders') != -1) {
    currentPath = `/admin/locations/${loc_id}/invoices/location_orders`
    isLocations = true
  }

  let locations = selectors.getSerializeLocationObj(state)
  var FILTER_STATE

  FILTER_STATE = [
    'from',
    'to',
    'location_id',
    'invoice_type',
    'payment_mode',
    'customer_id',
    'customer_type',
    'status',
    'has_discount',
    'invoicing_issued_type',
    'has_discount_goods',
    'has_coupon_template',
    'deduct_star_point'
  ]
  if (isLocations) {
    FILTER_STATE = [
      'from',
      'to',
      'invoice_type',
      'payment_mode',
      'customer_id',
      'customer_type',
      'status',
      'has_discount',
      'invoicing_issued_type',
      'has_discount_goods',
      'has_coupon_template',
      'deduct_star_point'
    ]
  } else if (isMembers) {
    FILTER_STATE = [
      'from',
      'to',
      'invoice_type',
      'payment_mode',
      'status',
      'has_discount',
      'invoicing_issued_type',
      'has_discount_goods',
      'has_coupon_template',
      'deduct_star_point'
    ]
  }

  var params = getFilterAndQuery(location.query, FILTER_STATE)

  let defaultInvoiceTypes = []
  if (params.filters && params.filters.invoice_type) {
    let invoice_types = params.filters.invoice_type.split(',')
    defaultInvoiceTypes = INVOICES_ORDER_TYPE.filter(data =>
      invoice_types.find(id => id == data.id)
    )
  }

  const spaceSetting = selectors.getSpaceSetting(state)
  const exchangeUnit ='办公券'
  let location_id = props.params.id
  let isManager = canAccessLocation(user, location_id)
  let isFinance = canAccessLocationFinance(user, location_id)
  return {
    page,
    loc_id,
    currentPath,
    isMemberShips,
    defaultInvoiceTypes,
    isMembers,
    isManager,
    isFinance,
    isLocations,
    locations,
    ...params,
    exchangeUnit
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.all, dispatch),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  return errors
}

const formConfig = {
  form: 'OrdersPersonal',
  fields: ['status', 'from', 'to'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrdersPersonal)
