import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { meetingsActions } from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import {
  KBLoadingContainer,
  KBPopover,
  KBPagination,
  KBDropdown
} from 'components'
import DayPicker, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import MeetingBookingForm from './MeetingBookingForm'
import { KBDayPicker } from 'components/views'
import * as selectors from 'app/selectors'
import * as Constants from 'app/constants'
import { getEntitiesJsonArray, paginate } from 'utils/kbUtil'
import moment from 'moment'
import { getInitialDate } from 'utils/kbData'
import { KBSelect, KBASNumberInput } from 'components/views'
import { DAY_TIME_HM } from 'app/constants'

var MeetingAddBooking = React.createClass({
  displayName: 'MeetingAddBooking',
  getInitialState() {
    return {
      filters: {},
      meetings: this.props.meetings || []
    }
  },
  getDefaultProps() {
    return {}
  },
  addBooking(meeting) {
    const { loc_id, setting_reservation_time } = this.props
    const { filters } = this.state
    const { time, start_at, end_at } = filters

    let st = moment(time)
      .add(1, 'hours')
      .startOf('hours')
    let et = moment(time)
      .add(2, 'hours')
      .startOf('hours')

    if (start_at) {
      st = moment(time).set({
        hour: start_at.split(':')[0],
        minute: start_at.split(':')[1],
        second: 0
      })
    }

    if (end_at) {
      et = moment(time).set({
        hour: end_at.split(':')[0],
        minute: end_at.split(':')[1],
        second: 0
      })
    }

    KBPopover.show(
      <MeetingBookingForm
        start_at={st}
        end_at={et}
        reservation_time_range={setting_reservation_time}
        initialValues={{
          start_at: st,
          end_at: et
        }}
        callback={this.searchMeeting}
        meeting={meeting}
        loc_id={loc_id}
      />
    )
  },
  componentDidMount() {
    const { condition } = this.props

    var filters = {
      ...condition,
      time: condition
        ? moment(condition.time).toDate()
        : getInitialDate().initStartDate.toDate()
    }

    this._loadData(1, filters)
    this.setState({
      filters
    })
  },
  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      this._loadData(nextProps.page, this.state.filters)
    }
  },

  setHMString(date, value) {
    let t = value.split(':')

    return moment(date)
      .clone()
      .set({ hour: t[0], minute: t[1], second: 0 })
      .format('YYYY-MM-DD HH:mm:ss')
  },

  _loadData(page, filter) {
    const per_page = 50
    const { getMeetingsActions, loc_id } = this.props
    const { start_at, end_at, time } = filter
    var params = Object.assign({}, filter)
    var data = getInitialDate(time)

    if (!time || !start_at) {
      params.start_at = data.initStartDateString
    }

    if (start_at) {
      params.start_at = this.setHMString(moment(time), start_at)
    }

    if (end_at) {
      params.end_at = this.setHMString(moment(time), end_at)
    }

    delete params.time

    api
      .getMeetingRooms({ location_id: loc_id, per_page, page, ...params })
      .then(
        json => {
          getMeetingsActions.success(json)
          let pagination = paginate(json.response)
          let meetings = getEntitiesJsonArray(json, 'meetingRoots')
          this.setState({
            pagination,
            meetings: meetings.length ? meetings : []
          })
        },
        errors => {
          getMeetingsActions.failure(errors)
        }
      )
  },

  _setFilter(key, value) {
    let newFilters = Object.assign({}, this.state.filters, { [key]: value })
    this.setState({
      filters: newFilters
    })
    let dropDown = key + 'Dropdown'
    this.refs[dropDown] && this.refs[dropDown].hide()
  },

  _clearFilters() {
    const { page, routerActions, loc_id } = this.props
    this.setState({
      filters: {}
    })
    this._loadData(1, {})
    routerActions.replace(`/admin/locations/${loc_id}/meeting/booking`)
  },

  searchMeeting() {
    const { page } = this.props
    const { filters } = this.state
    this._loadData(1, filters)
  },

  showStartAndEndJsx() {
    const {
      filters: { start_at, end_at }
    } = this.state

    return (
      <span style={{ marginRight: 5 }}>
        {start_at ? moment(start_at).format('MM-DD HH:mm') : '起始时间'}-
        {end_at ? moment(end_at).format('MM-DD HH:mm') : '结束时间'}
      </span>
    )
  },

  changeDayPicker(date) {
    this._setFilter('time', date)
  },

  changeStartHMCallback(value) {
    this._setFilter('start_at', this.getHMName(value))
  },

  changeEndHMCallback(value) {
    this._setFilter('end_at', this.getHMName(value))
  },

  changeCapacity(value) {
    let number = value
    let newFilters = Object.assign({}, this.state.filters, { capacity: number })
    this.setState({
      filters: newFilters
    })
  },

  getHMName(value) {
    const { DEAL_DAY_TIME_HM } = this.props
    return DEAL_DAY_TIME_HM.find(json => json.id == value).name
  },

  parseTime(str) {
    if (!str) {
      return ''
    }
    let timeArray = str.split(':')

    let t = parseInt(timeArray[1])
    let h = parseInt(timeArray[0])
    if (t <= 15) {
      t = '1'
    } else if (t < 30) {
      t = '1'
    } else if (t <= 45) {
      t = '2'
    } else {
      t = '2'
      h = h + 1
    }

    if (h <= 9) {
      h = '' + h
    }
    return h + ',' + t
  },

  _renderFilters() {
    const {
      filters: { start_at, end_at, time, capacity }
    } = this.state
    const { DEAL_DAY_TIME_HM } = this.props

    return (
      <div className="nav-section-action-bar">
        <KBDropdown ref="timeDropdown">
          <KBDropdown.KBDropdownTrigger>
            <div className="task-status-title">
              <i
                className="iconfont icon-calendar"
                style={{ marginRight: '3px' }}
              />
              <span>
                {time
                  ? moment(time).format('YYYY-MM-DD')
                  : `${getInitialDate(time).initStartDate.format(
                    'YYYY-MM-DD'
                  )}`}
              </span>
              <span className="task-deg" />
            </div>
          </KBDropdown.KBDropdownTrigger>
          <KBDropdown.KBDropdownContent>
            <KBDayPicker onChange={this.changeDayPicker} selectedDay={time} />
          </KBDropdown.KBDropdownContent>
        </KBDropdown>

        <div className="set-select-box" style={{ marginLeft: 15 }}>
          <span style={{ marginRight: 5 }}>开始时间</span>
          <KBSelect
            selectStyle={{ width: 100 }}
            value={this.parseTime(start_at)}
            arrayData={DEAL_DAY_TIME_HM}
            callback={this.changeStartHMCallback}
          />
        </div>

        <div className="set-select-box" style={{ marginLeft: 15 }}>
          <span style={{ marginRight: 5 }}>结束时间</span>
          <KBSelect
            selectStyle={{ width: 100 }}
            value={this.parseTime(end_at)}
            arrayData={DEAL_DAY_TIME_HM}
            callback={this.changeEndHMCallback}
          />
        </div>

        <div className="d-iblock" style={{ marginLeft: 15 }}>
          <span style={{ marginRight: 5 }}>容纳人数</span>
          {/*<input value={ capacity || '' } type='text' onChange={ this.changeCapacity } />*/}
          <KBASNumberInput
            value={capacity || ''}
            spanStyle={{ width: 31, height: 31, lineHeight: '31px', top: 1 }}
            type="number"
            inputStyle={{
              textAlign: 'center',
              borderColor: '#e6e6e6',
              height: 33,
              padding: '0 36px'
            }}
            callback={this.changeCapacity}
          />
        </div>

        <button
          className="bordered-btn"
          style={{
            marginLeft: 20,
            padding: '6px 20px',
            fontSize: 14,
            borderRadius: '100px'
          }}
          onClick={this.searchMeeting}
        >
          搜索
        </button>
      </div>
    )
  },
  devicesJsx(devices) {
    if (!devices.length) {
      return <div className="meeting-device"></div>
    }
    let whiteboard = devices.find(device => device.id == 1)
    let tv = devices.find(device => device.id == 2)
    let camera = devices.find(device => device.id == 3)
    return (
      <div className="meeting-device">
        {tv ? (
          <span>
            <i className="iconfont icon-tv" />
            TV
          </span>
        ) : (
            ''
          )}
        {whiteboard ? (
          <span>
            <i className="iconfont icon-Whiteboard" />
            白板
          </span>
        ) : (
            ''
          )}
        {camera ? (
          <span>
            <i className="iconfont icon-camera" />
            摄影
          </span>
        ) : (
            ''
          )}
      </div>
    )
  },
  render() {
    const { filters, meetings, pagination } = this.state
    const { loc_id } = this.props

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>添加新预约</span>
          </h2>
        </header>
        <div className="nav-section-content-container">
          <div className="m-updown">
            {this._renderFilters()}
            {Object.keys(filters).length ? (
              <div
                className="clear-criteria"
                onClick={this._clearFilters}
                style={{ marginTop: 10 }}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : (
                ''
              )}
          </div>
          <table className="content-table  edit-table">
            <thead>
              <tr>
                <th>会议室名称</th>
                <th>可容纳人数</th>
                <th>元/小时</th>
                <th className="t-right" style={{ paddingRight: 40 }}>
                  操作
                </th>
              </tr>
            </thead>
            <tbody>
              {meetings &&
                meetings.map((meeting, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ width: 400 }}>
                        <span style={{ fontSize: 16, fontWeight: 500 }}>
                          {meeting.name}
                        </span>
                        {this.devicesJsx(meeting.devices)}
                      </td>
                      <td>{meeting.capacity || 0}人</td>
                      <td>{meeting.listing_price}</td>
                      <td className="t-right">
                        <button
                          className="certain-btn"
                          onClick={this.addBooking.bind(null, meeting)}
                        >
                          预约
                        </button>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <KBPagination
            pagination={pagination}
            template={`/admin/locations/${loc_id}/meeting/booking?page=#PAGE#`}
          />
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { query } = location
  let loc_id = params.id
  let locationInfo = selectors.getCurrentLocation(state, loc_id)
  const reservationTimeRange =
    locationInfo && locationInfo.reservation_time_range
  let setting_reservation_time =
    reservationTimeRange &&
      reservationTimeRange.default &&
      Object.keys(reservationTimeRange.default).length
      ? reservationTimeRange.default
      : { begin: 0, end: 24 }
  let { begin, start } = setting_reservation_time
  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page
  let meetings = selectors.getMeetingRoomsOfLocation(state, loc_id)
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(begin || 0, start || 24)
  const data = getInitialDate()
  var condition = {
    ...query
  }

  if (!query.time && !query.start_at && !query.end_at) {
    condition = null
  }

  return {
    meetings,
    condition,
    page,
    loc_id,
    DEAL_DAY_TIME_HM,
    setting_reservation_time
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMeetingsActions: bindActionCreators(meetingsActions.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingAddBooking)
