import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { routerActions } from 'react-router-redux'
import * as valid from 'utils/validate'
import { DAY_TIME_HM } from 'app/constants'
import { api, apiUtils } from 'app/services'
import {
  KBPopover,
  KBPopoverTop,
  KBUserInput,
  KBFormError,
  KBButton,
  KBDateInput,
  KBSelect,
  KBToReportCenter
} from 'components'
import moment from 'moment'
import { EXPORT_HINT } from 'app/constants/hint'

var MeetingRoomReportForm = React.createClass({
  getInitialState() {
    return {}
  },

  componentDidMount() {
    const {
      fields: { startYMD, startHM, endHM }
    } = this.props
    let defaultStartDate = moment()
      .subtract(1, 'weeks')
      .format('YYYY-MM-DD')
    this.changeStartHMCallback('0,1')
    this.changeEndHMCallback('24,1')
    startHM.onChange('0,1')
    endHM.onChange('24,1')
    setTimeout(() => {
      this.changeStartYMDCallback(defaultStartDate)
    })
    startYMD.onChange(defaultStartDate)
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="Excel导出"
        message="Excel已成功导出，可以前往报表中心下载"
      />
    )
  },

  reportMeetingRoom(model) {
    const { loc_id } = this.props
    const { start_time, end_time } = model
    let params = Object.assign(
      {},
      {
        start_at: moment(start_time).format('YYYY-MM-DD HH:mm:ss'),
        end_at: moment(end_time).format('YYYY-MM-DD HH:mm:ss'),
        location_id: loc_id
      }
    )
    return api.postMeetingRoomReport(params).then(() => {
      this.toReportCenter && this.toReportCenter()
    })
  },

  changeStartYMDCallback(data) {
    const {
      fields: { start_time }
    } = this.props
    this.setYMD(start_time, data)
  },

  changeEndYMDCallback(data) {
    const {
      fields: { end_time }
    } = this.props
    this.setYMD(end_time, data)
  },

  changeStartHMCallback(data) {
    const {
      fields: { start_time }
    } = this.props
    this.setHM(start_time, data)
  },

  changeEndHMCallback(data) {
    const {
      fields: { end_time }
    } = this.props
    this.setHM(end_time, data)
  },

  setYMD(field, value) {
    let date = moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  setHM(field, value) {
    let t = value.split(',')
    let isLastHour = t[0] === 24 || t[0] === '24'
    field.onChange(
      moment(field.value)
        .clone()
        .set({
          hour: isLastHour ? 23 : t[0],
          minute: t[1] == '1' ? (isLastHour ? 59 : 0) : 30,
          second: 0
        })
        .toDate()
    )
  },

  getDisabledDays(date) {
    // 最近6个月
    const earliest = moment().subtract(6, 'month')
    const day = moment(date)
    const today = moment()
    return !day.isBetween(earliest, today)
  },

  render() {
    const {
      fields: { startYMD, startHM, endYMD, endHM, start_time, end_time },
      error,
      submitting,
      handleSubmit,
      DEAL_DAY_TIME_HM
    } = this.props
    var halfClass = {
      className: 'kb-form-group kb-div-c48'
    }
    return (
      <form onSubmit={handleSubmit(this.reportMeetingRoom)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">导出会议室记录</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="开始日期"
              isRequired={true}
              className="kb-form-group kb-div-48"
              field={startYMD}
              disabledDays={this.getDisabledDays}
              onChange={this.changeStartYMDCallback}
            />
            <KBSelect
              title="开始时间"
              onChange={this.changeStartHMCallback}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
              field={startHM}
              {...halfClass}
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="结束日期"
              isRequired={true}
              className="kb-form-group kb-div-48"
              field={endYMD}
              disabledDays={this.getDisabledDays}
              onChange={this.changeEndYMDCallback}
            />
            <KBSelect
              title="结束时间"
              onChange={this.changeEndHMCallback}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
              field={endHM}
              {...halfClass}
            />
          </div>
          <p style={{fontSize: '12px', color: '#7F8FA4'}}>{EXPORT_HINT.concat_admin}</p>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(0, 24)
  return {
    DEAL_DAY_TIME_HM
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (
    values.start_time &&
    values.end_time &&
    moment(values.end_time).diff(moment(values.start_time)) <= 0
  ) {
    errors.endHM = '结束日期必须大于开始日期'
  }

  return errors
}

const formConfig = {
  form: 'LockPermissionsMultiForm',
  fields: ['start_time', 'end_time', 'startHM', 'endHM', 'startYMD', 'endYMD'],
  validate: validate,
  touchOnBlur: false
}

MeetingRoomReportForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(MeetingRoomReportForm)

export default MeetingRoomReportForm
