import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { invoicesActions, taxesAction } from 'app/actions'
import {
  KBInput,
  KBRadio,
  KBTextarea,
  KBSelect,
  KBUserInput,
  KBDateInput,
  KBFormError,
  KBButton,
  KBPopover
} from 'components'
import { DateUtils } from 'react-day-picker'
import { routerActions } from 'react-router-redux'
import { LINE_ITEM_TYPES, POINT_TYPES } from 'app/constants'
import { ADD_ORGANIZATION } from 'app/constants/hint'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import { toPercent } from 'utils/kbUtil'
import { getTaxes } from 'app/selectors'
import _ from 'lodash'
import moment from 'moment'
var AddInvoiceForm = React.createClass({
  getInitialState() {
    return {
      billType: []
    }
  },
  componentDidMount() {
    const { getTaxesAction } = this.props

    api.getSelfDefinedLineItemTypes().then(
      ({ json }) => {
        let NEW_LINE_ITEM_TYPES = JSON.parse(JSON.stringify(LINE_ITEM_TYPES))
        NEW_LINE_ITEM_TYPES.splice(NEW_LINE_ITEM_TYPES.length - 1, 0, ...json)
        this.setState({
          billType: NEW_LINE_ITEM_TYPES
        })
      },
      error => {}
    )
    api.getSpaceTaxes().then(
      json => {
        getTaxesAction.success(json)
      },
      error => {
        getTaxesAction.failure(error)
      }
    )
  },

  createInvoiceForm(model) {
    let location_id = this.props.location_id
    const { contract_id, org, getInvoicesActions } = this.props
    const { billType } = this.state

    for (let i = 0; i < model.line_items.length; i++) {
      for (let j = 0; j < billType.length; j++) {
        if (
          model.line_items[i].line_item_type == billType[j].id &&
          billType[j].charge_code
        ) {
          model.line_items[i].line_item_type = 'self_defined'
          model.line_items[i].self_defined_line_item_type_id = billType[j].id
        }
      }
    }

    if (contract_id) {
      model.line_items.map(item => {
        item.sales_subscription_id = contract_id
        return item
      })
    } else if (model.subscription_id) {
      model.line_items.map(item => {
        item.sales_subscription_id = model.subscription_id
        return item
      })
    }

    delete model.subscription_id

    return api
      .postInvoices({
        ...model,
        location_id: location_id || org.locations[0].id,
        organization_id: org.id
      })
      .then(json => {
        localStorage.setItem('reloadOrgInvoices', true)
        getInvoicesActions.success(json)
        KBPopover.close()
      })
  },

  goCustom() {
    const { routerActions } = this.props
    routerActions.replace('/admin/spaces_setting/invoice')
    KBPopover.close()
  },

  orgInvoices() {
    const {
      fields: { line_items },
      error,
      submitting,
      handleSubmit,
      taxes
    } = this.props
    const { billType } = this.state
    // const newTaxes = taxes.filter(taxe=>taxe.rate===0 || taxe.rate === 0.05 || taxe.rate === 0.06 || taxe.rate === 0.09)
    const newTaxes = [...(taxes || [])].sort((a,b) => a.rate - b.rate)
    return (
      <fieldset className="fieldset">
        {line_items.length && billType.length ? (
          <ul className="region-input-box">
            {line_items &&
              line_items.map((line, index) => {
                return (
                  <li key={index}>
                    <div className="kb-row">
                      <i
                        className="iconfont icon-close"
                        onClick={() => line_items.removeField(index)}
                      />
                      <div className="kb-row">
                        <div className="kb-form-group kb-form-alone kb-div-c18">
                          <label style={{ position: 'relative' }}>
                            单价
                            <span
                              style={{
                                color: '#F62D51',
                                position: 'absolute',
                                top: 3,
                                left: '-8px'
                              }}
                            >
                              *
                            </span>
                          </label>
                          <div>
                            <input type="text" {...line.unit_price} />
                            {line.unit_price.touched &&
                              line.unit_price.error && (
                                <p className="lr-error">
                                  {' '}
                                  {line.unit_price.error}{' '}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="kb-row">
                        <div className="kb-form-group kb-form-alone kb-div-c18">
                          <label
                            style={{ position: 'relative' }}
                            className="label-rt"
                          >
                            数量
                            <span
                              style={{
                                color: '#F62D51',
                                position: 'absolute',
                                top: 3,
                                left: '-8px'
                              }}
                            >
                              *
                            </span>
                          </label>
                          <div>
                            <input type="number" {...line.units} />
                            {line.units.touched && line.units.error && (
                              <p className="lr-error"> {line.units.error} </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="kb-row">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <KBSelect
                            style={{ width: '80%' }}
                            isRequired={true}
                            defaultValue="-1"
                            field={line.line_item_type}
                            arrayData={billType}
                            title="类型"
                            className="kb-form-group kb-form-c18 kb-form-alone"
                          />
                          <span
                            style={{
                              width: '15%',
                              height: '34px',
                              marginTop: '30px'
                            }}
                            className="cancel-btn"
                            onClick={this.goCustom}
                          >
                            自定义
                          </span>
                        </div>
                      </div>
                      {line.line_item_type.value &&
                      line.line_item_type.value == 'renting_fee' ? (
                        <div className="kb-row">
                          <KBRadio
                            isRequired={true}
                            className="kb-form-group kb-form-c18 kb-form-alone"
                            rightLabel="label-rt"
                            title="租赁内容"
                            arrayType={POINT_TYPES}
                            inClassName="kb-radio-box"
                            divClassName="kb-form-group kb-div-48"
                            itemStyle={{ width: '80px' }}
                            field={line.charge_code}
                          />
                        </div>
                      ) : null}
                      <div className="kb-row">
                        <KBDateInput
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          title="开始日期"
                          field={line.start_date}
                          isRequired={true}
                          placeholder=""
                          disabledDays={DateUtils.isPastDay}
                        />
                      </div>
                      <div className="kb-row">
                        <KBDateInput
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          title="结束日期"
                          field={line.end_date}
                          isRequired={true}
                          placeholder=""
                          disabledDays={DateUtils.isPastDay}
                        />
                      </div>
                      <div className="kb-row">
                        <KBSelect
                          defaultValue="-1"
                          field={line.sales_tax_id}
                          arrayData={newTaxes}
                          title="税率"
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          isRequired={true}
                        />
                      </div>
                      <div className="kb-row">
                        <KBTextarea
                          field={line.description}
                          title="说明"
                          className="kb-form-group kb-form-c18 kb-form-alone"
                          isRequired={true}
                        />
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        ) : (
          ''
        )}
        <div className="f-left">
          <button
            className="bordered-btn"
            style={{ marginLeft: 0 }}
            onClick={() => {
              line_items.addField()
            }}
            type="button"
          >
            <i className="iconfont icon-add" />
            账款
          </button>
        </div>
      </fieldset>
    )
  },

  render() {
    const {
      fields: { due_date, subscription_id },
      submitting,
      handleSubmit,
      option,
      avaliableSubscriptions,
      contract_id
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.createInvoiceForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            {option == 'generate' ? '单独生成账单' : '归并到下一个账单'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <div className="kb-row">
              <KBDateInput
                className="kb-form-group kb-form-c18 kb-form-alone"
                title="缴费日期"
                field={due_date}
                isRequired={true}
                placeholder=""
                disabledDays={DateUtils.isPastDay}
              />
            </div>
            {option == 'generate' && !contract_id ? (
              <div className="kb-row">
                <KBUserInput
                  title="选择合同"
                  placeholder="选择账款需要关联的合同"
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={subscription_id}
                  selectedUsers={[]}
                  multi={false}
                  users={avaliableSubscriptions}
                />
              </div>
            ) : null}
            {this.orgInvoices()}
          </fieldset>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

AddInvoiceForm.PropTypes = {
  location_id: PropTypes.number.isRequired,
  org: PropTypes.object.isRequired
}

function mapStateToProps(state, props) {
  const { org } = props
  let taxes = Object.assign([], getTaxes(state))
  taxes = _.cloneDeep(taxes).map(tax => {
    let name = tax.name
    tax.name = `${name} (税率:${toPercent(tax.rate, 2)})`
    return tax
  })
  const avaliableSubscriptions =
    (org &&
      org.sales_subscriptions &&
      org.sales_subscriptions
        .filter(sub =>
          ['ended', 'draft', 'active', 'expiring'].includes(sub.status)
        )
        .map(sub => {
          sub.name = sub.serial
          return sub
        })) ||
    []

  return {
    taxes,
    avaliableSubscriptions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.get, dispatch),
    getTaxesAction: bindActionCreators(taxesAction.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validateLine = line => {
  const errors = {}
  if (valid.isNumber(line.unit_price)) {
    errors.unit_price = '请输入正确的价格, 小数点保留2位'
  }

  if (valid.required(line.units) || line.units == 0) {
    errors.units = '请输入数量并且不等于0'
  }

  if (valid.required(line.line_item_type)) {
    errors.line_item_type = '请选择类型'
  }

  if (!line.charge_code && line.line_item_type == 'renting_fee') {
    errors.charge_code = '请选择租赁内容'
  }

  if (!line.start_date && line.line_item_type == 'renting_fee') {
    errors.start_date = '请选择开始时间'
  }

  if (!line.end_date && line.line_item_type == 'renting_fee') {
    errors.end_date = '请选择结束时间'
  }

  if(line.start_date && line.end_date && !moment(line.start_date).isSameOrBefore(line.end_date)){
    errors.end_date = '请选择晚于或者等于开始时间的结束时间'
  }


  if (!line.sales_tax_id || line.sales_tax_id == -1) {
    errors.sales_tax_id = '请选择税率'
  }

  if(!line.description){
    errors.description = '请输入说明'
  }

  return errors
}

const validate = values => {
  const errors = {}

  if (valid.required(values.due_date)) {
    errors.due_date = '请选择缴费日期'
  }

  errors.line_items = values.line_items.map(validateLine)

  return errors
}

const formConfig = {
  form: 'AddInvoiceForm',
  fields: [
    'option',
    'due_date',
    'line_items[].units',
    'line_items[].unit_price',
    'line_items[].description',
    'line_items[].line_item_type',
    'line_items[].sales_tax_id',
    'line_items[].charge_code',
    'line_items[].start_date',
    'line_items[].end_date',
    'subscription_id'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(AddInvoiceForm)
