import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'

var OrdersBox = React.createClass({
  displayName: 'OrdersBox',
  render() {
    return this.props.children
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersBox)
