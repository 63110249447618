import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { ORG_ENTER_TYPES_OBJ } from 'app/constants'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBTabs,
  KBPopover,
  KBImageCropperInput,
  KBLoadingContainer,
  KBTipsy,
  KBDropdown,
  KBLogo,
  KBStatisticNumber
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import {
  orgActions,
  subscriptionActions,
  leaveEnterOptionActions,
  orgEnterSettingActions,
  successState
} from 'app/actions'
import { connect } from 'react-redux'
import OrganizationForm from './OrganizationForm'
import OrgOffGraduationForm from './OrgOffGraduationForm'
import OrganizationMemberForm from './OrganizationMemberForm'
import OrgOffHireGuideForm from './OrgOffHireGuideForm'
import {
  canAccessLocation,
  canAccessLocationFinance,
  canAccessLocationExceptObserver,
  canAccessLocationRoles,
  canRechargePoint,
  canAdminSpace,
  onlyCanAdminLocationOfWeChat
} from 'app/reducers/role'
import OrganizationRechargePointForm from './OrganizationRechargePointForm'
import * as selectors from 'app/selectors'
import OrganizationOptionsCheckForm from './OrganizationOptionsCheckForm'
import OrgDirectlyEnterForm from './OrgDirectlyEnterForm'
import AddInvoiceForm from './AddInvoiceForm'
import moment from 'moment'
import { notification } from 'antd'

let meetingTimeUnitObj = {
  number: '小时',
  infinity: '',
  per_desk: '小时/工位'
}

var Organization = React.createClass({
  displayName: 'Organization',

  getInitialState() {
    return {
      isUpdatePoint: false,
      loading: true
    }
  },

  addMember() {
    const { org, loc_id } = this.props
    KBPopover.show(
      <OrganizationMemberForm
        callback={() => {
          localStorage.setItem('reloadOrgMemberInfo', true)
        }}
        org={org}
        loc_id={loc_id}
        loadOrganizationInfo={this.loadOrganizationInfo}
        initialValues={{
          gender: 'male',
          role: 'employee'
        }}
      />
    )
  },

  offhire() {
    const { org, loc_id } = this.props
    KBPopover.show(<OrgOffHireGuideForm org={org} loc_id={loc_id} />, {
      closeOnOutsideClick: false
    })
  },

  expirDateChange(day) {
    this.setState({
      execute_at: day
    })
  },

  graduation() {
    KBPopover.show(
      <OrgOffGraduationForm
        {...this.props}
        expirDateChange={this.expirDateChange}
      />
    )
  },

  componentDidMount() {
    let {
      org_id,
      loc_id,
      getOrgAction,
      getSubscriptionActions,
      getLeaveEnterOptionActions,
      isCreateMember,
      getOrgEnterSettingActions,
      changeParentScrollTop,
      subscriptions
    } = this.props
    changeParentScrollTop && changeParentScrollTop()
    if (isCreateMember) {
      this.addMember()
    }

    this.loadOrganizationInfo()

    subscriptions.length == 0 &&
      api
        .getSubscriptions({
          organization_id: org_id,
          location_id: loc_id,
          subscription_type: 'normal'
        })
        .then(
          json => {
            getSubscriptionActions.success(json)
          },
          error => {
            getSubscriptionActions.failure(error)
          }
        )

    api.getOrganizationOptions(loc_id, org_id).then(
      json => {
        getLeaveEnterOptionActions.success(json)
      },
      error => {
        getLeaveEnterOptionActions.failure(error)
      }
    )
  },
  componentWillReceiveProps(nextProps) {
    if (JSON.parse(localStorage.getItem('reloadOrgInfo')) == true) {
      localStorage.setItem('reloadOrgInfo', false)
      this.loadOrganizationInfo()
    }
  },
  loadOrganizationInfo() {
    let { org_id, loc_id, updateOrganizationsAction } = this.props
    this.setState({
      isUpdatePoint: !this.state.isUpdatePoint
    })
    api
      .getOrganizationInfo(org_id, {
        location_id: loc_id,
        includes:
          // 'details,subscriptions,invoices,areas,statistics,opportunities,users,balances'
          'details,subscriptions,areas,statistics,opportunities,balances'
      })
      .then(
        json =>
          updateOrganizationsAction.success(json, {
            organization_id: org_id,
            location_id: loc_id
          }),
        errors => updateOrganizationsAction.failure(errors)
      )
      .then(() => {
        this.setState({
          loading: false
        })
      })
  },

  addSubscriptions() {
    const { org, loc_id, routerActions } = this.props
    routerActions.push(
      `/admin/locations/${loc_id}/organizations/${org.id}/subscriptions/new`
    )
  },

  editOrg() {
    const { org, loc_id, routerActions } = this.props

    routerActions.push(
      `/admin/locations/${loc_id}/new_organization?type=update&org_id=${org.id}&add_type=${org.enter_type}`
    )
    // KBPopover.show(<OrganizationForm
    //   org           = { org }
    //   location_id   = { loc_id }
    //   type          = 'update'
    //   initialValues = { {
    //     ...org,
    //     industry_id  : org.industry && org.industry.id,
    //     fund_raising : org.fund_raising && org.fund_raising.id,
    //   } }
    //   />)
  },

  deleteOrg() {
    const { org, loc_id, deleteOrgAction, org_id, routerActions } = this.props

    KBPopover.plugins.confirm('删除公司', '是否要删除公司' + org.name, {
      confirm: () => {
        api.deleteOrgnization(org_id, loc_id).then(
          json => {
            deleteOrgAction.success(json, { id: org_id, key: 'organizations' })
            routerActions.replace(`/admin/locations/${loc_id}/organizations`)
            KBPopover.close()
          },
          error => {
            deleteOrgAction.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  addContract() {
    const { loc_id, org, routerActions } = this.props
    routerActions.push(
      `/admin/locations/${loc_id}/organizations/${org.id}/subscriptions/new?contract=无销售面积`
    )
  },

  chooseInvoiceMode() {
    KBPopover.show(
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">选择添加账款模式</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <div className="kb-form-fr">
              <KBButton
                className="certain-btn"
                onClick={this.addInvoice.bind(null, 'generate')}
              >
                立即生成账单
              </KBButton>
              <KBButton
                className="certain-btn"
                onClick={this.addInvoice.bind(null, 'merge')}
                style={{ marginLeft: 20 }}
              >
                归并到下一个账单
              </KBButton>
            </div>
          </div>
        </div>
      </div>
    )
  },

  addInvoice(mode = 'generate') {
    const { org, loc_id } = this.props
    KBPopover.close()

    KBPopover.show(
      <AddInvoiceForm
        org={org}
        location_id={loc_id}
        option={mode}
        initialValues={{
          option: mode,
          sales_tax_id: '',
          due_date: moment().format('YYYY-MM-DD'),
          line_items: [
            {
              units: 1,
              unit_price: 0,
              description: '',
              start_date: moment().format('YYYY-MM-DD'),
              end_date: moment().format('YYYY-MM-DD')
            }
          ]
        }}
      />
    )
  },
  setOrgOptions() {
    const { org, loc_id } = this.props
    KBPopover.show(
      <OrganizationOptionsCheckForm
        type="enter"
        org={org}
        location_id={loc_id}
      />
    )
  },

  orgEnterDirectly() {
    const { org, loc_id, org_id } = this.props

    KBPopover.show(
      <OrgDirectlyEnterForm org={org} org_id={org_id} location_id={loc_id} />
    )
  },

  updateOrgLogo(url) {
    const { loc_id, org_id, updateOrganizationsAction } = this.props

    api.updateOrganization(org_id, { logo: url }).then(
      json => {
        updateOrganizationsAction.success(json, {
          location_id: loc_id,
          id: org_id
        })
      },
      error => {
        updateOrganizationsAction.failure(error)
      }
    )
  },

  rechargePoint() {
    const { loc_id, org } = this.props
    KBPopover.show(
      <OrganizationRechargePointForm
        loc_id={loc_id}
        org={org}
        loadOrganizationInfo={this.loadOrganizationInfo}
      />
    )
  },

  suspendOrganization() {
    const { loc_id, org_id, updateOrganizationsAction } = this.props
    KBPopoverConfirm({
      name: '停用公司',
      context: `停用后，将无法为该公司提供会员权限和服务，是否确认停用该公司?`,
      callback: () => {
        return api.suspendOrganization(org_id, loc_id).then(
          json => {
            updateOrganizationsAction.success(json, {
              location_id: loc_id,
              id: org_id
            })
            KBPopover.close()
          },
          errors => {
            updateOrganizationsAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  unsuspendOrganization() {
    const { loc_id, org_id, updateOrganizationsAction } = this.props
    KBPopoverConfirm({
      name: '启用公司',
      context: `确定要启用该公司?`,
      callback: () => {
        return api.unsuspendOrganization(org_id, loc_id).then(
          json => {
            updateOrganizationsAction.success(json, {
              location_id: loc_id,
              id: org_id
            })
            KBPopover.close()
          },
          errors => {
            updateOrganizationsAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  failureCallBack(err) {
    if(!err) return
    notification.error({ message: '上传失败', description: err })
  },

  render() {
    let {
      org,
      org_id,
      loc_id,
      location,
      section,
      currentUrl,
      query,
      isManager,
      isSales,
      isLandlord,
      noObserver,
      isAdmin,
      connect,
      menu,
      virtual_currency_name,
      subscriptions,
      isRechargePoint,
      meetingLimitation
    } = this.props

    const { isUpdatePoint, loading } = this.state
    const deskRate = (
      (parseFloat(org && org.assigned_desks_count) /
        parseFloat(org && org.desks_count)) *
      100
    ).toFixed(0)
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        org: org,
        location: location,
        org_id: org_id,
        loc_id,
        currentUrl: currentUrl,
        query: query,
        isUpdatePoint,
        loadOrganizationInfo: this.loadOrganizationInfo
      })
    )
    const current = moment()
    const entered_at = moment(org && org.entered_at)
    const subscription_latest_expire_date = moment(
      org && org.subscription_latest_expire_date
    )
    const diffDays = current.diff(entered_at, 'days') || 0
    const subscriptionDiffDays =
      subscription_latest_expire_date.diff(current, 'days') || 0
    const isSuspended = org && org.status === 'suspended'
    const isOCRM = org && org.enter_type === 'longterm'
    // isOCRM
    //   ? (menu = menu.filter(item => item.value !== 'contracts'))
    //   : (menu = menu)
    const tipStyle = {
      width: '100%',
      position: 'absolute',
      left: 0,
      padding: '10px 50px',
      backgroundColor: '#FCEBEB'
    }
    const iconStyle = {
      width: 20,
      position: 'absolute',
      top: '47%',
      transform: 'translateY(-50%)'
    }
    const msgStyle = {
      color: '#e13b3f',
      marginLeft: 40
    }

    return (
      <KBLoadingContainer loading={loading}>
        <section className="kb-content">
          {org && location ? (
            <div className="kb-content-container clear-fix">
              <header className="nav-header">
                <div className="nav-section-header-title">
                  <KBImageCropperInput
                    aspect={1}
                    callback={this.updateOrgLogo}
                    className="org-logo-box"
                    maskStyle={org.logo ? {} : { borderRadius: '50%' }}
                    mask={true}
                    type="announcement"
                    accept="image/*"
                    failureCallBack={this.failureCallBack}
                  >
                    <KBLogo
                      className="f-left company-logo"
                      size={60}
                      numberOfChars={4}
                      obj={org}
                      style={{ float: 'left' }}
                    />
                  </KBImageCropperInput>
                  <div className="d-iblock">
                    <span style={{ verticalAlign: 'middle' }}>
                      {org && org.name}
                    </span>
                    {org.is_important ? (
                      <div
                        className="team-located"
                        style={{
                          background: 'inherit',
                          padding: 0,
                          cursor: 'pointer'
                        }}
                      >
                        <KBTipsy content="重要客户">
                          <i
                            className="iconfont icon-important_crm"
                            style={{
                              color: '#FF3F3F',
                              fontSize: '22px',
                              position: 'relative',
                              top: '4px'
                            }}
                          />
                        </KBTipsy>
                      </div>
                    ) : null}
                    {['longterm', 'shortterm'].includes(org.enter_type) ? (
                      <div
                        className="team-located"
                        style={{ backgroundColor: '#2ea1f8' }}
                      >
                        {ORG_ENTER_TYPES_OBJ[org.enter_type]}
                      </div>
                    ) : null}
                    {org.is_archived ? (
                      <div className="team-located">已毕业企业</div>
                    ) : org.is_entered ? (
                      org.enter_type == 'shortterm' &&
                      org.is_expired &&
                      (!org.subscriptions || !org.subscriptions.length) ? (
                        <div
                          style={{ background: '#dd5a55' }}
                          className="team-located"
                        >
                          合同已到期
                        </div>
                      ) : null
                    ) : null}
                    {org.is_legal ? (
                      <div
                        style={{ background: '#73bb4b' }}
                        className="team-located"
                      >
                        资质已确认
                      </div>
                    ) : (
                      <div className="team-located">资质未确认</div>
                    )}
                    <div>
                      <div className="action-bar-type">
                        <span style={{ marginLeft: 0, fontSize: '16px' }}>
                          {org && org.full_name}
                        </span>
                      </div>
                      {org.industry ? (
                        <div
                          className="action-bar-status"
                          style={org.full_name ? {} : { marginLeft: 0 }}
                        >
                          <i className="iconfont icon-desktop_mac" />
                          <span>{org.industry && org.industry.name}</span>
                        </div>
                      ) : null}
                      {org.fund_raising ? (
                        <div className="action-bar-status">
                          <i className="iconfont icon-finance" />
                          <span>{org.fund_raising.name}</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {isSuspended ? (
                    <div className="f-right">
                      <button
                        className="certain-btn"
                        onClick={this.unsuspendOrganization}
                      >
                        启用
                      </button>
                    </div>
                  ) : (
                    <div className="f-right">
                      {connect && (noObserver || isAdmin || isLandlord) && org.is_entered ? (
                        <button
                          className="bordered-btn"
                          onClick={this.addMember}
                        >
                          <i className="iconfont icon-add" />
                          添加新员工
                        </button>
                      ) : (
                        ''
                      )}
                      {connect && (noObserver || isSales || isLandlord) && !isOCRM ? (
                        <button
                          className="bordered-btn"
                          onClick={this.addSubscriptions}
                        >
                          <i className="iconfont icon-add" />
                          添加新合同
                        </button>
                      ) : (
                        ''
                      )}
                      {/* 联办公司结束所有合同后显示 //8月*/}
                      {!org.is_archived &&
                      org.enter_type === 'shortterm' &&
                      (!org.subscriptions ||
                        (org.subscriptions && !org.subscriptions.length)) ? (
                        <button
                          className="bordered-btn"
                          onClick={this.graduation}
                        >
                          毕业
                        </button>
                      ) : (
                        ''
                      )}
                      {connect && (noObserver || isSales || isAdmin || isLandlord) ? (
                        <KBDropdown>
                          <KBDropdown.KBDropdownTrigger>
                            <button className="c-btn-secondary m-left-sm">
                              <i
                                className="iconfont icon-more"
                                style={{ marginTop: 2 }}
                              />
                              更多
                            </button>
                          </KBDropdown.KBDropdownTrigger>
                          <KBDropdown.KBDropdownContent>
                            <div
                              className="user-dropdown"
                              style={{ width: 140 }}
                            >
                              {isManager || isSales || isLandlord ? (
                                <a onClick={this.editOrg}>编辑</a>
                              ) : (
                                ''
                              )}
                              {org.is_entered&&(noObserver||isSales||isLandlord) ? (
                                <a onClick={() => this.addInvoice()}>
                                  添加账款
                                </a>
                              ) : (
                                ''
                              )}
                              {(noObserver||isSales||isLandlord)?<a onClick={this.addContract}>
                                增加无租赁信息合同
                              </a>:''}
                              {(noObserver||isSales||isLandlord)?<a onClick={this.offhire}>退租</a>:''}
                              {isManager || isSales || isLandlord ? (
                                <a onClick={this.deleteOrg}>删除</a>
                              ) : (
                                ''
                              )}
                              <a
                                onClick={this.suspendOrganization}
                                className="color-red"
                              >
                                停用
                              </a>
                            </div>
                          </KBDropdown.KBDropdownContent>
                        </KBDropdown>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </div>
              </header>
              <div className="kb-tabs nav-section-content-container">
                {connect && !org.is_entered && !isSuspended ? (
                  <div style={tipStyle}>
                    <img
                      src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/mirro@2x.png"
                      style={iconStyle}
                      alt=""
                    />
                    <span style={msgStyle}>
                      此公司尚未完成入驻手续{isManager ? '，点击确认入驻' : ''}
                    </span>
                    {isManager ? (
                      <button
                        className="bordered-btn bordered-btn-solid"
                        style={{ padding: '4px 14px', fontSize: 13 }}
                        onClick={
                          this.props.enterThroughOrgNotes
                            ? this.setOrgOptions
                            : this.orgEnterDirectly
                        }
                      >
                        点击入驻
                      </button>
                    ) : null}
                  </div>
                ) : null}
                {isSuspended ? (
                  <div style={tipStyle}>
                    <img
                      src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/mirro@2x.png"
                      style={iconStyle}
                      alt=""
                    />
                    <span style={msgStyle}>
                      该公司已被停用，无法使用提供的会员权限和服务，如果要关闭停用，请点击上面的“启用”按钮
                    </span>
                  </div>
                ) : null}
                <div
                  className="kb-chart t-center"
                  style={{
                    marginTop: 60,
                    marginBottom: 50,
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 50px'
                  }}
                >
                  <div className="chart-box t-left" style={{ flex: 1 }}>
                    <Link
                      to={`/admin/locations/${loc_id}/organizations/${org_id}/contracts`}
                      style={{ position: 'relative' }}
                    >
                      <KBStatisticNumber
                        number={org.desks_count}
                        unit="个"
                        name="购买工位数"
                      />
                    </Link>
                  </div>
                  <div className="chart-box t-left" style={{ flex: 1 }}>
                    <KBStatisticNumber
                      number={org.assigned_desks_count}
                      unit="个"
                      name="入驻工位数"
                    />
                  </div>
                  <div className="chart-box t-left" style={{ flex: 1 }}>
                    <KBStatisticNumber
                      number={diffDays}
                      unit="天"
                      name="入驻时间"
                    />
                  </div>
                  <div className="chart-box t-left" style={{ flex: 1 }}>
                    <Link
                      to={`/admin/locations/${loc_id}/organizations/${org_id}/payments`}
                      style={{ position: 'relative' }}
                    >
                      <KBStatisticNumber
                        number={subscriptionDiffDays}
                        unit="天"
                        name="合同到期时间"
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className="kb-chart t-center"
                  style={{
                    marginBottom: 50,
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 50px',
                    marginTop: 0
                  }}
                >
                  <div className="chart-box t-left" style={{ flex: 1 }}>
                    {org.enter_type == 'shortterm' ? (
                      <KBStatisticNumber
                        color={
                          org.user_units >= org.total_units
                            ? '#a71704'
                            : '#4c4c4c'
                        }
                        number={`${org.user_units || 0}/${org.total_units ||
                          0}`}
                        unit="个"
                        name="公司人数"
                      />
                    ) : (
                      <KBStatisticNumber
                        // number={org.users && org.users.length}
                        number={org.user_units || 0}
                        unit="个"
                        name="公司人数"
                      />
                    )}
                  </div>
                  <div className="chart-box t-left" style={{ flex: 1 }}>
                    <KBStatisticNumber
                      number={org.areas && org.areas.length}
                      unit="个"
                      name="区域数"
                    />
                  </div>
                  <div className="chart-box t-left" style={{ flex: 1 }}>
                    <KBStatisticNumber
                      number={
                        meetingLimitation && meetingLimitation !== 'infinity'
                          ? (org.meeting_room_usage &&
                              org.meeting_room_usage.meeting_room_remain) ||
                            0
                          : '不限制'
                      }
                      unit={meetingTimeUnitObj[meetingLimitation]}
                      name="今日剩余时长"
                    />
                  </div>
                  <div className="chart-box t-left" style={{ flex: 1 }}>
                    <Link
                      to={`/admin/locations/${loc_id}/organizations/${org_id}/point`}
                      style={{ position: 'relative' }}
                    >
                      <KBStatisticNumber
                        number={
                          org.balances && org.balances.point
                            ? org.balances.point + 0
                            : 0
                        }
                        unit="个"
                        name={virtual_currency_name}
                      >
                        {isRechargePoint ? (
                          <div
                            className="org-recharge-btn bordered-btn-solid"
                            onClick={this.rechargePoint}
                            style={{
                              position: 'initial',
                              display: 'inline-block',
                              marginLeft: 10,
                              border: 'none'
                            }}
                          >
                            赠送
                          </div>
                        ) : null}
                      </KBStatisticNumber>
                    </Link>
                  </div>
                </div>
                <ul className="nav-section-tab">
                  {menu.map((json, index) => {
                    return (
                      <li
                        key={index}
                        className={`kb-tabs-menu-item ${
                          json.value == section ? 'nav-section-tab-active' : ''
                        }`}
                      >
                        <Link
                          to={`/admin/locations/${loc_id}/organizations/${org_id}/${json.value}`}
                        >
                          {json.name}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
                <article className="kb-tab-panel">{childrenWithProps}</article>
              </div>
            </div>
          ) : null}
        </section>
      </KBLoadingContainer>
    )
  }
})

function mapStateToProps(state, props) {
  const { user, spaceSetting } = state

  let org_id = parseInt(props.params.organization_id)
  let loc_id = parseInt(props.params.id)

  let query = props.location.query
  let isCreateMember = query && query.member == 'new' ? true : false

  let sections = props.location.pathname.split('/')
  let section = sections.length > 5 ? sections[6] || '' : ''

  let org = selectors.getOrg(state, org_id)
  let subscriptions = selectors.getSubScriptionsByOrg(state, org_id)
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)
  let location = selectors.getLocation(state, loc_id)
  let isManager = canAccessLocation(user, loc_id)
  let isRechargePoint = canRechargePoint(user)
  let isFinance = canAccessLocationFinance(user, loc_id)
  let noObserver = canAccessLocationExceptObserver(user, loc_id)
  let isAdmin =canAdminSpace(user)
  let isSales = canAccessLocationRoles(user, loc_id, ['sales'])
  const isLandlord = canAccessLocationRoles(user, loc_id, ['landlord'])
  let connect = org && org.connect

  let locationSetting = selectors.getLocationSetting(state, loc_id)
  let meetingDailyLimitation =
    locationSetting &&
    locationSetting.meeting_room_settings &&
    locationSetting.meeting_room_settings.daily_limitation
  let meetingMonthlyLimitation =
    locationSetting &&
    locationSetting.meeting_room_settings &&
    locationSetting.meeting_room_settings.monthly_limitation
  let meetingLimitation = meetingDailyLimitation || meetingMonthlyLimitation

  let currentUrl = props.location.pathname + props.location.search

  let enterThroughOrgNotes =
    spaceSetting.organization_settings &&
    spaceSetting.organization_settings.org_enter_setting &&
    spaceSetting.organization_settings.org_enter_setting.enter_through_org_notes

  let menu = [
    {
      name: '概况',
      value: ''
    },
    {
      name: '员工',
      value: 'members'
    },
    {
      name: '合同',
      value: 'contracts'
    },
    {
      name: '账单',
      value: 'payments'
    },
    {
      name: '订单',
      value: 'orders'
    },
    {
      name: `${virtual_currency_name}`,
      value: 'point'
    },
    {
      name: '会议室',
      value: 'meeting'
    },
    {
      name: '附件',
      value: 'annex'
    }
  ]

  if(onlyCanAdminLocationOfWeChat(user, loc_id)){
    menu = menu.filter(item=>item.value!=='annex')
  }
  return {
    isCreateMember,
    loc_id,
    section,
    org_id,
    org,
    location,
    isManager,
    isFinance,
    isSales,
    isLandlord,
    currentUrl,
    query,
    noObserver,
    connect,
    subscriptions,
    virtual_currency_name,
    menu,
    enterThroughOrgNotes,
    meetingLimitation,
    isRechargePoint,
    isAdmin
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    getLeaveEnterOptionActions: bindActionCreators(
      leaveEnterOptionActions.get,
      dispatch
    ),
    updateOrganizationsAction: bindActionCreators(orgActions.update, dispatch),
    getOrgAction: bindActionCreators(orgActions.update, dispatch),
    deleteOrgAction: bindActionCreators(orgActions.delete, dispatch),
    getSubscriptionActions: bindActionCreators(
      subscriptionActions.all,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    getOrgEnterSettingActions: bindActionCreators(
      orgEnterSettingActions.get,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Organization)
