import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import { checkObject, getEntitiesJsonArray, objectToArray } from 'utils/kbUtil'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBUserInput,
  KBLogoCropper,
  KBSelect,
  KBRadio,
  KBTextarea
} from 'components'
import * as constants from 'app/constants'
import * as selectors from 'app/selectors'

var ServiceProviderForm = React.createClass({
  displayName: 'ServiceProviderForm',
  createLockdForm(model) {
    const {
      createServiceProvidersAction,
      callback,
      updateServiceProvidersAction,
      state,
      serviceProvider
    } = this.props

    if (state == 'update') {
      return api
        .updateServiceProviders(serviceProvider.id, model)
        .then(json => {
          callback && callback(getEntitiesJsonArray(json, 'serviceProviders'))
          updateServiceProvidersAction.success(json)
          KBPopover.close()
          return true
        })
    }

    return api.createServiceProviders(model).then(json => {
      const provider = getEntitiesJsonArray(json, 'serviceProviders')
      callback && callback(provider)
      createServiceProvidersAction.success(json)
      KBPopover.close()
      this.props.routerActions.push(
        `/admin/service/provider/${provider.id}/detail?type=service`
      )
      return true
    })
  },
  render() {
    const {
      fields: {
        name,
        full_name,
        logo,
        cities,
        province_id,
        address,
        oneliner,
        description,
        website,
        contact_name,
        contact_phone,
        contact_email,
        contact_wechat,
        notes
      },
      error,
      submitting,
      handleSubmit
    } = this.props
    let { serviceProvider, state, citiesProps, provinces, cityIds } = this.props
    const provincesArray = objectToArray(provinces)
    let citiesPropsFilter = province_id.value
      ? citiesProps.filter(city => city.province_id == province_id.value)
      : citiesProps
    let selectedProvince =
      provincesArray.filter(pro => pro.id === this.props.province_id) || []
    let selectedCity =
      citiesPropsFilter.filter(city => cityIds.includes(city.id)) || []

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    return (
      <form onSubmit={handleSubmit(this.createLockdForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            {state ? '修改服务商信息' : '创建服务商'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              field={name}
              title="名称"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              field={full_name}
              title="全称"
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="服务省份"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={province_id}
              multi={false}
              users={provincesArray}
              placeholder="选择省份"
              selectedUsers={selectedProvince}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="服务城市"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={cities}
              multi={true}
              placeholder="选择城市"
              users={citiesPropsFilter}
              selectedUsers={selectedCity}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              title="地址"
              field={address}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              title="简要描述"
              field={oneliner}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              title="详细描述"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={description}
            />
          </div>
          <div className="kb-row">
            <KBInput
              {...halfClass}
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="网址"
              field={website}
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBInput
              {...halfClass}
              title="联系人"
              isRequired={true}
              field={contact_name}
            />
            <KBInput
              {...halfClass}
              title="电话"
              isRequiredR={true}
              field={contact_phone}
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBInput
              {...halfClass}
              title="邮箱"
              isRequired={true}
              field={contact_email}
            />
            <KBInput
              {...halfClass}
              titleClass="label-rt"
              title="微信"
              field={contact_wechat}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              title="备注"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={notes}
            />
          </div>
          <div className="kb-row" style={{ paddingBottom: 30 }}>
            <KBLogoCropper
              aspect={1}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={logo}
              bucket={'common'}
              obj={serviceProvider}
              isRequired={true}
              type="serviceProviders"
              accept="image/*"
            />
            {logo.touched && logo.error && (
              <p className="lr-error">{logo.error}</p>
            )}
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const citiesProps = selectors.getCitiesToArray(state)
  const provinces = selectors.getProvinces(state)
  const { serviceProvider } = props
  let cityIds = []
  let province_id =
    serviceProvider &&
    serviceProvider.cities &&
    serviceProvider.cities[0] &&
    serviceProvider.cities[0].province_id
  serviceProvider &&
    serviceProvider.cities &&
    serviceProvider.cities.map(city => {
      cityIds.push(city.id)
      return city
    })
  return {
    citiesProps,
    provinces,
    cityIds,
    province_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createServiceProvidersAction: bindActionCreators(
      actions.serviceProvidersAction.create,
      dispatch
    ),
    updateServiceProvidersAction: bindActionCreators(
      actions.serviceProvidersAction.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入名称'
  }

  if (valid.required(values.full_name)) {
    errors.full_name = '请输入全称'
  }

  if (valid.required(values.logo)) {
    errors.logo = '请输入Logo'
  }

  if (valid.required(values.address)) {
    errors.address = '请输入物理地址'
  }

  if (valid.required(values.oneliner)) {
    errors.oneliner = '请输入简要描述'
  }

  if (valid.required(values.contact_name)) {
    errors.contact_name = '请输入联系人'
  }

  if (valid.required(values.contact_phone)) {
    errors.contact_phone = '请输入联系电话'
  }

  if (valid.required(values.contact_email)) {
    errors.contact_email = '请输入邮箱'
  }

  return errors
}

const formConfig = {
  form: 'ServiceProviderForm',
  fields: [
    'name',
    'full_name',
    'logo',
    'cities',
    'province_id',
    'address',
    'oneliner',
    'description',
    'website',
    'contact_name',
    'contact_phone',
    'contact_email',
    'contact_wechat',
    'notes'
  ],
  validate: validate,
  touchOnBlur: false
}

ServiceProviderForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ServiceProviderForm)

export default ServiceProviderForm
