import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBPopover,
  KBDropdown
} from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { routerActions } from 'react-router-redux'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import * as constant from 'app/constants'
import ServiceProviderForm from './ServiceProviderForm'
import _ from 'lodash'

var menu = [
  {
    name: '请求',
    value: 'info'
  },
  {
    name: '详情',
    value: 'detail'
  }
]

var ServiceProvider = React.createClass({
  displayName: 'ServiceProvider',
  getInitialState() {
    return {}
  },

  componentDidMount() {
    const { serviceProvider } = this.props
    this._loadData(serviceProvider)
    this._loadRequests(serviceProvider)
  },

  componentWillReceiveProps(nextProps) {
    const { serviceProvider } = nextProps
    if (
      !this.props.serviceProvider &&
      serviceProvider &&
      !_.isEqual(serviceProvider, this.props.serviceProvider)
    ) {
      this._loadData(serviceProvider)
      this._loadRequests(serviceProvider)
    }
  },

  _loadData(serviceProvider) {
    const { getServiceProvidersAction } = this.props
    serviceProvider &&
      api.getServiceProviderDetails(serviceProvider.id).then(
        json => {
          getServiceProvidersAction.success(json)
        },
        error => {
          getServiceProvidersAction.failure(error)
        }
      )
  },

  _loadRequests(serviceProvider) {
    const { getServiceRequestsAction, getServiceAction } = this.props
    const id = serviceProvider && serviceProvider.id
    serviceProvider &&
      api.getServiceRequests({ service_provider_id: id }).then(
        json => {
          getServiceRequestsAction.success(json)
        },
        error => {
          getServiceRequestsAction.failure(error)
        }
      )

    serviceProvider &&
      api.getProvidersServices(id).then(
        json => {
          getServiceAction.success(json)
        },
        error => {
          getServiceAction.failure(error)
        }
      )
  },

  deleteProperty() {
    const {
      serviceProvider,
      deleteServiceProvidersAction,
      routerActions
    } = this.props
    let id = serviceProvider.id

    if (!serviceProvider) {
      return
    }

    KBPopover.plugins.confirm('删除服务商', '确定要删除该服务商?', {
      confirm: () => {
        api.deleteServiceProviders(id).then(
          json => {
            deleteServiceProvidersAction.success(json, {
              id,
              key: 'serviceProviders'
            })
            KBPopover.close()
            routerActions.push('/admin/service/')
          },
          errors => {
            deleteServiceProvidersAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  editProperty() {
    const { section, serviceProvider } = this.props

    if (!serviceProvider) {
      return
    }

    KBPopover.show(
      <ServiceProviderForm
        serviceProvider={serviceProvider}
        state="update"
        initialValues={{
          ...serviceProvider
        }}
      />,
      {
        closeOnOutsideClick: false
      }
    )
  },

  render() {
    const {
      section,
      serviceProvider,
      provider_id,
      services,
      serviceCategoriesArray,
      route
    } = this.props

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { serviceProvider })
    )

    let filterServices =
      services.length > 0 && _.uniqBy(services, 'service_category_id')

    if (!serviceProvider) {
      return <KBLoadingContainer loading={!serviceProvider} />
    }

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-section-header">
          <div className="nav-section-header-title">
            <div className="d-iblock f-left m-right-sm">
              <KBAvatar
                user={serviceProvider}
                size={60}
                imgStyle={{ borderRadius: 0 }}
              />
            </div>
            <div className="d-iblock">
              <span>{serviceProvider.name}</span>
              <div>
                <div className="action-bar-type">
                  <span
                    style={{
                      marginLeft: 0,
                      maxWidth: 630,
                      display: 'inline-block'
                    }}
                  >
                    {serviceProvider.oneliner}
                  </span>
                </div>
              </div>
            </div>
            <div className="f-right">
              <KBDropdown>
                <KBDropdown.KBDropdownTrigger>
                  <button className="c-btn-secondary m-left-sm">
                    <i
                      className="iconfont icon-more"
                      style={{ marginTop: 2 }}
                    />
                    更多
                  </button>
                </KBDropdown.KBDropdownTrigger>
                <KBDropdown.KBDropdownContent>
                  <div className="user-dropdown">
                    <a onClick={this.editProperty}>编辑</a>
                    <a onClick={this.deleteProperty}>删除</a>
                  </div>
                </KBDropdown.KBDropdownContent>
              </KBDropdown>
            </div>
          </div>
          <div style={{ paddingLeft: 80, marginTop: 10 }}>
            {filterServices.length > 0
              ? filterServices.map((service, index) => {
                  return (
                    <span
                      key={index}
                      className="badge"
                      style={{ marginRight: 10, marginBottom: 10 }}
                    >
                      {
                        serviceCategoriesArray[
                          service.service_parent_category_id
                        ].name
                      }
                      -{service.serviceCategory.name}
                    </span>
                  )
                })
              : ''}
          </div>
          <ul
            className="nav-section-tab"
            style={{ marginBottom: 0, marginTop: 10 }}
          >
            {menu.map((json, index) => {
              return (
                <li
                  key={index}
                  className={`kb-tabs-menu-item ${
                    json.value == section ? 'nav-section-tab-active' : ''
                  }`}
                >
                  <Link
                    to={`/admin/service/provider/${provider_id}/${json.value}`}
                  >
                    {json.name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </header>
        <div className="nav-section-content-container">{childrenWithProps}</div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location, routes } = props
  let provider_id = params.provider_id
  let sections = location.pathname.split('/')
  let section = sections.length > 4 ? sections[5] : ''
  let serviceProvider = selectors.getServiceProvidersOfId(state, provider_id)
  //一级分类
  const serviceCategoriesArray = state.entities.serviceCategories
  var services = Object.assign(
    [],
    selectors.getServiceOfProviderId(
      state,
      serviceProvider && serviceProvider.id
    )
  )
  services.map(service => {
    service.serviceCategory = selectors.getSubCategoriesOfId(
      state,
      service.service_category_id
    )
    return service
  })
  let route = routes[routes.length - 1]
  return {
    serviceProvider,
    provider_id,
    section: section || '',
    services,
    serviceCategoriesArray,
    route
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getServiceProvidersAction: bindActionCreators(
      actions.serviceProvidersAction.get,
      dispatch
    ),
    getServiceAction: bindActionCreators(actions.servicesAction.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    getServiceRequestsAction: bindActionCreators(
      actions.serviceRequestsAction.get,
      dispatch
    ),
    deleteServiceProvidersAction: bindActionCreators(
      actions.serviceProvidersAction.delete,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvider)
