import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBTextarea,
  KBMorePhotoUpload,
  KBUserInput,
  KBSelect
} from 'components'
import { SHOP_STATUS } from 'app/constants'
import { salesProductsActions, successState } from 'app/actions'
import * as selectors from 'app/selectors'
import * as constants from 'app/constants'

var SalesProductsForm = React.createClass({
  createSalesProducts(model) {
    const {
      createSalesProductsActions,
      updateSalesProductsActions,
      product,
      state,
      successAction,
      shopping_id
    } = this.props

    if (state) {
      return api.putSalesProducts(product.id, model).then(json => {
        updateSalesProductsActions.success(json)
        successAction({ message: '修改商品成功!' })
        KBPopover.close()
        return true
      })
    }

    model.shop_id = shopping_id
    return api.postSalesProducts(model).then(json => {
      createSalesProductsActions.success(json)
      successAction({ message: '创建商品成功!' })
      KBPopover.close()
      return true
    })
  },

  render() {
    const {
      fields: {
        product_category_id,
        title,
        images,
        unit_price,
        barcode,
        sku,
        cost,
        point,
        description,
        number,
        status
      },
      error,
      submitting,
      handleSubmit,
      virtual_currency_name
    } = this.props
    const { locations, state, productCategories, product } = this.props
    let product_category = product
      ? [product.product_category]
      : productCategories

    const oneClass = {
      className: 'kb-form-group kb-form-210 kb-form-alone'
    }
    return (
      <form onSubmit={handleSubmit(this.createSalesProducts)}>
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改商品' : '创建商品'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-210 kb-form-alone"
              isRequired={true}
              field={title}
              title="商品名称"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="商品分类"
              placeholder={'商品分类'}
              {...oneClass}
              isRequired={true}
              field={product_category_id}
              multi={false}
              users={productCategories}
              selectedUsers={product_category}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              title="详情介绍"
              placeholder="详情介绍"
              className="kb-form-group kb-form-210 kb-form-alone"
              field={description}
              value={description.value}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-210 kb-form-alone"
              field={cost}
              title="进价"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-210 kb-form-alone"
              isRequired={true}
              field={unit_price}
              title="售价"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-210 kb-form-alone"
              label="true"
              isRequired={true}
              field={point}
              title={`${virtual_currency_name}价`}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-210 kb-form-alone"
              field={number}
              title="商品库存"
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-div-210 kb-form-alone"
              title="商品状态"
              field={status}
              arrayData={SHOP_STATUS}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-210 kb-form-alone"
              field={sku}
              title="商品编号"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-210 kb-form-alone"
              field={barcode}
              title="条形码"
            />
          </div>
          <div className="kb-row">
            <KBMorePhotoUpload
              title="上传图片"
              hint="允许多张上传,格式为png或jpg,大小不大于20M"
              photoShow={true}
              className="kb-form-group kb-form-210 kb-form-alone"
              field={images}
              text="上传商品图片"
              prefix="images/shopping/salesproduct/"
              accept="image/*"
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton className="certain-btn" submitting={submitting}>
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { section } = props
  const productCategories = selectors.getProductCategories(
    state,
    props.shopping_id
  )
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)

  return {
    productCategories,
    virtual_currency_name
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createSalesProductsActions: bindActionCreators(
      salesProductsActions.create,
      dispatch
    ),
    updateSalesProductsActions: bindActionCreators(
      salesProductsActions.update,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.product_category_id)) {
    errors.product_category_id = '请选择商品分类'
  }

  if (valid.required(values.title)) {
    errors.title = '请选择商品名称'
  }

  if (valid.required(values.unit_price)) {
    errors.unit_price = '请选择商品售价'
  }

  if (valid.integer(values.number)) {
    errors.number = '请输入整数'
  }

  if (values.number < 0) {
    errors.number = '库存数量不能小于0'
  }

  if (values.number > 1000000000) {
    errors.number = '库存数量不能大于10亿'
  }

  if (valid.required(values.number)) {
    errors.number = '请输入库存数量'
  }

  return errors
}

const formConfig = {
  form: 'SalesProductsForm',
  fields: [
    'product_category_id',
    'title',
    'description',
    'cost',
    'unit_price',
    'point',
    'sku',
    'barcode',
    'images',
    'number',
    'status'
  ],
  validate: validate,
  touchOnBlur: false
}

SalesProductsForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SalesProductsForm)

export default SalesProductsForm
