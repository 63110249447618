import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import ReactDOM from 'react-dom'
import { reduxForm, getValues } from 'redux-form'
import {
  reservationsActions,
  successState,
  apiCallFailure,
  orgActions
} from 'app/actions'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBSelect,
  KBButton,
  KBUserInput,
  KBDateInput,
  KBRangeDate,
  KBTextarea,
  KBMorePhotoUpload,
  KBMoreFileUpload
} from 'components'
import moment from 'moment'
import { DAY_TIME_HM } from 'app/constants'
import { getMeetingInitData } from 'utils/kbData'

const PAYMENT_TYPES = [
  {id: 'temporary_booking', name: '临时代订'},
  {id: 'own_use', name: '中心自用'},
  {id: 'special_booking', name: '特殊预订'},
]

var MeetingBookingForm = React.createClass({
  getInitialState() {
    const { start_at, end_at } = this.props
    return {
      orgId: null,
      members: [],
      name: null,
      phone: null,
      from: (start_at && moment(start_at).format('YYYY-MM-DD')) || null,
      to: (end_at && moment(end_at).format('YYYY-MM-DD')) || null
    }
  },

  reservationsRoom(model) {
    const {
      meeting,
      createReservationsAction,
      callback,
      successAction
    } = this.props
    model.meeting_room_id = meeting.id

    delete model.startHM
    delete model.endHM
    delete model.start_at
    delete model.end_at
    delete model.organization_id

    if (!model.sales_customer_id) {
      delete model.sales_customer_id
    }

    if (!model.contacts_id) {
      delete model.contacts_id
    }

    if (model.contacts_phone && model.contacts_phone.includes('*')) {
      delete model.contacts_phone
    }

    if(model.payment_type) {
      model.mixed_payments = [model.payment_type]
    }

    if(model.attachments && model.attachments.length) {
      model.attachments = model.attachments.map(i => i.url)
    }

    return api.createReservations(model).then(
      json => {
        successAction({ message: `${meeting.name} 预约成功!` })
        createReservationsAction.success(json)
        callback && callback()
        KBPopover.close()
        return true
      },
      error => {
        createReservationsAction.failure(error)
      }
    )
  },

  componentDidMount() {
    const {
      fields: { startHM, endHM, start_at, end_at },
      orgs,
      meeting
    } = this.props
    const currentStartAt = this.props.start_at
    const currentEndAt = this.props.end_at
    const orderOption = meeting && meeting.order_option
    let s = this.parseHM(currentStartAt)
    let e = this.parseHM(currentEndAt)
    //  orderOption === 'by_day' ? startHM.onChange('0,1'): startHM.onChange(s)
    //  orderOption === 'by_day' ? endHM.onChange('24,1'): endHM.onChange(e)
    orderOption === 'by_day' ? startHM.onChange('00:00') : startHM.onChange(s)
    orderOption === 'by_day' ? endHM.onChange('23:59') : endHM.onChange(e)
    this.setHM(start_at, s)
    this.setHM(end_at, e)

    // if (
    //   !(
    //     orgs &&
    //     orgs.length &&
    //     orgs[orgs.length - 1] &&
    //     orgs[orgs.length - 1].id
    //   )
    // ) {
      this.loadOrgs()
    // }
  },

  parseHM(date) {
    let hours = date.get('hour')
    let minute = date.get('minute')
    let t = '1'
    let h = hours
    if (minute == 30) {
      t = '2'
    } else {
      t = '1'
    }

    return h + ',' + t
  },

  loadOrgs() {
    const { getOrganizationsAction, loc_id } = this.props
    api
      .getOrganizations({
        location_id: loc_id,
        per_page: 1000,
        includes: 'details',
        organization_type: 'normal'
      })
      .then(
        json => {
          getOrganizationsAction.success(json)
        },
        errors => {
          getOrganizationsAction.failure(errors)
        }
      )
  },

  orgCallBack(org) {
    const { loc_id } = this.props
    const {
      fields: { sales_customer_id }
    } = this.props

    if (!org.id) {
      this.setState({
        members: []
      })
      return null
    }

    this.setState({
      orgId: org.id
    }, () => {
      this.onInputChange()
    })

    api
      .getOrganizationInfo(org.id, {
        location_id: loc_id,
        includes: 'details'
      })
      .then(json => {
        let detailedOrg = json.response.body
        detailedOrg.sales_customer &&
          sales_customer_id.onChange(detailedOrg.sales_customer.id)
        // api
        //   .getUsers({
        //     organization_id: org.id,
        //     includes: 'space_users',
        //     per_page: 200,
        //   })
        //   .then(json => {
        //     this.setState({
        //       members: json.response.body
        //     })
        //     contacts_id.onChange(null)
        //   })
      })
  },

  onInputChange(name) {
    const {
      fields: { contacts_id }
    } = this.props
    if (!this.state.orgId) {
      return
    }
    api
      .getUsers({
        organization_id: this.state.orgId,
        includes: 'space_users',
        per_page: 999,
        name
      })
      .then(json => {
        this.setState({
          members: json.response.body
        })
        contacts_id.onChange(null)
      })
  },

  memberCallBack(member) {
    const { name, phone_num, phone_num_disabled } = member
    const {
      fields: { contacts_name, contacts_phone }
    } = this.props

    if (name && name.indexOf('*') == -1) {
      contacts_name.onChange(name)
    }

    if (phone_num) {
      if (phone_num_disabled == true) {
        contacts_phone.onChange('您的电话号处于挂失状态')
      } else {
        contacts_phone.onChange(phone_num)
      }
    }
  },

  setYMD(field, value) {
    let date = moment(value)

    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  setHM(field, value) {
    let t = value.split(',')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ hour: t[0], minute: t[1] == '1' ? 0 : 30, second: 0 })
        .toDate()
    )
    setTimeout(
      this.changeDate.bind(null, { from: this.state.from, to: this.state.to }),
      50
    )
  },

  changeYMDCallback(date) {
    const {
      fields: { start_at, end_at }
    } = this.props
    this.setYMD(start_at, date)
    this.setYMD(end_at, date)
  },

  changeStartHMCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setHM(start_at, data)
  },

  changeEndHMCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setHM(end_at, data)
  },

  changeDate(range) {
    const {
      fields: { start_at, end_at },
      apiCallFailureAction
    } = this.props
    if (!(range.from && range.to)) {
      return apiCallFailureAction({
        status: 'error',
        message: '请选择预订结束日期'
      })
    }
    let timeRange = {
      start_at: start_at.value,
      end_at: end_at.value
    }
    this.setDateTimeRangeField(range, timeRange)
    this.setState({
      from: range.from,
      to: range.to
    })
  },

  setDateTimeRangeField(dateRange, timeRange) {
    const {
      fields: { timespan },
      meeting
    } = this.props
    let timespanIndex = timespan.length - 1
    while (timespanIndex >= 0) {
      timespan.removeField(timespanIndex)
      timespanIndex--
    }
    const orderOption = meeting && meeting.order_option
    let start_date = moment(dateRange.from).format('YYYY-MM-DD')
    let end_date = moment(dateRange.to).format('YYYY-MM-DD')
    let start_time =
      orderOption === 'by_day'
        ? '00:00'
        : moment(timeRange.start_at).format('HH:mm')
    let end_time =
      orderOption === 'by_day'
        ? '23:59'
        : moment(timeRange.end_at).format('HH:mm')
    let dates_in_range = []
    let currentDate = start_date
    while (moment(currentDate).isBefore(end_date)) {
      dates_in_range.push(currentDate)
      currentDate = moment(currentDate)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    }
    dates_in_range.push(end_date)
    dates_in_range.map(date => {
      timespan.addField({
        start_at: date + ' ' + start_time,
        end_at: date + ' ' + end_time
      })
    })
  },

  render() {
    const {
      fields: {
        contacts_id,
        startHM,
        endHM,
        organization_id,
        contacts_name,
        contacts_phone,
        remark,
        attachments,
        payment_type
      },
      orgs,
      submitting,
      handleSubmit,
      meeting,
      DEAL_DAY_TIME_HM
    } = this.props
    const { members, from, to } = this.state
    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }
    const orderOption = meeting && meeting.order_option

    return (
      <form onSubmit={handleSubmit(this.reservationsRoom)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <div className="header-title">
            预约<span style={{ marginLeft: 5 }}>{meeting.name}</span>
          </div>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <div className="kb-form-c18 kb-form-group kb-form-alone">
              <label>
                预订日期<span className="must-fill">*</span>
              </label>
              <div>
                <KBRangeDate
                  format="YYYY/MM/DD"
                  from={from || ''}
                  to={to || ''}
                  callback={this.changeDate}
                  leftNone={true}
                />
              </div>
              <p style={{ color: '#c8c8c8', fontSize: 12 }}>
                当预订日期超过一天，则将会在日期内的每一天预订同一时间段
              </p>
            </div>
          </div>
          {orderOption === 'by_day' ? (
            <div className="kb-row kb-form-66">
              {/* <KBSelect title='开始' isRequired={ true } arrayData={ DEAL_DAY_TIME_HM } field={startHM} {...halfClass} disabled={true} /> */}
              <KBInput
                disabled={true}
                isRequiredR={true}
                title="开始"
                className="kb-form-group kb-div-c48"
                inputClass=" kb-input kb-input-disabled"
                field={startHM}
              />
              {/* <KBSelect title='结束' isRequiredR={ true } titleClass='label-rt' arrayData={ DEAL_DAY_TIME_HM } field={endHM} {...halfClass}disabled={true} /> */}
              <KBInput
                disabled={true}
                isRequiredR={true}
                title="结束"
                className="kb-form-group kb-div-c48"
                inputClass=" kb-input kb-input-disabled"
                field={endHM}
              />
            </div>
          ) : (
            <div className="kb-row kb-form-66">
              <KBSelect
                title="开始"
                isRequired={true}
                onChange={this.changeStartHMCallback}
                arrayData={DEAL_DAY_TIME_HM}
                field={startHM}
                {...halfClass}
              />
              <KBSelect
                title="结束"
                isRequiredR={true}
                onChange={this.changeEndHMCallback}
                titleClass="label-rt"
                arrayData={DEAL_DAY_TIME_HM}
                field={endHM}
                {...halfClass}
              />
            </div>
          )}

          {/* 预定情况 */}
          <div>
            <div className="kb-row">
              <KBSelect
                className="kb-form-group kb-form-c18 kb-form-alone"
                title="预定情况"
                isRequired
                defaultValue="-1"
                arrayData={PAYMENT_TYPES}
                defa
                field={payment_type}
              />
            </div>
            <div className="kb-row">
              <KBTextarea
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={remark}
                title="备注"
                isRequired
              />
            </div>
          <div className="kb-row">
            <KBMoreFileUpload
              uploadingText="正在上传"
              uploadedText="点击上传"
              sort={false}
              multiple
              className="kb-form-group kb-form-alone"
              field={attachments}
              text="上传附件"
              accept=".pdf,.png,.jpg,.jpeg"
              title="附件格式为PDF、PNG、JPG、JPEG，可上传多个附件"
              type="attachments"
            />
          </div>
          </div>

          {
            // meeting.area_type != 'meeting_room' ? null :
            <div className="kb-row">
              <KBUserInput
                title="公司"
                hint="可以选择公司，来宾请直接输入姓名和电话"
                callback={this.orgCallBack}
                placeholder={'请选择公司'}
                className="kb-form-c18 kb-form-group kb-form-alone"
                field={organization_id}
                multi={false}
                users={orgs}
              />
            </div>
          }
          <div className="kb-row">
            <KBUserInput
              title="员工"
              callback={this.memberCallBack}
              className="kb-form-c18 kb-form-group kb-form-alone"
              field={contacts_id}
              multi={false}
              users={members}
            />
          </div>
          <div className="kb-row">
            <KBInput
              title="联系人"
              isRequired={true}
              className="kb-form-c18 kb-form-group kb-form-alone"
              field={contacts_name}
            />
          </div>
          <div className="kb-row">
            <KBInput
              title="联系电话"
              isRequired={true}
              className="kb-form-c18 kb-form-group kb-form-alone"
              field={contacts_phone}
            />
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  let reservation_time_range = props.reservation_time_range
  const { begin, end } = reservation_time_range
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(begin || 0, end || 24)
  let loc_id = props.loc_id
  const orgs = selectors.getOrgsOfLocation(state, loc_id)
  orgs.unshift({ name: '请选择', id: null })

  return {
    orgs,
    state,
    loc_id,
    DEAL_DAY_TIME_HM
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createReservationsAction: bindActionCreators(
      reservationsActions.create,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    getOrganizationsAction: bindActionCreators(orgActions.replace, dispatch)
  }
}

const validate = values => {
  console.log(values)
  const errors = {}

  if (valid.required(values.startHM)) {
    errors.startHM = '请选择开始时间'
  }

  if (valid.required(values.endHM)) {
    errors.endHM = '请选择结束时间'
  }

  if (valid.required(values.contacts_name)) {
    errors.contacts_name = '请输入联系人名称'
  }

  if (valid.required(values.remark)) {
    errors.remark = '请输入备注'
  }

  if (valid.required(values.payment_type)) {
    errors.payment_type = '请选择预定情况'
  }

  if (valid.required(values.contacts_phone)) {
    errors.contacts_phone = '请输入联系电话'
  }

  if (
    valid.mobile(values.contacts_phone) &&
    values.contacts_phone &&
    !values.contacts_phone.includes('*')
  ) {
    errors.contacts_phone = '请输入正确的联系电话'
  }

  let end_at = moment(values.end_at)
  let start_at = moment(values.start_at)

  if (end_at.diff(start_at, 'minutes') < 30) {
    errors.startHM = '预订时长必须大于30分钟'
  }

  return errors
}

const formConfig = {
  form: 'MeetingForm',
  fields: [
    'start_at',
    'end_at',
    'contacts_id',
    'organization_id',
    'contacts_name',
    'contacts_phone',
    'startHM',
    'endHM',
    'sales_customer_id',
    'timespan[].start_at',
    'timespan[].end_at',
    'remark',
    'attachments[].url',
    'payment_type'
  ],
  validate: validate,
  touchOnBlur: false
}

MeetingBookingForm.propTypes = {
  loc_id: PropTypes.string.isRequired,
  meeting: PropTypes.object.isRequired,
  end_at: PropTypes.object.isRequired,
  start_at: PropTypes.object.isRequired,
  callback: PropTypes.func
}

MeetingBookingForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(MeetingBookingForm)

export default MeetingBookingForm
