import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import { KBPopover } from 'components'
import PhasesCell from './PhasesCell'
import moment from 'moment'
import _ from 'lodash'

var OrgSubscriptionEditRentingPhase = React.createClass({
  componentDidMount() {
    const {
      sales_renting_phases,
      oldContract,
      fields: { old_sales_renting_phases },
      validDate
    } = this.props
    oldContract.sales_renting_phases &&
      oldContract.sales_renting_phases.map(phase => {
        old_sales_renting_phases.addField(getPhase(phase))
      })

    function getPhase(phase) {
      return Object.assign({}, phase, {
        //id               : phase.id,
        start_date: moment(phase.start_date)
          .clone()
          .hours(12)
          .minutes(0)
          .seconds(0)
          .toDate(),
        end_date: moment(phase.end_date)
          .clone()
          .hours(12)
          .minutes(0)
          .seconds(0)
          .toDate(),
        recurring_amount: phase.recurring_amount,
        total_amount: parseFloat(phase.total_amount).toFixed(2),
        recurring_house_fee: parseFloat(phase.recurring_house_fee),
        rent_type: phase.rent_type,
        status: phase.status,
        invoice_schedule: phase.invoice_schedule,
        check_status: phase.check_status
      })
    }
  },

  removePhases() {
    const {
      fields: { old_sales_renting_phases }
    } = this.props
    let sales_renting_phases_clone = _.cloneDeep(old_sales_renting_phases)
    let indexTemp = []
    let d = 0

    KBPopover.plugins.confirm(`删除缴租阶段`, `是否确定删除缴租阶段`, {
      confirm: () => {
        sales_renting_phases_clone.forEach((phase, index) => {
          indexTemp.push(index)
        })

        indexTemp.forEach(i => {
          old_sales_renting_phases.removeField(i - d)
          ++d
        })
        KBPopover.close()
      }
    })
  },

  removeOldPhases() {
    const {
      fields: { old_sales_renting_phases },
      sales_renting_phases
    } = this.props
    let sales_renting_phases_clone = _.cloneDeep(old_sales_renting_phases)
    let indexTemp = []
    let d = 0

    sales_renting_phases_clone.forEach((phase, index) => {
      if (
        moment(phase.end_date.value).isAfter(
          moment(sales_renting_phases[0].start_date.value).format('YYYY-MM-DD')
        )
      ) {
        indexTemp.push(index)
      }
    })

    indexTemp.forEach(i => {
      old_sales_renting_phases.removeField(i - d)
      ++d
    })
  },

  recordPhases() {
    const {
      fields: { old_sales_renting_phases },
      recordPhasesMessage,
      isContract
    } = this.props
    recordPhasesMessage && recordPhasesMessage(old_sales_renting_phases)
  },

  render() {
    let {
      fields: { old_sales_renting_phases },
      sales_renting_phases,
      removeUpdatePhases,
      parseRentingPhases,
      validDate,
      status,
      phase_reset_status,
      freePhases,
      isParking,
      isContract
    } = this.props
    if (sales_renting_phases.length > 0) {
      this.removeOldPhases()
    }
    this.recordPhases()
    old_sales_renting_phases.sort(function(a, b) {
      return (
        moment(a.start_date.value).valueOf() -
        moment(b.start_date.value).valueOf()
      )
    })

    let total = 0
    return (
      <fieldset className="fieldset">
        <legend className="clear-fix">
          <span className="f-left">缴{isContract ? '费' : '租'}阶段</span>
          {!isContract&&<span
            className="f-left"
            style={{
              fontSize: 14,
              color: '#666',
              marginLeft: 10,
              marginTop: 5
            }}
          >
            (更改{isContract ? '周期性收费' : (isParking ? '车位' : '工位设置')}后请重新{isContract ? '添加' : '生成'}缴租阶段)
          </span>}
        </legend>
        {phase_reset_status && !isContract ? (
          <p style={{ color: '#f55b5b', marginBottom: 15 }}>
            您的修改会导致会员费或者押金变化，需要重新计算缴租周期
          </p>
        ) : (
          ''
        )}
        {!isContract && <div>
          <div style={{ margintop: 10, marginBottom: 15 }}>
          <button
            className="bordered-btn"
            type="button"
            onClick={() => {
              parseRentingPhases(status)
            }}
            style={{ marginLeft: 0 }}
          >
            重新生成缴租阶段
          </button>
        </div>
        <p className="m-bottom-sm color-six">
          点击后，删除修改有效日期以后的缴租阶段，并重新生成修改有效日期以后的缴租阶段（从下一个月份开始）。
        </p>
        </div>}
        {old_sales_renting_phases.length ? (
          <div className="form-content" style={{ paddingLeft: 20 }}>
            <div className="m-top">
              <div
                style={{
                  paddingBottom: 10,
                  borderBottom: '1px solid #e5e5e5',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{ marginRight: 15, fontSize: 16, color: '#333' }}
                  >
                    {moment(
                      old_sales_renting_phases[0].start_date.value
                    ).format('YYYY年MM月DD日')}{' '}
                    -{' '}
                    {moment(
                      _.last(old_sales_renting_phases).end_date.value
                    ).format('YYYY年MM月DD日')}
                  </span>
                  <span style={{ fontSize: 10 }}>(现有缴租阶段)</span>
                </div>
                <span
                  className="c-pointer"
                  onClick={() => {
                    this.removePhases()
                  }}
                  style={{ fontSize: 16 }}
                >
                  <i className="iconfont icon-close a-hover" />
                </span>
              </div>
              <div style={{ paddingLeft: 15 }}>
                <table className="content-table">
                  <tbody>
                    {old_sales_renting_phases.map((phase, index) => {
                      total = total + parseFloat(phase.total_amount.value)
                      let index_d = old_sales_renting_phases.findIndex(
                        sales =>
                          moment(sales.start_date.value).isSame(
                            phase.start_date.value
                          ) &&
                          moment(sales.end_date.value).isSame(
                            phase.end_date.value
                          )
                      )
                      return (
                        <PhasesCell
                          key={index}
                          phase={phase}
                          index={index}
                          index_d={index_d}
                          validDate={validDate}
                          sales_renting_phases={old_sales_renting_phases}
                          status={status}
                          all_sales_renting_phases={old_sales_renting_phases}
                        />
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {sales_renting_phases.length ? (
          <div style={{ paddingLeft: 20 }}>
            <div className="m-top">
              <div
                style={{
                  paddingBottom: 10,
                  borderBottom: '1px solid #e5e5e5',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{ marginRight: 15, fontSize: 16, color: '#333' }}
                  >
                    {moment(sales_renting_phases[0].start_date.value).format(
                      'YYYY年MM月DD日'
                    )}{' '}
                    -{' '}
                    {moment(_.last(sales_renting_phases).end_date.value).format(
                      'YYYY年MM月DD日'
                    )}
                  </span>
                  <span style={{ fontSize: 10 }}>(新缴租阶段)</span>
                </div>
                <span
                  className="c-pointer"
                  onClick={() => {
                    removeUpdatePhases()
                  }}
                  style={{ fontSize: 16 }}
                >
                  <i className="iconfont icon-close a-hover" />
                </span>
              </div>
              <div style={{ paddingLeft: 15 }}>
                <table className="content-table">
                  <tbody>
                    {sales_renting_phases.map((phase, index) => {
                      total = total + parseFloat(phase.total_amount.value)
                      let index_d = sales_renting_phases.findIndex(
                        sales =>
                          moment(sales.start_date.value).isSame(
                            phase.start_date.value
                          ) &&
                          moment(sales.end_date.value).isSame(
                            phase.end_date.value
                          )
                      )
                      return (
                        <PhasesCell
                          key={index}
                          phase={phase}
                          index={index}
                          index_d={index_d}
                          sales_renting_phases={sales_renting_phases}
                          all_sales_renting_phases={sales_renting_phases}
                        />
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            <button
              className="bordered-btn"
              type="button"
              style={{ marginLeft: 0 }}
              onClick={() => {
                parseRentingPhases()
              }}
            >
              <i className="iconfont icon-add" />
              添加缴{isContract ? '费' : '租'}阶段
            </button>
            {!isContract && <button
              className="bordered-btn"
              type="button"
              onClick={() => {
                freePhases()
              }}
            >
              <i className="iconfont icon-add" />
              添加免租期
            </button>}
          </div>
          <div className="total-num">
            <span>共&nbsp;¥&nbsp;{parseFloat(total).toFixed(2)}</span>
          </div>
        </div>
      </fieldset>
    )
  }
})

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'OrgSubscriptionEditRentingPhase',
  fields: [
    'old_sales_renting_phases[].start_date',
    'old_sales_renting_phases[].end_date',
    'old_sales_renting_phases[].total_amount',
    'old_sales_renting_phases[].recurring_amount',
    'old_sales_renting_phases[].recurring_cycles',
    'old_sales_renting_phases[].recurring_due_date',
    'old_sales_renting_phases[].recurring_house_fee',
    'old_sales_renting_phases[].rent_type',
    'old_sales_renting_phases[].note',
    'old_sales_renting_phases[].status', // 状态
    'old_sales_renting_phases[].invoice_schedule', // 开票
    'old_sales_renting_phases[].check_status' // 核销
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionEditRentingPhase)
