import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import ReactDOM from 'react-dom'
import { reduxForm } from 'redux-form'
import { meetingsActions } from 'app/actions'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import moment from 'moment'
import { toImageProxyDirect } from 'utils/kbUtil'
import {
  ORIENTATION_STATES,
  LOCK_ARRAY,
  RESERVATION_PRIVILEGES,
  AREATYPES_STATES,
  CUSTOM_TIME_ARRAY
} from 'app/constants'
import {
  KBInput,
  KBPopover,
  KBSelect,
  KBFormError,
  KBButton,
  KBRadio,
  KBUserInput,
  KBMorePhotoUpload,
  KBDateInput,
  KBTextarea,
  KBMoreImageCropperInput,
  KBImageCropperInput
} from 'components'
import { notification } from 'antd'
import {
  getDevices,
  getCurrentUser,
  getMeetingRoomsOfLocation,
  getLocation
} from 'app/selectors'
import { canAdminSpace } from 'app/reducers/role'
import _ from 'lodash'
import Datetime from 'react-datetime'

const BridgeVisible = [
  {
    id: 'yes',
    name: '显示'
  },
  {
    id: 'no',
    name: '不显示'
  }
]
const VISIBLE = {
  yes: true,
  no: false
}

var MeetingForm = React.createClass({
  getInitialState() {
    return {
      price_determined: true,
      // meetingPrice: 'free',
      placeState: null,
      areaDevices: [],
      orderOption: '',
      floors: [],
      bridge_enabled: false,
      customDate: false,
      WEEK_ARRAY: [
        {
          title: '周一',
          check: true,
          value: 1,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周二',
          check: true,
          value: 2,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周三',
          check: true,
          value: 3,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周四',
          check: true,
          value: 4,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周五',
          check: true,
          value: 5,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周六',
          check: true,
          value: 6,
          startTime: '09:00',
          endTime: '18:00'
        },
        {
          title: '周日',
          check: true,
          value: 7,
          startTime: '09:00',
          endTime: '18:00'
        }
      ],
      fileList: [],
      merchants: [],
      taxArray: []
    }
  },

  componentDidMount() {
    const {
      loc_id,
      meeting,
      location_groups_id,
      fields: { images }
    } = this.props
    // api.getLocationGroupSettings(location_groups_id, true).then(json => {
    //   const bridge_enabled = _.get(json, 'json.meeting_room_settings.bridge_enabled', false);
    //   this.setState({
    //     bridge_enabled,
    //   });
    // });
    api.getMerchants({ location_id: loc_id }).then(res => {
      const { json = [] } = res
      this.setState({ merchants: json })
    })
    api.getInvoiceApplicationSetting({ location_id: loc_id }).then(res => {
      const { json = [] } = res
      this.setState(
        {
          taxArray: json.map(item => ({ ...item, name: item.account_name }))
        },
        () => {
          if (meeting && meeting.id) {
            // 给税收分类赋值
            this.applicationChange(
              (meeting.invoicing_application_setting || {}).id
            )
          }
        }
      )
    })
    if (meeting) {
      this.setState({
        price_determined: !!meeting.price_determined,
        fileList: meeting.images || []
      })
    }
    const reservation_time_range = _.get(meeting, 'reservation_time_range', [])
    if (reservation_time_range.length > 0) {
      let { WEEK_ARRAY } = this.state
      let ranges = reservation_time_range
      let timeRange = ranges[0]
      const newWeekArray = [...WEEK_ARRAY]
      if (timeRange.day !== -1) {
        reservation_time_range.forEach(item => {
          newWeekArray.forEach(weekItem => {
            if (item.day == weekItem.value) {
              weekItem.check = !item.nowork
              weekItem.startTime = this.ChangeHourMinutestr(item.begin)
              weekItem.endTime = this.ChangeHourMinutestr(item.end)
            }
          })
        })
        this.setState({
          customDate: true,
          WEEK_ARRAY: newWeekArray
        })
      }
    }

    api.getGroupsInfo(location_groups_id).then(
      json => {
        let group = _.get(json, 'response.body')
        const bridge_enabled = _.get(group, 'bridge_enabled', false)
        this.setState({
          bridge_enabled
        })
      },
      errors => {}
    )

    api
      .getAreas({
        location_id: loc_id,
        includes: 'subscriptions,organizations',
        area_type: 'floor',
        per_page: 1000
      })
      .then(
        json => {
          let floors = json.response.body
          this.setState({
            floors
          })
          // getAreasAction.success(json, { location_id: loc_id });
          return true
        }
        // , errors => {
        //   getAreasAction.failure(errors)
        // }
      )

    api.getDevices({ area_only: true }).then(json => {
      let areaDevices = json.response.body
      this.setState({
        areaDevices
      })
    })
  },

  createAreaForm(model) {
    const {
      loc_id,
      state,
      meeting,
      createMeetingAction,
      updateMeetingAction
    } = this.props
    const { customDate, WEEK_ARRAY, price_determined } = this.state
    let params = model
    params.price_determined = price_determined
    params.bridgego_visible = VISIBLE[model.bridgego_visible]
    if(!params.bridgego_visible || !price_determined) {
      params.fake_order_num = 0
    }
    if (params.merchant_id === '-1') params.merchant_id = null
    if (params.invoicing_application_setting_id === '-1')
      params.invoicing_application_setting_id = null
    if (customDate) {
      const reservation_time_range = WEEK_ARRAY.map(week => {
        let startMinute = this.ChangeStrToMinutes(week.startTime)
        let endMinute = this.ChangeStrToMinutes(week.endTime)
        return {
          day: week.value,
          begin: startMinute,
          end: endMinute,
          nowork: !week.check
        }
      })
      params.settings = {
        meeting_room_settings: { reservation_time_range }
      }
    } else {
      // params.settings = { meeting_room_settings:{reservation_time_range: [{
      //   day: -1,
      //   begin: 540,
      //   end: 1380,
      //   nowork: false}]} };
    }

    if (params.self_setuped === 'unset') {
      delete params.star_point_settings
      delete params.self_setuped
    } else {
      // 自定义
      params.settings = {
        ...params.settings,
        star_point_settings: {
          ex_rate: Number(params.ex_rate),
          de_rate: params.de_rate / 100
        }
      }

      delete params.star_point_settings
      delete params.self_setuped
      delete params.ex_rate
      delete params.de_rate
    }

    if (params.redirect_mini === 'false' || !params.redirect_mini) {
      ;[
        'appid',
        'mini_id',
        'mini_path',
        'mini_qrcode_image_url',
        'mini_name',
        'mini_redirect_title',
        'mini_redirect_content'
      ].forEach(key => {
        params[key] = ''
      })
    }

    if (state == 'update') {
      return api.updateMeetingRooms(meeting.id, params).then(json => {
        updateMeetingAction.success(json)
        KBPopover.close()
        return true
      })
    }

    return api
      .createMeetingRooms({ location_id: loc_id, ...params })
      .then(json => {
        createMeetingAction.success(json, { loc_id })
        KBPopover.close()
        return true
      })
  },

  ChangeStrToMinutes(str) {
    var arrminutes = str.split(':')
    if (arrminutes.length == 2) {
      var minutes = parseInt(arrminutes[0]) * 60 + parseInt(arrminutes[1])
      return minutes
    } else {
      return 0
    }
  },

  ChangeHourMinutestr(str) {
    if (str !== '0' && str !== '' && str !== null) {
      return (
        (Math.floor(str / 60).toString().length < 2
          ? '0' + Math.floor(str / 60).toString()
          : Math.floor(str / 60).toString()) +
        ':' +
        ((str % 60).toString().length < 2
          ? '0' + (str % 60).toString()
          : (str % 60).toString())
      )
    } else {
      return ''
    }
  },
  changeInvoiceContent(id) {
    const {
      fields: { orientation }
    } = this.props
    orientation.onChange(id)
  },
  _changeAreaType(event) {
    this.setState({ orderOption: event.target.value })
    const {
      fields: { order_option }
    } = this.props
    order_option.onChange(event)
  },
  _changeMeetingOpenTime(event) {
    this.setState({
      customDate: !this.state.customDate
    })
  },

  _changeMeetingState(state) {
    // const { fields: { reserve_role } } = this.props;
    // if (state === 'disabled') {
    //   reserve_role.onChange('space_manager');
    // }
    this.setState({
      price_determined: state
    })
  },

  UploadFailureCallBack(msg) {
    if (msg) {
      notification.error({
        message: '上传失败',
        description: msg,
        top: 100,
        style: { zIndex: 1001 }
      })
    }
  },

  removeFile(index) {
    const {
      fields: { images }
    } = this.props
    const { fileList } = this.state
    let newFileList = [...fileList]
    newFileList.splice(index, 1)
    images.onChange(newFileList)
    this.setState({ fileList: newFileList })
  },

  applicationChange(val) {
    const { taxArray } = this.state
    const {
      fields: { xforceplus_tax_setting_id }
    } = this.props
    const objMain = taxArray.find(i => i.id == val)
    let valueStr = ''
    if (objMain) {
      const objItem = objMain.invoicing_xforceplus_settings.find(
        i => i.invoice_type === 'reservation'
      )
      if (objItem) {
        valueStr = objItem.name
      }
    }
    xforceplus_tax_setting_id.onChange(valueStr)
  },

  render() {
    const {
      fields: {
        name,
        area_type,
        orientation,
        lock,
        wall,
        priority,
        floor,
        listing_price,
        non_entering_listing_price,
        capacity,
        management_only,
        images,
        device_ids,
        size,
        max_meanwhile_number,
        floor_ids,
        checkin_date,
        features,
        fake_order_num,
        description,
        day_listing_price,
        non_entering_day_listing_price,
        reserve_role,
        order_option,
        bridge_listing_price,
        bridgego_visible,
        merchant_id,
        invoicing_application_setting_id,
        xforceplus_tax_setting_id,
        // 第三方小程序字段
        redirect_mini,
        appid,
        mini_id,
        mini_path,
        mini_qrcode_image_url,
        mini_name,
        mini_redirect_title,
        mini_redirect_content,
        self_setuped,
        ex_rate,
        de_rate,
        floor_color,
        visit_apply
      },
      error,
      submitting,
      handleSubmit,
      areaState,
      state,
      loc_id
    } = this.props
    const { user, selectDevices, defaultImages, meeting = {} } = this.props
    const {
      areaDevices,
      bridge_enabled,
      price_determined,
      fileList,
      merchants,
      WEEK_ARRAY,
      taxArray
    } = this.state

    const orientationArray = ORIENTATION_STATES
    const lockArray = LOCK_ARRAY
    const reservationPrivileges = RESERVATION_PRIVILEGES
    const areaTypesArray = AREATYPES_STATES

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    return (
      <form onSubmit={handleSubmit(this.createAreaForm)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改场地' : '创建场地'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="名称"
              isRequired={true}
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="类型"
              field={area_type}
              arrayData={areaTypesArray}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="对奕桥小程序是否可见"
              field={bridgego_visible}
              arrayData={BridgeVisible}
              defaultValue="-1"
            />
          </div>

          <div className="kb-row kb-form-66">
            <KBInput
              {...halfClass}
              field={capacity}
              title="容纳人数"
              isRequired={true}
            />
            <KBInput
              {...halfClass}
              inputClass="input-area"
              titleClass="label-rt"
              field={size}
              title="面积"
            />
          </div>
          {/*<div className='kb-row kb-form-66'>*/}
          {/*<KBRadio {...halfClass} inClassName='kb-form-group kb-div-33' groupStyle={ {width: '100%'} } divClassName='kb-form-group kb-div-66' rightLabel='label-rt' title='锁' arrayType={ lockArray } name='lock'  field={ lock } />*/}
          {/*<KBRadio {...halfClass} inClassName='kb-form-group kb-div-33' groupStyle={ {width: '100%'} } titleClass='label-rt' divClassName='kb-form-group kb-div-66' rightLabel='label-rt' title='墙&nbsp;&nbsp;&nbsp;' arrayType={ lockArray } name='wall'  field={ wall } />*/}
          {/*</div>*/}
          {/*<div className="kb-row">*/}
          {/*<div className='kb-form-group kb-div-c39 kb-form-alone'>*/}
          {/*<label style={{ width: '13%' }}>朝向</label>*/}
          {/*<div>*/}
          {/*<div className="kb-form-group r1_radios">*/}
          {/*{*/}
          {/*orientationArray.map((data, index) => {*/}
          {/*return (*/}
          {/*<div key={ index } className="kb-form-group r1_radio" onClick={ this.changeInvoiceContent.bind(null, data.id) }>*/}
          {/*<div className={ `radio-btn ${orientation.value == data.id ? 'radio-btn-active' : ''}` }></div>*/}
          {/*<label className="label" >{ data.name }</label>*/}
          {/*</div>*/}
          {/*)*/}
          {/*})*/}
          {/*}*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-div-c39 kb-form-alone"
              field={floor}
              title="楼层"
            />
          </div>
          <div className='kb-row'>
            <div className="kb-form-group kb-div-c39 kb-form-alone">
              <label>角标颜色</label>
              <div>
                <input type="color" value={floor_color.value} onChange={e => floor_color.onChange(e.target.value)} style={{width: '100px'}} />
              </div>
            </div>
          </div>
          <div className="kb-row">
            <KBUserInput
              title="所属区域"
              className="kb-form-group kb-form-c18 kb-form-alone"
              placeholder="选择场地所属区域"
              field={floor_ids}
              multi={true}
              users={this.state.floors}
              selectedUsers={this.props.selectedFloors}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="设备"
              selectedUsers={selectDevices}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={device_ids}
              placeholder="选择设备"
              multi={true}
              users={areaDevices}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={'请输入数字，修改客户端的会议室位置，数字越大排上面'}
              field={priority}
              title="排序位"
            />
          </div>
          {
            <div className="kb-row kb-form-66">
              <KBSelect
                {...halfClass}
                title="预订权限"
                field={reserve_role}
                arrayData={reservationPrivileges}
              />
              <KBInput
                {...halfClass}
                type="number"
                titleClass="label-rt"
                title="最大同时预订数"
                field={max_meanwhile_number}
              />
            </div>
          }
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>场地价格</div>
            <div
              className="kb-form-group kb-div-11"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <input
                type="radio"
                checked={!price_determined}
                onChange={() => this._changeMeetingState(false)}
                id="close-office"
              />
              <label className="label" style={{ marginLeft: 5 }}>
                价格需与运营团队商议（需要联系管理员预定）
              </label>
            </div>
            <div
              className="kb-form-group kb-div-11"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <input
                type="radio"
                checked={price_determined}
                onChange={() => this._changeMeetingState(true)}
                id="close-office"
              />
              <label className="label" style={{ marginLeft: 5 }}>
                有明确价格
              </label>
            </div>
          </div>

          {price_determined && (
            <div>
              <div
                className="kb-form-group kb-div-11"
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <input
                  type="radio"
                  value="by_day"
                  checked={order_option.value === 'by_day'}
                  onChange={this._changeAreaType}
                  id="close-office"
                />
                <label
                  className="label"
                  htmlFor="close-office"
                  style={{ marginLeft: 5 }}
                >
                  按天预订
                </label>
              </div>
              <div
                className="kb-form-group kb-div-11"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 20
                }}
              >
                <input
                  type="radio"
                  value="by_hour"
                  checked={order_option.value === 'by_hour'}
                  onChange={this._changeAreaType}
                  id="close-office"
                />
                <label
                  className="label"
                  htmlFor="close-office"
                  style={{ marginLeft: 5 }}
                >
                  按小时预订
                </label>
              </div>
            </div>
          )}
          {price_determined &&
            (this.state.orderOption === 'by_day' ||
              order_option.value === 'by_day') && (
              <div className={bridge_enabled ? 'kb-row kb-form-84' : 'kb-row'}>
                <div
                  className={
                    bridge_enabled ? 'kb-form-66' : 'kb-row kb-form-66'
                  }
                >
                  <KBInput
                    className={
                      bridge_enabled
                        ? 'kb-form-group kb-div-48'
                        : 'kb-form-group kb-div-c39'
                    }
                    titleClass="label-rt"
                    inputClass={`input-dollar-day ${
                      meeting.can_modify_price === false
                        ? 'kb-input-disabled'
                        : ''
                    }`}
                    field={non_entering_day_listing_price}
                    title="一般价格"
                    hint="外部人员看到的价格"
                    disabled={meeting.can_modify_price === false}
                  />
                  <KBInput
                    className={
                      bridge_enabled
                        ? 'kb-form-group kb-div-48'
                        : 'kb-form-group kb-div-c39'
                    }
                    titleClass="label-rt"
                    inputClass={`input-dollar-day ${
                      meeting.can_modify_price === false
                        ? 'kb-input-disabled'
                        : ''
                    }`}
                    field={day_listing_price}
                    title="办公楼会员价"
                    hint="办公楼租户员工看到的价格"
                    disabled={meeting.can_modify_price === false}
                  />
                </div>
                {bridge_enabled && (
                  <KBInput
                    className="kb-form-group kb-div-48"
                    titleClass="label-rt"
                    inputClass={`input-dollar-day ${
                      meeting.can_modify_price === false
                        ? 'kb-input-disabled'
                        : ''
                    }`}
                    field={bridge_listing_price}
                    title="奕桥会员价"
                    hint="联办租户员工看到的价格"
                    disabled={meeting.can_modify_price === false}
                  />
                )}
              </div>
            )}
          {price_determined &&
            (this.state.orderOption === 'by_hour' ||
              order_option.value === 'by_hour') && (
              <div className={bridge_enabled ? 'kb-row kb-form-84' : 'kb-row'}>
                <div
                  className={
                    bridge_enabled ? 'kb-form-66' : 'kb-row kb-form-66'
                  }
                >
                  <KBInput
                    className={
                      bridge_enabled
                        ? 'kb-form-group kb-div-48'
                        : 'kb-form-group kb-div-c39'
                    }
                    titleClass="label-rt"
                    // inputClass="input-dollar-hour"
                    inputClass={`input-dollar-hour ${
                      meeting.can_modify_price === false
                        ? 'kb-input-disabled'
                        : ''
                    }`}
                    field={non_entering_listing_price}
                    title="一般价格"
                    hint="外部人员看到的价格"
                    disabled={meeting.can_modify_price === false}
                  />
                  <KBInput
                    className={
                      bridge_enabled
                        ? 'kb-form-group kb-div-48'
                        : 'kb-form-group kb-div-c39'
                    }
                    inputClass={`input-dollar-hour ${
                      meeting.can_modify_price === false
                        ? 'kb-input-disabled'
                        : ''
                    }`}
                    // inputClass="input-dollar-hour"
                    field={listing_price}
                    title="办公楼会员价"
                    hint="办公楼租户员工看到的价格"
                    disabled={meeting.can_modify_price === false}
                  />
                </div>
                {bridge_enabled && (
                  <KBInput
                    className="kb-form-group kb-div-48"
                    // inputClass="input-dollar-hour"
                    inputClass={`input-dollar-hour ${
                      meeting.can_modify_price === false
                        ? 'kb-input-disabled'
                        : ''
                    }`}
                    field={bridge_listing_price}
                    title="奕桥会员价"
                    hint="联办租户员工看到的价格"
                    disabled={meeting.can_modify_price === false}
                  />
                )}
              </div>
            )}

          {bridgego_visible.value === 'yes' && price_determined && (
            <div className="kb-row">
              <KBInput
                isRequired
                className="kb-form-group kb-form-c18 kb-form-alone"
                type="text"
                title="订单基数"
                hint="小程序显示的订单数=订单基数+实际订单数"
                field={fake_order_num}
              />
            </div>
          )}

          {/* 星积分设置 */}
          <div
            className="kb-row"
            style={{
              marginTop: '10px'
            }}
          >
            <KBRadio
              className="kb-form-group kb-div-c48"
              groupStyle={{ width: '100%' }}
              divClassName="kb-form-group kb-div-33"
              rightLabel="label-rt"
              title="星积分设置"
              arrayType={[
                {
                  value: 'unset',
                  title: '与项目统一的星积分兑换比例和抵扣比例保持一致'
                },
                { value: 'custom', title: '设置独立的星积分兑换比例和抵扣比例' }
              ]}
              field={self_setuped}
              name="self_setuped"
              isRequired
            />
            {self_setuped.value === 'custom' && (
              <div>
                <div className="kb-row">
                  <KBInput
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    title="兑换比例"
                    hint="钱与虚拟币的兑换比例(1元=x币)"
                    field={ex_rate}
                  />
                </div>
                <div className="kb-row">
                  <KBInput
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    inputClass="input-percent"
                    title="抵扣比例"
                    hint="星积分抵扣在订单中的最高使用占比，可以设置0~100，为0的则不支持星积分抵扣"
                    field={de_rate}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="税盘"
              field={invoicing_application_setting_id}
              arrayData={taxArray}
              defaultValue="-1"
              onChange={this.applicationChange}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="税收分类"
              field={xforceplus_tax_setting_id}
              disabled
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="汇付账号"
              field={merchant_id}
              arrayData={merchants}
              defaultValue="-1"
            />
          </div>
          <div className="kb-row">
            <KBDateInput
              field={checkin_date}
              title="可预订起始日期"
              className="kb-form-c18 kb-form-group kb-form-alone"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              className="kb-form-group kb-div-11"
              style={{ marginTop: 15, display: 'flex', alignItems: 'center' }}
            >
              <input
                type="radio"
                checked={!this.state.customDate}
                onChange={this._changeMeetingOpenTime}
                id="close-office"
              />
              <label className="label" style={{ marginLeft: 5 }}>
                与项目统一的开放时间一致
              </label>
            </div>
            <div
              className="kb-form-group kb-div-11"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <input
                type="radio"
                checked={this.state.customDate}
                onChange={this._changeMeetingOpenTime}
                id="close-office"
              />
              <label className="label" style={{ marginLeft: 5 }}>
                设置独立开放时间
              </label>
            </div>
          </div>
          <div>
            {this.state.customDate && (
              <div>
                <table className="content-table">
                  <thead>
                    <tr>
                      <th>星期</th>
                      <th>是否营业</th>
                      <th>开放时间</th>
                    </tr>
                  </thead>
                  <tbody>
                    {WEEK_ARRAY.map((week, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ width: 130 }}>{week.title}</td>
                          <td style={{ width: 130 }}>
                            <div
                              className="slide-btn"
                              onClick={() => {
                                const newWeekArray = [...WEEK_ARRAY]
                                newWeekArray[index].check = !newWeekArray[index]
                                  .check
                                this.setState({ WEEK_ARRAY: newWeekArray })
                              }}
                            >
                              <span
                                className={`slide-bg ${
                                  week.check ? 'slide-left' : 'slide-right'
                                }`}
                              >
                                <span className="slide-move" />
                              </span>
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <KBSelect
                                {...halfClass}
                                field={{
                                  value: week.startTime,
                                  onChange: date => {
                                    const newWeekArray = [...WEEK_ARRAY]
                                    newWeekArray[index].startTime = date
                                    this.setState({ WEEK_ARRAY: newWeekArray })
                                  }
                                }}
                                selectStyle={{ width: 100 }}
                                arrayData={CUSTOM_TIME_ARRAY}
                              />
                              <span>-</span>
                              <KBSelect
                                {...halfClass}
                                field={{
                                  value: week.endTime,
                                  onChange: date => {
                                    const newWeekArray = [...WEEK_ARRAY]
                                    newWeekArray[index].endTime = date
                                    this.setState({ WEEK_ARRAY: newWeekArray })
                                  }
                                }}
                                selectStyle={{ width: 100 }}
                                arrayData={CUSTOM_TIME_ARRAY}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              type="text"
              title="场地特点"
              hint="描述当前场地特点的关键词，用“,”分隔"
              field={features}
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint=""
              field={description}
              title="场地描述"
            />
          </div>

          <div className="kb-row">
            {/* <KBMorePhotoUpload
              defaultImages={defaultImages}
              title="上传图片"
              hint="允许多张上传,格式为png或jpg,大小不大于20M"
              photoShow={true}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={images}
              text="上传场地图片"
              prefix="images/meeting/"
              accept="image/*"
            /> */}
            <KBMoreImageCropperInput
              title="会议室图片"
              aspect={1125 / 750}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={images}
              type="groups"
              prefix={`meeting/${loc_id}/`}
              width={225}
              height={150}
              imageSize={1024}
              failureCallBack={this.UploadFailureCallBack}
              callback={v => this.setState({ fileList: v })}
              accept=".png,.jpg,.jpeg"
              isPublic
            >
              <div className="upload-portrait">
                <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
                  <button className="bordered-btn m-none" type="button">
                    {fileList && fileList.length ? '继续上传图片' : '上传图片'}
                  </button>
                  <p
                    className="color-nine"
                    style={{ paddingTop: 20, fontSize: 12 }}
                  >
                    图片格式为png, jpeg或jpg,文件大小不大于1M
                  </p>
                </div>
              </div>
            </KBMoreImageCropperInput>
            <div>
              {fileList ? (
                <ul className="kb-more-file" style={{ margin: 0 }}>
                  {fileList.map((file, index) => {
                    return (
                      <li key={index} style={{ cursor: 'pointer' }}>
                        <img
                          src={file}
                          alt="loading"
                          style={{
                            width: '225px',
                            height: '150px',
                            marginBottom: 10
                          }}
                        />

                        <div className="delete">
                          <i
                            className="iconfont icon-close"
                            onClick={this.removeFile.bind(null, index)}
                          />
                        </div>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                ''
              )}
            </div>
          </div>

          {/* 预定咖啡提醒 */}
          <div>
            <div className="kb-row">
              <KBRadio
                className="kb-form-group kb-div-c48"
                inClassName="kb-form-group kb-div-66"
                groupStyle={{ width: '100%' }}
                divClassName="kb-form-group kb-div-33"
                rightLabel="label-rt"
                title="增加第三方合作小程序"
                arrayType={[
                  { value: true, title: '是' },
                  { value: false, title: '否' }
                ]}
                name="redirect_mini"
                field={redirect_mini}
              />
            </div>
            {(redirect_mini.value === true ||
              redirect_mini.value === 'true') && (
              <div>
                <div className="kb-row">
                  <KBInput
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    type="text"
                    title="微信注册appid"
                    field={appid}
                    isRequired
                  />
                </div>
                <div className="kb-row">
                  <KBInput
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    type="text"
                    title="小程序原始id"
                    field={mini_id}
                    isRequired
                  />
                </div>
                <div className="kb-row">
                  <KBInput
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    type="text"
                    title="小程序路径"
                    field={mini_path}
                    isRequired
                  />
                </div>
                <div className="kb-row">
                  <KBInput
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    type="text"
                    title="小程序名字"
                    field={mini_name}
                    isRequired
                  />
                </div>
                <div className="kb-row">
                  <KBInput
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    type="text"
                    title="小程序跳转标题"
                    field={mini_redirect_title}
                    isRequired
                  />
                </div>
                <div className="kb-row">
                  <KBInput
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    type="text"
                    title="小程序跳转文案"
                    field={mini_redirect_content}
                    isRequired
                  />
                </div>
                <div className="kb-row" style={{ textAlign: 'left' }}>
                  <KBImageCropperInput
                    title="小程序图片"
                    aspect={60 / 60}
                    className="kb-form-group kb-form-c18 kb-form-alone"
                    field={mini_qrcode_image_url}
                    type="activities"
                    imageSize={1024}
                    width={60}
                    height={60}
                    failureCallBack={this.UploadFailureCallBack}
                    accept=".png,.jpg"
                    isRequired
                  >
                    <div className="upload-portrait">
                      {mini_qrcode_image_url && mini_qrcode_image_url.value ? (
                        <img
                          src={toImageProxyDirect(
                            mini_qrcode_image_url.value,
                            60,
                            60
                          )}
                          style={{
                            width: '60px',
                            height: '60px',
                            display: 'inline-block',
                            marginLeft: 0,
                            borderRadius: 0,
                            objectFit: 'cover'
                          }}
                          alt=""
                        />
                      ) : (
                        ''
                      )}
                      <div
                        className="d-iblock"
                        style={{ verticalAlign: 'middle' }}
                      >
                        <button className="bordered-btn m-none" type="button">
                          {mini_qrcode_image_url.dirty
                            ? '图片已上传'
                            : '上传图片'}
                        </button>
                        <p
                          className="color-nine"
                          style={{ paddingTop: 20, fontSize: 12 }}
                        >
                          图片格式为png或jpg,文件大小不大于1M
                        </p>
                      </div>
                    </div>
                  </KBImageCropperInput>
                  {mini_qrcode_image_url.touched &&
                    mini_qrcode_image_url.error && (
                      <p className="lr-error">{mini_qrcode_image_url.error}</p>
                    )}
                </div>
              </div>
            )}
          </div>

          <KBRadio
            isRequired
            className="kb-form-group kb-form-c178 kb-form-alone"
            inClassName="kb-from-initial"
            divClassName="kb-from-radio"
            rightLabel="label-rt"
            title="预定成功后提醒用户进行访客申请"
            arrayType={[
              {title: '是', value: true},
              {title: '否', value: false},
            ]}
            name="visit_apply"
            field={visit_apply}
          />

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

//

function mapStateToProps(state, props) {
  const devices = getDevices(state)
  const user = getCurrentUser(state)
  const { meeting, loc_id } = props
  const location = getLocation(state, loc_id)
  let location_id = loc_id || (meeting && meeting.location_id)
  const location_groups_id = _.get(location, 'location_groups[0].id', '')
  let meetings = getMeetingRoomsOfLocation(state, location_id)
  const floors =
    meetings.filter(a => {
      return a.area_type === 'floor'
    }) || []
  let selectedFloors = (meeting && meeting.floors) || []
  var params = {
    location_groups_id,
    devices,
    user,
    floors,
    selectedFloors
  }

  if (!props.state) {
    // 新建时
    params.initialValues = {
      management_only: 'false',
      max_meanwhile_number: 1,
      self_setuped: 'unset',
      de_rate: '0',
      fake_order_num: '0',
      floor_color: '#00a0b0',
      visit_apply: false
    }
  } else if (meeting) {
    // 编辑时
    let self_setuped = false
    const { star_point_settings } = meeting
    if (star_point_settings) {
      self_setuped = star_point_settings.self_setuped
    }

    params.initialValues = {
      ...meeting,
      bridgego_visible: meeting.bridgego_visible ? 'yes' : 'no',
      management_only: meeting.management_only + '',
      device_ids: meeting.devices.map(json => json.id).join(','),
      merchant_id: (meeting.merchant || {}).id,
      invoicing_application_setting_id: (
        meeting.invoicing_application_setting || {}
      ).id,
      redirect_mini: meeting.redirect_mini || false,
      self_setuped: self_setuped ? 'custom' : 'unset',
      de_rate: star_point_settings.de_rate * 100 + '',
      ex_rate: star_point_settings.ex_rate,
      floor_color: meeting.floor_color || '#00a0b0',
      visit_apply: meeting.visit_apply || false
    }

    params.selectDevices = meeting.devices
    params.defaultImages = meeting.images
  }
  return params
}

function mapDispatchToProps(dispatch) {
  return {
    createMeetingAction: bindActionCreators(meetingsActions.create, dispatch),
    updateMeetingAction: bindActionCreators(meetingsActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入会议室名称'
  }

  if (values.listing_price && valid.isPrice(values.listing_price)) {
    errors.listing_price = '请输入正确的价格'
  }

  if (
    values.non_entering_listing_price &&
    valid.isPrice(values.non_entering_listing_price)
  ) {
    errors.non_entering_listing_price = '请输入正确的价格'
  }

  if (
    values.bridge_listing_price &&
    valid.isPrice(values.bridge_listing_price)
  ) {
    errors.bridge_listing_price = '请输入正确的价格'
  }

  if (valid.integer(values.capacity)) {
    errors.capacity = '输入人数必须为整数'
  }

  if(valid.required(values.fake_order_num)) {
    errors.fake_order_num = '请输入订单基数'
  } else if (valid.integer(values.fake_order_num)) {
    errors.fake_order_num = '订单基数必须为整数'
  } else if (values.fake_order_num < 0) {
    errors.fake_order_num = '订单基数不能为负数'
  }

  if (values.priority && valid.Int(values.priority)) {
    errors.priority = '排序只能输入数字'
  }

  // 第三方小程序字段
  if (values.redirect_mini === 'true') {
    if (valid.required(values.appid)) {
      errors.appid = '请输入微信注册appid'
    }
    if (valid.required(values.mini_id)) {
      errors.mini_id = '请输入小程序原始id'
    }
    if (valid.required(values.mini_path)) {
      errors.mini_path = '请输入小程序路径'
    }
    if (valid.required(values.mini_name)) {
      errors.mini_name = '请输入小程序名字'
    }
    if (valid.required(values.mini_redirect_title)) {
      errors.mini_redirect_title = '请输入小程序跳转标题'
    }
    if (valid.required(values.mini_redirect_content)) {
      errors.mini_redirect_content = '请输入小程序跳转文案'
    }
    if (valid.required(values.mini_qrcode_image_url)) {
      errors.mini_qrcode_image_url = '请上传小程序图片'
    }
  }

  // 选择独立的星积分时必填
  if (values.self_setuped === 'custom') {
    if (valid.required(values.ex_rate)) {
      errors.ex_rate = '请输入兑换比例'
    }
    if (valid.required(values.de_rate)) {
      errors.de_rate = '请输入抵扣比例'
    } else {
      const deRateNum = Number(values.de_rate)
      if (isNaN(deRateNum) || deRateNum < 0 || deRateNum > 100) {
        errors.de_rate = '兑换比例只能为0-100之间的数字'
      }
    }
  }

  if (valid.required(values.visit_apply)) {
    errors.visit_apply = '请选择预定成功后提醒用户进行访客申请'
  }

  return errors
}

const formConfig = {
  form: 'MeetingForm',
  fields: [
    'name',
    'area_type',
    'orientation',
    'priority',
    'listing_price',
    'non_entering_listing_price',
    'lock',
    'wall',
    'floor',
    'device_ids',
    'capacity',
    'management_only',
    'size',
    'images',
    'max_meanwhile_number',
    'floor_ids',
    'checkin_date',
    'features',
    'fake_order_num',
    'description',
    'day_listing_price',
    'non_entering_day_listing_price',
    'reserve_role',
    'order_option',
    'bridge_listing_price',
    'bridgego_visible',
    'merchant_id',
    'invoicing_application_setting_id',
    'xforceplus_tax_setting_id',
    // 第三方小程序字段
    'redirect_mini',
    'appid',
    'mini_id',
    'mini_path',
    'mini_qrcode_image_url',
    'mini_name',
    'mini_redirect_title',
    'mini_redirect_content',
    'self_setuped',
    'star_point_settings',
    'ex_rate',
    'de_rate',
    'floor_color',
    'visit_apply'
  ],
  validate: validate,
  touchOnBlur: false
}

MeetingForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(MeetingForm)

export default MeetingForm
