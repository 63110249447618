import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import { invoicesActions, successState, apiCallFailure } from 'app/actions'
import { api } from 'app/services'
import {
  paginate,
  arrayToObject,
  formatMinuteSecondEN,
  formatYearDayCHS
} from 'utils/kbUtil'
import { reduxForm } from 'redux-form'
import {
  KBDropdown,
  KBPagination,
  KBLoadingContainer,
  KBRangeDate,
  KBTableWithoutData
} from 'components'
import {
  INVOICES_STATE,
  INVOICES_TYPE,
  INVOICES_ORDER_TYPE,
  ORDER_STATE_ARRAY,
  INVOICE_ORDER_SALES_PAYMENTS,
  INVOICES_STATE_CLASS,
  INVOICE_ORDER_CERTIFICATE_PAYMENT_TYPES
} from 'app/constants'
import { queryFilter, getFilterAndQuery, kbCloseDropDown } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import { routerActions } from 'react-router-redux'
import moment from 'moment'
import {
  getUserInfoUrl,
  getUrlOfOrgDetailPage,
  getUrlOfLocationHomePage
} from 'utils/kbUrl'

const DROP_DOWNS = [
  'location_idDropdown',
  'dateDropDown',
  'statusDropdown',
  'typesDropdown'
]
const backgroundColorOfState = {
  unpaid: '#f2a73d',
  paid: '#73bb4b',
  cancelled: '#e1e1e1',
  refunding: '#dd5a55',
  refunded: '#0AC862'
}

var OrdersOrg = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      total: 0,
      paid: 0,
      invoices: [],
      filters,
      page: this.props.page || 1,
      loading: false,
      queryFiltersStr: '',
      fixedParams: {
        status: filters.status ? filters.status : ORDER_STATE_ARRAY[0].id,
        invoice_type: filters.invoice_type
          ? filters.invoice_type
          : INVOICES_ORDER_TYPE[0].id
      }
    }
  },
  componentDidMount() {
    const { params, org_id } = this.props
    let obj = {}

    this.mounted = true

    obj.location_id = params.id
    obj.customer_id = org_id
    obj.customer_type = 'org'

    this.setParams(obj)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(model, newPage) {
    const per_page = 10
    const { getInvoicesActions, routerActions, query, currentPath } = this.props
    const { page, filters, fixedParams } = this.state
    const newFixedParams = Object.assign({}, fixedParams, model)

    let params = Object.assign({}, filters, newFixedParams)
    let { from, to } = params
    if (from && to) {
      params.from = from && moment(from).format('YYYY-MM-DD')
      params.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete params.from
      delete params.to
    }

    let queryFiltersStr = queryFilter({
      ...query,
      ...params,
      page: newPage || page
    })
    this.setState({ loading: true })
    return api
      .getInvoices({
        ...newFixedParams,
        ...params,
        per_page,
        page: newPage || page,
        source_type: 'personal'
      })
      .then(
        ({ json, response }) => {
          const { invoices } = json
          let pagination = paginate(response)
          getInvoicesActions.success({
            entities: { invoices: arrayToObject(invoices) }
          })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(`${currentPath}${queryFiltersStr}`)
          if (this.mounted) {
            const { total, unpaid, paid } = json
            this.setState({
              total: total,
              invoices: invoices || [],
              unpaid: unpaid,
              paid: paid,
              pagination: pagination,
              queryFiltersStr
            })
          }
          return true
        },
        errors => {
          getInvoicesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      this._loadData({}, nextProps.page)
      return
    }
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, page: this.props.page })

    // force refresh
    this._refresh()
  },

  changeDate(range) {
    this._setFilter(range)
  },

  _refresh() {
    this['location_idDropdown'] && this['location_idDropdown'].hide()
    this['statusDropdown'] && this['statusDropdown'].hide()
    this['typesDropdown'] && this['typesDropdown'].hide()
    this['reviewedDropdown'] && this['reviewedDropdown'].hide()

    setTimeout(this._loadData)
  },

  _clearFilters() {
    this.setState({ filters: {}, page: 1 })
    // force refresh
    this._refresh(null, {})
  },

  fromToTextRender(from, to) {
    return from || to
      ? `${from ? formatYearDayCHS(from) : ''}${to ? '-' + formatYearDayCHS(to) : ''
      }`
      : '选择日期'
  },

  setParams(obj) {
    const { fixedParams } = this.state
    const newParams = Object.assign({}, fixedParams, obj)
    this.setState({
      fixedParams: newParams,
      page: 1
    })
    this._loadData(newParams)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  memberFilter() {
    const { from, to } = this.props.filters
    const { status, invoice_type } = this.state.fixedParams
    const iconStyle = { color: '#0a7ae6', paddingRight: '10px' }
    const spanStyle = { paddingRight: '20px' }

    return (
      <div>
        <div
          className="select-progress dc-mr-smail"
          style={{ marginRight: 0, marginBottom: 0 }}
        >
          <KBRangeDate
            style={{ float: 'left' }}
            format="YYYY/MM/DD"
            title="交易日期"
            spanStyle={spanStyle}
            iconStyle={iconStyle}
            leftNone={true}
            from={from || ''}
            to={to || ''}
            ref={ref => {
              this.dateDropDown = ref
            }}
            dropDown_name="dateDropDown"
            closeClick={this.closeDropDowns}
            callback={this.changeDate}
          />
          {
            <KBDropdown
              ref={ref => {
                this.typesDropdown = ref
              }}
              style={{ marginBottom: '10px', float: 'left' }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'typesDropdown')}
                >
                  <span className="span-pr-smail">
                    {this.state.filters.payment_mode
                      ? INVOICE_ORDER_SALES_PAYMENTS[
                      this.state.filters.payment_mode
                      ]
                      : '支付类型'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ payment_mode: '' })}>
                      全部
                    </li>
                    {INVOICE_ORDER_CERTIFICATE_PAYMENT_TYPES.map(
                      (state, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this._setFilter({ payment_mode: state.id })
                            }
                          >
                            <span>{state.name}</span>
                          </li>
                        )
                      }
                    )}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          }
        </div>
        <div style={{ marginBottom: 0, display: 'inline-block' }}>
          <div
            style={{
              display: 'inline-block',
              marginRight: 15,
              marginBottom: 10
            }}
            className="f-left"
          >
            {ORDER_STATE_ARRAY.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{ marginRight: 0, padding: '7px 9px' }}
                  className={`border-show_status ${status == data.id ? 'border-show_status_active' : ''
                    }`}
                  onClick={() => this.setParams({ status: data.id })}
                >
                  <span
                    className={`color-show`}
                    style={{
                      border: 0,
                      width: 16,
                      height: 16,
                      marginRight: 6,
                      backgroundColor: `${backgroundColorOfState[data.id]
                        ? backgroundColorOfState[data.id]
                        : status
                          ? '#2ea1f8'
                          : '#ffffff'
                        }`
                    }}
                  ></span>
                  <span className="status-text">{data.name}</span>
                </div>
              )
            })}
          </div>
          <div className="f-left">
            {INVOICES_ORDER_TYPE.map((json, index) => {
              const isCheckedType = json.id == invoice_type
              return (
                <div
                  key={index}
                  style={{ marginRight: 0, padding: '7px 13px' }}
                  className={`border-show_status ${isCheckedType ? 'border-show_status_active' : ''
                    }`}
                  onClick={() => this.setParams({ invoice_type: json.id })}
                >
                  <span className="status-text">{json.name}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  },

  toOrderInfo(invoice) {
    const { routerActions, loc_id } = this.props
    routerActions.push(
      `/admin/locations/${loc_id}/invoices/location_orders/${invoice.id}`
    )
  },

  disposeTotal(invoice) {
    const { payment_type, total_amount, total_credits, total_points } = invoice
    if (payment_type == 'point') {
      return total_points
    }
    if (payment_type == 'credits') {
      return total_credits
    }
    return total_amount
  },

  invoicesJsx() {
    const { invoices, pagination, loading, queryFiltersStr } = this.state
    const { exchangeUnit, currentPath, loc_id } = this.props
    delete this.state.filters.status
    delete this.state.filters.invoice_type
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter !== '')

    return (
      <div>
        <div className="nav-section-content-container" style={{ padding: 0 }}>
          <div>
            {this.memberFilter()}
            {selectFilters.length > 0 ? (
              <div
                className="clear-criteria"
                style={{ marginTop: 5 }}
                onClick={this._clearFilters}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : null}
          </div>
          <div style={{ float: 'left', width: '100%', position: 'relative' }}>
            <KBLoadingContainer loading={loading}>
              <table className="content-table table-align">
                <thead style={{ whiteSpace: 'nowrap' }}>
                  <tr>
                    <th>订单编号</th>
                    <th>应付金额</th>
                    <th style={{ width: 80 }}>收款状态</th>
                    <th>订单类型</th>
                    <th>所在分店</th>
                    <th>客户名称</th>
                    <th>交易时间</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={invoices.length > 0}
                  tableHeadNum="7"
                  tipMessage="暂无订单信息"
                >
                  {invoices &&
                    invoices.map((json, index) => {
                      let total = this.disposeTotal(json)
                      total = Math.round(total * 100) / 100
                      let isUser =
                        json.sales_customer &&
                        json.sales_customer.foreign_type === 'user'
                      let isOrg =
                        json.sales_customer &&
                        json.sales_customer.foreign_type === 'org'

                      return (
                        <tr key={index}>
                          <td style={{ verticalAlign: 'middle' }}>
                            <span
                              className="color-link a-hover"
                              onClick={e => {
                                e.stopPropagation()
                                this.toOrderInfo(json)
                              }}
                            >
                              {json.serial_number}
                            </span>
                            {
                              // json.sales_payments.length && json.payment_type !== 'point' ? (
                              //   <div style={{marginTop: 5}}>
                              //     <span className='span-title-smail'>流水号：</span>
                              //     { json.sales_payments.map((j, index) =>
                              //       <span key={ index } className='span-title-smail'>{ j.reference_number }{ index != (json.sales_payments.length - 1) ? ',' : '' }</span>
                              //       ) }
                              //   </div>
                              // ) : ''
                            }
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            {json.payment_type == 'money'
                              ? '¥ ' + total
                              : json.payment_type == 'point'
                                ? total + ' ' + exchangeUnit
                                : '¥ ' + total}{' '}
                            {json.payment_type ? (
                              <span
                                className="span-type-smail-i"
                                style={{ display: 'inline-block' }}
                              >
                                {
                                  INVOICE_ORDER_SALES_PAYMENTS[
                                  json.payment_type
                                  ]
                                }
                              </span>
                            ) : (
                                ''
                              )}
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <span
                              style={{ marginLeft: 0 }}
                              className={`order-default ${INVOICES_STATE_CLASS[json.status]
                                }`}
                            >
                              {INVOICES_STATE[json.status]}
                            </span>
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            {INVOICES_TYPE[json.invoice_type]}
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <Link
                              className="color-link"
                              to={getUrlOfLocationHomePage(loc_id)}
                            >
                              {json.location && json.location.name}
                            </Link>
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            <span
                              style={{ display: 'inline-block', marginTop: 5 }}
                            >
                              {isUser ? (
                                <i className="iconfont icon-account_circle" />
                              ) : isOrg ? (
                                <i className="iconfont icon-company2" />
                              ) : (
                                    ''
                                  )}
                              <span style={{ marginLeft: 5 }}>
                                <Link
                                  className={
                                    isUser || isOrg ? 'color-link' : ''
                                  }
                                  to={
                                    isUser
                                      ? getUserInfoUrl(
                                        json.sales_customer.foreign_id
                                      )
                                      : isOrg
                                        ? getUrlOfOrgDetailPage(
                                          loc_id,
                                          json.sales_customer.foreign_id
                                        )
                                        : ''
                                  }
                                >
                                  {json.sales_customer.name}
                                </Link>
                              </span>
                            </span>
                          </td>
                          <td style={{ verticalAlign: 'middle' }}>
                            {(json.created_at &&
                              formatMinuteSecondEN(json.created_at)) ||
                              ''}
                          </td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={pagination}
                template={`${currentPath}?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  },

  render() {
    return this.invoicesJsx()
  }
})

function mapStateToProps(state, props) {
  let page = parseInt(props.query && props.query.page)
  page = isNaN(page) ? 1 : page

  let loc_id = props.params.id
  let org_id = props.params.organization_id
  let currentPath = `/admin/locations/${loc_id}/organizations/${org_id}/orders`

  let locations = selectors.getSerializeLocationObj(state)
  var FILTER_STATE = ['from', 'to', 'invoice_type', 'payment_mode', 'status']

  var params = getFilterAndQuery(props.query, FILTER_STATE)

  let defaultInvoiceTypes = []
  if (params.filters && params.filters.invoice_type) {
    let invoice_types = params.filters.invoice_type.split(',')
    defaultInvoiceTypes = INVOICES_ORDER_TYPE.filter(data =>
      invoice_types.find(id => id == data.id)
    )
  }

  const spaceSetting = selectors.getSpaceSetting(state)
  const exchangeUnit =
    (spaceSetting &&
      spaceSetting.virtual_currency_settings &&
      spaceSetting.virtual_currency_settings.virtual_currency_name) ||
    '积分'

  return {
    page,
    loc_id,
    currentPath,
    defaultInvoiceTypes,
    locations,
    org_id,
    ...params,
    exchangeUnit
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.all, dispatch),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  return errors
}

const formConfig = {
  form: 'OrdersOrg',
  fields: ['status', 'from', 'to'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrdersOrg)
