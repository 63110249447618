import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { activitiesAction, successState, apiCallFailure } from 'app/actions'
import {
  toImageProxyDirect,
  formatHmN4TwoDate,
  formatYMDN4TwoDate
} from 'utils/kbUtil'
import { ACTIVITY_STATUS_OBJ, ACTIVITY_STATUS_TAG_OBJ } from 'app/constants'
import {
  KBLoadingContainer,
  KBStatisticNumber,
  KBAvatar,
  KBTipsy,
  KBPopover
} from 'components'
import * as selectors from 'app/selectors'
import moment from 'moment'
import AnnouncementForm from '../announcements/AnnouncementForm'
import { getUserInfoUrl } from 'utils/kbUrl'
import { getEditRole } from './constant'
import { accurateMath } from 'utils/math'

let ActivitiesInfo = React.createClass({
  displayName: 'ActivitiesInfo',

  getInitialState() {
    return {
      loading: true,
      signInCode: '',
      showSignInCode: false,
      activityDetailCode: '',
      details: {}
    }
  },
  componentDidMount() {
    const { activity_id, apiCallFailureAction } = this.props
    api
      .getReplacementInfo(activity_id)
      .then(
        ({ json }) => {
          this.setState({ details: json })
        },
        error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  toActivitiesList() {
    const { routerActions, loc_id } = this.props
    if (loc_id) {
      routerActions.push(`/admin/locations/${loc_id}/replacement`)
    } else {
      routerActions.push(`/admin/memberships/memberManage/replacement`)
    }
  },

  toEditActivity() {
    const { routerActions, loc_id } = this.props
    const { details: activity } = this.state
    const { id } = activity
    if (loc_id) {
      routerActions.push(
        `/admin/locations/${loc_id}/replacement/edit?act_id=${activity.id}`
      )
    } else {
      routerActions.push(
        `/admin/memberships/memberManage/replacement/edit?act_id=${activity.id}`
      )
    }
  },

  toPublish(model) {
    const {
      updateActivitiesAction,
      apiCallFailureAction,
      successActions
    } = this.props
    const { id } = model

    KBPopover.plugins.confirm('发布', '确定发布该权益吗？', {
      confirm: () => {
        api
          .setReplacementStatus(id, { status: 'active' })
          .then(
            ({ json }) => {
              successActions({ message: '发布成功！' })
              this.setState({ details: json })
            },
            error => {
              apiCallFailureAction({
                status: 'error',
                message: error.message
              })
            }
          )
          .finally(() => {
            KBPopover.close()
          })
      }
    })
  },

  deleteActivity(model) {
    const {
      apiCallFailureAction,
      routerActions,
      successActions,
      loc_id
    } = this.props
    const { id } = model
    KBPopover.plugins.confirm('删除权益', '确定删除该权益吗？', {
      confirm: () => {
        api.delReplacement(id).then(
          json => {
            successActions({ message: `成功删除权益:${model.name}` })
            KBPopover.close()
            routerActions.push(`/admin/locations/${loc_id}/replacement`)
          },
          error => {
            apiCallFailureAction({
              status: 'error',
              message: error.message
            })
          }
        )
      }
    })
  },

  toSingPeople() {
    const { routerActions, loc_id } = this.props
    const { details } = this.state
    routerActions.push(
      `/admin/locations/${loc_id}/replacement/${details.id}/orders`
    )
  },

  unDowmActivity() {
    const { apiCallFailureAction, successActions } = this.props
    const { on_sales } = this.state.details
    const title = on_sales ? '下架' : '上架'
    const content = on_sales ? '确定下架该权益吗？' : '确定上架该权益吗？'
    const msg = on_sales ? '下架成功！' : '上架成功！'
    KBPopover.plugins.confirm(title, content, {
      confirm: () => {
        api
          .setReplacementSales(this.state.details.id, { on_sales: !on_sales })
          .then(
            ({ json }) => {
              successActions({ message: msg })
              this.setState({ details: json })
            },
            error => {
              apiCallFailureAction({
                status: 'error',
                message: error.message
              })
            }
          )
          .finally(() => {
            KBPopover.close()
          })
      }
    })
  },

  cloneData() {
    const { routerActions, loc_id } = this.props
    const { details } = this.state
    const clonedReplacementData = {
      ...details
    }
    const needRemoveFileds = [
      'creator',
      'has_invoice',
      'id',
      'on_sales',
      'sold_number',
      'status'
    ]
    needRemoveFileds.forEach(key => {
      delete clonedReplacementData[key]
    })
    sessionStorage.clonedReplacementData = JSON.stringify(clonedReplacementData)
    routerActions.push(`/admin/locations/${loc_id}/replacement/edit?isClone=1`)
  },

  render() {
    const { details: activity, loading } = this.state
    const { hasEditRole } = this.props
    const {
      cover,
      name,
      start_at,
      end_at,
      tags,
      creator,
      location,
      details,
      activity_guests,
      contacts_phone,
      status,
      status_tag,
      areas,
      on_sales,
      description,
      has_invoice
    } = activity

    return (
      <div className="nav-section-container f-right">
        <KBLoadingContainer loading={loading}>
          <header className="nav-section-header clear-fix border-bottom">
            <div className="nav-section-header-title f-left">
              <span
                className="color-nine c-pointer a-hover"
                onClick={this.toActivitiesList}
              >
                权益
              </span>
              <i
                className="iconfont icon-navigate_next color-nine"
                style={{ marginLeft: 15, marginRight: 15 }}
              />
              <span>{name}</span>
            </div>
            {hasEditRole && (
              <div className="f-right">
                <span
                  className="bordered-btn-end f-right"
                  onClick={this.deleteActivity.bind(this, activity)}
                >
                  删除
                </span>
                <span
                  className="bordered-btn f-right"
                  onClick={this.toEditActivity}
                >
                  编辑
                </span>
                <span className="bordered-btn f-right" onClick={this.cloneData}>
                  克隆
                </span>
                {status && status !== 'draft' && (
                  <dev
                    className="bordered-btn f-right"
                    onClick={this.unDowmActivity}
                  >
                    {on_sales ? '下架' : '上架'}
                  </dev>
                )}
                {status && status === 'draft' && (
                  <span
                    className="bordered-btn f-right"
                    onClick={this.toPublish.bind(this, activity)}
                  >
                    发布
                  </span>
                )}
              </div>
            )}
          </header>
          <div className="activity-chart">
            <div className="chart-box">
              <KBStatisticNumber
                number={activity.quantity || 0}
                name="总数量"
                unit="张"
              />
            </div>
            <div className="chart-box a-hover" onClick={this.toSingPeople}>
              <a>
                <KBStatisticNumber
                  number={activity.sold_number || 0}
                  name="已售"
                  unit="张"
                />
              </a>
            </div>
          </div>
          {activity && Object.keys(activity).length > 0 ? (
            <div style={{ padding: 30 }}>
              <div className="activity-main">
                <div className="act_main_img">
                  <img
                    src={toImageProxyDirect(cover, 200, 200)}
                    alt=""
                    style={{ marginBottom: 20, width: 200 }}
                  />
                </div>
                <div className="activity-content">
                  <div style={{ position: 'relative' }}></div>
                  {/*<div className="activity-title" style={ {color: '#404040', cursor: 'default'} }>{ title }</div>*/}
                  <span
                    className={`activity-status activity-status_${
                      // status === 'active' ? status_tag : status
                      status === 'active' ? status : status
                    }`}
                    style={{ display: 'inline-block', marginBottom: '15px' }}
                  >
                    {/* {status === 'active'
                      ? ACTIVITY_STATUS_TAG_OBJ[status_tag]
                      : ACTIVITY_STATUS_OBJ[status]} */}
                    {ACTIVITY_STATUS_OBJ[status]}
                  </span>
                  {tags && tags.length > 0 && (
                    <div className="activity-space_between">
                      <i
                        className="iconfont icon-tag"
                        style={{ marginRight: 10 }}
                      />
                      {tags.map((tag, index) => {
                        return <span className="act-info-tag">{tag}</span>
                      })}
                    </div>
                  )}
                  <div className="activity-space_between">
                    <i
                      className="iconfont icon-time"
                      style={{ marginRight: 10 }}
                    />
                    {formatYMDN4TwoDate(start_at, end_at)}
                    <span style={{ marginLeft: 10 }}>
                      {formatHmN4TwoDate(start_at, end_at)}
                    </span>
                  </div>
                  <div
                    className="activity-space_between"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <i
                      className="iconfont icon-account_circle"
                      style={{ marginRight: 10 }}
                    />
                    <span style={{ marginRight: 10 }}>创建人:</span>
                    <KBAvatar
                      style={{ display: 'inline-block', marginRight: '5px' }}
                      imgStyle={{ marginRight: 10 }}
                      user={creator}
                      size={20}
                    />
                    <Link
                      className="color-link"
                      to={getUserInfoUrl(creator.id)}
                    >
                      {creator.name}
                    </Link>
                    <i
                      className="iconfont icon-icon_phone"
                      style={{ marginRight: 10, marginLeft: 20 }}
                    />
                    <span>{contacts_phone || '无'}</span>
                  </div>
                  <div className="activity-space_between">
                    <i
                      className="iconfont icon-position"
                      style={{ marginRight: 10 }}
                    />
                    {areas.map(i => i.name).join()}
                  </div>
                  {activity.can_reserve && (
                    <div className="activity-tickets-list">
                      <div>
                        <i
                          className="iconfont icon-money"
                          style={{ marginRight: 10, marginBottom: 3 }}
                        />
                      </div>
                      <div style={{ display: 'inline-block' }}>
                        <div className="activity-ticket">
                          <span>
                            {activity.min_price == 0
                              ? '免费'
                              : `${accurateMath.mul(activity.min_price, 100)} 积分`}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {activity_guests && activity_guests.length > 0 ? (
                <div
                  className="activity-guests clear-fix"
                  style={{ marginTop: 30 }}
                >
                  <div className="kb-title">
                    <span>嘉宾</span>
                  </div>
                  <div>
                    {activity_guests.map(guest => {
                      return (
                        <div
                          className="guest-card guest-box"
                          style={{ marginLeft: 0, marginBottom: 0 }}
                        >
                          <div>
                            <KBAvatar
                              style={{
                                display: 'inline-block',
                                marginRight: '5px'
                              }}
                              imgStyle={{ marginRight: 10 }}
                              user={guest}
                              size={50}
                            />
                          </div>
                          <div className="guest-message">
                            <span>{guest.name}</span>
                            <span>{guest.title}</span>
                            {/* <span>{ guest.brief }</span> */}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}
              <div
                className="activity-introduce clear-fix"
                style={{ marginTop: 30 }}
              >
                <div className="kb-title">
                  <span>权益介绍</span>
                </div>
                <div className="m-top-sm">
                  <div className="news-content" />
                  {description}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let loc_id = props.params.id
  let activity_id = props.params.activity_id
  let activity = selectors.getActivitiesInfo(state, activity_id)
  const hasEditRole = getEditRole(state.user, loc_id)

  return {
    loc_id,
    activity_id,
    activity,
    hasEditRole
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    updateActivitiesAction: bindActionCreators(
      activitiesAction.update,
      dispatch
    ),
    deleteActivitiesAction: bindActionCreators(
      activitiesAction.delete,
      dispatch
    )
  }
}

ActivitiesInfo = connect(mapStateToProps, mapDispatchToProps)(ActivitiesInfo)

export default ActivitiesInfo
