import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { orgNoteActions } from 'app/actions'
import {
  KBLoadingContainer,
  KBFormError,
  KBButton,
  KBTextarea,
  KBAvatar,
  KBTableWithoutData,
  KBPopover
} from 'components'
import OrgNoteList from './OrgNoteList'
import * as selectors from 'app/selectors'
import { getEntitiesJsonArray, formatYearDaySOL } from 'utils/kbUtil'
import { canAccessLocation } from 'app/reducers/role'
import {
  INVOICES_STATE_CLASS,
  INVOICES_STATE,
  ORG_SUBSCRIPTION_STATES_CLASS_NEW,
  ORG_SUBSCRIPTION_STATES,
  SUBSCRIPTION_TYPES_OBJ
} from 'app/constants'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import {
  canAccessLocationExceptObserver,
  canAccessSpaceRoles,
  getLSRoles,
  canAdminSpace,
  canAccessLocationRoles
} from 'app/reducers/role'
import _ from 'lodash'
import { getUserInfoUrl } from 'utils/kbUrl'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy';

const isInvoiceType = {
  special: '增值税专用发票',
  common: '增值税普通发票'
}

var OrgSummarize = React.createClass({
  displayName: 'OrgSummarize',

  getInitialState() {
    return {
      loadingNotes: true,
      notes: [],
      loading: false,
      invoiceTitles: []
    }
  },

  componentDidMount() {
    let { getOrganizationNotesAction, location, org } = this.props
    this.mounted = true
    this.setState({ loading: true })
    api
      .getOrganizationNotes(location.id, org.id)
      .then(
        json => {
          getOrganizationNotesAction.success(json)
          let notes = getEntitiesJsonArray(json, 'organizationNotes')
          if (this.mounted) {
            this.setState({ notes: notes || [] })
          }
        },
        errors => {
          getOrganizationNotesAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loadingNotes: false, loading: false })
      })

    api
      .getInvoiceTitles({
        location_id: location.id,
        sales_customer_id: org.sales_customer.id
      })
      .then(({ json }) => {
        this.setState({
          invoiceTitles: json
        })
      })
  },

  componentWillUnmount() {
    this.mounted = false
  },

  createOrganizationNote(model) {
    let { location, org, resetForm } = this.props
    return api
      .createOrganizationNote(location.id, org.id, {
        ...model,
        source: 'interview'
      })
      .then(json => {
        this.props.createOrgNoteAction.success(json)
        let notes = getEntitiesJsonArray(json, 'organizationNotes')

        this.setState({ notes: [notes, ...this.state.notes] })
        resetForm()
      })
  },

  linkSubscriptionsDetail(m) {
    const { routerActions } = this.props
    const { location, org } = this.props

    routerActions.push(
      `/admin/locations/${location.id}/organizations/${org.id}/subscriptions/${m.id}`
    )
  },

  toRenewContract(subscription, e) {
    e.stopPropagation()
    window.event.cancelBubble = true
    e.nativeEvent.stopImmediatePropagation()
    const { routerActions, org_id, loc_id } = this.props
    let location_id = loc_id
    let organization_id = org_id
    const { id } = subscription
    KBPopover.plugins.confirm(
      '续约',
      '新合同即将copy合同工位信息并重置起止日期、签订日期、缴租阶段，确定要续约吗？',
      {
        confirm: () => {
          KBPopover.close()
          routerActions.push(
            `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=extend`
          )
        }
      }
    )
  },
  openAudit(subscription, e) {
    e.stopPropagation()
    window.event.cancelBubble = true
    e.nativeEvent.stopImmediatePropagation()
    const { routerActions, org_id, loc_id } = this.props
    let location_id = loc_id
    let organization_id = org_id
    const { id } = subscription
    let url ;
    if(subscription.subscription_type === 'no_sales_area'){
      url=`/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract=无面积销售&contract_id=${id}&status=audit`
    }else{
      url=`/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=audit`
    }
    routerActions.push(
      url
    )
  },

  editOrg(step) {
    const { routerActions, loc_id, org_id } = this.props
    routerActions.push(
      `/admin/locations/${loc_id}/new_organization?type=update&org_id=${org_id}&step=${step}&wizard=1`
    )
  },

  render() {
    const {
      org,
      org_id,
      loc_id,
      leaders,
      isManager,
      connect,
      user,
      isOnlyContract,
      isApproveContract,
      isSpaceAdmin,
      isLocationAdmin
    } = this.props
    const {
      contacts_name,
      contacts_email,
      contacts_phone,
      business_license_number,
      contacts_idcard_type,
      contacts_id_number,
      sales_subscriptions,
      invoice_type,
      open_bank,
      auto_invoice,
      bank_account,
      invoice_header,
      tax_number,
      business_license_positive_url,
      business_license_negative_url,
      opportunities,
      areas,
      invoice_address,
      invoice_phone,
      invoice_content,
      face_plus_settings
    } = org
    const {
      fields: { content },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { notes, invoiceTitles } = this.state
    const deskRate = (
      (parseFloat(org.assigned_desks_count) / parseFloat(org.desks_count)) *
      100
    ).toFixed(0)
    const stateType = ORG_SUBSCRIPTION_STATES
    const stateType_class = ORG_SUBSCRIPTION_STATES_CLASS_NEW
    const isOCRM = org && org.enter_type === 'longterm'

    const noneStyle = {
      color: '#4c4c4c',
      opacity: 0.8,
      paddingTop: '25px',
      textAlign: 'center',
      fontSize: '16px'
    }
    return (
      <KBLoadingContainer loading={this.state.loading}>
        <div>
          <div className="nav-content-1 f-left">
            {areas && areas.length ? (
              <div>
                <header className="nav-inner-header clear-fix">
                  <div className="org_summary_header f-left">所在分区</div>
                </header>
                <div style={{ marginTop: '30px' }}>
                  {areas.map((area, index) => {
                    const { name, area_type, contract } = area
                    return (
                      <Link
                        to={`/admin/locations/${area.location_id}/areas/desks/list?area_id=${area.id}`}
                        key={index}
                      >
                        <div className="areas_new">
                          <div className="area_names">
                            {name}（
                            {area_type == 'public_office'
                              ? '开放'
                              : area_type == 'private_office'
                              ? '封闭'
                              : '灵活'}
                            ）
                          </div>
                          <div className="area_details">
                            <span>
                              {contract
                                ? `${formatYearDaySOL(
                                    area.contract.start_date
                                  )} - ${formatYearDaySOL(
                                    area.contract.end_date
                                  )}`
                                : ''}
                            </span>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            ) : (
              ''
            )}
            {isOCRM ? null : (
              <div className="org_summary_pad">
                <header className="nav-inner-header clear-fix">
                  <span className="org_summary_header f-left">合同</span>
                  <Link
                    to={`/admin/locations/${loc_id}/organizations/${org_id}/contracts`}
                    style={{ position: 'relative' }}
                    className="a-hover"
                  >
                    <span className="c-btn-secondary f-right">更多</span>
                  </Link>
                </header>
                <table
                  style={{ width: '100%' }}
                  className="content-table edit-table"
                >
                  <thead>
                    <tr>
                      <th>合同编号</th>
                      <th>状态</th>
                      <th>日期</th>
                      <th>已入驻人数</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {sales_subscriptions && sales_subscriptions.length > 0 ? (
                      sales_subscriptions.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            onClick={this.linkSubscriptionsDetail.bind(
                              null,
                              item
                            )}
                          >
                            <td className="color-link">
                              {item.serial}
                              <span className="span-type-smail-i m-left-sm">
                                {SUBSCRIPTION_TYPES_OBJ[
                                  item.subscription_type
                                ] || ''}
                              </span>
                            </td>
                            <td>
                              {
                                <span
                                  style={{ marginLeft: 0 }}
                                  className={`finance-default ${
                                    stateType_class[item.status]
                                  }`}
                                >
                                  {stateType[item.status]}
                                </span>
                              }
                            </td>
                            <td style={{ fontSize: '14px' }}>
                              {formatYearDaySOL(item.start_date)} -{' '}
                              {formatYearDaySOL(item.end_date)}
                            </td>
                            <td>{item.assigned_units}</td>
                            {connect &&
                            canAccessLocationExceptObserver(user) &&
                            !isOnlyContract &&
                            (item.status == 'active' ||
                              item.status == 'expiring') ? (
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  className="contract_continue"
                                  onClick={this.toRenewContract.bind(
                                    null,
                                    item
                                  )}
                                >
                                  合同续约
                                </span>
                              </td>
                            ) : (connect &&
                                item &&
                                (item.status == 'approving' ||
                                  item.status == 'rejected') &&
                                isApproveContract) ||
                              (connect &&
                                item &&
                                item.status != 'active' &&
                                item.status != 'approving' &&
                                item.status != 'rejected' &&
                                item.status != 'expiring' &&
                                item.status != 'ended' &&
                                isLocationAdmin) ? (
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  className="contract_approve"
                                  onClick={this.openAudit.bind(null, item)}
                                >
                                  进入合同流程
                                </span>
                              </td>
                            ) : (
                              <td />
                            )}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan="5"
                          style={{ background: '#fff', cursor: 'default' }}
                        >
                          <div
                            style={{
                              textAlign: 'center',
                              paddingTop: '20px',
                              paddingBottom: 20
                            }}
                          >
                            <div style={noneStyle}>暂无合同</div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}

            <div className="org_summary_pad">
              <header className="nav-inner-header clear-fix">
                <div className="org_summary_header f-left">公司负责人</div>
              </header>
              {leaders && leaders.length ? (
                <div>
                  {leaders.map((user, index) => {
                    return (
                      <div key={user.id} className="team_hover org_wrapper_box">
                        {isManager ? (
                          <div
                            className="kb-form-container"
                            style={{ paddingBottom: 0 }}
                          >
                            <div className="kb-row kb-div-66">
                              <div className="kb-form-group kb-div-c39">
                                <label>负责人</label>
                                <div className="group-content">
                                  <span>
                                    <Link
                                      to={getUserInfoUrl(user.id)}
                                      className="color-link"
                                    >
                                      {user.name}
                                    </Link>
                                  </span>
                                </div>
                              </div>
                              <div className="kb-form-group kb-div-173">
                                <label className="label-rt">手机号</label>
                                <div className="group-content">
                                  <span>{user.phone_num}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <a>
                            <div className="desk-user-img-2">
                              <KBAvatar user={user} size={30} />
                            </div>
                            <div className="desk-user-message-2">
                              <div className="desk-user-name-2">
                                <span>{user.name}</span>
                                <span>{user.title || ' '}</span>
                              </div>
                            </div>
                          </a>
                        )}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className="org_wrapper_box">
                  <div
                    className="kb-form-container"
                    style={{ paddingBottom: 0 }}
                  >
                    <div className="kb-row kb-div-66">
                      <div className="kb-form-group kb-div-c39">
                        <label>负责人</label>
                        <div className="group-content">
                          <span>{contacts_name}</span>
                        </div>
                      </div>
                      <div className="kb-form-group kb-div-173">
                        <label className="label-rt">手机号</label>
                        <div className="group-content">
                          <span>{contacts_phone}</span>
                        </div>
                      </div>
                    </div>
                    <div className="kb-row kb-div-66">
                      <div className="kb-form-group kb-div-c39">
                        <label>证件类型</label>
                        <div className="group-content">
                          <span>
                            {contacts_idcard_type
                              ? contacts_idcard_type === 'passport'
                                ? '护照'
                                : '身份证'
                              : '-'}
                          </span>
                        </div>
                      </div>
                      <div className="kb-form-group kb-div-173">
                        <label className="label-rt">证件号</label>
                        <div className="group-content">
                          <span>
                            {contacts_id_number ? contacts_id_number : '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="kb-row kb-div-66">
                      <div className="kb-form-group kb-div-c39">
                        <label>邮箱</label>
                        <div className="group-content">
                          <span>{contacts_email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isOCRM ? null : (
              <div className="org_summary_pad">
                <header className="nav-inner-header clear-fix">
                  <div className="org_summary_header f-left">客户信息</div>
                </header>
                {opportunities && opportunities.length > 0 ? (
                  opportunities.map((user, index) => {
                    user.name = user.title
                    return (
                      <div key={user.id} className="org_wrapper_box">
                        <div
                          className="kb-form-container"
                          style={{ paddingBottom: 0 }}
                        >
                          <div className="kb-row kb-div-66">
                            <div className="kb-form-group kb-div-c39">
                              <label>客户名称</label>
                              <div className="group-content">
                                <Link
                                  to={`/admin/crm/opportunities/${user.id}`}
                                  className="color-link"
                                >
                                  <span>{user.title || '-'}</span>
                                </Link>
                              </div>
                            </div>
                            {user.sales_leaders && user.sales_leaders.length ? (
                              <div className="kb-form-group kb-div-173">
                                <label className="label-rt">执行人</label>
                                <div className="group-content">
                                  <Link
                                    to={getUserInfoUrl(
                                      user.sales_leaders[0].id
                                    )}
                                    className="color-link"
                                  >
                                    <span>{user.sales_leaders[0].name}</span>
                                  </Link>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                    <div style={noneStyle}>暂无客户信息</div>
                  </div>
                )}
              </div>
            )}

            <div className="org_summary_pad">
              <header className="nav-inner-header clear-fix">
                <div className="org_summary_header f-left">公司介绍</div>
              </header>
              {org && org.description ? (
                <div className="nav-body">
                  <p style={{ paddingLeft: 30 }}>{org.description}</p>
                </div>
              ) : (
                <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                  <div style={noneStyle}>暂无公司介绍</div>
                </div>
              )}
            </div>

            {isOCRM ? null : (
              <div className="org_summary_pad">
                <header className="nav-inner-header clear-fix">
                  <div className="org_summary_header f-left">公司资料</div>
                  {isManager ? (
                    <KBButton
                      className="bordered-btn f-right"
                      onClick={() => {
                        this.editOrg(5)
                      }}
                    >
                      编辑
                    </KBButton>
                  ) : null}
                </header>
                <div className="kb-form-container" style={{ paddingBottom: 0 }}>
                  <div className="kb-row kb-div-66">
                    <div className="kb-form-group kb-div-c39">
                      <label>营业执照号</label>
                      <div className="group-content">
                        <span>
                          {business_license_number
                            ? business_license_number
                            : '-'}
                        </span>
                      </div>
                    </div>
                    <div className="kb-form-group kb-div-173">
                      <label className="label-rt">营业执照正面</label>
                      <div className="group-content">
                        <span>
                          {business_license_positive_url ? (
                            <KBDownloadPrivacy url={business_license_positive_url}>
                              <a
                                className="color-link"
                              >
                                点击下载
                              </a>
                            </KBDownloadPrivacy>
                          ) : (
                            '-'
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="kb-row kb-div-66">
                    <div className="kb-form-group kb-div-c39">
                      <label>身份证扫描件</label>
                      <div className="group-content">
                        <span>
                          {business_license_negative_url ? (
                            <KBDownloadPrivacy url={business_license_negative_url}>
                              <a
                                className="color-link"
                              >
                                点击下载
                              </a>
                            </KBDownloadPrivacy>
                          ) : (
                            '-'
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isOCRM ? null : (
              <div className="org_summary_pad">
                <header className="nav-inner-header clear-fix">
                  <div className="org_summary_header f-left">发票信息</div>
                  {isManager ? (
                    <KBButton
                      className="bordered-btn f-right"
                      onClick={() => {
                        this.editOrg(3)
                      }}
                    >
                      编辑
                    </KBButton>
                  ) : null}
                </header>
                <div
                  className="kb-form-container"
                  style={{ paddingBottom: 20 }}
                >
                  <div className="kb-row kb-div-66">
                    <div className="kb-form-group kb-div-c39">
                      <label>发票类型</label>
                      <div className="group-content">
                        <span>{isInvoiceType[invoice_type] || '-'}</span>
                      </div>
                    </div>
                    {/* <div className='kb-form-group kb-div-173'>
										<label className='label-rt'>自动开票</label>
										<div className='group-content'>
											<span>{ auto_invoice ? '是' : '否' }</span>
										</div>
									</div> */}
                  </div>
                </div>
                <div style={{ paddingLeft: 24 }}>
                  {invoiceTitles.length > 0 &&
                    invoiceTitles.map((title, index) => {
                      return (
                        <div
                          key={index}
                          className="location-provider"
                          style={{ marginBottom: 20 }}
                        >
                          <div className="provider-line">
                            <div>
                              <span>发票抬头</span>
                              <span>{title.title || '暂无'}</span>
                            </div>
                            <div>
                              <span>税号</span>
                              <span>{title.tax_num || '暂无'}</span>
                            </div>
                          </div>
                          <div className="provider-line">
                            <div>
                              <span>邮箱地址</span>
                              <span>{title.email || '暂无'}</span>
                            </div>
                            <div>
                              <span>开户银行</span>
                              <span>{title.bank || '暂无'}</span>
                            </div>
                          </div>
                          <div className="provider-line">
                            <div>
                              <span>银行账号</span>
                              <span>{title.bank_account || '暂无'}</span>
                            </div>
                            <div>
                              <span>注册电话</span>
                              <span>{title.phone_num || '暂无'}</span>
                            </div>
                          </div>
                          <div className="provider-line">
                            <div>
                              <span>注册地址</span>
                              <span>{title.address || '暂无'}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            )}

            {/* {
						!(isSpaceAdmin) ? null :
						<div className="org_summary_pad">
							<header className="nav-inner-header clear-fix">
								<div className="org_summary_header f-left">Face++ 设置信息</div>
							</header>
							<div className="kb-form-container" style={{ paddingBottom: 0 }} >
								<div className="kb-row kb-div-66" >
									<div className='kb-form-group kb-div-c39'>
										<label>User Name</label>
										<div className='group-content'>
											<span>{ face_plus_settings.username || '-' }</span>
										</div>
									</div>
									<div className='kb-form-group kb-div-173'>
										<label className='label-rt'>Password</label>
										<div className='group-content'>
											<span>{ face_plus_settings.password || '-' }</span>
										</div>
									</div>
								</div>
								<div className="kb-row kb-div-66" >
									<div className='kb-form-group kb-div-c39'>
										<label>URL</label>
										<div className='group-content'>
											<span>{ face_plus_settings.base_url || '-' }</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					} */}
          </div>

          <div className="nav-content-2 f-right">
            <header className="nav-inner-header clear-fix">
              <div className="org_summary_header f-left">公司历史</div>
            </header>
            <div style={{ paddingTop: '30px' }}>
              <KBLoadingContainer loading={this.state.loadingNotes}>
                <OrgNoteList
                  notes={notes}
                  org_id={org_id}
                  location_id={loc_id}
                  style={{ paddingTop: '15px' }}
                />
              </KBLoadingContainer>
            </div>
          </div>
        </div>
      </KBLoadingContainer>
    )
  }
})

const validate = values => {
  const errors = {}

  if (valid.required(values.content)) {
    errors.content = '请输入公司访谈记录'
  }
  return errors
}

const formConfig = {
  form: 'OrganizationNotesForm',
  fields: ['content'],
  touchOnBlur: false,
  validate: validate
}

function mapStateToProps(state, props) {
  const { user } = state
  let { org } = props
  let org_id = props.org_id
  let loc_id = props.location.id
  let leaders = selectors.getLeadersOfOrg(state, org_id)
  let userRoles = getLSRoles(user)
  let notes = selectors.getOrganizationNotes(state)
  let isManager = canAccessLocation(user, loc_id)
  let connect = org && org.connect
  let operateContract = canAccessSpaceRoles(user, ['admin', 'invoices'])
  let isApproveContract = canAccessSpaceRoles(user, [
    // 'admin',
    'contract',
    'location_contract'
  ])
  const isLocationAdmin = canAccessLocationRoles(user, loc_id, [
    'location_manager',
    'location_operator'
  ])
  let isOnlyContract =
    _.intersection(userRoles, ['contract']).length == 1 && !operateContract
      ? true
      : false
  const isSpaceAdmin = canAdminSpace(user)

  return {
    notes,
    loc_id,
    leaders,
    isManager,
    connect,
    org,
    user,
    isOnlyContract,
    isApproveContract,
    isSpaceAdmin,
    isLocationAdmin
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createOrgNoteAction: bindActionCreators(orgNoteActions.create, dispatch),
    getOrganizationNotesAction: bindActionCreators(
      orgNoteActions.all,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgSummarize)
