import React from 'react'
import * as selectors from 'app/selectors'
import * as valid from 'utils/validate'
import { reduxForm } from 'redux-form'
import {
  KBInput,
  KBPopover,
  KBTextarea,
  KBButton,
  KBMorePhotoUpload
} from 'components'

var OfflineRefundForm = React.createClass({

  refundOrder(model) {
    const { callBack } = this.props
    callBack && callBack({
      ...model,
      nwf_attachments: [model.pdfFile],
      pdfFile: undefined,
      refund: true
    })
    KBPopover.close()
  },

  render() {
    const {
      fields: { nwf_number, note, pdfFile },
      submitting,
      handleSubmit,
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.refundOrder)}>
        <div className="kb-form-header">
          <h2 className="header-title">线下退款</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-row" style={{ textAlign: 'left' }}>
            <KBMorePhotoUpload
              uploadedText="点击上传"
              photoShow={false}
              sort={false}
              className="kb-form-group kb-form-alone"
              field={pdfFile}
              text="点击上传"
              accept=".pdf"
              title="上传审批单（PDF文件）"
              isRequired={true}
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBInput
              className="kb-form-group kb-div-c39"
              isRequired={true}
              field={nwf_number}
              title="运营单号"
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              isRequired={true}
              field={note}
              title="备注"
            />
          </div>
          <div className="kb-row">
            <div className="kb-form-fr">
              <span
                className="cancel-btn"
                onClick={KBPopover.close}
                style={{ marginRight: '20px' }}
              >
                取消
              </span>
              <KBButton
                className="certain-btn kb-from-cmr"
                type="submit"
                submitting={submitting}
              >
                确定
              </KBButton>
            </div>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {
    // initialValues:
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}
  if (valid.required(values.pdfFile)) {
    errors.pdfFile = '请上传审批单'
  }

  if (valid.required(values.nwf_number)) {
    errors.nwf_number = '请输入运营单号'
  }

  if (valid.required(values.note)) {
    errors.note = '请输入备注'
  }
  return errors
}

const formConfig = {
  form: 'MemberLocationRolesForm',
  fields: ['nwf_number', 'note', 'pdfFile'],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OfflineRefundForm)
