import React from 'react'
import { KBSelect } from 'components'
import { api } from 'app/services'

const SelectInvoiceAccount = React.createClass({
  getInitialState() {
    return {
      list: []
    }
  },

  componentDidMount() {
    this.getInvoicesList()
  },

  getInvoicesList() {
    // 获取列表信息
    const { locationId } = this.props
    api.getMerchants({ location_id: locationId }).then(res => {
      const { json = [] } = res
      this.setState({ list: json })
    })
  },

  render() {
    const { field } = this.props
    const { list } = this.state
    return (
      <div className="kb-row">
        <KBSelect
          defaultValue="-1"
          className="kb-form-group kb-form-alone"
          field={field}
          title="汇付账号"
          isRequired
          arrayData={list}
        />
      </div>
    )
  }
})

export default SelectInvoiceAccount
