import React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { KBButton } from 'components'

var OrgCreateSuccess = React.createClass({
  continueAddOrg() {
    const { saveParams } = this.props
    saveParams && saveParams(null, 1)
  },

  toOrgInfo() {
    const { org, routerActions, location_id } = this.props
    routerActions.replace(
      `/admin/locations/${location_id}/organizations/${org.id}`
    )
  },

  render() {
    return (
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">完成添加</h2>
        </header>
        <div
          className="flex-center"
          style={{ paddingTop: 115, paddingBottom: 150 }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/createOrgSuccess.png"
              style={{ width: 110 }}
            />
            <span
              style={{
                marginTop: 25,
                marginBottom: 80,
                color: '#354052',
                fontSize: 16
              }}
            >
              添加成功
            </span>
            <div>
              <KBButton className="certain-btn" onClick={this.toOrgInfo}>
                查看团队详情
              </KBButton>
              <KBButton
                className="certain-btn"
                style={{ marginLeft: 20 }}
                onClick={() => {
                  this.continueAddOrg()
                }}
              >
                继续添加团队
              </KBButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

OrgCreateSuccess = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgCreateSuccess)

export default OrgCreateSuccess
