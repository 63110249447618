import React, { Component, PropTypes } from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import { KBFormError, KBButton, KBPopover, KBTextarea } from 'components'
import { subscriptionActions, successState } from 'app/actions'

var SubscriptioApprovalRejectForm = React.createClass({
  disapproveSubscription(model) {
    const {
      successAction,
      updateSubscriptionActions,
      callback,
      note,
      nextCallBack,
      id,
      previousCallBack
    } = this.props

    return api
      .updateSubScriptionApprove(id, {
        pass: false,
        step: 'auditFailure',
        reason: model.reason
      })
      .then(
        json => {
          successAction({ message: '合同审批不通过操作成功' })
          updateSubscriptionActions.success(json)
          let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
          previousCallBack && previousCallBack(subscription)
          KBPopover.close()
        },
        errors => {
          updateSubscriptionActions.failure(errors)
        }
      )
  },

  render() {
    const {
      fields: { reason },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.disapproveSubscription)}>
        <header className="kb-form-header">
          <h2 className="header-title">审批不通过</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={error} />
        <div className="kb-form-container">
          <div className="kb-row">
            <KBTextarea
              className="kb-form-group kb-form-210 kb-form-alone"
              title="不通过原因"
              isRequired={true}
              field={reason}
            />
          </div>
          <div className="f-right">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn m-left-sm"
              type="submit"
              submitting={submitting}
            >
              确认
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubscriptionActions: bindActionCreators(
      subscriptionActions.update,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.reason)) {
    errors.reason = '请输入合同审批不通过原因'
  }

  return errors
}

const formConfig = {
  form: 'SubscriptioApprovalRejectForm',
  fields: ['reason'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SubscriptioApprovalRejectForm)
