import React, { Component, PropTypes } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import {} from 'app/actions'
import { isListValue, getListFirstValue } from 'utils/kbUtil'
import { canAccessSpaceRoles } from 'app/reducers/role'
import _ from 'lodash'

const menu = [
  {
    name: '报修管理',
    value: ['repair/type/my', 'repair', 1],
    roles: [
      'repair',
      'admin',
      'location_repair',
      'sales',
      'location_operator',
      'location_manager',
      'location_group_operator'
    ]
  },
  {
    name: '增值服务',
    value: ['custom_service', 2],
    roles: [
      'repair',
      'admin',
      'location_repair',
      'sales',
      'location_operator',
      'location_manager',
      'location_group_operator'
    ]
  },
  {
    name: '意见反馈',
    value: ['feedback', 3],
    roles: ['task_feedback', 'admin', 'location_operator', 'location_manager']
  }
]

var TasksBox = React.createClass({
  render() {
    const { section, locationSection, location_id, user } = this.props
    let loc_url = location_id ? `/locations/${location_id}` : ''
    let menu_n = _.cloneDeep(menu)
    if (location_id) {
      _.remove(menu_n, item => item.value[0] === 'feedback')
    }
    console.log(menu_n);
    return (
      <div
        className="kb-out-box clear-fix"
        style={location_id ? { paddingTop: 0 } : {}}
      >
        <div className="sub-nav-bar" style={{ minWidth: 1450 }}>
          <div style={{ position: 'relative' }}>
            <div className="sub-nav-list clear-fix">
              {menu_n.map((data, index) => {
                if (!canAccessSpaceRoles(user, data.roles)) {
                  return null
                }

                return (
                  <Link
                    key={index}
                    className={
                      isListValue(data.value, section) ||
                      (location_id && isListValue(data.value, locationSection))
                        ? 'active'
                        : ''
                    }
                    to={`/admin${loc_url}/tasks/${getListFirstValue(
                      data.value
                    )}`}
                  >
                    {' '}
                    {data.name}{' '}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>

        <div className="margin-top-lg">
          <div className="clear-fix" style={{ height: '100%' }}>
            <section className="kb-content-t">{this.props.children}</section>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { params } = props
  let sections = props.location.pathname.split('/')
  let section = sections.length > 3 ? sections[3] : ''
  let locationSection = sections.length > 5 ? sections[5] : ''
  let location_id = params && params.id

  return {
    section,
    locationSection,
    user,
    location_id
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksBox)
