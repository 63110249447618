import React from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { successState, apiCallFailure } from 'app/actions'
import {
  KBPagination,
  KBLoadingContainer,
  KBNoData,
  KBPopover,
  KBSelectTab,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import {
  percent,
  toImageProxyDirect,
  queryFilter,
  getFilterAndQuery,
  formatYMDN4TwoDate,
  formatHmN4TwoDate,
  paginate
} from 'utils/kbUtil'
import { connect } from 'react-redux'
import moment from 'moment'
import { KBPopoverConfirm } from 'components/tools'
import { Icon } from 'antd'

var MemberWelfareList = React.createClass({
  displayName: 'MemberWelfareList',
  getInitialState() {
    return {
      pagination: null,
      page: 1,
      loading: true,
      location_id: '',
      filters: {
        progress: '',
        status: ''
      }
    }
  },

  componentDidMount() {
    this.mounted = true
    this.loadData()
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.location.query.page) {
      this.setState({page: nextProps.location.query.page}, () => {
        this.loadData()
      })
      return
    }
  },

  componentWillUnmount() {
    this.mounted = false
  },

  loadData(newPage) {
    const per_page = 10
    const { groupId } = this.props
    const { filters, page } = this.state

    this.setState({ loading: true })
    console.log(filters)
    api
      .getMemberWelfareList({
        per_page,
        page: newPage || page,
        location_group_id: groupId,
        progress: filters.progress || undefined,
        status: filters.status || undefined,
      })
      .then(
        ({ json, response }) => {
          if (!this.mounted) {
            return
          }
          let pagination = paginate(response)
          this.setState({
            pagination,
            dataList: json
          })
        },
        error => {
          this.props.apiCallFailureAction({
            message: error.message
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  _setFilter(obj) {
    let newFilt = obj || {}
    let newFilters = Object.assign({}, this.state.filters, newFilt)
    this.setState({ filters: newFilters, page: 1 }, ()  => {
      this.loadData()
    })
  },

  _clearFilters() {
    this.setState({ filters: {}, page: 1 }, () => {
      this.loadData()
    })
  },

  _renderFilters() {
    const { groupId } = this.props
    const { progress, status } = this.state.filters
    return (
      <div
        style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}
      >
        <KBSelectTab
          options={[
            { label: '未开始', value: 'upcoming' },
            { label: '进行中', value: 'active' },
            { label: '已结束', value: 'ended' }
          ]}
          value={progress}
          style={{ marginRight: 20, marginLeft: 20 }}
          onChange={(value) => this._setFilter({progress: value})}
        />

        <KBSelectTab
          options={[
            { label: '已发布', value: 'publish' },
            { label: '草稿', value: 'draft' }
          ]}
          value={status}
          style={{ marginRight: 20, marginLeft: 20 }}
          onChange={(value) => this._setFilter({status: value})}
        />
      </div>
    )
  },

  toEdit(item) {
    const {
      routerActions,
      params: { id }
    } = this.props

    if (id) {
      routerActions.push(`/admin/groups/${id}/memberWelfare/edit${item ? `?fid=${item.id}` : ''}`)
    } else {
      routerActions.push(`/admin/memberships/memberManage/memberWelfare/edit${item ? `?fid=${item.id}` : ''}`)
    }
  },

  handleStatus(item, type) {
    let name = '',
      context = '',
      callback = null
    if (type === 'status') {
      if(item.status === 'publish') {
        name = '下架会员福利'
        context = `确定要下架'${item.title}'?`
        callback = () => {
          return api.editMemberWelfare(item.id, {
            ...item,
            start_at: moment.unix(item.start_at).format('YYYY-MM-DD HH:mm:ss'),
            end_at: moment.unix(item.end_at).format('YYYY-MM-DD HH:mm:ss'),
            status: 'draft'
          }).then(() => {
            this.props.successAction({ message: '下架成功' })
            const arr = this.state.dataList
            const obj = arr.find(i => i.id === item.id)
            obj.status = 'draft'
            this.setState({
              dataList: [...arr]
            })
          }).catch((error) => {
            this.props.apiCallFailureAction({message: error.message})
          }).finally(() => {
            KBPopover.close()
          })
        }
      } else {
        name = '上架会员福利'
        context = `确定要上架'${item.title}'?`
        callback = () => {
          return api.editMemberWelfare(item.id, {
            ...item,
            start_at: moment.unix(item.start_at).format('YYYY-MM-DD HH:mm:ss'),
            end_at: moment.unix(item.end_at).format('YYYY-MM-DD HH:mm:ss'),
            status: 'publish'
          }).then(() => {
            this.props.successAction({ message: '上架成功' })
            const arr = this.state.dataList
            const obj = arr.find(i => i.id === item.id)
            obj.status = 'publish'
            this.setState({
              dataList: [...arr]
            })
          }).catch((error) => {
            this.props.apiCallFailureAction({message: error.message})
          }).finally(() => {
            KBPopover.close()
          })
        }
      }
    } else {
      name = '删除会员福利'
      context = `确定要删除'${item.title}'?`
      callback = () => {
        return api.deleteMemberWelfare(item.id).then(() => {
          this.props.successAction({ message: '删除成功' })
          this.loadData()
        }).catch((error) => {
          this.props.apiCallFailureAction({message: error.message})
        }).finally(() => {
          KBPopover.close()
        })
      }
    }

    KBPopoverConfirm({
      name,
      context,
      callback
    })
  },

  exportData() {
    const { groupId } = this.props
    api
      .exportMemberWelfare({
        location_group_id: groupId
      })
      .then(res => {
        const { json: { result } = {} } = res
        if (result.code && result.code === 200) {
          KBPopoverTop.show(
            <KBToReportCenter title="会员导出" message={result.msg} />
          )
        }
      })
  },

  render() {
    const { activities, groupId } = this.props
    const { loading, filters, pagination, dataList } = this.state

    const contentBox = (
      <div>
        <header className="nav-section-header clear-fix border-bottom">
          <div className="nav-section-header-title f-left">
            <span>福利</span>
          </div>
          <span
            className="bordered-btn f-right"
            onClick={this.exportData}
          >
            导出浏览收藏数据
          </span>
          <span className="bordered-btn f-right" onClick={() => this.toEdit()}>
            创建福利
          </span>
        </header>
        <div style={{ margin: '30px 0 10px', display: 'flex', alignItems: 'center' }}>
          {this._renderFilters()}
          {Object.values(this.state.filters).some(i => i) ? (
            <div className="clear-criteria m-left" style={{marginTop: 0}} onClick={this._clearFilters}>
              <i className="iconfont icon-close" />
              <span>清除所有筛选条件</span>
            </div>
          ) : null}
        </div>
        <KBLoadingContainer loading={loading}>
          <div className="nav-section-content-container">
            {
              (dataList || []).map(item => {
                return (
                  <div className="activity-container">
              <img
                src={item.images[0]}
                alt="img"
                style={{width: '150px', height: '200px'}}
              />
              <div className="activity-content" style={{width: '650px'}}>
                <div
                  className="activity-title"
                  style={{ maxWidth: 'none' }}
                  onClick={() => this.toEdit(item)}
                >
                  {item.title}
                </div>
                <div className="activity-date-status">
                  <span className="activity-date">
                  {formatYMDN4TwoDate(item.start_at, item.end_at)}
                    <span style={{ marginLeft: 10 }}>{formatHmN4TwoDate(item.start_at, item.end_at)}</span>
                  </span>
                  {
                    item.status === 'draft' && (
                      <span className="activity-status activity-status_draft">草稿</span>
                    )
                  }
                </div>
                <div>
                  <button
                    className="bordered-btn"
                    style={{ marginLeft: 0 }}
                    onClick={() => this.toEdit(item)}
                  >
                    编辑
                  </button>
                  <button
                    className="bordered-btn"
                    onClick={() => this.handleStatus(item, 'status')}
                  >
                    {item.status === 'publish' ? '下架' : '上架'}
                  </button>
                  <button
                    className="bordered-btn-end "
                    onClick={() => this.handleStatus(item, 'delete')}
                  >
                    删除
                  </button>
                </div>
              </div>
              <div style={{marginLeft: '40px'}}>
                <div style={{ marginBottom: 16, color: '#969696' }}>
                  <Icon type="eye" style={{ marginRight: 10 }} />
                  <span style={{color: '#ff5a60'}}>{item.view_count}</span>
                </div>
                <div style={{ marginBottom: 16, color: '#969696' }}>
                  <Icon type="heart" style={{ marginRight: 10 }} />
                  <span style={{color: '#ff5a60'}}>{item.like_count}</span>
                </div>
              </div>
            </div>
                )
              })
            }
            {
              (!dataList || !dataList.length) && (
            <KBNoData tipMessage="暂无数据" />
              )
            }
            {groupId ? (
              <KBPagination
                pagination={pagination}
                template={`/admin/groups/${groupId}/memberWelfare?page=#PAGE#`}
              />
            ) : (
              <KBPagination
                pagination={pagination}
                template={`/admin/memberships/memberManage/memberWelfare?page=#PAGE#`}
              />
            )}
          </div>
        </KBLoadingContainer>
      </div>
    )

    return (
      <div className="kb-content" style={groupId ? {} : { paddingTop: 0 }}>
        <div
          className="kb-content-container clear-fix"
          style={{ minHeight: '100%' }}
        >
          {contentBox}
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  let groupId = params && params.id

  return {
    groupId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberWelfareList)
