import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import { lockEventsActions } from 'app/actions'
import { routerActions } from 'react-router-redux'
import * as selectors from 'app/selectors'
import {
  KBPopover,
  KBPagination,
  KBDropdown,
  KBRangeDate,
  KBLoadingContainer,
  KBTipsy,
  KBTableWithoutData
} from 'components'
import { KBDropSelect } from 'components/plugins'
import { LOCK_HOT_SPOT_COLOR, LOCK_ACTION_TYPE } from 'app/constants'
import {
  getEntitiesJsonArray,
  paginate,
  getDateType,
  arrayToObject,
  queryFilter,
  getFilterAndQuery,
  getMaximin,
  formatYearDayEN,
  formatOnlyMinuteSecondEN,
  formatMinuteSecondEN,
  formatMonthDayCHS,
  kbCloseDropDown
} from 'utils/kbUtil'
import { getInitialDate } from 'utils/kbData'
import moment from 'moment'
import LockManageForm from './LockManageForm'
import { getUserInfoUrl } from 'utils/kbUrl'
import {
  canAccessLocation,
  canAccessLocationVisitorOnly
} from 'app/reducers/role'
import ExportLockEventsForm from './ExportLockEventsForm'
const pinyin = require('tiny-pinyin')

/**
 * 门禁管理
 * @type {[type]}
 */
const FILTER_STATE = ['from', 'to', 'lock_id']
const DROP_DOWNS = ['lock', 'dateDropDown']
const LOCK_SOURCE = {
  android: 'Android',
  ios: 'iOS',
  face_plus: 'Face++人脸识别',
  wechat: '微信',
  m1_card: '卡片',
  web_admin: '管理端远程开门',
  s_bracelet: '手环',
  s_qrcode: '二维码',
  offline: '线下',
  wechat_miniapp: '微信小程序',
  passcode: '密码',
  space_role: '用户权限更改'
}

var LockEvents = React.createClass({
  displayName: 'LockEvents',
  getInitialState() {
    const { filters } = this.props
    return {
      lockEvents: [],
      pagination: null,
      filters,
      lockDate: [],
      lockHotspot: {},
      loading: false
    }
  },

  componentDidMount() {
    const { page, queryFilters, filters, loc_id } = this.props

    this.mounted = true

    if (queryFilters.lock_id) {
      const filters = Object.assign(
        {},
        {
          lock_id: queryFilters.lock_id
        },
        filters
      )
      this.setState({
        filters
      })
      this._loadDate(page, filters)
    } else {
      this._loadDate(page, filters)
    }
    this._loadLockHotspot()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadLockHotspot() {
    const { loc_id } = this.props
    api.getLockHotspot({ location_id: loc_id }).then(({ json }) => {
      let lockDate = Object.keys(json)
      this.setState({
        lockDate,
        lockHotspot: json
      })
    })
  },

  _loadDate(page, isfilters) {
    const { loc_id, getLockEventsActions, routerActions, query } = this.props
    const per_page = 10
    const filters = isfilters || this.state.filters
    let pageObj = { per_page, page }
    let params = Object.assign({}, isfilters, pageObj)
    const { from, to } = filters
    var lock_id = null
    var isFilters = isfilters && Object.keys(isfilters).length

    if (isFilters && isfilters.lock_id) {
      lock_id = isfilters.lock_id
    } else {
      lock_id = ''
    }
    if (from && to) {
      params.from = from && formatMinuteSecondEN(from)
      params.to = to && formatMinuteSecondEN(to)
    } else {
      delete params.from
      delete params.to
    }

    let queryFiltersStr = queryFilter({ ...query, ...params, page: page })
    this.setState({
      loading: true
    })

    let qr = {
      per_page,
      location_id: loc_id,
      ...params,
      lock_id: lock_id,
      page
    }

    if (!lock_id) {
      delete qr.lock_id
    }

    // 获取门禁列表
    api
      .getLockEvents(qr)
      .then(
        ({ json, response }) => {
          const lock_events = json || []
          getLockEventsActions.success({
            entities: { lockEvents: arrayToObject(lock_events) }
          })

          let pagination = paginate(response)
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/locations/${loc_id}/locks${queryFiltersStr}`
          )

          if (this.mounted) {
            this.setState({
              lockEvents: lock_events,
              pagination
            })
          }
        },
        errors => {
          getLockEventsActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page != nextProps.page) {
      this._loadDate(nextProps.page, { ...this.state.filters, ...filters })
    }
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })

    this._loadDate(1, newFilters)
  },

  _clearFilters() {
    this.setState({ filters: {} })
    this._loadDate(1, {})
  },
  _refresh(key) {
    let dropDown = key + 'Dropdown'
    this.refs[dropDown] && this.refs[dropDown].hide()
    setTimeout(this._loadDate)
  },

  changeDate(range) {
    this._setFilter(range)
  },

  selectLockCallBack(value) {
    this._setFilter({
      lock_id: value.id
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  _renderFilters() {
    let { locks, lockArray, defaultLock } = this.props
    const { filters } = this.state
    const { from, to } = this.state.filters
    let lockArraySort = Object.assign([], locks)
    lockArraySort &&
      lockArraySort.map(lock => {
        lock.pinyin = pinyin.convertToPinyin(lock.name).split('')[0]
      })
    lockArraySort &&
      lockArraySort.sort((a, b) => {
        return a.pinyin.localeCompare(b.pinyin)
      })
    return (
      <div className="nav-section-action-bar">
        <KBDropSelect
          ref={ref => {
            this.lock = ref
          }}
          options={{
            defaultSingleContent: `${
              defaultLock && defaultLock.length
                ? defaultLock[0].name
                : '选择门禁'
            }`
          }}
          defaultData={lockArraySort}
          multi={false}
          showImg={false}
          dropDown_name="lock"
          closeClick={this.closeDropDowns}
          callback={this.selectLockCallBack}
          style={{ marginLeft: 0 }}
        />
        <KBRangeDate
          from={from || ''}
          to={to || ''}
          ref={ref => {
            this.dateDropDown = ref
          }}
          dropDown_name="dateDropDown"
          closeClick={this.closeDropDowns}
          callback={this.changeDate}
        />
      </div>
    )
  },

  hotspotBlock() {
    let hotspotBlock = []
    for (let i = 0; i < 24; i++) {
      hotspotBlock.push({
        id: i,
        color: 'rgb(220,241,254)'
      })
    }
    return hotspotBlock
  },

  getLockCountNumber() {
    const { lockHotspot, lockDate } = this.state
    let initLockCount = []
    lockDate.length > 0 &&
      lockDate.map(date => {
        lockHotspot[date].map(lock => {
          if (lock.count) {
            initLockCount.push(lock.count)
          }
        })
      })
    return initLockCount
  },

  judgmentSpotLevel(lock) {
    const { count } = lock
    let color = ''
    let initLockCount = this.getLockCountNumber()
    let maxCount = getMaximin(initLockCount, 'max')
    let minCount = getMaximin(initLockCount, 'min')
    let deviation = maxCount - minCount

    if (deviation < 7) {
      color = 'rgb(82,175,254)'
      return color
    }

    let deviationFloor = Math.floor(deviation / 7)

    if (count >= minCount && count <= deviationFloor) {
      color = 'rgb(167,217,255)'
      return color
    }
    if (count > deviationFloor && count <= 2 * deviationFloor) {
      color = 'rgb(124,197,255)'
      return color
    }
    if (count > 2 * deviationFloor && count <= 3 * deviationFloor) {
      color = 'rgb(82,175,254)'
      return color
    }
    if (count > 3 * deviationFloor && count <= 4 * deviationFloor) {
      color = 'rgb(34,143,255)'
      return color
    }
    if (count > 4 * deviationFloor && count <= 5 * deviationFloor) {
      color = 'rgb(14,123,255)'
      return color
    }
    if (count > 5 * deviationFloor && count <= 6 * deviationFloor) {
      color = 'rgb(13,102,223)'
      return color
    }
    if (count > 6 * deviationFloor && count <= maxCount) {
      color = 'rgb(10,80,170)'
      return color
    }
  },

  renderHotspotBlock(date) {
    const { lockDate, lockHotspot } = this.state
    let lockHotspotArray = lockHotspot[date]
    let hotspotBlock = this.hotspotBlock()
    let jsx = hotspotBlock.map((obj, index) => {
      lockHotspotArray.map(lock => {
        if (lock.hour == index) {
          obj.color = this.judgmentSpotLevel(lock)
          obj.count = lock.count
          obj.class = 'lock-filter-time'
        }
      })
      return (
        <span
          key={index}
          className={`lock-hotspot ${obj.class ? obj.class : ''}`}
          style={{ backgroundColor: obj.color }}
          onClick={this.setFilterTime.bind(null, date, index, obj)}
        />
      )
    })
    return jsx
  },

  setFilterTime(date, index, obj) {
    if (!obj.count) {
      return
    }
    let range = {
      from: moment(date)
        .hour(index)
        .toDate(),
      to: moment(date)
        .hour(index + 1)
        .toDate()
    }
    this._setFilter(range)
  },

  renderHotspot() {
    const { lockDate, lockHotspot } = this.state
    let jsx =
      lockDate &&
      lockDate.length > 0 &&
      lockDate.map((date, index) => {
        return (
          <div key={index} className="lock-hotspot_line">
            <span className="lock-date">{formatMonthDayCHS(date)}</span>
            {this.renderHotspotBlock(date)}
          </div>
        )
      })
    return jsx
  },

  exportLockReport() {
    let initValue = {
      startYMD: new Date(),
      endYMD: new Date(),
      startHM: '9,1',
      endHM: '19,1',
      start_at: moment(new Date())
        .clone()
        .set({ hour: 9, minute: 0, second: 0 })
        .toDate(),
      end_at: moment(new Date())
        .clone()
        .set({ hour: 19, minute: 0, second: 0 })
        .toDate()
    }

    KBPopover.show(
      <ExportLockEventsForm
        initialValues={initValue}
        location_id={this.props.loc_id}
      />
    )
  },

  renderLockTime() {
    return (
      <div className="lock-time">
        <span>0h</span>
        <span>1h</span>
        <span>2h</span>
        <span>3h</span>
        <span>4h</span>
        <span>5h</span>
        <span>6h</span>
        <span>7h</span>
        <span>8h</span>
        <span>9h</span>
        <span>10h</span>
        <span>11h</span>
        <span>12h</span>
        <span>13h</span>
        <span>14h</span>
        <span>15h</span>
        <span>16h</span>
        <span>17h</span>
        <span>18h</span>
        <span>19h</span>
        <span>20h</span>
        <span>21h</span>
        <span>22h</span>
        <span>23h</span>
      </div>
    )
  },
  // 门禁管理弹出
  chooseLockEvent() {
    const { loc_id, organizations, locks } = this.props
    KBPopover.show(
      <LockManageForm
        loc_id={loc_id}
        locks={locks}
        organizations={organizations}
      />
    )
  },

  render() {
    const { loc_id, isManager, isVisitorOnly } = this.props
    const { lockEvents, pagination, filters, loading } = this.state
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    // /admin/locations/2/locks/lock_info/100
    return (
      <section className="kb-content-container clear-fix">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>开门记录</span>
          </h2>
          {/*<div className='f-right'><button className='bordered-btn' onClick={ this.chooseLockEvent }>添加门禁权限</button></div>*/}
          {isManager || isVisitorOnly ? (
            <div className="f-right">
              <button
                className="c-btn-secondary"
                onClick={this.exportLockReport}
              >
                导出门禁记录
              </button>
            </div>
          ) : null}
        </header>
        <div className="nav-section-content-container">
          <div className="m-top">
            {this.renderLockTime()}
            {this.renderHotspot()}
          </div>
          <div className="m-top">
            {this._renderFilters()}
            {selectFilters.length > 0 ? (
              <div
                className="clear-criteria"
                style={{ marginTop: 5 }}
                onClick={this._clearFilters}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : (
              ''
            )}
          </div>
          <KBLoadingContainer loading={loading}>
            <table className="content-table">
              <thead>
                <tr>
                  <th>行为</th>
                  <th>门禁名称</th>
                  <th>用户</th>
                  <th>开门设备</th>
                  <th>开门方式</th>
                  <th>所在区域</th>
                  <th>日期</th>
                  <th>时间</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={lockEvents.length > 0}
                tableHeadNum="8"
                tipMessage="暂无开门记录"
              >
                {// location_group_id
                lockEvents &&
                  lockEvents.map((lock, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {lock.action == 'open_door' ? (
                            <KBTipsy content="开门成功">
                              <div
                                className="lock-action"
                                style={{ backgroundColor: '#73bb4b' }}
                              >
                                <i className="iconfont icon-open" />
                              </div>
                            </KBTipsy>
                          ) : (
                            <KBTipsy
                              content={
                                lock.description ? lock.description : '开门失败'
                              }
                            >
                              <div
                                className="lock-action"
                                style={{ backgroundColor: '#dd5a55' }}
                              >
                                <i className="iconfont icon-open" />
                              </div>
                            </KBTipsy>
                          )}
                        </td>
                        {isVisitorOnly ? (
                          <td>{lock.lock.name} </td>
                        ) : (
                          <td>
                            <Link
                              className="color-link"
                              to={`/admin/locations/${loc_id}/locks/lock_info/lock_list/${(lock.lock &&
                                lock.lock.id) ||
                                ''}`}
                            >
                              {lock.lock.name}
                            </Link>
                          </td>
                        )}
                        {isVisitorOnly ? (
                          <td>{lock.user && lock.user.name}</td>
                        ) : (
                          <td>
                            <Link
                              className="color-link"
                              to={lock.user && getUserInfoUrl(lock.user.id)}
                            >
                              {lock.user && lock.user.name}
                            </Link>
                          </td>
                        )}
                        <td>{LOCK_SOURCE[lock.source]}</td>
                        <td>{LOCK_ACTION_TYPE[lock.action_type]}</td>
                        {isVisitorOnly ? (
                          <td>{lock.area && lock.area.name}</td>
                        ) : (
                          <td>
                            <Link
                              className="color-link"
                              to={`/admin/locations/${loc_id}/areas/desks/list?area_id=${lock.area &&
                                lock.area.id}`}
                            >
                              {lock.area && lock.area.name}
                            </Link>
                          </td>
                        )}
                        <td>{formatYearDayEN(lock.created_at)}</td>
                        <td>{formatOnlyMinuteSecondEN(lock.created_at)}</td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/locations/${loc_id}/locks?page=#PAGE#`}
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { params, location } = props
  let loc_id = params.id
  let organizations = Object.assign(
    [],
    selectors.getOrgsOfLocation(state, loc_id)
  )
  let isManager = canAccessLocation(state.user, loc_id)
  let page = parseInt(location.query && location.query.page)
  let locks = selectors.getLocks(state)
  page = isNaN(page) ? 1 : page
  let queryFilters = location.query
  let lockArray = state.entities.locks

  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)

  let defaultLock = []
  if (paramsAll.filters && paramsAll.filters.lock_id) {
    let lock_ids = paramsAll.filters.lock_id.split(',')
    defaultLock = locks.filter(data => lock_ids.find(id => id == data.id))
  }

  let isVisitorOnly = canAccessLocationVisitorOnly(user, loc_id)

  return {
    loc_id,
    locks,
    organizations,
    page,
    queryFilters,
    lockArray,
    defaultLock,
    isManager,
    isVisitorOnly,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLockEventsActions: bindActionCreators(lockEventsActions.all, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockEvents)
