import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { Link } from 'react-router'
import { activitiesOrdersAction, apiCallFailure } from 'app/actions'
import {
  util,
  preventDefaultPropagation,
  paginate,
  formatMinuteSecondSOL
} from 'utils/kbUtil'
import { ACTIVITY_STATUS_OBJ } from 'app/constants'
import {
  KBLoadingContainer,
  KBStatisticNumber,
  KBAvatar,
  KBPopover,
  KBPagination,
  KBTableWithoutData,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import * as selectors from 'app/selectors'
import { getUserInfoUrl } from 'utils/kbUrl'
import moment from 'moment'

const ACTIVITY_STATUS = {
  reserved: '未支付',
  cancelled: '已取消',
  active: '已支付'
}

var ActivitiesOrders = React.createClass({
  displayName: 'ActivitiesOrders',

  getInitialState() {
    return {
      loading: true,
      list: []
    }
  },

  componentDidMount() {
    this.loadData()
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      this.loadData(nextProps.page)
    }
  },

  loadData(page) {
    const { activity_id, apiCallFailureAction } = this.props
    let params = Object.assign(
      {},
      { id: activity_id, per_page: 10, page: page || 1 }
    )
    api
      .getReplacementOrder(activity_id, params)
      .then(
        json => {
          let pagination = paginate(json.response)
          this.setState({
            pagination,
            list: json.json
          })
        },
        error => {
          apiCallFailureAction({
            status: 'error',
            message: (error.message)
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  reportActivitySignUp() {
    const { activity_id, loc_id } = this.props
    api.salesInvoiceReport({
      location_id: loc_id,
      discount_goods_id: activity_id
    }).then(
      res => {
        return KBPopoverTop.show(
          <KBToReportCenter
            title="Excel导出"
            message="Excel已成功导出，可以前往报表中心下载"
          />
        )
      },
      err => {
        console.log(err)
      }
    )
  },

  getPhone(phone){
    if(!phone) return ''
    return phone.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")
  },

  render() {
    const { loading, pagination, list } = this.state
    const { activity_id, loc_id } = this.props
    return (
      <div className="nav-section-container f-right">
        <header className="nav-section-header clear-fix border-bottom">
          <div className="nav-section-header-title f-left">
            <span>订单列表</span>
          </div>
          <div className="f-right" style={{ display: 'flex' }}>
            <button
              className="c-btn-secondary"
              onClick={() => this.reportActivitySignUp()}
            >
              导出Excel
            </button>
          </div>
        </header>
        <KBLoadingContainer loading={loading}>
          <div style={{ padding: '0 30px' }}>
            <table className="content-table">
              <thead>
                <tr>
                  <th>姓名</th>
                  <th>电话</th>
                  <th>会议室/工位</th>
                  <th>实际价格</th>
                  <th>下单时间</th>
                  <th>订单状态</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={list.length > 0}
                tableHeadNum="6"
                tipMessage="暂无订单"
              >
                {list.map(order => {
                    return (
                      <tr>
                        <td>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <KBAvatar
                              style={{
                                display: 'inline-block',
                                marginRight: '5px'
                              }}
                              imgStyle={{ marginRight: 5 }}
                              user={{
                                name: order.user_name
                              }}
                              size={20}
                            />
                            {order.user_name}
                            {/* <Link
                              to={getUserInfoUrl(order.user.id)}
                              className="color-link"
                            >
                              {order.user.name}
                            </Link> */}
                          </div>
                        </td>
                        <td>{this.getPhone(order.phone_num)}</td>
                        <td>
                          {order.area_name}
                        </td>
                        <td>{order.extract.total_amount}元</td>
                        <td>{formatMinuteSecondSOL(order.extract.created_at)}</td>
                        <td>
                          {/* {order.status === 'active' && order.signed_at
                            ? moment
                                .unix(order.signed_at)
                                .format('YYYY/MM/DD HH:mm:ss')
                            : ''} */}
                            {ACTIVITY_STATUS[order.state]}
                        </td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={
                loc_id
                  ? `/admin/locations/${loc_id}/replacement/${activity_id}/orders?page=#PAGE#`
                  : `/admin/memberships/memberManage/replacement/${activity_id}/orders?page=#PAGE#`
              }
            />
          </div>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let loc_id = props.params.id
  let activity_id = props.params.activity_id
  let activitiesOrders = selectors.getActivitiesOrders(state)
  // activitiesOrders = activitiesOrders && activitiesOrders.filter(order => order.status === 'active')
  let page = parseInt(props.location.query && props.location.query.page, 10)
  page = isNaN(page) ? 1 : page
  /*let activity = selectors.getActivitiesInfo(state, activity_id)
  loc_id = activity.location.id || loc_id*/
  return {
    loc_id,
    activity_id,
    activitiesOrders,
    page
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getActivitiesOrdersAction: bindActionCreators(
      activitiesOrdersAction.replace,
      dispatch
    ),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
  }
}

ActivitiesOrders = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitiesOrders)

export default ActivitiesOrders
