import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { appModulesActions } from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'

const menu = [
  {
    name: '应用列表',
    value: ''
  }
]

var ApplicationBox = React.createClass({
  displayName: 'ApplicationBox',
  componentDidMount() {
    const { getAppModulesActions } = this.props
    api.getAppModules({ per_page: 1000 }).then(
      json => {
        getAppModulesActions.success(json)
      },
      errors => {
        getAppModulesActions.failure(errors)
      }
    )
  },
  render() {
    const { section } = this.props

    return (
      <div className="kb-out-box clear-fix">
        <section className="kb-content">
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">应用管理</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menu.map((data, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          section == data.value
                            ? 'nav-container-list-hover'
                            : ''
                        }
                      >
                        <Link to={`/admin/application/${data.value}`}>
                          {' '}
                          {data.name}{' '}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          {this.props.children}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let sections = location.pathname.split('/')
  let section = sections.length > 2 ? sections[3] : ''

  return {
    section: section || ''
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAppModulesActions: bindActionCreators(appModulesActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationBox)
