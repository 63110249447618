import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import { faqFilesActions } from 'app/actions'
import { KBLoadingContainer, KBDropdown, KBTipsy } from 'components'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES } from 'app/constants'
import * as selectors from 'app/selectors'
import moment from 'moment'
import { paginate } from 'utils/kbUtil'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import * as constants from 'app/constants'

var ProviderRequest = React.createClass({
  displayName: 'ProviderRequest',
  getInitialState() {
    return {}
  },

  componentDidMount() {},

  toRequestDetail(request) {
    const { routerActions } = this.props
    routerActions.push(`/admin/service/request_detail/${request.id}`)
  },

  filterRender() {
    return (
      <div
        className="nav-section-action-bar border-bottom"
        style={{ margin: 0, padding: '30px 0' }}
      >
        <KBDropdown ref="userDropdown">
          <KBDropdown.KBDropdownTrigger>
            <div className="task-status-title">
              <i className="iconfont icon-desktop_mac" />
              <span>服务分类</span>
              <span className="task-deg" />
            </div>
          </KBDropdown.KBDropdownTrigger>
          <KBDropdown.KBDropdownContent>
            <ul className="task-type">
              <li>
                <span>分类</span>
              </li>
            </ul>
          </KBDropdown.KBDropdownContent>
        </KBDropdown>
        <div
          className="common-search d-iblock"
          style={{ width: 163, marginLeft: 20 }}
        >
          <input
            type="text"
            placeholder="搜索"
            style={{ backgroundImage: 'none', padding: '6px' }}
          />
        </div>
        <span
          className="s-btn"
          style={{ marginLeft: 10, padding: '8px 20px 6px 20px' }}
        >
          搜索
        </span>
        <br />
        <div className="clear-criteria" style={{ marginTop: 5 }}>
          <i className="iconfont icon-close" />
          <span onClick={this.clearFilter}>清除所有选项</span>
        </div>
      </div>
    )
  },

  render() {
    const { serviceRequests, serviceProvider } = this.props
    const { contact_name, contact_phone, contact_email } = serviceProvider
    return (
      <div className="p-bottom">
        {/* {this.filterRender()} */}
        <table className="content-table edit-table table-align">
          <thead>
            <tr>
              <th>服务对象</th>
              <th>服务</th>
              <th>状态</th>
              <th>联系人</th>
              <th>最后更新</th>
            </tr>
          </thead>
          <tbody>
            {serviceRequests.map((request, index) => {
              return (
                <tr
                  key={index}
                  className="service-tr"
                  onClick={this.toRequestDetail.bind(null, request)}
                >
                  <td>
                    <span>{request.organization_name || '无'}</span>
                  </td>
                  <td>
                    <span
                      className="badge"
                      style={{ marginRight: 5, marginBottom: 5 }}
                    >
                      {request.service_name && request.service_name}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`default-status ${
                        constants.SERVICE_REQUEST_STATE_CLASS[request.status]
                      }`}
                      style={{ width: 60, textAlign: 'center' }}
                    >
                      {constants.SERVICE_REQUEST_STATE_OBJ[request.status]}
                    </span>
                  </td>
                  <td className="linkman">
                    <KBTipsy content={`电话: ${contact_phone}`}>
                      <span>{contact_name}</span>
                    </KBTipsy>
                  </td>
                  <td>
                    <span>{moment.unix(request.updated_at).fromNow()}</span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { serviceProvider } = props
  var serviceRequests = Object.assign(
    [],
    selectors.getServiceRequestOfProvidersId(state, serviceProvider.id)
  )

  return {
    serviceRequests,
    serviceProvider
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderRequest)
