import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { subscriptionsAction, apiCallFailure } from 'app/actions'
import { api } from 'app/services'
import { KBInput, KBPopover, KBForm, KBFormError, KBButton } from 'components'

var ParkingCarPlatesForm = React.createClass({
  updateCarPlatesForm(model) {
    const { sub_id, parking, updateSubscriptionAction } = this.props

    delete model.name
    let parking_area = {
      area_id: parking.area_id,
      car_plates: model.car_plates
    }
    model.car_plates = []
    model.car_plates.push(parking_area)
    return api.putParkingContractCarPlates(sub_id, model).then(
      json => {
        updateSubscriptionAction.success(json)
        KBPopover.close()
      },
      error => {
        updateSubscriptionAction.failure(error)
        KBPopover.close()
      }
    )
  },

  addNewCarPlate() {
    const {
      fields: { car_plates },
      apiCallFailureActions
    } = this.props

    if (car_plates.length > 4) {
      return apiCallFailureActions({
        status: 'error',
        message: '每个车位最多只能添加 5 个车牌号'
      })
    }
    car_plates.addField({
      car_plate: '',
      driver_license: '',
      owner_name: ''
    })
  },

  render() {
    const {
      fields: { name, car_plates },
      handleSubmit,
      submitting
    } = this.props

    return (
      <KBForm
        type="reduxForm"
        ref="ParkingCarPlatesForm"
        onSubmit={handleSubmit(this.updateCarPlatesForm)}
        fields={this.props.fields}
      >
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">修改车牌号</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              title="车位编号"
              className="kb-form-group kb-form-100 kb-form-alone"
              inputClass="kb-input-disabled"
              field={name}
              disabled={true}
            />
          </div>

          <div
            className="kb-row border"
            style={{ padding: 20, borderRadius: 4 }}
          >
            {car_plates.map((plate, index) => {
              return (
                <div
                  className="kb-form-group kb-form-48"
                  style={{ width: '100%', position: 'relative' }}
                >
                  <KBInput
                    title={`车牌号${index + 1}`}
                    className="kb-form-group kb-form-c18"
                    field={plate.car_plate}
                  />
                  <div className="kb-form-group kb-div-66">
                    <KBInput
                      className="kb-form-group kb-div-210"
                      title={`行驶证编号`}
                      field={plate.driver_license}
                    />
                    <div className="kb-form-group kb-div-102">
                      <KBInput
                        className="kb-form-group kb-div-210"
                        title={`行驶证所有者`}
                        field={plate.owner_name}
                      />
                      <i
                        className="iconfont icon-delete hover-delete"
                        style={{
                          position: 'absolute',
                          right: '13px',
                          bottom: '12px'
                        }}
                        onClick={e => {
                          e.stopPropagation()
                          car_plates.removeField(index)
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
            {car_plates.length > 4 ? null : (
              <div
                className={`parking-car-plate-add-btn f-left ${
                  car_plates.length > 0 ? 'm-top-sm' : ''
                }`}
                onClick={this.addNewCarPlate}
              >
                <i className="iconfont icon-add m-right-sm" />
                <span>添加车牌号</span>
              </div>
            )}
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定修改
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { parking } = props
  const { area, car_plates } = parking

  return {
    initialValues: {
      name: area && area.name,
      car_plates,
      car_plates_saved: false
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    updateSubscriptionAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'ParkingCarPlatesForm',
  fields: [
    'name',
    'car_plates[].car_plate',
    'car_plates[].driver_license',
    'car_plates[].owner_name',
    'car_plates_saved'
  ],
  validate: validate,
  touchOnBlur: false
}

ParkingCarPlatesForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ParkingCarPlatesForm)

export default ParkingCarPlatesForm
