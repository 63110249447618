import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, FieldArray } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  subscriptionsAction,
  apiCallFailure,
  usersActions,
  taxesAction,
  locationActions,
  orgActions,
  crmSourcesActions
} from 'app/actions'
import {
  KBInput,
  KBDateInput,
  KBTextarea,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover,
  KBMorePhotoUpload,
  KBAvatar,
  KBLoadingContainer,
  KBMoreFilesUpload
} from 'components'
import * as selectors from 'app/selectors'
import {
  RECURRING_CYCLES,
  CONTRACT_END_DATE_BTN,
  LINE_ITEM_TYPES
} from 'app/constants'
import { ADD_SUBSCRIPTION } from 'app/constants/hint'
import {
  contrastDate,
  contrastYMDDate,
  getIntegralDate,
  toPercent,
  getFloatOfSetting,
  formatYearDayEN
} from 'utils/kbUtil'
import moment from 'moment'
import RentingPhasesForm from './RentingPhasesForm'
import FreePhasesForm from './FreePhasesForm'
import PhasesCell from './PhasesCell'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import OrgSubscriptionMembersList from './OrgSubscriptionMembersList'
import OrgSubscriptionDeskListPublic from './OrgSubscriptionDeskListPublic'
import OrgSubscriptionDeskListPrivateOfArea from './OrgSubscriptionDeskListPrivateOfArea'
import OrgSubscriptionDeskListPrivateOfDesk from './OrgSubscriptionDeskListPrivateOfDesk'
import OrgSubscriptionDeskListFlexible from './OrgSubscriptionDeskListFlexible'
import OrgSubscriptionAreasForm from './OrgSubscriptionAreasForm'
import OrgSubscriptionCreateRentingPhase from './OrgSubscriptionCreateRentingPhase'
import OrgSubscriptionEditRentingPhase from './OrgSubscriptionEditRentingPhase'
import SelectChargeTypeForm from './SelectChargeTypeForm'
import SelectProvidersForm from './SelectProvidersForm'
import SelectInvoiceTitleForm from './SelectInvoiceTitleForm'
import PeriodicChargeForm from './PeriodicChargeForm'
import PeriodicChargeList from './PeriodicChargeList'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'
import _ from 'lodash'
import config from 'app/config'

const defaultYear = moment().get('year') - 1
const STEPS = ['时间期限', '工位', '押金', '交租', '记录', '备注']
const BIG_MONTH = [1, 3, 5, 7, 8, 10, 12]
let DAYS = []
for (let i = 1; i <= 31; i++) {
  DAYS.push(i)
}

let isC = false

let OrgSubscriptionForm = React.createClass({
  getInitialState() {
    const { defaultProvider } = this.props
    return {
      monthly: null,
      total: 0,
      deposit_diff: 0,
      membersInContract: [],
      selectMembers: [],
      yetDeskIds: [],
      yetAreaIds: [],
      oldContract: {},
      areaSetting: {},
      locationSetting: {},
      loading: true,
      phase_reset_status: false,
      old_sales_renting_phases: [],
      defaultProvider,
      defaultInvoiceTitle: null,
      invoiceTitles: []
    }
  },

  contextTypes: {
    isCencelled: PropTypes.func
  },

  componentDidMount() {
    const {
      fields: {
        signed_at,
        opportunity_id,
        valid_date,
        deposit,
        deposit_cycles,
        step,
        start_date,
        sales_tax_id,
        house_fee_tax_id,
        deposit_due_date,
        parent_id,
        merge_deposit_into_renting,
        office_area_name,
        files,
        contract_operate_status,
        location_provider_id
      },
      status,
      init_deposit_due_date,
      invoice_due_day,
      contract_id,
      location,
      organization_id,
      subscription,
      getTaxesAction,
      getLocationInfo,
      loc_id,
      getCrmSourcesActions,
      isContract,
    } = this.props
    isC = isContract
    const { defaultProvider } = this.state
    let location_id = (location && location.id) || loc_id
    const { getOrgAction } = this.props

    api
      .getOrganizationInfo(organization_id, {
        location_id,
        includes: 'details'
      })
      .then(
        json => {
          let newOrg = json.response.body || {}
          this.getAvailableInvoiceTitles(newOrg)
          getOrgAction.success(json)
        },
        error => {
          getOrgAction.failure(error)
        }
      )

    // CRM 来源
    api.getCrmSources().then(
      json => {
        getCrmSourcesActions.success(json)
      },
      error => {
        getCrmSourcesActions.failure(error)
      }
    )
    api.getSpaceTaxes().then(
      json => {
        const {response:{body:taxes}} = json
        getTaxesAction.success(json)
          api.getLocationInfo(location_id).then(
            json => {
              getLocationInfo.success(json)
            },
            errors => {
              getLocationInfo.failure(errors)
            }
          )


          api
            .getLocationSettings(location.id, true)
            .then(({ json }) => {
              const { location_settings, area_settings } = json
              this.setState({
                locationSetting: location_settings || {},
                areaSetting: area_settings || {}
              })
            })
            .then(() => {
              let oldFiles =
                this.props.status == 'update'
                  ? []
                  : (subscription && subscription.files) || []
              merge_deposit_into_renting.onChange(
                this.props.merge_deposit_into_renting
              )
              office_area_name.onChange((location && location.physical_address) || '')
              files.onChange(oldFiles)
              contract_operate_status.onChange(this.props.status)
              if (contract_id) {
                if (status == 'extend') {
                  parent_id.onChange(contract_id)
                }
                if (status == 'update') {
                  valid_date.onChange(moment(new Date()).format('YYYY-MM-DD'))
                }
                if (status === 'anew') {
                  this.changeFormalSerialNumber()
                }
                if (status === 'edit') {
                  this.changeFormalSerialNumber()
                }
                if (!subscription) {
                  api.getSubscription(contract_id).then(
                    json => {
                      this.props.getSubscriptionAction.success(json)
                      let subscription = selectors.getEntity(
                        json.json,
                        'subscriptions',
                        contract_id
                      )
                      this.renewDeskList(subscription)
                      this.setState({
                        oldContract: subscription
                      })
                    },
                    errors => {
                      this.props.getSubscriptionAction.failure(errors)
                    }
                  )
                } else {
                  this.setState({
                    oldContract: subscription
                  })
                  this.renewDeskList(subscription)
                }
                api
                  .getUsers({
                    location_id: location_id,
                    organization_id,
                    page: 1,
                    per_page: 500,
                    contract_id: contract_id,
                    includes: 'space_users'
                  })
                  .then(json => {
                    this.props.getUserActions.success(json, {
                      organization_id: organization_id
                    })
                    let membersInContract =
                      selectors.getMembersOfOrg(json.json, organization_id) || []
                    this.setState({
                      membersInContract,
                      selectMembers: membersInContract
                    })
                  })
              } else {
                house_fee_tax_id.onChange('')
                deposit.onChange(0)
                deposit_cycles.onChange(1)
                const new_date = moment(new Date()).format('YYYY-MM-DD')
                sales_tax_id.onChange((_.find(taxes,{rate:0.06})||{}).id||'')
                signed_at.onChange(new_date)
                start_date.onChange(new_date)
                //如果当前时间大于默认时间 取当前时间 否则取默认时间
                if (
                  moment(init_deposit_due_date).diff(moment(new_date), 'days') >= 0
                ) {
                  deposit_due_date.onChange(init_deposit_due_date)
                } else {
                  deposit_due_date.onChange(new_date)
                }
                this.changeFormalSerialNumber()
              }
              return null
            })
            .finally(() => {
              this.setState({
                loading: false
              })
            })

      },
      error => {
        getTaxesAction.failure(error)
      }
    )



    if (defaultProvider) {
      location_provider_id.onChange(defaultProvider.id)
    }
    step.onChange(this.props.step)
    opportunity_id.onChange(this.props.opportunity_id)
  },

  getAvailableInvoiceTitles(org) {
    const {
      location,
      subscription,
      fields: { invoice_title_id }
    } = this.props
    api
      .getInvoiceTitles({
        sales_customer_id: org.sales_customer && org.sales_customer.id,
        location_id: location.id
      })
      .then(({ json }) => {
        const invoiceTitles = json || []
        const defaultInvoiceTitle = subscription.invoice_title
          ? subscription.invoice_title
          : invoiceTitles.length > 0
          ? invoiceTitles[0]
          : null
        if (defaultInvoiceTitle) {
          invoice_title_id.onChange(defaultInvoiceTitle.id)
        }
        this.setState({
          invoiceTitles,
          defaultInvoiceTitle
        })
      })
  },

  changeFormalSerialNumber() {
    const {
      fields: { serial },
      location,
      organization_id
    } = this.props
    api
      .getFormalSubSerialNumber({ location_id: location.id, organization_id })
      .then(({ json }) => {
        serial.onChange(json.serial || '')
      })
  },

  renewDeskList(subscription) {
    const {
      fields: {
        deskList,
        deposit,
        deposit_cycles,
        deposit_due_date,
        end_date,
        merge_deposit_into_renting,
        start_date,
        signed_at,
        deposit_diff,
        sales_tax_id,
        house_fee_tax_id,
        sales_resources_amount,
        crm_source_id,
        crm_source_fee,
        crm_source_notes,
        note
      },
      status,
      contract_id
    } = this.props
    const {
      areaSetting: { m2_per_desk, house_fee_per_m2 }
    } = this.state
    const {
      sales_areas,
      sales_renting_phases,
      tax,
      house_fee_tax,
      sales_resources
    } = subscription
    const salesResourcesAmount = (
      _.find(sales_resources, { resource_type: 'point' }) || {}
    ).points
    // sales_resources && sales_resources[0] && sales_resources[0].points
    let extendMonthly = 0
    let yetAreaIds = []
    this.setState({
      monthly:
        (sales_renting_phases &&
          sales_renting_phases[0] &&
          sales_renting_phases[0].recurring_amount) ||
        subscription.deposit,
      old_deposit: subscription.deposit
    })
    sales_tax_id.onChange(tax ? tax.id : sales_tax_id.initialValue)
    house_fee_tax_id.onChange(house_fee_tax ? house_fee_tax.id : '')
    deposit_due_date.onChange(subscription.deposit_due_date)
    merge_deposit_into_renting.onChange(subscription.merge_deposit_into_renting)
    sales_resources_amount.onChange(salesResourcesAmount)
    crm_source_id.onChange(subscription.crm_source_id)
    crm_source_fee.onChange(subscription.crm_source_fee)
    crm_source_notes.onChange(subscription.crm_source_notes)
    note.onChange(subscription.note)
    if (status == 'extend') {
      start_date.onChange(moment(subscription.end_date).add(1, 'days'))
      signed_at.onChange(moment(new Date()).format('YYYY-MM-DD'))
    } else {
      end_date.onChange(formatYearDayEN(subscription.end_date))
      start_date.onChange(formatYearDayEN(subscription.start_date))
      signed_at.onChange(formatYearDayEN(subscription.signed_at))
    }
    if (status == 'update') {
      deposit_diff.onChange(0)
    }
    deposit.onChange(subscription.deposit)
    deposit_cycles.onChange(subscription.deposit_cycles)
    sales_areas &&
      sales_areas.forEach(desk => {
        deskList.addField(getPhase(desk))
        extendMonthly += getPhase(desk).totalRent
        if (desk.area_id) {
          yetAreaIds.push(desk.area_id)
        }
      })
    this.setState({
      yetAreaIds
    })
    if (contract_id && status == 'extend') {
      this.setState({
        monthly: extendMonthly
      })
    }
    function getPhase(desk) {
      const { desks } = desk
      let deskNums = []
      desks &&
        desks.length > 0 &&
        desks.map(d => {
          deskNums.push(d.serial_number)
        })
      let rent = parseFloat(desk.total_price) - parseFloat(desk.house_fee) || 0
      let totalRent = desk.total_price || 0
      let tenement = desk.house_fee || 0
      let price_per_m2 = 0
      let office_price = parseFloat(desk.price * desk.units) || ''
      let area_id = desk.area_id || null
      let area_name = (desk.area && desk.area.name) || desk.area_name || null
      let area_size = (desk.area && desk.area.size) || null
      let desk_nums = deskNums.join(',') || ''
      let desk_ids =
        (desk.desk_ids &&
          (typeof desk.desk_ids === 'string'
            ? desk.desk_ids
            : desk.desk_ids.join(','))) ||
        ''
      let square =
        (desk.extra_info && desk.extra_info.m2_per_desk) || m2_per_desk
      let propertyFee =
        (desk.extra_info && desk.extra_info.house_fee_per_m2) ||
        house_fee_per_m2
      let unitsNew =
        desk.area_type === 'private_office' && desk.charge_type === 'by_area'
          ? desk.m2
          : desk.units
      price_per_m2 = parseFloat(desk.price / unitsNew).toFixed(2)

      if (
        desk.area_type == 'public_office' ||
        desk.area_type == 'flexible_office'
      ) {
        totalRent = desk.price * desk.units
        tenement = desk.units * m2_per_desk * house_fee_per_m2
        rent =
          parseFloat(desk.price * desk.units) -
          parseFloat(desk.units * house_fee_per_m2 * m2_per_desk)
      } else if (
        desk.area_type == 'private_office' &&
        desk.charge_type == 'by_area'
      ) {
        rent = desk.price
        tenement = desk.units * house_fee_per_m2
        totalRent =
          parseFloat(desk.price) + parseFloat(desk.units * house_fee_per_m2)
      } else if (
        desk.area_type == 'private_office' &&
        desk.charge_type == 'by_desk'
      ) {
        totalRent =
          parseFloat(desk.price * desk.units) +
          parseFloat(desk.units * m2_per_desk * house_fee_per_m2)
        tenement = desk.units * m2_per_desk * house_fee_per_m2
        rent = parseFloat(desk.price * desk.units)
        office_price = desk.price
      }
      return Object.assign({}, desk, {
        price: desk.price,
        units: unitsNew,
        area_type:
          desk.area_type == 'public_office'
            ? 'public_office'
            : desk.area_type == 'private_office'
            ? 'private_office'
            : 'flexible_office',
        charge_type:
          desk.area_type == 'public_office' ||
          desk.area_type == 'flexible_office'
            ? 'by_desk'
            : desk.charge_type == 'by_desk'
            ? 'by_desk'
            : 'by_area',
        area_id,
        area_name,
        area_size,
        rent,
        totalRent,
        square,
        propertyFee,
        tenement,
        price_per_m2,
        office_price,
        desk_nums,
        desk_ids,
        desk_allot_type: desk_ids ? 'manual' : 'auto'
      })
    }
  },

  // create subscription
  createSubscriptionForm(model) {
    const {
      fields: { sales_renting_phases,location_provider_id },
      loc_id,
      org_id,
      createSubscriptionAction,
      apiCallFailureActions,
      subscription_id,
      status,
      updateSubscriptionAction,
      nextCallBack,
      contract_id,
      isContract
    } = this.props
    const { defaultProvider } = this.state
    let deskList = model.deskList
    let isAreaNoFount = false
    isAreaNoFount = deskList.find(
      desk => !desk.area_id && desk.area_type != 'flexible_office'
    )
    if (isAreaNoFount) {
      return apiCallFailureActions({
        status: 422,
        message: '请选择分区'
      })
    }
    let startDate = moment(model.start_date).toDate()
    let signedDate = moment(model.signed_at).toDate()
    if (startDate.getTime() - signedDate.getTime() < 0) {
      return apiCallFailureActions({
        status: 'error',
        message: '合同签约时间不可以晚于合同开始时间'
      })
    }
    let allDesk = this.disposeDeskList(deskList)

    delete model.monthly_renting_fees

    if (model.sales_tax_id == -1) {
      delete model.sales_tax_id
    }

    if (model.house_fee_tax_id == -1) {
      delete model.house_fee_tax_id
    }

    model.start_date = this.disposeDateFormat(model.start_date)
    model.end_date = this.disposeDateFormat(model.end_date)
    model.signed_at = this.disposeDateFormat(model.signed_at)
    model.deposit_due_date = this.disposeDateFormat(model.deposit_due_date)
    if (
      model.merge_deposit_into_renting &&
      model.sales_renting_phases &&
      model.sales_renting_phases.length > 0
    ) {
      let phasesFirstDate = model.sales_renting_phases[0].recurring_due_date
      model.deposit_due_date = phasesFirstDate
        ? this.disposeDateFormat(phasesFirstDate)
        : ''
    }

    model.sales_renting_phases.map(phase => {
      phase.total_amount = parseFloat(phase.total_amount)
      phase.recurring_due_date = this.disposeDateFormat(
        phase.recurring_due_date
      )
      phase.start_date = this.disposeDateFormat(phase.start_date)
      phase.end_date = this.disposeDateFormat(phase.end_date)
      return phase
    })
    // 判断资源类型
    if (model.sales_resources_amount) {
      model.sales_resources.push({
        resource_type: 'point',
        cycle_unit: 'monthly',
        amount: model.sales_resources_amount,
        cycle_fee_type: undefined,
        note: undefined,
        sales_tax_id: undefined,
        cycle_fee: undefined
      })
    }
    delete model.sales_resources_amount

    delete model.deskList
    model = this.deleteUselessModel(model)
    if (isContract) {
      model.subscription_type = 'no_sales_area'
    }
    if (defaultProvider&&!location_provider_id.value) {
      model.location_provider_id=defaultProvider.id
      location_provider_id.onChange(defaultProvider.id)
    }
    if (status == 'update' && contract_id) {
      const { old_sales_renting_phases } = this.state
      const all_sales_renting_phases = _.concat(
        old_sales_renting_phases,
        sales_renting_phases
      )
      let phases_errors = this.validatePhases(all_sales_renting_phases)
      if (phases_errors) {
        return apiCallFailureActions({
          status: 422,
          message: phases_errors
        })
      }
      return api
        .putSubscriptionsChangeRoom(subscription_id, {
          ...model,
          ...allDesk
        })
        .then(
          json => {
            updateSubscriptionAction.success(json)
            let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
            nextCallBack && nextCallBack(subscription)
            return true
          },
          error => {
            updateSubscriptionAction.failure(error)
          }
        )
    }
    if (status != 'extend' && contract_id && status != 'anew') {
      return api
        .putSubscriptions(subscription_id, {
          ...model,
          ...allDesk
        })
        .then(
          json => {
            updateSubscriptionAction.success(json)
            let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
            nextCallBack && nextCallBack(subscription)
            return true
          },
          error => {
            updateSubscriptionAction.failure(error)
          }
        )
    }
    return api
      .createSubscriptions(loc_id, {
        ...model,
        organization_id: org_id,
        ...allDesk
      })
      .then(
        json => {
          createSubscriptionAction.success(json)
          let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
          nextCallBack && nextCallBack(subscription)
          return null
        },
        error => {
          createSubscriptionAction.failure(error)
        }
      )
  },

  validatePhases(all_sales_renting_phases) {
    let errors = null
    if (all_sales_renting_phases.length == 0) {
      return (errors = '请添加至少一个缴租阶段')
    }
    all_sales_renting_phases.map((phases, index) => {
      let endDate = phases.end_date.value
      let startDate = phases.start_date.value
      if (index != 0) {
        let compareDateOne = moment(startDate).format('YYYY-MM-DD')
        let compareDateTwo = moment(
          all_sales_renting_phases[index - 1].end_date.value
        )
          .add(1, 'days')
          .format('YYYY-MM-DD')
        if (!moment(compareDateOne).isSame(compareDateTwo)) {
          errors = '缴租阶段结束时间和开始时间必须连续'
          return errors
        }
      }
      if (startDate && moment(endDate).diff(moment(startDate)) < 0) {
        errors = '每个缴租阶段结束日期必须大于开始日期'
        return errors
      }
    })
    return errors
  },

  deleteUselessModel(model) {
    const { status } = this.props
    const { oldContract } = this.state
    if (model.templete_id == '-1') {
      delete model.templete_id
    }
    if (status != 'update') {
      delete model.files
      delete model.deposit_diff
      delete model.valid_date
    } else {
      model.files = _.concat(oldContract.files, model.files)
      delete model.location_id
      delete model.organization_id
      delete model.start_date
      delete model.signed_at
      delete model.deposit
      delete model.deposit_cycles
      delete model.deposit_due_date
      delete model.parent_id
      delete model.renting_user_ids
      delete model.templete_id
      delete model.merge_deposit_into_renting
      delete model.serial
      if (model.sales_renting_phases.length == 0) {
        delete model.sales_renting_phases
      }
    }
    return model
  },

  disposeDateFormat(date) {
    return moment(date).format('YYYY-MM-DD')
  },

  disposeDeskList(deskList) {
    let areas = []
    if (deskList) {
      deskList &&
        deskList.forEach(desk => {
          desk.price = parseFloat(
            desk.area_type === 'private_office' &&
              desk.charge_type === 'by_desk'
              ? desk.office_price
              : desk.price
          )
          desk.units = parseFloat(desk.units)
          desk.total_price = parseFloat(desk.totalRent)
          desk.m2_per_desk = parseFloat(desk.square)
          desk.house_fee_per_m2 = parseFloat(desk.propertyFee)
          desk.house_fee = parseFloat(desk.tenement)
          desk.m2 = parseFloat(desk.units)
          delete desk.totalRent
          delete desk.square
          delete desk.propertyFee
          delete desk.rent
          delete desk.area_name
          delete desk.office_price
          if (
            desk.area_type == 'private_office' &&
            desk.charge_type == 'by_area'
          ) {
            desk.price_per_m2 = parseFloat(desk.price_per_m2)
            delete desk.units
          }
          if (desk.area_type == 'public_office') {
            delete desk.price_per_m2
          }
          if (desk.area_type == 'flexible_office') {
            delete desk.house_fee
            delete desk.house_fee_per_m2
            delete desk.m2_per_desk
          }
          if (
            !desk.desk_allot_type ||
            (desk.desk_allot_type && desk.desk_allot_type == 'auto')
          ) {
            delete desk.desk_ids
          }
          delete desk.desk_allot_type
          areas.push(desk)
        })
    }
    return {
      areas: areas
    }
  },

  _calculationDeposit(event, field) {
    if (!field) {
      return
    }

    field.onChange(event) // first emit the change event

    // props.fields only updates after next tick
    setTimeout(() => {
      // then calculate the deposit
      const {
        fields: { deskList, deposit_cycles, deposit, deposit_diff },
        financeNumberFormat,
        status
      } = this.props

      let total = null
      let monthly = null

      deskList.map(desk => {
        let totalRent = parseFloat(desk.totalRent.value)
        total += totalRent
      })

      if (total) {
        monthly = total
        total = total * deposit_cycles.value
        this.setState({
          monthly,
          total
        })
        deposit.onChange(getFloatOfSetting(total, financeNumberFormat))
        if (status == 'update') {
          deposit_diff.onChange(
            getFloatOfSetting(total, financeNumberFormat) -
              this.state.old_deposit
          )
        }
        this.changePhasesResetStatus()
      }
    })
  },

  calculateDeposit() {
    setTimeout(() => {
      // then calculate the deposit
      const {
        fields: { deskList, deposit_cycles, deposit },
        financeNumberFormat
      } = this.props

      let total = null
      let monthly = null

      deskList.map(desk => {
        let totalRent = parseFloat(desk.totalRent.value)
        total += totalRent
      })

      if (total || !deskList.length) {
        monthly = total
        total = total * deposit_cycles.value
        this.setState({
          monthly,
          total
        })
        deposit.onChange(getFloatOfSetting(total, financeNumberFormat))
      }
      this.disposeOfficeAreaName()
    })
  },

  updateRentingPhases(phases, isRemoveAllPhases) {
    const {
      fields: { sales_renting_phases },
      apiCallFailureActions,
      status
    } = this.props
    if (!phases.length) {
      apiCallFailureActions({
        status: 422,
        message: '您的开始日期、结束日期填写无效'
      })
      return
    }
    if (!sales_renting_phases.length) {
      addField()
    } else {
      if (status == 'update' && isRemoveAllPhases) {
        this.removeUpdatePhasesNoCertain()
        addField()
      } else {
        let index = sales_renting_phases.findIndex(phase => {
          return (
            contrastDate(
              phases[0].start_date,
              phase.start_date.value,
              'integer'
            ) < 0
          )
        })
        if (index == -1) {
          addField()
        } else {
          phases &&
            phases.reverse().forEach(phase => {
              sales_renting_phases.addField(getPhase(phase), index)
            })
        }
      }
    }
    if (!isRemoveAllPhases) {
      setTimeout(this.changeFirstPhaseDueDate, 100)
    }

    function addField() {
      phases &&
        phases.forEach(phase => {
          sales_renting_phases.addField(getPhase(phase))
        })
    }
    function getPhase(phase) {
      return Object.assign({}, phase, {
        //id               : phase.id,
        start_date: moment(phase.start_date)
          .clone()
          .hours(12)
          .minutes(0)
          .seconds(0)
          .toDate(),
        end_date: moment(phase.end_date)
          .clone()
          .hours(12)
          .minutes(0)
          .seconds(0)
          .toDate(),
        recurring_amount: phase.recurring_amount,
        recurring_renting_fee: phase.recurring_renting_fee,
        recurring_house_fee: phase.recurring_house_fee,
        total_amount: phase.total_amount,
        total_renting_fee: phase.total_renting_fee,
        total_house_fee: phase.total_house_fee,
        rent_type: phase.rent_type,
        note: phase.note,
        recurring_cycle_fee: phase.recurring_cycle_fee
      })
    }
  },

  changeFirstPhaseDueDate() {
    const {
      fields: { sales_renting_phases, start_date, deposit_due_date },
      invoice_due_day
    } = this.props
    // if (sales_renting_phases.length > 0) {
    //   sales_renting_phases[0].recurring_due_date.onChange(start_date.value)
    // }
  },

  parseRentingPhases(status) {
    const {
      areaSetting: { house_fee_per_m2 },
      monthly,
      oldContract
    } = this.state
    const {
      fields: {
        start_date,
        end_date,
        sales_renting_phases,
        deskList,
        valid_date,
        monthly_renting_fees,
        sales_resources
      },
      enting_phase_recurring_date_compute_way,
      invoice_due_day,
      // enting_phase_recurring_date_compute_day,
      loc_id,
      isContract
    } = this.props
    let endDate = null
    let startDate = null
    let totalTenement = 0
    deskList.map(desk => {
      let tenementNew = 0
      if (desk.area_type.value != 'flexible_office') {
        tenementNew = parseFloat(desk.tenement.value)
      }
      totalTenement += tenementNew
    })
    let sales_renting_phases_exclude_free = sales_renting_phases.filter(
      sale => sale.rent_type.value != 'free'
    )
    if (sales_renting_phases_exclude_free.length > 0) {
      let parEndDate =
        sales_renting_phases_exclude_free[
          sales_renting_phases_exclude_free.length - 1
        ].end_date.value
      startDate =
        (parEndDate &&
          moment(parEndDate)
            .add(1, 'days')
            .clone()
            .hours(12)
            .minutes(0)
            .seconds(0)
            .toDate()) ||
        start_date.value
    } else {
      startDate = start_date.value
    }
    endDate = end_date.value
    if (status == 'update') {
      startDate = moment(valid_date.value).format('YYYY-MM-DD')
    }

    let monthly_renting_fees_arr = []
    monthly_renting_fees.length &&
      monthly_renting_fees.map(json => {
        let value = {
          month: json.month.value,
          recurring_amount: json.recurring_amount.value
        }
        monthly_renting_fees_arr.push(value)
      })

    KBPopover.show(
      <RentingPhasesForm
        loc_id={loc_id}
        callback={this.updateRentingPhases}
        start_date={startDate}
        end_date={endDate}
        subscription_start_date={start_date.value}
        subscription_end_date={end_date.value}
        total={monthly || 0}
        status={status}
        valid_date={valid_date}
        totalTenement={totalTenement}
        oldContract={oldContract}
        house_fee_per_m2={house_fee_per_m2}
        enting_phase_recurring_date_compute_way={
          enting_phase_recurring_date_compute_way
        }
        enting_phase_recurring_date_compute_day={invoice_due_day}
        // enting_phase_recurring_date_compute_day={enting_phase_recurring_date_compute_day}
        sales_renting_phases={sales_renting_phases}
        monthly_renting_fees={monthly_renting_fees_arr}
        sales_resources={sales_resources}
        isC={isContract}
      />
    )
  },

  freePhases() {
    const {
      fields: { start_date, sales_renting_phases, end_date }
    } = this.props
    let endDate = null
    let startDate = null
    let contractEndDate = end_date.value
    if (sales_renting_phases.length > 0) {
      let parEndDate =
        sales_renting_phases[sales_renting_phases.length - 1].end_date.value
      startDate =
        (parEndDate &&
          moment(parEndDate)
            .add(1, 'days')
            .clone()
            .hours(12)
            .minutes(0)
            .seconds(0)
            .toDate()) ||
        start_date.value
    } else {
      startDate = start_date.value
    }
    endDate = moment(startDate)
      .add(1, 'months')
      .subtract(1, 'day')

    KBPopover.show(
      <FreePhasesForm
        callback={this.updateRentingPhases}
        start_date={startDate}
        end_date={endDate}
        subscription_start_date={start_date.value}
        subscription_end_date={end_date.value}
        contractEndDate={contractEndDate}
        sales_renting_phases={sales_renting_phases}
      />
    )
  },
  pushSalesResources(sales, isRemove) {
    const {
      fields: { sales_resources }
    } = this.props
    sales.resource_type = 'cycle_fee'
    sales.cycle_unit = 'monthly'
    sales.amount = undefined
    sales_resources.addField(sales)
  },
  addCharge() {
    const {
      fields: { sales_resources },
      taxes
    } = this.props
    KBPopover.show(
      <PeriodicChargeForm
        callback={this.pushSalesResources}
        taxes={taxes}
        sales_resources={sales_resources}
      />
    )
  },
  removePeriodicCharge(sales, index) {
    const {
      fields: { sales_resources }
    } = this.props
    KBPopover.plugins.confirm('删除周期性收费', '是否确定删除周期性收费?', {
      confirm: () => {
        sales_resources.removeField(index)
        KBPopover.close()
      }
    })
  },
  /**
   * 周期性收费 JSX
   * @returns {XML}
   */
  periodicChargeJSX() {
    const {
      fields: { sales_resources, sales_resources_error },
      taxes
    } = this.props
    const { oldContract } = this.state
    const filterItem = LINE_ITEM_TYPES.filter(
      types =>
        types.name != '会员费' &&
        types.name != '押金' &&
        types.name != '保证金没收'
    )

    filterItem.forEach(item => {
      item.disabled = false
    })
    filterItem.forEach(item => {
      sales_resources.forEach(sales => {
        if (item.id === sales.cycle_fee_type.value) {
          item.disabled = true
        }
      })
    })
    return (
      <fieldset className="fieldset">
        <legend>周期性收费</legend>
        <PeriodicChargeList
          oldContract={oldContract}
          sales_resources={sales_resources}
          removePeriodicCharge={this.removePeriodicCharge}
          filterItem={filterItem}
          taxes={taxes}
        />
        <div className="kb-row">
          <button
            className="bordered-btn"
            type="button"
            onClick={() => {
              this.addCharge()
            }}
            style={{ marginLeft: 0 }}
          >
            <i className="iconfont icon-add" />
            添加周期类型
          </button>
          {sales_resources_error.error && (
            <p className="lr-error">{sales_resources_error.error}</p>
          )}
        </div>
      </fieldset>
    )
  },

  /**
   * 添加交租 JSX
   * @returns {XML}
   */
  phasesJsx() {
    let {
      fields: {
        sales_renting_phases,
        sales_renting_phases_error,
        sales_tax_id,
        valid_date,
        sales_resources
      },
      taxes,
      status,
      isContract
    } = this.props
    const { oldContract, phase_reset_status } = this.state
    const hint = ADD_SUBSCRIPTION
    let total = 0
    let tax = taxes.find(t => t.id == sales_tax_id.value)
    return status != 'update' ? (
      <OrgSubscriptionCreateRentingPhase
        sales_renting_phases={sales_renting_phases}
        sales_renting_phases_error={sales_renting_phases_error}
        removePhases={this.removePhases}
        total={total}
        tax={tax}
        parseRentingPhases={this.parseRentingPhases}
        isShowPhases={status == 'edit' ? true : false}
        oldContract={oldContract}
        freePhases={this.freePhases}
        isContract={isContract}
        sales_resources={sales_resources}
      />
    ) : (
      <OrgSubscriptionEditRentingPhase
        sales_renting_phases={sales_renting_phases}
        sales_renting_phases_error={sales_renting_phases_error}
        removeUpdatePhases={this.removeUpdatePhases}
        oldContract={oldContract}
        tax={tax}
        status={status}
        phase_reset_status={phase_reset_status}
        recordPhasesMessage={this.recordPhasesMessage}
        validDate={valid_date.value}
        parseRentingPhases={this.parseRentingPhases}
        freePhases={this.freePhases}
        isContract={isContract}
        sales_resources={sales_resources}
      />
    )
  },

  changePhasesResetStatus() {
    this.setState({
      phase_reset_status: true
    })
  },

  recordPhasesMessage(obj) {
    this.setState({
      old_sales_renting_phases: obj
    })
  },

  removePhases(type) {
    const {
      fields: { sales_renting_phases }
    } = this.props
    let sales_renting_phases_clone = _.cloneDeep(sales_renting_phases)
    let indexTemp = []
    let d = 0

    KBPopover.plugins.confirm(
      `删除${type == 'normal' ? '缴租阶段' : '免租期'}`,
      `是否确定删除${type == 'normal' ? '缴租阶段' : '免租期'}?`,
      {
        confirm: () => {
          sales_renting_phases_clone.forEach((phase, index) => {
            if (phase.rent_type.value == type) indexTemp.push(index)
          })

          indexTemp.forEach(i => {
            sales_renting_phases.removeField(i - d)
            ++d
          })
          KBPopover.close()
        }
      }
    )
  },

  removeUpdatePhases() {
    const {
      fields: { sales_renting_phases }
    } = this.props
    let sales_renting_phases_clone = _.cloneDeep(sales_renting_phases)
    let indexTemp = []
    let d = 0

    KBPopover.plugins.confirm(`删除缴租阶段`, `是否确定删除缴租阶段`, {
      confirm: () => {
        sales_renting_phases_clone.forEach((phase, index) => {
          indexTemp.push(index)
        })

        indexTemp.forEach(i => {
          sales_renting_phases.removeField(i - d)
          ++d
        })
        KBPopover.close()
      }
    })
  },

  removeUpdatePhasesNoCertain() {
    const {
      fields: { sales_renting_phases }
    } = this.props
    let sales_renting_phases_clone = _.cloneDeep(sales_renting_phases)
    let indexTemp = []
    let d = 0

    sales_renting_phases_clone.forEach((phase, index) => {
      indexTemp.push(index)
    })

    indexTemp.forEach(i => {
      sales_renting_phases.removeField(i - d)
      ++d
    })
  },

  /**
   * 工位信息  添加区域 和 添加工位功能
   * @returns {XML}
   */
  areasJsx() {
    const {
      loc_id,
      location,
      contract_id,
      organization_id,
      fields: { end_date, start_date, deskList_error }
    } = this.props
    const { locationSetting, areaSetting, yetDeskIds, yetAreaIds } = this.state
    let deskList = this.props.fields.deskList
    const hint = ADD_SUBSCRIPTION
    let location_id = (location && location.id) || loc_id
    let deskListPrivate =
      deskList && deskList.length > 0
        ? deskList.filter(desk => desk.area_type.value == 'private_office')
        : []
    let deskListPublic =
      deskList && deskList.length > 0
        ? deskList.filter(desk => desk.area_type.value == 'public_office')
        : []
    let deskListFlexible =
      deskList && deskList.length > 0
        ? deskList.filter(desk => desk.area_type.value == 'flexible_office')
        : []
    return (
      <fieldset className="fieldset">
        <legend>工位设置</legend>
        <div>
          {deskListPublic && deskListPublic.length ? (
            <div className="m-bottom-sm" style={{ paddingLeft: 30 }}>
              <legend style={{ fontSize: 16 }}>开放工位</legend>
              <ul className="region-input-box" style={{ border: 'none' }}>
                {deskListPublic.map((desk, index) => {
                  let indexT = deskList.findIndex(
                    value => value.id.value == desk.id.value
                  )
                  return (
                    <OrgSubscriptionDeskListPublic
                      key={index}
                      organization_id={organization_id}
                      index={indexT}
                      deleteCallBack={this.calculateDeposit}
                      desk={desk}
                      endDateValue={end_date.value}
                      startDateValue={start_date.value}
                      contract_id={contract_id}
                      loc_id={location_id}
                      yetDeskIds={yetDeskIds}
                      locationSetting={Object.assign(
                        {},
                        locationSetting,
                        areaSetting
                      )}
                      calculationDeposit={this._calculationDeposit}
                      disposeOfficeAreaName={this.disposeOfficeAreaName}
                      deskList={deskList}
                    />
                  )
                })}
              </ul>
            </div>
          ) : (
            ''
          )}
          {deskListPrivate && deskListPrivate.length ? (
            <div style={{ paddingLeft: 30 }}>
              <legend style={{ fontSize: 16 }}>封闭工位</legend>
              <ul className="region-input-box" style={{ border: 'none' }}>
                {deskListPrivate.map((desk, index) => {
                  let indexT = deskList.findIndex(
                    value => value.id.value == desk.id.value
                  )
                  if (desk.charge_type.value == 'by_area') {
                    return (
                      <OrgSubscriptionDeskListPrivateOfArea
                        key={index}
                        index={indexT}
                        organization_id={organization_id}
                        desk={desk}
                        yetAreaIds={yetAreaIds}
                        endDateValue={end_date.value}
                        startDateValue={start_date.value}
                        deleteCallBack={this.calculateDeposit}
                        contract_id={contract_id}
                        loc_id={location_id}
                        calculateDeposit={this.calculateDeposit}
                        locationSetting={Object.assign(
                          {},
                          locationSetting,
                          areaSetting
                        )}
                        calculationDeposit={this._calculationDeposit}
                        disposeOfficeAreaName={this.disposeOfficeAreaName}
                        deskList={deskList}
                      />
                    )
                  }
                  return (
                    <OrgSubscriptionDeskListPrivateOfDesk
                      key={index}
                      index={indexT}
                      organization_id={organization_id}
                      endDateValue={end_date.value}
                      startDateValue={start_date.value}
                      desk={desk}
                      yetAreaIds={yetAreaIds}
                      deleteCallBack={this.calculateDeposit}
                      contract_id={contract_id}
                      loc_id={location_id}
                      calculateDeposit={this.calculateDeposit}
                      locationSetting={Object.assign(
                        {},
                        locationSetting,
                        areaSetting
                      )}
                      calculationDeposit={this._calculationDeposit}
                      disposeOfficeAreaName={this.disposeOfficeAreaName}
                      deskList={deskList}
                    />
                  )
                })}
              </ul>
            </div>
          ) : (
            ''
          )}
          {deskListFlexible && deskListFlexible.length ? (
            <div style={{ paddingLeft: 30 }}>
              <legend style={{ fontSize: 16 }}>灵活工位</legend>
              <ul className="region-input-box" style={{ border: 'none' }}>
                {deskListFlexible.map((desk, index) => {
                  let indexT = deskList.findIndex(
                    value => value.id.value == desk.id.value
                  )
                  return (
                    <OrgSubscriptionDeskListPublic
                      type="flexible_office"
                      key={index}
                      organization_id={organization_id}
                      index={indexT}
                      deleteCallBack={this.calculateDeposit}
                      desk={desk}
                      endDateValue={end_date.value}
                      startDateValue={start_date.value}
                      contract_id={contract_id}
                      loc_id={location_id}
                      yetDeskIds={yetDeskIds}
                      locationSetting={Object.assign(
                        {},
                        locationSetting,
                        areaSetting
                      )}
                      calculationDeposit={this._calculationDeposit}
                      disposeOfficeAreaName={this.disposeOfficeAreaName}
                      deskList={deskList}
                    />
                  )
                })}
              </ul>
            </div>
          ) : (
            ''
          )}

          {/* {
            deskListFlexible && deskListFlexible.length ? (
              <div style={{ paddingLeft: 30 }}>
                <legend style={{ fontSize: 16 }}>灵活工位</legend>
                <ul className='region-input-box' style={{ border: 'none' }}>
                  {
                    deskListFlexible.map((desk, index) => {
                      let indexT = deskList.findIndex(value => value.id.value == desk.id.value)
                      return (
                        <OrgSubscriptionDeskListFlexible
                          key={index}
                          index={indexT}
                          organization_id={organization_id}
                          deleteCallBack={this.calculateDeposit}
                          desk={desk}
                          contract_id={contract_id}
                          loc_id={location_id}
                          locationSetting={Object.assign({}, locationSetting, areaSetting)}
                          calculationDeposit={this._calculationDeposit}
                          deskList={deskList} />
                      )
                    })
                  }
                </ul>
              </div>
            ) : ''
          } */}
        </div>
        <div className="m-bottom-sm">
          <button
            className="bordered-btn"
            style={{ marginLeft: 0 }}
            type="button"
            onClick={this.addPrivateOfArea}
          >
            <i className="iconfont icon-add" />
            添加封闭工位
          </button>
          <button
            className="bordered-btn m-left-sm"
            style={{ marginLeft: 0 }}
            type="button"
            onClick={this.addPublicOfArea}
          >
            <i className="iconfont icon-add" />
            添加开放工位
          </button>
          <button
            className="bordered-btn m-left-sm"
            style={{ marginLeft: 0 }}
            type="button"
            onClick={this.addFlexible}
          >
            <i className="iconfont icon-add" />
            添加灵活工位
          </button>
        </div>
        <div>
          {deskList_error.touched && deskList_error.error && (
            <p className="lr-error">{deskList_error.error}</p>
          )}
        </div>
      </fieldset>
    )
  },

  addPublicOfArea() {
    const {
      loc_id,
      fields: { end_date, start_date },
      apiCallFailureActions
    } = this.props
    if (!start_date.value || !end_date.value) {
      return apiCallFailureActions({
        status: 'error',
        message: '请选择终止日期'
      })
    }
    KBPopover.show(
      <OrgSubscriptionAreasForm
        type="public"
        charge_type="by_desk"
        loc_id={loc_id}
        callBack={areas => this.addPublic(areas, 'public_office')}
        endDateValue={end_date.value}
        startDateValue={start_date.value}
      />
    )
  },
  // addFlexible() {
  //   const deskList = this.props.fields.deskList
  //   deskList.addField({
  //     id: 2000 + deskList.length,
  //     price: '',
  //     units: '',
  //     area_type: 'flexible_office',
  //     totalRent: 0,
  //     charge_type: 'by_desk'
  //   })
  // },
  addFlexible() {
    const {
      loc_id,
      areaRentType,
      fields: { end_date, start_date },
      apiCallFailureActions,
      contract_id
    } = this.props
    const { yetAreaIds } = this.state
    if (!start_date.value || !end_date.value) {
      return apiCallFailureActions({
        status: 'error',
        message: '请选择终止日期'
      })
    }
    if (areaRentType == 'both') {
      KBPopover.show(
        <SelectChargeTypeForm
          charge_type={areaRentType}
          callBack={type => this.certainChargeType(type, 'flexible_office')}
        />
      )
      return
    }
    KBPopover.show(
      <OrgSubscriptionAreasForm
        type="flexible_office"
        charge_type={areaRentType}
        loc_id={loc_id}
        contract_id={contract_id}
        // callBack={(areas, areaRentType) => this.addPrivate(areas, areaRentType, 'flexible_office')}
        callBack={areas => this.addPublic(areas, 'flexible_office')}
        yetAreaIds={yetAreaIds}
        endDateValue={end_date.value}
        startDateValue={start_date.value}
      />
    )
  },

  addPublic(areas, type) {
    const {
      fields: { end_date }
    } = this.props
    let deskList = this.props.fields.deskList
    let {
      areaSetting: { m2_per_desk, house_fee_per_m2 }
    } = this.state
    let isAfterToday = moment(end_date.value).isAfter(moment().toDate())
    areas &&
      areas.map(area => {
        return deskList.addField({
          id: deskList.length,
          area_id: area.id,
          area_name: area.name,
          area_size: isAfterToday ? area.free_desks_count : area.desks_count,
          price: area.listing_price || 0,
          lowest_price: area.lowest_price || 0,
          units: '',
          // area_type: 'public_office',
          area_type: type || 'public_office',
          charge_type: 'by_desk',
          tenement: 0,
          rent: 0,
          totalRent: 0,
          square: m2_per_desk || 0,
          propertyFee: house_fee_per_m2 || 0,
          desk_ids: '',
          desk_allot_type: 'auto',
          desk_nums: ''
        })
      })
    this.disposeOfficeAreaName()
  },

  certainChargeType(type, deskType) {
    const {
      loc_id,
      areaRentType,
      fields: { end_date, start_date },
      apiCallFailureActions,
      contract_id
    } = this.props
    const { yetAreaIds } = this.state
    console.log(123, type, deskType);
    KBPopover.show(
      <OrgSubscriptionAreasForm
        type={deskType}
        charge_type={type}
        loc_id={loc_id}
        contract_id={contract_id}
        callBack={this.addPrivate}
        yetAreaIds={yetAreaIds}
        endDateValue={end_date.value}
        startDateValue={start_date.value}
      />
    )
  },

  addPrivateOfArea() {
    const {
      loc_id,
      areaRentType,
      fields: { end_date, start_date },
      apiCallFailureActions,
      contract_id
    } = this.props
    const { yetAreaIds } = this.state
    if (!start_date.value || !end_date.value) {
      return apiCallFailureActions({
        status: 'error',
        message: '请选择终止日期'
      })
    }
    if (areaRentType == 'both') {
      KBPopover.show(
        <SelectChargeTypeForm
          charge_type={areaRentType}
          callBack={type => this.certainChargeType(type, 'private')}
        />
      )
      return
    }
    KBPopover.show(
      <OrgSubscriptionAreasForm
        type="private"
        charge_type={areaRentType}
        loc_id={loc_id}
        contract_id={contract_id}
        callBack={this.addPrivate}
        yetAreaIds={yetAreaIds}
        endDateValue={end_date.value}
        startDateValue={start_date.value}
      />
    )
  },

  addPrivate(areas, areaRentType, type) {
    const {
      fields: { end_date }
    } = this.props
    const deskList = this.props.fields.deskList
    let {
      areaSetting: { m2_per_desk, house_fee_per_m2 }
    } = this.state
    let isAfterToday = moment(end_date.value).isAfter(moment().toDate())
    areas &&
      areas.map(area => {
        return deskList.addField({
          id: 1000 + deskList.length,
          area_id: area.id,
          area_name: area.name,
          area_size: isAfterToday ? area.free_desks_count : area.desks_count,
          price: area.listing_price || 0,
          units: areaRentType == 'by_area' ? area.size : area.free_desks_count,
          area_type: type || 'private_office',
          charge_type: areaRentType,
          tenement:
            areaRentType == 'by_area'
              ? parseFloat(area.size * house_fee_per_m2).toFixed(2)
              : parseFloat(
                  area.free_desks_count * m2_per_desk * house_fee_per_m2
                ).toFixed(2),
          rent: 0,
          totalRent:
            areaRentType == 'by_area'
              ? parseFloat(area.size * house_fee_per_m2).toFixed(2)
              : parseFloat(
                  area.free_desks_count * m2_per_desk * house_fee_per_m2
                ).toFixed(2),
          square: m2_per_desk || 0,
          propertyFee: house_fee_per_m2 || 0,
          price_per_m2: 0,
          office_price: area.listing_price || 0,
          lowest_price: area.lowest_price || 0,
          desk_ids: '',
          desk_allot_type: 'auto',
          desk_nums: ''
        })
      })
    this.disposeOfficeAreaName()
  },

  _rentingPhaseDate(startDate, endDate) {
    const {
      fields: { start_date, end_date, signed_at, sales_renting_phases },
      status
    } = this.props

    if (sales_renting_phases.length > 0) {
      let first = sales_renting_phases[0]
      let last = sales_renting_phases[sales_renting_phases.length - 1]
      if (status != 'update') {
        startDate && first.start_date.onChange(startDate)
      }
      endDate && last.end_date.onChange(endDate)
    }
  },

  _changeStartDate(day) {
    const {
      fields: { end_date, deposit_due_date },
      deposit_pay_offset,
      init_deposit_due_date
    } = this.props
    const { timeOffset } = this.state
    if (timeOffset) {
      let defaultEndDate = moment(day)
        .add(timeOffset, 'month')
        .subtract(1, 'day')
        .toDate()
      let endDateMonth = moment(defaultEndDate).month() + 1
      let startDateNum = moment(day).date()
      let isBigMonth = BIG_MONTH.find(month => month == endDateMonth)
      if (!isBigMonth && startDateNum == 31) {
        defaultEndDate = moment(day)
          .add(timeOffset, 'month')
          .toDate()
      }
      end_date.onChange(defaultEndDate)
    }
    //如果当前时间大于默认时间 取当前时间 否则取默认时间
    if (moment(init_deposit_due_date).diff(moment(day), 'days') >= 0) {
      deposit_due_date.onChange(init_deposit_due_date)
    } else {
      deposit_due_date.onChange(day)
    }

    // deposit_due_date.onChange(moment(day).subtract(deposit_pay_offset, 'days').toDate())
    this._rentingPhaseDate(day)
  },

  _changeEndDate(day) {
    const {
      fields: { start_date, end_date, signed_at, sales_renting_phases }
    } = this.props
    const endTime = day
    let startTime = ''
    startTime = start_date.value

    if (day && startTime && moment(startTime).isBefore(endTime)) {
      this._rentingPhaseDate(startTime, endTime)
    }
    this.changePhasesResetStatus()
  },

  countEndDate(month) {
    const {
      fields: { start_date, end_date }
    } = this.props
    let start_data_value = start_date.value || moment().format('YYYY-MM-DD')
    if (typeof start_data_value == 'string') {
      start_data_value = start_data_value + ' 12:00:00'
    }
    let endDateValue = moment(start_data_value)
      .add(month, 'month')
      .subtract(1, 'day')
      .toDate()
    let endDateMonth = moment(endDateValue).month() + 1
    let isBigMonth = BIG_MONTH.find(month => month == endDateMonth)
    let startDateNum = moment(start_data_value).date()
    if (!isBigMonth && startDateNum == 31) {
      endDateValue = moment(start_data_value)
        .add(month, 'month')
        .toDate()
    }
    end_date.onChange(endDateValue)
    this.setState({ timeOffset: month })
  },

  // 有效日期
  effectiveDateJsx() {
    const {
      fields: { valid_date, end_date, start_date },
      status
    } = this.props
    const hint = ADD_SUBSCRIPTION
    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    if (status != 'update') {
      return ''
    }

    return (
      <fieldset className="fieldset">
        <legend>有效日期</legend>
        <div className="kb-row kb-form-66 kb-ps">
          <KBDateInput
            defaultYear={2010}
            {...halfClass}
            field={start_date}
            value={start_date.value}
            title="起始日期"
            ref="startDate"
            disabled="disabled"
          />
          <KBDateInput
            defaultYear={2010}
            {...halfClass}
            field={end_date}
            value={end_date.value}
            titleClass="label-rt"
            title="终止日期"
            ref="endDate"
            onChange={this._changeEndDate}
          />
        </div>
        <div className="kb-row kb-form-66 kb-ps">
          <KBDateInput
            defaultYear={2010}
            {...halfClass}
            field={valid_date}
            title="有效日期"
            value={valid_date.value}
          />
          <div
            style={{
              position: 'absolute',
              bottom: '-20px',
              left: 0,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-warning.png"
              alt="icon"
              style={{ width: 15, marginRight: 5 }}
            />
            <span style={{ color: '#999' }}>修改内容在该指定日期以后生效</span>
          </div>
        </div>
      </fieldset>
    )
  },

  /**
   * 时间期限JSX
   * @returns {XML}
   */
  timeJsx() {
    const {
      fields: { start_date, end_date, signed_at, sales_renting_phases },
      status
    } = this.props
    const hint = ADD_SUBSCRIPTION
    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    if (status == 'update') {
      return ''
    }

    return (
      <fieldset className="fieldset">
        <legend>合同日期</legend>
        <div className="kb-row kb-form-66 kb-ps">
          <KBDateInput
            defaultYear={2010}
            {...halfClass}
            hint={hint.sign_date}
            field={signed_at}
            title="签约日期"
            value={signed_at.value}
          />
        </div>
        <div className="kb-row kb-form-66">
          <KBDateInput
            defaultYear={2010}
            {...halfClass}
            hint={hint.start_date}
            field={start_date}
            title="起始日期"
            isRequired={true}
            value={start_date.value}
            onChange={this._changeStartDate}
          />
          <div className="clear-fix f-left">
            <KBDateInput
              defaultYear={2010}
              {...halfClass}
              hint={hint.end_date}
              field={end_date}
              disabledTimeShow={true}
              style={{ width: '100%' }}
              value={end_date.value}
              isRequiredR={true}
              titleClass="label-rt"
              title="终止日期"
              ref="endDate"
              onChange={this._changeEndDate}
            />
            <div className="time-bucket-btn">
              {CONTRACT_END_DATE_BTN.map((json, index) => {
                return (
                  <span
                    key={index}
                    onClick={this.countEndDate.bind(null, json.id)}
                  >
                    {json.name}
                  </span>
                )
              })}
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  // 办公地点
  officeNameJsx() {
    const {
      fields: { office_area_name }
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend>办公地点</legend>
        <KBTextarea
          label="true"
          placeholder="输入公司办公地点"
          className="kb-form-group kb-form-alone kb-div-100"
          field={office_area_name}
          value={office_area_name.value}
          txtStyle={{ height: 120 }}
        />
      </fieldset>
    )
  },

  disposeOfficeAreaName(type) {
    //flexible_office
    setTimeout(() => {
      const {
        fields: { deskList, office_area_name },
        location
      } = this.props
      let areaNameStr = ''
      let publicNum = 0
      let privateNum = 0
      let totalNum = 0
      let yetDeskIds_new = []
      let yetAreaIds_new = []
      let publicNumArray = []
      let privateNumArray = []
      let locationAddress = (location && location.physical_address) || ''
      deskList.map(desk => {
        if (
          desk.area_type.value == 'private_office' ||
          desk.area_type.value == 'flexible_office'
        ) {
          privateNumArray.push(desk.area_name.value)
          yetAreaIds_new.push(desk.area_id.value)
          if (desk.charge_type.value == 'by_desk') {
            privateNum = privateNum + parseFloat(desk.units.value || 0)
          }
        }
        if (desk.area_type.value == 'public_office') {
          publicNum = publicNum + parseFloat(desk.units.value || 0)
          if (desk.desk_allot_type.value == 'manual') {
            let deskIdsValue =
              desk.desk_ids.value && desk.desk_ids.value.split(',')
            publicNumArray.push(desk.desk_nums.value)
            deskIdsValue &&
              deskIdsValue.length > 0 &&
              deskIdsValue.map(d_id => {
                yetDeskIds_new.push(d_id)
              })
          }
        }
      })
      totalNum = publicNum + privateNum
      this.setState({
        yetAreaIds: yetAreaIds_new,
        yetDeskIds: yetDeskIds_new
      })
      areaNameStr =
        locationAddress +
        privateNumArray.join(',') +
        (publicNum > 0 ? `  开放工位${publicNumArray.join(',')}` : '') +
        (totalNum > 0 ? `共${totalNum}个工位` : '')
      office_area_name.onChange(areaNameStr)
    })
  },

  // 合同备注
  nodeJsx() {
    const {
      fields: { note }
    } = this.props
    const errorMsg = note.touched && note.error
    const hint = ADD_SUBSCRIPTION
    return (
      <fieldset className="fieldset">
        <legend>合同备注</legend>
        <KBTextarea
          label="true"
          placeholder="备注"
          className="kb-form-group kb-form-alone kb-div-100"
          field={note}
          value={note.value}
        />
        {errorMsg ? (
          <p className="lr-error"> {errorMsg} </p>
        ) : (
          <p
            style={{ color: '#c8c8c8', fontSize: 12, padding: '2px 0 0 11px' }}
          >
            {hint.remark}
          </p>
        )}
      </fieldset>
    )
  },

  // 合同编号
  serialJsx() {
    const {
      fields: { serial },
      status
    } = this.props
    if (status == 'update') {
      return ''
    }
    return (
      <fieldset className="fieldset">
        <legend>合同编号</legend>
        <div className="kb-row">
          <KBInput
            className="kb-form-group kb-form-c18 kb-form-alone"
            disabled={true}
            field={serial}
            title="合同编号"
          />
        </div>
      </fieldset>
    )
  },

  // 税率设置
  taxesJsx() {
    const {
      fields: { sales_tax_id, house_fee_tax_id },
      taxes
    } = this.props

    // const orderTaxesGroup = taxes.sort((o) => {
    //   if (o.rate === 0.06) {
    //     return -1
    //   } else {
    //     return 1
    //   }
    // })

    // const getDefaultServcieTaxes = {
    //   defaultValue:isRateSixPa ? -1: null,
    //   defaultName:isRateSixPa ? '无':null,
    //   arrayData: isRateSixPa ? taxes : orderTaxesGroup
    // }

    return (
      <fieldset className="fieldset">
        <legend>税率设置</legend>
        <div className="kb-row kb-form-66 kb-ps">
          <KBSelect
            // {...getDefaultServcieTaxes}
            arrayData={taxes}
            isRequired={true}
            title="会员费税率"
            className="kb-form-group kb-form-c39"
            field={sales_tax_id}
          />
          <KBSelect
            isRequired={true}
            title="物业费税率"
            className="kb-form-group kb-form-c48"
            defaultValue="-1"
            defaultName="无"
            field={house_fee_tax_id}
            arrayData={taxes}
            titleClass="label-rt"
          />
        </div>
      </fieldset>
    )
  },

  /**
   * 计算押金 JSX
   * @returns {XML}
   */
  depositJsx() {
    const {
      fields: {
        deposit,
        deposit_cycles,
        deposit_due_date,
        merge_deposit_into_renting,
        deposit_diff
      },
      financeNumberFormat,
      status
    } = this.props
    return (
      <fieldset className="fieldset">
        <legend>押金信息</legend>
        {status == 'update' ? (
          <div className="kb-row kb-form-c48 kb-ps">
            <div className="kb-form-group kb-div-c39">
              <label>押金</label>
              <div>
                <select
                  name="deposit"
                  {...deposit_cycles}
                  onChange={event =>
                    this._calculationDeposit(event, deposit_cycles)
                  }
                  disabled={status == 'update' ? 'disabled' : false}
                >
                  {RECURRING_CYCLES.map((json, index) => {
                    return (
                      <option value={json.id} key={index}>
                        {json.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="kb-form-group kb-form-66">
              <div className="kb-form-group kb-div-c48">
                <label className="label-rt">
                  <span className="must-fillR">*</span>押金总金额
                </label>
                <div>
                  <input
                    type="text"
                    className="kb-input"
                    value={deposit.value||''}
                    {...deposit}
                    onChange={this.depositChange}
                  />
                  {deposit.touched && deposit.error && (
                    <p className="lr-error"> {deposit.error} </p>
                  )}
                </div>
              </div>
              <div className="kb-form-group kb-div-c48">
                <label className="label-rt">
                  <span className="must-fillR">*</span>押金差额
                </label>
                <div>
                  <input
                    type="text"
                    className="kb-input"
                    value={deposit_diff.value||''}
                    {...deposit_diff}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="kb-row kb-form-66 kb-ps">
            <div className="kb-form-group kb-div-c39">
              <label>押金</label>
              <div>
                <select
                  name="deposit"
                  {...deposit_cycles}
                  onChange={event =>
                    this._calculationDeposit(event, deposit_cycles)
                  }
                  disabled={status == 'update' ? 'disabled' : false}
                >
                  {RECURRING_CYCLES.map((json, index) => {
                    return (
                      <option value={json.id} key={index}>
                        {json.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="kb-form-group kb-div-c48">
              <label className="label-rt">
                <span className="must-fillR">*</span>押金总金额
              </label>
              <div>
                <input
                  type="text"
                  className="kb-input"
                  value={deposit.value||''}
                  {...deposit}
                />
                {deposit.touched && deposit.error && (
                  <p className="lr-error"> {deposit.error} </p>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className="kb-row "
          style={{ display: 'flex', alignItems: 'center', minHeight: 40 }}
        >
          <span style={{ marginRight: 30, color: '#999', marginLeft: '-3px' }}>
            是否将押金日期与第一缴租周期同步?
          </span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              style={{ marginRight: 10 }}
              value={merge_deposit_into_renting.value||''}
              id="mergeDepositTrue"
              name="mergeDepositTrue"
              disabled={status == 'update' ? 'disabled' : false}
              checked={merge_deposit_into_renting.value ? 'checked' : ''}
              onChange={this.mergeDepositTrue}
            />
            <label
              htmlFor="mergeDepositTrue"
              style={{ marginLeft: 10, marginRight: 20, color: '#999' }}
            >
              是
            </label>
            <input
              type="radio"
              style={{ marginRight: 10 }}
              value={merge_deposit_into_renting.value||''}
              id="mergeDepositFalse"
              name="mergeDepositFalse"
              disabled={status == 'update' ? 'disabled' : false}
              checked={merge_deposit_into_renting.value ? '' : 'checked'}
              onChange={this.mergeDepositFalse}
            />
            <label
              htmlFor="mergeDepositFalse"
              style={{ marginLeft: 10, marginRight: 20, color: '#999' }}
            >
              否
            </label>
          </div>
          {!merge_deposit_into_renting.value ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 10, color: '#999' }}>
                我要自定义押金付款日
              </span>
              <KBDateInput
                defaultYear={2010}
                className="kb-form-group kb-div-c84"
                titleClass="label-rt"
                title={false}
                type="date"
                field={deposit_due_date}
                disabled={status == 'update' ? 'disabled' : false}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </fieldset>
    )
  },

  depositChange(e) {
    const {
      fields: { deposit, deposit_diff }
    } = this.props
    let value = e.target.value
    deposit.onChange(value)
    deposit_diff.onChange(
      parseFloat(value) - parseFloat(this.state.old_deposit) || 0
    )
  },

  mergeDepositTrue() {
    let {
      fields: { merge_deposit_into_renting }
    } = this.props
    merge_deposit_into_renting.onChange(true)
  },

  mergeDepositFalse() {
    let {
      fields: { merge_deposit_into_renting }
    } = this.props
    merge_deposit_into_renting.onChange(false)
  },

  // 上传协议
  filesJsx() {
    const {
      fields: { files },
      status
    } = this.props
    const { oldContract } = this.state
    const fileList = (files && files.value) || []
    let oldFileList = oldContract && oldContract.files

    if (status != 'update') {
      return ''
    }
    return (
      <fieldset className="fieldset">
        <legend>上传文件</legend>
        <div className="kb-row kb-form-210 m-top-sm">
          <KBMoreFilesUpload
            className="kb-form-group kb-form-c39 kb-form-alone"
            text="上传文件"
            uploadedText="上传文件"
            uploadingText="正在上传"
            prefix={config.uploadDir + 'subscriptions/'}
            buttonClass={'bordered-btn m-none'}
            defaultFiles={files.value}
            type="attachment"
            ref="files"
            accept=".doc,.docx,.pdf"
            field={files}
          />
        </div>
        <div className="m-bottom-sm">
          {fileList &&
            fileList.map((json, index) => {
              return (
                <div
                  key={index}
                  style={{
                    marginBottom: 10,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ marginRight: 5, color: '#999' }}>
                    文件名：
                  </span>
                  <KBDownloadPrivacy url={json.file_url}>
                    <a
                      className="color-link c-pointer"
                      style={{ marginRight: 5 }}
                    >
                      {json.name}
                      {json.type == 'source' ? '(原文件)' : ''}
                    </a>
                  </KBDownloadPrivacy>
                  <i
                    className="iconfont delete-file-btn icon-delete"
                    onClick={this.cancelFiles.bind(null, index)}
                  />
                </div>
              )
            })}
        </div>
        <div className="m-bottom-sm">
          <div style={{ marginBottom: 10, color: '#333', fontSize: 16 }}>
            当前合同协议
          </div>
          {oldFileList &&
            oldFileList.map((json, index) => {
              return (
                <div
                  key={index}
                  style={{
                    marginBottom: 10,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ marginRight: 5, color: '#999' }}>
                    文件名：
                  </span>
                  <KBDownloadPrivacy url={json.file_url}>
                    <a
                      className="color-link c-pointer"
                      style={{ marginRight: 5 }}
                    >
                      {json.name}
                      {json.type == 'source' ? '(原文件)' : ''}
                    </a>
                  </KBDownloadPrivacy>
                </div>
              )
            })}
        </div>
      </fieldset>
    )
  },

  cancelFiles(fileIndex) {
    this.refs.files.removeFile(fileIndex)
  },

  /**
   * 合同所在公司会员 JSX
   * @returns {XML}
   */
  membersJsx() {
    const {
      fields: { renting_user_ids },
      status
    } = this.props
    const { membersInContract, selectMembers } = this.state

    if (status != 'extend') {
      return ''
    }

    return membersInContract.length > 0 ? (
      <fieldset className="fieldset">
        <legend>公司会员</legend>
        <ul className="contract-members">
          {membersInContract.map((member, index) => {
            return (
              <OrgSubscriptionMembersList
                key={index}
                renting_user_ids={renting_user_ids}
                callback={this.membersCallback}
                membersInContract={selectMembers}
                member={member}
              />
            )
          })}
        </ul>
      </fieldset>
    ) : (
      ''
    )
  },

  // 选择甲方
  providerJsx() {
    const { locationProviders, subscription } = this.props
    const { defaultProvider } = this.state
    if (!defaultProvider) {
      return null
    }
    return (
      <fieldset className="fieldset">
        <legend>选择收款账户</legend>
        <div className="location-provider">
          <div className="provider-line">
            <div>
              <span>账户信息</span>
              <span>{defaultProvider.info || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>账户开户行</span>
              <span>{defaultProvider.account_bank || '暂无'}</span>
            </div>
            <div>
              <span>账户地址</span>
              <span>{defaultProvider.physical_address || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>账户名</span>
              <span>{defaultProvider.account_name || '暂无'}</span>
            </div>
            <div>
              <span>账号</span>
              <span>{defaultProvider.account_number || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>联系人</span>
              <span>{defaultProvider.contact_name || '暂无'}</span>
            </div>
            <div>
              <span>联系方式</span>
              <span>{defaultProvider.contact_phone || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>统一社会信用代码</span>
              <span>
                {(defaultProvider.business_license_number &&
                  defaultProvider.business_license_number) ||
                  '暂无'}
              </span>
            </div>
            <div>
              <span>SWIFT代码</span>
              <span>
                {(defaultProvider.swift_code && defaultProvider.swift_code) ||
                  '暂无'}
              </span>
            </div>
          </div>
        </div>
        {locationProviders.length > 1 ? (
          <div className="m-top-sm">
            <button
              type="button"
              className="bordered-btn"
              onClick={this.reselectProvider}
              style={{ marginLeft: 0 }}
            >
              重新选择
            </button>
          </div>
        ) : (
          ''
        )}
      </fieldset>
    )
  },

  reselectProvider() {
    const { locationProviders } = this.props
    const { defaultProvider } = this.state
    KBPopover.show(
      <SelectProvidersForm
        locationProviders={locationProviders}
        defaultProvider={defaultProvider}
        callBack={this.changeProviderField}
      />
    )
  },

  changeProviderField(provider) {
    const {
      fields: { location_provider_id }
    } = this.props
    this.setState({
      defaultProvider: provider
    })
    location_provider_id.onChange(provider.id)
  },

  // 选择发票抬头
  invoiceTitleJsx() {
    const { defaultInvoiceTitle, invoiceTitles } = this.state
    if (!defaultInvoiceTitle) {
      return (
        <fieldset className="fieldset">
          <legend>选择发票抬头</legend>
          <div>
            <button
              type="button"
              className="bordered-btn"
              onClick={this.reselectInvoiceTitle}
              style={{ marginLeft: 0 }}
            >
              {invoiceTitles.length == 0 ? '选择发票抬头' : '重新选择'}
            </button>
          </div>
        </fieldset>
      )
    }
    const {
      title,
      tax_num,
      bank,
      bank_account,
      contacts_name,
      phone_num,
      address,
      email,
      invoice_type
    } = defaultInvoiceTitle

    return (
      <fieldset className="fieldset">
        <legend>选择发票抬头</legend>
        <div className="location-provider">
          <div className="provider-line">
            <div>
              <span>发票类别</span>
              <span>
                {invoice_type
                  ? invoice_type == 'common'
                    ? '增值税普通发票'
                    : '增值税专用发票'
                  : '暂无'}
              </span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>发票抬头</span>
              <span>{title || '暂无'}</span>
            </div>
            <div>
              <span>纳税人识别号</span>
              <span>{tax_num || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>开户行</span>
              <span>{bank || '暂无'}</span>
            </div>
            <div>
              <span>银行账号</span>
              <span>{bank_account || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>联系人</span>
              <span>{contacts_name || '暂无'}</span>
            </div>
            <div>
              <span>联系电话</span>
              <span>{phone_num || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>地址</span>
              <span>{address || '暂无'}</span>
            </div>
            <div>
              <span>邮箱</span>
              <span>{email || '暂无'}</span>
            </div>
          </div>
        </div>
        <div className="m-top-sm">
          <button
            type="button"
            className="bordered-btn"
            onClick={this.reselectInvoiceTitle}
            style={{ marginLeft: 0 }}
          >
            {invoiceTitles.length == 0 ? '选择发票抬头' : '重新选择'}
          </button>
        </div>
      </fieldset>
    )
  },

  reselectInvoiceTitle() {
    const { location, org } = this.props
    const { defaultInvoiceTitle, invoiceTitles } = this.state
    KBPopover.show(
      <SelectInvoiceTitleForm
        invoiceTitles={invoiceTitles}
        defaultInvoiceTitle={defaultInvoiceTitle}
        location={location}
        organization={org}
        callBack={this.changeInvoiceTitleField}
        updateInvoiceTitles={this.updateInvoiceTitles}
      />
    )
  },

  updateInvoiceTitles(newTitles) {
    if (!newTitles || !newTitles.length) {
      return null
    }
    this.setState({
      invoiceTitles: newTitles
    })
  },

  changeInvoiceTitleField(invoiceTitle) {
    const {
      fields: { invoice_title_id }
    } = this.props
    this.setState({
      defaultInvoiceTitle: invoiceTitle
    })
    invoice_title_id.onChange(invoiceTitle.id)
  },

  membersCallback(checked, member) {
    const {
      fields: { renting_user_ids }
    } = this.props
    const { selectMembers } = this.state

    let selectMemberArray = Object.assign([], selectMembers)
    let memberIds = []

    if (checked) {
      selectMemberArray.push(member)
      selectMemberArray.length > 0 &&
        selectMemberArray.map(member => {
          memberIds.push(member.id)
        })

      this.setState({
        selectMembers: selectMemberArray
      })
      renting_user_ids.onChange(memberIds)
      return
    }

    let removeIndex = selectMemberArray.findIndex(json => json.id == member.id)

    if (removeIndex == -1) {
      return
    }

    selectMemberArray.splice(removeIndex, 1)
    selectMemberArray.length > 0 &&
      selectMemberArray.map(member => {
        memberIds.push(member.id)
      })

    this.setState({
      selectMembers: selectMemberArray
    })

    renting_user_ids.onChange(memberIds)
  },

  createNewMonthlyRentingFee() {
    const {
      fields: { start_date, end_date, monthly_renting_fees },
      apiCallFailureActions
    } = this.props
    const { monthly } = this.state

    if (!start_date.value || !end_date.value) {
      return apiCallFailureActions({
        status: 'error',
        message: '请选择合同终止日期'
      })
    }

    let oldStartDay =
      monthly_renting_fees &&
      monthly_renting_fees[monthly_renting_fees.length - 1]
    let newStartDay =
      (oldStartDay &&
        oldStartDay.month.value &&
        moment(oldStartDay.month.value)
          .add(1, 'years')
          .format('YYYY-MM-DD')) ||
      moment(start_date.value)
        .add(1, 'years')
        .format('YYYY-MM-DD')

    monthly_renting_fees.addField({
      month: newStartDay,
      recurring_rate: parseFloat(0).toFixed(2),
      recurring_amount: parseFloat(monthly || 0).toFixed(2)
    })
  },

  changeMonthlyRentByRate(e, index) {
    const {
      fields: { monthly_renting_fees }
    } = this.props
    const { monthly } = this.state
    let value = e.target.value || 0
    let numberValue = parseFloat(parseFloat(value) / 100).toFixed(2)
    let amountValue =
      monthly && parseFloat(monthly + monthly * numberValue).toFixed(2)

    monthly_renting_fees[index].recurring_rate.onChange(value)
    monthly_renting_fees[index].recurring_amount.onChange(amountValue)
  },

  // 月会员费调整
  adjustMonthlyRentJsx() {
    const {
      fields: { monthly_renting_fees }
    } = this.props
    const tdStyle = {
      display: 'inline-flex',
      alignItems: 'center'
    }

    return (
      <fieldset className="fieldset">
        <legend>月会员费调整</legend>
        <p className="m-bottom-sm color-six">
          只有合同周期大于一年才能自定义设置单价递增率，只签订一年则无法设置
        </p>
        <div className="m-bottom-sm">
          <button
            className="bordered-btn"
            type="button"
            onClick={() => {
              this.createNewMonthlyRentingFee()
            }}
            style={{ marginLeft: 0 }}
          >
            <i className="iconfont icon-add" />
            新建月会员费调整
          </button>
        </div>
        {monthly_renting_fees.length ? (
          <table className="content-table">
            <tbody>
              {monthly_renting_fees.map((json, index) => {
                return (
                  <tr style={{ borderBottom: 0 }}>
                    <td style={{ paddingBottom: 0 }}>
                      <div style={tdStyle}>
                        <span style={{ marginRight: 15 }}>开始日期</span>
                        <KBDateInput
                          type="date"
                          className="kb-form-group kb-div-c66"
                          field={json.month}
                        />
                      </div>
                    </td>
                    <td
                      style={{ paddingBottom: 0 }}
                      style={{ paddingBottom: 0 }}
                    >
                      <div style={tdStyle}>
                        <span style={{ marginRight: 15 }}>月会员费递增率</span>
                        <KBInput
                          type="number"
                          className=""
                          inputClass="entry-input input-percent kb-input"
                          field={json.recurring_rate}
                          onChange={e => {
                            this.changeMonthlyRentByRate(e, index)
                          }}
                        />
                      </div>
                    </td>
                    <td style={{ paddingBottom: 0 }}>
                      <div style={tdStyle}>
                        <span style={{ marginRight: 15 }}>月会员费</span>
                        <KBInput
                          type="number"
                          className=""
                          inputClass="entry-input input-dollar kb-input"
                          field={json.recurring_amount}
                        />
                      </div>
                    </td>
                    <td style={{ paddingBottom: 0 }}>
                      <i
                        className="iconfont icon-close a-hover m-left"
                        onClick={() => {
                          monthly_renting_fees.removeField(index)
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : null}
      </fieldset>
    )
  },

  /**
   * 合同附赠的资源 JSX
   * @returns {XML}
   */
  salesResourcesJsx() {
    const {
      fields: { sales_resources_amount, sales_resources_error },
      virtual_currency_name
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend>资源设置</legend>
        <div className="kb-row kb-form-66 kb-ps">
          <div className="kb-form-group kb-div-c39">
            <label>赠送{virtual_currency_name}</label>
            <div>
              <input
                type="text"
                className="kb-input"
                value={sales_resources_amount.value||''}
                {...sales_resources_amount}
              />
              {sales_resources_error.error && (
                <p className="lr-error">{sales_resources_error.error}</p>
              )}
              {(sales_resources_amount.touched &&
                sales_resources_amount.error && (
                  <p className="lr-error"> {sales_resources_amount.error} </p>
                )) || (
                <p
                  style={{
                    color: '#c8c8c8',
                    fontSize: 12,
                    padding: '4px 0 0 0'
                  }}
                >
                  每月向公司赠送{virtual_currency_name}
                  数量。月底清空。如果不填则不赠送。
                </p>
              )}
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  changeCrmSourceCallbck(data) {
    const {
      fields: { crm_source_fee, sales_renting_phases },
      sources
    } = this.props
    let contractTotalAmount = 0
    sales_renting_phases &&
      sales_renting_phases.map(
        s => (contractTotalAmount += parseFloat(s.total_amount.value))
      )
    let currentSource = sources.find(s => s.id == data) || {}
    let sourceRate = currentSource.allotment || 0
    crm_source_fee.onChange(
      parseFloat(contractTotalAmount * sourceRate).toFixed(2)
    )
  },

  /**
   * 渠道分成 JSX
   * @returns {XML}
   */
  crmSourceJsx() {
    const {
      fields: { crm_source_id, crm_source_fee, crm_source_notes },
      sources
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend>渠道分成</legend>
        <div className="kb-row kb-form-66 kb-ps">
          <KBSelect
            title="渠道来源"
            className="kb-form-group kb-form-c39"
            defaultValue="-1"
            defaultName="无"
            field={crm_source_id}
            arrayData={sources}
            onChange={this.changeCrmSourceCallbck}
          />
          <KBInput
            title="渠道费用"
            className="kb-form-group kb-form-c48"
            inputClass="input-dollar"
            titleClass="label-rt"
            field={crm_source_fee}
            type="number"
          />
        </div>
        <div className="kb-row">
          <KBInput
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={crm_source_notes}
            title="渠道备注"
          />
        </div>
      </fieldset>
    )
  },

  renderHeader() {
    const { contract_id, subscription, status } = this.props
    const { isCencelled } = this.context
    const isCenceled = isCencelled(subscription)
    if (status == 'edit' || status == 'update' || status == 'anew') {
      return <span className="header-title">编辑合同</span>
    }

    return (
      <span className="header-title">
        {contract_id ? (isCenceled ? '调整合同' : '续约合同') : '添加新合同'}
      </span>
    )
  },

  render() {
    const {
      fields: { start_date, end_date,location_provider_id },
      error,
      submitting,
      handleSubmit,
      contract_id,
      subscription,
      status,
      taxes,
      isContract
    } = this.props
    const { loading } = this.state
    const { isCencelled } = this.context
    const hint = ADD_SUBSCRIPTION
    const isCenceled = isCencelled(subscription)

    const canAdjustMonthlyRent =
      start_date.value &&
      end_date.value &&
      moment(moment(end_date.value).format('YYYY-MM-DD')).isAfter(
        moment(start_date.value)
          .add(1, 'years')
          .format('YYYY-MM-DD'),
        'day'
      )

    return (
      <form
        onSubmit={handleSubmit(this.createSubscriptionForm)}
        autoComplete="off"
      >
        <div className="kb-title" style={{ marginLeft: 30, marginRight: 30 }}>
          {this.renderHeader()}
        </div>
        <KBLoadingContainer loading={loading}>
          <div className="kb-form-container">
            {this.providerJsx()}
            {this.invoiceTitleJsx()}
            {this.effectiveDateJsx()}
            {this.timeJsx()}
            {/* { this.officeNameJsx() } */}
            {isContract ? '' : this.areasJsx()}
            {this.salesResourcesJsx()}
            {this.membersJsx()}
            {canAdjustMonthlyRent && this.adjustMonthlyRentJsx()}
            {taxes && taxes.length > 0 && (isContract ? '' : this.taxesJsx())}
            {isContract ? '' : this.depositJsx()}
            {isContract ? this.periodicChargeJSX() : ''}
            {this.phasesJsx()}
            {this.serialJsx()}
            {!isContract && this.filesJsx()}
            {isContract ? '' : this.crmSourceJsx()}
            {this.nodeJsx()}
            <div className="kb-row m-top-lg">
              <div className="kb-form-fr">
                <KBButton
                  className="certain-btn"
                  type="submit"
                  submitting={submitting}
                  style={{ marginRight: 0 }}
                >
                  {status == 'edit' || status == 'update'
                    ? '编辑并下一步'
                    : '创建并下一步'}
                </KBButton>
              </div>
            </div>
          </div>
        </KBLoadingContainer>
      </form>
    )
  }
})

OrgSubscriptionForm.PropTypes = {
  loc_id: PropTypes.number.isRequired,
  org: PropTypes.object.isRequired,
  status: PropTypes.string
}

function mapStateToProps(state, props) {
  let {
    org_id,
    loc_id,
    location,
    subscription_id,
    opportunity_id,
    status,
    params
  } = props
  let locationSetting = selectors.getLocationSetting(state, loc_id) || {}
  let invoice_due_day = _.get(
    locationSetting,
    'subscription_settings.invoice_due_day',
    15
  )
  let contract_id = subscription_id
  let organization_id = org_id
  let subscription = contract_id
    ? selectors.getEntity(state, 'subscriptions', contract_id)
    : ''
  let currentLocation = selectors.getLocation(state, loc_id)
  let org = selectors.getOrg(state, org_id)
  let spacesSalesSetting = selectors.getSpacesSalesSetting(state) || {}
  let spacesSetting = selectors.getSpaceSetting(state) || {}
  let deposit_pay_offset = spacesSalesSetting.deposit_pay_offset || 0
  let date = `${moment().format('YYYY-MM')}-${invoice_due_day}`
  // let deposit_due_date = moment().subtract(deposit_pay_offset, 'days').toDate()
  let deposit_due_date = moment(date).toDate()
  let financeNumberFormat =
    spacesSetting.settings &&
    spacesSetting.settings.finance_number_format &&
    spacesSetting.settings.finance_number_format
  let taxes = Object.assign([], selectors.getTaxes(state))
  let spaceSetting = selectors.getSpaceSetting(state)
  let areaRentType =
    (locationSetting &&
      locationSetting.location_settings &&
      locationSetting.location_settings.entering_subscription_compute_way) ||
    'by_desk'
  let merge_deposit_into_renting =
    spaceSetting.settings &&
    spaceSetting.settings.merge_deposit_into_renting &&
    spaceSetting.settings.merge_deposit_into_renting == 'true'
      ? true
      : false
  let enting_phase_recurring_date_compute_way =
    (spaceSetting.subscription_settings &&
      spaceSetting.subscription_settings
        .renting_phase_recurring_date_compute_way &&
      spaceSetting.subscription_settings
        .renting_phase_recurring_date_compute_way.type) ||
    'fixed'
  // let enting_phase_recurring_date_compute_day =
  //   (spaceSetting.subscription_settings &&
  //     spaceSetting.subscription_settings
  //       .renting_phase_recurring_date_compute_way &&
  //     (enting_phase_recurring_date_compute_way === 'advance'
  //       ? spaceSetting.subscription_settings
  //           .renting_phase_recurring_date_compute_way.default_advanced || 7
  //       : spaceSetting.subscription_settings
  //           .renting_phase_recurring_date_compute_way.default_fixed || 1)) ||
  //   1
  let locationProviders =
    (currentLocation && currentLocation.location_providers) || []
  let defaultProvider =
    subscription && subscription.provider
      ? subscription.provider
      : locationProviders.length > 0
      ? locationProviders[0]
      : null
  // let state =
  taxes = _.cloneDeep(taxes).map(tax => {
    let name = tax.name
    tax.name = `${name} (税率:${toPercent(tax.rate, 2)})`
    return tax
  })
  let sources = selectors.getCrmSources(state)
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)
  return {
    invoice_due_day,
    location: currentLocation,
    init_deposit_due_date: deposit_due_date,
    locationProviders,
    defaultProvider,
    spacesSalesSetting,
    deposit_pay_offset,
    financeNumberFormat,
    opportunity_id,
    org,
    contract_id,
    subscription,
    organization_id,
    taxes,
    areaRentType,
    loc_id,
    merge_deposit_into_renting,
    // enting_phase_recurring_date_compute_day,
    enting_phase_recurring_date_compute_way,
    sources,
    virtual_currency_name
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createSubscriptionAction: bindActionCreators(
      subscriptionsAction.create,
      dispatch
    ),
    updateSubscriptionAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getSubscriptionAction: bindActionCreators(
      subscriptionsAction.get,
      dispatch
    ),
    getUserActions: bindActionCreators(usersActions.get, dispatch),
    getTaxesAction: bindActionCreators(taxesAction.get, dispatch),
    getLocationInfo: bindActionCreators(locationActions.update, dispatch),
    getOrgAction: bindActionCreators(orgActions.get, dispatch),
    getCrmSourcesActions: bindActionCreators(crmSourcesActions.get, dispatch)
  }
}

const validateDesk = desk => {
  const errors = {}
  // if (valid.isPrice(desk.price)) {
  //   errors.price = '请输入正确的价格, 小数点保留2位'
  // }
  if (valid.required(desk.units)) {
    errors.units = '请输入工位个数'
  }
  if (
    desk.area_size &&
    desk.units > desk.area_size &&
    desk.charge_type == 'by_desk' &&
    desk.area_type != 'flexible_office'
  ) {
    errors.units = '所选分区工位数不足'
  }

  return errors
}

const validatePhases = (phases, index, array) => {
  const errors = {}

  let endDate = phases.end_date
  let startDate = phases.start_date
  let cycles = phases.recurring_cycles

  if (index != 0) {
    if (
      !moment(formatYearDayEN(startDate)).isSame(
        formatYearDayEN(moment(array[index - 1].end_date).add(1, 'days'))
      )
    ) {
      errors.start_date = '缴租阶段结束时间和开始时间必须连续'
    }
  }

  if (startDate && moment(endDate).diff(moment(startDate)) < 0) {
    errors.end_date = '每个缴租阶段结束日期必须大于开始日期'
  }

  return errors
}

const validate = values => {
  const errors = {}
  let endDate = moment(values.end_date).toDate()
  let startDate = moment(values.start_date).toDate()
  // let signedDate = moment(values.signed_at).toDate()

  if (valid.required(values.start_date)) {
    errors.start_date = '请输入合同起始日期'
  }
  if (valid.required(values.end_date)) {
    errors.end_date = '请输入合同终止日期'
  }

  if (
    values.start_date &&
    values.end_date &&
    endDate.getTime() - startDate.getTime() < 0
  ) {
    errors.end_date = '合同截止日期必须大于起始日期'
  }

  // if (values.start_date && values.signed_at && (startDate.getTime() - signedDate.getTime() < 0)) {
  //   errors.signed_at = '合同签约时间不可以晚于合同开始时间'
  // }

  if (valid.required(values.end_date)) {
    errors.end_date = '请输入合同终止日期'
  }

  if (valid.required(values.signed_at)) {
    errors.signed_at = '请输入合同签约日期'
  }

  if (valid.required(values.deposit)) {
    errors.deposit = '请输入合同押金总金额'
  }

  if (!isC) {
    errors.deskList = values.deskList.map(validateDesk)
  }

  if (values.contract_operate_status != 'update') {
    values.sales_renting_phases.sort((a, b) => {
      return contrastDate(a.start_date, b.start_date)
    })
    errors.sales_renting_phases = values.sales_renting_phases.map(
      validatePhases
    )
  }
  if (
    values.contract_operate_status != 'update' &&
    values.sales_renting_phases.length &&
    values.start_date &&
    !moment(formatYearDayEN(values.sales_renting_phases[0].start_date)).isSame(
      formatYearDayEN(values.start_date)
    )
  ) {
    errors.sales_renting_phases_error =
      "合同开始时间必须等于合同周期首'阶段开始'日期"
  }

  if (
    values.contract_operate_status != 'update' &&
    values.sales_renting_phases.length &&
    values.end_date &&
    !moment(
      formatYearDayEN(
        values.sales_renting_phases[values.sales_renting_phases.length - 1]
          .end_date
      )
    ).isSame(formatYearDayEN(values.end_date))
  ) {
    errors.sales_renting_phases_error =
      "合同结束时间必须等于合同周期最后'阶段终止'日期"
  }

  if (values.deskList.length === 0 && !isC) {
    errors.deskList_error = '请选择至少一个工位'
  }

  if ((!values.sales_tax_id || values.sales_tax_id == '-1') && !isC) {
    errors.sales_tax_id = '请选择会员费税率'
  }

  if ((!values.house_fee_tax_id || values.house_fee_tax_id == '-1') && !isC) {
    errors.house_fee_tax_id = '请选择物业费税率'
  }
  if (isC && values.sales_resources.length) {
    if (
      values.contract_operate_status != 'update' &&
      values.sales_renting_phases.length == 0
    ) {
      errors.sales_renting_phases_error = '请添加至少一个缴租阶段'
    }
  } else if (!isC) {
    if (
      values.contract_operate_status != 'update' &&
      values.sales_renting_phases.length == 0
    ) {
      errors.sales_renting_phases_error = '请添加至少一个缴租阶段'
    }
  }

  if (isC && !values.sales_resources_amount && !values.sales_resources.length) {
    errors.sales_resources_error = '请至少设置资源设置或者周期性收费中的一项'
  }
  return errors
}

const formConfig = {
  form: 'OrgSubscriptionForm',
  fields: [
    'valid_date',
    'start_date',
    'end_date',
    'signed_at',
    'deposit',
    'formal',
    'step',
    'opportunity_id',
    'crm_source_id',
    'crm_source_fee',
    'crm_source_notes',
    'deposit_cycles',
    'deposit_due_date',
    'merge_deposit_into_renting',
    'deskList[].id',
    'deskList[].area_id',
    'deskList[].area_name',
    'deskList[].area_size',
    'deskList[].price',
    'deskList[].units',
    'deskList[].area_type',
    'deskList[].tenement',
    'deskList[].rent',
    'deskList[].square', // 平米/工位
    'deskList[].propertyFee', // 物业费
    'deskList[].totalRent',
    'deskList[].charge_type',
    'deskList[].price_per_m2',
    'deskList[].office_price',
    'deskList[].lowest_price',
    'deskList[].desk_allot_type',
    'deskList[].desk_ids',
    'deskList[].desk_nums',
    'deskList_error',
    'office_area_name',
    'sales_renting_phases_error',
    //'sales_renting_phases[].id',
    'sales_renting_phases[].start_date',
    'sales_renting_phases[].end_date',
    'sales_renting_phases[].total_amount',
    'sales_renting_phases[].recurring_amount',
    'sales_renting_phases[].recurring_cycles',
    'sales_renting_phases[].recurring_due_date',
    'sales_renting_phases[].recurring_house_fee',
    'sales_renting_phases[].recurring_renting_fee',
    'sales_renting_phases[].total_renting_fee',
    'sales_renting_phases[].total_house_fee',
    'sales_renting_phases[].rent_type',
    'sales_renting_phases[].note',
    'sales_renting_phases[].recurring_cycle_fee',
    'sales_resources_amount',
    'sales_resources[].resource_type',
    'sales_resources[].cycle_unit',
    'sales_resources[].amount',
    'sales_resources[].cycle_fee_type', // 周期性收费 账单类型
    'sales_resources[].cycle_fee', // 周期性收费 金额
    'sales_resources[].sales_tax_id', // 周期性收费 税率
    'sales_resources[].note', // 周期性收费 备注
    'sales_resources_error',
    'parent_id',
    'sales_tax_id',
    'house_fee_tax_id',
    'renting_user_ids',
    'serial',
    'note',
    'files',
    'deposit_diff',
    'contract_operate_status',
    'location_provider_id',
    'invoice_title_id',
    // monthly_renting_fees
    'monthly_renting_fees[].month',
    'monthly_renting_fees[].recurring_rate',
    'monthly_renting_fees[].recurring_amount'
  ],
  validate: validate,
  touchOnBlur: true
}

OrgSubscriptionForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionForm)

export default OrgSubscriptionForm
