import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { areaActions } from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import MeetingForm from './MeetingForm'
import { AREATYPES_CLASS, AREATYPES_TIPSY, MEETING_DEVICE } from 'app/constants'
import {
  KBLoadingContainer,
  KBPopover,
  KBTipsy,
  KBTableWithoutData
} from 'components'
import * as selectors from 'app/selectors'
import { canAccessLocation } from 'app/reducers/role'
import * as Constants from 'app/constants'
import _ from 'lodash'
import MeetingSearchRoomForm from './MeetingSearchRoomForm'
import BatchUpdateAreas from '../desks/BatchUpdateAreas'
import { toImageProxyDirect } from 'utils/kbUtil'

var MeetingManage = React.createClass({
  displayName: 'MeetingManage',
  getInitialState() {
    return {
      areaTypes: [],
      showType: 'meeting'
    }
  },

  addMeeting() {
    const { loc_id } = this.props
    KBPopover.show(<MeetingForm loc_id={loc_id} />)
  },
  devicesJsx(devices) {
    if (!devices.length) {
      return (
        <div>
          <i />
        </div>
      )
    }
    let deviceAll = []
    devices.map((device, index) => {
      let deviceMessage = {}
      deviceMessage.class = MEETING_DEVICE[device.id]
      deviceMessage.name = device.name
      deviceMessage.icon = device.icon
      deviceAll.push(deviceMessage)
    })
    deviceAll = _.slice(deviceAll, 0, 6)
    return (
      <div className="color-nine">
        {deviceAll.map((device, index) => {
          return (
            <KBTipsy key={index} content={device.name}>
              <img
                style={{
                  marginRight: index + 1 !== deviceAll.length ? 5 : 0,
                  width: 20
                }}
                src={device.icon}
              />
            </KBTipsy>
          )
        })}
        <i />
      </div>
    )
  },

  toMeetingInfo(room) {
    const { routerActions, loc_id } = this.props
    routerActions.push(`/admin/locations/${loc_id}/meeting/info/${room.id}`)
  },

  searchMeetingRoom() {
    const { loc_id } = this.props
    KBPopover.show(<MeetingSearchRoomForm loc_id={loc_id} />)
  },

  setShowType(type) {
    this.setState({
      showType: type
    })
  },

  batchUpdateMeetingRooms() {
    const { meetings, loc_id } = this.props
    KBPopover.show(
      <BatchUpdateAreas areaType="room" loc_id={loc_id} areas={meetings} />
    )
  },

  roomBlockJsx(room, index) {
    const { loc_id } = this.props
    const orderOption = room.order_option
    const roomPrice =
      (orderOption === 'by_day'
        ? room.day_listing_price
        : room.listing_price) || 0
    const unit = orderOption === 'by_day' ? ' /天' : ' /小时'
    const { price_determined } = room
    return (
      <div
        key={index}
        className="meeting-room"
        onClick={this.toMeetingInfo.bind(null, room)}
      >
        {room.images && room.images.length > 0 ? (
          <img
            src={toImageProxyDirect(room.images[0], 500, 300)}
            alt="loading"
            height={150}
          />
        ) : (
            <img
              src={toImageProxyDirect(
                'https://media-dev-ssl.kuban.io/meeting-room-default.png',
                500,
                300
              )}
              width={250}
              height={150}
              alt={room.name}
            />
          )}
        <div className="meeting-content">
          <div
            style={{
              marginTop: 5,
              display: 'inline-flex',
              alignItems: 'center'
            }}
          >
            <span style={{ color: '#7a5137', marginRight: 5 }}>
              {AREATYPES_TIPSY[room.area_type]}
            </span>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              {this.devicesJsx(room.devices)}
              {room.meeting_screens &&
                room.meeting_screens.map((screen, index) => (
                  <Link
                    key={index}
                    to={`/admin/locations/${loc_id}/meeting/theme/${screen.device_id}`}
                    onClick={e => e.stopPropagation()}
                  >
                    <KBTipsy content={`${screen.device_name}`}>
                      <i
                        style={{ marginLeft: 5 }}
                        className="iconfont icon-pad color-nine"
                      />
                    </KBTipsy>
                  </Link>
                ))}
              {room.locks &&
                room.locks.map((lock, index) => (
                  <Link
                    key={index}
                    to={`/admin/locations/${loc_id}/locks?lock_id=${lock.id}`}
                    onClick={e => e.stopPropagation()}
                  >
                    <KBTipsy content={`设备编号:${lock.device_id}`}>
                      <i
                        style={{ marginLeft: 5 }}
                        className="iconfont icon-close2 color-nine"
                      />
                    </KBTipsy>
                  </Link>
                ))}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <h4 className="meeting-room-name">{room.name}</h4>
            {price_determined ? (
              <div style={{ color: '#666' }}>
                ¥
                <span
                  className="color-price"
                  style={{ paddingLeft: 5, fontSize: 16 }}
                >{`${roomPrice}${unit}`}</span>
              </div>
            ) : (
                <div className="color-price" style={{ fontSize: 16 }}>
                  价格需与运营团队商议
                </div>
              )}
          </div>
          {room.capacity ? (
            <div className="meeting-people-num">容纳{room.capacity}人</div>
          ) : (
              ''
            )}
        </div>
      </div>
    )
  },

  render() {
    const { meetings, loc_id, isManager, loadingStatus } = this.props
    const { showType } = this.state
    const meetingRooms = meetings.filter(room => {
      return room.area_type == 'meeting_room'
    })
    const otherRooms = meetings.filter(room => {
      return room.area_type != 'meeting_room' && room.area_type != 'floor'
    })

    if (loadingStatus === 'loading') {
      return (
        <section className="nav-section-container  f-right">
          <KBLoadingContainer loading={true} />
        </section>
      )
    }

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>场地管理</span>
          </h2>
          {isManager ? (
            <span
              className="bordered-btn f-right"
              onClick={this.searchMeetingRoom}
            >
              <i className="iconfont icon-add" />
              添加新预约
            </span>
          ) : (
              ''
            )}
          {isManager ? (
            <span className="bordered-btn f-right" onClick={this.addMeeting}>
              <i className="iconfont icon-add" />
              添加场地
            </span>
          ) : (
              ''
            )}
          {/* {
            isManager ? <span className='bordered-btn f-right' onClick={ this.batchUpdateMeetingRooms }>批量修改场地</span> : ''
          } */}
        </header>
        <ul
          className="nav-section-tab"
          style={{ width: 'auto', margin: '20px 30px 0px 30px' }}
        >
          <li
            onClick={this.setShowType.bind(null, 'meeting')}
            className={`kb-tabs-menu-item ${showType == 'meeting' ? 'nav-section-tab-active' : ''
              }`}
          >
            <a>会议室</a>
          </li>
          {otherRooms && otherRooms.length > 0 ? (
            <li
              onClick={this.setShowType.bind(null, 'other')}
              className={`kb-tabs-menu-item ${showType == 'other' ? 'nav-section-tab-active' : ''
                }`}
            >
              <a>其他活动场地</a>
            </li>
          ) : (
              ''
            )}
        </ul>
        {showType == 'meeting' ? (
          <table style={{ width: '100%' }}>
            <KBTableWithoutData
              hasData={meetingRooms.length > 0}
              tableHeadNum="1"
              tipMessage="暂无场地信息"
            >
              <div className="nav-section-content-container">
                {meetingRooms &&
                  meetingRooms.map((room, index) => {
                    return this.roomBlockJsx(room, index)
                  })}
              </div>
            </KBTableWithoutData>
          </table>
        ) : (
            ''
          )}
        {showType == 'other' && otherRooms && otherRooms.length > 0 ? (
          <table style={{ width: '100%' }}>
            <KBTableWithoutData
              hasData={otherRooms.length > 0}
              tableHeadNum="1"
              tipMessage="暂无场地信息"
            >
              <div className="nav-section-content-container">
                {otherRooms &&
                  otherRooms.map((room, index) => {
                    return this.roomBlockJsx(room, index)
                  })}
              </div>
            </KBTableWithoutData>
          </table>
        ) : (
            ''
          )}
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const { user } = state
  const loadingStatus = selectors.getLoadingStatusOfKey(state, 'meetingRooms')
  let loc_id = params.id
  let meetings = selectors.getMeetingRoomsOfLocation(state, loc_id)
  let isManager = canAccessLocation(user, loc_id)
  return {
    loc_id,
    meetings,
    isManager,
    loadingStatus
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingManage)
