import React from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { meetingsActions, loadingAction } from 'app/actions'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import * as selectors from 'app/selectors'
import { isListValue, getListFirstValue } from 'utils/kbUtil'

const menu = [
  {
    name: '预约管理',
    value: ['', 'booking'],
    icon: 'icon-reserve'
  },
  {
    name: '场地管理',
    value: ['manage', 'info'],
    icon: 'icon-ConferenceRoom'
  },
  {
    name: '预定记录',
    value: 'record',
    icon: 'icon-record'
  },
  {
    name: '设备管理',
    value: 'theme'
  },
  {
    name:'优惠管理',
    value:'coupon'
  }
]

var MeetingBox = React.createClass({
  displayName: 'MeetingBox',

  componentDidMount() {
    const { loc_id, updateLoadingAction, getMeetingsAction } = this.props

    api.getMeetingRooms({ location_id: loc_id, per_page: 100 }).then(
      json => {
        updateLoadingAction.success(null, {
          key: 'meetingRooms',
          value: 'complete'
        })
        getMeetingsAction.success(json)
      },
      errors => {
        getMeetingsAction.failure(errors)
      }
    )
  },

  render() {
    const { loc_id, section } = this.props
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child)
    )
    return (
      <div className="clear-fix" style={{ height: '100%' }}>
        <section className="kb-content" style={{ marginTop: 0 }}>
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">场地</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menu.map((data, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          isListValue(data.value, section)
                            ? 'nav-container-list-hover'
                            : ''
                        }
                      >
                        <Link
                          to={`/admin/locations/${loc_id}/meeting/${getListFirstValue(
                            data.value
                          )}`}
                        >
                          <span>{data.name}</span>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          {childrenWithProps}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let sections = location.pathname.split('/')
  let section = sections.length > 4 ? sections[5] || '' : ''
  return {
    loc_id,
    section
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMeetingsAction: bindActionCreators(meetingsActions.get, dispatch),
    updateLoadingAction: bindActionCreators(loadingAction.update, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingBox)
