import React, { Component, PropTypes } from 'react'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBDateInput,
  KBTextarea,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover
} from 'components'

var OrgOffHireForm = React.createClass({
  render() {
    const {
      fields: {
        terminate_reason,
        terminate_note,
        expire_date,
        line_items,
        promptly
      },
      error,
      submitting,
      handleSubmit,
      org,
      count,
      subscriptions
    } = this.props

    return (
      <div className="kb-form-container">
        {count ? (
          <div className="finance-detail">
            <header>需要终止的合同</header>
            <table className="content-table t-layout">
              <thead>
                <tr>
                  <th>编号</th>
                  <th>开始日期</th>
                  <th>终止日期</th>
                  <th>工位数</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((json, index) => {
                  return (
                    <tr key={index}>
                      <td>{json.serial}</td>
                      <td>{json.start_date}</td>
                      <td>{json.expire_date}</td>
                      <td>{json.units}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div>暂无合同信息</div>
        )}
      </div>
    )
  }
})

export default OrgOffHireForm
