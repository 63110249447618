import React, { Component, PropTypes } from 'react'
import { KBTableWithoutData, KBAvatar } from 'components'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { getUserInfoUrl } from 'utils/kbUrl'

var OrgMembers = React.createClass({
  render() {
    const { organization } = this.props
    const { users } = organization

    return (
      <section className="nav-section-container f-right">
        <div className="">
          <header className="member-header">
            <h2 className="header-title f-left" style={{ fontSize: '22px' }}>
              公司员工
            </h2>
          </header>
          <div
            className="nav-section-content-container"
            style={{ paddingBottom: 30 }}
          >
            <table className="content-table">
              <thead>
                <tr>
                  <th>姓名</th>
                  <th>职务</th>
                  <th>联系方式</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={users.length > 0}
                tableHeadNum="3"
                tipMessage="暂无数据"
              >
                {users &&
                  users.map(m => {
                    return (
                      <tr key={m.id}>
                        <td className="vf-center">
                          <KBAvatar
                            style={{
                              display: 'inline-block',
                              marginRight: '5px'
                            }}
                            imgStyle={{ marginRight: 5 }}
                            user={m}
                            size={30}
                          />
                          <Link
                            className="color-link"
                            to={getUserInfoUrl(m.id)}
                          >
                            {m.name}
                            {m.roles.includes('employer') ? (
                              <i
                                style={{ marginLeft: '5px', color: '#f9d83b' }}
                                className="iconfont icon-star"
                              />
                            ) : (
                              ''
                            )}
                          </Link>
                        </td>
                        <td>{m.title || '员工'}</td>
                        <td>{m.phone_num || ''}</td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgMembers)
