import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { newsAction, successState, apiCallFailure } from 'app/actions'
import {
  KBDropdown,
  KBPagination,
  KBLoadingContainer,
  KBPopover,
  KBRangeDate,
  KBAvatar
} from 'components'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import { NEWS_STATUS_OBJ } from 'app/constants'
import {
  percent,
  mergeArrayWithKey,
  queryFilter,
  toImageProxyDirect,
  formatHoursMinutesPON
} from 'utils/kbUtil'
import { connect } from 'react-redux'
import moment from 'moment'
import { KBNoData } from 'components'

var NewsList = React.createClass({
  displayName: 'NewsList',
  getInitialState() {
    let { filters } = this.props
    return {
      filters,
      loading: true
    }
  },

  componentDidMount() {
    this.mounted = true
    this.loadData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  loadData(model) {
    const { loc_id, getNewsAction, query, routerActions } = this.props
    const { filters } = this.state
    let params = Object.assign({}, filters, model)
    params.per_page = 100
    let queryFiltersStr = queryFilter({ ...query, ...params })
    this.setState({ loading: true })

    api
      .getNews(params)
      .then(
        json => {
          getNewsAction.success(json, {
            entityName: 'news'
          })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/memberships/memberManage/news${queryFiltersStr}`
          )
        },
        error => {
          getNewsAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  _setFilter(obj) {
    let newFilt = obj || {}
    let newFilters = Object.assign({}, this.state.filters, newFilt)
    this.setState({ filters: newFilters })
    this.loadData(newFilters)
  },

  _clearFilters() {
    this.setState({ filters: {} })
  },

  setTag(name) {
    this._setFilter({ tag: name })
  },

  escape(str) {
    return str.replace(/<\/script/g, '<\\/script').replace(/<!--/g, '<\\!--')
  },

  toNewsInfo(newsData) {
    const { routerActions, loc_id } = this.props
    routerActions.push(
      `/admin/memberships/memberManage/news/${newsData.id}/detail`
    )
  },

  toNewsCreate() {
    const { routerActions, loc_id } = this.props
    routerActions.push(`/admin/memberships/memberManage/news/edit`)
  },

  render() {
    const { news, tags } = this.props
    const { loading, filters } = this.state

    return (
      <section className="kb-content-container">
        <header className="nav-section-header clear-fix border-bottom">
          <div className="nav-section-header-title f-left">
            <span>资讯</span>
          </div>
          <span className="bordered-btn f-right" onClick={this.toNewsCreate}>
            添加资讯
          </span>
        </header>
        {/* <div style={{marginBottom: 10}}> */}
        {
          // tags && tags.length > 0 ? <div className="news-tag">
          //   <span>标签</span>
          //   <div className="tag-list">
          //     <span onClick={ this.setTag.bind(null, '') } className={filters.tag == '' ? 'tag-active' : ''}>全部</span>
          //     {
          //      tags.map(tag => {
          //         return(
          //           <span onClick={ this.setTag.bind(null, tag.name) } className={filters.tag == tag.name ? 'tag-active' : ''}>{ tag.name }</span>
          //         )
          //       })
          //     }
          //   </div>
          // </div> : ''
        }
        {/* </div> */}
        <KBLoadingContainer loading={loading}>
          <div className="nav-section-content-container">
            {news && news.length ? (
              news.map(newsData => {
                return (
                  <div className="news-container">
                    <img
                      src={toImageProxyDirect(
                        newsData.image_url ||
                          'https://media-ssl.kuban.io/static/wechat/app/images/img_pic_tu.png',
                        680,
                        400
                      )}
                      alt="img"
                    />
                    <div className="news-content">
                      <div
                        className="news-title"
                        onClick={this.toNewsInfo.bind(null, newsData)}
                      >
                        {newsData.title}
                        <span
                          className={`news-status news-status_${newsData.status}`}
                        >
                          {NEWS_STATUS_OBJ[newsData.status]}
                        </span>
                      </div>
                      <div className="news-date-status">
                        <span className="news-date">
                          {newsData.summary || (
                            <a className="color-link" href={newsData.url}>
                              {newsData.url}
                            </a>
                          )}
                        </span>
                      </div>

                      {/* 暂时还没有 tag 字段 */}
                      {/* <div style={ {marginBottom: 10, color: '#969696'} }>
                          <i className="iconfont icon-tag" style={ {marginRight: 10} }/>
                          <span>{ newsData.tags.join(' ,') }</span>
                        </div> */}

                      <div className="news-time-and-viewcounts">
                        <span className="news-created-time">
                          <i className="iconfont icon-time" />
                          <span>
                            {newsData.created_at
                              ? formatHoursMinutesPON(newsData.created_at)
                              : '暂无'}
                          </span>
                        </span>
                        <span className="news-view-counts">
                          <i className="iconfont icon-reading" />
                          <span>{newsData.view_count || 0}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="box-content">
                <KBNoData className="m-updown" tipMessage="暂无数据" />
              </div>
            )}
          </div>
        </KBLoadingContainer>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location, tags } = props
  let loc_id = params.id
  let news = selectors.getNews(state)
  news.reverse()

  return {
    loc_id,
    news,
    tags
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getNewsAction: bindActionCreators(newsAction.replace, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsList)
