import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { ENGLISH_ALPHABET } from 'app/constants'
import {
  KBNoData,
  KBButton,
  KBPopover,
  KBLoadingContainer,
  KBRangeDate,
  KBTableWithoutData,
  KBPagination,
  KBTipsy
} from 'components'
import { orgActions, locationFloorsAction } from 'app/actions'
import { connect } from 'react-redux'
import { canAccessLocation, canAccessLocationRoles } from 'app/reducers/role'
import OrgBlock from './OrgBlock'
import OrgTeamList from './OrgTeamList'
import { Link } from 'react-router'
import * as selectors from 'app/selectors'
import moment from 'moment'
import { isWooSpace, paginate, } from 'utils/kbUtil'
import _ from 'lodash'

var Organizations = React.createClass({
  displayName: 'Organizations',
  getInitialState() {
    return {
      str: '',
      connect: true,
      status: null,
      viewState: 'logo',
      currentTap: 'all',
      is_unentered: null,
      is_all: true,
      filters: {},
      loading: true,
      page: this.props.page || 1,
      selectedFloorSection: '全部',
      selectedFloor: '',
      organizations: [],
      floorGroupedOrgs: []
    }
  },
  componentDidMount() {
    this.mounted = true
    this.changeTap('existing')
    this.getLocFloors()
  },
  componentWillReceiveProps(nextProps) {
    const {
      type,
      routerActions,
      location_id,
      customer_id,
      industry_id
    } = nextProps
    if (type == 'crmrecord') {
      routerActions.replace(`/admin/locations/${location_id}/organizations`)
      this.createOrganization(type, customer_id, industry_id)
    }
    if (
      JSON.stringify(this.props.organizations) !==
      JSON.stringify(nextProps.organizations)
    ) {
      this.setFloorGroupedOrgs(nextProps.organizations)
      this.setState({
        organizations: nextProps.organizations
      })
    }
    if (
      JSON.stringify(this.props.floors) !== JSON.stringify(nextProps.floors)
    ) {
      const currentFloors = nextProps.floors || []
      this.setState({
        selectedFloor: ''
      })
    }
    if (this.props.page != nextProps.page) {
      this.setState({
        page: nextProps.page
      }, () => {
        this._loadData({})
      })
      return
    }
  },
  componentWillUnmount() {
    this.mounted = false
  },

  getLocFloors(params) {
    const { location_id } = this.props

    api.getLocationFloors(location_id, params).then(
      json => {
        this.props.getLocationFloorsAction.success(json, {
          location_id,
          key: 'locationFloors'
        })
      },
      error => {
        this.props.getLocationFloorsAction.failure(error)
      }
    )
  },

  createOrganization(type, customer_id, industry_id) {
    const { routerActions, location_id } = this.props
    let params = Object.assign(
      {},
      { is_important: false, opportunity_id: customer_id },
      { external_visible: false },
      { is_legal: false }
    )
    if (type === 'crmrecord') {
      return routerActions.push(
        `/admin/locations/${location_id}/new_organization?source=crm`
      )
    }
    return routerActions.push(
      `/admin/locations/${location_id}/new_organization?add_type=${type}`
    )
    //KBPopover.show(<OrganizationForm idstry_id={ industry_id } location_id = { this.props.location_id } onOrgCreated={ this.toNewCreatedCompany } status={ type } initialValues={ params } />)
  },

  filterOrg(letter) {
    if (letter.toLocaleLowerCase() === this.state.str) {
      return
    }
    this.setState({ str: letter.toLocaleLowerCase(), page: 1 }, () => {
      this._loadData({})
    })
  },

  showAllOrgs() {
    if ('' === this.state.str) {
      return
    }
    this.setState({ str: '', page: 1 }, () => {
      this._loadData({})
    })
  },

  _loadData(params) {
    const { getOrganizationsAction, location_id, currentPath, routerActions } = this.props
    const { page, is_unentered, connect, str } = this.state;
    this.setState({
      loading: true,
    })
    api
      .getOrganizations({
        location_id,
        first_letter: str,
        connect,
        is_unentered,
        page,
        per_page: 12,
        ...params,
        includes: 'details',
        organization_type: 'normal'
      })
      .then(
        json => {
          let pagination = paginate(json.response)
          this.setState({
            pagination
          })

          if (!this.mounted) {
            return
          }
          routerActions.replace(`${currentPath}?page=${page}`)
          getOrganizationsAction.success(json)
        },
        errors => {
          getOrganizationsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  },

  toNewCreatedCompany(org) {
    const { routerActions, location_id } = this.props
    routerActions.push(
      `/admin/locations/${location_id}/organizations/${org.id}`
    )
  },

  toReportCenter() {
    const { routerActions } = this.props
    KBPopover.close()
    routerActions.push(`/admin/report`)
  },

  exportOrganization() {
    const { location_id } = this.props
    api.postReportsOrganization({ location_ids: location_id }).then(() => {
      KBPopover.show(
        <div>
          <header className="kb-form-header">
            <h2 className="header-title">导出Excel</h2>
            <i
              className="iconfont header-close icon-cancel"
              onClick={KBPopover.close}
            />
          </header>
          <div className="kb-form-container">
            <div className="kb-row">
              <p>Excel已成功导出,可以前往报表中心下载</p>
            </div>
            <div className="kb-row">
              <div className="kb-form-fr">
                <KBButton
                  className="certain-btn"
                  style={{ marginRight: 10 }}
                  onClick={KBPopover.close}
                >
                  OK
                </KBButton>
                <KBButton className="certain-btn" onClick={this.toReportCenter}>
                  前往报表中心
                </KBButton>
              </div>
            </div>
          </div>
        </div>
      )
    })
  },

  showOrgsAll() {
    let params = {connect: null, is_unentered: null}

    this.setState({
      is_all: true,
      str: '',
      ...params
    }, () => {
      this._loadData(params)
    })
  },

  showOrgsExisting() {
    let params = { connect: true, is_unentered: null }
    this.setState({
      is_all: false,
      str: '',
      ...params
    }, () => {
      this._loadData(params)
    })
  },

  showOrgsLeave() {
    let params = { connect: false, is_unentered: null }
    this.setState({
      is_all: false,
      str: '',
      ...params
    }, () => {
      this._loadData(params)
    })
  },

  showUnentered() {
    let params = { connect: true, is_unentered: true }
    this.setState({
      is_all: false,
      str: '',
      ...params
    }, () => {
      this._loadData(params)

    })
  },

  showFiltered() {
    const { filters } = this.state
    let params = Object.assign({}, filters)

    let { from, to } = params
    if (from && to) {
      params.subscription_end_date_from =
        from && moment(from).format('YYYY-MM-DD')
      params.subscription_end_date_to = to && moment(to).format('YYYY-MM-DD')
      delete params.from
      delete params.to
      delete params.shortcut
    } else {
      delete params.from
      delete params.to
      delete params.shortcut
    }
    this.setState({
      str: '',
      loading: true,
      ...params
    }, () => {
      this._loadData(params)
    })
  },

  changeTap(type) {
    if (type == 'existing') {
      this.showOrgsExisting()
    } else if (type == 'leave') {
      this.showOrgsLeave()
    } else if (type == 'all') {
      this.showOrgsAll()
    } else {
      this.showUnentered()
    }
    this.setState({
      filters: {},
      loading: true,
      page: 1,
    })
  },

  _refresh() {
    setTimeout(this.showFiltered)
  },

  _clearFilters() {
    this.setState({ filters: {}, page: 1 })
    // force refresh
    this._refresh(null, {})
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, page: 1 })

    // force refresh
    this._refresh()
  },

  changeDate(range) {
    this._setFilter(range)
  },

  changeDateShortcut(data) {
    let range = {}
    range.from = moment(new Date())._d
    range.to = moment(new Date()).add(data.days, 'days')._d
    range.shortcut = data.id
    this.changeDate(range)
  },

  _renderFilters() {
    const { from, to, shortcut } = this.state.filters
    const { filterShortcuts } = this.props
    return (
      <div style={{ width: '100%' }}>
        <div
          className="nav-section-action-bar"
          style={{ display: 'inline-flex', marginTop: 0 }}
        >
          <KBRangeDate
            leftNone={true}
            title="选择合同到期日范围"
            format="YYYY/MM/DD"
            from={from || ''}
            to={to || ''}
            callback={this.changeDate}
          ></KBRangeDate>
          {filterShortcuts.map((data, index) => {
            return (
              <div
                key={index}
                className={`border-show_status ${shortcut && shortcut == data.id
                  ? 'border-show_status_active'
                  : ''
                  }`}
                onClick={this.changeDateShortcut.bind(null, data)}
              >
                <span
                  className={`color-show`}
                  style={{ backgroundColor: `${data.color}` }}
                ></span>
                <span className="status-text">{data.name}</span>
              </div>
            )
          })}
        </div>

        {Object.keys(this.state.filters).length > 0 ? (
          <div
            className="clear-criteria"
            style={{ float: 'right', margin: 0 }}
            onClick={this._clearFilters}
          >
            <i className="iconfont icon-close" />
            <span>清除所有筛选条件</span>
          </div>
        ) : null}
      </div>
    )
  },

  selectFloorSection(f_sec) {
    const { organizations } = this.props

    this.getLocFloors({
      layer_num_min: f_sec.start_floor,
      layer_num_max: f_sec.end_floor
    })

    const filterOrgs =
      f_sec.label === '全部'
        ? organizations
        : organizations.filter(
          org =>
            org.floor_info &&
            org.floor_info.layer_num_i >= f_sec.start_floor &&
            org.floor_info.layer_num_i <= f_sec.end_floor
        )
    this.setFloorGroupedOrgs(filterOrgs)

    this.setState({
      selectedFloorSection: f_sec.label,
      organizations: filterOrgs
    })
  },

  selectExactFloor(floor) {
    // const { organizations } = this.state
    // console.log('organizations', organizations)
    // const filterOrgs = floor.layer_num
    //   ? organizations.filter(
    //     org =>
    //       org.floor_info && org.floor_info.layer_num_i === floor.layer_num_i
    //   )
    //   : organizations
    // this.setFloorGroupedOrgs(filterOrgs)
    // this.setState({
    //   selectedFloor: floor.layer_num || ''
    // })

    const { getOrganizationsAction, location_id, currentPath, routerActions } = this.props
    const { page, is_unentered, connect, str } = this.state;
    this.setState({
      selectedFloor: floor.layer_num || ''
    })

    api
      .getOrganizations({
        location_id,
        floor_info_id: floor.id,
        connect,
        is_unentered,
        page: 1,
        per_page: 12,
        // ...params,
        includes: 'details',
        organization_type: 'normal'
      })
      .then(
        json => {
          let pagination = paginate(json.response)
          this.setState({
            pagination
          })

          if (!this.mounted) {
            return
          }
          routerActions.replace(`${currentPath}?page=${page}`)
          getOrganizationsAction.success(json)

        },
        errors => {
          getOrganizationsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        })
      })

  },

  setFloorGroupedOrgs(organizations = []) {
    const floorGroupedOrgs = _.groupBy(
      organizations.map(org => {
        org.floor_info_id = (org.floor_info && org.floor_info.id) || null
        return org
      }),
      'floor_info_id'
    )
    this.setState({
      floorGroupedOrgs
    })
  },

  render() {
    const {
      connect,
      viewState,
      is_unentered,
      is_all,
      loading,
      selectedFloorSection,
      selectedFloor,
      floorGroupedOrgs,
      page,
    } = this.state
    const {
      isManager,
      space_id,
      isSales,
      isLandlord,
      floorSections,
      floors,
      location_id,
      isUnionLoc,
      currentLocation,
      currentPath
    } = this.props

    let isConnect = connect
    let isUnentered = is_unentered
    let isAll = is_all
    let orgs = this.props.organizations

    const { str, pagination } = this.state
    orgs =
      (orgs &&
        orgs.filter(org => {
          if (!str || !str.toLocaleUpperCase()) {
            return true
          }
          return org.name_pinyin.split('')[0].toLocaleLowerCase() == str
        })) ||
      []
    const viewStateJsxObj = {
      floor: (
        <KBLoadingContainer loading={loading}>
          <div
            className="org-filter m-bottom-sm m-top-sm"
            style={{ position: 'relative' }}
          >
            <div className="search-topic">
              <span style={{ color: '#7F8FA4', fontSize: 14, marginRight: 5 }}>
                楼层区间:
              </span>
              {floorSections.map((f_sec, index) => (
                <span
                  key={index}
                  className={`tag-str ${f_sec.label === selectedFloorSection ? 'topic-click' : ''
                    }`}
                  onClick={() => {
                    this.selectFloorSection(f_sec)
                  }}
                >
                  {f_sec.label}
                </span>
              ))}
            </div>
            {true ? (
              <div
                className="search-topic"
                style={{
                  paddingTop: 16,
                  marginTop: 16,
                  borderTop: '1px solid #DFE2E5'
                }}
              >
                <span
                  style={{ color: '#7F8FA4', fontSize: 14, marginRight: 5 }}
                >
                  具体楼层:
                </span>
                <span
                  className={`letter-str-all ${!selectedFloor ? 'letter-str-all_active' : ''
                    }`}
                  style={{ width: 'auto' }}
                  onClick={() => {
                    this.selectExactFloor({})
                  }}
                >
                  全部
                </span>
                {floors.map((floor, index) => {
                  return (
                    <span
                      key={index}
                      className={`tag-str ${floor.layer_num === selectedFloor ? 'topic-click' : ''
                        } deletable-block`}
                      onClick={() => {
                        this.selectExactFloor(floor)
                      }}
                    >
                      {floor.layer_num}
                    </span>
                  )
                })}
              </div>
            ) : null}
          </div>
          <div style={{ marginTop: 30 }}>
            {Object.keys(floorGroupedOrgs).length > 0 ? (
              Object.keys(floorGroupedOrgs).map(floor_id => {
                const floor = floors.find(f => f.id == floor_id) || {
                  layer_num: '暂无楼层'
                }
                return (
                  <div className="floor-group-orgs">
                    <div className="top-left">
                      <span>{floor.layer_num}</span>
                      <i className="iconfont icon-company" />
                    </div>
                    <div className="block-container">
                      {floorGroupedOrgs[floor_id].map(o => {
                        return (
                          <Link
                            to={`/admin/locations/${location_id}/organizations/${o.id}`}
                            className="company-block"
                          >
                            <img
                              src={
                                o.logo ||
                                'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/company_default.png'
                              }
                              alt="logo"
                              className="logo"
                            />
                            <KBTipsy content={o.name}>
                              <div className="name">{o.name}</div>
                            </KBTipsy>
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                )
              })
            ) : (
                <KBNoData tipMessage="暂无公司" />
              )}
          </div>
        </KBLoadingContainer>
      ),
      logo: (
        <KBLoadingContainer loading={loading}>
          <div className="org-filter m-top-sm">
            <div className="search-topic">
              <span style={{ color: '#7F8FA4', fontSize: 14, marginRight: 5 }}>
                字母查找:
              </span>
              <span
                className={`letter-str-all ${!str ? 'letter-str-all_active' : ''
                  }`}
                style={{ width: 'auto' }}
                onClick={this.showAllOrgs}
              >
                不限
              </span>
              {ENGLISH_ALPHABET.map((letter, index) => (
                <span
                  key={index}
                  className={`letter-str ${letter == str.toLocaleUpperCase() ? 'topic-click' : ''
                    }`}
                  onClick={this.filterOrg.bind(null, letter)}
                >
                  {letter}
                </span>
              ))}
            </div>
          </div>
          <table style={{ width: '100%' }}>
            <KBTableWithoutData
              hasData={orgs.length > 0}
              tableHeadNum="1"
              tipMessage="暂无公司"
            >
              <tr>
                <td>
                  <div className="company-logo-all t-center">
                    {orgs &&
                      orgs.map((org, index) => (
                        <OrgBlock
                          location_id={location_id}
                          org={org}
                          key={'allorg_' + index}
                        />
                      ))}
                  </div>
                </td>
              </tr>
            </KBTableWithoutData>
          </table>
        </KBLoadingContainer>
      ),
      list: (
        <KBLoadingContainer loading={loading}>
          <table className="content-table edit-table">
            <thead>
              <tr>
                <th>公司名称</th>
                <th>阶段</th>
                <th>行业</th>
                <th>公司总人数</th>
                <th>上传照片人数</th>
                <th>租用工位数</th>
                <th>已入驻人数</th>
              </tr>
            </thead>
            <KBTableWithoutData
              hasData={orgs.length > 0}
              tableHeadNum="6"
              tipMessage="暂无公司"
            >
              {orgs &&
                orgs.map((org, index) => (
                  <OrgTeamList
                    location_id={location_id}
                    org={org}
                    key={'org_' + index}
                  />
                ))}
            </KBTableWithoutData>
          </table>
        </KBLoadingContainer>
      )
    }

    return (
      <KBLoadingContainer className="kb-content" loading={!orgs}>
        <div className="kb-content-container clear-fix">
          <header className="nav-section-header clear-fix">
            <div className="nav-section-header-title f-left">
              <span>入驻公司</span>
            </div>
            {(isManager || isSales || isLandlord) && !isWooSpace(space_id) ? (
              <button
                className="bordered-btn f-right"
                onClick={() => {
                  this.createOrganization('shortterm')
                }}
              >
                <i className="iconfont icon-add" />
                添加联办公司
              </button>
            ) : null}
            {(isManager || isSales || isLandlord) && !isWooSpace(space_id) && isUnionLoc ? (
              <button
                className="bordered-btn f-right"
                onClick={() => {
                  this.createOrganization('longterm')
                }}
              >
                <i className="iconfont icon-add" />
                添加写字楼公司
              </button>
            ) : null}
            {(isManager || isLandlord) ? (
              <button
                className="c-btn-secondary f-right"
                onClick={this.exportOrganization}
              >
                导出公司信息
              </button>
            ) : null}
          </header>
          <div className="nav-section-content-container">
            <ul className="nav-section-tab">
              <li
                className={`${isAll ? 'nav-section-tab-active' : ''}`}
                onClick={this.changeTap.bind(null, 'all')}
              >
                <a>全部企业</a>
              </li>
              <li
                className={`${!isAll && isConnect && isUnentered == null
                  ? 'nav-section-tab-active'
                  : ''
                  }`}
                onClick={this.changeTap.bind(null, 'existing')}
              >
                <a>已入驻企业</a>
              </li>
              <li
                className={`${!isAll && isConnect && isUnentered
                  ? 'nav-section-tab-active'
                  : ''
                  }`}
                onClick={this.changeTap.bind(null, 'unentered')}
              >
                <a>未入驻企业</a>
              </li>
              <li
                className={`${!isAll && !isConnect && isUnentered == null
                  ? 'nav-section-tab-active'
                  : ''
                  }`}
                onClick={this.changeTap.bind(null, 'leave')}
              >
                <a>毕业企业</a>
              </li>
              <li
                className={`team-list m-left-sm ${viewState === 'list' ? 'color-link' : ''
                  }`}
                onClick={() => this.setState({ viewState: 'list', page: 1 }, () => { this._loadData() })}
              >
                <i className="iconfont icon-list" />
                <span style={{ marginLeft: 3 }}>列表显示</span>
              </li>
              <li
                className={`team-tile m-left-sm ${viewState === 'logo' ? 'color-link' : ''
                  }`}
                onClick={() => this.setState({ viewState: 'logo', page: 1 }, () => { this._loadData() })}
              >
                <i className="iconfont icon-tile" />
                <span style={{ marginLeft: 3 }}>Logo墙显示</span>
              </li>
              <li
                className={`team-tile ${viewState === 'floor' ? 'color-link' : ''
                  }`}
                onClick={() => this.setState({ viewState: 'floor', page: 1 }, () => { this._loadData() })}
              >
                <i className="iconfont icon-company" />
                <span style={{ marginLeft: 3 }}>楼层显示</span>
              </li>
            </ul>
            {!isAll && isConnect && isUnentered == null
              ? this._renderFilters()
              : null}

            {viewStateJsxObj[viewState]}
            {/* {loading ? null : */}
            <KBPagination
              pagination={pagination}
              template={`${currentPath}?page=#PAGE#`}
            />
            {/* } */}
          </div>
        </div>
      </KBLoadingContainer>
    )
  }
})

function mapStateToProps(state, ownProps) {

  const { user } = state
  let location_id = parseInt(ownProps.params.id)
  let org_id = parseInt(ownProps.params.organization_id) || null
  let organizations = Object.assign(
    [],
    selectors.getOrgsOfLocation(state, location_id)
  )
  if(Array.isArray(organizations) && organizations.length) {
    organizations.sort((a, b) => {
      if(a.name && a.name.includes('临时租户公司')) {
        return Infinity
      }
      return a.id - b.id
    })
  }
  let isManager = canAccessLocation(user, location_id)
  let isSales = canAccessLocationRoles(user, location_id, ['sales'])
  const isLandlord = canAccessLocationRoles(user, location_id, ['landlord'])

  const { location } = ownProps
  const type = location.query.type
  const industry_id = location.query.industry_id
  const customer_id = location.query.id
  let space_id = Object.keys(state.entities.spaces || {})[0]

  let filterShortcuts = [
    {
      id: 1,
      days: 7,
      name: '一周内',
      color: '#B85E63'
    },
    {
      id: 2,
      days: 30,
      name: '一月内',
      color: '#F6A623'
    },
    {
      id: 3,
      days: 60,
      name: '两月内',
      color: '#666666'
    }
  ]
  const currentLocation = selectors.getLocation(state, location_id) || {}
  const isUnionLoc =
    currentLocation.location_type === 'self_office' ||
    currentLocation.location_type === 'sales_office'
  // 楼层逻辑
  const maxFloor = currentLocation.floor_layer_num_max || 0
  const minFloor = currentLocation.floor_layer_num_min || 0
  const totalFloorAboveGroundSecs = maxFloor
    ? parseInt((maxFloor - 1) / 10, 10) + 1
    : 0
  const totalFloorUnderGroundSecs = minFloor
    ? parseInt((1 - minFloor) / 10, 10) + 1
    : 0
  const floorSections = []
  // 处理地下楼层
  for (let i = 1; i < totalFloorUnderGroundSecs + 1; i++) {
    let endFloor = -1 - 10 * (i - 1)
    let startFloor = endFloor - 10 < minFloor ? minFloor : endFloor - 9
    floorSections.push({
      label: `${startFloor} ~ ${endFloor}层`,
      start_floor: startFloor,
      end_floor: endFloor
    })
  }
  floorSections.reverse()
  // 处理地上楼层
  for (let i = 1; i < totalFloorAboveGroundSecs + 1; i++) {
    let startFloor = 1 + 10 * (i - 1)
    let endFloor = startFloor + 10 > maxFloor ? maxFloor : startFloor + 9
    floorSections.push({
      label: `${startFloor} ~ ${endFloor}层`,
      start_floor: startFloor,
      end_floor: endFloor
    })
  }
  floorSections.unshift({
    label: '全部',
    start_floor: minFloor,
    end_floor: maxFloor
  })
  const floors = selectors.getLocationFloorsFromState(state) || []
  floors.sort((a, b) => a.layer_num_i - b.layer_num_i)
  const currentPath = `/admin/locations/${location_id}/organizations`

  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page

  return {
    currentPath,
    type,
    customer_id,
    org_id: org_id,
    currentLocation,
    location_id: location_id,
    organizations,
    isManager,
    isSales,
    isLandlord,
    industry_id,
    filterShortcuts,
    space_id,
    floorSections,
    floors,
    isUnionLoc,
    page,
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getOrganizationsAction: bindActionCreators(orgActions.replace, dispatch),
    getLocationFloorsAction: bindActionCreators(
      locationFloorsAction.replace,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Organizations)
