import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  usersActions,
  apiCallFailure,
  getLocationMembersAction
} from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBPopover,
  KBDropdown,
  KBSearchList
} from 'components'
import { connect } from 'react-redux'
import { util } from 'utils/kbUtil'
import { MembersList } from 'components'
import {
  paginate,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown
} from 'utils/kbUtil'
import {
  MEBMER_GENDERS_FORM,
  MEMBER_AGE,
  MEMBER_GENDER_OBJ,
  USER_TYPE,
  USER_TYPE_OBJ
} from 'app/constants'
import { routerActions } from 'react-router-redux'
import { getMembershipMember } from 'utils/kbUrl'
import * as selectors from 'app/selectors'

const MEMBER_AGE_OBJ = {
  18: '18-22',
  23: '23-26',
  27: '27-35',
  36: '35岁以上'
}

const DROP_DOWNS = [
  'locationIdDropdown',
  'genderDropdown',
  'ageDropdown',
  'userTypeDropdown'
]

var MembershipsMembers = React.createClass({
  displayName: 'MembershipsMembers',
  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      queryFiltersStr: '',
      pagination: null, // local state for pagination
      users: null, // local state for user list
      filters: {
        ...filters
      }
    }
  },

  _loadData(page, newFilters) {
    const per_page = 50
    const { routerActions, query, location_id } = this.props
    const preFilters = this.props.filters
    const { filters } = this.state
    this.setState({ loading: true })
    let loc_id = this.props.loc_id

    let queryFiltersStr = queryFilter({
      ...query,
      ...filters,
      page: page || this.props.page
    })
    let params = Object.assign(
      {},
      { page, per_page, ...filters, includes: 'space_users' }
    )
    if (filters && filters.location_id) {
      params.location_id = filters.location_id
    }
    if (location_id) {
      params.location_id = location_id
    }

    api
      .getUsers(params)
      .then(
        ({ json, response }) => {
          let pagination = paginate(response)
          this.props.getUsersActions.success(
            { json },
            {
              location_id: params.location_id || loc_id,
              per_page: per_page,
              ...pagination
            }
          )
          this.setState({ pagination: pagination, users: json.entities.users })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/memberships/memberManage/members${queryFiltersStr}`
          )
        },
        errors => {
          this.props.getUsersActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  componentDidMount() {
    this.mounted = true
    this.refreshMember()
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this._loadData(nextProps.page)
    }
  },

  componentWillUnmount() {
    this.mounted = false
    this.setState({ loading: false })
  },

  refreshMember() {
    const { page } = this.props
    this._loadData(page)
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, page: 1 })
    // force refresh
    this._refresh()
  },

  _clearFilters() {
    this.setState({ filters: {}, page: 1 })
    this._refresh({})
  },

  _refresh(newFilters) {
    this['genderDropdown'] && this['genderDropdown'].hide()
    this['ageDropdown'] && this['ageDropdown'].hide()
    this['locationIdDropdown'] && this['locationIdDropdown'].hide()
    this['userTypeDropdown'] && this['userTypeDropdown'].hide()

    setTimeout(this._loadData.bind(null, 1))
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  getUser(str, callback) {
    util.setTimeout(
      'membersSearchInput',
      () => {
        api.getSearchAllType({ query: str, type: 'user' }).then(({ json }) => {
          callback && callback(json)
        })
      },
      0
    )
  },

  _selectCallBack(data) {
    const { routerActions } = this.props
    data &&
      routerActions.push(`/admin/memberships/memberManage/members/${data.id}`)
  },

  render() {
    const { location_id, locations, allUsers } = this.props
    const { filters } = this.state
    let users = this.state.users && Object.values(this.state.users)
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    return (
      <section className="kb-content-container">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>会员</span>
          </div>
        </header>
        <div className="nav-section-content-container">
          <div className="m-top-sm">
            <KBDropdown
              ref={ref => {
                this.locationIdDropdown = ref
              }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'locationIdDropdown')}
                >
                  <i className="iconfont icon-position" />
                  <span>
                    {this.state.filters.location_id &&
                    Object.keys(locations).length > 0
                      ? locations[this.state.filters.location_id].name
                      : '选择分店'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ location_id: '' })}>
                      全部
                    </li>
                    {locations &&
                      Object.keys(locations).map((location_id, index) => {
                        let location = locations[location_id]
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this._setFilter({ location_id: location.id })
                            }
                          >
                            <span>{location.name}</span>
                          </li>
                        )
                      })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
            <KBDropdown
              ref={ref => {
                this.genderDropdown = ref
              }}
              style={{ marginRight: 20, marginLeft: 20 }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'genderDropdown')}
                >
                  <span>
                    {filters.gender
                      ? MEMBER_GENDER_OBJ[filters.gender]
                      : '选择性别'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li onClick={() => this._setFilter({ gender: '' })}>
                      全部
                    </li>
                    {MEBMER_GENDERS_FORM.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ gender: state.value })
                          }
                        >
                          <span>{state.title}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
            {/* <KBDropdown ref={ ref => { this.ageDropdown = ref } } style={{marginRight : 20}}>
                <KBDropdown.KBDropdownTrigger>
                  <div className='task-status-title' onClick={ this.closeDropDowns.bind(null, 'ageDropdown') }>
                    <span>{ filters.age_more_than ? MEMBER_AGE_OBJ[filters.age_more_than] : '选择年龄段' }</span>
                    <span className='task-deg' />
                  </div>
                </KBDropdown.KBDropdownTrigger>
                <KBDropdown.KBDropdownContent>
                  <div>
                    <ul className='task-type'>
                      { MEMBER_AGE.map((state, index) => {
                        return (
                          <li key={ index } onClick={()=>this._setFilter(state.value)}>
                            <span>{state.name}</span>
                          </li>
                        )})
                      }
                    </ul>
                  </div>
                </KBDropdown.KBDropdownContent>
              </KBDropdown> */}
            <KBDropdown
              ref={ref => {
                this.userTypeDropdown = ref
              }}
              style={{ marginRight: 20 }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'userTypeDropdown')}
                >
                  <span>
                    {filters.user_type
                      ? USER_TYPE_OBJ[filters.user_type]
                      : '选择会员类型'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    {USER_TYPE.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ user_type: state.value })
                          }
                        >
                          <span>{state.name}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
            <KBSearchList
              ref="dropDownOrg"
              getUsers={this.getUser}
              selectCallBack={this._selectCallBack}
              placeholder="会员搜索"
              style={{ display: 'inline-block', width: '300px' }}
              itemWidth="full"
            />
          </div>
          {selectFilters.length > 0 ? (
            <div
              className="clear-criteria"
              style={{ marginTop: 5 }}
              onClick={this._clearFilters}
            >
              <i className="iconfont icon-close" />
              <span>清除所有筛选条件</span>
            </div>
          ) : null}
          <KBLoadingContainer loading={!!allUsers && this.state.loading}>
            <KBLoadingContainer loading={this.state.loading}>
              <MembersList
                getMemberUrl={getMembershipMember}
                refreshMember={this.refreshMember}
                loc_id={location_id}
                users={allUsers}
              />
            </KBLoadingContainer>
            <KBPagination
              pagination={this.state.pagination}
              template={`/admin/memberships/memberManage/members?page=#PAGE#`}
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let locations = props.locations
  let loc_id = props.loc_id
  let location_id = props.params.id
  let page = parseInt(location.query && location.query.page) || 1
  var FILTER_STATE = [
    'location_id',
    'gender',
    'age_more_than',
    'age_less_than',
    'role',
    'user_type'
  ]
  var params = getFilterAndQuery(location.query, FILTER_STATE)

  let allUsers = selectors.getAllUsers(state)

  return {
    loc_id,
    location_id,
    locations,
    ...params,
    page,
    allUsers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getUsersActions: bindActionCreators(usersActions.replace, dispatch),
    getLocationMembersAction: bindActionCreators(
      getLocationMembersAction,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipsMembers)
