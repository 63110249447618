import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { api, apiUtils } from 'app/services'
import {
  announcementsActions,
  apiCallFailure,
  getLocationMembersAction
} from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBPopover,
  KBDropdown
} from 'components'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES } from 'app/constants'
import { util, momentFromNow } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import { MembersList } from 'components'
import {
  paginate,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown
} from 'utils/kbUtil'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import {
  MEBMER_GENDERS_FORM,
  MEMBER_AGE,
  MEMBER_GENDER_OBJ,
  DUTIES_TYPE_OBJ,
  DUTIES_TYPE_FORM
} from 'app/constants'
import { routerActions } from 'react-router-redux'
import NonMembersList from './NonMembersList'

var MembershipsNonMembers = React.createClass({
  displayName: 'MembershipsNonMembers',
  getInitialState() {
    return {
      loading: true,
      users: null // local state for user list
    }
  },

  _loadData(page, newFilters) {
    // api.getMembershipDashboard().then(({json, response}) => {
    //   this.setState({users: json.normal_user || []})
    // }, errors => {
    // }).finally(() => {
    //   this.setState({loading: false})
    // })
  },

  componentDidMount() {
    this.mounted = true
    this.refreshMember()
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this._loadData(nextProps.page)
    }
  },

  componentWillUnmount() {
    this.mounted = false
    this.setState({ loading: false })
  },

  refreshMember() {
    const { page } = this.props
    this._loadData(page)
  },

  render() {
    const { loc_id, locations } = this.props
    let users = this.state.users && Object.values(this.state.users)
    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>个人会员</span>
          </div>
        </header>
        <div className="nav-section-content-container">
          <KBLoadingContainer loading={!!users && this.state.loading}>
            <KBLoadingContainer loading={this.state.loading}>
              <NonMembersList
                refreshMember={this.refreshMember}
                loc_id={loc_id}
                users={users}
              />
            </KBLoadingContainer>
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let locations = props.locations
  let loc_id = props.loc_id

  return {
    loc_id,
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipsNonMembers)
