import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import { isListValue, getListFirstValue } from 'utils/kbUtil'

const menu = [
  {
    name: '服务商',
    value: ['', 'provider']
  },
  {
    name: '服务请求',
    value: ['request', 'request_detail']
  }
]

var ServiceBox = React.createClass({
  displayName: 'ServiceBox',
  componentDidMount() {
    const {
      getServiceRequestsAction,
      getServiceCategoriesAction,
      getServiceProvidersAction
    } = this.props

    api.getServiceRequests({ per_page: 500 }).then(
      json => {
        getServiceRequestsAction.success(json)
      },
      error => {
        getServiceRequestsAction.failure(error)
      }
    )

    api.getServiceCategories({ per_page: 500 }).then(
      json => {
        getServiceCategoriesAction.success(json)
      },
      error => {
        getServiceCategoriesAction.failure(error)
      }
    )

    // api.getServiceProviders({ per_page: 100}).then(json => {
    //   getServiceProvidersAction.success(json)
    // }, error => {
    //   getServiceProvidersAction.failure(error)
    // })
  },
  render() {
    const { section } = this.props

    return (
      <div className="kb-out-box clear-fix">
        <section className="kb-content">
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">服务商管理</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menu.map((data, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          isListValue(data.value, section)
                            ? 'nav-container-list-hover'
                            : ''
                        }
                      >
                        <Link
                          to={`/admin/service/${getListFirstValue(data.value)}`}
                        >
                          {' '}
                          {data.name}{' '}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          {this.props.children}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let sections = location.pathname.split('/')
  let section = sections.length > 2 ? sections[3] : ''

  return {
    section: section || ''
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getServiceProvidersAction: bindActionCreators(
      actions.serviceProvidersAction.get,
      dispatch
    ),
    getServiceCategoriesAction: bindActionCreators(
      actions.serviceCategoriesAction.get,
      dispatch
    ),
    getServiceRequestsAction: bindActionCreators(
      actions.serviceRequestsAction.get,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceBox)
