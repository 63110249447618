import React from 'react'
import { reduxForm } from 'redux-form'
import { KBForm, KBButton, KBAsyncInputList } from 'components'
import { api } from 'app/services'
import { CONTACTS_IDCARD_TYPE } from 'app/constants'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'

var OrgOpportunityForm = React.createClass({
  getInitialState() {
    return {
      formSubmitting: this.props.formSubmitting
    }
  },

  componentWillMount() {
    const {
      source,
      urlParams,
      fields: { opportunity_id }
    } = this.props
    if (source === 'crm' && urlParams && urlParams.opportunity_id) {
      opportunity_id.onChange(urlParams.opportunity_id)
    }
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.formSubmitting !== this.props.formSubmitting) {
      this.setState({
        formSubmitting: nextProps.formSubmitting
      })
    }
  },

  getOpportunitiesOfQuery(str, callback) {
    api.getOpportunities({ query: str, state: 'success' }).then(json => {
      const opportunities = getEntitiesJsonArray(json, 'opportunities')
      callback && callback(opportunities)
    })
  },

  saveOrgOppOfNext(model) {
    const { saveParams } = this.props
    const { formSubmitting } = this.state
    !formSubmitting && saveParams && saveParams(model, 5)
  },

  saveOrgOppOfComplete() {
    const { saveParams } = this.props
    const { formSubmitting } = this.state
    !formSubmitting && saveParams && saveParams({}, 5)
  },

  saveOrgOppOfUpdate(model) {
    const { saveParams } = this.props
    return saveParams && saveParams(model, false, true)
  },

  saveOrgOppOfLast() {
    const { fields, saveParams } = this.props
    let model = {}
    Object.keys(fields).map(field_key => {
      model[field_key] = fields[field_key].value
    })
    saveParams && saveParams(model, 3)
  },

  render() {
    const {
      fields: { opportunity_id },
      handleSubmit,
      opp_title,
      urlParams,
      isUpdate
    } = this.props
    const { formSubmitting } = this.state

    return (
      <KBForm type="reduxForm" fields={this.props.fields}>
        <header className="kb-form-header">
          <h2 className="header-title">客户信息</h2>
        </header>
        <div className="kb-form-container">
          <div className="kb-row kb-form-84">
            <KBAsyncInputList
              showImg={false}
              param={{ name: 'title', user: 'customer' }}
              field={opportunity_id}
              defaultValue={
                isUpdate ? opp_title || '' : urlParams.opp_name || ''
              }
              multi={false}
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint="请输入客户名称来搜索并关联客户"
              title="客户"
              isTextOver={false}
              getData={this.getOpportunitiesOfQuery}
            />
            <div />
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="button"
              onClick={this.saveOrgOppOfLast}
              style={{ marginRight: 30 }}
            >
              上一步
            </KBButton>
            <KBButton
              className="certain-btn"
              type="submit"
              onClick={handleSubmit(this.saveOrgOppOfNext)}
              style={{ marginRight: 30 }}
            >
              下一步
            </KBButton>
            {!isUpdate ? (
              <KBButton
                className="certain-btn"
                onClick={this.saveOrgOppOfComplete}
              >
                不关联客户
              </KBButton>
            ) : (
              <KBButton
                className="certain-btn"
                type="submit"
                onClick={handleSubmit(this.saveOrgOppOfUpdate)}
                submitting={formSubmitting}
              >
                完成编辑
              </KBButton>
            )}
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { org, urlParams } = props
  const isUpdate = urlParams && urlParams.type === 'update' && org
  const opp_title =
    org &&
    org.opportunities &&
    org.opportunities[0] &&
    org.opportunities[0].title

  return {
    isUpdate,
    opp_title,
    initialValues: org
      ? {
          ...org
        }
      : {}
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'OrgOpportunityForm',
  fields: ['opportunity_id'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgOpportunityForm)
