import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import {
  paginate,
  queryFilter,
  getFilterAndQuery,
  formatMinuteSecondEN
} from 'utils/kbUtil'
import { lockPermissionsActions, apiCallFailure } from 'app/actions'
import {
  KBPopover,
  KBPagination,
  KBLoadingContainer,
  KBTableWithoutData,
  KBAvatar,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import {
  canAdminLocationGroup,
  canAdminLocation,
  canAccessLocationOperator
} from 'app/reducers/role'
import { KBPopoverConfirm } from 'components/tools'
import {
  LOCKS_PERMISSIONS_PROGRESS,
  LOCKS_PERMISSIONS_PROGRESS_CLASS
} from 'app/constants'
import * as selectors from 'app/selectors'
import LocksPermissionsUpdateForm from '../members/LocksPermissionsUpdateForm'
import { getUserInfoUrl } from 'utils/kbUrl'
import moment from 'moment'
import LockManageForm from './LockManageForm'

const SOURCE_TYPE = {
  null: '手动添加',
  SalesSubscription: '入驻合同',
  Reservation: '会议室预定'
}

var LockPermissions = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      loading: false,
      pagination: null
    }
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="门禁权限导出"
        message="门禁权限列表已成功导出，可以前往报表中心下载"
      />
    )
  },

  exportLockPermissions() {
    const { loc_id, apiCallFailureActions } = this.props
    let start_at = moment()
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
    let end_at = moment()
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
    return api
      .lockPermissionReport({ location_id: loc_id, start_at, end_at })
      .then(
        () => {
          this.toReportCenter && this.toReportCenter()
        },
        error => {
          apiCallFailureActions({
            status: 'error',
            message: error.message || '服务器出问题了，请稍后重试哦~'
          })
        }
      )
  },

  componentDidMount() {
    const { filters, page } = this.props
    this.mounted = true
    this._loadLockPermissions(page, filters)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page !== nextProps.page) {
      this._loadLockPermissions(nextProps.page, {
        ...this.state.filters,
        ...filters
      })
    }
  },

  _loadLockPermissions(page, filters) {
    const {
      loc_id,
      getLocksPermissionsAction,
      query,
      routerActions
    } = this.props
    const per_page = 10
    let pageObj = { per_page, page }
    let params = Object.assign({}, pageObj, filters, { location_id: loc_id })
    let queryFiltersStr = queryFilter({ ...query, ...pageObj, ...filters })
    api.getLockPermissions(params).then(
      json => {
        // 防止用户点击过快时路由来回跳转
        if (!this.mounted) {
          return
        }
        routerActions.replace(
          `/admin/locations/${loc_id}/locks/lock_permission${queryFiltersStr}`
        )
        let pagination = paginate(json.response)

        this.setState({
          pagination
        })
        getLocksPermissionsAction.success(json, {
          entityName: 'lockPermissions'
        })
      },
      errors => {
        getLocksPermissionsAction.failure(errors)
      }
    )
  },

  updateLocksPermissions(data) {
    const { loc_id } = this.props
    KBPopover.show(
      <LocksPermissionsUpdateForm
        loc_id={loc_id}
        initialValues={{
          lock_permissions: [
            {
              id: data.id,
              startYMD: this.setYMD(data.start_at),
              endYMD: this.setYMD(data.end_at),
              start_at:
                typeof data.start_at === 'number'
                  ? moment.unix(data.start_at)
                  : moment(data.start_at),
              end_at:
                typeof data.end_at === 'number'
                  ? moment.unix(data.end_at)
                  : moment(data.end_at),
              startHM: this.setHM(data.start_at),
              endHM: this.setHM(data.end_at)
            }
          ]
        }}
      />
    )
  },

  deleteLocksPermissions(lock) {
    const { loc_id, deleteLocksPermissionsAction } = this.props
    let idArray = []
    idArray.push(lock.id)
    KBPopoverConfirm({
      name: '删除门禁权限',
      context: '是否要删除门禁 ' + lock.lock.name + ' 的权限',
      callback: () => {
        return api.deleteLockPermissions(loc_id, { ids: idArray }).then(
          json => {
            deleteLocksPermissionsAction.success(json, {
              id: lock.id,
              key: 'lockPermissions'
            })
            KBPopover.close()
          },
          error => {
            deleteLocksPermissionsAction.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  setYMD(value) {
    let date = typeof value === 'number' ? moment.unix(value) : moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    if (typeof value === 'number') {
      return moment
        .unix(value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    }
    return moment(value)
      .clone()
      .set({ year, month, date: day })
      .toDate()
  },

  setHM(value) {
    let date = typeof value === 'number' ? moment.unix(value) : moment(value)
    let hour = date.get('hour')
    let minute = date.get('minute')
    let processHour = ''
    let processMinute = ''

    processHour = hour

    if (minute == 30) {
      processMinute = 2
    } else {
      processMinute = 1
    }

    return processHour + ',' + processMinute
  },

  addLockPermission() {
    const { loc_id, organizations, locks } = this.props
    let filterLocks = locks.filter(lock => lock.location_id == loc_id)
    KBPopover.show(
      <LockManageForm
        loc_id={loc_id}
        locks={filterLocks}
        organizations={organizations}
      />
    )
  },

  render() {
    const { loading, pagination } = this.state
    const { isManager, loc_id, lockPermissions, organizations } = this.props

    return (
      <section className="kb-content-container clear-fix">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>门禁权限</span>
          </h2>
          <div className="f-right">
            <button
              className="c-btn-secondary"
              onClick={this.exportLockPermissions}
            >
              导出门禁权限
            </button>
            {/* {isManager && organizations.length ? (
              <button
                className="bordered-btn m-left-sm"
                onClick={this.addLockPermission}
              >
                添加门禁权限
              </button>
            ) : null} */}
          </div>
        </header>
        <div className="nav-section-content-container">
          <KBLoadingContainer loading={loading}>
            <table className="content-table">
              <thead>
                <tr>
                  <th>拥有者</th>
                  <th>门禁名称</th>
                  <th>权限</th>
                  <th>开始时间</th>
                  <th>结束时间</th>
                  <th>赋予原因</th>
                  <th>所属门禁组</th>
                  <th />
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={lockPermissions.length > 0}
                tableHeadNum="6"
                tipMessage="暂无数据"
              >
                {lockPermissions &&
                  lockPermissions.map((json, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {json.user ? (
                            <div className="vf-center">
                              <KBAvatar
                                user={json.user}
                                imgStyle={{ marginRight: 5 }}
                                style={{
                                  display: 'inline-block',
                                  marginRight: '5px'
                                }}
                                size={30}
                              />
                              <Link
                                to={getUserInfoUrl(json.user.id)}
                                className="color-link"
                              >
                                {json.user.name || ''}
                              </Link>
                            </div>
                          ) : null}
                        </td>
                        <td style={{ maxWidth: 200 }}>{json.lock.name}</td>
                        <td>
                          <span
                            style={{ marginLeft: 0 }}
                            className={`finance-default ${
                              LOCKS_PERMISSIONS_PROGRESS_CLASS[json.progress]
                            }`}
                          >
                            {' '}
                            {LOCKS_PERMISSIONS_PROGRESS[json.progress]}
                          </span>
                        </td>
                        <td>
                          {(json.start_at &&
                            formatMinuteSecondEN(json.start_at)) ||
                            '暂无'}
                        </td>
                        <td>
                          {(json.end_at && formatMinuteSecondEN(json.end_at)) ||
                            '暂无'}
                        </td>
                        <td>{SOURCE_TYPE[json.source_type]}</td>
                        <td>{json.lock_group ? json.lock_group.name : '-'}</td>
                        {isManager ? (
                          <td className="t-right">
                            <i
                              className="iconfont icon-edit a-hover"
                              onClick={this.updateLocksPermissions.bind(
                                null,
                                json
                              )}
                            />
                            <i
                              className="iconfont icon-delete m-left-sm a-hover"
                              onClick={this.deleteLocksPermissions.bind(
                                null,
                                json
                              )}
                            />
                          </td>
                        ) : null}
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/locations/${loc_id}/locks/lock_permission?page=#PAGE#`}
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const loc_id = params.id
  let lockPermissions = selectors.getLocksPermissions(state) || []

  let isGroupManager = canAdminLocationGroup(state.user)
  let isLocationOperator = canAccessLocationOperator(state.user, loc_id)
  let isManager = canAdminLocation(state.user, loc_id)

  if (isLocationOperator && !isGroupManager && !isManager) {
    isManager = false
  }

  let page = parseInt(location.query && location.query.page, 10)
  page = isNaN(page) ? 1 : page

  let paramsAll = getFilterAndQuery(location.query, ['include_ended'])

  let organizations = Object.assign(
    [],
    selectors.getOrgsOfLocation(state, loc_id)
  )
  const locks = selectors.getLocks(state)

  return {
    loc_id,
    isManager,
    lockPermissions,
    organizations,
    locks,
    page,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    getLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.replace,
      dispatch
    ),
    deleteLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.delete,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockPermissions)
