import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { areaActions } from 'app/actions'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import {
  KBInput,
  KBUserInput,
  KBPopover,
  KBDropDownSpring,
  KBForm,
  KBFormError,
  KBButton
} from 'components'
import { PARKING_SPACE_TYPE } from 'app/constants'

var ParkingSpaceForm = React.createClass({
  getInitialState() {
    return {
      floorList: [],
      districtList: []
    }
  },

  componentDidMount() {
    this.getTags()
  },

  getTags() {
    const { loc_id } = this.props
    api
      .getSettingTags({
        model: 'area',
        columns: 'ceils,districts',
        location_id: loc_id
      })
      .then(json => {
        let res = json.response.body || {}
        let allFloors = Object.keys(res.ceils).map(key => {
          return { id: key, value: key }
        })
        let allDistricts = Object.keys(res.districts).map(key => {
          return { id: key, value: key }
        })
        let floorList = Object.assign([], this.state.floorList, allFloors || [])
        let districtList = Object.assign(
          [],
          this.state.districtList,
          allDistricts || []
        )
        this.setState({
          floorList,
          districtList
        })
      })
  },

  createParkingSpace(model) {
    const { loc_id, refreshParkingStatistic } = this.props

    model.area_type = 'parking'
    model.capacity = 1
    model.desk_serial_numbers = [model.name]

    return api.createArea(loc_id, model).then(json => {
      this.props.createAreaAction.success(json, { location_id: loc_id })
      refreshParkingStatistic && refreshParkingStatistic()
      KBPopover.close()
    })
  },

  updateParkingSpace(model) {
    const { parking } = this.props

    delete model.area_type

    return api.updateArea(parking.id, model).then(json => {
      this.props.updateAreaAction.success(json)
      KBPopover.close()
    })
  },

  parkingSpaceForm(model) {
    if (!model.lowest_price) {
      model.lowest_price = 0
    }
    if (this.props.isUpdate) {
      return this.updateParkingSpace(model)
    }
    return this.createParkingSpace(model)
  },

  render() {
    const {
      fields: {
        name,
        ceil_list,
        district_list,
        sub_area_type,
        listing_price,
        lowest_price
      },
      submitting,
      handleSubmit,
      isUpdate
    } = this.props
    const { floorList, districtList } = this.state

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    return (
      <KBForm
        type="reduxForm"
        ref="ParkingSpaceForm"
        onSubmit={handleSubmit(this.parkingSpaceForm)}
        fields={this.props.fields}
      >
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">{isUpdate ? '修改' : '添加'}车位</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row kb-form-66">
            <KBDropDownSpring
              newHint="添加新楼层"
              title="所属楼层"
              {...halfClass}
              field={ceil_list}
              source={floorList}
            />
            <KBDropDownSpring
              newHint="添加新区域"
              title="所属区域"
              {...halfClass}
              field={district_list}
              source={districtList}
            />
          </div>

          <div className="kb-row kb-form-66">
            <KBInput
              title="车位编号"
              {...halfClass}
              field={name}
              isRequired={true}
            />
            <KBUserInput
              title="车位类型"
              placeholder="请选择"
              {...halfClass}
              field={sub_area_type}
              multi={false}
              users={PARKING_SPACE_TYPE}
              selectedUsers={this.props.selectedParkingType}
            />
          </div>

          <div className="kb-row kb-form-66">
            <KBInput
              title={`车位价格`}
              {...halfClass}
              inputClass="input-dollar-moth"
              type="text"
              field={listing_price}
              isRequired={true}
            />
            <KBInput
              title={`最低车位价格`}
              {...halfClass}
              inputClass="input-dollar-moth"
              type="text"
              field={lowest_price}
              titleClass="label-rt"
            />
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

ParkingSpaceForm.propTypes = {
  onAreaCreated: PropTypes.func,
  areaEdited: PropTypes.func,
  parking: PropTypes.object
}

function mapStateToProps(state, props) {
  const { parking, type } = props
  const isUpdate = type === 'update'
  const selectedParkingType = isUpdate
    ? PARKING_SPACE_TYPE.filter(p => parking.sub_area_type === p.id)
    : PARKING_SPACE_TYPE.filter(p => 'flat_plate' === p.id)
  const initialValues = isUpdate
    ? { ...parking }
    : { sub_area_type: 'flat_plate' }

  return {
    selectedParkingType,
    isUpdate,
    initialValues
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createAreaAction: bindActionCreators(areaActions.create, dispatch),
    updateAreaAction: bindActionCreators(areaActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入车位编号'
  }

  if (valid.required(values.listing_price)) {
    errors.listing_price = '请输入车位价格'
  }

  if (values.listing_price && valid.isPrice(values.listing_price)) {
    errors.listing_price = '请输入正确的价格'
  }

  if (values.lowest_price && valid.isPrice(values.lowest_price)) {
    errors.lowest_price = '请输入正确的价格'
  }

  if (
    values.listing_price &&
    values.lowest_price &&
    parseFloat(values.listing_price) < parseFloat(values.lowest_price)
  ) {
    errors.lowest_price = '最低车位价格不能大于车位价格'
  }

  return errors
}

const formConfig = {
  form: 'ParkingSpaceForm',
  fields: [
    'name',
    'area_type',
    'ceil_list',
    'district_list',
    'sub_area_type',
    'capacity',
    'desk_serial_numbers',
    'listing_price',
    'lowest_price'
  ],
  validate: validate,
  touchOnBlur: false
}

ParkingSpaceForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ParkingSpaceForm)

export default ParkingSpaceForm
