import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { formatYearDayEN } from 'utils/kbUtil'
import { subscriptionActions, apiCallFailure } from 'app/actions'
import { api, apiUtils } from 'app/services'
import {
  KBDateInput,
  KBTextarea,
  KBFormError,
  KBButton,
  KBPopover,
  KBInput,
  KBSelect
} from 'components'
import { RECURRING_CYCLES,LINE_ITEM_TYPES } from 'app/constants'
import { getCurrentLocation } from 'utils/kbUtil'
import { ADD_SUBSCRIPTION } from 'app/constants/hint'
import moment from 'moment'
import lodash from 'lodash'
const format = 'YYYY-MM-DD'
let isCon=false
var RentingPhasesForm = React.createClass({
  getInitialState() {
    return {
      isLoading: false
    }
  },
  componentDidMount(){
    const {isC} = this.props
    isCon=isC
  },
  getAllPrice() {
    const {
      sales_resources
    } = this.props
    let allPrice=0
    sales_resources.forEach(sales=>{
      allPrice+=parseFloat(sales.cycle_fee.value)
    })
    return allPrice
  },
  parseRenting(model) {
    const {
      sales_renting_phases,
      callback,
      apiCallFailureActions,
      status,
      oldContract,
      valid_date,
      end_date,
      subscription_start_date,
      subscription_end_date,
      location_id,
      sales_resources,
      isC
    } = this.props
    model.location_id = location_id
    model.start_date =
      formatYearDayEN(model.start_date) ||
      formatYearDayEN(moment().format(format))
    model.end_date =
      formatYearDayEN(model.end_date) ||
      formatYearDayEN(moment().format(format))
    model.subscription_start_date =
      formatYearDayEN(subscription_start_date) ||
      formatYearDayEN(moment().format(format))
    model.subscription_end_date = formatYearDayEN(subscription_end_date)
    if (!model.subscription_end_date) {
      KBPopover.close()
      return apiCallFailureActions({
        status: 'error',
        message: '请填写合同终止日期'
      })
    }
    model.recurring_amount = parseFloat(model.recurring_amount)
    model.recurring_cycles = parseFloat(model.recurring_cycles)
    model.recurring_house_fee = parseFloat(model.recurring_house_fee)
    model.recurring_renting_fee = parseFloat(model.recurring_renting_fee)
    if(isC){
      model.recurring_cycle_fee=this.getAllPrice()
      model.recurring_amount=this.getAllPrice()
    }
    if (
      moment(model.end_date).isAfter(end_date) &&
      !moment(model.end_date).isSame(end_date)
    ) {
      return apiCallFailureActions({
        status: 'error',
        message: '交租阶段终止日期不能大于合同终止日期'
      })
    }

    if (status == 'update') {
      model.id = oldContract.id
      return api
        .getChangeRoomExpandRentingPhase(oldContract.id, model)
        .then(({ json }) => {
          callback && callback(json, true)
          KBPopover.close()
        })
    }

    let isConflict = false
    sales_renting_phases.map(phase => {
      let start_date_m = model.start_date
      let end_date_m = model.end_date
      let start_date_p = phase.start_date.value
      let end_date_p = phase.end_date.value

      if (
        moment(start_date_m).isSame(start_date_p) ||
        moment(start_date_m).isSame(end_date_p) ||
        moment(end_date_m).isSame(start_date_p) ||
        moment(end_date_m).isSame(end_date_p) ||
        moment(start_date_m).isBetween(start_date_p, end_date_p) ||
        moment(end_date_m).isBetween(start_date_p, end_date_p)
      ) {
        isConflict = true
      }
    })
    if (isConflict) {
      return apiCallFailureActions({
        status: 'error',
        message: '您设置的缴租阶段起止日期与现有缴租阶段的日期重叠，请修改'
      })
    }

    let free_phases = []
    this.setState({
      isLoading: true
    })

    if (sales_renting_phases.length > 0) {
      sales_renting_phases.map(phase => {
        if (phase.rent_type && phase.rent_type.value == 'free') {
          free_phases.push({
            start_date: formatYearDayEN(phase.start_date.value),
            end_date: formatYearDayEN(phase.end_date.value)
          })
        }
      })
    }
    model.free_phases = free_phases
    return api.getExpandRentingPhase(model).then(({ json }) => {
      callback && callback(json)
      KBPopover.close()
    })
  },

  componentWillUnmount() {
    this.setState({
      isLoading: false
    })
  },

  startDateCallBack(value) {
    const { valid_date, status } = this.props
    if (status == 'update') {
      valid_date.onChange(value)
    }
  },

  changeRecurringAmount(e) {
    let value = e.target.value
    let floatValue = parseFloat(value || 0)
    let key = e.target.name
    const { fields } = this.props
    const {
      recurring_amount,
      recurring_renting_fee,
      recurring_house_fee
    } = fields
    let pureRecurringRentingFee = 0
    if (key === 'recurring_house_fee') {
      pureRecurringRentingFee =
        parseFloat(recurring_amount.value || 0) - floatValue
    }
    if (key === 'recurring_amount') {
      pureRecurringRentingFee =
        floatValue - parseFloat(recurring_house_fee.value || 0)
    }

    fields[key].onChange(value)
    recurring_renting_fee.onChange(pureRecurringRentingFee)
  },

  render() {
    const {
      fields: {
        start_date,
        end_date,
        recurring_amount,
        recurring_cycles,
        recurring_due_day,
        note,
        recurring_house_fee,
        phase_rent_amount_setting
      },
      error,
      submitting,
      handleSubmit,
      totalTenement,
      house_fee_per_m2,
      enting_phase_recurring_date_compute_way,
      enting_phase_recurring_date_compute_day,
      status,
      sales_resources,
      isC
    } = this.props
    const { isLoading } = this.state
    const hint = ADD_SUBSCRIPTION
    let DAYS = []
    for (let i = 1; i <= 31; i++) {
      DAYS.push(i)
    }

    return (
      <form onSubmit={handleSubmit(this.parseRenting)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-header">
          <h2 className="header-title">添加缴租阶段</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-row kb-form-66">
            <KBDateInput
              title="阶段开始"
              className="kb-form-group kb-div-c39"
              isShowCenter={true}
              field={start_date}
              onChange={this.startDateCallBack}
            />
            <KBDateInput
              title="阶段终止"
              className="kb-form-group kb-div-c48"
              isShowCenter={true}
              field={end_date}
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row kb-form-66">
            <div className="kb-form-group kb-div-c39">

              {isC ? <div>
                <label>周期性费用</label>
                <input
                  type="text"
                  className="kb-input"
                  disabled={true}
                  defaultValue={this.getAllPrice()}
                />
              </div>
              :
              <div>
                <label>月会员费</label>
                <input
                  type="text"
                  className="kb-input"
                  {...recurring_amount}
                  onChange={e => {
                    this.changeRecurringAmount(e)
                  }}
                />
                <p
                  style={{
                    color: '#c8c8c8',
                    fontSize: 12,
                    paddingTop: 2,
                    marginTop: 8
                  }}
                >
                  {hint.month_price}
                </p>
              </div>}
            </div>
            {house_fee_per_m2 && house_fee_per_m2 != 0 && !isC ? (
              <div className="kb-form-group kb-div-c48">
                <label className="label-rt">月物业费</label>
                <div>
                  <input
                    type="text"
                    className="kb-input"
                    {...recurring_house_fee}
                    onChange={e => {
                      this.changeRecurringAmount(e)
                    }}
                  />
                  {recurring_house_fee.touched && recurring_house_fee.error ? (
                    <p className="lr-error">{recurring_house_fee.error}</p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div className="kb-row kb-form-66">
            <div className="kb-form-group kb-div-c39">
              <label>{isC ? "收费周期" : "会员费周期"}</label>
              <div>
                <select name="cycles" {...recurring_cycles}>
                  {RECURRING_CYCLES.map((json, index) => {
                    return (
                      <option value={json.id} key={index}>
                        {json.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div />
          </div>
          <div className="kb-row kb-form-66">
            <div className="kb-form-group kb-form-c39 kb-form-alone">
              <label>缴{isC ? '费' : '租'}日</label>
              <div>
                {enting_phase_recurring_date_compute_way == 'advance' ? (
                  ''
                ) : (
                  <select name="dueday" {...recurring_due_day}>
                    {DAYS.map(day => {
                      return (
                        <option value={day} key={day}>{`${day} 号`}</option>
                      )
                    })}
                  </select>
                )}
                <p
                  style={{
                    color: '#c8c8c8',
                    fontSize: 12,
                    paddingTop: 2,
                    marginTop: 8
                  }}
                >
                  {enting_phase_recurring_date_compute_way == 'advance'
                    ? `会员费周期开始日前${enting_phase_recurring_date_compute_day}天`
                    : `会员费周期开始日前一个月的${recurring_due_day.value}号`}
                </p>
              </div>
            </div>
            <div />
          </div>
          {phase_rent_amount_setting.length ? (
            <div className="kb-row">
              <div
                className="kb-row kb-title"
                style={{ fontSize: 16, color: '#666' }}
              >
                月会员费调整
              </div>
              {phase_rent_amount_setting.map((data, index) => {
                return (
                  <div className="kb-row kb-form-66">
                    <KBDateInput
                      title="开始日期"
                      className="kb-form-group kb-div-c39"
                      isShowCenter={true}
                      field={data.month}
                    />
                    <KBInput
                      title="月会员费"
                      className="kb-form-group kb-div-c48"
                      field={data.recurring_amount}
                      titleClass="label-rt"
                    />
                  </div>
                )
              })}
            </div>
          ) : null}
          <div className="kb-row">
            <KBTextarea
              title="备注"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={note}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              {isLoading ? '加载' : '确定'}
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const {
    total,
    start_date,
    end_date,
    totalTenement,
    enting_phase_recurring_date_compute_day,
    monthly_renting_fees,
    loc_id,
    isC
  } = props
  let currentLocation = getCurrentLocation()
  let location_id = (currentLocation && currentLocation.id) || loc_id
  return {
    initialValues: {
      recurring_amount: parseFloat(total).toFixed(2) || 0,
      start_date,
      end_date,
      recurring_cycles: 1,
      recurring_due_day: enting_phase_recurring_date_compute_day || 31,
      recurring_house_fee: parseFloat(totalTenement).toFixed(2),
      recurring_renting_fee: parseFloat(total - totalTenement).toFixed(2),
      phase_rent_amount_setting: monthly_renting_fees,
      rent_type: isC ? 'no_sales_area' : 'normal'
    },
    location_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (!isCon && valid.isPrice(values.recurring_amount)) {
    errors.recurring_amount = '请输入正确的价格, 小数点保留2位'
  }

  if (
    !isCon &&
    values.recurring_house_fee &&
    parseFloat(values.recurring_house_fee) > parseFloat(values.recurring_amount)
  ) {
    errors.recurring_house_fee = '月物业费不能大于月会员费'
  }

  return errors
}

const formConfig = {
  form: 'RentingPhasesForm',
  fields: [
    'start_date',
    'end_date',
    'recurring_amount',
    'recurring_cycles',
    'recurring_due_day',
    'note',
    'recurring_house_fee',
    'recurring_renting_fee',
    'free_phases[].start_date',
    'free_phases[].end_date',
    'phase_rent_amount_setting[].month',
    'phase_rent_amount_setting[].recurring_amount',
    'rent_type',
    'recurring_cycle_fee'
  ],
  touchOnBlur: false,
  validate: validate
}

RentingPhasesForm.propTypes = {
  total: PropTypes.number.isRequired,
  start_date: PropTypes.string.isRequired,
  callback: PropTypes.func,
  end_date: PropTypes.object.isRequired
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(RentingPhasesForm)
