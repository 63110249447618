import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import { apiCallFailure, lockPermissionsActions } from 'app/actions'
import {
  KBDateInput,
  KBPopover,
  KBPopoverTop,
  KBFormError,
  KBButton,
  KBTransfer,
  KBSelect
} from 'components'
import LockPermissionCertainForm from './LockPermissionCertainForm'
import { DAY_TIME_HM, LOCK_MANAGE_TIPSY } from 'app/constants'
import moment from 'moment'
import * as selectors from 'app/selectors'
const pinyin = require('tiny-pinyin')

var LockManageForm = React.createClass({
  displayName: 'LockManageForm',
  getInitialState() {
    return {
      memberSource: [],
      lockSource: [],
      lockGroupSource: [],
      memberTarget: [],
      lockTarget: [],
      lockGroupTarget: []
    }
  },
  getData(id) {
    const { loc_id } = this.props

    var organizationUsers = api
      .getUsers({
        location_id: loc_id,
        organization_id: id,
        per_page: 1000,
        includes: 'space_users'
      })
      .then(({ json }) => {
        return Object.values(json.entities.users || {})
      })

    return organizationUsers
  },
  createLockdForm() {
    const { apiCallFailureActions } = this.props
    let members = this.refs.memberTransfer.getData().target
    let locks = this.refs.lockTransfer.getData().target
    let lockGroups = this.refs.lockGroupTransfer.getData().target
    if (members.length <= 0) {
      apiCallFailureActions({
        status: 'error',
        message: '请选择会员'
      })
      return
    }
    if (lockGroups.length <= 0 && locks.length <= 0) {
      apiCallFailureActions({
        status: 'error',
        message: '请选择门禁'
      })
      return
    }
    this.showLockPermissionCertainForm(members, locks, lockGroups)
  },

  showLockPermissionCertainForm(members, locks, lockGroups) {
    const {
      fields: { start_at, end_at },
      loc_id
    } = this.props
    let lock_permissions = []
    members.length > 0 &&
      members.map(member => {
        member.childrens.map(child => {
          // 对每个 member 加上所选的门禁权限
          locks.length > 0 &&
            locks.map(lock => {
              lock.childrens.map(lockChild => {
                lock_permissions.push({
                  user_id: child.id,
                  lock_id: lockChild.id,
                  start_at: start_at.value,
                  end_at: end_at.value
                })
              })
            })
          // 对每个 member 加上所选的门禁组及其所属的门禁权限
          lockGroups.length > 0 &&
            lockGroups.map(lock_group => {
              lock_group.childrens.map(lockChild => {
                lock_permissions.push({
                  user_id: child.id,
                  lock_group_id: lock_group.id,
                  lock_id: lockChild.id,
                  start_at: start_at.value,
                  end_at: end_at.value
                })
              })
            })
        })
      })
    KBPopoverTop.show(
      <LockPermissionCertainForm
        start_at={start_at}
        end_at={end_at}
        members={members}
        lockGroups={lockGroups}
        locks={locks}
        lock_permissions={lock_permissions}
        loc_id={loc_id}
      />
    )
  },

  componentDidMount() {
    const {
      fields: { start_at, end_at, startHM, endHM, startYMD, endYMD },
      organizations,
      locks,
      getOrgMember
    } = this.props

    // 对门禁按照 area.area_type 进行分组
    let tempIds = {}
    locks &&
      locks.map(json => {
        let tempIdKeys = Object.keys(tempIds)
        let s_id = (json.area && json.area.area_type) || 'gate'
        if (tempIdKeys.includes(String(s_id))) {
          tempIds[s_id].push(json)
        } else {
          tempIds[s_id] = []
          tempIds[s_id].push(json)
        }
      })

    let arrayedLocks = Object.values(tempIds)
    let mapedLocks = []

    arrayedLocks &&
      arrayedLocks.map(data => {
        let id = mapedLocks.length || 0
        let nameIndex = (data[0].area && data[0].area.area_type) || 'gate'
        let name = LOCK_MANAGE_TIPSY[nameIndex]
        let childrens = data
        mapedLocks.push({ id, name, childrens })
      })

    this.setState({
      memberSource: organizations.filter(o => {
        let members = getOrgMember(o.id)
        return members && members.length
      }),
      lockSource: mapedLocks
    })
    startHM.onChange('9,1')
    endHM.onChange('9,1')
    startYMD.onChange(new Date())
    endYMD.onChange(
      moment(new Date())
        .clone()
        .set({ date: new Date().getDate() + 1 })
    )
    start_at.onChange(
      moment(new Date())
        .clone()
        .set({ hour: 9, minute: 0, second: 0 })
    )
    end_at.onChange(
      moment(new Date())
        .clone()
        .set({ date: new Date().getDate() + 1, hour: 9, minute: 0, second: 0 })
    )
    this.getLockGroups()
  },

  getLockGroups() {
    const { loc_id } = this.props

    api.getLockGroups({ location_id: loc_id, per_page: 1000, page: 1 }).then(
      json => {
        let lockGroupSource = json.response.body

        this.setState({
          lockGroupSource
        })
      },
      errors => {}
    )
  },

  getLocksOfGroup(id) {
    const { lockGroupSource } = this.state
    let children = lockGroupSource.find(l_g => l_g.id == id).locks

    children &&
      children.map(lock => {
        lock.pinyin = pinyin.convertToPinyin(lock.name).split('')[0]
      })
    children &&
      children.sort((a, b) => {
        return a.pinyin.localeCompare(b.pinyin)
      })

    return children
  },

  getAreaLocks(id) {
    const { lockSource } = this.state
    let children = lockSource[id].childrens

    children &&
      children.map(lock => {
        lock.pinyin = pinyin.convertToPinyin(lock.name).split('')[0]
      })
    children &&
      children.sort((a, b) => {
        return a.pinyin.localeCompare(b.pinyin)
      })

    return children
  },

  changeStartYMDCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setYMD(start_at, data)
  },

  changeEndYMDCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setYMD(end_at, data)
  },

  changeStartHMCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setHM(start_at, data)
  },

  changeEndHMCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setHM(end_at, data)
  },

  setYMD(field, value) {
    let date = moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  setHM(field, value) {
    let t = value.split(',')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ hour: t[0], minute: t[1] == '1' ? 0 : 30, second: 0 })
        .toDate()
    )
  },

  render() {
    const {
      fields: { startHM, endHM, startYMD, endYMD },
      error,
      submitting,
      handleSubmit,
      state,
      areas,
      selectAreas,
      lock,
      DEAL_DAY_TIME_HM
    } = this.props
    let {
      memberSource,
      memberTarget,
      lockSource,
      lockTarget,
      lockGroupSource,
      lockGroupTarget
    } = this.state
    var halfClass = {
      className: 'kb-form-group kb-div-c48'
    }
    return (
      <form onSubmit={handleSubmit(this.createLockdForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">添加门禁权限</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="开始日期"
              isRequired={true}
              className="kb-form-group kb-div-48"
              field={startYMD}
              onChange={this.changeStartYMDCallback}
            />
            <KBSelect
              title="开始时间"
              onChange={this.changeStartHMCallback}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
              field={startHM}
              {...halfClass}
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="结束日期"
              isRequired={true}
              className="kb-form-group kb-div-48"
              field={endYMD}
              onChange={this.changeEndYMDCallback}
            />
            <KBSelect
              title="结束时间"
              onChange={this.changeEndHMCallback}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
              field={endHM}
              {...halfClass}
            />
          </div>

          <div className="kb-title border-none">
            <i
              className="iconfont icon-star"
              style={{ fontSize: 18, marginRight: 5, color: '#666' }}
            />
            <span>会员选择</span>
          </div>
          <div className="kb-row">
            <KBTransfer
              ref="memberTransfer"
              isSearch={false}
              isChildrenLoad={true}
              isHead={true}
              isMiddle={false}
              getExpandChilds={this.getData}
              multi={true}
              sourceTitle="会员"
              targetTitle="已添加"
              source={memberSource}
              target={memberTarget}
            />
          </div>

          <div className="kb-title border-none">
            <i
              className="iconfont icon-close2"
              style={{ fontSize: 18, marginRight: 5, color: '#666' }}
            />
            <span>门禁组选择</span>
          </div>
          <div className="kb-row">
            <KBTransfer
              ref="lockGroupTransfer"
              isSearch={false}
              isHead={true}
              isMiddle={false}
              isChildrenLoad={true}
              getExpandChilds={this.getLocksOfGroup}
              multi={true}
              sourceTitle="门禁组"
              targetTitle="已添加"
              source={lockGroupSource}
              target={lockGroupTarget}
            />
          </div>

          <div className="kb-title border-none">
            <i
              className="iconfont icon-close2"
              style={{ fontSize: 18, marginRight: 5, color: '#666' }}
            />
            <span>门禁选择</span>
          </div>
          <div className="kb-row">
            <KBTransfer
              ref="lockTransfer"
              isSearch={false}
              isHead={true}
              isMiddle={false}
              isChildrenLoad={true}
              getExpandChilds={this.getAreaLocks}
              multi={true}
              sourceTitle="门禁"
              targetTitle="已添加"
              source={lockSource}
              target={lockTarget}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { entities } = state
  const { loc_id, lock } = props
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(0, 24)
  const getOrgMember = selectors.getMembersOfOrg.bind(null, state)
  return {
    getOrgMember,
    DEAL_DAY_TIME_HM,
    loc_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.create,
      dispatch
    ),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'LockManageForm',
  fields: ['start_at', 'end_at', 'startHM', 'endHM', 'startYMD', 'endYMD'],
  validate: validate,
  touchOnBlur: false
}

LockManageForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LockManageForm)

export default LockManageForm
