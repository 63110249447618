import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import {
  paginate,
  queryFilter,
  getFilterAndQuery,
  formatMinuteSecondEN,
  getServiceErrorMessage
} from 'utils/kbUtil'
import { apiCallFailure, successState } from 'app/actions'
import {
  KBPopover,
  KBPagination,
  KBLoadingContainer,
  KBTableWithoutData,
  KBDropdown,
  KBPopoverTop,
  KBToReportCenter,
  KBCheckBox
} from 'components'
import { KBDayPicker } from 'components/views'
import { canAccessLocation } from 'app/reducers/role'
import { KBPopoverConfirm } from 'components/tools'
import moment from 'moment'

const DAYS_ARRAY = [30, 60, 90]

var LockInactiveUsers = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters: filters,
      loading: false,
      pagination: null,
      inactiveUsers: [],
      searchTime: moment().toDate(),
      lastDate: '',
      selectedQueryDays: 30,
      selectedUsers: [],
      isAllUsersChecked: false,
      allCheckedBtn: false
    }
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="疑似离职人员管理报表导出"
        message="疑似离职人员管理报表已成功导出，可以前往报表中心下载"
      />
    )
  },

  exportInactiveUsers() {
    const { loc_id, apiCallFailureActions } = this.props
    const { searchTime, selectedQueryDays } = this.state

    return api
      .getLastOpenDoorReport({
        location_id: loc_id,
        last_date: moment(searchTime)
          .subtract(selectedQueryDays, 'days')
          .format('YYYY-MM-DD'),
        as_json: false
      })
      .then(
        () => {
          this.toReportCenter && this.toReportCenter()
        },
        error => {
          apiCallFailureActions({
            status: 'error',
            message: getServiceErrorMessage(error)
          })
        }
      )
  },

  componentDidMount() {
    const { filters, page } = this.props
    this.mounted = true
    if (!filters.last_date) {
      filters.last_date = moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD')
    }
    this._loadData(page, filters)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page !== nextProps.page) {
      this._loadData(nextProps.page, { ...this.state.filters, ...filters })
    }
  },

  _loadData(page, filters) {
    const { loc_id, query, routerActions, apiCallFailureActions } = this.props
    const { lastDate } = this.state
    const per_page = 10
    let pageObj = { per_page, page }
    let params = {}
    params = Object.assign({}, pageObj, filters, { location_id: loc_id })
    // if(lastDate && lastDate != ''){
    // 	params.last_date = lastDate
    // }
    let queryFiltersStr = queryFilter({ ...query, ...pageObj })
    params.as_json = true

    this.setState({
      loading: true
    })
    api
      .getLastOpenDoorReport(params)
      .then(
        json => {
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/locations/${loc_id}/locks/inactive_users${queryFiltersStr}`
          )
          let pagination = paginate(json.response)
          let inactiveUsers = json.response.body.body || []

          this.setState({
            pagination,
            inactiveUsers
          })
        },
        errors => {
          apiCallFailureActions({
            status: 'error',
            message: getServiceErrorMessage(errors)
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
          selectedUsers: []
        })
      })
  },

  loadDataWithParams(page) {
    const { searchTime, selectedQueryDays } = this.state
    let lastDate = moment(searchTime)
      .subtract(selectedQueryDays, 'days')
      .format('YYYY-MM-DD')
    this.setState({
      filters: { last_date: lastDate || '' }
    })
    const params = Object.assign({}, { last_date: lastDate })
    setTimeout(this._loadData.bind(null, page || 1, params))
  },

  // deleteLocksPermissions (lock) {
  // 	const { loc_id, deleteLocksPermissionsAction } = this.props
  // 	let idArray = []
  // 	idArray.push(lock.id)
  // 	KBPopoverConfirm({
  // 		name     : '删除门禁权限',
  // 		context  : '是否要删除门禁 ' + lock.lock.name + ' 的权限',
  // 		callback : () => {
  // 			return api.deleteLockPermissions(loc_id,{ ids : idArray }).then(json => {
  // 				deleteLocksPermissionsAction.success(json,{ id: lock.id, key: 'LockInactiveUsers'})
  // 				KBPopover.close()
  // 			}, error => {
  // 				deleteLocksPermissionsAction.failure(error)
  // 				KBPopover.close()
  // 			})
  // 		}
  // 	})
  // },

  _renderFilters() {
    const { searchTime, selectedQueryDays } = this.state

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 25
        }}
      >
        <div>
          <KBDropdown ref="filterDate">
            <KBDropdown.KBDropdownTrigger>
              <div className="task-status-title">
                <i
                  className="iconfont icon-calendar"
                  style={{ marginRight: '3px' }}
                />
                <span>查询日期:{moment(searchTime).format('YYYY-MM-DD')}</span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <KBDayPicker
                onChange={date => {
                  this.setState({ searchTime: date })
                }}
                selectedDay={searchTime}
              />
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
          <div className="time-filter">
            {DAYS_ARRAY.map(days => {
              return (
                <button
                  className={selectedQueryDays === days ? 'active' : ''}
                  onClick={() => {
                    this.setState({ selectedQueryDays: days })
                  }}
                >
                  {days}天
                </button>
              )
            })}
          </div>
        </div>
        <button
          className="certain-btn"
          onClick={() => {
            this.loadDataWithParams()
          }}
        >
          查询
        </button>
      </div>
    )
  },

  checkAllUsers() {
    const { allCheckedBtn } = this.state
    const { inactiveUsers } = this.state
    let isAllUsersChecked_now = !allCheckedBtn
    let users_now = inactiveUsers
    if (!isAllUsersChecked_now) {
      users_now = []
    }

    this.setState({
      isAllUsersChecked: isAllUsersChecked_now,
      selectedUsers: users_now,
      allCheckedBtn: isAllUsersChecked_now
    })
  },

  changeUser(checked, data) {
    var users = Object.assign([], this.state.selectedUsers)
    if (checked) {
      users.push(data)
      // 当选中的 user 逐个增加时的判断
      if (users.length == 10) {
        this.setState({
          selectedUsers: users,
          allCheckedBtn: true,
          isAllUsersChecked: true
        })
      } else {
        this.setState({
          selectedUsers: users,
          allCheckedBtn: false
        })
      }

      return
    }

    let removeIndex = users.findIndex(
      json => json.org_user_id == data.org_user_id
    )

    if (removeIndex == -1) {
      return
    }

    users.splice(removeIndex, 1)
    // 当选中的 user 逐个减少时的判断
    if (users.length == 10) {
      this.setState({
        selectedUsers: users,
        allCheckedBtn: true
      })
    } else {
      this.setState({
        selectedUsers: users,
        allCheckedBtn: false
        // isAllUsersChecked : false
      })
    }
  },

  deleteUsers(users) {
    const { loc_id, apiCallFailureActions, successActions } = this.props
    const ids = users.map(u => u.org_user_id).join(',')

    KBPopoverConfirm({
      name: '删除疑似离职人员',
      context: '是否要从相应公司中删除选中的全部 ' + users.length + ' 个人员',
      callback: () => {
        return api
          .batchDismissOrgMembers({ location_id: loc_id, org_user_ids: ids })
          .then(
            json => {
              this.loadDataWithParams(this.props.page)
              successActions({
                message: '员工删除成功'
              })
              KBPopover.close()
            },
            error => {
              apiCallFailureActions({
                status: 'error',
                message: getServiceErrorMessage(error)
              })
              KBPopover.close()
            }
          )
      }
    })
  },

  isBatchAudit() {
    const { selectedUsers } = this.state
    const { isManager } = this.props

    if (selectedUsers.length === 0 || !isManager) {
      return null
    }

    return (
      <div className="invoice-table-upon">
        <button
          className="c-btn-delete m-none"
          onClick={() => {
            this.deleteUsers(selectedUsers)
          }}
        >
          删除全部
        </button>
      </div>
    )
  },

  render() {
    const { loading, pagination, inactiveUsers, selectedUsers } = this.state
    const { loc_id } = this.props

    return (
      <section className="kb-content-container clear-fix">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>疑似离职人员列表</span>
          </h2>
          <div className="f-right">
            <button
              className="c-btn-secondary"
              onClick={this.exportInactiveUsers}
            >
              导出报表
            </button>
          </div>
        </header>
        <div className="nav-section-content-container">
          {this._renderFilters()}
          <div
            className="m-bottom-sm"
            style={{ fontSize: 12, color: '#999', paddingTop: 5 }}
          >
            可查看 距离查询日期大于30天，60天，90天 未有识别记录的人员列表
          </div>
          <KBLoadingContainer loading={loading}>
            {this.isBatchAudit()}
            <table className="content-table m-none">
              <thead>
                <tr>
                  <th>
                    <KBCheckBox
                      style={{ marginTop: 2 }}
                      data={inactiveUsers}
                      callback={this.checkAllUsers}
                      checked={this.state.allCheckedBtn}
                    />
                  </th>
                  <th>客户名称</th>
                  <th>公司名称</th>
                  <th>楼栋</th>
                  <th>楼层</th>
                  <th>单元</th>
                  <th>最后开门时间</th>
                  {/* <th>操作</th> */}
                  {/* <th/> */}
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={inactiveUsers.length > 0}
                tableHeadNum="6"
                tipMessage="暂无数据"
              >
                {inactiveUsers &&
                  inactiveUsers.map((json, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            position: 'static',
                            verticalAlign: 'middle'
                          }}
                          onClick={e => e.stopPropagation()}
                        >
                          <KBCheckBox
                            style={{ marginTop: 2 }}
                            data={json}
                            callback={this.changeUser}
                            checked={
                              (selectedUsers &&
                                selectedUsers.length &&
                                selectedUsers.find(data => {
                                  return data.org_user_id === json.org_user_id
                                })) ||
                              false
                            }
                          />
                        </td>
                        <td>{json.user_name}</td>
                        <td>{json.organization_name}</td>
                        <td>{json.location_name}</td>
                        <td>{json.floor_info}</td>
                        <td>{json.area_name}</td>
                        <td>
                          {(json.last_open_at &&
                            formatMinuteSecondEN(json.last_open_at)) ||
                            '暂无'}
                        </td>
                        {/* <td></td> */}
                        {/* {
														isManager ? <td className="t-right">
															<i className="iconfont icon-edit a-hover" onClick={ this.updateLocksPermissions.bind(null,json) }/>
															<i className="iconfont icon-delete m-left-sm a-hover" onClick={ this.deleteLocksPermissions.bind(null,json) }/>
														</td> : null
													} */}
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/locations/${loc_id}/locks/inactive_users?page=#PAGE#`}
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const loc_id = params.id

  let isManager = canAccessLocation(state.user, loc_id)

  let page = parseInt(location.query && location.query.page, 10)
  page = isNaN(page) ? 1 : page

  let paramsAll = getFilterAndQuery(location.query, ['last_date'])
  return {
    loc_id,
    isManager,
    page,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockInactiveUsers)
