import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { api } from 'app/services'
import {
  apiCallFailure,
  areaActions,
  orgActions,
  subscriptionsAction
} from 'app/actions'
import * as selectors from 'app/selectors'
import {
  KBPopover,
  KBFormError,
  KBButton,
  KBLoadingContainer,
  KBTipsy,
  KBAvatar,
  KBPagination
} from 'components'
import { KBDropSelect } from 'components/plugins'
import moment from 'moment'
import _ from 'lodash'
import {
  ORG_SUBSCRIPTION_STATES,
  ORG_SUBSCRIPTION_STATES_CLASS_NEW
} from 'app/constants'
import { kbCloseDropDown, paginate } from 'utils/kbUtil'
import OrgSubscriptionDetail from '../organization/OrgSubscriptionDetail'

const DROP_DOWNS = ['floor_list', 'district_list']

var ParkingContractAreasForm = React.createClass({
  getInitialState() {
    return {
      selectAreas: [],
      selectedAreaIds: [],
      loading: true,
      showAreaSub: {},
      areaSubDetail: {},
      floorList: [],
      districtList: [],
      filters: {},
      pagination: null,
      page: 1
    }
  },

  componentDidMount() {
    this._loadAreas()
    this.getTags()
  },

  _loadAreas(new_page) {
    const { filters, page } = this.state

    this.setState({
      loading: true
    })
    const { loc_id, getAreasAction, startDateValue, endDateValue } = this.props
    let startDateValue_n = moment(startDateValue).format('YYYY-MM-DD')
    let endDateValue_n = moment(endDateValue).format('YYYY-MM-DD')
    let area_type = 'parking'
    api
      .getAreas({
        location_id: loc_id,
        area_type,
        page: new_page || page || 1,
        per_page: 100,
        includes: 'occupancy,subscriptions_and_orgs',
        subscription_start_date: startDateValue_n,
        subscription_end_date: endDateValue_n,
        start_date: startDateValue_n,
        end_date: endDateValue_n,
        ...filters
      })
      .then(
        json => {
          let pagination = paginate(json.response)
          getAreasAction.success(json, { loc_id, entityName: 'areas' })
          this.setState({
            pagination
          })
        },
        error => {
          getAreasAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
          showAreaSub: {},
          areaSubDetail: {}
        })
      })
  },

  loadSubscription(subscription_id) {
    api.getSubscription(subscription_id).then(
      json => {
        this.props.getSubscriptionAction.success(json)
        let subscription = json.response.body
        let loc_id = subscription && subscription.location_id
        let sales_customer = (subscription && subscription.sales_customer) || {}
        if (sales_customer.foreign_type == 'org') {
          this.getOrgInfo(loc_id, sales_customer.foreign_id)
        }
      },
      errors => {
        this.props.getSubscriptionAction.failure(errors)
      }
    )
  },

  getOrgInfo(loc_id, org_id) {
    api
      .getOrganizationInfo(org_id, {
        location_id: loc_id
      })
      .then(
        json =>
          this.props.getOrgAction.success(json, {
            organization_id: org_id,
            location_id: loc_id
          }),
        errors => this.props.getOrgAction.failure(errors)
      )
  },

  getTags() {
    const { loc_id } = this.props
    const locationIdParam = loc_id ? { location_id: loc_id } : {}
    api
      .getSettingTags({
        model: 'area',
        columns: 'ceils,districts',
        ...locationIdParam
      })
      .then(json => {
        let res = json.response.body || {}
        let allFloors = Object.keys(res.ceils).map(key => {
          return { id: key, name: key }
        })
        let allDistricts = Object.keys(res.districts).map(key => {
          return { id: key, name: key }
        })
        let floorList = Object.assign([], this.state.floorList, allFloors || [])
        let districtList = Object.assign(
          [],
          this.state.districtList,
          allDistricts || []
        )
        this.setState({
          floorList,
          districtList
        })
      })
  },

  selectArea() {
    const { callBack, apiCallFailureActions, isReplace } = this.props
    const { selectAreas } = this.state
    if (selectAreas.length === 0) {
      return apiCallFailureActions({
        status: 422,
        message: '请选择车位'
      })
    }
    let callBackAreas = isReplace ? selectAreas[0] || {} : selectAreas
    callBack && callBack(callBackAreas)
    KBPopover.close()
  },

  clickArea(area) {
    const { isReplace } = this.props
    const { occupied, free_desks_count, isOwnContract } = area
    const { selectAreas, selectedAreaIds, showAreaSub } = this.state
    // 点击已占用车位时执行
    if (!isOwnContract && (occupied || free_desks_count <= 0)) {
      let newShowAreaSub = {}
      newShowAreaSub[String(area.id)] = !showAreaSub[String(area.id)]
      this.setState({
        showAreaSub: newShowAreaSub
      })
      return
    }
    // 替换车位时执行
    if (isReplace) {
      let newSelectedAreas = []
      let newSelectedIds = []
      newSelectedAreas.push(area)
      newSelectedIds.push(area.id)
      this.setState({
        selectAreas: newSelectedAreas,
        selectedAreaIds: newSelectedIds
      })
      return
    }

    if (selectedAreaIds.includes(area.id)) {
      selectedAreaIds.splice(
        selectedAreaIds.findIndex(a_id => {
          return a_id === area.id
        }),
        1
      )
      selectAreas.splice(
        selectAreas.findIndex(a => {
          return a.id === area.id
        }),
        1
      )
    } else {
      selectedAreaIds.push(area.id)
      selectAreas.push(area)
    }
    this.setState({
      selectAreas,
      selectedAreaIds,
      showAreaSub: {}
    })
  },

  showAreaSubDetail(area, org, sub) {
    const { areaSubDetail } = this.state
    areaSubDetail.org_id = org.id
    areaSubDetail.location_id = area.location_id
    areaSubDetail.subscription_id = sub.id
    this.setState({
      areaSubDetail
    })
  },

  _setFilter(obj) {
    let newFilt = obj || {}
    let newFilters = Object.assign({}, this.state.filters, newFilt)
    this.setState({ filters: newFilters, page: 1 })
    this['floor_list'] && this['floor_list'].hide()
    this['district_list'] && this['district_list'].hide()

    // force refresh
    setTimeout(this._loadAreas)
  },

  selectFloorCallBack(data) {
    this._setFilter({
      ceil_list: data && data.id
    })
  },

  selectDistrictCallBack(data) {
    this._setFilter({
      district_list: data && data.id
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  render() {
    const { error, submitting, handleSubmit, areas } = this.props
    const {
      selectedAreaIds,
      loading,
      showAreaSub,
      areaSubDetail,
      floorList,
      districtList,
      pagination,
      filters
    } = this.state
    const stateType = ORG_SUBSCRIPTION_STATES
    const stateType_class = ORG_SUBSCRIPTION_STATES_CLASS_NEW

    return (
      <div>
        <form onSubmit={handleSubmit(this.selectArea)}>
          <header className="kb-form-header">
            <h2 className="header-title">选择车位</h2>
            <i
              className="iconfont header-close icon-cancel"
              onClick={KBPopover.close}
            />
            <KBFormError error={this.props.error} />
          </header>
          <div
            className="kb-form-container"
            style={{ overflow: 'initial', maxHeight: 'none' }}
          >
            <div className="clear-fix m-bottom-sm">
              <div className="select-progress f-left">
                <KBDropSelect
                  ref={ref => {
                    this.floor_list = ref
                  }}
                  options={{ defaultSingleContent: '选择楼层' }}
                  defaultData={floorList}
                  multi={false}
                  showImg={false}
                  callback={this.selectFloorCallBack}
                  dropDown_name="floor_list"
                  closeClick={this.closeDropDowns}
                  style={{ marginLeft: 0 }}
                />
              </div>
              <div className="select-progress f-left">
                <KBDropSelect
                  ref={ref => {
                    this.district_list = ref
                  }}
                  options={{ defaultSingleContent: '选择区域' }}
                  defaultData={districtList}
                  multi={false}
                  showImg={false}
                  callback={this.selectDistrictCallBack}
                  dropDown_name="district_list"
                  closeClick={this.closeDropDowns}
                  style={{ marginLeft: 0 }}
                />
              </div>
              <div className="area-status f-right">
                <div className="status-title">
                  <span
                    style={{
                      backgroundColor: '#FFF',
                      border: '1px solid #DFE2E5'
                    }}
                  />
                  <span>可使用</span>
                </div>
                <div className="status-title">
                  <span style={{ backgroundColor: '#c8c8c8' }} />
                  <span>已占用</span>
                </div>
              </div>
            </div>
            <KBLoadingContainer loading={loading}>
              {areas && areas.length > 0 ? (
                <div className="clear-fix">
                  {areas.map((area, index) => {
                    const {
                      id,
                      name,
                      occupied,
                      free_desks_count,
                      isOwnContract,
                      subscriptions
                    } = area
                    const isAreaOccupied = occupied || free_desks_count <= 0
                    return (
                      <KBTipsy content={name}>
                        <div
                          key={index}
                          className={`area-block ${
                            selectedAreaIds.includes(id)
                              ? 'area-block_active'
                              : ''
                          } ${
                            !isOwnContract && isAreaOccupied
                              ? ''
                              : 'area-block_hover'
                          } ${
                            isAreaOccupied
                              ? 'area-block_occupied'
                              : 'area-block_occupied'
                          }`}
                          style={
                            !isOwnContract && isAreaOccupied
                              ? { backgroundColor: '#f7f7f7', color: '#c8c8c8' }
                              : {
                                  backgroundColor: '#FFF',
                                  color: '#354052',
                                  border: '1px solid #DFE2E5'
                                }
                          }
                          onClick={this.clickArea.bind(null, area)}
                        >
                          <div
                            className="area-name t-over"
                            style={{ maxWidth: 82 }}
                          >
                            {name}
                          </div>
                          <span className="area-desk-num">
                            {!isOwnContract && isAreaOccupied
                              ? '已占用'
                              : '可使用'}
                          </span>
                          {selectedAreaIds.includes(id) ? (
                            <i
                              className="iconfont icon-right"
                              style={{ position: 'absolute', top: 0, right: 0 }}
                            />
                          ) : null}
                          {!isOwnContract && isAreaOccupied ? (
                            <i
                              className="iconfont icon-shape"
                              style={{ position: 'absolute', top: 5, right: 5 }}
                            />
                          ) : null}
                          {isAreaOccupied &&
                          subscriptions &&
                          subscriptions.length > 0 &&
                          showAreaSub[area.id] ? (
                            <div
                              className="area-subscriptions-box-container"
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            >
                              <div className="box-arrow"></div>
                              <div className="area-subscriptions-box">
                                {subscriptions.map((sub_org, index) => {
                                  const org = sub_org.organization || {}
                                  const sub = sub_org.subscription || {}
                                  return (
                                    <div className="area-sub">
                                      <KBAvatar
                                        user={org}
                                        style={{
                                          display: 'inline-block',
                                          marginRight: '15px'
                                        }}
                                        size={54}
                                      />
                                      <div className="sub-infos">
                                        <div>
                                          <i className="iconfont icon-company" />
                                          <span className="middle-info">
                                            {org.name || ''}
                                          </span>
                                          {sub && (
                                            <span
                                              className={`finance-default ${
                                                stateType_class[
                                                  sub.status == 'expiring'
                                                    ? 'active'
                                                    : sub.status
                                                ]
                                              }`}
                                              style={{
                                                marginLeft: 5,
                                                textAlign: 'center'
                                              }}
                                            >
                                              {
                                                stateType[
                                                  sub.status == 'expiring'
                                                    ? 'active'
                                                    : sub.status
                                                ]
                                              }
                                            </span>
                                          )}
                                        </div>
                                        <div>
                                          <i className="iconfont icon-timer" />
                                          <span className="middle-info">{`${sub.start_date ||
                                            ''}${
                                            sub.end_date
                                              ? ' 至 ' + sub.end_date
                                              : ''
                                          }`}</span>
                                        </div>
                                        <div>
                                          <i className="iconfont icon-contract" />
                                          <span className="middle-info">
                                            合同编号：
                                            <a
                                              className="serial-link"
                                              onClick={this.showAreaSubDetail.bind(
                                                null,
                                                area,
                                                org,
                                                sub
                                              )}
                                            >
                                              {sub.serial || ''}
                                            </a>
                                          </span>
                                          {sub.status === 'expired' ? (
                                            <span className="sub-expired">
                                              已逾期
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </KBTipsy>
                    )
                  })}
                </div>
              ) : (
                <div style={{ marginBottom: 30 }}>
                  <p style={{ textAlign: 'center', fontSize: 16 }}>
                    暂无可选车位
                  </p>
                </div>
              )}
              <KBPagination
                pagination={pagination}
                withoutUrl={true}
                pageClickCallback={page => {
                  this._loadAreas(page)
                }}
                posRight={true}
                outterStyle={{ marginTop: 0 }}
              />
            </KBLoadingContainer>
            <div className="kb-row fixed-form-btn">
              <span className="cancel-btn" onClick={KBPopover.close}>
                取消
              </span>
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                确定
              </KBButton>
            </div>
          </div>
        </form>
        <div
          className={`slider-container${
            areaSubDetail.subscription_id ? ' slider-container-active' : ''
          }`}
          onClick={e => {
            e.stopPropagation()
            this.setState({ areaSubDetail: {} })
          }}
        >
          <div
            className="slider-content"
            onClick={e => {
              e.stopPropagation()
            }}
          >
            {areaSubDetail.subscription_id ? (
              <OrgSubscriptionDetail
                params={{
                  id: areaSubDetail.location_id,
                  organization_id: areaSubDetail.org_id,
                  subscription_id: areaSubDetail.subscription_id
                }}
                location={{ query: {} }}
                loadAreas={this._loadAreas}
                isFromAreasForm={true}
                isParkingContract={true}
                _loadData={this.loadSubscription}
              />
            ) : null}
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id, yetAreaIds, contract_id } = props
  let areas = selectors.getAreasOfLocation(state, loc_id)
  areas =
    areas &&
    _.cloneDeep(areas.filter(a => a.area_type === 'parking')).map(area => {
      let area_contract_id = area.contract && area.contract.id
      area.isOwnContract = false
      if (
        area_contract_id &&
        contract_id &&
        area_contract_id == contract_id &&
        !_.includes(yetAreaIds, area.id)
      ) {
        area.isOwnContract = true
      }
      yetAreaIds.map(area_id => {
        let areaOccupied = area.occupied
        if (area.id == area_id) {
          area.occupied = true
          area.isSelectedArea = true
        } else {
          area.occupied = areaOccupied
        }
      })
      return area
    })
  return {
    areas,
    loc_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getAreasAction: bindActionCreators(areaActions.replace, dispatch),
    getSubscriptionAction: bindActionCreators(
      subscriptionsAction.get,
      dispatch
    ),
    getOrgAction: bindActionCreators(orgActions.get, dispatch)
  }
}

const formConfig = {
  form: 'ParkingContractAreasForm',
  fields: ['area_id', 'area_name', 'size'],
  touchOnBlur: false
}

ParkingContractAreasForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ParkingContractAreasForm)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkingContractAreasForm)
