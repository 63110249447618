import React from 'react';
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { formatYearDayEN } from 'utils/kbUtil'
import { subscriptionActions, apiCallFailure } from 'app/actions'
import {
  KBSelect,
  KBInput,
  KBPopover,
  KBButton,
  KBTextarea
} from 'components'
import {LINE_ITEM_TYPES} from 'app/constants'
import lodash from 'lodash'

const PeriodicCharge=React.createClass({
  formSubmit(modalValues) {
    const {
      callback
    }=this.props
    callback&&callback(modalValues,true)
    KBPopover.close()
  },

  render(){
    const {
      fields:{
        cycle_fee_type,
        cycle_fee,
        sales_tax_id,
        note
      },
      handleSubmit,
      submitting,
      taxes,
      sales_resources
    }=this.props
    // 每种类型只能添加一次

    const filterItem=LINE_ITEM_TYPES.filter(types=>
      types.name != '会员费' && types.name != '押金' && types.name != '保证金没收'
    )

    filterItem.forEach(item=>{
        item.disabled = false
    })
    filterItem.forEach(item=>{
      sales_resources.forEach(sales=>{
        if(item.id === sales.cycle_fee_type.value){
          item.disabled = true
        }
      })
    })

    return <form onSubmit={handleSubmit(this.formSubmit)}>
      <div className="kb-form-header">
          <h2 className="header-title">添加周期性缴费</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
      </div>
      <div className="kb-form-container">
        <div className="kb-row kb-form-66 kb-ps">
          <KBSelect
          isRequired={true}
          defaultValue="请选择"
          title='账单类型'
          field={cycle_fee_type}
          arrayData={filterItem}
          className="kb-form-group kb-form-c39"/>
          <KBSelect
          isRequired={true}
          defaultValue="请选择"
          arrayData={(taxes || []).sort((a,b) => a.rate - b.rate)}
          title='税率'
          type='text'
          field={sales_tax_id}
          className="kb-form-group kb-form-c48"/>
        </div>
        <div className="kb-row kb-form-66 kb-ps">
          <KBInput
          isRequired={true}
          title='金额'
          type='text'
          field={cycle_fee}
          className="kb-form-group kb-form-c39"/>
        </div>
        <div className="kb-row">
          <KBTextarea
          title='备注'
          field={note}
          className="kb-form-group kb-form-c18 kb-form-alone"/>
        </div>
        <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
      </div>
  </form>
  }
})

function mapStateToProps(state, props) {
  const {
    cycle_fee_type,
    cycle_fee,
    sales_tax_id,
    note
  } = props

  return {
    cycle_fee_type,
    cycle_fee,
    sales_tax_id,
    note
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  if(!values.cycle_fee_type || values.cycle_fee_type === '请选择'){
    errors.cycle_fee_type="请选择账单类型"
  }
  if(!values.sales_tax_id || values.sales_tax_id === '请选择'){
    errors.sales_tax_id="请选择税率"
  }
  if(!values.cycle_fee){
    errors.cycle_fee="请输入金额"
  }
  if(valid.isNumber(values.cycle_fee)){
    errors.cycle_fee="请输入正确的数字"
  }
  if(values.cycle_fee < 0) {
    errors.cycle_fee="金额不能为负数"
  }
  return errors
}

const formConfig = {
  form: 'PeriodicCharge',
  fields: [
    'cycle_fee_type',
    'cycle_fee',
    'sales_tax_id',
    'note'
  ],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(PeriodicCharge)
