import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { subscriptionActions } from 'app/actions'
import {
  KBInput,
  KBFormError,
  KBMoreFilesUpload,
  KBButton,
  KBPopover
} from 'components'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import * as selectors from 'app/selectors'
import * as utils from 'utils/kbUtil'
import config from 'app/config'
import SubscriptioApprovalRejectForm from './SubscriptioApprovalRejectForm'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'

var SubscriptionSignForm = React.createClass({
  getInitialState() {
    const { currentSubscription } = this.props
    return {
      isExportTemplete: false,
      isExportPdfTemplete: false,
      templete_id: currentSubscription.templete_id || -1
    }
  },
  updateSubscriptionForm(model) {
    const {
      currentSubscription,
      updateSubscriptionActions,
      step,
      nextCallBack
    } = this.props
    const { templete_id } = this.state
    const { files } = model
    if (templete_id && templete_id != -1) {
      model.templete_id = templete_id
    } else {
      model.templete_id = null
    }
    model.files = files.map(json => {
      let newFile = { ...json }
      newFile.attachment =
        Boolean(newFile.attachment) || newFile.type == 'source'
      return newFile
    })

    return api
      .updateSignSubscription(currentSubscription.id, {
        ...model
      })
      .then(json => {
        updateSubscriptionActions.success(json)
        let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
        nextCallBack && nextCallBack(subscription)
        return null
      })
  },
  contextTypes: {
    previewSubscription: PropTypes.func
  },
  previewSubscription(export_type) {
    const { previewSubscription } = this.context
    const { templete_id } = this.state
    if (export_type == 'pdf') {
      this.setState({ isExportPdfTemplete: true })
    } else {
      this.setState({ isExportTemplete: true })
    }

    previewSubscription(templete_id, export_type).then(
      json => {
        this.setState({
          isExportTemplete: false,
          isExportPdfTemplete: false
        })
      },
      error => {
        this.setState({
          isExportTemplete: false,
          isExportPdfTemplete: false
        })
      }
    )
  },
  cancelFiles(fileIndex) {
    this.refs.files.removeFile(fileIndex)
  },

  changeExtendSubscriptionTemplete(e) {
    let value = e.target.value
    this.setState({
      templete_id: value
    })
  },

  subJsx() {
    const {
      currentSubscription: {
        sales_areas,
        sales_renting_phases,
        deposit,
        serial,
        start_date,
        end_date,
        renting_type_list
      },
      currentOrganization,
      isParkingContract,
      isContract
    } = this.props

    const private_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'private_office')
      : []
    const public_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'public_office')
      : []
    const flexible_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'flexible_office')
      : []

    let private_areas_array = []
    private_areas &&
      private_areas.map(area => {
        let str = `${(area.area && area.area.name) || ''}(${
          area.charge_type === 'by_desk'
            ? area.units + '个'
            : (area.m2 || '') + '㎡'
        })`
        private_areas_array.push(str)
      })
    let public_areas_array = []
    public_areas &&
      public_areas.map(area => {
        let str = `${(area.area && area.area.name) || ''}(${area.units}个)`
        public_areas_array.push(str)
      })
    let flexible_areas_array = []
    flexible_areas &&
      flexible_areas.map(area => {
        let str = `${area.units}个`
        flexible_areas_array.push(str)
      })

    let subTotalAmount = deposit || 0
    sales_renting_phases &&
      sales_renting_phases.length &&
      sales_renting_phases.map((phase, index) => {
        subTotalAmount += phase.total_amount
      })

    return (
      <fieldset className="fieldset m-top-sm">
        <div className="kb-title m-bottom-sm">
          <span style={{ fontSize: 16 }}>合同概况</span>
        </div>
        <div className="kb-row kb-form-66">
          <div className={'kb-form-group kb-div-210'}>
            <label>合同编号</label>
            <div className="group-content">
              <span>{serial || '暂无'}</span>
            </div>
          </div>
          <div className="kb-form-group kb-div-173">
            <label className="label-rt">合同时长</label>
            <div className="group-content">
              <span>
                {utils.formatYearDayCHS(start_date) +
                  ' - ' +
                  utils.formatYearDayCHS(end_date)}
              </span>
            </div>
          </div>
        </div>
        <div className="kb-row kb-form-66">
          <div className={'kb-form-group kb-div-210'}>
            <label>{isParkingContract ? '承租方名称' : '公司名称'}</label>
            <div className="group-content">
              <span>{currentOrganization.full_name}</span>
            </div>
          </div>
          {isParkingContract ? (
            <div className="kb-form-group kb-div-173">
              <label className="label-rt">租赁类型</label>
              <div className="group-content">
                <span>
                  {renting_type_list.length > 0
                    ? renting_type_list.join(',')
                    : '暂无'}
                </span>
              </div>
            </div>
          ) : null}
        </div>
        {isParkingContract || isContract ? null : (
          <div className="kb-row kb-form-66">
            <div className="kb-form-group kb-div-210">
              <label>封闭工位</label>
              <div className="group-content">
                <span>
                  {(private_areas_array &&
                    private_areas_array.length &&
                    private_areas_array.join('、')) ||
                    '暂无'}
                </span>
              </div>
            </div>
            <div className="kb-form-group kb-div-66">
              <div className="kb-form-group kb-div-173">
                <label className="label-rt">开放工位</label>
                <div className="group-content">
                  <span>
                    {(public_areas_array &&
                      public_areas_array.length &&
                      public_areas_array.join('、')) ||
                      '暂无'}
                  </span>
                </div>
              </div>
              <div className="kb-form-group kb-div-173">
                <label className="label-rt">灵活工位</label>
                <div className="group-content">
                  <span>
                    {(flexible_areas_array &&
                      flexible_areas_array.length &&
                      flexible_areas_array.join('')) ||
                      '暂无'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="kb-row kb-form-66">
          <div className="kb-form-group kb-div-210">
            <label>合同总额</label>
            <div className="group-content">
              <span>¥{utils.fmoney(subTotalAmount || 0)}</span>
            </div>
          </div>
          {!isContract && <div className="kb-form-group kb-div-173">
            <label className="label-rt">合同押金</label>
            <div className="group-content">
              <span>¥{utils.fmoney(deposit || 0)}</span>
            </div>
          </div>}
        </div>
      </fieldset>
    )
  },

  choseTempleteJsx() {
    const { extendSubscriptions } = this.props
    const { templete_id, isExportTemplete } = this.state
    const { isNeedTemplate } = this.context
    return (
      <div>
        <div className="kb-title m-bottom-sm">
          <span style={{ fontSize: 16 }}>选择合同模板</span>
        </div>
        <div>
          {!extendSubscriptions.length ? (
            <p style={{ padding: '10px 0px' }}>
              您当前没有合同模板，如果要使用请联系管理人员来创建合同模板
            </p>
          ) : (
            ''
          )}
        </div>
        <div>
          {extendSubscriptions.map((json, index) => {
            const { name, id } = json
            return (
              <div style={{ marginBottom: 10 }}>
                <input
                  name="subscriptionsradio"
                  id={index + 'subscriptionsradio'}
                  checked={templete_id == id ? 'checked' : ''}
                  onChange={this.changeExtendSubscriptionTemplete}
                  type="radio"
                  value={id}
                />
                <label
                  htmlFor={index + 'subscriptionsradio'}
                  style={{ marginLeft: 5 }}
                >
                  {name}
                </label>
              </div>
            )
          })}
        </div>
        {!isNeedTemplate ? (
          <div style={{ marginBottom: 15 }}>
            <input
              name="subscriptionsradio"
              id={-1 + 'subscriptionsradio'}
              checked={templete_id == -1 ? 'checked' : ''}
              onChange={this.changeExtendSubscriptionTemplete}
              type="radio"
              value="-1"
            />
            <label
              htmlFor={-1 + 'subscriptionsradio'}
              style={{ marginLeft: 5 }}
            >
              不采用合同模板
            </label>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  },

  setSubscriptionFailure(id) {
    const {
      fields: { note },
      nextCallBack,
      previousCallBack
    } = this.props
    let notes = {
      value: note ? note.value : '',
      type: 'note'
    }

    KBPopover.show(
      <SubscriptioApprovalRejectForm
        previousCallBack={previousCallBack}
        id={id}
        callback={this.updateSubscriptionForm}
        note={notes}
        nextCallBack={nextCallBack}
      />
    )
  },

  render() {
    const {
      fields: { files, serial },
      error,
      submitting,
      handleSubmit,
      isParkingContract,
      currentSubscription
    } = this.props
    const fileList = (files && files.value) || []
    const isUploadSouce = fileList.filter(
      file => Boolean(file.attachment) || file.type == 'source'
    ).length
    const { isExportTemplete, templete_id, isExportPdfTemplete } = this.state

    return (
      <form onSubmit={handleSubmit(this.updateSubscriptionForm)}>
        {isParkingContract ? (
          <header className="kb-form-header">
            <h2 className="header-title">打印合同签字</h2>
          </header>
        ) : (
          <div className="kb-title" style={{ margin: '0 30px' }}>
            <span className="header-title">合同签字</span>
          </div>
        )}
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          {this.subJsx()}
          {this.choseTempleteJsx()}
          {templete_id && templete_id != -1 ? (
            <div
              className="m-bottom-sm border-bottom"
              style={{ paddingBottom: 20 }}
            >
              <div style={{ marginBottom: 20, fontWeight: 500, color: '#666' }}>
                如果客户还未签字
              </div>
              <div style={{ marginBottom: 20, color: '#999' }}>
                请下载未签字合同，交予客户签字盖章及公司盖章后再上传
              </div>
              <div>
                {isExportTemplete ? (
                  <span className="certain-btn">正在下载中</span>
                ) : (
                  <span
                    className="certain-btn"
                    onClick={() => this.previewSubscription('docx')}
                  >
                    {'下载未签字合同.(docx)'}
                  </span>
                )}

                {isExportPdfTemplete ? (
                  <span className="certain-btn" style={{ marginLeft: 30 }}>
                    正在下载中
                  </span>
                ) : (
                  <span
                    className="certain-btn"
                    style={{ marginLeft: 30 }}
                    onClick={() => this.previewSubscription('pdf')}
                  >
                    {'下载未签字合同.(pdf)'}
                  </span>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          <div style={{ marginBottom: 20, fontWeight: 500, color: '#666' }}>
            如果客户已签字,请上传合同
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={serial}
              title="合同编号"
              disabled={true}
            />
          </div>
          <div className="kb-row kb-form-c18 m-top-sm m-bottom-sm">
            <div className="require">*</div>
            <div
              className="kb-form-group kb-form-c48 color-nine "
              style={{ marginTop: 8 }}
            >
              请上传合同原文件
            </div>
            <KBMoreFilesUpload
              className="kb-form-group kb-form-c48"
              text={isUploadSouce ? '文件已上传' : '上传签字合同'}
              uploadingText="正在上传"
              prefix={config.uploadDir + 'subscriptions/'}
              type="source"
              defaultFiles={files.value}
              ref="files"
              accept=".doc,.docx,.pdf"
              buttonClass={'bordered-btn m-none'}
              field={files}
            />
          </div>
          <div className="m-bottom-sm">
            {fileList &&
              fileList.map((json, index) => {
                return (
                  <div
                    style={{
                      marginBottom: 10,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    key={index}
                  >
                    <span style={{ marginRight: 5, color: '#999' }}>
                      文件名：
                    </span>
                    <KBDownloadPrivacy url={json.file_url}>
                      <a
                        className="color-link c-pointer"
                        style={{ marginRight: 5 }}
                      >
                        {json.name}
                        {json.type == 'source' ? '(原文件)' : ''}
                      </a>
                    </KBDownloadPrivacy>
                    <i
                      className="iconfont delete-file-btn icon-delete"
                      onClick={this.cancelFiles.bind(null, index)}
                    />
                  </div>
                )
              })}
          </div>
          {/* <div className='kb-row'>
            <KBButton className='certain-btn f-right' type='submit' submitting = { submitting }>完成合同</KBButton>
          </div> */}
          <div className="kb-row">
            <KBButton
              style={{ marginLeft: 10 }}
              className="certain-btn f-right"
              type="submit"
              submitting={submitting}
            >
              通过
            </KBButton>
            <KBButton
              className="certain-btn f-right"
              type="button"
              submitting={submitting}
              onClick={this.setSubscriptionFailure.bind(
                null,
                currentSubscription.id
              )}
              style={{ marginLeft: 30 }}
            >
              不通过
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

SubscriptionSignForm.PropTypes = {
  currentSubscription: PropTypes.object
}

function mapStateToProps(state, props) {
  const { currentSubscription } = props
  const user = selectors.getCurrentUser(state)
  const extendSubscriptions = selectors.getSubscriptionTempletesOfState(
    state,
    'enabled'
  )
  return {
    user,
    extendSubscriptions,
    initialValues: {
      files: currentSubscription.files,
      serial: currentSubscription.serial
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubscriptionActions: bindActionCreators(
      subscriptionActions.update,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.serial)) {
    errors.serial = '请输入合同编号'
  }
  const files = values.files || []
  const isUploadSouce = files.filter(
    file => Boolean(file.attachment) || file.type == 'source'
  ).length

  if (!isUploadSouce) {
    errors.files = '请上传合同签字后文件'
  }

  return errors
}

const formConfig = {
  form: 'SubscriptionSignForm',
  fields: ['files', 'serial'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionSignForm)
