import React from 'react'
import { PARKING_STATES_OBJ, PARKING_SPACE_TYPE_OBJ } from 'app/constants'
import { api } from 'app/services'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import { KBPopover, KBTipsy, KBNoData } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { connect } from 'react-redux'
import { areaActions } from 'app/actions'
import { formatYearDaySOL, formatYearDayEN } from 'utils/kbUtil'
import ParkingSpaceForm from './ParkingSpaceForm'
import moment from 'moment'
import { canAccessLocation } from 'app/reducers/role'

var ParkingSpaces = React.createClass({
  displayName: 'ParkingSpaces',

  getInitialState() {
    return {
      selectedParkingIds: []
    }
  },

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(this.props.parkingSpaces) !==
      JSON.stringify(nextProps.parkingSpaces)
    ) {
      this.clearSelected()
    }
  },

  clickParkingBlock(parking) {
    if (parking.state !== 'free') {
      return
    }
    const { selectedParkingIds } = this.state
    const newSelectedParkingIds = Object.assign([], selectedParkingIds)
    if (selectedParkingIds.includes(parking.id)) {
      newSelectedParkingIds.splice(
        newSelectedParkingIds.findIndex(id => id === parking.id),
        1
      )
    } else {
      newSelectedParkingIds.push(parking.id)
    }
    this.setState({
      selectedParkingIds: newSelectedParkingIds
    })
  },

  selectAllFreeParking() {
    const { parkingSpaces } = this.props
    const parkingIds = []
    parkingSpaces &&
      parkingSpaces.map(parking => {
        if (parking.state === 'free') {
          parkingIds.push(parking.id)
        }
      })
    this.setState({
      selectedParkingIds: parkingIds
    })
  },

  clearSelected() {
    this.setState({
      selectedParkingIds: []
    })
  },

  deleteAllSelectedParkings() {
    const { loc_id, deleteAreaAction, refreshParkingStatistic } = this.props
    const { selectedParkingIds } = this.state

    let ids = Object.assign([], selectedParkingIds)
    KBPopoverConfirm({
      name: '批量删除车位',
      context: `您确定要删除选中的全部 ${selectedParkingIds.length} 个车位？`,
      callback: () => {
        return api
          .batchDeleteAreas({ location_id: loc_id, area_ids: ids })
          .then(
            json => {
              deleteAreaAction.success(json, { key: 'areas', ids })
              this.setState({
                selectedParkingIds: []
              })
              refreshParkingStatistic && refreshParkingStatistic()
              KBPopover.close()
            },
            errors => {
              deleteAreaAction.failure(errors)
              KBPopover.close()
            }
          )
      }
    })
  },

  updateParking(parking) {
    let { loc_id } = this.props

    KBPopover.showForm(
      <ParkingSpaceForm type="update" loc_id={loc_id} parking={parking} />
    )
  },

  disableAllSelectedParkings() {
    const { loc_id, updateAreaAction } = this.props
    const { selectedParkingIds } = this.state

    let ids = Object.assign([], selectedParkingIds)
    KBPopoverConfirm({
      name: '批量锁定车位',
      context: `您确定要锁定选中的全部 ${selectedParkingIds.length} 个车位？锁定后车位将不可出租或预订。`,
      callback: () => {
        return api
          .batchUpdateAreas({
            location_id: loc_id,
            area_ids: ids,
            is_disabled: true
          })
          .then(
            json => {
              updateAreaAction.success(json, { key: 'areas', ids })
              this.setState({
                selectedParkingIds: []
              })
              KBPopover.close()
            },
            errors => {
              updateAreaAction.failure(errors)
              KBPopover.close()
            }
          )
      }
    })
  },

  enableOrDisableParking(parking, type) {
    let isEnable = type === 'enable'
    let tipMsg = isEnable
      ? `是否确认解锁当前车位：${parking.name}？`
      : `是否确认锁定当前车位：${parking.name}，锁定后车位将不可出租或预订。`
    KBPopoverConfirm({
      name: `${isEnable ? '解锁' : '锁定'}车位`,
      context: tipMsg,
      callback: () => {
        return api.updateArea(parking.id, { is_disabled: !isEnable }).then(
          json => {
            this.props.updateAreaAction.success(json, { key: 'areas' })
            KBPopover.close()
          },
          error => {
            this.props.updateAreaAction.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  render() {
    const { loc_id, parkingSpaces, isManager } = this.props
    const { selectedParkingIds } = this.state

    return (
      <div className="parking-spaces-container m-top-sm">
        <div className="m-bottom-sm t-left" style={{ width: '100%' }}>
          <KBTipsy content="全选将只会选中所有“可出租”状态的车位">
            <button
              className="bordered-btn m-none"
              onClick={this.selectAllFreeParking}
            >
              全选
            </button>
          </KBTipsy>
          {isManager && selectedParkingIds.length > 0 ? (
            <button
              className="c-btn-delete"
              onClick={this.deleteAllSelectedParkings}
            >
              删除选中
            </button>
          ) : null}
          {isManager && selectedParkingIds.length > 0 ? (
            <button
              className="bordered-btn"
              onClick={this.disableAllSelectedParkings}
            >
              锁定选中
            </button>
          ) : null}
          {selectedParkingIds.length > 0 ? (
            <span className="m-left-sm">
              已选中&nbsp;
              <span className="color-link">{selectedParkingIds.length}</span>
              &nbsp;个车位
            </span>
          ) : null}
          {selectedParkingIds.length > 0 ? (
            <span
              className="m-left-sm hover-delete"
              onClick={this.clearSelected}
              style={{ color: '#999' }}
            >
              <i className="iconfont icon-Uploadfailed" />
              取消选中
            </span>
          ) : null}
        </div>
        {parkingSpaces && parkingSpaces.length > 0 ? (
          parkingSpaces.map(parking => {
            const {
              id,
              state,
              name,
              sub_area_type,
              ceil_list,
              district_list,
              subscriptions
            } = parking
            let isActive = selectedParkingIds.includes(id)
            let activeSub =
              subscriptions && subscriptions.length > 0 && subscriptions[0]
            return (
              <div
                className={`parking-block parking-block-${state} ${
                  isActive ? 'parking-block-active' : ''
                }`}
                onClick={this.clickParkingBlock.bind(null, parking)}
              >
                {isActive ? (
                  <div className="selected-mark">
                    <i className="iconfont icon-right m-none" />
                  </div>
                ) : null}
                <div className="parking-serial">{name}</div>
                <div className="parking-state">
                  {PARKING_STATES_OBJ[state]}{' '}
                </div>
                <div className="hover-transition" />

                <div
                  className="parking-popup-box"
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  <div
                    className="box-arrow"
                    style={isManager ? {} : { backgroundColor: '#FFF' }}
                  ></div>
                  <div className="popup-container">
                    <div className="parking-detail">
                      <div>
                        <div className="parking-title">
                          <span>编号：{name}</span>
                          {PARKING_SPACE_TYPE_OBJ[sub_area_type] ? (
                            <span className="parking-type">
                              {PARKING_SPACE_TYPE_OBJ[sub_area_type]}
                            </span>
                          ) : null}
                        </div>
                        <div className="parking-area">
                          <span>所属楼层：{ceil_list.join(',')}</span>
                          <span>所属区域：{district_list.join(',')}</span>
                        </div>
                      </div>
                      {isManager ? (
                        <i
                          className="iconfont icon-edit a-hover"
                          onClick={this.updateParking.bind(null, parking)}
                        />
                      ) : null}
                    </div>
                    {activeSub ? (
                      <div className="parking-state-info">
                        {/* <div className="info-line" >
											<i className="iconfont icon-account_circle" />
											<span className="color-link" >张红霞</span>
										</div> */}
                        <div className="info-line">
                          <i className="iconfont icon-timer" />
                          <span>{`${formatYearDaySOL(
                            activeSub.subscription.start_date
                          )} 至 ${formatYearDaySOL(
                            activeSub.subscription.end_date
                          )}`}</span>
                          {state === 'expiring' ? (
                            <span style={{ color: '#FF5157', marginLeft: 10 }}>
                              还有
                              {moment(
                                formatYearDayEN(activeSub.subscription.end_date)
                              ).diff(moment().format('YYYY-MM-DD'), 'days')}
                              天到期
                            </span>
                          ) : null}
                        </div>
                        <div className="info-line">
                          <i className="iconfont icon-contract" />
                          <span>合同编号：</span>
                          <Link
                            to={`/admin/locations/${loc_id}/parking/contract/${activeSub.subscription.id}`}
                            className="color-link"
                          >
                            {activeSub.subscription.serial}
                          </Link>
                        </div>
                        <div className="info-line">
                          <i className="iconfont icon-tile" />
                          <span>车牌号：</span>
                          <span>
                            {(activeSub.sales_area &&
                              activeSub.sales_area.car_plates &&
                              activeSub.sales_area.car_plates
                                .map(p => p.car_plate)
                                .join('、')) ||
                              '暂无'}
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {isManager ? (
                      <div className="parking-operation">
                        {state === 'disabled' ? (
                          <button
                            className="certain-btn"
                            onClick={this.enableOrDisableParking.bind(
                              null,
                              parking,
                              'enable'
                            )}
                          >
                            解锁车位
                          </button>
                        ) : (
                          <button
                            className="c-btn-blue"
                            onClick={this.enableOrDisableParking.bind(
                              null,
                              parking,
                              'disable'
                            )}
                          >
                            锁定车位
                          </button>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <KBNoData
            tipMessage="暂无车位数据"
            outterStyle={{ marginTop: 100, width: '100%' }}
          />
        )}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { loc_id } = props
  let isManager = canAccessLocation(user, loc_id)

  return {
    isManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateAreaAction: bindActionCreators(areaActions.update, dispatch),
    deleteAreaAction: bindActionCreators(areaActions.delete, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingSpaces)
