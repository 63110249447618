import React, { Component, PropTypes } from 'react'

class AutoReload extends Component {
  constructor(props) {
    super(props)
    this.previousHash = null
    this.state = {
      codeHasChanged: false
    }
    this.fetchSource = this.fetchSource.bind(this)
  }

  componentDidMount() {
    const { tryDelay, forceDelay } = this.props
    this.fetchSource()
    this.interval = setInterval(this.fetchSource, tryDelay, forceDelay)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  setRomdom(n) {
    var t = ''
    for (var i = 0; i < n; i++) {
      var a = Math.floor(Math.random() * 10)
      if (i == 0 && a == 0) {
        a = 1
      }
      t += a
    }
    return t
  }

  fetchSource() {
    return fetch(`${this.props.url}?version=${this.setRomdom(11)}`)
      .then(response => {
        if (response.status !== 200) {
          throw new Error('offline')
        }
        return response.text()
      })
      .then(html => {
        const hash = this.hash(html)
        if (!this.previousHash) {
          this.previousHash = hash
          return
        }
        if (this.previousHash !== hash) {
          this.previousHash = hash
          this.setState({ codeHasChanged: true })
        }
      })
      .catch(() => {
        /* do nothing */
      })
  }

  /**
   * Java-like hashCode function for strings
   *
   * taken from http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery/7616484#7616484
   */
  hash(str) {
    const len = str.length
    let hash = 0
    if (len === 0) return hash
    let i
    for (i = 0; i < len; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i)
      hash |= 0 // Convert to 32bit integer
    }
    return hash
  }

  reloadApp(e) {
    window.location.href = `${window.location.href}?version=v${this.setRomdom(
      11
    )}`
    e.preventDefault()
  }

  render() {
    if (!this.state.codeHasChanged) return null
    const style = {
      position: 'absolute',
      top: 150,
      right: 10,
      padding: '1em',
      zIndex: 10500,
      backgroundColor: 'bisque',
      borderRadius: 5,
      textAlign: 'center'
    }
    return (
      <div style={style}>
        <div>管理系统有新的版本</div>
        <div>
          <a
            href="#"
            onClick={this.reloadApp.bind(this)}
            style={{ color: 'red' }}
          >
            点击更新
          </a>
        </div>
      </div>
    )
  }
}

AutoReload.propTypes = {
  url: PropTypes.string.isRequired,
  tryDelay: PropTypes.number.isRequired,
  forceDelay: PropTypes.number.isRequired
}

AutoReload.defaultProps = {
  url: '/',
  tryDelay: 5 * 60 * 1000, // 5 minutes
  forceDelay: 24 * 60 * 60 * 1000 // 1 day
}

export default AutoReload
