import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import { KBFormError, KBButton, KBPopover, KBDateInput } from 'components'
import * as selectors from 'app/selectors'
import { leaveEnterOptionActions, apiCallFailure } from 'app/actions'
import OrganizationOptionsRow from './OrganizationOptionsRow'
import _ from 'lodash'
import moment from 'moment'
import { KBNoData } from 'components'

var OrganizationOptionsCheckForm = React.createClass({
  getInitialState() {
    return {
      options: this.props.leaveOrEnterOptions.filter(option => {
        return option.cate == this.props.type
      }),
      execute_at: new Date()
    }
  },

  updateOrganizationOptions(e) {
    const { org, apiCallFailureActions } = this.props
    const { options } = this.state

    if (options.length === 0) {
      return apiCallFailureActions({
        status: 'error',
        message: '请先在空间设置中添加待办事项！'
      })
    }

    let checkedOptions =
      (options &&
        options.filter(op => {
          return op.checked
        })) ||
      []
    if (checkedOptions.length > 0) {
      this.callUpdateOrgOptApi(org.id, checkedOptions)
      KBPopover.close()
    } else {
      return apiCallFailureActions({
        status: 'error',
        message: '请至少勾选一个待办事项'
      })
    }
  },

  callUpdateOrgOptApi(org_id, opts) {
    const {
      fields: { execute_at },
      updateLeaveEnterOptionActions
    } = this.props

    api
      .updateOrganizationOptions(org_id, opts[0].id, {
        execute_at: moment(execute_at.value || new Date()).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      })
      .then(
        json => {
          opts.splice(0, 1)
          updateLeaveEnterOptionActions.success(json)
          opts.length && this.callUpdateOrgOptApi(org_id, opts)
        },
        error => {
          updateLeaveEnterOptionActions.failure(error)
        }
      )
  },

  updateOptions(optionId, value) {
    let { options } = this.state
    options.map(op => {
      if (op.id == optionId) {
        op.checked = value
      }
      return op
    })
    this.setState({
      options
    })
  },

  render() {
    const {
      fields: { execute_at },
      submitting,
      handleSubmit,
      location_id,
      org,
      type
    } = this.props
    var leaveOrEnterOptions = this.props.leaveOrEnterOptions

    return (
      <form onSubmit={handleSubmit(this.updateOrganizationOptions)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            {type == 'enter' ? '入驻待办事项' : '退租待办事项'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container-m">
          {type == 'enter' ? (
            <div className="kb-row">
              <KBDateInput
                type="date"
                title="入驻日期"
                className="kb-form-group kb-div-c18 kb-form-alone"
                field={execute_at}
              />
            </div>
          ) : null}
          <fieldset className="fieldset">
            <ul>
              {leaveOrEnterOptions.length > 0 ? (
                leaveOrEnterOptions.map((option, index) => {
                  return (
                    <OrganizationOptionsRow
                      key={index}
                      option={option}
                      location_id={location_id}
                      org={org}
                      updateOptions={this.updateOptions}
                    />
                  )
                })
              ) : (
                <div className="box-content">
                  <KBNoData className="m-updown" tipMessage="暂无待办事项" />
                </div>
              )}
            </ul>
          </fieldset>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton className="certain-btn" submitting={submitting}>
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

OrganizationOptionsCheckForm.PropTypes = {
  location_id: PropTypes.number.isRequired,
  org: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}

function mapStateToProps(state, props) {
  const { org, type } = props
  let leaveOrEnterOptions = selectors.getOrgOptionsOfOrgId(state, org.id)

  leaveOrEnterOptions = _.cloneDeep(leaveOrEnterOptions).filter(option => {
    return option.cate == type && !option.checked
  })
  return {
    leaveOrEnterOptions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLeaveEnterOptionActions: bindActionCreators(
      leaveEnterOptionActions.update,
      dispatch
    ),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'OrganizationOptionsCheckForm',
  fields: ['execute_at'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrganizationOptionsCheckForm)
