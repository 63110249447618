import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  KBPopover,
  KBDropdown,
  KBLoadingContainer,
  KBAvatar,
  KBTipsy,
  KBPagination,
  KBTableWithoutData
} from 'components'
import { deskActions, usersActions, orgUserActions } from 'app/actions'
import { connect } from 'react-redux'
import {
  getEntitiesJsonArray,
  encryptionPhone,
  paginate,
  getEntitiesJson,
  checkNull,
  formatYearDayEN
} from 'utils/kbUtil'
import { getOrgReplaceMembers, getDeskOfDeskId } from 'app/selectors'
import OrgMemberDeskForm from './OrgMemberDeskForm'
import OrganizationMemberForm from './OrganizationMemberForm'
import { canAccessLocation, canAccessLocationObserver, canAdminLocationWeChat, onlyCanAdminLocationOfWeChat, canAccessLocationRoles } from 'app/reducers/role'
import _ from 'lodash'
import { getUserInfoUrl } from 'utils/kbUrl'
import { PopoverShowPicture } from 'components/views'

var OrganizationMembers = React.createClass({
  displayName: 'OrganizationMembers',
  getInitialState() {
    return {
      pagination: null,
      loading: true,
      filters: {},
      phone_num: '',
      member: []
    }
  },

  componentDidMount() {
    this.loadData()
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.loadData(nextProps.page)
    }
    if (JSON.parse(localStorage.getItem('reloadOrgMemberInfo')) == true) {
      localStorage.setItem('reloadOrgMemberInfo', false)
      this.loadData()
    }
  },

  loadData(page_param, params) {
    const {
      getUserActions,
      loc_id,
      org_id,
      page,
      getOrgUserActions
    } = this.props
    const page_num = page_param || page
    const per_page = 10
    api
      .getUsersNoSchema({
        location_id: loc_id,
        organization_id: org_id,
        page: page_num,
        per_page: per_page,
        includes: 'desks,space_users',
        order_by: 'org_role_desc',
        ...params
      })
      .then(json => {
        let pagination = paginate(json.response)
        let newJSON = _.cloneDeep(json)
        _.set(newJSON, 'json.entities.users', newJSON.json.json)
        // newJSON.json.entities.users = newJSON.json.json;
        delete newJSON.json.json
        getUserActions.success(newJSON)
        newJSON.json.entities.orgUsers = newJSON.json.entities.users
        delete newJSON.json.entities.users
        getOrgUserActions.success(newJSON)
        const { org, location, org_id } = this.props
        let member = json.json
        for (let i = 0; i < member.length; i++) {
          if (member[i].organizations && member[i].organizations.length) {
            for (
              let index = 0;
              index < member[i].organizations.length;
              index++
            ) {
              if (org_id == member[i].organizations[index].id) {
                member[i].role =
                  member[i].organizations[index].roles[0] || 'employee'
              }
            }
          }
        }
        this.setState({
          member: json.json,
          pagination: pagination,
          loading: false
        })
      })
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, loading: true })

    // force refresh
    this._refresh()
  },

  _refresh() {
    this.setState({ phone_num: '' })
    setTimeout(this.showFiltered)
  },

  showFiltered() {
    const { filters } = this.state
    let params = Object.assign({}, filters)
    this.loadData(filters.page || 1, { ...params })
    this.setState({
      ...params
    })
  },

  dismissMember(member) {
    KBPopover.plugins.confirm(
      '会员离职',
      `您确定要离职会员${member.name || member.id}吗?`,
      {
        confirm: () => {
          this.memberDismiss(member)
        }
      }
    )
  },

  deleteMember(member) {
    KBPopover.plugins.confirm(
      '删除会员',
      `您确定要删除会员${member.name || member.id}吗?`,
      {
        confirm: () => {
          this.memberDismiss(member, true)
        }
      }
    )
  },

  memberDismiss(member, delete_d = false) {
    const { org } = this.props

    api.deleteOrgnizationMember(member.id, org.id, { delete: delete_d }).then(
      json => {
        this.props.deleteUserAction.success(json, {
          key: 'users',
          id: member.id
        })
        this.props.deleteOrgUserAction.success(json, {
          key: 'orgUsers',
          id: member.id
        })
        KBPopover.close()
        this.loadData()
        localStorage.setItem('reloadOrgInfo', true)
      },
      errors => {
        this.props.deleteUserAction.failure(errors)
        KBPopover.close()
      }
    )
  },

  updateMemberDesk(member, type) {
    const { org, location, loadOrganizationInfo, page } = this.props
    KBPopover.show(
      <OrgMemberDeskForm
        member={member}
        org={org}
        type={type}
        loc_id={location.id}
        callback={() => {
          if (type == 'create') {
            this.loadData(page)
            setTimeout(loadOrganizationInfo)
          }
        }}
      />,
      {
        closeOnOutsideClick: false
      }
    )
  },

  clearMemberDesk(m) {
    const {
      org,
      deleteDeskActions,
      loadOrganizationInfo,
      orgUserActions,
      page
    } = this.props
    const org_id = org.id

    KBPopover.plugins.confirm('取消入驻', '是否取消当前员工工位!', {
      confirm: () => {
        api.putFreeDeskOrgMember(org_id, m.id).then(
          json => {
            const {
              json: {
                entities: { users }
              }
            } = json
            const values = Object.values(users)[0]
            let entry = {
              [m.id]: {
                ...values,
                ...values.user,
                id: values.user.id
              }
            }

            entry[m.id].desks = entry[m.id].desks.map(json => {
              json.user = values.user
              return json
            })

            json.json.entities.users = entry
            json.json.entities.orgUsers = entry
            this.loadData(page)
            setTimeout(loadOrganizationInfo)
            this.props.updateUserActions.success(json)

            orgUserActions.success(json)
            deleteDeskActions.success({}, { key: 'desks', id: m.desk_id })
            KBPopover.close()
          },
          errors => {
            this.props.updateUserActions.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  editMember(member) {
    const { org, location, org_id } = this.props
    let role = 'employee'
    if (member.organizations && member.organizations.length) {
      for (let index = 0; index < member.organizations.length; index++) {
        if (org_id == member.organizations[index].id) {
          role = member.organizations[index].roles[0]
        }
      }
    }
    let m = Object.assign({}, member, {
      role,
      birth_year: checkNull(member.birth_year),
      birth_month: checkNull(member.birth_month),
      birth_day: checkNull(member.birth_day),
      skill_list: member.skill_list && member.skill_list.join(','),
      title: member.title || member.org_title || ''
    })

    KBPopover.show(
      <OrganizationMemberForm
        reLoad={this.loadData}
        defaultSkills={
          member.skill_list &&
          member.skill_list.map(json => {
            return { id: json, name: json }
          })
        }
        member={m}
        type="update"
        org={org}
        loc_id={location.id}
        initialValues={m}
      />
    )
  },

  _toOppInfo(invoice) {
    const {
      routerActions,
      loc_id,
      org_id,
      isManager,
      isObserver,
      org
    } = this.props
    let isView = false
    if (isManager || isObserver) {
      isView = true
    }
    if (!invoice.obfuscate && isView && org.connect) {
      routerActions.push(getUserInfoUrl(invoice.id))
    }
  },

  clickPhoto(url) {
    KBPopover.show(<PopoverShowPicture photoUrl={url} />)
  },

  phoneNumChange(e) {
    this.setState({ phone_num: e.target.value })
  },

  selPhoneNum() {
    this._setFilter({ phone_num: this.state.phone_num })
  },

  _clearFilters() {
    this.setState({ filters: {}, loading: true })
    // force refresh
    this._refresh()
  },

  getLockDate(user) {
    const obj = _.get(user, `organizations`, []).find(i => i.id == this.props.org_id)
    if(obj) {
      return _.get(obj, 'organization_user.lock_permission_end_date', '')
    }
  },

  render() {
    const { loc_id, org_id, isManager, org, members, isOnlyWeChatManger, isLandlord } = this.props
    const { phone_num, member } = this.state
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter !== '')
    return (
      <KBLoadingContainer loading={this.state.loading}>
        <div
          className="add-staff-component"
          style={Object.assign(
            {},
            { width: '300px', display: 'inline-block', marginRight: 40 }
          )}
        >
          <input
            type="text"
            maxLength={19}
            placeholder="请输入员工手机号"
            value={phone_num || ''}
            onChange={this.phoneNumChange}
            onFocus={null}
            onBlur={null}
          />
          <div className="currency-symbol" onClick={this.selPhoneNum}>
            搜索
          </div>
        </div>
        <br />
        {selectFilters.length > 0 ? (
          <div
            className="clear-criteria"
            style={{ marginTop: 5 }}
            onClick={this._clearFilters}
          >
            <i className="iconfont icon-close" />
            <span>清除所有筛选条件</span>
          </div>
        ) : null}
        <table className="content-table">
          <thead>
            <tr>
              <th>姓名</th>
              <th>face++ 照片</th>
              <th>职务</th>
              <th>联系方式</th>
              <th>入驻时间</th>
              <th>工位</th>
              <th>门禁权限截止日期</th>
              <th />
            </tr>
          </thead>
          <KBTableWithoutData
            hasData={member.length > 0}
            tableHeadNum="8"
            tipMessage="暂无数据"
          >
            {member &&
              member.length > 0 &&
              member.map(m => {
                return (
                  <tr key={m.id}>
                    <td>
                      <div className="vf-center">
                        <KBAvatar
                          style={{
                            display: 'inline-block',
                            marginRight: '5px'
                          }}
                          imgStyle={{ marginRight: 5 }}
                          user={m}
                          size={30}
                        />
                        {
                          isLandlord ? (
                            <div>
                              {m.name}
                              {m.role == 'employer' ? (
                                <i
                                  style={{ marginLeft: '5px', color: '#f9d83b' }}
                                  className="iconfont icon-star"
                                />
                              ) : (
                                  ''
                                )}
                            </div>
                          ) : (
                            <Link className="color-link" to={getUserInfoUrl(m.id)}>
                              {m.name}
                              {m.role == 'employer' ? (
                                <i
                                  style={{ marginLeft: '5px', color: '#f9d83b' }}
                                  className="iconfont icon-star"
                                />
                              ) : (
                                  ''
                                )}
                            </Link>
                          )
                        }
                      </div>
                    </td>
                    <td>
                      {m.face_photo_uploaded ?
                      // (
                      //   <img
                      //     className="face-img-in-table"
                      //     onClick={e => {
                      //       this.clickPhoto(m.face_photo.url)
                      //       e.stopPropagation()
                      //     }}
                      //     src={m.face_photo.url}
                      //     alt="Face++ Img"
                      //   />
                      // )
                      '已上传'
                      : (
                          '未上传'
                        )}
                    </td>
                    <td>{m.org_title || m.title || '员工'}</td>
                    {/**<td style={{maxWidth: 180}}>
                          {
                            m.skill_list && m.skill_list.length > 0 ? m.skill_list.map((skill,index) => {
                            return(
                              <span key={ index } className="badge" style={{marginRight: 5,marginBottom: 5}}>{skill}</span>
                            )
                          }) : '未填写'
                        }
                      </td> **/}
                    <td>
                      {m.phone_num_disabled ? <span>已挂失</span> : m.phone_num}
                    </td>
                    <td>{formatYearDayEN(m.user_entered_at)}</td>
                    {org.connect && org.is_entered ? (
                      <td>
                        {(m.desks && !m.desks.length) > 0 ? (
                          <span
                            className="certain-btn-ms"
                            onClick={e => {
                              this.updateMemberDesk(m, 'create')
                              e.stopPropagation()
                            }}
                          >
                            入驻
                          </span>
                        ) : (
                            <div style={{ display: 'flex' }}>
                              {m.desks &&
                                m.desks.map(d => {
                                  return d.state === 'assigned' ? (
                                    <span className="s-bg-fix">
                                      {d.serial_number}
                                    </span>
                                  ) : null
                                })}
                              {/** <span style={{ marginLeft: 5 }} className="certain-btn-ms" onClick={ (e) => {this.updateMemberDesk(m, 'update');e.stopPropagation();} } >更换</span> */}
                              <span
                                style={{ marginLeft: 5 }}
                                className="certain-btn-ms"
                                onClick={e => {
                                  this.clearMemberDesk.call(this, m)
                                  e.stopPropagation()
                                }}
                              >
                                取消入驻
                            </span>
                            </div>
                          )}
                      </td>
                    ) : (
                        <td />
                      )}
                    <td>{this.getLockDate(m)}</td>
                    {(isManager || isLandlord) && org.connect ? (
                      <td className="t-right">
                        <KBTipsy content="编辑">
                          <i
                            className="iconfont icon-edit"
                            alt="编辑员工"
                            onClick={e => {
                              this.editMember(m)
                              e.stopPropagation()
                              e.preventDefault()
                            }}
                            style={{ marginRight: '15px ' }}
                          />
                        </KBTipsy>
                       {!isOnlyWeChatManger && <KBTipsy content="离职">
                          <i
                            className="iconfont icon-dismiss"
                            alt="离职员工"
                            onClick={e => {
                              this.dismissMember(m)
                              e.stopPropagation()
                              e.preventDefault()
                            }}
                            style={{ marginRight: '15px ' }}
                          />
                        </KBTipsy>}
                        {/* <KBTipsy content='删除'>
                            <i className="iconfont icon-delete" alt="删除员工" onClick={(e) => {this.deleteMember(m);e.stopPropagation();e.preventDefault()}} />
                          </KBTipsy> */}
                      </td>
                    ) : (
                        <td />
                      )}
                  </tr>
                )
              })}
          </KBTableWithoutData>
        </table>
        <KBPagination
          pagination={this.state.pagination}
          template={`/admin/locations/${loc_id}/organizations/${org_id}/members?page=#PAGE#`}
        />
      </KBLoadingContainer>
    )
  }
})

OrganizationMembers.PropTypes = {
  org: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

function mapStateToProps(state, props) {
  const { user } = state
  let org_id = props.org.id
  let loc_id = props.location.id
  let isManager = canAccessLocation(user, loc_id) || canAdminLocationWeChat(user, loc_id)
  const isLandlord = canAccessLocationRoles(user, loc_id, ['landlord'])
  let isOnlyWeChatManger = onlyCanAdminLocationOfWeChat(user, loc_id)
  let isObserver = canAccessLocationObserver(user, loc_id)
  let page = parseInt(props.query && props.query.page) || 1
  let members = getOrgReplaceMembers(state)
  members &&
    members.map(member => {
      const { desks, organizations } = member
      if (desks && desks.length > 0) {
        let firstEnteredDesk =
          desks.filter(d => {
            return (
              ['reserved', 'assigned'].includes(d.state) &&
              org_id == d.organization_id
            )
          })[0] || {}
        member.user_entered_at = firstEnteredDesk.rented_at
        member.desks = desks.filter(d => org_id == d.organization_id)
      }
      if (organizations && organizations.length > 0) {
        let currentOrg =
          organizations.find(org => {
            return (
              org.id === org_id ||
              (org.organization && org.organization.id === org_id)
            )
          }) || {}
        member.organization = {}
        member.organization.roles = currentOrg.roles || []
        member.org_title =
          currentOrg.title || (currentOrg.user && currentOrg.user.title)
      }
      return member
    })
  return {
    loc_id,
    org_id,
    isManager,
    isObserver,
    page,
    members,
    isOnlyWeChatManger,
    isLandlord
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    deleteDeskActions: bindActionCreators(deskActions.delete, dispatch),
    updateUserActions: bindActionCreators(usersActions.update, dispatch),
    orgUserActions: bindActionCreators(orgUserActions.update, dispatch),
    getUserActions: bindActionCreators(usersActions.update, dispatch),
    getOrgUserActions: bindActionCreators(orgUserActions.replace, dispatch),
    deleteUserAction: bindActionCreators(usersActions.delete, dispatch),
    deleteOrgUserAction: bindActionCreators(orgUserActions.delete, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationMembers)
