import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { meetingDeviceMessageActions } from 'app/actions'
import { MEETING_THEME_CATEGORY } from 'app/constants'
import { connect } from 'react-redux'
import {
  KBLoadingContainer,
  KBPopover,
  KBRangeDate,
  KBPagination
} from 'components'
import { toImageProxyDirect } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import MeetingThemeForm from './MeetingThemeForm'
import moment from 'moment'

var MeetingDeviceDetail = React.createClass({
  getInitialState() {
    return {
      last_request_at: null
    }
  },

  componentDidMount() {
    const { loc_id, device_id, getMeetingDeviceActions } = this.props

    api
      .getMeetingDeviceMessage(device_id, { location_id: loc_id })
      .then(json => {
        getMeetingDeviceActions.success(json)
        this.setState({
          last_request_at: json.last_request_at
        })
      })
  },

  addTheme() {
    const { deviceMessage, loc_id } = this.props

    KBPopover.show(
      <MeetingThemeForm loc_id={loc_id} deviceMessage={deviceMessage} />
    )
  },

  render() {
    const { deviceMessage } = this.props
    const { last_request_at } = this.state
    let themeUrl = deviceMessage && deviceMessage.assets
    let now = moment()
    let fromNow = now.diff(last_request_at, 'minutes')

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span style={{ verticalAlign: 'middle', marginRight: 10 }}>
              {(deviceMessage && deviceMessage.device_name) || '设备详情'}
            </span>
            {deviceMessage && deviceMessage.enabled && fromNow > 10 ? (
              <span
                className="badge"
                style={{
                  borderColor: '#dd5a55',
                  color: '#dd5a55',
                  verticalAlign: 'middle'
                }}
              >
                设备异常
              </span>
            ) : (
              ''
            )}
          </h2>
          <a href="##" className="bordered-btn f-right" onClick={this.addTheme}>
            修改设备
          </a>
        </header>
        <div className="nav-section-content-container">
          <div className="kb-section">
            <ul>
              <li>
                <span className="section-title">分店:</span>
                <span className="section-title-content">
                  {deviceMessage && deviceMessage.location_name}
                </span>
              </li>
              <li>
                <span className="section-title">会议室:</span>
                <span className="section-title-content">
                  {deviceMessage && deviceMessage.area_name}
                </span>
              </li>
              <li>
                <span className="section-title">主题:</span>
                <span className="section-title-content">
                  {
                    MEETING_THEME_CATEGORY[
                      deviceMessage && deviceMessage.theme_type
                    ]
                  }
                </span>
              </li>
              <li>
                <span className="section-title">资料:</span>
                <div
                  className="clear-fix f-left"
                  style={{ maxWidth: 'calc(100% - 100px)' }}
                >
                  <ul className="f-left meet-device">
                    {themeUrl && themeUrl.length > 0 ? (
                      themeUrl.map((theme, index) => {
                        let URL = theme.url.indexOf('.mp4')
                        return URL != -1 ? (
                          <li key={index} style={{ padding: 0 }}>
                            <video
                              controls="controls"
                              width="200"
                              height="160"
                              autoplay="autoplay"
                            >
                              <source src={theme.url} type="video/mp4" />
                            </video>
                          </li>
                        ) : (
                          <li key={index} style={{ padding: 0 }}>
                            <img
                              src={toImageProxyDirect(theme.url, 200, 160)}
                              alt=""
                            />
                          </li>
                        )
                      })
                    ) : (
                      <span className="section-title-content">无</span>
                    )}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let device_id = params.device_id
  let deviceMessage = selectors.getMeetingDeviceMessage(state, device_id)
  return {
    loc_id,
    device_id,
    deviceMessage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getMeetingDeviceActions: bindActionCreators(
      meetingDeviceMessageActions.all,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingDeviceDetail)
