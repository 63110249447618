import React, { Component, PropTypes } from 'react'
import {
  KBInput,
  KBDateInput,
  KBTextarea,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover,
  KBTipsy
} from 'components'
import moment from 'moment'
import { INVOICES_STATE } from 'app/constants'
const CHECK_STATUS = {
  checked: '已核销',
  unchecked: '未核销',
  confirmed: '确认核销'
}

const SCHEDULE_TEXT = {
  rejected: '拒绝开票',
  process: '开票中',
  issued: '已开票',
  pending: '发票申请'
}

const STATUS_COLOR = {
  unpaid: '#f2a73d',
  partially_paid: '#2ea1f8',
  refunding: '#5962C1',
  paid: '#2ea1f8',
  overdue: '#DD5A55',
  cancelled: '#73BB4B'
}

const SCHEDULE_COLOR = {
  process: '#f2a73d',
  pending: '#5962C1',
  issued: '#2ea1f8',
  rejected: '#DD5A55'
}

const CHECK_STATUS_COLOR = {
  unchecked: '#DD5A55',
  checked: '#2ea1f8',
  confirmed: '#2ea1f8'
}

//const

var PhasesCell = React.createClass({
  removePasse() {
    const { all_sales_renting_phases, index_d, phase,isContract } = this.props
    if (
      (phase.status && phase.status.value == 'paid') ||
      (phase.check_status && phase.check_status.value) == 'checked' ||
      (phase.check_status && phase.check_status.value) == 'confirmed'
    ) {
      KBPopover.plugins.confirm(
        `删除缴${isContract ? '费' : '租'}阶段`,
        `待删除的缴${isContract ? '费' : '租'}阶段中有账单已经支付或核销，删除后将无法自动恢复，需要手工核对，确定要删除吗`,
        {
          confirm: () => {
            KBPopover.close()
            all_sales_renting_phases.removeField(index_d)
          }
        }
      )
    } else {
      all_sales_renting_phases.removeField(index_d)
    }
  },

  changeTotalPhaseAmount(e) {
    let value = e.target.value
    let valueFloat = parseFloat(value)
    const { phase } = this.props
    phase.total_amount.onChange(value)
    let changedRentingFee =
      valueFloat - parseFloat(phase.total_house_fee.value || 0)
    if (changedRentingFee < 0) {
      changedRentingFee = 0
    }
    phase.total_renting_fee.onChange(changedRentingFee)
  },

  render() {
    const { phase, index, sales_renting_phases, status, validDate, isContract } = this.props
    let isStatus =
      (phase.status && phase.status.value) ||
      (phase.invoice_schedule && phase.invoice_schedule.value) ||
      (phase.check_status && phase.check_status.value)
        ? true
        : false
    const tdStyle = {
      display: 'inline-flex',
      alignItems: 'center'
    }
    return (
      <tr className={status == 'update' && isStatus ? 'update-phases' : ''}>
        <td>
          <div style={Object.assign({}, tdStyle, { position: 'relative' })}>
            {status == 'update' &&
            moment(phase.end_date.value).isBefore(
              moment(validDate).format('YYYY-MM-DD')
            ) ? (
              <div
                style={{
                  backgroundColor: '#f55b5b',
                  padding: '3px 5px',
                  color: '#fff',
                  borderRadius: '5px',
                  position: 'absolute',
                  left: '-50px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  fontSize: 10
                }}
              >
                已结束
              </div>
            ) : (
              ''
            )}
            <span style={{ marginRight: 15 }}>日期</span>
            <KBDateInput
              type="date"
              className="kb-form-group kb-div-c66"
              field={phase.start_date}
            />
            <span className="f-left" style={{ padding: '0 5px', marginTop: 8 }}>
              -
            </span>
            <KBDateInput
              type="date"
              className="kb-form-group kb-div-c66"
              field={phase.end_date}
              titleClass="label-rt"
            />
            {phase.rent_type.value == 'free' ? (
              <img
                src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/phaseFree.png"
                alt="icon"
                style={{
                  right: -36,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: 34,
                  position: 'absolute'
                }}
              />
            ) : (
              ''
            )}
            {status == 'update' ? (
              <div className="update-phase_status">
                {phase.status && phase.status.value ? (
                  <span style={{ color: STATUS_COLOR[phase.status.value] }}>
                    {INVOICES_STATE[phase.status.value]}
                  </span>
                ) : (
                  ''
                )}
                {phase.invoice_schedule && phase.invoice_schedule.value ? (
                  <span
                    style={{
                      color: SCHEDULE_COLOR[phase.invoice_schedule.value]
                    }}
                  >
                    {SCHEDULE_TEXT[phase.invoice_schedule.value]}
                  </span>
                ) : (
                  ''
                )}
                {phase.check_status && phase.check_status.value ? (
                  <span
                    style={{
                      color: CHECK_STATUS_COLOR[phase.check_status.value]
                    }}
                  >
                    {CHECK_STATUS[phase.check_status.value]}
                  </span>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </td>
        <td>
          <div style={tdStyle}>
            <span style={{ marginRight: 15 }}>缴{isContract ? '费' : '租'}日</span>
            <KBDateInput
              type="date"
              className="kb-form-group kb-div-c66"
              field={phase.recurring_due_date}
            />
          </div>
        </td>
        <td>
          <div style={tdStyle}>
            <span style={{ marginRight: 15 }}>
              {phase.rent_type.value === "no_sales_area" ? '周期性费用' : '总会员费'}
              </span>
            <input
              type="text"
              className="entry-input"
              style={{ color: '#666' }}
              {...phase.total_amount}
              onChange={this.changeTotalPhaseAmount}
            />
          </div>
        </td>
        <td>
          {phase.note.value ? (
            <KBTipsy content={phase.note.value}>
              <i className="iconfont icon-record a-hover" />
            </KBTipsy>
          ) : (
            ''
          )}
        </td>
        <td>
          <i
            className="iconfont icon-close a-hover m-left"
            onClick={this.removePasse}
          />
        </td>
      </tr>
    )
  }
})

PhasesCell.propTypes = {
  phase: PropTypes.object.isRequired
}

export default PhasesCell
