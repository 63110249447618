import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { invoicesActions, successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import {
  formatYearDaySOL,
  formatYearDayEN,
  formatMinuteSecondSOL
} from 'utils/kbUtil'
import classNames from 'classnames'
import {
  KBPopover,
  KBButton,
  KBDropdown,
  KBLoadingContainer,
  KBTextarea,
  KBTable
} from 'components'
import {
  INVOICES_STATE,
  INVOICES_STATE_CLASS,
  INVOICES_SENDING_STATE,
  INVOICES_SENDING_STATE_CLASS,
  INVOICE_ORDER_SALES_PAYMENTS
} from 'app/constants'
import { get, getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import { getInvoiceOfId, getCurrentUser } from 'app/selectors'
import * as valid from 'utils/validate'
import * as selectors from 'app/selectors'
import { KBPopoverConfirm } from 'components/tools'
import OfflineRefundForm from './OfflineRefundForm'
import InvoiceForm from './InvoiceForm'
import Description from './components/Description'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'

var InvoicesInfo = React.createClass({
  getInitialState() {
    return {
      loading: true
    }
  },
  componentDidMount() {
    const { getInvoicesActions, invoice_id } = this.props
    api
      .getInvoice(invoice_id)
      .then(
        json => {
          getInvoicesActions.success(json)
        },
        errors => {
          getInvoicesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  getUnitByItem(type) {
    if (type === 'point') {
      return '办公券'
    }

    if (type === 'third_point') {
      return '星积分'
    }

    return '元'
  },

  // 手工开票逻辑
  showInvoicePopover() {
    KBPopover.show(
      <InvoiceForm invoice={this.props.invoice} callBack={this.doInvoice} />
    )
  },
  doInvoice(model) {
    const { updateInvoicesActions, invoice_id, successAction } = this.props
    api
      .setOfflineInvoice(invoice_id, model)
      .then(
        json => {
          successAction({ message: '操作成功' })
          updateInvoicesActions.success(json)
          KBPopover.close()
        },
        errors => {
          updateInvoicesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  showRefundPopover() {
    KBPopover.show(
      <OfflineRefundForm
        invoice={this.props.invoice}
        callBack={this.offlineRefund}
      />
    )
  },

  offlineRefund(model) {
    // 线下退款
    const { updateInvoicesActions, invoice_id, successAction } = this.props
    api
      .checkRefund(invoice_id, model)
      .then(
        json => {
          successAction({ message: '操作成功' })
          updateInvoicesActions.success(json)
          KBPopover.close()
        },
        errors => {
          updateInvoicesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  cancelRefund() {
    KBPopoverConfirm({
      name: '取消退款',
      context: `确认是否取消退款？`,
      callback: () => {
        const { updateInvoicesActions, invoice_id, successAction } = this.props
        return api
          .checkRefund(invoice_id, {
            refund: false
          })
          .then(
            json => {
              successAction({ message: '操作成功' })
              updateInvoicesActions.success(json)
              KBPopover.close()
            },
            errors => {
              updateInvoicesActions.failure(errors)
            }
          )
          .finally(() => {
            this.setState({ loading: false })
          })
      }
    })
  },

  getDetailTableList() {
    // 订单表格
    const { invoice } = this.props

    if (!invoice) return []

    const { paid_point_amount, paid_star_amount, total_amount, sales_tax_description } = invoice

    const result = [...(invoice.line_items || [])]

    const shouldPay = parseFloat(total_amount - paid_point_amount - paid_star_amount) + ''

    if (invoice.paid_point_amount) {
      result.push({
        disName: '办公券抵扣',
        disMoney: 0 - paid_point_amount + ''
      })
    }

    if (invoice.paid_star_amount) {
      result.push({
        disName: '星积分抵扣',
        disMoney: 0 - paid_star_amount + ''
      })
    }

    result.push({
      disName: '应付金额',
      disMoney: shouldPay
    })

    if(sales_tax_description) {
      result.push({
        disMoney: sales_tax_description
      })
    }

    return result
  },

  invoiceJsx() {
    const {
      invoice,
      fields: { note }
    } = this.props
    const { loading } = this.state
    const { invoicing_application, payment_mode, paid_point_amount, paid_star_amount, total_amount, } = invoice

    const status = INVOICES_STATE
    const status_class = INVOICES_STATE_CLASS


    const shouldPay = parseFloat(total_amount - paid_point_amount - paid_star_amount)

    const descriptions = [
      { label: '账单编号', value: invoice.serial_number },
      {
        label: '生成时间',
        value: formatMinuteSecondSOL(invoice.created_at)
      },
      {
        label: '截止日期',
        value: invoice.due_date
          ? formatYearDaySOL(invoice.due_date)
          : ''
      },
    ]
    // 如果不是管理端下单
    if(!['temporary_booking', 'own_use', 'special_booking'].includes(payment_mode)) {
      descriptions.push({ label: '服务内容', value: invoice.content })

      // 有用户名展示
      if(invoice.reserver) {
        descriptions.push({ label: '用户名', value: invoice.reserver.name })
        descriptions.push({ label: '电话', value: invoice.reserver.secure_phone_num })
      }
      // 支付金额大于0展示
      if(invoice.line_items && invoice.line_items.length && invoice.line_items[0].merchant && shouldPay > 0) {
        descriptions.push({ label: '收款方', value: invoice.line_items[0].merchant.account_name })
      }
    }

    // 是否展示部分退款明细
    const hasDiscountGoods = (invoice.line_items || []).some(i => i.line_item_type === 'discount_goods')
    const showPartRefund = hasDiscountGoods && invoice && invoice.status === 'refunded'

    // 表格项
    let columns = [
      { title: '说明', key: 'description', width: '450px' },
      {
        title: '单价(元)',
        key: 'unit_price',
        render(item) {
          if (item.line_item_type === 'discount_goods') return
          return item.unit_price
        }
      },
      {
        title: '数量',
        key: 'units',
        render(item) {
          if (item.line_item_type === 'discount_goods') return
          return item.units
        }
      },
      {
        title: '原价(元)',
        key: 'org_amount',
        render(item) {
          if (item.line_item_type === 'discount_goods' || !item.units)
            return
          return item.units * item.unit_price
        }
      },
      {
        title: '优惠减免(元)',
        key: 'discount_amount',
        render(item) {
          if (item.line_item_type === 'discount_goods') return
          return item.disName || item.discount_amount
        }
      },
      {
        title: `${invoice.coupon_name}(元)`,
        key: 'coupon_amount',
        render(item) {
          return item.coupon_amount
        }
      },
      {
        title: '总价(元)',
        key: 'total_amount',
        width: '290px',
        align: 'right',
        render(item) {
          return item.disMoney || item.total_amount
        }
      }
    ]

    if(!invoice.coupon_name) {
      columns = columns.filter(i => i.key !== 'coupon_amount')
    }

    return (
      <KBLoadingContainer loading={loading}>
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span className="f-left">
              {(invoice.sales_customer && invoice.sales_customer.name) || ''}
            </span>
            <span
              className={`finance-default ${status_class[invoice.status]}`}
              style={{ marginTop: 4 }}
            >
              {status[invoice.status]}
            </span>
            {/* 开票成功之后展示开票方式 */}
            {invoicing_application &&
              invoicing_application.status === 'issued' && (
                <span
                  className="finance-default"
                  style={{ marginTop: 4, backgroundColor: '#a2a73d' }}
                >
                  {invoicing_application.system_type === 'offline'
                    ? '已手工开票'
                    : '已线上开票'}
                </span>
              )}
            <br />
            <div className="f-left" style={{ marginTop: '5px', fontSize: 14 }}>
              <span>{invoice.location && invoice.location.name}</span>
            </div>
          </div>
          {// ★凯星卡退款逻辑
          invoice.can_offline_refund && (
            <div className="nav-section-header-title f-right">
              <KBButton
                className="bordered-btn"
                style={{
                  marginRight: '10px'
                }}
                onClick={this.showRefundPopover}
              >
                线下退款
              </KBButton>
              <KBButton className="certain-btn" onClick={this.cancelRefund}>
                取消退款
              </KBButton>
            </div>
          )}
          {invoice.can_offline_invoicing && (
            // 手工开票
            <div className="nav-section-header-title f-right">
              <KBButton
                className="bordered-btn"
                onClick={this.showInvoicePopover}
              >
                手工开票
              </KBButton>
            </div>
          )}
        </header>
        <div className="nav-section-content-container">
          <div className="kb-section p-bottom">
            <Description
              dataSource={descriptions}
            />
          </div>

          {/* 最新的表格 */}
          <KBTable
            columns={columns}
            dataSource={this.getDetailTableList()}
          />

          {invoice &&
            invoice.sales_payments &&
            invoice.sales_payments.length > 0 && (
              <div className="finance-detail" style={{ marginTop: 60 }}>
                <header>收款明细</header>
                <KBTable
                  columns={[
                    {
                      title: '时间',
                      key: 'created_at',
                      render(json) {
                        return formatYearDayEN(json.created_at)
                      }
                    },
                    {
                      title: '经手人',
                      key: 'creator',
                      render(json) {
                        return json.creator && json.creator.name
                      }
                    },
                    {
                      title: '付款方式',
                      key: 'payment_mode',
                      render(json) {
                        return json.payment_mode
                          ? INVOICE_ORDER_SALES_PAYMENTS[json.payment_mode]
                          : '无'
                      }
                    },
                    {
                      title: '数额',
                      key: 'amount',
                      render: json => {
                        return (
                          json.amount + this.getUnitByItem(json.payment_mode)
                        )
                      }
                    },
                    { title: '流水号', key: 'reference_number', align: 'right' }
                  ]}
                  dataSource={invoice.sales_payments || []}
                />
              </div>
            )}

          {/* 部分退款时，展示退款明细 */}
          {showPartRefund && (
              <div className="finance-detail" style={{ marginTop: 60 }}>
                <header>退款明细</header>
                <KBTable
                  columns={[
                    {
                      title: '时间',
                      key: 'refund_at',
                      render(json) {
                        return formatYearDayEN(json.refund_at)
                      }
                    },
                    {
                      title: '经手人',
                      key: 'canceller_name',
                      render(json) {
                        return json.canceller_name
                      }
                    },
                    {
                      title: '付款方式',
                      key: 'payment_mode',
                      render(json) {
                        return json.payment_mode
                          ? INVOICE_ORDER_SALES_PAYMENTS[json.payment_mode]
                          : '无'
                      }
                    },
                    {
                      title: '数额',
                      key: 'amount',
                      render: json => {
                        return (
                          json.amount + this.getUnitByItem(json.payment_mode)
                        )
                      }
                    },
                    { title: '流水号', key: 'reference_number', align: 'right' }
                  ]}
                  dataSource={invoice.refund_payments || []}
                />
              </div>
            )}

          {/* 手工开票展示开票明细 */}
          {invoicing_application &&
            invoicing_application.system_type === 'offline' && (
              <div className="finance-detail">
                <header>开票明细</header>
                <KBTable
                  columns={[
                    {
                      title: '开票日期',
                      key: 'invoice_date',
                      render(json) {
                        return formatYearDayEN(
                          invoicing_application.invoice_date
                        )
                      }
                    },
                    {
                      title: '经手人',
                      key: 'user',
                      render(json) {
                        return invoicing_application.user
                          ? invoicing_application.user.name
                          : ''
                      }
                    },
                    {
                      title: '发票号',
                      key: 'payment_mode',
                      render(json) {
                        return invoicing_application.invoice_number
                      }
                    },
                    {
                      title: '附件',
                      key: 'pdf_url',
                      render: json => {
                        return (
                          invoicing_application.pdf_url && (
                            <KBDownloadPrivacy url={invoicing_application.pdf_url}>
                              <a
                                style={{
                                  color: '#1a91eb',
                                  textDecoration: 'underline'
                                }}
                              >
                                点击下载
                              </a>
                            </KBDownloadPrivacy>
                          )
                        )
                      }
                    },
                    {
                      title: '操作',
                      key: 'op',
                      align: 'right',
                      render: () => {
                        return (
                          <button
                            className="bordered-btn"
                            onClick={this.showInvoicePopover}
                          >
                            编辑
                          </button>
                        )
                      }
                    }
                  ]}
                  dataSource={[invoicing_application]}
                />
              </div>
            )}

          {note && note.value ? (
            <div className="finance-detail">
              <header>备注信息</header>
              <form>
                <textarea
                  ref="invoiceNoteTextarea"
                  disabled={true}
                  className={'kb-input'}
                  placeholder="添加备注"
                  {...note}
                />
              </form>
            </div>
          ) : null}
        </div>
      </KBLoadingContainer>
    )
  },
  render() {
    const { invoice, member_id, isMemberShip } = this.props
    return member_id ? (
      <section className="nav-section-container f-right">
        <div className="clear-fix" style={{ height: '100%' }}>
          {invoice && this.invoiceJsx()}
        </div>
      </section>
    ) : isMemberShip ? (
      <div className="kb-content-container clear-fix" style={{ paddingTop: 0 }}>
        {invoice && this.invoiceJsx()}
      </div>
    ) : (
      <section className="kb-content" style={{ marginTop: 0, paddingTop: 0 }}>
        <div className="kb-content-container clear-fix">
          {invoice && this.invoiceJsx()}
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const invoice_id = params.invoice_id
  const invoice = getInvoiceOfId(state, invoice_id)
  let member_id = props.params.member_id
  let isMemberShip =
    location.query && location.query.type == 'membership' ? true : false
  const spaceSetting = selectors.getSpaceSetting(state)
  const exchangeUnit = selectors.getSpaceVirtualCurrencyName(state)

  return {
    invoice_id,
    invoice,
    member_id,
    isMemberShip,
    exchangeUnit,
    initialValues: {
      note: (invoice && invoice.point_plan && invoice.point_plan.note) || ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.get, dispatch),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  // if (valid.required(values.note)) {
  //   errors.note = '请输入备注信息'
  // }

  return errors
}

const formConfig = {
  form: 'InvoicesInfo',
  fields: ['note'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoicesInfo)
