import React, { Component, PropTypes } from 'react'
import { KBLoadingContainer, KBTipsy, KBPopover } from 'components'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { canAccessLocation } from 'app/reducers/role'
import OrganizationRechargePointForm from '../organization/OrganizationRechargePointForm'

var OrgDetail = React.createClass({
  rechargePoint() {
    const { organization, loadOrganizationInfo } = this.props
    KBPopover.show(
      <OrganizationRechargePointForm
        org={organization}
        loadOrganizationInfo={loadOrganizationInfo}
      />
    )
  },

  getRandom(id) {
    let random = id % 10

    if (random < 10) {
      random = '0' + random
    } else if (random > 12) {
      random = 11
    }

    return random
  },

  render() {
    const { loading, virtual_currency_name, organization, user } = this.props
    const { opportunities } = organization

    return (
      <section className="nav-section-container-multi f-right">
        <div className="multi-section-each">
          <header className="member-header">
            <h2 className="header-title f-left" style={{ fontSize: '22px' }}>
              <span>公司概况</span>
              {organization.is_important ? (
                <div
                  className="team-located"
                  style={{
                    background: 'inherit',
                    padding: 0,
                    cursor: 'pointer'
                  }}
                >
                  <KBTipsy content="重要客户">
                    <i
                      className="iconfont icon-important_crm"
                      style={{
                        color: '#FF3F3F',
                        fontSize: '22px',
                        position: 'relative',
                        top: '4px'
                      }}
                    />
                  </KBTipsy>
                </div>
              ) : null}
              {!connect ? (
                <div className="team-located">已毕业企业</div>
              ) : organization.is_entered ? (
                organization.is_expired ? (
                  <div
                    style={{ background: '#dd5a55' }}
                    className="team-located"
                  >
                    合同已到期
                  </div>
                ) : null
              ) : null}
              {organization.is_legal ? (
                <div style={{ background: '#73bb4b' }} className="team-located">
                  资质已确认
                </div>
              ) : (
                <div className="team-located">资质未确认</div>
              )}
            </h2>
          </header>
          <KBLoadingContainer loading={loading}>
            <div
              className="nav-section-content-container"
              style={{ paddingBottom: 30 }}
            >
              <div className="member-message">
                <ul className="message-list">
                  <li>
                    <span>所属行业</span>
                    <span>
                      {(organization.industry && organization.industry.name) ||
                        '暂无'}
                    </span>
                  </li>
                  <li>
                    <span>负责人</span>
                    <span>{organization.contacts_name || '暂无'}</span>
                  </li>
                  <li>
                    <span>客户执行人</span>
                    <span>
                      {(opportunities &&
                        opportunities.length > 0 &&
                        opportunities.map((opp, index) => {
                          return (
                            opp.sales_leaders &&
                            opp.sales_leaders
                              .map(user => {
                                return user.name
                              })
                              .join('、')
                          )
                        })) ||
                        '暂无'}
                    </span>
                  </li>
                </ul>
                <ul className="message-list">
                  <li>
                    <span>融资阶段</span>
                    <span>
                      {(organization.fund_raising &&
                        organization.fund_raising.name) ||
                        ''}
                    </span>
                  </li>
                  <li>
                    <span>关联客户</span>
                    {(opportunities &&
                      opportunities.length > 0 &&
                      opportunities.map((opp, index) => {
                        return (
                          <Link
                            to={`/admin/crm/opportunities/${opp.id}`}
                            className="color-link m-right-sm"
                          >
                            {opp.title}
                          </Link>
                        )
                      })) ||
                      '暂无'}
                  </li>
                </ul>
              </div>

              <div
                className="member-credits border-bottom"
                style={{ paddingBottom: 35 }}
              >
                <div className="color-six" style={{ marginBottom: 22 }}>
                  企业介绍
                </div>
                <div className="">{organization.description || '暂无'}</div>
              </div>

              <div className="member-credits">
                <div className="color-six" style={{ marginBottom: 22 }}>
                  公司{virtual_currency_name}
                </div>
                <div className="credit-message">
                  <div className="vf-center">
                    <div className="star-icon">
                      <i className="iconfont icon-star2" />
                    </div>
                    <div className="point-con">
                      <div className="point-num">
                        {(organization &&
                          organization.balances &&
                          organization.balances.point) ||
                          0}
                        &nbsp;{virtual_currency_name}
                      </div>
                      <div style={{ marginTop: 1 }}>
                        {virtual_currency_name}数量
                      </div>
                    </div>
                  </div>
                  <button
                    className="operation-btn"
                    onClick={this.rechargePoint}
                  >
                    充值
                  </button>
                </div>
              </div>
            </div>
          </KBLoadingContainer>
        </div>

        {organization.locations && organization.locations.length > 0 ? (
          <div className="multi-section-each">
            <header className="member-header">
              <h2 className="header-title f-left" style={{ fontSize: '22px' }}>
                入驻分店
              </h2>
            </header>
            <div
              className="nav-section-content-container"
              style={{ paddingBottom: 30 }}
            >
              {organization.locations.map((loc, index) => {
                if (canAccessLocation(user, loc.id)) {
                  return (
                    <Link
                      to={`/admin/locations/${
                        loc.id
                      }/organizations/${organization && organization.id}`}
                      className="org-loc-block-a org-loc-block"
                    >
                      <div
                        className="loc-img"
                        style={{
                          backgroundImage: `url(${(loc.images &&
                            loc.images[0]) ||
                            `https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/location_subbranch${this.getRandom(
                              loc.id
                            )}.jpg`})`
                        }}
                      />
                      <div className="loc-title">{loc.name}</div>
                    </Link>
                  )
                }
                return (
                  <div className="org-loc-block">
                    <div
                      className="loc-img"
                      style={{
                        backgroundImage: `url(${(loc.images && loc.images[0]) ||
                          `https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/location_subbranch${this.getRandom(
                            loc.id
                          )}.jpg`})`
                      }}
                    />
                    <div className="loc-title">{loc.name}</div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : null}
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state

  return {
    user
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgDetail)
