import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { formatYearDayEN } from 'utils/kbUtil'
import { subscriptionActions, apiCallFailure } from 'app/actions'
import { api, apiUtils } from 'app/services'
import { getCurrentLocation } from 'utils/kbUtil'
import {
  KBDateInput,
  KBTextarea,
  KBFormError,
  KBButton,
  KBPopover
} from 'components'
import moment from 'moment'

var FreePhasesForm = React.createClass({
  getInitialState() {
    return {
      isLoading: false
    }
  },
  parseRenting(model) {
    const {
      callback,
      sales_renting_phases,
      apiCallFailureActions,
      contractEndDate,
      subscription_start_date,
      subscription_end_date,
      location_id
    } = this.props
    model.end_date = formatYearDayEN(model.end_date)
    if (
      moment(model.end_date).isAfter(contractEndDate) &&
      !moment(model.end_date).isSame(contractEndDate)
    ) {
      return apiCallFailureActions({
        status: 'error',
        message: '免租期终止日期不能大于合同终止日期'
      })
    }

    let isConflict = false
    sales_renting_phases.map(phase => {
      let start_date_m = model.start_date
      let end_date_m = model.end_date
      let start_date_p = phase.start_date.value
      let end_date_p = phase.end_date.value

      if (
        moment(start_date_m).isSame(start_date_p) ||
        moment(start_date_m).isSame(end_date_p) ||
        moment(end_date_m).isSame(start_date_p) ||
        moment(end_date_m).isSame(end_date_p) ||
        moment(start_date_m).isBetween(start_date_p, end_date_p) ||
        moment(end_date_m).isBetween(start_date_p, end_date_p)
      ) {
        isConflict = true
      }
    })
    if (isConflict) {
      return apiCallFailureActions({
        status: 'error',
        message: '您设置的免租期阶段起止日期与现有缴租阶段的日期重叠，请修改'
      })
    }

    this.setState({
      isLoading: true
    })
    model.start_date = formatYearDayEN(model.start_date)
    model.end_date = formatYearDayEN(model.end_date)
    model.subscription_start_date = formatYearDayEN(subscription_start_date)
    model.subscription_end_date = formatYearDayEN(subscription_end_date)
    model.location_id = location_id

    return api.getExpandRentingPhase(model).then(({ json }) => {
      callback && callback(json)
      KBPopover.close()
    })
  },

  componentWillUnmount() {
    this.setState({
      isLoading: false
    })
  },

  render() {
    const {
      fields: { start_date, end_date, note },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { isLoading } = this.state
    let DAYS = []
    for (let i = 1; i <= 31; i++) {
      DAYS.push(i)
    }

    return (
      <form onSubmit={handleSubmit(this.parseRenting)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-header">
          <h2 className="header-title">添加免租期</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-row kb-form-66">
            <KBDateInput
              title="阶段开始"
              isShowCenter={true}
              className="kb-form-group kb-div-c39"
              field={start_date}
            />
            <KBDateInput
              title="阶段终止"
              isShowCenter={true}
              className="kb-form-group kb-div-c48"
              field={end_date}
              titleClass="label-rt"
            />
          </div>
          <div className="kb-row">
            <KBTextarea
              title="备注"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={note}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              {isLoading ? '加载' : '确定'}
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { start_date, end_date } = props
  let currentLocation = getCurrentLocation()
  let location_id = currentLocation && currentLocation.id
  return {
    initialValues: {
      recurring_amount: 0,
      start_date,
      end_date,
      recurring_cycles: 1,
      recurring_due_day: 31,
      recurring_house_fee: 0,
      recurring_renting_fee: 0,
      rent_type: 'free'
    },
    location_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.isPrice(values.recurring_amount)) {
    errors.recurring_amount = '请输入正确的价格, 小数点保留2位'
  }

  return errors
}

const formConfig = {
  form: 'FreePhasesForm',
  fields: [
    'start_date',
    'end_date',
    'recurring_amount',
    'recurring_cycles',
    'recurring_due_day',
    'note',
    'recurring_house_fee',
    'recurring_renting_fee',
    'rent_type'
  ],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(FreePhasesForm)
