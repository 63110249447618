import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { apiCallFailure } from 'app/actions'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import { KBInput, KBSelect, KBButton, KBPopover, KBForm } from 'components'
import { getServiceErrorMessage } from 'utils/kbUtil'
import intl from 'react-intl-universal'

var LockerCabinetForm = React.createClass({
  editCabinet(model) {
    const { callBack, cabinet, apiCallFailureActions } = this.props

    if (
      !model.cabinet_locker_category_id ||
      model.cabinet_locker_category_id == -1
    ) {
      delete model.cabinet_locker_category_id
    }
    if (model.status === '[object Object]') {
      delete model.status
    }
    return api.putLockerCabinet(cabinet.cabinet.id, cabinet.id, model).then(
      () => {
        callBack && callBack(cabinet.cabinet.id, true)
        KBPopover.close()
      },
      error => {
        const errorMessage = getServiceErrorMessage(error)
        apiCallFailureActions({
          status: 'error',
          message: errorMessage
        })
      }
    )
  },

  render() {
    const {
      fields: {
        status,
        section,
        port,
        locker_number,
        cabinet_locker_category_id
      },
      handleSubmit,
      submitting,
      LOCKER_STATUS,
      LOCKER_STATUS_FREE,
      lockerCategories
    } = this.props

    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.editCabinet)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">编辑格子</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBSelect
              disabled={
                status.value === 'occupied' ||
                status.value === 'overtime' ||
                status.value === 'cleanup'
              }
              defaultValue={status}
              className="kb-form-group kb-div-c18 kb-form-alone"
              title="状态"
              field={status}
              arrayData={
                status.value === 'free' || status.value === 'disabled'
                  ? LOCKER_STATUS_FREE
                  : LOCKER_STATUS
              }
            />
          </div>
          <div className="kb-row">
            <KBSelect
              defaultValue="-1"
              defaultName="无"
              className="kb-form-group kb-div-c18 kb-form-alone"
              title="规格"
              field={cabinet_locker_category_id}
              arrayData={lockerCategories}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={section}
              title="锁控板号"
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={port}
              title="端口号"
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={locker_number}
              title="格子锁号码"
              isRequired={true}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state) {
  const lockerCategories = selectors.getLockerCategories(state)
  const LOCKER_STATUS = [
    {
      id: 'free',
      name: '空闲'
    },
    {
      id: 'occupied',
      name: '使用中'
    },
    {
      id: 'overtime',
      name: '超时'
    },
    {
      id: 'cleanup',
      name: '等待清柜'
    },
    {
      id: 'disabled',
      name: '停用'
    }
  ]

  const LOCKER_STATUS_FREE = [
    {
      id: 'free',
      name: '空闲'
    },
    {
      id: 'disabled',
      name: '停用'
    }
  ]

  return {
    LOCKER_STATUS_FREE,
    LOCKER_STATUS,
    lockerCategories
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.section)) {
    errors.section = '请输入锁控板号'
  }

  if (valid.required(values.port)) {
    errors.port = '请输入端口号'
  }

  if (valid.required(values.locker_number)) {
    errors.locker_number = '请输入格子锁号码'
  }

  return errors
}

const formConfig = {
  form: 'LockerCabinetForm',
  fields: [
    'section',
    'status',
    'port',
    'locker_number',
    'cabinet_locker_category_id'
  ],
  validate: validate,
  touchOnBlur: false
}

LockerCabinetForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LockerCabinetForm)

export default LockerCabinetForm
