import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import { subscriptionActions } from 'app/actions'
import {
  KBFormError,
  KBButton,
  KBPopover,
  KBMorePhotoUpload,
  KBInput
} from 'components'
import config from 'app/config'

var RealSubscriptionForm = React.createClass({
  updateSubScriptionForm(model) {
    const { updateSubScriptionAction, member, subId } = this.props

    return api.updateSignSubscription(subId, model).then(json => {
      updateSubScriptionAction.success(json)
      KBPopover.close()
    })
  },

  render() {
    const {
      fields: { serial, file },
      error,
      submitting,
      handleSubmit,
      member,
      org,
      subscriptions
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.updateSubScriptionForm)}>
        <KBFormError error={error} />
        <header className="kb-form-header">
          <h2 className="header-title">签为正式合同</h2>
          <i
            className="header-close iconfont icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              title="合同编号"
              isRequired={true}
              label="true"
              placeholder="输入合同自有编号"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={serial}
              value={serial.value}
            />
          </div>
          <div className="kb-row">
            <KBMorePhotoUpload
              className="kb-form-group kb-form-c18  kb-form-alone"
              text={file.value ? '合同文件已上传' : '上传合同原文件'}
              uploadingText="正在上传"
              title="上传合同"
              photoShow={false}
              isRequired={true}
              prefix={config.uploadDir + 'subscriptions/'}
              buttonClass={'bordered-btn m-none'}
              uploadedText="上传合同"
              field={file}
              accept="image/*, .doc,.docx,.pdf"
            />
          </div>
          <div className="f-right">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn m-left-sm"
              type="submit"
              submitting={submitting}
            >
              确认
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

RealSubscriptionForm.propTypes = {
  subId: PropTypes.string.isRequired
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubScriptionAction: bindActionCreators(
      subscriptionActions.update,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.serial)) {
    errors.serial = '请输入合同编号'
  }

  if (valid.required(values.file)) {
    errors.file = '请选择合同原件'
  }

  return errors
}

const formConfig = {
  form: 'OrgSubTerminateForm',
  fields: ['serial', 'file'],
  validate: validate,
  touchOnBlur: false
}

RealSubscriptionForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(RealSubscriptionForm)

export default RealSubscriptionForm
