import React, { Component } from 'react'

export default class Description extends Component {
  render() {
    const {dataSource = []} = this.props
    return (
      dataSource.length > 0 && (
        <ul>
          {
            dataSource.map(item => {
              return (
                <li key={item.label}>
                  <span className="section-title">{item.label}:</span>
                  <span className="section-title-content">
                    {item.value}
                  </span>
                </li>
              )
            })
          }
        </ul>
      )
    )
  }
}
