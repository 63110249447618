import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  meetingsActions,
  reservationsActions,
  successState,
  apiCallFailure
} from 'app/actions'
import { connect } from 'react-redux'
import { KBLoadingContainer, KBDropdown, KBButton, KBPopover } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import * as selectors from 'app/selectors'
import { getEntitiesJsonArray, formatYearDayCHS } from 'utils/kbUtil'
import moment from 'moment'
import MeetingBookingForm from './MeetingBookingForm'
import { getInitialDate, getWeeks, WEEK } from 'utils/kbData'
import {
  MEETING_DEFAULT_COUNT,
  MEETING_START_TIME,
  MEETING_END_TIME,
  MEETING_DEFAULT_GRID_WIDTH
} from 'utils/kbData'
import {
  MEETING_COLORS,
  ORIENTATION_STATES_OBJ,
  MEETING_DEVICE,
  RESERVATION_PROGRESS_CLASS,
  RESERVATION_PROGRESS_STATUS
} from 'app/constants'
import { canAccessLocation } from 'app/reducers/role'
import MeetingPopup from './MeetingPopup'
import MeetingForm from './MeetingForm'
import SettingCoupon from './SettingCoupon'
import _ from 'lodash'
import * as QrCode from 'qrcode.react'
import { Input, Row, Col, DatePicker } from 'antd'

const DISABLED_COLOR = 'rgb(230,230,230)'

var MeetingInfo = React.createClass({
  displayName: 'MeetingInfo',
  getInitialState() {
    return {
      meeting: null,
      weekNumber: 0,
      scrollLeft: 0,
      reservations: [],
      isInitialScroll: true,
      isShowMeetingPopup: false,
      current_reservation: {},
      ...getWeeks(),
      showQrCode: false,
      showWeChatQrCode: false,
      weChatCode: null,
      modalVisible: false
    }
  },
  getDefaultProps() {
    return {}
  },

  _refresh(key, filters) {
    let dropDown = key + 'Dropdown'
    const { page } = this.props
    this.refs[dropDown] && this.refs[dropDown].hide()

    // force refresh
    setTimeout(() => {
      this._loadData(page, this.state.filters)
    })
  },

  setFilter(obj) {
    const { page } = this.props
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters
    })
    this._loadData(page, newFilters)
    this.refs.oppStateDropdown.hide()
    this.refs.principalDropdown.hide()
    this.refs.userTypeDropdown.hide()
  },

  clearFilter() {
    const { page } = this.props

    this.setState({ filters: {} })
    this._loadData(page)
  },

  componentDidMount() {
    this.isInitialScroll()
  },

  componentWillReceiveProps(nextProps) {
    const { meeting } = nextProps
    const { isInitialScroll } = this.state
    if (this.props.reservations.length != nextProps.reservations.length) {
      this._loadData()
    }

    if (
      meeting &&
      Object.keys(meeting).length &&
      !_.isEqual(meeting, this.props.meeting)
    ) {
      this._loadMeetingRoom()
    }

    this.refs['scroll'] && isInitialScroll && this.initialScrollLeft()
  },

  componentDidMount() {
    this._loadMeetingRoom()
    this._loadData()
  },

  _loadMeetingRoom() {
    const { meeting_id, getMeetingsActions } = this.props
    api.getMeetingRoomInfo(meeting_id).then(
      json => {
        getMeetingsActions.success(json)
        let meeting = getEntitiesJsonArray(json, 'meetingRoots')
        this.setState({
          meeting
        })
      },
      errors => {
        getMeetingsActions.failure(errors)
      }
    )
  },

  _loadData() {
    const { getReservationsAction, loc_id, meeting_id } = this.props
    const { startDay, endDay } = this.state
    var params = {
      start_at: startDay.format('YYYY-MM-DD HH:mm:ss'),
      end_at: endDay.format('YYYY-MM-DD HH:mm:ss'),
      per_page: 300,
      location_id: loc_id,
      state: 'reserved_active'
    }

    api.getReservations(params).then(
      json => {
        let reservations = _.cloneDeep(
          getEntitiesJsonArray(json, 'reservations')
        )
        reservations = reservations.filter(reservation => {
          return reservation.area_id == meeting_id
        })
        // 添加颜色
        let colorIndex = 0
        let themeColorsCounnt = MEETING_COLORS.length
        reservations = reservations.map(reservation => {
          if (colorIndex > themeColorsCounnt - 1) {
            colorIndex = 0
          }
          reservation.color = MEETING_COLORS[colorIndex]
          reservation.end_at = moment.unix(reservation.end_at).toDate()
          reservation.start_at = moment.unix(reservation.start_at).toDate()
          colorIndex++
          return reservation
        })

        reservations = reservations.map(reservation => {
          var temp = reservations.find(
            r =>
              r.organization_id == reservation.organization_id &&
              r.id != reservation.id &&
              r.color
          )

          if (temp) {
            reservation.color = temp.color
          }

          return reservation
        })

        getReservationsAction.success(json)
        this.setState({
          reservations
        })
      },
      errors => {
        getReservationsAction.failure(errors)
      }
    )
  },

  getMeetingInitData(momentDate) {
    let currentMoment = moment()

    let currentYmd = moment().format('YYYY-MM-DD')
    let otherYmd = momentDate.format('YYYY-MM-DD')

    let year = momentDate.get('year')
    let month = momentDate.get('month')
    let date = momentDate.get('date')
    const START_DATE = momentDate
      .clone()
      .set({ year, month, date, hour: 0, minute: 0, second: 0 })

    // 当前时间距离9点半 minutes
    let minutes = currentMoment.diff(START_DATE, 'minutes')
    if (minutes < 0) {
      minutes = 0
    }
    return {
      minutes
    }
  },

  // 解析时间
  parseMinutes(minutes) {
    // 有多少个不可选择项
    let d = Math.floor(minutes / 30)
    // 取余数
    let c = minutes % 30

    return {
      d,
      c
    }
  },

  getRows(obj) {
    // progress progressDisabled full empty disabled
    // const {
    //   reservation_time_range: { begin, end },
    //   setting_reservation_time
    // } = this.props
    let { meeting: { reservation_time_range = [] } = {} } = this.state
    const currentDay = moment(obj.date).day() ? moment(obj.date).day() : 7
    let reservation_time = reservation_time_range[0]
    if (reservation_time_range.length > 1) {
      reservation_time = reservation_time_range.filter(
        item => item.day == currentDay
      )[0]
    }
    var defaultGrid = {
      state: 'empty',
      width: 0
    }
    var rows = []
    // date
    let minutes = this.getMeetingInitData(obj.date).minutes

    const { d, c } = this.parseMinutes(minutes)

    for (let i = 0; i < 48; i++) {
      var grid = Object.assign({}, defaultGrid)

      if (
        i * 30 < reservation_time.begin ||
        i * 30 >= reservation_time.end
      ) {
        grid.state = 'none'
      } else {
        if (i < d) {
          grid.state = 'disabled'
        }
        if (c > 0 && d + 1 == i) {
          grid.state = 'progressDisabled'
          grid.width = Math.ceil((c / 30) * 33)
        }
      }

      if (reservation_time.nowork) grid.state = 'none'

      grid.id = i
      rows.push(grid)
    }
    return rows
  },

  devicesJsx(devices) {
    if (!devices.length) {
      return (
        <div>
          <i />
        </div>
      )
    }
    let deviceAll = []
    devices.map((device, index) => {
      let deviceMessage = {}
      deviceMessage.class = MEETING_DEVICE[device.id]
      deviceMessage.name = device.name
      deviceMessage.icon = device.icon
      deviceAll.push(deviceMessage)
    })
    return (
      <div className="color-nine" style={{ marginTop: 10 }}>
        {deviceAll.map((device, index) => {
          return (
            <span
              key={index}
              style={{
                marginRight: 10,
                display: 'inline-flex',
                alignItems: 'center'
              }}
            >
              {/*<i className={`iconfont ${device.class}`}/>*/}
              {device.icon ? (
                <img
                  src={device.icon}
                  style={{ marginRight: 5, width: 25 }}
                  alt=""
                />
              ) : null}
              {device.name}
            </span>
          )
        })}
        <i />
      </div>
    )
  },

  renderDateTime() {
    return (
      <div className="timeline-box" style={{ paddingTop: 0 }}>
        {this.reservationsJsx()}
      </div>
    )
  },

  rowsJsx(obj, rows) {
    let { meeting } = this.state
    const initialStartDate = obj.date
    let start_at = initialStartDate.set({ hour: 0, minute: 0, second: 0 })
    let jsx = rows.map((grid, index) => {
      if (grid.state === 'none') {
        return <div key={index} className="time-grid meeting-block_disabled" />
      }
      if (grid.state === 'disabled') {
        return <div key={index} className="time-grid past-tense" />
      }

      if (grid.state === 'progressDisabled') {
        return (
          <div
            key={index}
            className="time-grid"
            onClick={() => {
              this.addMeetingBooking(
                meeting,
                start_at
                  .clone()
                  .add(grid.id * 30, 'minutes')
                  .add(1, 'hours')
                  .startOf('hours')
              )
            }}
          />
        )
      }

      return (
        <div
          key={index}
          className="time-grid"
          onClick={() => {
            this.addMeetingBooking(
              meeting,
              start_at.clone().add(grid.id * 30, 'minutes')
            )
          }}
        />
      )
    })
    return jsx
  },

  addMeetingBooking(meeting, start_at) {
    const { loc_id, isManager, reservation_time_range } = this.props
    let end_at = moment(start_at)
      .clone()
      .add(1, 'hours')
    let END_AT = moment(start_at).set({
      hour: reservation_time_range.end,
      minute: 0,
      second: 0
    })

    if (end_at.diff(END_AT, 'minutes') > 0) {
      end_at = moment(END_AT).clone()
    }
    isManager &&
      KBPopover.show(
        <MeetingBookingForm
          end_at={end_at}
          start_at={start_at}
          reservation_time_range={reservation_time_range}
          initialValues={{
            end_at,
            start_at
          }}
          meeting={meeting}
          loc_id={loc_id}
        />
      )
  },

  parseReservationList(reservations) {
    let data = {}
    reservations.forEach((reservation, index) => {
      let startMoment = moment(reservation.start_at)
      let endMoment = moment(reservation.end_at)

      let ymdStart = startMoment.format('YYYY-MM-DD')
      let ymdEnd = endMoment.format('YYYY-MM-DD')
      // if (ymdStart != ymdEnd) {
      //   return
      // }

      let week = startMoment.format('e')

      if (!data[ymdStart]) {
        data[ymdStart] = {
          ymd: ymdStart,
          date: startMoment,
          md: startMoment.format('MM月DD日'),
          week: WEEK.en[week],
          reservations: reservation.fullType ? [] : [{ ...reservation }]
        }
      } else {
        data[ymdStart].reservations.push(reservation)
      }
    })

    let dateList = Object.values(data)

    return dateList
  },

  hideOtherDropDown(currentIndex, id, reservationList) {
    reservationList.forEach(obj => {
      obj.reservations.forEach((reservation, index) => {
        if (reservation.id == id && index == currentIndex) {
          return
        }

        this.refs['KBRoomDropDwon' + reservation.id + index].hide()
      })
    })
  },

  parseReservations(reservations, obj) {
    const {
      defaultDate,
      reservation_time_range: { begin }
    } = this.props
    const startMoment = obj.date.set({ hour: 0, minute: 0, second: 0 })

    reservations = reservations.map((reservation, index) => {
      let startReservation = moment(reservation.start_at)
      let endReservation = moment(reservation.end_at)
      let minutes = startReservation.diff(startMoment, 'minutes')
      let minutesEnd = endReservation.diff(startReservation, 'minutes')
      const { d, c } = this.parseMinutes(minutes)
      const minutesDate = this.parseMinutes(minutesEnd)

      reservation.left = 33 * (d - begin * 2) + Math.ceil((c / 30) * 33)
      reservation.width =
        minutesDate.d * 33 + Math.ceil((minutesDate.c / 30) * 33)
      return reservation
    })

    return reservations
  },

  showCurrentDropDown(index, meetingIndex, reservation) {
    let meetingStatusClientLeft = this.refs[
      'meetingStatus' + reservation.id + index
    ].getBoundingClientRect().left
    let containerClientLeft = this.refs['container'].getBoundingClientRect()
      .left
    let distanceLeft = meetingStatusClientLeft - containerClientLeft - 30
    let current_reservation = Object.assign(
      {},
      reservation,
      { distanceLeft: distanceLeft },
      { meetingIndex: meetingIndex + 1 }
    )

    this.setState({
      current_reservation,
      isShowMeetingPopup: true
    })
  },

  progressJsx(obj, reservationList, meetingIndex) {
    let reservations = this.parseReservations(
      Object.assign([], obj.reservations),
      obj
    )
    let jsx = reservations.map((reservation, index) => {
      const { left, width } = reservation
      return (
        <div
          key={index}
          className="meeting-status"
          ref={`meetingStatus${reservation.id}${index}`}
          style={{ width, left, backgroundColor: reservation.color, zIndex: 9 }}
          onClick={() => {
            this.showCurrentDropDown(index, meetingIndex, reservation)
          }}
        ></div>
      )
    })

    return jsx
  },

  cancelMeeting(reservation) {
    const { apiCallFailureActions } = this.props
    let id = reservation.id

    KBPopoverConfirm({
      name: '结束会议室预定',
      context: `是否结束当前${reservation.area_name || '会议室'}预定?`,
      callback: () => {
        if (moment(reservation.start_at).diff(new Date(), 'hours', true) >= 0) {
          KBPopover.close()
          apiCallFailureActions({
            status: 'error',
            message: '会议还没开始，不能终止!'
          })
          return
        }

        return api.getOverTimeInfo(id).then(({ json }) => {
          KBPopover.close()
          this.setState({ isShowMeetingPopup: false })
          let terminate_at = json.terminate_at
          let total_amount = json.total_amount
          let end_at = reservation.end_at
          if (!moment(end_at).isSame(terminate_at, 'day')) {
            terminate_at = moment(end_at)
              .clone()
              .set({ hour: 24, minute: 0, second: 0 })
          }

          if (total_amount > 0) {
            this.chooseMeetingMode(
              reservation,
              true,
              terminate_at,
              total_amount
            )
            return
          }

          this.overMeeting(reservation, false, terminate_at)
        })
      }
    })
  },

  cancelBooking(reservation) {
    const { deleteReservationsAction } = this.props
    let id = reservation.id
    KBPopoverConfirm({
      name: '取消会议',
      context: `确定要取消该次会议?`,
      callback: () => {
        return api.deleteReservations(id).then(
          json => {
            deleteReservationsAction.success(json, { id, key: 'reservations' })
            KBPopover.close()
            this.setState({ isShowMeetingPopup: false })
          },
          errors => {
            deleteReservationsAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  // 根据当前周获取 预定列表
  getReservationsOfWeek(obj) {
    const { startDay, endDay } = obj
    let week = []
    for (let i = 0; i < 7; i++) {
      let date = startDay.clone().add(i, 'day')
      let start_at = date.clone().toDate()
      let end_at = date.clone().toDate()
      week.push({
        start_at,
        end_at,
        fullType: 1
      })
    }
    return week
  },

  fullReservations(reservations) {
    const { weekNumber } = this.state
    let week = getWeeks(weekNumber)
    const weekReservations = this.getReservationsOfWeek(week)
    // let reservations = reservations
    var reservationList = Object.assign([], reservations)

    const { startDay, endDay } = week

    return weekReservations.concat(reservationList)
  },

  //计算时间线距离左侧距离
  countLeftDistance() {
    var current_time_left
    var current_time = moment().minute()
    var remainder = current_time - 30
    var hour_count = (moment().hour() - 0) * 2
    if (remainder > 0) {
      current_time = current_time - 30
      remainder = 1
    } else {
      remainder = 0
    }
    current_time_left =
      Math.ceil((current_time / 30) * 33) + remainder * 33 + hour_count * 33
    return current_time_left
  },

  reservationsJsx() {
    const { meeting, reservations } = this.state
    if (!meeting) {
      return null
    }
    //object.assign 深拷贝 获取本周开始日期和结束日期
    /**
     * {
     * startDay: moment,
     * endDay: moment
     * }
     *
     */
    const unexpired_reservations = this.fullReservations(
      Object.assign([], reservations)
    )
    const reservationList = this.parseReservationList(
      Object.assign([], unexpired_reservations)
    )

    /**
     * 此处Obj 始终代表一种数据格式
     * {
     *    ymd          : ymdStart,
     *    date         : startMoment,
     *    md           : startMoment.format('MM月DD日'),
     *    week         : WEEK.en[week],
     *    reservations : [{ ...reservation }]
     * }
     */
    let jsx =
      reservationList &&
      reservationList.map((obj, index) => {
        let rows = this.getRows(obj)
        // console.log(obj,rows,'reservationList');
        return (
          <div className="line-box" key={index}>
            {this.rowsJsx(obj, rows)}
            {this.progressJsx(obj, reservationList, index)}
            {this.progressDisabledJsx(rows)}
          </div>
        )
      })

    return jsx
  },

  //渲染日期
  reservationsDateJsx() {
    const { meeting, reservations } = this.state

    if (!meeting) {
      return null
    }

    const unexpired_reservations = this.fullReservations(
      Object.assign([], reservations)
    )
    const reservationList = this.parseReservationList(
      Object.assign([], unexpired_reservations)
    )
    let jsx =
      reservationList &&
      reservationList.map((obj, index) => {
        let isToday = obj.ymd == moment().format('YYYY-MM-DD') ? true : false
        let current_time_left = this.countLeftDistance()

        return (
          <div className="line-date" key={index}>
            <span className="week" style={isToday ? { color: '#ef7070' } : {}}>
              {obj.week}
            </span>
            <span className="day" style={isToday ? { color: '#ef7070' } : {}}>
              {obj.md}
            </span>
          </div>
        )
      })

    return jsx
  },

  progressDisabledJsx(rows) {
    let jsx = rows.map((grid, index) => {
      if (grid.state == 'progressDisabled') {
        let left = 33 * (index - 1)
        let width = grid.width
        return (
          <div
            key={index}
            className="meeting-status"
            style={{ left, width, backgroundColor: DISABLED_COLOR, opacity: 1 }}
          ></div>
        )
      }

      return null
    })

    return jsx
  },

  updateMeeting() {
    const { meeting } = this.state
    const { loc_id } = this.props
    KBPopover.show(
      <MeetingForm loc_id={loc_id} state="update" meeting={meeting} />
    )
  },

  deleteMeeting() {
    const { meeting } = this.state
    const { deleteMeetingAction, routerActions, loc_id, successAction } = this.props
    KBPopoverConfirm({
      name: '删除会议',
      context: '您是否删除该会议室?',
      callback: () => {
        return api.deleteMeetingRooms(meeting.id).then(
          json => {
            deleteMeetingAction.success(json, {
              id: meeting.id,
              key: 'meetingRoots'
            })
            KBPopover.close()
            successAction({ message: '删除成功' })
            routerActions.push(`/admin/locations/${loc_id}/meeting/manage`)
          },
          errors => {
            deleteMeetingAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  overMeeting(reservation, isMakeInvoice = false, terminate_at) {
    const { successAction, updateReservationsActions } = this.props
    if (!terminate_at) {
      return
    }

    let id = reservation.id
    api
      .putTerminate(id, {
        make_invoice: isMakeInvoice,
        terminate_at
      })
      .then(
        json => {
          updateReservationsActions.success(json)
          successAction({
            message: `成功结束当前会议室预定${
              isMakeInvoice ? '并生成账单' : ''
            }!`
          })
        },
        error => {
          updateReservationsActions.failure(error)
        }
      )
      .finally(() => {
        KBPopover.close()
      })
  },

  chooseMeetingMode(
    reservation,
    isMakeInvoice = false,
    terminate_at,
    total_amount
  ) {
    KBPopover.show(
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">生成账单</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            {`当前预定会议室超时将生成金额为${total_amount}(RMB)的账单，是否生成此账单？`}
          </div>
          <div className="kb-row">
            <div className="kb-form-fr">
              <KBButton
                className="certain-btn"
                onClick={this.overMeeting.bind(
                  null,
                  reservation,
                  isMakeInvoice,
                  terminate_at
                )}
              >
                立即生成账单
              </KBButton>
              <KBButton
                className="certain-btn"
                onClick={this.overMeeting.bind(
                  null,
                  reservation,
                  false,
                  terminate_at
                )}
              >
                取消生成账单
              </KBButton>
            </div>
          </div>
        </div>
      </div>
    )
  },

  fullZero(number) {
    if (number <= 9) {
      return '0' + number
    }
    return number
  },

  //渲染时间段
  meetingRoomsTimeJsx() {
    const { scrollLeft } = this.state
    const {
      reservation_time_range: { begin, end }
    } = this.props
    let times = []
    for (let i = begin; i <= end; i++) {
      let st = this.fullZero(i)
      times.push({
        id: i,
        name: st
      })
    }

    return (
      <div
        style={{
          position: 'absolute',
          zIndex: 10,
          top: -32,
          width: 'calc(100% - 130px)',
          overflow: 'hidden',
          marginLeft: 140,
          height: 30
        }}
      >
        <div
          className="line-time"
          style={{ position: 'absolute', left: -scrollLeft }}
        >
          {times.map(json => {
            return <div className="time-quantum">{json.name}</div>
          })}
        </div>
      </div>
    )
  },

  meetingScroll(e) {
    let scrollLeft = ReactDOM.findDOMNode(this.refs['scroll']).scrollLeft
    this.setState({
      scrollLeft
    })
  },

  initialScrollLeft() {
    if (!this['meetingScroll']) {
      return
    }

    if (moment().get('hour') < 10) {
      ReactDOM.findDOMNode(this.refs['scroll']).scrollLeft = 0
    } else {
      ReactDOM.findDOMNode(this.refs['scroll']).scrollLeft = 640
    }

    this.setState({
      isInitialScroll: false
    })
  },

  closePopup() {
    this.setState({
      isShowMeetingPopup: false
    })
  },
  isshowQrCode() {
    this.setState({
      showQrCode: !this.state.showQrCode
    })
  },
  downloadCodeImg() {
    let filename //图片名称
    let filetype //图片类型
    var path = this.state.weChatCode
    if (path.indexOf('/') > 0) {
      var file = path.substring(path.lastIndexOf('/') + 1, path.length)
      var fileArr = file.toLowerCase().split('.')
      filename = fileArr[0]
      filetype = fileArr[1]
    }
    var canvas = document.createElement('canvas')
    var img = document.createElement('img')
    img.onload = function(e) {
      canvas.width = img.width
      canvas.height = img.height
      var context = canvas.getContext('2d')
      context.drawImage(img, 0, 0, img.width, img.height)
      canvas.toBlob(blob => {
        var a = document.createElement('a')
        //a.href : blob:http://127.0.0.1:5500/fdd7df7a-c953-4a0f-a4ec-8bb9d09056d8
        a.href = window.URL.createObjectURL(blob)
        a.download = filename
        a.click()
      }, `image/${filetype}`)
    }
    img.setAttribute('crossOrigin', 'Anonymous')
    img.src = this.state.weChatCode
  },
  showWeChatCode() {
    const { meeting_id } = this.props
    const { showWeChatQrCode, meeting } = this.state
    if (!showWeChatQrCode) {
      this.setState({ weChatCode: meeting.bridgego_qrcode, showWeChatQrCode: true })
      // api
      //   .getWeChatCode(meeting_id, { source: 'bridgego', width: 1280 })
      //   .then(res => {
      //     const {
      //       json: { url }
      //     } = res
      //     this.setState({ weChatCode: url, showWeChatQrCode: true })
      //   })
    } else {
      this.setState({ showWeChatQrCode: false })
    }
  },
  couponSet() {
    this.setState({ modalVisible: true })
  },
  render() {
    const {
      space_id,
      isManager,
      defaultDate,
      reservation_time_range,
      setting_reservation_time
    } = this.props
    const {
      meeting,
      current_reservation,
      isShowMeetingPopup,
      showQrCode,
      showWeChatQrCode,
      modalVisible
    } = this.state
    if (!meeting) {
      return (
        <section className="nav-section-container  f-right">
          <KBLoadingContainer loading={true} />
        </section>
      )
    }
    const orderOption = meeting && meeting.order_option
    return (
      <section className="nav-section-container  f-right" ref="container">
        <header
          className="nav-header clear-fix"
          style={{ position: 'relative' }}
        >
          <div className="nav-section-header-title">
            <span>{meeting.name}</span>
            {isManager ? (
              <div className="f-right">
                {meeting.price_determined && (
                  <button
                    className="bordered-btn"
                    style={{ marginRight: 18 }}
                    onClick={this.couponSet}
                  >
                    优惠设置
                  </button>
                )}
                <button
                  className="certain-btn"
                  style={{ marginRight: 18 }}
                  onClick={this.showWeChatCode}
                >
                  {showWeChatQrCode ? '收起小程序码' : '显示小程序码'}
                </button>
                <button className="certain-btn" onClick={this.isshowQrCode}>
                  {showQrCode ? '收起二维码' : '显示二维码'}
                </button>
                <button className="bordered-btn" onClick={this.updateMeeting}>
                  编辑
                </button>
                <button
                  className="certain-delete-btn"
                  onClick={this.deleteMeeting}
                >
                  删除
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
          {showWeChatQrCode && (
            <div
              style={{
                width: '180px',
                // height:'140px',
                background:
                  'linear-gradient(360deg, rgb(69, 164, 247) 0%, rgb(200, 231, 255) 100%) ',
                position: 'absolute',
                borderRadius: 4,
                top: 60,
                right: 270,
                zIndex: 1000,
                padding: 10,
                textAlign: 'center'
              }}
            >
              <img
                src={this.state.weChatCode}
                style={{ margin: '10px', width: 140, height: 140 }}
                alt=""
              />
              <button
                className="certain-btn"
                onClick={() => this.downloadCodeImg()}
              >
                下载小程序码
              </button>
            </div>
          )}
          {showQrCode && (
            <div
              style={{
                width: '180px',
                // height:'140px',
                background:
                  'linear-gradient(360deg, rgb(69, 164, 247) 0%, rgb(200, 231, 255) 100%) ',
                position: 'absolute',
                borderRadius: 4,
                top: 60,
                right: 150,
                zIndex: 100000,
                padding: 10
              }}
            >
              <QrCode
                value={`http://t.cn/rph4cci?space_id=${space_id}&meeting_room_id=${meeting.id}`}
                size={1400}
                style={{ margin: '10px', width: 140, height: 140 }}
              />
              <p
                style={{
                  textAlign: 'center',
                  lineHeight: '20px',
                  paddingBottom: 10,
                  color: '#FFF'
                }}
              >
                线下可通过凯德星扫一扫 <br />
                可预订该会议室
              </p>
            </div>
          )}
          {meeting.images && meeting.images.length > 0 ? (
            <div className="meeting-room-imgs">
              {meeting.images.map(img => {
                return <img src={img} />
              })}
            </div>
          ) : null}
          <div className="m-top-sm color-nine">
            <div className="meeting-room-message">
              <span>{meeting.floor} 层楼</span>
            </div>
            <div className="meeting-room-message">
              <i className="iconfont icon-account_circle" />
              <span style={{ marginLeft: 3 }}>{meeting.capacity}人</span>
            </div>
            <div className="meeting-room-message">
              <i className="iconfont icon-close2" />
              <span>{meeting.lock ? '有锁' : '无锁'}</span>
            </div>
            {meeting.size ? (
              <div className="meeting-room-message">
                <span>总面积{meeting.size}平方米</span>
              </div>
            ) : (
              ''
            )}
            {meeting.price_determined ? (
              <div>
                {orderOption === 'by_day' ? (
                  <div className="meeting-room-message">
                    <span>{meeting.day_listing_price} 元/天</span>
                  </div>
                ) : (
                  <div className="meeting-room-message">
                    <span>{meeting.listing_price} 元/小时</span>
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
            {!meeting.price_determined ? (
              <div className="meeting-room-message">
                <span>协商定价</span>
              </div>
            ) : (
              ''
            )}
            {meeting.orientation ? (
              <div className="meeting-room-message">
                <span>朝向: {ORIENTATION_STATES_OBJ[meeting.orientation]}</span>
              </div>
            ) : null}
            {meeting.checkin_date ? (
              <div className="meeting-room-message">
                <span>{formatYearDayCHS(meeting.checkin_date)}起可预订</span>
              </div>
            ) : null}
            {meeting.features ? (
              <div className="meeting-room-message">
                <span>特点: {meeting.features}</span>
              </div>
            ) : null}
          </div>
          {this.devicesJsx(meeting.devices)}
        </header>
        <div className="nav-section-content-container" style={{ padding: 30 }}>
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            {Object.values(current_reservation).length > 0 ? (
              <MeetingPopup
                reservation={current_reservation}
                defaultDate={defaultDate}
                closePopup={this.closePopup}
                topType="info"
                isShowMeetingPopup={isShowMeetingPopup}
                isManager={isManager}
                reservation_time_range={setting_reservation_time}
                cancelMeeting={this.cancelMeeting}
                cancelBooking={this.cancelBooking}
              />
            ) : (
              ''
            )}
            <div className="meeting-title-box" style={{ top: 0 }}>
              {this.reservationsDateJsx()}
            </div>
            {this.meetingRoomsTimeJsx()}
            <div
              ref="scroll"
              style={{
                overflowX: 'auto',
                overflowY: 'hidden',
                marginTop: 15,
                marginLeft: 140,
                position: 'relative'
              }}
              onScroll={this.meetingScroll}
            >
              {this.renderDateTime()}
            </div>
          </div>
        </div>
        {modalVisible && (
          <SettingCoupon
            type="add"
            source_type="meeting"
            close={() => this.setState({ modalVisible: false })}
            visible={modalVisible}
            data={meeting}
          />
        )}
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let locationInfo = selectors.getCurrentLocation(state, loc_id)
  const reservationTimeRange = locationInfo.reservation_time_range
  let reservation_time_range = { begin: 0, end: 24 }
  let setting_reservation_time = (reservationTimeRange &&
    reservationTimeRange.default &&
    Object.keys(reservationTimeRange.default).length &&
    reservationTimeRange.default) || { begin: 0, end: 24 }
  const { user } = state
  let meeting_id = params.meeting_id
  let reservations = Object.assign([], selectors.getReservations(state))
  let meeting = selectors.getMeetingRoomsOfMeetingId(state, meeting_id)
  const time = location.query
    ? moment(location.query.time).toDate()
    : new Date()
  const defaultDate = getInitialDate(time)
  let isManager = canAccessLocation(user, loc_id)
  let space_id = user.space_id

  return {
    loc_id,
    meeting,
    meeting_id,
    reservations,
    isManager,
    defaultDate,
    reservation_time_range,
    setting_reservation_time,
    space_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    deleteMeetingAction: bindActionCreators(meetingsActions.delete, dispatch),
    getMeetingsActions: bindActionCreators(meetingsActions.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    getReservationsAction: bindActionCreators(
      reservationsActions.all,
      dispatch
    ),
    updateReservationsActions: bindActionCreators(
      reservationsActions.update,
      dispatch
    ),
    deleteReservationsAction: bindActionCreators(
      reservationsActions.delete,
      dispatch
    ),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingInfo)
