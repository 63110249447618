import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { KBPopover, KBInput } from 'components'
import { apiCallFailure } from 'app/actions'
import OrgSubscriptionAreasForm from './OrgSubscriptionAreasForm'
import OrgSubscriptionAllotDeskForm from './OrgSubscriptionAllotDeskForm'
import validator from 'validator'

var OrgSubscriptionDeskListPublic = React.createClass({
  getInitialState() {
    return {
      selectArea: {},
      allotType: 'auto',
      selectDesks: [],
      disPrice: false,
      disUnits: false,
      disTotalPrice: false
    }
  },

  selectAreas() {
    const {
      loc_id,
      endDateValue,
      contract_id,
      apiCallFailureActions,
      startDateValue,
      type,
      yetDeskIds
    } = this.props
    if (!endDateValue) {
      return apiCallFailureActions({
        status: 'error',
        message: '请选择终止日期'
      })
    }
    KBPopover.show(
      <OrgSubscriptionAreasForm
        type={type == 'flexible_office' ? 'flexible_office' : 'public'}
        charge_type="by_desk"
        loc_id={loc_id}
        callBack={this.certainArea}
        contract_id={contract_id}
        startDateValue={startDateValue}
        endDateValue={endDateValue}
        yetDeskIds={yetDeskIds}
        isReplace={true}
      />
    )
  },

  certainArea(area) {
    const {
      desk: { area_id, area_name, area_size, desk_ids },
      disposeOfficeAreaName
    } = this.props
    this.setState({
      selectArea: area
    })
    area_id.onChange(area.id)
    area_name.onChange(area.name)
    area_size.onChange(area.free_desks_count)
    desk_ids.onChange('')
    disposeOfficeAreaName && disposeOfficeAreaName()
  },

  priceChange(e) {
    let value = e.target.value
    const {
      desk: { units, rent, totalRent, tenement, price }
    } = this.props
    const { disPrice, disTotalPrice, disUnits } = this.state
    let rentNew = 0
    if (!disPrice) {
      if (value) {
        this.setState({ disTotalPrice: true }, () => {
          if (units.value) {
            rentNew = value * units.value - parseFloat(tenement.value)
            rent.onChange(rentNew)
            const totalRentNew = value * units.value
            totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
          }
        })
      } else {
        this.setState({ disTotalPrice: false, disPrice: false }, () => {
          totalRent.onChange('')
        })
      }
    } else {
      return
    }
  },

  totalPriceChange(e) {
    let value = e.target.value
    // let rentNew = 0
    const {
      desk: { units, rent, totalRent, tenement, price }
    } = this.props
    const { disPrice, disTotalPrice, disUnits } = this.state
    if (!disTotalPrice) {
      if (value) {
        this.setState({ disPrice: true }, () => {
          if (units.value) {
            const newRent = value - parseFloat(tenement.value)
            rent.onChange(newRent)
            const newPrice = value / units.value
            console.log('newPrice:', newPrice, 'units.value:', units.value)
            price.onChange(newPrice)
          }
        })
      } else {
        this.setState({ disTotalPrice: false, disPrice: false }, () => {
          price.onChange('')
        })
      }
    } else {
      return
    }
  },

  unitsChange(e) {
    let value = e.target.value
    const {
      desk: {
        price,
        propertyFee,
        square,
        totalRent,
        tenement,
        rent,
        units,
        area_size
      },
      apiCallFailureActions,
      disposeOfficeAreaName
    } = this.props
    const { disPrice, disTotalPrice, disUnits } = this.state
    let tenementNew = 0
    let rentNew = 0
    let totalRentNew = 0
    if(!validator.isInt(value)){
      return apiCallFailureActions({
        status: 'error',
        message: '请输入整数'
      })
    }
    if (disPrice) {
      if (value && totalRent.value) {
        const newPrice = totalRent.value / value
        price.onChange(parseFloat(newPrice).toFixed(2))
      }
      if (!value) {
        price.onChange('')
      }
    } else if (disTotalPrice) {
      if (value && price.value) {
        rentNew = value * price.value - tenementNew
        totalRentNew = value * price.value
        totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
      }
      if (!value) {
        totalRent.onChange('')
      }
    }
    if (!disTotalPrice && !disPrice && price.value && value) {
      this.setState({ disTotalPrice: true }, () => {
        rentNew = value * price.value - tenementNew
        totalRentNew = value * price.value
        totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
      })
    }
    if (value && square.value && propertyFee.value) {
      tenementNew = square.value * propertyFee.value * value
    }

    tenement.onChange(parseFloat(tenementNew).toFixed(2))

    rent.onChange(rentNew)
    disposeOfficeAreaName && disposeOfficeAreaName()
  },

  perDeskChange(e) {
    const { desk } = this.props
    const { propertyFee, price, units } = desk
    let value = e.target.value
    let tenementNew = 0
    if (value) {
      tenementNew = value * propertyFee.value * desk.units.value
    }
    desk.tenement.onChange(parseFloat(tenementNew).toFixed(2))
    desk.rent.onChange(
      parseFloat(price.value * units.value) - parseFloat(tenementNew)
    )
  },

  perFeeChange(e) {
    const { desk } = this.props
    const { square, rent, totalRent, price, units } = desk
    let value = e.target.value
    let tenement = 0
    if (value) {
      tenement = value * square.value * desk.units.value
    }
    desk.tenement.onChange(parseFloat(tenement).toFixed(2))
    rent.onChange(parseFloat(price.value * units.value) - parseFloat(tenement))
  },

  selectDesks(desks) {
    const {
      desk: { desk_ids, desk_nums },
      disposeOfficeAreaName
    } = this.props
    let deskIds = desks.map(desk => desk.id).join(',')
    let deskNums = desks.map(desk => desk.serial_number).join(',')
    desk_ids.onChange(deskIds)
    desk_nums.onChange(deskNums)
    this.setState({
      selectDesks: desks
    })
    disposeOfficeAreaName && disposeOfficeAreaName()
  },

  editSelectDesks() {
    const {
      desk: { area_id, area_name, units, desk_allot_type },
      loc_id,
      organization_id,
      yetDeskIds,
      endDateValue,
      startDateValue,
      apiCallFailureActions
    } = this.props
    const { selectDesks } = this.state
    if (desk_allot_type.value == 'auto') {
      return
    }
    if (!endDateValue) {
      return apiCallFailureActions({
        status: 'error',
        message: '请选择终止日期'
      })
    }
    KBPopover.show(
      <OrgSubscriptionAllotDeskForm
        loc_id={loc_id}
        type="edit"
        selectDesks={selectDesks}
        area_id={area_id.value}
        area_name={area_name.value}
        startDateValue={startDateValue}
        endDateValue={endDateValue}
        units={units.value}
        callBack={this.selectDesks}
        yetDeskIds={yetDeskIds}
        organization_id={organization_id}
      />
    )
  },

  allotDeskManualChange() {
    const {
      desk: { desk_allot_type, area_id, area_name, units },
      loc_id,
      organization_id,
      yetDeskIds,
      endDateValue,
      startDateValue
    } = this.props
    KBPopover.show(
      <OrgSubscriptionAllotDeskForm
        loc_id={loc_id}
        area_id={area_id.value}
        area_name={area_name.value}
        startDateValue={startDateValue}
        endDateValue={endDateValue}
        units={units.value}
        yetDeskIds={yetDeskIds}
        callBack={this.selectDesks}
        organization_id={organization_id}
      />
    )
    desk_allot_type.onChange('manual')
  },

  allotDeskAutoChange() {
    const {
      desk: { desk_allot_type, desk_ids, desk_nums }
    } = this.props

    desk_allot_type.onChange('auto')
    desk_ids.onChange('')
    desk_nums.onChange('')
  },

  render() {
    const {
      desk,
      deskList,
      index,
      calculationDeposit,
      locationSetting: { house_fee_per_m2 },
      deleteCallBack,
      type
    } = this.props
    const { allotType, disPrice, disTotalPrice, disUnits } = this.state
    return (
      <li style={{ padding: '0 30px 30px 0' }}>
        <i
          className="iconfont icon-close"
          onClick={() => {
            deskList.removeField(index)
            deleteCallBack && deleteCallBack()
          }}
          style={{ top: 10, right: 0 }}
        />
        <div className="kb-row kb-form-210">
          <div
            className="select-area-btn"
            onClick={this.selectAreas}
            onMouseEnter={e => {
              this.setState({ showReplaceText: true })
              e.stopPropagation()
            }}
            onMouseLeave={e => {
              this.setState({ showReplaceText: false })
              e.stopPropagation()
            }}
          >
            <span>
              {this.state.showReplaceText
                ? '重新选择'
                : desk.area_id.value
                ? desk.area_name.value
                : '选择分区'}
            </span>
          </div>
          <div
            className="kb-form-66 clear-fix f-left"
            style={{ paddingLeft: 30 }}
          >
            <div className="kb-form-group kb-div-c39">
              <label className="label-rt">
                <span className="must-fillR">*</span>工位数量
              </label>
              <div>
                <input
                  className={`kb-input input-num ${
                    disUnits ? 'kb-input-disabled' : ''
                  }`}
                  disabled={disUnits}
                  style={{ backgroundColor: '#fff' }}
                  {...desk.units}
                  onChange={event => {
                    calculationDeposit(event, desk.units)
                    this.unitsChange(event)
                  }}
                />
                {desk.units.touched && desk.units.error && (
                  <p className="lr-error"> {desk.units.error} </p>
                )}
              </div>
            </div>
            <div className="kb-form-group kb-div-c39">
              <label className="label-rt">工位价格</label>
              <div>
                <input
                  className={`input-dollar-moth kb-input ${
                    disPrice ? 'kb-input-disabled' : ''
                  }`}
                  disabled={disPrice}
                  {...desk.price}
                  onChange={event => {
                    calculationDeposit(event, desk.price)
                    this.priceChange(event)
                  }}
                />
                {desk.price.touched && desk.price.error && (
                  <p className="lr-error"> {desk.price.error} </p>
                )}
                {parseFloat(desk.price.value) <
                  parseFloat(desk.lowest_price.value) && (
                  <p className="lr-warning">低于底价，需要审批</p>
                )}
                {disTotalPrice&&<p style={{color: '#fd9a18', fontSize:12}}>清空后修改【总会员费】</p>}
              </div>
            </div>
          </div>
        </div>
        {desk.area_id.touched && desk.area_id.error && (
          <p style={{ marginBottom: 20, color: '#e03641' }}>请选择分区</p>
        )}
        <div
          className="rent-box border-none"
          style={{ backgroundColor: '#fbfcfc' }}
        >
          <div className="total-rent">
            <div>
              <span style={{ marginRight: 38, color: '#323232' }}>
                总会员费:
              </span>
              <input
                className={`input-dollar-moth totalRent-input ${
                  disTotalPrice ? 'kb-input-disabled' : ''
                }`}
                {...desk.totalRent}
                disabled={disTotalPrice}
                style={{ color: '#d72431', textIndent: '11px', height: 36 }}
                onChange={event => {
                  calculationDeposit(event, desk.totalRent)
                  this.totalPriceChange(event)
                }}
              />
              {disPrice&&<p style={{color: '#fd9a18', fontSize:12}}>清空后修改【工位价格】</p>}
            </div>
          </div>
          {!house_fee_per_m2 || house_fee_per_m2 == 0 ? (
            ''
          ) : (
            <div style={{ paddingLeft: 15 }}>
              <div className="color-nine" style={{ marginTop: 15 }}>
                以月为单位填写物业费与会员费
              </div>
              <div className="rent-list">
                <span style={{ width: 50, marginRight: 35 }}>会员费:</span>
                <span>¥ {parseFloat(desk.rent.value).toFixed(2)}</span>
              </div>
              <div className="rent-list">
                <span style={{ width: 50, marginRight: 35 }}>物业费:</span>
                <span style={{ minWidth: 90 }}>
                  ¥ {parseFloat(desk.tenement.value).toFixed(2)}
                </span>
                <div style={{ position: 'relative' }}>
                  <input
                    {...desk.square}
                    onChange={event => {
                      calculationDeposit(event, desk.square)
                      this.perDeskChange(event)
                    }}
                    className="per-desk-input"
                  />
                  <span className="per-desk-unit">平米/工位</span>
                </div>
                <span
                  style={{ marginLeft: 20, marginRight: 20, color: '#999' }}
                >
                  X
                </span>
                <div style={{ position: 'relative' }}>
                  <input
                    {...desk.propertyFee}
                    onChange={event => {
                      calculationDeposit(event, desk.propertyFee)
                      this.perFeeChange(event)
                    }}
                    className="per-free-input"
                  />
                  <span className="per-free-unit">物业费/平米</span>
                </div>
                <span
                  style={{ marginLeft: 20, marginRight: 20, color: '#999' }}
                >
                  X
                </span>
                <span>{desk.units.value || 0}</span>
              </div>
            </div>
          )}
          {desk.units.value &&
          desk.units.value != '0' &&
          type !== 'flexible_office' ? (
            <div className="allot-desk">
              <div style={{ marginRight: 22, color: '#323232' }}>选择工位:</div>
              <div>
                <div className="allot-type">
                  <input
                    type="radio"
                    value="auto"
                    {...desk.desk_allot_type}
                    checked={
                      desk.desk_allot_type.value == 'auto' ? 'checked' : ''
                    }
                    id={`auto-radio${index}`}
                    name={`radio-${index}`}
                    onChange={this.allotDeskAutoChange}
                  />
                  <label
                    htmlFor={`auto-radio${index}`}
                    style={{ marginLeft: 10 }}
                  >
                    自动分配工位
                  </label>
                </div>
                <div className="allot-type">
                  <div>
                    <input
                      type="radio"
                      value="manual"
                      checked={
                        desk.desk_allot_type.value == 'manual' ? 'checked' : ''
                      }
                      id={`manual-radio${index}`}
                      name={`radio-${index}`}
                      onChange={this.allotDeskManualChange}
                    />
                    <label
                      htmlFor={`manual-radio${index}`}
                      style={{ marginLeft: 10 }}
                    >
                      手动分配工位
                    </label>
                  </div>
                  {desk.desk_nums.value ? (
                    <div className="desk-num">
                      <span
                        className="m-right-sm"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        已分配工位号:
                      </span>
                      <div
                        className="m-right-sm"
                        style={{ maxWidth: '580px', wordBreak: 'break-all' }}
                      >
                        {desk.desk_nums.value}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    className={`contract-edit-desk-btn ${
                      desk.desk_allot_type.value == 'manual'
                        ? ''
                        : 'contract-edit-desk-btn_disabled'
                    }`}
                    onClick={() => {
                      this.editSelectDesks()
                    }}
                  >
                    修改
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </li>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionDeskListPublic)
