import React, { Component, PropTypes } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { isListValue, getListFirstValue } from 'utils/kbUtil'

const menu = [
  {
    name: '车位管理',
    value: ['manage', 1]
  },
  {
    name: '车位合同',
    value: ['contract', 'contract_new', 2]
  }
  //   {
  //     name  : '预订记录',
  //     value : ['reserve', 3]
  //   }
]

var ParkingBox = React.createClass({
  render() {
    const { section, locationSection, location_id } = this.props
    let loc_url = location_id ? `/locations/${location_id}` : ''

    return (
      <div
        className="kb-out-box clear-fix"
        style={location_id ? { paddingTop: 0 } : {}}
      >
        <div className="sub-nav-bar" style={{ minWidth: 1450 }}>
          <div style={{ position: 'relative' }}>
            <div className="sub-nav-list clear-fix">
              {menu.map((data, index) => {
                return (
                  <Link
                    key={index}
                    className={
                      isListValue(data.value, section) ||
                      (location_id && isListValue(data.value, locationSection))
                        ? 'active'
                        : ''
                    }
                    to={`/admin${loc_url}/parking/${getListFirstValue(
                      data.value
                    )}`}
                  >
                    {' '}
                    {data.name}{' '}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>

        <div className="margin-top-lg">
          <div className="clear-fix" style={{ height: '100%' }}>
            <section className="kb-content-t">{this.props.children}</section>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { params } = props
  let sections = props.location.pathname.split('/')
  let section = sections.length > 3 ? sections[3] : ''
  let locationSection = sections.length > 5 ? sections[5] : ''
  let location_id = params && params.id

  return {
    section,
    locationSection,
    user,
    location_id
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingBox)
