import React from 'react'
import { bindActionCreators } from 'redux'
import { apiCallFailure } from 'app/actions'
import { reduxForm } from 'redux-form'
import { DAY_TIME_HM } from 'app/constants'
import { api, apiUtils } from 'app/services'
import { getServiceErrorMessage } from 'utils/kbUtil'
import {
  KBPopover,
  KBUserInput,
  KBFormError,
  KBButton,
  KBDateInput,
  KBSelect,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import moment from 'moment'
import { EXPORT_HINT } from 'app/constants/hint'


var ExportLockEventsForm = React.createClass({
  exportLockEvents(model) {
    delete model.startHM
    delete model.endHM
    delete model.startYMD
    delete model.endYMD

    model.location_id = this.props.location_id
    model.start_at = moment(model.start_at).format('YYYY-MM-DD HH:mm')
    model.end_at = moment(model.end_at).format('YYYY-MM-DD HH:mm')

    return api.lockEventsReport(model).then(
      () => {
        KBPopover.close()
        KBPopoverTop.show(
          <KBToReportCenter
            title="门禁权限导出"
            message="门禁权限列表已成功导出，可以前往报表中心下载"
          />
        )
      },
      error => {
        this.props.apiCallFailureActions({
          status: 'error',
          message: getServiceErrorMessage(error)
        })
      }
    )
  },

  changeStartYMDCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setYMD(start_at, data)
  },

  changeStartHMCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setHM(start_at, data)
  },

  changeEndYMDCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setYMD(end_at, data)
  },

  changeEndHMCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setHM(end_at, data)
  },

  setYMD(field, value) {
    let date = moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  setHM(field, value) {
    let t = value.split(',')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ hour: t[0], minute: t[1] == '1' ? 0 : 30, second: 0 })
        .toDate()
    )
  },

  getDisabledDays(date) {
    // 最近一个月
    const earliest = moment().subtract(1, 'month')
    const day = moment(date)
    const today = moment()
    return !day.isBetween(earliest, today)
  },

  render() {
    const {
      fields: { startHM, endHM, startYMD, endYMD },
      submitting,
      handleSubmit,
      DEAL_DAY_TIME_HM
    } = this.props
    const halfClass = {
      className: 'kb-form-group kb-div-c48'
    }
    return (
      <form onSubmit={handleSubmit(this.exportLockEvents)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">导出门禁记录</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="开始日期"
              isRequired={true}
              className="kb-form-group kb-div-48"
              field={startYMD}
              disabledDays={this.getDisabledDays}
              onChange={this.changeStartYMDCallback}
            />
            <KBSelect
              title="开始时间"
              onChange={this.changeStartHMCallback}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
              field={startHM}
              {...halfClass}
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="结束日期"
              isRequired={true}
              className="kb-form-group kb-div-48"
              field={endYMD}
              disabledDays={this.getDisabledDays}
              onChange={this.changeEndYMDCallback}
            />
            <KBSelect
              title="结束时间"
              onChange={this.changeEndHMCallback}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
              field={endHM}
              {...halfClass}
            />
          </div>
          <p style={{fontSize: '12px', color: '#7F8FA4'}}>{EXPORT_HINT.concat_admin}</p>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(0, 24)
  return {
    DEAL_DAY_TIME_HM
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (
    values.start_at &&
    values.end_at &&
    moment(values.end_at).diff(moment(values.start_at)) <= 0
  ) {
    errors.endHM = '结束日期必须大于开始日期'
  }

  return errors
}

const formConfig = {
  form: 'ExportLockEventsForm',
  fields: ['start_at', 'end_at', 'startHM', 'endHM', 'startYMD', 'endYMD'],
  validate: validate,
  touchOnBlur: false
}

ExportLockEventsForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ExportLockEventsForm)

export default ExportLockEventsForm
