import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { subscriptionActions } from 'app/actions'
import { getSpaceVirtualCurrencyName } from 'app/selectors'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBPopoverTop
} from 'components'

var SpaceAddMemberShipDiscountForm = React.createClass({
  getInitialState() {
    return {
      isLoading: false
    }
  },
  parseDiscountForm(model) {
    const { callback, isTop } = this.props
    this.setState({
      isLoading: true
    })
    callback && callback(model)
    if (isTop) {
      return KBPopoverTop.close()
    }
    KBPopover.close()
  },

  componentWillUnmount() {
    this.setState({
      isLoading: false
    })
  },

  render() {
    const {
      fields: {
        number,
        price,
        complimentary_number,
        point,
        complimentary_point
      },
      error,
      submitting,
      handleSubmit,
      isTop,
      virtual_currency_name,
      quota_type
    } = this.props
    const { isLoading } = this.state

    const quotaType = {
      desk: 'input-frequency',
      booking: 'input-hour',
      printing: 'input-sheet'
    }

    return (
      <form onSubmit={handleSubmit(this.parseDiscountForm)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-header">
          <h2 className="header-title">添加折扣信息</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={isTop ? KBPopoverTop.close : KBPopover.close}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              title="每满"
              type="number"
              isRequired={true}
              label="true"
              className="kb-form-group kb-form-c18 kb-form-alone"
              inputClass={quotaType[quota_type]}
              field={number}
              value={number.value}
            />
          </div>
          {/* <div className='kb-row'>
            <KBInput title='价格' isRequired = {true} label='true' placeholder='价格是多少' className = 'kb-form-group kb-form-c18 kb-form-alone' field={ price } value={ price.value } />
          </div> */}
          {/* <div className='kb-row'>
            <KBInput title={`${virtual_currency_name}购买`} isRequired = {true} label='true' placeholder={`用多少${virtual_currency_name}购买，默认是价格*100`} className = 'kb-form-group kb-form-c18 kb-form-alone' field={ point } value={ point.value } />
          </div> */}
          <div className="kb-row">
            <KBInput
              title="赠送"
              type="number"
              isRequired={true}
              label="true"
              className="kb-form-group kb-form-c18 kb-form-alone"
              inputClass={quotaType[quota_type]}
              field={complimentary_number}
              value={complimentary_number.value}
            />
          </div>
          {/* <div className='kb-row'>
            <KBInput title={ virtual_currency_name } isRequired = {true} label='true' placeholder={`可获得的${virtual_currency_name}`} className = 'kb-form-group kb-form-c18 kb-form-alone' field={ complimentary_point } value={ complimentary_point.value } />
          </div> */}
          <div className="f-right">
            <span
              className="cancel-btn"
              onClick={isTop ? KBPopoverTop.close : KBPopover.close}
            >
              取消
            </span>
            <button className="certain-btn m-left-sm" type="submit">
              {isLoading ? '加载中...' : '添加'}
            </button>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  let virtual_currency_name = getSpaceVirtualCurrencyName(state)
  return {
    virtual_currency_name,
    initialValues: {
      // recurring_amount  : parseFloat(total).toFixed(2) || 0,
      // start_date,
      // end_date,
      // recurring_cycles  : 1,
      // recurring_due_day : 31
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

SpaceAddMemberShipDiscountForm.propTypes = {
  isTop: React.PropTypes.bool
}

const validate = values => {
  const errors = {}

  // if (valid.isPrice(values.recurring_amount)) {
  //   errors.recurring_amount = '请输入正确的价格, 小数点保留2位'
  // }

  return errors
}

const formConfig = {
  form: 'SpaceAddMemberShipDiscountForm',
  fields: [
    'number',
    'price',
    'point',
    'complimentary_number',
    'complimentary_point'
  ],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceAddMemberShipDiscountForm)
