import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { lockPermissionsActions, apiCallFailure } from 'app/actions'
import { api } from 'app/services'
import { KBPopover, KBPopoverTop, KBFormError, KBButton } from 'components'
import { formatMinuteSecondCHS } from 'utils/kbUtil'

var LockPermissionCertainForm = React.createClass({
  createLockForm() {
    const {
      lock_permissions,
      loc_id,
      createLocksPermissionsAction,
      apiCallFailureActions
    } = this.props
    return api.createLockPermissions(loc_id, { lock_permissions }).then(
      json => {
        createLocksPermissionsAction.success(json)
        KBPopoverTop.close()
        KBPopover.close()
        return true
      },
      error => {
        createLocksPermissionsAction.failure(error)
      }
    )
  },

  render() {
    const {
      submitting,
      handleSubmit,
      locks,
      lockGroups,
      members,
      start_at,
      end_at
    } = this.props
    const start = formatMinuteSecondCHS(start_at.value)
    const end = formatMinuteSecondCHS(end_at.value)
    return (
      <form onSubmit={handleSubmit(this.createLockForm)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">门禁权限确认</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopoverTop.close}
          />
        </header>
        <div className="kb-form-container">
          <p className="lock_title">您确定在以下时间段</p>
          <div className="date">
            <p>
              开始时间:<div>{start}</div>
            </p>
            <p>
              结束时间:<div>{end}</div>
            </p>
          </div>

          <p className="lock_title">将以下会员</p>
          <div className="member" style={{ overflowY: 'auto' }}>
            {members.map(orgName => {
              return (
                <div className="lock_member">
                  <span style={{ display: 'block', padding: 15 }}>
                    {orgName.name}
                  </span>
                  {orgName.childrens.map(userName => {
                    return (
                      <div className="member_name">
                        <div>
                          <p></p>
                          <span className="hidden">{userName.name}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
          {lockGroups.length > 0 ? (
            <div>
              <p className="lock_title">赋予以下门禁组权限</p>
              <div className="member lock" style={{ overflowY: 'auto' }}>
                {lockGroups.map(lock_group => {
                  return (
                    <div className="lock_member">
                      <span style={{ display: 'block', padding: 15 }}>
                        {lock_group.name}
                      </span>
                      {lock_group.childrens.map(lock_c => {
                        return (
                          <div className="member_name">
                            <div>{lock_c.name}</div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}
          {locks.length > 0 ? (
            <div>
              <p className="lock_title">赋予以下门禁权限</p>
              <div className="member lock" style={{ overflowY: 'auto' }}>
                {locks.map(lock => {
                  return (
                    <div className="lock_member">
                      <span style={{ display: 'block', padding: 15 }}>
                        {lock.name}
                      </span>
                      {lock.childrens.map(lock_c => {
                        return (
                          <div className="member_name">
                            <div>{lock_c.name}</div>
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopoverTop.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { members } = props
  let memberName = []
  members.map(member => {
    member.childrens.map(child => {
      memberName.push({
        user_name: child.name
      })
    })
  })
  return {
    memberName
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.create,
      dispatch
    ),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  return errors
}

const formConfig = {
  form: 'LockPermissionCertainForm',
  fields: [''],
  validate: validate,
  touchOnBlur: false
}

LockPermissionCertainForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LockPermissionCertainForm)

export default LockPermissionCertainForm
