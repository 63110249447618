import React from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import {
  getSpace,
  getSpaceSetting,
  getCurrentUser,
  getSpaceVirtualCurrencyName,
  getRoleSetting
} from 'app/selectors'
import {
  spacesSettingAction,
  spacesActions,
  successState,
  spaceRoleAction,
  memberActions
} from 'app/actions'
import { canSpaceAdmin } from 'app/reducers/role'
import { util } from 'utils/kbUtil'

var LocationSettings = React.createClass({
  getInitialState() {
    return {
      loading: false,
      setting: null
    }
  },
  updateCurrentSpace(model, { key }) {
    const { user } = this.props
    const { updateCurrentSpaceAction, successAction } = this.props
    return api
      .putSpaceSetting({
        ...model,
        target_type: 'Space',
        target_id: user.space
      })
      .then(json => {
        successAction({ message: '保存成功!' })
        updateCurrentSpaceAction.success(json, { key })
      })
  },
  updateSpace(model) {
    const { updateSpaceAction, successAction } = this.props
    return api.setSpace(model).then(json => {
      successAction({ message: '保存成功!' })
      updateSpaceAction.success(json)
    })
  },
  updateSpaceRole({ role, range = 'admin', user_ids }) {
    const { updateSpaceRoleAction, successAction } = this.props
    return api
      .postSpaceRoles({
        details: [
          {
            role,
            range,
            user_ids
          }
        ]
      })
      .then(json => {
        successAction({ message: '保存成功!' })
        updateSpaceRoleAction.success(json, { key: role })
      })
  },

  updatePayMode(mode) {
    const { updateCurrentSpaceAction } = this.props
    api.payModeSetting({ pay_mode: mode }).then(
      json => {
        updateCurrentSpaceAction.success(json)
      },
      errors => {
        updateCurrentSpaceAction.failure(errors)
      }
    )
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentDidMount() {
    const {
      getSpaceAction,
      getSpaceSettingAction,
      getSpaceRoleAction
    } = this.props
    this.setState({
      loading: true
    })
    this.mounted = true

    api.getSpace().then(
      json => {
        getSpaceAction.success(json)
      },
      errors => {
        getSpaceAction.failure(errors)
      }
    )

    api
      .getSpaceSetting({
        inherit: true
      })
      .then(
        json => {
          getSpaceSettingAction.success(json)
        },
        errors => {
          getSpaceSettingAction.success(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })

    api.getSpaceRoles().then(
      json => {
        getSpaceRoleAction.success(json)
      },
      errors => {
        getSpaceRoleAction.failure(errors)
      }
    )

    api.getMembers({ per_page: 1000 }).then(
      ({ json }) => {
        this.props.getMembersAction.success(json, { entityName: 'members' })
      },
      errors => {
        this.props.getMembersAction.failure(errors)
      }
    )
  },
  render() {
    const {
      section,
      space,
      settings,
      user,
      space_id,
      lock_settings,
      virtual_currency_name,
      spaceRole,
      menu
    } = this.props
    const { loading } = this.state
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, {
        space_id,
        virtual_currency_name,
        updateCurrentSpace: this.updateCurrentSpace,
        updateSpaceRole: this.updateSpaceRole,
        updatePayMode: this.updatePayMode,
        spaceRole,
        updateSpace: this.updateSpace,
        space,
        setting: settings,
        lock_settings: lock_settings
      })
    )

    return (
      <div className="kb-out-box">
        <section className="kb-content">
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">空间设置</h2>
              </header>
              <div className="nav-body" style={{ maxHeight: 650 }}>
                <ul className="nav-container-list">
                  {menu.map((json, index) => {
                    if (json.roles && !canSpaceAdmin(user, json.roles)) {
                      return
                    }
                    return (
                      <li
                        key={index}
                        className={
                          json.value == section
                            ? 'nav-container-list-hover select-list-hover'
                            : ''
                        }
                      >
                        <Link to={`/admin/spaces_setting/${json.value}`}>
                          {json.name}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          <section className="nav-section-container f-right">
            <KBLoadingContainer loading={loading}>
              {childrenWithProps}
            </KBLoadingContainer>
          </section>
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let sections = location.pathname.split('/')
  let section = sections[3] || ''
  let spaceSeeting = getSpaceSetting(state)
  let spaceRole = getRoleSetting(state)
  let space = getSpace(state)
  let user = getCurrentUser(state)
  let space_id = Object.keys(state.entities.spaces || {})[0]
  let virtual_currency_name = getSpaceVirtualCurrencyName(state)
  let menu = [
    {
      name: '基本设置',
      value: ''
    },
    {
      name: '安全设置',
      value: 'security'
    },
    {
      name: 'CRM设置',
      value: 'crm'
    },
    {
      name: '财务设置',
      value: 'invoice'
    },
    // {
    //   name  : '会议室设置',
    //   value : 'meeting'
    // },
    {
      name: '虚拟货币设置',
      value: 'currency'
    },
    {
      name: '待办事项设置',
      value: 'options'
    },
    {
      name: '服务设置',
      value: 'service'
    },
    {
      name: '合同模板设置',
      value: 'templete'
    },
    // {
    //   name  : '邮件模板设置',
    //   value : 'templete_email'
    // },
    // {
    //   name  : '微信小程序设置',
    //   value : 'wechat'
    // },
    // {
    //   name  : '分店组设置',
    //   value : 'location_groups'
    // },
    {
      name: '操作日志',
      value: 'logs'
    }
  ]
  const { space_roles } = user
  if (
    space_roles &&
    Array.isArray(space_roles) &&
    space_roles.length === 1 &&
    space_roles[0].role === 'location_group_wechat_manager'
  ) {
    menu = [menu[0]]
  }
  var params = {
    space,
    space_id,
    user,
    settings: {},
    section,
    spaceRole,
    spaceSeeting,
    virtual_currency_name,
    menu
  }

  if (!spaceSeeting) {
    return params
  }

  params.settings = spaceSeeting
  params.lock_settings = spaceSeeting.lock_settings

  return params
}

function mapDispatchToProps(dispatch) {
  return {
    updateCurrentSpaceAction: bindActionCreators(
      spacesSettingAction.update,
      dispatch
    ),
    updateSpaceRoleAction: bindActionCreators(spaceRoleAction.update, dispatch),
    updateSpaceAction: bindActionCreators(spacesActions.update, dispatch),
    getSpaceAction: bindActionCreators(spacesActions.get, dispatch),
    getSpaceRoleAction: bindActionCreators(spaceRoleAction.get, dispatch),
    getSpaceSettingAction: bindActionCreators(
      spacesSettingAction.get,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch),
    getMembersAction: bindActionCreators(memberActions.replace, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSettings)
