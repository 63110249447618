import React from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { KBLoadingContainer, KBPopover, KBNoData, KBButton } from 'components'
import { connect } from 'react-redux'
import { lockersAction, apiCallFailure } from 'app/actions'
import { Popover, List, Card } from 'antd'
import * as selectors from 'app/selectors'
import intl from 'react-intl-universal'
import _ from 'lodash'
import { KBPopoverConfirm } from 'components/tools'
import {
  getEntitiesJsonArray,
  getServiceErrorMessage,
  formatHoursMinutesCHS
} from 'utils/kbUtil'
import LightForm from './LightForm'

var Light = React.createClass({
  getInitialState() {
    return {
      loading: true,
      currentAssIndex: 0,
      currentAss: null,
      currentLights: [],
      lights: [],
      assetable: []
    }
  },

  componentDidMount() {
    this.loadData()
  },

  loadData() {
    const { location_id } = this.props
    const { currentAssIndex } = this.state
    this.setState(
      {
        loading: true
      },
      () => {
        api
          .getSensors({
            location_id,
            sensor_type: 'kb_faraday_actuator',
            per_page: 1000
          })
          .then(
            json => {
              const lights = _.get(json, 'json', [])
              const assetable = _.uniqBy(
                _.flatMap(lights, o => o.assetable),
                'id'
              )
              const currentLights = _.filter(lights, o => {
                return (
                  _.get(o, 'assetable.id') ===
                  _.get(assetable, `[${currentAssIndex}].id`)
                )
              })
              this.setState({
                currentLights,
                lights,
                assetable,
                currentAss: assetable[currentAssIndex]
              })
            },
            error => { }
          )
          .finally(() => {
            this.setState({
              loading: false
            })
          })
      }
    )
  },

  editLight(light) {
    let turn_on_time = _.get(light, 'auto_switch_settings.turn_on_time', null)
    let turn_off_time = _.get(light, 'auto_switch_settings.turn_off_time', null)
    if (_.isNil(turn_on_time)) {
      turn_on_time = '00:00'
    } else {
      const onhour = parseInt(Number(turn_on_time) / 60)
      const onmin = Number(turn_on_time) - onhour * 60
      turn_on_time = onhour + ':' + onmin
    }
    if (_.isNil(turn_off_time)) {
      turn_off_time = '00:00'
    } else {
      const offhour = parseInt(Number(turn_off_time) / 60)
      const offmin = Number(turn_off_time) - offhour * 60
      turn_off_time = offhour + ':' + offmin
    }
    const weekly_days = _.get(light, 'auto_switch_settings.weekly_days')
    KBPopover.show(
      <LightForm
        initialValues={{
          name: light.name,
          status: light.status,
          auto_switch: light.auto_switch,
          turn_on_time,
          turn_off_time,
          pwr: _.get(light, 'last_state.pwr', '0')
        }}
        weekly_days={weekly_days}
        id={light.id}
        cabinet={light}
        callBack={this.loadData}
      />
    )
  },

  switchLight(locker, index) {
    const { assetable, lights, currentAss } = this.state
    const currentLights = _.filter(lights, o => {
      return _.get(o, 'assetable.id') === _.get(locker, 'id')
    })
    this.setState({
      currentAssIndex: index,
      currentAss: locker,
      currentLights
    })
  },

  renderLight(item) {
    const state = _.get(item, 'last_state.pwr', '0')
    if (_.isNull(state)) state = '0'
    return (
      <div className="light-box" onClick={() => this.editLight(item)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1
          }}
        >
          <div className={`light-status light-status-${state}`}></div>
          <div className="light-name">{_.get(item, 'name', '')}</div>
          <div className="assetable-name">
            {_.get(item, 'assetable.name', '')}
          </div>
        </div>
        <div className="light-heartbeat"></div>
      </div>
    )
  },

  render() {
    const { loading, currentAss, currentLights, assetable } = this.state

    return (
      <section
        className="kb-content clear-fix"
        style={{ height: '100%', paddingTop: 0 }}
      >
        <nav className="nav-container f-left">
          <div className="nav-container-content">
            <KBLoadingContainer loading={loading}>
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">管理</h2>
              </header>
              <div className="nav-body" style={{ padding: '20px 0' }}>
                <ul className="nav-container-list">
                  {assetable && assetable.length > 0 ? (
                    assetable.map((light, index) => {
                      if (light) {
                        return (
                          <li
                            className={
                              currentAss && currentAss.id == light.id
                                ? 'nav-container-list-hover'
                                : ''
                            }
                            onClick={() => {
                              this.switchLight(light, index)
                            }}
                          >
                            <a>{light.name || '-'}</a>
                          </li>
                        )
                      }
                    })
                  ) : (
                      <div className="flex-center">
                        <KBNoData tipMessage="暂无数据" imgWidth={120} />
                      </div>
                    )}
                </ul>
              </div>
            </KBLoadingContainer>
          </div>
        </nav>
        <section className="nav-section-container f-right">
          <KBLoadingContainer loading={loading}>
            <header className="nav-header">
              <h2 className="nav-section-header-title f-left">
                {(currentAss && currentAss.name) || '管理'}
              </h2>
            </header>
            <div
              className="nav-section-content-container"
              style={{ paddingTop: 40 }}
            >
              {currentLights && currentLights.length > 0 ? (
                <List
                  grid={{ gutter: 16, column: 6 }}
                  dataSource={currentLights}
                  renderItem={item => (
                    <List.Item>{this.renderLight(item)}</List.Item>
                  )}
                />
              ) : (
                  <div className="flex-center" style={{ paddingTop: 40 }}>
                    <KBNoData tipMessage="暂无数据" />
                  </div>
                )}
            </div>
          </KBLoadingContainer>
        </section>
      </section>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const user = state.user
  const { params } = ownProps
  const location_id = params.id
  const LIGHT_STATUS = [
    {
      id: 'online',
      name: '在线'
    },
    {
      id: 'offline',
      name: '离线'
    },
    {
      id: 'expired',
      name: '长期离线'
    },
    {
      id: 'never_reported',
      name: '未上报'
    }
  ]
  return {
    LIGHT_STATUS,
    location_id,
    user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLockersAction: bindActionCreators(lockersAction.replace, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Light)
