import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { newsAction, successState } from 'app/actions'
import {
  util,
  preventDefaultPropagation,
  formatHoursMinutesPON
} from 'utils/kbUtil'
import { NEWS_STATUS_OBJ } from 'app/constants'
import {
  KBLoadingContainer,
  KBStatisticNumber,
  KBAvatar,
  KBTipsy,
  KBPopover
} from 'components'
import * as selectors from 'app/selectors'
import MobilePreview from '../activities/MobilePreview'
import AnnouncementForm from '../announcements/AnnouncementForm'

var NewsDetail = React.createClass({
  displayName: 'NewsDetail',

  getInitialState() {
    return {
      loading: true
    }
  },

  componentDidMount() {
    const { news_id, getNewsAction } = this.props
    api
      .getNewsInfo(news_id)
      .then(
        json => {
          getNewsAction.success(json)
        },
        error => {
          getNewsAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  toNewsList() {
    const { routerActions, loc_id } = this.props
    routerActions.push(`/admin/memberships/memberManage/news`)
  },

  toEditNews() {
    const { routerActions, loc_id, news } = this.props
    routerActions.push(
      `/admin/memberships/memberManage/news/edit?news_id=${news.id}`
    )
  },

  deleteNews(model) {
    const {
      deleteNewsAction,
      routerActions,
      successActions,
      loc_id
    } = this.props
    const { id } = model
    KBPopover.plugins.confirm('删除资讯', '确定删除该资讯吗？', {
      confirm: () => {
        api.deleteNews(id).then(
          json => {
            deleteNewsAction.success(json)
            successActions({ message: `成功删除资讯:${model.title}` })
            KBPopover.close()
            if (loc_id) {
              routerActions.push(`/admin/locations/${loc_id}/news`)
            } else {
              routerActions.push(`/admin/memberships/memberManage/news`)
            }
          },
          error => {
            deleteNewsAction.failure(error)
          }
        )
      }
    })
  },

  toPublish(model) {
    const { updateNewsAction, successActions } = this.props
    const { id } = model

    KBPopover.plugins.confirm('发布', '确定发布该资讯吗？', {
      confirm: () => {
        api
          .updateNews(id, { status: 'published' })
          .then(
            json => {
              successActions({ message: '发布成功！' })
              updateNewsAction.success(json)
            },
            error => {
              updateNewsAction.failure(error)
            }
          )
          .finally(() => {
            KBPopover.close()
          })
      }
    })
  },

  escape(str) {
    return str.replace(/<\/script/g, '<\\/script').replace(/<!--/g, '<\\!--')
  },

  previewOnMobile() {
    const { news } = this.props
    KBPopover.show(<MobilePreview qrcode_url={news.preview_qrcode} />)
  },

  createAnnouncement() {
    const { news } = this.props

    let link = news.detail_url || ''
    let title = news.title
    let image = news.image_url
    KBPopover.show(
      <AnnouncementForm
        initialValues={{ link, title, image }}
        disableLinkEditable={true}
      />
    )
  },

  render() {
    const { news } = this.props
    const {
      image_url,
      title,
      details,
      created_at,
      view_count,
      status,
      url
    } = news
    const { loading } = this.state

    return (
      <section className="kb-content-container">
        <header className="nav-section-header clear-fix border-bottom">
          <div className="nav-section-header-title f-left">
            <span
              className="color-nine c-pointer a-hover"
              onClick={this.toNewsList}
            >
              资讯
            </span>
            <i
              className="iconfont icon-navigate_next color-nine"
              style={{ marginLeft: 15, marginRight: 15 }}
            />
            <span>资讯详情</span>
          </div>
          <span
            className="bordered-btn-end f-right"
            onClick={this.deleteNews.bind(null, news)}
          >
            删除
          </span>
          <span className="bordered-btn f-right" onClick={this.toEditNews}>
            编辑
          </span>
          <span className="bordered-btn f-right" onClick={this.previewOnMobile}>
            手机预览
          </span>
          {status && status === 'published' ? (
            <span
              className="bordered-btn f-right"
              onClick={this.createAnnouncement}
            >
              创建公告
            </span>
          ) : null}
          {status && (status === 'draft' || status === 'deleted') ? (
            <span
              className="bordered-btn f-right"
              onClick={this.toPublish.bind(this, news)}
            >
              发布
            </span>
          ) : (
            ''
          )}
        </header>
        <KBLoadingContainer loading={loading} className="news-detail-container">
          {image_url ? (
            <div
              className="news-image"
              style={{ backgroundImage: `url(${image_url})` }}
            />
          ) : null}
          <div className="news-detail">
            <div className="news-header">
              <div
                className="news-title"
                style={{ marginBottom: 0, cursor: 'default' }}
              >
                <span style={{ color: '#404040' }}>{title}</span>
                <span className={`news-status news-status_${status}`}>
                  {NEWS_STATUS_OBJ[status]}
                </span>
              </div>
              <div className="news-time-and-viewcounts">
                <span className="news-created-time">
                  <i className="iconfont icon-time" />
                  <span>
                    {created_at ? formatHoursMinutesPON(created_at) : '暂无'}
                  </span>
                </span>
                <span className="news-view-counts">
                  <i className="iconfont icon-reading" />
                  <span>{view_count || 0}</span>
                </span>
              </div>
            </div>

            <div className="news-body">
              {url ? (
                <div className="news-content">
                  此条资讯为外部链接，
                  <a href={url} target="_blank" className="color-link">
                    点我查看资讯详情
                  </a>
                </div>
              ) : (
                <div
                  className="news-content"
                  dangerouslySetInnerHTML={{
                    __html: details ? this.escape(details) : '此条资讯暂无内容'
                  }}
                />
              )}
            </div>
          </div>
        </KBLoadingContainer>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  let loc_id = props.params.id
  let news_id = props.params.news_id
  let news = selectors.getNewsInfo(state, news_id)
  return {
    loc_id,
    news_id,
    news
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getNewsAction: bindActionCreators(newsAction.get, dispatch),
    deleteNewsAction: bindActionCreators(newsAction.delete, dispatch),
    updateNewsAction: bindActionCreators(newsAction.update, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch)
  }
}

NewsDetail = connect(mapStateToProps, mapDispatchToProps)(NewsDetail)

export default NewsDetail
