import React, { PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { KBLoadingContainer } from 'components'
import {
  successState,
  subscriptionActions,
  subscriptionTempletesAction,
  orgActions
} from 'app/actions'
import * as selectors from 'app/selectors'

import ParkingContractForm from './ParkingContractForm'
import SubscriptionSignForm from '../organization/SubscriptionSignForm'
import SubscriptionApprovalForm from '../organization/SubscriptionApprovalForm'
import OrganizationAuditSuccess from '../organization/OrganizationAuditSuccess'
import OrganizationAuditFailure from '../organization/OrganizationAuditFailure'

const ORG_HEADER = [
  {
    step: 1,
    title: '输入合同细节'
  },
  {
    step: 2,
    title: '提交合同审批'
  },
  {
    step: 3,
    title: '打印合同签字'
  },
  {
    step: 4,
    title: '完成合同'
  }
]

var ParkingContractCreate = React.createClass({
  getInitialState() {
    const { currentContract } = this.props

    return {
      currentSubscription: currentContract || {},
      currentOrganization: {},
      step: 1,
      loading: false,
      currentState: ''
    }
  },

  childContextTypes: {
    user: PropTypes.object,
    previewSubscription: PropTypes.func,
    isDraft: PropTypes.func,
    isCencelled: PropTypes.func,
    isNeedTemplate: PropTypes.bool
  },
  getChildContext() {
    return {
      user: this.props.user,
      isDraft: this.isDraft,
      previewSubscription: this.previewSubscription,
      isCencelled: this.isCancelled,
      isNeedTemplate: this.props.isNeedTemplate
    }
  },

  componentDidMount() {
    const { contract_id } = this.props

    if (contract_id) {
      this.getContractDetail()
    }
    this.getSubscriptionTemplates()
  },

  stepInitial(subscription) {
    const { loc_id, status, contract_id, routerActions } = this.props

    let params = {
      currentSubscription: subscription
    }
    if (status === 'audit' && contract_id) {
      params.step = 2
      if (subscription.status === 'active') {
        let url = `/admin/locations/${loc_id}/parking/contract/${subscription.id}`
        routerActions.push(url)
        return
      }

      if (this.isCancelled(subscription)) {
        params.step = 1
      }

      if (
        (!subscription.step || subscription.step === 'auditSuccess') &&
        this.isDraft(subscription)
      ) {
        params.step = 2
        params.currentState = 'auditSuccess'
      }

      if (
        (!subscription.step || subscription.step === 'auditFailure') &&
        this.isCancelled(subscription)
      ) {
        params.step = 2
        params.currentState = 'auditFailure'
      }

      if (
        (this.isDraft(subscription) && subscription.step === 'sign') ||
        (this.isDraft(subscription) && !subscription.step) ||
        this.isDraft(subscription)
      ) {
        params.step = 3
      }
    }
    this.setState(params)
  },

  getContractDetail() {
    const { loc_id, contract_id, getSubscriptionActions } = this.props

    this.setState({
      loading: true
    })
    api
      .getSubscriptionDetail(contract_id)
      .then(
        json => {
          getSubscriptionActions.success(json)
          let currentSubscription = json.response.body
          this.stepInitial(currentSubscription)
          this.getOrgInfo(loc_id, currentSubscription.organization_id)
          this.setState({
            currentSubscription
          })
        },
        error => {
          getSubscriptionActions.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  getOrgInfo(loc_id, org_id) {
    api
      .getOrganizationInfo(org_id, {
        location_id: loc_id
      })
      .then(
        json => {
          let currentOrganization = json.response.body
          this.props.getOrgAction.success(json, {
            organization_id: org_id,
            location_id: loc_id
          })
          this.setState({
            currentOrganization
          })
        },
        errors => this.props.getOrgAction.failure(errors)
      )
  },

  getSubscriptionTemplates() {
    const { getSubscriptionTempletesAction } = this.props
    api.getSubScriptionTempletes().then(
      json => {
        getSubscriptionTempletesAction.success(json)
      },
      error => {
        getSubscriptionTempletesAction.failure(error)
      }
    )
  },

  componentWillReceiveProps(nextProps) {
    // const { urlParams } = this.props
    // if(!_.isEqual(urlParams, nextProps.urlParams) && nextProps.urlParams.step){
    //   this.setState({
    //     step:
    //   })
    // }
  },

  isCancelled(subscription) {
    if (
      subscription &&
      (subscription.status === 'cancelled' ||
        subscription.status === 'rejected')
    ) {
      return true
    }
    return false
  },

  isDraft(subscription) {
    if (subscription.status === 'draft') {
      return true
    }
    return false
  },

  previewSubscription(templete_id) {
    const { currentSubscription } = this.state
    // const templete_id = this.state.data.templete_id
    const { user } = this.props
    return new Promise((resolve, reject) => {
      try {
        let templeteId =
          templete_id && templete_id != -1
            ? templete_id
            : currentSubscription.templete_id
        api
          .previewTemplete(templeteId, {
            sales_subscription_id: currentSubscription.id
          })
          .then(json => {
            const responseURL =
              json.response.xhr.responseURL + `&user_token=${user.jwt_token}`
            this.setState(
              {
                responseURL
              },
              () => {
                this.refs.subscriptionFile.click()
              }
            )
            resolve()
          })
      } catch (e) {
        reject()
      }
    })
  },

  contractDetailForm() {
    const { loc_id, status, contract_id } = this.props
    const { currentSubscription } = this.state

    return (
      <ParkingContractForm
        status={status}
        loc_id={loc_id}
        subscription_id={contract_id}
        currentSubscription={currentSubscription}
        nextCallBack={this.detailNextBtn}
      />
    )
  },

  contractApprovalForm() {
    const { loc_id } = this.props
    const { currentSubscription, currentOrganization } = this.state

    return (
      <SubscriptionApprovalForm
        isParkingContract={true}
        templete_id={currentSubscription.templete_id}
        loc_id={loc_id}
        org_id={currentSubscription.organization_id}
        currentSubscription={currentSubscription}
        currentOrganization={currentOrganization}
        nextCallBack={this.auditNextBtn}
        step={'audit'}
      />
    )
  },

  renderAuditSuccess() {
    const currentState = this.state.currentState

    if (currentState != 'auditSuccess') {
      return ''
    }
    const { currentSubscription } = this.state
    const { user } = this.props
    return (
      <OrganizationAuditSuccess
        isParkingContract={true}
        currentSubscription={currentSubscription}
        setNextAndCurrentSubscription={this.setNextAndCurrentSubscription}
        user={user}
      />
    )
  },

  renderAuditFailure() {
    const currentState = this.state.currentState

    if (currentState != 'auditFailure') {
      return ''
    }

    const { currentSubscription } = this.state
    const { user } = this.props
    return (
      <OrganizationAuditFailure
        isParkingContract={true}
        currentSubscription={currentSubscription}
        setNextAndCurrentSubscription={this.setNextAndCurrentSubscription}
        user={user}
      />
    )
  },

  contractSignForm() {
    const { currentSubscription, currentOrganization } = this.state

    return (
      <SubscriptionSignForm
        isParkingContract={true}
        currentSubscription={currentSubscription}
        currentOrganization={currentOrganization}
        step={'sign'}
        templete_id={currentSubscription.templete_id}
        nextCallBack={this.signNextBtn}
      />
    )
  },

  toSubscriptionDetail() {
    const { currentSubscription } = this.state
    const { routerActions, loc_id } = this.props

    let url = `/admin/locations/${loc_id}/parking/contract/${currentSubscription.id}`
    routerActions.push(url)
  },

  contractFinish() {
    return (
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">完成合同</h2>
        </header>
        <div
          className="flex-center"
          style={{ flexDirection: 'column', marginTop: 90 }}
        >
          <img
            src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/complete.png"
            alt="complete"
            style={{ width: 80, heightL: 80 }}
          />
          <span
            className="d-block"
            style={{ fontSize: 18, marginBottom: 30, marginTop: 15 }}
          >
            合同完成
          </span>
          <span
            className="bordered-btn"
            onClick={this.toSubscriptionDetail}
            style={{ marginLeft: 0, padding: '7px 25px' }}
          >
            查看合同详情
          </span>
        </div>
      </div>
    )
  },

  detailNextBtn(subscription) {
    const { routerActions, loc_id } = this.props

    let url = `/admin/locations/${loc_id}/parking/contract_new?contract_id=${subscription.id}&status=audit`
    routerActions.replace(url)
    const data = {
      currentSubscription: subscription,
      step: 2
    }
    if (subscription.status == 'active') {
      data.step = 4
    }
    if (this.isDraft(subscription)) {
      data.step = 3
    }
    this.setState(data)
  },

  auditNextBtn(subscription) {
    let params = {
      currentSubscription: subscription
    }
    if (this.isDraft(subscription)) {
      params.currentState = 'auditSuccess'
    }
    if (this.isCancelled(subscription)) {
      params.currentState = 'auditFailure'
    }
    if (subscription.status === 'active') {
      params.step = 4
    }
    this.setState(params)
  },

  setNextAndCurrentSubscription(subscription, nextStep) {
    const { loc_id, routerActions } = this.props
    let stepObj = {
      detail: 1,
      sign: 3
    }
    if (subscription.formal && nextStep === 'detail') {
      let url = `/admin/locations/${loc_id}/parking/contract_new?contract_id=${subscription.id}&status=update`
      routerActions.replace(url)
    }
    this.setState({
      step: stepObj[nextStep],
      currentState: nextStep,
      currentSubscription: subscription
    })
  },

  signNextBtn(subscription) {
    this.setState({
      currentSubscription: subscription,
      step: 4
    })
  },

  render() {
    const { step, loading, currentState, responseURL } = this.state

    return (
      <KBLoadingContainer
        className="kb-content"
        style={{ paddingTop: 0 }}
        loading={loading}
      >
        <div className="org-header">
          {ORG_HEADER.map((header, index) => {
            const isLast = ORG_HEADER.length - 1 === index

            return (
              <div
                className={`org-header-step ${
                  step === header.step ? 'org-step_active' : ''
                }`}
                key={index}
                style={isLast ? { flex: 'initial' } : {}}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: isLast ? 'start' : 'center',
                    alignItems: 'center'
                  }}
                >
                  <div className="org-header-num">{header.step}</div>
                  {!isLast ? <div className="org-header-line" /> : null}
                </div>
                <div className="org-header-title">{header.title}</div>
              </div>
            )
          })}
        </div>
        <div
          style={{
            backgroundColor: '#FFFFFF',
            marginTop: 20,
            border: '1px solid #DFE2E5',
            paddingBottom: 60,
            borderRadius: 4
          }}
        >
          {step === 1 ? this.contractDetailForm() : null}
          {step === 2
            ? currentState === 'auditSuccess'
              ? this.renderAuditSuccess()
              : currentState === 'auditFailure'
              ? this.renderAuditFailure()
              : this.contractApprovalForm()
            : null}
          {step === 3 ? this.contractSignForm() : null}
          {step === 4 ? this.contractFinish() : null}
          <a ref="subscriptionFile" href={responseURL} download />
        </div>
      </KBLoadingContainer>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const { params, location } = ownProps
  const loc_id = params.id
  const status = location.query.status || ''
  const contract_id = location.query.contract_id
  const spaceSetting = selectors.getSpaceSetting(state)
  const isNeedTemplate =
    spaceSetting &&
    spaceSetting.settings &&
    spaceSetting.settings.need_subscription_templete &&
    spaceSetting.settings.need_subscription_templete == 'true'
  let currentContract = selectors.getEntity(state, 'subscriptions', contract_id)
  const user = selectors.getCurrentUser(state)

  return {
    user,
    loc_id,
    status,
    contract_id,
    currentContract,
    isNeedTemplate
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    getSubscriptionActions: bindActionCreators(
      subscriptionActions.get,
      dispatch
    ),
    getSubscriptionTempletesAction: bindActionCreators(
      subscriptionTempletesAction.get,
      dispatch
    ),
    getOrgAction: bindActionCreators(orgActions.get, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkingContractCreate)
