import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { orgActions, successState } from 'app/actions'
import {
  KBInput,
  KBSelect,
  KBMorePhotoUpload,
  KBFormError,
  KBButton,
  KBPopover
} from 'components'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'

var UploadAnnexForm = React.createClass({
  getInitialState() {
    return {
      type: [],
      selectedContent: ''
    }
  },
  componentWillMount() {
    const { annexType } = this.props

    const type = []
    annexType.map(json => {
      type.push({
        id: json,
        name: json
      })
    })
    this.setState({ type: type })
  },

  createAnnexForm(model) {
    let location_id = this.props.location_id
    const { loc_id, org_id, loadData, filters, page, clearFilters } = this.props

    return api
      .postAnnex(loc_id, org_id, model)
      .then(json => {
        this.props.updateOrgAction.success(json)
        loadData(filters, page)
        clearFilters()
      })
      .finally(() => {
        KBPopover.close()
      })
  },

  getFileName(names) {
    const {
      fields: { filename }
    } = this.props
    const { selectedContent } = this.state

    let name = (names && names[0]) || ''
    let fieldName = name && name.split('.')[0]

    if (selectedContent == '其他') {
      filename.onChange(fieldName)
    }
  },

  changeFileName(value) {
    const {
      fields: { filename }
    } = this.props

    filename.onChange(value)
    this.setState({
      selectedContent: value
    })
  },

  render() {
    const {
      fields: { url, filename },
      loc_id,
      org_id,
      submitting,
      handleSubmit
    } = this.props
    const { selectedContent } = this.state
    const submit = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    }

    var halfClass = {
      className: 'kb-form-group kb-form-210 kb-form-alone'
    }

    const filenames = [
      { title: '营业执照', value: '营业执照' },
      { title: '法人身份证正面', value: '法人身份证正面' },
      { title: '法人身份证反面', value: '法人身份证反面' },
      { title: '其他', value: '其他' }
    ]
    const filenamesArray = ['营业执照', '法人身份证正面', '法人身份证反面', '']

    return (
      <form onSubmit={handleSubmit(this.createAnnexForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">上传附件</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-row">
            {filenamesArray.includes(selectedContent) ? (
              <div className={halfClass.className}>
                <label>
                  附件名称<span className="must-fill">*</span>
                </label>
                <div>
                  <div className="kb-form-group r1_radios">
                    {filenames.map(data => {
                      return (
                        <div
                          className="kb-form-group r1_radio"
                          onClick={this.changeFileName.bind(null, data.value)}
                        >
                          <div
                            className={`radio-btn ${
                              selectedContent == data.value
                                ? 'radio-btn-active'
                                : ''
                            }`}
                          ></div>
                          <label className="label">{data.title}</label>
                        </div>
                      )
                    })}
                    {filename.touched && filename.error ? (
                      <p className="lr-error">{filename.error}</p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <KBInput
                {...halfClass}
                title="附件名称"
                isRequired={true}
                field={filename}
                fistFoucs={true}
              />
            )}
          </div>
          <div className="kb-row">
            <KBMorePhotoUpload
              title="上传附件"
              isRequired={true}
              uploadOneTime={true}
              multiple={false}
              photoShow={true}
              hint="一次只能上传一个文件,大小不大于20M"
              className="kb-form-group kb-form-210 kb-form-alone"
              field={url}
              getFileNameCallback={this.getFileName}
              text="点击上传附件"
              prefix="images/organization/"
              accept="image/*, .doc,.docx,.pdf"
            />
          </div>
          <div className="kb-row" style={submit}>
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateOrgAction: bindActionCreators(orgActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.filename)) {
    errors.filename = '请选择或填写附件名称'
  }

  if (valid.required(values.url)) {
    errors.url = '请上传附件'
  }

  return errors
}

const formConfig = {
  form: 'UploadAnnexForm',
  fields: ['url', 'filename'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(UploadAnnexForm)
