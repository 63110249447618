import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import { Link } from 'react-router'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBRangeDate,
  KBTableWithoutData
} from 'components'
import { connect } from 'react-redux'
import { INVOICE_SALES_PAYMENTS } from 'app/constants'
import { paginate, formatMinuteSecondEN } from 'utils/kbUtil'
import moment from 'moment'
import * as selectors from 'app/selectors'
import { getUserInfoUrl } from 'utils/kbUrl'

var MembersPoint = React.createClass({
  displayName: 'OrganizationPoint',
  getInitialState() {
    return {
      pagination: null,
      pointList: [],
      loading: true,
      filters: {},
      pointMessage: {}
    }
  },

  componentDidMount() {
    this.loadData()
    this._loadMembershipData()
  },

  loadData(page = 1) {
    const { member_id, user } = this.props
    const { filters } = this.state
    let params = Object.assign(
      {},
      { customer_type: 'user' },
      { user_id: member_id },
      { per_page: 10 },
      { page: page },
      filters,
      { sales_customer_id: user.sales_customer.id }
    )
    let { from, to } = params
    if (from && to) {
      params.from = from && moment(from).format('YYYY-MM-DD')
      params.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete params.from
      delete params.to
    }
    api
      .getPointPlansLedgers(params)
      .then(({ json, response }) => {
        let pagination = paginate(response)
        this.setState({
          pointList: json,
          pagination
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },
  // user
  _loadMembershipData() {
    const { user } = this.props
    if (!user.sales_customer) {
      return
    }

    api
      .getMembershipUserMembership(user.sales_customer.id)
      .then(({ json }) => {
        this.getMemberships(json)
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  getMemberships(json) {
    let pointMessage = {}
    const { points } = json
    if (points && points.length > 0) {
      points.map(point => {
        if (point.customer_type == 'user') {
          pointMessage = point
        }
      })
    }
    this.setState({
      pointMessage
    })
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.loadData(nextProps.page)
    }
  },

  changeDate(range) {
    this._setFilter(range)
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })

    // force refresh
    this._refresh()
  },

  _refresh() {
    setTimeout(this.loadData)
  },

  _clearFilters() {
    this.setState({ filters: {} })
    // force refresh
    this._refresh()
  },

  render() {
    const { pointList, loading, filters, pointMessage } = this.state
    const { from, to } = filters
    const { loc_id, org_id, member_id, virtual_currency_name } = this.props
    return (
      <section className="nav-section-container f-right">
        <div style={{ height: '100%' }}>
          <header className="nav-header">
            <div className=" clear-fix">
              <div className="nav-section-header-title f-left">
                <span>{virtual_currency_name}记录</span>
              </div>
            </div>
          </header>
          <div className="nav-section-content-container">
            <KBLoadingContainer loading={loading}>
              <div className="m-top-sm">
                <KBRangeDate
                  format="YYYY/MM/DD"
                  from={from || ''}
                  to={to || ''}
                  callback={this.changeDate}
                  leftNone={true}
                />
                <div
                  className="org-point"
                  style={{
                    marginLeft: 20,
                    display: 'inline-flex',
                    alignItems: 'center',
                    height: 37,
                    color: '#cccccc'
                  }}
                >
                  <p>
                    {virtual_currency_name}余额:{' '}
                    <span style={{ color: '#666666' }}>
                      {pointMessage && pointMessage.balance
                        ? pointMessage.balance
                        : 0}
                    </span>
                  </p>
                  <p style={{ paddingLeft: 15, paddingRight: 15 }}>|</p>
                  <p>
                    基础{virtual_currency_name}:{' '}
                    <span style={{ color: '#666666' }}>
                      {pointMessage && pointMessage.basic_balance
                        ? pointMessage.basic_balance
                        : 0}
                    </span>
                  </p>
                  <p style={{ paddingLeft: 15, paddingRight: 15 }}>|</p>
                  <p>
                    赠送{virtual_currency_name}:{' '}
                    <span style={{ color: '#666666' }}>
                      {pointMessage && pointMessage.gift_balance
                        ? pointMessage.gift_balance
                        : 0}
                    </span>
                  </p>
                </div>
                <div style={{ marginTop: 5 }}>
                  {Object.keys(this.state.filters).length > 0 ? (
                    <div
                      className="clear-criteria"
                      style={{ marginTop: 5 }}
                      onClick={this._clearFilters}
                    >
                      <i className="iconfont icon-close" />
                      <span>清除所有筛选条件</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <table className="content-table">
                <thead>
                  <tr>
                    <th>基础余额</th>
                    <th>赠送余额</th>
                    <th>余额</th>
                    <th>支付现金</th>
                    <th>支付方式</th>
                    <th>时间</th>
                    <th>操作人</th>
                    <th>备注</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={pointList.length > 0}
                  tableHeadNum="8"
                  tipMessage="暂无记录"
                >
                  {pointList &&
                    pointList.map((point, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {point.outgoings == 0 ? (
                              <span style={{ color: '#25c324' }}>
                                +{point.incomings}
                              </span>
                            ) : (
                              <span style={{ color: '#ff0000' }}>
                                -{point.outgoings}
                              </span>
                            )}
                          </td>
                          <td>
                            {point.gift_outgoings == 0 ? (
                              <span style={{ color: '#25c324' }}>
                                +{point.gift_incomings}
                              </span>
                            ) : (
                              <span style={{ color: '#ff0000' }}>
                                -{point.gift_outgoings}
                              </span>
                            )}
                          </td>
                          <td>{point.balance}</td>
                          <td>
                            {point.payment_mode === 'point'
                              ? ''
                              : `${
                                  point.paid_amount ? point.paid_amount : 0
                                } 元`}
                          </td>
                          <td>
                            {point.payment_mode
                              ? INVOICE_SALES_PAYMENTS[point.payment_mode]
                              : '无'}
                          </td>
                          <td>{formatMinuteSecondEN(point.created_at)}</td>
                          <td>
                            {point.operator ? (
                              <div className="vf-center">
                                <KBAvatar
                                  user={point.operator}
                                  imgStyle={{ marginRight: 5 }}
                                  style={{
                                    display: 'inline-block',
                                    marginRight: '5px'
                                  }}
                                  size={30}
                                />
                                <Link
                                  to={getUserInfoUrl(point.operator.id)}
                                  className="color-link"
                                >
                                  {point.operator.name || ''}
                                </Link>
                              </div>
                            ) : null}
                          </td>
                          <td>{point.note}</td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={this.state.pagination}
                template={`${getUserInfoUrl(member_id)}/point?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let page = parseInt(location.query && location.query.page) || 1
  const member_id = params.member_id
  const user = selectors.getUserById(state, member_id)
  return {
    page,
    user,
    loc_id: params.id,
    member_id,
    org_id: params.organization_id
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersPoint)
