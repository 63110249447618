import React, { Component, PropTypes } from 'react'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from 'react-sortable-hoc'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBPopover,
  KBTipsy,
  KBTableWithoutData
} from 'components'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import * as actions from 'app/actions'
import { api, apiUtils } from 'app/services'
import _ from 'lodash'
const ServiceDragHandle = SortableHandle(() => (
  <i className="iconfont icon-list-point drag-move" />
))

const ServiceItem = SortableElement(
  ({
    service,
    renderToInfo,
    switchServiceProvidersEnable,
    switchServiceProvidersPrioritize
  }) => {
    return (
      <tr
        className="service-tr"
        onClick={() => {
          renderToInfo(service.id)
        }}
      >
        <td style={{ width: 263, verticalAlign: 'middle' }}>
          <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
            <KBAvatar
              user={service}
              size={60}
              className="d-iblock"
              imgStyle={{ borderRadius: 0 }}
            />
          </div>
          <div
            className="d-iblock color-link"
            style={{ verticalAlign: 'middle', marginLeft: 10 }}
          >
            <KBTipsy content={service.full_name}>
              <div style={{ fontSize: 18 }}>{service.name}</div>
            </KBTipsy>
          </div>
        </td>
        <td className="linkman" style={{ width: 100, verticalAlign: 'middle' }}>
          <KBTipsy content={service.contact_phone}>
            <div className="d-iblock">{service.contact_name}</div>
          </KBTipsy>
        </td>
        <td style={{ width: 180, verticalAlign: 'middle' }}>
          {service.services.length
            ? _.uniqBy(service.services, 'category_id').map((json, index) => (
                <span
                  key={index}
                  className="badge"
                  style={{ marginRight: 5, marginBottom: 5 }}
                >
                  {json.category_name}
                </span>
              ))
            : '无'}
        </td>
        <td style={{ width: 115, verticalAlign: 'middle' }}>
          <div>
            <span>申请数:</span>&nbsp;
            <span>{service.service_requests_count}</span>
          </div>
          <div>
            <span>接单率:</span>&nbsp;
            <span>{service.service_requests_complete_rate + '%'}</span>
          </div>
          <div>
            <span>完成率:</span>&nbsp;
            <span>{service.service_requests_accpet_rate + '%'}</span>
          </div>
        </td>
        <td style={{ width: 64, verticalAlign: 'middle' }}>
          <div
            className="slide-btn"
            onClick={e => switchServiceProvidersEnable(e, service)}
          >
            <span
              className={`slide-bg ${
                service.is_enabled ? 'slide-left' : 'slide-right'
              }`}
            >
              <span className="slide-move" />
            </span>
          </div>
        </td>
        <td style={{ width: 64, verticalAlign: 'middle' }}>
          <div
            className="slide-btn"
            onClick={e => switchServiceProvidersPrioritize(e, service)}
          >
            <span
              className={`slide-bg ${
                service.is_prioritized ? 'slide-left' : 'slide-right'
              }`}
            >
              <span className="slide-move" />
            </span>
          </div>
        </td>
        <td style={{ width: 35, verticalAlign: 'middle' }}>
          <ServiceDragHandle />
        </td>
      </tr>
    )
  }
)

const List = SortableContainer(
  ({
    items,
    is_enabled,
    renderToInfo,
    switchServiceProvidersPrioritize,
    switchServiceProvidersEnable
  }) => (
    <KBTableWithoutData
      hasData={items.length > 0}
      tableHeadNum="6"
      tipMessage="暂无数据"
    >
      {items &&
        items.map((item, index) => (
          <ServiceItem
            switchServiceProvidersPrioritize={switchServiceProvidersPrioritize}
            switchServiceProvidersEnable={switchServiceProvidersEnable}
            renderToInfo={renderToInfo}
            disabled={!is_enabled}
            key={`item-${index}`}
            service={item}
            index={index}
          />
        ))}
    </KBTableWithoutData>
  )
)

var ServiceList = React.createClass({
  getInitialState() {
    return {
      serviceProviders: this.props.serviceProviders
    }
  },
  componentWillReceiveProps(nextProps) {
    const { serviceProviders } = nextProps
    if (!_.isEqual(this.props.serviceProviders, serviceProviders)) {
      this.setState({
        serviceProviders
      })
    }
  },
  onSortEnd({ oldIndex, newIndex }) {
    var serviceProviders = this.state.serviceProviders
    const newServiceProviders = arrayMove(serviceProviders, oldIndex, newIndex)
    let serviceProvidersStr = JSON.stringify(
      serviceProviders.map((json, index) => {
        return { service_provider_id: json.id, position: index }
      })
    )
    let newServiceProvidersStr = JSON.stringify(
      newServiceProviders.map((json, index) => {
        return { service_provider_id: json.id, position: index }
      })
    )
    if (serviceProvidersStr == newServiceProvidersStr) {
      return
    }
    this.updateServiceProvidersPosition(newServiceProviders)
    this.setState({ serviceProviders: newServiceProviders })
  },
  updateServiceProvidersPosition(ServiceList) {
    const { updateServiceProvidersAction } = this.props
    var params = {
      positions: JSON.stringify(
        ServiceList.map((json, index) => {
          return { service_provider_id: json.id, position: index }
        })
      )
    }

    api.putServiceProvidersSetPositions(params).then(
      json => {
        updateServiceProvidersAction.success(json)
        this.setState({ serviceProviders: ServiceList })
      },
      error => {
        updateServiceProvidersAction.failure(error)
      }
    )
  },

  switchServiceProvidersEnable(e, service) {
    e.stopPropagation()
    const { updateServiceProvidersAction } = this.props
    api.putServiceProviderEnabled(service.id).then(
      json => {
        updateServiceProvidersAction.success(json)
      },
      error => {
        updateServiceProvidersAction.failure(error)
      }
    )
  },

  switchServiceProvidersPrioritize(e, service) {
    const { apiCallFailureActions } = this.props
    e.stopPropagation()
    const { updateServiceProvidersAction } = this.props
    if (!service.is_enabled) {
      apiCallFailureActions({
        status: 'error',
        message: `该服务商尚未在当前空间启用！`
      })
      return
    }
    api.putPrioritize(service.id).then(
      json => {
        updateServiceProvidersAction.success(json)
        KBPopover.close()
      },
      error => {
        updateServiceProvidersAction.failure(error)
        KBPopover.close()
      }
    )
  },

  renderToInfo(id) {
    if (!id) {
      return
    }
    this.props.routerActions.push(`/admin/service/provider/${id}/info`)
  },
  render() {
    const { is_enabled } = this.props
    const { serviceProviders, loading } = this.state

    return (
      <table className="content-table edit-table table-align">
        <thead>
          <tr>
            <th>服务商</th>
            <th>联系人</th>
            <th>服务</th>
            <th>申请</th>
            <th>启用</th>
            <th>置顶</th>
            <th />
          </tr>
        </thead>
        <List
          items={serviceProviders}
          switchServiceProvidersPrioritize={
            this.switchServiceProvidersPrioritize
          }
          switchServiceProvidersEnable={this.switchServiceProvidersEnable}
          is_enabled={is_enabled}
          renderToInfo={this.renderToInfo}
          useDragHandle={true}
          onSortEnd={this.onSortEnd}
        />
      </table>
    )
  }
})

ServiceList.PropTypes = {
  serviceProviders: PropTypes.object.isRequired,
  is_enabled: PropTypes.bool
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    updateServiceProvidersAction: bindActionCreators(
      actions.serviceProvidersAction.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(actions.apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList)
