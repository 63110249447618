import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import {
  invoicesActions,
  successState,
  apiCallFailure,
  salesProductsActions
} from 'app/actions'
import { api, apiUtils } from 'app/services'
import { paginate, arrayToObject, util } from 'utils/kbUtil'
import { reduxForm } from 'redux-form'
import {
  KBDropdown,
  KBPagination,
  KBLoadingContainer,
  KBSearchList,
  KBRangeDate,
  KBUserSelect,
  KBPopover
} from 'components'
import {
  INVOICES_STATE,
  ORDER_STATE_ARRAY,
  INVOICES_STATE_CLASS
} from 'app/constants'
import {
  queryFilter,
  getFilterAndQuery,
  formatMinuteSecondEN,
  kbCloseDropDown
} from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import { routerActions } from 'react-router-redux'
import { canAccessLocationFinance } from 'app/reducers/role'
import OrdersReport from './OrdersReport'
import moment from 'moment'
import _ from 'lodash'

const DROP_DOWNS = ['statusDropdown', 'location_idDropdown', 'dateDropDown']
const FILTER_STATE = ['status', 'from', 'to', 'location_id', 'product_id']

var MembershipsOrders = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      total: 0,
      paid: 0,
      percentUnpaid: 0,
      invoices: [],
      filters,
      page: this.props.page || 1,
      loading: false,
      queryFiltersStr: ''
    }
  },
  componentDidMount() {
    const { shopping_id, salesProductsActions } = this.props

    this.mounted = true

    api.getSalesProducts({ shop_id: shopping_id, per_page: 100 }).then(
      json => {
        salesProductsActions.success(json, {
          entityName: 'salesProducts'
        })
      },
      errors => {
        salesProductsActions.failure(errors)
      }
    )
    this._loadData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(model, newPage) {
    const per_page = 10
    const { getInvoicesActions, routerActions, query, shopping_id } = this.props
    const { page, filters } = this.state
    let params = Object.assign({}, model, filters, { shop_id: shopping_id })
    let { from, to } = params
    if (from && to) {
      params.from = from && moment(from).format('YYYY-MM-DD')
      params.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete params.from
      delete params.to
    }

    let queryFiltersStr = queryFilter({
      ...query,
      ...params,
      page: newPage || page
    })

    this.setState({ loading: true })
    return api
      .getInvoices({
        ...params,
        per_page,
        page: newPage || page,
        invoice_type: 'product_reservation'
      })
      .then(
        ({ json, response }) => {
          const { invoices } = json
          let pagination = paginate(response)
          getInvoicesActions.success({
            entities: { invoices: arrayToObject(invoices) }
          })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/shopping/${shopping_id}/product_orders${queryFiltersStr}`
          )
          if (this.mounted) {
            const { total, unpaid, paid } = json
            this.setState({
              total: total,
              invoices: invoices || [],
              unpaid: unpaid,
              paid: paid,
              pagination: pagination,
              queryFiltersStr
            })
          }
          return true
        },
        errors => {
          getInvoicesActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      this._loadData({}, nextProps.page)
      return
    }
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, page: 1 })

    // force refresh
    this._refresh()
  },

  changeDate(range) {
    this._setFilter(range)
  },

  _refresh() {
    this['location_idDropdown'] && this['location_idDropdown'].hide()
    this['statusDropdown'] && this['statusDropdown'].hide()
    this['typeDropdown'] && this['typeDropdown'].hide()

    setTimeout(this._loadData)
  },

  _clearFilters() {
    this.setState({ filters: {}, page: 1 })
    // force refresh
    this._refresh(null, {})
    this.refs.dropDownOrg.clearInput()
  },

  _selectRange(range) {
    this.setState({
      ...range
    })
  },

  getOrgs(str, callback) {
    util.setTimeout(
      'invoicesSearchInput',
      () => {
        api.getSearchAllType({ query: str, type: 'org' }).then(({ json }) => {
          callback && callback(json)
        })
      },
      0
    )
  },

  _selectCallBack(data) {
    var filters = Object.assign({}, this.state.filters)

    if (data) {
      filters = Object.assign({}, filters, {
        customer_type: 'org',
        customer_id: data.id
      })
    } else {
      delete filters.customer_type
      delete filters.customer_id
      filters = Object.assign({}, filters)
    }

    this.setState({ filters })
    this._refresh()
  },

  fromToTextRender(from, to) {
    return from || to
      ? `${from ? moment(from).format('YYYY年MM月DD日') : ''}${
          to ? '-' + moment(to).format('YYYY年MM月DD日') : ''
        }`
      : '选择日期'
  },

  handleSelectChange(value) {
    this._setFilter({ product_id: value.id })
  },

  exportOrders() {
    const { filters } = this.state
    const { shopping_id, salesProductsData } = this.props

    KBPopover.show(
      <OrdersReport
        products={salesProductsData}
        current_shop_id={shopping_id}
        filters={filters}
      />
    )
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  _renderFilters() {
    const { from, to } = this.props.filters
    const { locations, salesProductsData, selectProductData } = this.props
    return (
      <div className="nav-section-action-bar" style={{ marginTop: 0 }}>
        <div className="select-progress">
          {
            <KBDropdown
              ref={ref => {
                this.statusDropdown = ref
              }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'statusDropdown')}
                >
                  <i className="iconfont icon-finance" />
                  <span>
                    {this.state.filters.status
                      ? INVOICES_STATE[this.state.filters.status]
                      : '选择进度'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    {ORDER_STATE_ARRAY.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => this._setFilter({ status: state.id })}
                        >
                          <span>{state.name}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          }
        </div>

        {
          <KBDropdown
            ref={ref => {
              this.location_idDropdown = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'location_idDropdown')}
              >
                <i className="iconfont icon-position" />
                <span>
                  {this.state.filters.location_id &&
                  Object.keys(locations).length > 0
                    ? locations[this.state.filters.location_id].name
                    : '选择分店'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter({ location_id: '' })}>
                    全部
                  </li>
                  {locations &&
                    Object.keys(locations).map((location_id, index) => {
                      let location = locations[location_id]
                      return (
                        <li
                          key={index}
                          onClick={() =>
                            this._setFilter({ location_id: location.id })
                          }
                        >
                          <span>{location.name}</span>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        }
        <KBRangeDate
          format="YYYY/MM/DD"
          from={from || ''}
          to={to || ''}
          ref={ref => {
            this.dateDropDown = ref
          }}
          dropDown_name="dateDropDown"
          closeClick={this.closeDropDowns}
          callback={this.changeDate}
        />
        <div className="product-search">
          <KBUserSelect
            multi={false}
            placeholder="商品搜索"
            selectedUsers={selectProductData}
            users={salesProductsData}
            onUsersSelected={this.handleSelectChange}
          />
        </div>
        <div className="d-iblock m-left-sm">
          <KBSearchList
            ref="dropDownOrg"
            placeholder="公司搜索"
            getUsers={this.getOrgs}
            selectCallBack={this._selectCallBack}
            style={{ display: 'inline-block', width: '160px' }}
          />
        </div>
        <button className="c-btn-secondary f-right" onClick={this.exportOrders}>
          导出订单
        </button>
      </div>
    )
  },

  toOrderInfo(invoice) {
    const { routerActions, shopping_id } = this.props
    routerActions.push(
      `/admin/shopping/${shopping_id}/product_orders/${invoice.id}`
    )
  },

  disposeTotal(invoice) {
    const { payment_type, total_amount, total_credits, total_points } = invoice
    if (payment_type == 'point') {
      return total_points
    }
    if (payment_type == 'credits') {
      return total_credits
    }
    return total_amount
  },

  invoicesJsx() {
    const { invoices, pagination, loading, queryFiltersStr } = this.state
    const { shopping_id, exchangeUnit, shopInfo } = this.props
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    const tdStyle = {
      paddingTop: '140px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8,
      cursor: 'default'
    }
    return (
      <div style={{ padding: '20px 30px' }}>
        {this._renderFilters()}
        {selectFilters.length > 0 ? (
          <div
            className="clear-criteria"
            style={{ marginTop: 5 }}
            onClick={this._clearFilters}
          >
            <i className="iconfont icon-close" />
            <span>清除所有筛选条件</span>
          </div>
        ) : null}
        <KBLoadingContainer loading={loading}>
          <table className="content-table edit-table">
            <thead>
              <tr>
                <th>订单号</th>
                <th>收款状态</th>
                <th>客户名称</th>
                <th>分店名称</th>
                <th>应付金额</th>
                <th>交易日期</th>
              </tr>
            </thead>
            {invoices && invoices.length ? (
              invoices.map((json, index) => {
                let total = this.disposeTotal(json)
                total = Math.round(total * 100) / 100
                return (
                  <tbody key={index}>
                    <tr onClick={this.toOrderInfo.bind(null, json)}>
                      <td>
                        <span>{json.serial_number || '暂无'}</span>
                      </td>
                      <td>
                        <span
                          style={{ marginLeft: 0 }}
                          className={`finance-default ${
                            INVOICES_STATE_CLASS[json.status]
                          }`}
                        >
                          {INVOICES_STATE[json.status]}
                        </span>
                      </td>
                      <td>
                        <span>{json.sales_customer.name}</span>
                      </td>
                      <td>{json.location && json.location.name}</td>
                      <td>
                        {json.payment_type == 'money'
                          ? '¥ ' + total
                          : json.payment_type == 'point'
                          ? total + ' ' + exchangeUnit
                          : shopInfo && shopInfo.payment_type == 'point'
                          ? total + ' ' + exchangeUnit
                          : '¥ ' + total}
                      </td>
                      <td>
                        {json.created_at
                          ? formatMinuteSecondEN(json.created_at)
                          : ''}
                      </td>
                    </tr>
                  </tbody>
                )
              })
            ) : (
              <tbody>
                <tr style={{ border: 0, backgroundColor: '#ffffff' }}>
                  <td style={tdStyle} colSpan="6">
                    暂无订单
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <KBPagination
            pagination={pagination}
            template={`/admin/shopping/${shopping_id}/product_orders?page=#PAGE#`}
          />
        </KBLoadingContainer>
      </div>
    )
  },

  render() {
    return (
      <div className="clear-fix" style={{ height: '100%' }}>
        {this.invoicesJsx()}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let shopping_id = parseInt(props.params.shopping_id)
  let page = parseInt(location.query && location.query.page)
  let selectProductId = location.query && location.query.product_id
  page = isNaN(page) ? 1 : page
  let locations = selectors.getSerializeLocationObj(state)
  var params = getFilterAndQuery(location.query, FILTER_STATE)
  const spaceSetting = selectors.getSpaceSetting(state)
  const exchangeUnit =
    spaceSetting &&
    spaceSetting.virtual_currency_settings &&
    spaceSetting.virtual_currency_settings.virtual_currency_name
  const salesProducts = selectors.getSalesProducts(state)
  let salesProductsData = []
  let selectProductData = []
  salesProducts &&
    salesProducts.map(sale => {
      salesProductsData.push({
        id: sale.id,
        name: sale.title
      })
    })
  if (selectProductId) {
    selectProductData = salesProductsData.filter(
      sale => sale.id == selectProductId
    )
  }
  return {
    page,
    locations,
    shopping_id,
    salesProductsData,
    selectProductData,
    exchangeUnit,
    ...params
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getInvoicesActions: bindActionCreators(invoicesActions.all, dispatch),
    salesProductsActions: bindActionCreators(
      salesProductsActions.replace,
      dispatch
    ),
    updateInvoicesActions: bindActionCreators(invoicesActions.update, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  return errors
}

const formConfig = {
  form: 'InvoicesForm',
  fields: ['status', 'from', 'to'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(MembershipsOrders)
