import React from 'react'
import { KBButton,KBSelect,KBInput } from 'components'

const PeriodicChargeList = React.createClass({
  componentDidMount() {
    const { oldContract, sales_resources } = this.props
    oldContract.sales_resources && oldContract.sales_resources
    .filter(sales=>sales.resource_type !== 'point')
    .map(sales=>{
      sales_resources.addField(sales)
    })
  },
  render() {
    const { sales_resources, removePeriodicCharge, filterItem, taxes } = this.props
    return !!sales_resources.length &&
    <div className="kb-row kb-form-100">
      {sales_resources.map((sales,index)=>{
        return <div className="kb-row kb-form-100" style={{display:'flex'}} key={sales.cycle_fee_type.value}>
          <div  className="kb-form-group kb-div-c18" style={{display:'flex' , alignItems:'center'}}>
            <KBSelect
            title='账单类型'
            field={sales.cycle_fee_type}
            arrayData={filterItem}/>
          </div>
          <div className="kb-form-group kb-div-c18">
            <KBSelect
            arrayData={taxes}
            title='税率'
            type='text'
            field={sales.sales_tax_id}/>
          </div>
          <div className="kb-form-group kb-div-c18">
            <KBInput
            title='金额'
            type='text'
            field={sales.cycle_fee}/>
          </div>
          <div className="kb-form-group kb-div-c18">
            <KBInput
              title='备注'
              type='text'
              field={sales.note}/>
          </div>
          <span
            className="c-pointer"
            onClick={() => {
              removePeriodicCharge(sales,index)
            }}
            style={{ fontSize: 16 }}
          >
            <i className="iconfont icon-close a-hover" />
          </span>
        </div>
      })}
      </div>
  }
})

export default PeriodicChargeList
