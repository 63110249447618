import React, { Component } from 'react'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { api, apiUtils } from 'app/services'
import { KBPopover, KBPopoverTop, KBError, KBSuccess } from 'components'
import { InitialHighCharts } from 'components/mixins'
import { percent } from 'utils/kbUtil'
import { extendDefaultHighchartsConfig } from 'utils/kbData'
import * as selectors from 'app/selectors'

var MembershipsBox = React.createClass({
  mixins: [InitialHighCharts],

  getInitialState() {
    return {
      data: {},
      dataOut: [],
      customerStateArray: []
    }
  },

  componentDidMount() {
    // let Highcharts = this.getHighcharts()

    this.mounted = true

    this.loadData()
    // this.renderChart('Chart', extendDefaultHighchartsConfig({
    //   chart : {
    //     plotBackgroundColor : null,
    //     plotBorderWidth     : null,
    //     plotShadow          : false,
    //     type                : 'pie'
    //   },
    //   title : {
    //     text : null
    //   },
    //   tooltip : {
    //     pointFormat : '所占比率: <b>{point.percentage:.1f}%</b>'
    //   },
    //   plotOptions : {
    //     pie : {
    //       allowPointSelect : false,
    //       cursor           : 'pointer',
    //       dataLabels       : false
    //     }
    //   }
    // }))
  },

  componentWillUnmount() {
    this.mounted = false
  },

  loadData() {
    // api.getMembershipDashboard().then(({json})=> {
    //   const { entered_user_count, membership_user_count, normal_user_count } = json
    //   let data = [
    //     {
    //       name: '常驻会员',
    //       value: entered_user_count || 0
    //     },{
    //       name: '非会员',
    //       value: normal_user_count || 0
    //     },{
    //       name: '会员卡',
    //       value: membership_user_count || 0
    //     }
    //   ]
    //   this.parseHightChartData(data)
    //   this.setState({
    //     data: json
    //   })
    // })
  },

  parseHightChartData(data) {
    let colors = this.getHighChartColor()
    let Highcharts = this.getHighcharts()
    let dataOut = []
    let count = 0

    data.forEach(json => {
      count = count + json.value
    })
    let dt = {
      colorByPoint: true,
      size: '100%',
      innerSize: '80%',
      data: []
    }
    data.forEach((json, index) => {
      let y = parseFloat(percent(count, json.value))
      let name = json.name
      let color = colors[index]

      dt.name = name
      dt.data.push({
        name: name,
        y: y,
        color: color
      })
    })
    dataOut.push(dt)

    if (this.mounted) {
      this.setState({
        dataOut
      })
    }

    this.fillChart(dataOut)
  },

  fillChart(dataOut) {
    this.setSerierDate(dataOut)
  },

  //   <Link to={`/admin/memberships/memberManage/membership_orders`} className="order">
  //   <div className="order-bj"/>
  //   <span className="title">订单</span>
  // </Link>

  toPercentage(str) {
    let a = str.toFixed(2)
    let b = a.slice(2, 4) + '%'
    let c = a.substr(2, 1)
    let d = a.substr(3, 1)
    if (c == 0) {
      b = d + '%'
    }
    return b
  },

  render() {
    const {
      data: { entered_user_count, membership_user_count, normal_user_count }
    } = this.state
    let total = entered_user_count + membership_user_count + normal_user_count
    let entered_user_percentage =
      entered_user_count && this.toPercentage(entered_user_count / total)
    let membership_user_percentage =
      membership_user_count && this.toPercentage(membership_user_count / total)
    let normal_user_percentage =
      normal_user_count && this.toPercentage(normal_user_count / total)

    return (
      <section className="kb-content">
        <div className="kb-content-container clear-fix">
          <div
            className="nav-section-header-title border-bottom"
            style={{ padding: '20px 30px' }}
          >
            <span>会员制</span>
          </div>
          {/** <div className="member-number-chart">
            <div className="member-percentage">
              <div>
                <div ref='highcharts' style={ {width: 250, height: 250, marginRight: 30} }/>
              </div>
              <div>
                <div className="title">会员入驻趋势图</div>
                <div className="member-type">
                  <div>
                    <div className="type-name">
                      <i style={{backgroundColor: '#7cb5ec'}}/>
                      <span>常驻会员</span>
                    </div>
                    <span>{ entered_user_percentage }</span>
                  </div>
                  <div>
                    <div className="type-name">
                      <i style={{backgroundColor: '#90ed7d'}}/>
                      <span>会员卡</span>
                    </div>
                    <span>{ membership_user_percentage }</span>
                  </div>
                  <div>
                    <div className="type-name">
                      <i style={{backgroundColor: '#434348'}}/>
                      <span>非会员</span>
                    </div>
                    <span>{ normal_user_percentage }</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="member-chart">
              <div className="member-type-num">
                <span>{ entered_user_count || 0 }</span>
                <span>常驻会员</span>
              </div>
              <div className="member-type-num">
                <span>{ membership_user_count || 0 }</span>
                <span>会员卡</span>
              </div>
              <div className="member-type-num">
                <span>{ normal_user_count || 0 }</span>
                <span>非会员</span>
              </div>
            </div>
    </div> */}
          <div className="member-navigation">
            <Link
              to={`/admin/memberships/memberManage/members`}
              className="member_special"
            >
              <div className="member-bj" />
              <span className="title">会员</span>
            </Link>
            {/** <Link to={`/admin/memberships/memberManage/cards`} className="card">
              <div className="card-bj"/>
              <span className="title">会员卡</span>
  </Link> */}
            {/* <Link to={`/admin/memberships/memberManage/resources`} className="resource">
              <div className="resource-bj"/>
              <span className="title">资源包</span>
            </Link> */}
            <Link
              to={`/admin/memberships/memberManage/announcements`}
              className="announcement"
            >
              <div className="announcement-bj" />
              <span className="title">公告</span>
            </Link>
            <Link
              to={`/admin/memberships/memberManage/messages`}
              className="message"
            >
              <div className="message-bj" />
              <span className="title">消息</span>
            </Link>
            <Link
              to={`/admin/memberships/memberManage/pointsRecord`}
              className="detail"
            >
              <div className="detail-bj" />
              <span className="title">
                {this.props.virtual_currency_name}明细
              </span>
            </Link>
            <Link
              to={`/admin/memberships/memberManage/activities`}
              className="detail"
            >
              <div className="detail-bj" />
              <span className="title">活动</span>
            </Link>
            <Link
              to={`/admin/memberships/memberManage/news`}
              className="detail"
            >
              <div className="detail-bj" />
              <span className="title">资讯</span>
            </Link>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)
  return {
    virtual_currency_name
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipsBox)
