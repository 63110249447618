import React, { Component } from 'react'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import * as selectors from '../../app/selectors'
import { KBPopover, KBPopoverTop, KBError, KBSuccess } from 'components'

var MembershipsBox = React.createClass({
  componentDidMount() {},
  componentWillReceiveProps(nextProps) {},
  render() {
    return <div className="kb-out-box clear-fix">{this.props.children}</div>
  }
})

export default MembershipsBox
