import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  KBSearchList,
  KBPopover,
  KBLoadingContainer,
  KBPagination,
  KBDropdown,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import { KBDropSelect } from 'components/plugins'
import { MembersList } from 'components'
import { usersActions, orgActions } from 'app/actions'
import {
  MEBMER_GENDERS_FORM,
  MEMBER_AGE,
  MEMBER_GENDER_OBJ,
  DUTIES_TYPE_OBJ,
  DUTIES_TYPE_FORM
} from 'app/constants'
import { connect } from 'react-redux'
import {
  util,
  paginate,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown
} from 'utils/kbUtil'
import { canAccessLocation, canAdminLocationWeChat } from 'app/reducers/role'
import * as selectors from 'app/selectors'
import SendMessageForm from '../spaces/LocationSendMessageForm'

const MEMBER_AGE_OBJ = {
  18: '18-22',
  23: '23-26',
  27: '27-35',
  36: '35岁以上'
}

const ORG_ROLES = [
  {
    title: '公司负责人',
    value: 'employer'
  },
  {
    title: '公司一般成员',
    value: 'employee'
  }
]
const ORG_ROLES_OBJ = {
  employer: '公司负责人',
  employee: '公司一般成员'
}

const DROP_DOWNS = [
  'dutiesDropdown',
  'genderDropdown',
  'ageDropdown',
  'orgsDropdown',
  'roleDropdown'
]

var LocationMembers = React.createClass({
  displayName: 'LocationMembers',
  getInitialState() {
    const { filters, org_id } = this.props
    return {
      loading: false,
      pagination: null, // local state for pagination
      users: null, // local state for user list
      filters,
      selectedOrgId: org_id || ''
    }
  },

  _loadData(page, newFilters) {
    const { routerActions, query, loc_id } = this.props
    const { filters } = this.state
    const pageObj = {
      page: page || this.props.page || 1,
      per_page: 50
    }

    const queryFiltersStr = queryFilter({
      ...query,
      ...filters,
      ...newFilters,
      ...pageObj
    })
    const params = Object.assign(
      {},
      pageObj,
      filters,
      newFilters,
      { location_id: loc_id },
      { includes: 'space_users', user_type: 'entered' }
    )

    this.setState({ loading: true })

    api
      .getUsers(params)
      .then(
        ({ json, response }) => {
          let pagination = paginate(response)
          this.props.getUsersActions.success({ json }, { location_id: loc_id })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/locations/${loc_id}/members${queryFiltersStr}`
          )
          this.setState({ pagination: pagination, users: json.entities.users })
        },
        errors => {
          this.props.getUsersActions.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  componentDidMount() {
    this.mounted = true
    this.refreshMember()
    this.loadOrgs()
  },

  loadOrgs() {
    const { loc_id, getOrgAction, orgs } = this.props
    orgs.length === 0 &&
      api
        .getOrganizations({
          location_id: loc_id,
          per_page: 1000,
          organization_type: 'normal'
        })
        .then(
          json => {
            getOrgAction.success(json, { location_id: loc_id })
          },
          errors => {
            getOrgAction.failure(errors)
          }
        )
  },

  refreshMember() {
    this._loadData()
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this._loadData(nextProps.page)
    }
  },

  componentWillUnmount() {
    this.mounted = false
    this.setState({ loading: false })
  },

  selectOrg(org) {
    const organization_id = org && org.id

    this._setFilter({ organization_id })
    this.setState({ selectedOrgId: organization_id })
    this.closeDropDowns()
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    Object.keys(obj).map(key => {
      if (!obj[key]) {
        delete newFilters[key]
      }
    })

    this.setState({ filters: newFilters, page: 1 })

    // force refresh
    this._refresh(newFilters)
  },

  _clearFilters() {
    this.setState({ filters: {}, selectedOrgId: '' })
    this._refresh({})
  },

  _refresh(newFilters) {
    this['genderDropdown'] && this['genderDropdown'].hide()
    this['ageDropdown'] && this['ageDropdown'].hide()
    this['dutiesDropdown'] && this['dutiesDropdown'].hide()
    this['roleDropdown'] && this['roleDropdown'].hide()
    setTimeout(this._loadData.bind(null, 1, newFilters))
  },

  sendMessage() {
    const { filters } = this.state
    const { loc_id } = this.props
    let role = filters.role ? filters.role : ''
    KBPopover.show(
      <SendMessageForm memberType={'member'} role={role} loc_id={loc_id} />
    )
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="会员导出"
        message="会员已成功导出，可以前往报表中心下载"
      />
    )
  },

  memberReport() {
    const { loc_id } = this.props
    return api.memberReport({ location_id: loc_id }).then(() => {
      this.toReportCenter && this.toReportCenter()
    })
  },

  _renderHeader() {
    const { isManager } = this.props

    return (
      <header className="nav-header">
        <div className=" clear-fix">
          <div className="nav-section-header-title f-left">
            <span>所有会员</span>
          </div>
          {isManager ? (
            <button className="bordered-btn f-right" onClick={this.sendMessage}>
              发送消息
            </button>
          ) : null}
          {isManager ? (
            <div className="f-right">
              <button className="c-btn-secondary" onClick={this.memberReport}>
                导出会员
              </button>
            </div>
          ) : null}
        </div>
      </header>
    )
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  getUser(str, callback) {
    const { loc_id } = this.props
    util.setTimeout(
      'membersSearchInput',
      () => {
        api
          .getSearchAllType({ query: str, type: 'user', location_id: loc_id })
          .then(({ json }) => {
            callback && callback(json)
          })
      },
      0
    )
  },

  _selectCallBack(data) {
    const { routerActions } = this.props
    data && routerActions.push(`/admin/members/${data.id}`)
  },

  render() {
    const { orgs, loc_id, org_id, allUsers } = this.props
    const { filters, selectedOrgId } = this.state
    let users = this.state.users && Object.values(this.state.users)
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    let selectedOrg =
      orgs &&
      orgs.filter(org => {
        return org.id === selectedOrgId
      })

    return (
      <section className="kb-content">
        <section className="kb-content-container">
          {this._renderHeader()}
          <div className="nav-section-content-container">
            <div className="m-top-sm">
              <KBDropdown
                ref={ref => {
                  this.genderDropdown = ref
                }}
                style={{ marginRight: 20 }}
              >
                <KBDropdown.KBDropdownTrigger>
                  <div
                    className="task-status-title"
                    onClick={this.closeDropDowns.bind(null, 'genderDropdown')}
                  >
                    <span>
                      {filters.gender
                        ? MEMBER_GENDER_OBJ[filters.gender]
                        : '选择性别'}
                    </span>
                    <span className="task-deg" />
                  </div>
                </KBDropdown.KBDropdownTrigger>
                <KBDropdown.KBDropdownContent>
                  <div>
                    <ul className="task-type">
                      <li onClick={() => this._setFilter({ gender: '' })}>
                        全部
                      </li>
                      {MEBMER_GENDERS_FORM.map((state, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this._setFilter({ gender: state.value })
                            }
                          >
                            <span>{state.title}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </KBDropdown.KBDropdownContent>
              </KBDropdown>
              {/* <KBDropdown  ref={ ref => { this.ageDropdown = ref } } style={{marginRight : 20}}>
                    <KBDropdown.KBDropdownTrigger>
                      <div className='task-status-title' onClick={ this.closeDropDowns.bind(null, 'ageDropdown') }>
                        <span>{ filters.age_more_than ? MEMBER_AGE_OBJ[filters.age_more_than] : '选择年龄段' }</span>
                        <span className='task-deg' />
                      </div>
                    </KBDropdown.KBDropdownTrigger>
                    <KBDropdown.KBDropdownContent>
                      <div>
                        <ul className='task-type'>
                          { MEMBER_AGE.map((state, index) => {
                            return (
                              <li key={ index } onClick={()=>this._setFilter(state.value)}>
                                <span>{state.name}</span>
                              </li>
                            )})
                          }
                        </ul>
                      </div>
                    </KBDropdown.KBDropdownContent>
                  </KBDropdown> */}
              <div className="select-progress">
                <KBDropSelect
                  selectedData={selectedOrg[0]}
                  ref={ref => {
                    this.orgsDropdown = ref
                  }}
                  options={{ defaultSingleContent: '选择公司' }}
                  defaultData={orgs}
                  multi={false}
                  showImg={true}
                  callback={this.selectOrg}
                  dropDown_name="orgsDropdown"
                  closeClick={this.closeDropDowns}
                  style={{ marginLeft: 0 }}
                />
              </div>
              <KBDropdown
                ref={ref => {
                  this.roleDropdown = ref
                }}
                style={{ marginRight: 20 }}
              >
                <KBDropdown.KBDropdownTrigger>
                  <div
                    className="task-status-title"
                    onClick={this.closeDropDowns.bind(null, 'roleDropdown')}
                  >
                    <span>
                      {filters.role ? ORG_ROLES_OBJ[filters.role] : '选择职责'}
                    </span>
                    <span className="task-deg" />
                  </div>
                </KBDropdown.KBDropdownTrigger>
                <KBDropdown.KBDropdownContent>
                  <div>
                    <ul className="task-type">
                      <li onClick={() => this._setFilter({ role: '' })}>
                        全部
                      </li>
                      {ORG_ROLES.map((state, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() =>
                              this._setFilter({ role: state.value })
                            }
                          >
                            <span>{state.title}</span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </KBDropdown.KBDropdownContent>
              </KBDropdown>
              <KBSearchList
                ref="dropDownOrg"
                getUsers={this.getUser}
                selectCallBack={this._selectCallBack}
                placeholder="会员搜索"
                style={{ display: 'inline-block', width: '300px' }}
                itemWidth="full"
              />
            </div>
            {selectFilters.length > 0 ? (
              <div
                className="clear-criteria"
                style={{ marginTop: 5 }}
                onClick={this._clearFilters}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : null}
            <KBLoadingContainer loading={this.state.loading}>
              <MembersList
                isLocation={true}
                refreshMember={this.refreshMember}
                loc_id={loc_id}
                org_id={org_id}
                users={allUsers || []}
              />
              <KBPagination
                pagination={this.state.pagination}
                template={`/admin/locations/${loc_id}/members?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </section>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { user } = state

  let loc_id = parseInt(params.id, 10)
  let org_id = parseInt(location.query.organization_id, 10)
  let orgs = selectors.getOrgsOfLocation(state, loc_id)
  let allUsers = selectors.getAllUsers(state)

  let org = orgs && orgs.find(org => org.id == org_id)
  let page = (location.query && parseInt(location.query.page, 10)) || 1

  const FILTER_STATE = [
    'location_id',
    'gender',
    'age_more_than',
    'age_less_than',
    'role',
    'organization_id'
  ]
  const allParams = getFilterAndQuery(location.query, FILTER_STATE)
  let isManager = canAccessLocation(user, loc_id) || canAdminLocationWeChat(user, loc_id)

  return {
    loc_id,
    org_id,
    org,
    orgs,
    page,
    ...allParams,
    allUsers,
    isManager
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getUsersActions: bindActionCreators(usersActions.replace, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    getOrgAction: bindActionCreators(orgActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationMembers)
