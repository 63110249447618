import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import * as selectors from 'app/selectors'
import { KBPopover, KBTipsy, KBDropdown } from 'components'
import {
  MEMBERSHIP_RESOURCE_UNIT,
  MEMBERSHIP_RESOURCE_TYPE_OBJ
} from 'app/constants'
import * as actions from 'app/actions'
import { connect } from 'react-redux'
import SpaceAddMemberShipSource from './SpaceAddMemberShipSource'

var menu = [
  {
    name: '工位',
    value: 'desk'
  },
  {
    name: '会议室',
    value: 'booking'
  },
  {
    name: '打印机',
    value: 'printing'
  }
]

var MembershipCards = React.createClass({
  displayName: 'MembershipCards',
  getInitialState() {
    return {
      type: 'desk'
    }
  },
  componentDidMount() {
    const { type } = this.state
    this.getData(type)
  },
  getData(newType) {
    const { replaceMembershipResourceAction } = this.props
    this.setState({
      type: newType
    })
    api
      .getMembershipResources({
        resource_type: newType,
        per_page: 20
      })
      .then(
        res => {
          if (!res.json.entities.membershipResources) {
            res.json.entities.membershipResources = {}
          }
          replaceMembershipResourceAction.success(res, {
            entityName: 'membershipResources'
          })
        },
        err => {
          replaceMembershipResourceAction.failure(err)
        }
      )
  },
  updateMembership(membership, e) {
    const { resource_type, id } = membership
    KBPopover.show(
      <SpaceAddMemberShipSource
        status="update"
        id={id}
        resource_type={resource_type}
      />
    )
    // this.props.routerActions.push(`/admin/memberships/memberManage/resources/setting?status=update&id=${id}&resource_type=${ resource_type }`)
  },
  deleteMembership(membership, e) {
    const { id, name, resource_type } = membership
    const { deleteMembershipsResourceAction, successAction } = this.props
    KBPopover.plugins.confirm(
      '删除会员制资源',
      `您是否确定删除 ${name} 会员制资源?`,
      {
        confirm: () => {
          api.deleteMemberShipsResource(id, { resource_type }).then(
            json => {
              deleteMembershipsResourceAction.success(json, {
                id,
                key: 'membershipResources'
              })
              successAction({ message: '删除会员制资源成功!' })
              KBPopover.close()
            },
            e => {
              deleteMembershipsResourceAction.failure(e)
            }
          )
        }
      }
    )
  },
  addMembershipSource() {
    // this.props.routerActions.push('/admin/memberships/memberManage/resources/setting')
    KBPopover.show(<SpaceAddMemberShipSource />)
  },

  render() {
    const { membershipResources } = this.props
    const { type } = this.state
    return (
      <div className="nav-section-container f-right">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <span>资源包</span>
          </div>
          <button
            className="bordered-btn f-right"
            onClick={this.addMembershipSource}
          >
            <i className="iconfont icon-add" />
            新增会员制资源
          </button>
        </header>
        <div className="nav-section-content-container">
          <div className="m-updown">
            <ul className="nav-section-tab">
              {menu.map((json, index) => {
                return (
                  <li
                    key={index}
                    className={`kb-tabs-menu-item ${
                      type == json.value ? 'nav-section-tab-active' : ''
                    }`}
                    onClick={this.getData.bind(null, json.value)}
                  >
                    <a>{json.name}</a>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="clear-fix">
            {membershipResources.map(membership => {
              const { id } = membership
              return (
                <div
                  className="member-resource-content"
                  onClick={this.updateMembership.bind(null, membership)}
                >
                  <i
                    className="iconfont icon-delete"
                    onClick={e => {
                      e.stopPropagation()
                      this.deleteMembership(membership)
                    }}
                  />
                  <div className="title">
                    <span>{membership.name}</span>
                  </div>
                  <div className="resource-message">
                    <span className="price">{membership.price}元</span>
                    <span className="type">
                      {MEMBERSHIP_RESOURCE_TYPE_OBJ[membership.resource_type]}:{' '}
                      {membership.available_credits}
                      {MEMBERSHIP_RESOURCE_UNIT[membership.resource_type]}
                    </span>
                  </div>
                  <div className="resource-date">
                    <span>有效期 {membership.duration_months}个月</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  // const state
  const membershipResources = selectors.getMembershipResources(state)
  return {
    membershipResources
  }
}

function mapDispatchToProps(dispatch) {
  return {
    replaceMembershipResourceAction: bindActionCreators(
      actions.membershipResourcesAction.replace,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    deleteMembershipsResourceAction: bindActionCreators(
      actions.membershipResourcesAction.delete,
      dispatch
    ),
    successAction: bindActionCreators(actions.successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipCards)
