import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { meetingDeviceMessageActions, apiCallFailure } from 'app/actions'
import { api, apiUtils } from 'app/services'
import {
  MEETING_THEME_CATEGORY_ARRAY,
  MEETING_THEME_UPLOAD_ARRAY
} from 'app/constants'
import {
  KBInput,
  KBPopover,
  KBSelect,
  KBFormError,
  KBButton,
  KBRadio,
  KBUserInput,
  KBMoreFileUpload
} from 'components'
import * as valid from 'utils/validate'

var MeetingThemeForm = React.createClass({
  getInitialState() {
    return {
      messageState: null
    }
  },

  addThemeForm(model) {
    const {
      deviceMessage,
      loc_id,
      updateMeetingDeviceActions,
      apiCallFailureActions
    } = this.props
    const { messageState } = this.state
    let deviceStorage = deviceMessage.storage - 50
    model.location_id = loc_id
    var fileSize = 0
    if (messageState) {
      for (let i = 0; i < messageState.length; i++) {
        fileSize += messageState[i].size
        if (
          model.uploadType == 'image' &&
          messageState[i].type != 'image/jpeg' &&
            messageState[i].type != 'image/png'
        ) {
          return apiCallFailureActions({
            status: 'error',
            message: '请您选择文件格式为jpg,png的图片!'
          })
        }
        if (
          model.uploadType == 'video' &&
          messageState[i].type != 'video/mp4'
        ) {
          return apiCallFailureActions({
            status: 'error',
            message: '请您选择文件格式为mp4的视频!'
          })
        }
      }
      if (fileSize / 1000000 >= deviceStorage) {
        return apiCallFailureActions({
          status: 'error',
          message: '您上传的内容超出会议室设备可用容量!'
        })
      }
    }
    return api
      .updateMeetingDeviceMessage(deviceMessage.device_id, model)
      .then(json => {
        updateMeetingDeviceActions.success(json)
        KBPopover.close()
      })
  },
  messageCallback(message) {
    this.setState({
      messageState: message
    })
  },

  render() {
    const {
      fields: { assets_ary, theme_type, device_name, uploadType },
      error,
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.addThemeForm)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">修改设备</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={device_name}
              title="设备名称"
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="主题"
              field={theme_type}
              arrayData={MEETING_THEME_CATEGORY_ARRAY}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="类型"
              field={uploadType}
              arrayData={MEETING_THEME_UPLOAD_ARRAY}
            />
          </div>
          {uploadType.value == 'image' ? (
            <div className="kb-row">
              <KBMoreFileUpload
                title="上传图片"
                fileType={uploadType.value}
                hint="格式为png或jpg,大小不大于10M"
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={assets_ary}
                text="上传图片"
                messageCallback={this.messageCallback}
                prefix="images/meeting/"
                accept="image/*"
              />
            </div>
          ) : (
            <div className="kb-row">
              <KBMoreFileUpload
                title="上传视频"
                fileType={uploadType.value}
                hint="允许上传的视频格式为mp4"
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={assets_ary}
                text="上传视频"
                messageCallback={this.messageCallback}
                prefix="images/meeting/"
                accept="video/*"
              />
            </div>
          )}
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
          </div>
        </div>
      </form>
    )
  }
})

//

function mapStateToProps(state, props) {
  const { deviceMessage } = props

  return {
    initialValues: {
      device_name: deviceMessage.device_name,
      theme_type: deviceMessage.theme_type,
      uploadType: 'image',
      assets_ary: deviceMessage.assets
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateMeetingDeviceActions: bindActionCreators(
      meetingDeviceMessageActions.update,
      dispatch
    ),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.device_name)) {
    errors.device_name = '请输入设备名称'
  }

  if (valid.required(values.theme_type)) {
    errors.theme_type = '请选择主题'
  }

  return errors
}

const formConfig = {
  form: 'MeetingThemeForm',
  fields: [
    'assets_ary[].category',
    'assets_ary[].url',
    'theme_type',
    'device_name',
    'uploadType'
  ],
  validate: validate,
  touchOnBlur: false
}

MeetingThemeForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(MeetingThemeForm)

export default MeetingThemeForm
