import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'

const menu = [
  {
    name: 'CRM统计',
    value: ''
  },
  {
    name: '报修统计',
    value: 'task'
  },
  {
    name: '会议室统计',
    value: 'meeting'
  }
]

var SpaceLocationStatisticBox = React.createClass({
  displayName: 'SpaceLocationStatisticBox',
  componentDidMount() {},
  render() {
    const { section, location_id } = this.props

    return (
      <section className="kb-content">
        <nav className="nav-container f-left">
          <div className="nav-container-content">
            <header className="nav-header clear-fix">
              <h2 className="header-title f-left">统计报表</h2>
            </header>
            <div className="nav-body">
              <ul className="nav-container-list">
                {menu.map((data, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        section == data.value ? 'nav-container-list-hover' : ''
                      }
                    >
                      <Link
                        to={`/admin/locations/${location_id}/statistic/${data.value}`}
                      >
                        {' '}
                        {data.name}{' '}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </nav>
        {this.props.children}
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let sections = props.location.pathname.split('/')
  let section = sections.length > 4 ? sections[5] : ''
  let location_id = props.params.id
  return {
    section: section || '',
    location_id
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpaceLocationStatisticBox)
