import React from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { KBLoadingContainer, KBPopover } from 'components'
import { lockerCategoriesAction } from 'app/actions'
import * as selectors from 'app/selectors'
import LockerCategoryForm from './LockerCategoryForm'
import { KBPopoverConfirm } from 'components/tools'
import intl from 'react-intl-universal'

var LockerCategories = React.createClass({
  getInitialState() {
    return {
      loading: false
    }
  },

  componentDidMount() {},

  addLockerCategory() {
    const { loc_id, currency } = this.props

    KBPopover.show(
      <LockerCategoryForm location_id={loc_id} currency={currency} />
    )
  },

  editLockerCategory(category) {
    const { loc_id, currency } = this.props

    KBPopover.show(
      <LockerCategoryForm
        location_id={loc_id}
        type="update"
        category={category}
        currency={currency}
        initialValues={{
          name: category.name,
          price: category.price,
          capacity: category.capacity
        }}
      />
    )
  },

  deleteLockerCategory(category) {
    const { deleteLockerCategoriesAction } = this.props

    KBPopoverConfirm({
      name: '删除',
      context: `确定删除${category.name}规格么?`,
      callback: () => {
        return api.deleteLockerCategories(category.id).then(
          json => {
            deleteLockerCategoriesAction.success(json, {
              key: 'lockerCategories',
              id: category.id
            })
            KBPopover.close()
          },
          error => {
            deleteLockerCategoriesAction.failure(error)
          }
        )
      }
    })
  },

  render() {
    const { lockerCategories, currency } = this.props
    const { loading } = this.state

    return (
      <div className="kb-content-container clear-fix">
        <header className="nav-header">
          <div className=" clear-fix">
            <div className="nav-section-header-title f-left">
              <span>规格管理</span>
            </div>
            <div
              className="bordered-btn f-right"
              onClick={this.addLockerCategory}
            >
              添加规格
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          <KBLoadingContainer loading={loading}>
            <div className="m-top">
              {lockerCategories &&
                lockerCategories.map((category, index) => {
                  return (
                    <div className="lockerCategory" key={index}>
                      <div className="categoryTitle">{category.name}</div>
                      {/* <div className='categorySize'>大小: 70*120*79cm</div> */}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div className="categoryPrice">
                          {currency}
                          {category.price}/分钟
                        </div>
                        <div>
                          <i
                            className="iconfont icon-delete"
                            onClick={() => {
                              this.deleteLockerCategory(category)
                            }}
                          />
                          <i
                            className="iconfont icon-edit"
                            onClick={() => {
                              this.editLockerCategory(category)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const loc_id = params.id
  const currency = selectors.getLocationCurrency(state, loc_id)
  const lockerCategories = selectors.getLockerCategories(state)

  return {
    currency,
    loc_id,
    lockerCategories
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLockerCategoriesAction: bindActionCreators(
      lockerCategoriesAction.replace,
      dispatch
    ),
    deleteLockerCategoriesAction: bindActionCreators(
      lockerCategoriesAction.delete,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockerCategories)
