import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { reportActions } from 'app/actions'
import { api, apiUtils } from 'app/services'
import classNames from 'classnames'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { KBLoadingContainer } from 'components'
import _ from 'lodash'

const menu = [
  {
    name: '申请列表',
    value: ''
  }
]

var SpaceReportBox = React.createClass({
  displayName: 'SpaceReportBox',
  render() {
    const { section } = this.props
    const isLocation = window.location.href.includes('/admin/locations/')

    return (
      <div className="kb-out-box clear-fix" style={{
        paddingTop: isLocation ? 0 : 60
      }}>
        <section className="kb-content">
          {/* <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">自助认证</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menu.map((data, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          section == data.value
                            ? 'nav-container-list-hover'
                            : ''
                        }
                      >
                        <Link to={`/admin/autoAuth/${data.value}`}>
                          {' '}
                          {data.name}{' '}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav> */}
          {this.props.children}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let sections = location.pathname.split('/')
  let section = sections.length > 2 ? sections[3] : ''

  return {
    section: section || ''
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getReportActions: bindActionCreators(reportActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceReportBox)
