import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { KBLoadingContainer, KBAvatar, KBTipsy } from 'components'
import * as selectors from 'app/selectors'
import { NOTE_HANDLE } from 'app/constants'
import { formatYearDaySOL } from 'utils/kbUtil'
import { getUserInfoUrl } from 'utils/kbUrl'

var OrgNoteList = React.createClass({
  renderContent(note) {
    const { org_id, location_id, org_members } = this.props

    if (note.handle + '_' + note.obj == 'join_OrganizationUser') {
      let org_num =
        org_members && org_members.find(num => num.id == note.obj_id)
      if (!org_num) {
        return (
          <span className="content" style={{ marginTop: '10px' }}>
            {note.content}
          </span>
        )
      }
      return (
        <div className="content" style={{ marginTop: '10px' }}>
          添加新员工{' '}
          <Link to={getUserInfoUrl(note.obj_id)} className="color-link">
            <span>{org_num.name}</span>
          </Link>
        </div>
      )
    }
    if (note.obj == 'SalesSubscription') {
      return (
        <div className="content" style={{ marginTop: '10px' }}>
          <Link
            to={`/admin/locations/${location_id}/organizations/${org_id}/subscriptions/${note.obj_id}`}
            className="color-link"
          >
            <span>{note.content}</span>
          </Link>
        </div>
      )
    }
    return (
      <div className="content" style={{ marginTop: '10px' }}>
        {note.content}
      </div>
    )
  },

  render() {
    const { notes } = this.props
    return (
      <div className="nav-comment-container">
        {notes &&
          notes.map(note => {
            let creator = note.creator
            return (
              <div
                className="nav-comment-content comment-timeline"
                key={note.id}
              >
                <div
                  className="time-line-icon"
                  style={{
                    backgroundColor:
                      NOTE_HANDLE[note.handle + '_' + note.obj] &&
                      NOTE_HANDLE[note.handle + '_' + note.obj].color
                  }}
                >
                  {
                    <i
                      className={`iconfont ${NOTE_HANDLE[
                        note.handle + '_' + note.obj
                      ] && NOTE_HANDLE[note.handle + '_' + note.obj].class}`}
                    />
                  }
                </div>
                <div style={{ marginLeft: 44 }}>
                  <div style={{ display: 'inline-block' }}>
                    <span className="publish-time">
                      {formatYearDaySOL(note.created_at)}
                    </span>
                    <span style={{ color: '#000' }} className="publish-name">
                      {(creator && creator.name) || ''}
                    </span>
                  </div>
                  {this.renderContent(note)}
                </div>
              </div>
            )
          })}
      </div>
    )
  }
})

OrgNoteList.PropTypes = {
  notes: PropTypes.array.isRequired
}

function mapStateToProps(state, props) {
  let org_id = props.org_id

  const org_subscriptions = state.entities.subscriptions
  const org_members = selectors.getMembersOfOrg(state, org_id)
  return {
    org_members,
    org_subscriptions
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgNoteList)
