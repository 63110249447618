import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import classNames from 'classnames'
import {
  subscriptionActions,
  orgActions,
  successState,
  apiCallFailure,
  leaveEnterOptionActions,
  taxesAction
} from 'app/actions'
import { api, apiUtils } from 'app/services'
import {
  KBDateInput,
  KBTextarea,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover
} from 'components'
import { TERMINATE_REASON } from 'app/constants'
import * as selectors from 'app/selectors'
import OrgOffHireForm from './OrgOffHireForm'
import OrganizationOptionsRow from './OrganizationOptionsRow'
import OrgOffHireSubDetail from './OrgOffHireSubDetail'
import OrgOffHireAdjust from './OrgOffHireAdjust'
import { toPercent } from 'utils/kbUtil'
import _ from 'lodash'

var menuTop = [
  { index: 1, name: '终止时间' },
  { index: 2, name: '合同信息' },
  { index: 3, name: '待办事项' },
  { index: 4, name: '账款调整' }
]
const START_INDEX = 1
const END_INDEX = 4

var OrgOffHireGuideForm = React.createClass({
  getInitialState() {
    return {
      // 第一页的menuIndex
      menuIndex: START_INDEX,
      options: this.props.leaveOrEnterOptions.filter(option => {
        return option.cate == 'archive'
      }),
      execute_at: null
    }
  },

  leaveOrganization(model) {
    const {
      updateSubScriptionAction,
      loc_id,
      org,
      updateOrgActions
    } = this.props
    const { graduation } = this.state
    const org_id = org.id
    delete model.is_tower_location
    var params = Object.assign({}, model)

    params.location_id = loc_id
    params.organization_id = org_id

    if (params.promptly) {
      params.expire_date = null
    }

    params.line_items = params.line_items.filter(json => json.units)

    return api
      .terminateAllSubScriptions(params)
      .then(
        values => {
          updateSubScriptionAction.success(values)
        },
        error => {
          updateSubScriptionAction.failure(error)
        }
      )
      .then(json => {
        if (graduation) {
          return api
            .archiveOrganization(loc_id, org_id, {
              archive_at: model.archive_at
            })
            .then(
              json => {
                updateOrgActions.success(json)
                KBPopover.close()
              },
              error => {
                updateOrgActions.failure(error)
              }
            )
        } else {
          return api
            .getOrganizationInfo(org_id, {
              location_id: loc_id,
              includes:
                'details,subscriptions,invoices,areas,statistics,opportunities,users,balances'
            })
            .then(
              json => {
                updateOrgActions.success(json)
                KBPopover.close()
              },
              error => {
                updateOrgActions.failure(error)
              }
            )
        }
      })
  },

  componentDidMount() {
    const {
      getTaxesAction,
      getSubscriptionActions,
      loc_id,
      org,
      subscriptions,
      fields: { line_items, is_tower_location }
    } = this.props

    api
      .getSubscriptions({
        organization_id: org.id,
        per_page: 100,
        location_id: loc_id,
        subscription_type: 'normal'
      })
      .then(
        json => {
          getSubscriptionActions.success(json)
        },
        error => {
          getSubscriptionActions.failure(error)
        }
      )

    const {
      fields: { terminate_reason, expire_date, archive_at }
    } = this.props

    expire_date.onChange(new Date())
    archive_at.onChange(new Date())
    // terminate_reason.onChange(TERMINATE_REASON[0].id)

    api
      .exitRefundingSubScriptions({
        location_id: loc_id,
        organization_id: org.id
      })
      .then(
        ({ json }) => {
          json.forEach(data => {
            line_items.addField(data)
          })
        },
        error => {
          getSubscriptionActions.failure(error)
        }
      )

    api.getSpaceTaxes().then(
      json => {
        getTaxesAction.success(json)
      },
      error => {
        getTaxesAction.failure(error)
      }
    )
  },

  expirDateChange(day) {
    this.setState({
      execute_at: day
    })
  },

  isGraduation(e) {
    this.setState({
      graduation: e.target.checked
    })
  },
  overTimeJsx() {
    return (
      <OrgOffHireForm
        {...this.props}
        expirDateChange={this.expirDateChange}
        isGraduation={this.isGraduation}
      />
    )
  },

  updateOrganizationOptions(e) {
    const { org, loc_id, updateLeaveEnterOptionActions } = this.props
    const { options, execute_at } = this.state
    let isAllRequiredChecked = true

    options.map(op => {
      if (!op.checked && op.is_required) {
        isAllRequiredChecked = false
      }

      if (!op.checked) {
        return
      }
      return api
        .updateOrganizationOptions(
          org.id,
          op.id,
          execute_at ? { execute_at } : {}
        )
        .then(
          json => {
            updateLeaveEnterOptionActions.success(json)
          },
          error => {
            updateLeaveEnterOptionActions.failure(error)
          }
        )
    })
    return isAllRequiredChecked
  },

  updateOptions(optionId, value) {
    let { options } = this.state
    options.map(op => {
      if (op.id == optionId) {
        op.checked = value
      }
      return op
    })
    this.setState({
      options
    })
  },

  optopnsJsx() {
    const { loc_id, org } = this.props
    var leaveOrEnterOptions = this.props.leaveOrEnterOptions
    leaveOrEnterOptions = leaveOrEnterOptions.filter(option => {
      return option.cate == 'archive'
    })

    return (
      <div className="kb-form-container">
        {leaveOrEnterOptions.length ? (
          <div>
            <h2 className="header-title" style={{ marginBottom: '10px' }}>
              退租待办事项
            </h2>
            <fieldset className="fieldset">
              <ul>
                {leaveOrEnterOptions.map((option, index) => {
                  return (
                    <OrganizationOptionsRow
                      key={index}
                      option={option}
                      location_id={loc_id}
                      org={org}
                      updateOptions={this.updateOptions}
                    />
                  )
                })}
              </ul>
            </fieldset>
          </div>
        ) : (
          <div>暂无退租待办事项</div>
        )}
      </div>
    )
  },

  selectMenu(index) {
    if (!this.checkTerminateReasonValid()) {
      return
    }
    if (index === 4 && !this.validateLeaveOptions()) {
      return
    }

    this.setState({
      menuIndex: index
    })
  },

  preStep() {
    var menuIndex = this.state.menuIndex - 1

    if (menuIndex < START_INDEX) {
      menuIndex = START_INDEX
    }

    this.setState({
      menuIndex
    })
  },

  subscriptionDetailJsx() {
    return <OrgOffHireSubDetail {...this.props} />
  },

  checkTerminateReasonValid() {
    const {
      fields: { terminate_reason, terminate_note },
      apiCallFailureAction
    } = this.props
    if (terminate_reason.invalid || terminate_reason.value == '-1') {
      apiCallFailureAction({
        status: 'error',
        message: '请选择终止原因'
      })
      return false
    }
    if (terminate_note.invalid) {
      apiCallFailureAction({
        status: 'error',
        message: terminate_note.error
      })
      return false
    }
    return true
  },

  validateLeaveOptions() {
    const { apiCallFailureAction } = this.props

    let isAllRequiredChecked = this.updateOrganizationOptions()
    if (!isAllRequiredChecked) {
      apiCallFailureAction({
        status: 'error',
        title: '待办事项',
        message: '您有未完成的退租待办事项'
      })
      return false
    }
    return true
  },

  nextStep() {
    if (!this.checkTerminateReasonValid()) {
      return
    }
    if (this.state.menuIndex === 3 && !this.validateLeaveOptions()) {
      return
    }
    var menuIndex = this.state.menuIndex + 1

    if (menuIndex > END_INDEX) {
      menuIndex = END_INDEX
    }

    this.setState({
      menuIndex
    })
  },

  orgSubAdjust() {
    const {
      fields: { line_items },
      taxes
    } = this.props
    return <OrgOffHireAdjust line_items={line_items} taxes={taxes} />
  },

  render() {
    const { menuIndex, errorMsg, location } = this.state
    const { handleSubmit, submitting } = this.props

    return (
      <div className="clear-fix">
        <form onSubmit={handleSubmit(this.leaveOrganization)}>
          <header className="kb-form-header">
            <h2 className="header-title">退租步骤</h2>
            <i
              className="iconfont header-close icon-cancel"
              onClick={KBPopover.close}
            />
          </header>
          <KBFormError error={this.props.error} />
          <ul className="kb-form-nav">
            {menuTop.map((json, index) => (
              <li
                key={index}
                className={classNames(
                  menuIndex == json.index ? 'kb-nav-hover' : ''
                )}
              >
                <span onClick={this.selectMenu.bind(null, json.index)}>
                  {json.name}
                </span>
                <i className="iconfont icon-navigate_next" />
              </li>
            ))}
          </ul>
          {menuIndex == 1 ? this.overTimeJsx() : ''}
          {menuIndex == 2 ? this.subscriptionDetailJsx() : ''}
          {menuIndex == 3 ? this.optopnsJsx() : ''}
          {menuIndex == 4 ? this.orgSubAdjust() : ''}
          <div
            className="kb-row clear-fix"
            style={{ padding: '0 30px 30px 30px' }}
          >
            <div className="f-left">
              {menuIndex != START_INDEX ? (
                <div className="certain-btn" onClick={this.preStep}>
                  上一步
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="kb-form-fr">
              {menuIndex == END_INDEX ? (
                <KBButton
                  className="certain-btn m-none"
                  type="submit"
                  submitting={submitting}
                >
                  退租
                </KBButton>
              ) : (
                <div className="certain-btn m-none" onClick={this.nextStep}>
                  下一步
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { org } = props
  let subscriptions = selectors.getSubScriptionsByOrg(state, org.id)

  subscriptions = subscriptions.filter(subscription => {
    return subscription.status == 'active' || subscription.status == 'expiring'
  })

  const leaveOrEnterOptions = selectors.getOrgOptionsOfOrgId(state, org.id)

  let taxes = Object.assign([], selectors.getTaxes(state))
  taxes = _.cloneDeep(taxes).map(tax => {
    let name = tax.name
    tax.name = `${name} (税率:${toPercent(tax.rate, 2)})`
    return tax
  })

  return {
    taxes,
    subscriptions,
    leaveOrEnterOptions,
    count: subscriptions.length
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubScriptionAction: bindActionCreators(
      subscriptionActions.update,
      dispatch
    ),
    getSubscriptionActions: bindActionCreators(
      subscriptionActions.get,
      dispatch
    ),
    updateOrgActions: bindActionCreators(orgActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    updateLeaveEnterOptionActions: bindActionCreators(
      leaveEnterOptionActions.update,
      dispatch
    ),
    getTaxesAction: bindActionCreators(taxesAction.get, dispatch)
  }
}

const validateLine = line => {
  const errors = {}
  if (valid.isNumber(line.unit_price)) {
    errors.unit_price = '请输入正确的价格, 小数点保留2位'
  }
  return errors
}

const validate = values => {
  const errors = {}

  if (
    !values.is_tower_location &&
    !values.promptly &&
    valid.required(values.expire_date)
  ) {
    errors.expire_date = '请选择终止日期'
  }

  if (!values.is_tower_location && valid.required(values.terminate_reason)) {
    errors.terminate_reason = '请选择终止原因'
  }

  if (!values.is_tower_location && valid.required(values.terminate_note)) {
    errors.terminate_note = '请填写备注'
  }

  errors.line_items = values.line_items.map(validateLine)

  return errors
}

const formConfig = {
  form: 'OrgOffHireGuideForm',
  fields: [
    'promptly',
    'expire_date',
    'archive_at',
    'terminate_reason',
    'terminate_note',
    'line_items[].units',
    'line_items[].unit_price',
    'line_items[].sales_tax_id',
    'line_items[].line_item_type',
    'line_items[].description',
    'line_items[].refund_type',
    'is_tower_location'
  ],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgOffHireGuideForm)
