import React from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { sectionsActions, spacesSettingAction } from 'app/actions'
import { connect } from 'react-redux'
import { isListValue, getListFirstValue } from 'utils/kbUtil'
import { canAccessSpaceRoles } from 'app/reducers/role'

const menu = [
  {
    name: '分店展示',
    value: ['', 'locations'],
    roles: ['admin', 'faq']
  },
  {
    name: 'FAQ管理',
    value: ['sections'],
    roles: ['admin', 'faq']
  },
  // {
  //   name  : '公告管理',
  //   value : 'announcements',
  //   roles : ['admin', 'faq']
  // },
  // {
  //   name : '消息管理',
  //   value : 'messages',
  //   roles : ['faq']
  // },
  {
    name: '应用管理',
    value: 'application',
    roles: ['admin', 'app']
  }
  // ,{
  //   name : '微信小程序',
  //   value : 'wechat',
  //   roles : ['admin']
  // }
]

var FaqBox = React.createClass({
  displayName: 'FaqBox',
  componentDidMount() {
    const { getSectionsActions } = this.props
    api.getSections().then(
      json => {
        getSectionsActions.success(json)
      },
      errors => {
        getSectionsActions.failure(errors)
      }
    )
  },
  render() {
    const { section, user } = this.props

    return (
      <div className="kb-out-box clear-fix">
        <section className="kb-content">
          <nav className="nav-container f-left">
            <div className="nav-container-content">
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">小程序显示管理</h2>
              </header>
              <div className="nav-body">
                <ul className="nav-container-list">
                  {menu.map((data, index) => {
                    if (!canAccessSpaceRoles(user, data.roles)) {
                      return null
                    }

                    return (
                      <li
                        key={index}
                        className={
                          isListValue(data.value, section)
                            ? 'nav-container-list-hover'
                            : ''
                        }
                      >
                        <Link
                          to={`/admin/faq/${getListFirstValue(data.value)}`}
                        >
                          {' '}
                          {data.name}{' '}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </nav>
          {this.props.children}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { params, location } = props
  let sections = location.pathname.split('/')
  let section = sections.length > 2 ? sections[3] : ''

  return {
    user,
    section: section || ''
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSpaceSettingAction: bindActionCreators(
      spacesSettingAction.get,
      dispatch
    ),
    getSectionsActions: bindActionCreators(sectionsActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqBox)
