import React from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { locksActions, orgActions } from 'app/actions'
import * as selectors from 'app/selectors'
import { connect } from 'react-redux'
import { isListValue, getListFirstValue } from 'utils/kbUtil'

var GroupLockBox = React.createClass({
  displayName: 'GroupLockBox',
  componentDidMount() {
    const { loc_id, getLocksAction, getOrganizationsAction } = this.props

    // 获取门禁列表
    // api.getLocks({ location_id: loc_id, per_page : 2000 }).then(json => {
    //   getLocksAction.success(json)
    // }, errors => {
    //   getLocksAction.failure(errors)
    // })
    //获取公司
    // api.getOrganizations({ location_id: loc_id, per_page: 1000, organization_type: 'normal' }).then(json => {
    //   getOrganizationsAction.success(json, { loc_id })
    // }, errors => {
    //   getOrganizationsAction.failure(errors)
    // })
  },
  render() {
    const { loc_id, section, menu } = this.props
    return (
      <div
        className="kb-out-box clear-fix"
        style={loc_id ? { paddingTop: 0 } : {}}
      >
        <div className="sub-nav-bar" style={{ minWidth: 1450 }}>
          <div style={{ position: 'relative' }}>
            <div className="sub-nav-list clear-fix">
              {menu.map((data, index) => {
                return (
                  <Link key={index} className={'active'}>
                    {' '}
                    {data.name}{' '}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>

        <div className="margin-top-lg">
          <div className="clear-fix" style={{ height: '100%' }}>
            <section className="kb-content-t">{this.props.children}</section>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let sections = location.pathname.split('/')
  let section = sections.length > 4 ? sections[5] || '' : ''

  let menu = [
    {
      name: 'Dashboard',
      value: ['lock_dashboard']
    }
  ]

  return {
    loc_id,
    section,
    menu
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLocksAction: bindActionCreators(locksActions.all, dispatch),
    getOrganizationsAction: bindActionCreators(orgActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupLockBox)
