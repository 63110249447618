import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput
} from 'components'
import { productCategoriesActions, successState } from 'app/actions'
import * as selectors from 'app/selectors'
import * as constants from 'app/constants'

var ProductCategoriesForm = React.createClass({
  createCategories(model) {
    const {
      createProductCategoriesActions,
      updateProductCategoriesActions,
      categories,
      state,
      successAction,
      shopping_id
    } = this.props
    model.shop_id = shopping_id

    if (state) {
      return api.putProductCategories(categories.id, model).then(json => {
        updateProductCategoriesActions.success(json)
        successAction({ message: '修改商品分类成功!' })
        KBPopover.close()
        return true
      })
    }

    return api.postProductCategories(model).then(json => {
      createProductCategoriesActions.success(json)
      successAction({ message: '创建商品分类成功!' })
      KBPopover.close()
      return true
    })
  },

  render() {
    const {
      fields: { name },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { locations, state } = this.props

    return (
      <form onSubmit={handleSubmit(this.createCategories)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改分类' : '创建分类'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="分类名称"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton className="certain-btn" submitting={submitting}>
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { section } = props
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createProductCategoriesActions: bindActionCreators(
      productCategoriesActions.create,
      dispatch
    ),
    updateProductCategoriesActions: bindActionCreators(
      productCategoriesActions.update,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入商品分类名称'
  }

  return errors
}

const formConfig = {
  form: 'ProductCategoriesForm',
  fields: ['name'],
  validate: validate,
  touchOnBlur: false
}

ProductCategoriesForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ProductCategoriesForm)

export default ProductCategoriesForm
