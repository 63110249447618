import React, { Component, PropTypes } from 'react'
import { KBLogo, KBTipsy } from 'components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

var OrgTeamList = React.createClass({
  _toOrgInfo(org) {
    const { routerActions, location_id } = this.props
    routerActions.push(
      `/admin/locations/${location_id}/organizations/${org.id}`
    )
  },

  render() {
    let { org } = this.props
    return (
      <tr onClick={this._toOrgInfo.bind(null, org)}>
        <td>
          {org.name}
          {org.is_important ? (
            <i
              className="iconfont icon-starb"
              style={{ color: 'rgb(249, 216, 59)', marginLeft: 3 }}
            />
          ) : (
              ''
            )}
        </td>
        <td>{org.fund_raising ? org.fund_raising.name : '无'}</td>
        <td>{org.industry && org.industry.name}</td>
        <td>{org.org_users_count || 0}</td>
        <td>{org.face_photos_count || 0}</td>
        <td>{org.desks_count}</td>
        <td>{org.assigned_desks_count}</td>
      </tr>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgTeamList)
