import React, { Component, PropTypes } from 'react'

// Used only for UserAuthWrapper
var PermissionBox = React.createClass({
  displayName: 'PermissionBox',
  render() {
    return this.props.children
  }
})

export default PermissionBox
