import React from 'react'
import * as selectors from 'app/selectors'
import * as valid from 'utils/validate'
import { reduxForm } from 'redux-form'
import {
  KBInput,
  KBPopover,
  KBDateInput,
  KBButton,
  KBMorePhotoUpload
} from 'components'
import {
  formatYearDayEN,
} from 'utils/kbUtil'

var OfflineRefundForm = React.createClass({
  refundOrder(model) {
    const { callBack } = this.props
    callBack &&
      callBack({
        ...model,
      })
    KBPopover.close()
  },

  render() {
    const {
      fields: { invoice_number, invoice_date, pdf_url },
      submitting,
      handleSubmit
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.refundOrder)}>
        <div className="kb-form-header">
          <h2 className="header-title">手工开票</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </div>
        <div className="kb-form-container">
          <div className="kb-row kb-form-66">
            <KBInput
              className="kb-form-group kb-div-c39"
              isRequired
              field={invoice_number}
              title="发票号"
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBDateInput
              className="kb-form-group kb-form-c48"
              title="开票日期"
              isRequired={true}
              field={invoice_date}
            />
          </div>
          <div className="kb-row" style={{ textAlign: 'left' }}>
            <KBMorePhotoUpload
              uploadedText="点击上传"
              photoShow={false}
              sort={false}
              className="kb-form-group kb-form-alone"
              field={pdf_url}
              text={pdf_url.initialValue ? '重新上传附件' : '上传附件'}
              accept="*"
              title="上传附件"
            />
          </div>
          <div className="kb-row">
            <div className="kb-form-fr">
              <span
                className="cancel-btn"
                onClick={KBPopover.close}
                style={{ marginRight: '20px' }}
              >
                取消
              </span>
              <KBButton
                className="certain-btn kb-from-cmr"
                type="submit"
                submitting={submitting}
              >
                确定
              </KBButton>
            </div>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const {invoice: {invoicing_application}} = props;
  let initialValues = {}
  if(invoicing_application && invoicing_application.invoice_number) {
    initialValues = {
      ...invoicing_application,
      invoice_date: formatYearDayEN(invoicing_application.invoice_date)
    }
  }
  return {
    initialValues
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  if (valid.required(values.invoice_number)) {
    errors.invoice_number = '请输入发票号'
  }

  if (valid.required(values.invoice_date)) {
    errors.invoice_date = '请选择开票日期'
  }
  return errors
}

const formConfig = {
  form: 'MemberLocationRolesForm',
  fields: ['invoice_number', 'invoice_date', 'pdf_url'],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OfflineRefundForm)
