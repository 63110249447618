import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

var ParkingManageBox = React.createClass({
  displayName: 'ParkingManageBox',

  render() {
    const { loc_id, lastSection } = this.props
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child)
    )

    return (
      <div style={{ height: '100%' }}>
        <header
          className="m-bottom-sm"
          style={{
            height: 30,
            width: 1136,
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <span className="f-left" style={{ fontSize: 24 }}>
            车位管理
          </span>
          <div className="edit-way f-right">
            {/* <Link className={!lastSection  ? 'active' : ''}  to={ `/admin/locations/${loc_id}/parking/manage/` } >列表图</Link> */}
            {/* <Link className={lastSection && lastSection == 'time_line' ? 'active' : ''}  to={ `/admin/locations/${loc_id}/parking/manage/time_line` } >时间轴</Link> */}
          </div>
        </header>
        {childrenWithProps}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let sections = location.pathname.split('/')
  let section = sections.length > 2 ? sections[3] : ''
  const lastSection = sections.length > 5 ? sections[6] : ''

  return {
    section: section || '',
    sections,
    lastSection,
    loc_id
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingManageBox)
