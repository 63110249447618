import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  sectionsActions,
  appModulesActions,
  spacesSettingAction
} from 'app/actions'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import { isListValue, getListFirstValue } from 'utils/kbUtil'
import * as selectors from 'app/selectors'

var MembershipsManage = React.createClass({
  displayName: 'MembershipsManage',
  componentDidMount() {},
  render() {
    const { section, locations, loc_id, spaceSection, menu } = this.props
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { locations, loc_id, pageType: 'space' })
    )

    return (
      <div className="kb-out-box clear-fix" style={{ paddingTop: 0 }}>
        <div className="sub-nav-bar" style={{ minWidth: 1450 }}>
          <div style={{ position: 'relative' }}>
            <div className="sub-nav-list clear-fix">
              {menu.map((data, index) => {
                return (
                  <Link
                    key={index}
                    className={
                      isListValue(data.value, section || spaceSection)
                        ? 'active'
                        : ''
                    }
                    to={`/admin/memberships/memberManage/${getListFirstValue(
                      data.value
                    )}`}
                  >
                    {' '}
                    {data.name}{' '}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
        <div className="margin-top-lg">
          <div className="clear-fix" style={{ height: '100%' }}>
            <section className="kb-content-t">{childrenWithProps}</section>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  let sections = location.pathname.split('/')
  let section = sections.length > 3 ? sections[4] : ''
  let locations = selectors.getSerializeLocationObj(state)
  let loc_id = locations && Object.keys(locations) && Object.keys(locations)[0]
  let spaceSection = sections.length > 1 ? sections[2] : ''
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)
  let menu = [
    {
      name: '会员',
      value: ['members']
    },
    {
      name: '活动',
      value: 'activities'
    },
    {
      name: '福利',
      value: 'memberWelfare'
    },
    {
      name: '资讯',
      value: 'news'
    },
    {
      name: virtual_currency_name,
      value: 'pointsRecord'
    },
    {
      name: '公告',
      value: 'announcements'
    },
    {
      name: '消息',
      value: 'messages'
    },
    {
      name: '社群管理',
      value: 'posts'
    }
    // {
    //   name  : '个人会员',
    //   value : ['non_members']
    // },
    // {
    //   name  : '会员卡',
    //   value : ['cards']
    // },
    // {
    //   name  : '资源包',
    //   value : 'resources'
    // },
  ]

  const { user: { space_roles } = {} } = state
  if(space_roles&&Array.isArray(space_roles)&&space_roles.length===1&&space_roles[0].role==='location_group_wechat_manager'){
    menu = [
      {
        name: '会员',
        value: ['members']
      },
      {
        name: '活动',
        value: 'activities'
      },
      {
        name: '福利',
        value: 'memberWelfare'
      }
    ]
  }

  return {
    section: section || '',
    locations,
    loc_id,
    spaceSection,
    menu
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSpaceSettingAction: bindActionCreators(
      spacesSettingAction.get,
      dispatch
    ),
    getSectionsActions: bindActionCreators(sectionsActions.get, dispatch),
    getAppModulesActions: bindActionCreators(appModulesActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipsManage)
