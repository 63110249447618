import React from 'react'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { KBForm, KBInput, KBMorePhotoUpload, KBButton } from 'components'
import { isFunwork } from 'utils/kbUtil'

var OrgInformationForm = React.createClass({
  getInitialState() {
    return {
      formSubmitting: this.props.formSubmitting
    }
  },

  componentWillMount() {
    const { type } = this.props
    const {
      fields: { spaceId },
      space_id
    } = this.props
    if (type === 'update') {
      return
    }
    spaceId.onChange(space_id)
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.formSubmitting !== this.props.formSubmitting) {
      this.setState({
        formSubmitting: nextProps.formSubmitting
      })
    }
  },

  saveOrgInfoOfComplete(model) {
    const { saveParams } = this.props
    saveParams && saveParams(model, 6, true)
  },

  saveOrgInfoOfUpdate(model) {
    const { saveParams } = this.props
    return saveParams && saveParams(model, false, true)
  },

  saveOrgInfoOfLast() {
    const { fields, saveParams } = this.props
    let model = {}
    Object.keys(fields).map(field_key => {
      model[field_key] = fields[field_key].value
    })
    saveParams && saveParams(model, 4)
  },

  render() {
    const {
      fields: {
        business_license_number,
        business_license_positive_url,
        business_license_negative_url
      }
    } = this.props
    const {
      space_id,
      handleSubmit,
      isUpdate,
      defaultBusinessLicense,
      defaultOtherLicense,
      isWizard
    } = this.props
    const { formSubmitting } = this.state

    return (
      <KBForm type="reduxForm" fields={this.props.fields}>
        <header className="kb-form-header">
          <h2 className="header-title">公司资料</h2>
        </header>
        <div className="kb-form-container">
          <div className="kb-row kb-form-84">
            <KBInput
              className="kb-form-group kb-form-210 kb-form-alone"
              field={business_license_number}
              isRequired={isFunwork && isFunwork(space_id) ? true : false}
              title="营业执照号"
            />
            <div />
          </div>
          <div className="kb-row">
            <KBMorePhotoUpload
              defaultImages={defaultBusinessLicense}
              title="营业执照"
              hint="单张上传,大小不大于20M"
              uploadOneTime={true}
              multiple={false}
              photoShow={true}
              className="kb-form-group kb-form-210 kb-form-alone"
              field={business_license_positive_url}
              text="上传营业执照"
              prefix="images/organization/"
              outerStyle={{ width: '66.66667%' }}
              accept="image/*,.pdf"
            />
            <div />
          </div>
          <div className="kb-row">
            <KBMorePhotoUpload
              defaultImages={defaultOtherLicense}
              title="身份证扫描件"
              hint="上传文件,单个文件大小不大于20M"
              multiple={true}
              photoShow={true}
              className="kb-form-group kb-form-210 kb-form-alone"
              field={business_license_negative_url}
              text="上传身份证扫描件"
              prefix="images/organization/"
              outerStyle={{ width: '66.66667%' }}
              accept="image/*,.pdf"
            />
            <div />
          </div>
          <div className="kb-row">
            {!isWizard ? (
              <KBButton
                className="certain-btn"
                type="button"
                onClick={this.saveOrgInfoOfLast}
                style={{ marginRight: 30 }}
              >
                上一步
              </KBButton>
            ) : null}
            {!isUpdate ? (
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={formSubmitting}
                onClick={handleSubmit(this.saveOrgInfoOfComplete)}
              >
                稍后填写资料
              </KBButton>
            ) : (
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={formSubmitting}
                onClick={handleSubmit(this.saveOrgInfoOfUpdate)}
              >
                完成编辑
              </KBButton>
            )}
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { org, urlParams } = props
  const isUpdate = urlParams && urlParams.type === 'update' && org
  const isWizard = urlParams && urlParams.wizard === '1' && org
  let space_id = Object.keys(state.entities.spaces || {})[0]
  let posUrl = (org && org.business_license_positive_url) || ''
  let negUrl = (org && org.business_license_negative_url) || ''
  let defaultBusinessLicense = posUrl ? [posUrl] : null
  let defaultOtherLicense = negUrl ? [negUrl] : null

  return {
    space_id,
    isUpdate,
    isWizard,
    defaultBusinessLicense,
    defaultOtherLicense,
    initialValues: org
      ? {
          ...org
        }
      : {}
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  if (
    isFunwork(values.spaceId) &&
    valid.required(values.business_license_number)
  ) {
    errors.business_license_number = '请输入营业执照号'
  }

  return errors
}

const formConfig = {
  form: 'OrgInformationForm',
  fields: [
    'business_license_number',
    'business_license_positive_url',
    'business_license_negative_url',
    'spaceId'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgInformationForm)
