import React from 'react'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { KBLoadingContainer } from 'components'
import { successState, orgActions, floorInfosAction } from 'app/actions'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import * as selectors from 'app/selectors'

import OrgBasicMessageForm from './OrgBasicMessageForm'
import OrgManagerForm from './OrgManagerForm'
import OrgInvoiceMessage from './OrgInvoiceMessage'
import OrgOpportunityForm from './OrgOpportunityForm'
import OrgInformationForm from './OrgInformationForm'
import OrgRentInfoForm from './OrgRentInfoForm'
import OrgCreateSuccess from './OrgCreateSuccess'

const ORG_HEADER_OCRM = [
  {
    step: 1,
    title: '公司基本信息'
  },
  {
    step: 2,
    title: '公司负责人'
  },
  {
    step: 3,
    title: '租赁信息'
  }
]
const ORG_HEADER_COWORKING = [
  {
    step: 1,
    title: '公司基本信息'
  },
  {
    step: 2,
    title: '公司负责人'
  },
  {
    step: 3,
    title: '发票信息'
  },
  {
    step: 4,
    title: '客户信息'
  },
  {
    step: 5,
    title: '公司资料'
  }
]
var CreateOrganization = React.createClass({
  getInitialState() {
    const { urlParams } = this.props

    return {
      step: (urlParams && parseInt(urlParams.step)) || 1,
      params: null,
      isCreateSuccess: false,
      newOrg: null,
      formSubmitting: false,
      floorsOfCurrentLocObj: {},
      groupedAreasOfCurrentLoc: [],
      areaTarget: []
    }
  },

  componentDidMount() {
    const { org_id, urlParams } = this.props
    this.getLocFoolers()
    if (urlParams && urlParams.type === 'update' && org_id) {
      this.loadOrganizationInfo()
    }
  },

  // componentWillReceiveProps(nextProps) {
  //   const { urlParams } = this.props
  //   if(!_.isEqual(urlParams, nextProps.urlParams) && nextProps.urlParams.step){
  //     this.setState({
  //       step:
  //     })
  //   }
  // },

  loadOrganizationInfo() {
    let { org_id, location_id, updateOrganizationsAction } = this.props
    api
      .getOrganizationInfo(org_id, {
        location_id: location_id,
        includes:
          'details,subscriptions,invoices,areas,statistics,opportunities,users,balances'
      })
      .then(
        json =>
          updateOrganizationsAction.success(json, {
            organization_id: org_id,
            location_id: location_id
          }),
        errors => updateOrganizationsAction.failure(errors)
      )
  },

  getLocFoolers() {
    const { location_id, getFloorInfosAction } = this.props
    api.getFloorInfos(location_id, { per_page: 1000 }).then(
      json => {
        getFloorInfosAction.success(json, {
          entityName: 'floorInfos'
        })
      },
      errors => {
        getFloorInfosAction.failure(errors)
      }
    )
  },
  orgBasicMessageJsx() {
    // 公司基本信息
    const { source, urlParams, org, floorInfos, isOCRM } = this.props
    const { step, params, newOrg } = this.state
    if (step !== 1) {
      return null
    }
    return (
      <OrgBasicMessageForm
        saveParams={this.saveParams}
        source={source}
        newOrg={newOrg}
        isOCRM={isOCRM}
        floorInfos={floorInfos}
        org={Object.assign({}, org, params)}
        urlParams={urlParams}
      />
    )
  },

  orgManagerJsx() {
    // 公司负责人
    const { source, urlParams, org, isOCRM } = this.props
    const { step, formSubmitting, params, newOrg } = this.state
    if (step !== 2) {
      return null
    }
    return (
      <OrgManagerForm
        saveParams={this.saveParams}
        source={source}
        isOCRM={isOCRM}
        newOrg={newOrg}
        formSubmitting={formSubmitting}
        org={Object.assign({}, org, params)}
        urlParams={urlParams}
      />
    )
  },

  orgBillJsx() {
    // 发票信息
    const { source, urlParams, org, location_id, isOCRM } = this.props
    const { step, formSubmitting, params, newOrg } = this.state
    if (step !== 3) {
      return null
    }

    if (isOCRM) {
      const {
        isCreateSuccess,
        floorsOfCurrentLocObj,
        groupedAreasOfCurrentLoc,
        areaTarget
      } = this.state
      if (isCreateSuccess) {
        return (
          <OrgCreateSuccess
            org={Object.assign({}, newOrg, params)}
            location_id={this.props.location_id}
            saveParams={this.saveParams}
            formSubmitting={formSubmitting}
            source={source}
            urlParams={urlParams}
          />
        )
      }

      return (
        <OrgRentInfoForm
          location_id={this.props.location_id}
          areaSource={groupedAreasOfCurrentLoc}
          areaTarget={areaTarget}
          floorsObj={floorsOfCurrentLocObj}
          setFloorAndAreas={this.setFloorAndAreas}
          source={source}
          newOrg={newOrg}
          org={Object.assign({}, org, params)}
          formSubmitting={formSubmitting}
          saveParams={this.saveParams}
          urlParams={urlParams}
        />
      )
    }

    return (
      <OrgInvoiceMessage
        saveParams={this.saveParams}
        source={source}
        newOrg={newOrg}
        location_id={location_id}
        org={Object.assign({}, org, params)}
        formSubmitting={formSubmitting}
        urlParams={urlParams}
      />
    )
  },

  orgClientJsx() {
    // 客户信息
    const { source, urlParams, org } = this.props
    const { step, formSubmitting, params, newOrg } = this.state

    if (step !== 4) {
      return null
    }

    return (
      <OrgOpportunityForm
        saveParams={this.saveParams}
        source={source}
        newOrg={newOrg}
        org={Object.assign({}, org, params)}
        formSubmitting={formSubmitting}
        urlParams={urlParams}
      />
    )
  },

  orgInformationJsx() {
    // 团队资料
    const { source, urlParams, org } = this.props
    const { step, newOrg, formSubmitting, params, isCreateSuccess } = this.state

    if (step !== 5) {
      return null
    }

    if (isCreateSuccess) {
      return (
        <OrgCreateSuccess
          org={Object.assign({}, newOrg, params)}
          location_id={this.props.location_id}
          saveParams={this.saveParams}
          formSubmitting={formSubmitting}
          source={source}
          urlParams={urlParams}
        />
      )
    }

    return (
      <OrgInformationForm
        source={source}
        newOrg={newOrg}
        org={Object.assign({}, org, params)}
        formSubmitting={formSubmitting}
        saveParams={this.saveParams}
        urlParams={urlParams}
      />
    )
  },

  orgRentInfoJsx() {
    const { source, urlParams, org } = this.props
    const {
      step,
      isCreateSuccess,
      newOrg,
      formSubmitting,
      params,
      floorsOfCurrentLocObj,
      groupedAreasOfCurrentLoc,
      areaTarget
    } = this.state

    if (step !== 6) {
      return null
    }

    if (isCreateSuccess) {
      return (
        <OrgCreateSuccess
          org={Object.assign({}, newOrg, params)}
          location_id={this.props.location_id}
          saveParams={this.saveParams}
          formSubmitting={formSubmitting}
          source={source}
          urlParams={urlParams}
        />
      )
    }

    return (
      <OrgRentInfoForm
        location_id={this.props.location_id}
        areaSource={groupedAreasOfCurrentLoc}
        areaTarget={areaTarget}
        floorsObj={floorsOfCurrentLocObj}
        setFloorAndAreas={this.setFloorAndAreas}
        source={source}
        newOrg={newOrg}
        org={Object.assign({}, org, params)}
        formSubmitting={formSubmitting}
        saveParams={this.saveParams}
        urlParams={urlParams}
      />
    )
  },

  setFloorAndAreas(floors, areaSource, areaTarget) {
    if (floors) {
      this.setState({
        floorsOfCurrentLocObj: floors
      })
    }

    if (areaSource) {
      this.setState({
        groupedAreasOfCurrentLoc: areaSource
      })
    }

    if (areaTarget) {
      this.setState({
        areaTarget
      })
    }
  },

  // 保存form参数  params: 参数, num: 需要跳转步骤, isComplete: 是否完成(call api), isSwitchUpdate: 是否切换为编辑公司模式
  saveParams(params, num, isComplete, isSwitchUpdate) {
    const { source } = this.props
    const params_new = params
      ? Object.assign({}, this.state.params, params)
      : {}
    this.setState({
      params: params_new
    })
    if (isComplete && source === 'crm') {
      return this.createOrg(params_new)
    }
    num && !isComplete && this.setCurrentStep(num)
    isComplete && this.createOrg(params_new, isSwitchUpdate, num)
  },

  setCurrentStep(num) {
    // 跳转当前step
    this.setState({
      step: num
    })
  },

  createOrg(params, isSwitchUpdate, num) {
    const {
      location_id,
      successActions,
      source,
      urlParams,
      org_id,
      routerActions,
      addType,
      isOCRM
    } = this.props

    // if (!params.contacts_idcard_type || !params.contacts_id_number) {
    //   delete params.contacts_idcard_type
    //   delete params.contacts_id_number
    // }

    let posUrl = params.business_license_positive_url
    let negUrl = params.business_license_negative_url

    if (posUrl) {
      let newPosUrl = this.getNewestUrl(posUrl)
      params.business_license_positive_url = newPosUrl
    }
    if (negUrl) {
      let newNegUrl = this.getNewestUrl(negUrl)
      params.business_license_negative_url = newNegUrl
    }

    delete params.spaceId

    if (urlParams && urlParams.type === 'update') {
      this.setState({
        formSubmitting: true
      })
      return api
        .updateOrganization(org_id, { ...params, location_id })
        .then(
          json => {
            this.props.updateOrganizationsAction.success(json)
            routerActions.replace(
              `/admin/locations/${location_id}/organizations/${org_id}`
            )
          },
          error => {
            this.props.updateOrganizationsAction.failure(error)
          }
        )
        .finally(() => {
          this.setState({
            formSubmitting: false
          })
        })
    }
    this.setState({
      formSubmitting: true
    })
    if (addType) {
      params.enter_type = addType
    }
    return api
      .createOrganization(location_id, params)
      .then(
        resp => {
          successActions({ message: '创建公司成功！' })
          if (source === 'crm') {
            this.props.createOrgAction.success(resp, {
              location_id: location_id
            })
          }
          let org = getEntitiesJsonArray(resp, 'organizations')
          if (isSwitchUpdate) {
            this.setState({
              newOrg: org
            })
            num && this.setCurrentStep(num)
            return routerActions.push(
              `/admin/locations/${location_id}/new_organization?type=update&org_id=${org.id}&step=${num}&add_type=${addType}`
            )
          }
          this.setState({
            isCreateSuccess: true,
            step: isOCRM ? 3 : 5,
            newOrg: org
          })
          if (source && source === 'crm') {
            this.toCreateSubscription(org)
          }
        },
        error => {
          this.props.createOrgAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          formSubmitting: false
        })
      })
  },

  toCreateSubscription(org) {
    const { urlParams, routerActions, location_id } = this.props
    const opp_id = urlParams && urlParams.opportunity_id
    routerActions.push(
      `/admin/crm/opportunities/sub/organizations/${org.id}/subscriptions/new?opportunity_id=${opp_id}&location_id=${location_id}&typeSource=crmrecord`
    )
  },

  getNewestUrl(url) {
    let freshUrl = url.split(',')
    let index = freshUrl.length - 1
    return freshUrl[index]
  },

  switchStep(header) {
    const { urlParams } = this.props
    const { params } = this.state
    if (urlParams && urlParams.type && urlParams.type === 'update') {
      this.saveParams(params, header.step)
    }
  },

  render() {
    const { step } = this.state
    const { isOCRM } = this.props
    const currentHeader = isOCRM ? ORG_HEADER_OCRM : ORG_HEADER_COWORKING

    return (
      <KBLoadingContainer className="kb-content" loading={false}>
        <div className="org-header">
          {currentHeader.map((header, index) => {
            const isLast = currentHeader.length - 1 === index

            return (
              <div
                className={`org-header-step ${
                  step === header.step ? 'org-step_active' : ''
                }`}
                key={index}
                style={isLast ? { flex: 'initial' } : {}}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: isLast ? 'start' : 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    className="org-header-num"
                    onClick={() => {
                      this.switchStep(header)
                    }}
                  >
                    {header.step}
                  </div>
                  {!isLast ? <div className="org-header-line" /> : null}
                </div>
                <div className="org-header-title">{header.title}</div>
              </div>
            )
          })}
        </div>
        <div
          style={{
            backgroundColor: '#FFFFFF',
            marginTop: 20,
            border: '1px solid #DFE2E5',
            paddingBottom: 60
          }}
        >
          {this.orgBasicMessageJsx()}
          {this.orgManagerJsx()}
          {this.orgBillJsx()}
          {this.orgClientJsx()}
          {this.orgInformationJsx()}
          {/* { this.orgRentInfoJsx() } */}
        </div>
      </KBLoadingContainer>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const location_id = ownProps.params.id
  const urlParams = ownProps.location.query
  const source = urlParams && urlParams.source
  const org_id = urlParams && urlParams.org_id
  const addType = urlParams && urlParams.add_type
  const org =
    urlParams && urlParams.org_id && selectors.getOrg(state, urlParams.org_id)
  const isOCRM = addType === 'longterm'
  let floorInfos = selectors.getFloorInfos(state, location_id)
  floorInfos &&
    floorInfos.map(floor => {
      floor.name = floor.layer_num
    })
  return {
    location_id,
    source,
    urlParams,
    org_id,
    org,
    addType,
    isOCRM,
    floorInfos
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    createOrgAction: bindActionCreators(orgActions.create, dispatch),
    updateOrganizationsAction: bindActionCreators(orgActions.update, dispatch),
    getFloorInfosAction: bindActionCreators(floorInfosAction.replace, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganization)
