import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { successState, apiCallFailure } from 'app/actions'
import { routerActions } from 'react-router-redux'
import {
  KBForm,
  KBInput,
  KBButton,
  KBMoreImageCropperInput,
  KBTimeRangeSelect
} from 'components'
import { api } from 'app/services'
import { notification } from 'antd'
import moment from 'moment'
import KBEditor from 'components/plugins/KBEditor'

var EditValueAddedServices = React.createClass({
  getInitialState() {
    return {
      fileList: []
    }
  },

  componentDidMount() {
    const { fid } = this.props.location.query

    if (fid) {
      // 获取详情数据
      const {
        fields: { end_at, start_at },
        fields
      } = this.props
      api.getMemberWelfareDetail(fid).then(({ json }) => {
        ;['content', 'title', 'images', 'non_member_content'].forEach(key => {
          fields[key].onChange(json[key])
        })
        start_at.onChange(json.start_at)
        end_at.onChange(json.end_at)
        this.setState({ fileList: json.images })
      })
    }
  },

  submitData(model) {
    const {
      params: { id },
      apiCallFailureAction,
      successAction
    } = this.props
    const { fid } = this.props.location.query
    if (!fid) {
      // 新建
      api
        .addMemberWelfare({
          ...model,
          location_group_id: id
        })
        .then(res => {
          successAction({ message: '创建成功' })
          this.goBack()
        })
        .catch(error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message
          })
        })
    } else {
      // 编辑
      api
        .editMemberWelfare(fid, {
          ...model,
          location_group_id: id
        })
        .then(res => {
          successAction({ message: '修改成功' })
          this.goBack()
        })
        .catch(error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message
          })
        })
    }
  },

  failureCallBack(err) {
    if (!err) return
    return notification.error({ message: '上传失败', description: err })
  },

  removeFile(index) {
    const {
      fields: { images }
    } = this.props
    const { fileList } = this.state
    let newFileList = [...fileList]
    newFileList.splice(index, 1)
    images.onChange(newFileList)
    this.setState({ fileList: newFileList })
  },

  goBack() {
    this.props.routerActions.goBack()
  },

  onNotSupport() {
    this.props.apiCallFailureAction({
      status: 'error',
      message: '最多上传6张图片'
    })
  },

  render() {
    const {
      fields: {
        title,
        images,
        start_at,
        end_at,
        content,
        status,
        non_member_content
      },
      submitting,
      handleSubmit
    } = this.props
    const { fileList } = this.state
    const { fid } = this.props.location.query

    return (
      <div className="kb-content">
        <div className="kb-content-container">
          <KBForm
            type="reduxForm"
            fields={this.props.fields}
            onSubmit={handleSubmit(this.submitData)}
          >
            <header className="kb-form-header">
              <h2 className="header-title">{fid ? '编辑' : '创建'}福利</h2>
            </header>
            <div className="kb-form-container">
              <div className="kb-row ">
                <KBInput
                  className="kb-form-group kb-form-alone"
                  title="福利标题"
                  label="福利标题"
                  isRequired
                  placeholder="请填写福利标题（5-30个字符）"
                  field={title}
                />
              </div>

              <KBTimeRangeSelect
                isRequired
                startTime={start_at}
                endTime={end_at}
              />

              <div className="kb-row kb-form-66">
                <KBMoreImageCropperInput
                  title="福利封面"
                  aspect={3 / 4}
                  isRequired
                  className="kb-form-group kb-form-210 kb-form-alone"
                  field={images}
                  type="groups"
                  accept="image/jpeg, image/png, image/jpg"
                  width={300}
                  height={400}
                  imageSize={1024 * 2}
                  supportClick={fileList.length < 6}
                  failureCallBack={this.failureCallBack}
                  callback={v => this.setState({ fileList: v })}
                  onNotSupport={this.onNotSupport}
                  isPublic
                >
                  <div className="upload-portrait">
                    <div
                      className="d-iblock"
                      style={{ verticalAlign: 'middle' }}
                    >
                      <button className="bordered-btn m-none" type="button">
                        {fileList && fileList.length
                          ? '继续上传图片'
                          : '上传图片'}
                      </button>
                      <p
                        className="color-nine"
                        style={{ paddingTop: 20, fontSize: 12 }}
                      >
                        图片格式为png, jpeg或jpg, 图片尺寸要求（宽高比=3:4）,
                        文件大小不大于2M, 最多可添加6张图片, 封面为首图
                      </p>
                    </div>
                  </div>
                  {images.error && images.touched && (
                    <p className="lr-error">{images.error}</p>
                  )}
                </KBMoreImageCropperInput>
                <div>
                  {fileList ? (
                    <ul className="kb-more-file" style={{ margin: 0 }}>
                      {fileList.map((file, index) => {
                        return (
                          <li key={index} style={{ cursor: 'pointer' }}>
                            <img
                              src={file}
                              alt="loading"
                              style={{
                                width: '150px',
                                height: '200px',
                                marginBottom: 10
                              }}
                            />

                            <div className="delete">
                              <i
                                className="iconfont icon-close"
                                onClick={this.removeFile.bind(null, index)}
                              />
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <KBEditor
                field={content}
                isRequired
                title="福利详情（办公楼会员）"
              />

              <KBEditor
                field={non_member_content}
                isRequired
                title="福利详情（非办公楼会员）"
              />

              <div className="kb-form-fr" style={{ marginTop: '80px' }}>
                <span
                  className="cancel-btn"
                  style={{ marginRight: 15 }}
                  onClick={this.goBack}
                >
                  取消
                </span>
                <KBButton
                  className="c-btn-warning"
                  type="submit"
                  style={{ marginRight: 15 }}
                  submitting={submitting}
                  onClick={() => status.onChange('draft')}
                >
                  保存草稿
                </KBButton>
                <KBButton
                  className="certain-btn"
                  type="submit"
                  style={{ marginRight: 15 }}
                  submitting={submitting}
                  onClick={() => status.onChange('publish')}
                >
                  立即发布
                </KBButton>
              </div>
            </div>
          </KBForm>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  return {
    initialValues: {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}
  console.log(values)
  if (valid.required(values.title)) {
    errors.title = '请输入福利标题'
  }

  if (values.title && values.title.length < 5) {
    errors.title = '福利标题不少于5个字符'
  }

  if (values.title && values.title.length > 30) {
    errors.title = '福利标题不能超过30个字符'
  }

  if (valid.required(values.images)) {
    errors.images = '请上传福利封面'
  }

  if (valid.required(values.content)) {
    errors.content = '请输入福利详情（办公楼会员）'
  }

  if (valid.required(values.non_member_content)) {
    errors.non_member_content = '请输入福利详情（非办公楼会员）'
  }

  if (new Date(values.start_at) > new Date(values.end_at)) {
    // 如果开始和结束是同一天
    if (
      moment(values.start_at).format('YYYY.MM.DD') ===
      moment(values.end_at).format('YYYY.MM.DD')
    ) {
      errors.start_at = '开始时间不能晚于结束时间'
    } else {
      errors.start_at = '开始日期不能晚于结束日期'
    }
  }

  return errors
}

const formConfig = {
  form: 'EditValueAddedServices',
  fields: [
    'title',
    'images',
    'start_at',
    'end_at',
    'content',
    'status',
    'non_member_content'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(EditValueAddedServices)
