import React from 'react'
import { reduxForm } from 'redux-form'
import { KBInput, KBSelect, KBButton, KBPopover, KBForm } from 'components'
import intl from 'react-intl-universal'

var LockerForm = React.createClass({
  render() {
    const {
      fields: { name, device_id, status },
      handleSubmit,
      submitting,
      LOCKER_STATUS
    } = this.props
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.createLocker)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title"> 添加储物柜 </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="储物柜名称"
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={device_id}
              title="储物柜ID"
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBSelect
              defaultValue=" "
              className="kb-form-group kb-div-c18 kb-form-alone"
              title="状态"
              field={status}
              arrayData={LOCKER_STATUS}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              {' '}
              取消{' '}
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state) {
  const LOCKER_STATUS = [
    {
      id: 'normal',
      name: '正常'
    },
    {
      id: 'disabled',
      name: '停用'
    },
    {
      id: 'damaged',
      name: '损坏'
    }
  ]
  return {
    LOCKER_STATUS
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'LockerForm',
  fields: ['name', 'status', 'device_id'],
  validate: validate,
  touchOnBlur: false
}

LockerForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LockerForm)

export default LockerForm
