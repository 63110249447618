import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import { apiCallFailure, lockGroupsAction } from 'app/actions'
import {
  KBPopover,
  KBFormError,
  KBButton,
  KBTransfer,
  KBInput
} from 'components'
import { LOCK_MANAGE_TIPSY } from 'app/constants'
import * as valid from 'utils/validate'
const pinyin = require('tiny-pinyin')

var LockGroupForm = React.createClass({
  displayName: 'LockGroupForm',
  getInitialState() {
    return {
      lockSource: [],
      lockTarget: []
    }
  },

  createLockGroup(model) {
    const {
      createLockGroupsAction,
      apiCallFailureActions,
      loc_id,
      type
    } = this.props
    let locks = this.refs.lockTransfer.getData().target

    if (locks.length <= 0) {
      apiCallFailureActions({
        status: 'error',
        message: '请选择门禁'
      })
      return
    }

    let locksArr = []
    locks.map(l => (locksArr = locksArr.concat(l.childrens)))
    model.lock_ids = locksArr.map(l => l.id).join(',')

    if (type === 'update') {
      const { lockGroup, updateLockGroupAction } = this.props
      const id = lockGroup.id
      return api.putLockGroup(id, model).then(
        json => {
          updateLockGroupAction.success(json, { id, key: 'lockGroups' })
          KBPopover.close()
        },
        error => {
          updateLockGroupAction.failure(error)
          KBPopover.close()
        }
      )
    }

    model.location_id = loc_id
    model.enabled = true

    return api.postLockGroups(model).then(
      json => {
        createLockGroupsAction.success(json)
        KBPopover.close()
      },
      error => {
        createLockGroupsAction.failure(error)
        KBPopover.close()
      }
    )
  },

  componentDidMount() {
    const { lockGroup, type } = this.props
    let { locks } = this.props
    // locks = locks.concat(locks);
    this.setState({
      lockSource: this.groupLocks(locks),
      lockTarget: type === 'update' ? this.groupLocks(lockGroup.locks) : []
    })
  },

  groupLocks(locks) {
    // 对门禁按照 area.area_type 进行分组
    let tempIds = {}
    locks &&
      locks.map(json => {
        let tempIdKeys = Object.keys(tempIds)
        let s_id = (json.area && json.area.area_type) || 'gate'
        if (tempIdKeys.includes(String(s_id))) {
          tempIds[s_id].push(json)
        } else {
          tempIds[s_id] = []
          tempIds[s_id].push(json)
        }
      })

    let arrayedLocks = Object.values(tempIds)
    let mapedLocks = []

    arrayedLocks &&
      arrayedLocks.map(data => {
        let nameIndex = (data[0].area && data[0].area.area_type) || 'gate'
        let id = nameIndex
        let name = LOCK_MANAGE_TIPSY[nameIndex]
        let childrens = data
        mapedLocks.push({ id, name, childrens })
      })

    return mapedLocks
  },

  getAreaLocks(id) {
    const { lockSource, lockTarget } = this.state
    let children = lockSource.concat(lockTarget).find(l => l.id == id).childrens

    children &&
      children.map(lock => {
        lock.pinyin = pinyin.convertToPinyin(lock.name).split('')[0]
      })
    children &&
      children.sort((a, b) => {
        return a.pinyin.localeCompare(b.pinyin)
      })

    return children
  },

  render() {
    const {
      fields: { name },
      submitting,
      handleSubmit,
      type
    } = this.props
    let { lockSource, lockTarget } = this.state

    return (
      <form onSubmit={handleSubmit(this.createLockGroup)}>
        <header className="kb-form-header">
          <h2 className="header-title">
            {type === 'update' ? '修改' : '创建'}门禁组
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          {type === 'update' ? (
            <div className="kb-tip-warning">
              <div className="icon">
                <i className="iconfont icon-hint" />
              </div>
              <div className="msg">
                门禁组添加或减少门禁，已赋予的门禁组权限人员也会相应的修改
              </div>
            </div>
          ) : null}
          <div className="kb-row">
            <KBInput
              isRequired={true}
              className="kb-form-group kb-form-c39 kb-form-alone"
              field={name}
              title="门禁组名称"
              fistFoucs={true}
            />
          </div>

          <div className="kb-title border-none">
            <i
              className="iconfont icon-close2"
              style={{ fontSize: 18, marginRight: 5, color: '#666' }}
            />
            <span>门禁选择</span>
          </div>
          <div className="kb-row">
            <KBTransfer
              ref="lockTransfer"
              isSearch={false}
              isHead={true}
              isMiddle={false}
              isChildrenLoad={true}
              getExpandChilds={this.getAreaLocks}
              multi={true}
              sourceTitle="门禁"
              targetTitle="已添加"
              source={lockSource}
              target={lockTarget}
            />
          </div>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { lockGroup } = props

  return {
    initialValues: {
      name: (lockGroup && lockGroup.name) || ''
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createLockGroupsAction: bindActionCreators(
      lockGroupsAction.create,
      dispatch
    ),
    updateLockGroupAction: bindActionCreators(
      lockGroupsAction.update,
      dispatch
    ),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入门禁组名称'
  }

  return errors
}

const formConfig = {
  form: 'LockGroupForm',
  fields: ['name'],
  validate: validate,
  touchOnBlur: false
}

LockGroupForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LockGroupForm)

export default LockGroupForm
