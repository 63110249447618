import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  taxesAction,
  apiCallFailure,
  subscriptionsAction,
  orgActions
} from 'app/actions'
import {
  KBForm,
  KBInput,
  KBSelect,
  KBDateInput,
  KBPopover,
  KBTextarea,
  KBButton
} from 'components'
import { api } from 'app/services'
import { ADD_SUBSCRIPTION } from 'app/constants/hint'
import { CONTRACT_END_DATE_BTN, RECURRING_CYCLES } from 'app/constants'
import * as selectors from 'app/selectors'
import {
  getFloatOfSetting,
  contrastDate,
  getEntitiesJsonArray,
  formatYearDayEN,
  toPercent
} from 'utils/kbUtil'
import ParkingContractRenterForm from './ParkingContractRenterForm'
import ParkingContractAreasForm from './ParkingContractAreasForm'
import OrgSubscriptionCreateRentingPhase from '../organization/OrgSubscriptionCreateRentingPhase'
import OrgSubscriptionEditRentingPhase from '../organization/OrgSubscriptionEditRentingPhase'
import RentingPhasesForm from '../organization/RentingPhasesForm'
import FreePhasesForm from '../organization/FreePhasesForm'
import moment from 'moment'
import _ from 'lodash'
import { Object } from 'core-js'

const BIG_MONTH = [1, 3, 5, 7, 8, 10, 12]

var ParkingContractForm = React.createClass({
  getInitialState() {
    const { status, contract_id, currentSubscription } = this.props

    return {
      hasOrg: contract_id && status ? true : false,
      monthly: 0,
      total: 0,
      yetAreaIds: [],
      oldContract: currentSubscription || {},
      currentOrg: {}
    }
  },

  componentDidMount() {
    const { loc_id, currentSubscription } = this.props
    this.getInitValues()
    this.getTaxes()
    if (currentSubscription.organization_id) {
      let org_id = currentSubscription.organization_id
      this.getOrgInfo(loc_id, org_id)
    }
  },

  getInitValues() {
    const {
      fields: {
        organization_id,
        start_date,
        end_date,
        signed_at,
        deposit,
        deposit_diff,
        deposit_cycles,
        deposit_due_date,
        merge_deposit_into_renting,
        renting_type_list,
        sales_tax_id,
        serial,
        note,
        files,
        areas
      }
    } = this.props
    const { status, currentSubscription } = this.props

    if (status) {
      start_date.onChange(currentSubscription.start_date)
      end_date.onChange(currentSubscription.end_date)
      signed_at.onChange(
        currentSubscription.signed_at &&
          moment.unix(currentSubscription.signed_at).format('YYYY-MM-DD')
      )
      renting_type_list.onChange(
        currentSubscription.renting_type_list &&
          currentSubscription.renting_type_list[0]
      )
      sales_tax_id.onChange(currentSubscription.sales_tax_id)
      deposit.onChange(currentSubscription.deposit)
      deposit_diff.onChange(0)
      deposit_cycles.onChange(currentSubscription.deposit_cycles)
      deposit_due_date.onChange(currentSubscription.deposit_due_date)
      merge_deposit_into_renting.onChange(
        currentSubscription.merge_deposit_into_renting
      )
      if (status === 'edit') {
        serial.onChange(currentSubscription.serial)
        note.onChange(currentSubscription.note)
      }
      if (status === 'update') {
        files.onChange(currentSubscription.files)
      }
      if (status === 'extend') {
        let newStartDate = moment(currentSubscription.end_date)
          .add(1, 'days')
          .format('YYYY-MM-DD')
        start_date.onChange(newStartDate)
        signed_at.onChange(newStartDate)
        end_date.onChange('')
      }
      if (status === 'anew' || status === 'extend') {
        organization_id.onChange(currentSubscription.organization_id)
      }
      let yetAreaIds = []
      currentSubscription.sales_areas &&
        currentSubscription.sales_areas.map(parking => {
          const {
            area_id,
            area_type,
            desk_ids,
            price,
            total_price,
            units,
            car_plates,
            area
          } = parking
          yetAreaIds.push(area_id)
          areas.addField({
            area_id,
            area_type,
            desk_ids,
            price,
            total_price,
            units,
            car_plates,
            name: area && area.name,
            district_list: area && area.district_list,
            ceil_list: area && area.ceil_list
          })
        })
      this.calculateDeposit()
      this.setState({
        old_deposit: currentSubscription.deposit,
        yetAreaIds
      })
      return
    }

    deposit.onChange(0)
    deposit_cycles.onChange(1)
    merge_deposit_into_renting.onChange(true)
    signed_at.onChange(moment(new Date()).format('YYYY-MM-DD'))
    start_date.onChange(moment(new Date()).format('YYYY-MM-DD'))
    renting_type_list.onChange('月租')
  },

  getTaxes() {
    const { getTaxesAction } = this.props

    api.getSpaceTaxes().then(
      json => {
        getTaxesAction.success(json)
      },
      error => {
        getTaxesAction.failure(error)
      }
    )
  },

  getOrgInfo(loc_id, org_id) {
    api
      .getOrganizationInfo(org_id, {
        location_id: loc_id
      })
      .then(
        json => {
          this.props.getOrgAction.success(json, {
            organization_id: org_id,
            location_id: loc_id
          })
          const currentOrg = json.response.body
          this.setState({
            currentOrg
          })
        },
        errors => this.props.getOrgAction.failure(errors)
      )
  },

  createParkingContract(model) {
    const {
      loc_id,
      status,
      createSubscriptionAction,
      updateSubscriptionAction,
      subscription_id,
      nextCallBack,
      contract_id
    } = this.props

    if (model.sales_tax_id == -1) {
      delete model.sales_tax_id
    }

    model.start_date = formatYearDayEN(model.start_date)
    model.end_date = formatYearDayEN(model.end_date)
    model.signed_at = formatYearDayEN(model.signed_at)
    model.deposit_due_date = formatYearDayEN(model.deposit_due_date)

    if (status != 'extend' && contract_id && status != 'anew') {
      return api.putSubscriptions(subscription_id, model).then(
        json => {
          updateSubscriptionAction.success(json)
          let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
          nextCallBack && nextCallBack(subscription)
        },
        error => {
          updateSubscriptionAction.failure(error)
        }
      )
    }

    model.subscription_type = 'parking'
    return api.createSubscriptions(loc_id, model).then(
      json => {
        createSubscriptionAction.success(json)
        let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
        nextCallBack && nextCallBack(subscription)
      },
      error => {
        createSubscriptionAction.failure(error)
      }
    )
  },

  // 承租人信息相关 ============================================================
  orgCreateCallback(org) {
    const {
      fields: { organization_id }
    } = this.props
    if (org && org.id) {
      organization_id.onChange(org.id)
      this.setState({
        hasOrg: true
      })
    }
  },

  renterInfoJsx() {
    const { loc_id } = this.props
    const { hasOrg, currentOrg } = this.state
    return (
      <ParkingContractRenterForm
        orgCreateCallback={this.orgCreateCallback}
        hasOrg={hasOrg}
        currentOrg={currentOrg}
        loc_id={loc_id}
      />
    )
  },

  // 租赁类型相关 ============================================================
  rentTypeJsx() {
    const {
      fields: { renting_type_list }
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend className="m-none p-top border-top">租赁类型</legend>
        <div className="kb-row kb-form-66">
          <KBSelect
            title="租赁类型"
            className="kb-form-group kb-div-39"
            field={renting_type_list}
            arrayData={[
              { id: '月租', name: '月租' },
              { id: '时租', name: '时租' }
            ]}
          />
        </div>
      </fieldset>
    )
  },

  // 合同日期相关 ============================================================
  _rentingPhaseDate(startDate, endDate) {
    const {
      fields: { sales_renting_phases },
      status
    } = this.props

    if (sales_renting_phases.length > 0) {
      let first = sales_renting_phases[0]
      let last = sales_renting_phases[sales_renting_phases.length - 1]
      if (status != 'update') {
        startDate && first.start_date.onChange(startDate)
      }
      endDate && last.end_date.onChange(endDate)
    }
  },

  _changeStartDate(day) {
    const {
      fields: { end_date, deposit_due_date },
      deposit_pay_offset
    } = this.props
    const { timeOffset } = this.state
    if (timeOffset != 0) {
      let defaultEndDate = moment(day)
        .add(timeOffset, 'month')
        .subtract(1, 'day')
        .toDate()
      let endDateMonth = moment(defaultEndDate).month() + 1
      let startDateNum = moment(day).date()
      let isBigMonth = BIG_MONTH.find(month => month == endDateMonth)
      if (!isBigMonth && startDateNum == 31) {
        defaultEndDate = moment(day)
          .add(timeOffset, 'month')
          .toDate()
      }
      end_date.onChange(defaultEndDate)
    }
    deposit_due_date.onChange(
      moment(day)
        .subtract(deposit_pay_offset, 'days')
        .toDate()
    )
    this._rentingPhaseDate(day)
  },

  _changeEndDate(day) {
    const {
      fields: { start_date }
    } = this.props
    const endTime = day
    let startTime = ''
    startTime = start_date.value

    if (day && startTime && moment(startTime).isBefore(endTime)) {
      this._rentingPhaseDate(startTime, endTime)
    }
    this.changePhasesResetStatus()
  },

  countEndDate(month) {
    const {
      fields: { start_date, end_date }
    } = this.props
    let start_data_value = start_date.value || moment().format('YYYY-MM-DD')
    if (typeof start_data_value == 'string') {
      start_data_value = start_date.value + ' 12:00:00'
    }
    let endDateValue = moment(start_data_value)
      .add(month, 'month')
      .subtract(1, 'day')
      .toDate()
    let endDateMonth = moment(endDateValue).month() + 1
    let isBigMonth = BIG_MONTH.find(month => month == endDateMonth)
    let startDateNum = moment(start_date.value).date()
    if (!isBigMonth && startDateNum == 31) {
      endDateValue = moment(start_data_value)
        .add(month, 'month')
        .toDate()
    }
    end_date.onChange(endDateValue)
    this.setState({ timeOffset: month })
  },

  dateUpdateJsx() {
    const {
      fields: { valid_date, end_date, start_date }
    } = this.props
    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    return (
      <fieldset className="fieldset">
        <legend className="m-none p-top border-top">有效日期</legend>
        <div className="kb-row kb-form-66 kb-ps">
          <KBDateInput
            defaultYear={2010}
            {...halfClass}
            field={start_date}
            value={start_date.value}
            title="起始日期"
            ref="startDate"
            disabled="disabled"
          />
          <KBDateInput
            defaultYear={2010}
            {...halfClass}
            field={end_date}
            value={end_date.value}
            titleClass="label-rt"
            title="终止日期"
            ref="endDate"
            onChange={this._changeEndDate}
          />
        </div>
        <div className="kb-row kb-form-66 kb-ps">
          <KBDateInput
            defaultYear={2010}
            {...halfClass}
            field={valid_date}
            title="有效日期"
            value={valid_date.value}
          />
          <div
            style={{
              position: 'absolute',
              bottom: '-20px',
              left: '0px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img
              src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-warning.png"
              alt="icon"
              style={{ width: 15, marginRight: 5 }}
            />
            <span style={{ color: '#999' }}>修改内容在该指定日期以后生效</span>
          </div>
        </div>
      </fieldset>
    )
  },

  dateJsx() {
    const {
      fields: { start_date, end_date, signed_at }
    } = this.props
    const hint = ADD_SUBSCRIPTION

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    return (
      <fieldset className="fieldset">
        <legend className="m-none p-top border-top">合同日期</legend>
        <div className="kb-row kb-form-66 kb-ps">
          <KBDateInput
            defaultYear={2010}
            {...halfClass}
            hint={hint.sign_date}
            field={signed_at}
            title="签约日期"
            value={signed_at.value}
          />
        </div>
        <div className="kb-row kb-form-66">
          <KBDateInput
            defaultYear={2010}
            {...halfClass}
            hint={hint.start_date}
            field={start_date}
            title="起始日期"
            isRequired={true}
            value={start_date.value}
            onChange={this._changeStartDate}
          />
          <div className="clear-fix f-left">
            <KBDateInput
              defaultYear={2010}
              {...halfClass}
              hint={hint.end_date}
              field={end_date}
              disabledTimeShow={true}
              style={{ width: '100%' }}
              value={end_date.value}
              isRequiredR={true}
              titleClass="label-rt"
              title="终止日期"
              ref="endDate"
              onChange={this._changeEndDate}
            />
            <div className="time-bucket-btn">
              {CONTRACT_END_DATE_BTN.map((json, index) => {
                return (
                  <span
                    key={index}
                    onClick={this.countEndDate.bind(null, json.id)}
                  >
                    {json.name}
                  </span>
                )
              })}
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  // 选择车位相关 ============================================================

  selectAreaCallback(parkings = []) {
    const {
      fields: { areas }
    } = this.props
    const { yetAreaIds } = this.state
    const yetAreaIds_new = Object.assign([], yetAreaIds)
    parkings.map(par => {
      yetAreaIds_new.push(par.id)
      areas.addField({
        area_id: par.id,
        area_type: par.area_type,
        desk_ids: par.desks && par.desks.map(d => d.id).join(','),
        price: par.listing_price,
        total_price: par.listing_price,
        units: 1,
        car_plates: [],
        // 以下是用于展示的数据，可在 call api 时删除
        name: par.name,
        district_list: par.district_list[0] || '',
        ceil_list: par.ceil_list[0] || ''
      })
      return par
    })
    this.calculateDeposit()
    this.setState({
      yetAreaIds: _.uniq(yetAreaIds_new)
    })
  },

  addParkingSpace() {
    const {
      fields: { start_date, end_date }
    } = this.props
    const { loc_id, apiCallFailureActions } = this.props

    if (!start_date.value || !end_date.value) {
      return apiCallFailureActions({
        status: 'error',
        message: '请选择终止日期'
      })
    }

    KBPopover.show(
      <ParkingContractAreasForm
        loc_id={loc_id}
        callBack={this.selectAreaCallback}
        endDateValue={end_date.value}
        startDateValue={start_date.value}
        yetAreaIds={this.state.yetAreaIds}
      />
    )
  },

  removeParkingSpace(index) {
    const {
      fields: { areas }
    } = this.props
    areas.removeField(index)
    this.calculateDeposit()
    let yetAreaIds_new = Object.assign([], this.state.yetAreaIds)
    _.remove(yetAreaIds_new, id => id === areas[index].area_id.value)
    this.setState({
      yetAreaIds: yetAreaIds_new
    })
  },

  addCarPlateField(index) {
    const {
      fields: { areas },
      apiCallFailureActions
    } = this.props
    if (areas[index].car_plates.length > 4) {
      return apiCallFailureActions({
        status: 'error',
        message: '每个车位最多只能添加5个车牌号'
      })
    }
    areas[index].car_plates.addField({
      car_plate: '',
      driver_license: '',
      owner_name: ''
    })
  },

  parkingsJsx() {
    const {
      fields: { areas, areas_error }
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend className="m-none p-top border-top">选择车位</legend>
        <div>
          {areas.map((parking, index) => {
            return (
              <div className="kb-row">
                <div className="kb-row kb-div-c48">
                  <div className="kb-form-group kb-form-210">
                    <label>
                      {parking.ceil_list.value +
                        ' - ' +
                        parking.district_list.value}
                    </label>
                    <div>
                      <div className="parking-area-btn">
                        <span>车位编号：{parking.name.value}</span>
                        <i
                          className="iconfont icon-close"
                          onClick={this.removeParkingSpace.bind(null, index)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="kb-form-group kb-form-66">
                    <KBInput
                      className="kb-form-group kb-div-210"
                      title="车位价格"
                      inputClass="input-dollar-moth"
                      field={parking.price}
                      type="number"
                      onChange={event => {
                        this._calculationDeposit(event, parking.price)
                      }}
                    />
                    <div className="kb-form-group kb-form-100">
                      <label>&nbsp;</label>
                      <div>
                        <div
                          className="add-parking-plate-btn c-btn-secondary"
                          onClick={e => {
                            this.addCarPlateField(index)
                            e.stopPropagation()
                          }}
                        >
                          添加车牌号
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kb-row kb-div-c48">
                  <div className="kb-form-group kb-form-210">&nbsp;</div>
                  <div className="kb-form-group kb-form-100">
                    {parking.car_plates.map((plate, key) => {
                      return (
                        <div className="kb-form-group kb-form-48">
                          <KBInput
                            className="kb-form-group kb-div-210"
                            title={`车牌号${key + 1}`}
                            field={plate.car_plate}
                          />
                          <div className="kb-form-group kb-div-66">
                            <KBInput
                              className="kb-form-group kb-div-210"
                              title={`行驶证编号`}
                              field={plate.driver_license}
                            />
                            <div className="kb-form-group kb-div-102">
                              <KBInput
                                className="kb-form-group kb-div-210"
                                title={`行驶证所有者`}
                                field={plate.owner_name}
                              />
                              <i
                                className="iconfont icon-close hover-delete"
                                style={{
                                  position: 'relative',
                                  top: 38,
                                  right: 15,
                                  color: '#DFE3E9'
                                }}
                                onClick={e => {
                                  parking.car_plates.removeField(key)
                                  e.stopPropagation()
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
          {areas_error.touched && areas_error.error ? (
            <p className="lr-error">{areas_error.error}</p>
          ) : null}
        </div>

        <div className="kb-row m-top-sm">
          <span className="certain-btn m-none" onClick={this.addParkingSpace}>
            <i className="iconfont icon-add" />
            添加车位
          </span>
        </div>
      </fieldset>
    )
  },

  // 税率相关 ============================================================
  taxJsx() {
    const {
      fields: { sales_tax_id },
      taxes
    } = this.props

    if (!taxes || taxes.length === 0) {
      return null
    }

    return (
      <fieldset className="fieldset">
        <legend className="m-none p-top border-top">税率设置</legend>
        <div className="kb-row kb-form-66">
          <KBSelect
            title="会员费税率"
            defaultValue="-1"
            className="kb-form-group kb-div-39"
            field={sales_tax_id}
            arrayData={taxes}
          />
        </div>
      </fieldset>
    )
  },

  // 押金设置相关 ============================================================
  _calculationDeposit(event, field) {
    if (!field) {
      return
    }
    // first emit the change event
    field.onChange(event)
    // props.fields only updates after next tick
    setTimeout(() => {
      // then calculate the deposit
      const {
        fields: { areas, deposit_cycles, deposit, deposit_diff },
        financeNumberFormat,
        status
      } = this.props

      let total = 0
      let monthly = 0

      areas &&
        areas.map(parking => {
          let totalRent = parseFloat(parking.price.value || 0)
          total += totalRent
        })

      if (total) {
        monthly = total
        total = total * deposit_cycles.value
        this.setState({
          monthly,
          total
        })
        deposit.onChange(getFloatOfSetting(total, financeNumberFormat))
        if (status == 'update') {
          deposit_diff.onChange(
            getFloatOfSetting(total, financeNumberFormat) -
              this.state.old_deposit
          )
        }
      }
    })
  },

  calculateDeposit() {
    setTimeout(() => {
      // then calculate the deposit
      const {
        fields: { areas, deposit_cycles, deposit },
        financeNumberFormat
      } = this.props

      let total = 0
      let monthly = 0

      areas.map(desk => {
        let totalRent = parseFloat(desk.price.value || 0)
        total += totalRent
      })

      if (total || !areas.length) {
        monthly = total
        total = total * deposit_cycles.value
        this.setState({
          monthly,
          total
        })
        deposit.onChange(getFloatOfSetting(total, financeNumberFormat))
      }
    })
  },

  depositChange(e) {
    const {
      fields: { deposit, deposit_diff }
    } = this.props
    let value = e.target.value
    deposit.onChange(value)
    deposit_diff.onChange(
      parseFloat(value) - parseFloat(this.state.old_deposit) || 0
    )
  },

  mergeDepositTrue() {
    let {
      fields: { merge_deposit_into_renting }
    } = this.props
    merge_deposit_into_renting.onChange(true)
  },

  mergeDepositFalse() {
    let {
      fields: { merge_deposit_into_renting }
    } = this.props
    merge_deposit_into_renting.onChange(false)
  },

  depositJsx() {
    const {
      fields: {
        deposit,
        deposit_cycles,
        deposit_due_date,
        merge_deposit_into_renting,
        deposit_diff
      },
      status
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend className="m-none p-top border-top">押金信息</legend>
        {status == 'update' ? (
          <div className="kb-row kb-form-c48 kb-ps">
            <div className="kb-form-group kb-div-c39">
              <label>押金</label>
              <div>
                <select
                  name="deposit"
                  {...deposit_cycles}
                  onChange={event =>
                    this._calculationDeposit(event, deposit_cycles)
                  }
                  disabled={status == 'update' ? 'disabled' : false}
                >
                  {RECURRING_CYCLES.map((json, index) => {
                    return (
                      <option value={json.id} key={index}>
                        {json.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="kb-form-group kb-form-66">
              <div className="kb-form-group kb-div-c48">
                <label className="label-rt">
                  <span className="must-fillR">*</span>押金总金额
                </label>
                <div>
                  <input
                    type="text"
                    className="kb-input"
                    value={deposit.value}
                    {...deposit}
                    onChange={this.depositChange}
                  />
                  {deposit.touched && deposit.error && (
                    <p className="lr-error"> {deposit.error} </p>
                  )}
                </div>
              </div>
              <div className="kb-form-group kb-div-c48">
                <label className="label-rt">
                  <span className="must-fillR">*</span>押金差额
                </label>
                <div>
                  <input
                    type="text"
                    className="kb-input"
                    value={deposit_diff.value}
                    {...deposit_diff}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="kb-row kb-form-66 kb-ps">
            <div className="kb-form-group kb-div-c39">
              <label>押金</label>
              <div>
                <select
                  name="deposit"
                  {...deposit_cycles}
                  onChange={event =>
                    this._calculationDeposit(event, deposit_cycles)
                  }
                  disabled={status == 'update' ? 'disabled' : false}
                >
                  {RECURRING_CYCLES.map((json, index) => {
                    return (
                      <option value={json.id} key={index}>
                        {json.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="kb-form-group kb-div-c48">
              <label className="label-rt">
                <span className="must-fillR">*</span>押金总金额
              </label>
              <div>
                <input
                  type="text"
                  className="kb-input"
                  value={deposit.value}
                  {...deposit}
                />
                {deposit.touched && deposit.error && (
                  <p className="lr-error"> {deposit.error} </p>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className="kb-row "
          style={{ display: 'flex', alignItems: 'center', minHeight: 40 }}
        >
          <span style={{ marginRight: 30, color: '#999', marginLeft: '-3px' }}>
            是否将押金日期与第一缴租周期同步?
          </span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              style={{ marginRight: 10 }}
              value={merge_deposit_into_renting.value}
              id="mergeDepositTrue"
              name="mergeDepositTrue"
              disabled={status == 'update' ? 'disabled' : false}
              checked={merge_deposit_into_renting.value ? 'checked' : ''}
              onChange={this.mergeDepositTrue}
            />
            <label
              htmlFor="mergeDepositTrue"
              style={{ marginLeft: 10, marginRight: 20, color: '#999' }}
            >
              是
            </label>
            <input
              type="radio"
              style={{ marginRight: 10 }}
              value={merge_deposit_into_renting.value}
              id="mergeDepositFalse"
              name="mergeDepositFalse"
              disabled={status == 'update' ? 'disabled' : false}
              checked={merge_deposit_into_renting.value ? '' : 'checked'}
              onChange={this.mergeDepositFalse}
            />
            <label
              htmlFor="mergeDepositFalse"
              style={{ marginLeft: 10, marginRight: 20, color: '#999' }}
            >
              否
            </label>
          </div>
          {!merge_deposit_into_renting.value ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 10, color: '#999' }}>
                我要自定义押金付款日
              </span>
              <KBDateInput
                defaultYear={2010}
                className="kb-form-group kb-div-c84"
                titleClass="label-rt"
                title={false}
                type="date"
                field={deposit_due_date}
                disabled={status == 'update' ? 'disabled' : false}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </fieldset>
    )
  },

  // 缴租阶段 ============================================================
  parseRentingPhases(status) {
    const { monthly, oldContract } = this.state
    const {
      fields: { start_date, end_date, sales_renting_phases, valid_date },
      enting_phase_recurring_date_compute_way,
      enting_phase_recurring_date_compute_day,
      loc_id
    } = this.props

    let endDate = null
    let startDate = null
    let sales_renting_phases_exclude_free = sales_renting_phases.filter(
      sale => sale.rent_type.value != 'free'
    )
    if (sales_renting_phases_exclude_free.length > 0) {
      let parEndDate =
        sales_renting_phases_exclude_free[
          sales_renting_phases_exclude_free.length - 1
        ].end_date.value
      startDate =
        (parEndDate &&
          moment(parEndDate)
            .add(1, 'days')
            .clone()
            .hours(12)
            .minutes(0)
            .seconds(0)
            .toDate()) ||
        start_date.value
    } else {
      startDate = start_date.value
    }
    endDate = end_date.value
    if (status == 'update') {
      startDate = moment(valid_date.value).format('YYYY-MM-DD')
    }

    KBPopover.show(
      <RentingPhasesForm
        loc_id={loc_id}
        callback={this.updateRentingPhases}
        start_date={startDate}
        end_date={endDate}
        subscription_start_date={start_date.value}
        subscription_end_date={end_date.value}
        total={monthly || 0}
        status={status}
        valid_date={valid_date}
        totalTenement={0}
        oldContract={oldContract}
        house_fee_per_m2={0}
        enting_phase_recurring_date_compute_way={
          enting_phase_recurring_date_compute_way
        }
        enting_phase_recurring_date_compute_day={
          enting_phase_recurring_date_compute_day
        }
        sales_renting_phases={sales_renting_phases}
        monthly_renting_fees={[]}
      />
    )
  },

  freePhases() {
    const {
      fields: { start_date, sales_renting_phases, end_date }
    } = this.props
    let endDate = null
    let startDate = null
    let contractEndDate = end_date.value
    if (sales_renting_phases.length > 0) {
      let parEndDate =
        sales_renting_phases[sales_renting_phases.length - 1].end_date.value
      startDate =
        (parEndDate &&
          moment(parEndDate)
            .add(1, 'days')
            .clone()
            .hours(12)
            .minutes(0)
            .seconds(0)
            .toDate()) ||
        start_date.value
    } else {
      startDate = start_date.value
    }
    endDate = moment(startDate)
      .add(1, 'months')
      .subtract(1, 'day')

    KBPopover.show(
      <FreePhasesForm
        callback={this.updateRentingPhases}
        start_date={startDate}
        end_date={endDate}
        subscription_start_date={start_date.value}
        subscription_end_date={end_date.value}
        contractEndDate={contractEndDate}
        sales_renting_phases={sales_renting_phases}
      />
    )
  },

  updateRentingPhases(phases, isRemoveAllPhases) {
    const {
      fields: { sales_renting_phases },
      apiCallFailureActions,
      status
    } = this.props
    if (!phases.length) {
      apiCallFailureActions({
        status: 422,
        message: '您的开始日期、结束日期填写无效'
      })
      return
    }

    if (!sales_renting_phases.length) {
      addField()
    } else {
      if (status == 'update' && isRemoveAllPhases) {
        this.removeUpdatePhasesNoCertain()
        addField()
      } else {
        let index = sales_renting_phases.findIndex(phase => {
          return (
            contrastDate(
              phases[0].start_date,
              phase.start_date.value,
              'integer'
            ) < 0
          )
        })
        if (index == -1) {
          addField()
        } else {
          phases &&
            phases.reverse().forEach(phase => {
              sales_renting_phases.addField(getPhase(phase), index)
            })
        }
      }
    }

    function addField() {
      phases &&
        phases.forEach(phase => {
          sales_renting_phases.addField(getPhase(phase))
        })
    }
    function getPhase(phase) {
      return Object.assign({}, phase, {
        //id               : phase.id,
        start_date: moment(phase.start_date)
          .clone()
          .hours(12)
          .minutes(0)
          .seconds(0)
          .toDate(),
        end_date: moment(phase.end_date)
          .clone()
          .hours(12)
          .minutes(0)
          .seconds(0)
          .toDate(),
        recurring_amount: phase.recurring_amount,
        recurring_renting_fee: phase.recurring_renting_fee,
        recurring_house_fee: phase.recurring_house_fee,
        total_amount: phase.total_amount,
        total_renting_fee: phase.total_renting_fee,
        total_house_fee: phase.total_house_fee,
        rent_type: phase.rent_type,
        note: phase.note
      })
    }
  },

  removePhases(type) {
    const {
      fields: { sales_renting_phases }
    } = this.props
    let sales_renting_phases_clone = _.cloneDeep(sales_renting_phases)
    let indexTemp = []
    let d = 0

    KBPopover.plugins.confirm(
      `删除${type == 'normal' ? '缴租阶段' : '免租期'}`,
      `是否确定删除${type == 'normal' ? '缴租阶段' : '免租期'}?`,
      {
        confirm: () => {
          sales_renting_phases_clone.forEach((phase, index) => {
            if (phase.rent_type.value == type) indexTemp.push(index)
          })

          indexTemp.forEach(i => {
            sales_renting_phases.removeField(i - d)
            ++d
          })
          KBPopover.close()
        }
      }
    )
  },

  removeUpdatePhases() {
    const {
      fields: { sales_renting_phases }
    } = this.props
    let sales_renting_phases_clone = _.cloneDeep(sales_renting_phases)
    let indexTemp = []
    let d = 0

    KBPopover.plugins.confirm(`删除缴租阶段`, `是否确定删除缴租阶段`, {
      confirm: () => {
        sales_renting_phases_clone.forEach((phase, index) => {
          indexTemp.push(index)
        })

        indexTemp.forEach(i => {
          sales_renting_phases.removeField(i - d)
          ++d
        })
        KBPopover.close()
      }
    })
  },

  recordPhasesMessage(obj) {
    this.setState({
      old_sales_renting_phases: obj
    })
  },

  phasesJsx() {
    let {
      fields: {
        sales_renting_phases,
        sales_renting_phases_error,
        sales_tax_id,
        valid_date
      },
      taxes,
      status
    } = this.props
    const { oldContract, phase_reset_status } = this.state
    let total = 0
    let tax = taxes.find(t => t.id == sales_tax_id.value)

    return status != 'update' ? (
      <OrgSubscriptionCreateRentingPhase
        sales_renting_phases={sales_renting_phases}
        sales_renting_phases_error={sales_renting_phases_error}
        removePhases={this.removePhases}
        total={total}
        tax={tax}
        parseRentingPhases={this.parseRentingPhases}
        isShowPhases={status == 'edit' ? true : false}
        oldContract={oldContract}
        freePhases={this.freePhases}
        isParking={true}
      />
    ) : (
      <OrgSubscriptionEditRentingPhase
        sales_renting_phases={sales_renting_phases}
        sales_renting_phases_error={sales_renting_phases_error}
        removeUpdatePhases={this.removeUpdatePhases}
        oldContract={oldContract}
        tax={tax}
        status={status}
        phase_reset_status={phase_reset_status}
        recordPhasesMessage={this.recordPhasesMessage}
        validDate={valid_date.value}
        parseRentingPhases={this.parseRentingPhases}
        freePhases={this.freePhases}
        isParking={true}
      />
    )
  },

  // 合同编号 ============================================================
  serialJsx() {
    const {
      fields: { serial }
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend className="m-none p-top border-top">合同编号</legend>
        <div className="kb-row kb-form-66">
          <KBInput className="kb-form-group kb-form-c39" field={serial} />
        </div>
      </fieldset>
    )
  },

  // 合同备注 ============================================================
  notesJsx() {
    const {
      fields: { note }
    } = this.props
    const hint = ADD_SUBSCRIPTION

    return (
      <fieldset className="fieldset">
        <legend className="m-none p-top border-top">合同备注</legend>
        <div className="kb-row">
          <KBTextarea
            className="kb-form-group kb-form-100 kb-form-alone"
            field={note}
            placeholder="请输入合同备注"
            hint={hint.remark}
            label="true"
          />
        </div>
      </fieldset>
    )
  },

  render() {
    const { submitting, handleSubmit, status } = this.props
    const { hasOrg } = this.state

    return (
      <KBForm
        type="reduxForm"
        fields={this.props.fields}
        onSubmit={handleSubmit(this.createParkingContract)}
      >
        <header className="kb-form-header">
          <h2 className="header-title">输入合同细节</h2>
        </header>
        <div className="kb-form-container">
          {this.renterInfoJsx()}
          {hasOrg && this.rentTypeJsx()}
          {hasOrg &&
            (status === 'update' ? this.dateUpdateJsx() : this.dateJsx())}
          {hasOrg && this.parkingsJsx()}
          {hasOrg && this.taxJsx()}
          {hasOrg && this.depositJsx()}
          {hasOrg && this.phasesJsx()}
          {hasOrg && status !== 'update' && this.serialJsx()}
          {hasOrg && this.notesJsx()}

          {hasOrg ? (
            <div className="t-right">
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                确认创建
              </KBButton>
            </div>
          ) : null}
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { subscription_id } = props

  let contract_id = subscription_id
  let taxes = Object.assign([], selectors.getTaxes(state))
  taxes = _.cloneDeep(taxes).map(tax => {
    let name = tax.name
    tax.name = `${name} (税率:${toPercent(tax.rate, 2)})`
    return tax
  })
  let spaceSetting = selectors.getSpaceSetting(state) || {}
  let financeNumberFormat =
    spaceSetting.settings &&
    spaceSetting.settings.finance_number_format &&
    spaceSetting.settings.finance_number_format
  let enting_phase_recurring_date_compute_way =
    (spaceSetting.subscription_settings &&
      spaceSetting.subscription_settings
        .renting_phase_recurring_date_compute_way &&
      spaceSetting.subscription_settings
        .renting_phase_recurring_date_compute_way.type) ||
    'fixed'
  let enting_phase_recurring_date_compute_day =
    (spaceSetting.subscription_settings &&
      spaceSetting.subscription_settings
        .renting_phase_recurring_date_compute_way &&
      (enting_phase_recurring_date_compute_way === 'advance'
        ? spaceSetting.subscription_settings
            .renting_phase_recurring_date_compute_way.default_advanced || 7
        : spaceSetting.subscription_settings
            .renting_phase_recurring_date_compute_way.default_fixed || 1)) ||
    1

  return {
    contract_id,
    taxes,
    financeNumberFormat,
    enting_phase_recurring_date_compute_way,
    enting_phase_recurring_date_compute_day
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createSubscriptionAction: bindActionCreators(
      subscriptionsAction.create,
      dispatch
    ),
    updateSubscriptionAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    ),
    getTaxesAction: bindActionCreators(taxesAction.get, dispatch),
    getOrgAction: bindActionCreators(orgActions.get, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validatePhases = (phases, index, array) => {
  const errors = {}

  let endDate = phases.end_date
  let startDate = phases.start_date

  if (index != 0) {
    if (
      !moment(formatYearDayEN(startDate)).isSame(
        formatYearDayEN(moment(array[index - 1].end_date).add(1, 'days'))
      )
    ) {
      errors.start_date = '缴租阶段结束时间和开始时间必须连续'
    }
  }

  if (startDate && moment(endDate).diff(moment(startDate)) < 0) {
    errors.end_date = '每个缴租阶段结束日期必须大于开始日期'
  }

  return errors
}

const validate = values => {
  const errors = {}
  let endDate = moment(values.end_date).toDate()
  let startDate = moment(values.start_date).toDate()

  if (valid.required(values.start_date)) {
    errors.start_date = '请输入合同起始日期'
  }
  if (valid.required(values.end_date)) {
    errors.end_date = '请输入合同终止日期'
  }

  if (
    values.start_date &&
    values.end_date &&
    endDate.getTime() - startDate.getTime() < 0
  ) {
    errors.end_date = '合同截止日期必须大于起始日期'
  }

  if (valid.required(values.end_date)) {
    errors.end_date = '请输入合同终止日期'
  }

  if (valid.required(values.signed_at)) {
    errors.signed_at = '请输入合同签约日期'
  }

  if (values.areas.length == 0) {
    errors.areas_error = '请添加至少一个车位'
  }

  if (valid.required(values.deposit)) {
    errors.deposit = '请输入合同押金总金额'
  }

  if (values.sales_renting_phases.length == 0) {
    errors.sales_renting_phases_error = '请添加至少一个缴租阶段'
  }

  if (values.contract_operate_status != 'update') {
    values.sales_renting_phases.sort((a, b) => {
      return contrastDate(a.start_date, b.start_date)
    })
    errors.sales_renting_phases = values.sales_renting_phases.map(
      validatePhases
    )
  }

  return errors
}

const formConfig = {
  form: 'ParkingContractForm',
  fields: [
    'valid_date',
    'start_date',
    'end_date',
    'signed_at',
    'organization_id',
    'subscription_type',
    'renting_type_list',
    'areas[].area_id',
    'areas[].area_type',
    'areas[].desk_ids',
    'areas[].price',
    'areas[].total_price',
    'areas[].units',
    'areas[].rent_way',
    'areas[].car_plates[].car_plate',
    'areas[].car_plates[].driver_license',
    'areas[].car_plates[].owner_name',
    'areas_error',
    // 以下是用于展示的数据，可在 call api 时删除
    'areas[].name',
    'areas[].district_list',
    'areas[].ceil_list',
    'deposit',
    'deposit_diff',
    'deposit_cycles',
    'deposit_due_date',
    'merge_deposit_into_renting',
    'sales_tax_id',
    'sales_renting_phases[].start_date',
    'sales_renting_phases[].end_date',
    'sales_renting_phases[].total_amount',
    'sales_renting_phases[].recurring_amount',
    'sales_renting_phases[].recurring_cycles',
    'sales_renting_phases[].recurring_due_date',
    'sales_renting_phases[].recurring_house_fee',
    'sales_renting_phases[].recurring_renting_fee',
    'sales_renting_phases[].total_renting_fee',
    'sales_renting_phases[].total_house_fee',
    'sales_renting_phases[].rent_type',
    'sales_renting_phases[].note',
    'sales_renting_phases_error',
    'serial',
    'note',
    'files'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ParkingContractForm)
