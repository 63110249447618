import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { KBLoadingContainer, KBAvatar } from 'components'
import * as selectors from 'app/selectors'
import {
  ORG_SUBSCRIPTION_STATES,
  ORG_SUBSCRIPTION_STATES_CLASS
} from 'app/constants'
import { formatMinuteSecondCHS } from 'utils/kbUtil'
import { getUserInfoUrl } from 'utils/kbUrl'
import { KBNoData } from 'components'

var OrgSubscriptionDetailHistory = React.createClass({
  getInitialState() {
    return {}
  },

  componentDidMount() {},

  // 合同历史
  historyJsx() {
    const {
      subscription: { attrlogs },
      spaceUsers
    } = this.props
    if (attrlogs.length == 0) {
      return (
        <fieldset className="fieldset" style={{ textAlign: 'center' }}>
          <KBNoData tipMessage="暂无数据" />
        </fieldset>
      )
    }
    return (
      <fieldset className="fieldset">
        <table className="content-table m-bottom">
          <thead>
            <tr>
              <th>状态</th>
              <th>日期</th>
              <th>经办人</th>
              {/* <th>备注</th> */}
            </tr>
          </thead>
          <tbody>
            {attrlogs.map((log, index) => {
              return (
                <tr key={index}>
                  <td>{log.content || ''}</td>
                  <td>{formatMinuteSecondCHS(log.created_at)}</td>
                  <td>
                    <div className="vf-center">
                      <KBAvatar
                        style={{ display: 'inline-block', marginRight: '5px' }}
                        imgStyle={{ marginRight: 5 }}
                        user={log.creator}
                        size={25}
                      />
                      <Link
                        className="color-link"
                        to={getUserInfoUrl(log.creator && log.creator.id)}
                      >
                        {(log.creator && log.creator.name) || ''}
                      </Link>
                    </div>
                  </td>
                  {/* <td>{ log.note }</td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
      </fieldset>
    )
  },

  render() {
    const { subscription, pathname, typeSource } = this.props

    return (
      <div className="nav-section-container f-right">
        <KBLoadingContainer loading={false}>
          <header className="kb-form-header">
            <h2 className="header-title clear-fix">
              <span className="f-left" style={{ color: '#333' }}>
                <Link
                  to={`${pathname}${
                    typeSource && typeSource == 'crmrecord'
                      ? '?typeSource=crmrecord'
                      : ''
                  }`}
                  style={{ color: '#999' }}
                  className="a-hover"
                >
                  合同详情 >{' '}
                </Link>
                操作记录
              </span>
            </h2>
          </header>
          <div className="kb-form-container">
            {subscription && this.historyJsx()}
          </div>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let subscription_id = props.params.subscription_id
  let subscription = selectors.getEntity(
    state,
    'subscriptions',
    subscription_id
  )
  let spaceUsers = state.entities.users

  return {
    subscription,
    spaceUsers
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionDetailHistory)
