import React from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { subscriptionsAction, successState, orgActions } from 'app/actions'
import {
  getSubScriptions,
  getLocationsAsDictionary,
  getCurrentUser,
  getOrgsOfLocation
} from 'app/selectors'
import {
  ORG_SUBSCRIPTION_STATES_CLASS_NEW,
  ORG_SUBSCRIPTION_STATES,
  INVOICES_CONTRACT_STATES,
  PARKING_RENT_TYPE,
  PARKING_RENT_TYPE_OBJ,
  CAR_PLATES_SAVED,
  CAR_PLATES_SAVED_OBJ
} from 'app/constants'
import {
  getEntitiesJsonArray,
  paginate,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown,
  formatYearDaySOL,
  fmoney,
  formatYearDayEN
} from 'utils/kbUtil'
import {
  KBDropdown,
  KBPagination,
  KBRangeDate,
  KBPopover,
  KBTableWithoutData,
  KBLoadingContainer,
  KBTipsy,
  KBCheckBox
} from 'components'
import { KBDropSelect } from 'components/plugins'
import { KBPopoverConfirm } from 'components/tools'
import OnlyTimeReportForm from '../invoices/OnlyTimeReportForm'
import { canAccessLocation } from 'app/reducers/role'

const DROP_DOWNS = [
  'statusDropdown',
  'rentTypeDropdown',
  'dateDropDown',
  'company',
  'carPlatesSaved'
]

var ParkingContracts = React.createClass({
  displayName: 'ParkingContracts',

  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      filters,
      pagination: null,
      showCarPlates: {},
      selectInvoices: [],
      isAllInvoicesChecked: false,
      allCheckedBtn: false
    }
  },

  componentDidMount() {
    this.mounted = true
    this.getSubscriptions()
    this.getParkingRenters()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.getSubscriptions(nextProps.page)
    }
  },

  getSubscriptions(newPage) {
    const { getSubscriptionsAction, routerActions, location_id } = this.props
    const { filters } = this.state

    const per_page = 10
    let params = Object.assign(
      {},
      { per_page, page: newPage || this.props.page },
      { location_id },
      filters
    )

    let queryFiltersStr = queryFilter({
      ...filters,
      page: newPage || this.props.page
    })
    this.setState({ loading: true })
    api
      .getSubscriptions({
        ...params,
        subscription_type: 'parking',
        includes: 'sales_area,organization'
      })
      .then(
        json => {
          let pagination = paginate(json.response)
          getSubscriptionsAction.success(json)
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/locations/${location_id}/parking/contract${queryFiltersStr}`
          )
          let subscriptions = getEntitiesJsonArray(json, 'subscriptions')
          this.setState({
            subscriptions,
            pagination
          })
        },
        errors => {
          getSubscriptionsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  getParkingRenters() {
    const { location_id, getOrganizationsAction } = this.props

    api
      .getOrganizations({
        location_id,
        per_page: 1000,
        organization_type: 'simple,inner_user,outer_user'
      })
      .then(
        json => {
          getOrganizationsAction.success(json)
        },
        errors => {
          getOrganizationsAction.failure(errors)
        }
      )
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, page: 1 })
    this.rentTypeDropdown && this.rentTypeDropdown.hide()
    this.statusDropdown && this.statusDropdown.hide()
    this.dateDropDown && this.dateDropDown.hide()
    this.company && this.company.hide()
    this.carPlatesSaved && this.carPlatesSaved.hide()

    setTimeout(this.getSubscriptions.bind(null, 1))
  },

  _clearFilters() {
    const { routerActions, location_id } = this.props
    this.setState({ filters: {}, page: 1 })
    setTimeout(this.getSubscriptions.bind(null, 1))
    routerActions.replace(`/admin/locations/${location_id}/parking/contract`)
  },

  changeDate(range) {
    this._setFilter({
      start_date: formatYearDayEN(range.from),
      end_date: formatYearDayEN(range.to)
    })
  },

  orgSelectCallBack(data) {
    this._setFilter({
      organization_id: data && data.id
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  toCreatePage() {
    const { routerActions, location_id } = this.props
    routerActions.push(`/admin/locations/${location_id}/parking/contract_new`)
  },

  toParkingContractDetail(id) {
    const { routerActions, location_id } = this.props
    routerActions.push(`/admin/locations/${location_id}/parking/contract/${id}`)
  },

  _renderFilters() {
    const { filters } = this.state
    const { start_date, end_date, organization_id } = filters
    const { parkingRenters, currentRenter } = this.props

    return (
      <div className="nav-section-action-bar">
        <KBDropSelect
          ref={ref => {
            this.company = ref
          }}
          dropDown_name="company"
          selectedData={currentRenter}
          closeClick={this.closeDropDowns}
          options={{ defaultSingleContent: '选择承租方' }}
          defaultData={parkingRenters}
          multi={false}
          callback={this.orgSelectCallBack}
          style={{ marginRight: 20 }}
        />

        <div className="select-progress">
          <KBDropdown
            ref={ref => {
              this.statusDropdown = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'statusDropdown')}
              >
                <i className="iconfont icon-finance" />
                <span>
                  {this.state.filters.status
                    ? ORG_SUBSCRIPTION_STATES[this.state.filters.status]
                    : '选择合同状态'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter({ status: undefined })}>
                    全部
                  </li>
                  {INVOICES_CONTRACT_STATES.map((state, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => this._setFilter({ status: state.id })}
                      >
                        <span>{state.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        </div>

        <div className="select-progress">
          <KBDropdown
            ref={ref => {
              this.rentTypeDropdown = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'rentTypeDropdown')}
              >
                <i className="iconfont icon-finance" />
                <span>
                  {this.state.filters.renting_type_list
                    ? PARKING_RENT_TYPE_OBJ[
                        this.state.filters.renting_type_list
                      ]
                    : '选择租赁类型'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li
                    onClick={() =>
                      this._setFilter({ renting_type_list: undefined })
                    }
                  >
                    全部
                  </li>
                  {PARKING_RENT_TYPE.map((type, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          this._setFilter({ renting_type_list: type.id })
                        }
                      >
                        <span>{type.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        </div>

        <div className="select-progress">
          <KBDropdown
            ref={ref => {
              this.carPlatesSaved = ref
            }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'carPlatesSaved')}
              >
                <i className="iconfont icon-finance" />
                <span>
                  {this.state.filters.car_plates_saved
                    ? CAR_PLATES_SAVED_OBJ[this.state.filters.car_plates_saved]
                    : '是否收录'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  {CAR_PLATES_SAVED.map((type, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          this._setFilter({ car_plates_saved: String(type.id) })
                        }
                      >
                        <span>{type.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        </div>

        <KBRangeDate
          leftNone={true}
          title="选择合同起始日范围"
          from={start_date || ''}
          to={end_date || ''}
          callback={this.changeDate}
          ref={ref => {
            this.dateDropDown = ref
          }}
          dropDown_name="dateDropDown"
          closeClick={this.closeDropDowns}
        />
      </div>
    )
  },

  checkAllInvoices() {
    const { allCheckedBtn } = this.state
    const { subscriptions } = this.state
    let isAllInvoicesChecked_now = !allCheckedBtn
    let invoices_now = subscriptions
    if (!isAllInvoicesChecked_now) {
      invoices_now = []
    }
    this.setState({
      isAllInvoicesChecked: isAllInvoicesChecked_now,
      selectInvoices: invoices_now,
      allCheckedBtn: isAllInvoicesChecked_now
    })
  },

  changeInvoice(checked, data) {
    var invoices = Object.assign([], this.state.selectInvoices)
    if (checked) {
      invoices.push(data)
      // 当选中的 invoice 逐个增加时的判断
      if (invoices.length == 10) {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: true,
          isAllInvoicesChecked: true
        })
      } else {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: false
        })
      }

      return
    }

    let removeIndex = invoices.findIndex(json => json.id == data.id)

    if (removeIndex == -1) {
      return
    }

    invoices.splice(removeIndex, 1)
    // 当选中的 invoice 逐个减少时的判断
    if (invoices.length == 10) {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: true
      })
    } else {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: false
        // isAllInvoicesChecked : false
      })
    }
  },

  changeExpand(sub_id) {
    const { showCarPlates } = this.state
    const newShowCarPlates = Object.assign({}, showCarPlates)
    newShowCarPlates[sub_id] = !showCarPlates[sub_id]
    this.setState({
      showCarPlates: newShowCarPlates
    })
  },

  batchSaveCarPlates(ids) {
    const { location_id, updateSubscriptionsAction } = this.props

    KBPopoverConfirm({
      name: '收录',
      context: '是否确定收录?',
      callback: () => {
        return api
          .putContractsSaveCarPlates({
            location_id,
            subscription_ids: ids,
            car_plates_saved: true
          })
          .then(
            json => {
              updateSubscriptionsAction.success(json)
              KBPopover.close()
            },
            error => {
              updateSubscriptionsAction.failure(error)
              KBPopover.close()
            }
          )
      }
    })
  },

  isBatchAudit() {
    const { selectInvoices } = this.state

    if (selectInvoices.length === 0) {
      return null
    }

    let isAllFalse = selectInvoices.every(ele => {
      return !ele.car_plates_saved
    })

    return (
      <div className="invoice-table-upon">
        {isAllFalse ? (
          <button
            className="bordered-btn m-none"
            onClick={() => {
              this.batchSaveCarPlates(
                selectInvoices.map(data => data.id).join(',')
              )
            }}
          >
            收录
          </button>
        ) : null}
      </div>
    )
  },

  parkingContractReport() {
    const { location_id } = this.props
    KBPopover.show(
      <OnlyTimeReportForm loc_id={location_id} type="parking_contract" />
    )
  },

  render() {
    const {
      loading,
      pagination,
      filters,
      showCarPlates,
      selectInvoices
    } = this.state
    const { location_id, subscriptions, isManager } = this.props
    let selectFilters = Object.values(filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    const tdStyle = {
      paddingTop: '140px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8,
      cursor: 'default'
    }

    return (
      <div className="kb-content" style={{ paddingTop: 0 }}>
        <div className="kb-content-container clear-fix">
          <header className="nav-section-header clear-fix border-bottom">
            <div className="nav-section-header-title f-left">
              <span>车位合同</span>
            </div>
            {isManager ? (
              <button
                className="bordered-btn f-right"
                onClick={this.toCreatePage}
              >
                <i className="iconfont icon-add" />
                租赁车位
              </button>
            ) : null}
            <button
              className="c-btn-secondary f-right"
              onClick={this.parkingContractReport}
            >
              导出车位合同信息
            </button>
          </header>
          <div className="nav-section-content-container">
            <div className="m-updown">
              {this._renderFilters()}
              {selectFilters.length > 0 ? (
                <div className="clear-criteria" onClick={this._clearFilters}>
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
            <KBLoadingContainer loading={loading}>
              {this.isBatchAudit()}
              <table className="content-table edit-table">
                <thead>
                  <tr>
                    <th>
                      <KBCheckBox
                        style={{ marginTop: 2 }}
                        data={subscriptions}
                        callback={this.checkAllInvoices}
                        checked={this.state.allCheckedBtn}
                      />
                    </th>
                    <th>承租方</th>
                    <th>车位承租人</th>
                    <th>合同编号</th>
                    <th>状态</th>
                    <th>租赁类型</th>
                    <th>开始日</th>
                    <th>结束日</th>
                    <th>车位数量</th>
                    <th>合同总额</th>
                  </tr>
                </thead>
                {(subscriptions &&
                  subscriptions.length > 0 &&
                  subscriptions.map((sub, index) => {
                    const isExpanded = showCarPlates[String(sub.id)]
                    return (
                      <tbody>
                        <tr
                          onClick={this.toParkingContractDetail.bind(
                            null,
                            sub.id
                          )}
                        >
                          <td
                            style={{
                              position: 'static',
                              verticalAlign: 'middle'
                            }}
                            onClick={e => e.stopPropagation()}
                          >
                            <KBCheckBox
                              style={{ marginTop: 2 }}
                              data={sub}
                              callback={this.changeInvoice}
                              checked={
                                (selectInvoices &&
                                  selectInvoices.length &&
                                  selectInvoices.find(data => {
                                    return data.id === sub.id
                                  })) ||
                                false
                              }
                            />
                          </td>
                          <td>{sub.sales_customer.name}</td>
                          <td>
                            {sub.organization && sub.organization.contacts_name}
                          </td>
                          <td>
                            <span>{sub.serial}</span>
                            {sub.car_plates_saved ? (
                              <span
                                className="span-type-smail-i m-left-sm"
                                style={{ display: 'inline-block' }}
                              >
                                已收录
                              </span>
                            ) : null}
                          </td>
                          <td style={{ minWidth: 70 }}>
                            <span
                              style={{ marginLeft: 0, whiteSpace: 'nowrap' }}
                              className={`finance-default ${
                                ORG_SUBSCRIPTION_STATES_CLASS_NEW[sub.status]
                              }`}
                            >
                              {ORG_SUBSCRIPTION_STATES[sub.status]}
                            </span>
                          </td>
                          <td>
                            {(sub.renting_type_list &&
                              sub.renting_type_list[0]) ||
                              '月租'}
                          </td>
                          <td>{formatYearDaySOL(sub.start_date)}</td>
                          <td>{formatYearDaySOL(sub.end_date)}</td>
                          <td>
                            <span>
                              {(sub.sales_areas && sub.sales_areas.length) || 0}
                            </span>
                            <KBTipsy
                              content={
                                isExpanded ? '点击收起车牌号' : '点击查看车牌号'
                              }
                            >
                              <i
                                className={`iconfont ${
                                  isExpanded
                                    ? 'icon-arrow_drop_down_circle'
                                    : 'icon-navigate_next'
                                }`}
                                onClick={e => {
                                  e.stopPropagation()
                                  this.changeExpand(sub.id)
                                }}
                                style={{
                                  fontSize: 18,
                                  verticalAlign: 'middle',
                                  color: '#999',
                                  marginLeft: 10
                                }}
                              />
                            </KBTipsy>
                          </td>
                          <td>¥{fmoney(sub.total_amount || 0)}</td>
                        </tr>
                        {isExpanded ? (
                          <tr className="expand-th" style={{ border: 'none' }}>
                            <td colSpan="4" className="t-center">
                              车位编号
                            </td>
                            <td>车牌号1</td>
                            <td>车牌号2</td>
                            <td>车牌号3</td>
                            <td>车牌号4</td>
                            <td>车牌号5</td>
                            <td
                              className="border-none"
                              style={{ backgroundColor: '#f0f2f3' }}
                            />
                          </tr>
                        ) : null}
                        {isExpanded &&
                          sub.sales_areas &&
                          sub.sales_areas.map(parking => {
                            const { car_plates } = parking
                            return (
                              <tr
                                key={index}
                                className="expand-tr"
                                style={{ border: 'none' }}
                              >
                                <td colSpan="4" className="t-center">
                                  {parking.area_name}
                                </td>
                                <td>
                                  {car_plates &&
                                    car_plates[0] &&
                                    car_plates[0].car_plate}
                                </td>
                                <td>
                                  {car_plates &&
                                    car_plates[1] &&
                                    car_plates[1].car_plate}
                                </td>
                                <td>
                                  {car_plates &&
                                    car_plates[2] &&
                                    car_plates[2].car_plate}
                                </td>
                                <td>
                                  {car_plates &&
                                    car_plates[3] &&
                                    car_plates[3].car_plate}
                                </td>
                                <td>
                                  {car_plates &&
                                    car_plates[4] &&
                                    car_plates[4].car_plate}
                                </td>
                                <td
                                  className="border-none"
                                  style={{ backgroundColor: '#f0f2f3' }}
                                />
                              </tr>
                            )
                          })}
                      </tbody>
                    )
                  })) || (
                  <tbody>
                    <tr style={{ border: 0, backgroundColor: '#ffffff' }}>
                      <td style={tdStyle} colSpan="8">
                        暂无合同
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              <KBPagination
                pagination={pagination}
                template={`/admin/locations/${location_id}/parking/contract?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let location_id = params.id
  let user = getCurrentUser(state)
  let isManager = canAccessLocation(user, location_id)
  let page = parseInt(location.query && location.query.page, 10) || 1
  let parkingRenters = getOrgsOfLocation(state, location_id)
  let subscriptions = getSubScriptions(state)
  //   subscriptions = subscriptions.filter(sub => sub.subscription_type === 'parking')
  let locations = getLocationsAsDictionary(state)
  var FILTER_STATE = [
    'status',
    'start_date',
    'end_date',
    'organization_id',
    'car_plates_saved'
  ]

  var param = getFilterAndQuery(location.query, FILTER_STATE)

  let currentRenter = {}
  if (param.filters.organization_id) {
    currentRenter = parkingRenters.find(
      renter => param.filters.organization_id == renter.organization_id
    )
  }

  return {
    user,
    subscriptions,
    locations,
    location_id,
    page,
    parkingRenters,
    currentRenter,
    isManager,
    ...param
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSubscriptionsAction: bindActionCreators(
      subscriptionsAction.replace,
      dispatch
    ),
    updateSubscriptionsAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    deleteSubscriptionsAction: bindActionCreators(
      subscriptionsAction.delete,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch),
    getOrganizationsAction: bindActionCreators(orgActions.replace, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingContracts)
