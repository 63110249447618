import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { KBLoadingContainer } from 'components'
import * as selectors from 'app/selectors'
import {
  ORG_SUBSCRIPTION_STATES,
  ORG_SUBSCRIPTION_STATES_CLASS_NEW
} from 'app/constants'
import { formatMinuteSecondCHS } from 'utils/kbUtil'
import { KBNoData } from 'components'

var OrgSubscriptionDetailHistories = React.createClass({
  getInitialState() {
    return {}
  },

  componentDidMount() {},

  // 合同历史
  historyJsx() {
    const {
      subscription: { histories },
      pathname,
      typeSource
    } = this.props
    const tdStyle = {
      height: '280px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8,
      cursor: 'default'
    }
    return (
      <table className="content-table edit-table">
        <thead>
          <tr>
            <th>合同编号</th>
            <th>修改日期</th>
            <th style={{ width: 115 }}>合同状态</th>
          </tr>
        </thead>
        {histories && histories.length ? (
          histories.map((json, index) => {
            if (json.status == 'approving' && json.step == 'detail') {
              json.status = 'waiting_approving'
            } else if (json.status == 'approving' && json.step == 'audit') {
              json.status = 'already_approving'
            }
            return (
              <tr key={index}>
                <td>
                  <Link
                    to={`${pathname}${
                      typeSource && typeSource == 'crmrecord'
                        ? `?typeSource=crmrecord&historyIndex=${index}`
                        : `?historyIndex=${index}`
                    }`}
                    style={{ color: '#999' }}
                    className="a-hover"
                  >
                    {json.serial}
                  </Link>
                </td>
                <td>
                  {(json.diffed_at && formatMinuteSecondCHS(json.diffed_at)) ||
                    ''}
                </td>
                <td>
                  <span
                    style={{ marginLeft: 0, minWidth: 64, textAlign: 'center' }}
                    className={`finance-default ${
                      ORG_SUBSCRIPTION_STATES_CLASS_NEW[
                        json.status == 'expiring' ? 'active' : json.status
                      ]
                    }`}
                  >
                    {
                      ORG_SUBSCRIPTION_STATES[
                        json.status == 'expiring' ? 'active' : json.status
                      ]
                    }
                  </span>
                </td>
              </tr>
            )
          })
        ) : (
          <tbody>
            <tr style={{ border: 0, backgroundColor: '#ffffff' }}>
              <td colSpan="9" style={tdStyle}>
                <KBNoData tipMessage="暂无数据" />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    )
  },

  render() {
    const { subscription, pathname, typeSource } = this.props

    return (
      <div className="nav-section-container f-right">
        <KBLoadingContainer loading={false}>
          <header className="kb-form-header">
            <h2 className="header-title clear-fix">
              <span className="f-left" style={{ color: '#333' }}>
                <Link
                  to={`${pathname}${
                    typeSource && typeSource == 'crmrecord'
                      ? '?typeSource=crmrecord'
                      : ''
                  }`}
                  style={{ color: '#999' }}
                  className="a-hover"
                >
                  合同详情 >{' '}
                </Link>
                历史记录
              </span>
            </h2>
          </header>
          <div className="kb-form-container">
            {subscription && this.historyJsx()}
          </div>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let subscription_id = props.params.subscription_id
  let subscription = selectors.getEntity(
    state,
    'subscriptions',
    subscription_id
  )
  let spaceUsers = state.entities.users

  return {
    subscription,
    spaceUsers
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionDetailHistories)
