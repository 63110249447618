import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import {
  KBDateInput,
  KBTextarea,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopoverTop
} from 'components'
import { LINE_ITEM_TYPES } from 'app/constants'
import { getSubScriptionsByOrg } from 'app/selectors'

var OrgOffHireForm = React.createClass({
  getInitialState() {
    return {
      refund_type: []
    }
  },

  componentDidMount() {
    api.getSubscriptionLineItemRefundType().then(
      json => {
        this.setState({ refund_type: json.response.body })
      },
      error => {}
    )
  },

  deleteLineItem(line_items, item, index) {
    KBPopoverTop.plugins.confirm(
      '删除账款',
      `确定要删除该账款单价：${item.unit_price.value}, 数量：${item.units.value}, 说明：${item.description.value}?`,
      {
        confirm: () => {
          line_items.removeField(index)
          KBPopoverTop.close()
        }
      }
    )
  },

  render() {
    const { line_items, taxes } = this.props

    return (
      <div className="kb-form-container">
        <fieldset className="fieldset">
          <div className="form-content">
            {line_items.length ? (
              <ul className="region-input-box">
                {line_items &&
                  line_items.map((line, index) => {
                    return (
                      <li key={index}>
                        <div className="kb-row">
                          <i
                            className="iconfont icon-close"
                            onClick={() =>
                              this.deleteLineItem(line_items, line, index)
                            }
                          />
                          <div className="kb-row">
                            <div className="kb-form-group kb-form-alone kb-div-210">
                              <label>金额</label>
                              <div>
                                <input type="text" {...line.unit_price} />
                                {line.unit_price.touched &&
                                  line.unit_price.error && (
                                    <p className="lr-error">
                                      {' '}
                                      {line.unit_price.error}{' '}
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                          {/* <div className='kb-row'>
                              <div className='kb-form-group kb-form-alone kb-div-210'>
                                <label>数量</label>
                                <div>
                                  <input type='number' { ...line.units } />
                                  { line.units.touched && line.units.error && <p className='lr-error'> { line.units.error } </p> }
                                </div>
                              </div>
							</div> */}
                          <div className="kb-row">
                            <KBSelect
                              defaultValue="-1"
                              field={line.line_item_type}
                              arrayData={LINE_ITEM_TYPES}
                              title="类型"
                              className="kb-form-group kb-form-c18 kb-form-alone"
                            />
                          </div>
                          <div className="kb-row">
                            <KBSelect
                              defaultValue="-1"
                              field={line.sales_tax_id}
                              arrayData={taxes}
                              title="税率"
                              className="kb-form-group kb-form-210 kb-form-alone"
                            />
                          </div>
                          <div className="kb-row">
                            <KBSelect
                              className="kb-form-group kb-form-210 kb-form-alone"
                              title="退款类型"
                              field={line.refund_type}
                              arrayData={this.state.refund_type}
                            />
                          </div>
                          <div className="kb-row">
                            <KBTextarea
                              field={line.description}
                              title="说明"
                              className="kb-form-group kb-form-210 kb-form-alone"
                            />
                          </div>
                        </div>
                      </li>
                    )
                  })}
              </ul>
            ) : (
              ''
            )}

            <div className="f-left">
              <button
                className="bordered-btn"
                style={{ marginLeft: 0 }}
                onClick={() => {
                  line_items.addField({ units: 1 })
                }}
                type="button"
              >
                <i className="iconfont icon-add" />
                账款
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    )
  }
})

export default OrgOffHireForm
