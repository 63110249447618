import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import { Link } from 'react-router'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBRangeDate,
  KBTableWithoutData
} from 'components'
import { connect } from 'react-redux'
import { INVOICE_SALES_PAYMENTS } from 'app/constants'
import { paginate, formatMinuteSecondEN } from 'utils/kbUtil'
import moment from 'moment'
import * as selectors from 'app/selectors'
import { getUserInfoUrl } from 'utils/kbUrl'
import _ from 'lodash'

var MembersElevator = React.createClass({
  displayName: 'MembersElevator',
  getInitialState() {
    return {
      pagination: null,
      loading: true,
      info: []
    }
  },

  componentDidMount() {
    this.loadData()
  },

  loadData(page = 1) {
    const { member_id, user } = this.props
    const { filters } = this.state
    api
      .getElevatorRecord(member_id, { id: member_id })
      .then(({ json, response }) => {
        let pagination = paginate(response)
        this.setState({
          info: json,
          pagination
        })
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.page !== this.props.page) {
      this.loadData(nextProps.page)
    }
  },

  render() {
    const { info, loading } = this.state
    const { member_id } = this.props
    return (
      <section className="nav-section-container f-right">
        <div style={{ height: '100%' }}>
          <header className="nav-header">
            <div className=" clear-fix">
              <div className="nav-section-header-title f-left">
                <span>face++信息</span>
              </div>
            </div>
          </header>
          <div className="nav-section-content-container">
            <KBLoadingContainer loading={loading}>
              <table className="content-table">
                <thead>
                  <tr>
                    <th>项目名称</th>
                    <th>UID</th>
                    <th>卡号</th>
                    <th>同步时间</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={info.length > 0}
                  tableHeadNum="3"
                  tipMessage="暂无数据"
                >
                  {info &&
                    info.map((value, index) => {
                      const created_at = moment(
                        `${value.created_at}000` - 0
                      ).format('YYYY-MM-DD HH:mm')
                      return (
                        <tr key={index}>
                          <td>{_.get(value, 'location.name', '')}</td>
                          <td>{_.get(value, 'subject_id', '')}</td>
                          <td>{_.get(value, 'elevator_number', '')}</td>
                          <td>{created_at}</td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={this.state.pagination}
                template={`${getUserInfoUrl(member_id)}/elevator?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let page = parseInt(location.query && location.query.page) || 1
  const member_id = params.member_id
  return {
    page,
    member_id
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersElevator)
