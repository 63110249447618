import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  subscriptionActions,
  spacesSettingAction,
  subscriptionTempletesAction
} from 'app/actions'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import OrgSubscriptionForm from './OrgSubscriptionForm'
import SubscriptionApprovalForm from './SubscriptionApprovalForm'
import SubscriptionSignForm from './SubscriptionSignForm'
import SubscriptionLockLinkForm from './SubscriptionLockLinkForm'
import { KBMorePhotoUpload, KBLoadingContainer } from 'components'
import { routerActions } from 'react-router-redux'
import moment from 'moment'
import OrganizationAuditSuccess from './OrganizationAuditSuccess'
import OrganizationAuditFailure from './OrganizationAuditFailure'
import { listIsNullCallBack } from 'utils/kbUtil'

const step = [
  {
    id: 'chooseType',
    name: '选择合同类型',
    step_num: 1
  },
  {
    id: 'newTemplate',
    name: '选择新合同模板',
    superior: 'chooseType',
    isHidden: true
  },
  {
    id: 'extendSubscription',
    name: '选择续约合同',
    superior: 'chooseType',
    isHidden: true
  },
  {
    id: 'detail',
    name: '输入合同细节',
    step_num: 2
  },
  {
    id: 'lock_link',
    name: '关联门禁权限',
    step_num: 3
  },
  {
    id: 'audit',
    name: '提交合同审批',
    step_num: 4
  },
  {
    id: 'auditSuccess',
    name: '选择续约合同',
    superior: 'audit',
    isHidden: true
  },
  {
    id: 'auditFailure',
    name: '选择续约合同',
    superior: 'audit',
    isHidden: true
  },
  {
    id: 'sign',
    name: '打印合同签字',
    step_num: 5
  },
  {
    id: 'finish',
    name: '完成合同',
    step_num: 6
  }
]

const stepC = [
  {
    id: 'detail',
    name: '输入合同细节',
    step_num: 1
  },
  {
    id: 'audit',
    name: '提交合同审批',
    step_num: 2
  },
  {
    id: 'sign',
    name: '打印合同签字',
    step_num: 3
  },
  {
    id: 'finish',
    name: '完成合同',
    step_num: 4
  }
]

const OrgSubscriptionsGenerate = React.createClass({
  displayName: 'OrgSubscriptionsGenerate',
  getInitialState() {
    return {
      currentState: 'chooseType',
      errorMessage: '',
      data: {},
      extendSubscriptionList: [],
      currentSubscription: {},
      isLoading: false
    }
  },
  childContextTypes: {
    user: React.PropTypes.object,
    previewSubscription: PropTypes.func,
    isDraft: PropTypes.func,
    isCencelled: PropTypes.func,
    isNeedTemplate: PropTypes.bool
  },
  isContract() {
    const { query }=this.props.location;
    let isC=false;
    if(query.contract){
      isC=true
    }
    return isC;
  },
  getChildContext() {
    return {
      user: this.props.user,
      isDraft: this.isDraft,
      previewSubscription: this.previewSubscription,
      isCencelled: this.isCencelled,
      isNeedTemplate: this.props.isNeedTemplate
    }
  },
  setNextAndCurrentSubscription(subscription, nextStep) {
    const { loc_id, org_id, typeSource, routerActions } = this.props
    if (subscription.formal && nextStep == 'detail') {
      let url =
        typeSource && typeSource == 'crmrecord'
          ? `/admin/crm/opportunities/sub/organizations/${org_id}/subscriptions/new?contract_id=${subscription.id}&status=update&location_id=${loc_id}&typeSource=crmrecord`
          : `/admin/locations/${loc_id}/organizations/${org_id}/subscriptions/new?contract_id=${subscription.id}&status=update`
      routerActions.replace(url)
    }
    this.setState({
      currentState: nextStep,
      currentSubscription: subscription
    })
  },
  componentDidMount() {
    const {
      org_id,
      getSubscriptionActions,
      getSubscriptionTempletesAction,
      subscriptions,
      contract_id,
      status,
      isNeedTemplate
    } = this.props

    if(this.isContract()){
      this.setState({currentState:'detail'})
    }

    const { routerActions, loc_id, typeSource } = this.props

    api.getSubScriptionTempletes().then(
      json => {
        getSubscriptionTempletesAction.success(json)
      },
      error => {
        getSubscriptionTempletesAction.failure(error)
      }
    )

    api
      .getSubscriptions({
        organization_id: org_id,
        per_page: 500,
        subscription_type: 'normal'
      })
      .then(
        json => {
          getSubscriptionActions.success(json)
          let subscriptions = getEntitiesJsonArray(json, 'subscriptions') || []
          subscriptions = subscriptions.filter(
            subscription =>
              subscription.status == 'active' ||
              subscription.status == 'expiring'
          )
          this.setState({
            extendSubscriptionList: subscriptions
          })
        },
        error => {
          getSubscriptionActions.failure(error)
        }
      )
    if (status == 'extend') {
      this.setState({
        currentState: 'detail'
      })
    } else if (status == 'audit' && contract_id) {
      this.loadCurrentSubscription().then(json => {
        let subscriptions = getEntitiesJsonArray(json, 'subscriptions') || []
        let params = {
          isLoading: false,
          currentState: 'audit',
          currentSubscription: subscriptions
        }
        if (subscriptions.step === 'detail') {
          params.currentState = this.isContract()?'audit':'lock_link'
        }
        if (this.isDraft(subscriptions)) {
          params.currentState = 'sign'
        }

        if (this.isDraft(subscriptions) && subscriptions.step == 'sign') {
          params.currentState = 'sign'
        }

        if (
          (!subscriptions.step || subscriptions.step == 'auditSuccess') &&
          this.isDraft(subscriptions)
        ) {
          params.currentState = 'auditSuccess'
        }

        if (subscriptions.status == 'active') {
          let url =
            typeSource && typeSource == 'crmrecord'
              ? `/admin/crm/opportunities/sub/organizations/${org_id}/subscriptions/${subscriptions.id}?location_id=${loc_id}&typeSource=crmrecord`
              : `/admin/locations/${loc_id}/organizations/${org_id}/subscriptions/${subscriptions.id}`
          routerActions.push(url)
          return
        }

        if (this.isCencelled(subscriptions)) {
          params.currentState = 'detail'
        }

        if (
          (!subscriptions.step || subscriptions.step == 'auditFailure') &&
          this.isCencelled(subscriptions)
        ) {
          params.currentState = 'auditFailure'
        }

        this.setState(params)
      })
    } else if (
      (status == 'edit' || status == 'update' || status == 'anew') &&
      contract_id
    ) {
      this.loadCurrentSubscription().then(json => {
        let subscriptions = getEntitiesJsonArray(json, 'subscriptions') || []
        let params = {
          isLoading: false,
          currentState: 'detail',
          currentSubscription: subscriptions
        }

        this.setState(params)

        // 重新录入时删除原合同
        if (status === 'anew') {
          const { contract_id, deleteSubscriptionActions } = this.props
          api.deleteSubscription(contract_id).then(
            json => {
              deleteSubscriptionActions.success(json)
            },
            error => {
              deleteSubscriptionActions.failure(error)
            }
          )
        }
      })
    }
    if (!isNeedTemplate) {
      this.setStateDataRoot({
        templete_id: -1
      })
    }
  },
  loadCurrentSubscription() {
    const {
      org_id,
      getSubscriptionActions,
      getSpacesSettingAction,
      contract_id,
      status
    } = this.props
    const { routerActions, loc_id } = this.props
    this.setState({
      isLoading: true,
      currentState: ''
    })

    return new Promise((resolve, reject) => {
      api.getSubscriptionDetail(contract_id).then(
        json => {
          getSubscriptionActions.success(json)
          resolve(json)
        },
        error => {
          getSubscriptionActions.failure(error)
          reject(error)
        }
      )
    })
  },
  addNewSubscription() {
    this.setState({
      currentState: 'detail'
    })
  },

  extendSubscription() {
    this.setState({
      currentState: 'extendSubscription'
    })
  },

  renderChooseType() {
    const currentState = this.state.currentState
    const extendSubscriptionList = this.state.extendSubscriptionList

    if (currentState != 'chooseType') {
      return ''
    }

    return (
      <div className="sub-add-btn">
        <span
          className="bordered-btn-lg m-bottom-sm"
          onClick={this.addNewSubscription}
        >
          添加新合同
        </span>
        {extendSubscriptionList && extendSubscriptionList.length == 0 ? (
          ''
        ) : (
          <span className="bordered-btn-lg" onClick={this.extendSubscription}>
            旧合同续约
          </span>
        )}
      </div>
    )
  },
  changeTemplete(e) {
    let value = e.target.value
    this.setStateDataRoot({
      templete_id: value
    })
  },
  setStateDataRoot(data, obj) {
    this.setState({
      data: {
        ...this.state.data,
        ...data
      },
      ...obj
    })
  },
  subscriptionNextBtn() {
    const { isNeedTemplate } = this.props
    const { data } = this.state
    const templete_id = data.templete_id
    if (!templete_id) {
      this.setState({
        errorMessage: isNeedTemplate
          ? '暂无合同模板,请创建合同模板'
          : '请选择一个合同模板或者选择不采用'
      })
      return
    }

    this.setState({
      currentState: 'detail',
      errorMessage: ''
    })
  },
  renderNewTemplate() {
    const currentState = this.state.currentState
    const { data } = this.state
    const templete_id = data.templete_id

    if (currentState != 'newTemplate') {
      return ''
    }

    const { createSubscriptions, isNeedTemplate } = this.props

    return (
      <div style={{ padding: 30 }}>
        <div className="kb-title m-bottom-sm">
          <span>选择合同模板</span>
        </div>
        <div>
          {!createSubscriptions.length ? (
            <p style={{ padding: '30px 0px' }}>
              您当前没有新合同模板，如果要使用请创建新合同模板
            </p>
          ) : (
            ''
          )}
        </div>
        <div>
          {createSubscriptions.map((json, index) => {
            const { name, id } = json
            return (
              <div className="m-bottom-sm" key={index}>
                <input
                  name="subscriptionsradio"
                  id={index + 'subscriptionsradio'}
                  onChange={this.changeTemplete}
                  type="radio"
                  value={id}
                />
                <label
                  htmlFor={index + 'subscriptionsradio'}
                  style={{ marginLeft: 10 }}
                >
                  {name}
                </label>
              </div>
            )
          })}
        </div>
        {!isNeedTemplate ? (
          <div className="m-bottom-sm">
            <input
              name="subscriptionsradio"
              id={-1 + 'subscriptionsradio'}
              checked={templete_id == -1 ? 'checked' : ''}
              onChange={this.changeTemplete}
              type="radio"
              value="-1"
            />
            <label
              htmlFor={-1 + 'subscriptionsradio'}
              style={{ marginLeft: 10 }}
            >
              不采用合同模板
            </label>
          </div>
        ) : (
          ''
        )}
        <div>
          <button className="certain-btn" onClick={this.subscriptionNextBtn}>
            下一步
          </button>
        </div>
      </div>
    )
  },
  setStepState(step, data) {
    this.setState({
      currentState: step,
      ...data
    })
  },
  // 续约合同下一步按钮
  extendSubscriptionNextBtn() {
    const { data } = this.state
    const subscription_id = data.subscription_id
    const templete_id = data.templete_id
    const {
      isNeedTemplate,
      contract_id,
      routerActions,
      loc_id,
      org_id,
      typeSource
    } = this.props
    const contractId = subscription_id || contract_id

    if (!subscription_id && !contract_id) {
      this.setState({
        errorMessage: '必须选择一个续约合同'
      })
      return
    }
    // if (!templete_id) {
    //   this.setState({
    //     errorMessage : '请选择一个合同模板或者选择不采用'
    //   })
    //   return
    // }

    let params = {
      currentState: 'detail',
      errorMessage: ''
    }

    if (!isNeedTemplate && !contract_id && !subscription_id) {
      params.currentState = 'sign'
    }

    this.setState(params)

    let url =
      typeSource && typeSource == 'crmrecord'
        ? `/admin/crm/opportunities/sub/organizations/${org_id}/subscriptions/new?contract_id=${contractId}&status=extend&location_id=${loc_id}&typeSource=crmrecord`
        : `/admin/locations/${loc_id}/organizations/${org_id}/subscriptions/new?contract_id=${contractId}&status=extend`
    routerActions.replace(url)
  },
  changeExtendSubscriptionTemplete(e) {
    let value = e.target.value
    this.setStateDataRoot({
      templete_id: value
    })
  },
  changeExtendSubscription(e) {
    let value = e.target.value
    this.setStateDataRoot({
      subscription_id: value
    })
  },
  renderExtendSubscription() {
    const currentState = this.state.currentState

    if (currentState != 'extendSubscription') {
      return ''
    }

    const {
      extendSubscriptions,
      isNeedTemplate,
      contract_id,
      currentSubscription
    } = this.props
    const { extendSubscriptionList, data } = this.state
    const templete_id = data.templete_id

    return (
      <div>
        {!contract_id ? (
          <div style={{ padding: 30 }}>
            <div className="kb-title m-bottom-sm">
              <span>选择要续约的合同</span>
            </div>
            <div style={{ paddingLeft: 20 }}>
              {extendSubscriptionList.map((json, index) => {
                const { serial, id, expire_date, units } = json

                return (
                  <div
                    style={{ marginBottom: 10 }}
                    className="flex-vertical-center"
                    key={index}
                  >
                    <input
                      name="extendSubscriptionsradio"
                      id={index + 'extendSubscriptionsradio'}
                      onChange={this.changeExtendSubscription}
                      type="radio"
                      value={id}
                    />
                    <label
                      className="t-over"
                      htmlFor={index + 'extendSubscriptionsradio'}
                      style={{
                        marginLeft: 5,
                        display: 'inline-block',
                        width: 170
                      }}
                    >
                      {serial}
                    </label>
                    <span
                      className="m-left"
                      style={{ display: 'inline-block', width: 150 }}
                    >
                      到期日: {moment(expire_date).format('YYYY-MM-DD')}
                    </span>
                    <span className="m-left">工位数: {units}个</span>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          ''
        )}

        {/*<div className="kb-title m-bottom-sm" style={{marginLeft: 30,marginRight: 30}}>
          <span>选择合同模板</span>
        </div>*/}
        {/*<div>
          {
            !extendSubscriptions.length ? (
              <p style={{padding: '10px 30px'}}>您当前没有续约合同模板，如果要使用请创建续约合同模板</p>
            ) : ''
          }
        </div>*/}
        {/*<div style={{padding:'0 30px'}}>
          {
            extendSubscriptions.map((json, index) => {
              const { name, id } = json
              return (
                <div style={{marginBottom : 10}}>
                  <input name='subscriptionsradio' id={ index + 'subscriptionsradio' } onChange={ this.changeExtendSubscriptionTemplete } type='radio' value={ id } />
                  <label htmlFor={ index + 'subscriptionsradio' } style={{marginLeft : 5}}>{ name }</label>
                </div>
              )
            })
          }
        </div>*/}
        {/*
          !isNeedTemplate ? (
            <div style={{padding:'0 30px',marginBottom : 5}}>
              <input name='subscriptionsradio' id={ -1 + 'subscriptionsradio' } checked={ templete_id == -1 ? 'checked' : '' } onChange={ this.changeExtendSubscriptionTemplete } type='radio' value='-1' />
              <label htmlFor={ -1 + 'subscriptionsradio' } style={{marginLeft : 5}}>不采用合同模板</label>
            </div>
          ) : ''
        */}
        <div style={{ padding: '0 30px 30px 30px' }}>
          <button
            className="certain-btn"
            onClick={this.extendSubscriptionNextBtn}
          >
            下一步
          </button>
        </div>
      </div>
    )
  },
  isCencelled(subscription) {
    if (
      subscription &&
      (subscription.status == 'cancelled' || subscription.status == 'rejected')
    ) {
      return true
    }
    return false
  },
  renderHeader() {
    const newStep=this.isContract()?stepC:step;
    const { currentState } = this.state
    return (
      <div className="sub-step-header">
        {newStep.map((json, index) => {
          if (json.isHidden) {
            return ''
          }
          const { id } = json
          const currentStep = step.find(json => json.id == currentState)
          const isActive =
            currentState == id || (currentStep && currentStep.superior == id)
          const stepNum=this.isContract()?4:6;
          return (
            <div className="step-content" key={index}>
              <span>{json.name}</span>
              <div className={`step-num ${isActive ? 'active' : ''}`}>
                {json.step_num}
              </div>
              <div
                className="parting-line"
                style={json.step_num == stepNum ? { display: 'none' } : {}}
              />
            </div>
          )
        })}
      </div>
    )
  },
  isDraft(subscription) {
    if (subscription.status == 'draft') {
      return true
    }
    return false
  },
  previewSubscription(templete_id, export_type = 'docx') {
    const { currentSubscription } = this.state
    // const templete_id = this.state.data.templete_id
    const { user } = this.props
    return new Promise((resolve, reject) => {
      try {
        const win = window
        let templeteId =
          templete_id && templete_id != -1
            ? templete_id
            : currentSubscription.templete_id
        api
          .previewTemplete(templeteId, {
            sales_subscription_id: currentSubscription.id,
            export_type
          })
          .then(json => {
            const responseURL =
              json.response.xhr.responseURL +
              `&user_token=${user.jwt_token}&sales_subscription_id=${currentSubscription.id}&export_type=${export_type}`

            this.setState(
              {
                responseURL
              },
              () => {
                this.refs.subscriptionFile && this.refs.subscriptionFile.click()
              }
            )
            resolve()
          })
      } catch (e) {
        reject()
      }
    })
  },

  renderLockLink() {
    const { currentSubscription, currentState } = this.state
    const { currentOrganization, loc_id, org_id, typeSource } = this.props
    const { templete_id } = this.state.data
    if (currentState != 'lock_link') {
      return ''
    }
    return (
      <SubscriptionLockLinkForm
        templete_id={templete_id}
        loc_id={loc_id}
        org_id={org_id}
        currentSubscription={currentSubscription}
        currentOrganization={currentOrganization}
        nextCallBack={this.lockLinkNextBtn}
        step={currentState}
        typeSource={typeSource}
      />
    )
  },

  renderAudit() {
    const { currentSubscription, currentState } = this.state
    const { currentOrganization, loc_id, org_id, typeSource } = this.props
    const { templete_id } = this.state.data
    if (currentState != 'audit') {
      return ''
    }
    return (
      <SubscriptionApprovalForm
        templete_id={templete_id}
        loc_id={loc_id}
        org_id={org_id}
        currentSubscription={currentSubscription}
        currentOrganization={currentOrganization}
        nextCallBack={this.auditNextBtn}
        step={currentState}
        typeSource={typeSource}
        isContract={this.isContract()}
      />
    )
  },
  detailNextBtn(subscription) {
    const { routerActions, loc_id, org_id, typeSource } = this.props

    let url ='';
    if(this.isContract()){
      url=`/admin/locations/${loc_id}/organizations/${org_id}/subscriptions/new?contract=无销售面积&contract_id=${subscription.id}&status=audit`;
    }else{
      url=typeSource && typeSource == 'crmrecord'
      ? `/admin/crm/opportunities/sub/organizations/${org_id}/subscriptions/new?contract_id=${subscription.id}&status=audit&location_id=${loc_id}&typeSource=crmrecord`
      : `/admin/locations/${loc_id}/organizations/${org_id}/subscriptions/new?contract_id=${subscription.id}&status=audit`

    }
     routerActions.replace(url)
    const data = {
      currentSubscription: subscription,
      currentState: this.isContract()?'audit':'lock_link'
    }
    this.setState(data)
  },
  // 关联门禁权限 callback
  lockLinkNextBtn(subscription) {
    const data = {
      currentSubscription: subscription,
      currentState: 'audit'
    }
    if (subscription.status == 'active') {
      data.currentState = 'finish'
    }
    if (this.isDraft(subscription)) {
      data.currentState = 'sign'
    }
    this.setState(data)
  },
  // 审批callback
  auditNextBtn(subscription) {
    let params = {
      currentSubscription: subscription
    }
    if (this.isDraft(subscription)) {
      params.currentState = 'auditSuccess'
    }
    if (this.isCencelled(subscription)) {
      params.currentState = 'auditFailure'
    }
    if (subscription.status == 'active') {
      params.currentState = 'finish'
    }
    this.setState(params)
  },
  signNextBtn(subscription) {
    this.setState({
      currentSubscription: subscription,
      currentState: 'finish'
    })
  },
  signPreviousBtn(subscription) {
    this.setState({
      currentSubscription: subscription,
      currentState: 'auditFailure'
    })
  },
  renderDetail() {
    const currentState = this.state.currentState
    const { opportunity_id } = this.props

    if (currentState != 'detail') {
      return ''
    }
    const { templete_id, subscription_id } = this.state.data
    const { org_id, loc_id, contract_id, status, typeSource } = this.props
    return (
      <OrgSubscriptionForm
        org_id={org_id}
        opportunity_id={opportunity_id}
        step={currentState}
        loc_id={loc_id}
        status={status}
        nextCallBack={this.detailNextBtn}
        templete_id={templete_id}
        subscription_id={subscription_id || contract_id}
        typeSource={typeSource}
        isContract={this.isContract()}
      />
    )
  },
  toSubscriptionDetail() {
    const { currentSubscription } = this.state
    const { routerActions, org_id, loc_id, typeSource } = this.props

    let url =
      typeSource && typeSource == 'crmrecord'
        ? `/admin/crm/opportunities/sub/organizations/${org_id}/subscriptions/${currentSubscription.id}?location_id=${loc_id}&typeSource=crmrecord`
        : `/admin/locations/${loc_id}/organizations/${org_id}/subscriptions/${currentSubscription.id}`
    routerActions.push(url)
  },
  renderFinish() {
    const currentState = this.state.currentState

    if (currentState != 'finish') {
      return ''
    }

    return (
      <div className="flex-center" style={{ flexDirection: 'column' }}>
        <img
          src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/complete.png"
          alt="complete"
          style={{ width: 80, heightL: 80 }}
        />
        <span
          className="d-block"
          style={{ fontSize: 18, marginBottom: 30, marginTop: 15 }}
        >
          合同完成
        </span>
        <span
          className="bordered-btn"
          onClick={this.toSubscriptionDetail}
          style={{ marginLeft: 0, padding: '7px 25px' }}
        >
          查看合同详情
        </span>
      </div>
    )
  },
  renderSign() {
    const currentState = this.state.currentState

    if (currentState != 'sign') {
      return ''
    }
    const { currentSubscription } = this.state
    const { currentOrganization } = this.props
    const { templete_id } = this.state.data
    return (
      <SubscriptionSignForm
        currentSubscription={currentSubscription}
        currentOrganization={currentOrganization}
        step={currentState}
        templete_id={templete_id}
        nextCallBack={this.signNextBtn}
        previousCallBack={this.signPreviousBtn}
        isContract={this.isContract()}
      />
    )
  },
  renderAuditSuccess() {
    const currentState = this.state.currentState

    if (currentState != 'auditSuccess') {
      return ''
    }
    const { currentSubscription } = this.state
    const { user } = this.props
    return (
      <OrganizationAuditSuccess
        currentSubscription={currentSubscription}
        setNextAndCurrentSubscription={this.setNextAndCurrentSubscription}
        user={user}
      />
    )
  },
  renderAuditFailure() {
    const currentState = this.state.currentState

    if (currentState != 'auditFailure') {
      return ''
    }

    const { currentSubscription } = this.state
    const { user } = this.props
    return (
      <OrganizationAuditFailure
        currentSubscription={currentSubscription}
        setNextAndCurrentSubscription={this.setNextAndCurrentSubscription}
        user={user}
      />
    )
  },
  render() {
    const { errorMessage, isLoading, responseURL } = this.state
    return (
      <div>
        {this.renderHeader()}
        <KBLoadingContainer loading={isLoading}>
          {this.isContract()?'':this.renderChooseType()}
          {this.isContract()?'':this.renderNewTemplate()}
          {this.isContract()?'':this.renderExtendSubscription()}
          {this.renderDetail()}
          {this.renderLockLink()}
          {this.renderAudit()}
          {this.renderAuditSuccess()}
          {this.renderAuditFailure()}
          {this.renderSign()}
          {this.renderFinish()}
        </KBLoadingContainer>
        <a ref="subscriptionFile" href={responseURL} download />
        {errorMessage ? (
          <p className="lr-error" style={{ padding: '0 30px 30px' }}>
            {errorMessage}
          </p>
        ) : (
          ''
        )}
      </div>
    )
  }
})

OrgSubscriptionsGenerate.PropTypes = {}

function mapStateToProps(state, props) {
  const { location } = props
  const status = location.query.status || ''
  const opportunity_id = location.query.opportunity_id
  let org_id = props.org_id
  let loc_id = props.loc_id

  const contract_id = location.query.contract_id
  const currentOrganization = selectors.getOrg(state, org_id) || {}
  const spaceSetting = selectors.getSpaceSetting(state)
  const user = selectors.getCurrentUser(state)
  const subscriptions = selectors.getSubscriptionTempletesOfState(
    state,
    'enabled'
  )
  // const createSubscriptions = subscriptions.filter(json => json.template_type == 'new_contract')
  // const extendSubscriptions = subscriptions.filter(json => json.template_type == 'continue_contract')
  const createSubscriptions = subscriptions
  const extendSubscriptions = subscriptions
  const isNeedTemplate =
    spaceSetting &&
    spaceSetting.settings &&
    spaceSetting.settings.need_subscription_templete &&
    spaceSetting.settings.need_subscription_templete == 'true'
  const currentSubscription = contract_id
    ? selectors.getSubScriptions(state, contract_id)
    : {}
  return {
    opportunity_id,
    currentOrganization,
    currentSubscription,
    user,
    contract_id,
    status,
    createSubscriptions,
    extendSubscriptions,
    subscriptions,
    isNeedTemplate
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getSubscriptionTempletesAction: bindActionCreators(
      subscriptionTempletesAction.get,
      dispatch
    ),
    getSpacesSettingAction: bindActionCreators(
      spacesSettingAction.get,
      dispatch
    ),
    getSubscriptionActions: bindActionCreators(
      subscriptionActions.get,
      dispatch
    ),
    deleteSubscriptionActions: bindActionCreators(
      subscriptionActions.delete,
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionsGenerate)
