import React, { Component, PropTypes } from 'react'
import { KBLogo, KBTipsy } from 'components'
import { Router, Route, Link } from 'react-router'
import moment from 'moment'

var OrgBlock = React.createClass({
  render() {
    let { org, location_id, style, isGlobalOrg } = this.props
    let expiringDate = org.expiring_date && moment(org.expiring_date)
    let now = moment(new Date())
    let expiringDays = expiringDate && expiringDate.diff(now, 'days')
    let isEntered = org && org.is_entered
    let notGraduated = org && !org.is_archived
    let isImportant = org && org.is_important
    let isSuspended = org && org.status === 'suspended'
    return (
      <KBTipsy content={org.full_name}>
        <Link
          to={
            isGlobalOrg
              ? `/admin/organizations/${org.id}`
              : `/admin/locations/${location_id}/organizations/${org.id}`
          }
          key={org.id}
          className="company-message"
          style={style}
        >
          {notGraduated ? (
            <img
              className="tag-img"
              src={`https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/${isEntered ? 'icon_enter@3x.png' : '12b7dffd-ce93-4992-a36b-ee74e22a4859.png'
                }`}
              alt="Enter Status"
            />
          ) : null}
          <div className="company-logo-position f-left">
            <div className="company-logo  f-left">
              <img
                src={
                  org.logo
                    ? org.logo
                    : 'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/company_default.png'
                }
              />
            </div>
            <div className="org_info f-left">
              <div className="org_name">
                {org.name}
                {isImportant ? (
                  <KBTipsy content="重要客户">
                    <i
                      className="iconfont icon-important_crm"
                      style={{
                        color: '#FF3F3F',
                        fontSize: '20px',
                        position: 'relative',
                        top: '3px',
                        marginLeft: 10
                      }}
                    />
                  </KBTipsy>
                ) : null}
                {isSuspended ? (
                  <KBTipsy content="已停用">
                    <i
                      className="iconfont icon-crm_status_4"
                      style={{
                        color: '#7F8FA4',
                        fontSize: '18px',
                        position: 'relative',
                        top: '2px',
                        marginLeft: 10
                      }}
                    />
                  </KBTipsy>
                ) : null}
              </div>

              <div className="industry_name">
                {(org.industry && org.industry.name) || '暂无行业信息'}
              </div>
              <div className="desk_info">
                <span style={{ marginRight: 5 }}>
                  {org.desks_count ? `${org.desks_count}工位` : '暂无工位'}
                </span>
                <span style={{ color: '#DD5A55' }}>
                  {expiringDays && expiringDays < 61
                    ? expiringDays < 30
                      ? expiringDays < 7
                        ? '(7天内到期)'
                        : '(1个月内到期)'
                      : '(2个月内到期)'
                    : ''}
                </span>
              </div>
              {/* {org.enter_type == 'shortterm' ?
                <div className="desk_info" style={{ marginTop: 5 }}>{org.total_units ? `${org.user_units}/${org.total_units}员工` : '暂无员工'}</div>
                : null} */}
            </div>
          </div>
        </Link>
      </KBTipsy>
    )
  }
})

export default OrgBlock
