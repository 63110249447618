import React from 'react'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import * as valid from 'utils/validate'
import { reduxForm } from 'redux-form'
import { successState, apiCallFailure } from 'app/actions'
import {
  KBForm,
  KBRadio,
  KBLoadingContainer,
  KBInput,
  KBDateInput,
  KBSelect,
  KBButton,
  KBImageCropperInput,
  KBCheckBox,
  KBUserInput,
  KBExcelUpload,
  KBTextarea
} from 'components'
// 富文本编辑器
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { notification } from 'antd'
import { api } from 'app/services'
import { DAY_TIME_HM } from 'app/constants'
import * as selectors from 'app/selectors'
import moment from 'moment'
import {
  formatOnlyMinuteSecondEN,
  formatMinuteSecondEN,
} from 'utils/kbUtil'
import './cutUploadImage.css'
import { canAdminSpace } from 'app/reducers/role'
import SelectInvoiceAccount from './components/SelectInvoiceAccount';

const methodsArr = [
  // { title: '复制兑换码并跳转', value: 'promo_code' },
  { title: '跳转H5页面', value: 'by_h5' },
  { title: '跳转微信小程序页面', value: 'by_mini_app' }
]

const typeArr = [
  { title: '不限条件', value: 'no_limitation' },
  { title: '订单总额达标', value: 'order_amount' }
]

let _REPLACEMENT_DETAILS = null

var ReplacementCreateForm = React.createClass({
  displayName: 'ReplacementCreateForm',

  getInitialState() {
    const { isAllDay } = this.props
    return {
      loading: true,
      meetingRooms: [],
      searchFieldValue: '',
      preview: false,
      isAllDay,
    }
  },

  async componentDidMount() {
    const { act_id, apiCallFailureAction, location } = this.props
    const {
      fields: { location_id },
      loc_id
    } = this.props

    if (act_id) {
      try {
        const json = await api.getReplacementInfo(act_id)
        const { end_at, start_at } = json.response.body || {}
        let isAllDay = false
        if (
          moment.unix(start_at).format('HH:mm:ss') === '00:00:00' &&
          moment.unix(end_at).format('HH:mm:ss') === '23:59:59'
        ) {
          isAllDay = true
        }
        _REPLACEMENT_DETAILS = json.json
        this.setState({
          loading: false,
          isAllDay
        })
      } catch (err) {
        apiCallFailureAction({
          status: 'error',
          message: err.message
        })
      }
    }

    if (loc_id) {
      location_id.onChange(loc_id)
      this.selectMeetingRoom(loc_id)
    }

    if(location.query && location.query.isClone === '1') {
      const activity = JSON.parse(sessionStorage.clonedReplacementData)
      const { end_at, start_at } = activity
      let isAllDay = false
      if (
        moment.unix(start_at).format('HH:mm:ss') === '00:00:00' &&
        moment.unix(end_at).format('HH:mm:ss') === '23:59:59'
      ) {
        isAllDay = true
      }
      this.setState({
        isAllDay
      })
    }
  },

  changeEndHMCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setHM(end_at, data)
  },

  changeStartHMCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setHM(start_at, data)
  },

  createActivity(model) {
    const { act_id, loc_id, routerActions, apiCallFailureAction } = this.props
    const { isAllDay } = this.state

    delete model.areas

    model.area_ids = model.area_ids.split(',')
    model.discount_amount = 0 - model.discount_amount
    // return
    if (isAllDay) {
      model.start_at = `${moment(model.start_at).format('YYYY-MM-DD')} 00:00:00`
      model.end_at = `${moment(model.end_at).format('YYYY-MM-DD')} 23:59:59`
    } else {
      // 时间的结果是数组[小时，整点是1半点是2]
      const start_t = model.startHM ? model.startHM.split(',') : [0, 1]
      model.start_at = `${moment(model.start_at).format('YYYY-MM-DD')} ${
        start_t[0] < 10 ? `0${start_t[0]}` : start_t[0]
      }:${start_t[1] == 1 ? '00' : '30'}`

      const end_t = model.endHM ? model.endHM.split(',') : [0, 1]
      model.end_at = `${moment(model.end_at).format('YYYY-MM-DD')} ${
        end_t[0] < 10 ? `0${end_t[0]}` : end_t[0]
      }:${end_t[1] == 1 ? '00' : '30'}`
    }

    if (model.limitation_type !== 'order_amount') {
      delete model.limitation_amount
    }

    if (model.has_promo === 'no') {
      delete model.promo_codes
    }

    if (model.redirect_method !== 'by_h5') {
      delete model.redirect_url
    }

    if (model.redirect_method !== 'by_mini_app') {
      delete model.appid
      delete model.mini_id
      delete model.mini_path
    }

    if (model.has_promo === 'yes') {
      model.access_method = 'promo_code'
    } else {
      model.access_method = model.redirect_method.replace('by_', '')
    }

    delete model.has_promo

    delete model.startHM
    delete model.endHM

    if(model.status === 'active') {
      model.on_sales = true
    }

    // 编辑
    if (act_id) {
      return api.updateReplacement(act_id, model).then(
        json => {
          successState({ message: '操作成功' })
          routerActions.push(
            `/admin/locations/${loc_id}/replacement/${act_id}/info`
          )
        },
        error => {
          apiCallFailureAction({
            status: 'error',
            message: error.message
          })
        }
      )
    }

    // 新建
    return api.createReplacement(model).then(
      json => {
        let new_act = json.response && json.response.body
        let new_act_id = new_act && new_act.id
        routerActions.push(
          `/admin/locations/${loc_id}/replacement/${new_act_id}/info`
        )
        successState({ message: '操作成功' })
      },
      error => {
        apiCallFailureAction({
          status: 'error',
          message: error.message
        })
      }
    )
  },

  selectMeetingRoom(id) {
    if (id === '' || id === undefined || id === '-1') {
      return
    }

    const { apiCallFailureAction } = this.props

    api.getReplacementArea({ location_id: id, per_page: 99999 }).then(
      json => {
        this.setState({
          meetingRooms: json.json
        })
      },
      error => {
        apiCallFailureAction({
          status: 'error',
          message: error.message
        })
      }
    )
  },

  checkDay(check) {
    const {
      fields: { start_at, end_at, startHM, endHM }
    } = this.props
    if (check) {
      this.setHM(start_at, '0,1')
      this.setHM(end_at, '23,1')
      startHM.onChange('0,1')
      endHM.onChange('23,1')
    }
    this.setState({ isAllDay: check })
  },

  downloadFile(url, fileName) {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = function(e) {
      const url = window.URL.createObjectURL(xhr.response)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()
    }
    xhr.send()
  },

  componentWillReceiveProps(nextProps) {
    const {fields: { quantity, has_promo, promo_codes}} = nextProps
    if(has_promo.value === 'yes' && this.props.fields && this.props.fields.has_promo && this.props.fields.has_promo.value === 'no' && promo_codes.value && promo_codes.value.length) {
      quantity.onChange(promo_codes.value.length)
    }
  },

  // 基本信息
  basicMessageJsx() {
    const {
      fields: {
        name,
        description,
        start_at,
        end_at,
        startHM,
        endHM,
        appid,
        mini_id,
        mini_path,
        reserve_role,
        area_ids,
        limitation_amount,
        limitation_type,
        redirect_method,
        redirect_url,
        original_price,
        price,
        quantity,
        use_rule,
        has_promo,
        promo_codes,
        merchant_id,
        discount_amount
      },
      areas,
      DEAL_DAY_TIME_HM
    } = this.props
    let { isAllDay } = this.state

    const identityArr = [
      { title: '所有人', value: 'all_user' },
      { title: '办公楼会员专享', value: 'tenant' },
      { title: '奕桥会员专享', value: 'bridge_tenant' }
    ]

    return (
      <fieldset className="fieldset">
        <legend>基本信息</legend>
        <div className="kb-row">
          <KBInput
            title="名称"
            isRequired
            label="true"
            placeholder="请填写权益名称(不多于20个字)"
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={name}
            maxlength="20"
          />
        </div>
        <div className="kb-row">
          <KBInput
            title="介绍"
            label="true"
            isRequired
            placeholder="请填写权益介绍(不多于50个字)"
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={description}
            maxlength="20"
          />
        </div>
        <div className="kb-row kb-form-c48">
          <div className="kb-form-66 clear-fix f-left c_cursor">
            <KBDateInput
              className="kb-form-group kb-form-c48"
              title="开始日期"
              isRequired
              field={start_at}
              // disabledDays={DateUtils.isPastDay}
            />
            <KBDateInput
              className="kb-form-group kb-form-c48"
              title="结束日期"
              isRequiredR={true}
              titleClass="label-rt"
              field={end_at}
              // disabledDays={DateUtils.isPastDay}
            />
          </div>
        </div>
        <div className="kb-row kb-form-84">
          {!isAllDay && (
            <div className="kb-form-66 clear-fix f-left">
              <KBSelect
                className="kb-form-group kb-form-c48"
                title="开始时间"
                isRequired
                onChange={this.changeStartHMCallback}
                arrayData={DEAL_DAY_TIME_HM}
                field={startHM}
              />
              <KBSelect
                className="kb-form-group kb-form-c48"
                title="结束时间"
                isRequiredR={true}
                onChange={this.changeEndHMCallback}
                titleClass="label-rt"
                arrayData={DEAL_DAY_TIME_HM}
                field={endHM}
              />
            </div>
          )}
          <div className="kb-form-group kb-div-100">
            <label>活动时间</label>
            <div style={{ paddingTop: 14 }}>
              <KBCheckBox
                checked={isAllDay}
                callback={this.checkDay}
                style={{ display: 'inline-block', marginRight: 15 }}
              />
              <span>全天</span>
            </div>
          </div>
        </div>

        <div className="kb-row kb-form-210">
          <KBRadio
            className="kb-form-group kb-div-c48"
            groupStyle={{ width: '100%' }}
            divClassName="kb-form-group kb-div-33"
            rightLabel="label-rt"
            title="领取身份限定"
            arrayType={identityArr}
            field={reserve_role}
            name="reserve_role"
            isRequired
          />
        </div>

        <div className="kb-row">
          {this.state.meetingRooms.length > 0 ? (
            <KBUserInput
              key="hasdata"
              isRequired
              title="领取范围"
              selectedUsers={areas}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={area_ids}
              placeholder="选择会议室"
              multi={true}
              users={this.state.meetingRooms}
            />
          ) : (
            <KBUserInput
              key="nodata"
              isRequired
              title="领取范围"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={area_ids}
              placeholder="选择会议室"
              multi={true}
              users={this.state.meetingRooms}
            />
          )}
        </div>

        <SelectInvoiceAccount field={merchant_id} locationId={this.props.loc_id} />

        <div className="kb-row kb-form-210">
          <KBRadio
            className="kb-form-group kb-div-c48"
            groupStyle={{ width: '100%' }}
            divClassName="kb-form-group kb-div-33"
            rightLabel="label-rt"
            title="领取条件"
            arrayType={typeArr}
            field={limitation_type}
            name="limitation_type"
            isRequired
          />
        </div>

        {limitation_type.value === 'order_amount' && (
          <div className="kb-row">
            <KBInput
              isRequired
              className="kb-form-group kb-form-alone"
              field={limitation_amount}
              title="达标金额"
              label
              placeholder="￥"
            />
          </div>
        )}

        <legend style={{ margin: '40px 0 20px' }}>领取形式</legend>
        <div className="kb-row kb-form-210">
          <KBRadio
            className="kb-form-group kb-div-c48"
            groupStyle={{ width: '100%' }}
            divClassName="kb-form-group kb-div-33"
            rightLabel="label-rt"
            title="是否存在兑换码"
            arrayType={[
              { title: '是', value: 'yes' },
              { title: '否', value: 'no' }
            ]}
            field={has_promo}
            name="has_promo"
            isRequired
          />
        </div>

        {has_promo.value === 'yes' && this.uploadExcellJsx()}

        <div className="kb-row kb-form-210">
          <KBRadio
            className="kb-form-group kb-div-c48"
            groupStyle={{ width: '100%' }}
            divClassName="kb-form-group kb-div-33"
            rightLabel="label-rt"
            title="跳转方式"
            arrayType={methodsArr}
            field={redirect_method}
            name="redirect_method"
            isRequired
          />
        </div>

        {/* {access_method.value === 'promo_code' && this.uploadExcellJsx()} */}

        {redirect_method.value === 'by_h5' && (
          <div className="kb-row">
            <KBInput
              isRequired
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={redirect_url}
              title="H5链接"
              label
            />
            <div
              style={{
                fontSize: '12px'
              }}
            >
              下载
              <span
                style={{
                  color: '#1890ff',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                onClick={() =>
                  this.downloadFile(
                    'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/uploads/static/a2bMyBmNRj.txt',
                    '校验文件'
                  )
                }
              >
                校验文件
              </span>
              <span
                style={{
                  color: '#F62D51',
                  marginLeft: '20px'
                }}
              >
                注意：请将此校验文件配置到合作方域名根目录下后，联系小程序管理员在小程序后台添加域名白名单
              </span>
            </div>
          </div>
        )}

        {/* 小程序相关配置 */}
        {redirect_method.value === 'by_mini_app' && (
          <div className="kb-row">
            <KBInput
              isRequired
              title="微信注册appid"
              label="true"
              placeholder=""
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={appid}
            />
            <KBInput
              isRequired
              title="小程序原始id"
              label="true"
              placeholder=""
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={mini_id}
            />
            <KBInput
              isRequired
              title="小程序路径"
              label="true"
              placeholder=""
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={mini_path}
            />
            <div
              style={{
                fontSize: '12px'
              }}
            >
              下载
              <span
                download="校验文件"
                style={{
                  color: '#1890ff',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                onClick={() =>
                  this.downloadFile(
                    'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/uploads/static/a2bMyBmNRj.txt',
                    '校验文件'
                  )
                }
              >
                校验文件
              </span>
              <span
                style={{
                  color: '#F62D51',
                  marginLeft: '20px'
                }}
              >
                注意：请将此校验文件配置到合作方域名根目录下后，联系小程序管理员在小程序后台添加域名白名单
              </span>
            </div>
          </div>
        )}

        <legend style={{ margin: '40px 0 20px' }}>价格设置</legend>
        <div className="kb-row">
          <KBInput
            isRequired
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={original_price}
            title="原价"
            label
            placeholder="￥"
          />
        </div>
        <div className="kb-row">
          <KBInput
            isRequired
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={price}
            title="实际价格"
            label
            placeholder="￥"
          />
        </div>
        <div className="kb-row">
          <KBInput
            isRequired
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={discount_amount}
            title="补贴价格"
            label
            placeholder="￥"
          />
        </div>
        <div className="kb-row">
          <KBInput
            isRequired
            className="kb-form-group kb-form-c18 kb-form-alone"
            field={quantity}
            title="数量"
            label
            disabled={promo_codes.value && promo_codes.value.length && has_promo.value === 'yes'}
          />
        </div>
        <div className="kb-row">
          <KBTextarea
            isRequired
            label
            title="规则"
            className="kb-form-group kb-form-alone kb-div-c18"
            field={use_rule}
          />
        </div>

        <div className="kb-row">
          <div className="kb-form-group">
            <label>
              <span className="must-fillR">*</span>权益封面
            </label>
            <div>{this.pcTitleCoverJsx()}</div>
          </div>
        </div>
      </fieldset>
    )
  },

  changeDate(values) {
    const {
      fields: { start_at, end_at }
    } = this.props
    const { from, to } = values
    start_at.onChange(moment(from).format('YYYY-MM-DD'))
    end_at.onChange(moment(to).format('YYYY-MM-DD'))
  },
  setHM(field, value) {
    let t = value.split(',')
    field.onChange(
      moment(
        moment(field.value)
          .clone()
          .set({ hour: t[0], minute: t[1] == '1' ? 0 : 30, second: 0 })
          .toDate()
      ).format('YYYY-MM-DD HH:mm:ss')
    )
  },

  changeTicketNumber(value, field) {
    field.onChange(value)
  },

  UploadFailureCallBack(msg) {
    if (msg) {
      notification.error({ message: '上传失败', description: msg, top: 100 })
    }
  },

  // pc端活动头部
  pcTitleCoverJsx() {
    const {
      fields: { cover }
    } = this.props
    return (
      <div className="kb-row" style={{ textAlign: 'left' }}>
        <KBImageCropperInput
          title={false}
          // 长宽比例
          aspect={200 / 200}
          className="kb-form-group kb-form-c18 kb-form-alone"
          field={cover}
          type="activities"
          // 文件尺寸限制
          imageSize={1024 * 1}
          // 预览时的图片大小
          width={200}
          height={200}
          failureCallBack={this.UploadFailureCallBack}
          accept=".png,.jpg"
          isPublic
        >
          <div className="upload-portrait">
            {/* 已上传时的内容展示 */}
            {cover && cover.value ? (
              <img
                // 通过修改url来截取图片
                src={cover.value}
                style={{
                  width: '200px',
                  height: '200px',
                  display: 'inline-block',
                  marginLeft: 0,
                  borderRadius: 0
                }}
                alt=""
              />
            ) : (
              ''
            )}
            <div className="d-iblock" style={{ verticalAlign: 'middle' }}>
              <button className="bordered-btn m-none" type="button">
                {cover.dirty ? '图片已上传' : '上传图片'}
              </button>
              <p
                className="color-nine"
                style={{ paddingTop: 20, fontSize: 12 }}
              >
                请上传1:1的图片，图片格式为png或jpg，文件大小不大于1M
              </p>
            </div>
          </div>
        </KBImageCropperInput>
        <div style={{ marginTop: 10 }}>
          {cover.touched && cover.error && (
            <p className="lr-error">{cover.error}</p>
          )}
        </div>
      </div>
    )
  },

  updateStatus(type) {
    const {
      fields: { status }
    } = this.props
    status.onChange(type)
  },

  uploadExcellCallback(res) {
    const {
      apiCallFailureAction,
      fields: { promo_codes, quantity }
    } = this.props
    if (res && res.status === 'failed') {
      apiCallFailureAction({
        status: 'error',
        message: res.message
      })
    } else {
      // 成功
      const arr = res || []
      promo_codes.onChange(arr)
      quantity.onChange(arr.length + '')
    }
  },

  uploadExcellJsx() {
    const {
      location,
      fields: { promo_codes }
    } = this.props
    return (
      <div className="excel-upload-box">
        <KBExcelUpload
          url={api.promocodeAsyncImportUrl()}
          params={{ location_id: location.id }}
          callback={this.uploadExcellCallback}
        />
        <div className="upload-text">
          <span style={{ marginRight: '4px' }}>下载</span>
          <a
            href={`https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/uploads/static/efc8f726-e0df-445b-a095-4d776a83f658.xlsx`}
            download="兑换码模板"
          >
            兑换码模板
          </a>
        </div>
        <div className="m-top-sm">
          <span>提示：下载标准文档,填写完毕后，点击中间位置进行文档上传</span>
        </div>
        {promo_codes.touched && promo_codes.error && (
          <p className="lr-error">{promo_codes.error}</p>
        )}
      </div>
    )
  },

  render() {
    const {
      fields: { status },
      handleSubmit,
      error,
      submitting,
      act_id,
      canEdit
    } = this.props
    const { loading } = this.state

    return (
      <div className="kb-content">
        <div
          className="kb-content-container clear-fix"
          style={{ minHeight: '100%' }}
        >
          <header className="nav-section-header clear-fix border-bottom">
            <div className="nav-section-header-title f-left">
              <span>{act_id ? '编辑权益' : '发布权益'}</span>
            </div>
          </header>
          <KBLoadingContainer loading={act_id && loading}>
            <div className="kb-form-container">
              <KBForm
                type="reduxForm"
                onSubmit={handleSubmit(this.createActivity)}
                fields={this.props.fields}
              >
                {this.basicMessageJsx()}

                {canEdit && (
                  <div className="kb-row m-top-lg">
                    <div className="kb-form-fr">
                      {(!act_id || (act_id && status && status.value !== 'active')) && (
                        <KBButton
                          className="c-btn-warning"
                          submitting={submitting}
                          type="submit"
                          onClick={() => {
                            this.updateStatus('draft')
                            this.setState({ preview: true })
                          }}
                          style={{ marginRight: 15 }}
                        >
                          {act_id ? '修改草稿' : '保存为草稿'}
                        </KBButton>
                      )}
                      <KBButton
                        className="certain-btn"
                        submitting={submitting && status.value === 'active'}
                        type="submit"
                        onClick={() => {
                          this.updateStatus('active')
                        }}
                        style={{ marginRight: 0 }}
                      >
                        立即发布
                      </KBButton>
                    </div>
                  </div>
                )}
              </KBForm>
            </div>
          </KBLoadingContainer>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = (params && params.id) || ''
  let locations = selectors.getLocations(state)
  let locationGroups = selectors.getLocationGroups(state)
  let act_id = location.query && location.query.act_id
  let activity = {}

  if (act_id && _REPLACEMENT_DETAILS) {
    // 获取到的详情数据
    activity = _REPLACEMENT_DETAILS
  }

  let initValues = act_id ? activity : {}

  // 从其他权益克隆
  if (
    location.query &&
    location.query.isClone === '1' &&
    sessionStorage.clonedReplacementData
  ) {
    initValues = JSON.parse(sessionStorage.clonedReplacementData)
    activity = JSON.parse(sessionStorage.clonedReplacementData)
    // sessionStorage.removeItem('clonedReplacementData')
  }

  let isAllDay = false
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(0, 23)
  let currentStartAt = activity && activity.start_at
  let currentEndAt = activity && activity.end_at
  let s = currentStartAt && parseHM(formatOnlyMinuteSecondEN(currentStartAt))
  let e = currentEndAt && parseHM(formatOnlyMinuteSecondEN(currentEndAt))
  function parseHM(date) {
    let hours = date.split(':')[0]
    let minute = date.split(':')[1]
    let t = '1'
    let h = hours
    if (h < 10) {
      h = h.substring(1)
    }
    if (minute == 30) {
      t = '2'
    } else {
      t = '1'
    }

    return h + ',' + t
  }
  const isAdmin = canAdminSpace(state.user)

  return {
    loc_id,
    locations,
    locationGroups,
    DEAL_DAY_TIME_HM,
    act_id,
    isAllDay,
    isAdmin,
    areas: initValues.areas,
    canEdit: activity && !activity.has_invoice,
    initialValues:
      Object.keys(initValues).length > 0
        ? Object.assign({}, initValues, {
            start_at: formatMinuteSecondEN(activity.start_at),
            end_at: formatMinuteSecondEN(activity.end_at),
            location_id: loc_id
              ? loc_id
              : (activity.location && activity.location.id) || '',
            startHM: s || '0,0',
            endHM: e || '0,0',
            area_ids: (activity.areas || []).map(i => i.id).join(),
            has_promo: activity.access_method === 'promo_code' ? 'yes' : 'no',
            merchant_id: (activity.merchant && activity.merchant.id) || '',
            discount_amount: 0 - activity.discount_amount + ''
          })
        : {
            reserve_role: 'all_user',
            limitation_type: 'no_limitation',
            redirect_method: 'by_h5',
            has_promo: 'yes',
            merchant_id: ''
          }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  console.log('====================================')
  console.log(values)
  console.log('====================================')
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入权益名称'
  }
  if (valid.required(values.description)) {
    errors.description = '请输入权益介绍'
  }

  // if (valid.required(values.end_at)) {
  //   errors.end_at = '请输入结束日期'
  // }

  if (new Date(values.start_at) > new Date(values.end_at)) {
    errors.start_at = '开始日期不能晚于结束日期'
  }

  if (valid.required(values.area_ids)) {
    errors.area_ids = '请选择会议室'
  }

  if(valid.required(values.merchant_id)) {
    errors.merchant_id = '请选择汇付账号'
  }

  if (
    values.limitation_type === 'order_amount' &&
    valid.required(values.limitation_amount)
  ) {
    errors.limitation_amount = '请输入达标金额'
  }

  // 上传兑换码
  if (values.has_promo === 'yes' && valid.required(values.promo_codes)) {
    errors.promo_codes = '请输入上传兑换码'
  }

  // 跳转H5页面
  if (
    values.redirect_method === 'by_h5' &&
    valid.required(values.redirect_url)
  ) {
    errors.redirect_url = '请输入H5链接'
  }

  // 跳转小程序
  if (values.redirect_method === 'by_mini_app') {
    if (valid.required(values.appid)) {
      errors.appid = '请填写微信注册appid'
    }
    if (valid.required(values.mini_id)) {
      errors.mini_id = '请填写小程序原始id'
    }
    if (valid.required(values.mini_path)) {
      errors.mini_path = '请填写小程序路径'
    }
  }

  // 实际价格需要小于原价
  const { original_price, price, quantity } = values
  // 原价大于0
  if (!original_price || original_price <= 0) {
    errors.original_price = '原价需要为正数'
  }
  // 实际价格大于等于0
  if (valid.required(values.price)) {
    errors.price = '请输入实际价格'
  } else {
    if (price < 0) {
      errors.price = '实际价格不能小于0'
    }
    if (Number(price) >= Number(original_price)) {
      errors.price = '实际价格必须小于原价'
    }
  }

  if(valid.required(values.discount_amount)) {
    errors.discount_amount = '请输入补贴价格'
  } else {
    if(valid.isNumber(values.discount_amount) || values.discount_amount < 0) {
      errors.discount_amount = '补贴价格格式错误'
    }
  }

  if (!quantity || quantity % 1 !== 0) {
    errors.quantity = '数量需要为正整数'
  }

  if (valid.required(values.use_rule)) {
    errors.use_rule = '请输入规则'
  }

  if (valid.required(values.cover)) {
    errors.cover = '请选择封面图片'
  }

  return errors
}

const formConfig = {
  form: 'ReplacementCreateForm',
  fields: [
    'status',
    'location_id',
    'name',
    'description',
    'start_at',
    'end_at',
    'startHM',
    'endHM',
    'can_reserve',
    'appid',
    'mini_id',
    'mini_path',
    'reserve_role',
    'area_ids',
    'areas',
    'limitation_amount',
    'limitation_type',
    'access_method',
    'redirect_method',
    'cover',
    'redirect_url',
    'original_price',
    'price',
    'quantity',
    'promo_codes',
    'use_rule',
    'has_promo',
    'merchant_id',
    'discount_amount'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ReplacementCreateForm)
