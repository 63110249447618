import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { locksActions, apiCallFailure, successState } from 'app/actions'
import * as selectors from 'app/selectors'
import {
  KBPopover,
  KBPagination,
  KBLoadingContainer,
  KBTableWithoutData,
  KBTipsy
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import * as util from 'utils/kbUtil'
import { THEME_COLORS, NETWORK_TYPE } from 'app/constants'
import { getInitialDate } from 'utils/kbData'
import { canAccessLocation } from 'app/reducers/role'
import LockForm from './LockForm'
const pinyin = require('tiny-pinyin')

const per_page = 100

/**
 * 门禁列表
 * @type {[type]}
 */
var LockIndex = React.createClass({
  displayName: 'LockIndex',

  getInitialState() {
    return {
      locks: [],
      pagination: null,
      loading: false
    }
  },

  componentDidMount() {
    const { page } = this.props
    this._loadDate(page)
  },

  componentWillReceiveProps(nextProps) {
    const { page, locks } = nextProps
    if (page != this.props.page) {
      this._loadDate(page)
    }
  },

  _loadDate(page) {
    const { loc_id, getLocksAction } = this.props
    this.setState({ loading: true })
    // 获取门禁列表
    api
      .getLocks({ location_id: loc_id, per_page: 30, page })
      .then(
        json => {
          getLocksAction.success(json)
          let locks = []
          locks = util.getEntitiesJsonArray(json, 'locks')
          let pagination = util.paginate(json.response)

          this.setState({
            locks,
            pagination
          })
        },
        errors => {
          getLocksAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  createLockCallBack(lock) {
    const { locks } = this.state
    this.setState({
      locks: util.addDataToArrayOfIndex(locks, lock)
    })
  },

  createLock() {
    const { loc_id } = this.props
    KBPopover.show(
      <LockForm
        loc_id={loc_id}
        callback={this.createLockCallBack}
        initialValues={{
          lock_type: 'bluetooth',
          is_enabled: true,
          is_gate: true,
          is_auto_enabled: false
        }}
      />,
      {
        closeOnOutsideClick: false
      }
    )
  },

  updateLockCallBack(index, lock) {
    const { locks } = this.state
    this.setState({
      locks: util.updateDataToArrayOfIndex(locks, lock, index)
    })
  },

  updatelock(lock, index) {
    const { loc_id } = this.props
    KBPopover.show(
      <LockForm
        callback={this.updateLockCallBack.bind(null, index)}
        loc_id={loc_id}
        lock={lock}
        state="update"
        initialValues={{
          ...lock,
          area_id: lock.area ? lock.area.id : -1
        }}
      />,
      {
        closeOnOutsideClick: false
      }
    )
  },

  deletelock(lock, index) {
    const { deleteLocksAction } = this.props
    const { locks } = this.state
    let id = lock.id
    KBPopoverConfirm({
      name: '删除门禁',
      context: '确定要删除该门禁?',
      callback: () => {
        return api.deleteLock(id).then(
          json => {
            this.setState({
              locks: util.deleteDataToArrayOfIndex(locks, index)
            })
            deleteLocksAction.success(json, { id, key: 'locks' })
            KBPopover.close()
          },
          errors => {
            deleteLocksAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  toLockInfo(id) {
    const { routerActions, loc_id } = this.props
    routerActions.push(
      `/admin/locations/${loc_id}/locks/lock_info/lock_list/${id}`
    )
  },

  unlock(lock) {
    const { loc_id, apiCallFailureActions, successActions } = this.props
    const { id } = lock

    KBPopoverConfirm({
      name: '联网开门',
      context: '确定要开启该门禁?',
      callback: () => {
        return api
          .createUnlockNetworking(loc_id, { lock_id: id, source: 'web_admin' })
          .then(
            json => {
              KBPopover.close()
              successActions({ message: '开门成功' })
            },
            errors => {
              KBPopover.close()
              apiCallFailureActions({
                status: 'error',
                message: errors._error.message
              })
            }
          )
      }
    })
  },

  render() {
    const { loc_id, page, isManager, organizations } = this.props
    let { pagination, locks, loading } = this.state
    locks &&
      locks.map(lock => {
        lock.pinyin = pinyin.convertToPinyin(lock.name).split('')[0]
      })
    let sortedData = locks.sort((a, b) => {
      return a.pinyin.localeCompare(b.pinyin)
    })
    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>门禁列表</span>
          </h2>
        </header>
        <div className="nav-section-content-container">
          <KBLoadingContainer loading={loading}>
            <table className="content-table edit-table">
              <thead>
                <tr>
                  <th>门禁设备编号</th>
                  <th>名称</th>
                  <th>联网类型</th>
                  <th>所在区域</th>
                  <th />
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={locks.length > 0}
                tableHeadNum="4"
                tipMessage="暂无门禁设备"
              >
                {sortedData &&
                  sortedData.map((lock, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={this.toLockInfo.bind(null, lock.id)}
                      >
                        <td>{lock.device_id}</td>
                        <td>{lock.name}</td>
                        <td>
                          {lock.networking
                            ? NETWORK_TYPE[lock.network_type]
                            : '不联网'}
                        </td>
                        <td>
                          {lock.is_gate
                            ? '大门'
                            : lock.area
                            ? lock.area.name
                            : '无'}
                        </td>
                        {isManager ? (
                          <td className="t-right">
                            {lock.networking ? (
                              <KBTipsy content="开门">
                                <i
                                  className="iconfont icon-open a-hover"
                                  onClick={e => {
                                    e.stopPropagation()
                                    this.unlock(lock, index)
                                  }}
                                />
                              </KBTipsy>
                            ) : (
                              ''
                            )}
                            <KBTipsy content="修改">
                              <i
                                className="iconfont icon-edit m-left-sm"
                                style={{ opacity: 1 }}
                                onClick={e => {
                                  e.stopPropagation()
                                  this.updatelock(lock, index)
                                }}
                              />
                            </KBTipsy>
                          </td>
                        ) : (
                          <td />
                        )}
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
          </KBLoadingContainer>
          <KBPagination
            pagination={pagination}
            template={`/admin/locations/${loc_id}/locks/lock_info/lock_list?page=#PAGE#`}
          />
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { user } = state
  let loc_id = params.id
  let organizations = Object.assign(
    [],
    selectors.getOrgsOfLocation(state, loc_id)
  )
  let page = parseInt(location.query && location.query.page, 10)
  page = isNaN(page) ? 1 : page

  const locks = selectors.getLocks(state)
  let isManager = canAccessLocation(user, loc_id)
  return {
    loc_id,
    page,
    locks,
    isManager,
    organizations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLocksAction: bindActionCreators(locksActions.all, dispatch),
    deleteLocksAction: bindActionCreators(locksActions.delete, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    successActions: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockIndex)
