import React from 'react'
import { Link } from 'react-router'
import * as selectors from 'app/selectors'
import { connect } from 'react-redux'

var ParkingTimeLine = React.createClass({
  displayName: 'ParkingTimeLine',

  render() {
    return (
      <div className="kb-content" style={{ paddingTop: 0 }}>
        <div className="kb-content-container clear-fix">
          <header className="nav-section-header clear-fix border-bottom">
            <div className="nav-section-header-title f-left">
              <span>车位时间轴</span>
            </div>
          </header>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props

  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingTimeLine)
