import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { KBPopover, KBDropdown } from 'components'
import * as selectors from 'app/selectors'
import { connect } from 'react-redux'
import * as constants from 'app/constants'

var MembershipsCardContent = React.createClass({
  displayName: 'MembershipsCardContent',
  getInitialState() {
    return {}
  },

  toSettingMemberCard(membership) {
    const { id, parent_id } = membership
    this.props.routerActions.push(
      `/admin/memberships/memberManage/cards/setting?id=${
        membership.id
      }&status=update&${parent_id ? 'parent_id=' + parent_id : 'parent_id='}`
    )
  },

  render() {
    const {
      membership,
      deskCountTotal,
      bookingCountTotal,
      resourceCountTotal,
      deleteMembership,
      updateMembershipVisbility,
      activateMembership,
      updateMembershipPosition,
      currencyName,
      hideMoveBottom,
      hideMoveTop
    } = this.props
    const isShow = membership.is_show
    const operationBtnClass = `a-hover m-left-sm ${isShow ? '' : 'disable'}`
    const isFormal = membership.status == 'formal'
    const isDraft = membership.status == 'draft'

    return (
      <div className="member-card">
        <div className="title">
          <div className="flex-vertical-center">
            <span className={`grade ${isShow ? '' : 'disable'}`}>
              {membership.title}
            </span>
          </div>
          <div className="flex-vertical-center">
            {!hideMoveTop && isFormal ? (
              <a
                className={operationBtnClass}
                style={{ color: '#666' }}
                onClick={() => {
                  updateMembershipPosition(membership, 'up')
                }}
              >
                上移
              </a>
            ) : null}
            {!hideMoveBottom && isFormal ? (
              <a
                className={operationBtnClass}
                style={{ color: '#666' }}
                onClick={() => {
                  updateMembershipPosition(membership, 'down')
                }}
              >
                下移
              </a>
            ) : null}
            <a
              className={operationBtnClass}
              style={{ color: '#666' }}
              onClick={this.toSettingMemberCard.bind(null, membership)}
            >
              编辑
            </a>
            <a
              className={operationBtnClass}
              style={{ color: '#666' }}
              onClick={() => {
                deleteMembership(membership.id)
              }}
            >
              删除
            </a>
            {isFormal ? (
              <a
                className="a-hover m-left-sm"
                style={{ color: '#666' }}
                onClick={() => {
                  updateMembershipVisbility(membership.id, !isShow)
                }}
              >
                {isShow ? '停止' : '恢复'}
              </a>
            ) : null}
            {isDraft ? (
              <a
                className="a-hover m-left-sm"
                style={{ color: '#666' }}
                onClick={() => {
                  activateMembership(membership.id)
                }}
              >
                激活
              </a>
            ) : null}
          </div>
        </div>
        <div className={`member-card_content ${isShow ? '' : 'disable'}`}>
          <div className="source-content">
            <div style={{ width: '14%' }}>
              <div className="content-title">已购人数</div>
              <span className="numbers">
                {membership.customer_memberships_count || 0}
              </span>
            </div>
            <div style={{ width: '14%' }}>
              <div className="content-title">活跃人数</div>
              <span className="numbers">{0}</span>
            </div>
            <div style={{ width: '23%' }}>
              <div className="content-title">价格</div>
              <span className="numbers">
                ¥&nbsp;{membership.listing_price || 0}
                <span style={{ color: '#999', fontSize: 20 }}>
                  /{membership.duration_units == 'days' ? '日' : '月'}
                </span>
              </span>
            </div>
            <div>
              <div className="content-title">押金</div>
              <span className="numbers">¥&nbsp;{membership.deposit || 0}</span>
            </div>
            <div>
              <div className="content-title">资源</div>
              <span style={{ width: '100%', float: 'left', paddingTop: 10 }}>
                {deskCountTotal || 0}个工位，{bookingCountTotal || 0}
                小时会议室，{membership.point || 0}
                {currencyName}
              </span>
              <span
                className="color-link"
                style={{
                  width: '100%',
                  float: 'left',
                  paddingTop: 5,
                  cursor: 'pointer'
                }}
                onClick={this.toSettingMemberCard.bind(null, membership)}
              >
                更多...
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let spaceSetting = selectors.getSpaceSetting(state)
  let {
    virtual_currency_settings: {
      virtual_currency_name,
      point_show_rate_setting
    }
  } = spaceSetting
  let currencyName = virtual_currency_name
  let currencyRate = point_show_rate_setting
  return {
    currencyName,
    currencyRate
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipsCardContent)
