import React, { Component, PropTypes } from 'react'
// import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
// import * as valid from 'utils/validate'
import {
  subscriptionActions,
  orgActions,
  successState,
  apiCallFailure,
  leaveEnterOptionActions,
  taxesAction
} from 'app/actions'
import { api, apiUtils } from 'app/services'
import {
  KBDateInput,
  KBTextarea,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover
} from 'components'
import * as selectors from 'app/selectors'
import { toPercent } from 'utils/kbUtil'
import _ from 'lodash'

var OrgOffGraduationForm = React.createClass({
  componentDidMount() {
    const {
      loc_id,
      org,
      subscriptions,
      fields: { line_items }
    } = this.props
    const {
      fields: { archive_at }
    } = this.props
    archive_at.onChange(new Date())
  },

  leaveOrganization(model) {
    const { loc_id, updateOrgActions, org } = this.props
    return api
      .archiveOrganization(loc_id, org.id, { archive_at: model.archive_at })
      .then(
        json => {
          updateOrgActions.success(json)
          KBPopover.close()
        },
        error => {
          updateOrgActions.failure(error)
        }
      )
  },

  render() {
    const {
      fields: { archive_at },
      org
    } = this.props
    const { handleSubmit, submitting } = this.props
    return (
      <div className="clear-fix">
        <form onSubmit={handleSubmit(this.leaveOrganization)}>
          <header className="kb-form-header">
            <h2 className="header-title">毕业</h2>
            <i
              className="iconfont header-close icon-cancel"
              onClick={KBPopover.close}
            />
          </header>
          <KBFormError error={this.props.error} />

          <div className="kb-form-container">
            <div className="kb-row kb-form-66 kb-ps">
              <div className="kb-form-group kb-div-c39">
                <label>公司名称</label>
                <div className="group-content">
                  <span>{org.name}</span>
                </div>
              </div>
              <div className="kb-form-group kb-div-66">
                <label className="label-rt">入驻员工数目</label>
                <div className="group-content">
                  <span>{org.assigned_desks_count}</span>
                </div>
              </div>
            </div>
            <div className="kb-row">
              <KBDateInput
                className="kb-form-group kb-form-c18 kb-form-alone"
                title="退租日期"
                field={archive_at}
                isRequired={true}
              />
            </div>
          </div>
          <div
            className="kb-row clear-fix"
            style={{ padding: '0 30px 30px 30px' }}
          >
            <div className="kb-form-fr">
              <KBButton
                className="certain-btn m-none"
                type="submit"
                submitting={submitting}
              >
                毕业
              </KBButton>
            </div>
          </div>
        </form>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { org } = props
  let subscriptions = selectors.getSubScriptionsByOrg(state, org.id)

  subscriptions = subscriptions.filter(subscription => {
    return subscription.status == 'active' || subscription.status == 'expiring'
  })

  const leaveOrEnterOptions = selectors.getOrgOptionsOfOrgId(state, org.id)

  let taxes = Object.assign([], selectors.getTaxes(state))
  taxes = _.cloneDeep(taxes).map(tax => {
    let name = tax.name
    tax.name = `${name} (税率:${toPercent(tax.rate, 2)})`
    return tax
  })

  return {
    taxes,
    subscriptions,
    leaveOrEnterOptions,
    count: subscriptions.length
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateOrgActions: bindActionCreators(orgActions.update, dispatch)
  }
}

// const validate = values => {
//   const errors = {}

//   if (!values.is_tower_location && !values.promptly && valid.required(values.expire_date)) {
//     errors.expire_date = '请选择退租日期'
//   }
//   return errors
// }

const formConfig = {
  form: 'OrgOffGraduationForm',
  fields: ['archive_at'],
  touchOnBlur: false
  // validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgOffGraduationForm)
