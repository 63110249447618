import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { routerActions } from 'react-router-redux'
import { KBLoadingContainer, KBTipsy } from 'components'
import { connect } from 'react-redux'
import { paginate } from 'utils/kbUtil'
import PostBlock from './PostBlock'
import PostCommentBlock from './PostCommentBlock'

var PostDetail = React.createClass({
  getInitialState() {
    return {
      postInfo: {},
      comments: [],
      currentCommentPage: 1,
      pagination: {},
      loading: false,
      commentsLoading: false
    }
  },

  componentDidMount() {
    this._loadData()
  },

  _loadData() {
    const { post_id } = this.props
    this.setState({ loading: true })
    api
      .getPostDetail(post_id)
      .then(({ json }) => {
        const postInfo = json
        this.setState({
          postInfo
        })
        this.getPostComments(1, postInfo.id)
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  getPostComments(page = 1, post_id) {
    const { comments } = this.state
    this.setState({ commentsLoading: true })
    api
      .getPostComments(post_id, { page, per_page: 20 })
      .then(({ json, response }) => {
        let pagination = paginate(response)
        const addedComments = json
        const newComments = comments.concat(addedComments)
        this.setState({
          comments: newComments,
          currentCommentPage: page,
          pagination
        })
      })
      .finally(() => {
        this.setState({
          commentsLoading: false
        })
      })
  },

  updateComments(deleted_id) {
    const { comments } = this.state
    let newComments = Object.assign([], comments)
    let deletedIndex = comments.findIndex(p => p.id === deleted_id)
    newComments.splice(deletedIndex, 1)
    this.setState({
      comments: newComments
    })
  },

  toPostListPage() {
    const { routerActions } = this.props
    routerActions.push(`/admin/memberships/memberManage/posts`)
  },

  render() {
    const {
      loading,
      postInfo,
      comments,
      currentCommentPage,
      pagination,
      commentsLoading
    } = this.state
    const { current_page, total_pages } = pagination
    const { post_id } = this.props
    const hasComments = comments.length > 0
    const hasVotes = postInfo.votes && postInfo.votes.length > 0

    return (
      <section className="kb-content-container">
        <header className="nav-header clear-fix">
          <div className="nav-section-header-title f-left">
            <i
              className="iconfont iconfont icon-arrow_drop_down_circle a-hover"
              style={{
                transform: 'translateY(2px) rotate(90deg)',
                color: '#bbb'
              }}
              onClick={this.toPostListPage}
            />
            <span>帖子详情</span>
          </div>
        </header>
        <div className="nav-section-content-container">
          <KBLoadingContainer loading={loading}>
            {postInfo.id ? <PostBlock post={postInfo} isDetail={true} /> : null}
            {hasComments || hasVotes ? (
              <div className="post-comments">
                {hasVotes ? (
                  <div className="post-votes vf-center">
                    <i className="iconfont iconfont icon-collect_icon" />
                    {postInfo.votes.map(v => {
                      let avatar = v.user.avatar || ''
                      let name = v.user.name || ''
                      return (
                        <KBTipsy content={name}>
                          <span
                            className="voter"
                            style={{ backgroundImage: `url(${avatar})` }}
                          />
                        </KBTipsy>
                      )
                    })}
                  </div>
                ) : null}

                {hasComments ? (
                  <div className="comments-list">
                    {comments.map(comment => {
                      return (
                        <PostCommentBlock
                          comment={comment}
                          deleteCallback={this.updateComments}
                        />
                      )
                    })}
                  </div>
                ) : null}

                {commentsLoading ? (
                  <KBLoadingContainer
                    loading={commentsLoading}
                    innerStyle={{ margin: '20px auto 40px' }}
                  />
                ) : null}

                {!commentsLoading &&
                current_page &&
                total_pages &&
                current_page < total_pages ? (
                  <div className="add-more">
                    <div
                      className="add-more-btn"
                      onClick={e => {
                        e.stopPropagation()
                        this.getPostComments(currentCommentPage + 1, post_id)
                      }}
                    >
                      加载更多
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const {
    params: { post_id }
  } = props

  return {
    post_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostDetail)
