import React, { Component, PropTypes } from 'react'
import PhasesCell from './PhasesCell'
import moment from 'moment'
import _ from 'lodash'

var OrgSubscriptionCreateRentingPhase = React.createClass({
  componentDidMount() {
    const { isShowPhases, oldContract, sales_renting_phases } = this.props

    isShowPhases &&
      oldContract.sales_renting_phases &&
      oldContract.sales_renting_phases.map(phase => {
        sales_renting_phases.addField(getPhase(phase))
      })

    function getPhase(phase) {
      return Object.assign({}, phase, {
        start_date: moment(phase.start_date)
          .clone()
          .hours(12)
          .minutes(0)
          .seconds(0)
          .toDate(),
        end_date: moment(phase.end_date)
          .clone()
          .hours(12)
          .minutes(0)
          .seconds(0)
          .toDate(),
        recurring_due_date: moment(phase.recurring_due_date)
          .clone()
          .hours(12)
          .minutes(0)
          .seconds(0)
          .toDate(),
        recurring_amount: phase.recurring_amount,
        total_amount: parseFloat(phase.total_amount).toFixed(2),
        recurring_house_fee: parseFloat(phase.recurring_house_fee),
        rent_type: phase.rent_type
      })
    }
  },

  render() {
    let {
      sales_renting_phases,
      removePhases,
      parseRentingPhases,
      freePhases,
      sales_renting_phases_error,
      total,
      tax,
      isParking,
      isContract,
      sales_resources
    } = this.props
    let normal_phases = sales_renting_phases.filter(
      phases => phases.rent_type.value == 'normal' || phases.rent_type.value == 'no_sales_area'
    )
    let free_phases = sales_renting_phases.filter(
      phases => phases.rent_type.value == 'free'
    )
    normal_phases.sort(function(a, b) {
      return (
        moment(a.start_date.value).valueOf() -
        moment(b.start_date.value).valueOf()
      )
    })
    free_phases.sort(function(a, b) {
      return (
        moment(a.start_date.value).valueOf() -
        moment(b.start_date.value).valueOf()
      )
    })
    let tax_rate = (tax && tax.rate) || 0
    let btn_disabled=sales_resources.length===0 && isContract ? true:false;

    return (
      <fieldset className="fieldset">
        <legend className="clear-fix">
          <span className="f-left">缴{isContract ? '费' : '租'}阶段</span>
          {!isContract&&<span
            className="f-left"
            style={{
              fontSize: 14,
              color: '#666',
              marginLeft: 10,
              marginTop: 5
            }}
          >
            (更改{isParking ? '车位' : '工位设置'}后请重新生成缴租阶段)
          </span>}
        </legend>
        {!isContract&&<p className="m-bottom-sm color-six">
          缴租阶段是指使用同样的缴租规则的一个或多个缴租周期。例如，如果所有月份缴租方式和金额都是一样的话，就可以创建一个缴租阶段。如果前两个月免费，后八个月正常付款，则可以创建两个缴租阶段。每个缴租阶段可以具体规定每个周期长度和金额。所有缴租阶段必须连续而且覆盖租约的整个时间段。
        </p>}
        {sales_renting_phases.length ? (
          <div className="form-content" style={{ paddingLeft: 20 }}>
            {normal_phases.length > 0 ? (
              <div className="m-top">
                <div
                  style={{
                    paddingBottom: 10,
                    borderBottom: '1px solid #e5e5e5',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{ flex: 1, display: 'flex', alignItems: 'center' }}
                  >
                    <span
                      style={{ marginRight: 15, fontSize: 16, color: '#333' }}
                    >
                      {moment(normal_phases[0].start_date.value).format(
                        'YYYY年MM月DD日'
                      )}{' '}
                      -{' '}
                      {moment(_.last(normal_phases).end_date.value).format(
                        'YYYY年MM月DD日'
                      )}
                    </span>
                    <span style={{ fontSize: 10 }}>(缴{isContract ? '费' : '租'}阶段)</span>
                  </div>
                  <span
                    className="c-pointer"
                    onClick={() => {
                      removePhases(isContract ? 'no_sales_area':'normal')
                    }}
                    style={{ fontSize: 16 }}
                  >
                    <i className="iconfont icon-close a-hover" />
                  </span>
                </div>
                <div style={{ paddingLeft: 15 }}>
                  <table className="content-table">
                    <tbody>
                      {normal_phases.map((phase, index) => {
                        total = total + parseFloat(phase.total_amount.value)
                        let index_d = sales_renting_phases.findIndex(
                          sales =>
                            moment(sales.start_date.value).isSame(
                              phase.start_date.value
                            ) &&
                            moment(sales.end_date.value).isSame(
                              phase.end_date.value
                            )
                        )
                        return (
                          <PhasesCell
                            key={index}
                            phase={phase}
                            index={index}
                            index_d={index_d}
                            sales_renting_phases={normal_phases}
                            all_sales_renting_phases={sales_renting_phases}
                            isContract={isContract}
                          />
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              ''
            )}
            {free_phases.length > 0 ? (
              <div className="m-top">
                <div
                  style={{
                    paddingBottom: 10,
                    borderBottom: '1px solid #e5e5e5',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{ flex: 1, display: 'flex', alignItems: 'center' }}
                  >
                    <span
                      style={{ marginRight: 15, fontSize: 16, color: '#333' }}
                    >
                      {moment(free_phases[0].start_date.value).format(
                        'YYYY年MM月DD日'
                      )}{' '}
                      -{' '}
                      {moment(_.last(free_phases).end_date.value).format(
                        'YYYY年MM月DD日'
                      )}
                    </span>
                    <span style={{ fontSize: 10 }}>(免租期)</span>
                  </div>
                  <span
                    className="c-pointer"
                    onClick={() => {
                      removePhases('free')
                    }}
                    style={{ fontSize: 16 }}
                  >
                    <i className="iconfont icon-close a-hover" />
                  </span>
                </div>
                <div style={{ paddingLeft: 15 }}>
                  <table className="content-table">
                    <tbody>
                      {free_phases.map((phase, index) => {
                        total = total + parseFloat(phase.total_amount.value)
                        let index_d = sales_renting_phases.findIndex(
                          sales =>
                            moment(sales.start_date.value).isSame(
                              phase.start_date.value
                            ) &&
                            moment(sales.end_date.value).isSame(
                              phase.end_date.value
                            )
                        )
                        return (
                          <PhasesCell
                            key={index}
                            phase={phase}
                            index={index}
                            index_d={index_d}
                            sales_renting_phases={free_phases}
                            all_sales_renting_phases={sales_renting_phases}
                            isContract={isContract}
                          />
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="total-num">
              <span>共&nbsp;¥&nbsp;{parseFloat(total).toFixed(2)}</span>
              {tax && total ? (
                <span>
                  (税额: ¥
                  {parseFloat((total * tax_rate) / (1 + tax_rate)).toFixed(2)})
                </span>
              ) : (
                ''
              )}
            </div>
            {sales_renting_phases_error.touched &&
              sales_renting_phases_error.error && (
                <p className="lr-error">{sales_renting_phases_error.error}</p>
              )}
            <div>
              <button
                className="bordered-btn"
                type="button"
                onClick={() => {
                  parseRentingPhases()
                }}
                style={{ marginLeft: 0 }}
              >
                <i className="iconfont icon-add" />
                添加缴{isContract ? '费' : '租'}阶段
              </button>
              {!isContract && <button
                className="bordered-btn"
                type="button"
                onClick={() => {
                  freePhases()
                }}
              >
                <i className="iconfont icon-add" />
                添加免租期
              </button>}
            </div>
          </div>
        ) : (
          <div>
            <button
              className={`${btn_disabled ? "bordered-btn-disabled" : "bordered-btn"}`}
              type="button"
              onClick={() => {
                parseRentingPhases()
              }}
              style={{ marginLeft: 0 }}
              disabled={btn_disabled}
            >
              <i className="iconfont icon-add" />
              添加缴{isContract ? '费' : '租'}阶段
            </button>
            {isContract ? "" : <button
              className="bordered-btn"
              type="button"
              onClick={() => {
                freePhases()
              }}
            >
              <i className="iconfont icon-add" />
              添加免租期
            </button>}
            {sales_renting_phases_error.touched &&
              sales_renting_phases_error.error && (
                <p className="lr-error">{sales_renting_phases_error.error}</p>
              )}
          </div>
        )}
      </fieldset>
    )
  }
})

export default OrgSubscriptionCreateRentingPhase
