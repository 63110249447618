import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { invoicesActions, successState, apiCallFailure } from 'app/actions'
import { KBAvatar, KBCheckBox } from 'components'

var OrgSubscriptionMembersList = React.createClass({
  changeMember(checked) {
    const { member, callback } = this.props
    callback && callback(checked, member)
  },

  render() {
    const { member } = this.props

    return (
      <li style={{ display: 'flex', alignItems: 'center' }}>
        <KBCheckBox
          callback={this.changeMember}
          checked={true}
          style={{ marginRight: 5 }}
        />
        <KBAvatar
          style={{ display: 'inline-block', marginRight: '5px' }}
          user={member}
          size={30}
        />
        <span>{member.name}</span>
      </li>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionMembersList)
