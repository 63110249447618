import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import {
  KBDateInput,
  KBTextarea,
  KBSelect,
  KBFormError,
  KBButton,
  KBPopover
} from 'components'
import { TERMINATE_REASON } from 'app/constants'
import { getSubScriptionsByOrg } from 'app/selectors'

var OrgOffHireForm = React.createClass({
  componentDidMount() {
    const {
      fields: { terminate_note }
    } = this.props

    if (!terminate_note.value) {
      terminate_note.onChange('无备注')
    }
  },

  render() {
    const {
      fields: {
        terminate_reason,
        terminate_note,
        expire_date,
        archive_at,
        promptly
      },
      org,
      expirDateChange,
      isGraduation
    } = this.props

    return (
      <div className="kb-form-container">
        <div className="kb-row kb-form-66 kb-ps">
          <div className="kb-form-group kb-div-c39">
            <label>公司名称</label>
            <div className="group-content">
              <span>{org.name}</span>
            </div>
          </div>
          <div className="kb-form-group kb-div-66">
            <label className="label-rt">入驻员工数目</label>
            <div className="group-content">
              <span>{org.assigned_desks_count}</span>
            </div>
          </div>
        </div>
        <div className="kb-row">
          <KBDateInput
            className="kb-form-group kb-form-c18 kb-form-alone"
            title="退租日期"
            field={archive_at}
            isRequired={true}
          />
        </div>

        <div className="kb-row">
          <div className="kb-form-group kb-form-c18 kb-form-alone">
            <label>合同立即终止</label>
            <div>
              <input {...promptly} className="kb-input" type="checkbox" />
            </div>
          </div>
        </div>

        <div className="kb-row">
          <div className="kb-form-group kb-form-c18 kb-form-alone">
            <label>同时毕业</label>
            <div>
              <input
                onChange={isGraduation}
                className="kb-input"
                type="checkbox"
              />
            </div>
          </div>
        </div>
        {promptly.value ? (
          ''
        ) : (
          <div className="kb-row">
            <KBDateInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="合同终止日期"
              field={expire_date}
              isRequired={true}
              placeholder=""
              onChange={expirDateChange}
            />
          </div>
        )}
        <div className="kb-row">
          <KBSelect
            defaultValue="-1"
            className="kb-form-group kb-form-c18 kb-form-alone"
            title="终止原因"
            field={terminate_reason}
            isRequired={true}
            arrayData={TERMINATE_REASON}
          />
        </div>
        <div className="kb-row">
          <KBTextarea
            className="kb-form-group kb-form-c18 kb-form-alone"
            title="备注"
            field={terminate_note}
            isRequired={true}
          />
        </div>
      </div>
    )
  }
})

export default OrgOffHireForm
