import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import classNames from 'classnames'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import * as constants from 'app/constants'
import {
  KBInput,
  KBUserInput,
  KBTextarea,
  KBSelect,
  KBFormError,
  KBButton,
  KBAvatar,
  KBPopover,
  KBPopoverTop
} from 'components'
import SpaceAddMemberShipDiscountForm from '../memberships/SpaceAddMemberShipDiscountForm'
import _ from 'lodash'

var SpaceAddMemberShipSource = React.createClass({
  displayName: 'SpaceAddMemberShipSource',
  getInitialState() {
    return {}
  },
  componentDidMount() {
    const {
      query,
      updateMembershipResourceAction,
      fields: { duration_months }
    } = this.props

    if (!duration_months.value) {
      duration_months.onChange('12')
    }

    if (!query.id) {
      return
    }
    const { resource_type, id } = query

    api
      .getMemberShipResourceInfo(id, {
        resource_type
      })
      .then(
        res => {
          updateMembershipResourceAction.success(res)
        },
        err => {
          updateMembershipResourceAction.failure(err)
        }
      )
  },

  updateMembershipSource(model) {
    const {
      createMembershipResourceAction,
      successAction,
      updateMembershipResourceAction,
      routerActions,
      query
    } = this.props

    if (query.status == 'update') {
      const { id } = query
      return api
        .putMembershipResources(id, {
          ...model
        })
        .then(
          res => {
            updateMembershipResourceAction.success(res)
            successAction({ message: '修改会员制资源成功!' })
            routerActions.push('/admin/memberships/memberManage/resources')
            KBPopover.close()
          },
          err => {
            updateMembershipResourceAction.failure(err)
          }
        )
    }

    return api
      .postMembershipResources({
        ...model
      })
      .then(
        res => {
          createMembershipResourceAction.success(res)
          successAction({ message: '创建会员制资源成功!' })
          routerActions.push('/admin/memberships/memberManage/resources')
          KBPopover.close()
        },
        err => {
          createMembershipResourceAction.failure(err)
        }
      )
  },

  changeResType(type) {
    const {
      fields: { resource_type }
    } = this.props

    resource_type.onChange(type)
  },

  renderMembershipBase() {
    const {
      fields: {
        resource_type,
        name,
        price,
        point,
        available_credits,
        accessible_areas,
        timespans,
        duration_months,
        discounts
      },
      selectedAreas,
      virtual_currency_name
    } = this.props
    const oneClass = { className: 'kb-form-group kb-form-c18 kb-form-alone' }
    const quotaType = {
      desk: 'input-frequency',
      booking: 'input-hour',
      printing: 'input-sheet'
    }

    return (
      <fieldset className="fieldset">
        <p style={{ marginBottom: 20 }}>允许一次性购买多次使用权并获得优惠</p>
        <div className="kb-row">
          {/* <KBSelect defaultValue='-1' title='资源类型' className='kb-form-group kb-form-c18 kb-form-alone'  isRequired={true} field={ resource_type } arrayData={ constants.MEETING_SHIP_RESOURCE_TYPE } /> */}
          <div className="kb-form-group kb-form-c18 kb-form-alone">
            <label>
              资源类型<span className="must-fill">*</span>
            </label>
            <div>
              <div className="memberships-res-types">
                {constants.MEETING_SHIP_RESOURCE_TYPE.map(type => {
                  return (
                    <span
                      className={`res-type ${
                        resource_type.value == type.id ? 'res-type-active' : ''
                      }`}
                      onClick={this.changeResType.bind(null, type.id)}
                    >
                      {type.name}
                    </span>
                  )
                })}
              </div>
              {resource_type.touched && resource_type.error ? (
                <p className="lr-error">{resource_type.error}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="kb-row">
          <KBInput
            title="名称"
            isRequired={true}
            className="kb-form-group kb-form-c18 kb-form-alone"
            hint="资源包名称，用户可见。如 “Day Pass”, “10小时的会议室使用”"
            field={name}
            value={name.value}
          />
        </div>
        <div className="kb-row kb-form-66">
          <KBInput
            title="价格"
            type="number"
            isRequired={true}
            label="true"
            placeholder="¥"
            className="kb-form-group kb-form-c39"
            field={price}
            value={price.value}
          />
          <KBInput
            title="可使用数量"
            type="number"
            label="true"
            isRequiredR={true}
            inputClass={quotaType[resource_type.value]}
            className="kb-form-group kb-form-c48"
            titleClass="label-rt"
            field={available_credits}
            value={available_credits.value}
          />
        </div>
        <div className="kb-row kb-form-66">
          <KBInput
            title="有效时长"
            type="number"
            hint={
              duration_months.value
                ? `购买后${duration_months.value}月后失效，剩余次数不退还`
                : ''
            }
            isRequired={true}
            inputClass="input-month"
            className="kb-form-group kb-form-c39"
            field={duration_months}
            value={duration_months.value}
          />
        </div>
        <div className="kb-row">
          <div className="kb-form-group kb-form-c18 kb-form-alone">
            <label>优惠</label>
            <div>
              {discounts.map((discount, index) => {
                return (
                  <div style={{ paddingTop: 9 }}>
                    <span>
                      满&nbsp;{discount.number.value || 0}&nbsp;赠&nbsp;
                      {discount.complimentary_number.value || 0}
                    </span>
                    <a
                      className="color-link m-left-sm"
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        discounts.removeField(index)
                      }}
                    >
                      删除
                    </a>
                  </div>
                )
              })}
              <div style={{ paddingTop: 9 }}>
                <a
                  className="color-link"
                  style={{ cursor: 'pointer' }}
                  onClick={this.addDiscounts}
                >
                  添加优惠
                </a>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  updateDiscounts(model) {
    const {
      fields: { price, discounts }
    } = this.props
    model.price = price.value || '0'
    discounts.addField(model)
  },

  addDiscounts() {
    const {
      fields: { resource_type }
    } = this.props
    KBPopoverTop.show(
      <SpaceAddMemberShipDiscountForm
        quota_type={resource_type.value}
        isTop={true}
        callback={this.updateDiscounts}
      />
    )
  },

  renderDiscounts() {
    const {
      fields: { discounts },
      query,
      virtual_currency_name
    } = this.props
    const isUpdate = query.status == 'update'
    return (
      <fieldset className="fieldset">
        <legend className="clear-fix">
          <span>优惠信息</span>
          <button
            className="f-right bordered-btn"
            type="button"
            onClick={this.addDiscounts}
          >
            添加
          </button>
        </legend>
        <table className="content-table" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th>购买满多少</th>
              <th>价格</th>
              <th>{virtual_currency_name}购买</th>
              <th>赠送数量</th>
              <th>可获得的{virtual_currency_name}</th>
              <th className="t-right">操作</th>
            </tr>
          </thead>
          <tbody>
            {discounts.map((discount, index) => {
              return (
                <tr>
                  <td>{discount.number.value}</td>
                  <td>{discount.price.value}</td>
                  <td>{discount.point.value}</td>
                  <td>{discount.complimentary_number.value}</td>
                  <td>{discount.complimentary_point.value}</td>
                  <td className="t-right" style={{ paddingRight: 26 }}>
                    <i
                      className="iconfont icon-delete"
                      onClick={() => discounts.removeField(index)}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </fieldset>
    )
  },

  render() {
    const { handleSubmit, submitting } = this.props
    const { query } = this.props

    return (
      <form onSubmit={handleSubmit(this.updateMembershipSource)}>
        <KBFormError error={this.props.error} />
        <div className="kb-form-header">
          <h2 className="header-title">
            {query.status == 'update' ? '编辑' : '添加'}资源
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </div>
        <div className="kb-form-container">
          {this.renderMembershipBase()}
          {/* { this.renderDiscounts() } */}
          <div className="f-right">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn m-left-sm"
              type="submit"
              submitting={submitting}
            >
              {query.status == 'update' ? '保存' : '添加'}
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function parseData(data) {
  if (!data) {
    return {}
  }

  let dt = _.cloneDeep(data)
  dt.accessible_areas = data.accessible_areas && data.accessible_areas.join(',')
  dt.timespans =
    (dt.timespans &&
      dt.timespans
        .map(json => {
          json = json.join('-')
          return json
        })
        .join(',')) ||
    ''
  return dt
}

function mapStateToProps(state, props) {
  const { location, status, id, resource_type } = props
  const query = { status, id, resource_type }
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)

  let params = {
    query,
    virtual_currency_name
  }

  if (query.id) {
    const currentMemberShip = selectors.getMembershipResources(state, query.id)

    const selectedAreas =
      currentMemberShip &&
      currentMemberShip.accessible_areas &&
      currentMemberShip.accessible_areas.map(json => {
        let obj = {}
        obj.id = json
        obj.name = constants.MEETING_SHIP_AREA_TYPE_OBJ[json]
        return obj
      })
    params.selectedAreas = selectedAreas || []
    params.initialValues = {
      ...parseData(currentMemberShip)
    }
  }

  return params
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    createMembershipResourceAction: bindActionCreators(
      actions.membershipResourcesAction.create,
      dispatch
    ),
    updateMembershipResourceAction: bindActionCreators(
      actions.membershipResourcesAction.update,
      dispatch
    ),
    successAction: bindActionCreators(actions.successState, dispatch),
    apiCallFailureActions: bindActionCreators(actions.apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.resource_type)) {
    errors.resource_type = '请选择资源类型'
  }

  if (valid.required(values.name)) {
    errors.name = '请输入资源名称'
  }

  if (valid.isPrice(values.price)) {
    errors.price = '请输入正确的价格, 小数点保留2位'
  }

  if (valid.required(values.available_credits)) {
    errors.available_credits = '请输入使用数量'
  }

  if (valid.required(values.duration_months)) {
    errors.duration_months = '请输入有效时长'
  }

  return errors
}

const formConfig = {
  form: 'SpaceAddMemberShipSource',
  fields: [
    'resource_type',
    'name',
    'price',
    'point',
    'available_credits',
    'accessible_areas',
    'timespans',
    'duration_months',
    'discounts[].number',
    'discounts[].price',
    'discounts[].complimentary_number',
    'discounts[].point',
    'discounts[].complimentary_point'
  ],
  touchOnBlur: false,
  validate: validate
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SpaceAddMemberShipSource)
