import React, { Component, PropTypes } from 'react'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { memberActions } from 'app/actions'
import { SPACE_ROLES, LOCATION_ROLES } from 'app/constants'
import { KBLoadingContainer, KBAvatar } from 'components'
import { canAccessLocationRoles } from 'app/reducers/role'
import _ from 'lodash'

var LocationMemberInfo = React.createClass({
  displayName: 'LocationMemberInfo',
  getInitialState() {
    return {}
  },
  componentDidMount() {
    const { member_id } = this.props
    this._loadMembersData(member_id)
  },

  componentWillReceiveProps(nextProps) {
    const { member_id } = nextProps
    if (this.props.member_id != member_id) {
      this._loadMembersData(member_id)
    }
  },

  _loadMembersData(member_id) {
    const { updateMemberAction } = this.props
    api.getSpaceMemberInfo(member_id).then(
      json => {
        updateMemberAction.success(json)
      },
      errors => {
        updateMemberAction.failure(errors)
      }
    )
  },
  render() {
    const {
      member,
      members,
      SpaceRoles,
      locations,
      locationRole,
      locationRoleOfId
    } = this.props
    return (
      <div className="kb-out-box clear-fix">
        {member ? (
          <section className="kb-content">
            <nav className="nav-member">
              <header>
                <div className="member-img">
                  <KBAvatar user={member} size={90} />
                </div>
              </header>
              <div
                className="kb-section"
                style={{ padding: '0 30px 30px 30px' }}
              >
                <ul>
                  <li>
                    <span className="section-title" style={{ width: 'auto' }}>
                      姓名:
                    </span>
                    <span className="section-title-content">
                      {member.name ? member.name : '无'}
                    </span>
                  </li>
                  <li>
                    <span className="section-title" style={{ width: 'auto' }}>
                      性别:
                    </span>
                    <span className="section-title-content">
                      {member.gender == 'male' ? '男' : '女'}
                    </span>
                  </li>
                  <li>
                    <span className="section-title" style={{ width: 'auto' }}>
                      电话:
                    </span>
                    <span className="section-title-content">
                      {member.phone_num_disabled ? (
                        <span>已禁用</span>
                      ) : (
                        member.phone_num || '无'
                      )}
                    </span>
                  </li>
                  <li>
                    <span className="section-title" style={{ width: 'auto' }}>
                      微信号:
                    </span>
                    <span className="section-title-content">
                      {member.wechat || '无'}
                    </span>
                  </li>
                  <li>
                    <span className="section-title" style={{ width: 'auto' }}>
                      邮箱:
                    </span>
                    <span
                      className="section-title-content"
                      style={{ width: 120 }}
                    >
                      {member.email || '无'}
                    </span>
                  </li>
                  <li>
                    <span className="section-title" style={{ width: 'auto' }}>
                      职位:
                    </span>
                    <span className="section-title-content">
                      {member.space_title || '无'}
                    </span>
                  </li>
                </ul>
              </div>
            </nav>
            <section className="nav-section-container f-right">
              <header className="nav-header clear-fix">
                <div className="nav-header-title">
                  <h2 className="header-title f-left" style={{ fontSize: 22 }}>
                    {member.name || '员工详情'}
                  </h2>
                </div>
              </header>
              <div className="nav-section-content-container">
                <table className="content-table table-align">
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>上级员工</th>
                      <th>下级员工</th>
                      <th>模块权限</th>
                      <th>分店</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-none">
                      <td>
                        {member.parent_id && members[member.parent_id] ? (
                          <Link
                            className="a-hover"
                            to={`/admin/employee/${member.parent_id}`}
                          >
                            {members[member.parent_id].name}
                          </Link>
                        ) : (
                          '无'
                        )}
                      </td>
                      <td>
                        {member.childrens
                          ? member.childrens.map((child, index) => {
                              return (
                                <div style={{ marginBottom: 5 }} key={index}>
                                  <Link
                                    className="a-hover"
                                    to={`/admin/employee/${child.id}`}
                                  >
                                    {child.name}
                                  </Link>
                                </div>
                              )
                            })
                          : '无'}
                      </td>
                      <td>
                        {SpaceRoles.length > 0
                          ? SpaceRoles.map((role, index) => {
                              return (
                                <div style={{ marginBottom: 5 }} key={index}>
                                  {SPACE_ROLES[role]}
                                </div>
                              )
                            })
                          : '无'}
                      </td>
                      <td>
                        {Object.keys(locations).length > 0 &&
                        locationRoleOfId.length > 0
                          ? locationRoleOfId.map((role, index) => {
                              let filterRoleOfLocationId = _.filter(
                                locationRole,
                                function(o) {
                                  return o.location_id == role.location_id
                                }
                              )
                              return (
                                <div style={{ marginBottom: 5 }} key={index}>
                                  <span style={{ marginRight: 10 }}>
                                    {locations[role.location_id] &&
                                      locations[role.location_id].name}
                                  </span>
                                  <span>
                                    (
                                    {Object.keys(filterRoleOfLocationId)
                                      .length > 0
                                      ? filterRoleOfLocationId
                                          .map(
                                            role => LOCATION_ROLES[role.role]
                                          )
                                          .join(' ')
                                      : '无'}
                                    )
                                  </span>
                                </div>
                              )
                            })
                          : '无'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </section>
        ) : (
          ''
        )}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params } = props
  const { member_id } = params
  let member = selectors.getSpaceMemberInfo(state, member_id)
  let members = state.entities.users
  let locations = selectors.getSerializeLocationObj(state)
  //获取空间模块权限
  function getSpaceRole(user) {
    let roles = []
    if (user && user.roles) {
      roles = user.roles
        .filter(role => role.location_id == null)
        .map(role => role.role)
    }
    return roles
  }
  //获取所在分店权限
  function getLocationRole(user) {
    let roles = []
    if (user && user.roles) {
      roles = user.roles.filter(role => role.location_id != null)
    }
    return roles
  }
  let SpaceRoles = getSpaceRole(member)
  let locationRole = getLocationRole(member)
  let locationRoleOfId = _.uniqBy(locationRole, 'location_id')
  return {
    member_id,
    member,
    members,
    SpaceRoles,
    locations,
    locationRole,
    locationRoleOfId
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    updateMemberAction: bindActionCreators(memberActions.update, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationMemberInfo)
