import React from 'react'
import { routerActions } from 'react-router-redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { successState } from 'app/actions'
import {
  KBLoadingContainer,
  KBDropdown,
  KBButton,
  KBPopover,
  KBTableWithoutData
} from 'components'
import moment from 'moment'
import _ from 'lodash'
import { Input, Row, Col, DatePicker, Icon, notification } from 'antd'
import SettingCoupon from './SettingCoupon'

var CouponDetail = React.createClass({
  displayName: 'CouponDetail',
  getInitialState() {
    const { params = {} } = this.props
    return {
      loc_id: params.id,
      modalVisible: false,
      detail: {}
    }
  },

  getList() {
    const { coupon_id } = this.props
    api.getCouponDeatil(coupon_id).then(res => {
      const { json } = res
      this.setState({ detail: json })
    })
  },

  componentDidMount() {
    this.getList()
    // api.getCoupon()
  },

  updateCoupon(params, type) {
    const { coupon_id } = this.props
    api.updateCoupon(coupon_id, params).then(
      res => {
        if (type === 'edit') {
          this.setState({ modalVisible: false }, () => {
            this.getList()
          })
        } else {
          this.getList()
        }
      },
      err => {
        notification.error({ message: err.message })
      }
    )
  },

  upOrDownCoupon() {
    const { successActions } = this.props
    const {
      detail: { on_sales, id }
    } = this.state
    const title = on_sales ? '下架' : '上架'
    const content = on_sales ? '确定下架该优惠吗？' : '确定上架该优惠吗？'
    const msg = on_sales ? '下架成功！' : '上架成功！'
    KBPopover.plugins.confirm(title, content, {
      confirm: () => {
        api
          .updateCoupon(id, { on_sales: !on_sales })
          .then(
            json => {
              this.getList()
              successActions({ message: msg })
            },
            error => {
              notification.error({ message: error.message })
              console.log(error)
            }
          )
          .finally(() => {
            KBPopover.close()
          })
      }
    })
  },

  showBtn() {
    const { pageType } = this.props
    const {
      detail: { bridge_price, price, tenant_price, source } = {}
    } = this.state
    let show = true
    if (pageType === 'coupon') {
      if (
        (source && source.non_entering_listing_price != price) ||
        (source && source.bridge_listing_price != bridge_price) ||
        (source && source.listing_price != tenant_price)
      ) {
        show = false
      }
    } else {
      if (
        (source && source.non_entering_day_listing_price != price) ||
        (source && source.bridge_listing_price != bridge_price) ||
        (source && source.day_listing_price != tenant_price)
      ) {
        show = false
      }
    }
    return show
  },

  getPrice(price, discount) {
    const dis = Math.abs(discount)
    if (price > dis) {
      return Number(price - dis).toFixed(2)
    } else {
      return 0
    }
  },

  render() {
    const { pageType } = this.props
    const { detail, modalVisible } = this.state

    return (
      <div className="kb-content">
        <section className="kb-content-container" ref="container">
          <header className={`kb-form-header`}>
            <h2 className="header-title clear-fix">
              <span>优惠详情</span>
              <span>({(detail.source || {}).name})</span>
              {!detail.on_sales ? (
                <span
                  style={{
                    display: 'inline-block',
                    fontSize: 12,
                    marginLeft: 16,
                    padding: '3px 6px',
                    backgroundColor: '#e6e6e6',
                    color: '#666666'
                  }}
                >
                  已下架
                </span>
              ) : (
                <span
                  style={{
                    display: 'inline-block',
                    fontSize: 12,
                    marginLeft: 16,
                    padding: '3px 6px',
                    backgroundColor: '#73bb4b ',
                    color: '#ffffff'
                  }}
                >
                  已上架
                </span>
              )}
            </h2>
            {this.showBtn() ? (
              <button
                className="f-right bordered-btn"
                style={{ margin: '22px 18px 0 0' }}
                onClick={() => this.setState({ modalVisible: true })}
              >
                编辑
              </button>
            ) : (
              ''
            )}
            {detail.on_sales ? (
              <button
                className="f-right bordered-btn"
                style={{ margin: '22px 18px 0 0' }}
                onClick={() => this.upOrDownCoupon()}
              >
                下架
              </button>
            ) : this.showBtn() ? (
              <button
                className="f-right bordered-btn"
                style={{ margin: '22px 18px 0 0' }}
                onClick={() => this.upOrDownCoupon()}
              >
                上架
              </button>
            ) : (
              ''
            )}
          </header>
          <div className="kb-form-container">
            <fieldset className="fieldset">
              <div className="kb-row kb-form-66">
                <div className={'kb-form-group kb-div-c39'}>
                  <label>优惠名称</label>
                  <div className="group-content">
                    <span>{detail.title}</span>
                  </div>
                </div>
                <div className={'kb-form-group kb-div-c39'}>
                  <label>预定日期</label>
                  <div className="group-content">
                    <span>
                      {moment(detail.start_date).format('YYYY年MM月DD日')}-
                      {moment(detail.end_date).format('YYYY年MM月DD日')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="kb-row kb-form-66">
                <div className={'kb-form-group kb-div-c39'}>
                  <label>提前预览时间</label>
                  <div className="group-content">
                    <span>
                      {moment
                        .unix(detail.preview_start_at)
                        .format('YYYY年MM月DD日 HH时')}
                    </span>
                  </div>
                </div>
                <div className={'kb-form-group kb-div-c39'}>
                  <label>创建人</label>
                  <div className="group-content">
                    <span>{(detail.creator || {}).name}</span>
                  </div>
                </div>
              </div>
              <table className="content-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>原价</th>
                    <th>优惠额度</th>
                    <th>最终活动价</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{width:200}}>一般价格</td>
                    <td>¥{detail.price || 0}</td>
                    <td>¥{Math.abs(detail.discount_price) || 0}</td>
                    <td>
                      ¥{this.getPrice(detail.price, detail.discount_price) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td style={{width:200}}>办公楼会员价</td>
                    <td>¥{detail.tenant_price || 0}</td>
                    <td>¥{Math.abs(detail.discount_tenant_price) || 0}</td>
                    <td>
                      ¥
                      {this.getPrice(
                        detail.tenant_price,
                        detail.discount_tenant_price
                      ) || 0}
                    </td>
                  </tr>
                  <tr>
                    <td style={{width:200}}>奕桥会员价</td>
                    <td>¥{detail.bridge_price || 0}</td>
                    <td>¥{Math.abs(detail.discount_bridge_price) || 0}</td>
                    <td>
                      ¥
                      {this.getPrice(
                        detail.bridge_price,
                        detail.discount_bridge_price
                      ) || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <div className="kb-row kb-form-66">
                <div className="kb-form-group kb-div-66">
                  <div className={'kb-form-group kb-div-173'}>
                    <label>一般价格(原价)</label>
                    <div className="group-content">
                      <span>¥{detail.price || 0}</span>
                    </div>
                  </div>
                  <div className={'kb-form-group kb-div-173'}>
                    <label>一般价格(优惠价格)</label>
                    <div className="group-content">
                      <span>¥{Math.abs(detail.discount_price) || 0}</span>
                    </div>
                  </div>
                </div>
                <div className="kb-form-group kb-div-66">
                  <div className={'kb-form-group kb-div-173'}>
                    <label>一般价格(最终价格)</label>
                    <div className="group-content">
                      <span>
                        ¥
                        {this.getPrice(detail.price, detail.discount_price) ||
                          0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="kb-row kb-form-66">
                <div className="kb-form-group kb-div-66">
                  <div className={'kb-form-group kb-div-c39'}>
                    <label>办公楼会员价(原价)</label>
                    <div className="group-content">
                      <span>¥{detail.tenant_price || 0}</span>
                    </div>
                  </div>
                  <div className={'kb-form-group kb-div-c39'}>
                    <label>办公楼会员价(优惠价格)</label>
                    <div className="group-content">
                      <span>
                        ¥{Math.abs(detail.discount_tenant_price) || 0}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="kb-form-group kb-div-66">
                  <div className={'kb-form-group kb-div-c39'}>
                    <label>办公楼会员价(最终价格)</label>
                    <div className="group-content">
                      <span>
                        ¥
                        {this.getPrice(
                          detail.tenant_price,
                          detail.discount_tenant_price
                        ) || 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="kb-row kb-form-66">
                <div className="kb-form-group kb-div-66">
                  <div className={'kb-form-group kb-div-c39'}>
                    <label>奕桥会员价(原价)</label>
                    <div className="group-content">
                      <span>¥{detail.bridge_price || 0}</span>
                    </div>
                  </div>
                  <div className={'kb-form-group kb-div-c39'}>
                    <label>奕桥会员价(优惠价格)</label>
                    <div className="group-content">
                      <span>
                        ¥{Math.abs(detail.discount_bridge_price) || 0}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="kb-form-group kb-div-66">
                  <div className={'kb-form-group kb-div-c39'}>
                    <label>奕桥会员价(最终价格)</label>
                    <div className="group-content">
                      <span>
                        ¥
                        {this.getPrice(
                          detail.bridge_price,
                          detail.discount_bridge_price
                        ) || 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="kb-row kb-form-66">
                <div className={'kb-form-group kb-div-c39'}>
                  <label>备注</label>
                  <div className="group-content">
                    <span>{detail.note || '-'}</span>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          {modalVisible && (
            <SettingCoupon
              source_type={pageType === 'coupon' ? 'meeting' : 'desk'}
              type={'edit'}
              close={() => this.setState({ modalVisible: false })}
              visible={modalVisible}
              data={detail}
              callback={params => {
                this.updateCoupon(params, 'edit')
              }}
            />
          )}
        </section>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location: { query = {} } = {} } = props
  const pageType = query.type
  return {
    coupon_id: query.coupon_id,
    pageType
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successActions: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponDetail)
