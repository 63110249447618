import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { KBPopover, KBInput } from 'components'
import { apiCallFailure } from 'app/actions'
import OrgSubscriptionAreasForm from './OrgSubscriptionAreasForm'

var OrgSubscriptionDeskListPrivateOfArea = React.createClass({
  getInitialState() {
    return {
      selectArea: {}
    }
  },

  componentDidMount() {
    const {
      desk: { price, totalRent },
      calculationDeposit
    } = this.props
    calculationDeposit({ target: { value: totalRent.value || 0 } }, totalRent)
    this.priceChange({ target: { value: price.value || 0 } })
  },

  selectAreas() {
    const {
      loc_id,
      yetAreaIds,
      endDateValue,
      startDateValue,
      contract_id,
      apiCallFailureActions
    } = this.props
    if (!endDateValue) {
      return apiCallFailureActions({
        status: 'error',
        message: '请选择终止日期'
      })
    }
    KBPopover.show(
      <OrgSubscriptionAreasForm
        type="private"
        charge_type="by_area"
        loc_id={loc_id}
        callBack={this.certainArea}
        yetAreaIds={yetAreaIds}
        contract_id={contract_id}
        startDateValue={startDateValue}
        endDateValue={endDateValue}
        isReplace={true}
      />
    )
  },
  certainArea(area) {
    const { desk, calculateDeposit, disposeOfficeAreaName } = this.props
    const {
      price,
      propertyFee,
      tenement,
      totalRent,
      area_id,
      area_name,
      price_per_m2,
      units
    } = desk
    this.setState({
      selectArea: area
    })
    area_id.onChange(area.id)
    if (area.size) {
      units.onChange(area.size)
      calculateDeposit && calculateDeposit()
      let value = area.size
      let tenementNew = 0
      let totalRentNew = 0
      if (value && propertyFee.value) {
        tenementNew = propertyFee.value * value
      }
      if (price.value) {
        totalRentNew = parseFloat(price.value) + parseFloat(tenementNew)
        price_per_m2.onChange(parseFloat(price.value / value).toFixed(2))
      }
      tenement.onChange(parseFloat(tenementNew).toFixed(2))
      totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
    }
    area_name.onChange(area.name)
    disposeOfficeAreaName && disposeOfficeAreaName()
  },

  pricePerM2Change(e) {
    let value = e.target.value
    const {
      desk: { tenement, totalRent, rent, units, price }
    } = this.props
    let priceNew = 0
    let rentNew = 0
    let totalRentNew = 0
    if (value && units.value) {
      priceNew = parseFloat(units.value * value).toFixed(2)
      totalRentNew = parseFloat(
        parseFloat(priceNew) + parseFloat(tenement.value)
      ).toFixed(2)
      rentNew = parseFloat(priceNew).toFixed(2)
    } else {
      totalRentNew = parseFloat(tenement.value).toFixed(2)
    }
    rent.onChange(parseFloat(priceNew).toFixed(2))
    price.onChange(parseFloat(priceNew).toFixed(2))
    totalRent.onChange(totalRentNew)
  },

  priceChange(e) {
    let value = e.target.value
    const {
      desk: { tenement, totalRent, rent, units, price_per_m2 }
    } = this.props
    let rentNew = 0
    let totalRentNew = 0
    let price_per_m2_new = 0
    if (value) {
      rentNew = value
      totalRentNew = parseFloat(tenement.value) + parseFloat(value)
    }
    if (value && units.value) {
      price_per_m2_new = parseFloat(value) / parseFloat(units.value)
    }
    price_per_m2.onChange(parseFloat(price_per_m2_new).toFixed(2))
    totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
    rent.onChange(rentNew)
  },

  unitsChange(e) {
    let value = e.target.value
    const {
      desk: { price, propertyFee, tenement, totalRent, price_per_m2 }
    } = this.props
    let tenementNew = 0
    let totalRentNew = 0
    let price_per_m2_new = 0
    if (value && propertyFee.value) {
      tenementNew = propertyFee.value * value
    }
    if (price.value) {
      totalRentNew = parseFloat(price.value) + parseFloat(tenementNew)
    }
    if (price.value && value) {
      price_per_m2_new = parseFloat(price.value) / parseFloat(value)
    }
    price_per_m2.onChange(parseFloat(price_per_m2_new).toFixed(2))
    tenement.onChange(parseFloat(tenementNew).toFixed(2))
    totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
  },

  perFeeChange(e) {
    const { desk } = this.props
    const { square, rent, units, tenement, totalRent, price } = desk
    let value = e.target.value
    let tenementNew = 0

    if (value && units.value) {
      tenementNew = value * units.value
    }
    if (price.value) {
      let totalRentNew = parseFloat(price.value) + parseFloat(tenementNew)
      totalRent.onChange(parseFloat(totalRentNew).toFixed(2))
    }
    tenement.onChange(parseFloat(tenementNew).toFixed(2))
  },

  render() {
    const {
      desk,
      deskList,
      index,
      calculationDeposit,
      locationSetting: { house_fee_per_m2 },
      deleteCallBack
    } = this.props
    const { selectArea } = this.state
    return (
      <li style={{ padding: '0 30px 30px 0' }}>
        <i
          className="iconfont icon-close"
          onClick={() => {
            deskList.removeField(index)
            deleteCallBack && deleteCallBack()
          }}
          style={{ top: 10, right: 0 }}
        />
        <div className="kb-row kb-form-210">
          <div
            className="select-area-btn"
            onClick={this.selectAreas}
            onMouseEnter={e => {
              this.setState({ showReplaceText: true })
              e.stopPropagation()
            }}
            onMouseLeave={e => {
              this.setState({ showReplaceText: false })
              e.stopPropagation()
            }}
          >
            <span>
              {this.state.showReplaceText
                ? '重新选择'
                : desk.area_id.value
                ? desk.area_name.value
                : '选择分区'}
            </span>
          </div>
          <div
            className="kb-form-c48 clear-fix f-left"
            style={{ paddingLeft: 30 }}
          >
            <div className="kb-form-group kb-div-c39">
              <label className="label-rt">
                <span className="must-fillR">*</span>房间价格
              </label>
              <div>
                <input
                  className="input-dollar-moth kb-input"
                  {...desk.price}
                  onChange={event => {
                    calculationDeposit(event, desk.price)
                    this.priceChange(event)
                  }}
                />
                {desk.price.touched && desk.price.error && (
                  <p className="lr-error"> {desk.price.error} </p>
                )}
                {parseFloat(desk.price.value) <
                  parseFloat(desk.lowest_price.value) && (
                  <p className="lr-warning">低于底价，需要审批</p>
                )}
              </div>
            </div>
            <div className="kb-form-66 clear-fix f-left">
              <div className="kb-form-group kb-div-c48">
                <label className="label-rt">
                  <span className="must-fillR">*</span>平米数
                </label>
                <div>
                  <input
                    disabled={true}
                    className="kb-input input-area kb-input-disabled"
                    style={{ backgroundColor: '#fff' }}
                    {...desk.units}
                    onChange={event => {
                      calculationDeposit(event, desk.units)
                      this.unitsChange(event)
                    }}
                  />
                  {desk.units.touched && desk.units.error && (
                    <p className="lr-error"> {desk.units.error} </p>
                  )}
                </div>
              </div>
              <KBInput
                disabled={true}
                isRequiredR={true}
                title="面积单价"
                className="kb-form-group kb-div-c48"
                titleClass="label-rt"
                inputClass="input-dollar-area-moth kb-input kb-input-disabled"
                field={desk.price_per_m2}
                onChange={event => {
                  calculationDeposit(event, desk.price_per_m2)
                  this.pricePerM2Change(event)
                }}
              />
            </div>
          </div>
        </div>
        {desk.area_id.touched && desk.area_id.error && (
          <p style={{ marginBottom: 20, color: '#e03641' }}>请选择分区</p>
        )}
        <div
          className="rent-box border-none"
          style={{ backgroundColor: '#fbfcfc' }}
        >
          <div className="total-rent">
            <div>
              <span style={{ marginRight: 38 }}>总会员费:</span>
              <input
                disabled={true}
                className="input-dollar-moth totalRent-input kb-input-disabled"
                {...desk.totalRent}
                style={{ color: '#d72431', textIndent: '11px', height: 36 }}
                onChange={event => {
                  calculationDeposit(event, desk.totalRent)
                }}
              />
            </div>
          </div>
          {!house_fee_per_m2 || house_fee_per_m2 == 0 ? (
            ''
          ) : (
            <div style={{ paddingLeft: 15 }}>
              <div className="color-nine" style={{ marginTop: 15 }}>
                以月为单位填写物业费与会员费
              </div>
              <div className="rent-list">
                <span style={{ width: 50, marginRight: 35 }}>会员费:</span>
                <span>¥ {parseFloat(desk.rent.value).toFixed(2)}</span>
              </div>
              <div className="rent-list">
                <span style={{ width: 50, marginRight: 35 }}>物业费:</span>
                <span style={{ minWidth: 90 }}>
                  ¥ {parseFloat(desk.tenement.value).toFixed(2)}
                </span>
                <div>
                  <span>{desk.units.value || 0}</span>
                </div>
                <span
                  style={{ marginLeft: 20, marginRight: 20, color: '#999' }}
                >
                  X
                </span>
                <div style={{ position: 'relative' }}>
                  <input
                    {...desk.propertyFee}
                    onChange={event => {
                      calculationDeposit(event, desk.propertyFee)
                      this.perFeeChange(event)
                    }}
                    className="per-free-input"
                  />
                  <span className="per-free-unit">物业费/平米</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </li>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionDeskListPrivateOfArea)
