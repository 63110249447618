import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import {
  KBLoadingContainer,
  KBPagination,
  KBDropdown,
  KBRangeDate
} from 'components'
import { KBDropSelect } from 'components/plugins'
import { connect } from 'react-redux'
import {
  queryFilter,
  getFilterAndQuery,
  paginate,
  kbCloseDropDown
} from 'utils/kbUtil'
import {
  INVOICES_STATE,
  INVOICES_UPDATE_TYPE,
  INVOICES_UPDATE_STATE,
  INVOICES_IS_REVIEWED
} from 'app/constants'
import InvoiceList from '../invoices/InvoiceList'
import moment from 'moment'
import { KBNoData } from 'components'

const DROP_DOWNS = [
  'statusDropdown',
  'invoiceTypeDropDown',
  'dateDropDown',
  'reviewedDropdown'
]

var OrgAccounting = React.createClass({
  displayName: 'OrgAccounting',
  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      invoices: null,
      pagination: null,
      filters
    }
  },
  componentDidMount() {
    const { filters } = this.props
    this.mounted = true
    this._loadData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page != nextProps.page) {
      this._loadData(nextProps.page)
    }
    if (JSON.parse(localStorage.getItem('reloadOrgInvoices')) == true) {
      localStorage.setItem('reloadOrgInvoices', false)
      this._loadData()
    }
  },

  _loadData(new_page) {
    const { org, loc_id, routerActions, query } = this.props
    const { filters } = this.state
    const { from, to } = filters
    const per_page = 10
    let pageObj = {
      per_page,
      page: new_page || 1
    }

    if (from && to) {
      filters.from = from && moment(from).format('YYYY-MM-DD')
      filters.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete filters.from
      delete filters.to
    }

    let queryFiltersStr = queryFilter({ ...query, ...filters, ...pageObj })

    this.setState({ loading: true })

    // let stage = ''
    // if(filters && filters.status){
    //   stage = filters.status
    // }else{
    //   stage = ''
    // }
    api
      .getInvoices({
        customer_type: 'org',
        customer_id: org.id,
        location_id: loc_id,
        ...filters,
        ...pageObj
      })
      .then(
        json => {
          let pagination = paginate(json.response)

          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/locations/${loc_id}/organizations/${org.id}/payments${queryFiltersStr}`
          )
          if (this.mounted) {
            this.setState({
              invoices: json.json.invoices,
              pagination
            })
          }
        },
        errors => {}
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  _toOppInfo(invoice) {
    const { routerActions, loc_id } = this.props
    if (!invoice.obfuscate) {
      routerActions.push(`/admin/locations/${loc_id}/invoices/${invoice.id}`)
    }
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })
    // force refresh
    this._refresh()
  },

  _refresh() {
    this['statusDropdown'] && this['statusDropdown'].hide()
    this['typeDropdown'] && this['typeDropdown'].hide()
    this['reviewedDropdown'] && this['reviewedDropdown'].hide()

    setTimeout(this._loadData)
  },

  _clearFilters() {
    this.setState({ filters: {} })
    this._refresh(null, {})
    this.invoiceTypeDropDown.clearData()
  },

  selectInvoiceTypeCallBack(data) {
    this._setFilter({
      invoice_type: data.map(json => json.id).join(',')
    })
  },

  changeDate(range) {
    this._setFilter(range)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  render() {
    let { invoices, pagination } = this.state
    const { loc_id, org, defaultInvoiceTypes } = this.props
    const { from, to } = this.props.filters
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    const tdStyle = {
      textAlign: 'center',
      color: '#6e6e6e',
      fontSize: '16px',
      opacity: 0.8,
      cursor: 'default'
    }
    return (
      <div className="nav-section-action-bar">
        <div className="select-progress">
          {
            <KBDropdown
              ref={ref => {
                this.statusDropdown = ref
              }}
            >
              <KBDropdown.KBDropdownTrigger>
                <div
                  className="task-status-title"
                  onClick={this.closeDropDowns.bind(null, 'statusDropdown')}
                >
                  <i className="iconfont icon-finance" />
                  <span>
                    {this.state.filters.status
                      ? INVOICES_STATE[this.state.filters.status]
                      : '选择进度'}
                  </span>
                  <span className="task-deg" />
                </div>
              </KBDropdown.KBDropdownTrigger>
              <KBDropdown.KBDropdownContent>
                <div>
                  <ul className="task-type">
                    <li
                      onClick={() => {
                        this._setFilter({ status: '' })
                      }}
                    >
                      全部
                    </li>
                    {INVOICES_UPDATE_STATE.map((state, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            this._setFilter({ status: state.id })
                          }}
                        >
                          <span>{state.name}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </KBDropdown.KBDropdownContent>
            </KBDropdown>
          }
        </div>
        {/* 类型筛选 */}
        <div className="select-progress">
          <KBDropSelect
            ref={ref => {
              this.invoiceTypeDropDown = ref
            }}
            options={{ defaultSelectPrompt: '账单类型' }}
            defaultData={INVOICES_UPDATE_TYPE}
            selectedData={defaultInvoiceTypes}
            multi={true}
            showImg={false}
            dropDown_name="invoiceTypeDropDown"
            closeClick={this.closeDropDowns}
            callback={this.selectInvoiceTypeCallBack}
            style={{ marginLeft: 0 }}
          />
        </div>
        {/* 审核状态筛选 */}
        <KBDropdown
          ref={ref => {
            this.reviewedDropdown = ref
          }}
        >
          <KBDropdown.KBDropdownTrigger>
            <div
              className="task-status-title"
              onClick={this.closeDropDowns.bind(null, 'reviewedDropdown')}
            >
              <span>
                {typeof this.state.filters.is_reviewed == 'boolean' ||
                this.state.filters.is_reviewed == ('true' || 'false')
                  ? this.state.filters.is_reviewed
                    ? '已审核'
                    : '未审核'
                  : '审核状态'}
              </span>
              <span className="task-deg" />
            </div>
          </KBDropdown.KBDropdownTrigger>
          <KBDropdown.KBDropdownContent>
            <div>
              <ul className="task-type">
                {INVOICES_IS_REVIEWED.map((state, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => this._setFilter({ is_reviewed: state.id })}
                    >
                      <span>{state.name}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </KBDropdown.KBDropdownContent>
        </KBDropdown>

        <KBRangeDate
          format="YYYY/MM/DD"
          from={from || ''}
          to={to || ''}
          ref={ref => {
            this.dateDropDown = ref
          }}
          dropDown_name="dateDropDown"
          closeClick={this.closeDropDowns}
          callback={this.changeDate}
        />

        <div style={{ marginTop: 5 }}>
          {selectFilters.length > 0 ? (
            <div className="clear-criteria">
              <i className="iconfont icon-close" />
              <span onClick={this._clearFilters}>清除所有筛选条件</span>
            </div>
          ) : null}
        </div>
        <KBLoadingContainer loading={this.state.loading}>
          <table className="content-table edit-table">
            <thead>
              <tr>
                <th>收款状态</th>
                <th>开票状态</th>
                <th style={{ width: 115 }}>账单类型</th>
                <th>公司名称</th>
                <th>应付金额</th>
                <th>待付金额</th>
                <th>到期日期</th>
                <th>实际付款日</th>
                <th>收款信息</th>
              </tr>
            </thead>
            {invoices && invoices.length ? (
              invoices.map((json, index) => {
                let obligation =
                  Math.round(json.total_amount * 100) / 100 -
                  Math.round(json.paid_amount * 100) / 100
                let isReviewed = json.is_reviewed
                return (
                  <InvoiceList
                    key={index}
                    json={json}
                    location_id={loc_id}
                    isReviewed={isReviewed}
                    obligation={obligation}
                    isTeam={true}
                  />
                )
              })
            ) : (
              <tbody>
                <tr style={{ border: 0, backgroundColor: '#ffffff' }}>
                  <td colSpan="9" style={tdStyle}>
                    <KBNoData tipMessage="暂无数据" />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <KBPagination
            pagination={pagination}
            template={`/admin/locations/${loc_id}/organizations/${org.id}/payments?page=#PAGE#`}
          />
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { currentUrl, query, location } = props
  var FILTER_STATE = [
    'status',
    'check_status',
    'from',
    'to',
    'customer_type',
    'customer_id',
    'stage',
    'is_reviewed',
    'invoice_type'
  ]
  const params = getFilterAndQuery(query, FILTER_STATE)

  let defaultInvoiceTypes = []
  if (params.filters && params.filters.invoice_type) {
    let invoice_types = params.filters.invoice_type.split(',')
    defaultInvoiceTypes = INVOICES_UPDATE_TYPE.filter(data =>
      invoice_types.find(id => id == data.id)
    )
  }

  let page = parseInt(props.query && props.query.page, 10)
  page = isNaN(page) ? 1 : page

  return {
    currentUrl,
    page,
    ...params,
    defaultInvoiceTypes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

OrgAccounting.propTypes = {
  org: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgAccounting)
