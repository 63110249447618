import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  deskActions,
  usersActions,
  subscriptionsAction,
  orgUserActions
} from 'app/actions'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBSelect,
  KBPopoverTop,
  KBTipsy,
  KBLoadingContainer
} from 'components'
import { getSubScriptionsByOrg, getAreasOfLocation } from 'app/selectors'
import { THEME_COLORS } from 'app/constants'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import _ from 'lodash'

const CURRENT_ORG_COLOR = '#2ea1f8'
const RESERVED_COLOR = 'rgb(233, 149, 98)'

var OrgMemberDeskForm = React.createClass({
  getInitialState() {
    const { selectArea, allAreas } = this.props
    let areas = selectArea.length > 0 ? selectArea : allAreas
    return {
      desks: [],
      selectDesk: null,
      areaSelect: null,
      loading: false,
      areas
    }
  },

  componentDidMount() {
    const {
      fields: { contract_id, desk_id, area_id },
      member,
      type,
      org_id,
      loc_id,
      getSubscriptionsAction,
      selectSubscription
    } = this.props

    if (selectSubscription) {
      this.contractChange(selectSubscription)
    }

    if (type == 'update') {
      contract_id.onChange(member.contract_id || '')
      area_id.onChange((member.desk && member.desk.area_id) || '')
      desk_id.onChange((member.desk && member.desk.id) || '')
      member.desk &&
        member.desk.area_id &&
        this.getDesks(member.desk.area_id, member.contract_id)
    } else {
      this.setState({
        loading: false
      })
    }
    api
      .getSubscriptions({
        organization_id: org_id,
        per_page: 100,
        location_id: loc_id,
        subscription_type: 'normal'
      })
      .then(
        json => {
          getSubscriptionsAction.success(json, { entityName: 'subscriptions' })
        },
        errors => {
          getSubscriptionsAction.failure(errors)
        }
      )
  },

  componentWillReceiveProps(nextProps) {},

  updateOrganizationMemberForm(model) {
    const {
      updateUserActions,
      member,
      callback,
      org,
      orgUserActions
    } = this.props
    const { contract_id, desk_id } = model
    return api
      .assignDeskUser(member.id, org.id, {
        contract_id,
        desk_id
      })
      .then(json => {
        const {
          json: {
            entities: { users }
          }
        } = json
        const values = Object.values(users)[0]
        let entry = {
          [member.id]: {
            ...values,
            ...values.user,
            id: values.user.id
          }
        }

        entry[member.id].desks = entry[member.id].desks.map(json => {
          json.user = values.user
          return json
        })

        json.json.entities.users = entry
        json.json.entities.orgUsers = entry
        callback && callback()
        updateUserActions.success(json)
        orgUserActions.success(json)
        this.closeWindow()
      })
  },

  closeWindow() {
    KBPopoverTop.close()
    KBPopover.close()
  },
  contractChange(sub) {
    let { sales_areas } = sub
    const {
      fields: { area_id }
    } = this.props
    let areas = []
    let areaSelect = '-1'
    sales_areas &&
      sales_areas.length > 0 &&
      sales_areas.map(area => {
        if (area.area_id && area.area_name) {
          areas.push({
            area_id: area.area_id,
            area_name: area.area_name
          })
        }
      })
    if (areas.length == 0) {
      areas = []
    }

    if (areas.length) {
      let areaId = areas[0].area_id
      area_id.onChange(areaId)
      areaSelect = areaId
      this.getDesks(areaId, sub.id)
    }

    this.setState({ desks: [], areaSelect, areas })
  },

  areaChange(e) {
    let areaId = e.target.value
    const {
      loc_id,
      getDesksActions,
      fields: { area_id }
    } = this.props

    if (areaId === '-1') {
      this.setState({
        desks: [],
        areaSelect: '-1'
      })
      return
    }

    area_id.onChange(areaId)
    this.getDesks(areaId)
  },

  getDesks(id, contractId) {
    const {
      loc_id,
      getDesksActions,
      fields: { area_id, contract_id }
    } = this.props
    let areaId = id || area_id.value
    let contractIdNew = contractId || contract_id.value
    this.setState({
      loading: true
    })
    api
      .getDesks({
        location_id: loc_id,
        area_id: areaId,
        per_page: 500,
        includes: 'users'
      })
      .then(
        json => {
          getDesksActions.success(json)
          let allDesks = _.cloneDeep(getEntitiesJsonArray(json, 'desks'))
          allDesks = allDesks.filter(desk_a => {
            return desk_a.contract && desk_a.contract.id == contractIdNew
          })
          this.setState({ desks: allDesks, areaSelect: areaId })
        },
        error => {
          getDesksActions.failure(error)
          return Promise.reject(error && error.body)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  isEmptyDesk(desk) {
    const { state, contract_type } = desk
    if (state == 'reserved') {
      return true
    }
    if (state == 'free') {
      return true
    }

    return false
  },

  selectDesk(desk) {
    const { color, state } = desk
    const {
      fields: { desk_id }
    } = this.props

    if (color && !this.isEmptyDesk(desk)) {
      return
    }

    const deskObj = Object.assign({}, desk)
    deskObj.color = CURRENT_ORG_COLOR
    this.setState({
      selectDesk: deskObj
    })
    desk_id.onChange(deskObj.id)
  },
  renderDeskColor() {
    const { desks, selectDesk } = this.state
    var deskArray = Object.assign([], desks)

    deskArray = deskArray.map(desk => {
      if (!this.isEmptyDesk(desk)) {
        desk.color = RESERVED_COLOR
      }
      return desk
    })

    const desk_num_style = {
      maxWidth: 40,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }

    if (!deskArray.length) {
      return <p>暂时没有工位可以入驻，请检查员工数和合同</p>
    }

    return deskArray.map((desk, index) => {
      return (
        <KBTipsy key={index} content={desk.serial_number}>
          <div
            className={`station-number ${
              this.isEmptyDesk(desk) ? 'border' : 'station-select'
            } ${desk.class ? desk.class : ''}`}
            style={{
              backgroundColor:
                selectDesk && selectDesk.id == desk.id
                  ? selectDesk.color
                  : desk.color
                  ? desk.color
                  : ''
            }}
            onClick={this.selectDesk.bind(null, desk)}
          >
            <div className="kb-lr-center">
              <span style={desk_num_style}>{desk.serial_number}</span>
              {<i className="iconfont icon-people" />}
            </div>
          </div>
        </KBTipsy>
      )
    })
  },

  render() {
    const {
      fields: { contract_id, desk_id, area_id },
      error,
      submitting,
      handleSubmit,
      member,
      org,
      subscriptions,
      selectSubscription
    } = this.props
    const { areaSelect, areas, loading } = this.state
    return (
      <form onSubmit={handleSubmit(this.updateOrganizationMemberForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">会员入驻</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={this.closeWindow}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row">
            <div className="kb-form-group kb-form-c18 kb-form-alone">
              <label>公司成员</label>
              <div className="group-form-content">
                <span>{member.name}</span>
              </div>
            </div>
          </div>
          <div className="kb-row">
            <div className="kb-form-group kb-form-c18 kb-form-alone">
              <label>公司名称</label>
              <div className="group-form-content">
                <span>{org.name}</span>
              </div>
            </div>
          </div>
          <div className="kb-row">
            <KBUserInput
              placeholder="选择合同"
              className="kb-form-group kb-div-c18 kb-form-alone"
              title="合同"
              callback={this.contractChange}
              selectedUsers={[selectSubscription]}
              users={subscriptions}
              multi={false}
              field={contract_id}
            />
          </div>
          {contract_id.value ? (
            <div>
              <div className="kb-row">
                <div className="kb-form-group kb-div-c18 kb-form-alone">
                  <label>分区</label>
                  <div>
                    <select
                      name="customer_type"
                      onChange={this.areaChange}
                      value={areaSelect}
                    >
                      <option value="-1">请选择</option>
                      {areas &&
                        areas.length > 0 &&
                        areas.map((area, index) => (
                          <option key={index} value={area.area_id || area.id}>
                            {area.area_name || area.name}
                          </option>
                        ))}
                    </select>
                    {area_id.touched && area_id.error && (
                      <p className="lr-error">{area_id.error}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="station-show" style={{ paddingBottom: 30 }}>
                <KBLoadingContainer loading={loading}>
                  <div className="clear-fix">
                    {this.renderDeskColor()}
                    {desk_id.touched && desk_id.error && (
                      <p className="lr-error">{desk_id.error}</p>
                    )}
                  </div>
                </KBLoadingContainer>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={this.closeWindow}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              完成
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

OrgMemberDeskForm.propTypes = {
  member: PropTypes.object.isRequired,
  org: PropTypes.object.isRequired
}

function mapStateToProps(state, props) {
  const { member, org, loc_id, type } = props
  var subscriptions = Object.assign([], getSubScriptionsByOrg(state, org.id))
  var allAreas = getAreasOfLocation(state, loc_id)
  let selectSubscription = null
  let selectArea = []

  subscriptions = subscriptions.filter(subscription => {
    return (
      subscription.status != 'cancelled' &&
      subscription.status != 'ended' &&
      subscription.status != 'approving' &&
      subscription.status != 'rejected'
    )
  })

  subscriptions = subscriptions.map(subscription => {
    subscription.name = `合同 (编号 《${subscription.serial}》, ${subscription.units}人 剩余${subscription.remain_units}人)`
    subscription.disabled = false
    return subscription
  })

  selectSubscription = subscriptions.length ? subscriptions[0] : null
  return {
    org_id: org.id,
    loc_id,
    subscriptions,
    member,
    selectSubscription,
    selectArea,
    allAreas
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateUserActions: bindActionCreators(usersActions.update, dispatch),
    getDesksActions: bindActionCreators(deskActions.get, dispatch),
    orgUserActions: bindActionCreators(orgUserActions.update, dispatch),
    deleteDeskActions: bindActionCreators(deskActions.delete, dispatch),
    getSubscriptionsAction: bindActionCreators(
      subscriptionsAction.replace,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.contract_id)) {
    errors.contract_id = '请选择合同编号'
  }

  if (valid.required(values.desk_id)) {
    errors.desk_id = '请选择工位号'
  }

  if (valid.required(values.area_id)) {
    errors.area_id = '请选择分区号'
  }

  return errors
}

const formConfig = {
  form: 'OrgMemberDeskForm',
  fields: ['contract_id', 'desk_id', 'area_id'],
  validate: validate,
  touchOnBlur: false,
  initialValues: {
    gender: 'female'
  }
}

OrgMemberDeskForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgMemberDeskForm)

export default OrgMemberDeskForm
