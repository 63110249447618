import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Link } from 'react-router'
import { TASK_SECTIONS } from 'app/constants'

class TaskBox extends Component {
  render() {
    let { tasks_type, location_id } = this.props
    tasks_type = tasks_type || 'my'
    let pathname = '/admin/tasks/repair'
    if (location_id) {
      pathname = `/admin/locations/${location_id}/tasks/repair`
    }
    return (
      <section className="kb-content" style={{ paddingTop: 0 }}>
        <nav className="nav-container f-left">
          <div className="nav-container-content">
            <header className="nav-header clear-fix">
              <h2 className="header-title f-left">报修</h2>
            </header>
            <div className="nav-body">
              <ul className="nav-container-list">
                {Object.keys(TASK_SECTIONS).map(key => {
                  let menu_item = TASK_SECTIONS[key]
                  let active = key === tasks_type
                  return (
                    <li
                      key={key}
                      className={classNames(
                        active
                          ? 'nav-container-list-hover select-list-hover'
                          : ''
                      )}
                    >
                      <Link to={`${pathname}/type/${key}`}> {menu_item} </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </nav>
        {this.props.children}
      </section>
    )
  }
}

function mapStateToProps(state, props) {
  let sections = props.location.pathname.split('/')
  let tasks_type = sections[sections.length - 1]
  let location_id = (props.params && props.params.id) || null
  return {
    tasks_type,
    location_id
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskBox)
