import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import * as actions from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBPopover,
  KBTipsy,
  KBTableWithoutData
} from 'components'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import { REPORT_LINK_STATE } from 'app/constants'
import { paginate, formatMinuteSecondEN } from 'utils/kbUtil'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import moment from 'moment'
import * as constant from 'app/constants'
import { getUserInfoUrl } from 'utils/kbUrl'
import config from 'app/config'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy';

var Report = React.createClass({
  displayName: 'Report',

  getInitialState() {
    return {}
  },

  render() {
    const { reports, user } = this.props
    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>报表列表</span>
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          <p style={{ marginTop: 15, marginBottom: 15 }}>下载系统生成的报表</p>
          <div className="kb-tip-warning">
            <div className="icon">
              <i className="iconfont icon-hint" />
            </div>
            <div className="msg">
              如果报表数据没有即时更新状态，请等待5-10分钟后刷新当前页面以获取最新状态
            </div>
          </div>
          <table className="content-table edit-table">
            <thead>
              <tr>
                <th>名称</th>
                <th>下载</th>
                <th>状态</th>
                <th>创建者</th>
                <th style={{ textAlign: 'right' }}>创建时间</th>
              </tr>
            </thead>
            <KBTableWithoutData
              hasData={reports.length > 0}
              tableHeadNum="5"
              tipMessage="暂无报表"
            >
              {reports &&
                reports.map((report, index) => {
                  let names = (report.name && report.name.split(' ')) || []
                  let reportTitle = names[0] || '无'
                  names.shift()
                  let reportDes = (names.length && names.join(' ')) || ''
                  return (
                    <tr
                      key={index}
                      style={{ background: '#fff', cursor: 'default' }}
                    >
                      <td>
                        <div
                          style={{
                            maxWidth: 350,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <img
                            src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_excel@3x.png"
                            style={{
                              width: '18px',
                              marginRight: '15px',
                              verticalAlign: 'bottom'
                            }}
                            alt=""
                          />
                          <span>
                            <div
                              style={{
                                verticalAlign: 'top',
                                whiteSpace: 'normal',
                                color: '#333'
                              }}
                            >
                              {reportTitle}
                            </div>
                            {reportDes ? (
                              <div
                                style={{
                                  verticalAlign: 'top',
                                  whiteSpace: 'normal',
                                  color: '#666'
                                }}
                              >
                                {reportDes}
                              </div>
                            ) : null}
                          </span>
                        </div>
                      </td>
                      <td>
                        {report.state === 'active' ? (
                          report.download_url ?
                          (
                            <KBDownloadPrivacy url={report.download_url}>
                              <a
                                className="app-module-url">
                                <img
                                  src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_down@3x.png"
                                  alt="点击下载"
                                  style={{ width: '18px' }}
                                />
                              </a>
                            </KBDownloadPrivacy>
                          ) :
                          (
                            <a
                            onClick={() => downloadReports(report, user)}
                            className="app-module-url">
                            <img
                              src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_down@3x.png"
                              alt="点击下载"
                              style={{ width: '18px' }}
                            />
                          </a>
                          )
                        ) : (
                          '无'
                        )}
                      </td>
                      <td className="report_default">
                        <span
                          className={`report_${report.state}`}
                          style={{ minWidth: 64, textAlign: 'center' }}
                        >
                          {REPORT_LINK_STATE[report.state]}
                        </span>
                      </td>
                      <td>
                        <div className="vf-center">
                          <KBAvatar
                            style={{
                              display: 'inline-block',
                              marginRight: '5px'
                            }}
                            imgStyle={{ marginRight: 5 }}
                            user={report.creator}
                            size={25}
                          />
                          <Link
                            className="color-link"
                            to={getUserInfoUrl(
                              report.creator && report.creator.id
                            )}
                          >
                            {(report.creator && report.creator.name) || ''}
                          </Link>
                        </div>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {formatMinuteSecondEN(report.created_at)}
                      </td>
                    </tr>
                  )
                })}
            </KBTableWithoutData>
          </table>
        </div>
      </section>
    )
  }
})

const downloadReports = (report, user) => {
  const filename = `${report.name}.csv`
  const xhr = new XMLHttpRequest()
  xhr.open('GET', `${config.urlScheme}://${config.url}/managements/v2/reports/${report.id}/download`, true)
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.setRequestHeader('Authorization', `Bearer ${user.jwt_token}`)
  xhr.responseType = 'blob'
  xhr.onload = function (res) {
    if (this.status === 200) {
      let type = xhr.getResponseHeader('Content-Type')
      let blob = new Blob([this.response], { type: type })
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        /*
         * For IE
         * >=IE10
         */
        window.navigator.msSaveBlob(blob, filename)
      } else {
        /*
         * For Non-IE (chrome, firefox)
         */
        let URL = window.URL || window.webkitURL
        let objectUrl = URL.createObjectURL(blob)
        if (filename) {
          let a = document.createElement('a')
          if (typeof a.download === 'undefined') {
            window.location = objectUrl
          } else {
            a.href = objectUrl
            a.download = filename
            document.body.appendChild(a)
            a.click()
            a.remove()
          }
        } else {
          window.location = objectUrl
        }
      }
    }
  }
  xhr.send()
}

function mapStateToProps(state, props) {
  let reports = selectors.getReports(state)
  let user = selectors.getCurrentUser(state)
  return {
    reports,
    user
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)
