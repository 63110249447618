import React, { Component, PropTypes } from 'react'
import { apiCallFailure } from 'app/actions'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBButton,
  KBPopover,
  KBForm,
  KBPopoverTop,
  KBRadio
} from 'components'
import { getServiceErrorMessage } from 'utils/kbUtil'

const TITLE_TYPES = [
  {
    title: '增值税普通发票',
    value: 'common'
  },
  {
    title: '增值税专用发票',
    value: 'special'
  }
]

var InvoiceTitleForm = React.createClass({
  getInitialState() {
    const { isContract, isInvoiceApp } = this.props
    return {
      isTop: isContract || isInvoiceApp
    }
  },

  componentDidMount() {
    const {
      fields: { isSpecial, is_contract },
      is_special,
      isContract
    } = this.props
    isSpecial.onChange(is_special)
    is_contract.onChange(isContract)
  },

  createInvoiceTitle(model) {
    delete model.isSpecial
    const {
      type,
      location_id,
      sales_customer_id,
      callBack,
      apiCallFailureActions,
      invoiceTitle
    } = this.props
    const { isTop } = this.state

    if (type === 'update') {
      return api
        .putInvoiceTitle(invoiceTitle.id, {
          ...model,
          sales_customer_id,
          location_id,
          invoice_type: model.invoice_title_type
        })
        .then(
          ({ json }) => {
            callBack && callBack(json)
            if (isTop) {
              return KBPopoverTop.close()
            }
            KBPopover.close()
          },
          error => {
            const errorMessage = getServiceErrorMessage(error)
            errorMessage &&
              apiCallFailureActions({
                status: 'error',
                message: errorMessage
              })
          }
        )
    }
    return api
      .postInvoiceTitle({
        ...model,
        sales_customer_id,
        location_id,
        invoice_type: model.invoice_title_type
      })
      .then(
        ({ json }) => {
          callBack && callBack(json)
          if (isTop) {
            return KBPopoverTop.close()
          }
          KBPopover.close()
        },
        error => {
          const errorMessage = getServiceErrorMessage(error)
          errorMessage &&
            apiCallFailureActions({
              status: 'error',
              message: errorMessage
            })
        }
      )
  },

  render() {
    const {
      fields: {
        title,
        tax_num,
        bank,
        bank_account,
        contacts_name,
        phone_num,
        address,
        email,
        isSpecial,
        invoice_title_type
      },
      handleSubmit,
      submitting,
      type,
      isContract
    } = this.props
    const { isTop } = this.state
    const is_special = isSpecial.value || invoice_title_type.value === 'special'
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.createInvoiceTitle)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">
            {type === 'update' ? '修改' : '添加'}发票抬头
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={isTop ? KBPopoverTop.close : KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          {
            <div className="kb-row">
              <KBRadio
                className="kb-form-group kb-form-c18 kb-form-alone"
                inClassName="kb-from-initial"
                divClassName="kb-from-radio"
                isRequired={true}
                title="发票类型"
                arrayType={TITLE_TYPES}
                name="title_type"
                field={invoice_title_type}
              />
            </div>
          }
          {!isContract || invoice_title_type.value ? (
            <div>
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={title}
                  title="发票抬头"
                  isRequired={true}
                />
              </div>
              <div className="kb-row">
                <KBInput
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  field={tax_num}
                  title="税号"
                  isRequired={true}
                />
              </div>
              <div className="kb-row kb-form-66">
                <KBInput
                  className="kb-form-group kb-form-c39"
                  field={bank}
                  title="开户行"
                  isRequired={is_special}
                />
                <KBInput
                  className="kb-form-group kb-form-c48"
                  field={bank_account}
                  title="银行账号"
                  titleClass="label-rt"
                  isRequired={is_special}
                />
              </div>
              <div className="kb-row kb-form-66">
                <KBInput
                  className="kb-form-group kb-form-c39"
                  field={contacts_name}
                  title="联系人"
                  isRequired={is_special}
                />
                <KBInput
                  className="kb-form-group kb-form-c48"
                  field={phone_num}
                  title="电话"
                  titleClass="label-rt"
                  isRequired={is_special}
                />
              </div>
              <div className="kb-row kb-form-66">
                <KBInput
                  className="kb-form-group kb-form-c39"
                  field={address}
                  title="地址"
                  isRequired={is_special}
                />
                <KBInput
                  className="kb-form-group kb-form-c48"
                  field={email}
                  title="邮箱"
                  titleClass="label-rt"
                  isRequired={is_special}
                />
              </div>
            </div>
          ) : null}
          <div className="kb-row fixed-form-btn">
            <span
              className="cancel-btn"
              onClick={isTop ? KBPopoverTop.close : KBPopover.close}
            >
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

InvoiceTitleForm.PropTypes = {
  location_id: PropTypes.number.isRequired,
  sales_customer_id: PropTypes.number.isRequired,
  callBack: PropTypes.func.isRequired,
  invoiceTitle: PropTypes.object,
  type: PropTypes.string
}

function mapStateToProps(state, props) {
  const { invoiceTitle } = props

  return {
    initialValues: invoiceTitle ? invoiceTitle : {}
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}
  const is_special = values.isSpecial || values.invoice_title_type === 'special'

  if (values.is_contract && valid.required(values.invoice_title_type)) {
    errors.invoice_title_type = '请选择您要添加的发票类型'
  }

  if (valid.required(values.title)) {
    errors.title = '请输入发票抬头'
  }
  if (valid.required(values.tax_num)) {
    errors.tax_num = '请输入税号'
  }
  if (valid.numberAndLetter(values.tax_num)) {
    errors.tax_num = '请输入正确格式'
  }
  if (is_special && valid.required(values.bank)) {
    errors.bank = '请输入开户行'
  }
  if (is_special && valid.required(values.bank_account)) {
    errors.bank_account = '请输入银行账号'
  }
  if (values.bank_account && valid.integer(values.bank_account)) {
    errors.bank_account = '请输入正确格式'
  }
  if (is_special && valid.required(values.contacts_name)) {
    errors.contacts_name = '请输入联系人姓名'
  }
  if (is_special && valid.required(values.phone_num)) {
    errors.phone_num = '请输入电话'
  }
  if (values.phone_num && valid.commonPhoneNumber(values.phone_num)) {
    errors.phone_num = '请输入正确的电话'
  }
  if (is_special && valid.required(values.address)) {
    errors.address = '请输入地址'
  }
  if (is_special && valid.required(values.email)) {
    errors.email = '请输入邮箱'
  }
  if (values.email && valid.email(values.email)) {
    errors.email = '请输入正确的邮箱'
  }

  return errors
}

const formConfig = {
  form: 'InvoiceTitleForm',
  fields: [
    'is_contract',
    'invoice_title_type',
    'title',
    'tax_num',
    'bank',
    'bank_account',
    'contacts_name',
    'phone_num',
    'address',
    'email',
    'isSpecial'
  ],
  validate: validate,
  touchOnBlur: false
}

InvoiceTitleForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(InvoiceTitleForm)

export default InvoiceTitleForm
