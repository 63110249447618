import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import ReactDOM from 'react-dom'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import { reservationsActions, successState, apiCallFailure } from 'app/actions'
import * as selectors from 'app/selectors'
import {
  KBLoadingContainer,
  KBButton,
  KBDropdown,
  KBPopover,
  KBTipsy,
  KBStatisticNumber
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import {
  MEETING_COLORS,
  AREATYPES_CLASS,
  RESERVATION_PROGRESS_STATUS,
  RESERVATION_PROGRESS_CLASS
} from 'app/constants'
import {
  getInitialDate,
  MEETING_TIME_COUNT,
  MEETING_DEFAULT_COUNT,
  MEETING_START_TIME,
  MEETING_END_TIME,
  MEETING_DEFAULT_GRID_WIDTH
} from 'utils/kbData'
import moment from 'moment'
import MeetingBookingForm from './MeetingBookingForm'
import MeetingSearchRoomForm from './MeetingSearchRoomForm'
import MeetingPopup from './MeetingPopup'
import MeetingForm from './MeetingForm'
import MeetingRoomReportForm from './MeetingRoomReportForm'
import { KBDayPicker } from 'components/views'
import { canAccessLocation } from 'app/reducers/role'
import { percent, getEntitiesJsonArray } from 'utils/kbUtil'

const DISABLED_COLOR = 'rgb(230,230,230)'

var MeetingIndex = React.createClass({
  displayName: 'MeetingIndex',

  getInitialState() {
    const { time, defaultDate } = this.props
    return {
      loading: false,
      meetingInitDate: defaultDate,
      rows: this.getRows(this.getMeetingInitData(defaultDate)),
      searchTime: time,
      current_time: '',
      isInitialScroll: true,
      isShowMeetingPopup: false,
      current_reservation: {}
    }
  },

  componentDidMount() {
    const { appointment, time } = this.props
    let initialDate = getInitialDate(time)
    const { initStartDate, initEndDate } = initialDate
    let start_at = initStartDate.format('YYYY-MM-DD HH:mm:ss')
    let end_at = initEndDate.format('YYYY-MM-DD HH:mm:ss')
    this._loadData({ start_at, end_at })

    this.mounted = true

    if (appointment) {
      setTimeout(() => {
        this.searchMeetingRoom()
      }, 200)
    }

    this.initialScrollLeft()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  cancelBooking(reservation) {
    const { deleteReservationsAction } = this.props
    let id = reservation.id
    let tip = '确定要取消该次会议?'
    if(reservation.has_discount_goods) {
      tip = '此订单包含加购项，取消仅退会议室预定费用，操作前请与客户确认已经知晓退款内容，请确认是否取消该次会议？'
    }
    KBPopoverConfirm({
      name: '取消会议',
      context: tip,
      callback: () => {
        return api.deleteReservations(id).then(
          json => {
            deleteReservationsAction.success(json, { id, key: 'reservations' })
            KBPopover.close()
            this.setState({ isShowMeetingPopup: false })
          },
          errors => {
            deleteReservationsAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  /**
   * 获取会议室初始化参数
   */
  getMeetingInitData(defaultDate) {
    let currentMoment = moment()
    let initialDate = defaultDate
    // 当前时间距离9点半 minutes
    let minutes = currentMoment.diff(initialDate.initStartDate, 'minutes')
    if (minutes < 0) {
      minutes = 0
    }
    return {
      minutes
    }
  },

  componentWillReceiveProps(nextProps) {
    const { isInitialScroll } = this.state
    if (
      moment(this.props.time).format('YYYY-MM-DD') !=
      moment(nextProps.time).format('YYYY-MM-DD')
    ) {
      let initialDate = getInitialDate(nextProps.time)
      const { initStartDate, initEndDate } = initialDate
      let start_at = initStartDate.format('YYYY-MM-DD HH:mm:ss')
      let end_at = initEndDate.format('YYYY-MM-DD HH:mm:ss')
      this.setState({
        searchTime: nextProps.time,
        loading: true,
        meetingInitDate: initialDate,
        rows: this.getRows(this.getMeetingInitData(initialDate))
      })
      this._loadData({
        start_at,
        end_at
      })
    }
    if (this.meetingScroll && isInitialScroll) {
      this.initialScrollLeft()
    }
  },

  _loadData(params) {
    const { getReservationsAction, loc_id } = this.props
    // 获取会议室预定列表
    api
      .getReservations({
        location_id: loc_id,
        per_page: 100,
        ...params,
        state: 'reserved_active'
      })
      .then(
        json => {
          getReservationsAction.success(json)
        },
        errors => {
          getReservationsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
          isShowMeetingPopup: false
        })
      })
  },

  cancelMeeting(reservation) {
    const { apiCallFailureActions } = this.props
    let id = reservation.id

    KBPopoverConfirm({
      name: '结束会议室预定',
      context: `是否结束当前${reservation.area_name || '会议室'}预定?`,
      callback: () => {
        if (moment(reservation.start_at).diff(new Date(), 'hours', true) >= 0) {
          KBPopover.close()
          apiCallFailureActions({
            status: 'error',
            message: '会议还没开始，不能终止!'
          })
          return
        }
        //getOverTimeInfo
        return api.getOverTimeInfo(id).then(({ json }) => {
          KBPopover.close()
          this.setState({ isShowMeetingPopup: false })
          let terminate_at = json.terminate_at
          let total_amount = json.total_amount
          let end_at = reservation.end_at
          if (!moment(end_at).isSame(terminate_at, 'day')) {
            terminate_at = moment(end_at)
              .clone()
              .set({ hour: 19, minute: 0, second: 0 })
          }

          if (total_amount > 0) {
            this.chooseMeetingMode(
              reservation,
              true,
              terminate_at,
              total_amount
            )
            return
          }

          this.overMeeting(reservation, false, terminate_at)
        })
      }
    })
  },

  overMeeting(reservation, isMakeInvoice = false, terminate_at) {
    const { successAction, updateReservationsActions } = this.props
    if (!terminate_at) {
      return
    }

    let id = reservation.id
    api
      .putTerminate(id, {
        make_invoice: isMakeInvoice,
        terminate_at
      })
      .then(
        json => {
          updateReservationsActions.success(json)
          successAction({
            message: `成功结束当前会议室预定${
              isMakeInvoice ? '并生成账单' : ''
            }!`
          })
        },
        error => {
          updateReservationsActions.failure(error)
        }
      )
      .finally(() => {
        KBPopover.close()
      })
  },

  chooseMeetingMode(
    reservation,
    isMakeInvoice = false,
    terminate_at,
    total_amount
  ) {
    KBPopover.show(
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">生成账单</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            {`当前预定会议室超时将生成金额为${total_amount}(RMB)的账单，是否生成此账单？`}
          </div>
          <div className="kb-row">
            <div className="kb-form-fr">
              <KBButton
                className="certain-btn"
                onClick={this.overMeeting.bind(
                  null,
                  reservation,
                  isMakeInvoice,
                  terminate_at
                )}
              >
                立即生成账单
              </KBButton>
              <KBButton
                className="certain-btn"
                onClick={this.overMeeting.bind(
                  null,
                  reservation,
                  false,
                  terminate_at
                )}
              >
                取消生成账单
              </KBButton>
            </div>
          </div>
        </div>
      </div>
    )
  },

  renderDateTime() {
    const { time, reservation_time_range } = this.props
    let current_date = moment().format('YYYY-MM-DD')
    let select_date = moment(time).format('YYYY-MM-DD')

    var current_time_left
    var current_time = moment().minute()
    var remainder = current_time - 30
    if (remainder > 0) {
      current_time = current_time - 30
      remainder = 1
    } else {
      remainder = 0
    }
    var hour_count = (moment().hour() - reservation_time_range.begin) * 2
    current_time_left =
      Math.ceil((current_time / 30) * 33) + remainder * 33 + hour_count * 33

    return (
      <div className="timeline-box">
        {select_date == current_date ? (
          <div
            className="current-time"
            style={{ left: current_time_left }}
          ></div>
        ) : (
          ''
        )}
        {this.meetingRoomsTimeJsx()}
        {this.meetingRoomsJsx()}
      </div>
    )
  },

  devicesJsx(devices) {
    if (!devices.length) {
      return <div className="meet-device"></div>
    }

    let whiteboard = devices.find(device => device && device.id == 1)
    let tv = devices.find(device => device && device.id == 2)
    let camera = devices.find(device => device && device.id == 3)
    return (
      <div className="meet-device">
        {tv ? <i className="iconfont icon-tv" /> : ''}
        {whiteboard ? <i className="iconfont icon-Whiteboard" /> : ''}
        {camera ? <i className="iconfont icon-camera" /> : ''}
        <i />
      </div>
    )
  },
  // 解析时间
  parseMinutes(minutes) {
    // 有多少个不可选择项
    let d = Math.floor(minutes / 30)
    // 取余数
    let c = minutes % 30

    return {
      d,
      c
    }
  },

  getRows(meetingInitDate) {
    // progress progressDisabled full empty disabled
    const {
      reservation_time_range: { begin, end },
      setting_reservation_time
    } = this.props
    var defaultGrid = {
      state: 'empty',
      width: 0
    }
    var rows = []
    let minutes = meetingInitDate.minutes

    const { d, c } = this.parseMinutes(minutes)
    for (let i = begin * 2; i < end * 2; i++) {
      var grid = Object.assign({}, defaultGrid)
      if (
        i / 2 < setting_reservation_time.begin ||
        i / 2 >= setting_reservation_time.end
      ) {
        grid.state = 'none'
      } else {
        if (i < d) {
          grid.state = 'disabled'
        }
        if (c > 0 && d + 1 == i) {
          grid.state = 'progressDisabled'
          grid.width = Math.ceil((c / 30) * 33)
        }
      }
      grid.id = i
      rows.push(grid)
    }

    return rows
  },

  addMeetingBooking(meeting, start_at) {
    const {
      defaultDate,
      isManager,
      reservation_time_range,
      setting_reservation_time
    } = this.props
    const { loc_id } = this.props
    let end_at = moment(start_at)
      .clone()
      .add(1, 'hours')
    let END_AT = moment(start_at).set({
      hour: reservation_time_range.end,
      minute: 0,
      second: 0
    })
    if (end_at.diff(END_AT, 'minutes') > 0) {
      end_at = moment(END_AT).clone()
    }
    if (this.mounted) {
      this.setState({
        rows: this.getRows(this.getMeetingInitData(defaultDate))
      })
    }

    isManager &&
      KBPopover.show(
        <MeetingBookingForm
          end_at={end_at}
          start_at={start_at}
          reservation_time_range={setting_reservation_time}
          initialValues={{
            end_at,
            start_at
          }}
          meeting={meeting}
          loc_id={loc_id}
        />
      )
  },

  getRowsEveryone(meetingInitDate, meeting) {
    const { reservation_time_range = [] } = meeting
    let defaultGrid = {
      state: 'empty',
      width: 0
    }
    let minutes = meetingInitDate.minutes
    const { d, c } = this.parseMinutes(minutes)
    const currentDay = moment(this.state.searchTime).day() ? moment(this.state.searchTime).day() : 7
    let currentReservation = reservation_time_range[0]
    if (reservation_time_range.length > 1)
      currentReservation = reservation_time_range.filter(
        item => item.day == currentDay
      )[0]
    const { begin, end } = currentReservation

    let row = []

    for (let i = 0; i < 48; i++) {
      let grid = Object.assign({}, defaultGrid)
      if (i * 30 < begin || i * 30 >= end) {
        grid.state = 'none'
      } else {
        if (i < d) {
          grid.state = 'disabled'
        }
        if (c > 0 && d + 1 == i) {
          grid.state = 'progressDisabled'
          grid.width = Math.ceil((c / 30) * 33)
        }
      }
      if(currentReservation.nowork){
        grid.state = 'none'
      }
      grid.id = i
      row.push(grid)
    }
    return row
  },

  rowsJsx(meeting) {
    // let { rows } = this.state
    const { defaultDate } = this.props
    const rows = this.getRowsEveryone(this.getMeetingInitData(defaultDate), meeting)
    let jsx = rows.map((grid, index) => {
      let start_at = defaultDate.initStartDate
        .clone()
        .add(grid.id * 30, 'minutes')
      if (grid.state == 'none') {
        return (
          <div key={index} className="time-grid meeting-block_disabled"></div>
        )
      }

      if (grid.state == 'disabled') {
        return <div key={index} className="time-grid past-tense"></div>
      }

      if (grid.state == 'progressDisabled') {
        return (
          <div
            key={index}
            className="time-grid"
            onClick={() => {
              this.addMeetingBooking(meeting, start_at)
            }}
          ></div>
        )
      }

      return (
        <div
          key={index}
          className="time-grid"
          onClick={() => {
            this.addMeetingBooking(meeting, start_at)
          }}
        ></div>
      )
    })
    return jsx
  },

  progressDisabledJsx() {
    let rows = Object.assign([], this.state.rows)

    let jsx = rows.map((grid, index) => {
      if (grid.state == 'progressDisabled') {
        let left = 33 * (index - 1)
        let width = grid.width
        return (
          <div
            key={index}
            className="meeting-status"
            style={{ left, width, backgroundColor: DISABLED_COLOR, opacity: 1 }}
          ></div>
        )
      }

      return null
    })

    return jsx
  },

  parseReservations(reservations) {
    const {
      defaultDate,
      reservation_time_range: { begin }
    } = this.props

    reservations = reservations.map((reservation, index) => {
      let startMoment = defaultDate.initStartDate
      let startReservation = moment(reservation.start_at)
      let endReservation = moment(reservation.end_at)
      let minutes = startReservation.diff(startMoment, 'minutes')
      let minutesEnd = endReservation.diff(startReservation, 'minutes')
      const { d, c } = this.parseMinutes(minutes)
      const minutesDate = this.parseMinutes(minutesEnd)

      reservation.left = 33 * (d - begin * 2) + Math.ceil((c / 30) * 33)
      reservation.width =
        minutesDate.d * 33 + Math.ceil((minutesDate.c / 30) * 33)
      return reservation
    })
    return reservations
  },

  hideOtherDropDown(currentIndex, id) {
    const { meetings } = this.props

    meetings.forEach(meeting => {
      meeting.reservations.forEach((reservation, index) => {
        if (reservation && reservation.id == id && index == currentIndex) {
          return
        }

        this.refs['KBRoomDropDwon' + reservation.id + index].hide()
      })
    })
  },

  showCurrentDropDown(index, meetingIndex, reservation) {
    let meetingStatusClientLeft = this.refs[
      'meetingStatus' + reservation.id + index
    ].getBoundingClientRect().left
    let containerClientLeft = this.refs['container'].getBoundingClientRect()
      .left
    let distanceLeft = meetingStatusClientLeft - containerClientLeft - 30
    let current_reservation = Object.assign(
      {},
      reservation,
      { distanceLeft: distanceLeft },
      { meetingIndex: meetingIndex + 1 }
    )
    this.setState({
      current_reservation,
      isShowMeetingPopup: true
    })
  },

  progressJsx(meeting, meetingIndex) {
    let reservations = this.parseReservations(
      Object.assign([], meeting.reservations)
    )

    let jsx = reservations.map((reservation, index) => {
      const { left, width } = reservation
      return (
        <div
          key={index}
          className="meeting-status"
          ref={`meetingStatus${reservation.id}${index}`}
          style={{ width, left, backgroundColor: reservation.color, zIndex: 9 }}
          onClick={() => {
            this.showCurrentDropDown(index, meetingIndex, reservation)
          }}
        ></div>
      )
    })

    return jsx
  },

  // 渲染会议室日期
  meetingRoomsJsx() {
    const { meetings } = this.props
    const meetingList = meetings
    const render =
      meetingList &&
      meetingList.map((meeting, index) => {
        return (
          <div className="line-box" key={index}>
            {this.rowsJsx(meeting)}
            {this.progressJsx(meeting, index)}
            {this.progressDisabledJsx(meeting)}
          </div>
        )
      })

    return render
  },

  //渲染会议室
  meetingRoomsTitleJsx() {
    const { meetings, reservations, loc_id } = this.props
    const meetingList = meetings
    const render =
      meetingList &&
      meetingList.map((meeting, index) => {
        return (
          <div className="line-meet" key={index}>
            <div className="meet-title">
              <KBTipsy content={meeting.name}>
                <Link
                  to={`/admin/locations/${loc_id}/meeting/info/${meeting &&
                    meeting.id}`}
                >
                  <i
                    className={`iconfont ${AREATYPES_CLASS[meeting.area_type]}`}
                    style={{ marginRight: 5 }}
                  />
                  {meeting.name}
                </Link>
              </KBTipsy>
            </div>
          </div>
        )
      })

    return render
  },

  fullZero(number) {
    if (number <= 9) {
      return '0' + number
    }
    return number
  },

  //渲染时间段
  meetingRoomsTimeJsx() {
    const {
      reservation_time_range: { begin, end }
    } = this.props
    let times = []
    for (let i = begin; i <= end; i++) {
      let st = this.fullZero(i)
      times.push({
        id: i,
        name: st
      })
    }

    return (
      <div style={{ height: 30 }}>
        <div className="line-time">
          {times.map((json, index) => {
            return (
              <div key={index} className="time-quantum">
                {json.name}
              </div>
            )
          })}
        </div>
      </div>
    )
  },

  changeDayPicker(date) {
    const { routerActions, loc_id } = this.props
    this.refs.filterDate.hide()
    if (this.mounted) {
      this.setState({
        searchTime: date
      })
    }

    routerActions.replace(
      `/admin/locations/${loc_id}/meeting?time=${moment(date).format(
        'YYYY-MM-DD'
      )}`
    )
  },

  searchMeetingRoom() {
    const { loc_id, time } = this.props

    KBPopover.show(<MeetingSearchRoomForm time={time} loc_id={loc_id} />)
  },

  addMeeting() {
    const { loc_id } = this.props
    KBPopover.show(<MeetingForm loc_id={loc_id} />)
  },

  initialScrollLeft() {
    if (!this['meetingScroll']) {
      return
    }

    if (moment().get('hour') < 10) {
      this['meetingScroll'].scrollLeft = 0
    } else {
      this['meetingScroll'].scrollLeft = 640
    }
    this.setState({
      isInitialScroll: false
    })
  },

  closePopup() {
    this.setState({
      isShowMeetingPopup: false
    })
  },

  reportMeetingRoom() {
    const { loc_id } = this.props
    let initValue = {
      startYMD: new Date(),
      endYMD: new Date(),
      startHM: '9,1',
      endHM: '9,1',
      start_time: moment(new Date())
        .clone()
        .set({ hour: 9, minute: 0, second: 0 }),
      end_time: moment(new Date())
        .clone()
        .set({ hour: 9, minute: 0, second: 0 })
    }
    KBPopover.show(
      <MeetingRoomReportForm loc_id={loc_id} initialValues={initValue} />
    )
  },

  render() {
    const {
      loc_id,
      reservations,
      info,
      meetings,
      loadingStatus,
      time,
      isManager,
      defaultDate,
      reservation_time_range,
      setting_reservation_time
    } = this.props
    const {
      searchTime,
      loading,
      isInitialScroll,
      current_reservation,
      isShowMeetingPopup
    } = this.state
    const today = moment().toDate()
    const tomorrow = moment()
      .add(1, 'days')
      .toDate()
    const yesterday = moment()
      .subtract(1, 'days')
      .toDate()
    if (loadingStatus == 'loading') {
      return (
        <section className="nav-section-container  f-right">
          <KBLoadingContainer loading={true} />
        </section>
      )
    }
    const todayReservations = reservations.filter(
      reservation =>
        moment(reservation.start_at).format('YYYY-MM-DD') ==
        moment(time).format('YYYY-MM-DD')
    )
    const todayReservationsLength = todayReservations.length

    return (
      <section
        className="nav-section-container  f-right"
        ref="container"
        style={{ position: 'relative' }}
      >
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>预约管理</span>
          </h2>
          {isManager ? (
            <div className="f-right" style={{ display: 'flex' }}>
              <a className="c-btn-secondary" onClick={this.reportMeetingRoom}>
                导出会议室记录
              </a>
              <a className="bordered-btn" onClick={this.searchMeetingRoom}>
                <i className="iconfont icon-add" />
                添加新预约
              </a>
            </div>
          ) : (
            ''
          )}
        </header>
        {meetings && meetings.length > 0 ? (
          <div className="nav-section-content-container">
            <div className="m-updown t-center">
              <div className="chart-box t-left" style={{ marginRight: '75px' }}>
                <KBStatisticNumber
                  number={todayReservationsLength}
                  unit="个"
                  name="会议个数"
                />
              </div>
              <div className="chart-box t-left" style={{ marginRight: '75px' }}>
                <KBStatisticNumber
                  number={info.totalTime}
                  unit="小时"
                  name="总时长"
                />
              </div>
              <div className="chart-box t-left">
                <KBStatisticNumber
                  number={`${info.probability}%`}
                  name="利用率"
                />
              </div>
            </div>
            <div className="m-top" style={{ marginBottom: 40 }}>
              <div className="kb-title m-bottom">
                <span>场地管理</span>
              </div>
              <KBDropdown ref="filterDate">
                <KBDropdown.KBDropdownTrigger>
                  <div className="task-status-title">
                    <i
                      className="iconfont icon-calendar"
                      style={{ marginRight: '3px' }}
                    />
                    <span>
                      选择日期:{moment(searchTime).format('YYYY-MM-DD')}
                    </span>
                    <span className="task-deg" />
                  </div>
                </KBDropdown.KBDropdownTrigger>
                <KBDropdown.KBDropdownContent>
                  <KBDayPicker
                    onChange={this.changeDayPicker}
                    selectedDay={searchTime}
                  />
                </KBDropdown.KBDropdownContent>
              </KBDropdown>
              <div className="time-filter">
                <button
                  className={
                    moment(searchTime).isSame(yesterday, 'day') ? 'active' : ''
                  }
                  onClick={this.changeDayPicker.bind(null, yesterday)}
                >
                  昨天
                </button>
                <button
                  className={
                    moment(searchTime).isSame(today, 'day') ? 'active' : ''
                  }
                  onClick={this.changeDayPicker.bind(null, today)}
                >
                  今天
                </button>
                <button
                  className={
                    moment(searchTime).isSame(tomorrow, 'day') ? 'active' : ''
                  }
                  onClick={this.changeDayPicker.bind(null, tomorrow)}
                >
                  明天
                </button>
              </div>
            </div>
            <KBLoadingContainer loading={loading}>
              {Object.values(current_reservation).length > 0 ? (
                <MeetingPopup
                  reservation={current_reservation}
                  defaultDate={defaultDate}
                  closePopup={this.closePopup}
                  isShowMeetingPopup={isShowMeetingPopup}
                  isManager={isManager}
                  reservation_time_range={setting_reservation_time}
                  cancelBooking={this.cancelBooking}
                  cancelMeeting={this.cancelMeeting}
                />
              ) : (
                ''
              )}
              <div
                ref={ref => {
                  this.meetingScroll = ref
                }}
                style={{
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  marginTop: 15,
                  marginLeft: 140
                }}
              >
                <div className="meeting-title-box">
                  {this.meetingRoomsTitleJsx()}
                </div>
                {this.renderDateTime()}
              </div>
            </KBLoadingContainer>
          </div>
        ) : (
          <div className="meeting-empty">
            <div className="add-new m-none" onClick={this.addMeeting}>
              <i className="iconfont add-new-btn" />
              <span>添加新场地</span>
            </div>
          </div>
        )}
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { user } = state
  let loc_id = params.id
  let locationSetting = selectors.getLocationSetting(state, loc_id)
  const reservationTimeRange =
    locationSetting &&
    locationSetting.meeting_room_settings &&
    locationSetting.meeting_room_settings.reservation_time_range
  let reservation_time_range = { begin: 0, end: 24 }
  let settingDateRange =
    reservationTimeRange && reservationTimeRange.find(r => r.day === -1)
  let setting_reservation_time = settingDateRange
    ? { begin: settingDateRange.begin / 60, end: settingDateRange.end / 60 + 1 }
    : { begin: 0, end: 24 }
  let meetings = selectors.getMeetingRoomsOfLocation(state, loc_id)
  meetings = meetings.filter(m_room => m_room.area_type !== 'floor')

  let reservations = selectors.getReservations(state)
  const time = location.query
    ? moment(location.query.time).toDate()
    : new Date()
  const defaultDate = getInitialDate(time)
  const loadingStatus = selectors.getLoadingStatusOfKey(state, 'meetingRooms')
  let isManager = canAccessLocation(user, loc_id)

  let reservationCount = reservations.length

  // 当天之内的时间
  reservations = reservations.filter(reservation => {
    let start_at = moment.unix(reservation.start_at)
    // && end_at.diff(start_at, 'minutes') >= 30
    return start_at.isBetween(
      moment(defaultDate.initStartDate).subtract(1, 'hours'),
      defaultDate.initEndDate
    )
  })

  // 添加颜色
  let colorIndex = 0
  let themeColorsCounnt = MEETING_COLORS.length
  reservations = reservations.map(reservation => {
    if (colorIndex > themeColorsCounnt - 1) {
      colorIndex = 0
    }
    reservation.color = MEETING_COLORS[colorIndex]
    colorIndex++
    return reservation
  })

  // 计算预定总时间 把相同公司标记为相同的颜色
  let totalTime = 0
  reservations = reservations.map(reservation => {
    var temp = reservations.find(
      r =>
        r.organization_id == reservation.organization_id &&
        r.id != reservation.id &&
        r.color
    )
    if (temp) {
      reservation.color = temp.color
    }

    totalTime += moment
      .unix(reservation.end_at)
      .diff(moment.unix(reservation.start_at), 'hours', true)
    return reservation
  })

  meetings &&
    meetings.map(meeting => {
      let reservationList = []
      reservations.map(reservation => {
        if (reservation.area_id == meeting.id) {
          reservationList.push({
            ...reservation,
            start_at: moment.unix(reservation.start_at).toDate(),
            end_at: moment.unix(reservation.end_at).toDate()
          })
        }
      })

      meeting.reservations = reservationList
    })

  totalTime = parseFloat(totalTime).toFixed(2)
  let probability = percent(MEETING_TIME_COUNT * meetings.length, totalTime)
  let info = {
    reservationCount,
    totalTime,
    probability
  }

  const appointment = location.query.appointment == 'new' ? true : false
  return {
    loc_id,
    loadingStatus,
    time,
    defaultDate,
    meetings,
    reservations,
    info,
    appointment,
    isManager,
    reservation_time_range,
    setting_reservation_time
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    getReservationsAction: bindActionCreators(
      reservationsActions.all,
      dispatch
    ),
    updateReservationsActions: bindActionCreators(
      reservationsActions.update,
      dispatch
    ),
    deleteReservationsAction: bindActionCreators(
      reservationsActions.delete,
      dispatch
    ),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingIndex)
