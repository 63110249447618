import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { locksActions } from 'app/actions'
import { checkObject, getEntitiesJsonArray } from 'utils/kbUtil'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBButton,
  KBUserInput,
  KBSelect,
  KBRadio,
  KBTextarea
} from 'components'
import * as constants from 'app/constants'
import * as selectors from 'app/selectors'

var LockForm = React.createClass({
  displayName: 'LockForm',
  createLockdForm(model) {
    const {
      createLockAction,
      callback,
      updateLockAction,
      state,
      lock,
      loc_id
    } = this.props
    if (model.is_gate && model.is_gate != 'false') {
      model.area_id = ''
    }
    if (state == 'update') {
      return api.updateLock(lock.id, model).then(json => {
        callback && callback(getEntitiesJsonArray(json, 'locks'))
        updateLockAction.success(json)
        KBPopover.close()
        return
      })
    }
    return api.createLock(loc_id, model).then(json => {
      callback && callback(getEntitiesJsonArray(json, 'locks'))
      createLockAction.success(json)
      KBPopover.close()
      return
    })
  },
  render() {
    const {
      fields: {
        name,
        device_id,
        factory_version,
        lock_type,
        serial,
        device_password,
        is_enabled,
        is_auto_enabled,
        is_gate,
        area_id,
        notes
      },
      error,
      submitting,
      handleSubmit,
      state,
      areas,
      selectAreas,
      lock
    } = this.props

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }
    return (
      <form onSubmit={handleSubmit(this.createLockdForm)}>
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改门禁' : '添加门禁'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="名称"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={device_id}
              title="门禁ID"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="厂家型号"
              field={factory_version}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="设备号"
              field={serial}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              title="门禁密码"
              field={device_password}
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBRadio
              {...halfClass}
              inClassName="kb-form-group kb-div-33"
              groupStyle={{ width: '100%' }}
              divClassName="kb-form-group kb-div-66"
              rightLabel="label-rt"
              title="是否启用"
              arrayType={constants.LOCK_ARRAY}
              name="is_enabled"
              field={is_enabled}
            />
            <KBRadio
              {...halfClass}
              inClassName="kb-form-group kb-div-33"
              groupStyle={{ width: '100%' }}
              divClassName="kb-form-group kb-div-66"
              rightLabel="label-rt"
              title="自动开门"
              arrayType={constants.LOCK_ARRAY}
              name="is_auto_enabled"
              field={is_auto_enabled}
            />
          </div>
          <div className="kb-row">
            <div className="kb-form-group kb-form-c18 kb-form-alone">
              <label>所在区域</label>
              <div className="group-content">
                <span className="color-nine" style={{ fontWeight: 'normal' }}>
                  {lock.is_gate ? '大门' : lock.area ? lock.area.name : '无'}
                </span>
              </div>
            </div>
          </div>
          <div className="kb-row">
            <KBTextarea
              title="备注"
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={notes}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { entities } = state
  const { loc_id, lock } = props
  const areas = selectors.getAreasOfLocation(state, loc_id)
  const selectAreas = lock && lock.area ? [lock.area] : []
  return {
    areas,
    selectAreas
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createLockAction: bindActionCreators(locksActions.create, dispatch),
    updateLockAction: bindActionCreators(locksActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入名称'
  }

  if (valid.required(values.device_id)) {
    errors.device_id = '请输入门禁设备id'
  }

  if (values.mobile && valid.mobile(values.mobile)) {
    errors.mobile = '请输入正确的电话'
  }

  return errors
}

const formConfig = {
  form: 'LockForm',
  fields: [
    'name',
    'device_id',
    'factory_version',
    'lock_type',
    'serial',
    'device_password',
    'is_enabled',
    'is_auto_enabled',
    'is_gate',
    'area_id',
    'notes'
  ],
  validate: validate,
  touchOnBlur: false
}

LockForm = reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(LockForm)

export default LockForm
