import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import classNames from 'classnames'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import OrgSubscriptionsGenerate from './OrgSubscriptionsGenerate'

var NewOrgSubscription = React.createClass({
  displayName: 'NewOrgSubscription',
  render() {
    return (
      <section className="kb-content">
        <div className="kb-content-container clear-fix">
          <OrgSubscriptionsGenerate {...this.props} />
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  let org_id = parseInt(props.params.organization_id)
  let loc_id =
    parseInt(props.params.id) || parseInt(props.location.query.location_id)
  let typeSource = props.location.query.typeSource || null

  return {
    loc_id,
    org_id,
    typeSource
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewOrgSubscription)
