import React, { Component, PropTypes } from 'react'
import {
  KBDropdown,
  KBPopover,
  KBTableWithoutData,
  KBLoadingContainer,
  KBPagination,
  KBCheckBox,
  KBNoData
} from 'components'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { lockPermissionsActions, lockEventsActions } from 'app/actions'
import * as selectors from 'app/selectors'
import {
  paginate,
  queryFilter,
  getFilterAndQuery,
  formatMinuteSecondEN
} from 'utils/kbUtil'
import {
  canAccessLocation,
  canAdminLocation,
  canAccessLocationOperator,
  canAdminLocationGroup,
  canAdminSpace
} from 'app/reducers/role'
import { KBPopoverConfirm } from 'components/tools'
import {
  LOCKS_PERMISSIONS_PROGRESS,
  LOCKS_PERMISSIONS_PROGRESS_CLASS
} from 'app/constants'
import LocksPermissionsUpdateForm from './LocksPermissionsUpdateForm'
import LocksPermissionsMultiForm from './LocksPermissionsMultiForm'
import LockGroupBlock from '../locks/LockGroupBlock'
import { getCurrentLocation } from 'utils/kbUtil'
import moment from 'moment'
import _ from 'lodash'
import { getUserInfoUrl } from 'utils/kbUrl'
import { Object } from 'core-js'

const FILTER_STATE = ['include_ended']

const SOURCE_TYPE = {
  null: '手动添加',
  SalesSubscription: '入驻合同',
  Reservation: '会议室预定'
}

var MembersLockPermission = React.createClass({
  displayName: 'MembersLockPermission',
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      loading: false,
      isAllChecked: false,
      selectedPermissions: []
    }
  },

  componentDidMount() {
    const { filters, page } = this.props
    this.mounted = true
    this._setFilters({
      include_ended: false
    })
    // this._loadLockPermissions(page, filters)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page !== nextProps.page) {
      this._loadLockPermissions(nextProps.page, {
        ...this.state.filters,
        ...filters
      })
    }
  },

  _loadLockPermissions(page, filters) {
    const {
      loc_id,
      getLocksPermissionsAction,
      query,
      routerActions,
      member_id,
      isMemberShipPath
    } = this.props
    const per_page = 10
    let pageObj = { per_page, page }
    let params = Object.assign({}, pageObj, filters, { user_id: member_id })
    let queryFiltersStr = queryFilter({ ...query, ...pageObj, ...filters })
    this.setState({ loading: true })
    api.getLockPermissions(params).then(
      json => {
        // 防止用户点击过快时路由来回跳转
        if (!this.mounted) {
          return
        }
        routerActions.replace(
          `${getUserInfoUrl(
            member_id,
            isMemberShipPath
          )}/lock_permissions${queryFiltersStr}`
        )
        let pagination = paginate(json.response)

        this.setState({
          pagination
        })
        getLocksPermissionsAction.success(json, {
          entityName: 'lockPermissions'
        })
      },
      errors => {
        getLocksPermissionsAction.failure(errors)
      }
    ).finally(() => {
      this.setState({
        loading: false
      })
    })
  },

  createLocksPermissions() {
    const { member, lockPermissions, location_id } = this.props

    let initValue = {
      location_id: location_id,
      user_id: member.id,
      startYMD: new Date(),
      endYMD: new Date(),
      lock_id: '',
      startHM: '9,1',
      endHM: '9,1',
      start_at: moment(new Date())
        .clone()
        .set({ hour: 9, minute: 0, second: 0 }),
      end_at: moment(new Date())
        .clone()
        .set({ hour: 9, minute: 0, second: 0 })
    }

    KBPopover.show(
      <LocksPermissionsMultiForm
        initialValues={initValue}
        lockPermissions={lockPermissions}
      />
    )
  },

  updateLocksPermissions(data) {
    const { loc_id } = this.props
    KBPopover.show(
      <LocksPermissionsUpdateForm
        loc_id={loc_id}
        initialValues={{
          lock_permissions: [
            {
              id: data.id,
              startYMD: this.setYMD(data.start_at),
              endYMD: this.setYMD(data.end_at),
              start_at:
                typeof data.start_at === 'number'
                  ? moment.unix(data.start_at)
                  : moment(data.start_at),
              end_at:
                typeof data.end_at === 'number'
                  ? moment.unix(data.end_at)
                  : moment(data.end_at),
              startHM: this.setHM(data.start_at),
              endHM: this.setHM(data.end_at)
            }
          ]
        }}
      />
    )
  },

  deleteLocksPermissions(lock) {
    const { loc_id, deleteLocksPermissionsAction } = this.props
    let idArray = []
    idArray.push(lock.id)
    KBPopoverConfirm({
      name: '删除门禁权限',
      context: '是否要删除门禁 ' + lock.lock.name + ' 的权限',
      callback: () => {
        return api.deleteLockPermissions(loc_id, { ids: idArray }).then(
          json => {
            deleteLocksPermissionsAction.success(json, {
              id: lock.id,
              key: 'lockPermissions'
            })
            KBPopover.close()
          },
          error => {
            deleteLocksPermissionsAction.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  setYMD(value) {
    let date = typeof value === 'number' ? moment.unix(value) : moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    if (typeof value === 'number') {
      return moment
        .unix(value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    }
    return moment(value)
      .clone()
      .set({ year, month, date: day })
      .toDate()
  },

  setHM(value) {
    let date = typeof value === 'number' ? moment.unix(value) : moment(value)
    let hour = date.get('hour')
    let minute = date.get('minute')
    let processHour = ''
    let processMinute = ''

    processHour = hour

    if (minute == 30) {
      processMinute = 2
    } else {
      processMinute = 1
    }

    return processHour + ',' + processMinute
  },

  _setFilters(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({
      filters: newFilters,
      selectedPermissions: [],
      isAllChecked: false
    })
    this.refs.lock_idDropdown.hide()
    this._refresh(newFilters)
  },

  _refresh(filters) {
    setTimeout(this._loadLockPermissions(filters.page || 1, filters))
  },

  _clearFilters() {
    this.setState({
      filters: {},
      selectedPermissions: [],
      isAllChecked: false
    })
    this._refresh({})
  },

  deleteMultiPermissions() {
    const { loc_id, deleteLocksPermissionsAction } = this.props
    const { selectedPermissions } = this.state
    const idArray = selectedPermissions.map(permission => permission.id)

    KBPopoverConfirm({
      name: '删除门禁权限',
      context: '是否要删除所选门禁权限',
      callback: () => {
        return api.deleteLockPermissions(loc_id, { ids: idArray }).then(
          json => {
            deleteLocksPermissionsAction.success(json, {
              ids: idArray,
              key: 'lockPermissions'
            })
            KBPopover.close()
          },
          error => {
            deleteLocksPermissionsAction.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  checkAllPermissions(checked, data) {
    this.setState({
      isAllChecked: checked,
      selectedPermissions: checked ? data : []
    })
  },

  changePermission(checked, data) {
    const permissions = Object.assign([], this.state.selectedPermissions)

    if (checked) {
      permissions.push(data)
      // 当选中的 permissions 逐个增加时的判断
      const isPermissionsAllAdd = permissions.length === 10
      return this.setState({
        selectedPermissions: permissions,
        isAllChecked: isPermissionsAllAdd ? true : false
      })
    }

    let removeIndex = permissions.findIndex(json => json.id === data.id)
    if (removeIndex == -1) {
      return
    }

    permissions.splice(removeIndex, 1)
    // 当选中的 permissions 逐个减少时的判断
    const isPermissionsAllSub = permissions.length === 10
    this.setState({
      selectedPermissions: permissions,
      isAllChecked: isPermissionsAllSub ? true : false
    })
  },

  batchUpdateLockPermissions(lock_group) {
    const { locks } = lock_group
    const { loc_id } = this.props

    KBPopover.show(
      <LocksPermissionsUpdateForm
        loc_id={loc_id}
        isGroup={true}
        allPermissions={locks}
        initialValues={{
          lock_permissions: [
            {
              id: locks[0].id,
              startYMD: this.setYMD(locks[0].start_at),
              endYMD: this.setYMD(locks[0].end_at),
              start_at:
                typeof locks[0].start_at === 'number'
                  ? moment.unix(locks[0].start_at)
                  : moment(locks[0].start_at),
              end_at:
                typeof locks[0].end_at === 'number'
                  ? moment.unix(locks[0].end_at)
                  : moment(locks[0].end_at),
              startHM: this.setHM(locks[0].start_at),
              endHM: this.setHM(locks[0].end_at)
            }
          ]
        }}
      />
    )
  },

  batchDeleteLockPermissions(lock_group) {
    const { locks } = lock_group
    const { loc_id, deleteLocksPermissionsAction } = this.props
    const idArray = locks.map(permission => permission.id)

    KBPopoverConfirm({
      name: '删除门禁组权限',
      context: `您将要删除门禁组：${lock_group.name} 下的所有门禁权限，是否确认删除？`,
      callback: () => {
        return api.deleteLockPermissions(loc_id, { ids: idArray }).then(
          json => {
            deleteLocksPermissionsAction.success(json, {
              ids: idArray,
              key: 'lockPermissions'
            })
            KBPopover.close()
          },
          error => {
            deleteLocksPermissionsAction.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  _renderFilters() {
    const { filters } = this.state

    return (
      <div className="nav-section-action-bar">
        <KBDropdown ref="lock_idDropdown">
          <KBDropdown.KBDropdownTrigger>
            <div className="task-status-title">
              <span>{filters.include_ended ? '权限历史' : '当前权限'}</span>
              <span className="task-deg" />
            </div>
          </KBDropdown.KBDropdownTrigger>
          <KBDropdown.KBDropdownContent>
            <div>
              <ul className="task-type">
                <li
                  onClick={this._setFilters.bind(null, {
                    include_ended: false
                  })}
                >
                  <span>当前权限</span>
                </li>
                <li
                  onClick={this._setFilters.bind(null, { include_ended: true })}
                >
                  <span>权限历史</span>
                </li>
              </ul>
            </div>
          </KBDropdown.KBDropdownContent>
        </KBDropdown>
      </div>
    )
  },

  render() {
    const {
      lockPermissions,
      member_id,
      isManager,
      loc_id,
      lockGroups
    } = this.props
    const { pagination, loading, selectedPermissions } = this.state
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    return (
      <section className="nav-section-container f-right">
        <div style={{ height: '100%' }}>
          <header className="member-header" style={{ borderBottom: 'none' }}>
            <div className="nav-header-title">
              <h2 className="header-title f-left" style={{ fontSize: 22 }}>
                门禁权限
              </h2>
            </div>
            <div>
              {isManager ? (
                <button
                  className="bordered-btn"
                  onClick={this.createLocksPermissions}
                >
                  添加门禁权限
                </button>
              ) : (
                ''
              )}
            </div>
          </header>
          <div className="nav-section-content-container">
            <ul className="nav-section-tab" style={{ marginTop: 30 }}>
              <li className={`lock nav-section-tab-active`}>
                <a>门禁权限</a>
              </li>
            </ul>
            <div>
              <div>
                {this._renderFilters()}
                {isManager &&
                selectedPermissions &&
                selectedPermissions.length > 0 &&
                !this.state.filters.include_ended ? (
                  <button
                    className="c-btn-delete"
                    onClick={this.deleteMultiPermissions}
                    style={{ marginLeft: 0, marginTop: 15 }}
                  >
                    删除权限
                  </button>
                ) : null}
                {selectFilters.length > 0 ? (
                  <div
                    className="clear-criteria"
                    style={{ marginTop: 15 }}
                    onClick={this._clearFilters}
                  >
                    <i className="iconfont icon-close" />
                    <span>清除所有筛选条件</span>
                  </div>
                ) : null}
              </div>
              <KBLoadingContainer loading={loading}>
                <table className="content-table">
                  <thead>
                    <tr>
                      <th>
                        <KBCheckBox
                          callback={this.checkAllPermissions}
                          data={lockPermissions || []}
                          checked={this.state.isAllChecked}
                        />
                      </th>
                      <th>门禁名称</th>
                      <th>权限</th>
                      <th>开始时间</th>
                      <th>结束时间</th>
                      <th>赋予原因</th>
                      <th>所属门禁组</th>
                      <th />
                    </tr>
                  </thead>
                  <KBTableWithoutData
                    hasData={lockPermissions.length > 0}
                    tableHeadNum="6"
                    tipMessage="暂无数据"
                  >
                    {lockPermissions &&
                      lockPermissions.map((json, index) => {
                        const isChecked =
                          selectedPermissions &&
                          selectedPermissions.find(
                            permission => permission.id === json.id
                          )
                            ? true
                            : false
                        return (
                          <tr key={index}>
                            <td>
                              <KBCheckBox
                                callback={this.changePermission}
                                data={json}
                                checked={isChecked}
                              />
                            </td>
                            <td>{(json.lock && json.lock.name) || ''}</td>
                            <td>
                              <span
                                style={{ marginLeft: 0 }}
                                className={`finance-default ${
                                  LOCKS_PERMISSIONS_PROGRESS_CLASS[
                                    json.progress
                                  ]
                                }`}
                              >
                                {' '}
                                {LOCKS_PERMISSIONS_PROGRESS[json.progress]}
                              </span>
                            </td>
                            <td>
                              {(json.start_at &&
                                formatMinuteSecondEN(json.start_at)) ||
                                '暂无'}
                            </td>
                            <td>
                              {(json.end_at &&
                                formatMinuteSecondEN(json.end_at)) ||
                                '暂无'}
                            </td>
                            <td>{SOURCE_TYPE[json.source_type]}</td>
                            <td>
                              {json.lock_group ? json.lock_group.name : '-'}
                            </td>
                            {isManager ? (
                              <td className="t-right">
                                <i
                                  className="iconfont icon-edit a-hover"
                                  onClick={this.updateLocksPermissions.bind(
                                    null,
                                    json
                                  )}
                                />
                                <i
                                  className="iconfont icon-delete m-left-sm a-hover"
                                  onClick={this.deleteLocksPermissions.bind(
                                    null,
                                    json
                                  )}
                                />
                              </td>
                            ) : null}
                          </tr>
                        )
                      })}
                  </KBTableWithoutData>
                </table>
                <KBPagination
                  pagination={pagination}
                  template={`${getUserInfoUrl(
                    member_id
                  )}/lock_permissions?page=#PAGE#`}
                />
              </KBLoadingContainer>
            </div>
            <ul
              className="nav-section-tab nav-section-tab-active"
              style={{ marginTop: 30 }}
            >
              <li className={`lock_group nav-section-tab-active`}>
                <a>门禁组权限</a>
              </li>
            </ul>
            <KBLoadingContainer loading={loading}>
              {lockGroups.length > 0 ? (
                lockGroups.map(lock_g => {
                  return (
                    <LockGroupBlock
                      isPermission={true}
                      loc_id={loc_id}
                      lock_group={lock_g}
                      locks={[]}
                      onEdit={this.batchUpdateLockPermissions}
                      onDelete={this.batchDeleteLockPermissions}
                      onDeleteSingle={this.deleteLocksPermissions}
                    />
                  )
                })
              ) : (
                <KBNoData tipMessage="暂无门禁组权限" />
              )}
            </KBLoadingContainer>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let orgId = params.organization_id
  let member_id = params.member_id
  let lockPermissions =
    selectors.getLocksPermissionsUser(state, member_id) || []
  lockPermissions.map(l => {
    l.name = l.lock.name
    return l
  })
  let lockPersWithLockGroup = lockPermissions.filter(l => l.lock_group_id)
  let groupedLockGroups = Object.values(
    _.groupBy(lockPersWithLockGroup, 'lock_group_id') || {}
  )
  let lockGroups = []
  groupedLockGroups.map(lock_permissions => {
    let lock_group = {}
    lock_group.id = lock_permissions[0].lock_group_id
    lock_group.name = lock_permissions[0].lock_group.name
    lock_group.locks = lock_permissions || []
    lockGroups.push(lock_group)
  })

  let currentLocation = getCurrentLocation()
  let location_id = currentLocation && currentLocation.id
  let isGroupManager = canAdminLocationGroup(state.user)
  let isLocationOperator = canAccessLocationOperator(state.user, location_id)
  let isManager = canAdminSpace(state.user)

  let sections = location.pathname.split('/')
  let isMemberShipPath =
    sections[2] === 'memberships' && sections[3] === 'memberManage'
  // let isManager = canAccessLocation(state.user, loc_id)
  if (isLocationOperator && !isGroupManager && !isManager) {
    isManager = false
  }

  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page

  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)

  return {
    loc_id,
    location_id: 0,
    orgId,
    member_id,
    isManager,
    lockPermissions,
    lockGroups,
    isMemberShipPath,
    page,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLockEventsActions: bindActionCreators(lockEventsActions.all, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    getLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.replace,
      dispatch
    ),
    deleteLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.delete,
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersLockPermission)
