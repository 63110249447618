import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import * as util from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import { apiCallFailure, orgActions } from 'app/actions'
import { KBForm, KBInput, KBUserInput, KBButton, KBTipsy } from 'components'
import { api } from 'app/services'
import { PARKING_CONTRACT_RENTER_TYPES } from 'app/constants'
import _ from 'lodash'

var ParkingContractRenterForm = React.createClass({
  getInitialState() {
    return {
      currentSelectedRenter: this.props.currentRenter,
      positionList: []
    }
  },

  componentDidMount() {
    const { currentOrg } = this.props
    if (currentOrg && currentOrg.id) {
      this.getInitialValues(currentOrg)
    }
    this.getParkingRenters()
    this.getTags()
  },

  getTags() {
    const { loc_id } = this.props
    api
      .getSettingTags({
        model: 'Organization',
        columns: 'positions',
        location_id: loc_id
      })
      .then(json => {
        let res = json.response.body || {}
        let allPositions = Object.keys(res.positions || {})
        let positionList = Object.assign(
          [],
          this.state.positionList,
          allPositions || []
        )
        this.setState({
          positionList
        })
      })
  },

  getParkingRenters() {
    const { loc_id, getOrganizationsAction } = this.props

    api.getOrganizations({ location_id: loc_id, per_page: 1000 }).then(
      json => {
        getOrganizationsAction.success(json)
      },
      errors => {
        getOrganizationsAction.failure(errors)
      }
    )
  },

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.currentOrg, nextProps.currentOrg)) {
      this.getInitialValues(nextProps.currentOrg)
    }
  },

  getInitialValues(org) {
    const {
      fields: { full_name, contacts_name, contacts_phone, organization_type }
    } = this.props

    full_name.onChange(org.full_name)
    contacts_name.onChange(org.contacts_name)
    contacts_phone.onChange(org.contacts_phone)
    organization_type.onChange(org.organization_type)
  },

  createSimpleOrg(model) {
    const { loc_id, orgCreateCallback, apiCallFailureActions } = this.props

    if (!model.organization_type) {
      return apiCallFailureActions({
        status: 'error',
        message: '请选择承租人类型'
      })
    }

    if (model.organization_type === 'simple' && !model.is_create) {
      const { currentSelectedRenter } = this.state
      const org = currentSelectedRenter[0]
      return orgCreateCallback && orgCreateCallback(org)
    }

    if (
      model.organization_type === 'outer_user' ||
      model.organization_type === 'inner_user'
    ) {
      model.full_name = model.contacts_name
    }

    delete model.user_id
    delete model.is_create

    model.name = model.full_name

    return api.createSimpleOrganization(loc_id, model).then(
      json => {
        let org = json.response.body
        orgCreateCallback && orgCreateCallback(org)
      },
      error => {
        return apiCallFailureActions({
          status: 'error',
          message: util.getServiceErrorMessage(error)
        })
      }
    )
  },

  changeOrgType(type) {
    const {
      fields: { organization_type }
    } = this.props
    organization_type.onChange(type.id)
  },

  renterSelectCallback(renter) {
    this.setState({
      currentSelectedRenter: [renter]
    })
  },

  changeCreateRenterType() {
    const {
      fields: { is_create }
    } = this.props
    is_create.onChange(!is_create.value)
  },

  selectCommonLabel(label) {
    const {
      fields: { position_list }
    } = this.props
    if (position_list.value) {
      if (position_list.value.indexOf(label) !== -1) {
        return null
      }
      return position_list.onChange(position_list.value + ',' + label)
    }
    position_list.onChange(label)
  },

  render() {
    const {
      fields: {
        full_name,
        contacts_name,
        contacts_phone,
        organization_type,
        user_id,
        is_create,
        position_list
      },
      submitting,
      handleSubmit
    } = this.props
    const { parkingRenters, hasOrg, currentRenter } = this.props
    const { currentSelectedRenter, positionList } = this.state
    const isSimpleOrg = organization_type.value === 'simple'
    const isNormalOrg = organization_type.value === 'normal'
    const changeBthText = is_create.value ? '选择已有公司' : '输入新公司'

    return (
      <KBForm type="reduxForm" fields={this.props.fields}>
        <fieldset className="fieldset">
          <legend>承租人信息</legend>
          <div className="kb-row">
            <div className="time-filter m-none">
              {PARKING_CONTRACT_RENTER_TYPES.map(type => {
                return (
                  <span
                    className={
                      organization_type.value === type.id ||
                      (organization_type.value === 'normal' &&
                        type.id === 'simple')
                        ? 'active'
                        : ''
                    }
                    onClick={this.changeOrgType.bind(null, type)}
                  >
                    {type.name}
                  </span>
                )
              })}
            </div>
          </div>
          {isSimpleOrg || isNormalOrg ? (
            <div className="kb-row kb-form-66">
              {is_create.value && isSimpleOrg ? (
                <KBInput
                  className="kb-form-group kb-div-39"
                  title="公司名称"
                  isRequired={true}
                  fistFoucs={true}
                  field={full_name}
                  disabled={hasOrg}
                  inputClass={hasOrg ? 'kb-input-disabled' : ''}
                />
              ) : (
                <KBUserInput
                  title="公司"
                  isRequired={true}
                  placeholder="请选择已有公司"
                  hint="如不存在可点击“输入新公司”以创建一个新的公司"
                  className="kb-form-group kb-div-39"
                  field={user_id}
                  multi={false}
                  users={parkingRenters}
                  selectedUsers={
                    currentSelectedRenter.length > 0
                      ? currentSelectedRenter
                      : currentRenter
                  }
                  callback={this.renterSelectCallback}
                  disabled={hasOrg}
                />
              )}
              <div className="kb-form-group kb-div-102">
                {is_create.value && isSimpleOrg ? (
                  <div className="kb-form-group kb-div-100">
                    <KBInput
                      className="kb-form-group kb-div-111"
                      title="位置标签"
                      hint='如需输入多个标签，请用逗号","分隔'
                      field={position_list}
                      disabled={hasOrg}
                      inputClass={hasOrg ? 'kb-input-disabled' : ''}
                    />
                    <div className="time-bucket-btn">
                      {positionList.map((json, index) => {
                        return (
                          <span
                            key={index}
                            onClick={this.selectCommonLabel.bind(null, json)}
                          >
                            {json}
                          </span>
                        )
                      })}
                    </div>
                  </div>
                ) : null}
                {hasOrg ? (
                  <div />
                ) : (
                  <div className="kb-form-group kb-div-100">
                    <label>&nbsp;</label>
                    <div>
                      <KBTipsy content={changeBthText}>
                        <span
                          className="c-btn-secondary"
                          onClick={this.changeCreateRenterType}
                        >
                          {changeBthText}
                        </span>
                      </KBTipsy>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div className="kb-row kb-form-66">
            <KBInput
              isRequired={true}
              className="kb-form-group kb-div-39"
              title="姓名"
              field={contacts_name}
              disabled={hasOrg}
              inputClass={hasOrg ? 'kb-input-disabled' : ''}
            />
            <KBInput
              className="kb-form-group kb-div-39"
              title="联系电话"
              field={contacts_phone}
              disabled={hasOrg}
              inputClass={hasOrg ? 'kb-input-disabled' : ''}
            />
          </div>

          {hasOrg ? null : (
            <div className="kb-row">
              <span
                className={`certain-btn ${
                  submitting ? 'certain-btn-disabled' : ''
                }`}
                onClick={handleSubmit(this.createSimpleOrg)}
              >
                确认承租人信息
              </span>
            </div>
          )}
        </fieldset>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const { loc_id, currentOrg } = props
  let parkingRenters = selectors.getOrgsOfLocation(state, loc_id) || []
  let currentRenter = parkingRenters.filter(re => re.id === currentOrg.id)

  return {
    parkingRenters,
    currentRenter
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getOrganizationsAction: bindActionCreators(orgActions.replace, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (
    values.organization_type === 'simple' &&
    values.is_create &&
    valid.required(values.full_name)
  ) {
    errors.full_name = '请输入公司名称'
  }

  if (
    values.organization_type === 'simple' &&
    !values.is_create &&
    valid.required(values.user_id)
  ) {
    errors.user_id = '请选择公司'
  }

  if (valid.required(values.contacts_name)) {
    errors.contacts_name = '请输入负责人姓名'
  }

  return errors
}

const formConfig = {
  form: 'ParkingContractRenterForm',
  fields: [
    'name',
    'full_name',
    'contacts_name',
    'contacts_phone',
    'organization_type',
    'user_id',
    'is_create',
    'position_list'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ParkingContractRenterForm)
