import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api } from 'app/services'
import {
  orgActions,
  successState,
  industriesActions,
  raisingsActions
} from 'app/actions'
import {
  KBInput,
  KBRadio,
  KBTextarea,
  KBSelect,
  KBMorePhotoUpload,
  KBForm,
  KBAsyncInputList,
  KBFormError,
  KBButton,
  KBPopover,
  KBImageCropperInput,
  KBLogo
} from 'components'
import { CONTACTS_IDCARD_TYPE } from 'app/constants'
import { ADD_ORGANIZATION } from 'app/constants/hint'
import { routerActions } from 'react-router-redux'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import { getIndustries, getRaisings } from 'app/selectors'
import { isFunwork, listIsNullCallBack } from 'utils/kbUtil'
import { notification } from 'antd'

const invoiceContent = [
  { title: '房租', value: '房租' },
  { title: '水费', value: '水费' },
  { title: '电费', value: '电费' },
  { title: '服务费', value: '服务费' },
  { title: '物业管理费', value: '物业管理费' },
  { title: '会议室使用费', value: '会议室使用费' },
  { title: '网络服务费', value: '网络服务费' },
  { title: '其他费用', value: '其他费用' }
]
const invoiceContentArray = [
  '房租',
  '物业管理费',
  '会议室使用费',
  '水费',
  '电费',
  '服务费',
  '网络服务费',
  ''
]

var OrganizationForm = React.createClass({
  getInitialState() {
    return {}
  },

  componentDidMount() {
    const {
      getIndustriesActions,
      industries,
      raisings,
      getRaisingsActions
    } = this.props
    listIsNullCallBack(industries, () => {
      // 行业列表
      api.getIndustries().then(
        json => {
          getIndustriesActions.success(json)
        },
        error => {
          getIndustriesActions.failure(error)
        }
      )
    })

    listIsNullCallBack(raisings, () => {
      // 融资情况
      api.getRaisings().then(
        json => {
          getRaisingsActions.success(json)
        },
        error => {
          getRaisingsActions.failure(error)
        }
      )
    })
  },

  componentWillMount() {
    const { type } = this.props
    if (type === 'update') {
      return
    }
    const {
      fields: { industry_id, spaceId, is_legal },
      idstry_id,
      space_id
    } = this.props
    if (idstry_id) {
      industry_id.onChange(idstry_id)
    }
    spaceId.onChange(space_id)
    is_legal.onChange(false)
  },

  getNewestUrl(url) {
    let freshUrl = url.split(',')
    let index = freshUrl.length - 1
    return freshUrl[index]
  },

  createOrganizationForm(model) {
    let location_id = this.props.location_id
    const { type, org, status, successActions } = this.props
    if (!model.contacts_idcard_type || !model.contacts_id_number) {
      delete model.contacts_idcard_type
      delete model.contacts_id_number
    }

    let posUrl = model.business_license_positive_url
    let negUrl = model.business_license_negative_url

    if (posUrl) {
      let newPosUrl = this.getNewestUrl(posUrl)
      model.business_license_positive_url = newPosUrl
    }
    if (negUrl) {
      let newNegUrl = this.getNewestUrl(negUrl)
      model.business_license_negative_url = newNegUrl
    }

    delete model.spaceId
    if (type === 'update') {
      return api.updateOrganization(org.id, model).then(json => {
        this.props.updateOrgAction.success(json)
        KBPopover.close()
        return true
      })
    }

    return api.createOrganization(location_id, model).then(resp => {
      if (status != 'organization') {
        this.props.createOrgAction.success(resp, { location_id: location_id })
      }
      let org = getEntitiesJsonArray(resp, 'organizations')
      successActions({ message: '创建公司成功！' })
      this.props.onOrgCreated && this.props.onOrgCreated(org)
      this.props.onSubOrgCreated && this.props.onSubOrgCreated(org)
      KBPopover.close()
    })
  },

  getOpportunitiesOfQuery(str, callback) {
    api.getOpportunities({ query: str }).then(json => {
      const opportunities = getEntitiesJsonArray(json, 'opportunities')
      callback && callback(opportunities)
    })
  },

  failureCallBack(err) {
    if(!err) return
    return notification.error({ message: '上传失败', description: err })
  },

  render() {
    const {
      fields: {
        name,
        full_name,
        industry_id,
        org_contacts_phone,
        business_license_positive_url,
        business_license_negative_url,
        invoice_type,
        auto_invoice,
        notes,
        is_important,
        is_legal,
        opportunity_id,
        fund_raising_id,
        external_visible,
        logo,
        description,
        contacts_name,
        contacts_phone,
        contacts_idcard_type,
        contacts_id_number,
        contacts_email,
        business_license_number,
        spaceId
      },
      error,
      submitting,
      handleSubmit,
      industries,
      raisings,
      org,
      type
    } = this.props
    const {
      status,
      defaultBusinessLicense,
      defaultOtherLicense,
      space_id
    } = this.props
    const { selectedContent } = this.state
    const isCrmRecord = status == 'crmrecord' && opportunity_id.value
    const is_auto_invoice =
      (auto_invoice &&
        (auto_invoice.value == 'true' || auto_invoice.value == true)) ||
      false
    const classNmaeCom = {
      className: 'kb-form-group kb-div-c39'
    }

    const isImportant = [
      { title: '是', value: true },
      { title: '否', value: false }
    ]

    const isInvoiceType = [
      {
        id: '增值税专用发票',
        name: '增值税专用发票'
      },
      {
        id: '增值税普通发票',
        name: '增值税普通发票'
      }
    ]

    const isVisible = [
      { title: '是', value: true },
      { title: '否', value: false }
    ]

    const isLegal = [
      { title: '已确认企业合法性', value: true },
      { title: '未确认企业合法性', value: false }
    ]

    const isAutoInvoice = [
      { id: false, name: '否' },
      { id: true, name: '是' }
    ]

    const hint = ADD_ORGANIZATION
    const defaultImages = []

    return (
      <KBForm
        type="reduxForm"
        fields={this.props.fields}
        onSubmit={handleSubmit(this.createOrganizationForm)}
      >
        <header className="kb-form-header">
          <h2 className="header-title">
            {type == 'update' ? '修改公司' : '添加新公司'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <fieldset className="fieldset">
            <div className="kb-row kb-form-84">
              <KBInput
                className="kb-form-group kb-div-210"
                title="全称"
                hint={hint.full_name}
                isRequired={true}
                fistFoucs={true}
                field={full_name}
              />
              <KBInput
                className="kb-form-group kb-div-48"
                title="简称"
                hint={hint.short_name}
                isRequiredR={true}
                field={name}
                titleClass="label-rt"
              />
            </div>
            <div className="kb-row kb-form-84">
              <KBSelect
                defaultValue="-1"
                className="kb-form-group kb-div-210"
                title="行业"
                field={industry_id}
                arrayData={industries}
              />
              <KBSelect
                defaultValue="-1"
                className="kb-form-group kb-div-48"
                titleClass="label-rt"
                title="阶段"
                field={fund_raising_id}
                arrayData={raisings}
              />
            </div>
            <div className="kb-row kb-form-66">
              <KBRadio
                {...classNmaeCom}
                inClassName="kb-form-group kb-div-33"
                groupStyle={{ width: '100%' }}
                divClassName="kb-form-group kb-div-66"
                rightLabel="label-rt"
                title="重要客户"
                arrayType={isImportant}
                name="lock"
                field={is_important}
              />
              <KBRadio
                className="kb-form-group kb-div-c48"
                inClassName="kb-form-group kb-div-33"
                groupStyle={{ width: '100%' }}
                divClassName="kb-form-group kb-div-66"
                rightLabel="label-rt"
                title="是否外部可见"
                arrayType={isVisible}
                name="external"
                field={external_visible}
              />
              <KBRadio
                className="kb-form-group kb-div-c48 kb-div-c48-active"
                inClassName="kb-form-group kb-div-33-active"
                groupStyle={{ width: '100%' }}
                divClassName="kb-form-group kb-div-66-active"
                rightLabel="label-rt"
                title="企业资质"
                arrayType={isLegal}
                name="legal"
                field={is_legal}
              />
            </div>
            <div className="kb-row">
              <KBTextarea
                className="kb-form-group kb-form-c18 kb-form-alone"
                hint={hint.intro}
                field={description}
                title="简介"
              />
            </div>
            <div className="kb-row">
              <KBTextarea
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={notes}
                title="备注"
              />
            </div>
          </fieldset>
          {/* <fieldset className='fieldset'>
            <legend>发票信息</legend>
            <div className='kb-row kb-form-84'>
              <KBSelect defaultValue='-1' className='kb-form-group kb-div-210' title='发票类型' field={invoice_type} arrayData={isInvoiceType} />
              <KBSelect defaultValue='-1' className='kb-form-group kb-div-66' titleClass='label-rt' title='自动开票' field={auto_invoice} arrayData={isAutoInvoice} />
            </div>
          </fieldset> */}
          <fieldset className="fieldset">
            <legend>负责人信息</legend>
            <div className="kb-row kb-form-66">
              <KBInput {...classNmaeCom} title="姓名" field={contacts_name} />
              <KBInput
                {...classNmaeCom}
                title="手机"
                titleClass="label-rt"
                field={contacts_phone}
                type="number"
              />
            </div>
            <div className="kb-row kb-form-66">
              <KBInput {...classNmaeCom} title="邮箱" field={contacts_email} />
              <KBInput
                {...classNmaeCom}
                field={org_contacts_phone}
                hint={'请输入企业电话'}
                titleClass="label-rt"
                title="电话"
              />
            </div>
            <div className="kb-row kb-form-84">
              <KBInput
                className="kb-form-group kb-div-210"
                field={contacts_id_number}
                title="证件号码"
              />
              <KBSelect
                className="kb-form-group kb-div-66"
                defaultValue="-1"
                title="证件类型"
                titleClass="label-rt"
                field={contacts_idcard_type}
                arrayData={CONTACTS_IDCARD_TYPE}
              />
            </div>
          </fieldset>
          {!isCrmRecord ? (
            <fieldset className="fieldset">
              <legend>客户信息</legend>
              <div className="kb-row">
                <KBAsyncInputList
                  showImg={false}
                  param={{ name: 'title', user: 'customer' }}
                  field={opportunity_id}
                  multi={false}
                  className="kb-form-group kb-form-c18 kb-form-alone"
                  hint="选择关联客户"
                  title="客户"
                  getData={this.getOpportunitiesOfQuery}
                />
              </div>
            </fieldset>
          ) : (
            ''
          )}
          <div className="kb-row">
            <KBImageCropperInput
              title="Logo"
              aspect={1}
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={logo}
              type="origanizations"
              accept="image/*"
              failureCallBack={this.failureCallBack}
              isPublic
            >
              <div className="upload-portrait">
                {!logo.dirty && org && org.name ? (
                  <KBLogo
                    size={100}
                    numberOfChars={4}
                    obj={org}
                    style={{ display: 'inline-block' }}
                  />
                ) : (
                  <img
                    src={
                      logo.value ||
                      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_pic@3x.png'
                    }
                    style={{
                      width: '100px',
                      height: '100px',
                      display: 'inline-block',
                      marginLeft: 0
                    }}
                  />
                )}
                <button className="bordered-btn" type="button">
                  {logo.dirty ? '已上传公司LOGO' : '上传公司LOGO'}
                </button>
              </div>
            </KBImageCropperInput>
          </div>

          <fieldset className="fieldset">
            <legend>公司资料</legend>
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-210 kb-form-alone"
                field={business_license_number}
                isRequired={isFunwork && isFunwork(space_id) ? true : false}
                title="营业执照号"
              />
            </div>
            <div className="kb-row">
              <KBMorePhotoUpload
                defaultImages={defaultBusinessLicense}
                title="营业执照"
                hint="单张上传,大小不大于20M"
                uploadOneTime={true}
                multiple={false}
                photoShow={true}
                className="kb-form-group kb-form-210 kb-form-alone"
                field={business_license_positive_url}
                text="上传营业执照"
                prefix="images/organization/"
                accept="image/*,.pdf"
              />
            </div>
            <div className="kb-row">
              <KBMorePhotoUpload
                defaultImages={defaultOtherLicense}
                title="身份证扫描件"
                hint="单张上传,大小不大于20M"
                uploadOneTime={true}
                multiple={true}
                photoShow={true}
                className="kb-form-group kb-form-210 kb-form-alone"
                field={business_license_negative_url}
                text="上传身份证扫描件"
                prefix="images/organization/"
                accept="image/*,.pdf"
              />
            </div>
          </fieldset>

          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

OrganizationForm.PropTypes = {
  location_id: PropTypes.number.isRequired,
  type: PropTypes.string,
  status: PropTypes.string
}

function mapStateToProps(state, props) {
  const industries = getIndustries(state)
  const raisings = getRaisings(state)
  const org = props.org
  let posUrl = (org && org.business_license_positive_url) || ''
  let negUrl = (org && org.business_license_negative_url) || ''
  let defaultBusinessLicense = posUrl ? [posUrl] : null
  let defaultOtherLicense = negUrl ? [negUrl] : null
  let space_id = Object.keys(state.entities.spaces || {})[0]

  return {
    raisings,
    defaultBusinessLicense,
    defaultOtherLicense,
    industries,
    space_id,
    org
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createOrgAction: bindActionCreators(orgActions.create, dispatch),
    updateOrgAction: bindActionCreators(orgActions.update, dispatch),
    getRaisingsActions: bindActionCreators(raisingsActions.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    successActions: bindActionCreators(successState, dispatch),
    getIndustriesActions: bindActionCreators(industriesActions.get, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入公司简称'
  }

  if (valid.required(values.full_name)) {
    errors.full_name = '请输入公司全称'
  }

  // if (valid.required(values.industry_id)) {
  //   errors.industry_id = '请选择行业类型'
  // }

  // if (valid.required(values.fund_raising_id)) {
  //   errors.fund_raising_id = '请选择公司阶段'
  // }

  //   if (valid.required(values.contacts_name)) {
  //     errors.contacts_name = '请输入负责人姓名'
  //   }

  //   if (valid.required(values.contacts_phone)) {
  //     errors.contacts_phone = '请输入负责人手机号码'
  //   }

  if (values.contacts_phone && valid.isNumber(values.contacts_phone)) {
    errors.contacts_phone = '手机号码只能为数字'
  }

  //  if(values.contacts_id_number&&valid.IDCard(values.contacts_id_number)){
  //      errors.contacts_id_number = '请输入正确的代表人身份证'
  //  }

  if (values.contacts_email && valid.email(values.contacts_email)) {
    errors.contacts_email = '请输入正确的负责人邮箱'
  }

  if (values.org_contacts_phone) {
    if (valid.commonPhoneNumber(values.org_contacts_phone)) {
      errors.org_contacts_phone = '请输入正确的电话,由数字和”-“组成'
    }
  }

  if (
    isFunwork(values.spaceId) &&
    valid.required(values.business_license_number)
  ) {
    errors.business_license_number = '请输入营业执照号'
  }

  return errors
}

const formConfig = {
  form: 'OrganizationForm',
  fields: [
    'name',
    'full_name',
    'notes',
    'business_license_positive_url',
    'business_license_negative_url',
    'is_important',
    'is_legal',
    'opportunity_id',
    'external_visible',
    'industry_id',
    'fund_raising_id',
    'invoice_type',
    'auto_invoice',
    'org_contacts_phone',
    'logo',
    'description',
    'contacts_name',
    'contacts_phone',
    'contacts_idcard_type',
    'contacts_id_number',
    'business_license_number',
    'contacts_email',
    'spaceId'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrganizationForm)
