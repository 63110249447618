import React from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import {
  successState,
  apiCallFailure,
  groupsActions,
  groupSettingActions
} from 'app/actions'
import { KBLoadingContainer } from 'components'
import {
  canAdminLocation,
  canAdminLocationGroup,
  canAdminSpace
} from 'app/reducers/role'
import * as selectors from 'app/selectors'
import { setCurrentLocation } from 'utils/kbUtil'
import _ from 'lodash'

var GroupSettingBox = React.createClass({
  displayName: 'GroupSettingBox',
  getInitialState() {
    return {
      setting: null,
      initing: true
    }
  },
  updateLocation(model) {
    const {
      loc_id,
      group,
      successAction,
      isManager,
      apiCallFailureActions,
      updateGroupsInfoAction
    } = this.props

    if (!isManager) {
      apiCallFailureActions({
        status: 'error',
        message: '您没有此操作权限!'
      })
      return
    }
    return api.putGroupsInfo(loc_id, model).then(json => {
      successAction({ message: '保存成功!' })
      // const updatedLocation = json.response.body
      // setCurrentLocation(updatedLocation)
      updateGroupsInfoAction.success(json)
    })
  },
  updateLocationSetting(model, { key }) {
    const { groupSettingAction, loc_id, successAction } = this.props
    return api
      .putSpaceSetting({
        target_type: 'LocationGroup',
        target_id: loc_id,
        settings: model
      })
      .then(
        json => {
          // console.log(json,'groupsetting');
          // if (json.json.hasOwnProperty('cabinet_settings')) {
          //   groupSettingAction.success(json, {
          //     id: loc_id,
          //     key: 'cabinet_settings'
          //   })
          // } else {
          //   groupSettingAction.success(json, {
          //     id: loc_id,
          //     key: 'meeting_room_settings'
          //   })
          // }
          // if(json.json.hasOwnProperty('invoice_settings')){

          // }
          groupSettingAction.success(json, {
            group_id: loc_id,
            key: key
          })
          successAction({ message: '保存成功!' })
        },
        error => {
          groupSettingAction.failure(error)
        }
      )
  },
  /**
   * 如果已经Call locationinfo 那么不执行ajax
   */
  componentDidMount() {
    api.getGroupsInfo(this.props.loc_id).then(
      json => {
        this.props.getGroupsInfoAction.success(json)
      },
      errors => {
        this.props.getGroupsInfoAction.failure(errors)
      }
    )
    const { getGroupSettingAction, loc_id, routerActions, menu } = this.props

    return api
      .getSpaceSetting({
        target_type: 'LocationGroup',
        target_id: loc_id,
        inherit: true
      })
      .then(
        json => {
          getGroupSettingAction.success(json)
          this.setState({
            initing: false
          })
          if(menu.length === 1) {
            routerActions.replace(
              `/admin/groups/${loc_id}/settings/${menu[0].value}`
            )
          }
        },
        error => {
          getGroupSettingAction.failure(error)
        }
      )


  },
  // componentWillReceiveProps(nextProps) {
  //   const { getGroupSettingAction, loc_id } = this.props
  //   api
  //     .getSpaceSetting({
  //       target_type: 'LocationGroup',
  //       target_id: loc_id,
  //       inherit: true
  //     })
  //     .then(
  //       json => {
  //         const { params } = this.props
  //         params.settings.meeting_room_settings =
  //           json.json.meeting_room_settings
  //         params.settings.cabinet_settings = json.json.cabinet_settings
  //         params.invoice_settings = json.json.invoice_settings
  //         getGroupSettingAction.success(json)
  //       },
  //       errors => {
  //         getGroupSettingAction.success(errors)
  //       }
  //     )
  // },

  render() {
    const {
      loc_id,
      section,
      location,
      menu,
      params,
      provinces,
      cities,
      groupSetting
    } = this.props
    const { settings } = params
    const childrenWithProps = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        updateLocation: this.updateLocation,
        location,
        updateLocationSetting: this.updateLocationSetting,
        setting: settings,
        provinces,
        cities,
        groupSetting
      })
    })
    return (
      <section className="kb-content">
        <nav className="nav-container f-left">
          <div className="nav-container-content">
            <header className="nav-header clear-fix">
              <h2 className="header-title f-left">项目设置</h2>
            </header>
            <div className="nav-body">
              <ul className="nav-container-list">
                {menu.map((menuItem, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        menuItem.value === section
                          ? 'nav-container-list-hover select-list-hover'
                          : ''
                      }
                    >
                      <Link
                        to={`/admin/groups/${loc_id}/settings/${menuItem.value}`}
                      >
                        {menuItem.name}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </nav>
        <section className="nav-section-container f-right">
          <KBLoadingContainer loading={this.state.initing}>{childrenWithProps}</KBLoadingContainer>
        </section>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  const { user } = state
  const { id } = props.params

  const spaceRoles = (JSON.parse(sessionStorage.getItem('current_user')) || {})
    .space_roles

  const adminGroup = spaceRoles.filter(
    x => x.role === 'location_group_operator' && x.location_group_id == id
  )
  const cities = selectors.getCities(state)
  const provinces = selectors.getProvinces(state)
  const canAdminGroup = adminGroup && adminGroup.length > 0 ? true : false
  let sections = location.pathname.split('/')
  let section = sections.length > 4 ? sections[5] || '' : ''
  let currentLocation = selectors.getCurrentGroup(state, id) || {}
  let isManager = canAdminLocation(user, id)
  const isAdmin = canAdminSpace(user)
  // const isLocationGroupManager = canAdminLocationGroup(user)
  let spaceSetting = selectors.getGroupSetting(state)
  const space_roles = user.space_roles

  let menu = [
    {
      name: '基本设置',
      value: ''
    },
    {
      name: '支付设置',
      value: 'payment'
    },
    {
      name: '虚拟货币设置',
      value: 'virtualMoney'
    },
    {
      name: '活动设置',
      value: 'activity'
    },
    {
      name: '会议室设置',
      value: 'meeting'
    },
    {
      name: '储物柜设置',
      value: 'lockers'
    },
    {
      name: '权限设置',
      value: 'role'
    },
    {
      name: '工位设置',
      value: 'station'
    },
    {
      name: '财务设置',
      value: 'finance'
    }
  ]

  if (isAdmin || canAdminGroup) {
    menu.push({
      name: '小程序广告及公告设置',
      value: 'banner'
    })
    menu.push({
      name: '预约参观设置',
      value: 'preview'
    })
    menu.push({
      name: '增值服务设置',
      value: 'valueAddedServices'
    })
  }

  if (
    space_roles &&
    Array.isArray(space_roles) &&
    space_roles.length === 1 &&
    space_roles[0].role === 'location_group_wechat_manager'
  ) {
    menu = [
      {
        name: '基本设置',
        value: ''
      },
      {
        name: '支付设置',
        value: 'payment'
      },
      {
        name: '活动设置',
        value: 'activity'
      },
      {
        name: '会议室设置',
        value: 'meeting'
      },
      {
        name: '工位设置',
        value: 'station'
      },
      {
        name: '小程序广告及公告设置',
        value: 'banner'
      }
    ]
  }

  // 有项目层级只有一个项目内容管理员
  if (
    space_roles &&
    Array.isArray(space_roles)
  ) {
    const locationGroupRoles = space_roles.filter(i => i.role.includes('location_group_') && i.location_group_id == id)
    if(locationGroupRoles.length === 1 && locationGroupRoles[0].role === 'location_group_content_manager') {
      menu = [
        {
          name: '小程序广告及公告设置',
          value: 'banner'
        }
      ]
    }
  }

  let params = {
    settings: {}
  }
  if (!spaceSetting) {
    return params
  }
  params.settings = spaceSetting
  return {
    menu,
    loc_id: id,
    section,
    location: currentLocation,
    isManager,
    params,
    cities,
    provinces,
    groupSetting: state.groupSetting
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getGroupsInfoAction: bindActionCreators(groupsActions.get, dispatch),
    updateGroupsInfoAction: bindActionCreators(groupsActions.update, dispatch),
    groupSettingAction: bindActionCreators(
      groupSettingActions.update,
      dispatch
    ),
    getGroupSettingAction: bindActionCreators(
      groupSettingActions.get,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupSettingBox)
