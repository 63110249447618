import React from 'react'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import {
  paginate,
  queryFilter,
  getFilterAndQuery,
  formatMinuteSecondEN,
  formatYearDayEN,
  kbCloseDropDown,
  getServiceErrorMessage
} from 'utils/kbUtil'
import { apiCallFailure } from 'app/actions'
import {
  KBPopover,
  KBPagination,
  KBLoadingContainer,
  KBTableWithoutData,
  KBAvatar,
  KBPopoverTop,
  KBToReportCenter,
  KBRangeDate
} from 'components'
import { canAccessLocation } from 'app/reducers/role'
import moment from 'moment'

var LockEntryRecord = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      loading: false,
      data: [],
      pagination: null,
      searchTime: filters.start_date || moment().toDate()
    }
  },

  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="进出记录导出"
        message="进出记录列表已成功导出，可以前往报表中心下载"
      />
    )
  },

  exportLockPermissions() {
    const { loc_id, apiCallFailureActions } = this.props
    const { searchTime } = this.state
    let start_date = moment(searchTime)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
    let end_date = moment(searchTime)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
    return api
      .getUserInoutReport({
        location_id: loc_id,
        start_date,
        end_date,
        as_json: false
      })
      .then(
        () => {
          this.toReportCenter && this.toReportCenter()
        },
        error => {
          apiCallFailureActions({
            status: 'error',
            message: getServiceErrorMessage(error)
          })
        }
      )
  },

  componentDidMount() {
    const { searchTime } = this.state
    this.mounted = true
    this.changeDayPicker(searchTime)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page !== nextProps.page) {
      this._loadData(nextProps.page, { ...this.state.filters, ...filters })
    }
  },

  _loadData(page, filters) {
    const { loc_id, apiCallFailureActions, query, routerActions } = this.props
    const per_page = 10
    let pageObj = { per_page, page }
    let params = Object.assign({}, pageObj, filters, {
      location_id: loc_id,
      as_json: true
    })
    let queryFiltersStr = queryFilter({ ...query, ...pageObj, ...filters })
    api
      .getUserInoutReport(params)
      .then(
        json => {
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/locations/${loc_id}/locks/entry_record${queryFiltersStr}`
          )
          let pagination = paginate(json.response)
          let data = json.response.body.body || []

          this.setState({
            pagination,
            data
          })
        },
        errors => {
          apiCallFailureActions({
            status: 'error',
            message: getServiceErrorMessage(errors)
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters, loading: true })

    // force refresh
    this._refresh()
  },

  _refresh() {
    this['filterDate'] && this['filterDate'].hide()

    setTimeout(this.showFiltered)
  },

  _clearFilters() {
    this.setState({ filters: {}, searchTime: '', loading: true })
    // force refresh
    this._refresh()
  },

  showFiltered() {
    const { filters } = this.state

    let params = Object.assign({}, filters)

    this._loadData(filters.page || 1, { ...params })
    this.setState({
      ...params
    })
  },

  changeDayPicker(date, is_last_month) {
    if (!date) {
      return null
    }
    this.changeQueryDateRange({
      from: moment(date)
        .startOf('day')
        .toDate(),
      to: is_last_month
        ? moment(date)
            .endOf('month')
            .endOf('day')
            .toDate()
        : moment(date)
            .endOf('day')
            .toDate()
    })
  },

  changeQueryDateRange(range) {
    if (!(range.from && range.to)) {
      return null
    }
    this.filterDate && this.filterDate.hide()
    this._setFilter({
      start_date: formatMinuteSecondEN(range.from),
      end_date: formatMinuteSecondEN(range.to)
    })
    if (this.mounted) {
      this.setState({
        searchTime: formatYearDayEN(range.from),
        loading: true
      })
    }
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, ['filterDate'], type)
  },

  _renderFilters() {
    const { searchTime, filters } = this.state
    const { start_date, end_date } = filters
    const today = moment().toDate()
    const lastMonthStartDate = moment()
      .subtract(1, 'months')
      .startOf('month')
      .startOf('day')
      .toDate()
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter !== '')

    return (
      <div style={{ paddingTop: 25 }}>
        <div className="time-filter" style={{ margin: '0 20px 0 0' }}>
          <button
            className={
              searchTime && moment(searchTime).isSame(today, 'day')
                ? 'active'
                : ''
            }
            onClick={this.changeDayPicker.bind(null, today)}
          >
            今天
          </button>
          <button
            className={
              searchTime && moment(searchTime).isSame(lastMonthStartDate, 'day')
                ? 'active'
                : ''
            }
            onClick={this.changeDayPicker.bind(null, lastMonthStartDate, true)}
          >
            上月
          </button>
        </div>
        {/* <KBRangeDate
					from={ start_date || '' }
					to={ end_date || '' }
					title='查询日期'
					callback={ this.changeQueryDateRange }
					closeClick={ this.closeDropDowns }
					dropDown_name='filterDate'
					ref={ ref => { this.filterDate = ref }}
					leftNone={ true }
				/> */}
      </div>
    )
  },

  render() {
    const { loading, pagination, data } = this.state
    const { loc_id } = this.props

    return (
      <section className="kb-content-container clear-fix">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>出入记录</span>
          </h2>
          <div className="f-right">
            <button
              className="c-btn-secondary"
              onClick={this.exportLockPermissions}
            >
              导出报表
            </button>
          </div>
        </header>
        <div className="nav-section-content-container">
          {this._renderFilters()}
          <KBLoadingContainer loading={loading}>
            <table className="content-table">
              <thead>
                <tr>
                  <th>日期</th>
                  <th>公司名称</th>
                  <th>租户刷脸次数</th>
                  <th>访客刷脸次数</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={data.length > 0}
                tableHeadNum="6"
                tipMessage="暂无数据"
              >
                {data &&
                  data.map((json, index) => {
                    return (
                      <tr key={index}>
                        <td>{json.date}</td>
                        <td>{json.organization}</td>
                        <td>{json.member_count}</td>
                        <td>{json.visitor_count}</td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
            <KBPagination
              pagination={pagination}
              template={`/admin/locations/${loc_id}/locks/entry_record?page=#PAGE#`}
            />
          </KBLoadingContainer>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const loc_id = params.id

  let isManager = canAccessLocation(state.user, loc_id)

  let page = parseInt(location.query && location.query.page, 10)
  page = isNaN(page) ? 1 : page

  var FILTER_STATE = ['start_date', 'end_date']
  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)

  return {
    loc_id,
    isManager,
    page,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockEntryRecord)
