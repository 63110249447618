import React, { Component, PropTypes } from 'react'
import { KBPopover, KBButton } from 'components'
import { reduxForm } from 'redux-form'

var SelectChargeTypeForm = React.createClass({
  displayName: 'SelectChargeTypeForm',
  getInitialState() {
    const { charge_type } = this.props
    return {
      privateChargeType: charge_type == 'both' ? 'by_desk' : charge_type
    }
  },

  certainChargeType(model) {
    const { callBack } = this.props
    KBPopover.close()
    callBack && callBack(model.charge_type)
  },

  changeEnteringWay(way) {
    const {
      fields: { charge_type }
    } = this.props
    this.setState({ privateChargeType: way })
    charge_type.onChange(way)
  },

  render() {
    const {
      fields: { charge_type },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { privateChargeType } = this.state

    return (
      <form onSubmit={handleSubmit(this.certainChargeType)}>
        <header className="kb-form-header">
          <h2 className="header-title">选择入住率计算方式</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="area-entering-way m-bottom">
            <div>
              <input
                type="radio"
                id="byDesk"
                value="by_desk"
                {...charge_type}
                checked={privateChargeType == 'by_desk' ? 'checked' : false}
                onChange={this.changeEnteringWay.bind(null, 'by_desk')}
              />
              <label htmlFor="byDesk">按工位计算</label>
            </div>
            <div>
              <input
                type="radio"
                id="byArea"
                value="by_area"
                {...charge_type}
                checked={privateChargeType == 'by_area' ? 'checked' : false}
                onChange={this.changeEnteringWay.bind(null, 'by_area')}
              />
              <label htmlFor="byArea">按面积计算</label>
            </div>
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { charge_type } = props
  return {
    initialValues: {
      charge_type: charge_type == 'both' ? 'by_desk' : charge_type
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'SelectChargeTypeForm',
  fields: ['charge_type'],
  validate: validate,
  touchOnBlur: false
}

SelectChargeTypeForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SelectChargeTypeForm)

export default SelectChargeTypeForm
