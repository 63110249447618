import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import { faqFilesActions } from 'app/actions'
import { KBLoadingContainer } from 'components'
import { connect } from 'react-redux'
import { OPPORTUNITY_STATES } from 'app/constants'
import { paginate, fillHttp } from 'utils/kbUtil'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import ProviderServices from './ProviderServices'

var ProviderDetail = React.createClass({
  displayName: 'ProviderDetail',

  serviceDetailJsx() {
    const { serviceProvider } = this.props
    const { cities, website, address } = serviceProvider
    const citiesString = Object.assign([], cities)
      .map(city => city.name)
      .join('、')

    return (
      <div className="staff-details-left border-none m-none">
        <div className="kb-section">
          <header>
            <h2>服务商信息</h2>
          </header>
          <ul>
            <li>
              <span className="section-title">官网网址:</span>
              <a
                className="section-title-content a-hover"
                href={fillHttp(website)}
                target="_blank"
              >
                {website || '无'}
              </a>
            </li>
            <li>
              <span className="section-title">总部地址:</span>
              <span className="section-title-content">{address}</span>
            </li>
          </ul>
        </div>
      </div>
    )
  },

  contactJsx() {
    const { serviceProvider } = this.props
    const {
      contact_name,
      contact_phone,
      contact_wechat,
      contact_email,
      contact_qq
    } = serviceProvider

    return (
      <div className="staff-details-right m-none">
        <div className="kb-section">
          <header>
            <h2>联系人</h2>
          </header>
          <ul>
            <li>
              <span className="section-title">姓名:</span>
              <span className="section-title-content">
                {contact_name || '无'}
              </span>
            </li>
            <li>
              <span className="section-title">手机号:</span>
              <span className="section-title-content">
                {contact_phone || '无'}
              </span>
            </li>
            <li>
              <span className="section-title">微信号:</span>
              <span className="section-title-content">
                {contact_wechat || '无'}
              </span>
            </li>
            <li>
              <span className="section-title">邮件:</span>
              <span className="section-title-content">
                {contact_email || '无'}
              </span>
            </li>
          </ul>
        </div>
      </div>
    )
  },

  servicesJsx() {
    const { serviceProvider, type } = this.props
    const {
      contact_name,
      contact_phone,
      contact_wechat,
      contact_email,
      contact_qq
    } = serviceProvider

    return <ProviderServices serviceProvider={serviceProvider} type={type} />
  },

  providerDetailJsx() {
    const { serviceProvider } = this.props
    const { description } = serviceProvider

    return (
      <div className="img-text">
        <p>{description}</p>
        {/* <img src='' alt='图片'/> */}
      </div>
    )
  },

  noteJsx() {
    const { serviceProvider } = this.props
    const { notes } = serviceProvider

    return (
      <div className="img-text">
        <p>{notes}</p>
      </div>
    )
  },

  render() {
    return (
      <div>
        <div className="clear-fix">
          {this.serviceDetailJsx()}
          {this.contactJsx()}
        </div>
        <div className="kb-title">
          <span>服务类型</span>
        </div>
        {this.servicesJsx()}
        <div className="kb-title m-top-sm">
          <span>详情介绍</span>
        </div>
        {this.providerDetailJsx()}
        <div className="kb-title">
          <span>备注</span>
        </div>
        {this.noteJsx()}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { location } = props
  const query = location.query
  const type = query && query.type
  return {
    type
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDetail)
