import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { successState, reportActions } from 'app/actions'
import {
  KBInput,
  KBDateInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBUserInput,
  KBPopoverTop,
  KBToReportCenter
} from 'components'
import * as selectors from 'app/selectors'
import moment from 'moment'
import * as constants from 'app/constants'

var globalIsLocation = null

let OrdersReport = React.createClass({
  toReportCenter() {
    KBPopoverTop.show(
      <KBToReportCenter
        title="商城订单导出"
        message="订单已成功导出，可以前往报表中心下载"
      />
    )
  },

  invoicesReport(model) {
    const { current_shop_id, products } = this.props

    model.start_date = moment(model.from).format('YYYY-MM-DD HH:mm:ss')
    model.end_date = moment(model.to).format('YYYY-MM-DD HH:mm:ss')
    if (products && products.length) {
      model.invoice_type = 'product_reservation'
    } else {
      delete model.product_id
    }
    return api.exportProductOrders(model).then(res => {
      this.toReportCenter && this.toReportCenter()
      return true
    })
  },

  componentDidMount() {
    const {
      fields: { from, to },
      filters,
      loc_id,
      locations
    } = this.props

    from.onChange(
      (filters && filters.from) ||
        moment()
          .date(1)
          .toDate()
    )
    to.onChange((filters && filters.to) || new Date())
  },

  renderLocation() {
    const {
      fields: { location_id }
    } = this.props
    const { locations, filters } = this.props
    const oneClass = {
      className: 'kb-form-group kb-form-c18 kb-form-alone'
    }

    let hasLocId = filters && filters.location_id ? true : false
    // 获取已选择的分店
    let selectedLoc =
      locations &&
      locations.filter(data => {
        return data.id == filters && filters.location_id
      })
    return (
      <div className="kb-row" onClick={this.setScrollTop}>
        <KBUserInput
          title="分店"
          placeholder={'请选择分店(可多选)'}
          {...oneClass}
          field={location_id}
          hint={
            hasLocId
              ? null
              : '选择订单消费分店，不选则默认导出当前商城所有消费分店订单'
          }
          selectedUsers={selectedLoc}
          multi={true}
          users={locations}
        />
      </div>
    )
  },

  handleScroll(e) {
    return this.formScroll.scrollHeight
  },

  setScrollTop(e) {
    let scrollHeight = this.handleScroll()
    this['taskReportScroll'].scrollTop = scrollHeight
  },

  render() {
    const {
      fields: { location_id, from, to, status, product_id, shop_id },
      error,
      submitting,
      handleSubmit
    } = this.props
    const { locations, filters, products, shops, current_shop_id } = this.props
    const oneClass = {
      className: 'kb-form-group kb-form-c18 kb-form-alone'
    }
    // 获取已选择的 订单进度
    let statusOptions = constants.ORDER_STATE_ARRAY
    let selectedStatus = statusOptions.filter(data => {
      return data.id == filters && filters.status
    })
    // 获取已选择的商品
    let pro_id = (filters && filters.product_id) || ''
    let selectedProduct =
      products &&
      products.filter(data => {
        return data.id == pro_id
      })
    let selectedShop = shops.filter(data => {
      return data.id == current_shop_id
    })

    return (
      <form
        onSubmit={handleSubmit(this.invoicesReport)}
        ref={ref => {
          this.formScroll = ref
        }}
        onScroll={this.handleScroll}
      >
        <header className="kb-form-header">
          <h2 className="header-title">导出商城订单</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <KBFormError error={this.props.error} />
        <div
          className="kb-form-container"
          ref={ref => {
            this.taskReportScroll = ref
          }}
        >
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="开始日期"
              isRequired={true}
              isShowCenter={true}
              className="kb-form-group kb-div-c39"
              field={from}
            />
            <KBDateInput
              type="date"
              title="结束日期"
              titleClass="label-rt"
              isShowCenter={true}
              className="kb-form-group kb-div-c48"
              field={to}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="商城"
              placeholder={'请选择商城'}
              {...oneClass}
              field={shop_id}
              hint={
                current_shop_id ? null : '不选则默认导出当前空间所有商城的订单'
              }
              selectedUsers={selectedShop}
              multi={false}
              disabled={current_shop_id}
              users={shops}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确认导出
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const locations = selectors.getSerializeLocation(state)
  const shops = selectors.getShops(state)
  return {
    locations,
    shops
  }
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  // if (!globalIsLocation && valid.required(values.location_id)) {
  //   errors.location_id = '请选择分店ID'
  // }

  if (valid.required(values.from)) {
    errors.from = '请选择开始时间'
  }

  if (valid.required(values.to)) {
    errors.to = '请选择结束时间'
  }

  const beginDate = moment(values.from)
  const endDate = moment(values.to)

  return errors
}

const formConfig = {
  form: 'OrdersReport',
  fields: [
    'shop_id',
    'from',
    'to',
    'customer_type',
    'customer_id',
    'invoice_type'
  ],
  validate: validate,
  touchOnBlur: false
}

OrdersReport = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrdersReport)

export default OrdersReport
