import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import { api, apiUtils } from 'app/services'
import { KBAvatar } from 'components'
import {
  locationActions,
  getLocationMembersAction,
  floorplansAction,
  locationRoleAction,
  locationSettingAction,
  areaActions,
  reservationsActions,
  loadingAction,
  meetingsActions,
  orgActions,
  apiCallFailure
} from 'app/actions'
import classNames from 'classnames'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  ORG_SUBSCRIPTION_STATES,
  ORG_SUBSCRIPTION_STATES_CLASS,
  INVOICES_STATE,
  INVOICES_STATE_CLASS,
  OFFICE_AREA_STATE
} from 'app/constants'
import { util, isHideModule, groupLocationsByCity } from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import {
  canAdminSpace,
  canAccessLocationRoles,
  canSpaceAdmin,
  canAccessLocation,
  canAccessLocationObserver,
  canAccessLocationOnlyFinance,
  canAccessLocationRepairOnly
} from 'app/reducers/role'
import * as kbData from 'utils/kbData'
import _ from 'lodash'
import { getUserInfoUrl } from 'utils/kbUrl'

const DEFAULT_LOCATION_MENU = [
  {
    label: '首页0-0-',
    section: '',
    roles: [
      'location_operator',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'observer',
      'finance',
      'sales',
      'contract'
    ]
  },
  {
    label: '公司',
    section: '/organizations',
    roles: [
      'location_operator',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'observer',
      'finance',
      'sales',
      'contract'
    ]
  },
  {
    label: '工位',
    section: '/areas/desks/list',
    roles: [
      'location_operator',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'observer',
      'sales',
      'contract'
    ]
  },
  {
    label: '会员',
    section: '/members',
    roles: [
      'location_operator',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'observer'
    ]
  },
  {
    label: '任务',
    section: '/tasks/repair/type/my',
    roles: [
      'location_operator',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'observer',
      'repair'
    ]
  },
  {
    label: '财务',
    section: '/invoices',
    roles: [
      'location_manager',
      // 'location_group_manager',
      'location_group_operator',
      'observer',
      'finance',
      'location_operator',
      'sales',
      'contract'
    ]
  },
  {
    label: '场地',
    section: '/meeting',
    roles: [
      'location_operator',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'observer'
    ]
  },
  {
    label: '门禁',
    section: '/locks',
    roles: [
      'location_operator',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'observer',
      'sales',
      'visitor'
    ]
  },
  // {label: '平面图', section: '/floorplan', roles: ['location_manager', 'location_operator']},
  {
    label: '访客',
    section: '/visit',
    roles: [
      'location_manager',
      // 'location_group_manager',
      'location_group_operator',
      'observer',
      'location_operator',
      'repair'
    ]
  },
  {
    label: '订单',
    section: '/location_orders',
    roles: [
      'location_manager',
      // 'location_group_manager',
      'location_group_operator',
      'observer',
      'finance',
      'location_operator'
    ]
  },
  {
    label: '活动',
    section: '/activities',
    roles: [
      'location_manager',
      // 'location_group_manager',
      'location_group_operator',
      'location_operator'
    ]
  },
  // {label: '资讯', section: '/news', roles: ['location_manager', 'location_operator']},
  {
    label: '设置',
    section: '/settings',
    roles: [
      'location_manager',
      // 'location_group_manager',
      'location_group_operator',
      'observer'
    ]
  }
  // {label: '数据统计', section: '/statistic', roles: ['admin']}
]
const HIDE_MODULE = [
  // {label: '访客', section: '/visit', roles: ['location_manager','location_group_manager','location_group_operator', 'observer', 'finance', 'location_operator']},
  {
    label: '任务',
    section: '/tasks/repair/type/my',
    roles: [
      'location_operator',
      // 'location_group_manager',
      'location_group_operator',
      'location_manager',
      'observer',
      'repair'
    ]
  },
  {
    label: '访客',
    section: '/visit',
    roles: [
      'location_manager',
      // 'location_group_manager',
      'location_group_operator',
      'observer',
      'location_operator',
      'repair'
    ]
  },
  {
    label: '订单',
    section: '/location_orders',
    roles: [
      'location_manager',
      // 'location_group_manager',
      'location_group_operator',
      'observer',
      'finance',
      'location_operator'
    ]
  },
  {
    label: '活动',
    section: '/activities',
    roles: [
      'location_manager',
      // 'location_group_manager',
      'location_group_operator',
      'location_operator'
    ]
  },
  {
    label: '资讯',
    section: '/news',
    roles: [
      'location_manager',
      // 'location_group_manager',
      'location_group_operator',
      'location_operator'
    ]
  }
  // {label: '资讯', section: '/news', roles: ['location_manager', 'location_operator']}
]

var LocationBox = React.createClass({
  displayName: 'LocationBox',
  getInitialState() {
    return {
      selectState: false,
      dropDownState: false,
      organization_members: [],
      organizations: [],
      invoices: [],
      contracts: [],
      desks: [],
      searchType: 'org_user',
      searchResult: [],
      searchingOrgs: false,
      searchResultStatus: true
    }
  },

  changeParentScrollTop() {
    ReactDOM.findDOMNode(this).scrollTop = 0
  },

  componentDidMount() {
    const { location_id, locs, isRepair, routerActions } = this.props
    if (locs && locs.length > 0 && !locs.includes(String(location_id))) {
      return this.noLocExist()
    }

    locs && locs.length > 0 && this._loadData(location_id)

    if (isRepair) {
      routerActions.replace(
        `/admin/locations/${location_id}/tasks/repair/type/my`
      )
    }
  },

  componentWillReceiveProps(nextProps) {
    const { location_id, locs } = this.props

    if (
      nextProps.locs &&
      nextProps.locs.length > 0 &&
      !_.isEqual(locs, nextProps.locs) &&
      !nextProps.locs.includes(String(nextProps.location_id))
    ) {
      return this.noLocExist()
    }

    if (!_.isEqual(locs, nextProps.locs)) {
      return this._loadData(nextProps.location_id)
    }

    if (!locs.includes(String(location_id))) {
      return
    } else if (this.props.location_id != nextProps.location_id) {
      return this._loadData(nextProps.location_id)
    }
  },

  _loadData(location_id) {
    const {
      getOrganizationsAction,
      getLocationInfo,
      locationSettingActions,
      locationRoleActions,
      getFloorPlansAction,
      getAreasAction,
      updateLoadingAction,
      createLoadingAction,
      getReservationsAction,
      getMeetingsAction,
      isManager,
      isFinance,
      isRepair,
      user,
      section
    } = this.props
    if (isRepair) {
      return null
    }

    api.getLocationInfo(location_id).then(
      json => {
        getLocationInfo.success(json)
      },
      errors => {
        getLocationInfo.failure(errors)
      }
    )

    if (section !== 'members') {
      api
        .getUsers({
          location_id,
          page: 1,
          per_page: 1000,
          includes: 'space_users'
        })
        .then(
          ({ json }) => {
            this.props.getLocationMembersAction.success(
              { json },
              { location_id: location_id }
            )
          },
          errors => {
            this.props.getLocationMembersAction.failure(errors)
          }
        )
    }

    if (canAccessLocation(user, location_id)) {
      // api.getFloorPlans(location_id, { per_page: 1000 }).then(json => {
      //   getFloorPlansAction.success(json)
      // }, errors => {
      //   getFloorPlansAction.failure(errors)
      // })
    }

    api
      .getSpaceSetting({
        target_type: 'Location',
        target_id: location_id,
        inherit: true
      })
      .then(
        json => {
          locationSettingActions.success(json, { location_id })
        },
        error => {
          locationSettingActions.failure(error)
        }
      )

    api
      .getSpaceRoles({
        location_id
      })
      .then(
        json => {
          locationRoleActions.success(json, { location_id })
        },
        error => {
          locationRoleActions.failure(error)
        }
      )
  },

  triggerSelectLocation() {
    this.setState({ selectState: !this.state.selectState })
  },

  selectServer() {
    this.setState({ selectState: false })
  },

  selectKbOutBox() {
    this.setState({ selectState: false, dropDownState: false })
  },

  hideDropDown() {
    this.setState({
      dropDownState: false
    })
  },

  showDropDown() {
    this.setState({
      dropDownState: true
    })
  },

  searchDown() {},

  changeDropDown(e) {
    const value = e.target.value
    const { location_id } = this.props
    const { searchType, searchResultStatus } = this.state

    if (!value) {
      this.setState({
        organizations: [],
        organization_members: []
      })
      return
    }
    this.setState({
      searchResultStatus: true
    })

    util.setTimeout(
      'locationSearchInput',
      () => {
        //value
        this.setState({ searchingOrgs: true })
        api
          .getSearchAllType({ location_id, type: searchType, query: value })
          .then(
            ({ json }) => {
              let searchResult = json.map(result => {
                result['searchType'] = searchType
              })
              this.setState({
                // organizations : json.organizations,
                // organization_members : json.organization_members,
                searchResult: json
              })
            },
            errors => {}
          )
          .finally(() => {
            this.setState({ searchingOrgs: false })
          })
      },
      300
    )
  },

  _renderLocationPicker(locations, cities, location_id) {
    let locationsByCity = groupLocationsByCity(locations)
    return (
      <div className="bar-drop-down">
        {Object.keys(locationsByCity).map((city_id, index) => {
          let city = cities[city_id]
          let cityLocations = locationsByCity[city_id]
          return (
            <div className="city-list" key={index + '' + city_id}>
              <header className="city-name">{city.name}</header>
              <ul className="company-name clear-fix">
                {cityLocations &&
                  cityLocations.map((location, index) => (
                    <li key={index}>
                      <Link
                        onClick={this.triggerSelectLocation}
                        to={`/admin/locations/${location.id}`}
                      >
                        {location.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          )
        })}
      </div>
    )
  },

  _renderMenu() {
    const { section, user, location_id, space_id } = this.props
    let menu = DEFAULT_LOCATION_MENU

    if (isHideModule(space_id)) {
      menu = _.differenceWith(DEFAULT_LOCATION_MENU, HIDE_MODULE, _.isEqual)
    }
    return (
      <div className="sub-nav-list clear-fix">
        {menu.map((menu_item, index) => {
          if (
            !canAdminSpace(user) &&
            !canAccessLocationRoles(user, location_id, menu_item.roles)
          ) {
            return
          }

          if (!canSpaceAdmin(user, menu_item.roles)) {
            return
          }

          let current =
            menu_item.section.indexOf('/') == -1
              ? menu_item.section
              : menu_item.section.split('/')[1]
          let active = current === section ? true : false
          return (
            <Link
              onClick={this.selectServer}
              key={index + menu_item.label}
              className={classNames({ active: active })}
              to={`/admin/locations/${location_id}${menu_item.section}`}
            >
              {menu_item.label}
            </Link>
          )
        })}
      </div>
    )
  },

  closeResult() {
    this.setState({
      searchResultStatus: false
    })
  },

  _renderSearch() {
    const {
      dropDownState,
      organizations,
      organization_members,
      searchResult
    } = this.state
    const { location_id, isObserver, isManager, isRepair } = this.props

    if (isRepair) {
      return null
    }

    if (!dropDownState) {
      return null
    }
    return searchResult.length ? (
      <div className="search-staff-company">
        <div className="company-staff-result">
          <ul className="add-staff-list search-company-result">
            {searchResult.map((result, index) => {
              if (result.searchType == 'contract') {
                return (
                  <li key={index} className="t-left" onClick={this.closeResult}>
                    <Link
                      to={`/admin/locations/${location_id}/organizations/${result.organization_id}/subscriptions/${result.id}`}
                    >
                      <span
                        className={`${
                          ORG_SUBSCRIPTION_STATES_CLASS[result.status]
                        } receipt-status f-left`}
                      >
                        {ORG_SUBSCRIPTION_STATES[result.status]}
                      </span>
                      <span
                        className="f-left"
                        style={{ marginLeft: 10, marginTop: 2 }}
                      >
                        {result.serial}
                      </span>
                    </Link>
                  </li>
                )
              }
              if (result.searchType == 'invoice') {
                return (
                  <li key={index} onClick={this.closeResult}>
                    <Link
                      to={`/admin/locations/${location_id}/invoices/${result.id}`}
                    >
                      <span
                        className={`${
                          INVOICES_STATE_CLASS[result.status]
                        } receipt-status f-left`}
                      >
                        {INVOICES_STATE[result.status]}
                      </span>
                      <span
                        className="f-left"
                        style={{ marginLeft: 10, marginTop: 2 }}
                      >
                        {result && result.serial_number}
                      </span>
                    </Link>
                  </li>
                )
              }
              if (result.searchType == 'desk') {
                return (
                  <li key={index} className="t-left" onClick={this.closeResult}>
                    <Link
                      to={`/admin/locations/${location_id}/desks?area_id=${result.area.id}`}
                    >
                      <span style={{ marginRight: 10 }}>
                        {result.area.name}
                      </span>
                      <span style={{ marginRight: 10 }}>
                        {OFFICE_AREA_STATE[result.area.area_type]}
                      </span>
                      <span>工位号: {result && result.serial_number}</span>
                    </Link>
                  </li>
                )
              }
              if (result.searchType == 'org') {
                return (
                  <li key={index} onClick={this.closeResult}>
                    <Link
                      to={`/admin/locations/${location_id}/organizations/${result.id}`}
                    >
                      <div className="add-staff-portrait">
                        <KBAvatar user={{ avatar: result.logo }} size={30} />
                      </div>
                      <span className="search-company-name">{result.name}</span>
                    </Link>
                  </li>
                )
              }
              if (result.searchType == 'org_user') {
                return (
                  <li key={index} onClick={this.closeResult}>
                    <Link to={getUserInfoUrl(result.id)}>
                      <div className="add-staff-portrait">
                        <KBAvatar user={{ avatar: result.avatar }} size={30} />
                      </div>
                      <span className="search-company-name">{result.name}</span>
                    </Link>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </div>
    ) : (
      <div className="search-staff-company">
        <div className="company-staff-result">
          <div style={{ padding: 20 }}>
            {this.state.searchingOrgs ? '正在搜索' : '输入搜索关键字'}
          </div>
        </div>
      </div>
    )
  },

  selectSearchType(type) {
    this.setState({
      searchType: type.id
    })
    this.refs['search_typeDropdown'].hide()
  },

  noLocExist() {
    const { apiCallFailureActions } = this.props

    return apiCallFailureActions({
      status: 'error',
      message: '访问的分店在当前用户权限下不存在'
    })
  },

  render() {
    const {
      selectState,
      dropDownState,
      organizations,
      organization_members,
      searchType,
      searchResultStatus
    } = this.state
    const {
      location_id,
      locations,
      cities,
      section,
      currentLocation,
      user,
      locs,
      isRepair
    } = this.props

    if (!locs.includes(String(location_id))) {
      return <div></div>
    }

    if (!locations.length) {
      return <div></div>
    }

    return (
      <div className="kb-out-box clear-fix">
        {/**
          <div className="sub-nav-bar" style={{minWidth:1450}}>
          <div style={{position: 'relative'}}>
            <div className="bar-address">
              <a href="javascript:void(0)" onClick={ this.triggerSelectLocation }>
                <span>{ currentLocation && currentLocation.name }</span>
                {
                  currentLocation.public ? '' : <span className="badge" style={{borderColor:'#dd5a55',color:'#dd5a55'}}>尚未启用</span>
                }
                <i className={ `iconfont ${selectState ? "icon-arrow_drop_down_circle" : "icon-keyboard_arrow_right"}`} />
              </a>
            </div>
            {
              selectState && this._renderLocationPicker(locations, cities, location_id)
            }
            { this._renderMenu()}
            {
              !isRepair ?
              <div className="sub-nav-search">
                <KBDropdown ref='search_typeDropdown'>
                  <KBDropdown.KBDropdownTrigger>
                    <div className="search-type">
                      <span>{ SEARCH_TYPE_CONTENT[searchType] }</span>
                      <i className='iconfont icon-arrow_drop_down_circle' style={{marginLeft: 2,fontSize: 10}}/>
                    </div>
                  </KBDropdown.KBDropdownTrigger>
                  <KBDropdown.KBDropdownContent style={{marginTop: 15, zIndex: 11}}>
                      <ul className="search-type_content">
                        {
                          SEARCH_TYPE.map((type,index) => {
                            return(
                              <li key={ index }  onClick={this.selectSearchType.bind(null,type)}>{ type.name }</li>
                            )
                          })
                        }
                      </ul>
                  </KBDropdown.KBDropdownContent>
                </KBDropdown>
                <input type="text"  onKeyDown={ this.searchDown } onFocus={ this.showDropDown } onChange={ this.changeDropDown } />
              </div>
              : null
            }
            { searchResultStatus && this._renderSearch()}
          </div>
          </div> **/}
        {this.props.children && (
          <ReactCSSTransitionGroup
            onClick={this.selectKbOutBox}
            className="margin-top-lg"
            component="div"
            transitionName="example"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
            transitionLeave={false}
          >
            {React.cloneElement(this.props.children, {
              key: this.props.pathname,
              changeParentScrollTop: this.changeParentScrollTop
            })}
          </ReactCSSTransitionGroup>
        )}
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let sections = props.location.pathname.split('/')
  let section = sections.length > 4 ? sections[4] : ''
  let location_id = props.params.id
  let cities = selectors.getCities(state)
  let locations = selectors.getLocations(state)
  let currentLocation = selectors.getLocation(state, location_id)
  let user = selectors.getCurrentUser(state)
  var pathname = props.location.pathname.split('/')
  let isManager = canAccessLocation(user, location_id)
  let isObserver = canAccessLocationObserver(user, location_id)
  let isFinance = canAccessLocationOnlyFinance(user, location_id)
  let isRepair = canAccessLocationRepairOnly(user, location_id)
  let space_id = Object.keys(state.entities.spaces || {})[0]
  let locs = []
  locations &&
    locations.map(data => {
      locs.push(String(data.id))
    })
  if (pathname.length > 5) {
    pathname = pathname.slice(0, 5)
  }

  return {
    section,
    pathname: pathname.join('/'),
    cities,
    location_id,
    locations,
    currentLocation,
    space_id,
    user,
    isManager,
    isObserver,
    isFinance,
    isRepair,
    locs
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getLocationInfo: bindActionCreators(locationActions.update, dispatch),
    getLocationMembersAction: bindActionCreators(
      getLocationMembersAction,
      dispatch
    ),
    getOrganizationsAction: bindActionCreators(orgActions.get, dispatch),
    getFloorPlansAction: bindActionCreators(floorplansAction.get, dispatch),
    createLoadingAction: bindActionCreators(loadingAction.create, dispatch),
    updateLoadingAction: bindActionCreators(loadingAction.update, dispatch),
    getAreasAction: bindActionCreators(areaActions.get, dispatch),
    getReservationsAction: bindActionCreators(
      reservationsActions.all,
      dispatch
    ),
    getMeetingsAction: bindActionCreators(meetingsActions.get, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    locationSettingActions: bindActionCreators(
      locationSettingAction.get,
      dispatch
    ),
    locationRoleActions: bindActionCreators(locationRoleAction.get, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationBox)
