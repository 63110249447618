import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { apiCallFailure } from 'app/actions'
import { api } from 'app/services'
import * as selectors from 'app/selectors'
import { KBInput, KBSelect, KBButton, KBPopover, KBForm } from 'components'
import { getServiceErrorMessage } from 'utils/kbUtil'
import intl from 'react-intl-universal'

var DelLockerCabinetForm = React.createClass({
  deleteCabinet(model) {
    const { callBack, cabinet, apiCallFailureActions } = this.props
    model.cabinet_id = cabinet.cabinet.id
    model.id = cabinet.id
    return api.cleanupLockers(cabinet.cabinet.id, cabinet.id, model).then(
      () => {
        callBack && callBack(cabinet.cabinet.id, true)
        KBPopover.close()
      },
      error => {
        const errorMessage = getServiceErrorMessage(error)
        apiCallFailureActions({
          status: 'error',
          message: errorMessage
        })
      }
    )
  },

  render() {
    const {
      fields: { note },
      handleSubmit,
      submitting
    } = this.props

    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.deleteCabinet)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">结束使用</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={note}
              title="原因"
              isRequired={true}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.note)) {
    errors.note = '请输入锁控板号'
  }

  return errors
}

const formConfig = {
  form: 'DelLockerCabinetForm',
  fields: ['note'],
  validate: validate,
  touchOnBlur: false
}

DelLockerCabinetForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(DelLockerCabinetForm)

export default DelLockerCabinetForm
