import React, { Component } from 'react'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import * as selectors from 'app/selectors'
import { api, apiUtils } from 'app/services'
import { productCategoriesActions, successState } from 'app/actions'
import {
  KBPopover,
  KBPopoverTop,
  KBError,
  KBSuccess,
  KBDropdown,
  KBTipsy,
  KBTableWithoutData
} from 'components'
import ProductCategoriesForm from './ProductCategoriesForm'
import { canAccessSpaceShop } from 'app/reducers/role'

var ProductCategories = React.createClass({
  getInitialState() {
    return {
      selectState: true
    }
  },
  componentDidMount() {
    const { productCategoriesActions, shopping_id } = this.props

    api.getProductCategories({ shop_id: shopping_id, per_page: 50 }).then(
      json => {
        productCategoriesActions.success(json)
      },
      errors => {
        productCategoriesActions.failure(errors)
      }
    )
  },
  componentWillReceiveProps(nextProps) {},

  dropDownClick(id) {
    const { productCategories } = this.props
    productCategories.forEach((Category, index) => {
      if (Category.id == id) {
        return
      }
      let kbDropDownDom = this.refs[`KBProductDropDwon${Category.id}`]
      kbDropDownDom && kbDropDownDom.hide()
    })
  },
  updateProductCategories(product) {
    KBPopover.show(
      <ProductCategoriesForm
        initialValues={product}
        categories={product}
        state="update"
      />
    )
  },
  deleteProductCategories(product) {
    const { deleteProductCategoriesActions, successAction } = this.props
    KBPopover.plugins.confirm(
      '删除商品分类',
      `您确定要删除当前商品分类${product.name}吗?`,
      {
        confirm: () => {
          api.deleteProductCategories(product.id).then(
            json => {
              deleteProductCategoriesActions.success(json, {
                key: 'productCategories',
                id: product.id
              })
              successAction({ message: '删除商品分类成功!' })
              KBPopover.close()
            },
            error => {
              deleteProductCategoriesActions.failure(error)
            }
          )
        }
      }
    )
  },
  render() {
    const { productCategories, user } = this.props

    return (
      <div style={{ padding: '20px 0' }}>
        <div className="product-categories">
          <table style={{ width: '100%' }}>
            <KBTableWithoutData
              hasData={productCategories.length > 0}
              tableHeadNum="1"
              tipMessage="暂无分类信息"
            >
              {productCategories &&
                productCategories.map(product => {
                  const { id } = product
                  return (
                    <KBDropdown ref={`KBProductDropDwon${id}`} key={id}>
                      <KBDropdown.KBDropdownTrigger>
                        <KBTipsy content={product.name}>
                          <div
                            className="category-block"
                            style={{ marginRight: 20 }}
                            onClick={this.dropDownClick.bind(null, id)}
                          >
                            <span>{product.name}</span>
                          </div>
                        </KBTipsy>
                      </KBDropdown.KBDropdownTrigger>
                      <KBDropdown.KBDropdownContent
                        style={{
                          left: '50%',
                          transform: 'translateX(-50%)',
                          top: 95
                        }}
                      >
                        {canAccessSpaceShop(user) ? (
                          <div className="user-dropdown">
                            <a
                              onClick={this.updateProductCategories.bind(
                                null,
                                product
                              )}
                            >
                              修改
                            </a>
                            <a
                              onClick={this.deleteProductCategories.bind(
                                null,
                                product
                              )}
                            >
                              删除
                            </a>
                          </div>
                        ) : null}
                      </KBDropdown.KBDropdownContent>
                    </KBDropdown>
                  )
                })}
            </KBTableWithoutData>
          </table>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let shopping_id = parseInt(props.params.shopping_id)
  const productCategories = selectors.getProductCategories(state, shopping_id)
  let user = selectors.getCurrentUser(state)
  return {
    productCategories,
    shopping_id,
    user
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    deleteProductCategoriesActions: bindActionCreators(
      productCategoriesActions.delete,
      dispatch
    ),
    productCategoriesActions: bindActionCreators(
      productCategoriesActions.get,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategories)
