import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import { api, apiUtils } from 'app/services'
import {
  apiCallFailure,
  areaActions,
  orgActions,
  subscriptionsAction
} from 'app/actions'
import * as selectors from 'app/selectors'
import {
  KBPopover,
  KBCheckBox,
  KBFormError,
  KBButton,
  KBLoadingContainer,
  KBTipsy,
  KBAvatar
} from 'components'
import moment from 'moment'
import _ from 'lodash'
import {
  ORG_SUBSCRIPTION_STATES,
  ORG_SUBSCRIPTION_STATES_CLASS_NEW
} from 'app/constants'
import OrgSubscriptionDetail from './OrgSubscriptionDetail'
import SelectDeskNumForm from './SelectDeskNumForm'

var OrgSubscriptionAreasForm = React.createClass({
  getInitialState() {
    const { charge_type } = this.props
    return {
      selectAreas: [],
      selectedAreaIds: [],
      loading: true,
      chargeType: charge_type == 'both' ? 'by_desk' : charge_type,
      showAreaSub: {},
      areaSubDetail: {},
      isSelectDeskNum: false
    }
  },

  componentDidMount() {
    this._loadAreas()
  },

  _loadAreas() {
    this.setState({
      loading: true
    })
    const {
      loc_id,
      getAreasAction,
      startDateValue,
      endDateValue,
      type
    } = this.props
    let startDateValue_n = moment(startDateValue).format('YYYY-MM-DD')
    let endDateValue_n = moment(endDateValue).format('YYYY-MM-DD')
    let area_type =
      type === 'flexible_office'
        ? 'flexible_office'
        : type === 'public'
        ? 'public_office'
        : 'private_office'
    api
      .getAreas({
        location_id: loc_id,
        area_type,
        per_page: 500,
        time: new Date().getTime(),
        includes: 'occupancy,subscriptions_and_orgs',
        subscription_start_date: startDateValue_n,
        subscription_end_date: endDateValue_n,
        start_date: startDateValue_n,
        end_date: endDateValue_n
      })
      .then(
        json => {
          getAreasAction.success(json, { loc_id })
        },
        error => {
          getAreasAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
          showAreaSub: {},
          areaSubDetail: {}
        })
      })
  },

  loadSubscription(subscription_id) {
    api.getSubscription(subscription_id).then(
      json => {
        this.props.getSubscriptionAction.success(json)
        let subscription = json.response.body
        let loc_id = subscription && subscription.location_id
        let sales_customer = (subscription && subscription.sales_customer) || {}
        if (sales_customer.foreign_type == 'org') {
          this.getOrgInfo(loc_id, sales_customer.foreign_id)
        }
      },
      errors => {
        this.props.getSubscriptionAction.failure(errors)
      }
    )
  },

  getOrgInfo(loc_id, org_id) {
    api
      .getOrganizationInfo(org_id, {
        location_id: loc_id
      })
      .then(
        json =>
          this.props.getOrgAction.success(json, {
            organization_id: org_id,
            location_id: loc_id
          }),
        errors => this.props.getOrgAction.failure(errors)
      )
  },

  selectArea() {
    const { callBack, apiCallFailureActions, isReplace, type } = this.props
    const { selectAreas, chargeType } = this.state
    if (selectAreas.length === 0) {
      return apiCallFailureActions({
        status: 422,
        message: '请选择分区'
      })
    }
    let callBackAreas = isReplace ? selectAreas[0] || {} : selectAreas
    callBack && callBack(callBackAreas, chargeType)
    KBPopover.close()
  },

  clickArea(area) {
    const { isReplace } = this.props
    const { occupied, free_desks_count, isOwnContract } = area
    const { selectAreas, selectedAreaIds, showAreaSub } = this.state
    // 点击已占用分区时执行
    if (!isOwnContract && (occupied || free_desks_count <= 0)) {
      let newShowAreaSub = {}
      newShowAreaSub[String(area.id)] = !showAreaSub[String(area.id)]
      this.setState({
        showAreaSub: newShowAreaSub
      })
      return
    }
    console.log(area,'subscription=====area');
    // 替换分区时执行
    if (isReplace) {
      let newSelectedAreas = []
      let newSelectedIds = []
      newSelectedAreas.push(area)
      newSelectedIds.push(area.id)
      this.setState({
        selectAreas: newSelectedAreas,
        selectedAreaIds: newSelectedIds
      })
      return
    }

    if (selectedAreaIds.includes(area.id)) {
      selectedAreaIds.splice(
        selectedAreaIds.findIndex(a_id => {
          return a_id === area.id
        }),
        1
      )
      selectAreas.splice(
        selectAreas.findIndex(a => {
          return a.id === area.id
        }),
        1
      )
    } else {
      selectedAreaIds.push(area.id)
      selectAreas.push(area)
    }
    this.setState({
      selectAreas,
      selectedAreaIds,
      showAreaSub: {}
    })
  },

  changeEnteringWay(way) {
    this.setState({
      chargeType: way
    })
  },

  showAreaSubDetail(area, org, sub) {
    const { areaSubDetail } = this.state
    areaSubDetail.org_id = org.id
    areaSubDetail.location_id = area.location_id
    areaSubDetail.subscription_id = sub.id
    this.setState({
      areaSubDetail
    })
  },

  render() {
    const {
      error,
      submitting,
      handleSubmit,
      areas,
      type,
      contract_id,
      charge_type
    } = this.props
    const {
      selectedAreaIds,
      loading,
      chargeType,
      showAreaSub,
      areaSubDetail
    } = this.state
    const stateType = ORG_SUBSCRIPTION_STATES
    const stateType_class = ORG_SUBSCRIPTION_STATES_CLASS_NEW
    return (
      <div>
        <form onSubmit={handleSubmit(this.selectArea)}>
          <header className="kb-form-header">
            <h2 className="header-title">选择分区</h2>
            <i
              className="iconfont header-close icon-cancel"
              onClick={KBPopover.close}
            />
            <KBFormError error={this.props.error} />
          </header>
          <div
            className="kb-form-container"
            style={{ overflow: 'initial', maxHeight: 'none' }}
          >
            <KBLoadingContainer loading={loading}>
              <div className="clear-fix m-bottom-sm">
                {/*
                charge_type == 'both' && type == 'private' ? <div className="area-entering-way">
                  <div>
                    <input type="radio" id="byDesk" name="byDesk" checked={ chargeType == 'by_desk' ? 'checked' : false } onChange={ this.changeEnteringWay.bind(null, 'by_desk') }/>
                    <label htmlFor="byDesk">按工位计算</label>
                  </div>
                  <div>
                    <input type="radio" id="byArea" name="byDesk" onChange={ this.changeEnteringWay.bind(null, 'by_area') }/>
                    <label htmlFor="byArea">按面积计算</label>
                  </div>
                </div> : ''
              */}
                <div className="area-status f-right">
                  <div className="status-title">
                    <span
                      style={{
                        backgroundColor: '#FFF',
                        border: '1px solid #DFE2E5'
                      }}
                    />
                    <span>可使用</span>
                  </div>
                  <div className="status-title">
                    <span style={{ backgroundColor: '#c8c8c8' }} />
                    <span>已占用</span>
                  </div>
                </div>
              </div>
              {areas && areas.length > 0 ? (
                <div className="kb-row clear-fix">
                  {areas.map((area, index) => {
                    const {
                      id,
                      name,
                      size,
                      occupied,
                      free_desks_count,
                      contract,
                      isSelectedArea,
                      isOwnContract,
                      subscriptions
                    } = area
                    const isAreaOccupied = occupied || free_desks_count <= 0
                    return (
                      <KBTipsy content={name}>
                        <div
                          key={index}
                          className={`area-block ${
                            selectedAreaIds.includes(id)
                              ? 'area-block_active'
                              : ''
                          } ${
                            !isOwnContract && isAreaOccupied
                              ? ''
                              : 'area-block_hover'
                          } ${
                            isAreaOccupied
                              ? 'area-block_occupied'
                              : 'area-block_occupied'
                          }`}
                          style={
                            !isOwnContract && isAreaOccupied
                              ? { backgroundColor: '#f7f7f7', color: '#c8c8c8' }
                              : {
                                  backgroundColor: '#FFF',
                                  color: '#354052',
                                  border: '1px solid #DFE2E5'
                                }
                          }
                          onClick={this.clickArea.bind(null, area)}
                        >
                          <div
                            className="area-name t-over"
                            style={{ maxWidth: 82 }}
                          >
                            {name}
                          </div>
                          {type === 'flexible_office' ? null : (
                            <span className="area-desk-num">
                              {!isOwnContract && isAreaOccupied
                                ? '已占用'
                                : chargeType === 'by_desk'
                                ? `剩余${free_desks_count || 0}个工位`
                                : `面积:${size ? size + '平米' : '暂无'}`}
                            </span>
                          )}
                          {selectedAreaIds.includes(id) ? (
                            <i
                              className="iconfont icon-right"
                              style={{ position: 'absolute', top: 0, right: 0 }}
                            />
                          ) : null}
                          {!isOwnContract && isAreaOccupied ? (
                            <i
                              className="iconfont icon-shape"
                              style={{ position: 'absolute', top: 5, right: 5 }}
                            />
                          ) : null}
                          {isAreaOccupied &&
                          subscriptions &&
                          subscriptions.length > 0 &&
                          showAreaSub[area.id] ? (
                            <div
                              className="area-subscriptions-box-container"
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            >
                              <div className="box-arrow"></div>
                              <div className="area-subscriptions-box">
                                {subscriptions.map((sub_org, index) => {
                                  const org = sub_org.organization || {}
                                  const sub = sub_org.subscription || {}
                                  return (
                                    <div className="area-sub">
                                      <KBAvatar
                                        user={org}
                                        style={{
                                          display: 'inline-block',
                                          marginRight: '15px'
                                        }}
                                        size={54}
                                      />
                                      <div className="sub-infos">
                                        <div>
                                          <i className="iconfont icon-company" />
                                          <span className="middle-info">
                                            {org.name || ''}
                                          </span>
                                          {sub && (
                                            <span
                                              className={`finance-default ${
                                                stateType_class[
                                                  sub.status == 'expiring'
                                                    ? 'active'
                                                    : sub.status
                                                ]
                                              }`}
                                              style={{
                                                marginLeft: 5,
                                                textAlign: 'center'
                                              }}
                                            >
                                              {
                                                stateType[
                                                  sub.status == 'expiring'
                                                    ? 'active'
                                                    : sub.status
                                                ]
                                              }
                                            </span>
                                          )}
                                        </div>
                                        <div>
                                          <i className="iconfont icon-timer" />
                                          <span className="middle-info">{`${sub.start_date ||
                                            ''}${
                                            sub.end_date
                                              ? ' 至 ' + sub.end_date
                                              : ''
                                          }`}</span>
                                        </div>
                                        <div>
                                          <i className="iconfont icon-contract" />
                                          <span className="middle-info">
                                            合同编号：
                                            <a
                                              className="serial-link"
                                              onClick={this.showAreaSubDetail.bind(
                                                null,
                                                area,
                                                org,
                                                sub
                                              )}
                                            >
                                              {sub.serial || ''}
                                            </a>
                                          </span>
                                          {sub.status === 'expired' ? (
                                            <span className="sub-expired">
                                              已逾期
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </KBTipsy>
                    )
                  })}
                </div>
              ) : (
                <div style={{ marginBottom: 30 }}>
                  <p style={{ textAlign: 'center', fontSize: 16 }}>
                    暂无可选分区
                  </p>
                </div>
              )}
            </KBLoadingContainer>
            <div className="kb-row fixed-form-btn">
              <span className="cancel-btn" onClick={KBPopover.close}>
                取消
              </span>
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                确定
              </KBButton>
            </div>
          </div>
        </form>
        <div
          className={`slider-container${
            areaSubDetail.subscription_id ? ' slider-container-active' : ''
          }`}
          onClick={e => {
            e.stopPropagation()
            this.setState({ areaSubDetail: {} })
          }}
        >
          <div
            className="slider-content"
            onClick={e => {
              e.stopPropagation()
            }}
          >
            {areaSubDetail.subscription_id ? (
              <OrgSubscriptionDetail
                params={{
                  id: areaSubDetail.location_id,
                  organization_id: areaSubDetail.org_id,
                  subscription_id: areaSubDetail.subscription_id
                }}
                location={{ query: {} }}
                loadAreas={this._loadAreas}
                isFromAreasForm={true}
                _loadData={this.loadSubscription}
              />
            ) : null}
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const {
    loc_id,
    type,
    charge_type,
    yetAreaIds,
    endDateValue,
    contract_id
  } = props
  let openOffices = selectors.getOpenOfficesOfLocation(state, loc_id)
  let closeOffices = selectors.getCloseOfficesOfLocation(state, loc_id)
  let flexibleOffices = selectors.getFlexibleOfficesOfLocation(state, loc_id)
  let areas =
    type === 'flexible_office'
      ? flexibleOffices
      : type == 'public'
      ? openOffices
      : closeOffices
  if (type == 'public' || type == 'flexible_office') {
  } else {
    areas =
      areas &&
      _.cloneDeep(areas).map(area => {
        let area_contract_id = area.contract && area.contract.id
        area.isOwnContract = false
        if (
          area_contract_id &&
          contract_id &&
          area_contract_id == contract_id &&
          !_.includes(yetAreaIds, area.id)
        ) {
          area.isOwnContract = true
        }
        yetAreaIds.map(area_id => {
          let areaOccupied = area.occupied
          if (area.id == area_id) {
            area.occupied = true
            area.isSelectedArea = true
          } else {
            area.occupied = areaOccupied
          }
        })
        return area
      })
  }
  return {
    areas,
    charge_type,
    loc_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getAreasAction: bindActionCreators(areaActions.update, dispatch),
    getSubscriptionAction: bindActionCreators(
      subscriptionsAction.get,
      dispatch
    ),
    getOrgAction: bindActionCreators(orgActions.get, dispatch)
  }
}

const formConfig = {
  form: 'OrgSubscriptionAreasForm',
  fields: ['area_id', 'area_name', 'size'],
  touchOnBlur: false
}

OrgSubscriptionAreasForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionAreasForm)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionAreasForm)
