import React from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { KBPopover, KBRangeDate, KBStatisticNumber } from 'components'
import { KBPopoverConfirm } from 'components/tools'
import {
  areaActions,
  informActions,
  apiCallFailure,
  locationActions
} from 'app/actions'
import { connect } from 'react-redux'
import {
  formatYearDayEN,
  getFilterAndQuery,
  queryFilter,
  kbCloseDropDown
} from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import { canAccessLocation, canAccessLocationRoles } from 'app/reducers/role'
import * as Constants from 'app/constants'
import ParkingSpaceForm from './ParkingSpaceForm'
import ParkingSpaces from './ParkingSpaces'
import _ from 'lodash'
import OnlyTimeReportForm from '../invoices/OnlyTimeReportForm'

const DROP_DOWNS = ['dateDropDown']
const FILTER_STATE = [
  'state',
  'ceil_list',
  'district_list',
  'start_date',
  'end_date'
]

const backgroundColorOfState = {
  free: '#FFFFFF',
  assigned: '#39B54A',
  expiring: '#E63B41',
  disabled: '#EFF3F6',
  reserved: '#8DA9FF',
  approving: '#ECBC23'
}

var ParkingList = React.createClass({
  getInitialState() {
    const { filters } = this.props
    return {
      filters,
      loading: false,
      floorList: [''],
      districtList: ['']
    }
  },

  refreshParkingStatistic() {
    const { getLocationInfo, loc_id } = this.props
    api
      .getLocationInfo(loc_id)
      .then(
        json => {
          getLocationInfo.success(json)
        },
        errors => {
          getLocationInfo.failure(errors)
        }
      )
      .finally(() => {
        this.getTags()
      })
  },

  componentDidMount() {
    this.mounted = true
    this._loadData(this.props.filters)
    this.getTags()
  },

  getTags() {
    const { loc_id } = this.props
    api
      .getSettingTags({
        model: 'area',
        columns: 'ceils,districts',
        location_id: loc_id
      })
      .then(json => {
        let res = json.response.body || {}
        let allFloors = Object.keys(res.ceils || {})
        let allDistricts = Object.keys(res.districts || {})
        let floorList = _.uniq(this.state.floorList.concat(allFloors || []))
        let districtList = _.uniq(
          this.state.districtList.concat(allDistricts || [])
        )
        this.setState({
          floorList,
          districtList
        })
      })
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(filters = {}) {
    const { getAreasAction, loc_id } = this.props

    this.setState({
      loading: true
    })

    let subFilters = {}
    if (filters.start_date && filters.end_date) {
      subFilters.subscription_start_date = filters.start_date
      subFilters.subscription_end_date = filters.end_date
    }

    api
      .getAreas({
        location_id: loc_id,
        area_type: 'parking',
        includes: 'occupancy,subscriptions,organizations',
        per_page: 1000,
        ...filters,
        ...subFilters
      })
      .then(
        json => {
          getAreasAction.success(json, {
            entityName: 'areas',
            location_id: loc_id
          })
          return true
        },
        errors => {
          getAreasAction.failure(errors)
        }
      )
      .finally(() => {
        if (this.mounted) {
          this.setState({
            loading: false
          })
        }
      })
  },

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.filters, nextProps.filters)) {
      this._loadData(nextProps.filters)
    }
  },

  _setFilter(new_filter = {}) {
    const { query, routerActions, loc_id } = this.props
    let newFilters = Object.assign({}, this.state.filters, new_filter)
    if (query.page) {
      query.page = 1
    }
    let queryFiltersStr = queryFilter({ ...query, ...newFilters })
    this.setState({
      filters: newFilters,
      queryFiltersStr
    })
    routerActions.replace(
      `/admin/locations/${loc_id}/parking/manage${queryFiltersStr}`
    )
  },

  _clearFilters() {
    const { query, loc_id, routerActions } = this.props
    if (query.page) {
      query.page = 1
    }
    let queryFiltersStr = queryFilter({ ...query })
    this.setState({
      filters: {},
      queryFiltersStr
    })
    routerActions.replace(
      `/admin/locations/${loc_id}/parking/manage${queryFiltersStr}`
    )
  },

  addParkingSpace() {
    let { loc_id } = this.props

    KBPopover.showForm(
      <ParkingSpaceForm
        loc_id={loc_id}
        refreshParkingStatistic={this.refreshParkingStatistic}
      />
    )
  },

  deleteArea(area) {
    const {
      deleteAreaAction,
      routerActions,
      loc_id,
      apiCallFailureActions
    } = this.props
    if (area.occupied_desks_count > 0) {
      apiCallFailureActions({
        status: 'error',
        message: '此分区已有工位入驻不可删除!'
      })
      return
    }
    KBPopoverConfirm({
      name: '删除分区',
      context: `您确定要删除分区 ${area.name} 吗?`,
      callback: () => {
        return api.deleteArea(area.id).then(
          json => {
            routerActions.replace(`/admin/locations/${loc_id}/areas/desks/list`)
            deleteAreaAction.success(json, {
              location_id: loc_id,
              key: 'areas',
              id: area.id
            })
            KBPopover.close()
          },
          error => {
            deleteAreaAction.success(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  changeDate(range) {
    this._setFilter({
      start_date: formatYearDayEN(range.from),
      end_date: formatYearDayEN(range.to)
    })
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  renderFloors() {
    let { filters, floorList, districtList } = this.state

    return (
      <nav className="nav-container f-left">
        <div className="nav-container-content">
          <header className="nav-header clear-fix">
            <h2 className="header-title f-left">楼层</h2>
          </header>
          <div className="nav-body" style={{ padding: '20px 0' }}>
            <ul className="nav-container-list">
              {floorList.length > 0
                ? floorList.map(floor => {
                    let isActive =
                      filters.ceil_list === floor ||
                      (!filters.ceil_list && !floor)
                    return (
                      <li
                        className={isActive ? 'nav-container-list-hover' : ''}
                        onClick={() => this._setFilter({ ceil_list: floor })}
                      >
                        <a>{floor || '全部楼层'}</a>
                      </li>
                    )
                  })
                : null}
            </ul>
          </div>
        </div>

        <div className="nav-container-content">
          <header className="nav-header clear-fix">
            <h2 className="header-title f-left">区域</h2>
          </header>
          <div className="nav-body" style={{ padding: '20px 0' }}>
            <ul className="nav-container-list">
              {districtList.length > 0
                ? districtList.map(dist => {
                    let isActive =
                      filters.district_list === dist ||
                      (!filters.district_list && !dist)
                    return (
                      <li
                        className={isActive ? 'nav-container-list-hover' : ''}
                        onClick={() => this._setFilter({ district_list: dist })}
                      >
                        <a>{dist || '全部区域'}</a>
                      </li>
                    )
                  })
                : null}
            </ul>
          </div>
        </div>
      </nav>
    )
  },

  exportParkingUsage() {
    const { loc_id } = this.props
    KBPopover.show(<OnlyTimeReportForm loc_id={loc_id} type="parking" />)
  },

  render() {
    var { area, loc_id, isManager, parkingSpaces, location } = this.props
    let statistic = location.statistic
    const { filters } = this.state

    return (
      <section
        className="kb-content clear-fix"
        style={{ height: '100%', paddingTop: 0 }}
      >
        {this.renderFloors()}

        <section className="nav-section-container f-right">
          <header className="nav-header">
            <div
              className="clear-fix"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <h2
                className="nav-section-header-title f-left vf-center"
                style={{ flex: 1 }}
              >
                <span>{(area && area.name) || '全部车位'}</span>
              </h2>
              {isManager ? (
                <div className="f-right">
                  <button
                    className="c-btn-secondary"
                    onClick={this.exportParkingUsage}
                  >
                    导出车位使用情况
                  </button>
                  <button
                    className="bordered-btn"
                    onClick={this.addParkingSpace}
                  >
                    <i className="iconfont icon-add" />
                    添加车位
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
            {location && location.statistic ? (
              <div className="chart-out_box t-center m-updown">
                <div className="chart-box t-left" style={{ marginRight: 30 }}>
                  <KBStatisticNumber
                    number={statistic.occupied_parking_count || 0}
                    unit="个"
                    name="已出租车位个数"
                  />
                </div>
                <div className="chart-box t-left" style={{ marginRight: 30 }}>
                  <KBStatisticNumber
                    number={statistic.total_parking_count || 0}
                    unit="个"
                    name="总车位个数"
                  />
                </div>
                <div className="chart-box t-left">
                  <KBStatisticNumber
                    number={statistic.parking_entering_rate || 0}
                    unit="%"
                    name="出租率"
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </header>

          <div className="nav-section-content-container">
            <div className="nav-section-action-bar">
              <div className="select-progress">
                {Constants.PARKING_STATES.map((data, index) => {
                  let color = backgroundColorOfState[data.id]
                  return (
                    <div
                      key={index}
                      style={{ marginRight: 0, padding: '7px 9px' }}
                      className={`border-show_status ${
                        filters.state == data.id
                          ? 'border-show_status_active'
                          : ''
                      }`}
                      onClick={() => this._setFilter({ state: data.id })}
                    >
                      <span
                        className={`color-show`}
                        style={{
                          borderRadius: 0,
                          width: 16,
                          height: 16,
                          marginRight: 6,
                          backgroundColor: `${color ||
                            (filters.state ? '#2ea1f8' : '#ffffff')}`
                        }}
                      ></span>
                      <span className="status-text">{data.name}</span>
                    </div>
                  )
                })}
              </div>
              <KBRangeDate
                title="选择合同起止日范围"
                from={filters.start_date || ''}
                to={filters.end_date || ''}
                callback={this.changeDate}
                ref={ref => {
                  this.dateDropDown = ref
                }}
                dropDown_name="dateDropDown"
                closeClick={this.closeDropDowns}
                leftNone={true}
                style={{ top: '-3px' }}
              />
            </div>

            {filters.state || (filters.start_date && filters.end_date) ? (
              <div className="filters-box">
                <span className="filter-label">您已选择：</span>
                {filters.state ? (
                  <span className="single-filter">
                    <span>{Constants.PARKING_STATES_OBJ[filters.state]}</span>
                    <i
                      className="iconfont icon-close"
                      onClick={() => this._setFilter({ state: '' })}
                    />
                  </span>
                ) : null}
                {filters.start_date && filters.end_date ? (
                  <span className="single-filter">
                    <span>
                      {formatYearDayEN(filters.start_date) +
                        '至' +
                        formatYearDayEN(filters.end_date)}
                    </span>
                    <i
                      className="iconfont icon-close"
                      onClick={() =>
                        this._setFilter({ start_date: '', end_date: '' })
                      }
                    />
                  </span>
                ) : null}
                <span
                  className="clear-filters"
                  onClick={() =>
                    this._setFilter({ state: '', start_date: '', end_date: '' })
                  }
                >
                  <i className="iconfont icon-delete" />
                  <span>清除</span>
                </span>
              </div>
            ) : null}

            <ParkingSpaces
              loc_id={loc_id}
              parkingSpaces={parkingSpaces}
              refreshParkingStatistic={this.refreshParkingStatistic}
            />
          </div>
        </section>
      </section>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const { params, location } = ownProps
  const { user } = state
  let space_id = Object.keys(state.entities.spaces || {})[0]
  let loc_id = params.id
  let page = parseInt(location.query && location.query.page, 10) || 1
  let area_id = location.query && location.query.area_id
  let areas = Object.assign([], selectors.getAreasOfLocation(state, loc_id))
  let parkingSpaces =
    areas &&
    areas.filter(area => {
      return area.area_type === 'parking'
    })
  let area = selectors.getArea(state, area_id)
  let inform = state.inform
  let isManager = canAccessLocation(user, loc_id)
  let isSales = canAccessLocationRoles(user, loc_id, ['sales'])

  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)

  return {
    space_id,
    loc_id,
    area_id,
    inform,
    location: selectors.getLocation(state, loc_id),
    area,
    page,
    areas,
    isManager,
    isSales,
    parkingSpaces,
    ...paramsAll
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getAreasAction: bindActionCreators(areaActions.replace, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    informRemoveActions: bindActionCreators(informActions.remove, dispatch),
    deleteAreaAction: bindActionCreators(areaActions.delete, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getLocationInfo: bindActionCreators(locationActions.update, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingList)
