import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { Router, Route, Link } from 'react-router'
import classNames from 'classnames'
import { routerActions } from 'react-router-redux'
import { api, apiUtils } from 'app/services'
import { faqFilesActions, serviceRequestsAction } from 'app/actions'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBPopover,
  KBDropdown,
  KBTableWithoutData
} from 'components'
import { connect } from 'react-redux'
import {
  OPPORTUNITY_STATES,
  SERVICE_REQUEST_STATES,
  SERVICE_REQUEST_STATE_OBJ
} from 'app/constants'
import * as selectors from 'app/selectors'
import moment from 'moment'
import {
  getEntitiesJsonArray,
  paginate,
  kbCloseDropDown,
  getFilterAndQuery,
  queryFilter
} from 'utils/kbUtil'
import * as constants from 'app/constants'
import ServiceProviderForm from './ServiceProviderForm'

const DROP_DOWNS = ['typesDropdown']

var ServiceRequest = React.createClass({
  displayName: 'ServiceRequest',
  getInitialState() {
    const { filters } = this.props
    return {
      serviceRequests: [],
      filters,
      loading: false
    }
  },

  componentDidMount() {
    const { page } = this.props
    this.mounted = true
    this._loadData(page, { status: '' })
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(page, newFilters) {
    const { getServiceRequestsAction, routerActions, query } = this.props
    const { filters } = this.state
    let status = filters.status

    if (newFilters) {
      status = newFilters.status
    }

    var params = {
      per_page: 20,
      status: status,
      page
    }
    this.setState({ lading: true })
    let queryFiltersStr = queryFilter({
      ...query,
      ...params,
      page: page || this.props.page
    })

    api
      .getServiceRequests({ ...params, ...filters })
      .then(
        json => {
          let serviceRequests = []
          serviceRequests = getEntitiesJsonArray(json, 'serviceRequests')
          let pagination = paginate(json.response)
          getServiceRequestsAction.success(json)
          if (!this.mounted) {
            return
          }
          routerActions.replace(`/admin/service/request${queryFiltersStr}`)
          this.setState({
            serviceRequests,
            pagination
          })
        },
        error => {
          getServiceRequestsAction.failure(error)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  toRequestDetail(request) {
    const { routerActions } = this.props
    routerActions.push(`/admin/service/request_detail/${request.id}`)
  },

  componentWillReceiveProps(nextProps) {
    if (
      this.props.page != nextProps.page ||
      nextProps.serviceRequests.length != this.props.serviceRequests.length
    ) {
      this._loadData(nextProps.page)
    }
  },

  _setFilter(key, value) {
    const { page } = this.props
    let newFilters = Object.assign({}, this.state.filters, { [key]: value })
    this.setState({ filters: newFilters }, () => {
      this._refresh()
      this._loadData(1, newFilters)
    })
  },

  _clearFilters() {
    const { page } = this.props
    this.setState({ filters: {} })
    this._loadData(1, {})
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },
  _refresh() {
    this['typesDropdown'] && this['typesDropdown'].hide()

    setTimeout(this._loadData)
  },

  filterRender() {
    /*return (
      <div className="select-progress m-top">
        <span>选择状态</span>
        {
          SERVICE_REQUEST_STATES.map((state, index) => {
            return (
              <div key={ index } style={{marginRight: 0, padding: '7px 9px'}} className={`border-show_status ${ status == state.id ? 'border-show_status_active' : ''}`} onClick={()=> this._setFilter('status', state.id) } >
                <span className={`color-show color-show_${state.id}`} style={{border: 0, width: 16, height: 16, marginRight:6,  backgroundColor: `${state.id ? '#2ea1f8': '#fff' }`}}></span>
                <span key={state.id} className="status-text">{state.name}</span>
              </div>
            )
          })
        }
      </div>
    )*/
    return (
      <div style={{ marginTop: 35 }}>
        {
          <KBDropdown
            ref={ref => {
              this.typesDropdown = ref
            }}
            style={{ marginBottom: '20px', float: 'left' }}
          >
            <KBDropdown.KBDropdownTrigger>
              <div
                className="task-status-title"
                onClick={this.closeDropDowns.bind(null, 'typesDropdown')}
              >
                <span className="span-pr-smail">
                  {this.state.filters.status
                    ? SERVICE_REQUEST_STATE_OBJ[this.state.filters.status]
                    : '选择状态'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilter('status', '')}>全部</li>
                  {SERVICE_REQUEST_STATES.map((state, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => this._setFilter('status', state.id)}
                      >
                        <span>{state.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        }
      </div>
    )
  },

  createServiceProvider() {
    KBPopover.show(<ServiceProviderForm />, {
      closeOnOutsideClick: false
    })
  },

  render() {
    const { pagination, serviceRequests, filters, loading } = this.state
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header">
          <div className="clear-fix">
            <div className="nav-section-header-title f-left">
              <span>服务请求列表</span>
            </div>
            <div className="f-right">
              <button
                className="bordered-btn"
                onClick={this.createServiceProvider}
              >
                <i className="iconfont icon-add" />
                添加服务商
              </button>
            </div>
          </div>
        </header>
        <div className="nav-section-content-container">
          {this.filterRender()}
          {/* {
            selectFilters.length > 0 ?
            <div>
              <div className='clear-criteria' onClick={ this._clearFilters } style={{marginTop:5}}>
                <i className='iconfont icon-close'/><span>清除所有选项</span>
              </div>
            </div> : ''
          }*/}
          <table className="content-table edit-table">
            <thead>
              <tr>
                <th>服务对象</th>
                <th>服务</th>
                <th>服务商</th>
                <th>状态</th>
                <th>最后更新</th>
              </tr>
            </thead>
            <KBTableWithoutData
              hasData={serviceRequests.length > 0}
              tableHeadNum="5"
              tipMessage="暂无服务请求"
            >
              {serviceRequests &&
                serviceRequests.map((request, index) => {
                  return (
                    <tr
                      key={index}
                      className="service-tr"
                      onClick={this.toRequestDetail.bind(null, request)}
                    >
                      <td>
                        <span className="color-link">
                          {request.organization_name}
                        </span>
                      </td>
                      <td>
                        <span>{request.service_name}</span>
                      </td>
                      <td>
                        <span>{request.service_provider_name}</span>
                      </td>
                      <td>
                        <span
                          className={`default-status ${
                            constants.SERVICE_REQUEST_STATE_CLASS[
                              request.status
                            ]
                          }`}
                        >
                          {constants.SERVICE_REQUEST_STATE_OBJ[request.status]}
                        </span>
                      </td>
                      <td>
                        <span>{moment.unix(request.updated_at).fromNow()}</span>
                      </td>
                    </tr>
                  )
                })}
            </KBTableWithoutData>
          </table>
          <KBPagination
            pagination={pagination}
            template={`/admin/service/request?page=#PAGE#`}
          />
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const serviceRequests = Object.assign(
    selectors.getServiceRequestOfProvidersId(state)
  )

  const { params, location } = props
  let loc_id = params.id

  let page = location.query && parseInt(location.query.page)
  var FILTER_STATE = ['status']
  var paramsAll = getFilterAndQuery(location.query, FILTER_STATE)
  page = isNaN(page) ? 1 : page
  return {
    serviceRequests,
    page,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch),
    getServiceRequestsAction: bindActionCreators(
      serviceRequestsAction.get,
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequest)
