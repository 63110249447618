import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { DAY_TIME_HM } from 'app/constants'
import { reservationsActions } from 'app/actions'
import { api, apiUtils } from 'app/services'
import {
  KBPopover,
  KBUserInput,
  KBFormError,
  KBButton,
  KBDateInput,
  KBSelect
} from 'components'
import moment from 'moment'

var MeetingChangeTimeForm = React.createClass({
  getInitialState() {
    return {}
  },

  changeMeetingTime(model) {
    const {
      updateReservationsAction,
      deleteReservationsAction,
      reservation_id
    } = this.props
    return api.putMeetingChangeTime(reservation_id, model).then(json => {
      deleteReservationsAction.success(json, {
        key: 'reservations',
        id: reservation_id
      })
      updateReservationsAction.success(json)
      KBPopover.close()
      return true
    })
  },

  componentDidMount() {
    const {
      fields: { timeYMD, startHM, start_at, reserveDate }
    } = this.props
    const currentStartAt = this.props.start_at

    reserveDate.onChange(moment(currentStartAt).toDate())
    timeYMD.onChange(moment(currentStartAt).toDate())
    let s = this.parseHM(currentStartAt)
    startHM.onChange(s)

    this.setHM(start_at, s)
  },

  parseHM(date) {
    let hours = moment(date).hour()
    let minute = moment(date).minute()
    let t = '1'
    let h = hours
    if (minute == 30) {
      t = '2'
    } else {
      t = '1'
    }

    return h + ',' + t
  },

  setHM(field, value) {
    let t = value.split(',')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ hour: t[0], minute: t[1] == '1' ? 0 : 30, second: 0 })
        .toDate()
    )
  },

  setYMD(field, value) {
    let date = moment(value)

    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  changeYMDCallback(date) {
    const {
      fields: { start_at }
    } = this.props
    this.setYMD(start_at, date)
  },

  changeStartHMCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setHM(start_at, data)
  },

  render() {
    const {
      fields: { startHM, timeYMD },
      error,
      submitting,
      handleSubmit,
      DEAL_DAY_TIME_HM
    } = this.props
    return (
      <form onSubmit={handleSubmit(this.changeMeetingTime)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">更改会议时间</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row kb-form-66">
            <KBDateInput
              field={timeYMD}
              title="预定日期"
              className="kb-form-group kb-div-48"
              onChange={this.changeYMDCallback}
            />
            <KBSelect
              field={startHM}
              title="开始时间"
              className="kb-form-group kb-div-c48"
              onChange={this.changeStartHMCallback}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { reservation_time_range, duration } = props
  const { begin, end } = reservation_time_range
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(begin, end - Math.ceil(duration))

  return {
    DEAL_DAY_TIME_HM
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateReservationsAction: bindActionCreators(
      reservationsActions.update,
      dispatch
    ),
    deleteReservationsAction: bindActionCreators(
      reservationsActions.delete,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (
    moment(moment(values.start_at)).isAfter(
      moment(values.reserveDate).add(7, 'days')
    )
  ) {
    errors.timeYMD = '开始日期不可以更改到一周之后'
  }

  return errors
}

const formConfig = {
  form: 'MeetingChangeTimeForm',
  fields: ['start_at', 'startHM', 'timeYMD', 'reserveDate'],
  validate: validate,
  touchOnBlur: false
}

MeetingChangeTimeForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(MeetingChangeTimeForm)

export default MeetingChangeTimeForm
