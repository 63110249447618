import React, { Component, PropTypes } from 'react'
import { api, apiUtils } from 'app/services'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import {
  KBLoadingContainer,
  KBAvatar,
  KBPagination,
  KBRangeDate,
  KBTableWithoutData
} from 'components'
import { connect } from 'react-redux'
import {
  paginate,
  formatMinuteSecondEN,
  getFilterAndQuery,
  queryFilter
} from 'utils/kbUtil'
import { INVOICE_SALES_PAYMENTS } from 'app/constants'
import { getSpaceVirtualCurrencyName, getOrg } from 'app/selectors'
import { routerActions } from 'react-router-redux'
import moment from 'moment'
import { getUserInfoUrl } from 'utils/kbUrl'

var OrganizationPoint = React.createClass({
  displayName: 'OrganizationPoint',
  getInitialState() {
    const { filters } = this.props
    return {
      pagination: null,
      pointList: [],
      loading: true,
      filters
    }
  },

  componentDidMount() {
    this.mounted = true
    this.loadData()
  },

  componentWillUnmount() {
    this.mounted = false
  },

  loadData(page = 1) {
    const { org_id, query, routerActions, loc_id, org } = this.props
    const { filters } = this.state
    let params = Object.assign({}, { per_page: 10 }, { page: page }, filters, {
      sales_customer_id: org && org.sales_customer && org.sales_customer.id,
      location_id: loc_id
    })
    let { from, to } = filters
    if (from && to) {
      filters.from = from && moment(from).format('YYYY-MM-DD')
      filters.to = to && moment(to).format('YYYY-MM-DD')
    } else {
      delete filters.from
      delete filters.to
    }
    let queryFiltersStr = queryFilter({ ...query, ...filters, page: page })
    api
      .getPointPlansLedgers(params)
      .then(({ json, response }) => {
        let pagination = paginate(response)
        this.setState({
          pointList: json,
          pagination
        })
        // 防止用户点击过快时路由来回跳转
        if (!this.mounted) {
          return
        }
        routerActions.replace(
          `/admin/locations/${loc_id}/organizations/${org_id}/point${queryFiltersStr}`
        )
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.page !== this.props.page ||
      nextProps.isUpdatePoint !== this.props.isUpdatePoint
    ) {
      this.loadData(nextProps.page)
    }
  },

  changeDate(range) {
    this._setFilter(range)
  },

  _setFilter(obj) {
    let newFilters = Object.assign({}, this.state.filters, obj)
    this.setState({ filters: newFilters })

    // force refresh
    this._refresh()
  },

  _refresh() {
    setTimeout(this.loadData)
  },

  _clearFilters() {
    this.setState({ filters: {} })
    // force refresh
    this._refresh()
  },

  render() {
    const { pointList, loading, filters } = this.state
    const { from, to } = filters
    const { loc_id, org_id, org, virtual_currency_name } = this.props

    return (
      <KBLoadingContainer loading={loading}>
        <div>
          <div className="meeting_available_time" style={{ paddingBottom: 30 }}>
            <div className="meeting_room">
              <div className="remain">
                {org && org.balances && org.balances.point
                  ? org.balances.point
                  : 0}
              </div>
              <p>{virtual_currency_name}余额</p>
            </div>
            <div className="meeting_room">
              <div className="used">
                {org && org.balances && org.balances.basic_point
                  ? org.balances.basic_point
                  : 0}
              </div>
              <p>基础{virtual_currency_name}</p>
            </div>
            <div className="meeting_room">
              <div className="used">
                {org && org.balances && org.balances.gift_point
                  ? org.balances.gift_point
                  : 0}
              </div>
              <p>赠送{virtual_currency_name}</p>
            </div>
          </div>
          <div
            className="member-org-point"
            style={{ display: 'flex', marginLeft: -20 }}
          >
            <div style={{ float: 'left' }}>
              <KBRangeDate
                format="YYYY/MM/DD"
                from={from || ''}
                to={to || ''}
                callback={this.changeDate}
              />
            </div>
          </div>
          <div style={{ marginTop: 5 }}>
            {Object.keys(this.state.filters).length > 0 ? (
              <div
                className="clear-criteria"
                style={{ marginTop: 5 }}
                onClick={this._clearFilters}
              >
                <i className="iconfont icon-close" />
                <span>清除所有筛选条件</span>
              </div>
            ) : null}
          </div>
        </div>
        <table className="content-table">
          <thead>
            <tr>
              <th>订单编号</th>
              <th>基础余额</th>
              <th>赠送余额</th>
              <th>现有余额</th>
              <th>支付现金</th>
              <th>支付方式</th>
              <th>操作人</th>
              <th>备注</th>
              <th>时间</th>
            </tr>
          </thead>
          <KBTableWithoutData
            hasData={pointList.length > 0}
            tableHeadNum="9"
            tipMessage="暂无数据"
          >
            {pointList &&
              pointList.map(point => {
                return (
                  <tr>
                    <td>
                      {point.sales_invoice_id ? (
                        <Link
                          to={`/admin/locations/${loc_id}/invoices/location_orders/${point.sales_invoice_id}`}
                          className="color-link"
                        >
                          {point.invoice_serial_number}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {point.outgoings == 0 ? (
                        <span style={{ color: '#25c324' }}>
                          +{point.incomings}
                        </span>
                      ) : (
                        <span style={{ color: '#ff0000' }}>
                          -{point.outgoings}
                        </span>
                      )}
                    </td>
                    <td>
                      {point.gift_outgoings == 0 ? (
                        <span style={{ color: '#25c324' }}>
                          +{point.gift_incomings}
                        </span>
                      ) : (
                        <span style={{ color: '#ff0000' }}>
                          -{point.gift_outgoings}
                        </span>
                      )}
                    </td>
                    <td>{point.balance}</td>
                    <td>
                      {point.payment_mode === 'point'
                        ? ''
                        : `${point.paid_amount ? point.paid_amount : 0} 元`}
                    </td>
                    <td>
                      {point.payment_mode
                        ? INVOICE_SALES_PAYMENTS[point.payment_mode]
                        : '无'}
                    </td>
                    <td>
                      {point.operator ? (
                        <div className="vf-center">
                          <KBAvatar
                            user={point.operator}
                            imgStyle={{ marginRight: 5 }}
                            style={{
                              display: 'inline-block',
                              marginRight: '5px'
                            }}
                            size={30}
                          />
                          <Link
                            to={getUserInfoUrl(point.operator.id)}
                            className="color-link"
                          >
                            {point.operator.name || ''}
                          </Link>
                        </div>
                      ) : null}
                    </td>
                    <td>{point.note}</td>
                    <td>{formatMinuteSecondEN(point.created_at)}</td>
                  </tr>
                )
              })}
          </KBTableWithoutData>
        </table>
        <KBPagination
          pagination={this.state.pagination}
          template={`/admin/locations/${loc_id}/organizations/${org_id}/point?page=#PAGE#`}
        />
      </KBLoadingContainer>
    )
  }
})

function mapStateToProps(state, props) {
  const { org_id, loc_id } = props
  let page = parseInt(props.query && props.query.page) || 1
  let virtual_currency_name = getSpaceVirtualCurrencyName(state)
  var FILTER_STATE = ['from', 'to']
  const params = getFilterAndQuery(props.query, FILTER_STATE)
  const org = getOrg(state, org_id)
  return {
    org_id,
    org,
    page,
    loc_id,
    virtual_currency_name,
    ...params
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPoint)
