import React from 'react'
import {
  KBInput,
  KBPopover,
  KBSelect,
  KBFormError,
  KBButton,
  KBRadio,
  KBUserInput,
  KBMorePhotoUpload,
  KBDateInput,
  KBTextarea,
  KBRangeDate
} from 'components'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { DAY_TIME_HM } from 'app/constants'
import { Input, Row, Col, DatePicker, Modal, notification, Button } from 'antd'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN'
import validator from 'validator'

const getFinalPrice = (price, discountPrice) => {
  let finalPrice = 0
  if (Number(price) > Math.abs(discountPrice)) {
    finalPrice = Number(price) - Math.abs(discountPrice)
  }
  return finalPrice
}
const { RangePicker } = DatePicker
let SettingCoupon = React.createClass({
  componentDidMount() {
    // moment.locale('zh-cn');
  },
  createAreaForm(model) {
    const { close, type, callback } = this.props
    let params = {}
    if (type === 'add') {
      params = {
        title: model.title,
        start_date: model.start_date,
        end_date: model.end_date,
        source_id: model.source_id,
        source_type: 'Area',
        discount_price: -model.discount_price,
        preview_start_at:
          model.preview_start_at || moment().format('YYYY-MM-DD HH'),
        discount_tenant_price: -model.discount_tenant_price,
        discount_bridge_price: -model.discount_bridge_price,
        note: model.note
      }
      api.createCoupon(params).then(
        res => {
          notification.success({ message: '添加成功' })
          close && close()
        },
        error => {
          if (error) {
            notification.error({ title: '添加失败', message: error.message })
            // close && close()
          }
        }
      )
    } else if (type === 'edit') {
      params = {
        title: model.title,
        start_date: model.start_date,
        end_date: model.end_date,
        preview_start_at: model.preview_start_at,
        note: model.note,
        on_sales: true
      }
      callback && callback(params)
    }
  },
  changeDate(date) {
    const {
      fields: { start_date, end_date }
    } = this.props
    const [startDate, endDate] = date
    // const { from, to } = range
    const start = moment(startDate).format('YYYY-MM-DD')
    const end = moment(endDate).format('YYYY-MM-DD')
    start_date.onChange(start)
    end_date.onChange(end)
  },
  changePrice(value, field, type) {
    const {
      data = {},
      fields: {
        price,
        tenant_price,
        bridge_price,
        real_price,
        real_tenant_price,
        real_bridge_price
      }
    } = this.props
    if (value && !validator.isNumeric(value)) {
      return notification.error({ message: '请输入数字' })
    }
    field.onChange(value)
    if (type === '1') {
      const Price = getFinalPrice(price.value, value)
      real_price.onChange(Price)
    }
    if (type === '2') {
      const Price = getFinalPrice(tenant_price.value, value)
      real_tenant_price.onChange(Price)
    }
    if (type === '3') {
      const Price = getFinalPrice(bridge_price.value, value)
      real_bridge_price.onChange(Price)
    }
  },
  render() {
    const {
      handleSubmit,
      submitting,
      data = {},
      visible,
      close,
      type,
      callback,
      fields: {
        title,
        start_date,
        end_date,
        price,
        tenant_price,
        bridge_price,
        discount_price,
        discount_bridge_price,
        discount_tenant_price,
        real_price,
        real_tenant_price,
        real_bridge_price,
        preview_start_at,
        source_type,
        note
      }
    } = this.props
    return (
      <Modal
        visible={visible}
        title={type === 'add' ? '增加促销活动' : '编辑促销活动'}
        width="800"
        onCancel={close}
        footer={null}
        destroyOnClose
        centered
      >
        <form onSubmit={handleSubmit(this.createAreaForm)}>
          <KBFormError error={this.props.error} />
          <div className="kb-form-container">
            <div className="kb-row">
              <KBInput
                className="kb-form-group kb-form-c18 kb-form-alone"
                field={title}
                title="优惠名称"
                label
                isRequired={true}
                fistFoucs={true}
                placeholder="范例：开业促销"
              />
            </div>
            <div className="kb-row">
              <div>
                <label style={{ position: 'relative' }}>
                  <span
                    style={{
                      position: 'absolute',
                      color: '#F62D51',
                      top: 3,
                      left: -8
                    }}
                  >
                    *
                  </span>
                  预订日期
                </label>
                <div>
                  <RangePicker
                    value={[moment(start_date.value), moment(end_date.value)]}
                    onChange={this.changeDate}
                    ranges={{
                      本周: [moment().startOf('week'), moment().endOf('week')],
                      本月: [
                        moment().startOf('month'),
                        moment().endOf('month')
                      ],
                      本季度: [
                        moment().startOf('quarter'),
                        moment().endOf('quarter')
                      ],
                      下月: [
                        moment()
                          .add(1, 'months')
                          .startOf('month'),
                        moment()
                          .add(1, 'months')
                          .endOf('month')
                      ],
                      下季度: [
                        moment()
                          .add(1, 'quarter')
                          .startOf('quarter'),
                        moment()
                          .add(1, 'quarter')
                          .endOf('quarter')
                      ]
                    }}
                  />
                  {/* <KBRangeDate
                    title="选择日期范围"
                    format="YYYY/MM/DD"
                    from={start_date.value || ''}
                    to={end_date.value || ''}
                    callback={this.changeDate}
                    leftNone={true}
                  /> */}
                </div>
                <p className="lr-error">{start_date.error}</p>
              </div>
            </div>

            <div className="kb-row">
              <div className="kb-form-c18 kb-form-group kb-form-alone">
                <label>
                  提前预览时间<span className="must-fill">*</span>
                </label>
                <div>
                  <DatePicker
                    locale={locale}
                    format="YYYY-MM-DD HH:mm"
                    defaultValue={
                      preview_start_at.value
                        ? moment(preview_start_at.value)
                        : ''
                    }
                    showTime={{
                      defaultValue: moment('00', 'HH'),
                      format: 'HH'
                    }}
                    onChange={value => {
                      if (value) {
                        const date = moment(value).format('YYYY-MM-DD HH:mm')
                        preview_start_at.onChange(date)
                      } else {
                        preview_start_at.onChange('')
                      }
                    }}
                  />
                  <p className="lr-error">{preview_start_at.error}</p>
                </div>
              </div>
            </div>
            {/* {source_type.value === 'meeting' && ( */}
            <Row gutter={[0, 16]} style={{ marginTop: 10 }}>
              <Row align="middle" justify="space-between">
                <Col span={6}>
                  <label>一般价格</label>
                  <div>
                    <Input value={price.value} disabled addonAfter="元/时" />
                    <p style={{fontSize:12, color:'#c6c6c6'}}>针对所有注册成为凯德星会员的会员购买的价格</p>
                  </div>
                </Col>
                <Col span={6} offset={1}>
                  <label>办公楼会员价</label>
                  <div>
                    <Input
                      value={tenant_price.value}
                      disabled
                      addonAfter="元/时"
                    />
                    <p style={{fontSize:12, color:'#c6c6c6'}}>在本系统中登记的会员预定价格</p>
                  </div>
                </Col>
                <Col span={6} offset={1}>
                  <label>奕桥会员价</label>
                  <div>
                    <Input
                      value={bridge_price.value || 0}
                      disabled
                      addonAfter="元/时"
                    />
                    <p style={{fontSize:12, color:'#c6c6c6'}}>所属公司有联办合同的会员预定是使用的价格</p>
                  </div>
                </Col>
              </Row>

              <Row align="middle" justify="space-between">
                <div
                  style={{ fontSize: 16, color: '#8d8d8d', margin: '10px 0' }}
                >
                  优惠额度
                </div>
                <Col span={6}>
                  <label>一般价格</label>
                  <div>
                    <Input
                      disabled={type === 'edit' ? true : false}
                      value={discount_price.value}
                      onChange={e =>
                        this.changePrice(e.target.value, discount_price, '1')
                      }
                      addonBefore="-"
                      addonAfter="元/时"
                    />
                  </div>
                </Col>
                <Col span={6} offset={1}>
                  <label>办公楼会员价</label>
                  <div>
                    <Input
                      disabled={type === 'edit' ? true : false}
                      value={discount_tenant_price.value}
                      onChange={e =>
                        this.changePrice(
                          e.target.value,
                          discount_tenant_price,
                          '2'
                        )
                      }
                      addonBefore="-"
                      addonAfter="元/时"
                    />
                  </div>
                </Col>
                <Col span={6} offset={1}>
                  <label>奕桥会员价</label>
                  <div>
                    <Input
                      disabled={type === 'edit' ? true : false}
                      value={discount_bridge_price.value}
                      onChange={e =>
                        this.changePrice(
                          e.target.value,
                          discount_bridge_price,
                          '3'
                        )
                      }
                      addonBefore="-"
                      addonAfter="元/时"
                    />
                  </div>
                </Col>
              </Row>

              <Row align="middle" justify="space-between">
                <div
                  style={{ fontSize: 16, color: '#8d8d8d', margin: '10px 0' }}
                >
                  最终价格
                </div>
                <Col span={6}>
                  <div>一般价格</div>
                  <div>
                    <Input
                      value={real_price.value}
                      disabled
                      addonAfter="元/时"
                    />
                  </div>
                </Col>
                <Col span={6} offset={1}>
                  <div>办公楼会员价</div>
                  <div>
                    <Input
                      value={real_tenant_price.value}
                      disabled
                      addonAfter="元/时"
                    />
                  </div>
                </Col>
                <Col span={6} offset={1}>
                  <div>奕桥会员价</div>
                  <div>
                    <Input
                      value={real_bridge_price.value}
                      disabled
                      addonAfter="元/时"
                    />
                  </div>
                </Col>
              </Row>
            </Row>
            <div style={{ marginTop: 20 }}>
              <label>备注</label>
              <Input.TextArea
                value={note.value}
                onChange={e => note.onChange(e.target.value)}
              />
            </div>
            <div className="kb-row fixed-form-btn">
              <span
                className="cancel-btn"
                onClick={() => {
                  close && close()
                }}
              >
                取消
              </span>
              <KBButton
                className="certain-btn"
                type="submit"
                submitting={submitting}
              >
                保存
              </KBButton>
            </div>
          </div>
        </form>
      </Modal>
    )
  }
})

function mapStateToProps(state, props) {
  const { source_type, params = {}, data = {}, type } = props

  let values = { source_type }
  if (type === 'edit' && Object.keys(data).length) {
    values = {
      id: data.id,
      start_date: moment(data.start_date).format('YYYY-MM-DD'),
      end_date: moment(data.end_date).format('YYYY-MM-DD'),
      preview_start_at: moment
        .unix(data.preview_start_at)
        .format('YYYY-MM-DD HH:mm'),
      discount_price: Math.abs(data.discount_price),
      price: data.price,
      real_price: getFinalPrice(data.price, data.discount_price),
      title: data.title,
      source_type,
      discount_bridge_price: Math.abs(data.discount_bridge_price),
      discount_tenant_price: Math.abs(data.discount_tenant_price),
      bridge_price: data.bridge_price,
      tenant_price: data.tenant_price,
      real_tenant_price: getFinalPrice(
        data.tenant_price,
        data.discount_tenant_price
      ),
      real_bridge_price: getFinalPrice(
        data.bridge_price,
        data.discount_bridge_price
      ),
      note: data.note || ''
    }
  } else if (type === 'add' && data && Object.keys(data).length) {
    values = {
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      preview_start_at: moment()
        .subtract(1, 'days')
        .startOf('hour')
        .format('YYYY-MM-DD HH:mm'),
      source_id: data.id || '',
      source_type
    }
    if (source_type === 'meeting') {
      values = {
        ...values,
        price: data.non_entering_listing_price,
        bridge_price: data.bridge_listing_price,
        tenant_price: data.listing_price
      }
    } else {
      values = {
        ...values,
        price: data.non_entering_day_listing_price,
        bridge_price: data.bridge_listing_price,
        tenant_price: data.day_listing_price
      }
    }
  }
  return {
    initialValues: values
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

const validate = values => {
  const errors = {}
  if (!values.title) errors.title = '请输入优惠名称'
  if (!values.start_date) errors.start_date = '选请择预定日期'
  if (!values.end_date) errors.start_date = '选请择预定日期'
  if (!values.preview_start_at) errors.preview_start_at = '选请择提前预览日期'
  if (
    values.start_date &&
    values.preview_start_at &&
    moment(values.preview_start_at) > moment(values.start_date)
  )
    errors.preview_start_at = `请选择小于${moment(values.start_date).format(
      'YYYY-MM-DD HH:mm'
    )}的日期`
  return errors
}

const formConfig = {
  form: 'SettingCoupon',
  fields: [
    'id',
    'title',
    'start_date',
    'end_date',
    'preview_start_at',
    'price',
    'tenant_price',
    'bridge_price',
    'discount_price',
    'discount_tenant_price',
    'discount_bridge_price',
    'real_price',
    'real_tenant_price',
    'real_bridge_price',
    'source_type',
    'source_id',
    'note'
  ],
  validate: validate,
  touchOnBlur: false
}

SettingCoupon = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SettingCoupon)

export default SettingCoupon
