import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import {
  KBPopover,
  KBLoadingContainer,
  KBDropdown,
  KBTableWithoutData
} from 'components'
import { subscriptionsAction } from 'app/actions'
import { connect } from 'react-redux'
import {
  getEntitiesJsonArray,
  paginate,
  arrayToObject,
  util,
  getFilterAndQuery,
  queryFilter,
  formatYearDayEN,
  fmoney
} from 'utils/kbUtil'
import {
  ORG_SUBSCRIPTION_STATES,
  ORG_SUBSCRIPTION_STATES_CLASS_NEW,
  INVOICES_SUBSCRIPTION_STATES,
  SUBSCRIPTION_TYPES_OBJ
} from 'app/constants'
import { getSubScriptionsByOrg } from 'app/selectors'
import OrgSubTerminateForm from './OrgSubTerminateForm'
import {
  canAccessLocation,
  canAccessSpaceContract,
  getLSRoles,
  canAccessLocationFinance,
  canAccessSpaceRoles,
  canAccessLocationExceptObserver,
  canAccessLocationRoles
} from 'app/reducers/role'
import _ from 'lodash'
import moment from 'moment'

let NEW_INVOICES_SUBSCRIPTION_STATES = INVOICES_SUBSCRIPTION_STATES.slice()
NEW_INVOICES_SUBSCRIPTION_STATES.push(
  NEW_INVOICES_SUBSCRIPTION_STATES.splice(0, 1)[0]
)

const FILTER_STATE = ['status']

var OrgSubscriptions = React.createClass({
  displayName: 'OrgSubscriptions',
  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      filters:
        Object.keys(filters).length > 0 ? filters : { status: 'available' }
    }
  },

  getSubscriptions(filters) {
    const {
      location,
      org,
      getSubscriptionsAction,
      query,
      routerActions,
      loc_id
    } = this.props
    let stage = ''
    if (filters && filters.status) {
      stage = filters.status
    }

    let queryFiltersStr = queryFilter({ ...query, ...filters })

    this.setState({
      loading: true
    })
    if (stage == 'available') {
      stage = 'active,expiring'
    }

    let params = {
      organization_id: org.id,
      per_page: 100,
      location_id: location.id,
      status: stage
    }

    if (!params.status) {
      delete params.status
    }

    api
      .getSubscriptions(params)
      .then(
        json => {
          let subscriptions = getEntitiesJsonArray(json, 'subscriptions')
          getSubscriptionsAction.success(json, { entityName: 'subscriptions' })
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/locations/${loc_id}/organizations/${org.id}/contracts${queryFiltersStr}`
          )
        },
        errors => {
          getSubscriptionsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  componentDidMount() {
    const { filters } = this.state
    this.mounted = true
    this.getSubscriptions(filters)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _setFilter(key, value) {
    let filters = Object.assign({}, this.state.filters, { [key]: value })
    this.setState({
      filters
    })
    // force refresh
    this.getSubscriptions(filters)
  },

  linkSubscriptionsDetail(m) {
    const { routerActions } = this.props
    const { location, org } = this.props

    routerActions.push(
      `/admin/locations/${location.id}/organizations/${org.id}/subscriptions/${m.id}`
    )
  },

  deleteSubscription(m) {
    let id = m.id
    if (!id) {
      return
    }
    const { deleteSubscriptionsAction } = this.props
    KBPopover.plugins.confirm('删除合同', '是否要删除合同' + m.serial, {
      confirm: () => {
        api.deleteSubscription(id).then(
          json => {
            deleteSubscriptionsAction.success(json, {
              id,
              key: 'subscriptions'
            })
            KBPopover.close()
          },
          errors => {
            deleteSubscriptionsAction.failure(errors)
            KBPopover.close()
          }
        )
      }
    })
  },

  toContractForm(m) {
    const { routerActions, location, org } = this.props
    const { id, subscription_type } = m
    let url = `/admin/locations/${location.id}/organizations/${org.id}/subscriptions/new?contract_id=${id}&status=update`
    if(subscription_type==='no_sales_area'){
      url = `/admin/locations/${location.id}/organizations/${org.id}/subscriptions/new?contract=无销售面积&contract_id=${id}&status=update`
    }
    KBPopover.plugins.confirm(
      '修改',
      '原合同会保留缴租阶段，用户可以重新生成缴租阶段，对于以前核销的缴租阶段也可以删除，确定要修改吗？',
      {
        confirm: () => {
          KBPopover.close()
          routerActions.push(
            url
          )
        }
      }
    )
  },

  anewContract(id) {
    const {
      routerActions,
      updateSubScriptionAction,
      location,
      org
    } = this.props
    let params = Object.assign(
      {},
      {
        expire_date: null,
        can_destroy_invoices: true,
        terminate_reason: 'input_mistake',
        terminate_note: '合同错误,重新录入'
      }
    )
    KBPopover.plugins.confirm(
      '重新录入',
      '即将终止合同，并copy合同到新合同，删除缴租阶段，确定要重新录入吗？',
      {
        confirm: () => {
          KBPopover.close()
          api.terminateSubScriptiopns(id, params).then(
            json => {
              updateSubScriptionAction.success(json)
              routerActions.push(
                `/admin/locations/${location.id}/organizations/${org.id}/subscriptions/new?contract=无销售面积&contract_id=${id}&status=anew`
              )
            },
            error => {
              updateSubScriptionAction.failure(error)
            }
          )
        }
      }
    )
  },

  terminateContract(id) {
    const { filters } = this.state
    KBPopover.show(
      <OrgSubTerminateForm
        filters={filters}
        subId={id}
        getSubscriptions={this.getSubscriptions}
        callback={() => {
          localStorage.setItem('reloadOrgInfo', true)
        }}
      />
    )
  },

  extendContract(id) {
    const { routerActions } = this.props
    const { location, org } = this.props
    KBPopover.plugins.confirm(
      '续约',
      '新合同即将copy合同工位信息并重置起止日期、签订日期、缴租阶段，确定要续约吗？',
      {
        confirm: () => {
          KBPopover.close()
          routerActions.push(
            `/admin/locations/${location.id}/organizations/${org.id}/subscriptions/new?contract=无销售面积&contract_id=${id}&status=extend`
          )
        }
      }
    )
  },

  openAudit(m) {
    const { routerActions } = this.props
    const { location, org } = this.props
    const { id, subscription_type } = m
    let url = `/admin/locations/${location.id}/organizations/${org.id}/subscriptions/new?contract_id=${id}&status=audit`
    if(subscription_type==='no_sales_area'){
      url = `/admin/locations/${location.id}/organizations/${org.id}/subscriptions/new?contract=无销售面积&contract_id=${id}&status=audit`
    }
    routerActions.push(
      url
    )
  },

  render() {
    const { filters } = this.state
    const {
      subscriptions,
      isManager,
      isOnlyContract,
      user,
      connect,
      org,
      isLocationAdmin
    } = this.props
    let newSubscriptions = subscriptions
    if(org.enter_type === 'longterm'){
      newSubscriptions=subscriptions.filter(sub=>sub.subscription_type === 'no_sales_area')
    }
    const stateType = ORG_SUBSCRIPTION_STATES
    const stateType_class = ORG_SUBSCRIPTION_STATES_CLASS_NEW
    return (
      <div style={{ height: '100%' }}>
        <div style={{ display: 'flex' }}>
          {NEW_INVOICES_SUBSCRIPTION_STATES.map((json, index) => {
            const isCheckedType = json.id == filters.status
            return (
              <div
                key={index}
                className={`border-show_status ${
                  isCheckedType ? 'border-show_status_active' : ''
                }`}
                onClick={() => this._setFilter('status', json.id, json)}
              >
                <span className="status-text">{json.name}</span>
              </div>
            )
          })}
        </div>
        <KBLoadingContainer loading={this.state.loading}>
          <table className="content-table edit-table">
            <thead>
              <tr>
                <th>编号</th>
                <th>状态</th>
                <th>起止日期</th>
                <th>可入驻人数</th>
                <th>已入驻人数</th>
                <th>合同总额</th>
                <th>操作</th>
              </tr>
            </thead>
            <KBTableWithoutData
              hasData={newSubscriptions.length > 0}
              tableHeadNum="7"
              tipMessage="暂无数据"
            >
              {newSubscriptions &&
                newSubscriptions.map(m => {
                  let isOperateContract =
                    canAccessLocationExceptObserver(user) &&
                    (m.status == 'active' || m.status == 'expiring')
                  let isOperateApprovingContract =
                    canAccessLocationExceptObserver(user) &&
                    (m.status == 'approving' ||
                      m.status == 'draft' ||
                      m.status == 'rejected')
                  let isEndedContract =
                    canAccessLocationExceptObserver(user) &&
                    m.status != 'cancelled' &&
                      m.status != 'expiring' &&
                      m.status != 'ended'
                  let contract_status =
                    m.status == 'expiring' ? 'active' : m.status
                  let isHaveNot =
                    m.status == 'active' &&
                    moment(m.start_date).isAfter(new Date())
                      ? true
                      : false
                  return (
                    <tr
                      key={m.id}
                      onClick={this.linkSubscriptionsDetail.bind(null, m)}
                    >
                      <td className="color-link">
                        {m.serial}
                        {m.file ? (
                          <i
                            style={{ marginLeft: 5 }}
                            className="iconfont icon-scann"
                          />
                        ) : (
                          ''
                        )}
                        <span className="span-type-smail-i m-left-sm">
                          {SUBSCRIPTION_TYPES_OBJ[m.subscription_type] || ''}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{ marginLeft: 0 }}
                          className={`finance-default ${stateType_class[contract_status]}`}
                        >
                          {stateType[contract_status]}
                        </span>
                      </td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{formatYearDayEN(m.start_date)}</span>
                          <span style={{ marginLeft: 5, marginRight: 5 }}>
                            至
                          </span>
                          <span>{formatYearDayEN(m.expire_date)}</span>
                          {m.status == 'expiring' ? (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: 15
                              }}
                            >
                              <img
                                src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-expiring.png"
                                alt="icon"
                                style={{ width: 15 }}
                              />
                              <span
                                style={{
                                  marginLeft: 5,
                                  color: '#dd5a55',
                                  fontSize: 12,
                                  marginTop: 1
                                }}
                              >
                                快到期
                              </span>
                            </div>
                          ) : isHaveNot ? (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: 15
                              }}
                            >
                              <img
                                src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-havenot.png"
                                alt="icon"
                                style={{ width: 15 }}
                              />
                              <span
                                style={{
                                  marginLeft: 5,
                                  color: '#2ea1f8',
                                  fontSize: 12,
                                  marginTop: 1
                                }}
                              >
                                未开始
                              </span>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                      <td>{m.units}</td>
                      <td>{m.units - m.remain_units}</td>
                      <td>
                        ¥&nbsp;
                        {fmoney((m.total_amount || 0) + (m.deposit || 0))}
                      </td>
                      <td>
                        {isLocationAdmin && connect && m.status != 'ended' ? (
                          <KBDropdown>
                            <KBDropdown.KBDropdownTrigger>
                              <div className="select-drop_down">
                                <div className="select-dropDown_left">选择</div>
                                <div className="select-dropDown_right">
                                  <i className="iconfont icon-arrow_drop_down_circle" />
                                </div>
                              </div>
                            </KBDropdown.KBDropdownTrigger>
                            <KBDropdown.KBDropdownContent
                              style={{
                                left: '50%',
                                transform: 'translateX(-50%)'
                              }}
                            >
                              <div className="user-dropdown">
                                {isOperateContract && m.subscription_type !== 'no_sales_area' ? (
                                  <a
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.extendContract(m.id)
                                    }}
                                  >
                                    续约
                                  </a>
                                ) : (
                                  ''
                                )}
                                {isEndedContract ? (
                                  <a
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.terminateContract(m.id)
                                    }}
                                  >
                                    终止
                                  </a>
                                ) : (
                                  ''
                                )}
                                {isOperateContract && m.subscription_type !== 'no_sales_area' ? (
                                  <a
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.anewContract(m.id)
                                    }}
                                  >
                                    重新录入
                                  </a>
                                ) : (
                                  ''
                                )}
                                {isOperateContract &&
                                m &&
                                (m.status == 'cancelled' ||
                                  m.status == 'ended' ||
                                  m.status == 'approving' ||
                                  m.status == 'rejected' ||
                                  m.status == 'draft' ||
                                  m.status == 'waiting_approving' ||
                                  m.status == 'already_approving') ? (
                                  <a
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.toContractForm(m)
                                    }}
                                  >
                                    修改
                                  </a>
                                ) : (
                                  ''
                                )}
                                {isOperateApprovingContract ? (
                                  <a
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.openAudit(m)
                                    }}
                                  >
                                    进入合同流程
                                  </a>
                                ) : (
                                  ''
                                )}
                                {isOperateApprovingContract ? (
                                  <a
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.deleteSubscription(m)
                                    }}
                                  >
                                    删除
                                  </a>
                                ) : (
                                  ''
                                )}
                              </div>
                            </KBDropdown.KBDropdownContent>
                          </KBDropdown>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  )
                })}
            </KBTableWithoutData>
          </table>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { org, loc_id, query } = props
  let { user } = state
  let subscriptions = getSubScriptionsByOrg(state, org.id)
  let isManager = canAccessLocation(user, loc_id)
  let connect = org && org.connect
  let userRoles = getLSRoles(user)
  let operateContract = canAccessSpaceRoles(user, ['admin', 'invoices'])
  const isLocationAdmin = canAccessLocationRoles(user, loc_id, [
    'location_manager',
    'location_operator'
  ])
  let isOnlyContract =
    _.intersection(userRoles, ['contract']).length == 1 && !operateContract
      ? true
      : false
  var params = getFilterAndQuery(query, FILTER_STATE)
  return {
    subscriptions,
    isManager,
    loc_id,
    org,
    connect,
    user,
    isOnlyContract,
    isLocationAdmin,
    ...params
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {
    getSubscriptionsAction: bindActionCreators(
      subscriptionsAction.replace,
      dispatch
    ),
    deleteSubscriptionsAction: bindActionCreators(
      subscriptionsAction.delete,
      dispatch
    ),
    updateSubScriptionAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgSubscriptions)
