import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { DAY_TIME_HM } from 'app/constants'
import { lockPermissionsActions, successState } from 'app/actions'
import { api, apiUtils } from 'app/services'
import {
  KBPopover,
  KBUserInput,
  KBFormError,
  KBButton,
  KBDateInput,
  KBSelect
} from 'components'
import moment from 'moment'

var LockPermissionsUpdateForm = React.createClass({
  getInitialState() {
    return {}
  },
  updateLockForm(model) {
    const {
      loc_id,
      successActions,
      updateLocksPermissionsAction,
      isGroup,
      allPermissions
    } = this.props

    if (isGroup) {
      let newValue = model.lock_permissions[0]
      model.lock_permissions = allPermissions.map(p => {
        let newP = {}
        newP.id = p.id
        newP.start_at = newValue.start_at
        newP.end_at = newValue.end_at
        return newP
      })
    }

    return api.updateLockPermissions(loc_id, model).then(json => {
      updateLocksPermissionsAction.success(json)
      successActions({
        message: isGroup ? '门禁组权限修改成功' : '门禁权限修改成功'
      })
      KBPopover.close()
      return true
    })
  },

  changeStartYMDCallback(data) {
    const {
      fields: { lock_permissions }
    } = this.props
    this.setYMD(lock_permissions[0].start_at, data)
  },

  changeEndYMDCallback(data) {
    const {
      fields: { lock_permissions }
    } = this.props
    this.setYMD(lock_permissions[0].end_at, data)
  },

  changeStartHMCallback(data) {
    const {
      fields: { lock_permissions }
    } = this.props
    this.setHM(lock_permissions[0].start_at, data)
  },

  changeEndHMCallback(data) {
    const {
      fields: { lock_permissions }
    } = this.props
    this.setHM(lock_permissions[0].end_at, data)
  },

  setYMD(field, value) {
    let date = moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  setHM(field, value) {
    let t = value.split(',')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ hour: t[0], minute: t[1] == '1' ? 0 : 30, second: 0 })
        .toDate()
    )
  },

  render() {
    const {
      fields: { lock_permissions },
      error,
      submitting,
      handleSubmit,
      isGroup,
      DEAL_DAY_TIME_HM
    } = this.props

    var halfClass = {
      className: 'kb-form-group kb-div-c48'
    }
    return (
      <form onSubmit={handleSubmit(this.updateLockForm)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">
            {isGroup ? '修改门禁组权限' : '修改门禁权限'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        {lock_permissions.length > 0 &&
          lock_permissions.map((permissions, index) => {
            return (
              <div className="kb-form-container-m" key={index}>
                {isGroup ? (
                  <div className="kb-tip-warning">
                    <div className="icon">
                      <i className="iconfont icon-hint" />
                    </div>
                    <div className="msg">
                      此修改将会应用到当前门禁组下的所有门禁权限
                    </div>
                  </div>
                ) : null}
                <div className="kb-row kb-form-66">
                  <KBDateInput
                    type="date"
                    title="开始日期"
                    isRequired={true}
                    className="kb-form-group kb-div-48"
                    field={permissions.startYMD}
                    onChange={this.changeStartYMDCallback}
                  />
                  <KBSelect
                    title="开始时间"
                    onChange={this.changeStartHMCallback}
                    titleClass="label-rt"
                    arrayData={DEAL_DAY_TIME_HM}
                    field={permissions.startHM}
                    {...halfClass}
                  />
                </div>
                <div className="kb-row kb-form-66">
                  <KBDateInput
                    type="date"
                    title="结束日期"
                    isRequired={true}
                    className="kb-form-group kb-div-48"
                    field={permissions.endYMD}
                    onChange={this.changeEndYMDCallback}
                  />
                  <KBSelect
                    title="结束时间"
                    onChange={this.changeEndHMCallback}
                    titleClass="label-rt"
                    arrayData={DEAL_DAY_TIME_HM}
                    field={permissions.endHM}
                    {...halfClass}
                  />
                </div>
                <div className="kb-row fixed-form-btn">
                  <span className="cancel-btn" onClick={KBPopover.close}>
                    取消
                  </span>
                  <KBButton
                    className="certain-btn"
                    type="submit"
                    submitting={submitting}
                  >
                    确定
                  </KBButton>
                </div>
              </div>
            )
          })}
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(0, 24)
  return {
    DEAL_DAY_TIME_HM
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.update,
      dispatch
    ),
    successActions: bindActionCreators(successState, dispatch)
  }
}

const validatePermissions = Permissions => {
  const errors = {}

  if (
    Permissions.start_at &&
    Permissions.end_at &&
    moment(Permissions.end_at).diff(moment(Permissions.start_at)) < 0
  ) {
    errors.endHM = '结束日期必须大于开始日期'
  }

  return errors
}

const validate = values => {
  const errors = {}

  errors.lock_permissions = values.lock_permissions.map(validatePermissions)

  return errors
}

const formConfig = {
  form: 'MeetingForm',
  fields: [
    'lock_permissions[].id',
    'lock_permissions[].start_at',
    'lock_permissions[].end_at',
    'lock_permissions[].startHM',
    'lock_permissions[].endHM',
    'lock_permissions[].startYMD',
    'lock_permissions[].endYMD'
  ],
  validate: validate,
  touchOnBlur: false
}

LockPermissionsUpdateForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LockPermissionsUpdateForm)

export default LockPermissionsUpdateForm
