import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { reservationsActions, orgActions, apiCallFailure } from 'app/actions'
import { connect } from 'react-redux'
import { get } from '../../app/reducers/reducer_utils'
import { routerActions } from 'react-router-redux'
import {
  KBLoadingContainer,
  KBPopover,
  KBRangeDate,
  KBPagination,
  KBTableWithoutData,
  KBAvatar
} from 'components'
import {
  getEntitiesJsonArray,
  paginate,
  queryFilter,
  getFilterAndQuery,
  formatYearDayEN,
  formatOnlyMinuteEN,
  kbCloseDropDown
} from 'utils/kbUtil'
import { KBDayPicker } from 'components/views'
import { KBDropSelect } from 'components/plugins'
import MeetingSearchRoomForm from './MeetingSearchRoomForm'
import * as selectors from 'app/selectors'
import { canAccessLocation } from 'app/reducers/role'
import { getInitialDate } from 'utils/kbData'
import _ from 'lodash'
import { Link } from 'react-router'
import { getUserInfoUrl, getMeetingRoomDetailPage } from 'utils/kbUrl'

const DROP_DOWNS = ['meeting_room', 'dateDropDown', 'company']
const FILTER_STATE = ['start_at', 'end_at', 'area_id', 'organization_id']

var MeetingRecord = React.createClass({
  displayName: 'MeetingRecord',
  getInitialState() {
    const { filters } = this.props
    return {
      loading: false,
      reservations: [],
      filters
    }
  },
  getDefaultProps() {
    return {}
  },

  getParamsThree() {
    return 'area_id'
  },

  componentDidMount() {
    const { page, filters, loc_id, orgs, getOrganizationsAction } = this.props
    this.mounted = true

    api
      .getOrganizations({
        location_id: loc_id,
        per_page: 1000,
        organization_type: 'normal'
      })
      .then(
        json => {
          getOrganizationsAction.success(json, { location_id: loc_id })
        },
        errors => {
          getOrganizationsAction.failure(errors)
        }
      )

    this._loadData(filters, page)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(filters, page) {
    const { loc_id, getReservationsAction, routerActions, query } = this.props
    const { from, to } = filters
    let params = Object.assign(
      {},
      filters,
      { page },
      { state: 'reserved_active' }
    )

    params.per_page = 20
    params.location_id = loc_id

    if (from && to) {
      params.start_at = from && formatYearDayEN(from)
      params.end_at = to && formatYearDayEN(to)
    }
    delete params.from
    delete params.to

    let queryFiltersStr = queryFilter({ ...query, ...params, page: page })
    this.setState({ loading: true })
    // 获取会议室预定列表
    api
      .getReservations(params)
      .then(
        json => {
          getReservationsAction.success(json)
          let reservations = []
          reservations = getEntitiesJsonArray(json, 'reservations')
          let pagination = paginate(json.response)
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `/admin/locations/${loc_id}/meeting/record${queryFiltersStr}`
          )
          this.setState({
            reservations,
            pagination
          })
        },
        errors => {
          getReservationsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page != nextProps.page) {
      this._loadData({ ...this.state.filters, ...filters }, nextProps.page)
    }
  },
  changeDate(range) {
    this._setFilter(null, null, range)
  },

  changeDayPicker(date) {
    const { loc_id } = this.props
    this._setFilter('time', date)
  },

  searchMeetingRoom() {
    const { loc_id } = this.props
    KBPopover.show(<MeetingSearchRoomForm loc_id={loc_id} />)
  },

  selectCallBack(data) {
    if (!data) {
      this._removeFilter('area_id')
      return
    }

    this._setFilter('area_id', data && data.id)
  },

  orgSelectCallBack(data) {
    if (!data) {
      this._removeFilter('organization_id')
      return
    }

    this._setFilter('organization_id', data && data.id)
  },

  _removeFilter(key) {
    let newFilters = _.omit(this.state.filters, [key])
    this.setState({ filters: newFilters })
    // force refresh
    this._refresh(key, newFilters)
  },

  _setFilter(key, value, obj) {
    let newFilters = Object.assign(
      {},
      this.state.filters,
      obj ? obj : { [key]: value }
    )

    this.setState({ filters: newFilters, loading: true })

    this._refresh(key, newFilters)
  },
  _refresh(key, filters) {
    const {
      tasks_type,
      page,
      newTask,
      allTasksAction,
      location_id,
      query,
      routerActions
    } = this.props
    let dropDown = key + 'Dropdown'
    this[dropDown] && this[dropDown].hide()
    this._loadData(filters, 1)
  },

  clearFilter() {
    this._clearFilters()
    this.meeting_room.clearData()
    this.company.clearData()
  },

  _clearFilters() {
    const filters = { filters: {}, loading: true }
    this.setState(filters)
    this._loadData({}, 1)
  },

  closeDropDowns(type) {
    kbCloseDropDown(this, DROP_DOWNS, type)
  },

  orgDetail(orgId, loc_id) {
    const { routerActions, locations, apiCallFailureAction } = this.props;
    api
      .getOrganizationInfo(orgId, {
        location_id: loc_id
      }).then(() => {
        //当前分店
        routerActions.replace(
          `/admin/locations/${loc_id}/organizations/${orgId}`
        )
      }, errors => {
        //其他分店

        if (errors.message.indexOf("Couldn't find Organization with 'id'") != -1) {
          api.getOrganizationInfo(orgId).then(json => {
            const organizations = get(json.json, ['entities', 'organizations', orgId])
            let hasPermissions = false
            locations.map(loc => {
              if (loc.id == organizations.locations[0].id) {// 有权限
                hasPermissions = true
              }
            })
            // 有权限
            if (hasPermissions) {
              routerActions.replace(
                `/admin/locations/${organizations.locations[0].id}/organizations/${orgId}`
              )
            } else {
              // 没权限提示
              apiCallFailureAction({
                status: 'error',
                message: '您没有查看当前公司所在分店的权限！'
              })
            }
          })
        }
      })
  },

  render() {
    const {
      loc_id,
      meetings,
      orgs,
      isManager,
      currentArea,
      currentOrg
    } = this.props
    const { reservations, pagination, filters, loading } = this.state
    const { from, to, start_at, end_at } = this.state.filters

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>预定记录</span>
          </h2>
          {isManager ? (
            <a
              className="bordered-btn f-right"
              onClick={this.searchMeetingRoom}
            >
              <i className="iconfont icon-add" />
              添加新预约
            </a>
          ) : (
              ''
            )}
        </header>
        <div className="nav-section-content-container">
          <div className="m-top-sm">
            <KBRangeDate
              format="YYYY/MM/DD"
              from={from || start_at}
              to={to || end_at}
              leftNone={true}
              ref={ref => {
                this.dateDropDown = ref
              }}
              dropDown_name="dateDropDown"
              closeClick={this.closeDropDowns}
              callback={this.changeDate}
            />
            <KBDropSelect
              ref={ref => {
                this.meeting_room = ref
              }}
              dropDown_name="meeting_room"
              closeClick={this.closeDropDowns}
              options={{ defaultSingleContent: '选择会议室' }}
              defaultData={meetings}
              selectedData={currentArea}
              multi={false}
              callback={this.selectCallBack}
              style={{ marginLeft: 20 }}
            />
            <KBDropSelect
              ref={ref => {
                this.company = ref
              }}
              dropDown_name="company"
              selectedData={currentOrg}
              closeClick={this.closeDropDowns}
              options={{ defaultSingleContent: '选择公司' }}
              defaultData={orgs}
              multi={false}
              callback={this.orgSelectCallBack}
              style={{ marginLeft: 20 }}
            />
            {Object.keys(this.state.filters).length > 0 ? (
              <div>
                <div
                  className="clear-criteria"
                  style={{ marginTop: 5 }}
                  onClick={this.clearFilter}
                >
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              </div>
            ) : null}
          </div>
          <KBLoadingContainer loading={loading}>
            <table className="content-table">
              <thead>
                <tr>
                  <th>场地名称</th>
                  <th>公司</th>
                  <th>租借者</th>
                  <th>电话</th>
                  <th>使用日期</th>
                  <th>开始时间</th>
                  <th>结束时间</th>
                </tr>
              </thead>
              <KBTableWithoutData
                hasData={reservations.length > 0}
                tableHeadNum="7"
                tipMessage="暂无预定记录"
              >
                {reservations &&
                  reservations.map((reservation, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Link
                            className="color-link"
                            to={getMeetingRoomDetailPage(
                              loc_id,
                              reservation.area_id
                            )}
                          >
                            {reservation.area_name}
                          </Link>
                        </td>
                        <td>
                          {/* <Link
                            className="color-link"
                            to={`/admin/locations/${loc_id}/organizations/${reservation.organization_id}`}
                          >
                            {reservation.organization_name || ''}
                          </Link> */}
                          <a className="color-link" onClick={() => this.orgDetail(reservation.organization_id, loc_id)}>
                            {reservation.organization_name || ''}
                          </a>
                        </td>
                        <td>
                          <div className="vf-center">
                            <KBAvatar
                              style={{
                                display: 'inline-block',
                                marginRight: '5px'
                              }}
                              imgStyle={{ marginRight: 5 }}
                              user={reservation.host}
                              size={20}
                            />
                            <Link
                              className="color-link"
                              to={getUserInfoUrl(
                                reservation.host && reservation.host.id
                              )}
                            >
                              {(reservation.host && reservation.host.name) ||
                                ''}
                            </Link>
                          </div>
                        </td>
                        <td>{reservation.contacts_phone}</td>
                        <td>
                          {reservation.start_at
                            ? formatYearDayEN(reservation.start_at)
                            : '无'}
                        </td>
                        <td>
                          {reservation.start_at
                            ? formatOnlyMinuteEN(reservation.start_at)
                            : '无'}
                        </td>
                        <td>
                          {reservation.real_end_at
                            ? formatOnlyMinuteEN(reservation.real_end_at)
                            : '无'}
                        </td>
                      </tr>
                    )
                  })}
              </KBTableWithoutData>
            </table>
          </KBLoadingContainer>
          <KBPagination
            pagination={pagination}
            template={`/admin/locations/${loc_id}/meeting/record?page=#PAGE#`}
          />
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { user } = state
  let loc_id = params.id

  let page = location.query && parseInt(location.query.page)
  page = isNaN(page) ? 1 : page

  let meetings = selectors.getMeetingRoomsOfLocation(state, loc_id)
  let orgs = selectors.getOrgsOfLocation(state, loc_id)
  const locations = selectors.getLocationsOfUser(state)
  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)
  let isManager = canAccessLocation(user, loc_id)
  let currentArea
  if (paramsAll.filters.area_id) {
    currentArea = meetings.find(
      meeting => paramsAll.filters.area_id == meeting.id
    )
  }
  let currentOrg
  if (paramsAll.filters.organization_id) {
    currentOrg = orgs.find(orgs => paramsAll.filters.organization_id == orgs.id)
  }

  return {
    loc_id,
    orgs,
    meetings,
    page,
    currentArea,
    currentOrg,
    ...paramsAll,
    isManager,
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getReservationsAction: bindActionCreators(
      reservationsActions.all,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    getOrganizationsAction: bindActionCreators(orgActions.get, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRecord)