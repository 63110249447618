import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import ReactDOM from 'react-dom'
import { reduxForm, getValues } from 'redux-form'
import { reservationsActions } from 'app/actions'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { connect } from 'react-redux'
import * as selectors from 'app/selectors'
import {
  KBInput,
  KBPopover,
  KBFormError,
  KBSelect,
  KBButton,
  KBASNumberInput,
  KBUserInput,
  KBDateInput
} from 'components'
import moment from 'moment'
import { DAY_TIME_HM } from 'app/constants'
import { getInitialDate } from 'utils/kbData'

var MeetingSearchRoomForm = React.createClass({
  getInitialState() {
    return {
      members: [],
      name: null,
      phone: null
    }
  },

  searchRoom(model) {
    const { routerActions, loc_id } = this.props
    let timeYMD = moment(model.timeYMD).format('YYYY-MM-DD')
    let startHM = this.getHMName(model.startHM)
    let endHM = this.getHMName(model.endHM)
    let capacity = model.capacity || 0

    routerActions.push(
      `/admin/locations/${loc_id}/meeting/booking?time=${timeYMD}&start_at=${startHM}&end_at=${endHM}&capacity=${capacity}`
    )
    KBPopover.close()
    return true
  },

  getHMName(value) {
    return DAY_TIME_HM(0, 24).find(json => json.id == value).name
  },

  componentDidMount() {
    const {
      fields: { timeYMD, startHM, endHM, start_at, end_at, capacity },
      time
    } = this.props
    const currentStartAt = getInitialDate(time).initStartDate
    let initTime = this.parseTime()
    let initMinute = this.parseInitTime(initTime)

    timeYMD.onChange(currentStartAt.toDate())

    startHM.onChange(initTime.h + ',' + initMinute)
    endHM.onChange(initTime.h + 1 + ',' + initMinute)
    start_at.onChange(
      moment()
        .set({ hour: initTime.h, minute: initTime.m, second: 0 })
        .toDate()
    )
    end_at.onChange(
      moment()
        .set({ hour: initTime.h + 1, minute: initTime.m, second: 0 })
        .toDate()
    )

    capacity.onChange(4)
  },

  parseTime() {
    let currentHour = moment().hour()
    let currentMinute = moment().minute()
    let initTime = {}
    if (currentMinute < 30) {
      currentMinute = 30
    } else {
      currentMinute = 0
      currentHour = currentHour + 1
    }
    initTime.h = currentHour
    initTime.m = currentMinute
    return initTime
  },

  parseInitTime(time) {
    let m = time.m
    if (m == 30) {
      m = 2
    } else {
      m = 1
    }
    return m
  },

  setYMD(field, value) {
    let date = moment(value)

    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  setHM(field, value) {
    let t = value.split(',')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ hour: t[0], minute: t[1] == '1' ? 0 : 30, second: 0 })
        .toDate()
    )
  },

  changeYMDCallback(date) {
    const {
      fields: { start_at, end_at }
    } = this.props
    this.setYMD(start_at, date)
    this.setYMD(end_at, date)
  },

  changeStartHMCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setHM(start_at, data)
  },

  changeEndHMCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setHM(end_at, data)
  },

  render() {
    const {
      fields: { timeYMD, startHM, endHM, capacity },
      error,
      submitting,
      handleSubmit,
      DEAL_DAY_TIME_HM
    } = this.props
    const { members } = this.state

    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }
    return (
      <form onSubmit={handleSubmit(this.searchRoom)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <div className="header-title">搜索预约场地</div>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBDateInput
              field={timeYMD}
              title="预定日期"
              className="kb-form-c18 kb-form-group kb-form-alone"
              onChange={this.changeYMDCallback}
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBSelect
              defaultValue="-1"
              title="开始"
              onChange={this.changeStartHMCallback}
              arrayData={DEAL_DAY_TIME_HM}
              field={startHM}
              {...halfClass}
            />
            <KBSelect
              defaultValue="-1"
              title="结束"
              onChange={this.changeEndHMCallback}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
              field={endHM}
              {...halfClass}
            />
          </div>
          <div className="kb-row">
            <KBASNumberInput
              className="kb-form-c18 kb-form-group kb-form-alone"
              title={'容纳人数'}
              field={capacity}
              counterStyle={{ width: 110 }}
              spanStyle={{ width: 34, height: 34, lineHeight: '34px', top: 1 }}
              inputStyle={{
                borderColor: '#e6e6e6',
                textAlign: 'center',
                textIndent: '0px'
              }}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton className="certain-btn" type="submit">
              搜索
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  let DEAL_DAY_TIME_HM = DAY_TIME_HM(0, 24)
  return {
    DEAL_DAY_TIME_HM
  }
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.timeYMD)) {
    errors.timeYMD = '请选择时间段'
  }

  if (valid.required(values.startHM)) {
    errors.startHM = '请选择开始时间'
  }

  if (valid.required(values.endHM)) {
    errors.endHM = '请选择结束时间'
  }

  let end_at = moment(values.end_at)
  let start_at = moment(values.start_at)

  if (end_at.diff(start_at, 'minutes') < 30) {
    errors.startHM = '预订时长必须大于30分钟'
  }

  return errors
}

const formConfig = {
  form: 'MeetingSearchRoomForm',
  fields: ['timeYMD', 'end_at', 'start_at', 'capacity', 'startHM', 'endHM'],
  validate: validate,
  touchOnBlur: false
}

MeetingSearchRoomForm.propTypes = {
  loc_id: PropTypes.string.isRequired
}

MeetingSearchRoomForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(MeetingSearchRoomForm)

export default MeetingSearchRoomForm
