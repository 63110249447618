import React from 'react'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { KBLoadingContainer, KBPopover, KBNoData, KBButton } from 'components'
import { connect } from 'react-redux'
import { lockersAction, apiCallFailure } from 'app/actions'
import { Popover } from 'antd'
import * as selectors from 'app/selectors'
import intl from 'react-intl-universal'
import _ from 'lodash'
import moment from 'moment'
import { KBPopoverConfirm } from 'components/tools'
import {
  getEntitiesJsonArray,
  getServiceErrorMessage,
  formatHoursMinutesCHS
} from 'utils/kbUtil'
import LockerForm from './LockerForm'
import LockerCabinetForm from './LockerCabinetForm'
import DelLockerCabinetForm from './DelLockerCabinetForm'

var Lockers = React.createClass({
  getInitialState() {
    return {
      lockerLoading: true,
      cabinetLoading: true,
      currentLocker: null,
      cabinets: []
    }
  },

  componentDidMount() {
    this.loadData()
  },

  loadData() {
    const { getLockersAction, location_id } = this.props
    this.setState(
      {
        lockerLoading: true,
        cabinetLoading: true
      },
      () => {
        api
          .getLockers({
            location_id
            // per_page: 500,
          })
          .then(
            json => {
              getLockersAction.success(json, { entityName: 'lockers' })
              const lockersState = getEntitiesJsonArray(json, 'lockers')
              this.setState({
                currentLocker: lockersState[0]
              })
              lockersState[0] && this.loadCabinets(lockersState[0].id)
            },
            error => {
              getLockersAction.failure(error)
              this.setState({ cabinetLoading: false })
            }
          )
          .finally(() => {
            this.setState({
              lockerLoading: false,
              cabinetLoading: false
            })
          })
      }
    )
  },

  loadCabinets(locker_id, loading = false) {
    const { apiCallFailureActions } = this.props

    if (loading) {
      this.setState({ cabinetLoading: loading })
    }
    api
      .getLockerCabinets(locker_id)
      .then(
        ({ json }) => {
          this.setState({
            cabinets: json
          })
        },
        error => {
          const errorMessage = getServiceErrorMessage(error)
          apiCallFailureActions({
            status: 'error',
            message: errorMessage
          })
        }
      )
      .finally(() => {
        this.setState({ cabinetLoading: false })
      })
  },

  addLocker() {
    KBPopover.show(<LockerForm />)
  },

  editCabinet(cab) {
    KBPopover.show(
      <LockerCabinetForm
        initialValues={{
          section: cab.section || '',
          status: cab.status,
          port: cab.port || '',
          locker_number: cab.locker_number,
          cabinet_locker_category_id: cab.cabinet_locker_category_id
        }}
        cabinet={cab}
        callBack={this.loadCabinets}
      />
    )
  },

  deleteCabinet(cab) {
    const { location_id } = this.props
    KBPopover.show(
      <DelLockerCabinetForm
        location_id={location_id}
        cabinet={cab}
        callBack={this.loadCabinets}
      />
    )
  },

  freeCabinet(cab) {
    const { apiCallFailureActions } = this.props
    KBPopoverConfirm({
      name: '结束使用',
      context: `确认结束使用 ${cab.locker_number} 吗?`,
      callback: () => {
        return api
          .putLockerFree(cab.cabinet_id, cab.id, {
            cabinet_id: cab.cabinet_id,
            id: cab.id
          })
          .then(
            () => {
              KBPopover.close()
              this.loadCabinets(cab.cabinet.id)
            },
            error => {
              const errorMessage = getServiceErrorMessage(error)
              apiCallFailureActions({
                status: 'error',
                message: errorMessage
              })
            }
          )
      }
    })
  },

  endReservation(cab) {
    const { apiCallFailureActions } = this.props

    KBPopoverConfirm({
      name: '结束使用',
      context: '确认结束使用吗?',
      callback: () => {
        return api.postLockerReservationComplete(cab.reservation.id).then(
          () => {
            KBPopover.close()
            this.loadCabinets(cab.cabinet.id)
          },
          error => {
            const errorMessage = getServiceErrorMessage(error)
            apiCallFailureActions({
              status: 'error',
              message: errorMessage
            })
          }
        )
      }
    })
  },

  reserveCabinet(cab) {
    const { apiCallFailureActions, user } = this.props

    KBPopoverConfirm({
      name: '预定使用',
      context: `确认预定使用 ${cab.locker_number} 吗?`,
      callback: () => {
        return api
          .postLockerReservation({ locker_id: cab.id, reserver_id: user.id })
          .then(
            () => {
              KBPopover.close()
              this.loadCabinets(cab.cabinet.id)
            },
            error => {
              const errorMessage = getServiceErrorMessage(error)
              apiCallFailureActions({
                status: 'error',
                message: errorMessage
              })
            }
          )
      }
    })
  },

  switchLocker(locker) {
    this.setState({
      currentLocker: locker
    })
    this.loadCabinets(locker.id, true)
  },

  lockerHover(cab) {
    const name = _.get(cab, 'locker_number', '--')
    const reservation = _.get(cab, 'reservation', {})
    const reservation_name = _.get(reservation, 'host.name', '')
    const reservation_phone = _.get(reservation, 'host.phone_num', '')
    let created_at = _.get(reservation, 'start_at', null)
    created_at = _.isNil(created_at)
      ? ''
      : moment(created_at + '000' - 0).format('YYYY-MM-DD HH:mm:ss')
    return (
      <div className="locker_popup">
        <div className="popup_title">{_.isNil(name) ? '--' : name}</div>
        <div className="popup_user">使用人: {reservation_name}</div>
        <div className="popup_content">联系电话: {reservation_phone}</div>
        <div className="popup_content">使用时间: {created_at}</div>
        <div className="popup_bottom">
          {cab.status !== 'free' && cab.status !== 'occupied' && (
            <KBButton
              onClick={() => {
                cab.status === 'overtime'
                  ? this.deleteCabinet(cab)
                  : this.freeCabinet(cab)
              }}
              className="certain-delete-btn"
            >
              {cab.status === 'overtime' ? '等待清柜' : '结束使用'}
            </KBButton>
          )}
          {cab.status !== 'cleanup' &&
            cab.status !== 'overtime' &&
            cab.status !== 'occupied' && (
              <KBButton
                onClick={() => this.editCabinet(cab)}
                className="certain-btn"
                style={{ marginLeft: 12 }}
              >
                编辑
              </KBButton>
            )}
        </div>
      </div>
    )
  },

  render() {
    const { lockers, LOCKER_STATUS } = this.props
    const {
      cabinetLoading,
      currentLocker,
      cabinets,
      lockerLoading
    } = this.state
    return (
      <section
        className="kb-content clear-fix"
        style={{ height: '100%', paddingTop: 0 }}
      >
        <nav className="nav-container f-left">
          <div className="nav-container-content">
            <KBLoadingContainer loading={lockerLoading}>
              <header className="nav-header clear-fix">
                <h2 className="header-title f-left">管理</h2>
              </header>
              <div className="nav-body" style={{ padding: '20px 0' }}>
                <ul className="nav-container-list">
                  {lockers && lockers.length > 0 ? (
                    lockers.map(locker => {
                      return (
                        <li
                          className={
                            currentLocker && currentLocker.id == locker.id
                              ? 'nav-container-list-hover'
                              : ''
                          }
                          onClick={() => {
                            this.switchLocker(locker)
                          }}
                        >
                          <a>{_.get(locker, 'name', '-') || '-'}</a>
                        </li>
                      )
                    })
                  ) : (
                    <div className="flex-center">
                      <KBNoData tipMessage="暂无储物柜" imgWidth={120} />
                    </div>
                  )}
                </ul>
              </div>
            </KBLoadingContainer>
          </div>
        </nav>
        <section className="nav-section-container f-right">
          <KBLoadingContainer loading={cabinetLoading}>
            <header className="nav-header">
              <h2 className="nav-section-header-title f-left">
                {_.get(currentLocker, 'name', '管理') || '管理'}
              </h2>
            </header>
            <div className="nav-section-content-container">
              {cabinets && cabinets.length > 0 ? (
                <div className="lockersList">
                  {cabinets &&
                    cabinets.map(cab => {
                      const reservation = cab.reservation
                      const is_disabled =
                        !cab.reservation && cab.status != 'normal'
                      const reserver_name = _.get(
                        reservation,
                        'reserver.name',
                        ''
                      )
                      return (
                        <Popover
                          overlayClassName="popup_root"
                          placement="bottom"
                          content={this.lockerHover(cab)}
                          trigger="hover"
                        >
                          <div className={`locker locker_${cab.status}`}>
                            <span
                              className="lockerNum"
                              style={{ fontSize: 14, marginBottom: 10 }}
                            >
                              {cab.locker_number}
                            </span>
                            <span style={{ fontSize: 12 }}>
                              {reserver_name || LOCKER_STATUS[cab.status]}
                            </span>
                          </div>
                        </Popover>
                      )
                    })}
                </div>
              ) : (
                <div className="flex-center" style={{ paddingTop: 40 }}>
                  <KBNoData tipMessage="暂无储物柜" />
                </div>
              )}
            </div>
          </KBLoadingContainer>
        </section>
      </section>
    )
  }
})

function mapStateToProps(state, ownProps) {
  const user = state.user
  const { params } = ownProps
  const location_id = params.id
  const lockers = selectors.getLockers(state)
  const LOCKER_STATUS = {
    free: '空闲',
    occupied: '使用中',
    overtime: '超时',
    cleanup: '等待清柜',
    disabled: '停用'
  }

  return {
    location_id,
    lockers,
    user,
    LOCKER_STATUS
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLockersAction: bindActionCreators(lockersAction.replace, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Lockers)
