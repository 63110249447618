import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import {
  lockPermissionsActions,
  apiCallFailure,
  lockGroupsAction
} from 'app/actions'
import { DAY_TIME_HM } from 'app/constants'
import { api } from 'app/services'
import {
  KBPopover,
  KBUserInput,
  KBFormError,
  KBButton,
  KBDateInput,
  KBSelect
} from 'components'
import { getCurrentLocation } from 'utils/kbUtil'
import { canAdminLocation, canAdminLocationGroup, canAdminSpace } from 'app/reducers/role'
import * as selectors from 'app/selectors'
import moment from 'moment'

var LockPermissionsMultiForm = React.createClass({
  getInitialState() {
    return {
      locksArray: [],
      locks: [],
      lockGroupArray: [],
      lockGroups: [],
      selectAll: false
    }
  },

  componentDidMount() {
    const { getLockGroupsAction } = this.props
    const { loc_id } = this.state
    api.getLockGroups({ location_id: loc_id, per_page: 100, page: 1 }).then(
      json => {
        getLockGroupsAction.success(json)
      },
      errors => {
        getLockGroupsAction.failure(errors)
      }
    )
  },

  createLockForm() {
    const {
      fields: { start_at, end_at, user_id },
      createLocksPermissionsAction,
      apiCallFailureAction
    } = this.props
    const { locksArray, lockGroupArray, loc_id } = this.state
    let lock_permissions = []

    if (!locksArray.length && !lockGroupArray.length) {
      return apiCallFailureAction({
        status: 'error',
        message: '没有选择门禁或您已经添加过所有门禁！'
      })
    }

    locksArray.map(lock => {
      lock_permissions.push({
        user_id: user_id.value,
        lock_id: lock.id,
        start_at: start_at.value,
        end_at: end_at.value
      })
    })

    lockGroupArray.map(lock_group => {
      lock_group.locks.map(lock => {
        lock_permissions.push({
          user_id: user_id.value,
          lock_id: lock.id,
          lock_group_id: lock_group.id,
          start_at: start_at.value,
          end_at: end_at.value
        })
      })
    })

    return api
      .createLockPermissions(loc_id, { location_id: loc_id, lock_permissions })
      .then(json => {
        createLocksPermissionsAction.success(json)
        KBPopover.close()
        return true
      })
  },

  changeStartYMDCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setYMD(start_at, data)
  },

  changeEndYMDCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setYMD(end_at, data)
  },

  changeStartHMCallback(data) {
    const {
      fields: { start_at }
    } = this.props
    this.setHM(start_at, data)
  },

  changeEndHMCallback(data) {
    const {
      fields: { end_at }
    } = this.props
    this.setHM(end_at, data)
  },

  setYMD(field, value) {
    let date = moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ year, month, date: day })
        .toDate()
    )
  },

  setHM(field, value) {
    let t = value.split(',')
    field.onChange(
      moment(field.value)
        .clone()
        .set({ hour: t[0], minute: t[1] == '1' ? 0 : 30, second: 0 })
        .toDate()
    )
  },

  selectMembers(data) {
    this.setState({
      locksArray: data
    })
  },

  selectLockGroups(data) {
    this.setState({
      lockGroupArray: data
    })
  },

  selectAllLock() {
    const { locks } = this.state
    const { selectAll } = this.state
    let selectAll_now = !selectAll
    this.setState({
      selectAll: selectAll_now,
      locksArray: selectAll_now ? locks : []
    })
  },

  selectLocation(loc) {
    const loc_id = loc.id
    const { selectAll } = this.state
    const { locks, lockGroups } = this.props

    var locksArray = Object.assign([], locks)
    locksArray = locksArray.map(lock => {
      lock.lock_id = lock.id
      return lock
    })
    //   let filterLocks = _.pullAllBy(locksArray,lockPermissions,'lock_id')
    let filterLocks = locksArray
    filterLocks = filterLocks.filter(lock => lock.location_id == loc_id)

    let filteredLockGroups = lockGroups.filter(l_g => l_g.location_id == loc_id)

    this.setState({
      locks: filterLocks,
      locksArray: selectAll ? filterLocks : [],
      loc_id,
      lockGroups: filteredLockGroups
    })
  },

  render() {
    const {
      fields: {
        location_id,
        lock_id,
        lock_group_id,
        startYMD,
        startHM,
        endYMD,
        endHM
      },
      error,
      submitting,
      handleSubmit,
      DEAL_DAY_TIME_HM,
      locations
    } = this.props
    const { selectAll, locks, loc_id, lockGroups } = this.state
    var halfClass = {
      className: 'kb-form-group kb-div-c48'
    }
    let selectedLoc =
      locations &&
      locations.filter(data => {
        return data.id == loc_id
      })
    return (
      <form onSubmit={handleSubmit(this.createLockForm)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">添加门禁权限</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row">
            <label htmlFor="selectAllLock" className="clear-fix">
              <input
                className="f-left chk_1"
                id="selectAllLock"
                type="checkbox"
                style={{ display: 'none' }}
                onClick={this.selectAllLock}
              />
              <span className="f-left color-nine" style={{ marginTop: 1 }} />
              <span className="label-title f-left">是否添加全部门禁权限</span>
            </label>
          </div>
          <div className="kb-row">
            <KBUserInput
              title="分店"
              placeholder={'请选择分店'}
              className="kb-form-group kb-form-210 kb-form-alone"
              field={location_id}
              disabled={false}
              selectedUsers={selectedLoc}
              isRequired={true}
              multi={false}
              users={locations}
              callback={this.selectLocation}
            />
          </div>
          <div className="kb-row">
            <KBUserInput
              title="门禁组名称"
              placeholder="选择门禁组"
              className="kb-form-group kb-form-210 kb-form-alone"
              callback={this.selectLockGroups}
              field={lock_group_id}
              multi={true}
              users={lockGroups}
              selectedUsers={[]}
            />
          </div>
          {!selectAll ? (
            <div className="kb-row">
              <KBUserInput
                title="门禁名称"
                placeholder="选择门禁"
                className="kb-form-group kb-form-210 kb-form-alone"
                callback={this.selectMembers}
                field={lock_id}
                multi={true}
                users={locks}
                selectedUsers={[]}
              />
            </div>
          ) : (
            ''
          )}
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="开始日期"
              isRequired={true}
              className="kb-form-group kb-div-48"
              field={startYMD}
              onChange={this.changeStartYMDCallback}
            />
            <KBSelect
              title="开始时间"
              onChange={this.changeStartHMCallback}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
              field={startHM}
              {...halfClass}
            />
          </div>
          <div className="kb-row kb-form-66">
            <KBDateInput
              type="date"
              title="结束日期"
              isRequired={true}
              className="kb-form-group kb-div-48"
              field={endYMD}
              onChange={this.changeEndYMDCallback}
            />
            <KBSelect
              title="结束时间"
              onChange={this.changeEndHMCallback}
              titleClass="label-rt"
              arrayData={DEAL_DAY_TIME_HM}
              field={endHM}
              {...halfClass}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state

  // let loc_id = params.id;

  let locationGroupRoles = []
  if (user && user.space_roles) {
    locationGroupRoles = user.space_roles.filter(data => {
      return data.role == 'location_group_operator'
    })
  }

  let DEAL_DAY_TIME_HM = DAY_TIME_HM(0, 24)
  let locations = selectors.getSerializeLocation(state)
  let locks = selectors.getLocks(state)
  const lockGroups = selectors.getLockGroups(state)

  let currentLocation = getCurrentLocation()
  let location_id = currentLocation && currentLocation.id
  let isGroupManager = canAdminLocationGroup(user)
  let isManager = canAdminLocation(user, location_id)

  // 只有管理员才能配置
  // if (!isGroupManager && isManager) {
  if (canAdminSpace(user, location_id)) {
    locations = locations.filter(data => {
      return data.id == location_id
    })
  } else {
    locations = []
  }

  // if (isGroupManager) {
  //   locations = locations.filter(data => {
  //     let isAdd = false
  //     let location_groups = null
  //     if (data.location_groups && data.location_groups.length > 0) {
  //       location_groups = data.location_groups[0]
  //     }
  //     locationGroupRoles.filter(rolesData => {
  //       if (
  //         location_groups &&
  //         location_groups.id == rolesData.location_group_id
  //       ) {
  //         isAdd = true
  //       }
  //     })
  //     return isAdd
  //   })
  // }

  return {
    DEAL_DAY_TIME_HM,
    locations,
    locks,
    lockGroups,
    location_id,
    isManager,
    isGroupManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.create,
      dispatch
    ),
    getLockGroupsAction: bindActionCreators(lockGroupsAction.replace, dispatch),
    apiCallFailureAction: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.location_id)) {
    errors.location_id = '请选择分店'
  }

  if (
    values.start_at &&
    values.end_at &&
    moment(values.end_at).diff(moment(values.start_at)) <= 0
  ) {
    errors.endHM = '结束时间必须大于开始时间'
  }

  return errors
}

const formConfig = {
  form: 'LockPermissionsMultiForm',
  fields: [
    'location_id',
    'user_id',
    'start_at',
    'end_at',
    'lock_id',
    'lock_group_id',
    'startHM',
    'endHM',
    'startYMD',
    'endYMD'
  ],
  validate: validate,
  touchOnBlur: false
}

LockPermissionsMultiForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LockPermissionsMultiForm)

export default LockPermissionsMultiForm
