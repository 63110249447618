import React from 'react'
import { reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { KBInput, KBButton, KBPopover, KBForm } from 'components'
import { lockerCategoriesAction } from 'app/actions'
import { api } from 'app/services'
import * as valid from 'utils/validate'
import intl from 'react-intl-universal'

var LockerCategoryForm = React.createClass({
  createLockerCategory(model) {
    const {
      location_id,
      createLockerCategoriesAction,
      updateLockerCategoriesAction,
      type,
      category
    } = this.props

    if (type == 'update') {
      return api
        .putLockerCategories(category.id, { location_id, ...model })
        .then(
          json => {
            updateLockerCategoriesAction.success(json)
            KBPopover.close()
          },
          error => {
            updateLockerCategoriesAction.failure(error)
          }
        )
    }

    return api.postLockerCategories({ location_id, ...model }).then(
      json => {
        createLockerCategoriesAction.success(json)
        KBPopover.close()
      },
      error => {
        createLockerCategoriesAction.failure(error)
      }
    )
  },

  render() {
    const {
      fields: { name, price, capacity },
      handleSubmit,
      submitting,
      type,
      currency
    } = this.props
    return (
      <KBForm
        type="reduxForm"
        onSubmit={handleSubmit(this.createLockerCategory)}
        fields={this.props.fields}
      >
        <header className="kb-form-header">
          <h2 className="header-title">
            {type == 'update' ? '修改规格' : '添加规格'}
          </h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={name}
              title="规格名称"
              isRequired={true}
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={price}
              title={`价格(${currency}/分钟)`}
              isRequired={true}
              type="number"
            />
          </div>
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={capacity}
              title="柜体容量/L"
              isRequired={true}
              type="number"
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    createLockerCategoriesAction: bindActionCreators(
      lockerCategoriesAction.create,
      dispatch
    ),
    updateLockerCategoriesAction: bindActionCreators(
      lockerCategoriesAction.update,
      dispatch
    )
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入规格名称'
  }

  if (valid.required(values.price)) {
    errors.price = '请输入价格'
  }

  return errors
}

const formConfig = {
  form: 'LockerCategoryForm',
  fields: ['name', 'price', 'capacity'],
  validate: validate,
  touchOnBlur: false
}

LockerCategoryForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(LockerCategoryForm)

export default LockerCategoryForm
