import React, { Component } from 'react'
import { Router, Route, Link } from 'react-router'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'
import { routerActions } from 'react-router-redux'
import { KBPopover, KBPopoverTop, KBError, KBSuccess } from 'components'
import { canSpaceAdmin } from 'app/reducers/role'
import * as selectors from 'app/selectors'
import { api } from 'app/services'
import * as actions from 'app/actions'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

var ShoppingBox = React.createClass({
  getInitialState() {
    return {}
  },

  componentDidMount() {},

  render() {
    return <div className="kb-out-box clear-fix">{this.props.children}</div>
  }
})

function mapStateToProps(state, props) {
  let sections = props.location.pathname.split('/')
  let section = sections.length > 3 ? sections[3] : ''
  let user = selectors.getCurrentUser(state)
  var pathname = props.location.pathname.split('/')

  if (pathname.length > 5) {
    pathname = pathname.slice(0, 5)
  }

  return {
    section,
    pathname: pathname.join('/'),
    user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    shopsActions: bindActionCreators(actions.shopsActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingBox)
