import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import { deskActions, apiCallFailure } from 'app/actions'
import {
  KBFormError,
  KBButton,
  KBPopover,
  KBPopoverTop,
  KBTipsy,
  KBLoadingContainer
} from 'components'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import moment from 'moment'

var OrgSubscriptionAllotDeskForm = React.createClass({
  getInitialState() {
    const { selectDesks } = this.props
    return {
      desks: [],
      selectDesks: selectDesks || [],
      loading: true
    }
  },

  componentDidMount() {
    const {
      area_id,
      loc_id,
      yetDeskIds,
      organization_id,
      startDateValue,
      endDateValue
    } = this.props
    api
      .getDesks({
        location_id: loc_id,
        area_id: area_id,
        per_page: 500,
        start_date: moment(startDateValue).format('YYYY-MM-DD'),
        end_date: moment(endDateValue).format('YYYY-MM-DD'),
        includes: 'users,occupancy'
      })
      .then(json => {
        let desks = getEntitiesJsonArray(json, 'desks') || []
        // desks.map(desk => {
        //   yetDeskIds.map(desk_id=> {
        //     if(desk.id == desk_id){
        //       desk.occupied = true
        //       desk.organization_id = organization_id
        //     }
        //   })
        // })
        this.setState({ desks })
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  selectDeskNum() {
    const { units, apiCallFailureActions, callBack } = this.props
    const { selectDesks } = this.state
    if (selectDesks.length != units) {
      return apiCallFailureActions({
        status: 422,
        message: '所选工位数量与填写工位数量不一致'
      })
    }
    callBack && callBack(selectDesks)
    KBPopover.close()
  },

  selectDesk(desk) {
    const { selectDesks } = this.state
    const { isAfterToday } = this.props
    let selectDesksArray = Object.assign([], selectDesks)
    let alreadyDeskIndex = selectDesksArray.findIndex(
      selectDesk => selectDesk.id == desk.id
    )
    const { occupied } = desk

    if (occupied && isAfterToday) {
      return
    }

    if (alreadyDeskIndex != -1) {
      selectDesksArray.splice(alreadyDeskIndex, 1)
      this.setState({
        selectDesks: selectDesksArray
      })
      return
    }

    selectDesksArray.push(desk)
    this.setState({
      selectDesks: selectDesksArray
    })
  },

  renderDeskColor() {
    const { desks, selectDesks } = this.state
    const { organization_id, isAfterToday } = this.props
    let deskArray = Object.assign([], desks)

    const desk_num_style = {
      maxWidth: 54,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: 12,
      marginBottom: 3
    }

    return deskArray.map((desk, index) => {
      let selectDeskShow = selectDesks.find(
        selectDesk => selectDesk.id == desk.id
      )
      let occupied = desk.state === 'assigned' || desk.state === 'reserved'
      return (
        <KBTipsy key={index} content={desk.serial_number}>
          <div
            className={`contract-station-number ${
              isAfterToday && occupied
                ? organization_id == desk.organization_id
                  ? 'contract-station-number-team'
                  : 'contract-station-number-assigned'
                : selectDeskShow
                ? 'contract-station-number-select'
                : 'contract-station-number-normal'
            } `}
            onClick={occupied ? null : this.selectDesk.bind(null, desk)}
          >
            <div className="kb-lr-center">
              <span style={desk_num_style}>{desk.serial_number}</span>
              <i className="iconfont desk-icon" />
            </div>
          </div>
        </KBTipsy>
      )
    })
  },

  render() {
    const {
      fields: { desk_ids },
      error,
      submitting,
      handleSubmit,
      area_name,
      units
    } = this.props
    let { loading, selectDesks, desks } = this.state
    let desksFree =
      desks &&
      desks.filter(desk => {
        return desk.state === 'free'
      })
    return (
      <form onSubmit={handleSubmit(this.selectDeskNum)}>
        <header className="kb-form-header">
          <h2 className="header-title">选择工位</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={() => {
              KBPopover.close()
            }}
          />
        </header>
        <div className="kb-form-container">
          <div>
            <div className="kb-form-group kb-form-c39 kb-form-alone">
              <label>分区名称</label>
              <div className="group-form-content">
                <span>{area_name}</span>
              </div>
            </div>
          </div>
          <div className="kb-row">
            <div className="kb-form-group kb-form-c39 kb-form-alone">
              <label>预定工位数量</label>
              <div className="group-form-content">
                <span>{units}个</span>
              </div>
            </div>
          </div>
          <KBLoadingContainer loading={loading}>
            <div className="station-show" style={{ marginTop: 45 }}>
              <header className="contract-select-desk-header">
                <div style={{ fontSize: 20 }}>工位选择</div>
                <div className="desk-state-container">
                  <div className="desk-state-content">
                    <span
                      className="desk-state-block"
                      style={{
                        backgroundColor: '#ffffff',
                        border: '1px solid #727272'
                      }}
                    />
                    <span>未分配</span>
                  </div>
                  {/* <div className="desk-state-content">
                    <span className="desk-state-block" style={ {backgroundColor: '#b4e0ff'} }/>
                    <span>所在公司使用</span>
                  </div> */}
                  <div className="desk-state-content">
                    <span
                      className="desk-state-block"
                      style={{ backgroundColor: '#f5f5f5' }}
                    />
                    <span>已分配</span>
                  </div>
                </div>
              </header>
              <div
                className="clear-fix"
                style={{ marginTop: 10, marginBottom: 20 }}
              >
                <p className="f-left">
                  已选中{' '}
                  <span style={{ color: '#2ea1f8', fontSize: 16 }}>
                    {selectDesks.length}
                  </span>{' '}
                  个
                </p>
                <p className="f-left" style={{ marginLeft: 15 }}>
                  剩余可选工位{' '}
                  <span style={{ color: '#2ea1f8', fontSize: 16 }}>
                    {desksFree.length - selectDesks.length}
                  </span>{' '}
                  个
                </p>
              </div>
              <div className="clear-fix">
                {this.renderDeskColor()}
                {desk_ids.touched && desk_ids.error && (
                  <p className="lr-error">{desk_ids.error}</p>
                )}
              </div>
            </div>
          </KBLoadingContainer>
          <div className="kb-row fixed-form-btn">
            <span
              className="cancel-btn"
              onClick={() => {
                KBPopover.close()
              }}
            >
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              完成
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const { endDateValue } = props
  let isAfterToday = moment(endDateValue).isAfter(moment().toDate())
  return {
    isAfterToday
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDesksActions: bindActionCreators(deskActions.get, dispatch),
    deleteDeskActions: bindActionCreators(deskActions.delete, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'OrgSubscriptionAllotDeskForm',
  fields: ['desk_ids'],
  validate: validate,
  touchOnBlur: false
}

OrgSubscriptionAllotDeskForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionAllotDeskForm)

export default OrgSubscriptionAllotDeskForm
