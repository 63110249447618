import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  KBInput,
  KBFormError,
  KBButton,
  KBPopover,
  KBTextarea,
  KBMorePhotoUpload,
  KBUserInput,
  KBRadio
} from 'components'
import { shopsActions, successState } from 'app/actions'
import * as selectors from 'app/selectors'
import * as constants from 'app/constants'

var ShopsForm = React.createClass({
  componentDidMount() {
    const {
      fields: { payment_type },
      shop,
      state
    } = this.props

    // if (state) {
    //   payment_type.onChange(shop)
    // }
  },
  createShops(model) {
    const {
      createShopsActions,
      updateShopsActions,
      shop,
      state,
      successAction
    } = this.props

    model.visible = model.visible == 'true'

    if (state) {
      return api.putShops(shop.id, model).then(json => {
        updateShopsActions.success(json)
        successAction({ message: '修改商城成功!' })
        KBPopover.close()
        return true
      })
    }

    return api.postShops(model).then(json => {
      createShopsActions.success(json)
      successAction({ message: '创建商城成功!' })
      KBPopover.close()
      return true
    })
  },

  render() {
    const {
      fields: { location_id, name, shop_type, payment_type, visible },
      error,
      submitting,
      handleSubmit
    } = this.props
    const {
      locations,
      state,
      product,
      shopObj,
      currentLocation,
      currentPayMentType
    } = this.props
    const oneClass = {
      className: 'kb-form-group kb-form-c18 kb-form-alone'
    }
    const isVisible = [
      { title: '是', value: true },
      { title: '否', value: false }
    ]

    return (
      <form onSubmit={handleSubmit(this.createShops)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">{state ? '修改商城' : '创建商城'}</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          <div className="kb-row">
            <KBInput
              className="kb-form-group kb-form-c39 kb-form-alone"
              isRequired={true}
              field={name}
              title="商城名称"
              fistFoucs={true}
            />
          </div>
          <div className="kb-row">
            <KBRadio
              className="kb-form-group kb-div-c39 kb-form-alone"
              inClassName="kb-form-group kb-div-33"
              groupStyle={{ width: '100%' }}
              divClassName="kb-form-group kb-div-39"
              rightLabel="label-rt"
              title="是否在app里显示"
              arrayType={isVisible}
              name="external"
              field={visible}
            />
          </div>
          {/*<div className='kb-row'>
            <KBUserInput title='商城类型' placeholder={ '商品类型' } { ...oneClass } isRequired = {true} field={ shop_type } multi={ false } users={ constants.SHOP_TYPE } selectedUsers={[ shopObj ]} />
          </div>
          <div className='kb-row'>
            <KBUserInput title='分店' placeholder={ '选择分店' } { ...oneClass } field={ location_id } multi={ false } users={ locations } selectedUsers={[ currentLocation ]} />
          </div>
          <div className='kb-row'>
            <KBUserInput title='支付类型' placeholder={ '商城支付类型' } { ...oneClass } field={ payment_type } multi={ false } users={ constants.PAYMENT_TYPE } selectedUsers={[ currentPayMentType ]} />
          </div>*/}
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton className="certain-btn" submitting={submitting}>
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  const locations = selectors.getLocations(state)
  const shop = props.shop
  const shopObj =
    shop && constants.SHOP_TYPE.find(json => json.id == shop.shop_type)
  const currentLocation = shop && selectors.getLocation(state, shop.location_id)
  const currentPayMentType = shop && shop.payment_type
  return {
    shopObj,
    currentPayMentType,
    currentLocation,
    locations
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createShopsActions: bindActionCreators(shopsActions.create, dispatch),
    updateShopsActions: bindActionCreators(shopsActions.update, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请填写商城名称'
  }

  return errors
}

const formConfig = {
  form: 'ShopsForm',
  fields: ['name', 'visible'],
  validate: validate,
  touchOnBlur: false
}

ShopsForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(ShopsForm)

export default ShopsForm
