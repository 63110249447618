import React from 'react'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { crmSourcesActions, membersActions } from 'app/actions'
import * as selectors from 'app/selectors'
import { listIsNullCallBack } from 'utils/kbUtil'

const menu = [
  {
    name: '首页',
    value: ''
  },
  {
    name: '客户',
    value: 'opportunities'
  },
  {
    name: '赢单合同',
    value: 'winsubscription'
  },
  // {
  //   name  : '线索',
  //   value : 'leads'
  // },
  {
    name: '库存',
    value: 'inventory'
  },
  {
    name: '库存时间轴',
    value: 'inventory_timeline'
  }
]

var CrmBox = React.createClass({
  displayName: 'CrmBox',
  componentDidMount() {
    const { getCrmSourcesActions, crmSources } = this.props
    listIsNullCallBack(crmSources, () => {
      // CRM 来源
      api.getCrmSources().then(
        json => {
          getCrmSourcesActions.success(json)
        },
        error => {
          getCrmSourcesActions.failure(error)
        }
      )
    })
    // // 空间员工
    api.getMembers({ per_page: 500 }).then(
      ({ json, response }) => {
        this.props.getMembersActions.success(json)
      },
      errors => {
        this.props.getMembersActions.failure(errors)
      }
    )
  },
  render() {
    const { section } = this.props

    return (
      <div className="kb-out-box clear-fix">
        <div className="sub-nav-bar" style={{ minWidth: 1450 }}>
          <div style={{ position: 'relative' }}>
            <div className="sub-nav-list clear-fix">
              {menu.map((data, index) => {
                return (
                  <Link
                    key={index}
                    className={section == data.value ? 'active' : ''}
                    to={`/admin/crm/${data.value}`}
                  >
                    {' '}
                    {data.name}{' '}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>

        <div className="margin-top-lg">
          <div className="clear-fix" style={{ height: '100%' }}>
            <section className="kb-content" style={{ paddingTop: 75 }}>
              {this.props.children}
            </section>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let sections = location.pathname.split('/')
  let section = sections.length > 2 ? sections[3] : ''
  let crmSources = selectors.getCrmSources(state)

  return {
    crmSources,
    section: section || ''
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCrmSourcesActions: bindActionCreators(crmSourcesActions.get, dispatch),
    getMembersActions: bindActionCreators(membersActions.get, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrmBox)
