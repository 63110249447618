import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import {} from 'app/actions'
import { KBAvatar, KBPopover } from 'components'
import { getUserInfoUrl } from 'utils/kbUrl'
import { KBPopoverConfirm } from 'components/tools'
import moment from 'moment'

var PostCommentBlock = React.createClass({
  deleteComment() {
    const { comment, deleteCallback } = this.props
    const { content, post_id, id } = comment
    KBPopoverConfirm({
      name: '删除评论',
      context: `您确定要删除评论： ${content} 吗?`,
      callback: () => {
        return api.deletePostComment(post_id, id).then(
          ({ json }) => {
            let deletedComment = json
            deleteCallback && deleteCallback(deletedComment.id)
            KBPopover.close()
          },
          errors => {
            KBPopover.close()
          }
        )
      }
    })
  },

  render() {
    const { comment } = this.props
    const {
      user_id,
      user_name,
      user_avatar,
      parent_user_id,
      parent_user_name,
      parent_user_avatar,
      content,
      created_at
    } = comment
    const user = { id: user_id, name: user_name, avatar: user_avatar }
    const parent_user = parent_user_id
      ? {
          id: parent_user_id,
          name: parent_user_name,
          avatar: parent_user_avatar
        }
      : ''
    let timeFromNow = created_at ? moment.unix(created_at).fromNow() : ''

    return (
      <div
        className="post-block"
        style={{
          borderTop: '1px solid #E3E8EF',
          borderBottom: 0,
          transform: 'translateY(-1px)'
        }}
      >
        <div className="header-info">
          <div className="vf-center">
            <KBAvatar
              user={user}
              size={60}
              style={{ margin: '0 16px' }}
              imgStyle={{ margin: '0 16px' }}
            />
            <div>
              <div className="creator">
                <Link className="color-link name" to={getUserInfoUrl(user.id)}>
                  {user.name || ''}
                </Link>
                {parent_user ? (
                  <span className="name" style={{ padding: '0 5px' }}>
                    回复
                  </span>
                ) : null}
                {parent_user ? (
                  <Link
                    className="color-link name"
                    to={getUserInfoUrl(parent_user.id)}
                  >
                    {parent_user.name || ''}
                  </Link>
                ) : null}
              </div>
              <div className="category-and-time vf-center">
                <span className="time">{timeFromNow || ''}</span>
              </div>
            </div>
          </div>
          <i
            className="iconfont iconfont icon-delete"
            onClick={e => {
              e.stopPropagation()
              this.deleteComment()
            }}
          />
        </div>
        <div className="body-detail">
          <div className="post-summary">{content || '此帖暂无描述内容'}</div>
        </div>
      </div>
    )
  }
})

PostCommentBlock.PropTypes = {
  comment: PropTypes.object.isRequired
}

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostCommentBlock)
