import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import {} from 'app/actions'
import {
  canAccessLocationRoles,
  canAccessSpaceFinance
} from 'app/reducers/role'
import { isListValue, getListFirstValue } from 'utils/kbUtil'
import _ from 'lodash'

var InvoiceBox = React.createClass({
  getInitialState() {
    const { location_id } = this.props
    const menu = [
      {
        name: '合同',
        value: location_id ? ['', 1] : ['', '1', 1]
      },
      {
        name: '账单',
        value: ['invoices_list', 2]
      },
      {
        name: '订单',
        value: ['membership_orders', 'location_orders', 3]
      },
      {
        name: '发票',
        value: ['invoices_application', 4]
      },
      {
        name: '发票库',
        value: ['invoicing_pool', 5]
      },
      {
        name: '支付信息',
        value: ['invoice_payments', 6]
      },
      {
        name: '月汇总账单统计',
        value: ['monthly_invoices', 7]
      },
      {
        name: '账单核销统计',
        value: ['confirmed_invoices', 8]
      },
      {
        name: '保证金',
        value: ['deposit', 9]
      }
    ]
    return {
      menu
    }
  },

  render() {
    const { section, locationSection, location_id } = this.props
    let loc_url = location_id ? `/locations/${location_id}` : ''

    return (
      <div
        className="kb-out-box clear-fix"
        style={location_id ? { paddingTop: 0 } : {}}
      >
        <div className="sub-nav-bar" style={{ minWidth: 1450 }}>
          <div style={{ position: 'relative' }}>
            <div className="sub-nav-list clear-fix">
              {this.state.menu.map((data, index) => {
                let url = getListFirstValue(data.value)
                if (location_id && url === 'membership_orders') {
                  url = 'location_orders'
                }
                return (
                  <Link
                    key={index}
                    className={
                      isListValue(data.value, section) ||
                      (location_id && isListValue(data.value, locationSection))
                        ? 'active'
                        : ''
                    }
                    to={`/admin${loc_url}/invoices/${url}`}
                  >
                    {' '}
                    {data.name}{' '}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>

        <div className="margin-top-lg">
          <div className="clear-fix" style={{ height: '100%' }}>
            <section className="kb-content-t">{this.props.children}</section>
          </div>
        </div>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  const { user } = state
  const { params } = props
  let sections = props.location.pathname.split('/')
  let section = sections.length > 3 ? sections[3] : ''
  let locationSection = sections.length > 5 ? sections[5] : ''
  let location_id = params && params.id

  return {
    section,
    locationSection,
    user,
    location_id
  }
}

// Map action creators dispatch to props
// So we can call this.props.<actionName>.request(<params>) directly
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceBox)
