import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { Router, Route, Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import classNames from 'classnames'
import { connect } from 'react-redux'
import {
  lockPermissionsActions,
  lockEventsActions,
  apiCallFailure,
  lockGroupsAction
} from 'app/actions'
import * as selectors from 'app/selectors'
import {
  KBPopover,
  KBPagination,
  KBLoadingContainer,
  KBTableWithoutData,
  KBCheckBox,
  KBAvatar,
  KBNoData
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { THEME_COLORS, NETWORK_TYPE } from 'app/constants'
import {
  getEntitiesJsonArray,
  arrayToObject,
  paginate,
  formatHoursMinutesCHS,
  formatOnlyMinuteSecondEN,
  formatYearDayCHS
} from 'utils/kbUtil'
import LocksPermissionsUpdateForm from '../members/LocksPermissionsUpdateForm'
import LocksPermissionsForm from '../members/LocksPermissionsForm'
import { canAccessLocation } from 'app/reducers/role'
import moment from 'moment'
import LockGroupBlock from './LockGroupBlock'
import { getUserInfoUrl } from 'utils/kbUrl'

/**
 * 门禁管理
 * @type {[type]}
 */
var LockInfo = React.createClass({
  displayName: 'LockInfo',

  getInitialState() {
    return {
      lockEvents: [],
      pagination: null,
      paginationLock: null,
      paginationLockGroup: null,
      lockQrcodeImage: null,
      currentTab: 'permissions',
      selectedPermissions: [],
      isAllChecked: false
    }
  },

  componentDidMount() {
    const { page, lock_id, location_id, pageLock, pageLockGroup } = this.props

    this.mounted = true

    api
      .getLockGenerateQrcode(lock_id, {
        location_id: location_id,
        size: 400,
        type: 'serial_number'
      })
      .then(({ json }) => {
        this.setState({
          lockQrcodeImage: json.image_base64
        })
      })
    this._loadLockGroups(pageLockGroup)
    this._loadLockData(pageLock)
    this._loadData(page)
  },

  componentWillUnmount() {
    this.mounted = false
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.page !== nextProps.page) {
      this._loadData(nextProps.page)
    }
    if (this.props.pageLock !== nextProps.pageLock) {
      this._loadLockData(nextProps.pageLock)
    }
    if (this.props.pageLockGroup !== nextProps.pageLockGroup) {
      this._loadLockGroups(nextProps.pageLockGroup)
    }
  },

  _loadLockGroups(page) {
    const { lock_id, location_id, getLockGroupsAction } = this.props

    api
      .getLockGroups({
        location_id,
        lock_ids: String(lock_id),
        per_page: 10,
        page
      })
      .then(
        json => {
          getLockGroupsAction.success(json)
          let paginationLockGroup = paginate(json.response)
          this.setState({
            paginationLockGroup
          })
        },
        errors => {
          getLockGroupsAction.failure(errors)
        }
      )
  },

  _loadLockData(page) {
    const { lock_id, location_id, getLockEventsActions } = this.props
    const per_page = 10
    let pageObj = { per_page, page }
    let params = Object.assign({}, pageObj)
    api.getLockEvents({ location_id, lock_id, ...params, page }).then(
      ({ json, response }) => {
        let lockEvents = []
        let paginationLock = paginate(response)
        lockEvents = json

        if (this.mounted) {
          this.setState({
            lockEvents,
            paginationLock
          })
        }
        getLockEventsActions.success({
          entities: { lockEvents: arrayToObject(lockEvents) }
        })
      },
      errors => {
        getLockEventsActions.failure(errors)
      }
    )
  },

  _loadData(page) {
    const {
      lock_id,
      location_id,
      getLocksPermissionsAction,
      apiCallFailureActions
    } = this.props
    const per_page = 10
    let pageObj = { per_page, page }
    let params = Object.assign({}, pageObj)

    api
      .getLockPermissions({
        location_id,
        per_page,
        ...params,
        lock_id: lock_id,
        page
      })
      .then(
        json => {
          let pagination = paginate(json.response)
          if (this.mounted) {
            this.setState({
              pagination
            })
          }
          getLocksPermissionsAction.success(json, {
            entityName: 'lockPermissions'
          })
        },
        errors => {
          getLocksPermissionsAction.failure(errors)
        }
      )
  },

  updateLocksPermissions(data) {
    const { location_id } = this.props
    let startAt = moment.unix(data.start_at).toDate()
    let endAt = moment.unix(data.end_at).toDate()
    KBPopover.show(
      <LocksPermissionsUpdateForm
        loc_id={location_id}
        initialValues={{
          lock_permissions: [
            {
              id: data.id,
              startYMD: this.setYMD(startAt),
              endYMD: this.setYMD(endAt),
              start_at: startAt,
              end_at: endAt,
              startHM: this.setHM(startAt),
              endHM: this.setHM(endAt)
            }
          ]
        }}
      />
    )
  },

  addLockPermissions() {
    const { location_id, lock_id } = this.props
    let initValue = {}
    initValue.lock_permissions = [
      {
        startYMD: new Date(),
        endYMD: new Date(),
        lock_id: lock_id,
        startHM: '9,1',
        endHM: '9,1',
        start_at: moment(new Date())
          .clone()
          .set({ hour: 9, minute: 0, second: 0 }),
        end_at: moment(new Date())
          .clone()
          .set({ hour: 9, minute: 0, second: 0 })
      }
    ]
    KBPopover.show(
      <LocksPermissionsForm
        initialValues={initValue}
        loc_id={location_id}
        operateType="create"
      />
    )
  },

  setYMD(value) {
    let date = moment(value)
    let year = date.get('year')
    let month = date.get('month')
    let day = date.get('date')
    return moment(value)
      .clone()
      .set({ year, month, date: day })
      .toDate()
  },

  setHM(value) {
    let date = moment(value)
    let hour = date.get('hour')
    let minute = date.get('minute')
    let processHour = ''
    let processMinute = ''

    processHour = hour

    if (minute == 30) {
      processMinute = 2
    } else {
      processMinute = 1
    }

    return processHour + ',' + processMinute
  },

  deleteLocksPermissions(lock) {
    const { location_id, deleteLocksPermissionsAction } = this.props
    let idArray = []
    idArray.push(lock.id)
    KBPopoverConfirm({
      name: '删除门禁权限',
      context: '是否要删除门禁 ' + lock.lock.name + ' 的权限',
      callback: () => {
        return api.deleteLockPermissions(location_id, { ids: idArray }).then(
          json => {
            deleteLocksPermissionsAction.success(json, {
              id: lock.id,
              key: 'lockPermissions'
            })
            KBPopover.close()
          },
          error => {
            deleteLocksPermissionsAction.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  toMemberInfo(json) {
    const { routerActions, location_id } = this.props
    const { user_id, organization_id } = json
    organization_id && routerActions.push(getUserInfoUrl(user_id))
  },

  printme() {
    const { lockQrcodeImage } = this.state

    let newwindow = window.open('', 'print')
    let printDom =
      `<img src='data:image/png;base64,` +
      lockQrcodeImage +
      `' alt="loading" style='position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)'/>`

    newwindow.document.write(printDom)
    setTimeout(() => {
      newwindow.window.print()
    }, 1500)
  },

  deleteMultiPermissions() {
    const { loc_id, deleteLocksPermissionsAction } = this.props
    const { selectedPermissions } = this.state
    const idArray = selectedPermissions.map(permission => permission.id)

    KBPopoverConfirm({
      name: '删除门禁权限',
      context: '是否要删除所选门禁权限',
      callback: () => {
        return api.deleteLockPermissions(loc_id, { ids: idArray }).then(
          json => {
            deleteLocksPermissionsAction.success(json, {
              ids: idArray,
              key: 'lockPermissions'
            })
            KBPopover.close()
          },
          error => {
            deleteLocksPermissionsAction.failure(error)
            KBPopover.close()
          }
        )
      }
    })
  },

  checkAllPermissions(checked, data) {
    this.setState({
      isAllChecked: checked,
      selectedPermissions: checked ? data : []
    })
  },

  changePermission(checked, data) {
    const permissions = Object.assign([], this.state.selectedPermissions)

    if (checked) {
      permissions.push(data)
      // 当选中的 permissions 逐个增加时的判断
      const isPermissionsAllAdd = permissions.length === 10
      return this.setState({
        selectedPermissions: permissions,
        isAllChecked: isPermissionsAllAdd ? true : false
      })
    }

    let removeIndex = permissions.findIndex(json => json.id === data.id)
    if (removeIndex == -1) {
      return
    }

    permissions.splice(removeIndex, 1)
    // 当选中的 permissions 逐个减少时的判断
    const isPermissionsAllSub = permissions.length === 10
    this.setState({
      selectedPermissions: permissions,
      isAllChecked: isPermissionsAllSub ? true : false
    })
  },

  render() {
    const {
      lock,
      locksPermissionsUser,
      location_id,
      lock_id,
      isManager,
      lockGroups,
      locks
    } = this.props
    const {
      lockEvents,
      pagination,
      paginationLock,
      paginationLockGroup,
      currentTab,
      selectedPermissions
    } = this.state

    return (
      <section className="nav-section-container  f-right">
        <KBLoadingContainer loading={!lock}>
          <header
            className="nav-header clear-fix"
            style={{ borderBottom: 'none', paddingBottom: 0 }}
          >
            <h2 className="nav-section-header-title f-left">
              <span>{lock && lock.name}</span>
            </h2>
            <button
              className="f-right bordered-btn"
              onClick={e => {
                this.printme()
                e.stopPropagation()
              }}
            >
              打印门禁二维码
            </button>
          </header>
          <div className="nav-section-content-container">
            {lock ? (
              <div style={{ color: '#848C98' }}>
                <div className="meeting-room-message">
                  <span>设备ID：{lock.device_id}</span>
                </div>
                <div className="meeting-room-message">
                  <span>是否联网：{lock.networking ? '是' : '否'}</span>
                </div>
                {lock.network_type ? (
                  <div className="meeting-room-message">
                    <span>联网类型：{NETWORK_TYPE[lock.network_type]}</span>
                  </div>
                ) : null}
              </div>
            ) : null}
            {lock && lock.notes ? (
              <div style={{ color: '#354052', lineHeight: '18px' }}>
                备注：{lock.notes}
              </div>
            ) : null}
            <ul className="nav-section-tab" style={{ marginTop: 50 }}>
              <li
                className={`${
                  currentTab === 'permissions' ? 'nav-section-tab-active' : ''
                }`}
                onClick={e => {
                  this.setState({ currentTab: 'permissions' })
                }}
              >
                <a>会员权限</a>
              </li>
              <li
                className={`${
                  currentTab === 'lock_events' ? 'nav-section-tab-active' : ''
                }`}
                onClick={e => {
                  this.setState({ currentTab: 'lock_events' })
                }}
              >
                <a>开门记录</a>
              </li>
              <li
                className={`${
                  currentTab === 'lock_groups' ? 'nav-section-tab-active' : ''
                }`}
                onClick={e => {
                  this.setState({ currentTab: 'lock_groups' })
                }}
              >
                <a>门禁组关联</a>
              </li>
            </ul>
            {currentTab === 'permissions' ? (
              <div style={{ float: 'left', width: '100%' }}>
                <div style={{ float: 'left', width: '100%' }}>
                  {selectedPermissions && selectedPermissions.length > 0 ? (
                    <button
                      className="bordered-btn-end f-left m-none"
                      onClick={this.deleteMultiPermissions}
                    >
                      删除选中
                    </button>
                  ) : null}
                  <button
                    className="bordered-btn f-right"
                    onClick={this.addLockPermissions}
                  >
                    添加会员
                  </button>
                </div>
                <table className="content-table f-left">
                  <thead>
                    <tr>
                      <th>
                        <KBCheckBox
                          callback={this.checkAllPermissions}
                          data={locksPermissionsUser || []}
                          checked={this.state.isAllChecked}
                        />
                      </th>
                      <th>会员</th>
                      <th>公司</th>
                      <th>开始时间</th>
                      <th>结束时间</th>
                      <th />
                    </tr>
                  </thead>
                  <KBTableWithoutData
                    hasData={locksPermissionsUser.length > 0}
                    tableHeadNum="5"
                    tipMessage="暂无会员权限"
                  >
                    {locksPermissionsUser.map((json, index) => {
                      const isChecked =
                        selectedPermissions &&
                        selectedPermissions.find(
                          permission => permission.id === json.id
                        )
                          ? true
                          : false
                      return (
                        <tr key={index}>
                          <td>
                            <KBCheckBox
                              callback={this.changePermission}
                              data={json}
                              checked={isChecked}
                            />
                          </td>
                          <td>
                            {json.user ? (
                              <div className="vf-center">
                                <KBAvatar
                                  user={json.user}
                                  imgStyle={{ marginRight: 5 }}
                                  style={{
                                    display: 'inline-block',
                                    marginRight: '5px'
                                  }}
                                  size={30}
                                />
                                <Link
                                  to={getUserInfoUrl(json.user.id)}
                                  className="color-link"
                                >
                                  {json.user.name || ''}
                                </Link>
                              </div>
                            ) : null}
                          </td>
                          <td>{json.organization && json.organization.name}</td>
                          <td>{formatHoursMinutesCHS(json.start_at)}</td>
                          <td>{formatHoursMinutesCHS(json.end_at)}</td>
                          {isManager ? (
                            <td className="t-right">
                              <i
                                className="iconfont icon-edit a-hover"
                                onClick={e => {
                                  e.stopPropagation()
                                  this.updateLocksPermissions(json)
                                }}
                              />
                              <i
                                className="iconfont icon-delete m-left-sm a-hover"
                                onClick={e => {
                                  e.stopPropagation()
                                  this.deleteLocksPermissions(json)
                                }}
                              />
                            </td>
                          ) : null}
                        </tr>
                      )
                    })}
                  </KBTableWithoutData>
                </table>
                <KBPagination
                  pagination={pagination}
                  template={`/admin/locations/${location_id}/locks/lock_info/lock_list/${lock_id}?page=#PAGE#`}
                />
              </div>
            ) : null}

            {currentTab === 'lock_events' ? (
              <div>
                <table
                  className="content-table"
                  style={{ tableLayout: 'fixed' }}
                >
                  <thead>
                    <tr>
                      <th>行为</th>
                      <th>用户</th>
                      <th>日期</th>
                      <th>时间</th>
                    </tr>
                  </thead>
                  <KBTableWithoutData
                    hasData={lockEvents.length > 0}
                    tableHeadNum="4"
                    tipMessage="暂无开门记录"
                  >
                    {lockEvents.map((json, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {json.action == 'open_door' ? (
                              <div
                                className="lock-action"
                                style={{ backgroundColor: '#73bb4b' }}
                              >
                                <i className="iconfont icon-open" />
                              </div>
                            ) : (
                              <div
                                className="lock-action"
                                style={{ backgroundColor: '#dd5a55' }}
                              >
                                <i className="iconfont icon-open" />
                              </div>
                            )}
                          </td>
                          <td>{json.user && json.user.name}</td>
                          <td>{formatYearDayCHS(json.created_at)}</td>
                          <td>{formatOnlyMinuteSecondEN(json.created_at)}</td>
                        </tr>
                      )
                    })}
                  </KBTableWithoutData>
                </table>
                <KBPagination
                  pagination={paginationLock}
                  query="pageLock"
                  template={`/admin/locations/${location_id}/locks/lock_info/lock_list/${lock_id}?pageLock=#PAGE#`}
                />
              </div>
            ) : null}

            {currentTab === 'lock_groups' ? (
              <div>
                {lockGroups.length > 0 ? (
                  lockGroups.map(lock_g => {
                    return (
                      <LockGroupBlock
                        loc_id={location_id}
                        lock_group={lock_g}
                        locks={locks}
                      />
                    )
                  })
                ) : (
                  <KBNoData tipMessage="暂无门禁组权限" />
                )}
                <KBPagination
                  pagination={paginationLockGroup}
                  query="pageLockGroup"
                  template={`/admin/locations/${location_id}/locks/lock_info/lock_list/${lock_id}?pageLockGroup=#PAGE#`}
                />
              </div>
            ) : null}
          </div>
        </KBLoadingContainer>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { user } = state
  const lock_id = params.lock_id
  const location_id = params.id
  const lock = selectors.getLockInfo(state, lock_id)
  const locks = selectors.getLocks(state)
  const locksPermissionsUser = selectors.getLocksPermissions(state)
  const lockGroups = selectors.getLockGroups(state)
  let isManager = canAccessLocation(user, location_id)
  let page = parseInt(location.query && location.query.page, 10)
  let pageLock = parseInt(location.query && location.query.pageLock, 10)
  let pageLockGroup = parseInt(
    location.query && location.query.pageLockGroup,
    10
  )
  page = isNaN(page) ? 1 : page
  pageLock = isNaN(pageLock) ? 1 : pageLock
  pageLockGroup = isNaN(pageLockGroup) ? 1 : pageLockGroup
  return {
    lock_id,
    lock,
    locks,
    lockGroups,
    location_id,
    locksPermissionsUser,
    page,
    pageLock,
    pageLockGroup,
    isManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.replace,
      dispatch
    ),
    getLockEventsActions: bindActionCreators(lockEventsActions.all, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    deleteLocksPermissionsAction: bindActionCreators(
      lockPermissionsActions.delete,
      dispatch
    ),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    getLockGroupsAction: bindActionCreators(lockGroupsAction.replace, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockInfo)
