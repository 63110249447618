import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { KBLoadingContainer, KBNoData } from 'components'
import * as selectors from 'app/selectors'
import { formatHoursMinutesEN } from 'utils/kbUtil'

var ParkingContractPlatesRecord = React.createClass({
  platesRecordJsx() {
    const { carPlatesHistory } = this.props
    if (carPlatesHistory.length == 0) {
      return (
        <fieldset className="fieldset" style={{ textAlign: 'center' }}>
          <KBNoData />
        </fieldset>
      )
    }
    return (
      <fieldset className="fieldset">
        <table className="content-table m-bottom">
          <thead>
            <tr>
              <th>车位编号</th>
              <th>变更前</th>
              <th>变更后</th>
              <th>变更时间</th>
              {/* <th>操作人</th> */}
            </tr>
          </thead>
          <tbody>
            {carPlatesHistory.map((log, index) => {
              return (
                <tr key={index}>
                  <td>{log.parking_name}</td>
                  <td>{log.old_value && log.old_value.join('、')}</td>
                  <td>{log.new_value && log.new_value.join('、')}</td>
                  <td>{formatHoursMinutesEN(log.updated_at)}</td>
                  {/* <td>
                      <div className="vf-center">
                          <KBAvatar style={{display:'inline-block',marginRight:'5px'}} imgStyle={{marginRight: 5}} user={spaceUsers[log.updated_by]} size={25}/>
                          <Link className="color-link" to={getUserInfoUrl(log.updated_by)}>{ (log.updated_by && spaceUsers[log.updated_by].name) || '' }</Link>
                      </div>
                  </td> */}
                </tr>
              )
            })}
          </tbody>
        </table>
      </fieldset>
    )
  },

  render() {
    const { subscription, pathname } = this.props

    return (
      <div className="nav-section-container f-right">
        <KBLoadingContainer loading={false}>
          <header className="kb-form-header">
            <h2 className="header-title clear-fix">
              <span className="f-left" style={{ color: '#333' }}>
                <Link
                  to={`${pathname}`}
                  style={{ color: '#999' }}
                  className="a-hover"
                >
                  合同详情 >{' '}
                </Link>
                车牌变更记录
              </span>
            </h2>
          </header>
          <div className="kb-form-container">
            {subscription && this.platesRecordJsx()}
          </div>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let subscription_id = props.params.subscription_id
  let subscription = selectors.getEntity(
    state,
    'subscriptions',
    subscription_id
  )
  let carPlatesHistory = []
  subscription &&
    subscription.sales_areas &&
    subscription.sales_areas.map(area => {
      carPlatesHistory = carPlatesHistory.concat(
        (area.car_plates_history &&
          area.car_plates_history.map(his => {
            his.parking_name = area.area && area.area.name
            return his
          })) ||
          []
      )
    })
  let spaceUsers = state.entities.users

  return {
    subscription,
    spaceUsers,
    carPlatesHistory
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkingContractPlatesRecord)
