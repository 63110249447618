import React from 'react'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { api } from 'app/services'
import { subscriptionsAction, orgActions, successState } from 'app/actions'
import {
  KBButton,
  KBTipsy,
  KBPopover,
  KBPopoverTop,
  KBLoadingContainer,
  KBCheckBox
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import * as selectors from 'app/selectors'
import {
  PARKING_CONTRACT_RENTER_TYPES_OBJ,
  ORG_SUBSCRIPTION_STATES,
  ORG_SUBSCRIPTION_STATES_CLASS_NEW,
  INVOICES_STATE,
  INVOICES_STATE_CLASS
} from 'app/constants'
import OrgSubTerminateForm from './OrgSubTerminateForm'
import RealSubscriptionForm from './RealSubscriptionForm'
import ParkingCarPlatesForm from '../parking/ParkingCarPlatesForm'
import {
  contrastDate,
  formatYearDayEN,
  formatYearDayCHS,
  fmoney,
  arrayToObject
} from 'utils/kbUtil'
import {
  canAdminFinance,
  getLSRoles,
  canAccessLocationFinance,
  canAccessSpaceRoles,
  canAccessLocationExceptObserver,
  canAccessLocationRoles,
  canAccessLocationContract
} from 'app/reducers/role'
import _ from 'lodash'
import moment from 'moment'
import { getUserInfoUrl } from 'utils/kbUrl'
import AddInvoiceForm from './AddInvoiceForm'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy';

var OrgSubscriptionDetail = React.createClass({
  getInitialState() {
    return {
      subscription: null,
      selectInvoices: [],
      allCheckedBtn: false,
      isAllInvoicesChecked: false,
      createBillLoading: false
    }
  },
  checkAllInvoices(sales_renting_phases) {
    const { allCheckedBtn } = this.state
    let isAllInvoicesChecked_now = !allCheckedBtn
    let invoices_now = sales_renting_phases
    if (!isAllInvoicesChecked_now) {
      invoices_now = []
    }
    this.setState({
      isAllInvoicesChecked: isAllInvoicesChecked_now,
      selectInvoices: invoices_now,
      allCheckedBtn: isAllInvoicesChecked_now
    })
  },

  changeInvoice(checked, data, sales_renting_phases) {
    var invoices = Object.assign([], this.state.selectInvoices)
    if (checked) {
      invoices.push(data)
      // 当选中的 invoice 逐个增加时的判断
      if (invoices.length == sales_renting_phases.length) {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: true,
          isAllInvoicesChecked: true
        })
      } else {
        this.setState({
          selectInvoices: invoices,
          allCheckedBtn: false
        })
      }

      return
    }

    let removeIndex = invoices.findIndex(json => json.id == data.id)

    if (removeIndex == -1) {
      return
    }

    invoices.splice(removeIndex, 1)
    // 当选中的 invoice 逐个减少时的判断
    if (invoices.length == 10) {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: true
      })
    } else {
      this.setState({
        selectInvoices: invoices,
        allCheckedBtn: false
        // isAllInvoicesChecked : false
      })
    }
  },

  createBill() {
    const { loc_id, subscription_id, successAction, _loadData } = this.props
    const { selectInvoices } = this.state
    const sales_renting_phase_ids = []
    selectInvoices.map(item => {
      if (!item.sales_invoice) {
        return sales_renting_phase_ids.push(item.id)
      }
    })
    const params = {
      location_id: loc_id,
      id: subscription_id,
      sales_renting_phase_ids
    }
    this.setState({createBillLoading:true},()=>{
      api.createGeneratePhaseInvoice(subscription_id, params).then(json => {
        successAction({ message: '创建成功!' })
        _loadData && _loadData(subscription_id)
        this.setState({
          selectInvoices: [],
          allCheckedBtn: false,
          isAllInvoicesChecked: false,
          createBillLoading: false
        })
      })
    })

  },

  // create subscription
  componentDidMount() {
    const { isFromAreasForm, _loadData, subscription_id } = this.props
    if (isFromAreasForm) {
      _loadData && _loadData(subscription_id)
    }
  },

  componentWillReceiveProps(nextProps) {
    const { subscription_id } = nextProps
    const oldSubId = this.props.subscription_id
    if (oldSubId != subscription_id) {
      this.props._loadData(subscription_id)
    }
  },

  // 税率
  taxJsx() {
    const {
      subscription: { tax, house_fee_tax }
    } = this.props
    return (tax && tax.rate) || (house_fee_tax && house_fee_tax.rate) ? (
      <fieldset className="fieldset">
        <legend>税率</legend>
        <div className="form-content" style={{ marginBottom: 0 }}>
          {tax && tax.rate ? (
            <div className="kb-row kb-form-66">
              <div className="kb-form-group kb-div-c39">
                <label>会员费税率</label>
                <div className="group-content">
                  <span>{tax.name}</span>
                </div>
              </div>
              <div className="kb-form-group kb-div-173">
                <label className="label-rt">税率值</label>
                <div className="group-content">
                  <span>{tax.rate}</span>
                </div>
              </div>
            </div>
          ) : null}
          {house_fee_tax && house_fee_tax.rate ? (
            <div className="kb-row kb-form-66">
              <div className="kb-form-group kb-div-c39">
                <label>物业费税率</label>
                <div className="group-content">
                  <span>{house_fee_tax.name}</span>
                </div>
              </div>
              <div className="kb-form-group kb-div-173">
                <label className="label-rt">税率值</label>
                <div className="group-content">
                  <span>{house_fee_tax.rate}</span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </fieldset>
    ) : (
      <div />
    )
  },

  /**
   * 交租 JSX
   * @returns {XML}
   */
  phasesJsx() {
    const {
      subscription: { sales_renting_phases, tax_amount, status, subscription_type },
      loc_id,
      user
    } = this.props
    let takeEffectSalesRentingPhases = []
    let notGenerate = 0
    if (status == 'active' || status == 'expiring') {
      if (sales_renting_phases && sales_renting_phases.length) {
        //获取未生成数量
        sales_renting_phases.map(item => {
          if (!item.sales_invoice) {
            notGenerate++
          }
        })
      }
      takeEffectSalesRentingPhases = sales_renting_phases
    } else {
      //终止的合同只展示生成的数据
      if (sales_renting_phases && sales_renting_phases.length) {
        sales_renting_phases.map(item => {
          if (item.sales_invoice) {
            takeEffectSalesRentingPhases.push(item)
          }
        })
      }
    }

    const { selectInvoices, allCheckedBtn } = this.state
    let DAYS = []
    let total = 0
    for (let i = 1; i <= 31; i++) {
      DAYS.push(i)
    }
    let currentTime = moment()
    takeEffectSalesRentingPhases &&
      takeEffectSalesRentingPhases.sort(function(a, b) {
        return (
          moment(a.recurring_due_date || a.recurring_due_day).valueOf() -
          moment(b.recurring_due_date || b.recurring_due_day).valueOf()
        )
      })
    return (
      <fieldset className="fieldset">
        <legend>缴{subscription_type === 'no_sales_area' ? '费' : '租'}阶段</legend>
        <div className="form-content" style={{ marginBottom: 0 }}>
          <table className="content-table">
            <thead>
              <tr>
                {(status == 'ended'||!canAdminFinance(user,loc_id)) ? null : (
                  <th>
                    <KBCheckBox
                      style={{ marginTop: 2 }}
                      data={takeEffectSalesRentingPhases}
                      callback={() =>
                        this.checkAllInvoices(takeEffectSalesRentingPhases)
                      }
                      checked={allCheckedBtn}
                      disabled={
                        !(
                          takeEffectSalesRentingPhases &&
                          takeEffectSalesRentingPhases.length
                        ) || !notGenerate
                      }
                    />
                  </th>
                )}
                <th>时段</th>
                <th>创建日期</th>
                <th>缴{subscription_type === 'no_sales_area' ? '费' : '租'}日</th>
                <th className="t-right">
                  <span
                    style={{
                      minWidth: 80,
                      display: 'inline-block',
                      textAlign: 'left'
                    }}
                  >
                    金额
                  </span>
                </th>
                <th>账单编号</th>
              </tr>
            </thead>
            <tbody>
              {takeEffectSalesRentingPhases &&
                takeEffectSalesRentingPhases.map((phase, index) => {
                  total = total + phase.total_amount
                  let isAfter = moment(phase.recurring_due_date).isAfter(
                    currentTime
                  )
                  let invoice = phase.sales_invoice
                  return (
                    <tr key={index}>
                      {(status == 'ended'||!canAdminFinance(user,loc_id)) ? null : (
                        <td
                          style={{
                            position: 'static',
                            verticalAlign: 'middle'
                          }}
                          onClick={e => e.stopPropagation()}
                        >
                          <KBCheckBox
                            style={{ marginTop: 2 }}
                            data={phase}
                            disabled={invoice}
                            callback={(checked, data) =>
                              this.changeInvoice(
                                checked,
                                data,
                                takeEffectSalesRentingPhases
                              )
                            }
                            checked={
                              (selectInvoices &&
                                selectInvoices.length &&
                                selectInvoices.find(data => {
                                  return data.id === phase.id
                                })) ||
                              false
                            }
                          />
                        </td>
                      )}
                      <td>
                        <span
                          style={
                            isAfter ? { color: '#333' } : { color: '#999' }
                          }
                        >
                          {formatYearDayCHS(phase.start_date)}
                        </span>
                        <span style={{ padding: '0 5px', marginTop: 8 }}>
                          -
                        </span>
                        <span
                          style={
                            isAfter ? { color: '#333' } : { color: '#999' }
                          }
                        >
                          {formatYearDayCHS(phase.end_date)}
                        </span>
                      </td>
                      <td>
                        <span>
                          {invoice && invoice.created_at
                            ? moment(invoice.created_at * 1000).format(
                                'YYYY-MM-DD'
                              )
                            : '未创建'}
                        </span>
                      </td>
                      <td>
                        <span>
                          {phase.recurring_due_date
                            ? phase.recurring_due_date
                            : phase.recurring_due_day}
                        </span>
                      </td>
                      <td className="t-right">
                        <span
                          style={{
                            minWidth: 80,
                            display: 'inline-block',
                            textAlign: 'left'
                          }}
                        >
                          ¥&nbsp;
                          {phase.recurring_due_date
                            ? parseFloat(phase.total_amount).toFixed(2)
                            : parseFloat(phase.recurring_amount).toFixed(2)}
                        </span>
                      </td>
                      <td>
                        {invoice ? (
                          <div>
                            {this.props.isFromAreasForm ? (
                              <span>{invoice.serial_number}</span>
                            ) : (
                              <Link
                                to={
                                  loc_id
                                    ? `/admin/locations/${invoice.location_id}/invoices/invoices_list/${invoice.id}`
                                    : `/admin/invoices/invoices_list/${invoice.id}`
                                }
                                className="color-link"
                              >
                                {invoice.serial_number}
                              </Link>
                            )}
                            <span
                              style={{
                                marginLeft: 25,
                                width: 64,
                                textAlign: 'center',
                                float: 'none',
                                lineHeight: 'normal'
                              }}
                              className={`finance-default ${
                                INVOICES_STATE_CLASS[invoice.status]
                              }`}
                            >
                              {invoice.status == 'paid'
                                ? '已付款'
                                : INVOICES_STATE[invoice.status]}
                            </span>
                          </div>
                        ) : (
                          '未生成'
                        )}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          {selectInvoices.length ? (
            <KBButton
              onClick={this.createBill}
              className="certain-btn"
              style={{ marginRight: 0, marginTop: 20 }}
              submitting={this.state.createBillLoading}
            >
              立即创建账单
            </KBButton>
          ) : null}
          <div className="total-num-block">
            <div className="">资金总额</div>
            <div className="total-money">¥{fmoney(total || 0)}</div>
            {tax_amount ? (
              <div className="tax">
                (包含税额：<span>¥{fmoney(tax_amount || 0)}</span>)
              </div>
            ) : null}
          </div>
        </div>
      </fieldset>
    )
  },

  /**
   * 工位信息  添加区域 和 添加工位功能
   * @returns {XML}
   */
  areasJsx() {
    const {
      subscription: { sales_areas, sales_desks, location_id },
      isInvoice
    } = this.props

    const private_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'private_office')
      : []
    const public_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'public_office')
      : []
    const flexible_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'flexible_office')
      : []

    if (
      !private_areas.length &&
      !public_areas.length &&
      !flexible_areas.length
    ) {
      return ''
    }

    const isTenement = private_areas.filter(area => {
      return parseFloat(area.house_fee)
    }).length
    return (
      <fieldset className="fieldset">
        <legend>工位设置</legend>
        {private_areas && private_areas.length > 0 ? (
          <div>
            <legend style={{ fontSize: 18, border: 0, marginBottom: 5 }}>
              封闭工位
            </legend>
            <table className="content-table m-bottom" style={{ marginTop: 0 }}>
              <thead>
                {isTenement ? (
                  <tr>
                    <th>位置</th>
                    <th>房间单价</th>
                    <th>平米数/工位数</th>
                    <th>会员费</th>
                    <th>物业费</th>
                    <th>总会员费</th>
                  </tr>
                ) : (
                  <tr>
                    <th>位置</th>
                    <th>房间单价</th>
                    <th>平米数/工位数</th>
                    <th>总会员费</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {private_areas.map((area, index) => {
                  const {
                    charge_type,
                    price,
                    units,
                    area_id,
                    m2,
                    house_fee,
                    total_price
                  } = area
                  return isTenement ? (
                    <tr key={index}>
                      <td>
                        <div className="t-over" style={{ width: 200 }}>
                          {area.area && area.area.name ? (
                            this.props.isFromAreasForm ? (
                              <span>{area.area.name}</span>
                            ) : (
                              <Link
                                className="color-link"
                                to={`/admin/locations/${location_id}/areas/desks/list?area_id=${area_id}`}
                              >
                                {area.area.name}
                              </Link>
                            )
                          ) : (
                            '暂无分区'
                          )}
                        </div>
                      </td>
                      <td>¥{price ? `${price}` : '0'}</td>
                      <td>
                        {charge_type === 'by_area'
                          ? (m2 || '0') + '㎡'
                          : units + '个'}
                      </td>
                      <td>
                        ¥ {parseFloat(total_price) - parseFloat(house_fee)}
                      </td>
                      <td>¥ {house_fee || '0'}</td>
                      <td>¥ {total_price || '0'}</td>
                    </tr>
                  ) : (
                    <tr key={index}>
                      <td>
                        <div className="t-over" style={{ width: 200 }}>
                          {area.area && area.area.name ? (
                            this.props.isFromAreasForm ? (
                              <span>{area.area.name}</span>
                            ) : (
                              <Link
                                className="color-link"
                                to={`/admin/locations/${location_id}/areas/desks/list?area_id=${area_id}`}
                              >
                                {area.area.name}
                              </Link>
                            )
                          ) : (
                            '暂无分区'
                          )}
                        </div>
                      </td>
                      <td>¥{price ? `${price}` : '0'}</td>
                      <td>
                        {charge_type === 'by_area'
                          ? (m2 || '0') + '㎡'
                          : units + '个'}
                      </td>
                      <td>¥ {total_price || '0'}/月</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ''
        )}
        {public_areas && public_areas.length > 0 ? (
          <div>
            <legend style={{ fontSize: 18, border: 0, marginBottom: 5 }}>
              开放工位
            </legend>
            <table className="content-table m-bottom" style={{ marginTop: 0 }}>
              <thead>
                <tr>
                  <th>位置</th>
                  <th>工位价格</th>
                  <th>工位数量</th>
                  <th>已分配工位号</th>
                  <th>总会员费</th>
                </tr>
              </thead>
              <tbody>
                {public_areas.map((area, index) => {
                  const { price, units, desks, area_id, total_price } = area
                  let deskNums = []
                  desks &&
                    desks.length > 0 &&
                    desks.map(desk => {
                      deskNums.push(desk.serial_number)
                    })
                  return (
                    <tr key={index}>
                      <td>
                        <div className="t-over" style={{ width: 200 }}>
                          {area.area && area.area.name ? (
                            this.props.isFromAreasForm ? (
                              <span>{area.area.name}</span>
                            ) : (
                              <Link
                                className="color-link"
                                to={`/admin/locations/${location_id}/areas/desks/list?area_id=${area_id}`}
                              >
                                {area.area.name}
                              </Link>
                            )
                          ) : (
                            '暂无分区'
                          )}
                        </div>
                      </td>
                      <td>{price ? `¥${price}` : ''}</td>
                      <td>{units || ''}</td>
                      <td>
                        <div style={{ maxWidth: 205, wordBreak: 'break-all' }}>
                          {deskNums.join(',')}
                        </div>
                      </td>
                      <td>¥ {total_price || '0'}/月</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ''
        )}
        {flexible_areas && flexible_areas.length > 0 ? (
          <div>
            <legend style={{ fontSize: 18, border: 0, marginBottom: 5 }}>
              灵活工位
            </legend>
            <table className="content-table m-bottom" style={{ marginTop: 0 }}>
              <thead>
                <tr>
                  <th>位置</th>
                  <th>工位价格</th>
                  <th>工位数量</th>
                  <th>已分配工位号</th>
                  <th>总会员费</th>
                </tr>
              </thead>
              <tbody>
                {flexible_areas.map((area, index) => {
                  const { price, units, desks, area_id, total_price } = area
                  let deskNums = []
                  desks &&
                    desks.length > 0 &&
                    desks.map(desk => {
                      deskNums.push(desk.serial_number)
                    })
                  return (
                    <tr key={index}>
                      <td>
                        <div className="t-over" style={{ width: 200 }}>
                          {area.area && area.area.name ? (
                            this.props.isFromAreasForm ? (
                              <span>{area.area.name}</span>
                            ) : (
                              <Link
                                className="color-link"
                                to={`/admin/locations/${location_id}/areas/desks/list?area_id=${area_id}`}
                              >
                                {area.area.name}
                              </Link>
                            )
                          ) : (
                            '暂无分区'
                          )}
                        </div>
                      </td>
                      <td>{price ? `¥${price}` : ''}</td>
                      <td>{units || ''}</td>
                      <td>{deskNums.join(',')}</td>
                      <td>¥ {total_price || ''}/月</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ''
        )}
      </fieldset>
    )
  },

  /**
   * 添加记录 JSX
   * @returns {XML}
   */
  serialJsx() {
    const {
      subscription: { serial, files, formal }
    } = this.props

    return (
      <fieldset className="fieldset">
        <legend>合同信息</legend>
        <div className="kb-row kb-form-66">
          <div className={'kb-form-group kb-div-c39'}>
            <label>合同类型</label>
            <div className="group-content">
              <span>
                {formal
                  ? '正式合同(只有正式签署的合同才会产生账单,待签字合同也可以安排会员入驻)'
                  : '待签字合同'}
              </span>
            </div>
          </div>
          <div className="kb-form-group kb-div-173">
            <label className="label-rt">合同编号</label>
            <div className="group-content">
              <span>{serial}</span>
            </div>
          </div>
        </div>
        <div className="kb-row kb-form-66">
          <div className={'kb-form-group kb-div-c39'}>
            <label>合同扫描件</label>
            {files && files.length > 0 ? (
              files.map((file, index) => {
                return (
                  <div className="group-content">
                    <KBDownloadPrivacy url={file.file_url}>
                      <a
                        key={index}
                        style={{ marginRight: 20 }}
                        className="color-link"
                      >
                        {file.name}
                        {file.attachment ? '(原文件)' : ''}
                      </a>
                    </KBDownloadPrivacy>
                  </div>
                )
              })
            ) : (
              <div className="group-content">
                <span>无</span>
              </div>
            )}
          </div>
          <div />
        </div>
      </fieldset>
    )
  },

  /**
   * 车位合同 车位信息JSX
   * @returns {XML}
   */
  parkingSpacesJsx() {
    const {
      subscription: { sales_areas }
    } = this.props
    const parkings = sales_areas
      ? sales_areas.filter(area => area.area_type === 'parking')
      : []

    return (
      <fieldset className="fieldset">
        <legend>车位信息</legend>
        {parkings && parkings.length > 0 ? (
          <div>
            <table className="content-table m-bottom" style={{ marginTop: 0 }}>
              <thead>
                <tr>
                  <th>车位编号</th>
                  <th>所属楼层</th>
                  <th>车牌号</th>
                  <th>价格</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {parkings.map((area, index) => {
                  const { price, car_plates } = area
                  return (
                    <tr key={index}>
                      <td>
                        {area.area && area.area.name ? (
                          <span>{area.area.name}</span>
                        ) : (
                          '暂无'
                        )}
                      </td>
                      <td>
                        {(area.area && area.area.ceil_list.join(',')) || ''}
                      </td>
                      <td>
                        {car_plates &&
                          car_plates.map(p => {
                            return (
                              <div>
                                车牌号：{p.car_plate}，行驶证编号：
                                {p.driver_license}，行驶证所有者：{p.owner_name}
                              </div>
                            )
                          })}
                      </td>
                      <td>¥ {price || '0'}/月</td>
                      <td className="t-right">
                        <KBTipsy content="修改车牌号">
                          <i
                            className="iconfont icon-edit"
                            onClick={e => {
                              e.stopPropagation()
                              this.updateCarPlates(area)
                            }}
                          />
                        </KBTipsy>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ''
        )}
      </fieldset>
    )
  },

  updateCarPlates(parking) {
    const { subscription } = this.props

    KBPopover.show(
      <ParkingCarPlatesForm sub_id={subscription.id} parking={parking} />
    )
  },

  /**
   * 合同信息JSX
   * @returns {XML}
   */
  subJsx() {
    const {
      org,
      isInvoice,
      loc_id,
      subscription,
      usersObj,
      isParkingContract
    } = this.props
    const {
      subscription: {
        sales_areas,
        sales_renting_phases,
        deposit,
        deposit_invoices,
        creator,
        approved_by,
        renting_type_list
      }
    } = this.props
    let deposit_invoice =
      (deposit_invoices && deposit_invoices.length && deposit_invoices[0]) || ''

    const private_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'private_office')
      : []
    const public_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'public_office')
      : []
    const flexible_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'flexible_office')
      : []

    let private_areas_array = []
    private_areas &&
      private_areas.map(area => {
        let str = `${(area.area && area.area.name) || ''}(${
          area.charge_type === 'by_desk'
            ? area.units + '个'
            : (area.m2 || '0') + '㎡'
        })`
        private_areas_array.push(str)
      })
    let public_areas_array = []
    public_areas &&
      public_areas.map(area => {
        let str = `${(area.area && area.area.name) || ''}(${area.units}个)`
        public_areas_array.push(str)
      })
    let flexible_areas_array = []
    flexible_areas &&
      flexible_areas.map(area => {
        let str = `${area.units}个`
        flexible_areas_array.push(str)
      })

    let subTotalAmount = deposit || 0
    let subRentAmount = 0
    sales_renting_phases &&
      sales_renting_phases.length &&
      sales_renting_phases.map(phase => {
        subRentAmount += phase.total_amount
      })
    subTotalAmount += subRentAmount

    return (
      <fieldset className="fieldset">
        <div className="kb-row kb-form-66">
          <div className={'kb-form-group kb-div-c39'}>
            <label>合同编号</label>
            <div className="group-content">
              <span>{subscription.serial}</span>
            </div>
          </div>
          <div className="kb-form-group kb-div-173">
            <label className="label-rt">合同时长</label>
            <div className="group-content">
              <span>
                {formatYearDayCHS(subscription.start_date) +
                  ' - ' +
                  formatYearDayCHS(subscription.end_date)}
              </span>
            </div>
          </div>
        </div>
        {creator || approved_by ? (
          <div className="kb-row kb-form-66">
            {creator ? (
              <div className={'kb-form-group kb-div-c39'}>
                <label>合同提交人</label>
                <div className="group-content">
                  <span>
                    {this.props.isFromAreasForm ? (
                      <span>{creator.name}</span>
                    ) : (
                      <Link
                        to={getUserInfoUrl(creator.id)}
                        className="color-link"
                      >
                        {creator.name}
                      </Link>
                    )}
                  </span>
                </div>
              </div>
            ) : null}
            {approved_by ? (
              <div className="kb-form-group kb-div-173">
                <label className="label-rt">合同审批人</label>
                <div className="group-content">
                  <span>
                    {this.props.isFromAreasForm ? (
                      <span>
                        {usersObj[approved_by] && usersObj[approved_by].name}
                      </span>
                    ) : (
                      <Link
                        to={getUserInfoUrl(approved_by)}
                        className="color-link"
                      >
                        {usersObj[approved_by] && usersObj[approved_by].name}
                      </Link>
                    )}
                  </span>
                </div>
              </div>
            ) : isParkingContract ? (
              <div className="kb-form-group kb-div-173">
                <label className="label-rt">租赁类型</label>
                <div className="group-content">
                  <span>
                    {renting_type_list.length > 0
                      ? renting_type_list.join(',')
                      : '暂无'}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="kb-row kb-form-66">
          <div className={'kb-form-group kb-div-c39'}>
            <label>{isParkingContract ? '承租方名称' : '公司名称'}</label>
            <div className="group-content">
              {isInvoice || !loc_id || this.props.isFromAreasForm ? (
                <span>{org.full_name}</span>
              ) : (
                <span>
                  <Link
                    className="color-link"
                    to={`/admin/locations/${loc_id}/organizations/${org.id}`}
                  >
                    {org.full_name}
                  </Link>
                </span>
              )}
            </div>
          </div>
          {isParkingContract ? (
            <div className="kb-form-group kb-div-c39">
              <label>租赁车位个数</label>
              <div className="group-content">
                <span>{(sales_areas && sales_areas.length) || 0}</span>
              </div>
            </div>
          ) : null}
        </div>
        {isParkingContract
          ? null
          : subscription.subscription_type !== 'no_sales_area' && (
              <div className="kb-row kb-form-66">
                <div className="kb-form-group kb-div-c39">
                  <label>封闭工位</label>
                  <div className="group-content">
                    <span>
                      {(private_areas_array &&
                        private_areas_array.length &&
                        private_areas_array.join('、')) ||
                        '暂无'}
                    </span>
                  </div>
                </div>
                <div className="kb-form-group kb-div-66">
                  <div className="kb-form-group kb-div-173">
                    <label className="label-rt">开放工位</label>
                    <div className="group-content">
                      <span>
                        {(public_areas_array &&
                          public_areas_array.length &&
                          public_areas_array.join('、')) ||
                          '暂无'}
                      </span>
                    </div>
                  </div>
                  <div className="kb-form-group kb-div-173">
                    <label className="label-rt">灵活工位</label>
                    <div className="group-content">
                      <span>
                        {(flexible_areas_array &&
                          flexible_areas_array.length &&
                          flexible_areas_array.join('')) ||
                          '暂无'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
        <div className="kb-row kb-form-66">
          <div className="kb-form-group kb-div-c39">
            <label>
              {subscription.subscription_type === 'no_sales_area'
                ? '周期性费用'
                : '合同会员费'}
            </label>
            <div className="group-content">
              <span>¥ {fmoney(subRentAmount) || 0}</span>
            </div>
          </div>
          {subscription.subscription_type !== 'no_sales_area' && (
            <div className="kb-form-group kb-div-173">
              <label className="label-rt">合同押金</label>
              <div className="group-content">
                <span>¥ {fmoney(subscription.deposit)}</span>
                {deposit_invoice ? (
                  <span
                    style={{
                      marginLeft: 25,
                      width: '64px',
                      textAlign: 'center',
                      float: 'none',
                      lineHeight: 'normal'
                    }}
                    className={`finance-default ${
                      INVOICES_STATE_CLASS[deposit_invoice.status]
                    }`}
                  >
                    {deposit_invoice.status == 'paid'
                      ? '已付款'
                      : INVOICES_STATE[deposit_invoice.status]}
                  </span>
                ) : null}
              </div>
            </div>
          )}
        </div>
        <div className="kb-row kb-form-66">
          <div className="kb-form-group kb-div-c39">
            <label>合同总额</label>
            <div className="group-content">
              <span>¥ {fmoney(subTotalAmount) || 0}</span>
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  /**
   * 公司信息JSX
   * @returns {XML}
   */
  orgJsx() {
    const { org, isInvoice, leaders, loc_id } = this.props
    let leader = leaders && leaders[0]

    return (
      <fieldset className="fieldset">
        <legend>公司信息</legend>
        <div className="kb-row kb-form-66">
          <div className={'kb-form-group kb-div-210'}>
            <label>公司全称</label>
            <div className="group-content">
              {isInvoice || !loc_id ? (
                <span>{org.full_name}</span>
              ) : (
                <span>
                  {this.props.isFromAreasForm ? (
                    <span>{org.full_name}</span>
                  ) : (
                    <Link
                      className="color-link"
                      to={`/admin/locations/${loc_id}/organizations/${org.id}`}
                    >
                      {org.full_name}
                    </Link>
                  )}
                </span>
              )}
            </div>
          </div>
          <div className="kb-form-group kb-div-173">
            <label className="label-rt">公司简称</label>
            <div className="group-content">
              <span>{org.name}</span>
            </div>
          </div>
        </div>
        <div className="kb-row kb-form-66">
          <div className="kb-form-group kb-div-210">
            <label>负责人</label>
            <div className="group-content">
              <span>{leader ? leader.name : org.contacts_name}</span>
            </div>
          </div>
          <div className="kb-form-group kb-div-173">
            <label className="label-rt">联系电话</label>
            <div className="group-content">
              <span>{leader ? leader.phone_num : org.contacts_phone}</span>
            </div>
          </div>
        </div>
        <div className="kb-row kb-form-66">
          <div className="kb-form-group kb-div-210">
            <label>微信</label>
            <div className="group-content">
              <span>{(leader && leader.wechat) || '暂无'}</span>
            </div>
          </div>
          <div className="kb-form-group kb-div-210">
            <label>邮箱</label>
            <div className="group-content">
              <span>{(leader && leader.email) || '暂无'}</span>
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  /**
   * 车位合同的承租人信息 JSX
   * @returns {XML}
   */
  renterInfoJsx() {
    const { org } = this.props

    return (
      <fieldset className="fieldset">
        <legend>承租方信息</legend>
        <div
          className="kb-row border"
          style={{ padding: '5px 15px', borderRadius: 4 }}
        >
          <div className="kb-row kb-form-66">
            <div className="kb-form-group kb-div-210">
              <label>承租方类型</label>
              <div className="group-content">
                <span>
                  {PARKING_CONTRACT_RENTER_TYPES_OBJ[org.organization_type] ||
                    '-'}
                </span>
              </div>
            </div>
            <div className="kb-form-group kb-div-173">
              <label className="label-rt">承租方名称</label>
              <div className="group-content">
                <span>{org.full_name || '-'}</span>
              </div>
            </div>
          </div>
          <div className="kb-row kb-form-66">
            <div className="kb-form-group kb-div-173">
              <label>承租方姓名</label>
              <div className="group-content">
                <span>{org.contacts_name || '-'}</span>
              </div>
            </div>
            <div className="kb-form-group kb-div-173">
              <label className="label-rt">联系电话</label>
              <div className="group-content">
                <span>{org.contacts_phone || '-'}</span>
              </div>
            </div>
          </div>
          <div className="kb-row kb-form-66">
            <div className="kb-form-group kb-div-173">
              <label>位置标签</label>
              <div className="group-content">
                <span>
                  {(org.position_list && org.position_list.join(',')) || '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  /**
   * 时间期限JSX
   * @returns {XML}
   */
  timeJsx() {
    const {
      subscription: {
        start_date,
        end_date,
        expire_date,
        signed_at,
        subscription_type
      },
      isInvoice
    } = this.props
    var halfClass = {
      className: 'kb-form-group kb-div-c39'
    }

    return (
      <fieldset className="fieldset">
        <legend>合同日期</legend>
        <div className="kb-row kb-form-66">
          <div className={`kb-form-group kb-div-c39`}>
            <label>签约日期</label>
            <div className="group-content">
              <span>{formatYearDayEN(signed_at)}</span>
            </div>
          </div>
          <div className="kb-form-group kb-div-c39">
            <label>起始日期</label>
            <div className="group-content">
              <span>{formatYearDayEN(start_date)}</span>
            </div>
          </div>
        </div>
        <div className="kb-row kb-form-66">
          <div className={`kb-form-group kb-div-c39`}>
            <label>合同终止日期</label>
            <div className="group-content">
              <span>{formatYearDayEN(end_date)}</span>
            </div>
          </div>
          <div className="kb-form-group kb-div-c39">
            <label>实际终止日期</label>
            <div className="group-content">
              <span>{formatYearDayEN(expire_date)}</span>
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  nodeJsx() {
    const {
      subscription: { status, note }
    } = this.props
    return (
      <fieldset className="fieldset">
        <legend>{status === 'rejected' ? '审批拒绝原因' : '合同备注'}</legend>
        {note ? (
          <span>{note}</span>
        ) : (
          <span style={{ color: '#999' }}>暂无数据</span>
        )}
      </fieldset>
    )
  },

  /**
   * 会员资源设置 JSX
   * @returns {XML}
   */
  salesResourcesJsx() {
    const {
      subscription: { sales_resources },
      virtual_currency_name,
      subscription
    } = this.props

    if (!(sales_resources && sales_resources.length > 0)) {
      return null
    }
    let isShow = false
    sales_resources.forEach(sales => {
      if (sales.amount) {
        isShow = true
      }
    })

    return (
      isShow && (
        <fieldset className="fieldset">
          <legend>会员资源信息</legend>
          <table className="content-table m-bottom" style={{ marginTop: 0 }}>
            <thead>
              <tr>
                <th>类型</th>
                <th>数量</th>
                <th>赠送周期</th>
              </tr>
            </thead>
            <tbody>
              {sales_resources
                .filter(sales => sales.resource_type === 'point')
                .map((resource, index) => {
                  const { resource_type, cycle_unit, points, amount } = resource
                  return (
                    <tr key={index}>
                      <td>
                        {resource_type &&
                        subscription.subscription_type !== 'no_sales_area'
                          ? virtual_currency_name
                          : '办公券'}
                      </td>
                      <td>{points || amount || 0}</td>
                      <td>
                        {cycle_unit && cycle_unit === 'monthly' ? '每月' : ''}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </fieldset>
      )
    )
  },

  /**
   * 计算押金 JSX
   * @returns {XML}
   */
  depositJsx() {
    const {
      subscription: {
        deposit,
        deposit_cycles,
        deposit_due_date,
        deposit_invoices
      },
      loc_id
    } = this.props
    let invoice =
      deposit_invoices && deposit_invoices.length && deposit_invoices[0]
    return (
      <fieldset className="fieldset">
        <legend>押金信息</legend>
        <table className="content-table m-bottom" style={{ marginTop: 0 }}>
          <thead>
            <tr>
              <th>时长</th>
              <th>付款日期</th>
              <th>押金总金额</th>
              <th>账单编号</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{deposit_cycles}个月</td>
              <td>{formatYearDayCHS(deposit_due_date)}</td>
              <td>{deposit ? `¥${deposit}` : ''}</td>
              <td>
                {invoice ? (
                  <div>
                    {this.props.isFromAreasForm ? (
                      <span>{invoice.serial_number}</span>
                    ) : (
                      <Link
                        to={
                          loc_id
                            ? `/admin/locations/${invoice.location_id}/invoices/invoices_list/${invoice.id}`
                            : `/admin/invoices/invoices_list/${invoice.id}`
                        }
                        className="color-link"
                      >
                        {invoice.serial_number}
                      </Link>
                    )}
                    <span
                      style={{
                        marginLeft: 25,
                        width: 64,
                        textAlign: 'center',
                        float: 'none',
                        lineHeight: 'normal'
                      }}
                      className={`finance-default ${
                        INVOICES_STATE_CLASS[invoice.status]
                      }`}
                    >
                      {invoice.status == 'paid'
                        ? '已付款'
                        : INVOICES_STATE[invoice.status]}
                    </span>
                  </div>
                ) : (
                  '未生成'
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    )
  },

  // 甲方信息
  providerJsx() {
    const {
      subscription: { provider }
    } = this.props
    if (!provider) {
      return null
    }
    return (
      <fieldset className="fieldset">
        <legend>收款账户信息</legend>
        <div className="location-provider">
          <div className="provider-line">
            <div>
              <span>账户信息</span>
              <span>{provider.info || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>账户开户行</span>
              <span>{provider.account_bank || '暂无'}</span>
            </div>
            <div>
              <span>账户地址</span>
              <span>{provider.physical_address || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>账户名</span>
              <span>{provider.account_name || '暂无'}</span>
            </div>
            <div>
              <span>账号</span>
              <span>{provider.account_number || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>联系人</span>
              <span>{provider.contact_name || '暂无'}</span>
            </div>
            <div>
              <span>联系方式</span>
              <span>{provider.contact_phone || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>统一社会信用代码</span>
              <span>
                {(provider.business_license_number &&
                  provider.business_license_number) ||
                  '暂无'}
              </span>
            </div>
            <div>
              <span>SWIFT代码</span>
              <span>
                {(provider.swift_code && provider.swift_code) || '暂无'}
              </span>
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  // 发票抬头
  invoiceTitleJsx() {
    const {
      subscription: { invoice_title }
    } = this.props
    if (!invoice_title) {
      return null
    }
    const {
      title,
      tax_num,
      bank,
      bank_account,
      contacts_name,
      phone_num,
      address,
      email
    } = invoice_title

    return (
      <fieldset className="fieldset">
        <legend>发票抬头信息</legend>
        <div className="location-provider">
          <div className="provider-line">
            <div>
              <span>发票抬头</span>
              <span>{title || '暂无'}</span>
            </div>
            <div>
              <span>纳税人识别号</span>
              <span>{tax_num || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>开户行</span>
              <span>{bank || '暂无'}</span>
            </div>
            <div>
              <span>银行账号</span>
              <span>{bank_account || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>联系人</span>
              <span>{contacts_name || '暂无'}</span>
            </div>
            <div>
              <span>联系电话</span>
              <span>{phone_num || '暂无'}</span>
            </div>
          </div>
          <div className="provider-line">
            <div>
              <span>地址</span>
              <span>{address || '暂无'}</span>
            </div>
            <div>
              <span>邮箱</span>
              <span>{email || '暂无'}</span>
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  terminate() {
    const { subscription_id, isFromAreasForm, loadAreas, loc_id } = this.props
    let terminateForm = (
      <OrgSubTerminateForm
        subId={subscription_id}
        loc_id={loc_id}
        isTop={isFromAreasForm}
        callback={isFromAreasForm ? loadAreas : null}
      />
    )
    if (isFromAreasForm) {
      KBPopoverTop.show(terminateForm)
    } else {
      KBPopover.show(terminateForm)
    }
  },

  setRealSubscription() {
    const { subscription } = this.state

    KBPopover.show(<RealSubscriptionForm subId={this.props.subscription_id} />)
  },

  subscriptionStateText(subscription) {
    if (!subscription) {
      return ''
    }

    const expire_date = moment(subscription.expire_date)
    const end_date = moment(subscription.end_date)
    const current_date = moment()
    let text = ''

    if (contrastDate(current_date, expire_date, 'days') > 0) {
      text = '合同已经终止'
    } else if (
      contrastDate(expire_date, current_date, 'days') >= 0 &&
      contrastDate(end_date, expire_date, 'days') > 0
    ) {
      text = `合同已经定于${moment(subscription.expire_date).format(
        'YYYY年MM月DD日'
      )}提前终止，合同终止之后不会继续生成账单!`
    }

    return text
  },

  setSubscriptionSuccess(id) {
    const { updateSubscriptionsAction, successAction } = this.props

    KBPopover.plugins.confirm('合同审批', '是否通过该合同？', {
      confirm: () => {
        api.updateSubScriptionApprove(id, { pass: true }).then(
          json => {
            successAction({ message: '合同审批通过操作成功' })
            updateSubscriptionsAction.success(json)
            KBPopover.close()
          },
          errors => {
            updateSubscriptionsAction.failure(errors)
          }
        )
      }
    })
  },

  setSubscriptionFailure(id) {
    const { updateSubscriptionsAction, successAction } = this.props
    KBPopover.plugins.confirm('合同审批', '是否不通过该合同？', {
      confirm: () => {
        api.updateSubScriptionApprove(id, { pass: false }).then(
          json => {
            successAction({ message: '合同审批不通过通过操作成功' })
            updateSubscriptionsAction.success(json)
            KBPopover.close()
          },
          errors => {
            updateSubscriptionsAction.failure(errors)
          }
        )
      }
    })
  },

  toRenewContract(subscription) {
    const { routerActions, isCrmContract, isParkingContract } = this.props
    const { id, location_id, organization_id } = subscription
    KBPopover.plugins.confirm(
      '续约',
      `新合同即将copy合同${
        isParkingContract ? '车位' : '工位'
      }信息并重置起止日期、签订日期、缴租阶段，确定要续约吗？`,
      {
        confirm: () => {
          KBPopover.close()
          let url = ''
          if (isParkingContract) {
            url = `/admin/locations/${location_id}/parking/contract_new?contract_id=${id}&status=extend`
          } else if (isCrmContract) {
            url = `/admin/crm/opportunities/sub/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=extend&location_id=${location_id}&typeSource=crmrecord`
          } else if (subscription.subscription_type === 'no_sales_area') {
            url = `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract=无销售面积&contract_id=${id}&status=extend`
          } else {
            url = `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=extend`
          }

          routerActions.push(url)
        }
      }
    )
  },

  parentSubJsx() {
    const {
      subscription,
      isInvoice,
      loc_id,
      org_id,
      isCrmContract
    } = this.props

    return (
      subscription &&
      subscription.parent && (
        <fieldset className="fieldset">
          <legend>原合同</legend>
          <ul>
            <li>
              {isInvoice ? (
                this.props.isFromAreasForm ? (
                  <span>{subscription.parent.serial}</span>
                ) : (
                  <Link
                    to={`/admin/invoices/1/organizations/${org_id}/subscriptions/${subscription.parent_id}`}
                  >
                    <span className="color-link">
                      {subscription.parent.serial}
                    </span>
                  </Link>
                )
              ) : this.props.isFromAreasForm ? (
                <span>{subscription.parent.serial}</span>
              ) : (
                <Link
                  to={
                    isCrmContract
                      ? `/admin/crm/opportunities/sub/organizations/${org_id}/subscriptions/${subscription.parent_id}?location_id=${loc_id}&typeSource=crmrecord`
                      : `/admin/locations/${loc_id}/organizations/${org_id}/subscriptions/${subscription.parent_id}`
                  }
                >
                  <span className="color-link">
                    {subscription.parent.serial}
                  </span>
                </Link>
              )}
            </li>
          </ul>
        </fieldset>
      )
    )
  },

  descendantSubJsx() {
    const {
      subscription,
      isInvoice,
      loc_id,
      org_id,
      isCrmContract
    } = this.props

    return (
      subscription.descendants &&
      subscription.descendants.length > 0 && (
        <fieldset className="fieldset">
          <legend>续约合同</legend>
          <ul>
            {subscription.descendants.map((contract, index) => {
              return (
                <li key={index}>
                  {isInvoice ? (
                    this.props.isFromAreasForm ? (
                      <span>{contract.serial}</span>
                    ) : (
                      <Link
                        to={`/admin/invoices/1/organizations/${org_id}/subscriptions/${contract.id}`}
                      >
                        <span className="color-link">{contract.serial}</span>
                      </Link>
                    )
                  ) : this.props.isFromAreasForm ? (
                    <span>{contract.serial}</span>
                  ) : (
                    <Link
                      to={
                        isCrmContract
                          ? `/admin/crm/opportunities/sub/organizations/${org_id}/subscriptions/${contract.id}?location_id=${loc_id}&typeSource=crmrecord`
                          : `/admin/locations/${loc_id}/organizations/${org_id}/subscriptions/${contract.id}`
                      }
                    >
                      <span className="color-link">{contract.serial}</span>
                    </Link>
                  )}
                </li>
              )
            })}
          </ul>
        </fieldset>
      )
    )
  },

  crmSourceJsx() {
    const {
      subscription: { crm_source, crm_source_fee, crm_source_notes }
    } = this.props

    if (!crm_source) {
      return null
    }

    return (
      <fieldset className="fieldset">
        <legend>渠道分成</legend>
        <div className="kb-row kb-form-66">
          <div className={`kb-form-group kb-div-c39`}>
            <label>渠道来源</label>
            <div className="group-content">
              <span>{(crm_source && crm_source.name) || ''}</span>
            </div>
          </div>
          <div className="kb-form-group kb-div-c39">
            <label>渠道费用</label>
            <div className="group-content">
              <span>{`¥ ${crm_source_fee || 0}`}</span>
            </div>
          </div>
        </div>
        <div className="kb-row kb-form-66">
          <div className={`kb-form-group kb-div-c39`}>
            <label>渠道备注</label>
            <div className="group-content">
              <span>{crm_source_notes || ''}</span>
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  openAudit(subscription) {
    const { routerActions, isCrmContract, isParkingContract } = this.props
    const { id, location_id, organization_id } = subscription
    let url = ''
    if (isParkingContract) {
      url = `/admin/locations/${location_id}/parking/contract_new?contract_id=${id}&status=audit`
    } else if (isCrmContract) {
      url = `/admin/crm/opportunities/sub/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=audit&location_id=${location_id}&typeSource=crmrecord`
    } else if (subscription.subscription_type === 'no_sales_area') {
      url = `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract=无销售面积&contract_id=${id}&status=audit`
    } else {
      url = `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=audit`
    }

    routerActions.push(url)
  },

  editSubscription(subscription) {
    const { routerActions, isCrmContract, isParkingContract } = this.props
    const { id, location_id, organization_id } = subscription

    let url = ''
    if (isParkingContract) {
      url = `/admin/locations/${location_id}/parking/contract_new?contract_id=${id}&status=edit`
    } else if (isCrmContract) {
      url = `/admin/crm/opportunities/sub/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=edit&location_id=${location_id}&typeSource=crmrecord`
    } else if (subscription.subscription_type === 'no_sales_area') {
      url = `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract=无销售面积&contract_id=${id}&status=edit`
    } else {
      url = `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=edit`
    }

    routerActions.push(url)
  },

  // toAnewContract(subscription) {
  //   const {
  //     routerActions,
  //     updateSubscriptionsAction,
  //     isCrmContract,
  //     isParkingContract
  //   } = this.props
  //   const { id, location_id, organization_id } = subscription
  //   let params = Object.assign(
  //     {},
  //     {
  //       expire_date: null,
  //       can_destroy_invoices: true,
  //       terminate_reason: 'input_mistake',
  //       terminate_note: '合同错误,重新录入'
  //     }
  //   )
  //   KBPopover.plugins.confirm(
  //     '重新录入',
  //     '即将终止合同，并copy合同到新合同，删除缴租阶段，确定要重新录入吗？',
  //     {
  //       confirm: () => {
  //         KBPopover.close()
  //         api.terminateSubScriptiopns(id, params).then(
  //           json => {
  //             updateSubscriptionsAction.success(json)
  //             let url = ''
  //             if (isParkingContract) {
  //               url = `/admin/locations/${location_id}/parking/contract_new?contract_id=${id}&status=anew`
  //             } else if (isCrmContract) {
  //               url = `/admin/crm/opportunities/sub/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=anew&location_id=${location_id}&typeSource=crmrecord`
  //             } else if (subscription.subscription_type === 'no_sales_area') {
  //               url = `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract=无销售面积&contract_id=${id}&status=extend`
  //             } else {
  //               url = `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=anew`
  //             }

  //             routerActions.push(url)
  //           },
  //           error => {
  //             updateSubscriptionsAction.failure(error)
  //           }
  //         )
  //       }
  //     }
  //   )
  // },

  updateContract(subscription) {
    const { routerActions, isCrmContract, isParkingContract } = this.props
    const { id, location_id, organization_id } = subscription
    let tipMsg = ''
    if (isParkingContract) {
      tipMsg =
        '修改将只能修改合同所签车位的车牌号码，其他信息无法修改，确定要修改吗？'
    } else {
      tipMsg = `原合同会保留缴租阶段，用户可以重新${
        subscription.subscription_type === 'no_sales_area' ? '添加' : '生成'
      }缴租阶段，对于以前核销的缴租阶段也可以删除，确定要修改吗？`
    }
    KBPopover.plugins.confirm('修改', tipMsg, {
      confirm: () => {
        KBPopover.close()
        let url = ''
        if (isParkingContract) {
          url = `/admin/locations/${location_id}/parking/contract_new?contract_id=${id}&status=update`
        } else if (isCrmContract) {
          url = `/admin/crm/opportunities/sub/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=update&location_id=${location_id}&typeSource=crmrecord`
        } else if (subscription.subscription_type === 'no_sales_area') {
          url = `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract=无销售面积&contract_id=${id}&status=update`
        } else {
          url = `/admin/locations/${location_id}/organizations/${organization_id}/subscriptions/new?contract_id=${id}&status=update`
        }

        routerActions.push(url)
      }
    })
  },

  batchSaveCarPlates(subscription) {
    const { updateSubscriptionsAction } = this.props
    const { location_id, id } = subscription

    KBPopoverConfirm({
      name: '收录',
      context: '是否确定收录?',
      callback: () => {
        return api
          .putContractsSaveCarPlates({
            location_id,
            subscription_ids: String(id),
            car_plates_saved: true
          })
          .then(
            json => {
              updateSubscriptionsAction.success(json)
              KBPopover.close()
            },
            error => {
              updateSubscriptionsAction.failure(error)
              KBPopover.close()
            }
          )
      }
    })
  },

  addInvoice() {
    const { org, loc_id, subscription } = this.props
    KBPopover.close()

    KBPopover.show(
      <AddInvoiceForm
        contract_id={subscription.id}
        org={org}
        location_id={loc_id}
        option="generate"
        initialValues={{
          option: 'generate',
          sales_tax_id: '',
          due_date: moment().format('YYYY-MM-DD'),
          line_items: [{ units: 1, unit_price: 0, description: '' }]
        }}
      />
    )
  },

  toPrevPage() {
    const { routerActions } = this.props

    routerActions.goBack()
  },

  render() {
    const {
      subscription,
      isInvoice,
      user,
      org,
      isOnlyContract,
      connect,
      isApproveContract,
      isSales,
      isFromAreasForm,
      loc_id,
      isAbnormalContract
    } = this.props
    const { isParkingContract } = this.props
    const stateText = this.subscriptionStateText(subscription)
    const stateType = ORG_SUBSCRIPTION_STATES
    const stateType_class = ORG_SUBSCRIPTION_STATES_CLASS_NEW
    let isHaveNot = false
    let create_by = subscription && subscription.created_by
    let isCreator = create_by === (user && user.id) ? true : false
    let isOperateContract =
      connect &&
      canAccessLocationExceptObserver(user) &&
      subscription &&
      (subscription.status == 'active' || subscription.status == 'expiring') &&
      !subscription.terminate_at
    let isEndedContract =
      connect &&
      (canAccessLocationExceptObserver(user) || isCreator) &&
      subscription &&
      subscription.status != 'cancelled' &&
      subscription.status != 'expiring' &&
      subscription.status != 'ended' &&
      !subscription.terminate_at
    if (subscription) {
      isHaveNot =
        subscription.status == 'active' &&
        moment(subscription.start_date).isAfter(new Date())
          ? true
          : false
      if (subscription.status == 'approving' && subscription.step == 'detail') {
        subscription.status = 'waiting_approving'
      } else if (
        subscription.status == 'approving' &&
        subscription.step == 'audit'
      ) {
        subscription.status = 'already_approving'
      }
    }
    const isNoSalesArea =
      subscription && subscription.subscription_type === 'no_sales_area'
    return (
      <div
        className={isFromAreasForm ? '' : 'nav-section-container f-right'}
        style={isFromAreasForm ? { height: '100%' } : {}}
      >
        <KBLoadingContainer loading={!subscription}>
          <header className="kb-form-header">
            <h2 className="header-title clear-fix">
              <span className="f-left">合同信息</span>
              {subscription && (
                <span
                  className={`finance-default ${
                    stateType_class[
                      subscription.status == 'expiring'
                        ? 'active'
                        : subscription.status
                    ]
                  }`}
                  style={{ marginTop: 3, minWidth: 64, textAlign: 'center' }}
                >
                  {
                    stateType[
                      subscription.status == 'expiring'
                        ? 'active'
                        : subscription.status
                    ]
                  }
                </span>
              )}
              {isParkingContract &&
              subscription &&
              subscription.car_plates_saved ? (
                <span
                  className="span-type-smail-i m-left-sm"
                  style={{ display: 'inline-block' }}
                >
                  已收录
                </span>
              ) : null}
            </h2>
            {isEndedContract ? (
              <button
                className="f-right bordered-btn-end"
                style={{ margin: '22px 30px 0 0' }}
                onClick={this.terminate}
              >
                终止合同
              </button>
            ) : (
              ''
            )}

            {!isFromAreasForm && isOperateContract && !isNoSalesArea ? (
              <button
                className="f-right bordered-btn"
                style={{ margin: '22px 18px 0 0' }}
                onClick={this.toRenewContract.bind(null, subscription)}
              >
                续约合同
              </button>
            ) : (
              ''
            )}

            {/* {!isFromAreasForm && isOperateContract && !isNoSalesArea ? (
              <button
                className="f-right bordered-btn"
                style={{ margin: '22px 18px 0 0' }}
                onClick={this.toAnewContract.bind(null, subscription)}
              >
                重新录入
              </button>
            ) : (
              ''
            )} */}
            {!isFromAreasForm &&
            (isOperateContract ||
              (subscription && subscription.status == 'rejected')) &&
            !isParkingContract &&
            subscription &&
            (subscription.status == 'cancelled' ||
              subscription.status == 'ended' ||
              subscription.status == 'approving' ||
              subscription.status == 'rejected' ||
              subscription.status == 'draft' ||
              subscription.status == 'waiting_approving' ||
              subscription.status == 'already_approving') ? (
              <button
                className="f-right bordered-btn"
                style={{ margin: '22px 18px 0 0' }}
                onClick={this.updateContract.bind(null, subscription)}
              >
                修改
              </button>
            ) : (
              ''
            )}
            {!isFromAreasForm &&
            connect &&
            (canAccessLocationRoles(user, loc_id, [
              'location_manager',
              'location_operator'
            ]) || isSales || isCreator) &&
            subscription &&
            !subscription.formal &&
            (subscription.status == 'approving' ||
              subscription.status == 'draft' ||
              subscription.status == 'cancelled') ? (
              <button
                className="f-right bordered-btn"
                style={{ margin: '22px 18px 0 0' }}
                onClick={this.editSubscription.bind(null, subscription)}
              >
                编辑合同
              </button>
            ) : (
              ''
            )}

            {!isFromAreasForm &&
            ((connect &&
              (canAccessLocationContract(user) || isSales || isCreator) &&
              subscription &&
              (subscription.status == 'approving' ||
                subscription.status == 'rejected') &&
              subscription.step == 'detail') ||
              (connect &&
                subscription &&
                (subscription.status == 'approving' ||
                  subscription.status == 'rejected') &&
                (isApproveContract||isAbnormalContract)) ||
              (connect &&
                subscription &&
                subscription.status != 'active' &&
                subscription.status != 'approving' &&
                subscription.status != 'rejected' &&
                subscription.status != 'expiring' &&
                subscription.status != 'ended' &&
                (canAccessLocationContract(user) || isCreator))) ? (
              <button
                className="f-right bordered-btn"
                style={{ margin: '22px 18px 0 0' }}
                onClick={this.openAudit.bind(null, subscription)}
              >
                进入合同流程
              </button>
            ) : (
              ''
            )}
            {isParkingContract && canAdminFinance(user,loc_id) &&
            subscription &&
            !subscription.car_plates_saved ? (
              <button
                className="f-right bordered-btn"
                style={{ margin: '22px 18px 0 0' }}
                onClick={this.batchSaveCarPlates.bind(null, subscription)}
              >
                收录
              </button>
            ) : (
              ''
            )}
            {!isFromAreasForm && isOperateContract && !isNoSalesArea ? (
              <button
                className="f-right bordered-btn"
                style={{ margin: '22px 18px 0 0' }}
                onClick={this.addInvoice}
              >
                添加账单
              </button>
            ) : (
              ''
            )}
          </header>
          {isHaveNot && !stateText ? (
            <div style={{ padding: '15px 30px', backgroundColor: '#ededed' }}>
              <img
                src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-havenot.png"
                alt="icon"
                style={{ marginRight: 5, width: 20, verticalAlign: 'middle' }}
              />
              <span style={{ color: '#2ea1f8' }}>
                未开始(还有
                {moment(formatYearDayEN(subscription.start_date)).diff(
                  moment().format('YYYY-MM-DD'),
                  'days'
                )}
                天开始)
              </span>
            </div>
          ) : (
            ''
          )}
          {subscription && subscription.status == 'expiring' ? (
            <div style={{ padding: '15px 30px', backgroundColor: '#FFF7DD' }}>
              <img
                src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/icon-expring_info.png"
                alt="icon"
                style={{ marginRight: 5, width: 20, verticalAlign: 'middle' }}
              />
              <span style={{ color: '#F79800' }}>
                快到期(还有
                {moment(formatYearDayEN(subscription.end_date)).diff(
                  moment().format('YYYY-MM-DD'),
                  'days'
                )}
                天到期)
              </span>
            </div>
          ) : (
            ''
          )}
          {stateText ? (
            <div style={{ padding: '15px 30px', backgroundColor: '#fcebeb' }}>
              <img
                src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/mirro@2x.png"
                alt="icon"
                style={{
                  marginRight: 5,
                  width: 20,
                  verticalAlign: 'middle',
                  transform: 'translateY(-2px)'
                }}
              />
              <span style={{ color: '#e13b3f' }}>{stateText}</span>
            </div>
          ) : (
            ''
          )}
          <div
            className="kb-form-container"
            style={
              this.props.isFromAreasForm
                ? {
                    width: '100%',
                    maxHeight: 'calc(100% - 66px)',
                    overflow: 'auto'
                  }
                : {}
            }
          >
            {org && subscription && this.subJsx()}
            {subscription && this.providerJsx()}
            {org &&
              subscription &&
              (isParkingContract ? this.renterInfoJsx() : this.orgJsx())}
            {subscription && this.invoiceTitleJsx()}
            {subscription && this.serialJsx()}
            {subscription && this.timeJsx()}
            {subscription && !isNoSalesArea && this.taxJsx()}
            {subscription &&
              subscription.sales_areas &&
              (isParkingContract
                ? this.parkingSpacesJsx()
                : !isNoSalesArea && this.areasJsx())}
            {subscription && this.salesResourcesJsx()}
            {subscription && !isNoSalesArea && this.depositJsx()}
            {subscription && this.phasesJsx()}
            {subscription && this.parentSubJsx()}
            {subscription && this.descendantSubJsx()}
            {subscription && this.crmSourceJsx()}
            {subscription && this.nodeJsx()}
            {!isFromAreasForm &&
            canAccessLocationFinance(user) &&
            !isInvoice ? (
              <div className="kb-row m-top-lg">
                <div className="kb-form-fr">
                  <KBButton
                    onClick={this.toPrevPage}
                    className="certain-btn"
                    style={{ marginRight: 0 }}
                  >
                    返回
                  </KBButton>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </KBLoadingContainer>
      </div>
    )
  }
})

function mapStateToProps(state, props) {
  let loc_id = props.params.id
  let subscription_id = props.params.subscription_id
  let subscriptions = selectors.getEntities(state, 'subscriptions')
  let subscription = selectors.getEntity(
    state,
    'subscriptions',
    subscription_id
  )
  let org_id =
    props.params.organization_id ||
    (subscription && subscription.organization_id)
  let isInvoice = props.params.invoice_id
  let location = selectors.getLocation(state, loc_id)
  let org = selectors.getOrg(state, org_id)
  let leaders = selectors.getLeadersOfOrg(state, org_id)

  let user = selectors.getCurrentUser(state)
  let userRoles = getLSRoles(user)
  let isSales = canAccessLocationRoles(user, loc_id, ['sales'])
  let operateContract = canAccessSpaceRoles(user, ['invoices'])
  let isOnlyContract =
    _.intersection(userRoles, ['contract']).length == 1 && !operateContract
      ? true
      : false
  const historyIndex = props.location.query.historyIndex
  subscription =
    (historyIndex &&
      subscription &&
      subscription.histories &&
      subscription.histories.length &&
      subscription.histories[historyIndex]) ||
    subscription
  let isApproveContract = canAccessSpaceRoles(user, [
    // 'admin',
    'contract',
    'location_contract'
  ])

  //低价合同审批人
  let isAbnormalContract = canAccessLocationRoles(user,loc_id,['abnormal_contract'])
  let connect = org && org.connect
  let spaceUsers = state.entities.users
  let usersObj = arrayToObject(selectors.getSpaceUsers(state) || [])

  let typeSource = props.location.query.typeSource || null
  let isCrmContract = typeSource && typeSource == 'crmrecord'
  let virtual_currency_name = selectors.getSpaceVirtualCurrencyName(state)

  return {
    loc_id,
    user,
    org_id,
    isInvoice,
    location,
    subscription,
    org,
    subscription_id,
    subscriptions,
    leaders,
    isOnlyContract,
    connect,
    isApproveContract,
    spaceUsers,
    isCrmContract,
    usersObj,
    virtual_currency_name,
    isSales,
    isAbnormalContract
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSubscriptionAction: bindActionCreators(
      subscriptionsAction.get,
      dispatch
    ),
    updateSubscriptionsAction: bindActionCreators(
      subscriptionsAction.update,
      dispatch
    ),
    routerActions: bindActionCreators(routerActions, dispatch),
    getOrgAction: bindActionCreators(orgActions.get, dispatch),
    successAction: bindActionCreators(successState, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSubscriptionDetail)
