import React, { Component, PropTypes } from 'react'
import { routerActions } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import { api } from 'app/services'
import { connect } from 'react-redux'
import { lockGroupsAction, apiCallFailure, successState } from 'app/actions'
import * as selectors from 'app/selectors'
import {
  KBPopover,
  KBPagination,
  KBLoadingContainer,
  KBNoData
} from 'components'
import * as util from 'utils/kbUtil'
import { canAccessLocation } from 'app/reducers/role'
import LockGroupForm from './LockGroupForm'
import LockGroupBlock from './LockGroupBlock'

/**
 * 门禁组列表
 * @type {[type]}
 */

var LockGroups = React.createClass({
  displayName: 'LockGroups',

  getInitialState() {
    return {
      locks: [],
      pagination: null,
      loading: false
    }
  },

  componentDidMount() {
    const { page, locks } = this.props

    this._loadData(page)
  },

  componentWillReceiveProps(nextProps) {
    const { page, locks } = nextProps
    if (page != this.props.page) {
      this._loadData(page)
    }
  },

  _loadData(page) {
    const { loc_id, getLockGroupsAction } = this.props
    this.setState({ loading: true })
    api
      .getLockGroups({ location_id: loc_id, per_page: 10, page })
      .then(
        json => {
          getLockGroupsAction.success(json)
          let pagination = util.paginate(json.response)

          this.setState({
            pagination
          })
        },
        errors => {
          getLockGroupsAction.failure(errors)
        }
      )
      .finally(() => {
        this.setState({
          loading: false
        })
      })
  },

  createLockGroup() {
    const { loc_id, locks } = this.props
    let filterLocks = locks.filter(lock => lock.location_id == loc_id)
    KBPopover.show(<LockGroupForm loc_id={loc_id} locks={filterLocks} />)
  },

  render() {
    const { loc_id, lockGroups, locks, isManager } = this.props
    let { pagination, loading } = this.state

    return (
      <section className="nav-section-container  f-right">
        <header className="nav-header clear-fix">
          <h2 className="nav-section-header-title f-left">
            <span>门禁组</span>
          </h2>
          {isManager ? (
            <div className="f-right">
              <button className="bordered-btn" onClick={this.createLockGroup}>
                添加门禁组
              </button>
            </div>
          ) : null}
        </header>
        <div className="nav-section-content-container">
          <KBLoadingContainer loading={loading} style={{ marginTop: 30 }}>
            {lockGroups.length > 0 ? (
              lockGroups.map(lock_g => {
                return (
                  <LockGroupBlock
                    loc_id={loc_id}
                    lock_group={lock_g}
                    locks={locks}
                  />
                )
              })
            ) : (
              <KBNoData tipMessage="暂无门禁组" />
            )}
          </KBLoadingContainer>
          <KBPagination
            pagination={pagination}
            template={`/admin/locations/${loc_id}/locks/lock_info/lock_group?page=#PAGE#`}
          />
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  const { user } = state
  let loc_id = params.id
  let page = parseInt(location.query && location.query.page, 10)
  page = isNaN(page) ? 1 : page

  const lockGroups = selectors.getLockGroups(state)
  const locks = selectors.getLocks(state)
  let isManager = canAccessLocation(user, loc_id)

  return {
    loc_id,
    page,
    locks,
    lockGroups,
    isManager
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLockGroupsAction: bindActionCreators(lockGroupsAction.replace, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch),
    apiCallFailureActions: bindActionCreators(apiCallFailure, dispatch),
    successActions: bindActionCreators(successState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockGroups)
