import React, { Component, PropTypes } from 'react'
import {
  KBDropdown,
  KBRangeDate,
  KBTableWithoutData,
  KBLoadingContainer,
  KBPagination
} from 'components'
import { bindActionCreators } from 'redux'
import { api, apiUtils } from 'app/services'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { locksActions, lockEventsActions } from 'app/actions'
import * as selectors from 'app/selectors'
import {
  paginate,
  arrayToObject,
  queryFilter,
  getFilterAndQuery,
  formatOnlyMinuteSecondEN,
  formatYearDayEN
} from 'utils/kbUtil'
import { getInitialDate } from 'utils/kbData'
import { LOCK_ACTION_TYPE } from 'app/constants'
import moment from 'moment'
import { getUserInfoUrl } from 'utils/kbUrl'

const FILTER_STATE = ['from', 'to', 'lock_id']

const LOCK_SOURCE = {
  android: 'Android',
  ios: 'iOS'
}

var MembersLocksRecord = React.createClass({
  displayName: 'MembersLocksRecord',
  getInitialState() {
    const { filters } = this.props
    return {
      lockEvents: [],
      filters,
      pagination: null,
      loading: false
    }
  },

  componentDidMount() {
    const { filters, page } = this.props
    this.mounted = true
    this._loadData(page, filters)
  },

  componentWillReceiveProps(nextProps) {
    const { filters } = nextProps
    if (this.props.page !== nextProps.page) {
      this._loadData(nextProps.page, { ...this.state.filters, ...filters })
    }
  },

  componentWillUnmount() {
    this.mounted = false
  },

  _loadData(page, filters) {
    const {
      getLockEventsActions,
      member,
      routerActions,
      query,
      isMemberShipPath
    } = this.props
    const { from, to } = filters

    const per_page = 10
    let pageObj = { per_page, page }

    let params = Object.assign({}, filters, pageObj)

    if (from && to) {
      params.from = from && getInitialDate(from).initStartDateString
      params.to = to && getInitialDate(to).initEndDateString
    } else {
      delete params.from
      delete params.to
    }

    let queryFiltersStr = queryFilter({ ...query, ...params })
    this.setState({ loading: true })

    api
      .getLockEvents({ user_id: member.id, ...params })
      .then(
        json => {
          const lock_events = json.json
          getLockEventsActions.success({
            entities: { lockEvents: arrayToObject(lock_events) }
          })

          let lockEvents = []
          lockEvents = lock_events
          // 防止用户点击过快时路由来回跳转
          if (!this.mounted) {
            return
          }
          routerActions.replace(
            `${getUserInfoUrl(
              member.id,
              isMemberShipPath
            )}/locks${queryFiltersStr}`
          )
          let pagination = paginate(json.response)
          this.setState({
            lockEvents,
            pagination
          })
          return true
        },
        errors => {
          getLockEventsActions.success(errors)
        }
      )
      .finally(() => {
        this.setState({ loading: false })
      })
  },

  _setFilters(key, value, obj) {
    let newFilters = Object.assign(
      {},
      this.state.filters,
      obj ? obj : { [key]: value }
    )
    this.setState({
      filters: newFilters
    })
    this._refresh(key, newFilters)
  },

  _refresh(key, filters) {
    let dropDown = key + 'DropDown'
    this.refs[dropDown] && this.refs[dropDown].hide()
    setTimeout(this._loadData(filters.page || 1, filters))
  },

  _clearFilters() {
    this.setState({
      filters: {}
    })
    this._refresh(null, {})
  },

  changeDate(range) {
    this._setFilters(null, null, range)
  },

  _renderFilters() {
    const { locks, lockArray } = this.props
    const { filters } = this.state
    const { from, to } = filters

    return (
      <div className="nav-section-action-bar">
        {
          <KBRangeDate
            from={from || ''}
            to={to || ''}
            leftNone={true}
            callback={this.changeDate}
          />
        }
        {
          <KBDropdown ref="lock_idDropDown" style={{ marginLeft: 30 }}>
            <KBDropdown.KBDropdownTrigger>
              <div className="task-status-title">
                <i className="iconfont icon-position" />
                <span>
                  {lockArray && filters.lock_id
                    ? lockArray[filters.lock_id].name
                    : '选择门禁'}
                </span>
                <span className="task-deg" />
              </div>
            </KBDropdown.KBDropdownTrigger>
            <KBDropdown.KBDropdownContent>
              <div>
                <ul className="task-type">
                  <li onClick={() => this._setFilters('lock_id', '')}>全部</li>
                  {locks &&
                    locks.map((lock, index) => (
                      <li
                        key={index}
                        onClick={() => this._setFilters('lock_id', lock.id)}
                      >
                        <span>{lock.name}</span>
                      </li>
                    ))}
                </ul>
              </div>
            </KBDropdown.KBDropdownContent>
          </KBDropdown>
        }
      </div>
    )
  },

  render() {
    const { member } = this.props
    const { lockEvents, pagination, loading } = this.state
    let selectFilters = Object.values(this.state.filters)
    selectFilters =
      selectFilters && selectFilters.filter(filter => filter != '')
    return (
      <section className="nav-section-container f-right">
        <div style={{ height: '100%' }}>
          <header className="nav-header clear-fix">
            <div className="nav-header-title">
              <h2 className="header-title f-left" style={{ fontSize: 22 }}>
                门禁记录
              </h2>
            </div>
          </header>
          <div className="nav-section-content-container">
            <div className="m-top">
              {this._renderFilters()}
              {selectFilters.length > 0 ? (
                <div
                  className="clear-criteria"
                  style={{ marginTop: 5 }}
                  onClick={this._clearFilters}
                >
                  <i className="iconfont icon-close" />
                  <span>清除所有筛选条件</span>
                </div>
              ) : null}
            </div>
            <KBLoadingContainer loading={loading}>
              <table className="content-table">
                <thead>
                  <tr>
                    <th>行为</th>
                    <th>门禁名称</th>
                    <th>开门设备</th>
                    <th>开门方式</th>
                    <th>所在区域</th>
                    <th>日期</th>
                    <th>时间</th>
                  </tr>
                </thead>
                <KBTableWithoutData
                  hasData={lockEvents && lockEvents.length > 0}
                  tableHeadNum="7"
                  tipMessage="暂无数据"
                >
                  {lockEvents &&
                    lockEvents.map((lock, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {lock.action == 'open_door' ? (
                              <div
                                className="lock-action"
                                style={{ backgroundColor: '#73bb4b' }}
                              >
                                <i className="iconfont icon-open" />
                              </div>
                            ) : (
                              <div
                                className="lock-action"
                                style={{ backgroundColor: '#dd5a55' }}
                              >
                                <i className="iconfont icon-open" />
                              </div>
                            )}
                          </td>
                          <td>{lock.lock.name}</td>
                          <td>{LOCK_SOURCE[lock.source]}</td>
                          <td>{LOCK_ACTION_TYPE[lock.action_type]}</td>
                          <td>{lock.area ? lock.area.name : '无'}</td>
                          <td>{formatYearDayEN(lock.created_at)}</td>
                          <td>{formatOnlyMinuteSecondEN(lock.created_at)}</td>
                        </tr>
                      )
                    })}
                </KBTableWithoutData>
              </table>
              <KBPagination
                pagination={pagination}
                template={`${getUserInfoUrl(member.id)}/locks?page=#PAGE#`}
              />
            </KBLoadingContainer>
          </div>
        </div>
      </section>
    )
  }
})

function mapStateToProps(state, props) {
  const { params, location } = props
  let loc_id = params.id
  let orgId = params.organization_id
  let lockArray = state.entities.locks
  let locks = selectors.getLocks(state)
  let sections = location.pathname.split('/')
  let isMemberShipPath =
    sections[2] === 'memberships' && sections[3] === 'memberManage'

  let page = parseInt(location.query && location.query.page)
  page = isNaN(page) ? 1 : page

  let paramsAll = getFilterAndQuery(location.query, FILTER_STATE)

  return {
    loc_id,
    orgId,
    lockArray,
    locks,
    isMemberShipPath,
    page,
    ...paramsAll
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getLockEventsActions: bindActionCreators(lockEventsActions.all, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersLocksRecord)
