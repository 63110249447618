import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { KBButton, KBPopover, KBPopoverTop } from 'components'
import InvoiceTitleForm from './createOrganization/InvoiceTitleForm'
import { KBNoData } from 'components'

var SelectInvoiceTitleForm = React.createClass({
  getInitialState() {
    const { defaultInvoiceTitle, invoiceTitles } = this.props
    return {
      selectedTitle: defaultInvoiceTitle || {},
      invoiceTitles
    }
  },

  certainSelectInvoiceTitle() {
    const { callBack } = this.props
    const { selectedTitle } = this.state
    callBack && callBack(selectedTitle)
    KBPopover.close()
  },

  selectTitle(title) {
    this.setState({
      selectedTitle: title
    })
  },

  addNewInvoiceTitle() {
    const { location, organization } = this.props
    let location_id = location && location.id
    let sales_customer_id =
      organization &&
      organization.sales_customer &&
      organization.sales_customer.id
    KBPopoverTop.show(
      <InvoiceTitleForm
        callBack={this.pushNewTitle}
        isContract={true}
        location_id={location_id}
        sales_customer_id={sales_customer_id}
      />
    )
  },

  pushNewTitle(title) {
    if (!title) {
      return null
    }
    const { invoiceTitles } = this.state
    const { updateInvoiceTitles } = this.props
    let newInvoiceTitles = Object.assign([], invoiceTitles)
    newInvoiceTitles.push(title)
    updateInvoiceTitles(newInvoiceTitles)
    this.setState({
      invoiceTitles: newInvoiceTitles,
      selectedTitle: title
    })
  },

  render() {
    const { selectedTitle, invoiceTitles } = this.state

    return (
      <div>
        <header className="kb-form-header">
          <h2 className="header-title">选择发票抬头</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container">
          {invoiceTitles.length > 0 ? (
            <div className="kb-row">
              <button
                className="certain-btn"
                style={{ marginLeft: 26 }}
                onClick={this.addNewInvoiceTitle}
              >
                添加新发票抬头
              </button>
            </div>
          ) : null}
          {(invoiceTitles.length > 0 &&
            invoiceTitles.map((title, index) => {
              const {
                tax_num,
                bank,
                bank_account,
                contacts_name,
                phone_num,
                address,
                email
              } = title
              return (
                <div className="kb-row">
                  <div
                    key={index}
                    className={`provider-item ${
                      selectedTitle.id == title.id
                        ? 'provider-item_active'
                        : null
                    }`}
                    onClick={this.selectTitle.bind(null, title)}
                  >
                    <input
                      type="radio"
                      id={`providerRadio${index}`}
                      name="radio"
                      checked={selectedTitle.id == title.id ? 'checked' : null}
                    />
                    <div className="location-provider">
                      <div className="provider-line">
                        <div>
                          <span>发票类别</span>
                          <span>
                            {title.invoice_type
                              ? title.invoice_type == 'common'
                                ? '增值税普通发票'
                                : '增值税专用发票'
                              : '暂无'}
                          </span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>发票抬头</span>
                          <span>{title.title || '暂无'}</span>
                        </div>
                        <div>
                          <span>纳税人识别号</span>
                          <span>{tax_num || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>开户行</span>
                          <span>{bank || '暂无'}</span>
                        </div>
                        <div>
                          <span>银行账号</span>
                          <span>{bank_account || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>联系人</span>
                          <span>{contacts_name || '暂无'}</span>
                        </div>
                        <div>
                          <span>联系电话</span>
                          <span>{phone_num || '暂无'}</span>
                        </div>
                      </div>
                      <div className="provider-line">
                        <div>
                          <span>地址</span>
                          <span>{address || '暂无'}</span>
                        </div>
                        <div>
                          <span>邮箱</span>
                          <span>{email || '暂无'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })) || (
            <div className="t-center" style={{ marginTop: 50, color: '#999' }}>
              <img
                src={'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/que.png'}
                style={{ width: 150 }}
                alt=""
              />
              <div style={{ marginTop: '15px' }}>暂无发票抬头信息</div>
              <button
                className="certain-btn m-top m-bottom"
                onClick={this.addNewInvoiceTitle}
              >
                点击添加
              </button>
            </div>
          )}
          {invoiceTitles.length > 0 ? (
            <div className="kb-row fixed-form-btn">
              <span className="cancel-btn" onClick={KBPopover.close}>
                取消
              </span>
              <button
                className="certain-btn"
                type="button"
                onClick={this.certainSelectInvoiceTitle}
              >
                确定
              </button>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
})

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectInvoiceTitleForm)
