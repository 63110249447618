import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import { api } from 'app/services'
import { orgActions, successState } from 'app/actions'
import { KBPopover, KBFormError, KBButton, KBDateInput } from 'components'
import moment from 'moment'

var OrgDirectlyEnterForm = React.createClass({
  enterOrg(model) {
    // const { location_id, org_id, org, successAction, updateOrganizationsAction } = this.props
    // model.location_id = location_id
    // if (!model.deskNum) {
    //   delete model.deskNum
    // } else {
    //   model.deskNum = moment(model.deskNum).format('YYYY-MM-DD HH:mm:ss')
    // }
    // return api.enterOrgDirectly(org_id, { ...model }).then(json => {
    //   successAction({ message: `恭喜，公司 ${org.name} 入驻成功!` })
    //   updateOrganizationsAction.success(json, { location_id, id: org_id })
    //   KBPopover.close()
    // }, error => {
    //   updateOrganizationsAction.failure(error)
    //   KBPopover.close()
    // })
  },

  render() {
    const {
      fields: { deskNum },
      submitting,
      handleSubmit,
      org
    } = this.props

    return (
      <form onSubmit={handleSubmit(this.enterOrg)}>
        <KBFormError error={this.props.error} />
        <header className="kb-form-header">
          <h2 className="header-title">工位数量</h2>
          <i
            className="iconfont header-close icon-cancel"
            onClick={KBPopover.close}
          />
        </header>
        <div className="kb-form-container-m">
          <div className="kb-row">
            <input
              className="kb-input input-num"
              style={{ backgroundColor: '#fff' }}
              field={deskNum}
              //  {...desk.units} onChange={(event) => { calculationDeposit(event, desk.units); this.unitsChange(event) }}
            />
          </div>
          <div className="kb-row fixed-form-btn">
            <span className="cancel-btn" onClick={KBPopover.close}>
              取消
            </span>
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              确定
            </KBButton>
          </div>
        </div>
      </form>
    )
  }
})

function mapStateToProps(state, props) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    successAction: bindActionCreators(successState, dispatch),
    updateOrganizationsAction: bindActionCreators(orgActions.update, dispatch)
  }
}

const validate = values => {
  const errors = {}

  return errors
}

const formConfig = {
  form: 'OrgDirectlyEnterForm',
  fields: ['deskNum'],
  validate: validate,
  touchOnBlur: false
}

OrgDirectlyEnterForm = reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgDirectlyEnterForm)

export default OrgDirectlyEnterForm
