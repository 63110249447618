import React, { Component, PropTypes } from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { api, apiUtils } from 'app/services'
import {
  subscriptionActions,
  successState,
  apiCallFailure,
  orgActions
} from 'app/actions'
import { Modal, Button } from 'antd'
import {
  KBTextarea,
  KBFormError,
  KBMoreFilesUpload,
  KBButton,
  KBPopover
} from 'components'
import { KBPopoverConfirm } from 'components/tools'
import { getEntitiesJsonArray } from 'app/reducers/reducer_utils'
import * as utils from 'utils/kbUtil'
import * as selectors from 'app/selectors'
import {
  canAccessSpaceContract,
  canAccessLocationRoles
} from 'app/reducers/role'
import moment from 'moment'
import { Link } from 'react-router'
import SubscriptioApprovalRejectForm from './SubscriptioApprovalRejectForm'
import config from 'app/config'
import KBDownloadPrivacy from 'components/v2/KBDownloadPrivacy'

const SubscriptionApprovalForm = React.createClass({
  displayName: 'SubscriptionApprovalForm',
  getInitialState() {
    const { currentSubscription } = this.props
    return {
      isExportTemplete: false,
      templete_id: currentSubscription.templete_id || -1,
      isFile:
        currentSubscription.files && currentSubscription.files.length
          ? true
          : false,
      approvalLoading: false,
      approvalModal: false,
      id: ''
    }
  },

  componentDidMount() {
    const { currentOrganization, org_id, loc_id } = this.props
    if (!currentOrganization.id) {
      this.getOrgInfo(loc_id, org_id)
    }
  },

  getOrgInfo(loc_id, org_id) {
    api
      .getOrganizationInfo(org_id, {
        location_id: loc_id
      })
      .then(
        json => {
          this.props.getOrgAction.success(json, {
            organization_id: org_id,
            location_id: loc_id
          })
        },
        errors => this.props.getOrgAction.failure(errors)
      )
  },

  updateSubscriptionForm(model, type) {
    const {
      currentSubscription,
      updateSubscriptionActions,
      step,
      nextCallBack
    } = this.props
    const { templete_id } = this.state
    const { files } = model

    if (type != 'note') {
      model.files = files.map(json => {
        let newFile = { ...json }
        newFile.attachment =
          Boolean(newFile.attachment) || newFile.type == 'source'
        return newFile
      })
    }
    if (templete_id && templete_id != -1) {
      model.templete_id = templete_id
    } else {
      model.templete_id = null
    }
    return api
      .putSubscriptions(currentSubscription.id, {
        ...model,
        step
      })
      .then(json => {
        updateSubscriptionActions.success(json)
        let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
        nextCallBack && nextCallBack(subscription)
        return null
      })
  },
  isWaitAudit() {
    const { currentSubscription } = this.props
    if (
      (currentSubscription.status == 'approving' ||
        currentSubscription.status == 'rejected') &&
      currentSubscription.step == 'audit'
    ) {
      return true
    }
    return false
  },
  cancelFiles(fileIndex) {
    this.refs.files.removeFile(fileIndex)
  },
  setSubscriptionSuccess() {
    const {
      fields: { note },
      updateSubscriptionActions,
      successAction,
      nextCallBack
    } = this.props
    const { id } = this.state
    this.setState({ approvalLoading: true }, () => {
      this.updateSubscriptionForm({ note: note.value || '' }, 'note')
      return api
        .updateSubScriptionApprove(id, {
          pass: true,
          step: 'auditSuccess'
        })
        .then(
          json => {
            successAction({ message: '合同审批通过操作成功' })
            updateSubscriptionActions.success(json)
            let subscription = getEntitiesJsonArray(json, 'subscriptions') || []
            nextCallBack && nextCallBack(subscription)
            this.setState({ approvalLoading: false, approvalModal: false })
          },
          errors => {
            updateSubscriptionActions.failure(errors)
          }
        )
    })
  },

  setSubscriptionFailure(id) {
    const {
      fields: { note },
      nextCallBack
    } = this.props

    let notes = {
      value: note.value,
      type: 'note'
    }
    KBPopover.show(
      <SubscriptioApprovalRejectForm
        id={id}
        callback={this.updateSubscriptionForm}
        note={notes}
        nextCallBack={nextCallBack}
      />
    )
  },
  contextTypes: {
    user: PropTypes.object,
    previewSubscription: PropTypes.func,
    isDraft: PropTypes.func
  },
  previewSubscription() {
    const { previewSubscription } = this.context
    const { templete_id } = this.state
    this.setState({
      isExportTemplete: true
    })
    previewSubscription(templete_id).then(json => {
      this.setState({
        isExportTemplete: false
      })
    })
  },
  isDetail(subscription) {
    if (
      (subscription.status == 'approving' ||
        subscription.status == 'rejected') &&
      (subscription.step == 'detail' || subscription.step == '')
    ) {
      return true
    }
    return false
  },

  subJsx() {
    const {
      currentSubscription: {
        sales_areas,
        sales_renting_phases,
        deposit,
        serial,
        start_date,
        end_date,
        renting_type_list
      },
      currentOrganization,
      isParkingContract,
      typeSource,
      loc_id,
      isInvoice,
      isContract
    } = this.props

    const private_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'private_office')
      : []
    const public_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'public_office')
      : []
    const flexible_areas = sales_areas
      ? sales_areas.filter(area => area.area_type == 'flexible_office')
      : []

    let private_areas_array = []
    private_areas &&
      private_areas.map(area => {
        let str = `${(area.area && area.area.name) || ''}(${
          area.charge_type === 'by_desk'
            ? area.units + '个'
            : (area.m2 || '') + '㎡'
        })`
        private_areas_array.push(str)
      })
    let public_areas_array = []
    public_areas &&
      public_areas.map(area => {
        let str = `${(area.area && area.area.name) || ''}(${area.units}个)`
        public_areas_array.push(str)
      })
    let flexible_areas_array = []
    flexible_areas &&
      flexible_areas.map(area => {
        let str = `${area.units}个`
        flexible_areas_array.push(str)
      })

    let subTotalAmount = deposit || 0
    let subRentAmount = 0
    sales_renting_phases &&
      sales_renting_phases.length &&
      sales_renting_phases.map((phase, index) => {
        subRentAmount += phase.total_amount
      })
    subTotalAmount += subRentAmount
    return (
      <fieldset className="fieldset m-top-sm">
        <div className="kb-row kb-form-66">
          <div className={'kb-form-group kb-div-210'}>
            <label>合同编号</label>
            <div className="group-content">
              <span>{serial || '暂无'}</span>
            </div>
          </div>
          <div className="kb-form-group kb-div-173">
            <label className="label-rt">合同时长</label>
            <div className="group-content">
              <span>
                {utils.formatYearDayCHS(start_date) +
                  ' - ' +
                  utils.formatYearDayCHS(end_date)}
              </span>
            </div>
          </div>
        </div>
        <div className="kb-row kb-form-66">
          <div className={'kb-form-group kb-div-210'}>
            <label>{isParkingContract ? '承租方名称' : '公司名称'}</label>
            <div className="group-content">
              {isInvoice ||
              !loc_id ||
              (typeSource && typeSource == 'crmrecord') ? (
                <span>{currentOrganization.full_name}</span>
              ) : (
                <span>
                  <Link
                    className="color-link"
                    to={`/admin/locations/${loc_id}/organizations/${currentOrganization.id}`}
                  >
                    {currentOrganization.full_name}
                  </Link>
                </span>
              )}
            </div>
          </div>
          {isParkingContract ? (
            <div className="kb-form-group kb-div-173">
              <label className="label-rt">租赁类型</label>
              <div className="group-content">
                <span>
                  {renting_type_list.length > 0
                    ? renting_type_list.join(',')
                    : '暂无'}
                </span>
              </div>
            </div>
          ) : null}
        </div>
        {isParkingContract || isContract ? null : (
          <div className="kb-row kb-form-66">
            <div className="kb-form-group kb-div-210">
              <label>封闭工位</label>
              <div className="group-content">
                <span>
                  {(private_areas_array &&
                    private_areas_array.length &&
                    private_areas_array.join('、')) ||
                    '暂无'}
                </span>
              </div>
            </div>
            <div className="kb-form-group kb-div-66">
              <div className="kb-form-group kb-div-173">
                <label className="label-rt">开放工位</label>
                <div className="group-content">
                  <span>
                    {(public_areas_array &&
                      public_areas_array.length &&
                      public_areas_array.join('、')) ||
                      '暂无'}
                  </span>
                </div>
              </div>
              <div className="kb-form-group kb-div-173">
                <label className="label-rt">灵活工位</label>
                <div className="group-content">
                  <span>
                    {(flexible_areas_array &&
                      flexible_areas_array.length &&
                      flexible_areas_array.join('')) ||
                      '暂无'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="kb-row kb-form-66">
          {!isContract && <div>
          <div className="kb-form-group kb-div-210">
            <label>合同会员费</label>
            <div className="group-content">
              <span>¥{utils.fmoney(subRentAmount || 0)}</span>
            </div>
          </div>
          <div className="kb-form-group kb-div-173">
            <label className="label-rt">合同押金</label>
            <div className="group-content">
              <span>¥{utils.fmoney(deposit || 0)}</span>
            </div>
          </div>
          </div>}
        </div>
        <div className="kb-row kb-form-66">
          <div className="kb-form-group kb-div-c39">
            <label>合同总额</label>
            <div className="group-content">
              <span>¥ {utils.fmoney(subTotalAmount || 0)}</span>
            </div>
          </div>
        </div>
      </fieldset>
    )
  },

  changeExtendSubscriptionTemplete(e) {
    let value = e.target.value
    this.setState({
      templete_id: value
    })
  },

  choseTempleteJsx() {
    const { extendSubscriptions, isContract } = this.props
    const { templete_id, isExportTemplete } = this.state
    const { isNeedTemplate } = this.context
    let newExtendSubscriptions=[]
    let station = []
    let noArea=[]
    extendSubscriptions.forEach((sub,index)=>{
      if(sub.template_type === "no_sales_area_contract"){
        noArea.push(sub)
      }else{
        station.push(sub)
      }
    })
    if(isContract){
      newExtendSubscriptions=[...noArea]
    }else{
      newExtendSubscriptions=[...station]
    }
    return (
      <div>
        <div className="kb-title m-bottom-sm">
          <span>选择合同模板</span>
        </div>
        <div>
          {!newExtendSubscriptions.length && !isContract ? (
            <p style={{ padding: '10px 0px' }}>
              您当前没有合同模板，如果要使用请联系管理人员来创建合同模板
            </p>
          ) : (
            ''
          )}
          {!newExtendSubscriptions.length && isContract ? (
              <p style={{ padding: '10px 0px' }}>
                您当前没有无面积租赁合同模板，如果要使用请联系管理人员来创建无面积租赁合同模板
              </p>
            ) : (
              ''
            )}
        </div>
        <div>
          {newExtendSubscriptions.map((json, index) => {
            const { name, id } = json
            return (
              <div style={{ marginBottom: 10 }} key={'key_' + index}>
                <input
                  name="subscriptionsradio"
                  id={index + 'subscriptionsradio'}
                  checked={templete_id == id ? 'checked' : ''}
                  onChange={this.changeExtendSubscriptionTemplete}
                  type="radio"
                  value={id}
                />
                <label
                  htmlFor={index + 'subscriptionsradio'}
                  style={{ marginLeft: 5 }}
                >
                  {name}
                </label>
              </div>
            )
          })}
        </div>
        {!isNeedTemplate ? (
          <div style={{ marginBottom: 15 }}>
            <input
              name="subscriptionsradio"
              id={-1 + 'subscriptionsradio'}
              checked={templete_id == -1 ? 'checked' : ''}
              onChange={this.changeExtendSubscriptionTemplete}
              type="radio"
              value="-1"
            />
            <label
              htmlFor={-1 + 'subscriptionsradio'}
              style={{ marginLeft: 5 }}
            >
              不采用合同模板
            </label>
          </div>
        ) : (
          ''
        )}
        <div style={{ paddingBottom: 30 }} className="flex-vertical-center">
          {templete_id && templete_id != -1 ? (
            isExportTemplete ? (
              <span
                className="bordered-btn"
                style={{ marginLeft: 0, marginRight: 20 }}
              >
                正在下载...
              </span>
            ) : (
              <button
                className="bordered-btn"
                onClick={this.previewSubscription}
                style={{ marginLeft: 0, marginRight: 20 }}
              >
                预览合同
              </button>
            )
          ) : (
            ''
          )}
        </div>
      </div>
    )
  },

  changeIsFile(bool) {
    this.setState({
      isFile: bool
    })
  },

  render() {
    const {
      fields: { files, note },
      error,
      submitting,
      handleSubmit,
      isParkingContract,
      currentSubscription,
      id,
      typeSource
    } = this.props
    const { user, isDraft } = this.context
    const { isFile } = this.state
    const isWaitAudit = this.isWaitAudit()

    const fileList = files.value
    const isCanContract =
      canAccessSpaceContract(user) ||
      canAccessLocationRoles(user, currentSubscription.location_id, [
        'contract',
        'abnormal_contract'
      ])
    const isDraftStatus = isDraft(currentSubscription)
    const isDetail = this.isDetail(currentSubscription)
    const detai_url = isParkingContract
      ? `/admin/locations/${this.props.loc_id}/parking/contract/${currentSubscription.id}`
      : typeSource && typeSource == 'crmrecord'
      ? `/admin/crm/opportunities/sub/organizations/${this.props.org_id}/subscriptions/${currentSubscription.id}?location_id=${this.props.loc_id}&typeSource=crmrecord`
      : `/admin/locations/${this.props.loc_id}/organizations/${this.props.org_id}/subscriptions/${currentSubscription.id}`

    if (isWaitAudit && !isCanContract) {
      return (
        <div>
          <div className="flex-center m-bottom" style={{ padding: 30 }}>
            <img
              src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/approving.png"
              alt="approving"
              style={{ marginRight: 20, width: 40, height: 50 }}
            />
            <span style={{ color: '#454545', fontSize: 24 }}>合同审批中</span>
          </div>
          <div
            className="kb-title"
            style={{
              marginLeft: 30,
              marginRight: 30,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span className="header-title">合同审批</span>
            <Link
              className="bordered-btn"
              style={{ marginLeft: 0 }}
              to={detai_url}
            >
              查看合同详情
            </Link>
          </div>
          <div className="kb-form-container">
            {this.subJsx()}
            {this.choseTempleteJsx()}
            {fileList &&
              fileList.map((json, index) => {
                return (
                  <div
                    style={{
                      marginBottom: 10,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    key={'key_' + index}
                  >
                    <span style={{ marginRight: 5 }}>文件名：</span>
                    <KBDownloadPrivacy url={json.file_url}>
                      <a
                        className="color-link c-pointer"
                        style={{ marginRight: 5 }}
                      >
                        {json.name}
                      </a>
                    </KBDownloadPrivacy>

                    {!isWaitAudit ? (
                      <i
                        className="iconfont delete-file-btn icon-delete"
                        onClick={this.cancelFiles.bind(null, index)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}
            <div>
              <span style={{ marginRight: 14 }}>
                备&nbsp;&nbsp;&nbsp;&nbsp;注:
              </span>
              <span>{currentSubscription.note}</span>
            </div>
          </div>
        </div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.updateSubscriptionForm)}>
        {isParkingContract ? (
          <header className="kb-form-header">
            <h2 className="header-title">合同审批</h2>
          </header>
        ) : (
          <div
            className="kb-title"
            style={{ marginLeft: 30, marginRight: 30, border: 'none' }}
          >
            <span className="header-title">合同审批</span>
          </div>
        )}
        {isDetail ? (
          <div
            className="hint-frame"
            style={{ marginLeft: 30, marginRight: 30 }}
          >
            <img
              src="https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/hint.png"
              alt="hint"
            />
            <span>请填写审批资料</span>
          </div>
        ) : (
          ''
        )}
        <KBFormError error={this.props.error} />
        <div className="kb-form-container">
          <div
            className="kb-title"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span>合同概况</span>
            <Link
              className="bordered-btn"
              style={{ marginLeft: 0 }}
              to={detai_url}
            >
              查看合同详情
            </Link>
          </div>
          {this.subJsx()}
          {this.choseTempleteJsx()}
          <div className="kb-title">
            <span>补充协议</span>
          </div>
          <div
            style={{
              color: '#666',
              display: 'flex',
              alignItems: 'center',
              marginTop: 15,
              marginBottom: 20
            }}
          >
            <span>是否有补充协议？</span>
            <div style={{ marginLeft: 20, marginRight: 15 }}>
              <input
                type="radio"
                id="noFile"
                name="fileRadio"
                checked={!isFile ? 'checked' : false}
                onChange={this.changeIsFile.bind(null, false)}
              />
              <label htmlFor="noFile" style={{ marginLeft: 5 }}>
                没有补充协议
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="isFile"
                name="fileRadio"
                checked={isFile ? 'checked' : false}
                onChange={this.changeIsFile.bind(null, true)}
              />
              <label htmlFor="isFile" style={{ marginLeft: 5 }}>
                有补充协议
              </label>
            </div>
          </div>
          {isWaitAudit && isDraftStatus ? (
            ''
          ) : isFile ? (
            <div className="kb-row kb-form-210">
              <KBMoreFilesUpload
                className="kb-form-group kb-form-c39 kb-form-alone"
                text={files.value.length ? '补充协议已上传' : '上传补充协议'}
                uploadingText="正在上传"
                prefix={config.uploadDir + 'subscriptions/'}
                buttonClass={'bordered-btn m-none'}
                defaultFiles={files.value}
                type="attachment"
                ref="files"
                accept=".pdf,.doc,.docx"
                field={files}
              />
            </div>
          ) : (
            ''
          )}
          {fileList &&
            fileList.map((json, index) => {
              return (
                <div
                  style={{
                    marginBottom: 10,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  key={'key_' + index}
                >
                  <span style={{ marginRight: 5 }}>文件名：</span>
                  <KBDownloadPrivacy url={json.file_url}>
                    <a
                      className="color-link c-pointer"
                      style={{ marginRight: 5 }}
                    >
                      {json.name}
                    </a>
                  </KBDownloadPrivacy>
                  {!isWaitAudit ? (
                    <i
                      className="iconfont delete-file-btn icon-delete"
                      onClick={this.cancelFiles.bind(null, index)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              )
            })}
          <div className="kb-row" style={{ paddingTop: 20 }}>
            <div style={{ color: '#666', marginBottom: 10 }}>备注</div>
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={note}
              childDivStyle={{ width: '100%' }}
            />
          </div>
          <div className="kb-row t-right">
            {/* {
              isWaitAudit ? (
                <div>
                  <KBButton className='certain-btn' type='button' submitting = { submitting } onClick={ this.setSubscriptionSuccess.bind(null, currentSubscription.id) }>通过</KBButton>
                  <KBButton className='certain-btn' type='button' submitting = { submitting } onClick={ this.setSubscriptionFailure.bind(null, currentSubscription.id) } style={{marginLeft : 30}}>不通过</KBButton>
                </div>
              ) : isDraftStatus ? '' : (
                <KBButton className='certain-btn' type='submit' submitting = { submitting } style={{marginRight : 0}}>提交审批</KBButton>
              )
            } */}
            <div>
              <KBButton
                className="certain-btn"
                type="button"
                submitting={submitting}
                onClick={() => {
                  this.setState({
                    approvalModal: true,
                    id: currentSubscription.id
                  })
                }}
              >
                下一步
              </KBButton>
            </div>

            <Modal
              title="合同审批"
              footer={null}
              visible={this.state.approvalModal}
              onCancel={() => {
                this.setState({ approvalModal: false, approvalLoading: false })
              }}
            >
              <div>是否通过该合同？</div>
              <div className="kb-row fixed-form-btn">
                <span
                  className="cancel-btn"
                  onClick={() => {
                    this.setState({
                      approvalModal: false,
                      approvalLoading: false
                    })
                  }}
                  style={{ marginRight: 10 }}
                >
                  取消
                </span>
                <KBButton
                  className="certain-btn"
                  type="submit"
                  submitting={this.state.approvalLoading}
                  onClick={this.setSubscriptionSuccess}
                >
                  确定
                </KBButton>
              </div>
            </Modal>
          </div>
        </div>
      </form>
    )
  }
})

SubscriptionApprovalForm.PropTypes = {
  currentOrganization: PropTypes.object,
  currentSubscription: PropTypes.object,
  loc_id: PropTypes.number,
  org_id: PropTypes.number,
  step: PropTypes.string
}

function mapStateToProps(state, props) {
  const currentOrganization =
    (props.currentOrganization.id && props.currentOrganization) ||
    selectors.getOrg(state, props.org_id) ||
    {}
  const extendSubscriptions = selectors.getSubscriptionTempletesOfState(
    state,
    'enabled'
  )
  let params = {}
  const currentSubscription = props.currentSubscription || {}
  params.note = currentSubscription.note
  params.files = currentSubscription.files
  return {
    currentOrganization,
    extendSubscriptions,
    initialValues: {
      ...params
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubscriptionActions: bindActionCreators(
      subscriptionActions.update,
      dispatch
    ),
    successAction: bindActionCreators(successState, dispatch),
    failAction: bindActionCreators(apiCallFailure, dispatch),
    getOrgAction: bindActionCreators(orgActions.get, dispatch)
  }
}

const validate = values => {
  const errors = {}

  // if (valid.required(values.name)) {
  //   errors.name = '请输入公司简称'
  // }

  return errors
}

const formConfig = {
  form: 'SubscriptionApprovalForm',
  fields: ['files', 'note'],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionApprovalForm)
