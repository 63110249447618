import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as valid from 'utils/validate'
import { industriesActions, raisingsActions } from 'app/actions'
import {
  KBForm,
  KBInput,
  KBSelect,
  KBRadio,
  KBImageCropperInput,
  KBLogo,
  KBTextarea,
  KBButton
} from 'components'
import { api } from 'app/services'
import { ADD_ORGANIZATION } from 'app/constants/hint'
import { getIndustries, getRaisings } from 'app/selectors'
import { listIsNullCallBack } from 'utils/kbUtil'
import { notification } from 'antd'
import { Select } from 'antd'
const commonEmails = [
  "gmail.com",
  "hotmail.com",
  "qq.com",
  "163.com",
  "126.com",
  "189.com",
  "139.com",
  "sina.com",
  "sogou.com"
]

const isImportant = [
  { title: '是', value: true },
  { title: '否', value: false }
]

const isVisible = [
  { title: '是', value: true },
  { title: '否', value: false }
]

const isLegal = [
  { title: '已确认企业合法性', value: true },
  { title: '未确认企业合法性', value: false }
]

const isInternal = [
  { title: 'internal', value: true },
  { title: 'external', value: false }
]

const classNmaeCom = {
  className: 'kb-form-group kb-div-39'
}

var OrgBasicMessageForm = React.createClass({
  componentWillMount() {
    const { type } = this.props
    if (type === 'update') {
      return
    }
    const {
      fields: {
        is_legal,
        industry_id,
        full_name,
        is_important,
        external_visible,
        auto_auth,
        enjoy_bridge_listing_price,
      },
      urlParams,
      source,
      org
    } = this.props
    if (source === 'crm' && urlParams) {
      urlParams.idstry_id && industry_id.onChange(urlParams.idstry_id)
      urlParams.full_name && full_name.onChange(urlParams.full_name)
      is_important.onChange(false)
      external_visible.onChange(false)
    }
    if (!(org && org.id)) {
      is_legal.onChange(false)
      auto_auth.onChange(true)
      enjoy_bridge_listing_price.onChange(true)
      external_visible.onChange(true)
    }
  },

  componentDidMount() {
    const {
      getIndustriesActions,
      industries,
      raisings,
      getRaisingsActions
    } = this.props
    listIsNullCallBack(industries, () => {
      // 行业列表
      api.getIndustries().then(
        json => {
          getIndustriesActions.success(json)
        },
        error => {
          getIndustriesActions.failure(error)
        }
      )
    })

    listIsNullCallBack(raisings, () => {
      // 融资情况
      api.getRaisings().then(
        json => {
          getRaisingsActions.success(json)
        },
        error => {
          getRaisingsActions.failure(error)
        }
      )
    })
  },

  saveOrgBasicMessage(model) {
    const { saveParams } = this.props
    saveParams && saveParams(model, 2) // 保存form params并跳转到第二步
  },

  failureCallBack(err) {
    if (!err) return
    return notification.error({ message: '上传失败', description: err })
  },

  render() {
    const {
      fields: {
        name,
        full_name,
        industry_id,
        fund_raising_id,
        floor_info_id,
        is_important,
        external_visible,
        is_internal,
        is_legal,
        logo,
        description,
        notes,
        is_travel,
        auto_auth,
        enjoy_bridge_listing_price,
        has_org_email,
        org_emails
      }
    } = this.props
    const {
      industries,
      raisings,
      floorInfos,
      org,
      submitting,
      handleSubmit
    } = this.props
    const hint = ADD_ORGANIZATION

    return (
      <KBForm
        type="reduxForm"
        fields={this.props.fields}
        onSubmit={handleSubmit(this.saveOrgBasicMessage)}
      >
        <header className="kb-form-header">
          <h2 className="header-title">公司基本信息</h2>
        </header>
        <div className="kb-form-container">
          <div className="kb-row kb-form-48">
            <KBInput
              className="kb-form-group kb-div-39"
              title="全称"
              hint={hint.full_name}
              isRequired={true}
              fistFoucs={true}
              field={full_name}
            />
            <div className="kb-row kb-form-66" style={{ float: 'left' }}>
              <KBInput
                className="kb-form-group kb-div-48"
                title="简称"
                hint={hint.short_name}
                isRequiredR={true}
                field={name}
                titleClass="label-rt"
              />
            </div>
          </div>
          <div className="kb-row kb-form-48">
            <KBSelect
              defaultValue="-1"
              className="kb-form-group kb-div-39"
              title="行业"
              field={industry_id}
              arrayData={industries}
            />
            <div className="kb-row kb-form-66" style={{ float: 'left' }}>
              <KBSelect
                defaultValue="-1"
                className="kb-form-group kb-div-48"
                titleClass="label-rt"
                title="阶段"
                field={fund_raising_id}
                arrayData={raisings}
              />
            </div>
          </div>
          {!this.props.isOCRM && (
            <div className="kb-row">
              <KBSelect
                defaultValue="-1"
                className="kb-form-group kb-form-48"
                style={{ width: 410 }}
                titleClass="label-rt"
                title="公司楼层"
                field={floor_info_id}
                arrayData={floorInfos}
              />
            </div>
          )}
          <div className="kb-row kb-form-48">
            <KBRadio
              {...classNmaeCom}
              inClassName="kb-from-initial"
              divClassName="kb-from-radio"
              rightLabel="label-rt"
              title="重要客户"
              arrayType={isImportant}
              name="important"
              field={is_important}
            />
            <KBRadio
              {...classNmaeCom}
              inClassName="kb-from-initial"
              divClassName="kb-from-radio"
              rightLabel="label-rt"
              title="外部可见"
              arrayType={isVisible}
              name="external"
              field={external_visible}
            />
          </div>
          {!this.props.isOCRM && (
            <div className="kb-row kb-form-48">
              <KBRadio
                {...classNmaeCom}
                inClassName="kb-from-initial"
                divClassName="kb-from-radio"
                rightLabel="label-rt"
                title="是否关联企业"
                arrayType={isInternal}
                name="internal"
                field={is_internal}
              />
              <div />
            </div>
          )}
          <div className="kb-row kb-form-48">
            <KBRadio
              {...classNmaeCom}
              inClassName="kb-from-initial"
              divClassName="kb-from-radio"
              rightLabel="label-rt"
              title="是否是出差公司"
              arrayType={isVisible}
              name="travel"
              field={is_travel}
            />

            <KBRadio
              {...classNmaeCom}
              inClassName="kb-from-initial"
              divClassName="kb-from-radio"
              rightLabel="label-rt"
              title="是否允许小程序用户自助认证"
              arrayType={isVisible}
              name="auto_auth"
              field={auto_auth}
              isRequired
            />
          </div>
          <div className="kb-row kb-form-48">
            <KBRadio
              {...classNmaeCom}
              inClassName="kb-from-initial"
              divClassName="kb-from-radio"
              rightLabel="label-rt"
              title="是否享受办公楼会员价"
              arrayType={isVisible}
              name="enjoy_bridge_listing_price"
              field={enjoy_bridge_listing_price}
              isRequired
            />
          </div>

          <div className="kb-row kb-form-66">
            <KBRadio
              {...classNmaeCom}
              inClassName="kb-from-initial"
              divClassName="kb-from-radio"
              rightLabel="label-rt"
              title="是否有企业邮箱"
              arrayType={isVisible}
              name="has_org_email"
              field={has_org_email}
              isRequired
            />

            {
              [true, 'true'].includes(has_org_email.value) && (
                <div>
              <div className="kb-form-group kb-form-c18 kb-form-alone kb-input">
                  <label>
                    邮箱后缀
                    <span className="must-fill">*</span>
                  </label>
                  <Select
                    style={{ width: '100%' }}
                    value={org_emails.value}
                    mode='tags'
                    notFoundContent='请输入企业邮箱后缀'
                    onChange={val => org_emails.onChange(val)}
                  >
                    {/* {
                      emailList.map(i => {
                        return <Option key={i.value} value={i.value}>{i.label}</Option>
                      })
                    } */}
                  </Select>
                  <p
                    style={{
                      color: '#c8c8c8',
                      fontSize: 12,
                      padding: '2px 0 0 11px'
                    }}
                  >
                    邮箱后缀是指邮箱地址中“@”符号后面的部分，也就是邮箱的域名。例如，在“username@example.com”这个邮箱地址中，“example.com”就是邮箱后缀。
                  </p>
                  {org_emails.error && org_emails.touched && (
                    <p className="lr-error">{org_emails.error}</p>
                  )}
                </div>
            </div>
              )
            }
          </div>

          <div className="kb-row kb-form-66">
            <KBImageCropperInput
              title="Logo"
              aspect={1}
              className="kb-form-group kb-form-210 kb-form-alone"
              field={logo}
              type="origanizations"
              accept="image/*"
              failureCallBack={this.failureCallBack}
              isPublic
            >
              <div className="upload-portrait">
                {!logo.dirty && org && org.name ? (
                  <KBLogo
                    size={100}
                    numberOfChars={4}
                    obj={org}
                    style={{ display: 'inline-block' }}
                  />
                ) : (
                  <img
                    src={
                      logo.value ||
                      'https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_pic@3x.png'
                    }
                    style={{
                      width: '100px',
                      height: '100px',
                      display: 'inline-block',
                      marginLeft: 0
                    }}
                  />
                )}
                <button className="bordered-btn" type="button">
                  {logo.dirty ? '已上传公司LOGO' : '上传公司LOGO'}
                </button>
              </div>
            </KBImageCropperInput>
            <div />
          </div>
          <div className="kb-row kb-form-84">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              hint={hint.intro}
              field={description}
              title="简介"
            />
            <div />
          </div>
          <div className="kb-row kb-form-84">
            <KBTextarea
              className="kb-form-group kb-form-c18 kb-form-alone"
              field={notes}
              title="备注"
            />
            <div />
          </div>
          <div className="kb-row">
            <KBButton
              className="certain-btn"
              type="submit"
              submitting={submitting}
            >
              下一步
            </KBButton>
          </div>
        </div>
      </KBForm>
    )
  }
})

function mapStateToProps(state, props) {
  const industries = getIndustries(state)
  const raisings = getRaisings(state)
  const org = props.org
  console.log(111, org);
  return {
    industries,
    raisings,
    initialValues: org
      ? {
          ...org,
          industry_id: org.industry && org.industry.id,
          fund_raising: org.fund_raising && org.fund_raising.id,
          org_emails: org.org_emails || [],
          has_org_email: org.id ? org.has_org_email : true
        }
      : {},
    org
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getIndustriesActions: bindActionCreators(industriesActions.get, dispatch),
    getRaisingsActions: bindActionCreators(raisingsActions.get, dispatch)
  }
}

const validate = values => {
  const errors = {}

  if (valid.required(values.name)) {
    errors.name = '请输入公司简称'
  }

  if (valid.required(values.full_name)) {
    errors.full_name = '请输入公司全称'
  }

  if (valid.required(values.auto_auth)) {
    errors.auto_auth = '请选择是否允许小程序用户自助认证'
  }

  if (valid.required(values.has_org_email)) {
    errors.has_org_email = '请选择是否有企业邮箱'
  }

  if(values.has_org_email === 'true' || values.has_org_email === true) {
    if(!values.org_emails || !values.org_emails.length) {
      errors.org_emails = '请填写企业邮箱后缀'
    }
    if(values.org_emails && values.org_emails.length && values.org_emails.some(i => {
      return commonEmails.some(email => i.includes(email))
    })) {
      errors.org_emails = '请填写企业邮箱后缀'
    }
    if(values.org_emails && values.org_emails.length && values.org_emails.some(i => {
      return i.includes('@') || !/^(?=.*[^.])\S+\.\S+$/.test(i)
    })) {
      errors.org_emails = '请填写正确的邮箱后缀'
    }
  }

  return errors
}

const formConfig = {
  form: 'OrgBasicMessageForm',
  fields: [
    'name',
    'full_name',
    'industry_id',
    'floor_info_id',
    'fund_raising_id',
    'is_important',
    'external_visible',
    'is_internal',
    'is_legal',
    'logo',
    'description',
    'notes',
    'is_travel',
    'auto_auth',
    'enjoy_bridge_listing_price',
    'has_org_email',
    'org_emails'
  ],
  validate: validate,
  touchOnBlur: false
}

export default reduxForm(
  formConfig,
  mapStateToProps,
  mapDispatchToProps
)(OrgBasicMessageForm)
